import { SortDirectionType } from "@/models/Sort";
import { ErrorModel } from "@/models/ErrorModel";
import { KienlongPayClient } from "@/api/kienlong_pay_http_client";
import { PosDeviceModel } from "@/models/PosModelModel";
import { PosDeviceModelRepository } from "../PosDeviceModelRepository";
import { mapPosDeviceModel } from "@/adapters/pos_model_adapter";
export class PosDeviceModelRepositoryImp implements PosDeviceModelRepository {
  private client: KienlongPayClient;
  constructor(kienlongPayClient: KienlongPayClient) {
    this.client = kienlongPayClient;
  }

  public async getListPosModel({
    name,
    minPrice,
    maxPrice,
    page,
    pageSize,
    sortDirection,
    sortBy,
  }: {
    name?: string;
    minPrice?: number;
    maxPrice?: number;
    page?: number;
    pageSize?: number;
    sortDirection?: SortDirectionType;
    sortBy?: string;
  }): Promise<{
    pageNumber: number;
    pageSize: number;
    totalSize: number;
    totalPage: number;
    items: Array<PosDeviceModel>;
  }> {
    try {
      let resp =
        await this.client.posDeviceModelController.portalGetListPosModel({
          name,
          minPrice,
          maxPrice,
          page,
          pageSize,
          sortDirection,
          sortBy,
        });
      if (resp.data.code === 0) {
        return {
          pageNumber: resp.data.data?.pageNumber ?? 0,
          pageSize: resp.data.data?.pageSize ?? 0,
          totalPage: resp.data.data?.totalPage ?? 0,
          totalSize: resp.data.data?.totalSize ?? 0,
          items:
            resp.data.data?.items?.map((d) => {
              return mapPosDeviceModel(d);
            }) ?? [],
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
