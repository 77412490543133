import { BankAccountType } from "@/models/BankAccountModel";
import { PayoutStatusType, SinglePayoutType } from "@/models/PayoutModel";
import { DataTablePayout } from "../components/column-payout";

export const dataTablePayout = (() => {
  const arrayPayout: DataTablePayout[] = [];
  for (let i = 0; i < 20; i++) {
    arrayPayout.push({
      id: `ADMIN-DEV${Math.floor(Math.random() * 789)}`,
      amount: 0,
      content: "",
      denyContent: "",
      fromAccountName: "",
      fromAccountNo: "",
      fromBranch: BankAccountType.KLB,
      status: PayoutStatusType.WAITING,
      toAccountName: "",
      toAccountNo: "",
      toBankBin: "",
      toBankLogo: "",
      toBankName: "",
      type: SinglePayoutType.SINGLE,
      txnNumber: "",
      transactionTime: "",
    });
  }
  return arrayPayout;
})();
