import { BankAccountModel, BankAccountType } from "./BankAccountModel";
import {
  CustomerInfoFormModel,
  CustomerInfoType,
  CustomerModel,
} from "./CustomersModel";
import { PayBoxTransactionType } from "./PayBoxTransactionModel";
import { PaymentType } from "./PaylinkModel";
import { ProductModel } from "./ProductModal";
import { ShopSettingModel } from "./ShopModel";
import {
  TransactionResponseModel,
  TransactionStatusType,
} from "./TransactionModel";

export enum PayPageStatusType {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum PayStatusALL {
  ALL = "NONE",
}

export enum PayPagePeriodType {
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
  THREE_MONTHS = "THREE_MONTHS",
  SIX_MONTHS = "SIX_MONTHS",
  YEARLY = "YEARLY",
}

export enum PagePayOrderStatusType {
  CREATED = "CREATED",
  PAID = "PAID",
  CANCEL = "CANCEL",
}

export interface PayPageModel {
  id: string;
  name: string;
  code: string;
  url: string;
  bankAccount: BankAccountModel;
  revenue: number;
  totalProducts: number;
  status: PayPageStatusType;
  createdAt: string;
  createdBy: string;
  modifiedAt: string;
  modifiedBy: string;
  periodType: PayPagePeriodType;
  totalSendMailSms: number;
}

export interface PayPageDetailModel {
  id: number;
  name: string;
  code: string;
  url: string;
  bankAccount: BankAccountModel;
  revenue: number;
  totalProducts: number;
  status: PayPageStatusType;
  createdAt: string;
  createdBy: string;
  modifiedAt: string;
  modifiedBy: string;
  periodType: PayPagePeriodType;
  products: Array<ProductModel>;
  customerInfoType: CustomerInfoType;
  customerInfoForm: CustomerInfoFormModel;
  customer: CustomerModel;
  contactEmail: string;
  contactPhone: string;
  merchantCode: string;
  merchantName: string;
  totalTransactions: number;
  shopSetting: ShopSettingModel;
}

export interface PayPageOrderModel {
  id: string;
  referenceId: string;
  transactionId: string;
  amount: number;
  attempts: number;
  fee: number;
  actuallyReceived: number;
  status: PagePayOrderStatusType;
  createdAt: string;
}

export interface PayPageOrderItemModel {
  id: number;
  name: string;
  description: string;
  imageUrl: string;
  price: number;
  quantity: number;
}

export interface PayPageOrderDetailModal {
  id: string;
  referenceId: string;
  transactionId: string;
  amount: number;
  attempts: number;
  fee: number;
  actuallyReceived: number;
  status: PagePayOrderStatusType;
  createdAt: string;
  customerName: string;
  customerEmail: string;
  customerPhone: string;
  customerAddress: string;
  items: Array<PayPageOrderItemModel>;
  transaction: TransactionResponseModel;
}

export interface PayPageTransactionModel {
  id: string;
  status: TransactionStatusType;
  amount: number;
  refTransactionId: string;
  createDateTime: string;
  depositTime: string;
  completeTime: string;
  virtualAccount: string;
  description: string;
  paymentType: PaymentType;
  bankType: BankAccountType;
  successUrl: string;
  failUrl: string;
  callbackAfter: number;
  customerName: string;
  customerEmail: string;
  customerPhone: string;
  customerAddress: string;
  merchantId: string;
  merchantCode: string;
  merchantName: string;
  payLinkCode: string;
  payboxTransactionType: PayBoxTransactionType;
  txnNumber: string;
  accountName: string;
  accountNo: string;
  interBankTrace: string;
  orderId: string;
  orderStatus: PagePayOrderStatusType;
  forControl: boolean;
}

export interface ResponsePayPageTransaction {
  pageNumber: number;
  pageSize: number;
  totalSize: number;
  totalPage: number;
  items: PayPageTransactionModel[];
}

export interface PayPageResponse {
  pageNumber: number;
  pageSize: number;
  totalSize: number;
  totalPage: number;
  items: PayPageModel[];
}

export interface CreateOrderModel {
  payPageCode: string;
  paymentType: PaymentType;
  customerAddress?: string;
  customerEmail?: string;
  customerName?: string;
  customerPhone?: string;
  failUrl?: string;
  note?: string;
  periodic?: boolean;
  redirectAfter?: number;
  refTransactionId?: string;
  successUrl?: string;
  items: {
    productId: number;
    quantity?: number;
    price?: number;
  }[];
}

export const mapModelPayPage = (data: PayPageModel) => {
  const payPageView: PayPageModel = {
    id: data?.id ? data?.id : "",
    name: data?.name ? data?.name : "-",
    code: data?.code ? data?.code : "-",
    url: data?.url ? data?.url : "-",
    bankAccount: {
      accountName: data?.bankAccount?.accountName
        ? data?.bankAccount?.accountName
        : "-",
      accountNo: data?.bankAccount?.accountNo
        ? data?.bankAccount?.accountNo
        : "-",
      bankType: data?.bankAccount?.bankType
        ? data?.bankAccount?.bankType
        : BankAccountType.UMEE,
      defaultAccount: true,
      id: data?.bankAccount?.id ? data?.bankAccount?.id : "-",
    },
    revenue: data?.revenue ? data?.revenue : 0,
    totalProducts: data?.totalProducts ? data?.totalProducts : 0,
    status: data?.status ? data?.status : PayPageStatusType.ACTIVE,
    createdAt: data?.createdAt ? data?.createdAt : "-",
    createdBy: data?.createdBy ? data?.createdBy : "-",
    modifiedAt: data?.modifiedAt ? data?.modifiedAt : "-",
    modifiedBy: data?.modifiedBy ? data?.modifiedBy : "-",
    periodType: data?.periodType ? data?.periodType : PayPagePeriodType.DAILY,
    totalSendMailSms: data.totalSendMailSms,
  };
  return payPageView;
};

export const mapModelTransactionPayPage = (data: PayPageTransactionModel) => {
  const payPageView: PayPageTransactionModel = {
    id: data?.id ? data?.id : "",
    status: data?.status ?? TransactionStatusType.NONE,
    amount: data?.amount ?? 0,
    refTransactionId: data?.refTransactionId ?? "",
    createDateTime: data?.createDateTime ?? "",
    depositTime: data?.depositTime ?? "",
    completeTime: data?.completeTime ?? "",
    virtualAccount: data?.virtualAccount ?? "",
    description: data?.description ?? "",
    paymentType: data?.paymentType ?? PaymentType.VIET_QR,
    bankType: data?.bankType ?? BankAccountType.KLB,
    successUrl: data?.successUrl ?? "",
    failUrl: data?.failUrl ?? "",
    callbackAfter: 0,
    customerName: data?.customerName ?? "",
    customerEmail: data?.customerEmail ?? "",
    customerPhone: data?.customerPhone ?? "",
    customerAddress: data?.customerAddress ?? "",
    accountName: data?.accountName ?? "",
    accountNo: data?.accountNo ?? "",
    forControl: data?.forControl ?? false,
    interBankTrace: data?.interBankTrace ?? "",
    merchantCode: data?.merchantCode ?? "",
    merchantId: data?.merchantId ?? "",
    merchantName: data?.merchantName ?? "",
    orderId: data?.orderId ?? "",
    orderStatus: data?.orderStatus ?? "",
    payboxTransactionType: data?.payboxTransactionType ?? "",
    payLinkCode: data?.payLinkCode ?? "",
    txnNumber: data?.txnNumber ?? "",
  };
  return payPageView;
};
