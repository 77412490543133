import React, { CSSProperties, useId, useLayoutEffect, useState } from "react";
import "./tab-bar-custom.css";
import "@/styles";

export const TabBarCustomer = ({
  data,
  defaultIndex,
  onChange,
  styleTabBar,
  scrollComponent,
  classNameScrollComponent,
  duration = 300,
  isLock = false,
  disableTabs,
  autoNext = false,
  isFullWidth = false,
  onTapTab,
}: {
  data: React.ReactNode[];
  defaultIndex: number;
  onChange: (v: number) => void;
  styleTabBar?: CSSProperties | undefined;
  scrollComponent?: React.ReactNode;
  classNameScrollComponent?: string;
  duration?: number;
  isLock?: boolean;
  disableTabs?: number[];
  autoNext?: boolean;
  isFullWidth?: boolean;
  onTapTab?: (i: number) => void;
}) => {
  const elementId = useId();
  const [option, setOption] = useState(defaultIndex);
  const [clientWidth, setClientWidth] = useState(0);

  useLayoutEffect(() => {
    setTimeout(() => {
      const clientElement = document.getElementById(`${elementId}-${option}`);
      if (clientElement) {
        setClientWidth(clientElement.clientWidth);
      }
    }, 150);
  }, [elementId, option]);

  useLayoutEffect(() => {
    if (autoNext) {
      setTimeout(() => {
        setOption(defaultIndex);
      }, 150);
    }
  }, [defaultIndex]);

  function _translate(index: number): number {
    if (index <= 0) {
      return 0;
    } else {
      return (
        (document.getElementById(`${elementId}-${index - 1}`)?.clientWidth ??
          0) + _translate(index - 1)
      );
    }
  }
  return (
    <div className="tab-bar-custom" style={styleTabBar}>
      <div
        className="row"
        style={{
          height: "100%",
          justifyContent: "flex-start",
          alignItems: "center",
          zIndex: 1,
          width: "100%",
        }}
      >
        {data.map((d, i) => {
          return (
            <div
              id={`${elementId}-${i}`}
              key={`${d}-${i}`}
              onClick={() => {
                onTapTab && onTapTab(i);
                if (option !== i && !isLock && !disableTabs?.includes(i)) {
                  setOption(i);
                  onChange(i);
                }
              }}
              className={`column`}
              style={{
                cursor: "pointer",
                justifyContent: "center",
                alignItems: "center",
                color: i === option ? "#3366FF" : "#9CA3AF",
                fontWeight: 500,
                width: isFullWidth ? "100%" : "fit-content",
                zIndex: "1",
              }}
            >
              {d}
            </div>
          );
        })}
      </div>
      <div
        className="row"
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          justifyContent: "flex-start",
          top: "0px",
          left: "0px",
          zIndex: 0,
        }}
      >
        <div
          className={`tab-bar-custom__label ${classNameScrollComponent ?? ""}`}
          style={{
            width: `${clientWidth ?? 0}px`,
            height: "100%",
            transition: `${duration}ms ease-in-out`,
            transform: `translateX(${_translate(option)}px)`,
            borderRadius: 0,
          }}
        >
          {scrollComponent}
        </div>
      </div>
    </div>
  );
};
