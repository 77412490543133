import { mapPaySubscriptionPlanModel } from "@/adapters/pay_subscription_plan_adapter";
import { KienlongPayClient } from "@/api/kienlong_pay_http_client";
import { ErrorModel } from "@/models/ErrorModel";
import {
  PaySubscriptionPlanCreateStatus,
  PaySubscriptionPlanModel,
  PaySubscriptionPlanPaymentType,
  PaySubscriptionPlanStatus,
  PaySubscriptionPlanType,
} from "@/models/PaySubscriptionPlanModel ";
import { FixedTimeModel, TimeType } from "@/models/TimeModel";
import { PaySubscriptionPlanRepository } from "../PaySubscriptionPlanRepository";

export class PaySubscriptionPlanRepositoryImp
  implements PaySubscriptionPlanRepository
{
  private client: KienlongPayClient;
  constructor(kienlongPayClient: KienlongPayClient) {
    this.client = kienlongPayClient;
  }

  public async getAllPaySubscriptionPlan({
    keyword,
    page,
    pageSize,
    fromDate,
    toDate,
    type,
    status,
    paymentType,
  }: {
    keyword?: string;
    page?: number;
    pageSize?: number;
    fromDate?: string;
    toDate?: string;
    type?: PaySubscriptionPlanType;
    status?: PaySubscriptionPlanStatus;
    paymentType?: PaySubscriptionPlanPaymentType;
  }): Promise<{
    pageNumber: number;
    pageSize: number;
    totalSize: number;
    totalPage: number;
    items: Array<PaySubscriptionPlanModel>;
  }> {
    try {
      let resp =
        await this.client.paySubscriptionPlan.getAllPaySubscriptionPlan1({
          keyword: keyword,
          page: page,
          size: pageSize,
          sort: ["createdAt,desc"],
          fromDate: fromDate,
          toDate: toDate,
          type: type,
          status: status,
          paymentType: paymentType,
        });
      if (resp.data.code === 0) {
        return {
          pageNumber: resp.data.data?.pageNumber ?? 0,
          pageSize: resp.data.data?.pageSize ?? 0,
          totalSize: resp.data.data?.totalSize ?? 0,
          totalPage: resp.data.data?.totalPage ?? 0,
          items:
            resp.data.data?.items?.map((d) => {
              return mapPaySubscriptionPlanModel(d);
            }) ?? [],
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getDetailPaySubscriptionPlan({
    id,
  }: {
    id: string;
  }): Promise<PaySubscriptionPlanModel> {
    try {
      let resp =
        await this.client.paySubscriptionPlan.getDetailPaySubscriptionPlan({
          id: id,
        });
      if (resp.data.code === 0) {
        return mapPaySubscriptionPlanModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async deletePaySubscriptionPlan({
    id,
  }: {
    id: string;
  }): Promise<{ success: boolean }> {
    try {
      let resp =
        await this.client.paySubscriptionPlan.deletePaySubscriptionPlan({
          id: id,
        });
      if (resp.data.code === 0 && resp.data.data) {
        return {
          success: resp?.data.data.success ?? false,
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async createPaySubscriptionPlan({
    name,
    description,
    status,
    cycleValue,
    timeType,
    isFixedPaymentTime,
    fixedTime,
    expirationDays,
    type,
    fixedAmount,
    bankAccountId,
    paymentType,
  }: {
    name: string;
    description: string;
    status: PaySubscriptionPlanCreateStatus;
    cycleValue: number;
    timeType: TimeType;
    isFixedPaymentTime: boolean;
    fixedTime: FixedTimeModel;
    expirationDays: number;
    type: PaySubscriptionPlanType;
    fixedAmount: number;
    bankAccountId: string;
    paymentType: PaySubscriptionPlanPaymentType;
  }): Promise<PaySubscriptionPlanModel> {
    try {
      let resp =
        await this.client.paySubscriptionPlan.createPaySubscriptionPlan({
          createPaySubscriptionPlanRequest: {
            name: name,
            description: description,
            status: status,
            cycleValue: cycleValue,
            timeType: timeType,
            isFixedPaymentTime: isFixedPaymentTime,
            fixedTime: fixedTime,
            expirationDays: expirationDays,
            type: type,
            fixedAmount: fixedAmount,
            bankAccountId: bankAccountId,
            paymentType: paymentType,
          },
        });
      if (resp.data.code === 0) {
        return mapPaySubscriptionPlanModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async updatePaySubscriptionPlan({
    id,
    name,
    description,
    status,
    cycleValue,
    timeType,
    isFixedPaymentTime,
    fixedTime,
    expirationDays,
    type,
    fixedAmount,
    callbackUrl,
    bankAccountId,
    paymentType,
  }: {
    id: string;
    name: string;
    description: string;
    status: PaySubscriptionPlanStatus;
    cycleValue: number;
    timeType: TimeType;
    isFixedPaymentTime: boolean;
    fixedTime: FixedTimeModel;
    expirationDays: number;
    type: PaySubscriptionPlanType;
    fixedAmount: number;
    callbackUrl: string;
    bankAccountId: string;
    paymentType: PaySubscriptionPlanPaymentType;
  }): Promise<PaySubscriptionPlanModel> {
    try {
      let resp =
        await this.client.paySubscriptionPlan.updatePaySubscriptionPlan({
          updatePaySubscriptionPlanRequest: {
            id: id,
            name: name,
            description: description,
            status: status,
            cycleValue: cycleValue,
            timeType: timeType,
            isFixedPaymentTime: isFixedPaymentTime,
            fixedTime: fixedTime,
            expirationDays: expirationDays,
            type: type,
            fixedAmount: fixedAmount,
            // callbackUrl: callbackUrl,
            bankAccountId: bankAccountId,
            paymentType: paymentType,
          },
        });
      if (resp.data.code === 0) {
        return mapPaySubscriptionPlanModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
