import { FlexSalaryClient } from "@/api/flexsalary_http_client";
import { ErrorModel } from "@/models/ErrorModel";
import { EnterpriseRepository } from "../EnterpriseRepository";
import { EnterpriseRegistrationRequestProgramType } from "../models/EnterpriseModel";

export class EnterpriseRepositoryImp implements EnterpriseRepository {
  private _c: FlexSalaryClient;
  constructor(flexsalaryClient: FlexSalaryClient) {
    this._c = flexsalaryClient;
  }

  public async check(): Promise<{ status: string }> {
    try {
      let resp = await this._c.enterPrice.checkEnterprise();
      if (resp.data.code === 20000) {
        return {
          status: resp.data.data?.status ?? "",
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
  public async register({
    numberOfEmployeeExpected,
    paySalaryLimitation,
    programType,
  }: {
    numberOfEmployeeExpected: number;
    paySalaryLimitation: number;
    programType: EnterpriseRegistrationRequestProgramType;
  }): Promise<void> {
    try {
      let resp = await this._c.enterPrice.registerEnterprise({
        enterpriseRegistrationRequest: {
          numberOfEmployeeExpected: numberOfEmployeeExpected,
          paySalaryLimitation: paySalaryLimitation,
          programType: programType,
        },
      });
      if (resp.data.code === 20000) {
        return;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async deactivate(): Promise<void> {
    try {
      let resp = await this._c.enterPrice.deactivateEnterprise();
      if (resp.data.code === 20000) {
        return;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async activate(): Promise<void> {
    try {
      let resp = await this._c.enterPrice.activateEnterprise();
      if (resp.data.code === 20000) {
        return;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
