import { NavlinkPath } from "@/services/router-config";

const exceptionPath: string[] = [
  NavlinkPath.setting,
  NavlinkPath.ultilities,
  NavlinkPath.home,
];

export function routerIntercepter({
  path,
  roles,
}: {
  path: string;
  roles: {
    id: number;
    routerPaths: string[];
  }[];
}): boolean {
  let ret = false;
  if (exceptionPath.includes(path)) {
    return true;
  }
  for (let i = 0; i < roles.length; i++) {
    if (roles[i].routerPaths.includes(path)) {
      ret = true;
      break;
    }
  }

  return ret;
}
