import { KienlongPayClient } from "@/api/kienlong_pay_http_client";
import { ErrorModel } from "@/models/ErrorModel";
import { WebhookUrlModel, WebhookUrlStatus } from "@/models/WebhookModel";
import { Webhookepository } from "../WebhookRepository";

export class WebhookRepositoyImp implements Webhookepository {
  private client: KienlongPayClient;
  constructor(kienlongPayClient: KienlongPayClient) {
    this.client = kienlongPayClient;
  }

  public async getWebhookUrl(): Promise<WebhookUrlModel> {
    try {
      let resp = await this.client.webhookController.portalGetWebhookUrl();
      if (resp.data.code === 0) {
        return {
          status:
            resp.data.data?.status === undefined
              ? undefined
              : WebhookUrlStatus[
                  resp.data.data?.status as keyof typeof WebhookUrlStatus
                ],
          webhookUrl: resp.data.data?.webhookUrl ?? "",
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async updateWebhookUrl({
    url,
  }: {
    url: string;
  }): Promise<WebhookUrlModel> {
    try {
      let resp = await this.client.webhookController.portalUpdateWebhookUrl({
        portalUpdateWebhookUrlRequest: {
          webhookUrl: url,
        },
      });
      if (resp.data.code === 0) {
        return {
          status:
            WebhookUrlStatus[
              resp.data.data?.status ??
                ("PENDING" as keyof typeof WebhookUrlStatus)
            ],
          webhookUrl: resp.data.data?.webhookUrl ?? "",
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async deleteWebhookUrl(): Promise<boolean> {
    try {
      let resp = await this.client.webhookController.portalDeleteWebhookUrl();
      if (resp.data.code === 0) {
        return resp.data.data?.success ?? false;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
