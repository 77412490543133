import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import { IconArrow } from "../icons-component/icon-component";
import "./breadcrumb-custom.css";
import "@/styles";
import { useEffect, useState } from "react";
import { useAppBloc } from "@/contexts/app";
import { NavlinkPath } from "@/services/router-config";

export const BreadCrumbCustom = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { appBloc } = useAppBloc();
  const [_showBreadcumb, _setShowBreadcumb] = useState(
    appBloc.session.isShowBreadcrumb.value,
  );
  const [_error, _setError] = useState(
    appBloc.session.error.value?.code.toString(),
  );

  const [_extension, _setExtension] = useState<JSX.Element | null>(
    appBloc.session.breadcrumb.value,
  );

  const [_stateLocation, _setStateLocation] = useState(location.state);

  useEffect(() => {
    _setStateLocation(location.state);
    if (!location.pathname.startsWith(NavlinkPath.dashboard)) {
      appBloc.session.isShowBreadcrumb.next(true);
    } else {
      appBloc.session.isShowBreadcrumb.next(false);
    }
  }, [location]);

  useEffect(() => {
    appBloc.session.isShowBreadcrumb.subscribe((v) => {
      _setShowBreadcumb(v);
    });
    appBloc.session.breadcrumb.subscribe((v) => {
      _setExtension(v);
    });
    appBloc.session.error.subscribe((v) => {
      _setError(v?.code.toString());
    });
  }, []);

  return (
    <div
      className={`breadcrumb-custom ${
        _showBreadcumb ? "breadcrumb-custom--unhide" : "breadcrumb-custom--hide"
      }`}
      style={{
        paddingBottom: _extension === null ? "16px" : "0px",
      }}
    >
      <div className="breadcrumb-custom__paths">
        <NavLink
          key={"homepage-breadCrumb"}
          to={NavlinkPath.home}
          style={{
            gap: "4px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            textDecoration: "none",
          }}
        >
          <span
            className="fontFamily--Roboto-Medium fontWeight--500 fontSize--14 lineHeight--20"
            style={{
              color: "#6B7280",
            }}
          >
            <>{t(`navigation.home-page`)}</>
          </span>

          <IconArrow color={"#6B7280"} />
        </NavLink>
        {location.pathname
          .split("/")
          .filter((path) => path)
          .map((d, i) => {
            return (
              <NavLink
                key={i}
                to={`${location.pathname
                  .split("/")
                  .filter((path) => path)
                  .slice(0, i + 1)
                  .join("/")}${location.search}`}
                style={{
                  gap: "4px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  textDecoration: "none",
                }}
                state={_stateLocation}
              >
                <span
                  className="fontWeight--500 fontSize--14 lineHeight--20"
                  style={{
                    color:
                      i ===
                      location.pathname.split("/").filter((path) => path)
                        .length -
                        1
                        ? "#3366FF"
                        : "#6B7280",
                  }}
                >
                  <>{t(`navigation.${d}`, decodeURIComponent(d))}</>
                </span>
                {i !==
                  location.pathname.split("/").filter((path) => path).length -
                    1 && (
                  <IconArrow
                    color={
                      i ===
                      location.pathname.split("/").filter((path) => path)
                        .length -
                        2
                        ? "#3366FF"
                        : "#6B7280"
                    }
                  />
                )}
              </NavLink>
            );
          })}
      </div>

      {_extension === null ? (
        <span className="fontWeight--500 fontSize--20 lineHeight--28">
          <>
            {t(
              `navigation.${decodeURIComponent(
                location.pathname.split("/")[
                  location.pathname.split("/").length - 1
                ],
              )}`,
              decodeURIComponent(
                location.pathname.split("/")[
                  location.pathname.split("/").length - 1
                ],
              ),
            )}
          </>
        </span>
      ) : (
        _extension
      )}
    </div>
  );
};
