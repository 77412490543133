import { Environment } from "@/services/environment";
import {
  Configuration,
  ConfigurationParameters,
  MinioControllerApi,
} from "./generated-sources/media";
import { MediaRepositoryImp } from "./storage-repositories/implements/MediaRepositoryImp";
import { MediaRepository } from "./storage-repositories/MediaRepository";

export const storageConfig: ConfigurationParameters = {
  basePath: `${Environment.hostApiStorage}/media`,
};

export class StorageClient {
  private config: Configuration;

  public readonly mediaController: MinioControllerApi;

  constructor(cf: Configuration) {
    this.config = cf;
    this.mediaController = new MinioControllerApi(cf, cf.basePath);
  }
}

export class StorageHttpClient {
  mediaController: MediaRepository;

  static storageClient: StorageClient;
  constructor(basePath?: string) {
    if (basePath) {
      storageConfig.basePath = basePath + "/media";
    }
    StorageHttpClient.storageClient = new StorageClient(
      new Configuration(storageConfig),
    );
    this.mediaController = new MediaRepositoryImp(
      StorageHttpClient.storageClient,
    );
  }
}
