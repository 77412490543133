import {
  RoleGroupDetailResponse,
  RoleGroupResponse,
  UserResponse,
} from "@/api/generated-sources/kienlong-pay";
import { UserModel, UserStatusType } from "@/models/UserModel";
import {
  RoleGroupModel,
  RoleGroupsDetailModel,
  RoleGroupStatusType,
} from "@/models/RoleGroupModel";
import { mapBankAccountModal } from "./bank_account_adapter";
import { mapPermissionModel } from "./permission_adapter";

export function mapRoleGroupModel(
  dto: RoleGroupResponse | undefined,
): RoleGroupModel {
  return {
    code: dto?.code ?? "",
    createdAt: dto?.createdAt ?? "",
    createdBy: dto?.createdBy ?? "",
    description: dto?.description ?? "",
    id: dto?.id ?? 0,
    modifiedAt: dto?.modifiedAt ?? "",
    modifiedBy: dto?.modifiedBy ?? "",
    name: dto?.name ?? "",
    status:
      RoleGroupStatusType[
        (dto?.status ?? "CLOSED") as keyof typeof RoleGroupStatusType
      ],
  };
}

export function mapUserModel(dto: UserResponse | undefined): UserModel {
  return {
    email: dto?.email ?? "",
    fullName: dto?.fullName ?? "",
    id: dto?.id ?? "",
    merchantName: dto?.merchantName ?? "",
    merchantCode: dto?.merchantCode ?? "",
    phone: dto?.phone ?? "",
    roleGroup: mapRoleGroupModel(dto?.roleGroup),
    status:
      UserStatusType[
        dto?.status ?? ("INACTIVE" as keyof typeof UserStatusType)
      ],
    username: dto?.username ?? "",
    avatar: dto?.avatar ?? "",
    bankAccounts: dto?.bankAccounts?.map((d) => mapBankAccountModal(d)) ?? [],
    isOwner: dto?.isOwner ?? false,
    allowEmail: dto?.allowEmail ?? false,
  };
}

export function mapRoleGroupDetail(
  dto: RoleGroupDetailResponse | undefined,
): RoleGroupsDetailModel {
  return {
    id: dto?.id || 0,
    name: dto?.name || "",
    code: dto?.code || "",
    description: dto?.description || "",
    status:
      RoleGroupStatusType[
        (dto?.status ?? "CLOSED") as keyof typeof RoleGroupStatusType
      ],
    createdAt: dto?.createdAt || "",
    createdBy: dto?.createdBy || "",
    modifiedAt: dto?.modifiedAt || "",
    modifiedBy: dto?.modifiedBy || "",
    actions: dto?.actions?.map((action) => mapPermissionModel(action)) || [],
    currentUsed: dto?.currentUsed || false,
  };
}
