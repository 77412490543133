import { PaymentMethodInfo } from "@/api/generated-sources/kienlong-pay";
import { PaymentType } from "@/models/PaylinkModel";
import {
  PaymentMethodInfoModel,
  PaymentMethodInfoStatus,
  PaymentMethodInfoType,
} from "@/models/Payment";

export function mapPaymentMethodInfoModel(
  dto: PaymentMethodInfo | undefined,
): PaymentMethodInfoModel {
  return {
    dynamicFee: dto?.dynamicFee ?? 0,
    fixedFee: dto?.fixedFee ?? 0,
    id: dto?.id ?? 0,
    name: dto?.name ?? "",
    paymentLimit: dto?.paymentLimit ?? 0,
    status:
      PaymentMethodInfoStatus[
        dto?.status ?? ("INACTIVE" as keyof typeof PaymentMethodInfoStatus)
      ],
    type: PaymentMethodInfoType[
      dto?.type ?? ("DOMESTIC" as keyof typeof PaymentMethodInfoType)
    ],
    logo: dto?.logo ?? "",
    paymentType: PaymentType[dto?.paymentType as keyof typeof PaymentType],
  };
}
