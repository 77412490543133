import {
  mapPayPageModel,
  mapPayPageModelDetailModel,
  mapPayPageOrderModel,
  mapPayPageTransactionModel,
} from "@/adapters/pay_page_adapter";
import { KienlongPayClient } from "@/api/kienlong_pay_http_client";
import { CustomerInfoType } from "@/models/CustomersModel";
import { ErrorModel } from "@/models/ErrorModel";
import { PaymentType } from "@/models/PaylinkModel";
import {
  PayPageDetailModel,
  PayPageModel,
  PayPageOrderModel,
  PayPagePeriodType,
  PayPageStatusType,
  PayPageTransactionModel,
} from "@/models/PayPageModel";
import { SortDirectionType } from "@/models/Sort";
import { TransactionStatusType } from "@/models/TransactionModel";
import { PayPageRepository } from "../PayPageRepository";

export class PayPageRepositoryImp implements PayPageRepository {
  private client: KienlongPayClient;
  constructor(kienlongPayClient: KienlongPayClient) {
    this.client = kienlongPayClient;
  }

  public async changeStatus({
    payPageCode,
    status,
  }: {
    payPageCode: string;
    status: PayPageStatusType;
  }): Promise<boolean> {
    try {
      let resp = await this.client.payPageController.changeStatus({
        payPageCode: payPageCode,
        status: status,
      });
      if (resp.data.code === 0) {
        return resp.data.data?.success ?? false;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async create({
    bankAccountId,
    customerInfoType,
    name,
    productIds,
    contactEmail,
    contactPhone,
    customerInfoForm,
    periodType,
  }: {
    bankAccountId: string;
    customerInfoType: CustomerInfoType;
    name: string;
    productIds: number[];
    contactEmail: string;
    contactPhone: string;
    customerInfoForm: {
      fillAddress: boolean;
      fillEmail: boolean;
      fillName: boolean;
      fillPhone: boolean;
      id: string;
    };
    periodType: PayPagePeriodType;
  }): Promise<PayPageModel> {
    try {
      let resp = await this.client.payPageController.create1({
        createPayPageRequest: {
          bankAccountId: bankAccountId,
          customerInfoType: customerInfoType,
          name: name,
          productIds: productIds,
          contactEmail: contactEmail,
          contactPhone: contactPhone,
          customerInfoForm: customerInfoForm,
          periodType: periodType,
        },
      });
      if (resp.data.code === 0) {
        return mapPayPageModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async createOrder({
    payPageCode,
    paymentType,
    customerAddress,
    customerEmail,
    customerName,
    customerPhone,
    failUrl,
    note,
    periodic,
    redirectAfter,
    refTransactionId,
    successUrl,
    items,
    trackingButton,
  }: {
    payPageCode: string;
    paymentType: PaymentType;
    customerAddress?: string;
    customerEmail?: string;
    customerName?: string;
    customerPhone?: string;
    failUrl?: string;
    note?: string;
    periodic?: boolean;
    redirectAfter?: number;
    refTransactionId?: string;
    successUrl?: string;
    items: {
      productId: number;
      quantity?: number;
      price?: number;
    }[];
    trackingButton?: string;
  }): Promise<PayPageOrderModel> {
    try {
      let resp = await this.client.payPageController.order({
        payPageCode: payPageCode,
        payPageOrderRequest: {
          items: items,
          paymentType: paymentType,
          customerAddress: customerAddress,
          customerEmail: customerEmail,
          customerName: customerName,
          customerPhone: customerPhone,
          failUrl: failUrl,
          note: note,
          periodic: periodic,
          redirectAfter: redirectAfter,
          refTransactionId: refTransactionId,
          successUrl: successUrl,
          trackingButton: trackingButton,
        },
      });
      if (resp.data.code === 0) {
        return mapPayPageOrderModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getAllPayPage({
    createdFrom,
    createdTo,
    name,
    page,
    pageSize,
    sortBy,
    sortDirection = SortDirectionType.DESC,
    status,
  }: {
    createdFrom?: string;
    createdTo?: string;
    name?: string;
    page?: number;
    pageSize?: number;
    sortBy?: string;
    sortDirection?: SortDirectionType;
    status?: PayPageStatusType;
  }): Promise<{
    pageNumber: number;
    pageSize: number;
    totalSize: number;
    totalPage: number;
    data: PayPageModel[];
  }> {
    try {
      let resp = await this.client.payPageController.getAllPayPage({
        createdFrom: createdFrom,
        createdTo: createdTo,
        name: name,
        page: page,
        pageSize: pageSize,
        sortBy: sortBy,
        sortDirection: sortDirection,
        status: status,
      });
      if (resp.data.code === 0) {
        return {
          pageNumber: resp.data.data?.pageNumber ?? 0,
          pageSize: resp.data.data?.pageSize ?? 0,
          totalPage: resp.data.data?.totalPage ?? 0,
          totalSize: resp.data.data?.totalSize ?? 0,
          data:
            resp.data.data?.items?.map((d) => {
              return mapPayPageModel(d);
            }) ?? [],
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getAllTransactionPayPage({
    payPageCode,
    page,
    pageSize,
    sortBy,
    sortDirection = SortDirectionType.DESC,
    status,
    transactionId,
  }: {
    payPageCode: string;
    page?: number;
    pageSize?: number;
    sortBy?: string;
    sortDirection?: SortDirectionType;
    status?: TransactionStatusType;
    transactionId?: string;
  }): Promise<{
    pageNumber: number;
    pageSize: number;
    totalSize: number;
    totalPage: number;
    data: PayPageTransactionModel[];
  }> {
    try {
      let resp = await this.client.payPageController.getAllPayPageTransactions({
        payPageCode: payPageCode,
        page: page,
        pageSize: pageSize,
        sortBy: sortBy,
        sortDirection: sortDirection,
        status: status,
        transactionId: transactionId,
      });
      if (resp.data.code === 0) {
        return {
          pageNumber: resp.data.data?.pageNumber ?? 0,
          pageSize: resp.data.data?.pageSize ?? 0,
          totalPage: resp.data.data?.totalPage ?? 0,
          totalSize: resp.data.data?.totalSize ?? 0,
          data:
            resp.data.data?.items?.map((d) => {
              return mapPayPageTransactionModel(d);
            }) ?? [],
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getDetailPayPage({
    payPageCode,
  }: {
    payPageCode: string;
  }): Promise<PayPageDetailModel> {
    try {
      let resp = await this.client.payPageController.getDetail1({
        payPageCode: payPageCode,
      });
      if (resp.data.code === 0) {
        return mapPayPageModelDetailModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getRevenue({ payPageCode }: { payPageCode: string }): Promise<{
    totalTransactions: number;
    totalRevenue: number;
  }> {
    try {
      let resp = await this.client.payPageController.getPayPageRevenue({
        payPageCode: payPageCode,
      });
      if (resp.data.code === 0) {
        return {
          totalRevenue: resp.data.data?.totalRevenue ?? 0,
          totalTransactions: resp.data.data?.totalTransactions ?? 0,
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async sendEmailSms({
    payPageCode,
    receiver,
    receiverIds,
    sendEmail,
    sendSms,
  }: {
    payPageCode: string;
    receiver: "CUSTOMER" | "CUSTOMER_GROUP";
    receiverIds: string[];
    sendEmail: boolean;
    sendSms: boolean;
  }): Promise<boolean> {
    try {
      let resp = await this.client.payPageController.sendEmailSms({
        sendEmailSmsPayPageRequest: {
          payPageCode: payPageCode,
          receiver: receiver,
          receiverIds: receiverIds,
          sendEmail: sendEmail,
          sendSms: sendSms,
        },
      });
      if (resp.data.code === 0) {
        return resp.data.data?.success ?? false;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getDetailPayPagePrivage({
    payPageCode,
  }: {
    payPageCode: string;
  }): Promise<PayPageDetailModel> {
    try {
      let resp = await this.client.payPageController.getPrivateDetail({
        payPageCode: payPageCode,
      });
      if (resp.data.code === 0) {
        return mapPayPageModelDetailModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async changeTitle({
    namePayPage,
    payPageCode,
  }: {
    namePayPage: string;
    payPageCode: string;
  }): Promise<boolean> {
    try {
      let resp = await this.client.payPageController.changeTitle({
        changePayPageTitleRequest: { name: namePayPage },
        payPageCode: payPageCode,
      });
      if (resp.data.code === 0) {
        return resp.data.data?.success ?? false;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
