import { ProfileDto } from "@/api/generated-sources/kienlong-pay";
import {
  BusinessProductsType,
  BusinessType,
  RevenueType,
} from "@/models/BusinessOwnerModel";

import { ProfileModel } from "../models/ProfileModel";
import { mapBusinessOwnerModal } from "./business_owner_adapter";
import { mapDocumentModal } from "./document_adapter";
import { mapEkycModalProfile } from "./ekyc_adapter";
import { mapIdenticalInfoModal } from "./identical_info_adapter";

export function mapProfileModal(
  profileDto: ProfileDto | undefined,
): ProfileModel {
  return {
    businessItems: profileDto?.businessItems ?? "",
    businessSectors:
      profileDto?.businessSectors?.map((d) => {
        return BusinessProductsType[
          (d ?? "OTHER") as keyof typeof BusinessProductsType
        ];
      }) ?? [],
    businessType:
      BusinessType[
        (profileDto?.businessType ?? "PERSONAL") as keyof typeof BusinessType
      ],
    companyAddress: profileDto?.companyAddress ?? "",
    companyName: profileDto?.companyName ?? "",
    companyPhone: profileDto?.companyPhone ?? "",
    contactEmail: profileDto?.contactEmail ?? "",
    documents:
      profileDto?.documents?.map((d) => {
        return mapDocumentModal(d);
      }) ?? [],
    owners:
      profileDto?.owners?.map((d) => {
        return mapBusinessOwnerModal(d);
      }) ?? [],
    repEkyc: mapEkycModalProfile(profileDto?.repEkyc),
    ownerEkyc: mapEkycModalProfile(profileDto?.ownerEkyc),
    ownerInfo: mapIdenticalInfoModal(profileDto?.ownerInfo),
    representativeInfo: mapIdenticalInfoModal(profileDto?.representativeInfo),
    storeAddress: profileDto?.storeAddress ?? "",
    transactionMaxAmount: profileDto?.transactionMaxAmount ?? 0,
    websiteUrl: profileDto?.websiteUrl ?? "",
    revenueType:
      RevenueType[
        (profileDto?.revenueType ??
          "REVENUE_TYPE_1") as keyof typeof RevenueType
      ],
  };
}
