import { ReactNode } from "react";
import "../style.css";
import "@/styles";
import { IconArrow } from "@/components/icons-component/icon-component";

export const SettingOption = ({
  title,
  content,
  icon,
  onTap,
}: {
  title: string;
  content: string;
  icon: ReactNode;
  onTap: () => void;
}) => {
  return (
    <div
      onClick={() => {
        onTap();
      }}
      className="option-setting-page"
    >
      {icon}
      <div
        className="column"
        style={{
          width: "100%",
          gap: "4px",
          alignItems: "flex-start",
          justifyContent: "flex-start",
        }}
      >
        <span
          className="fontSize--16 lineHeight--20 fontWeight--500"
          style={{
            color: "var(--color-global-gray-100)",
            width: "100%",
          }}
        >
          {title}
        </span>
        <span
          className="fontSize--16 lineHeight--20 fontWeight--400"
          style={{
            color: "var(--color-global-gray-80)",
            width: "100%",
            // maxWidth: "224px",
            maxLines: 3,
          }}
        >
          {content}
        </span>
      </div>
      <IconArrow color="#2B2B33" />
    </div>
  );
};
