export interface MonthlyFeeReportModel {
  createdDate: string;
  lastModifiedDate: string;
  createdBy: string;
  lastModifiedBy: string;
  id: number;
  prefix: string;
  merchantName: string;
  totalTransaction: number;
  totalFeeMoney: number;
  status: MonthlyFeeReportStatus;
  time: string;
  feeReports: FeeReportModel[];
  feeMoney: number;
  moneyVAT: number;
}

export enum MonthlyFeeReportStatus {
  WAITING_PAYMENT = "WAITING_PAYMENT",
  PAID = "PAID",
  PROVISIONAL = "PROVISIONAL",
}

export interface FeeReportModel {
  prefix: string;
  feeCode: string;
  serviceType: FeeReportServiceType;
  feeMethod: FeeReportFeeMethod;
  calculatePer: FeeReportCalculatePer;
  amountTransaction: number;
  feeMoney: number;
  moneyVAT: number;
  totalFeeMoney: number;
  activeFeeDate: string;
  time: string;
  id: string;
}

export enum FeeReportServiceType {
  COBO = "COBO",
  POBO = "POBO",
}

export enum FeeReportFeeMethod {
  FREE = "FREE",
  FIXED_FEE = "FIXED_FEE",
  BRACKET_FEE = "BRACKET_FEE",
}

export enum FeeReportCalculatePer {
  PER_MONTH = "PER_MONTH",
  PER_TRANSACTION = "PER_TRANSACTION",
  NONE = "NONE",
}
