import { KienlongPayClient } from "@/api/kienlong_pay_http_client";
import { ErrorModel } from "@/models/ErrorModel";
import {
  BankTransferInfoModel,
  PayInfoModel,
  VietQrInfoModel,
} from "@/models/PayInfoModel";
import { PayInfoRepository } from "../PayInfoRepository";
import {
  mapBankTransferInfoModel,
  mapPayInfoModel,
  mapVietQrInfoModel,
} from "@/adapters/pay_info_adapter";

export class PayInfoRepositoryImp implements PayInfoRepository {
  private client: KienlongPayClient;
  constructor(kienlongPayClient: KienlongPayClient) {
    this.client = kienlongPayClient;
  }

  public async getBankTransfer({
    transactionId,
  }: {
    transactionId: string;
  }): Promise<BankTransferInfoModel> {
    try {
      let resp = await this.client.portalPayInfo.getBankTransfer({
        transactionId: transactionId,
      });
      if (resp.data.code === 0) {
        return mapBankTransferInfoModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getVietQrTransfer({
    transactionId,
  }: {
    transactionId: string;
  }): Promise<VietQrInfoModel> {
    try {
      let resp = await this.client.portalPayInfo.getVietQR({
        transactionId: transactionId,
      });
      if (resp.data.code === 0) {
        return mapVietQrInfoModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async queryPayInfo({
    transactionId,
  }: {
    transactionId: string;
  }): Promise<PayInfoModel> {
    try {
      let resp = await this.client.portalPayInfo.queryPayInfo({
        transactionId: transactionId,
      });
      if (resp.data.code === 0) {
        return mapPayInfoModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
