import { Environment } from "@/services/environment";
import CryptoJS from "crypto-js";

export function hashString(v: string): string {
  return CryptoJS.HmacSHA256(v, Environment.saltKey).toString(CryptoJS.enc.Hex);
}

export function cipherEncryptAES(v: string): string {
  return CryptoJS.AES.encrypt(v, Environment.saltKey).toString();
}

export function cipherDecryptAES(v: string): string {
  return CryptoJS.AES.decrypt(v, Environment.saltKey).toString(
    CryptoJS.enc.Utf8,
  );
}
