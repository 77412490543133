export enum EnterpriseStatus {
  NOT_REGISTER = "NOT_REGISTER",
  WAITING_APPROVAL = "WAITING_APPROVAL",
  OK = "OK",
  DEACTIVATED = "DEACTIVATED",
  CMS_LOCKED = "CMS_LOCKED",
  OK_BUT_NOT_CONFIG = "OK_BUT_NOT_CONFIG",
}
export enum EnterpriseRegistrationRequestProgramType {
  FLEX_SALARY_USE_OVERDRAFT = "FLEX_SALARY_USE_OVERDRAFT",
  FLEX_SALARY_NOT_USE_OVERDRAFT = "FLEX_SALARY_NOT_USE_OVERDRAFT",
}
