import { EkycRepository } from "../EkycRepository";
import { EkycModel, EkycType } from "@/models/EkycModel";
import { ErrorModel } from "@/models/ErrorModel";
import { mapEkycModal } from "@/adapters/ekyc_adapter";
import { KienlongPayClient } from "@/api/kienlong_pay_http_client";
import { AxiosRequestConfig } from "axios";
import {
  Configuration,
  EkycControllerApi,
} from "@/api/generated-sources/kienlong-pay";
import { Environment } from "@/services/environment";

export class EkycRepositoryImp implements EkycRepository {
  private client: KienlongPayClient;
  constructor(kienlongPayClient: KienlongPayClient) {
    this.client = kienlongPayClient;
  }

  public async uploadCard({
    ekycType,
    imageFront,
    imageBack,
    options,
  }: {
    ekycType: EkycType;
    imageFront: File;
    imageBack: File;
    options?: AxiosRequestConfig<any> | undefined;
  }): Promise<EkycModel | null> {
    try {
      let _client = new EkycControllerApi(
        new Configuration({
          basePath: Environment.hostApiKlbPay,
        }),
      );
      let resp = await _client.detectCard(
        {
          ekycType: ekycType,
          imageFront: imageFront,
          imageBack: imageBack,
        },
        options,
      );
      if (resp.data.code === 0) {
        return mapEkycModal(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
