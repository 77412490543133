/* tslint:disable */
/* eslint-disable */
/**
 * Media API
 * Documentation Media API v1.0
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from "./configuration";
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "./base";

/**
 *
 * @export
 * @interface FileUploadResponse
 */
export interface FileUploadResponse {
  /**
   *
   * @type {string}
   * @memberof FileUploadResponse
   */
  previewUrl?: string;
}
/**
 *
 * @export
 * @interface FileUploadResponseV1
 */
export interface FileUploadResponseV1 {
  /**
   *
   * @type {string}
   * @memberof FileUploadResponseV1
   */
  previewUrl?: string;
  /**
   *
   * @type {string}
   * @memberof FileUploadResponseV1
   */
  privatePreviewUrl?: string;
}

/**
 * MinioControllerApi - axios parameter creator
 * @export
 */
export const MinioControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Use this endpoint to delete file
     * @summary Delete file
     * @param {string} bucketName
     * @param {string} fileType
     * @param {string} objectName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete: async (
      bucketName: string,
      fileType: string,
      objectName: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bucketName' is not null or undefined
      assertParamExists("_delete", "bucketName", bucketName);
      // verify required parameter 'fileType' is not null or undefined
      assertParamExists("_delete", "fileType", fileType);
      // verify required parameter 'objectName' is not null or undefined
      assertParamExists("_delete", "objectName", objectName);
      const localVarPath = `/delete/{bucketName}/{fileType}/{objectName}`
        .replace(`{${"bucketName"}}`, encodeURIComponent(String(bucketName)))
        .replace(`{${"fileType"}}`, encodeURIComponent(String(fileType)))
        .replace(`{${"objectName"}}`, encodeURIComponent(String(objectName)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Authorization required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Use this endpoint to download file
     * @summary Download file
     * @param {string} bucketName
     * @param {string} fileType
     * @param {string} objectName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadFile: async (
      bucketName: string,
      fileType: string,
      objectName: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bucketName' is not null or undefined
      assertParamExists("downloadFile", "bucketName", bucketName);
      // verify required parameter 'fileType' is not null or undefined
      assertParamExists("downloadFile", "fileType", fileType);
      // verify required parameter 'objectName' is not null or undefined
      assertParamExists("downloadFile", "objectName", objectName);
      const localVarPath = `/download/{bucketName}/{fileType}/{objectName}`
        .replace(`{${"bucketName"}}`, encodeURIComponent(String(bucketName)))
        .replace(`{${"fileType"}}`, encodeURIComponent(String(fileType)))
        .replace(`{${"objectName"}}`, encodeURIComponent(String(objectName)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Authorization required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Use this endpoint to preview file
     * @summary Preview file
     * @param {string} bucketName
     * @param {string} fileType
     * @param {string} objectName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    previewImage: async (
      bucketName: string,
      fileType: string,
      objectName: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bucketName' is not null or undefined
      assertParamExists("previewImage", "bucketName", bucketName);
      // verify required parameter 'fileType' is not null or undefined
      assertParamExists("previewImage", "fileType", fileType);
      // verify required parameter 'objectName' is not null or undefined
      assertParamExists("previewImage", "objectName", objectName);
      const localVarPath = `/preview/{bucketName}/{fileType}/{objectName}`
        .replace(`{${"bucketName"}}`, encodeURIComponent(String(bucketName)))
        .replace(`{${"fileType"}}`, encodeURIComponent(String(fileType)))
        .replace(`{${"objectName"}}`, encodeURIComponent(String(objectName)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Use this endpoint to preview file
     * @summary Preview file
     * @param {string} bucketName
     * @param {string} fileType
     * @param {string} objectName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    previewPrivatePicture: async (
      bucketName: string,
      fileType: string,
      objectName: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bucketName' is not null or undefined
      assertParamExists("previewPrivatePicture", "bucketName", bucketName);
      // verify required parameter 'fileType' is not null or undefined
      assertParamExists("previewPrivatePicture", "fileType", fileType);
      // verify required parameter 'objectName' is not null or undefined
      assertParamExists("previewPrivatePicture", "objectName", objectName);
      const localVarPath =
        `/private/preview/{bucketName}/private/{fileType}/{objectName}`
          .replace(`{${"bucketName"}}`, encodeURIComponent(String(bucketName)))
          .replace(`{${"fileType"}}`, encodeURIComponent(String(fileType)))
          .replace(`{${"objectName"}}`, encodeURIComponent(String(objectName)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Authorization required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Use this endpoint to upload file
     * @summary Upload file
     * @param {'AVATAR' | 'IDCARD' | 'LIVENESS' | 'ICON' | 'COLLOCATION' | 'PROPOSAL' | 'STATEMENT' | 'SIGN' | 'OTHER'} fileType
     * @param {string} [bucketName]
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    privateUpload: async (
      fileType:
        | "AVATAR"
        | "IDCARD"
        | "LIVENESS"
        | "ICON"
        | "COLLOCATION"
        | "PROPOSAL"
        | "STATEMENT"
        | "SIGN"
        | "OTHER",
      bucketName?: string,
      file?: File,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'fileType' is not null or undefined
      assertParamExists("privateUpload", "fileType", fileType);
      const localVarPath = `/private/upload`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      if (bucketName !== undefined) {
        localVarQueryParameter["bucketName"] = bucketName;
      }

      if (fileType !== undefined) {
        localVarQueryParameter["fileType"] = fileType;
      }

      if (file !== undefined) {
        localVarFormParams.append("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Use this endpoint to upload file
     * @summary Upload file
     * @param {'AVATAR' | 'IDCARD' | 'LIVENESS' | 'ICON' | 'COLLOCATION' | 'PROPOSAL' | 'STATEMENT' | 'SIGN' | 'OTHER'} fileType
     * @param {string} [bucketName]
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upload: async (
      fileType:
        | "AVATAR"
        | "IDCARD"
        | "LIVENESS"
        | "ICON"
        | "COLLOCATION"
        | "PROPOSAL"
        | "STATEMENT"
        | "SIGN"
        | "OTHER",
      bucketName?: string,
      file?: File,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'fileType' is not null or undefined
      assertParamExists("upload", "fileType", fileType);
      const localVarPath = `/upload`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      if (bucketName !== undefined) {
        localVarQueryParameter["bucketName"] = bucketName;
      }

      if (fileType !== undefined) {
        localVarQueryParameter["fileType"] = fileType;
      }

      if (file !== undefined) {
        localVarFormParams.append("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Use this endpoint to upload file
     * @summary Upload file
     * @param {'AVATAR' | 'IDCARD' | 'LIVENESS' | 'ICON' | 'COLLOCATION' | 'PROPOSAL' | 'STATEMENT' | 'SIGN' | 'OTHER'} fileType
     * @param {string} [bucketName]
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadV1: async (
      fileType:
        | "AVATAR"
        | "IDCARD"
        | "LIVENESS"
        | "ICON"
        | "COLLOCATION"
        | "PROPOSAL"
        | "STATEMENT"
        | "SIGN"
        | "OTHER",
      bucketName?: string,
      file?: File,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'fileType' is not null or undefined
      assertParamExists("uploadV1", "fileType", fileType);
      const localVarPath = `/v1/upload`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      if (bucketName !== undefined) {
        localVarQueryParameter["bucketName"] = bucketName;
      }

      if (fileType !== undefined) {
        localVarQueryParameter["fileType"] = fileType;
      }

      if (file !== undefined) {
        localVarFormParams.append("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MinioControllerApi - functional programming interface
 * @export
 */
export const MinioControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    MinioControllerApiAxiosParamCreator(configuration);
  return {
    /**
     * Use this endpoint to delete file
     * @summary Delete file
     * @param {string} bucketName
     * @param {string} fileType
     * @param {string} objectName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async _delete(
      bucketName: string,
      fileType: string,
      objectName: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator._delete(
        bucketName,
        fileType,
        objectName,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Use this endpoint to download file
     * @summary Download file
     * @param {string} bucketName
     * @param {string} fileType
     * @param {string} objectName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async downloadFile(
      bucketName: string,
      fileType: string,
      objectName: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.downloadFile(
        bucketName,
        fileType,
        objectName,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Use this endpoint to preview file
     * @summary Preview file
     * @param {string} bucketName
     * @param {string} fileType
     * @param {string} objectName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async previewImage(
      bucketName: string,
      fileType: string,
      objectName: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.previewImage(
        bucketName,
        fileType,
        objectName,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Use this endpoint to preview file
     * @summary Preview file
     * @param {string} bucketName
     * @param {string} fileType
     * @param {string} objectName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async previewPrivatePicture(
      bucketName: string,
      fileType: string,
      objectName: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.previewPrivatePicture(
          bucketName,
          fileType,
          objectName,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Use this endpoint to upload file
     * @summary Upload file
     * @param {'AVATAR' | 'IDCARD' | 'LIVENESS' | 'ICON' | 'COLLOCATION' | 'PROPOSAL' | 'STATEMENT' | 'SIGN' | 'OTHER'} fileType
     * @param {string} [bucketName]
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async privateUpload(
      fileType:
        | "AVATAR"
        | "IDCARD"
        | "LIVENESS"
        | "ICON"
        | "COLLOCATION"
        | "PROPOSAL"
        | "STATEMENT"
        | "SIGN"
        | "OTHER",
      bucketName?: string,
      file?: File,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FileUploadResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.privateUpload(
        fileType,
        bucketName,
        file,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Use this endpoint to upload file
     * @summary Upload file
     * @param {'AVATAR' | 'IDCARD' | 'LIVENESS' | 'ICON' | 'COLLOCATION' | 'PROPOSAL' | 'STATEMENT' | 'SIGN' | 'OTHER'} fileType
     * @param {string} [bucketName]
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async upload(
      fileType:
        | "AVATAR"
        | "IDCARD"
        | "LIVENESS"
        | "ICON"
        | "COLLOCATION"
        | "PROPOSAL"
        | "STATEMENT"
        | "SIGN"
        | "OTHER",
      bucketName?: string,
      file?: File,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FileUploadResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.upload(
        fileType,
        bucketName,
        file,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Use this endpoint to upload file
     * @summary Upload file
     * @param {'AVATAR' | 'IDCARD' | 'LIVENESS' | 'ICON' | 'COLLOCATION' | 'PROPOSAL' | 'STATEMENT' | 'SIGN' | 'OTHER'} fileType
     * @param {string} [bucketName]
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadV1(
      fileType:
        | "AVATAR"
        | "IDCARD"
        | "LIVENESS"
        | "ICON"
        | "COLLOCATION"
        | "PROPOSAL"
        | "STATEMENT"
        | "SIGN"
        | "OTHER",
      bucketName?: string,
      file?: File,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FileUploadResponseV1>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.uploadV1(
        fileType,
        bucketName,
        file,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * MinioControllerApi - factory interface
 * @export
 */
export const MinioControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = MinioControllerApiFp(configuration);
  return {
    /**
     * Use this endpoint to delete file
     * @summary Delete file
     * @param {string} bucketName
     * @param {string} fileType
     * @param {string} objectName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(
      bucketName: string,
      fileType: string,
      objectName: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        ._delete(bucketName, fileType, objectName, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Use this endpoint to download file
     * @summary Download file
     * @param {string} bucketName
     * @param {string} fileType
     * @param {string} objectName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadFile(
      bucketName: string,
      fileType: string,
      objectName: string,
      options?: any,
    ): AxiosPromise<Array<string>> {
      return localVarFp
        .downloadFile(bucketName, fileType, objectName, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Use this endpoint to preview file
     * @summary Preview file
     * @param {string} bucketName
     * @param {string} fileType
     * @param {string} objectName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    previewImage(
      bucketName: string,
      fileType: string,
      objectName: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .previewImage(bucketName, fileType, objectName, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Use this endpoint to preview file
     * @summary Preview file
     * @param {string} bucketName
     * @param {string} fileType
     * @param {string} objectName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    previewPrivatePicture(
      bucketName: string,
      fileType: string,
      objectName: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .previewPrivatePicture(bucketName, fileType, objectName, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Use this endpoint to upload file
     * @summary Upload file
     * @param {'AVATAR' | 'IDCARD' | 'LIVENESS' | 'ICON' | 'COLLOCATION' | 'PROPOSAL' | 'STATEMENT' | 'SIGN' | 'OTHER'} fileType
     * @param {string} [bucketName]
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    privateUpload(
      fileType:
        | "AVATAR"
        | "IDCARD"
        | "LIVENESS"
        | "ICON"
        | "COLLOCATION"
        | "PROPOSAL"
        | "STATEMENT"
        | "SIGN"
        | "OTHER",
      bucketName?: string,
      file?: File,
      options?: any,
    ): AxiosPromise<FileUploadResponse> {
      return localVarFp
        .privateUpload(fileType, bucketName, file, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Use this endpoint to upload file
     * @summary Upload file
     * @param {'AVATAR' | 'IDCARD' | 'LIVENESS' | 'ICON' | 'COLLOCATION' | 'PROPOSAL' | 'STATEMENT' | 'SIGN' | 'OTHER'} fileType
     * @param {string} [bucketName]
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upload(
      fileType:
        | "AVATAR"
        | "IDCARD"
        | "LIVENESS"
        | "ICON"
        | "COLLOCATION"
        | "PROPOSAL"
        | "STATEMENT"
        | "SIGN"
        | "OTHER",
      bucketName?: string,
      file?: File,
      options?: any,
    ): AxiosPromise<FileUploadResponse> {
      return localVarFp
        .upload(fileType, bucketName, file, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Use this endpoint to upload file
     * @summary Upload file
     * @param {'AVATAR' | 'IDCARD' | 'LIVENESS' | 'ICON' | 'COLLOCATION' | 'PROPOSAL' | 'STATEMENT' | 'SIGN' | 'OTHER'} fileType
     * @param {string} [bucketName]
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadV1(
      fileType:
        | "AVATAR"
        | "IDCARD"
        | "LIVENESS"
        | "ICON"
        | "COLLOCATION"
        | "PROPOSAL"
        | "STATEMENT"
        | "SIGN"
        | "OTHER",
      bucketName?: string,
      file?: File,
      options?: any,
    ): AxiosPromise<FileUploadResponseV1> {
      return localVarFp
        .uploadV1(fileType, bucketName, file, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for _delete operation in MinioControllerApi.
 * @export
 * @interface MinioControllerApiDeleteRequest
 */
export interface MinioControllerApiDeleteRequest {
  /**
   *
   * @type {string}
   * @memberof MinioControllerApiDelete
   */
  readonly bucketName: string;

  /**
   *
   * @type {string}
   * @memberof MinioControllerApiDelete
   */
  readonly fileType: string;

  /**
   *
   * @type {string}
   * @memberof MinioControllerApiDelete
   */
  readonly objectName: string;
}

/**
 * Request parameters for downloadFile operation in MinioControllerApi.
 * @export
 * @interface MinioControllerApiDownloadFileRequest
 */
export interface MinioControllerApiDownloadFileRequest {
  /**
   *
   * @type {string}
   * @memberof MinioControllerApiDownloadFile
   */
  readonly bucketName: string;

  /**
   *
   * @type {string}
   * @memberof MinioControllerApiDownloadFile
   */
  readonly fileType: string;

  /**
   *
   * @type {string}
   * @memberof MinioControllerApiDownloadFile
   */
  readonly objectName: string;
}

/**
 * Request parameters for previewImage operation in MinioControllerApi.
 * @export
 * @interface MinioControllerApiPreviewImageRequest
 */
export interface MinioControllerApiPreviewImageRequest {
  /**
   *
   * @type {string}
   * @memberof MinioControllerApiPreviewImage
   */
  readonly bucketName: string;

  /**
   *
   * @type {string}
   * @memberof MinioControllerApiPreviewImage
   */
  readonly fileType: string;

  /**
   *
   * @type {string}
   * @memberof MinioControllerApiPreviewImage
   */
  readonly objectName: string;
}

/**
 * Request parameters for previewPrivatePicture operation in MinioControllerApi.
 * @export
 * @interface MinioControllerApiPreviewPrivatePictureRequest
 */
export interface MinioControllerApiPreviewPrivatePictureRequest {
  /**
   *
   * @type {string}
   * @memberof MinioControllerApiPreviewPrivatePicture
   */
  readonly bucketName: string;

  /**
   *
   * @type {string}
   * @memberof MinioControllerApiPreviewPrivatePicture
   */
  readonly fileType: string;

  /**
   *
   * @type {string}
   * @memberof MinioControllerApiPreviewPrivatePicture
   */
  readonly objectName: string;
}

/**
 * Request parameters for privateUpload operation in MinioControllerApi.
 * @export
 * @interface MinioControllerApiPrivateUploadRequest
 */
export interface MinioControllerApiPrivateUploadRequest {
  /**
   *
   * @type {'AVATAR' | 'IDCARD' | 'LIVENESS' | 'ICON' | 'COLLOCATION' | 'PROPOSAL' | 'STATEMENT' | 'SIGN' | 'OTHER'}
   * @memberof MinioControllerApiPrivateUpload
   */
  readonly fileType:
    | "AVATAR"
    | "IDCARD"
    | "LIVENESS"
    | "ICON"
    | "COLLOCATION"
    | "PROPOSAL"
    | "STATEMENT"
    | "SIGN"
    | "OTHER";

  /**
   *
   * @type {string}
   * @memberof MinioControllerApiPrivateUpload
   */
  readonly bucketName?: string;

  /**
   *
   * @type {File}
   * @memberof MinioControllerApiPrivateUpload
   */
  readonly file?: File;
}

/**
 * Request parameters for upload operation in MinioControllerApi.
 * @export
 * @interface MinioControllerApiUploadRequest
 */
export interface MinioControllerApiUploadRequest {
  /**
   *
   * @type {'AVATAR' | 'IDCARD' | 'LIVENESS' | 'ICON' | 'COLLOCATION' | 'PROPOSAL' | 'STATEMENT' | 'SIGN' | 'OTHER'}
   * @memberof MinioControllerApiUpload
   */
  readonly fileType:
    | "AVATAR"
    | "IDCARD"
    | "LIVENESS"
    | "ICON"
    | "COLLOCATION"
    | "PROPOSAL"
    | "STATEMENT"
    | "SIGN"
    | "OTHER";

  /**
   *
   * @type {string}
   * @memberof MinioControllerApiUpload
   */
  readonly bucketName?: string;

  /**
   *
   * @type {File}
   * @memberof MinioControllerApiUpload
   */
  readonly file?: File;
}

/**
 * Request parameters for uploadV1 operation in MinioControllerApi.
 * @export
 * @interface MinioControllerApiUploadV1Request
 */
export interface MinioControllerApiUploadV1Request {
  /**
   *
   * @type {'AVATAR' | 'IDCARD' | 'LIVENESS' | 'ICON' | 'COLLOCATION' | 'PROPOSAL' | 'STATEMENT' | 'SIGN' | 'OTHER'}
   * @memberof MinioControllerApiUploadV1
   */
  readonly fileType:
    | "AVATAR"
    | "IDCARD"
    | "LIVENESS"
    | "ICON"
    | "COLLOCATION"
    | "PROPOSAL"
    | "STATEMENT"
    | "SIGN"
    | "OTHER";

  /**
   *
   * @type {string}
   * @memberof MinioControllerApiUploadV1
   */
  readonly bucketName?: string;

  /**
   *
   * @type {File}
   * @memberof MinioControllerApiUploadV1
   */
  readonly file?: File;
}

/**
 * MinioControllerApi - object-oriented interface
 * @export
 * @class MinioControllerApi
 * @extends {BaseAPI}
 */
export class MinioControllerApi extends BaseAPI {
  /**
   * Use this endpoint to delete file
   * @summary Delete file
   * @param {MinioControllerApiDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MinioControllerApi
   */
  public _delete(
    requestParameters: MinioControllerApiDeleteRequest,
    options?: AxiosRequestConfig,
  ) {
    return MinioControllerApiFp(this.configuration)
      ._delete(
        requestParameters.bucketName,
        requestParameters.fileType,
        requestParameters.objectName,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Use this endpoint to download file
   * @summary Download file
   * @param {MinioControllerApiDownloadFileRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MinioControllerApi
   */
  public downloadFile(
    requestParameters: MinioControllerApiDownloadFileRequest,
    options?: AxiosRequestConfig,
  ) {
    return MinioControllerApiFp(this.configuration)
      .downloadFile(
        requestParameters.bucketName,
        requestParameters.fileType,
        requestParameters.objectName,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Use this endpoint to preview file
   * @summary Preview file
   * @param {MinioControllerApiPreviewImageRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MinioControllerApi
   */
  public previewImage(
    requestParameters: MinioControllerApiPreviewImageRequest,
    options?: AxiosRequestConfig,
  ) {
    return MinioControllerApiFp(this.configuration)
      .previewImage(
        requestParameters.bucketName,
        requestParameters.fileType,
        requestParameters.objectName,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Use this endpoint to preview file
   * @summary Preview file
   * @param {MinioControllerApiPreviewPrivatePictureRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MinioControllerApi
   */
  public previewPrivatePicture(
    requestParameters: MinioControllerApiPreviewPrivatePictureRequest,
    options?: AxiosRequestConfig,
  ) {
    return MinioControllerApiFp(this.configuration)
      .previewPrivatePicture(
        requestParameters.bucketName,
        requestParameters.fileType,
        requestParameters.objectName,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Use this endpoint to upload file
   * @summary Upload file
   * @param {MinioControllerApiPrivateUploadRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MinioControllerApi
   */
  public privateUpload(
    requestParameters: MinioControllerApiPrivateUploadRequest,
    options?: AxiosRequestConfig,
  ) {
    return MinioControllerApiFp(this.configuration)
      .privateUpload(
        requestParameters.fileType,
        requestParameters.bucketName,
        requestParameters.file,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Use this endpoint to upload file
   * @summary Upload file
   * @param {MinioControllerApiUploadRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MinioControllerApi
   */
  public upload(
    requestParameters: MinioControllerApiUploadRequest,
    options?: AxiosRequestConfig,
  ) {
    return MinioControllerApiFp(this.configuration)
      .upload(
        requestParameters.fileType,
        requestParameters.bucketName,
        requestParameters.file,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Use this endpoint to upload file
   * @summary Upload file
   * @param {MinioControllerApiUploadV1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MinioControllerApi
   */
  public uploadV1(
    requestParameters: MinioControllerApiUploadV1Request,
    options?: AxiosRequestConfig,
  ) {
    return MinioControllerApiFp(this.configuration)
      .uploadV1(
        requestParameters.fileType,
        requestParameters.bucketName,
        requestParameters.file,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
