import { PermissionModel } from "./PermissionModel";

export interface RoleGroupModel {
  id: number;
  name: string;
  code: string;
  description: string;
  status: RoleGroupStatusType;
  createdAt: string;
  createdBy: string;
  modifiedAt: string;
  modifiedBy: string;
}

export enum RoleGroupStatusType {
  ACTIVE = "ACTIVE",
  CLOSED = "CLOSED",
}

export interface RoleGroupsDetailModel {
  id: number;
  name: string;
  code: string;
  description: string;
  status: RoleGroupStatusType;
  createdAt: string;
  createdBy: string;
  modifiedAt: string;
  modifiedBy: string;
  actions: PermissionModel[];
  currentUsed: boolean;
}
