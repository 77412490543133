import { kienlongPayConfig } from "@/api/kienlong_pay_http_client";
import { payboxConfig } from "@/api/paybox_http_client";
import { storageConfig } from "@/api/storage_http_client";
import { appMessage } from "@/components/app-message/app-message";
import KienlongBankLoader from "@/components/loader/KienlongBankLoader";
import { OverlayComponent } from "@/components/overlay/OverlayComponent";
import { useAppBloc } from "@/contexts/app";
import { LoginStatus } from "@/models/LoginStatus";
import { CreatePayButton } from "@/pages/create-paybutton/CreatePayButton";
import CreatePayout from "@/pages/create-payout";
import { CreatePayPage } from "@/pages/create-paypage";
import Home from "@/pages/home";
import { PayboxPage } from "@/pages/paybox";
import PayBoxDetail from "@/pages/paybox-detail";
import { PayButtonDashBoard } from "@/pages/paybutton";
import PayButtonDetail from "@/pages/paybutton-detail/PayButtonDetail";
import PayGatePage from "@/pages/paygate";
import PayGateTools from "@/pages/paygate/components/ToolsIntegrate";
import Payout from "@/pages/payout";
import PayoutDetail from "@/pages/payout-detail";
import PayPageDashboard from "@/pages/paypage/index";
import PayPos from "@/pages/paypos-device";
import { UpdatePayButton } from "@/pages/update-pay-button/UpdatePayButton";
import { loaderId, messageId, modalId } from "@/services/constants";
import { AppRepository } from "@/services/repository";
import { NavlinkPath } from "@/services/router-config";
import "@/styles";
import { useQuery } from "@tanstack/react-query";
import {
  ReactPortal,
  Suspense,
  lazy,
  useEffect,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { Navigate, Outlet, Route, Routes, useNavigate } from "react-router-dom";
import { flexSalaryConfig } from "./api/flexsalary_http_client";
import PageNotFound from "./components/page-not-found/PageNotFound";
import { StreamBuilder } from "./components/stream-builder/StreamBuilder";
import ErrorPage403 from "./pages/40x/403";
import { BaseBloc } from "./services/base-bloc";
import { Environment } from "./services/environment";

// ----public------------------
const NapasCheckin = lazy(() => import("@/pages/napas-checkin"));
const PaymentPaylinkPage = lazy(() => import("@/pages/payment-paylink"));
const PaymentPayPage = lazy(() => import("@/pages/payment-paypage"));
const WaitingPaymentPaypage = lazy(
  () => import("@/pages/waiting-payment-paypage"),
);
const WaitingPaymentPaylink = lazy(
  () => import("@/pages/waiting-payment-paylink"),
);
// ----public------------------

// ----auth------------------
const AuthenticatePage = lazy(() => import("@/pages/authenticate"));
const ForgetPasswordForm = lazy(
  () => import("@/pages/reset-password/ForgetPasswordForm"),
);
const ForgetPasswordMember = lazy(
  () => import("@/pages/reset-password/ForgetPasswordMemberForm"),
);
const LoginForm = lazy(() => import("@/pages/login/LoginForm"));
const RegisterForm = lazy(() => import("@/pages/register/RegisterForm"));
const GuideLoginQr = lazy(() => import("@/pages/guide-login-qr"));

// dashboard
const DashBoard = lazy(() => import("@/pages/dashboard"));

// setting
const SettingPage = lazy(() => import("@/pages/setting"));
const LinkingAccount = lazy(() =>
  import("@/pages/received-money-account").then((module) => ({
    default: module.LinkingAccount,
  })),
);
const DetailsLinkingAccount = lazy(() =>
  import("@/pages/received-money-account").then((module) => ({
    default: module.DetailsLinkingAccount,
  })),
);
const IdentificationSettingPage = lazy(
  () => import("@/pages/identification-setting"),
);
const AccountInfoScreen = lazy(
  () => import("@/pages/account-info/AccountInfoScreen"),
);
const SettingPaymentPage = lazy(() => import("@/pages/payment-setting"));
const MemberManagementPage = lazy(() => import("@/pages/member-management"));
const CreateMemberScreen = lazy(() => import("@/pages/create-member"));
const UpdateMemberScreen = lazy(() => import("@/pages/update-member"));
const ChangePasswordScreen = lazy(() => import("@/pages/change-password"));
const NotifyEmail = lazy(() => import("@/pages/notification-email"));

// fee report
const FeeReportPage = lazy(() => import("@/pages/fee-report"));
const FeeReportDetail = lazy(() => import("@/pages/fee-report-detail"));
const FeeReportDetailTransactionPage = lazy(
  () => import("@/pages/fee-report-detail-transaction"),
);

// paygate
const PaymentIntergration = lazy(
  () => import("@/pages/paygate/components/PaymentIntegration"),
);
const PayGateWebhooks = lazy(
  () => import("@/pages/paygate/components/WebhooksIntegrate"),
);
// const PayGateTools = lazy(
//   () => import("@/pages/paygate/components/ToolsIntegrate"),
// );
const ToolsTransferMoney = lazy(() => import("@/pages/tool-transfer-money"));

// identifaction
const IdentificationPage = lazy(() => import("@/pages/identification"));

// support 247
const SupportPage = lazy(() => import("@/pages/support-247"));

//for control
const ForControlPage = lazy(() => import("@/pages/for-control"));
const ForControlDetailPage = lazy(() => import("@/pages/for-control-detail"));

// statistic
const StatisticPage = lazy(() => import("@/pages/statistic"));

// transaction history
const TransactionsManagementPage = lazy(
  () => import("@/pages/transactions-management"),
);

// balance
const BalanceManagement = lazy(() => import("@/pages/balance-management"));

// paylink
const PaylinkPage = lazy(() => import("@/pages/paylink"));
const CreatePaylink = lazy(() => import("@/pages/create-paylink"));

// paypage
const PayPageDetail = lazy(() => import("@/pages/paypage-detail"));
const TabPayPageDetailInfo = lazy(
  () => import("@/pages/paypage-detail/components/TabPayPageDetailInfo"),
);
const TabPayPageHistoryTransaction = lazy(
  () =>
    import("@/pages/paypage-detail/components/TabPayPageHistoryTransaction"),
);
const PaypageDetailHistoryTransaction = lazy(
  () => import("@/pages/paypage-detail-history-transaction"),
);

/* ****************************** PaySubscription ****************************** */
const PaySubscription = lazy(() => import("@/pages/pay-subscription"));
const PaySubscriptionDetail = lazy(
  () => import("@/pages/pay-subscription-detail"),
);
const PaySubscriptionBillDetail = lazy(
  () => import("@/pages/pay-subscription-bill-detail"),
);
const CreatePaySubscription = lazy(
  () => import("@/pages/pay-subscription-create"),
);
const PaySubscriptionPlan = lazy(() => import("@/pages/pay-subscription-plan"));
const PaySubscriptionPlanDetail = lazy(
  () => import("@/pages/pay-subscription-plan-detail"),
);
const CreatePaySubscriptionPlan = lazy(
  () => import("@/pages/pay-subscription-plan-create"),
);
const UpdatePaySubscriptionPlan = lazy(
  () => import("@/pages/pay-subscription-plan-update"),
);
// customer management
const CustomerManagementPage = lazy(
  () => import("@/pages/customer-management"),
);
const CustomerGroupDetailPage = lazy(
  () => import("@/pages/customer-group-detail"),
);
const ProductManagement = lazy(() => import("@/pages/product-management"));

const CategoryDetailPage = lazy(() => import("@/pages/category-detail"));
// ----Utilities------------------
const UtilitiesPage = lazy(() => import("@/pages/utilities"));
const VietQrProPage = lazy(() => import("@/pages/vietqr-pro"));
const AppMarketPage = lazy(() => import("@/pages/app-market"));
const FlexibleSalaryPayment = lazy(
  () => import("@/pages/flexible-salary-payment"),
);

const FlexibleSalaryPaymentStatisticPage = lazy(
  () => import("@/pages/flexible-salary-payment-statistic"),
);
const FlexibleSalaryPaymentStatisticDetailPage = lazy(
  () => import("@/pages/flexible-salary-payment-statistic-detail"),
);

const FlexibleSalaryPaymentEnterpriseConfigurationPage = lazy(
  () => import("@/pages/flexible-salary-payment-enterprise-configuration"),
);

const FlexibleSalaryPaymentRequestAdvanceSalariesPage = lazy(
  () => import("@/pages/flexible-salary-payment-request-advance-salaries"),
);
const FlexibleSalaryPaymentRequestAdvanceSalariesDetailPage = lazy(
  () =>
    import("@/pages/flexible-salary-payment-request-advance-salaries-detail"),
);

const FlexibleSalaryPaymentStaffPage = lazy(
  () => import("@/pages/flexible-salary-payment-staff"),
);
const FlexibleSalaryPaymentStaffDetail = lazy(
  () => import("@/pages/flexible-salary-payment-staff-detail"),
);
const FlexibleSalaryPaymentStaffCreate = lazy(
  () => import("@/pages/flexible-salary-payment-staff-create"),
);
const FlexibleSalaryPaymentStaffUpdate = lazy(
  () => import("@/pages/flexible-salary-payment-staff-update"),
);
const FlexibleSalaryPaymentStaffImportExcel = lazy(
  () => import("@/pages/flexible-salary-payment-staff-import-excel"),
);

const FlexibleSalaryPaymentTypeOfAdvanceSalariesPage = lazy(
  () => import("@/pages/flexible-salary-payment-type-of-advance-salaries"),
);
const FlexibleSalaryPaymentTypeOfAdvanceSalariesDetail = lazy(
  () =>
    import("@/pages/flexible-salary-payment-type-of-advance-salaries-detail"),
);
const FlexibleSalaryPaymentTypeOfAdvanceSalariesCreate = lazy(
  () =>
    import("@/pages/flexible-salary-payment-type-of-advance-salaries-create"),
);
const FlexibleSalaryPaymentTypeOfAdvanceSalariesUpdate = lazy(
  () =>
    import("@/pages/flexible-salary-payment-type-of-advance-salaries-update"),
);

const FlexibleSalaryPaymentPeriodPage = lazy(
  () => import("@/pages/flexible-salary-payment-period"),
);
const FlexibleSalaryPaymentPeriodDetail = lazy(
  () => import("@/pages/flexible-salary-payment-period-detail"),
);
const FlexibleSalaryPaymentPeriodDetailImportExcel = lazy(
  () => import("@/pages/flexible-salary-payment-period-import-excel"),
);
const VietQrProTransactionPage = lazy(
  () => import("@/pages/vietqr-pro-transaction"),
);

interface AppPageState {
  isShowModel: boolean;
  isShowMessage: boolean;
  isLoading: boolean;
  models: ReactPortal[];
}

function App() {
  const { t } = useTranslation();
  const { appBloc } = useAppBloc();
  const _bloc = useRef<BaseBloc<AppPageState>>(
    new BaseBloc<AppPageState>({
      initState: {
        isLoading: false,
        isShowMessage: false,
        isShowModel: false,
        models: [],
      },
    }),
  ).current;

  const [_isLogin, _setIsLogin] = useState(appBloc.storage.isLogin);
  const navigate = useNavigate();

  kienlongPayConfig.accessToken =
    appBloc.storage.accessToken === ""
      ? undefined
      : appBloc.storage.accessToken;

  const { isLoading } = useQuery(
    ["checkLogin"],
    async () => {
      if (window.location.pathname.startsWith(NavlinkPath.public)) {
        if (appBloc.storage.testMode) {
          appBloc.storage.testMode = false;
          return window.location.reload();
        }
        return 0;
      }
      if (window.location.pathname.startsWith("/test/public")) {
        if (!appBloc.storage.testMode) {
          appBloc.storage.testMode = true;
          return window.location.reload();
        }
        return 0;
      }
      if (appBloc.storage.refreshToken === "") {
        appBloc.session.loginStatus.next(LoginStatus.Expired);
      } else {
        if (appBloc.storage.accessToken === "") {
          try {
            let ret =
              await appBloc.repository.kienlongPayHttpClient.authRepository.refreshToken(
                {
                  refreshToken: appBloc.storage.refreshToken,
                },
              );
            if (ret.token && ret.refreshToken) {
              appBloc.storage.accessToken = ret.token;
              appBloc.storage.refreshToken = ret.refreshToken;
              appBloc.session.loginStatus.next(LoginStatus.Success);
            } else {
              appBloc.session.loginStatus.next(LoginStatus.Expired);
            }
          } catch (error) {
            appBloc.session.loginStatus.next(LoginStatus.Expired);
          }
        } else {
          appBloc.session.loginStatus.next(LoginStatus.Success);
        }
      }
      return 0;
    },
    {
      refetchOnWindowFocus: false,
      onSuccess() {
        console.log(
          "%cWarning, Debug mode bring bad experience ",
          "background: red; color: yellow; font-size: x-large",
        );
      },
    },
  );

  useEffect(() => {
    if (!isLoading) {
      let loopIntergrate: number | undefined;
      let currentLoginStatus = LoginStatus.Idle;
      let countdouneTime = Environment.timeoutApp;
      let countQueue = 0;

      // stream login status
      appBloc.session.loginStatus.subscribe(async (v) => {
        if (currentLoginStatus === v) {
          return;
        } else {
          currentLoginStatus = v;
        }

        if (v === LoginStatus.Success) {
          kienlongPayConfig.accessToken = appBloc.storage.accessToken;
          flexSalaryConfig.accessToken = appBloc.storage.accessToken;
          appBloc.storage.isLogin = true;
          _setIsLogin(true);

          setTimeout(() => {
            const url = new URL(window.location.href);
            if ((url.searchParams.get("redirect_uri") ?? "").length > 0) {
              const redirectURL = new URL(
                url.searchParams.get("redirect_uri") ?? "",
              );
              if (redirectURL.pathname !== "" && redirectURL.pathname !== "/") {
                navigate(redirectURL.pathname);
              }
            }
          }, 120);
        } else if (v === LoginStatus.Expired) {
          if (appBloc.storage.refreshToken !== "") {
            appBloc.repository.kienlongPayHttpClient.authRepository.logout({
              refreshToken: appBloc.storage.refreshToken,
            });
          }
          kienlongPayConfig.accessToken = undefined;
          appBloc.storage.accessToken = "";
          appBloc.storage.refreshToken = "";
          appBloc.storage.clearAll();
          appBloc.storage.isLogin = false;

          kienlongPayConfig.basePath = Environment.hostApiKlbPay;
          storageConfig.basePath = Environment.hostApiStorage;
          payboxConfig.basePath = Environment.hostApiPayBox;
          appBloc.repository = new AppRepository(
            kienlongPayConfig.basePath,
            payboxConfig.basePath,
            storageConfig.basePath,
            flexSalaryConfig.basePath,
          );

          _setIsLogin(false);
        }
      });

      // stream error handler
      appBloc.session.error.subscribe((v) => {
        if (v && v.code.toString() === "122") {
          appMessage.error({
            title: `${t("warning")}`,
            content: `${t("error.122")}`,
            duration: 5000,
          });
        }
        // if (v && v.code.toString() === "10") {
        //   appBloc.session.breadcrumb.next(null);
        //   appBloc.session.bottomBarComponent.next(null);
        //   appBloc.session.queueModal.next(null);
        //   appBloc.session.queueModal.next(
        //     <PopupCustom2
        //       onConfirm={() => {
        //         appBloc.session.queueModal.next(null);
        //       }}
        //       title={`${t("notification.attention")}`}
        //       subTitle={`${t("notification.access_denied")}`}
        //       labelConfirm={`${t("agree")}`}
        //       img={iconAlert}
        //       styleImg={{
        //         width: "100px",
        //         height: "100px",
        //         aspectRatio: "100 / 100",
        //       }}
        //     />,
        //   );
        // }
      });

      // listen for loading page
      appBloc.session.isLoading.subscribe((v) => {
        _bloc.state.isLoading = v;
        _bloc.upDateState();
      });

      // listen for show modal
      appBloc.session.isShowModal.subscribe((v) => {
        _bloc.state.isShowModel = v;
        if (!v) {
          let currentModel = document.getElementById(`${modalId}`);
          if (currentModel && currentModel.firstElementChild)
            currentModel.firstElementChild.className =
              "column animation-faded--out";
          setTimeout(() => {
            _bloc.upDateState();
          }, 150);
        } else {
          _bloc.upDateState();
        }
      });

      // listen for show message modal
      appBloc.session.isShowMessage.subscribe((v) => {
        _bloc.state.isShowMessage = v;
        _bloc.upDateState();
      });

      // listen fo queue modal
      appBloc.session.queueModal.subscribe((v) => {
        if (v === null) {
          if (countQueue > 0) {
            countQueue--;
            let currentModel = document.getElementById(
              `${modalId}-${countQueue}`,
            );
            currentModel!.firstElementChild!.className =
              "column animation-faded--out";
            let tmp = [..._bloc.state.models].slice(0, countQueue);
            _bloc.state.models = [...tmp];
            _bloc.upDateState();
          }
        } else {
          let queueMo = [..._bloc.state.models].slice(0, countQueue);
          let idx = countQueue;
          queueMo.push(
            createPortal(
              <OverlayComponent
                id={`${modalId}-${idx}`}
                classChildren={"column animation-scale--up animation-faded--in"}
                classBackground="overlay-queue-modal"
                onTapBackGround={(e) => {
                  appBloc.session.queueModal.next(null);
                }}
                onTapChildren={(e) => {
                  e.stopPropagation();
                }}
              >
                {v}
              </OverlayComponent>,
              document.body,
              `${modalId}-${idx}`,
            ),
          );
          countQueue++;
          _bloc.state.models = [...queueMo];
          _bloc.upDateState();
        }
      });

      // listence integrate
      [
        "click",
        "wheel",
        "mouseenter",
        "mouseover",
        "drag",
        "drop",
        "keydown",
      ].forEach(function (type) {
        window.addEventListener(type, (event) => {
          countdouneTime = Environment.timeoutApp;
          if (type === "keydown") {
            let key = (event as KeyboardEvent).key;
            if (key === "Escape") {
              appBloc.session.isShowMessage.next(false);
              appBloc.session.isShowModal.next(false);

              appBloc.session.message.next(null);
              appBloc.session.modal.next(null);
            }
          }
        });
      });

      window.addEventListener("popstate", (e) => {
        if (_bloc.state.isLoading || _bloc.state.isShowMessage || isLoading) {
          appBloc.session.isShowMessage.next(false);
          appBloc.session.isShowModal.next(false);
          appBloc.session.isLoading.next(false);
          appBloc.session.message.next(null);
          appBloc.session.modal.next(null);
        }
      });

      if (
        !window.location.pathname.startsWith(NavlinkPath.public) &&
        !window.location.pathname.startsWith("/test/public")
      ) {
        loopIntergrate = setInterval(() => {
          if (
            currentLoginStatus === LoginStatus.Success &&
            countdouneTime > 0
          ) {
            countdouneTime--;
          }
          if (
            countdouneTime === 0 &&
            currentLoginStatus === LoginStatus.Success
          ) {
            countdouneTime = -1;
            appBloc.session.isShowMessage.next(false);
            appBloc.session.isShowModal.next(false);
            appBloc.session.isLoading.next(false);
            appBloc.session.message.next(null);
            appBloc.session.modal.next(null);
            appBloc?.session.loginStatus.next(LoginStatus.Expired);
          }
        }, 1000);
      }

      document.getElementById("fab-app-root-animation-loading")?.remove();

      return () => {
        clearInterval(loopIntergrate);
        [
          "click",
          "wheel",
          "mouseenter",
          "mouseover",
          "drag",
          "drop",
          "keydown",
          "popstate",
        ].forEach(function (e) {
          window.removeEventListener(e, () => {
            console.debug("clear event" + e);
          });
        });
      };
    }
  }, [isLoading]);

  if (isLoading) {
    return <></>;
    // return (
    //   <OverlayComponent
    //     classChildren={undefined}
    //     classBackground="overlay-loading"
    //     onTapBackGround={() => {}}
    //     onTapChildren={() => {}}
    //   >
    //     <div
    //       className="column"
    //       style={{
    //         width: "100%",
    //         height: "100%",
    //         alignItems: "center",
    //         justifyContent: "center",
    //         backgroundColor: "transparent",
    //       }}
    //     >
    //       <KienlongBankLoader height={120} width={120} />
    //     </div>
    //   </OverlayComponent>
    // );
  }

  return (
    <>
      <Routes>
        {/* define page not know or error page */}
        <Route path="*" element={<Navigate to="/404" replace={true} />} />

        {/* router map for main page */}
        {!window.location.pathname.startsWith("/public") &&
          !window.location.pathname.startsWith("/test/public") && (
            <Route
              path={`${NavlinkPath.home}`}
              element={
                _isLogin ? (
                  <Home>
                    <Outlet />
                  </Home>
                ) : (
                  <Navigate
                    to={`${NavlinkPath.authenticate}?redirect_uri=${window.location.href}`}
                    replace={true}
                  />
                )
              }
            >
              <Route
                path="/*"
                element={<Navigate to="/404" replace={true} />}
              />

              {/* 40x page */}
              <Route
                path="/404"
                element={
                  <Suspense>
                    <PageNotFound />
                  </Suspense>
                }
              />
              <Route path="/403" element={<ErrorPage403 />} />

              {/* dashboard */}
              <Route
                index
                element={<Navigate to={NavlinkPath.dashboard} replace={true} />}
              />
              <Route
                path={`${NavlinkPath.dashboard}`}
                element={
                  <Suspense>
                    <DashBoard />
                  </Suspense>
                }
              />
              {/* setting Group */}
              <Route
                path={NavlinkPath.setting}
                element={
                  <Suspense>
                    <Outlet />
                  </Suspense>
                }
              >
                {/* setting dashboard */}
                <Route
                  path={NavlinkPath.setting}
                  index
                  element={<SettingPage />}
                />
                {/* setting identication */}
                <Route
                  path={NavlinkPath.indenticateAccountSetting}
                  element={<IdentificationSettingPage />}
                />
                {/* setting account-info */}
                <Route
                  path={NavlinkPath.accountInfo}
                  element={<AccountInfoScreen />}
                />
                {/* setting account banks */}
                {Environment.onBankAccount && (
                  <Route
                    path={NavlinkPath.receivedMoneyAccount}
                    element={<Outlet />}
                  >
                    <Route index element={<LinkingAccount />} />
                    <Route
                      path={NavlinkPath.receivedMoneyAccountDetail}
                      element={<DetailsLinkingAccount />}
                    />
                  </Route>
                )}
                {/* setting member */}
                {Environment.onMember && (
                  <Route
                    path={NavlinkPath.memberManagement}
                    element={<Outlet />}
                  >
                    <Route
                      index
                      path={NavlinkPath.memberManagement}
                      element={<MemberManagementPage />}
                    />
                    <Route
                      path={NavlinkPath.memberManagementCreate}
                      element={<CreateMemberScreen />}
                    />
                    <Route
                      path={NavlinkPath.memberManagementUpdate}
                      element={<UpdateMemberScreen />}
                    />
                  </Route>
                )}
                {/* setting change password */}
                {Environment.onChangePassword && (
                  <Route
                    path={NavlinkPath.changePassword}
                    element={<ChangePasswordScreen />}
                  />
                )}
                {/* setting change email receive notification */}
                {Environment.onEmailNoti && (
                  <Route
                    path={NavlinkPath.notifyEmail}
                    element={<NotifyEmail />}
                  />
                )}

                {/* setting payment */}
                {Environment.onPaymentSetting && (
                  <Route
                    path={NavlinkPath.settingPayment}
                    element={<SettingPaymentPage />}
                  />
                )}
              </Route>
              {/* paylink group */}
              {Environment.onPayLink && (
                <Route
                  path={NavlinkPath.payLink}
                  element={
                    <Suspense>
                      <Outlet />
                    </Suspense>
                  }
                >
                  <Route
                    path={NavlinkPath.payLink}
                    index
                    element={<PaylinkPage />}
                  />
                  <Route
                    path={NavlinkPath.createPayLink}
                    element={<CreatePaylink />}
                  />
                </Route>
              )}
              {/* paypage group */}
              {Environment.onPayPage && (
                <Route
                  path={NavlinkPath.payPage}
                  element={
                    <Suspense>
                      <Outlet />
                    </Suspense>
                  }
                >
                  <Route
                    path={NavlinkPath.payPage}
                    index
                    element={<PayPageDashboard />}
                  />
                  <Route
                    path={NavlinkPath.createPayPage}
                    element={<CreatePayPage />}
                  />
                  <Route
                    path={NavlinkPath.payPageDetail}
                    element={<PayPageDetail />}
                  >
                    <Route
                      index
                      path={NavlinkPath.payPageDetail}
                      element={
                        <Navigate
                          to={`${NavlinkPath.payPageDetailInfo}${window.location.search}`}
                          replace={true}
                        />
                      }
                    />
                    <Route
                      path={NavlinkPath.payPageDetailInfo}
                      element={<TabPayPageDetailInfo />}
                    />
                    <Route
                      path={NavlinkPath.payPageHistoryTransaction}
                      element={<TabPayPageHistoryTransaction />}
                    />
                  </Route>
                  <Route
                    path={NavlinkPath.payPageDetailHistoryTransaction}
                    element={<PaypageDetailHistoryTransaction />}
                  />
                </Route>
              )}
              {/* customer group */}
              {Environment.onCustomer && (
                <Route
                  path={NavlinkPath.customerManagement}
                  element={
                    <Suspense>
                      <Outlet />
                    </Suspense>
                  }
                >
                  <Route
                    index
                    path={NavlinkPath.customerManagement}
                    element={<CustomerManagementPage />}
                  />
                  <Route
                    index
                    path={NavlinkPath.customerGroupDetail}
                    element={<CustomerGroupDetailPage />}
                  />
                </Route>
              )}
              {/* Transaction group */}
              {Environment.onTransaction && (
                <Route
                  path={NavlinkPath.transactionsManagement}
                  element={
                    <Suspense>
                      <Outlet />
                    </Suspense>
                  }
                >
                  <Route
                    index
                    path={NavlinkPath.transactionsManagement}
                    element={<TransactionsManagementPage />}
                  />
                </Route>
              )}
              {/* statistic group */}
              {Environment.onStatistic && (
                <Route
                  path={NavlinkPath.statistic}
                  element={
                    <Suspense>
                      <Outlet />
                    </Suspense>
                  }
                >
                  <Route
                    index
                    path={NavlinkPath.statistic}
                    element={<StatisticPage />}
                  />
                </Route>
              )}
              {/* for control group */}
              {Environment.onForControl && (
                <Route
                  path={NavlinkPath.forControl}
                  element={
                    <Suspense>
                      <Outlet />
                    </Suspense>
                  }
                >
                  <Route
                    index
                    path={NavlinkPath.forControl}
                    element={<ForControlPage />}
                  />
                  <Route
                    index
                    path={NavlinkPath.forControlDetail}
                    element={<ForControlDetailPage />}
                  />
                </Route>
              )}
              {/* balance group */}
              {Environment.onBalance && (
                <Route
                  path={NavlinkPath.balance}
                  element={
                    <Suspense>
                      <Outlet />
                    </Suspense>
                  }
                >
                  <Route
                    index
                    path={NavlinkPath.balance}
                    element={<BalanceManagement />}
                  />
                </Route>
              )}
              {/* product group*/}
              {Environment.onProduct && (
                <Route
                  path={NavlinkPath.productManagement}
                  element={
                    <Suspense>
                      <Outlet />
                    </Suspense>
                  }
                >
                  <Route
                    index
                    path={NavlinkPath.productManagement}
                    element={<ProductManagement />}
                  />
                  <Route
                    index
                    path={NavlinkPath.categoryDetail}
                    element={<CategoryDetailPage />}
                  />
                </Route>
              )}
              {/* payout group */}
              {Environment.onPayOut && (
                <Route
                  path={NavlinkPath.payout}
                  element={
                    <Suspense>
                      <Outlet />
                    </Suspense>
                  }
                >
                  {/* <Route
                path={NavlinkPath.payout}
                index
                element={<Navigate to={NavlinkPath.payoutDashboard} />}
              /> */}
                  <Route path={NavlinkPath.payout} index element={<Payout />} />
                  <Route
                    path={NavlinkPath.detailPayout}
                    element={<PayoutDetail />}
                  />
                  <Route
                    path={NavlinkPath.createPayout}
                    element={<CreatePayout />}
                  />
                </Route>
              )}
              {/* payBox */}
              {Environment.onPayBox && (
                <Route
                  path={NavlinkPath.payBox}
                  element={
                    <Suspense>
                      <Outlet />
                    </Suspense>
                  }
                >
                  <Route
                    path={NavlinkPath.payBox}
                    index
                    element={<PayboxPage />}
                  />
                  <Route
                    path={NavlinkPath.payBoxDetail}
                    element={<PayBoxDetail />}
                  />
                </Route>
              )}
              {/* paySubscription */}
              {Environment.onPaySubscription && (
                <>
                  <Route
                    path={NavlinkPath.paySubscription}
                    element={
                      <Suspense>
                        <Outlet />
                      </Suspense>
                    }
                  >
                    {/* dashBoard paySubscription*/}
                    <Route
                      key={NavlinkPath.paySubscription}
                      id={NavlinkPath.paySubscription}
                      path={NavlinkPath.paySubscription}
                      index
                      element={<PaySubscription />}
                    />
                    {/* detail paySubscription*/}
                    <Route
                      key={NavlinkPath.paySubscriptionDetail}
                      id={NavlinkPath.paySubscriptionDetail}
                      path={NavlinkPath.paySubscriptionDetail}
                      element={<PaySubscriptionDetail />}
                    />
                    {/* create paySubscription*/}
                    <Route
                      key={NavlinkPath.paySubscriptionCreate}
                      id={NavlinkPath.paySubscriptionCreate}
                      path={NavlinkPath.paySubscriptionCreate}
                      element={<CreatePaySubscription />}
                    />
                    {/* bill detail in paySubscription*/}
                    <Route
                      key={NavlinkPath.paySubscriptionBillDetail}
                      id={NavlinkPath.paySubscriptionBillDetail}
                      path={NavlinkPath.paySubscriptionBillDetail}
                      element={<PaySubscriptionBillDetail />}
                    />
                  </Route>
                  <Route
                    path={NavlinkPath.paySubscriptionPlan}
                    element={
                      <Suspense>
                        <Outlet />
                      </Suspense>
                    }
                  >
                    {/* dashBoard paySubscriptionPlan*/}
                    <Route
                      path={NavlinkPath.paySubscriptionPlan}
                      index
                      element={<PaySubscriptionPlan />}
                    />
                    {/* dashBoard paySubscriptionPlan*/}
                    <Route
                      path={NavlinkPath.paySubscriptionPlanDetail}
                      element={<PaySubscriptionPlanDetail />}
                    />
                    {/* create paySubscriptionPlan */}
                    <Route
                      path={NavlinkPath.paySubscriptionPlanCreate}
                      element={<CreatePaySubscriptionPlan />}
                    />
                    {/* update paySubscriptionPlan */}
                    <Route
                      path={NavlinkPath.paySubscriptionPlanUpdate}
                      element={<UpdatePaySubscriptionPlan />}
                    />
                  </Route>
                </>
              )}
              {/* payButton */}
              {Environment.onPayButton && (
                <Route
                  path={NavlinkPath.payButton}
                  element={
                    <Suspense>
                      <Outlet />
                    </Suspense>
                  }
                >
                  <Route
                    path={NavlinkPath.payButton}
                    index
                    element={<PayButtonDashBoard />}
                  />
                  <Route
                    path={NavlinkPath.createPayButton}
                    element={<CreatePayButton />}
                  />
                  <Route
                    path={NavlinkPath.payButtonDetail}
                    element={<PayButtonDetail />}
                  />
                  <Route
                    path={NavlinkPath.updatePayButton}
                    element={<UpdatePayButton />}
                  />
                </Route>
              )}
              {/* payPOS group */}
              {Environment.onPayPos && (
                <>
                  <Route
                    index
                    path={NavlinkPath.payPosDevice}
                    element={<PayPos />}
                  />
                  {/* <Route
                    index
                    path={NavlinkPath.payPosDeviceHistoryPayment}
                    element={<PayPosDeviceHistoryPayment />}
                  /> */}
                  {/* <Route
                    path={NavlinkPath.payPosRegisterService}
                    element={<RegisterServicePOS />}
                  />
                  <Route
                    path={NavlinkPath.payPosOrderService}
                    element={<OrderService />}
                  /> */}
                  {/* <Route
                    path={NavlinkPath.payPosHistoryPayment}
                    element={<PayPosHistoryPayment />}
                  /> */}
                </>
                // <Route path={NavlinkPath.payPosDevice} element={<Outlet />}>
                //   <Route
                //     path={NavlinkPath.payPosDevice}
                //     element={
                //       <Navigate to={NavlinkPath.payPosDevice} replace={true} />
                //     }
                //   />

                // </Route>
              )}

              {/* support group */}
              {Environment.onSupport && (
                <Route
                  path={NavlinkPath.support}
                  element={
                    <Suspense>
                      <Outlet />
                    </Suspense>
                  }
                >
                  <Route
                    index
                    path={NavlinkPath.support}
                    element={<SupportPage />}
                  />
                </Route>
              )}

              {/* pay-gate */}
              {Environment.onPayGate && (
                <Route
                  path={NavlinkPath.payGate}
                  element={
                    <PayGatePage>
                      <Outlet />
                    </PayGatePage>
                  }
                >
                  <Route
                    index
                    element={
                      <Navigate
                        to={NavlinkPath.payGatePayment}
                        replace={true}
                      />
                    }
                  />
                  <Route
                    path={NavlinkPath.payGatePayment}
                    element={
                      <Suspense>
                        <PaymentIntergration />
                      </Suspense>
                    }
                  />
                  <Route
                    path={NavlinkPath.payGateWebhooks}
                    element={
                      <Suspense>
                        <PayGateWebhooks />
                      </Suspense>
                    }
                  />
                  <Route path={NavlinkPath.payGateTools} element={<Outlet />}>
                    <Route
                      index
                      path={NavlinkPath.payGateTools}
                      element={<PayGateTools />}
                    />
                    <Route
                      path={NavlinkPath.payGateToolsTransferMoney}
                      element={
                        <Suspense>
                          <ToolsTransferMoney />
                        </Suspense>
                      }
                    />
                  </Route>
                </Route>
              )}

              {/* identification page */}
              <Route
                path={NavlinkPath.identification}
                element={
                  <Suspense>
                    <Outlet />
                  </Suspense>
                }
              >
                <Route
                  index
                  path={NavlinkPath.identification}
                  element={<IdentificationPage />}
                />
              </Route>

              {/* fee report page */}
              {Environment.onFeeReport && (
                <Route
                  path={NavlinkPath.feeReport}
                  element={
                    <Suspense>
                      <Outlet />
                    </Suspense>
                  }
                >
                  <Route
                    index
                    path={NavlinkPath.feeReport}
                    element={<FeeReportPage />}
                  />

                  <Route
                    path={NavlinkPath.feeReportDetail}
                    element={<FeeReportDetail />}
                  />
                  <Route
                    path={NavlinkPath.feeReportDetailTransactionHistory}
                    element={<FeeReportDetailTransactionPage />}
                  />
                </Route>
              )}

              {/* Utilities page */}
              {Environment.onUltilities && (
                <Route
                  path={NavlinkPath.ultilities}
                  element={
                    <Suspense>
                      <Outlet />
                    </Suspense>
                  }
                >
                  <Route index element={<UtilitiesPage />} />
                  {Environment.onUltilitiesVietQrPro && (
                    <Route path={NavlinkPath.vietQrPro} element={<Outlet />}>
                      <Route index element={<VietQrProPage />} />
                      <Route
                        path={NavlinkPath.vietQrProTransaction}
                        element={<VietQrProTransactionPage />}
                      />
                    </Route>
                  )}
                  {Environment.onUltilitiesAppMarket && (
                    <Route
                      path={NavlinkPath.appMarket}
                      element={<AppMarketPage />}
                    />
                  )}
                  {/* Flexible Salary Payment */}
                  {Environment.onUltilitiesFlexibleSalaryPayment && (
                    <>
                      <Route
                        path={NavlinkPath.flexibleSalaryPayment}
                        element={
                          <FlexibleSalaryPayment>
                            <Outlet />
                          </FlexibleSalaryPayment>
                        }
                      >
                        <Route
                          index
                          element={
                            <Navigate
                              to={NavlinkPath.flexibleSalaryPayment}
                              replace={true}
                            />
                          }
                        />
                        <Route
                          path={NavlinkPath.flexibleSalaryPaymentStatistic}
                          element={<FlexibleSalaryPaymentStatisticPage />}
                        />
                        <Route
                          path={
                            NavlinkPath.flexibleSalaryPaymentStatisticDetail
                          }
                          element={<FlexibleSalaryPaymentStatisticDetailPage />}
                        />
                        <Route
                          path={
                            NavlinkPath.flexibleSalaryPaymentRequestAdvanceSalaries
                          }
                          element={
                            <FlexibleSalaryPaymentRequestAdvanceSalariesPage />
                          }
                        />
                        <Route
                          path={
                            NavlinkPath.flexibleSalaryPaymentEnterpriseConfiguration
                          }
                          element={
                            <FlexibleSalaryPaymentEnterpriseConfigurationPage />
                          }
                        />
                        <Route
                          path={NavlinkPath.flexibleSalaryPaymentPeriod}
                          element={<FlexibleSalaryPaymentPeriodPage />}
                        />
                        <Route
                          path={NavlinkPath.flexibleSalaryPaymentStaff}
                          element={<FlexibleSalaryPaymentStaffPage />}
                        />

                        <Route
                          path={
                            NavlinkPath.flexibleSalaryPaymentTypeOfFlexibleSalary
                          }
                          element={
                            <FlexibleSalaryPaymentTypeOfAdvanceSalariesPage />
                          }
                        />
                      </Route>
                      {/* Flexible Salary Payment - Type Of Flexible Salary*/}
                      <Route
                        path={
                          NavlinkPath.flexibleSalaryPaymentTypeOfFlexibleSalaryDetail
                        }
                        element={
                          <FlexibleSalaryPaymentTypeOfAdvanceSalariesDetail />
                        }
                      />
                      <Route
                        path={
                          NavlinkPath.flexibleSalaryPaymentTypeOfFlexibleSalaryCreate
                        }
                        element={
                          <FlexibleSalaryPaymentTypeOfAdvanceSalariesCreate />
                        }
                      />
                      <Route
                        path={
                          NavlinkPath.flexibleSalaryPaymentTypeOfFlexibleSalaryUpdate
                        }
                        element={
                          <FlexibleSalaryPaymentTypeOfAdvanceSalariesUpdate />
                        }
                      />
                      {/* Flexible Salary Payment - Staff*/}
                      <Route
                        path={NavlinkPath.flexibleSalaryPaymentStaffDetail}
                        element={<FlexibleSalaryPaymentStaffDetail />}
                      />
                      <Route
                        path={NavlinkPath.flexibleSalaryPaymentStaffCreate}
                        element={<FlexibleSalaryPaymentStaffCreate />}
                      />
                      <Route
                        path={NavlinkPath.flexibleSalaryPaymentStaffUpdate}
                        element={<FlexibleSalaryPaymentStaffUpdate />}
                      />
                      <Route
                        path={NavlinkPath.flexibleSalaryPaymentStaffImportExcel}
                        element={<FlexibleSalaryPaymentStaffImportExcel />}
                      />
                      {/* Flexible Salary Payment - Period*/}
                      <Route
                        path={NavlinkPath.flexibleSalaryPaymentPeriodDetail}
                        element={<FlexibleSalaryPaymentPeriodDetail />}
                      />
                      <Route
                        path={
                          NavlinkPath.flexibleSalaryPaymentPeriodDetailImportExcel
                        }
                        element={
                          <FlexibleSalaryPaymentPeriodDetailImportExcel />
                        }
                      />
                      {/* Flexible Salary Payment - Request Advance Salaries*/}
                      <Route
                        path={
                          NavlinkPath.flexibleSalaryPaymentRequestAdvanceSalariesDetail
                        }
                        element={
                          <FlexibleSalaryPaymentRequestAdvanceSalariesDetailPage />
                        }
                      />
                    </>
                  )}
                </Route>
              )}
            </Route>
          )}

        {/* router path for login/register */}
        {!window.location.pathname.startsWith("/public") &&
          !window.location.pathname.startsWith("/test/public") && (
            <Route
              path={`${NavlinkPath.authenticate}`}
              element={
                _isLogin ? (
                  <Navigate to={NavlinkPath.home} replace={true} />
                ) : (
                  <Suspense>
                    <AuthenticatePage>
                      <Outlet />
                    </AuthenticatePage>
                  </Suspense>
                )
              }
            >
              <Route
                index
                element={
                  <Navigate
                    to={`${NavlinkPath.login}${window.location.search}`}
                  />
                }
              />
              <Route
                path={NavlinkPath.login}
                element={
                  <Suspense>
                    <LoginForm />
                  </Suspense>
                }
              />
              <Route
                path={NavlinkPath.register}
                element={
                  <Suspense>
                    <RegisterForm />
                  </Suspense>
                }
              />
              <Route
                path={NavlinkPath.guideLoginQr}
                element={
                  <Suspense>
                    <GuideLoginQr />
                  </Suspense>
                }
              />
              <Route
                path={NavlinkPath.forgetPassword}
                element={
                  <Suspense>
                    <ForgetPasswordForm />
                  </Suspense>
                }
              />
              <Route
                path={NavlinkPath.memberForgetPassword}
                element={
                  <Suspense>
                    <ForgetPasswordMember />
                  </Suspense>
                }
              />
            </Route>
          )}

        {/* Public group */}
        {window.location.pathname.startsWith(NavlinkPath.public) && (
          <Route path={NavlinkPath.public} element={<Outlet />}>
            {/* payment paylink */}
            <Route
              path={`${NavlinkPath.paymentPayLink}`}
              element={
                <Suspense>
                  <PaymentPaylinkPage />
                </Suspense>
              }
            />
            <Route
              path={NavlinkPath.waittingPaymentPayLink}
              element={
                <Suspense>
                  <WaitingPaymentPaylink />
                </Suspense>
              }
            />
            {/* payment paypage */}
            <Route
              path={NavlinkPath.paymentPayPage}
              element={
                <Suspense>
                  <PaymentPayPage />
                </Suspense>
              }
            />
            <Route
              path={NavlinkPath.waittingPaymentPayPage}
              element={
                <Suspense>
                  <WaitingPaymentPaypage />
                </Suspense>
              }
            />
            {/* napas checkin */}
            <Route
              path={NavlinkPath.napasCheckin}
              element={
                <Suspense>
                  <NapasCheckin />
                </Suspense>
              }
            />
          </Route>
        )}

        {/* Public for test group */}
        {window.location.pathname.startsWith("/test/public") && (
          <Route path={"/test/public"} element={<Outlet />}>
            {/* payment paylink */}
            <Route
              path={`/test${NavlinkPath.paymentPayLink}`}
              element={
                <Suspense>
                  <PaymentPaylinkPage />
                </Suspense>
              }
            />
            <Route
              path={`/test${NavlinkPath.waittingPaymentPayLink}`}
              element={
                <Suspense>
                  <WaitingPaymentPaylink />
                </Suspense>
              }
            />
            {/* payment paypage */}
            <Route
              path={`/test${NavlinkPath.paymentPayPage}`}
              element={
                <Suspense>
                  <PaymentPayPage />
                </Suspense>
              }
            />
            <Route
              path={`/test${NavlinkPath.waittingPaymentPayPage}`}
              element={
                <Suspense>
                  <WaitingPaymentPaypage />
                </Suspense>
              }
            />
          </Route>
        )}
      </Routes>

      {/* handler UI modal */}
      <StreamBuilder<AppPageState, any, any>
        stream={_bloc.stream}
        selector={(pre, next) => {
          return pre?.isShowModel !== next?.isShowModel;
        }}
        builder={(_, snap) => {
          if (snap?.data?.isShowModel === true) {
            return createPortal(
              <OverlayComponent
                id={modalId}
                classChildren={"column animation-scale--up animation-faded--in"}
                classBackground="overlay-1"
                onTapBackGround={() => {
                  appBloc.session.isShowModal.next(false);
                  appBloc.session.modal.next(null);
                }}
                onTapChildren={(e) => {
                  e.stopPropagation();
                }}
              >
                {appBloc.session.modal.value}
              </OverlayComponent>,
              document.body,
              modalId,
            );
          } else {
            return <></>;
          }
        }}
      />

      {/* handler UI message */}
      <StreamBuilder<AppPageState, any, any>
        stream={_bloc.stream}
        selector={(pre, next) => {
          return pre?.isShowMessage !== next?.isShowMessage;
        }}
        builder={(_, snap) => {
          if (snap?.data?.isShowMessage === true) {
            return createPortal(
              appBloc.session.message.value,
              document.body,
              messageId,
            );
          } else {
            return <></>;
          }
        }}
      />

      {/* handler UI loading */}
      <StreamBuilder<AppPageState, any, any>
        stream={_bloc.stream}
        selector={(pre, next) => {
          return pre?.isLoading !== next?.isLoading;
        }}
        builder={(_, snap) => {
          if (snap?.data?.isLoading === true) {
            return createPortal(
              <OverlayComponent
                classChildren={undefined}
                classBackground="overlay-loading"
                onTapBackGround={(e) => {}}
                onTapChildren={(e) => {}}
                id={loaderId}
              >
                <div
                  className="column"
                  style={{
                    width: "100%",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "transparent",
                  }}
                >
                  <KienlongBankLoader height={150} width={150} />
                </div>
              </OverlayComponent>,
              document.body,
              loaderId,
            );
          } else {
            return <></>;
          }
        }}
      />

      {/* queue potal */}
      <StreamBuilder<AppPageState, any, any>
        stream={_bloc.stream}
        builder={(_, snap) => {
          return snap?.data?.models;
        }}
      />
    </>
  );
}

export default App;
