import React, { useRef, useState, useEffect } from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { RangePickerProps } from "antd/lib/date-picker";
import "./input-date.css";

export type DateRangeModel = {
  fromDate?: string;
  toDate?: string;
};

interface DatePickerCustomProps {
  label?: string;
  onblur?: () => void;
  onFocus?: () => void;
  onChange?: (value: DateRangeModel) => void;
  value?: DateRangeModel;
}

const InputDateRange = React.memo(
  ({ onChange, value }: DatePickerCustomProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [dateRange, setDateRange] = useState<DateRangeModel>(
      value || {
        fromDate: "",
        toDate: "",
      },
    );
    const [isValue, setIsValue] = useState(false);
    const [isOpenCalendar, setIsOpenCalendar] = useState(false);
    useEffect(() => {
      setDateRange({
        ...value,
      });
    }, [value?.fromDate, value?.toDate, value]);

    const handleOnChangeInput = (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      // const newDateRange = {
      //   ...dateRange,
      //   [event?.target?.name]: event?.target?.value,
      // };
      // setDateRange({
      //   ...newDateRange,
      // });
      // onChange?.(newDateRange);
    };

    useEffect(() => {
      if (dateRange?.fromDate !== "" || dateRange?.toDate !== "") {
        setIsValue(true);
      }
      if (dateRange?.fromDate !== "" && dateRange?.toDate !== "") {
        setIsOpenCalendar(false);
      }

      if (dateRange?.fromDate === "" && dateRange?.toDate === "") {
        setIsValue(false);
      }
    }, [dateRange?.fromDate, dateRange?.toDate]);

    // eslint-disable-next-line arrow-body-style
    const disabledDate: RangePickerProps["disabledDate"] = (current) => {
      // Can not select days before today and today
      return current && current > dayjs().endOf("day");
    };

    return (
      <div style={{ position: "relative" }}>
        <div className="input-date-range">
          <div className="input-date-range-wrap">
            <div className="input-date-range-wrap__item">
              <input
                style={{ backgroundColor: "transparent" }}
                ref={inputRef}
                autoComplete="off"
                className="input-date-range-custom"
                placeholder="dd/mm/yyyy"
                value={dateRange?.fromDate}
                type="text"
                id="fromDate"
                name="fromDate"
                onChange={handleOnChangeInput}
                disabled
              />
            </div>
            <img
              style={{ width: "16px", height: "16px" }}
              src="/icons/icon-arrow-right.svg"
              alt="icon arrow right"
            />
            <div className="input-date-range-wrap__item">
              <input
                style={{ backgroundColor: "transparent" }}
                ref={inputRef}
                autoComplete="off"
                placeholder="dd/mm/yyyy"
                className="input-date-range-custom"
                value={dateRange?.toDate}
                type="text"
                id="toDate"
                name="toDate"
                onChange={handleOnChangeInput}
                disabled
              />
            </div>
          </div>
          <div
            onClick={() => setIsOpenCalendar((prev) => !prev)}
            onKeyDown={() => setIsOpenCalendar((prev) => !prev)}
            className="icon-date-range"
          >
            <img src="/icons/icon-calendar.svg" alt="icon calendar" />
          </div>
        </div>
        <div>
          <DatePicker.RangePicker
            style={{
              opacity: 0,
              width: 0,
              height: 0,
              padding: 0,
              lineHeight: 0,
              position: "absolute",
            }}
            bordered={false}
            open={isOpenCalendar}
            onClick={() => {
              setIsOpenCalendar(false);
            }}
            onChange={(date) => {
              if (date && date?.length > 0) {
                const fromDate = date[0]?.format("DD/MM/YYYY");
                const toDate = date[1]?.format("DD/MM/YYYY");
                const newDateRange = {
                  fromDate,
                  toDate,
                };
                setDateRange(newDateRange);
                onChange?.(newDateRange);
              }
            }}
            disabledDate={disabledDate}
          />
        </div>
      </div>
    );
  },
);

export default InputDateRange;
