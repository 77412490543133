import {
  mapBankAccountIdenticaltionModel,
  mapBankAccountModal,
  mapPayBoxBankAccount,
} from "@/adapters/bank_account_adapter";
import {
  BankAccountControllerApi,
  BankAccountDto,
  Configuration,
} from "@/api/generated-sources/kienlong-pay";
import { KienlongPayClient } from "@/api/kienlong_pay_http_client";
import {
  BankAccountModel,
  BankTransactionDetailModel,
  BankAccountType,
  LinkAccountInfoModel,
  FeeBankAccountInputModel,
} from "@/models/BankAccountModel";
import { ErrorModel } from "@/models/ErrorModel";
import { PayboxBankAccountModel } from "@/models/PayboxBankAccountModel";
import { BankAccountRepository } from "../BankAccountRepository";
import { Environment } from "@/services/environment";

export class BankAccountRepositoryImp implements BankAccountRepository {
  private client: KienlongPayClient;
  private _client: BankAccountControllerApi;
  constructor(kienlongPayClient: KienlongPayClient) {
    this.client = kienlongPayClient;
    this._client = new BankAccountControllerApi(
      new Configuration({
        basePath: Environment.hostApiKlbPay,
      }),
    );
  }
  async getBankAccount({
    id,
    testMode = true,
  }: {
    id: string;
    testMode?: boolean;
  }): Promise<PayboxBankAccountModel> {
    if (testMode) {
      try {
        let resp = await this.client.bankAccountController.getBankAccount({
          id: id,
        });
        if (resp.data.code === 0 && resp.data?.data) {
          return mapPayBoxBankAccount(resp.data?.data);
        } else {
          throw new ErrorModel({
            code: resp.data.code,
            message: resp.data.message,
          });
        }
      } catch (error) {
        throw error;
      }
    } else {
      try {
        let resp = await this._client.getBankAccount({
          id: id,
        });
        if (resp.data.code === 0 && resp.data?.data) {
          return mapPayBoxBankAccount(resp.data?.data);
        } else {
          throw new ErrorModel({
            code: resp.data.code,
            message: resp.data.message,
          });
        }
      } catch (error) {
        throw error;
      }
    }
  }

  public async getBankAccounts(testMode: boolean): Promise<BankAccountModel[]> {
    if (testMode) {
      try {
        let resp = await this.client.bankAccountController.getListBankAccount();
        if (
          resp.data.code === 0 &&
          resp.data?.data?.bankAccounts !== undefined
        ) {
          return (
            resp.data.data.bankAccounts.map((d, i) => {
              return mapBankAccountModal(d satisfies BankAccountDto);
            }) ?? []
          );
        } else {
          throw new ErrorModel({
            code: resp.data.code,
            message: resp.data.message,
          });
        }
      } catch (error) {
        throw error;
      }
    } else {
      try {
        let resp = await this._client.getListBankAccount();
        if (
          resp.data.code === 0 &&
          resp.data?.data?.bankAccounts !== undefined
        ) {
          return (
            resp.data.data.bankAccounts.map((d, i) => {
              return mapBankAccountModal(d satisfies BankAccountDto);
            }) ?? []
          );
        } else {
          throw new ErrorModel({
            code: resp.data.code,
            message: resp.data.message,
          });
        }
      } catch (error) {
        throw error;
      }
    }
  }

  public async checkBankAccount({
    bankAccountNo,
    bankType,
    idCardNo,
  }: {
    bankAccountNo: string;
    bankType: BankAccountType;
    idCardNo: string;
  }): Promise<string> {
    try {
      let resp = await this._client.checkBankAccount({
        checkBankAccountRequest: {
          accountNo: bankAccountNo,
          branch: bankType,
          idCardNo: idCardNo,
        },
      });
      if (resp.data.code === 0) {
        return resp.data.data?.accountName ?? "";
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async submitLinkAccount({
    bankAccountNo,
    bankType,
    accountName,
    idCardNo,
  }: {
    bankAccountNo: string;
    bankType: BankAccountType;
    accountName: string;
    idCardNo: string;
  }): Promise<{ phone: string; session: string }> {
    try {
      let resp = await this._client.submitLinkAccount({
        submitLinkAccountRequest: {
          accountNo: bankAccountNo,
          branch: bankType,
          accountName: accountName,
          idCardNo: idCardNo,
        },
      });
      if (resp.data.code === 0) {
        return {
          phone: resp.data.data?.phone ?? "",
          session: resp.data.data?.sessionId ?? "",
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async checkOTPLinkAccount({
    bankAccountNo,
    bankType,
    bankAccountName,
    otp,
    sessionId,
  }: {
    bankAccountNo: string;
    bankType: BankAccountType;
    bankAccountName: string;
    otp: string;
    sessionId: string;
  }): Promise<BankAccountModel> {
    try {
      let resp = await this._client.checkOTPLinkAccount({
        verifyOtpLinkAccountRequest: {
          accountName: bankAccountName,
          accountNo: bankAccountNo,
          branch: bankType,
          otp: otp,
          sessionId: sessionId,
        },
      });
      if (resp.data.code === 0) {
        return mapBankAccountModal(
          resp.data.data?.bankAccount satisfies BankAccountDto | undefined,
        );
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async updateDefaultBankAccount({
    bankAccountId,
  }: {
    bankAccountId: string;
  }): Promise<boolean> {
    try {
      let resp = await this._client.updateDefaultBankAccount({
        updateDefaultBankAccountRequest: {
          bankAccountId: bankAccountId,
        },
      });
      if (resp.data.code === 0) {
        return resp.data.data?.success ?? false;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async deleteBankAccount({
    bankAccountId,
  }: {
    bankAccountId: string;
  }): Promise<BankAccountModel[]> {
    try {
      let resp = await this._client.deleteBankAccount({
        id: bankAccountId,
      });
      if (resp.data.code === 0) {
        return (
          resp.data?.data?.bankAccounts?.map((d, i) => {
            return mapBankAccountModal(d satisfies BankAccountDto);
          }) ?? []
        );
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getbalance({
    bankAccountId,
    testMode = true,
  }: {
    bankAccountId: string;
    testMode?: boolean;
  }): Promise<
    Pick<BankAccountModel, "accountName" | "accountNo"> & {
      balance: number;
      currency: string;
    }
  > {
    if (testMode) {
      try {
        let resp = await this.client.bankAccountController.getBalance({
          bankAccountId: bankAccountId,
        });
        if (resp.data.code === 0) {
          return {
            accountName: resp.data.data?.accountName ?? "",
            accountNo: resp.data.data?.accountNumber ?? "",
            balance: resp.data.data?.balance ?? 0,
            currency: resp.data.data?.currency ?? "VND",
          };
        } else {
          throw new ErrorModel({
            code: resp.data.code,
            message: resp.data.message,
          });
        }
      } catch (error) {
        throw error;
      }
    } else {
      try {
        let resp = await this._client.getBalance({
          bankAccountId: bankAccountId,
        });
        if (resp.data.code === 0) {
          return {
            accountName: resp.data.data?.accountName ?? "",
            accountNo: resp.data.data?.accountNumber ?? "",
            balance: resp.data.data?.balance ?? 0,
            currency: resp.data.data?.currency ?? "VND",
          };
        } else {
          throw new ErrorModel({
            code: resp.data.code,
            message: resp.data.message,
          });
        }
      } catch (error) {
        throw error;
      }
    }
  }

  public async getTransactionStatistic({
    bankAccountId,
    fromDate,
    toDate,
    testMode = true,
  }: {
    bankAccountId: string;
    fromDate: string;
    toDate: string;
    testMode?: boolean;
  }): Promise<BankTransactionDetailModel[]> {
    if (testMode) {
      try {
        let resp =
          await this.client.bankAccountController.getTransactionStatisticSandBox(
            {
              bankAccountId: bankAccountId,
              // fromDate: fromDate,
              // toDate: toDate,
            },
          );
        if (resp.data.code === 0) {
          return (
            resp.data.data?.transactions?.map(
              (d): BankTransactionDetailModel => {
                return {
                  amount: d.amount ?? 0,
                  coefficient: d.coefficient ?? 0,
                  currency: d.currency ?? "VND",
                  dateTime: d.dateTime ?? "",
                  detail: d.detail ?? "",
                  transactionNo: d.transactionNo ?? "",
                  transGroup: d.transGroup ?? "",
                };
              },
            ) ?? []
          );
        } else {
          throw new ErrorModel({
            code: resp.data.code,
            message: resp.data.message,
          });
        }
      } catch (error) {
        throw error;
      }
    } else {
      try {
        let resp = await this._client.getTransactionStatistic1({
          bankAccountId: bankAccountId,
          fromDate: fromDate,
          toDate: toDate,
        });
        if (resp.data.code === 0) {
          return (
            resp.data.data?.transactions?.map(
              (d): BankTransactionDetailModel => {
                return {
                  amount: d.amount ?? 0,
                  coefficient: d.coefficient ?? 0,
                  currency: d.currency ?? "VND",
                  dateTime: d.dateTime ?? "",
                  detail: d.detail ?? "",
                  transactionNo: d.transactionNo ?? "",
                  transGroup: d.transGroup ?? "",
                };
              },
            ) ?? []
          );
        } else {
          throw new ErrorModel({
            code: resp.data.code,
            message: resp.data.message,
          });
        }
      } catch (error) {
        throw error;
      }
    }
  }

  public async exportTransactionStatisticToExcel({
    bankAccountId,
    fromDate,
    toDate,
    testMode = true,
  }: {
    bankAccountId: string;
    fromDate: string;
    toDate: string;
    testMode?: boolean;
  }): Promise<File> {
    if (testMode) {
      try {
        let resp =
          await this.client.bankAccountController.exportTransactionStatisticToExcel(
            {
              bankAccountId: bankAccountId,
              fromDate: fromDate,
              toDate: toDate,
            },
            { responseType: "arraybuffer" },
          );
        return resp.data;
      } catch (error) {
        throw error;
      }
    } else {
      try {
        let resp = await this._client.exportTransactionStatisticToExcel(
          {
            bankAccountId: bankAccountId,
            fromDate: fromDate,
            toDate: toDate,
          },
          { responseType: "arraybuffer" },
        );
        return resp.data;
      } catch (error) {
        throw error;
      }
    }
  }

  public async organizationGetLinkedAccountFromBank(): Promise<
    LinkAccountInfoModel[]
  > {
    try {
      let resp = await this._client.organizationGetLinkedAccountFromBank();
      if (resp.data.code === 0) {
        return (
          resp.data?.data?.bankAccounts?.map((d, i) => {
            return mapBankAccountIdenticaltionModel(d);
          }) ?? []
        );
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async organizationLinkAccount({
    listAccountNo,
  }: {
    listAccountNo: Array<FeeBankAccountInputModel>;
  }): Promise<string> {
    try {
      let resp = await this._client.organizationLinkAccount({
        organizationLinkAccountRequest: { listAccountNo },
      });
      if (resp.data.code === 0) {
        return resp.data?.message ?? "";
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async organizationgetUnlinkedAccounts(): Promise<
    LinkAccountInfoModel[]
  > {
    try {
      let resp = await this._client.organizationGetUnlinkedAccounts();
      if (resp.data.code === 0) {
        return (
          resp.data?.data?.bankAccounts?.map((d, i) => {
            return mapBankAccountIdenticaltionModel(d);
          }) ?? []
        );
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
