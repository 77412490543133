import { WarningMessage } from "@/components/custom-message/WarningMessage";
import { SuccessMessage } from "@/components/custom-message/SuccessMessage";
import { ErrorMessage } from "@/components/custom-message/ErrorMessage";
import { InfoMessage } from "@/components/custom-message/InfoMessage";
import { AppBloc } from "@/services/base-bloc";

class AppMessage {
  private static instance: AppMessage;
  private _appBloc: AppBloc = new AppBloc();
  private constructor() {}
  public static getInstance(): AppMessage {
    if (!AppMessage.instance) {
      AppMessage.instance = new AppMessage();
    }

    return AppMessage.instance;
  }

  success({
    title = "Success",
    content = "",
    onClick = () => {},
    duration = 1000,
  }: {
    title?: string;
    content?: string;
    onClick?: () => void;
    duration?: number;
  }) {
    this._appBloc.session.isShowMessage.next(false);
    this._appBloc.session.message.next(
      <SuccessMessage
        content={content}
        title={title}
        duration={duration}
        onClose={() => {
          this._appBloc.session.isShowMessage.next(false);
        }}
        onClick={() => {
          onClick();
        }}
      />,
    );
    this._appBloc.session.isShowMessage.next(true);
  }

  info({
    title = "Info",
    content = "",
    onClick = () => {},
    duration = 1000,
  }: {
    title?: string;
    content?: string;
    onClick?: () => void;
    duration?: number;
  }) {
    this._appBloc.session.isShowMessage.next(false);
    this._appBloc.session.message.next(
      <InfoMessage
        content={content}
        title={title}
        duration={duration}
        onClose={() => {
          this._appBloc.session.isShowMessage.next(false);
        }}
        onClick={() => {
          onClick();
        }}
      />,
    );
    this._appBloc.session.isShowMessage.next(true);
  }

  warning({
    title = "Warning",
    content = "",
    onClick = () => {},
    duration = 1000,
  }: {
    title?: string;
    content?: string;
    onClick?: () => void;
    duration?: number;
  }) {
    this._appBloc.session.isShowMessage.next(false);
    this._appBloc.session.message.next(
      <WarningMessage
        content={content}
        title={title}
        duration={duration}
        onClose={() => {
          this._appBloc.session.isShowMessage.next(false);
        }}
        onClick={() => {
          onClick();
        }}
      />,
    );
    this._appBloc.session.isShowMessage.next(true);
  }

  error({
    title = "Error",
    content = "",
    onClick = () => {},
    duration = 1000,
  }: {
    title?: string;
    content?: string;
    onClick?: () => void;
    duration?: number;
  }) {
    this._appBloc.session.isShowMessage.next(false);
    this._appBloc.session.message.next(
      <ErrorMessage
        content={content}
        title={title}
        duration={duration}
        onClose={() => {
          this._appBloc.session.isShowMessage.next(false);
        }}
        onClick={() => {
          onClick();
        }}
      />,
    );
    this._appBloc.session.isShowMessage.next(true);
  }

  destroy() {
    this._appBloc.session.isShowMessage.next(false);
  }
}

export const appMessage = AppMessage.getInstance();
