import { mapPaySubscriptionModel } from "@/adapters/pay_subscription_adapter";
import { KienlongPayClient } from "@/api/kienlong_pay_http_client";
import { ErrorModel } from "@/models/ErrorModel";
import {
  PaySubscriptionCancelType,
  PaySubscriptionModel,
  PaySubscriptionStatus,
} from "@/models/PaySubscriptionModel";
import { TimeType } from "@/models/TimeModel";
import { PaySubscriptionRepository } from "../PaySubscriptionRepository";

export class PaySubscriptionRepositoryImp implements PaySubscriptionRepository {
  private client: KienlongPayClient;
  constructor(kienlongPayClient: KienlongPayClient) {
    this.client = kienlongPayClient;
  }

  public async getAllPaySubscription({
    keyword,
    page,
    pageSize,
    fromDate,
    toDate,
    status,
  }: {
    keyword?: string;
    page?: number;
    pageSize?: number;
    fromDate?: string;
    toDate?: string;
    status?: PaySubscriptionStatus;
  }): Promise<{
    pageNumber: number;
    pageSize: number;
    totalSize: number;
    totalPage: number;
    items: Array<PaySubscriptionModel>;
  }> {
    try {
      let resp = await this.client.paySubscription.getAllPaySubscription1({
        keyword: keyword,
        page: page,
        size: pageSize,
        sort: ["createdAt,desc"],
        fromDate: fromDate,
        toDate: toDate,
        status: status,
      });
      if (resp.data.code === 0) {
        return {
          pageNumber: resp.data.data?.pageNumber ?? 0,
          pageSize: resp.data.data?.pageSize ?? 0,
          totalSize: resp.data.data?.totalSize ?? 0,
          totalPage: resp.data.data?.totalPage ?? 0,
          items:
            resp.data.data?.items?.map((d: any) => {
              return mapPaySubscriptionModel(d);
            }) ?? [],
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getDetailPaySubscription(
    id: string,
  ): Promise<PaySubscriptionModel> {
    try {
      let resp = await this.client.paySubscription.getDetailPaySubscription({
        id: id,
      });
      if (resp.data.code === 0) {
        return mapPaySubscriptionModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async createPaySubscription({
    customerId,
    planId,
    allowTrial,
    trialTimeType,
    trialTimeValue,
    numOfCycles,
  }: {
    customerId: string;
    planId: string;
    allowTrial: boolean;
    trialTimeType?: TimeType;
    trialTimeValue?: number;
    numOfCycles: number;
  }): Promise<PaySubscriptionModel> {
    try {
      let resp = await this.client.paySubscription.createPaySubscription({
        createPaySubscriptionRequest: {
          customerId: customerId,
          planId: planId,
          allowTrial: allowTrial,
          trialTimeType: trialTimeType,
          trialTimeValue: trialTimeValue,
          numOfCycles: numOfCycles,
        },
      });
      if (resp.data.code === 0) {
        return mapPaySubscriptionModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async pausePaySubscription(id: string): Promise<{ success: boolean }> {
    try {
      let resp = await this.client.paySubscription.pausePaySubscription({
        id: id,
      });
      if (resp.data.code === 0 && resp.data.data) {
        return {
          success: resp?.data.data.success ?? false,
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async continuePaySubscription(
    id: string,
  ): Promise<{ success: boolean }> {
    try {
      let resp = await this.client.paySubscription.continuePaySubscription({
        id: id,
      });
      if (resp.data.code === 0 && resp.data.data) {
        return {
          success: resp?.data.data.success ?? false,
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async cancelPaySubscription({
    id,
    cancelType,
  }: {
    id: string;
    cancelType: PaySubscriptionCancelType;
  }): Promise<{ success: boolean }> {
    try {
      let resp = await this.client.paySubscription.cancelPaySubscription({
        id: id,
        cancelPaySubscriptionRequest: {
          cancelType: cancelType,
        },
      });
      if (resp.data.code === 0 && resp.data.data) {
        return {
          success: resp?.data.data.success ?? false,
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async extendPaymentPaySubscription(
    id: string,
  ): Promise<{ success: boolean }> {
    try {
      let resp = await this.client.paySubscription.extendPaymentPaySubscription(
        {
          id: id,
        },
      );
      if (resp.data.code === 0 && resp.data.data) {
        return {
          success: resp?.data.data.success ?? false,
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async extendPaySubscription({
    id,
    addNumOfCycles,
  }: {
    id: string;
    addNumOfCycles: number;
  }): Promise<{ success: boolean }> {
    try {
      let resp = await this.client.paySubscription.extendPaySubscription({
        id: id,
        extendPaySubscriptionRequest: {
          addNumOfCycles: addNumOfCycles,
        },
      });
      if (resp.data.code === 0 && resp.data.data) {
        return {
          success: resp?.data.data.success ?? false,
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async restorePaySubscription(
    id: string,
  ): Promise<{ success: boolean }> {
    try {
      let resp = await this.client.paySubscription.restorePaySubscription({
        id: id,
      });
      if (resp.data.code === 0 && resp.data.data) {
        return {
          success: resp?.data.data.success ?? false,
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async recreateInvoicePaySubscription(
    id: string,
  ): Promise<{ success: boolean }> {
    try {
      let resp =
        await this.client.paySubscription.recreateInvoicePaySubscription({
          id: id,
        });
      if (resp.data.code === 0 && resp.data.data) {
        return {
          success: resp?.data.data.success ?? false,
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
