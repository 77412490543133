import { EkycDto, EkycResponse } from "@/api/generated-sources/kienlong-pay";
import { EkycModel, EkycProfileModel } from "@/models/EkycModel";

export function mapEkycModalProfile(
  dto: EkycDto | undefined,
): EkycProfileModel {
  return {
    address: dto?.address ?? "",
    backUrl: dto?.backUrl ?? "",
    frontUrl: dto?.frontUrl ?? "",
    id: dto?.id ?? 0,
    idType: dto?.idType ?? "",
    issueBy: dto?.issueBy ?? "",
    issueDate: dto?.issueDate ?? "",
    name: dto?.name ?? "",
    national: dto?.national ?? "",
    no: dto?.no ?? "",
    sex: dto?.sex ?? "",
    document: dto?.document ?? "",
  };
}

export function mapEkycModal(dto: EkycResponse | undefined): EkycModel {
  return {
    id: dto?.id ?? 0,
    idType: dto?.idType ?? "",
    issueBy: dto?.issueBy ?? "",
    issueDate: dto?.issueDate ?? "",
    name: dto?.name ?? "",
    national: dto?.national ?? "",
    no: dto?.no ?? "",
    sex: dto?.sex ?? "",
    birthday: dto?.birthday ?? "",
    country: dto?.country ?? "",
    createAt: dto?.createAt ?? "",
    district: dto?.district ?? "",
    documentId: dto?.documentId ?? "",
    ethnicity: dto?.ethnicity ?? "",
    hometown: dto?.hometown ?? "",
    idCheck: dto?.idCheck ?? "",
    idLogic: dto?.idLogic ?? "",
    idLogicMessage: dto?.idLogicMessage ?? "",
    optionalData: dto?.optionalData ?? "",
    passportType: dto?.passportType ?? "",
    province: dto?.province ?? "",
    religion: dto?.religion ?? "",
    street: dto?.street ?? "",
    document: dto?.document ?? "",
  };
}
