import { IconSearch } from "@/components/icons-component/icon-search";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import "./input-form-custom-normal.css";

interface InputFormCustomNormalProps {
  onChange?: (props: any) => void;
  placeHolder?: string;
  value: string;
  inputProps?: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
  prefix?: React.ReactNode;
  type?: React.HTMLInputTypeAttribute;
}

const InputFormCustomNormal: React.FC<InputFormCustomNormalProps> = ({
  value,
  onChange,
  placeHolder,
  inputProps,
  prefix,
  type = "text",
}) => {
  const [valueInput, setValueInput] = useState(value);
  useEffect(() => {
    setValueInput(value);
  }, [value]);

  const handleOnchange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange?.(e?.target?.value);
      setValueInput(e?.target?.value);
    },
    [onChange],
  );

  const BuildInput = useMemo(() => {
    return (
      <>
        <input
          value={valueInput}
          type={type}
          className={`${inputProps?.className ?? "form-style"}`}
          placeholder={placeHolder}
          onChange={(e) => {
            handleOnchange(e);
          }}
          onKeyDown={(e) => {
            if (
              type === "number" &&
              (e.key === "e" || e.key === "+" || e.key === "-" || e.key === "E")
            ) {
              e.preventDefault();
            }
          }}
          {...inputProps}
        />
        <div className="input-icon">
          {prefix ? prefix : <IconSearch color="#6B7280" />}
        </div>
      </>
    );
  }, [handleOnchange, inputProps, placeHolder, prefix, type, valueInput]);

  return (
    <>
      <div className="form-group">{BuildInput}</div>
    </>
  );
};

export default React.memo(InputFormCustomNormal);
