import {
  DayOfWeekItem,
  LogworkTableRecord,
  LogworkTableRecordWithStatus,
} from "@/api/generated-sources/flexsalary";
import {
  DayOfWeekItemModel,
  DayOfWeekType,
  LogworkTableRecordModel,
  LogworkTableRecordWithStatusModel,
} from "../models/LogworkModel";

export function mapDayOfWeekItemModel(dto?: DayOfWeekItem): DayOfWeekItemModel {
  return {
    dateString: dto?.dateString ?? "",
    dayOfWeek: DayOfWeekType[dto?.dayOfWeek as keyof typeof DayOfWeekType],
    workDate: dto?.workDate ?? false,
  };
}

export function mapLogworkTableRecordWithStatusModel(
  dto?: LogworkTableRecordWithStatus,
): LogworkTableRecordWithStatusModel {
  return {
    id: dto?.id,
    employeeCode: dto?.employeeCode ?? "",
    employeeName: dto?.employeeName ?? "",
    logworks: dto?.logworks ?? [],
    numberOfWorkDays: dto?.numberOfWorkDays ?? 0,
    status: dto?.status ?? [],
    totalWorkDays: dto?.totalWorkDays ?? 0,
  };
}

export function mapLogworkTableRecordModel(
  dto?: LogworkTableRecord,
): LogworkTableRecordModel {
  return {
    employeeCode: dto?.employeeCode ?? "",
    employeeName: dto?.employeeName ?? "",
    logworks: dto?.logworks ?? [],
    numberOfWorkDays: dto?.numberOfWorkDays ?? 0,
    totalWorkDays: dto?.totalWorkDays ?? 0,
  };
}
