export interface SystemParamModel {
  id: number;
  name: string;
  value: string;
  description: string;
  dataType: SystemParamDataType;
  group: SystemParamGroup;
  createdAt: string;
  createBy: string;
  modifiedAt: string;
  modifiedBy: string;
}

export enum SystemParamDataType {
  STRING = "STRING",
  NUMBER = "NUMBER",
}

export enum SystemParamGroup {
  PARAMETER_SYSTEM = "PARAMETER_SYSTEM",
  PARAMETER_CONNECTION = "PARAMETER_CONNECTION",
  PARAMETER_PAYBOX = "PARAMETER_PAYBOX",
  PARAMETER_CONTACT = "PARAMETER_CONTACT",
}
