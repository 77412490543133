import { mapPaySubscriptionInvoiceModel } from "@/adapters/pay_subscription_invoice_adapter";
import { KienlongPayClient } from "@/api/kienlong_pay_http_client";
import { ErrorModel } from "@/models/ErrorModel";
import { PaySubscriptionInvoiceModel } from "@/models/PaySubscriptionInvoiceModel";
import { PaySubscriptionInvoiceRepository } from "../PaySubscriptionInvoiceRepository";

export class PaySubscriptionInvoiceRepositoryImp
  implements PaySubscriptionInvoiceRepository
{
  private client: KienlongPayClient;
  constructor(kienlongPayClient: KienlongPayClient) {
    this.client = kienlongPayClient;
  }

  public async getAllPaySubscriptionInvoice({
    subscriptionId,
    page,
    pageSize,
  }: {
    subscriptionId?: string;
    page?: number;
    pageSize?: number;
  }): Promise<{
    pageNumber: number;
    pageSize: number;
    totalSize: number;
    totalPage: number;
    items: Array<PaySubscriptionInvoiceModel>;
  }> {
    try {
      let resp =
        await this.client.paySubscriptionInvoice.getAllPaySubscriptionInvoice1({
          subscriptionId: subscriptionId,
          page: page,
          size: pageSize,
          sort: ["createdAt,desc"],
        });
      if (resp.data.code === 0) {
        return {
          pageNumber: resp.data.data?.pageNumber ?? 0,
          pageSize: resp.data.data?.pageSize ?? 0,
          totalSize: resp.data.data?.totalSize ?? 0,
          totalPage: resp.data.data?.totalPage ?? 0,
          items:
            resp.data.data?.items?.map((d) => {
              return mapPaySubscriptionInvoiceModel(d);
            }) ?? [],
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getDetailPaySubscriptionInvoice(
    id: string,
  ): Promise<PaySubscriptionInvoiceModel> {
    try {
      let resp =
        await this.client.paySubscriptionInvoice.getDetailPaySubscriptionInvoice1(
          {
            id: id,
          },
        );
      if (resp.data.code === 0) {
        return mapPaySubscriptionInvoiceModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
