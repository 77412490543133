export const IconFile = ({ color = "#3366FF" }: { color?: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.47101 3C7.11158 3 6.8202 3.29079 6.8202 3.64951C6.8202 4.00822 7.11158 4.29902 7.47101 4.29902H18.101L18.101 16.6397C18.101 16.9984 18.3924 17.2892 18.7518 17.2892C19.1112 17.2892 19.4026 16.9984 19.4026 16.6397V4.29902C19.4026 3.58159 18.8199 3 18.101 3H7.47101Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.30163 5.38154C4.58276 5.38154 4 5.96313 4 6.68055V19.6707C4 20.3881 4.58276 20.9697 5.30163 20.9697H15.7147C16.4335 20.9697 17.0163 20.3881 17.0163 19.6707V6.68055C17.0163 5.96313 16.4335 5.38154 15.7147 5.38154H5.30163ZM7.41943 9.70993C7.41943 9.35122 7.71081 9.06042 8.07025 9.06042H12.9514C13.3108 9.06042 13.6022 9.35122 13.6022 9.70993C13.6022 10.0686 13.3108 10.3594 12.9514 10.3594H8.07025C7.71081 10.3594 7.41943 10.0686 7.41943 9.70993ZM8.07025 12.5245C7.71081 12.5245 7.41943 12.8153 7.41943 13.174C7.41943 13.5327 7.71081 13.8235 8.07025 13.8235H12.9514C13.3108 13.8235 13.6022 13.5327 13.6022 13.174C13.6022 12.8153 13.3108 12.5245 12.9514 12.5245H8.07025ZM7.41943 16.638C7.41943 16.2793 7.71081 15.9885 8.07025 15.9885H12.9514C13.3108 15.9885 13.6022 16.2793 13.6022 16.638C13.6022 16.9967 13.3108 17.2875 12.9514 17.2875H8.07025C7.71081 17.2875 7.41943 16.9967 7.41943 16.638Z"
        fill={color}
      />
    </svg>
  );
};
