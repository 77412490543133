import {
  BankAccountResponse,
  OrderDetailResponse,
  OrderItemResponse,
  OrderResponse,
  PayPageDetailResponse,
  PayPageResponse,
  PayPageTransactionResponse,
} from "@/api/generated-sources/kienlong-pay";
import { BankAccountType } from "@/models/BankAccountModel";
import { PaymentType } from "@/models/PaylinkModel";
import {
  PagePayOrderStatusType,
  PayPageModel,
  PayPageOrderDetailModal,
  PayPageOrderModel,
  PayPagePeriodType,
  PayPageStatusType,
  PayPageTransactionModel,
  PayPageOrderItemModel,
  PayPageDetailModel,
} from "@/models/PayPageModel";
import { TransactionStatusType } from "@/models/TransactionModel";
import { mapBankAccountModal } from "./bank_account_adapter";
import { mapTransacionResponseModel } from "./transaction_adapter";
import {
  mapCustomerInfoFormModal,
  mapCustomermModal,
} from "./customers_adapter";
import { CustomerInfoType } from "@/models/CustomersModel";
import { mapProductModel } from "./product_adapter";
import { mapShopSettingModel } from "./shop_adapter";
import { PayBoxTransactionType } from "@/models/PayBoxTransactionModel";

export function mapPayPageModel(dto?: PayPageResponse): PayPageModel {
  return {
    bankAccount: mapBankAccountModal(
      dto?.bankAccount satisfies BankAccountResponse | undefined,
    ),
    code: dto?.code ?? "",
    createdAt: dto?.createdAt ?? "",
    createdBy: dto?.createdBy ?? "",
    id: `${dto?.id}` ?? "",
    modifiedAt: dto?.modifiedAt ?? "",
    modifiedBy: dto?.modifiedBy ?? "",
    name: dto?.name ?? "",
    periodType:
      PayPagePeriodType[
        (dto?.periodType ?? "DAILY") as keyof typeof PayPagePeriodType
      ],
    revenue: dto?.revenue ?? 0,
    status: PayPageStatusType[dto?.status as keyof typeof PayPageStatusType],
    totalProducts: dto?.totalProducts ?? 0,
    url: dto?.url ?? "",
    totalSendMailSms: dto?.totalSendMailSms ?? 0,
  };
}

export function mapPayPageModelDetailModel(
  dto?: PayPageDetailResponse,
): PayPageDetailModel {
  return {
    bankAccount: mapBankAccountModal(
      dto?.bankAccount satisfies BankAccountResponse | undefined,
    ),
    code: dto?.code ?? "",
    createdAt: dto?.createdAt ?? "",
    createdBy: dto?.createdBy ?? "",
    id: dto?.id ?? 0,
    modifiedAt: dto?.modifiedAt ?? "",
    modifiedBy: dto?.modifiedBy ?? "",
    name: dto?.name ?? "",
    periodType:
      PayPagePeriodType[
        (dto?.periodType ?? "DAILY") as keyof typeof PayPagePeriodType
      ],
    revenue: dto?.revenue ?? 0,
    status: PayPageStatusType[dto?.status as keyof typeof PayPageStatusType],
    totalProducts: dto?.totalProducts ?? 0,
    url: dto?.url ?? "",
    contactEmail: dto?.contactEmail ?? "",
    contactPhone: dto?.contactPhone ?? "",
    customer: mapCustomermModal(dto?.customer),
    customerInfoForm: mapCustomerInfoFormModal(dto?.customerInfoForm),
    customerInfoType:
      CustomerInfoType[
        (dto?.customerInfoType ?? "NO_REQUIRE") as keyof typeof CustomerInfoType
      ],
    products:
      dto?.products?.map((d) => {
        return mapProductModel(d);
      }) ?? [],
    merchantCode: dto?.merchantCode ? dto?.merchantCode : "",
    merchantName: dto?.merchantName ? dto?.merchantName : "",
    shopSetting: mapShopSettingModel(dto?.shopSetting),
    totalTransactions: dto?.totalTransactions ?? 0,
  };
}

export function mapPayPageOrderModel(dto?: OrderResponse): PayPageOrderModel {
  return {
    actuallyReceived: dto?.actuallyReceived ?? 0,
    amount: dto?.amount ?? 0,
    attempts: dto?.attempts ?? 0,
    createdAt: dto?.createdAt ?? "",
    fee: dto?.fee ?? 0,
    id: dto?.id ?? "",
    referenceId: dto?.referenceId ?? "",
    status:
      PagePayOrderStatusType[
        (dto?.status ?? "CREATED") as keyof typeof PagePayOrderStatusType
      ],
    transactionId: dto?.transactionId ?? "",
  };
}

export function mapPayPageTransactionModel(
  dto?: PayPageTransactionResponse,
): PayPageTransactionModel {
  return {
    amount: dto?.amount ?? 0,
    bankType:
      BankAccountType[(dto?.bankType ?? "KLB") as keyof typeof BankAccountType],
    callbackAfter: dto?.callbackAfter ?? 0,
    completeTime: dto?.completeTime ?? "",
    createDateTime: dto?.createDateTime ?? "",
    customerAddress: dto?.customerAddress ?? "",
    customerEmail: dto?.customerEmail ?? "",
    customerName: dto?.customerName ?? "",
    customerPhone: dto?.customerPhone ?? "",
    depositTime: dto?.depositTime ?? "",
    description: dto?.description ?? "",
    failUrl: dto?.failUrl ?? "",
    id: dto?.id ?? "",
    paymentType:
      PaymentType[(dto?.paymentType ?? "VIET_QR") as keyof typeof PaymentType],
    refTransactionId: dto?.refTransactionId ?? "",
    status:
      TransactionStatusType[
        (dto?.status ?? "NONE") as keyof typeof TransactionStatusType
      ],
    successUrl: dto?.successUrl ?? "",
    virtualAccount: dto?.virtualAccount ?? "",
    accountName: dto?.accountName ?? "",
    accountNo: dto?.accountNo ?? "",
    forControl: dto?.forControl ?? false,
    interBankTrace: dto?.interBankTrace ?? "",
    merchantCode: dto?.merchantCode ?? "",
    merchantId: dto?.merchantId ?? "",
    merchantName: dto?.merchantName ?? "",
    orderId: dto?.orderId ?? "",
    orderStatus:
      PagePayOrderStatusType[
        (dto?.orderStatus ?? "CANCEL") as keyof typeof PagePayOrderStatusType
      ],
    payboxTransactionType:
      PayBoxTransactionType[
        (dto?.payboxTransactionType ??
          "PAYBOX") as keyof typeof PayBoxTransactionType
      ],
    payLinkCode: dto?.payLinkCode ?? "",
    txnNumber: dto?.txnNumber ?? "",
  };
}

export function mapPayPageOrderItem(
  dto?: OrderItemResponse,
): PayPageOrderItemModel {
  return {
    description: dto?.description ?? "",
    id: dto?.id ?? 0,
    imageUrl: dto?.imageUrl ?? "",
    name: dto?.name ?? "",
    price: dto?.price ?? 0,
    quantity: dto?.quantity ?? 0,
  };
}

export function mapPayPageOrderDetail(
  dto?: OrderDetailResponse,
): PayPageOrderDetailModal {
  return {
    actuallyReceived: dto?.actuallyReceived ?? 0,
    amount: dto?.amount ?? 0,
    attempts: dto?.attempts ?? 0,
    createdAt: dto?.createdAt ?? "",
    customerAddress: dto?.customerAddress ?? "",
    customerEmail: dto?.customerEmail ?? "",
    customerName: dto?.customerName ?? "",
    customerPhone: dto?.customerPhone ?? "",
    fee: dto?.fee ?? 0,
    id: dto?.id ?? "",
    items:
      dto?.items?.map((d) => {
        return mapPayPageOrderItem(d);
      }) ?? [],
    referenceId: dto?.referenceId ?? "",
    status:
      PagePayOrderStatusType[
        (dto?.status ?? "CREATED") as keyof typeof PagePayOrderStatusType
      ],
    transaction: mapTransacionResponseModel(dto?.transaction),
    transactionId: dto?.transactionId ?? "",
  };
}
