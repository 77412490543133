import { PosDeviceModelRepositoryImp } from "./kienlong-pay-repositories/implements/PosDeviceModelRepositoryImp";
import { CoreBankRepository } from "./kienlong-pay-repositories/CoreBankRepository";
import { EWalletRepository } from "./kienlong-pay-repositories/EWalletRepository";
import { CoreBankRepositoryImp } from "./kienlong-pay-repositories/implements/CoreBankRepositoryImp";
import { EWalletRepositoryImp } from "./kienlong-pay-repositories/implements/EWalletRepositoryImp";
import {
  AuditControllerApi,
  BankControllerApi,
  FeeControllerApi,
  LoginByQRControllerApi,
  PaymentMethodControllerApi,
  PayPosControllerApi,
  PortalPayInfoApi,
  PortalPayOutControllerApi,
  PortalRoleControllerApi,
  PosOrderControllerApi,
  PosModelControllerApi,
  QunLWebhookApi,
  ShopSettingApi,
  SupportControllerApi,
  ToolControllerApi,
  ForControlControllerApi,
  PaySubscriptionControllerApi,
  PaySubscriptionPlanControllerApi,
  PaySubscriptionInvoiceControllerApi,
  PortalVietQRProApi,
} from "./generated-sources/kienlong-pay/api";
import { AuthReposiroty } from "./kienlong-pay-repositories/AuthRepository";
import { CustomerRepository } from "./kienlong-pay-repositories/CustomerRepository";
import { DashboardRepository } from "./kienlong-pay-repositories/DashboardRepository";
import { EkycRepository } from "./kienlong-pay-repositories/EkycRepository";
import { MerchantReposiroty } from "./kienlong-pay-repositories/MerchantRepository";
import { PaylinkRepository } from "./kienlong-pay-repositories/PaylinkRepository";
import { UserReposiroty } from "./kienlong-pay-repositories/UserRepository";
import { AuthReposirotyImp } from "./kienlong-pay-repositories/implements/AuthRepositoryImp";
import { CustomerRepositoryImp } from "./kienlong-pay-repositories/implements/CustomerRepositoryImp";
import { DashboardRepositoryImp } from "./kienlong-pay-repositories/implements/DashboardRepositoryImp";
import { EkycRepositoryImp } from "./kienlong-pay-repositories/implements/EkycRepositoryImp";
import { MerchantReposirotyImp } from "./kienlong-pay-repositories/implements/MerchantRepositoryImp";
import { PaylinkRepositoryImp } from "./kienlong-pay-repositories/implements/PaylinkRepositoryImp";
import { UserReposirotyImp } from "./kienlong-pay-repositories/implements/UserRepositoryImp";
import { TransactionRepository } from "./kienlong-pay-repositories/TransactionRepository";
import { TransactionRepositoryImp } from "./kienlong-pay-repositories/implements/TransactionRepositoryImp";
import {
  AuthControllerApi,
  Configuration,
  ConfigurationParameters,
  CustomerControllerApi,
  EkycControllerApi,
  MerchantControllerApi,
  PaylinkControllerApi,
  PayPageControllerApi,
  PortalDashboardControllerApi,
  PORTALSettingControllerApi,
  UserControllerApi,
  PortalPayPageOrderControllerApi,
  PortalProductControllerApi,
  PortalTransactionControllerApi,
  BankAccountControllerApi,
  CoreBankControllerApi,
  EWalletControllerApi,
  PayButtonControllerApi,
} from "./generated-sources/kienlong-pay";
import { PayPageRepository } from "./kienlong-pay-repositories/PayPageRepository";
import { PayPageRepositoryImp } from "./kienlong-pay-repositories/implements/PayPageRepositoryImp";
import { SettingRepository } from "./kienlong-pay-repositories/SettingRepository";
import { SettingRepositoryImp } from "./kienlong-pay-repositories/implements/SettingRepositoryImp";
import { PayPageDashBoardRepository } from "./kienlong-pay-repositories/PayPageDashboardRepository";
import { PayPageDashboardRepositoryImp } from "./kienlong-pay-repositories/implements/PayPageDashboardRepositoryImp";
import { ProductRepository } from "./kienlong-pay-repositories/ProductRepository";
import { ProductRepositoryImp } from "./kienlong-pay-repositories/implements/ProductRepositoryImp";
import { BankAccountRepository } from "./kienlong-pay-repositories/BankAccountRepository";
import { BankAccountRepositoryImp } from "./kienlong-pay-repositories/implements/BankAccountRepositoryImp";
import { AuditRepository } from "./kienlong-pay-repositories/AuditRepository";
import { AuditRepositoryImp } from "./kienlong-pay-repositories/implements/AuditRepositoryImp";
import { BankRepository } from "./kienlong-pay-repositories/BankRepository";
import { BankRepositoryImp } from "./kienlong-pay-repositories/implements/BankRepositoryImp";
import { PayInfoRepository } from "./kienlong-pay-repositories/PayInfoRepository";
import { PayInfoRepositoryImp } from "./kienlong-pay-repositories/implements/PayInfoRepositoryImp";
import { LoginByQrRepository } from "./kienlong-pay-repositories/LoginByQrRepository";
import { LoginByQrRepositoryImp } from "./kienlong-pay-repositories/implements/LoginByQrRepositoryImp";
import { PayoutRepository } from "./kienlong-pay-repositories/PayoutRepository";
import { PayoutRepositoryImp } from "./kienlong-pay-repositories/implements/PayoutRepositoryImp";
import { RoleRepository } from "./kienlong-pay-repositories/RoleRepository";
import { RoleRepositoryImp } from "./kienlong-pay-repositories/implements/RoleRepositoryImp";
import { PayButtonRepository } from "./kienlong-pay-repositories/PayButtonRepository";
import { PayButtonRepositoryImp } from "./kienlong-pay-repositories/implements/PayButtonRepositoryImp";
import { PayPosRepository } from "./kienlong-pay-repositories/PayPosRepository";
import { PayPosRepositoryImp } from "./kienlong-pay-repositories/implements/PayPosRepositoryImp";
import { ShopSettingRepository } from "./kienlong-pay-repositories/ShopSettingRepository";
import { ShopSettingRepositoryImp } from "./kienlong-pay-repositories/implements/ShopSettingRepositoryImp";
import { PaymentMethodRepository } from "./kienlong-pay-repositories/PaymentMethodRepository";
import { PaymentMethodRepositoryImp } from "./kienlong-pay-repositories/implements/PaymentMethodRepositoryImp";
import { ToolRepository } from "./kienlong-pay-repositories/ToolRepository";
import { ToolRepositoryImp } from "./kienlong-pay-repositories/implements/ToolRepositoryImp";
import { SupportRepository } from "./kienlong-pay-repositories/SupportRepository";
import { SupportRepositoryImp } from "./kienlong-pay-repositories/implements/SupportRepositoryImp";
import { Webhookepository } from "./kienlong-pay-repositories/WebhookRepository";
import { WebhookRepositoyImp } from "./kienlong-pay-repositories/implements/WebhookRepositoryImp";
import { FeeReportRepository } from "./kienlong-pay-repositories/FeeReportRepository";
import { FeeReportRepositoryImp } from "./kienlong-pay-repositories/implements/FeeReportRepositoryImp";
import { PosOderRepository } from "./kienlong-pay-repositories/PosOrderRepository";
import { PosOderRepositoryImp } from "./kienlong-pay-repositories/implements/PosOrderRepositoryImp";
import { PosDeviceModelRepository } from "./kienlong-pay-repositories/PosDeviceModelRepository";
import { ForcontrolRepository } from "./kienlong-pay-repositories/ForcontrolRepository";
import { ForControlRepositoryImp } from "./kienlong-pay-repositories/implements/ForcontrolRepositoryImp";
import { PaySubscriptionRepository } from "./kienlong-pay-repositories/PaySubscriptionRepository";
import { PaySubscriptionRepositoryImp } from "./kienlong-pay-repositories/implements/PaySubscriptionRepositoryImp";
import { PaySubscriptionPlanRepository } from "./kienlong-pay-repositories/PaySubscriptionPlanRepository";
import { PaySubscriptionPlanRepositoryImp } from "./kienlong-pay-repositories/implements/PaySubscriptionPlanRepositoryImp";
import { PaySubscriptionInvoiceRepository } from "./kienlong-pay-repositories/PaySubscriptionInvoiceRepository";
import { PaySubscriptionInvoiceRepositoryImp } from "./kienlong-pay-repositories/implements/PaySubscriptionInvoiceRepositoryImp";
import { Environment } from "@/services/environment";
import { VietQrProRepository } from "./kienlong-pay-repositories/VietQrProRepository";
import { VietQrProRepositoryImp } from "./kienlong-pay-repositories/implements/VietQrProRepositoryImp";

export class KienlongPayClient {
  private config: Configuration;

  public readonly authController: AuthControllerApi;
  public readonly userController: UserControllerApi;
  public readonly ekycController: EkycControllerApi;
  public readonly merchantController: MerchantControllerApi;
  public readonly payLinkController: PaylinkControllerApi;
  public readonly portalDashboardController: PortalDashboardControllerApi;
  public readonly customerController: CustomerControllerApi;
  public readonly portalTransactionController: PortalTransactionControllerApi;
  public readonly portalSettingControleller: PORTALSettingControllerApi;
  public readonly payPageController: PayPageControllerApi;
  public readonly payPageDashBoardController: PortalPayPageOrderControllerApi;
  public readonly portalProductController: PortalProductControllerApi;
  public readonly bankAccountController: BankAccountControllerApi;
  public readonly transactionController: PortalTransactionControllerApi;
  public readonly settingController: PORTALSettingControllerApi;
  public readonly portalRoleController: PortalRoleControllerApi;
  public readonly portalPayInfo: PortalPayInfoApi;
  public readonly auditController: AuditControllerApi;
  public readonly bankController: BankControllerApi;
  public readonly loginByQRController: LoginByQRControllerApi;
  public readonly payoutController: PortalPayOutControllerApi;
  public readonly roleController: PortalRoleControllerApi;
  public readonly eWalletController: EWalletControllerApi;
  public readonly payButtonController: PayButtonControllerApi;
  public readonly coreBankController: CoreBankControllerApi;
  public readonly payPosController: PayPosControllerApi;
  public readonly shopSettingController: ShopSettingApi;
  public readonly paymentMethodController: PaymentMethodControllerApi;
  public readonly toolController: ToolControllerApi;
  public readonly supportController: SupportControllerApi;
  public readonly webhookController: QunLWebhookApi;
  public readonly feeReportController: FeeControllerApi;
  public readonly posOrderController: PosOrderControllerApi;
  public readonly posDeviceModelController: PosModelControllerApi;
  public readonly forControl: ForControlControllerApi;
  public readonly paySubscription: PaySubscriptionControllerApi;
  public readonly paySubscriptionPlan: PaySubscriptionPlanControllerApi;
  public readonly paySubscriptionInvoice: PaySubscriptionInvoiceControllerApi;
  public readonly vietQrPro: PortalVietQRProApi;

  constructor(cf: Configuration) {
    this.config = cf;
    this.authController = new AuthControllerApi(cf, cf.basePath);
    this.userController = new UserControllerApi(cf, cf.basePath);
    this.ekycController = new EkycControllerApi(cf, cf.basePath);
    this.merchantController = new MerchantControllerApi(cf, cf.basePath);
    this.payLinkController = new PaylinkControllerApi(cf, cf.basePath);
    this.portalDashboardController = new PortalDashboardControllerApi(
      cf,
      cf.basePath,
    );
    this.customerController = new CustomerControllerApi(cf, cf.basePath);
    this.portalTransactionController = new PortalTransactionControllerApi(
      cf,
      cf.basePath,
    );
    this.portalSettingControleller = new PORTALSettingControllerApi(
      cf,
      cf.basePath,
    );
    this.payPageController = new PayPageControllerApi(cf, cf.basePath);
    this.payPageDashBoardController = new PortalPayPageOrderControllerApi(
      cf,
      cf.basePath,
    );
    this.portalProductController = new PortalProductControllerApi(
      cf,
      cf.basePath,
    );
    this.bankAccountController = new BankAccountControllerApi(cf, cf.basePath);
    this.transactionController = new PortalTransactionControllerApi(
      cf,
      cf.basePath,
    );
    this.settingController = new PORTALSettingControllerApi(cf, cf.basePath);
    this.payPageController = new PayPageControllerApi(cf, cf.basePath);
    this.portalRoleController = new PortalRoleControllerApi(cf, cf.basePath);
    this.portalPayInfo = new PortalPayInfoApi(cf, cf.basePath);
    this.auditController = new AuditControllerApi(cf, cf.basePath);
    this.bankController = new BankControllerApi(cf, cf.baseOptions);
    this.loginByQRController = new LoginByQRControllerApi(cf, cf.basePath);
    this.payoutController = new PortalPayOutControllerApi(cf, cf.basePath);
    this.roleController = new PortalRoleControllerApi(cf, cf.basePath);
    this.eWalletController = new EWalletControllerApi(cf, cf.basePath);
    this.coreBankController = new CoreBankControllerApi(cf, cf.basePath);
    this.payButtonController = new PayButtonControllerApi(cf, cf.basePath);
    this.payPosController = new PayPosControllerApi(cf, cf.basePath);
    this.shopSettingController = new ShopSettingApi(cf, cf.basePath);
    this.paymentMethodController = new PaymentMethodControllerApi(
      cf,
      cf.basePath,
    );
    this.toolController = new ToolControllerApi(cf, cf.basePath);
    this.supportController = new SupportControllerApi(cf, cf.basePath);
    this.webhookController = new QunLWebhookApi(cf, cf.basePath);
    this.feeReportController = new FeeControllerApi(cf, cf.basePath);
    this.feeReportController = new FeeControllerApi(cf, cf.basePath);
    this.posOrderController = new PosOrderControllerApi(cf, cf.basePath);
    this.posDeviceModelController = new PosModelControllerApi(cf, cf.basePath);
    this.forControl = new ForControlControllerApi(cf, cf.basePath);
    this.paySubscription = new PaySubscriptionControllerApi(cf, cf.basePath);
    this.paySubscriptionPlan = new PaySubscriptionPlanControllerApi(
      cf,
      cf.basePath,
    );
    this.paySubscriptionInvoice = new PaySubscriptionInvoiceControllerApi(
      cf,
      cf.basePath,
    );
    this.vietQrPro = new PortalVietQRProApi(cf, cf.basePath);
  }
}

export const kienlongPayConfig: ConfigurationParameters = {
  basePath: Environment.hostApiKlbPay,
};

export class KienlongPayHttpClient {
  public readonly userRepository: UserReposiroty;
  public readonly authRepository: AuthReposiroty;
  public readonly ekycRepository: EkycRepository;
  public readonly merchantRepository: MerchantReposiroty;
  public readonly transactionRepository: TransactionRepository;
  public readonly payLinkRepository: PaylinkRepository;
  public readonly dashboardRepository: DashboardRepository;
  public readonly customerRepository: CustomerRepository;
  public readonly payPageRepository: PayPageRepository;
  public readonly settingRepository: SettingRepository;
  public readonly payPageDashBoardRepository: PayPageDashBoardRepository;
  public readonly productRepository: ProductRepository;
  public readonly bankAccountRepository: BankAccountRepository;
  public readonly auditRepository: AuditRepository;
  public readonly bankRepository: BankRepository;
  public readonly payInfoRepository: PayInfoRepository;
  public readonly loginByQrRepository: LoginByQrRepository;
  public readonly payoutRepository: PayoutRepository;
  public readonly roleRepository: RoleRepository;
  public readonly eWalletRepository: EWalletRepository;
  public readonly coreBankRepository: CoreBankRepository;
  public readonly payButtonRepository: PayButtonRepository;
  public readonly payPosRepository: PayPosRepository;
  public readonly shopSettingRepository: ShopSettingRepository;
  public readonly paymentMethodRepository: PaymentMethodRepository;
  public readonly toolRepository: ToolRepository;
  public readonly supportRepository: SupportRepository;
  public readonly webhookRepository: Webhookepository;
  public readonly feeReportRepository: FeeReportRepository;
  public readonly posOrderRepository: PosOderRepository;
  public readonly posDeviceModelRepository: PosDeviceModelRepository;
  public readonly forControlRepository: ForcontrolRepository;
  public readonly paySubscriptionRepository: PaySubscriptionRepository;
  public readonly paySubscriptionPlanRepository: PaySubscriptionPlanRepository;
  public readonly paySubscriptionInvoiceRepository: PaySubscriptionInvoiceRepository;
  public readonly vietQrProRepository: VietQrProRepository;

  private static kienlongPayClient: KienlongPayClient;

  constructor(basePath?: string) {
    if (basePath) {
      kienlongPayConfig.basePath = basePath;
    }
    KienlongPayHttpClient.kienlongPayClient = new KienlongPayClient(
      new Configuration(kienlongPayConfig),
    );
    this.userRepository = new UserReposirotyImp(
      KienlongPayHttpClient.kienlongPayClient,
    );
    this.authRepository = new AuthReposirotyImp(
      KienlongPayHttpClient.kienlongPayClient,
    );

    this.ekycRepository = new EkycRepositoryImp(
      KienlongPayHttpClient.kienlongPayClient,
    );
    this.merchantRepository = new MerchantReposirotyImp(
      KienlongPayHttpClient.kienlongPayClient,
    );
    this.transactionRepository = new TransactionRepositoryImp(
      KienlongPayHttpClient.kienlongPayClient,
    );
    this.payLinkRepository = new PaylinkRepositoryImp(
      KienlongPayHttpClient.kienlongPayClient,
    );
    this.dashboardRepository = new DashboardRepositoryImp(
      KienlongPayHttpClient.kienlongPayClient,
    );
    this.customerRepository = new CustomerRepositoryImp(
      KienlongPayHttpClient.kienlongPayClient,
    );
    this.payPageRepository = new PayPageRepositoryImp(
      KienlongPayHttpClient.kienlongPayClient,
    );
    this.settingRepository = new SettingRepositoryImp(
      KienlongPayHttpClient.kienlongPayClient,
    );
    this.payPageDashBoardRepository = new PayPageDashboardRepositoryImp(
      KienlongPayHttpClient.kienlongPayClient,
    );
    this.productRepository = new ProductRepositoryImp(
      KienlongPayHttpClient.kienlongPayClient,
    );
    this.bankAccountRepository = new BankAccountRepositoryImp(
      KienlongPayHttpClient.kienlongPayClient,
    );
    this.auditRepository = new AuditRepositoryImp(
      KienlongPayHttpClient.kienlongPayClient,
    );
    this.bankRepository = new BankRepositoryImp(
      KienlongPayHttpClient.kienlongPayClient,
    );
    this.payInfoRepository = new PayInfoRepositoryImp(
      KienlongPayHttpClient.kienlongPayClient,
    );
    this.loginByQrRepository = new LoginByQrRepositoryImp(
      KienlongPayHttpClient.kienlongPayClient,
    );
    this.payoutRepository = new PayoutRepositoryImp(
      KienlongPayHttpClient.kienlongPayClient,
    );
    this.roleRepository = new RoleRepositoryImp(
      KienlongPayHttpClient.kienlongPayClient,
    );
    this.eWalletRepository = new EWalletRepositoryImp(
      KienlongPayHttpClient.kienlongPayClient,
    );
    this.coreBankRepository = new CoreBankRepositoryImp(
      KienlongPayHttpClient.kienlongPayClient,
    );

    this.payButtonRepository = new PayButtonRepositoryImp(
      KienlongPayHttpClient.kienlongPayClient,
    );
    this.payPosRepository = new PayPosRepositoryImp(
      KienlongPayHttpClient.kienlongPayClient,
    );
    this.shopSettingRepository = new ShopSettingRepositoryImp(
      KienlongPayHttpClient.kienlongPayClient,
    );
    this.paymentMethodRepository = new PaymentMethodRepositoryImp(
      KienlongPayHttpClient.kienlongPayClient,
    );
    this.toolRepository = new ToolRepositoryImp(
      KienlongPayHttpClient.kienlongPayClient,
    );
    this.supportRepository = new SupportRepositoryImp(
      KienlongPayHttpClient.kienlongPayClient,
    );
    this.webhookRepository = new WebhookRepositoyImp(
      KienlongPayHttpClient.kienlongPayClient,
    );
    this.feeReportRepository = new FeeReportRepositoryImp(
      KienlongPayHttpClient.kienlongPayClient,
    );
    this.posOrderRepository = new PosOderRepositoryImp(
      KienlongPayHttpClient.kienlongPayClient,
    );
    this.posDeviceModelRepository = new PosDeviceModelRepositoryImp(
      KienlongPayHttpClient.kienlongPayClient,
    );
    this.forControlRepository = new ForControlRepositoryImp(
      KienlongPayHttpClient.kienlongPayClient,
    );
    this.paySubscriptionRepository = new PaySubscriptionRepositoryImp(
      KienlongPayHttpClient.kienlongPayClient,
    );
    this.paySubscriptionPlanRepository = new PaySubscriptionPlanRepositoryImp(
      KienlongPayHttpClient.kienlongPayClient,
    );
    this.paySubscriptionInvoiceRepository =
      new PaySubscriptionInvoiceRepositoryImp(
        KienlongPayHttpClient.kienlongPayClient,
      );
    this.vietQrProRepository = new VietQrProRepositoryImp(
      KienlongPayHttpClient.kienlongPayClient,
    );
  }
}
