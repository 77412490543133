import {
  mapPermissionGroupModel,
  mapPermissionModel,
} from "@/adapters/permission_adapter";
import { mapRoleGroupDetail } from "@/adapters/user_adapter";
import { KienlongPayClient } from "@/api/kienlong_pay_http_client";
import { ErrorModel } from "@/models/ErrorModel";
import {
  PermissionGroupModel,
  PermissionModel,
} from "@/models/PermissionModel";
import { RoleGroupsDetailModel } from "@/models/RoleGroupModel";
import { RoleRepository } from "../RoleRepository";
import {
  Configuration,
  PortalRoleControllerApi,
} from "@/api/generated-sources/kienlong-pay";
import { Environment } from "@/services/environment";

export class RoleRepositoryImp implements RoleRepository {
  private client: KienlongPayClient;
  constructor(kienlongPayClient: KienlongPayClient) {
    this.client = kienlongPayClient;
  }

  public async getAllRoleGroups(): Promise<RoleGroupsDetailModel[]> {
    try {
      let resp =
        await this.client.portalRoleController.getAllPortalRoleGroups();
      if (resp.data.code === 0) {
        return (
          resp.data?.data?.roleGroups?.map((roleGroup) =>
            mapRoleGroupDetail(roleGroup),
          ) ?? []
        );
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getAllPermissions(): Promise<PermissionGroupModel[]> {
    try {
      let resp =
        await this.client.portalRoleController.getAllPortalPermissions();
      if (resp.data.code === 0) {
        return (
          resp.data?.data?.permissionGroups?.map((permissionGroup) =>
            mapPermissionGroupModel(permissionGroup),
          ) ?? []
        );
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async listCurrentPermission(): Promise<PermissionModel[]> {
    let _client = new PortalRoleControllerApi(
      new Configuration({
        basePath: Environment.hostApiKlbPay,
      }),
    );
    try {
      let resp = await _client.getCurrentPermission();
      if (resp.data.code === 0) {
        return (
          resp.data?.data?.actions?.map((permissionGroup) =>
            mapPermissionModel(permissionGroup),
          ) ?? []
        );
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
  public async allReferencePermission(): Promise<PermissionModel[]> {
    try {
      let resp =
        await this.client.portalRoleController.getAllPortalPermissionActions();
      if (resp.data.code === 0) {
        return (
          resp.data?.data?.permissions?.map((permissionGroup) =>
            mapPermissionModel(permissionGroup),
          ) ?? []
        );
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
