import { mapAccessTokenModel } from "@/adapters/token_adapter";
import {
  AuthControllerApi,
  Configuration,
} from "@/api/generated-sources/kienlong-pay";
import { KienlongPayClient } from "@/api/kienlong_pay_http_client";
import { AccessTokenCustomModel } from "@/models/AccesstokenCustomModal";
import { ErrorModel } from "@/models/ErrorModel";
import { hashString } from "@/utils/hashString";
import { AuthReposiroty } from "../AuthRepository";
import { Environment } from "@/services/environment";

export class AuthReposirotyImp implements AuthReposiroty {
  private client: KienlongPayClient;
  constructor(kienlongPayClient: KienlongPayClient) {
    this.client = kienlongPayClient;
  }

  // public async login({
  //   username,
  //   password,
  // }: {
  //   username: string;
  //   password: string;
  // }): Promise<AccessTokenCustomModel> {
  //   let _client = new AuthControllerApi(
  //     new Configuration({
  //       basePath: Environment.hostApiKlbPay,
  //     }),
  //   );
  //   try {
  //     let resp = await _client.userLoginV21({
  //       loginRequest: {
  //         username: username,
  //         password: hashString(password),
  //       },
  //     });
  //     if (resp.data.code === 0) {
  //       return mapAccessTokenModel(resp.data.data);
  //     } else {
  //       throw new ErrorModel({
  //         code: resp.data.code,
  //         message: resp.data.message,
  //       });
  //     }
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  public async loginOTP({
    username,
    password,
  }: {
    username: string;
    password: string;
  }): Promise<{
    timeout: number;
    sessionId: string;
  }> {
    try {
      let _client = new AuthControllerApi(
        new Configuration({
          basePath: Environment.hostApiKlbPay,
        }),
      );
      let resp = await _client.userLoginV2({
        portalLoginRequest: {
          phone: username,
          password: hashString(password),
        },
      });
      if (resp.data.code === 0) {
        return {
          timeout: resp.data?.data?.timeout ?? 0,
          sessionId: resp.data?.data?.sessionId ?? "",
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async verifyOTP({
    phone,
    password,
    sessionId,
    otp,
  }: {
    phone: string;
    password: string;
    sessionId: string;
    otp: string;
  }): Promise<AccessTokenCustomModel> {
    let _client = new AuthControllerApi(
      new Configuration({
        basePath: Environment.hostApiKlbPay,
      }),
    );
    try {
      let resp = await _client.userLoginCheckOTPV2({
        portalVerifyOTPLoginRequest: {
          phone,
          password: hashString(password),
          sessionId,
          otp,
        },
      });
      if (resp.data.code === 0) {
        return mapAccessTokenModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async refreshToken({
    refreshToken,
  }: {
    refreshToken: string;
  }): Promise<AccessTokenCustomModel> {
    try {
      let _client = new AuthControllerApi(
        new Configuration({
          basePath: Environment.hostApiKlbPay,
        }),
      );
      let resp = await _client.refreshToken({
        portalRefreshTokenRequest: { refreshToken: refreshToken },
      });
      if (resp.data.code === 0) {
        return mapAccessTokenModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async logout({
    refreshToken,
  }: {
    refreshToken: string;
  }): Promise<boolean> {
    try {
      let _client = new AuthControllerApi(
        new Configuration({
          basePath: Environment.hostApiKlbPay,
        }),
      );
      let resp = await _client.logout({
        logoutRequest: {
          refreshToken: refreshToken,
        },
      });
      if (resp.data.code === 0) {
        return resp.data?.data?.logout ?? false;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async memberLogin({
    username,
    password,
    merchantCode,
  }: {
    username: string;
    password: string;
    merchantCode: string;
  }): Promise<{
    timeout: number;
    sessionId: string;
  }> {
    try {
      let _client = new AuthControllerApi(
        new Configuration({
          basePath: Environment.hostApiKlbPay,
        }),
      );
      let resp = await _client.portalMemberLogin({
        portalMemberLoginRequest: {
          merchantCode: merchantCode,
          phone: username,
          password: hashString(password),
        },
      });
      if (resp.data.code === 0) {
        return {
          timeout: resp.data?.data?.timeout ?? 0,
          sessionId: resp.data?.data?.sessionId ?? "",
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async verifyOTPMemberLogin({
    phone,
    password,
    sessionId,
    otp,
    merchantCode,
  }: {
    phone: string;
    password: string;
    sessionId: string;
    otp: string;
    merchantCode: string;
  }): Promise<AccessTokenCustomModel> {
    let _client = new AuthControllerApi(
      new Configuration({
        basePath: Environment.hostApiKlbPay,
      }),
    );
    try {
      let resp = await _client.portalMemberLoginCheckOTP({
        portalMemberVerifyOTPLoginRequest: {
          phone: phone,
          password: hashString(password),
          sessionId: sessionId,
          otp: otp,
          merchantCode: merchantCode,
        },
      });
      if (resp.data.code === 0) {
        return mapAccessTokenModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async memberGetOtpResetPass({
    phone,
    merchantCode,
  }: {
    phone: string;
    merchantCode: string;
  }): Promise<{
    timeout: number;
    sessionId: string;
  }> {
    try {
      let _client = new AuthControllerApi(
        new Configuration({
          basePath: Environment.hostApiKlbPay,
        }),
      );
      let resp = await _client.portalMemberResetPassGetOtp({
        portalMemberResetPassGetOTPRequest: {
          merchantCode: merchantCode,
          phone: phone,
        },
      });
      if (resp.data.code === 0) {
        return {
          timeout: resp.data?.data?.timeout ?? 0,
          sessionId: resp.data?.data?.sessionId ?? "",
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async memberVerifyOtpResetPass({
    merchantCode,
    otp,
    phone,
    sessionId,
  }: {
    merchantCode: string;
    otp: string;
    phone: string;
    sessionId: string;
  }): Promise<{
    timeout: number;
    sessionId: string;
    token: string;
  }> {
    try {
      let _client = new AuthControllerApi(
        new Configuration({
          basePath: Environment.hostApiKlbPay,
        }),
      );
      let resp = await _client.portalMemberResetPassVerifyOtp({
        portalMemberResetPassVerifyOTPRequest: {
          merchantCode: merchantCode,
          otp: otp,
          phone: phone,
          sessionId: sessionId,
        },
      });
      if (resp.data.code === 0) {
        return {
          timeout: resp.data?.data?.timeout ?? 0,
          sessionId: resp.data?.data?.sessionId ?? "",
          token: resp.data?.data?.token ?? "",
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async memberResetPass({
    merchantCode,
    password,
    phone,
    sessionId,
    token,
  }: {
    merchantCode: string;
    password: string;
    phone: string;
    sessionId: string;
    token: string;
  }): Promise<boolean> {
    try {
      let _client = new AuthControllerApi(
        new Configuration({
          basePath: Environment.hostApiKlbPay,
        }),
      );
      let resp = await _client.portalMemberResetPass({
        portalMemberResetPassRequest: {
          merchantCode: merchantCode,
          password: hashString(password),
          phone: phone,
          sessionId: sessionId,
          token: token,
        },
      });
      if (resp.data.code === 0) {
        return resp.data.data?.success ?? false;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
