import { PayBoxTransactionType } from "@/models/PayBoxTransactionModel";
import { BankAccountType } from "./BankAccountModel";
import { PaymentType } from "./PaylinkModel";

export interface TransactionResponseModel {
  id: string;
  status: TransactionStatusType;
  amount: number;
  refTransactionId: string;
  createDateTime: string;
  depositTime: string;
  completeTime: string;
  virtualAccount: string;
  description: string;
  paymentType: PaymentType;
  bankType: BankAccountType;
  successUrl: string;
  failUrl: string;
  callbackAfter: number;
  payLinkCode: string;
  merchantId: string;
  merchantCode: string;
  merchantName: string;
  customerName: string;
  customerPhone: string;
  customerEmail: string;
  customerAddress: string;
  txnNumber: string;
  payboxTransactionType: PayBoxTransactionType;
  accountName: string;
  accountNo: string;
  forControl: boolean;
  interBankTrace: string;
  payPageCode: string;
  chargeAmount: number;
  vatChargeAmount: number;
  actualAmount: number;
}

export enum TransactionStatusType {
  NONE = "NONE",
  CREATED = "CREATED",
  SUCCESS = "SUCCESS",
  CANCELED = "CANCELED",
  FAIL = "FAIL",
  TIMEOUT = "TIMEOUT",
  PENDING = "PENDING",
}

export enum PeriodBankTransaction {
  DAY_7 = "DAY_7",
  MONTH_1 = "MONTH_1",
  MONTH_2 = "MONTH_2",
  MONTH_4 = "MONTH_4",
}

export enum TransactionType {
  PAY_LINK = "PAY_LINK",
  PAY_PAGE = "PAY_PAGE",
  PAY_BUTTON = "PAY_BUTTON",
  PAY_BOX = "PAY_BOX",
  PAY_POS = "PAY_POS",
  PAY_SUBSCRIPTION = "PAY_SUBSCRIPTION",
  PAY_OUT = "PAY_OUT",
}

export enum TransactionTypeView {
  ALL = "ALL",
  PAY_LINK = "PAY_LINK",
  PAY_PAGE = "PAY_PAGE",
  PAY_BUTTON = "PAY_BUTTON",
  PAY_BOX = "PAY_BOX",
  PAY_POS = "PAY_POS",
  PAY_SUBSCRIPTION = "PAY_SUBSCRIPTION",
  PAY_OUT = "PAY_OUT",
}

export enum TransactionPaymentTypeView {
  ALL = "ALL",
  VIET_QR = "VIET_QR",
  ATM_CARD = "ATM_CARD",
  BANKING = "BANKING",
}

export enum StatisticTransactionTypeView {
  ALL = "ALL",
  PAY_LINK = "PAY_LINK",
  PAY_PAGE = "PAY_PAGE",
  PAY_BUTTON = "PAY_BUTTON",
  PAY_BOX = "PAY_BOX",
  PAY_POS = "PAY_POS",
  PAY_SUBSCRIPTION = "PAY_SUBSCRIPTION",
  PAY_OUT = "PAY_OUT",
}

export enum TransactionStatusStatistical {
  SUCCESS = "SUCCESS",
  FAIL = "FAIL",
}
