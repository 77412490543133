import { PaySubscriptionInvoiceResponse } from "@/api/generated-sources/kienlong-pay";
import {
  PaySubscriptionInvoiceModel,
  PaySubscriptionInvoiceStatus,
} from "@/models/PaySubscriptionInvoiceModel";
import { mapCustomermModal } from "./customers_adapter";
import { mapPaySubscriptionPlanModel } from "./pay_subscription_plan_adapter";
import { PaymentType } from "@/models/PaylinkModel";

export function mapPaySubscriptionInvoiceModel(
  dto?: PaySubscriptionInvoiceResponse | undefined,
): PaySubscriptionInvoiceModel {
  return {
    id: dto?.id ?? "",
    status:
      PaySubscriptionInvoiceStatus[
        dto?.status ??
          ("CANCELLED" as keyof typeof PaySubscriptionInvoiceStatus)
      ],
    plan: mapPaySubscriptionPlanModel(dto?.plan),
    customer: mapCustomermModal(dto?.customer),
    subscriptionId: dto?.subscriptionId ?? "",
    txnNumber: dto?.txnNumber ?? "",
    amount: dto?.amount ?? 0,
    paymentDate: dto?.paymentDate ?? "",
    expiredDate: dto?.expiredDate ?? "",
    url: dto?.url ?? "",
    paymentType:
      PaymentType[dto?.paymentType ?? ("VIET_QR" as keyof typeof PaymentType)],
  };
}
