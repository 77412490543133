import { PaySubscriptionResponse } from "@/api/generated-sources/kienlong-pay";
import {
  PaySubscriptionModel,
  PaySubscriptionStatus,
} from "@/models/PaySubscriptionModel";
import { mapCustomermModal } from "./customers_adapter";
import { mapPaySubscriptionPlanModel } from "./pay_subscription_plan_adapter";
import { TimeType } from "@/models/TimeModel";

export function mapPaySubscriptionModel(
  dto?: PaySubscriptionResponse | undefined,
): PaySubscriptionModel {
  return {
    id: dto?.id ?? "",
    status:
      PaySubscriptionStatus[
        dto?.status ?? ("CANCELLED" as keyof typeof PaySubscriptionStatus)
      ],
    code: dto?.code ?? "",
    plan: mapPaySubscriptionPlanModel(dto?.plan),
    customer: mapCustomermModal(dto?.customer),
    createdAt: dto?.createdAt ?? "",
    numOfCycles: dto?.numOfCycles ?? 0,
    numOfPayments: dto?.numOfPayments ?? 0,
    prePaymentDate: dto?.prePaymentDate ?? "",
    nextPaymentDate: dto?.nextPaymentDate ?? "",
    trialTimeValue: dto?.trialTimeValue ?? 0,
    trialTimeType:
      TimeType[dto?.trialTimeType ?? ("DAY" as keyof typeof TimeType)],
    allowTrial: dto?.allowTrial ?? false,
  };
}
