import "./popup-custom-2.css";
interface PopupCustom2Props {
  img?: any;
  title?: string;
  subTitle?: string;
  labelCancel?: string;
  labelConfirm?: string;
  labelDelete?: string;
  isFullWidthBtn?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
  onDelete?: () => void;
  styleImg?: React.CSSProperties;
}

export const PopupCustom2 = ({
  img,
  title,
  subTitle,
  labelCancel,
  labelConfirm,
  labelDelete,
  isFullWidthBtn = true,
  onConfirm,
  onCancel,
  onDelete,
  styleImg,
}: PopupCustom2Props) => {
  return (
    <div className="wrap-popup-custom-2">
      {/* Content  */}
      <div className="column" style={{ gap: "16px", width: "100%" }}>
        {/* Img Popup  */}
        {img ? <img src={img} alt="img-popup" style={styleImg} /> : null}

        {/* Title Popup  */}
        <div className="column" style={{ gap: "4px", width: "100%" }}>
          <span className="wrap-popup-custom-2__title">{title}</span>
          {/* Sub Title  */}
          <span className="wrap-popup-custom-2__subTitle">{subTitle}</span>
        </div>
      </div>
      {/* Button  */}
      <div
        className="row"
        style={{
          width: "100%",
          gap: "8px",
          paddingBottom: "16px",
        }}
      >
        {/* Button Cancel  */}
        {labelCancel ? (
          <button
            className="button-3 button-cancel"
            onClick={() => {
              if (onCancel) {
                onCancel();
              }
            }}
            style={{ width: isFullWidthBtn ? "100%" : undefined }}
          >
            <span>{labelCancel}</span>
          </button>
        ) : null}
        {/* Button Delete  */}
        {labelDelete ? (
          <button
            className="button-3 button-delete"
            onClick={() => {
              if (onDelete) {
                onDelete();
                if (onCancel) {
                  onCancel();
                }
              }
            }}
            style={{ width: isFullWidthBtn ? "100%" : undefined }}
          >
            <span>{labelDelete}</span>
          </button>
        ) : null}
        {/* Button Confirm  */}
        {labelConfirm ? (
          <button
            className="button-3 button-confirm"
            onClick={() => {
              if (onConfirm) {
                onConfirm();
                if (onCancel) {
                  onCancel();
                }
              }
            }}
            style={{ width: isFullWidthBtn ? "100%" : undefined }}
          >
            <span>{labelConfirm}</span>
          </button>
        ) : null}
      </div>
    </div>
  );
};
