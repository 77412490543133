import { ProductResponse } from "@/api/generated-sources/kienlong-pay";
import {
  ProductModel,
  ProductPriceType,
  ProductStatus,
  ProductStockStatus,
  ProductStorageType,
} from "@/models/ProductModal";

export function mapProductModel(
  dto: ProductResponse | undefined,
): ProductModel {
  return {
    categoryId: dto?.categoryId ?? -1,
    categoryName: dto?.categoryName ?? "",
    createdAt: dto?.createdAt ?? "",
    createdBy: dto?.createdBy ?? "",
    description: dto?.description ?? "",
    id: `${dto?.id}` ?? "",
    imageUrl: dto?.imageUrl ?? "",
    modifiedAt: dto?.modifiedAt ?? "",
    modifiedBy: dto?.modifiedBy ?? "",
    name: dto?.name ?? "",
    price: dto?.price ?? 0,
    priceType:
      ProductPriceType[dto?.priceType as keyof typeof ProductPriceType],
    revenue: dto?.revenue ?? 0,
    sold: dto?.sold ?? 0,
    storageQuantity: dto?.storageQuantity ?? 0,
    storageType:
      ProductStorageType[
        (dto?.storageType ?? "LIMITED") as keyof typeof ProductStorageType
      ],
    status:
      ProductStatus[dto?.status ?? ("INACTIVE" as keyof typeof ProductStatus)],
    stockStatus:
      ProductStockStatus[
        dto?.stockStatus ?? ("PENDING" as keyof typeof ProductStockStatus)
      ],
    code: dto?.code ?? "",
    hasPayPage: dto?.hasPayPage ?? false,
  };
}
