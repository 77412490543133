import { AuditDataModel } from "./AuditModel";
import { EnterpriseConfigCurrency } from "./EnterpriseConfigDetailModel";
import { PaymentHistoryDetailModel } from "./PaymentModel";

export interface SalaryPeriodInfoModel {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  workingDays: number;
}

export interface SalaryAdvanceTypeInfoModel {
  id: string;
  name: string;
  advancePercent: number;
  advanceForm: SalaryAdvanceType;
  frequencyLimit: number;
  limitFrequency: boolean;
}

export enum SalaryAdvanceType {
  AUTO = "AUTO",
  MANUAL = "MANUAL",
}
export interface SalaryAdvanceTypeDetailModel {
  id: string;
  name: string;
  advancePercent: number;
  advanceForm: SalaryAdvanceType;
  frequencyLimit: number;
  audit: Array<AuditDataModel>;
  limitFrequency: boolean;
}

export interface SalaryAdvanceDetailModel {
  id?: string;
  code?: string;
  employeeCode?: string;
  employeeName?: string;
  status?: SalaryAdvanceStatus;
  paymentStatus?: SalaryAdvancePaymentStatus;
  createdDate?: number;
  currency?: EnterpriseConfigCurrency;
  amount?: number;
  rejectReason?: string;
  periodName?: string;
  salaryAdvanceMaxAmount?: number;
  advancedAmount?: number;
  pendingAmount?: number;
  paymentData?: Array<PaymentHistoryDetailModel>;
  audit?: Array<AuditDataModel>;
  periodStartDate?: string;
  periodEndDate?: string;
  beneficiaryAccount?: string;
}

export interface SalaryAdvanceInfoModel {
  id: string;
  code: string;
  employeeCode: string;
  employeeName: string;
  status: SalaryAdvanceStatus;
  paymentStatus: SalaryAdvancePaymentStatus;
  createdDate: number;
  currency: EnterpriseConfigCurrency;
  amount: number;
  periodName?: string;
}

export enum SalaryAdvancePaymentStatus {
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  PROCESSING = "PROCESSING",
  TODO = "TODO",
  NONE = "NONE",
}

export enum SalaryAdvanceStatus {
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  WAITING_APPROVAL = "WAITING_APPROVAL",
  CANCELED = "CANCELED",
  EXPIRED = "EXPIRED",
}
