import { Dropdown, Tooltip } from "antd";
import { useAppBloc } from "@/contexts/app";
import "@/styles";
import "./header-custom.css";

import {
  iconAlert,
  iconLogoEx,
  iconLogout,
  iconSecurity,
  iconUser,
  iconUsFlag,
  iconVnFlag,
} from "@/services/assets";
import { useTranslation } from "react-i18next";
import { IconArrow } from "@/components/icons-component/icon-component";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NavlinkPath } from "@/services/router-config";
import { headerId, sidebarId } from "@/services/constants";
import { InputToggle } from "@/components/input-toggle/InputToggle";
import { LoginStatus } from "@/models/LoginStatus";
import { useQuery } from "@tanstack/react-query";
import { MerchantStatusType } from "@/models/MerchantModel";
import { PopupCustom2 } from "@/components/popup-custom/PopupCustom2";
import { Environment } from "@/services/environment";

export const HeaderCustom = ({
  merchantStatus,
}: {
  merchantStatus: MerchantStatusType;
}) => {
  const { appBloc } = useAppBloc();
  const [_openDropDown, _setDropDown] = useState(false);
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  const { refetch, data, isLoading } = useQuery(["get-user-info-header"], {
    refetchOnWindowFocus: false,
    retry: 2,
    enabled: false,
    queryFn: async () => {
      let data =
        await appBloc.repository.kienlongPayHttpClient.userRepository.getInfo();
      return data;
    },
  });

  useEffect(() => {
    refetch();
  }, []);

  function onTapLogo() {
    let sidebarNav = document.getElementById(sidebarId);
    if (sidebarNav) {
      sidebarNav.style.left =
        sidebarNav.style.left === "0px" ? "-264px" : "0px";
    }
  }

  if (isLoading) {
    return (
      <header
        id={headerId}
        style={{
          borderBottomColor: appBloc.storage.testMode
            ? "var(--color-global-orange-50)"
            : undefined,
        }}
        className="header-custom-wrap-child"
      ></header>
    );
  }

  return (
    <header
      id={headerId}
      style={{
        borderBottomColor: appBloc.storage.testMode
          ? "var(--color-global-orange-50)"
          : undefined,
      }}
      className="header-custom-wrap-child"
    >
      {/* merchant info */}
      <div
        className="row"
        style={{
          gap: "12px",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <img
          className="header-custom-wrap-child__logo-app"
          onClick={() => {
            onTapLogo();
          }}
          alt=""
        />
        <span
          className="fontSize--16 fontWeight--700 lineHeight--24 hide-on-576"
          style={{ color: "var(--color-global-brand-90)" }}
        >
          {data?.merchantName ?? "---"}
        </span>
        <div
          className="hide-on-576"
          style={{
            width: "1px",
            height: "20px",
            backgroundColor: "var(--color-global-brand-30)",
          }}
        ></div>
        <span
          className="fontSize--14 fontWeight--400 lineHeight--20 hide-on-576"
          style={{ color: "var(--color-global-brand-70)" }}
        >
          MID: {data?.merchantCode ?? "---"}
        </span>
      </div>

      <div
        className="row"
        style={{
          gap: "24px",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        {/* test button */}
        <div className="row" style={{ gap: "8px", width: "auto" }}>
          <Tooltip
            title={
              merchantStatus !== MerchantStatusType.ACTIVE ? (
                <div className="column" style={{ alignItems: "flex-end" }}>
                  <span
                    className="fontWeight--500 fontSize--14 lineHeight--20"
                    style={{ textAlign: "end", width: "300px" }}
                  >
                    Bạn đang trải nghiệm chế độ dữ liệu mẫu.
                  </span>
                  <span
                    className="fontWeight--500 fontSize--14 lineHeight--20"
                    style={{ textAlign: "end" }}
                  >
                    Vui lòng{" "}
                    <u
                      onClick={() => {
                        navigate(NavlinkPath.indenticateAccountSetting);
                      }}
                      style={{
                        fontStyle: "italic",
                        fontWeight: "500",
                        cursor: "pointer",
                      }}
                    >
                      Định danh
                    </u>{" "}
                    tài khoản để kích hoạt dữ liệu thật
                  </span>
                </div>
              ) : appBloc.storage.testMode ? (
                "Thoát khỏi chế độ Test Mode"
              ) : (
                "Bật Test Mode để trải nghiệm chế độ dữ liệu mẫu"
              )
            }
            placement="bottomRight"
            overlayInnerStyle={{
              maxWidth: "340px",
              minWidth: "300px",
              width: "100%",
              // width: "369px",
              borderRadius: "8px",
              backgroundColor: "#111827",
              padding: "10px 14px",
              position: "absolute",
              right: "0px",
            }}
          >
            <>
              <InputToggle
                isLock={
                  merchantStatus !== MerchantStatusType.ACTIVE ? true : false
                }
                defaultValue={appBloc.storage.testMode}
                onChange={(v) => {
                  appBloc.storage.testMode = v;
                  setTimeout(() => {
                    window.location.reload();
                  }, 300);
                }}
                offColor={"var(--color-global-orange-30)"}
                onColor={"var(--color-global-orange-60)"}
              />
            </>
          </Tooltip>
          <span
            className="fontWeight--400 fontSize--14 lineHeight--20"
            style={{ color: "var(--color-global-gray-100)" }}
          >
            Test Mode
          </span>
        </div>

        {/* language button */}
        <div
          className="language-button"
          onClick={() => {
            if (i18n.language === "vi") {
              appBloc.storage.language = "en";
              i18n.changeLanguage("en");
            } else {
              appBloc.storage.language = "vi";
              i18n.changeLanguage("vi");
            }
          }}
        >
          <img
            alt="language"
            src={i18n.language === "vi" ? iconVnFlag : iconUsFlag}
            style={{ width: "24px", height: "24px" }}
          />
        </div>

        {/* user info */}
        <Dropdown
          open={_openDropDown}
          onOpenChange={(v) => {
            _setDropDown(v);
          }}
          arrow={false}
          overlayStyle={{
            width: "240px",
            overflowY: "hidden",
            boxShadow: "0px 2px 7px 1px rgba(112, 144, 176, 0.25)",
            borderRadius: "8px",
            backgroundColor: "#00000000",
            paddingTop: "0px",
          }}
          trigger={["click"]}
          placement="bottomRight"
          dropdownRender={() => {
            return (
              <div
                className="column"
                style={{
                  borderRadius: "8px",
                }}
              >
                <div className="header-custom-wrap-child__dropdown-menu">
                  {/* account info */}
                  <div
                    className="header-custom-wrap-child__item-dropdown-menu"
                    onClick={() => {
                      _setDropDown(false);
                      navigate(NavlinkPath.accountInfo);
                    }}
                  >
                    <img alt="" src={iconUser} style={{ width: "32px" }} />
                    <div
                      className="column"
                      style={{
                        padding: "16px 0px",
                        borderBottom: "1px solid #EAF0FD",
                        alignItems: "flex-start",
                        width: "100%",
                      }}
                    >
                      <span className="fontWeight--400 fontSize--16 lineHeight--20">
                        {t("header.account-info")}
                      </span>
                    </div>
                  </div>

                  {/* change pass */}
                  <div
                    className="header-custom-wrap-child__item-dropdown-menu"
                    onClick={() => {
                      navigate(NavlinkPath.changePassword);
                      _setDropDown(false);
                    }}
                  >
                    <img
                      alt=""
                      src={iconSecurity}
                      style={{ width: "24px", height: "24px" }}
                    />
                    <div
                      className="column"
                      style={{
                        padding: "16px 0px",
                        borderBottom: "1px solid #EAF0FD",
                        alignItems: "flex-start",
                        width: "100%",
                      }}
                    >
                      <span className="fontWeight--400 fontSize--16 lineHeight--20">
                        {t("header.change-password")}
                      </span>
                    </div>
                  </div>

                  {/* Logout */}
                  <div
                    className="header-custom-wrap-child__item-dropdown-menu"
                    onClick={() => {
                      _setDropDown(false);
                      appBloc.session.modal.next(
                        <PopupCustom2
                          onConfirm={() => {
                            appBloc.session.loginStatus.next(
                              LoginStatus.Expired,
                            );
                          }}
                          title="Kienlongbank Pay"
                          subTitle={`${t("content-logout")}`}
                          labelConfirm={`${t("agree")}`}
                          labelCancel={`${t("close")}`}
                          onCancel={() => {
                            appBloc.session.isShowModal.next(false);
                          }}
                          img={iconAlert}
                          styleImg={{
                            width: "100px",
                            height: "100px",
                            aspectRatio: "100 / 100",
                          }}
                        />,
                      );
                      appBloc.session.isShowModal.next(true);
                    }}
                  >
                    <img
                      alt=""
                      src={iconLogout}
                      style={{ width: "24px", height: "24px" }}
                    />
                    <div
                      className="column"
                      style={{
                        padding: "16px 0px",
                        alignItems: "flex-start",
                        width: "100%",
                      }}
                    >
                      <span className="fontWeight--400 fontSize--16 lineHeight--20">
                        {t("logout")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
        >
          <div className="row" style={{ cursor: "pointer", gap: "8px" }}>
            <div
              className="column"
              style={{
                width: "32px",
                height: "32px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "100%",
              }}
            >
              <img
                alt="avater-user"
                src={
                  data?.avatar
                    ? `${Environment.hostApiStorage}${data.avatar}`
                    : iconLogoEx
                }
                style={{
                  width: "32px",
                  aspectRatio: 1,
                  borderRadius: "100%",
                }}
              />
            </div>
            <span
              className="fontWeight--500 fontSize--14 lineHeight--20 hide-on-768"
              style={{
                color: "#111827",
                textAlign: "start",
              }}
            >
              {data?.fullName ?? "---"}
            </span>
            <div className="hide-on-768" style={{ transform: "rotate(90deg)" }}>
              <IconArrow color="#111827" />
            </div>
          </div>
        </Dropdown>
      </div>

      {/* bottom testMode */}
      {appBloc.storage.testMode && (
        <div
          className="row"
          style={{
            position: "absolute",
            left: "0",
            right: "0",
            marginLeft: "auto",
            marginRight: "auto",
            top: "80px",
            backgroundColor: "var(--color-global-orange-60)",
            padding: "0px 8px",
            borderRadius: "0px 0px 4px 4px",
            width: "fit-content",
          }}
        >
          <span
            style={{ color: "#FFFFFF" }}
            className="fontSize--14 fontWeight--500 lineHeight--20"
          >
            Test Data
          </span>
        </div>
      )}
    </header>
  );
};
