import {
  mapLinkPayboxDeviceResponseModel,
  mapPayBoxModel,
} from "@/adapters/pay_box_adapter";
import { PayboxClient } from "@/api/paybox_http_client";
import { ErrorModel } from "@/models/ErrorModel";
import {
  PagePayBoxDeviceModel,
  PayBoxDeviceModel,
  PayBoxLinkMerchantModel,
} from "@/models/PayBoxDeviceModel";
import { SortDirectionType } from "@/models/Sort";
import { PayBoxDeviceRepository } from "../PayboxDeviceRepository";

export class PayBoxDeviceRepositoryImp implements PayBoxDeviceRepository {
  private client: PayboxClient;
  constructor(payboxClient: PayboxClient) {
    this.client = payboxClient;
  }

  async deviceBankAccount({ id }: { id: string }): Promise<boolean> {
    try {
      let resp = await this.client.payBoxDeviceController.deleteBankAccount({
        id: id,
      });
      if (resp.data.code === 0) {
        return resp.data.data?.success ?? false;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  async deleteDevice({ id }: { id: string }): Promise<boolean> {
    try {
      let resp = await this.client.payBoxDeviceController.deleteDevice({
        id: id,
      });
      if (resp.data.code === 0) {
        return resp.data.data?.success ?? false;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  async getDeviceById({ id }: { id: string }): Promise<PayBoxDeviceModel> {
    try {
      let resp = await this.client.payBoxDeviceController.getDevice({
        id: id,
      });
      if (resp.data.code === 0) {
        return mapPayBoxModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async linkMerchant({
    pairingKey,
    hardwareId,
  }: {
    hardwareId: string;
    pairingKey: string;
  }): Promise<PayBoxLinkMerchantModel> {
    try {
      let resp = await this.client.payBoxDeviceController.linkWithUser({
        linkWithUserRequest: {
          hardwareId,
          pairingKey,
        },
      });
      if (resp.data.code === 0) {
        return mapLinkPayboxDeviceResponseModel(resp?.data?.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
  public async checkLinkMerchant({
    pairingKey,
    hardwareId,
  }: {
    hardwareId: string;
    pairingKey: string;
  }): Promise<PayBoxDeviceModel> {
    try {
      let resp = await this.client.payBoxDeviceController.checkDeviceBeforeLink(
        {
          checkDeviceBeforeLinkRequest: {
            hardwareId,
            pairingKey,
          },
        },
      );
      if (resp.data.code === 0) {
        return mapPayBoxModel(resp?.data?.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
  public async getAllPayBoxDevices({
    name,
    pageNumber,
    pageSize,
    sortDirection,
    online,
  }: {
    name?: string;
    pageNumber?: number;
    pageSize?: number;
    sortDirection?: SortDirectionType;
    online?: boolean;
  }): Promise<PagePayBoxDeviceModel> {
    try {
      let resp = await this.client.payBoxDeviceController.getAllDevices({
        name: name,
        online: online,
        page: pageNumber,
        size: pageSize,
        sortDir: sortDirection,
      });

      if (resp.data.code === 0) {
        return {
          pageNumber: resp.data.data?.devices?.number ?? 0,
          pageSize: resp.data.data?.devices?.size ?? 0,
          totalPage: resp.data.data?.devices?.totalPages ?? 0,
          totalSize: resp.data.data?.devices?.totalElements ?? 0,
          items:
            resp.data.data?.devices?.content?.map((d) => mapPayBoxModel(d)) ??
            [],
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async updatePayboxDevice({
    id,
    displayName,
    bankAccountId,
  }: {
    id: string;
    displayName?: string;
    bankAccountId?: string;
  }): Promise<boolean> {
    try {
      let resp = await this.client.payBoxDeviceController.updateDevice({
        id: id,
        updateDeviceRequest: {
          displayName: displayName,
          bankAccountId: bankAccountId,
        },
      });
      if (resp.data.code === 0) {
        return resp.data.data?.success ?? false;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
