/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from "./configuration";
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "./base";

/**
 *
 * @export
 * @interface AdvanceFeeInfo
 */
export interface AdvanceFeeInfo {
  /**
   *
   * @type {number}
   * @memberof AdvanceFeeInfo
   */
  amount?: number;
  /**
   *
   * @type {number}
   * @memberof AdvanceFeeInfo
   */
  fee?: number;
  /**
   *
   * @type {number}
   * @memberof AdvanceFeeInfo
   */
  finalAmount?: number;
}
/**
 *
 * @export
 * @interface AdvanceHistoryDetail
 */
export interface AdvanceHistoryDetail {
  /**
   *
   * @type {string}
   * @memberof AdvanceHistoryDetail
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof AdvanceHistoryDetail
   */
  periodName?: string;
  /**
   *
   * @type {string}
   * @memberof AdvanceHistoryDetail
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof AdvanceHistoryDetail
   */
  status?: AdvanceHistoryDetailStatusEnum;
  /**
   *
   * @type {string}
   * @memberof AdvanceHistoryDetail
   */
  currency?: AdvanceHistoryDetailCurrencyEnum;
  /**
   *
   * @type {number}
   * @memberof AdvanceHistoryDetail
   */
  amount?: number;
  /**
   *
   * @type {number}
   * @memberof AdvanceHistoryDetail
   */
  createdDate?: number;
  /**
   *
   * @type {string}
   * @memberof AdvanceHistoryDetail
   */
  paymentStatus?: AdvanceHistoryDetailPaymentStatusEnum;
  /**
   *
   * @type {string}
   * @memberof AdvanceHistoryDetail
   */
  beneficiaryAccount?: string;
  /**
   *
   * @type {string}
   * @memberof AdvanceHistoryDetail
   */
  beneficiaryName?: string;
  /**
   *
   * @type {number}
   * @memberof AdvanceHistoryDetail
   */
  fee?: number;
  /**
   *
   * @type {number}
   * @memberof AdvanceHistoryDetail
   */
  finalAmount?: number;
}

export const AdvanceHistoryDetailStatusEnum = {
  Approved: "APPROVED",
  Rejected: "REJECTED",
  WaitingApproval: "WAITING_APPROVAL",
  Canceled: "CANCELED",
  Expired: "EXPIRED",
} as const;

export type AdvanceHistoryDetailStatusEnum =
  (typeof AdvanceHistoryDetailStatusEnum)[keyof typeof AdvanceHistoryDetailStatusEnum];
export const AdvanceHistoryDetailCurrencyEnum = {
  Vnd: "VND",
} as const;

export type AdvanceHistoryDetailCurrencyEnum =
  (typeof AdvanceHistoryDetailCurrencyEnum)[keyof typeof AdvanceHistoryDetailCurrencyEnum];
export const AdvanceHistoryDetailPaymentStatusEnum = {
  Success: "SUCCESS",
  Failed: "FAILED",
  Processing: "PROCESSING",
  Todo: "TODO",
  None: "NONE",
} as const;

export type AdvanceHistoryDetailPaymentStatusEnum =
  (typeof AdvanceHistoryDetailPaymentStatusEnum)[keyof typeof AdvanceHistoryDetailPaymentStatusEnum];

/**
 *
 * @export
 * @interface AdvanceHistoryInfo
 */
export interface AdvanceHistoryInfo {
  /**
   *
   * @type {string}
   * @memberof AdvanceHistoryInfo
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof AdvanceHistoryInfo
   */
  periodName?: string;
  /**
   *
   * @type {string}
   * @memberof AdvanceHistoryInfo
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof AdvanceHistoryInfo
   */
  status?: AdvanceHistoryInfoStatusEnum;
  /**
   *
   * @type {string}
   * @memberof AdvanceHistoryInfo
   */
  currency?: AdvanceHistoryInfoCurrencyEnum;
  /**
   *
   * @type {number}
   * @memberof AdvanceHistoryInfo
   */
  amount?: number;
  /**
   *
   * @type {number}
   * @memberof AdvanceHistoryInfo
   */
  createdDate?: number;
  /**
   *
   * @type {string}
   * @memberof AdvanceHistoryInfo
   */
  paymentStatus?: AdvanceHistoryInfoPaymentStatusEnum;
}

export const AdvanceHistoryInfoStatusEnum = {
  Approved: "APPROVED",
  Rejected: "REJECTED",
  WaitingApproval: "WAITING_APPROVAL",
  Canceled: "CANCELED",
  Expired: "EXPIRED",
} as const;

export type AdvanceHistoryInfoStatusEnum =
  (typeof AdvanceHistoryInfoStatusEnum)[keyof typeof AdvanceHistoryInfoStatusEnum];
export const AdvanceHistoryInfoCurrencyEnum = {
  Vnd: "VND",
} as const;

export type AdvanceHistoryInfoCurrencyEnum =
  (typeof AdvanceHistoryInfoCurrencyEnum)[keyof typeof AdvanceHistoryInfoCurrencyEnum];
export const AdvanceHistoryInfoPaymentStatusEnum = {
  Success: "SUCCESS",
  Failed: "FAILED",
  Processing: "PROCESSING",
  Todo: "TODO",
  None: "NONE",
} as const;

export type AdvanceHistoryInfoPaymentStatusEnum =
  (typeof AdvanceHistoryInfoPaymentStatusEnum)[keyof typeof AdvanceHistoryInfoPaymentStatusEnum];

/**
 *
 * @export
 * @interface AdvanceRequest
 */
export interface AdvanceRequest {
  /**
   *
   * @type {number}
   * @memberof AdvanceRequest
   */
  amount: number;
  /**
   *
   * @type {string}
   * @memberof AdvanceRequest
   */
  beneficiaryAccount: string;
}
/**
 *
 * @export
 * @interface ApproveRegistrationRequest
 */
export interface ApproveRegistrationRequest {
  /**
   *
   * @type {string}
   * @memberof ApproveRegistrationRequest
   */
  accountNumber: string;
  /**
   *
   * @type {string}
   * @memberof ApproveRegistrationRequest
   */
  accountName: string;
  /**
   *
   * @type {string}
   * @memberof ApproveRegistrationRequest
   */
  overdraftEndDate?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApproveRegistrationRequest
   */
  documentUrls: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ApproveRegistrationRequest
   */
  cifNo?: string;
}
/**
 *
 * @export
 * @interface AuditData
 */
export interface AuditData {
  /**
   *
   * @type {string}
   * @memberof AuditData
   */
  userId?: string;
  /**
   *
   * @type {string}
   * @memberof AuditData
   */
  userName?: string;
  /**
   *
   * @type {number}
   * @memberof AuditData
   */
  auditTime?: number;
  /**
   *
   * @type {string}
   * @memberof AuditData
   */
  changeType?: AuditDataChangeTypeEnum;
  /**
   *
   * @type {Array<AuditRecord>}
   * @memberof AuditData
   */
  changes?: Array<AuditRecord>;
}

export const AuditDataChangeTypeEnum = {
  Created: "CREATED",
  Updated: "UPDATED",
  Deleted: "DELETED",
} as const;

export type AuditDataChangeTypeEnum =
  (typeof AuditDataChangeTypeEnum)[keyof typeof AuditDataChangeTypeEnum];

/**
 *
 * @export
 * @interface AuditRecord
 */
export interface AuditRecord {
  /**
   *
   * @type {string}
   * @memberof AuditRecord
   */
  key?: string;
  /**
   *
   * @type {string}
   * @memberof AuditRecord
   */
  newValue?: string;
}
/**
 *
 * @export
 * @interface BaseResponseAdvanceFeeInfo
 */
export interface BaseResponseAdvanceFeeInfo {
  /**
   *
   * @type {number}
   * @memberof BaseResponseAdvanceFeeInfo
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponseAdvanceFeeInfo
   */
  message?: string;
  /**
   *
   * @type {AdvanceFeeInfo}
   * @memberof BaseResponseAdvanceFeeInfo
   */
  data?: AdvanceFeeInfo;
}
/**
 *
 * @export
 * @interface BaseResponseAdvanceHistoryDetail
 */
export interface BaseResponseAdvanceHistoryDetail {
  /**
   *
   * @type {number}
   * @memberof BaseResponseAdvanceHistoryDetail
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponseAdvanceHistoryDetail
   */
  message?: string;
  /**
   *
   * @type {AdvanceHistoryDetail}
   * @memberof BaseResponseAdvanceHistoryDetail
   */
  data?: AdvanceHistoryDetail;
}
/**
 *
 * @export
 * @interface BaseResponseCheckEnterpriseResponse
 */
export interface BaseResponseCheckEnterpriseResponse {
  /**
   *
   * @type {number}
   * @memberof BaseResponseCheckEnterpriseResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponseCheckEnterpriseResponse
   */
  message?: string;
  /**
   *
   * @type {CheckEnterpriseResponse}
   * @memberof BaseResponseCheckEnterpriseResponse
   */
  data?: CheckEnterpriseResponse;
}
/**
 *
 * @export
 * @interface BaseResponseCheckTermAndConditionResponse
 */
export interface BaseResponseCheckTermAndConditionResponse {
  /**
   *
   * @type {number}
   * @memberof BaseResponseCheckTermAndConditionResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponseCheckTermAndConditionResponse
   */
  message?: string;
  /**
   *
   * @type {CheckTermAndConditionResponse}
   * @memberof BaseResponseCheckTermAndConditionResponse
   */
  data?: CheckTermAndConditionResponse;
}
/**
 *
 * @export
 * @interface BaseResponseCmsAdvanceRequestDetail
 */
export interface BaseResponseCmsAdvanceRequestDetail {
  /**
   *
   * @type {number}
   * @memberof BaseResponseCmsAdvanceRequestDetail
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponseCmsAdvanceRequestDetail
   */
  message?: string;
  /**
   *
   * @type {CmsAdvanceRequestDetail}
   * @memberof BaseResponseCmsAdvanceRequestDetail
   */
  data?: CmsAdvanceRequestDetail;
}
/**
 *
 * @export
 * @interface BaseResponseCmsEnterpriseDetail
 */
export interface BaseResponseCmsEnterpriseDetail {
  /**
   *
   * @type {number}
   * @memberof BaseResponseCmsEnterpriseDetail
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponseCmsEnterpriseDetail
   */
  message?: string;
  /**
   *
   * @type {CmsEnterpriseDetail}
   * @memberof BaseResponseCmsEnterpriseDetail
   */
  data?: CmsEnterpriseDetail;
}
/**
 *
 * @export
 * @interface BaseResponseCmsEnterpriseStatisticResponse
 */
export interface BaseResponseCmsEnterpriseStatisticResponse {
  /**
   *
   * @type {number}
   * @memberof BaseResponseCmsEnterpriseStatisticResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponseCmsEnterpriseStatisticResponse
   */
  message?: string;
  /**
   *
   * @type {CmsEnterpriseStatisticResponse}
   * @memberof BaseResponseCmsEnterpriseStatisticResponse
   */
  data?: CmsEnterpriseStatisticResponse;
}
/**
 *
 * @export
 * @interface BaseResponseCmsStatisticResponse
 */
export interface BaseResponseCmsStatisticResponse {
  /**
   *
   * @type {number}
   * @memberof BaseResponseCmsStatisticResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponseCmsStatisticResponse
   */
  message?: string;
  /**
   *
   * @type {CmsStatisticResponse}
   * @memberof BaseResponseCmsStatisticResponse
   */
  data?: CmsStatisticResponse;
}
/**
 *
 * @export
 * @interface BaseResponseCreateAdvanceResponse
 */
export interface BaseResponseCreateAdvanceResponse {
  /**
   *
   * @type {number}
   * @memberof BaseResponseCreateAdvanceResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponseCreateAdvanceResponse
   */
  message?: string;
  /**
   *
   * @type {CreateAdvanceResponse}
   * @memberof BaseResponseCreateAdvanceResponse
   */
  data?: CreateAdvanceResponse;
}
/**
 *
 * @export
 * @interface BaseResponseCreatedResponse
 */
export interface BaseResponseCreatedResponse {
  /**
   *
   * @type {number}
   * @memberof BaseResponseCreatedResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponseCreatedResponse
   */
  message?: string;
  /**
   *
   * @type {CreatedResponse}
   * @memberof BaseResponseCreatedResponse
   */
  data?: CreatedResponse;
}
/**
 *
 * @export
 * @interface BaseResponseEmployeeInfo
 */
export interface BaseResponseEmployeeInfo {
  /**
   *
   * @type {number}
   * @memberof BaseResponseEmployeeInfo
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponseEmployeeInfo
   */
  message?: string;
  /**
   *
   * @type {EmployeeInfo}
   * @memberof BaseResponseEmployeeInfo
   */
  data?: EmployeeInfo;
}
/**
 *
 * @export
 * @interface BaseResponseEnterpriseConfigDetail
 */
export interface BaseResponseEnterpriseConfigDetail {
  /**
   *
   * @type {number}
   * @memberof BaseResponseEnterpriseConfigDetail
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponseEnterpriseConfigDetail
   */
  message?: string;
  /**
   *
   * @type {EnterpriseConfigDetail}
   * @memberof BaseResponseEnterpriseConfigDetail
   */
  data?: EnterpriseConfigDetail;
}
/**
 *
 * @export
 * @interface BaseResponseEnterpriseEmployeeDetail
 */
export interface BaseResponseEnterpriseEmployeeDetail {
  /**
   *
   * @type {number}
   * @memberof BaseResponseEnterpriseEmployeeDetail
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponseEnterpriseEmployeeDetail
   */
  message?: string;
  /**
   *
   * @type {EnterpriseEmployeeDetail}
   * @memberof BaseResponseEnterpriseEmployeeDetail
   */
  data?: EnterpriseEmployeeDetail;
}
/**
 *
 * @export
 * @interface BaseResponseListAdvanceHistoryInfo
 */
export interface BaseResponseListAdvanceHistoryInfo {
  /**
   *
   * @type {number}
   * @memberof BaseResponseListAdvanceHistoryInfo
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponseListAdvanceHistoryInfo
   */
  message?: string;
  /**
   *
   * @type {Array<AdvanceHistoryInfo>}
   * @memberof BaseResponseListAdvanceHistoryInfo
   */
  data?: Array<AdvanceHistoryInfo>;
}
/**
 *
 * @export
 * @interface BaseResponseListCmsEnterprisePaidInfo
 */
export interface BaseResponseListCmsEnterprisePaidInfo {
  /**
   *
   * @type {number}
   * @memberof BaseResponseListCmsEnterprisePaidInfo
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponseListCmsEnterprisePaidInfo
   */
  message?: string;
  /**
   *
   * @type {Array<CmsEnterprisePaidInfo>}
   * @memberof BaseResponseListCmsEnterprisePaidInfo
   */
  data?: Array<CmsEnterprisePaidInfo>;
}
/**
 *
 * @export
 * @interface BaseResponseListCmsPaidChartItem
 */
export interface BaseResponseListCmsPaidChartItem {
  /**
   *
   * @type {number}
   * @memberof BaseResponseListCmsPaidChartItem
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponseListCmsPaidChartItem
   */
  message?: string;
  /**
   *
   * @type {Array<CmsPaidChartItem>}
   * @memberof BaseResponseListCmsPaidChartItem
   */
  data?: Array<CmsPaidChartItem>;
}
/**
 *
 * @export
 * @interface BaseResponseListEmployeeCheckinResponse
 */
export interface BaseResponseListEmployeeCheckinResponse {
  /**
   *
   * @type {number}
   * @memberof BaseResponseListEmployeeCheckinResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponseListEmployeeCheckinResponse
   */
  message?: string;
  /**
   *
   * @type {Array<EmployeeCheckinResponse>}
   * @memberof BaseResponseListEmployeeCheckinResponse
   */
  data?: Array<EmployeeCheckinResponse>;
}
/**
 *
 * @export
 * @interface BaseResponseListUserEnterpriseInfo
 */
export interface BaseResponseListUserEnterpriseInfo {
  /**
   *
   * @type {number}
   * @memberof BaseResponseListUserEnterpriseInfo
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponseListUserEnterpriseInfo
   */
  message?: string;
  /**
   *
   * @type {Array<UserEnterpriseInfo>}
   * @memberof BaseResponseListUserEnterpriseInfo
   */
  data?: Array<UserEnterpriseInfo>;
}
/**
 *
 * @export
 * @interface BaseResponseLogworkTableResponse
 */
export interface BaseResponseLogworkTableResponse {
  /**
   *
   * @type {number}
   * @memberof BaseResponseLogworkTableResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponseLogworkTableResponse
   */
  message?: string;
  /**
   *
   * @type {LogworkTableResponse}
   * @memberof BaseResponseLogworkTableResponse
   */
  data?: LogworkTableResponse;
}
/**
 *
 * @export
 * @interface BaseResponseObject
 */
export interface BaseResponseObject {
  /**
   *
   * @type {number}
   * @memberof BaseResponseObject
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponseObject
   */
  message?: string;
  /**
   *
   * @type {object}
   * @memberof BaseResponseObject
   */
  data?: object;
}
/**
 *
 * @export
 * @interface BaseResponsePageDtoListCmsAdvanceRequestInfo
 */
export interface BaseResponsePageDtoListCmsAdvanceRequestInfo {
  /**
   *
   * @type {number}
   * @memberof BaseResponsePageDtoListCmsAdvanceRequestInfo
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponsePageDtoListCmsAdvanceRequestInfo
   */
  message?: string;
  /**
   *
   * @type {PageDtoListCmsAdvanceRequestInfo}
   * @memberof BaseResponsePageDtoListCmsAdvanceRequestInfo
   */
  data?: PageDtoListCmsAdvanceRequestInfo;
}
/**
 *
 * @export
 * @interface BaseResponsePageDtoListCmsEnterpriseInfo
 */
export interface BaseResponsePageDtoListCmsEnterpriseInfo {
  /**
   *
   * @type {number}
   * @memberof BaseResponsePageDtoListCmsEnterpriseInfo
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponsePageDtoListCmsEnterpriseInfo
   */
  message?: string;
  /**
   *
   * @type {PageDtoListCmsEnterpriseInfo}
   * @memberof BaseResponsePageDtoListCmsEnterpriseInfo
   */
  data?: PageDtoListCmsEnterpriseInfo;
}
/**
 *
 * @export
 * @interface BaseResponsePageDtoListEmployeePaidInfo
 */
export interface BaseResponsePageDtoListEmployeePaidInfo {
  /**
   *
   * @type {number}
   * @memberof BaseResponsePageDtoListEmployeePaidInfo
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponsePageDtoListEmployeePaidInfo
   */
  message?: string;
  /**
   *
   * @type {PageDtoListEmployeePaidInfo}
   * @memberof BaseResponsePageDtoListEmployeePaidInfo
   */
  data?: PageDtoListEmployeePaidInfo;
}
/**
 *
 * @export
 * @interface BaseResponsePageDtoListEnterpriseEmployeeInfo
 */
export interface BaseResponsePageDtoListEnterpriseEmployeeInfo {
  /**
   *
   * @type {number}
   * @memberof BaseResponsePageDtoListEnterpriseEmployeeInfo
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponsePageDtoListEnterpriseEmployeeInfo
   */
  message?: string;
  /**
   *
   * @type {PageDtoListEnterpriseEmployeeInfo}
   * @memberof BaseResponsePageDtoListEnterpriseEmployeeInfo
   */
  data?: PageDtoListEnterpriseEmployeeInfo;
}
/**
 *
 * @export
 * @interface BaseResponsePageDtoListRegistrationInfo
 */
export interface BaseResponsePageDtoListRegistrationInfo {
  /**
   *
   * @type {number}
   * @memberof BaseResponsePageDtoListRegistrationInfo
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponsePageDtoListRegistrationInfo
   */
  message?: string;
  /**
   *
   * @type {PageDtoListRegistrationInfo}
   * @memberof BaseResponsePageDtoListRegistrationInfo
   */
  data?: PageDtoListRegistrationInfo;
}
/**
 *
 * @export
 * @interface BaseResponsePageDtoListSalaryAdvanceByEmployeeInfo
 */
export interface BaseResponsePageDtoListSalaryAdvanceByEmployeeInfo {
  /**
   *
   * @type {number}
   * @memberof BaseResponsePageDtoListSalaryAdvanceByEmployeeInfo
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponsePageDtoListSalaryAdvanceByEmployeeInfo
   */
  message?: string;
  /**
   *
   * @type {PageDtoListSalaryAdvanceByEmployeeInfo}
   * @memberof BaseResponsePageDtoListSalaryAdvanceByEmployeeInfo
   */
  data?: PageDtoListSalaryAdvanceByEmployeeInfo;
}
/**
 *
 * @export
 * @interface BaseResponsePageDtoListSalaryAdvanceInfo
 */
export interface BaseResponsePageDtoListSalaryAdvanceInfo {
  /**
   *
   * @type {number}
   * @memberof BaseResponsePageDtoListSalaryAdvanceInfo
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponsePageDtoListSalaryAdvanceInfo
   */
  message?: string;
  /**
   *
   * @type {PageDtoListSalaryAdvanceInfo}
   * @memberof BaseResponsePageDtoListSalaryAdvanceInfo
   */
  data?: PageDtoListSalaryAdvanceInfo;
}
/**
 *
 * @export
 * @interface BaseResponsePageDtoListSalaryAdvanceTypeInfo
 */
export interface BaseResponsePageDtoListSalaryAdvanceTypeInfo {
  /**
   *
   * @type {number}
   * @memberof BaseResponsePageDtoListSalaryAdvanceTypeInfo
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponsePageDtoListSalaryAdvanceTypeInfo
   */
  message?: string;
  /**
   *
   * @type {PageDtoListSalaryAdvanceTypeInfo}
   * @memberof BaseResponsePageDtoListSalaryAdvanceTypeInfo
   */
  data?: PageDtoListSalaryAdvanceTypeInfo;
}
/**
 *
 * @export
 * @interface BaseResponsePageDtoListSalaryPeriodInfo
 */
export interface BaseResponsePageDtoListSalaryPeriodInfo {
  /**
   *
   * @type {number}
   * @memberof BaseResponsePageDtoListSalaryPeriodInfo
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponsePageDtoListSalaryPeriodInfo
   */
  message?: string;
  /**
   *
   * @type {PageDtoListSalaryPeriodInfo}
   * @memberof BaseResponsePageDtoListSalaryPeriodInfo
   */
  data?: PageDtoListSalaryPeriodInfo;
}
/**
 *
 * @export
 * @interface BaseResponsePageDtoListShortSalaryPeriodInfo
 */
export interface BaseResponsePageDtoListShortSalaryPeriodInfo {
  /**
   *
   * @type {number}
   * @memberof BaseResponsePageDtoListShortSalaryPeriodInfo
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponsePageDtoListShortSalaryPeriodInfo
   */
  message?: string;
  /**
   *
   * @type {PageDtoListShortSalaryPeriodInfo}
   * @memberof BaseResponsePageDtoListShortSalaryPeriodInfo
   */
  data?: PageDtoListShortSalaryPeriodInfo;
}
/**
 *
 * @export
 * @interface BaseResponsePaidChartResponse
 */
export interface BaseResponsePaidChartResponse {
  /**
   *
   * @type {number}
   * @memberof BaseResponsePaidChartResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponsePaidChartResponse
   */
  message?: string;
  /**
   *
   * @type {PaidChartResponse}
   * @memberof BaseResponsePaidChartResponse
   */
  data?: PaidChartResponse;
}
/**
 *
 * @export
 * @interface BaseResponseRegistrationDetail
 */
export interface BaseResponseRegistrationDetail {
  /**
   *
   * @type {number}
   * @memberof BaseResponseRegistrationDetail
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponseRegistrationDetail
   */
  message?: string;
  /**
   *
   * @type {RegistrationDetail}
   * @memberof BaseResponseRegistrationDetail
   */
  data?: RegistrationDetail;
}
/**
 *
 * @export
 * @interface BaseResponseSalaryAdvanceDetail
 */
export interface BaseResponseSalaryAdvanceDetail {
  /**
   *
   * @type {number}
   * @memberof BaseResponseSalaryAdvanceDetail
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponseSalaryAdvanceDetail
   */
  message?: string;
  /**
   *
   * @type {SalaryAdvanceDetail}
   * @memberof BaseResponseSalaryAdvanceDetail
   */
  data?: SalaryAdvanceDetail;
}
/**
 *
 * @export
 * @interface BaseResponseSalaryAdvanceTypeDetail
 */
export interface BaseResponseSalaryAdvanceTypeDetail {
  /**
   *
   * @type {number}
   * @memberof BaseResponseSalaryAdvanceTypeDetail
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponseSalaryAdvanceTypeDetail
   */
  message?: string;
  /**
   *
   * @type {SalaryAdvanceTypeDetail}
   * @memberof BaseResponseSalaryAdvanceTypeDetail
   */
  data?: SalaryAdvanceTypeDetail;
}
/**
 *
 * @export
 * @interface BaseResponseTotalStatisticResponse
 */
export interface BaseResponseTotalStatisticResponse {
  /**
   *
   * @type {number}
   * @memberof BaseResponseTotalStatisticResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponseTotalStatisticResponse
   */
  message?: string;
  /**
   *
   * @type {TotalStatisticResponse}
   * @memberof BaseResponseTotalStatisticResponse
   */
  data?: TotalStatisticResponse;
}
/**
 *
 * @export
 * @interface BaseResponseUploadEmployeeResponse
 */
export interface BaseResponseUploadEmployeeResponse {
  /**
   *
   * @type {number}
   * @memberof BaseResponseUploadEmployeeResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponseUploadEmployeeResponse
   */
  message?: string;
  /**
   *
   * @type {UploadEmployeeResponse}
   * @memberof BaseResponseUploadEmployeeResponse
   */
  data?: UploadEmployeeResponse;
}
/**
 *
 * @export
 * @interface BaseResponseUploadLogworkResponse
 */
export interface BaseResponseUploadLogworkResponse {
  /**
   *
   * @type {number}
   * @memberof BaseResponseUploadLogworkResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponseUploadLogworkResponse
   */
  message?: string;
  /**
   *
   * @type {UploadLogworkResponse}
   * @memberof BaseResponseUploadLogworkResponse
   */
  data?: UploadLogworkResponse;
}
/**
 *
 * @export
 * @interface BaseResponseUserLogworkByPeriodInfo
 */
export interface BaseResponseUserLogworkByPeriodInfo {
  /**
   *
   * @type {number}
   * @memberof BaseResponseUserLogworkByPeriodInfo
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponseUserLogworkByPeriodInfo
   */
  message?: string;
  /**
   *
   * @type {UserLogworkByPeriodInfo}
   * @memberof BaseResponseUserLogworkByPeriodInfo
   */
  data?: UserLogworkByPeriodInfo;
}
/**
 *
 * @export
 * @interface BulkRequestEmployeeRequest
 */
export interface BulkRequestEmployeeRequest {
  /**
   *
   * @type {Array<EmployeeRequest>}
   * @memberof BulkRequestEmployeeRequest
   */
  data?: Array<EmployeeRequest>;
}
/**
 *
 * @export
 * @interface BulkUpdateAdvanceTypeRequest
 */
export interface BulkUpdateAdvanceTypeRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof BulkUpdateAdvanceTypeRequest
   */
  employeeIds: Array<string>;
  /**
   *
   * @type {string}
   * @memberof BulkUpdateAdvanceTypeRequest
   */
  salaryAdvanceTypeId: string;
}
/**
 *
 * @export
 * @interface CheckEnterpriseResponse
 */
export interface CheckEnterpriseResponse {
  /**
   *
   * @type {string}
   * @memberof CheckEnterpriseResponse
   */
  status?: CheckEnterpriseResponseStatusEnum;
}

export const CheckEnterpriseResponseStatusEnum = {
  NotRegister: "NOT_REGISTER",
  WaitingApproval: "WAITING_APPROVAL",
  Ok: "OK",
  Deactivated: "DEACTIVATED",
  CmsLocked: "CMS_LOCKED",
  OkButNotConfig: "OK_BUT_NOT_CONFIG",
} as const;

export type CheckEnterpriseResponseStatusEnum =
  (typeof CheckEnterpriseResponseStatusEnum)[keyof typeof CheckEnterpriseResponseStatusEnum];

/**
 *
 * @export
 * @interface CheckTermAndConditionResponse
 */
export interface CheckTermAndConditionResponse {
  /**
   *
   * @type {boolean}
   * @memberof CheckTermAndConditionResponse
   */
  agreed?: boolean;
}
/**
 *
 * @export
 * @interface CmsAdvanceRequestDetail
 */
export interface CmsAdvanceRequestDetail {
  /**
   *
   * @type {string}
   * @memberof CmsAdvanceRequestDetail
   */
  enterpriseCode?: string;
  /**
   *
   * @type {string}
   * @memberof CmsAdvanceRequestDetail
   */
  enterpriseName?: string;
  /**
   *
   * @type {string}
   * @memberof CmsAdvanceRequestDetail
   */
  programType?: CmsAdvanceRequestDetailProgramTypeEnum;
  /**
   *
   * @type {string}
   * @memberof CmsAdvanceRequestDetail
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof CmsAdvanceRequestDetail
   */
  employeeCode?: string;
  /**
   *
   * @type {string}
   * @memberof CmsAdvanceRequestDetail
   */
  employeeName?: string;
  /**
   *
   * @type {string}
   * @memberof CmsAdvanceRequestDetail
   */
  periodName?: string;
  /**
   *
   * @type {string}
   * @memberof CmsAdvanceRequestDetail
   */
  periodStartDate?: string;
  /**
   *
   * @type {string}
   * @memberof CmsAdvanceRequestDetail
   */
  periodEndDate?: string;
  /**
   *
   * @type {string}
   * @memberof CmsAdvanceRequestDetail
   */
  status?: CmsAdvanceRequestDetailStatusEnum;
  /**
   *
   * @type {number}
   * @memberof CmsAdvanceRequestDetail
   */
  amount?: number;
  /**
   *
   * @type {number}
   * @memberof CmsAdvanceRequestDetail
   */
  createdDate?: number;
  /**
   *
   * @type {number}
   * @memberof CmsAdvanceRequestDetail
   */
  approvedDate?: number;
  /**
   *
   * @type {string}
   * @memberof CmsAdvanceRequestDetail
   */
  transactionNumber?: string;
  /**
   *
   * @type {string}
   * @memberof CmsAdvanceRequestDetail
   */
  fromAccount?: string;
  /**
   *
   * @type {string}
   * @memberof CmsAdvanceRequestDetail
   */
  toAccount?: string;
  /**
   *
   * @type {number}
   * @memberof CmsAdvanceRequestDetail
   */
  realAmount?: number;
  /**
   *
   * @type {number}
   * @memberof CmsAdvanceRequestDetail
   */
  fee?: number;
  /**
   *
   * @type {number}
   * @memberof CmsAdvanceRequestDetail
   */
  vat?: number;
  /**
   *
   * @type {string}
   * @memberof CmsAdvanceRequestDetail
   */
  paymentStatus?: CmsAdvanceRequestDetailPaymentStatusEnum;
  /**
   *
   * @type {number}
   * @memberof CmsAdvanceRequestDetail
   */
  paymentDate?: number;
  /**
   *
   * @type {string}
   * @memberof CmsAdvanceRequestDetail
   */
  description?: string;
}

export const CmsAdvanceRequestDetailProgramTypeEnum = {
  UseOverdraft: "FLEX_SALARY_USE_OVERDRAFT",
  NotUseOverdraft: "FLEX_SALARY_NOT_USE_OVERDRAFT",
} as const;

export type CmsAdvanceRequestDetailProgramTypeEnum =
  (typeof CmsAdvanceRequestDetailProgramTypeEnum)[keyof typeof CmsAdvanceRequestDetailProgramTypeEnum];
export const CmsAdvanceRequestDetailStatusEnum = {
  Approved: "APPROVED",
  Rejected: "REJECTED",
  WaitingApproval: "WAITING_APPROVAL",
  Canceled: "CANCELED",
  Expired: "EXPIRED",
} as const;

export type CmsAdvanceRequestDetailStatusEnum =
  (typeof CmsAdvanceRequestDetailStatusEnum)[keyof typeof CmsAdvanceRequestDetailStatusEnum];
export const CmsAdvanceRequestDetailPaymentStatusEnum = {
  Success: "SUCCESS",
  Failed: "FAILED",
  Processing: "PROCESSING",
  Todo: "TODO",
  None: "NONE",
} as const;

export type CmsAdvanceRequestDetailPaymentStatusEnum =
  (typeof CmsAdvanceRequestDetailPaymentStatusEnum)[keyof typeof CmsAdvanceRequestDetailPaymentStatusEnum];

/**
 *
 * @export
 * @interface CmsAdvanceRequestInfo
 */
export interface CmsAdvanceRequestInfo {
  /**
   *
   * @type {string}
   * @memberof CmsAdvanceRequestInfo
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CmsAdvanceRequestInfo
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof CmsAdvanceRequestInfo
   */
  status?: CmsAdvanceRequestInfoStatusEnum;
  /**
   *
   * @type {string}
   * @memberof CmsAdvanceRequestInfo
   */
  paymentStatus?: CmsAdvanceRequestInfoPaymentStatusEnum;
  /**
   *
   * @type {number}
   * @memberof CmsAdvanceRequestInfo
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof CmsAdvanceRequestInfo
   */
  fromAccount?: string;
  /**
   *
   * @type {string}
   * @memberof CmsAdvanceRequestInfo
   */
  toAccount?: string;
  /**
   *
   * @type {string}
   * @memberof CmsAdvanceRequestInfo
   */
  enterpriseName?: string;
  /**
   *
   * @type {string}
   * @memberof CmsAdvanceRequestInfo
   */
  employeeName?: string;
  /**
   *
   * @type {number}
   * @memberof CmsAdvanceRequestInfo
   */
  createdDate?: number;
  /**
   *
   * @type {number}
   * @memberof CmsAdvanceRequestInfo
   */
  approvedDate?: number;
}

export const CmsAdvanceRequestInfoStatusEnum = {
  Approved: "APPROVED",
  Rejected: "REJECTED",
  WaitingApproval: "WAITING_APPROVAL",
  Canceled: "CANCELED",
  Expired: "EXPIRED",
} as const;

export type CmsAdvanceRequestInfoStatusEnum =
  (typeof CmsAdvanceRequestInfoStatusEnum)[keyof typeof CmsAdvanceRequestInfoStatusEnum];
export const CmsAdvanceRequestInfoPaymentStatusEnum = {
  Success: "SUCCESS",
  Failed: "FAILED",
  Processing: "PROCESSING",
  Todo: "TODO",
  None: "NONE",
} as const;

export type CmsAdvanceRequestInfoPaymentStatusEnum =
  (typeof CmsAdvanceRequestInfoPaymentStatusEnum)[keyof typeof CmsAdvanceRequestInfoPaymentStatusEnum];

/**
 *
 * @export
 * @interface CmsEmployeePaidInfo
 */
export interface CmsEmployeePaidInfo {
  /**
   *
   * @type {string}
   * @memberof CmsEmployeePaidInfo
   */
  employeeId?: string;
  /**
   *
   * @type {string}
   * @memberof CmsEmployeePaidInfo
   */
  employeeCode?: string;
  /**
   *
   * @type {string}
   * @memberof CmsEmployeePaidInfo
   */
  employeeName?: string;
  /**
   *
   * @type {string}
   * @memberof CmsEmployeePaidInfo
   */
  employeePosition?: string;
  /**
   *
   * @type {string}
   * @memberof CmsEmployeePaidInfo
   */
  employeeSalaryAccount?: string;
  /**
   *
   * @type {number}
   * @memberof CmsEmployeePaidInfo
   */
  totalAdvancedAmount?: number;
  /**
   *
   * @type {number}
   * @memberof CmsEmployeePaidInfo
   */
  totalPaidAmount?: number;
  /**
   *
   * @type {number}
   * @memberof CmsEmployeePaidInfo
   */
  totalFeeAmount?: number;
}
/**
 *
 * @export
 * @interface CmsEnterpriseDetail
 */
export interface CmsEnterpriseDetail {
  /**
   *
   * @type {string}
   * @memberof CmsEnterpriseDetail
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CmsEnterpriseDetail
   */
  enterpriseName?: string;
  /**
   *
   * @type {string}
   * @memberof CmsEnterpriseDetail
   */
  enterpriseCode?: string;
  /**
   *
   * @type {string}
   * @memberof CmsEnterpriseDetail
   */
  ownerName?: string;
  /**
   *
   * @type {number}
   * @memberof CmsEnterpriseDetail
   */
  numberOfEmployeeExpected?: number;
  /**
   *
   * @type {number}
   * @memberof CmsEnterpriseDetail
   */
  numberOfEmployee?: number;
  /**
   *
   * @type {string}
   * @memberof CmsEnterpriseDetail
   */
  programType?: CmsEnterpriseDetailProgramTypeEnum;
  /**
   *
   * @type {string}
   * @memberof CmsEnterpriseDetail
   */
  accountNumber?: string;
  /**
   *
   * @type {boolean}
   * @memberof CmsEnterpriseDetail
   */
  cmsLocked?: boolean;
  /**
   *
   * @type {string}
   * @memberof CmsEnterpriseDetail
   */
  createdDate?: string;
  /**
   *
   * @type {string}
   * @memberof CmsEnterpriseDetail
   */
  overdraftEndDate?: string;
  /**
   *
   * @type {number}
   * @memberof CmsEnterpriseDetail
   */
  paySalaryLimitation?: number;
  /**
   *
   * @type {string}
   * @memberof CmsEnterpriseDetail
   */
  paymentSource?: CmsEnterpriseDetailPaymentSourceEnum;
  /**
   *
   * @type {string}
   * @memberof CmsEnterpriseDetail
   */
  accountName?: string;
  /**
   *
   * @type {string}
   * @memberof CmsEnterpriseDetail
   */
  bankName?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CmsEnterpriseDetail
   */
  documentUrls?: Array<string>;
  /**
   *
   * @type {Array<EnterpriseAuditData>}
   * @memberof CmsEnterpriseDetail
   */
  audit?: Array<EnterpriseAuditData>;
}

export const CmsEnterpriseDetailProgramTypeEnum = {
  UseOverdraft: "FLEX_SALARY_USE_OVERDRAFT",
  NotUseOverdraft: "FLEX_SALARY_NOT_USE_OVERDRAFT",
} as const;

export type CmsEnterpriseDetailProgramTypeEnum =
  (typeof CmsEnterpriseDetailProgramTypeEnum)[keyof typeof CmsEnterpriseDetailProgramTypeEnum];
export const CmsEnterpriseDetailPaymentSourceEnum = {
  BusinessAccount: "BUSINESS_ACCOUNT",
} as const;

export type CmsEnterpriseDetailPaymentSourceEnum =
  (typeof CmsEnterpriseDetailPaymentSourceEnum)[keyof typeof CmsEnterpriseDetailPaymentSourceEnum];

/**
 *
 * @export
 * @interface CmsEnterpriseInfo
 */
export interface CmsEnterpriseInfo {
  /**
   *
   * @type {string}
   * @memberof CmsEnterpriseInfo
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CmsEnterpriseInfo
   */
  enterpriseName?: string;
  /**
   *
   * @type {string}
   * @memberof CmsEnterpriseInfo
   */
  enterpriseCode?: string;
  /**
   *
   * @type {string}
   * @memberof CmsEnterpriseInfo
   */
  ownerName?: string;
  /**
   *
   * @type {number}
   * @memberof CmsEnterpriseInfo
   */
  numberOfEmployeeExpected?: number;
  /**
   *
   * @type {number}
   * @memberof CmsEnterpriseInfo
   */
  numberOfEmployee?: number;
  /**
   *
   * @type {string}
   * @memberof CmsEnterpriseInfo
   */
  programType?: CmsEnterpriseInfoProgramTypeEnum;
  /**
   *
   * @type {string}
   * @memberof CmsEnterpriseInfo
   */
  accountNumber?: string;
  /**
   *
   * @type {boolean}
   * @memberof CmsEnterpriseInfo
   */
  cmsLocked?: boolean;
}

export const CmsEnterpriseInfoProgramTypeEnum = {
  UseOverdraft: "FLEX_SALARY_USE_OVERDRAFT",
  NotUseOverdraft: "FLEX_SALARY_NOT_USE_OVERDRAFT",
} as const;

export type CmsEnterpriseInfoProgramTypeEnum =
  (typeof CmsEnterpriseInfoProgramTypeEnum)[keyof typeof CmsEnterpriseInfoProgramTypeEnum];

/**
 *
 * @export
 * @interface CmsEnterprisePaidInfo
 */
export interface CmsEnterprisePaidInfo {
  /**
   *
   * @type {string}
   * @memberof CmsEnterprisePaidInfo
   */
  enterpriseId?: string;
  /**
   *
   * @type {string}
   * @memberof CmsEnterprisePaidInfo
   */
  enterpriseCode?: string;
  /**
   *
   * @type {string}
   * @memberof CmsEnterprisePaidInfo
   */
  enterpriseName?: string;
  /**
   *
   * @type {number}
   * @memberof CmsEnterprisePaidInfo
   */
  numberOfAdvancedEmployee?: number;
  /**
   *
   * @type {number}
   * @memberof CmsEnterprisePaidInfo
   */
  numberOfAdvancedRequest?: number;
  /**
   *
   * @type {number}
   * @memberof CmsEnterprisePaidInfo
   */
  totalAdvancedAmount?: number;
  /**
   *
   * @type {number}
   * @memberof CmsEnterprisePaidInfo
   */
  totalPaidAmount?: number;
  /**
   *
   * @type {number}
   * @memberof CmsEnterprisePaidInfo
   */
  totalFeeAmount?: number;
}
/**
 *
 * @export
 * @interface CmsEnterpriseRequest
 */
export interface CmsEnterpriseRequest {
  /**
   *
   * @type {string}
   * @memberof CmsEnterpriseRequest
   */
  programType: CmsEnterpriseRequestProgramTypeEnum;
  /**
   *
   * @type {string}
   * @memberof CmsEnterpriseRequest
   */
  paymentSource: CmsEnterpriseRequestPaymentSourceEnum;
  /**
   *
   * @type {number}
   * @memberof CmsEnterpriseRequest
   */
  numberOfEmployeeExpected: number;
  /**
   *
   * @type {number}
   * @memberof CmsEnterpriseRequest
   */
  limitAmount: number;
  /**
   *
   * @type {string}
   * @memberof CmsEnterpriseRequest
   */
  accountNumber: string;
  /**
   *
   * @type {string}
   * @memberof CmsEnterpriseRequest
   */
  accountName: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CmsEnterpriseRequest
   */
  documentUrls: Array<string>;
}

export const CmsEnterpriseRequestProgramTypeEnum = {
  UseOverdraft: "FLEX_SALARY_USE_OVERDRAFT",
  NotUseOverdraft: "FLEX_SALARY_NOT_USE_OVERDRAFT",
} as const;

export type CmsEnterpriseRequestProgramTypeEnum =
  (typeof CmsEnterpriseRequestProgramTypeEnum)[keyof typeof CmsEnterpriseRequestProgramTypeEnum];
export const CmsEnterpriseRequestPaymentSourceEnum = {
  BusinessAccount: "BUSINESS_ACCOUNT",
} as const;

export type CmsEnterpriseRequestPaymentSourceEnum =
  (typeof CmsEnterpriseRequestPaymentSourceEnum)[keyof typeof CmsEnterpriseRequestPaymentSourceEnum];

/**
 *
 * @export
 * @interface CmsEnterpriseStatisticResponse
 */
export interface CmsEnterpriseStatisticResponse {
  /**
   *
   * @type {string}
   * @memberof CmsEnterpriseStatisticResponse
   */
  enterpriseId?: string;
  /**
   *
   * @type {string}
   * @memberof CmsEnterpriseStatisticResponse
   */
  enterpriseName?: string;
  /**
   *
   * @type {Array<CmsEmployeePaidInfo>}
   * @memberof CmsEnterpriseStatisticResponse
   */
  data?: Array<CmsEmployeePaidInfo>;
}
/**
 *
 * @export
 * @interface CmsLockEnterpriseRequest
 */
export interface CmsLockEnterpriseRequest {
  /**
   *
   * @type {string}
   * @memberof CmsLockEnterpriseRequest
   */
  reason: string;
  /**
   *
   * @type {string}
   * @memberof CmsLockEnterpriseRequest
   */
  lockDurationType: CmsLockEnterpriseRequestLockDurationTypeEnum;
  /**
   *
   * @type {string}
   * @memberof CmsLockEnterpriseRequest
   */
  description?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CmsLockEnterpriseRequest
   */
  documentUrls?: Array<string>;
}

export const CmsLockEnterpriseRequestLockDurationTypeEnum = {
  _7Days: "LOCK_7_DAYS",
  _14Days: "LOCK_14_DAYS",
  _30Days: "LOCK_30_DAYS",
  _60Days: "LOCK_60_DAYS",
  Forever: "LOCK_FOREVER",
} as const;

export type CmsLockEnterpriseRequestLockDurationTypeEnum =
  (typeof CmsLockEnterpriseRequestLockDurationTypeEnum)[keyof typeof CmsLockEnterpriseRequestLockDurationTypeEnum];

/**
 *
 * @export
 * @interface CmsPaidChartItem
 */
export interface CmsPaidChartItem {
  /**
   *
   * @type {string}
   * @memberof CmsPaidChartItem
   */
  date?: string;
  /**
   *
   * @type {number}
   * @memberof CmsPaidChartItem
   */
  paidAmount?: number;
}
/**
 *
 * @export
 * @interface CmsStatisticResponse
 */
export interface CmsStatisticResponse {
  /**
   *
   * @type {number}
   * @memberof CmsStatisticResponse
   */
  numberOfActivatedEnterprises?: number;
  /**
   *
   * @type {number}
   * @memberof CmsStatisticResponse
   */
  numberOfDeactivatedEnterprises?: number;
  /**
   *
   * @type {number}
   * @memberof CmsStatisticResponse
   */
  numberOfEmployees?: number;
  /**
   *
   * @type {number}
   * @memberof CmsStatisticResponse
   */
  totalRequestAdvancedAmount?: number;
  /**
   *
   * @type {number}
   * @memberof CmsStatisticResponse
   */
  totalPaidAmount?: number;
  /**
   *
   * @type {Array<TopEnterpriseItem>}
   * @memberof CmsStatisticResponse
   */
  topEnterprises?: Array<TopEnterpriseItem>;
}
/**
 *
 * @export
 * @interface CreateAdvanceResponse
 */
export interface CreateAdvanceResponse {
  /**
   *
   * @type {string}
   * @memberof CreateAdvanceResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CreateAdvanceResponse
   */
  status?: CreateAdvanceResponseStatusEnum;
  /**
   *
   * @type {string}
   * @memberof CreateAdvanceResponse
   */
  code?: string;
}

export const CreateAdvanceResponseStatusEnum = {
  Success: "SUCCESS",
  WaitingApproval: "WAITING_APPROVAL",
} as const;

export type CreateAdvanceResponseStatusEnum =
  (typeof CreateAdvanceResponseStatusEnum)[keyof typeof CreateAdvanceResponseStatusEnum];

/**
 *
 * @export
 * @interface CreatedResponse
 */
export interface CreatedResponse {
  /**
   *
   * @type {string}
   * @memberof CreatedResponse
   */
  id?: string;
}
/**
 *
 * @export
 * @interface DayOfWeekItem
 */
export interface DayOfWeekItem {
  /**
   *
   * @type {string}
   * @memberof DayOfWeekItem
   */
  dayOfWeek?: DayOfWeekItemDayOfWeekEnum;
  /**
   *
   * @type {string}
   * @memberof DayOfWeekItem
   */
  dateString?: string;
  /**
   *
   * @type {boolean}
   * @memberof DayOfWeekItem
   */
  workDate?: boolean;
}

export const DayOfWeekItemDayOfWeekEnum = {
  Monday: "MONDAY",
  Tuesday: "TUESDAY",
  Wednesday: "WEDNESDAY",
  Thursday: "THURSDAY",
  Friday: "FRIDAY",
  Saturday: "SATURDAY",
  Sunday: "SUNDAY",
} as const;

export type DayOfWeekItemDayOfWeekEnum =
  (typeof DayOfWeekItemDayOfWeekEnum)[keyof typeof DayOfWeekItemDayOfWeekEnum];

/**
 *
 * @export
 * @interface EmployeeCheckinDto
 */
export interface EmployeeCheckinDto {
  /**
   *
   * @type {string}
   * @memberof EmployeeCheckinDto
   */
  employeeCode?: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeCheckinDto
   */
  date?: string;
  /**
   *
   * @type {number}
   * @memberof EmployeeCheckinDto
   */
  checkInAt?: number;
  /**
   *
   * @type {number}
   * @memberof EmployeeCheckinDto
   */
  checkOutAt?: number;
}
/**
 *
 * @export
 * @interface EmployeeCheckinResponse
 */
export interface EmployeeCheckinResponse {
  /**
   *
   * @type {string}
   * @memberof EmployeeCheckinResponse
   */
  employeeCode?: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeCheckinResponse
   */
  date?: string;
  /**
   *
   * @type {number}
   * @memberof EmployeeCheckinResponse
   */
  errorCode?: number;
  /**
   *
   * @type {string}
   * @memberof EmployeeCheckinResponse
   */
  message?: string;
}
/**
 *
 * @export
 * @interface EmployeeInfo
 */
export interface EmployeeInfo {
  /**
   *
   * @type {string}
   * @memberof EmployeeInfo
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof EmployeeInfo
   */
  remainSalaryAdvanceLimit?: number;
  /**
   *
   * @type {string}
   * @memberof EmployeeInfo
   */
  dueDate?: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeInfo
   */
  position?: string;
  /**
   *
   * @type {number}
   * @memberof EmployeeInfo
   */
  advancedAmount?: number;
  /**
   *
   * @type {number}
   * @memberof EmployeeInfo
   */
  temporarySalary?: number;
}
/**
 *
 * @export
 * @interface EmployeePaidInfo
 */
export interface EmployeePaidInfo {
  /**
   *
   * @type {string}
   * @memberof EmployeePaidInfo
   */
  employeeId?: string;
  /**
   *
   * @type {string}
   * @memberof EmployeePaidInfo
   */
  employeeCode?: string;
  /**
   *
   * @type {string}
   * @memberof EmployeePaidInfo
   */
  employeeName?: string;
  /**
   *
   * @type {string}
   * @memberof EmployeePaidInfo
   */
  employeePosition?: string;
  /**
   *
   * @type {number}
   * @memberof EmployeePaidInfo
   */
  totalAdvancedAmount?: number;
  /**
   *
   * @type {number}
   * @memberof EmployeePaidInfo
   */
  totalPaidAmount?: number;
  /**
   *
   * @type {string}
   * @memberof EmployeePaidInfo
   */
  salaryPeriodName?: string;
}
/**
 *
 * @export
 * @interface EmployeeRequest
 */
export interface EmployeeRequest {
  /**
   *
   * @type {string}
   * @memberof EmployeeRequest
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeRequest
   */
  birthDate?: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeRequest
   */
  idCardNumber?: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeRequest
   */
  phoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeRequest
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeRequest
   */
  salaryAdvanceTypeId: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeRequest
   */
  salaryAdvanceTypeName?: string;
  /**
   *
   * @type {number}
   * @memberof EmployeeRequest
   */
  baseAmount: number;
  /**
   *
   * @type {boolean}
   * @memberof EmployeeRequest
   */
  activated: boolean;
  /**
   *
   * @type {string}
   * @memberof EmployeeRequest
   */
  salaryAccount: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeRequest
   */
  position: string;
}
/**
 *
 * @export
 * @interface EnterpriseAuditData
 */
export interface EnterpriseAuditData {
  /**
   *
   * @type {boolean}
   * @memberof EnterpriseAuditData
   */
  cmsLocked?: boolean;
  /**
   *
   * @type {string}
   * @memberof EnterpriseAuditData
   */
  userId?: string;
  /**
   *
   * @type {string}
   * @memberof EnterpriseAuditData
   */
  userName?: string;
  /**
   *
   * @type {number}
   * @memberof EnterpriseAuditData
   */
  auditTime?: number;
  /**
   *
   * @type {string}
   * @memberof EnterpriseAuditData
   */
  lockDurationType?: EnterpriseAuditDataLockDurationTypeEnum;
  /**
   *
   * @type {string}
   * @memberof EnterpriseAuditData
   */
  reason?: string;
  /**
   *
   * @type {string}
   * @memberof EnterpriseAuditData
   */
  description?: string;
}

export const EnterpriseAuditDataLockDurationTypeEnum = {
  _7Days: "LOCK_7_DAYS",
  _14Days: "LOCK_14_DAYS",
  _30Days: "LOCK_30_DAYS",
  _60Days: "LOCK_60_DAYS",
  Forever: "LOCK_FOREVER",
} as const;

export type EnterpriseAuditDataLockDurationTypeEnum =
  (typeof EnterpriseAuditDataLockDurationTypeEnum)[keyof typeof EnterpriseAuditDataLockDurationTypeEnum];

/**
 *
 * @export
 * @interface EnterpriseConfigDetail
 */
export interface EnterpriseConfigDetail {
  /**
   *
   * @type {string}
   * @memberof EnterpriseConfigDetail
   */
  programType?: EnterpriseConfigDetailProgramTypeEnum;
  /**
   *
   * @type {string}
   * @memberof EnterpriseConfigDetail
   */
  paymentSource: EnterpriseConfigDetailPaymentSourceEnum;
  /**
   *
   * @type {string}
   * @memberof EnterpriseConfigDetail
   */
  accountNumber?: string;
  /**
   *
   * @type {string}
   * @memberof EnterpriseConfigDetail
   */
  accountName?: string;
  /**
   *
   * @type {string}
   * @memberof EnterpriseConfigDetail
   */
  bankName?: string;
  /**
   *
   * @type {number}
   * @memberof EnterpriseConfigDetail
   */
  limitAmount?: number;
  /**
   *
   * @type {string}
   * @memberof EnterpriseConfigDetail
   */
  currency?: EnterpriseConfigDetailCurrencyEnum;
  /**
   *
   * @type {number}
   * @memberof EnterpriseConfigDetail
   */
  periodStartDate?: number;
  /**
   *
   * @type {number}
   * @memberof EnterpriseConfigDetail
   */
  periodEndDate?: number;
  /**
   *
   * @type {number}
   * @memberof EnterpriseConfigDetail
   */
  paySalaryDate?: number;
  /**
   *
   * @type {number}
   * @memberof EnterpriseConfigDetail
   */
  workingDays?: number;
  /**
   *
   * @type {number}
   * @memberof EnterpriseConfigDetail
   */
  disableAdvanceBeforeEndDate?: number;
  /**
   *
   * @type {Array<AuditData>}
   * @memberof EnterpriseConfigDetail
   */
  audit?: Array<AuditData>;
}

export const EnterpriseConfigDetailProgramTypeEnum = {
  UseOverdraft: "FLEX_SALARY_USE_OVERDRAFT",
  NotUseOverdraft: "FLEX_SALARY_NOT_USE_OVERDRAFT",
} as const;

export type EnterpriseConfigDetailProgramTypeEnum =
  (typeof EnterpriseConfigDetailProgramTypeEnum)[keyof typeof EnterpriseConfigDetailProgramTypeEnum];
export const EnterpriseConfigDetailPaymentSourceEnum = {
  BusinessAccount: "BUSINESS_ACCOUNT",
} as const;

export type EnterpriseConfigDetailPaymentSourceEnum =
  (typeof EnterpriseConfigDetailPaymentSourceEnum)[keyof typeof EnterpriseConfigDetailPaymentSourceEnum];
export const EnterpriseConfigDetailCurrencyEnum = {
  Vnd: "VND",
} as const;

export type EnterpriseConfigDetailCurrencyEnum =
  (typeof EnterpriseConfigDetailCurrencyEnum)[keyof typeof EnterpriseConfigDetailCurrencyEnum];

/**
 *
 * @export
 * @interface EnterpriseConfigRequest
 */
export interface EnterpriseConfigRequest {
  /**
   *
   * @type {number}
   * @memberof EnterpriseConfigRequest
   */
  limitAmount?: number;
  /**
   *
   * @type {number}
   * @memberof EnterpriseConfigRequest
   */
  periodStartDate: number;
  /**
   *
   * @type {number}
   * @memberof EnterpriseConfigRequest
   */
  periodEndDate: number;
  /**
   *
   * @type {number}
   * @memberof EnterpriseConfigRequest
   */
  paySalaryDate: number;
  /**
   *
   * @type {number}
   * @memberof EnterpriseConfigRequest
   */
  workingDays: number;
  /**
   *
   * @type {number}
   * @memberof EnterpriseConfigRequest
   */
  disableAdvanceBeforeEndDate: number;
}
/**
 *
 * @export
 * @interface EnterpriseEmployeeDetail
 */
export interface EnterpriseEmployeeDetail {
  /**
   *
   * @type {string}
   * @memberof EnterpriseEmployeeDetail
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof EnterpriseEmployeeDetail
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof EnterpriseEmployeeDetail
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof EnterpriseEmployeeDetail
   */
  phoneNumber?: string;
  /**
   *
   * @type {string}
   * @memberof EnterpriseEmployeeDetail
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof EnterpriseEmployeeDetail
   */
  salaryAccount?: string;
  /**
   *
   * @type {boolean}
   * @memberof EnterpriseEmployeeDetail
   */
  activated?: boolean;
  /**
   *
   * @type {string}
   * @memberof EnterpriseEmployeeDetail
   */
  idCardNumber?: string;
  /**
   *
   * @type {SalaryAdvanceTypeInfo}
   * @memberof EnterpriseEmployeeDetail
   */
  salaryAdvanceType?: SalaryAdvanceTypeInfo;
  /**
   *
   * @type {number}
   * @memberof EnterpriseEmployeeDetail
   */
  baseAmount?: number;
  /**
   *
   * @type {number}
   * @memberof EnterpriseEmployeeDetail
   */
  salaryAdvanceMaxAmount?: number;
  /**
   *
   * @type {string}
   * @memberof EnterpriseEmployeeDetail
   */
  birthDate?: string;
  /**
   *
   * @type {number}
   * @memberof EnterpriseEmployeeDetail
   */
  advancedAmount?: number;
  /**
   *
   * @type {string}
   * @memberof EnterpriseEmployeeDetail
   */
  position?: string;
}
/**
 *
 * @export
 * @interface EnterpriseEmployeeInfo
 */
export interface EnterpriseEmployeeInfo {
  /**
   *
   * @type {string}
   * @memberof EnterpriseEmployeeInfo
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof EnterpriseEmployeeInfo
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof EnterpriseEmployeeInfo
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof EnterpriseEmployeeInfo
   */
  phoneNumber?: string;
  /**
   *
   * @type {string}
   * @memberof EnterpriseEmployeeInfo
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof EnterpriseEmployeeInfo
   */
  salaryAccount?: string;
  /**
   *
   * @type {boolean}
   * @memberof EnterpriseEmployeeInfo
   */
  activated?: boolean;
}
/**
 *
 * @export
 * @interface EnterpriseLogworkRequest
 */
export interface EnterpriseLogworkRequest {
  /**
   *
   * @type {string}
   * @memberof EnterpriseLogworkRequest
   */
  enterpriseId?: string;
  /**
   *
   * @type {Array<EmployeeCheckinDto>}
   * @memberof EnterpriseLogworkRequest
   */
  employees?: Array<EmployeeCheckinDto>;
}
/**
 *
 * @export
 * @interface EnterpriseRegistrationRequest
 */
export interface EnterpriseRegistrationRequest {
  /**
   *
   * @type {string}
   * @memberof EnterpriseRegistrationRequest
   */
  programType: EnterpriseRegistrationRequestProgramTypeEnum;
  /**
   *
   * @type {number}
   * @memberof EnterpriseRegistrationRequest
   */
  numberOfEmployeeExpected: number;
  /**
   *
   * @type {number}
   * @memberof EnterpriseRegistrationRequest
   */
  paySalaryLimitation: number;
}

export const EnterpriseRegistrationRequestProgramTypeEnum = {
  UseOverdraft: "FLEX_SALARY_USE_OVERDRAFT",
  NotUseOverdraft: "FLEX_SALARY_NOT_USE_OVERDRAFT",
} as const;

export type EnterpriseRegistrationRequestProgramTypeEnum =
  (typeof EnterpriseRegistrationRequestProgramTypeEnum)[keyof typeof EnterpriseRegistrationRequestProgramTypeEnum];

/**
 *
 * @export
 * @interface LogworkItem
 */
export interface LogworkItem {
  /**
   *
   * @type {string}
   * @memberof LogworkItem
   */
  date: string;
  /**
   *
   * @type {number}
   * @memberof LogworkItem
   */
  workload: number;
}
/**
 *
 * @export
 * @interface LogworkRecord
 */
export interface LogworkRecord {
  /**
   *
   * @type {string}
   * @memberof LogworkRecord
   */
  employeeCode: string;
  /**
   *
   * @type {string}
   * @memberof LogworkRecord
   */
  employeeName: string;
  /**
   *
   * @type {Array<LogworkItem>}
   * @memberof LogworkRecord
   */
  items: Array<LogworkItem>;
}
/**
 *
 * @export
 * @interface LogworkRequest
 */
export interface LogworkRequest {
  /**
   *
   * @type {Array<LogworkRecord>}
   * @memberof LogworkRequest
   */
  data: Array<LogworkRecord>;
  /**
   *
   * @type {boolean}
   * @memberof LogworkRequest
   */
  overrideOldData: boolean;
}
/**
 *
 * @export
 * @interface LogworkTableRecord
 */
export interface LogworkTableRecord {
  /**
   *
   * @type {string}
   * @memberof LogworkTableRecord
   */
  employeeCode?: string;
  /**
   *
   * @type {string}
   * @memberof LogworkTableRecord
   */
  employeeName?: string;
  /**
   *
   * @type {number}
   * @memberof LogworkTableRecord
   */
  numberOfWorkDays?: number;
  /**
   *
   * @type {number}
   * @memberof LogworkTableRecord
   */
  totalWorkDays?: number;
  /**
   *
   * @type {Array<number>}
   * @memberof LogworkTableRecord
   */
  logworks?: Array<number>;
}
/**
 *
 * @export
 * @interface LogworkTableRecordWithStatus
 */
export interface LogworkTableRecordWithStatus {
  /**
   *
   * @type {string}
   * @memberof LogworkTableRecordWithStatus
   */
  employeeCode?: string;
  /**
   *
   * @type {string}
   * @memberof LogworkTableRecordWithStatus
   */
  employeeName?: string;
  /**
   *
   * @type {number}
   * @memberof LogworkTableRecordWithStatus
   */
  numberOfWorkDays?: number;
  /**
   *
   * @type {number}
   * @memberof LogworkTableRecordWithStatus
   */
  totalWorkDays?: number;
  /**
   *
   * @type {Array<number>}
   * @memberof LogworkTableRecordWithStatus
   */
  logworks?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof LogworkTableRecordWithStatus
   */
  id?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof LogworkTableRecordWithStatus
   */
  status?: Array<string>;
}
/**
 *
 * @export
 * @interface LogworkTableResponse
 */
export interface LogworkTableResponse {
  /**
   *
   * @type {Array<DayOfWeekItem>}
   * @memberof LogworkTableResponse
   */
  listDaysOfWeek?: Array<DayOfWeekItem>;
  /**
   *
   * @type {PageDtoListLogworkTableRecord}
   * @memberof LogworkTableResponse
   */
  records?: PageDtoListLogworkTableRecord;
}
/**
 *
 * @export
 * @interface PageDtoListCmsAdvanceRequestInfo
 */
export interface PageDtoListCmsAdvanceRequestInfo {
  /**
   *
   * @type {number}
   * @memberof PageDtoListCmsAdvanceRequestInfo
   */
  page?: number;
  /**
   *
   * @type {number}
   * @memberof PageDtoListCmsAdvanceRequestInfo
   */
  size?: number;
  /**
   *
   * @type {number}
   * @memberof PageDtoListCmsAdvanceRequestInfo
   */
  totalElements?: number;
  /**
   *
   * @type {number}
   * @memberof PageDtoListCmsAdvanceRequestInfo
   */
  totalPages?: number;
  /**
   *
   * @type {Array<CmsAdvanceRequestInfo>}
   * @memberof PageDtoListCmsAdvanceRequestInfo
   */
  data?: Array<CmsAdvanceRequestInfo>;
}
/**
 *
 * @export
 * @interface PageDtoListCmsEnterpriseInfo
 */
export interface PageDtoListCmsEnterpriseInfo {
  /**
   *
   * @type {number}
   * @memberof PageDtoListCmsEnterpriseInfo
   */
  page?: number;
  /**
   *
   * @type {number}
   * @memberof PageDtoListCmsEnterpriseInfo
   */
  size?: number;
  /**
   *
   * @type {number}
   * @memberof PageDtoListCmsEnterpriseInfo
   */
  totalElements?: number;
  /**
   *
   * @type {number}
   * @memberof PageDtoListCmsEnterpriseInfo
   */
  totalPages?: number;
  /**
   *
   * @type {Array<CmsEnterpriseInfo>}
   * @memberof PageDtoListCmsEnterpriseInfo
   */
  data?: Array<CmsEnterpriseInfo>;
}
/**
 *
 * @export
 * @interface PageDtoListEmployeePaidInfo
 */
export interface PageDtoListEmployeePaidInfo {
  /**
   *
   * @type {number}
   * @memberof PageDtoListEmployeePaidInfo
   */
  page?: number;
  /**
   *
   * @type {number}
   * @memberof PageDtoListEmployeePaidInfo
   */
  size?: number;
  /**
   *
   * @type {number}
   * @memberof PageDtoListEmployeePaidInfo
   */
  totalElements?: number;
  /**
   *
   * @type {number}
   * @memberof PageDtoListEmployeePaidInfo
   */
  totalPages?: number;
  /**
   *
   * @type {Array<EmployeePaidInfo>}
   * @memberof PageDtoListEmployeePaidInfo
   */
  data?: Array<EmployeePaidInfo>;
}
/**
 *
 * @export
 * @interface PageDtoListEnterpriseEmployeeInfo
 */
export interface PageDtoListEnterpriseEmployeeInfo {
  /**
   *
   * @type {number}
   * @memberof PageDtoListEnterpriseEmployeeInfo
   */
  page?: number;
  /**
   *
   * @type {number}
   * @memberof PageDtoListEnterpriseEmployeeInfo
   */
  size?: number;
  /**
   *
   * @type {number}
   * @memberof PageDtoListEnterpriseEmployeeInfo
   */
  totalElements?: number;
  /**
   *
   * @type {number}
   * @memberof PageDtoListEnterpriseEmployeeInfo
   */
  totalPages?: number;
  /**
   *
   * @type {Array<EnterpriseEmployeeInfo>}
   * @memberof PageDtoListEnterpriseEmployeeInfo
   */
  data?: Array<EnterpriseEmployeeInfo>;
}
/**
 *
 * @export
 * @interface PageDtoListLogworkTableRecord
 */
export interface PageDtoListLogworkTableRecord {
  /**
   *
   * @type {number}
   * @memberof PageDtoListLogworkTableRecord
   */
  page?: number;
  /**
   *
   * @type {number}
   * @memberof PageDtoListLogworkTableRecord
   */
  size?: number;
  /**
   *
   * @type {number}
   * @memberof PageDtoListLogworkTableRecord
   */
  totalElements?: number;
  /**
   *
   * @type {number}
   * @memberof PageDtoListLogworkTableRecord
   */
  totalPages?: number;
  /**
   *
   * @type {Array<LogworkTableRecord>}
   * @memberof PageDtoListLogworkTableRecord
   */
  data?: Array<LogworkTableRecord>;
}
/**
 *
 * @export
 * @interface PageDtoListRegistrationInfo
 */
export interface PageDtoListRegistrationInfo {
  /**
   *
   * @type {number}
   * @memberof PageDtoListRegistrationInfo
   */
  page?: number;
  /**
   *
   * @type {number}
   * @memberof PageDtoListRegistrationInfo
   */
  size?: number;
  /**
   *
   * @type {number}
   * @memberof PageDtoListRegistrationInfo
   */
  totalElements?: number;
  /**
   *
   * @type {number}
   * @memberof PageDtoListRegistrationInfo
   */
  totalPages?: number;
  /**
   *
   * @type {Array<RegistrationInfo>}
   * @memberof PageDtoListRegistrationInfo
   */
  data?: Array<RegistrationInfo>;
}
/**
 *
 * @export
 * @interface PageDtoListSalaryAdvanceByEmployeeInfo
 */
export interface PageDtoListSalaryAdvanceByEmployeeInfo {
  /**
   *
   * @type {number}
   * @memberof PageDtoListSalaryAdvanceByEmployeeInfo
   */
  page?: number;
  /**
   *
   * @type {number}
   * @memberof PageDtoListSalaryAdvanceByEmployeeInfo
   */
  size?: number;
  /**
   *
   * @type {number}
   * @memberof PageDtoListSalaryAdvanceByEmployeeInfo
   */
  totalElements?: number;
  /**
   *
   * @type {number}
   * @memberof PageDtoListSalaryAdvanceByEmployeeInfo
   */
  totalPages?: number;
  /**
   *
   * @type {Array<SalaryAdvanceByEmployeeInfo>}
   * @memberof PageDtoListSalaryAdvanceByEmployeeInfo
   */
  data?: Array<SalaryAdvanceByEmployeeInfo>;
}
/**
 *
 * @export
 * @interface PageDtoListSalaryAdvanceInfo
 */
export interface PageDtoListSalaryAdvanceInfo {
  /**
   *
   * @type {number}
   * @memberof PageDtoListSalaryAdvanceInfo
   */
  page?: number;
  /**
   *
   * @type {number}
   * @memberof PageDtoListSalaryAdvanceInfo
   */
  size?: number;
  /**
   *
   * @type {number}
   * @memberof PageDtoListSalaryAdvanceInfo
   */
  totalElements?: number;
  /**
   *
   * @type {number}
   * @memberof PageDtoListSalaryAdvanceInfo
   */
  totalPages?: number;
  /**
   *
   * @type {Array<SalaryAdvanceInfo>}
   * @memberof PageDtoListSalaryAdvanceInfo
   */
  data?: Array<SalaryAdvanceInfo>;
}
/**
 *
 * @export
 * @interface PageDtoListSalaryAdvanceTypeInfo
 */
export interface PageDtoListSalaryAdvanceTypeInfo {
  /**
   *
   * @type {number}
   * @memberof PageDtoListSalaryAdvanceTypeInfo
   */
  page?: number;
  /**
   *
   * @type {number}
   * @memberof PageDtoListSalaryAdvanceTypeInfo
   */
  size?: number;
  /**
   *
   * @type {number}
   * @memberof PageDtoListSalaryAdvanceTypeInfo
   */
  totalElements?: number;
  /**
   *
   * @type {number}
   * @memberof PageDtoListSalaryAdvanceTypeInfo
   */
  totalPages?: number;
  /**
   *
   * @type {Array<SalaryAdvanceTypeInfo>}
   * @memberof PageDtoListSalaryAdvanceTypeInfo
   */
  data?: Array<SalaryAdvanceTypeInfo>;
}
/**
 *
 * @export
 * @interface PageDtoListSalaryPeriodInfo
 */
export interface PageDtoListSalaryPeriodInfo {
  /**
   *
   * @type {number}
   * @memberof PageDtoListSalaryPeriodInfo
   */
  page?: number;
  /**
   *
   * @type {number}
   * @memberof PageDtoListSalaryPeriodInfo
   */
  size?: number;
  /**
   *
   * @type {number}
   * @memberof PageDtoListSalaryPeriodInfo
   */
  totalElements?: number;
  /**
   *
   * @type {number}
   * @memberof PageDtoListSalaryPeriodInfo
   */
  totalPages?: number;
  /**
   *
   * @type {Array<SalaryPeriodInfo>}
   * @memberof PageDtoListSalaryPeriodInfo
   */
  data?: Array<SalaryPeriodInfo>;
}
/**
 *
 * @export
 * @interface PageDtoListShortSalaryPeriodInfo
 */
export interface PageDtoListShortSalaryPeriodInfo {
  /**
   *
   * @type {number}
   * @memberof PageDtoListShortSalaryPeriodInfo
   */
  page?: number;
  /**
   *
   * @type {number}
   * @memberof PageDtoListShortSalaryPeriodInfo
   */
  size?: number;
  /**
   *
   * @type {number}
   * @memberof PageDtoListShortSalaryPeriodInfo
   */
  totalElements?: number;
  /**
   *
   * @type {number}
   * @memberof PageDtoListShortSalaryPeriodInfo
   */
  totalPages?: number;
  /**
   *
   * @type {Array<ShortSalaryPeriodInfo>}
   * @memberof PageDtoListShortSalaryPeriodInfo
   */
  data?: Array<ShortSalaryPeriodInfo>;
}
/**
 *
 * @export
 * @interface PaidChartItem
 */
export interface PaidChartItem {
  /**
   *
   * @type {string}
   * @memberof PaidChartItem
   */
  date?: string;
  /**
   *
   * @type {number}
   * @memberof PaidChartItem
   */
  paid?: number;
}
/**
 *
 * @export
 * @interface PaidChartResponse
 */
export interface PaidChartResponse {
  /**
   *
   * @type {Array<PaidChartItem>}
   * @memberof PaidChartResponse
   */
  paidList?: Array<PaidChartItem>;
}
/**
 *
 * @export
 * @interface PaymentHistoryDetail
 */
export interface PaymentHistoryDetail {
  /**
   *
   * @type {string}
   * @memberof PaymentHistoryDetail
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentHistoryDetail
   */
  fromAccount?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentHistoryDetail
   */
  toAccount?: string;
  /**
   *
   * @type {number}
   * @memberof PaymentHistoryDetail
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof PaymentHistoryDetail
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentHistoryDetail
   */
  transactionNumber?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentHistoryDetail
   */
  transactionStatus?: string;
  /**
   *
   * @type {number}
   * @memberof PaymentHistoryDetail
   */
  createdDate?: number;
  /**
   *
   * @type {number}
   * @memberof PaymentHistoryDetail
   */
  completedTime?: number;
}
/**
 *
 * @export
 * @interface RegisterUserV2Request
 */
export interface RegisterUserV2Request {
  /**
   *
   * @type {string}
   * @memberof RegisterUserV2Request
   */
  phoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof RegisterUserV2Request
   */
  name: string;
}
/**
 *
 * @export
 * @interface RegistrationAuditData
 */
export interface RegistrationAuditData {
  /**
   *
   * @type {string}
   * @memberof RegistrationAuditData
   */
  action?: RegistrationAuditDataActionEnum;
  /**
   *
   * @type {string}
   * @memberof RegistrationAuditData
   */
  status?: RegistrationAuditDataStatusEnum;
  /**
   *
   * @type {string}
   * @memberof RegistrationAuditData
   */
  userId?: string;
  /**
   *
   * @type {string}
   * @memberof RegistrationAuditData
   */
  userName?: string;
  /**
   *
   * @type {number}
   * @memberof RegistrationAuditData
   */
  auditTime?: number;
  /**
   *
   * @type {string}
   * @memberof RegistrationAuditData
   */
  rejectReason?: string;
  /**
   *
   * @type {string}
   * @memberof RegistrationAuditData
   */
  note?: string;
}

export const RegistrationAuditDataActionEnum = {
  Approve: "APPROVE",
  Reject: "REJECT",
} as const;

export type RegistrationAuditDataActionEnum =
  (typeof RegistrationAuditDataActionEnum)[keyof typeof RegistrationAuditDataActionEnum];
export const RegistrationAuditDataStatusEnum = {
  WaitingApproval: "WAITING_APPROVAL",
  Approved: "APPROVED",
  Rejected: "REJECTED",
  Cancel: "CANCEL",
} as const;

export type RegistrationAuditDataStatusEnum =
  (typeof RegistrationAuditDataStatusEnum)[keyof typeof RegistrationAuditDataStatusEnum];

/**
 *
 * @export
 * @interface RegistrationDetail
 */
export interface RegistrationDetail {
  /**
   *
   * @type {string}
   * @memberof RegistrationDetail
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof RegistrationDetail
   */
  enterpriseId?: string;
  /**
   *
   * @type {string}
   * @memberof RegistrationDetail
   */
  enterpriseName?: string;
  /**
   *
   * @type {string}
   * @memberof RegistrationDetail
   */
  enterpriseCode?: string;
  /**
   *
   * @type {string}
   * @memberof RegistrationDetail
   */
  ownerName?: string;
  /**
   *
   * @type {string}
   * @memberof RegistrationDetail
   */
  programType?: RegistrationDetailProgramTypeEnum;
  /**
   *
   * @type {number}
   * @memberof RegistrationDetail
   */
  numberOfEmployeeExpected?: number;
  /**
   *
   * @type {number}
   * @memberof RegistrationDetail
   */
  paySalaryLimitation?: number;
  /**
   *
   * @type {string}
   * @memberof RegistrationDetail
   */
  rejectReason?: string;
  /**
   *
   * @type {string}
   * @memberof RegistrationDetail
   */
  status?: RegistrationDetailStatusEnum;
  /**
   *
   * @type {number}
   * @memberof RegistrationDetail
   */
  createdDate?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof RegistrationDetail
   */
  documentUrls?: Array<string>;
  /**
   *
   * @type {Array<RegistrationAuditData>}
   * @memberof RegistrationDetail
   */
  audit?: Array<RegistrationAuditData>;
}

export const RegistrationDetailProgramTypeEnum = {
  UseOverdraft: "FLEX_SALARY_USE_OVERDRAFT",
  NotUseOverdraft: "FLEX_SALARY_NOT_USE_OVERDRAFT",
} as const;

export type RegistrationDetailProgramTypeEnum =
  (typeof RegistrationDetailProgramTypeEnum)[keyof typeof RegistrationDetailProgramTypeEnum];
export const RegistrationDetailStatusEnum = {
  WaitingApproval: "WAITING_APPROVAL",
  Approved: "APPROVED",
  Rejected: "REJECTED",
  Cancel: "CANCEL",
} as const;

export type RegistrationDetailStatusEnum =
  (typeof RegistrationDetailStatusEnum)[keyof typeof RegistrationDetailStatusEnum];

/**
 *
 * @export
 * @interface RegistrationInfo
 */
export interface RegistrationInfo {
  /**
   *
   * @type {string}
   * @memberof RegistrationInfo
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof RegistrationInfo
   */
  enterpriseId?: string;
  /**
   *
   * @type {string}
   * @memberof RegistrationInfo
   */
  enterpriseName?: string;
  /**
   *
   * @type {string}
   * @memberof RegistrationInfo
   */
  enterpriseCode?: string;
  /**
   *
   * @type {string}
   * @memberof RegistrationInfo
   */
  ownerName?: string;
  /**
   *
   * @type {string}
   * @memberof RegistrationInfo
   */
  programType?: RegistrationInfoProgramTypeEnum;
  /**
   *
   * @type {number}
   * @memberof RegistrationInfo
   */
  numberOfEmployeeExpected?: number;
  /**
   *
   * @type {number}
   * @memberof RegistrationInfo
   */
  paySalaryLimitation?: number;
  /**
   *
   * @type {string}
   * @memberof RegistrationInfo
   */
  rejectReason?: string;
  /**
   *
   * @type {string}
   * @memberof RegistrationInfo
   */
  status?: RegistrationInfoStatusEnum;
  /**
   *
   * @type {number}
   * @memberof RegistrationInfo
   */
  createdDate?: number;
}

export const RegistrationInfoProgramTypeEnum = {
  UseOverdraft: "FLEX_SALARY_USE_OVERDRAFT",
  NotUseOverdraft: "FLEX_SALARY_NOT_USE_OVERDRAFT",
} as const;

export type RegistrationInfoProgramTypeEnum =
  (typeof RegistrationInfoProgramTypeEnum)[keyof typeof RegistrationInfoProgramTypeEnum];
export const RegistrationInfoStatusEnum = {
  WaitingApproval: "WAITING_APPROVAL",
  Approved: "APPROVED",
  Rejected: "REJECTED",
  Cancel: "CANCEL",
} as const;

export type RegistrationInfoStatusEnum =
  (typeof RegistrationInfoStatusEnum)[keyof typeof RegistrationInfoStatusEnum];

/**
 *
 * @export
 * @interface RejectRegistrationRequest
 */
export interface RejectRegistrationRequest {
  /**
   *
   * @type {string}
   * @memberof RejectRegistrationRequest
   */
  rejectReason: string;
  /**
   *
   * @type {string}
   * @memberof RejectRegistrationRequest
   */
  rejectNote?: string;
}
/**
 *
 * @export
 * @interface RejectSalaryAdvanceRequest
 */
export interface RejectSalaryAdvanceRequest {
  /**
   *
   * @type {string}
   * @memberof RejectSalaryAdvanceRequest
   */
  description?: string;
}
/**
 *
 * @export
 * @interface SalaryAdvanceByEmployeeInfo
 */
export interface SalaryAdvanceByEmployeeInfo {
  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceByEmployeeInfo
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof SalaryAdvanceByEmployeeInfo
   */
  createdDate?: number;
  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceByEmployeeInfo
   */
  status?: SalaryAdvanceByEmployeeInfoStatusEnum;
  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceByEmployeeInfo
   */
  paymentStatus?: SalaryAdvanceByEmployeeInfoPaymentStatusEnum;
  /**
   *
   * @type {number}
   * @memberof SalaryAdvanceByEmployeeInfo
   */
  lastApproveReject?: number;
  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceByEmployeeInfo
   */
  lastApproveRejectByName?: string;
}

export const SalaryAdvanceByEmployeeInfoStatusEnum = {
  Approved: "APPROVED",
  Rejected: "REJECTED",
  WaitingApproval: "WAITING_APPROVAL",
  Canceled: "CANCELED",
  Expired: "EXPIRED",
} as const;

export type SalaryAdvanceByEmployeeInfoStatusEnum =
  (typeof SalaryAdvanceByEmployeeInfoStatusEnum)[keyof typeof SalaryAdvanceByEmployeeInfoStatusEnum];
export const SalaryAdvanceByEmployeeInfoPaymentStatusEnum = {
  Success: "SUCCESS",
  Failed: "FAILED",
  Processing: "PROCESSING",
  Todo: "TODO",
  None: "NONE",
} as const;

export type SalaryAdvanceByEmployeeInfoPaymentStatusEnum =
  (typeof SalaryAdvanceByEmployeeInfoPaymentStatusEnum)[keyof typeof SalaryAdvanceByEmployeeInfoPaymentStatusEnum];

/**
 *
 * @export
 * @interface SalaryAdvanceDetail
 */
export interface SalaryAdvanceDetail {
  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceDetail
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceDetail
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceDetail
   */
  employeeCode?: string;
  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceDetail
   */
  employeeName?: string;
  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceDetail
   */
  status?: SalaryAdvanceDetailStatusEnum;
  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceDetail
   */
  paymentStatus?: SalaryAdvanceDetailPaymentStatusEnum;
  /**
   *
   * @type {number}
   * @memberof SalaryAdvanceDetail
   */
  createdDate?: number;
  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceDetail
   */
  currency?: SalaryAdvanceDetailCurrencyEnum;
  /**
   *
   * @type {number}
   * @memberof SalaryAdvanceDetail
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceDetail
   */
  periodName?: string;
  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceDetail
   */
  rejectReason?: string;
  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceDetail
   */
  periodStartDate?: string;
  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceDetail
   */
  periodEndDate?: string;
  /**
   *
   * @type {number}
   * @memberof SalaryAdvanceDetail
   */
  salaryAdvanceMaxAmount?: number;
  /**
   *
   * @type {number}
   * @memberof SalaryAdvanceDetail
   */
  advancedAmount?: number;
  /**
   *
   * @type {number}
   * @memberof SalaryAdvanceDetail
   */
  pendingAmount?: number;
  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceDetail
   */
  beneficiaryAccount?: string;
  /**
   *
   * @type {Array<PaymentHistoryDetail>}
   * @memberof SalaryAdvanceDetail
   */
  paymentData?: Array<PaymentHistoryDetail>;
  /**
   *
   * @type {Array<AuditData>}
   * @memberof SalaryAdvanceDetail
   */
  audit?: Array<AuditData>;
}

export const SalaryAdvanceDetailStatusEnum = {
  Approved: "APPROVED",
  Rejected: "REJECTED",
  WaitingApproval: "WAITING_APPROVAL",
  Canceled: "CANCELED",
  Expired: "EXPIRED",
} as const;

export type SalaryAdvanceDetailStatusEnum =
  (typeof SalaryAdvanceDetailStatusEnum)[keyof typeof SalaryAdvanceDetailStatusEnum];
export const SalaryAdvanceDetailPaymentStatusEnum = {
  Success: "SUCCESS",
  Failed: "FAILED",
  Processing: "PROCESSING",
  Todo: "TODO",
  None: "NONE",
} as const;

export type SalaryAdvanceDetailPaymentStatusEnum =
  (typeof SalaryAdvanceDetailPaymentStatusEnum)[keyof typeof SalaryAdvanceDetailPaymentStatusEnum];
export const SalaryAdvanceDetailCurrencyEnum = {
  Vnd: "VND",
} as const;

export type SalaryAdvanceDetailCurrencyEnum =
  (typeof SalaryAdvanceDetailCurrencyEnum)[keyof typeof SalaryAdvanceDetailCurrencyEnum];

/**
 *
 * @export
 * @interface SalaryAdvanceInfo
 */
export interface SalaryAdvanceInfo {
  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceInfo
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceInfo
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceInfo
   */
  employeeCode?: string;
  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceInfo
   */
  employeeName?: string;
  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceInfo
   */
  status?: SalaryAdvanceInfoStatusEnum;
  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceInfo
   */
  paymentStatus?: SalaryAdvanceInfoPaymentStatusEnum;
  /**
   *
   * @type {number}
   * @memberof SalaryAdvanceInfo
   */
  createdDate?: number;
  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceInfo
   */
  currency?: SalaryAdvanceInfoCurrencyEnum;
  /**
   *
   * @type {number}
   * @memberof SalaryAdvanceInfo
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceInfo
   */
  periodName?: string;
}

export const SalaryAdvanceInfoStatusEnum = {
  Approved: "APPROVED",
  Rejected: "REJECTED",
  WaitingApproval: "WAITING_APPROVAL",
  Canceled: "CANCELED",
  Expired: "EXPIRED",
} as const;

export type SalaryAdvanceInfoStatusEnum =
  (typeof SalaryAdvanceInfoStatusEnum)[keyof typeof SalaryAdvanceInfoStatusEnum];
export const SalaryAdvanceInfoPaymentStatusEnum = {
  Success: "SUCCESS",
  Failed: "FAILED",
  Processing: "PROCESSING",
  Todo: "TODO",
  None: "NONE",
} as const;

export type SalaryAdvanceInfoPaymentStatusEnum =
  (typeof SalaryAdvanceInfoPaymentStatusEnum)[keyof typeof SalaryAdvanceInfoPaymentStatusEnum];
export const SalaryAdvanceInfoCurrencyEnum = {
  Vnd: "VND",
} as const;

export type SalaryAdvanceInfoCurrencyEnum =
  (typeof SalaryAdvanceInfoCurrencyEnum)[keyof typeof SalaryAdvanceInfoCurrencyEnum];

/**
 *
 * @export
 * @interface SalaryAdvanceTypeDetail
 */
export interface SalaryAdvanceTypeDetail {
  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceTypeDetail
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceTypeDetail
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof SalaryAdvanceTypeDetail
   */
  advancePercent?: number;
  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceTypeDetail
   */
  advanceForm?: SalaryAdvanceTypeDetailAdvanceFormEnum;
  /**
   *
   * @type {number}
   * @memberof SalaryAdvanceTypeDetail
   */
  frequencyLimit?: number;
  /**
   *
   * @type {Array<AuditData>}
   * @memberof SalaryAdvanceTypeDetail
   */
  audit?: Array<AuditData>;
  /**
   *
   * @type {boolean}
   * @memberof SalaryAdvanceTypeDetail
   */
  limitFrequency?: boolean;
}

export const SalaryAdvanceTypeDetailAdvanceFormEnum = {
  Auto: "AUTO",
  Manual: "MANUAL",
} as const;

export type SalaryAdvanceTypeDetailAdvanceFormEnum =
  (typeof SalaryAdvanceTypeDetailAdvanceFormEnum)[keyof typeof SalaryAdvanceTypeDetailAdvanceFormEnum];

/**
 *
 * @export
 * @interface SalaryAdvanceTypeInfo
 */
export interface SalaryAdvanceTypeInfo {
  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceTypeInfo
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceTypeInfo
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof SalaryAdvanceTypeInfo
   */
  advancePercent?: number;
  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceTypeInfo
   */
  advanceForm?: SalaryAdvanceTypeInfoAdvanceFormEnum;
  /**
   *
   * @type {number}
   * @memberof SalaryAdvanceTypeInfo
   */
  frequencyLimit?: number;
  /**
   *
   * @type {boolean}
   * @memberof SalaryAdvanceTypeInfo
   */
  limitFrequency?: boolean;
}

export const SalaryAdvanceTypeInfoAdvanceFormEnum = {
  Auto: "AUTO",
  Manual: "MANUAL",
} as const;

export type SalaryAdvanceTypeInfoAdvanceFormEnum =
  (typeof SalaryAdvanceTypeInfoAdvanceFormEnum)[keyof typeof SalaryAdvanceTypeInfoAdvanceFormEnum];

/**
 *
 * @export
 * @interface SalaryAdvanceTypeRequest
 */
export interface SalaryAdvanceTypeRequest {
  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceTypeRequest
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof SalaryAdvanceTypeRequest
   */
  advancePercent: number;
  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceTypeRequest
   */
  advanceForm: SalaryAdvanceTypeRequestAdvanceFormEnum;
  /**
   *
   * @type {boolean}
   * @memberof SalaryAdvanceTypeRequest
   */
  isLimitFrequency: boolean;
  /**
   *
   * @type {number}
   * @memberof SalaryAdvanceTypeRequest
   */
  frequencyLimit: number;
}

export const SalaryAdvanceTypeRequestAdvanceFormEnum = {
  Auto: "AUTO",
  Manual: "MANUAL",
} as const;

export type SalaryAdvanceTypeRequestAdvanceFormEnum =
  (typeof SalaryAdvanceTypeRequestAdvanceFormEnum)[keyof typeof SalaryAdvanceTypeRequestAdvanceFormEnum];

/**
 *
 * @export
 * @interface SalaryPeriodInfo
 */
export interface SalaryPeriodInfo {
  /**
   *
   * @type {string}
   * @memberof SalaryPeriodInfo
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof SalaryPeriodInfo
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof SalaryPeriodInfo
   */
  startDate?: string;
  /**
   *
   * @type {string}
   * @memberof SalaryPeriodInfo
   */
  endDate?: string;
  /**
   *
   * @type {number}
   * @memberof SalaryPeriodInfo
   */
  workingDays?: number;
}
/**
 *
 * @export
 * @interface SalaryPeriodRequest
 */
export interface SalaryPeriodRequest {
  /**
   *
   * @type {string}
   * @memberof SalaryPeriodRequest
   */
  month: string;
  /**
   *
   * @type {string}
   * @memberof SalaryPeriodRequest
   */
  startDate: string;
  /**
   *
   * @type {string}
   * @memberof SalaryPeriodRequest
   */
  endDate: string;
  /**
   *
   * @type {string}
   * @memberof SalaryPeriodRequest
   */
  paySalaryDate: string;
  /**
   *
   * @type {number}
   * @memberof SalaryPeriodRequest
   */
  workingDays: number;
}
/**
 *
 * @export
 * @interface ShortSalaryPeriodInfo
 */
export interface ShortSalaryPeriodInfo {
  /**
   *
   * @type {string}
   * @memberof ShortSalaryPeriodInfo
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ShortSalaryPeriodInfo
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ShortSalaryPeriodInfo
   */
  startDate?: string;
  /**
   *
   * @type {string}
   * @memberof ShortSalaryPeriodInfo
   */
  endDate?: string;
}
/**
 *
 * @export
 * @interface TopEnterpriseItem
 */
export interface TopEnterpriseItem {
  /**
   *
   * @type {string}
   * @memberof TopEnterpriseItem
   */
  enterpriseName?: string;
  /**
   *
   * @type {number}
   * @memberof TopEnterpriseItem
   */
  paidAmount?: number;
}
/**
 *
 * @export
 * @interface TotalStatisticResponse
 */
export interface TotalStatisticResponse {
  /**
   *
   * @type {number}
   * @memberof TotalStatisticResponse
   */
  numberOfEmployees?: number;
  /**
   *
   * @type {number}
   * @memberof TotalStatisticResponse
   */
  totalAdvanceRequestAmount?: number;
  /**
   *
   * @type {number}
   * @memberof TotalStatisticResponse
   */
  percentAdvanceRequestAmount?: number;
  /**
   *
   * @type {number}
   * @memberof TotalStatisticResponse
   */
  totalPaidAmount?: number;
  /**
   *
   * @type {number}
   * @memberof TotalStatisticResponse
   */
  percentTotalPaidAmount?: number;
}
/**
 *
 * @export
 * @interface UpdateLogworkRequest
 */
export interface UpdateLogworkRequest {
  /**
   *
   * @type {Array<LogworkRecord>}
   * @memberof UpdateLogworkRequest
   */
  data: Array<LogworkRecord>;
}
/**
 *
 * @export
 * @interface UploadEmployeeRecord
 */
export interface UploadEmployeeRecord {
  /**
   *
   * @type {string}
   * @memberof UploadEmployeeRecord
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof UploadEmployeeRecord
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof UploadEmployeeRecord
   */
  birthDate?: string;
  /**
   *
   * @type {string}
   * @memberof UploadEmployeeRecord
   */
  idCardNumber?: string;
  /**
   *
   * @type {string}
   * @memberof UploadEmployeeRecord
   */
  phoneNumber?: string;
  /**
   *
   * @type {string}
   * @memberof UploadEmployeeRecord
   */
  email?: string;
  /**
   *
   * @type {number}
   * @memberof UploadEmployeeRecord
   */
  baseAmount?: number;
  /**
   *
   * @type {string}
   * @memberof UploadEmployeeRecord
   */
  salaryAccount?: string;
  /**
   *
   * @type {string}
   * @memberof UploadEmployeeRecord
   */
  salaryAdvanceTypeId?: string;
  /**
   *
   * @type {string}
   * @memberof UploadEmployeeRecord
   */
  salaryAdvanceTypeName?: string;
  /**
   *
   * @type {string}
   * @memberof UploadEmployeeRecord
   */
  position?: string;
}
/**
 *
 * @export
 * @interface UploadEmployeeRecordWithStatus
 */
export interface UploadEmployeeRecordWithStatus {
  /**
   *
   * @type {string}
   * @memberof UploadEmployeeRecordWithStatus
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof UploadEmployeeRecordWithStatus
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof UploadEmployeeRecordWithStatus
   */
  birthDate?: string;
  /**
   *
   * @type {string}
   * @memberof UploadEmployeeRecordWithStatus
   */
  idCardNumber?: string;
  /**
   *
   * @type {string}
   * @memberof UploadEmployeeRecordWithStatus
   */
  phoneNumber?: string;
  /**
   *
   * @type {string}
   * @memberof UploadEmployeeRecordWithStatus
   */
  email?: string;
  /**
   *
   * @type {number}
   * @memberof UploadEmployeeRecordWithStatus
   */
  baseAmount?: number;
  /**
   *
   * @type {string}
   * @memberof UploadEmployeeRecordWithStatus
   */
  salaryAccount?: string;
  /**
   *
   * @type {string}
   * @memberof UploadEmployeeRecordWithStatus
   */
  salaryAdvanceTypeId?: string;
  /**
   *
   * @type {string}
   * @memberof UploadEmployeeRecordWithStatus
   */
  salaryAdvanceTypeName?: string;
  /**
   *
   * @type {string}
   * @memberof UploadEmployeeRecordWithStatus
   */
  position?: string;
  /**
   *
   * @type {number}
   * @memberof UploadEmployeeRecordWithStatus
   */
  id?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof UploadEmployeeRecordWithStatus
   */
  status?: Array<string>;
}
/**
 *
 * @export
 * @interface UploadEmployeeResponse
 */
export interface UploadEmployeeResponse {
  /**
   *
   * @type {Array<UploadEmployeeRecord>}
   * @memberof UploadEmployeeResponse
   */
  validItems?: Array<UploadEmployeeRecord>;
  /**
   *
   * @type {Array<UploadEmployeeRecordWithStatus>}
   * @memberof UploadEmployeeResponse
   */
  invalidItems?: Array<UploadEmployeeRecordWithStatus>;
}
/**
 *
 * @export
 * @interface UploadLogworkResponse
 */
export interface UploadLogworkResponse {
  /**
   *
   * @type {Array<DayOfWeekItem>}
   * @memberof UploadLogworkResponse
   */
  listDaysOfWeek?: Array<DayOfWeekItem>;
  /**
   *
   * @type {Array<LogworkTableRecord>}
   * @memberof UploadLogworkResponse
   */
  validItems?: Array<LogworkTableRecord>;
  /**
   *
   * @type {Array<LogworkTableRecordWithStatus>}
   * @memberof UploadLogworkResponse
   */
  invalidItems?: Array<LogworkTableRecordWithStatus>;
}
/**
 *
 * @export
 * @interface UserEnterpriseInfo
 */
export interface UserEnterpriseInfo {
  /**
   *
   * @type {string}
   * @memberof UserEnterpriseInfo
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof UserEnterpriseInfo
   */
  logo?: string;
  /**
   *
   * @type {string}
   * @memberof UserEnterpriseInfo
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof UserEnterpriseInfo
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof UserEnterpriseInfo
   */
  employeeCode?: string;
  /**
   *
   * @type {string}
   * @memberof UserEnterpriseInfo
   */
  employeeName?: string;
  /**
   *
   * @type {string}
   * @memberof UserEnterpriseInfo
   */
  employeePosition?: string;
}
/**
 *
 * @export
 * @interface UserLogworkByPeriodInfo
 */
export interface UserLogworkByPeriodInfo {
  /**
   *
   * @type {SalaryPeriodInfo}
   * @memberof UserLogworkByPeriodInfo
   */
  period?: SalaryPeriodInfo;
  /**
   *
   * @type {number}
   * @memberof UserLogworkByPeriodInfo
   */
  realWorkDays?: number;
  /**
   *
   * @type {number}
   * @memberof UserLogworkByPeriodInfo
   */
  realDaysOff?: number;
  /**
   *
   * @type {number}
   * @memberof UserLogworkByPeriodInfo
   */
  totalWorkDays?: number;
  /**
   *
   * @type {number}
   * @memberof UserLogworkByPeriodInfo
   */
  lastUpdated?: number;
  /**
   *
   * @type {Array<UserLogworkItem>}
   * @memberof UserLogworkByPeriodInfo
   */
  logwork?: Array<UserLogworkItem>;
}
/**
 *
 * @export
 * @interface UserLogworkItem
 */
export interface UserLogworkItem {
  /**
   *
   * @type {string}
   * @memberof UserLogworkItem
   */
  date: string;
  /**
   *
   * @type {number}
   * @memberof UserLogworkItem
   */
  workload: number;
  /**
   *
   * @type {string}
   * @memberof UserLogworkItem
   */
  type?: UserLogworkItemTypeEnum;
}

export const UserLogworkItemTypeEnum = {
  FullDate: "FULL_DATE",
  NotFullDate: "NOT_FULL_DATE",
  DateOff: "DATE_OFF",
  None: "NONE",
} as const;

export type UserLogworkItemTypeEnum =
  (typeof UserLogworkItemTypeEnum)[keyof typeof UserLogworkItemTypeEnum];

/**
 *
 * @export
 * @interface VerifyUserV2Request
 */
export interface VerifyUserV2Request {
  /**
   *
   * @type {string}
   * @memberof VerifyUserV2Request
   */
  phoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof VerifyUserV2Request
   */
  name: string;
  /**
   *
   * @type {Array<string>}
   * @memberof VerifyUserV2Request
   */
  accounts?: Array<string>;
}

/**
 * AgentApi - axios parameter creator
 * @export
 */
export const AgentApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Đồng bộ công
     * @summary [Agent] Đồng bộ công
     * @param {EnterpriseLogworkRequest} enterpriseLogworkRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logwork: async (
      enterpriseLogworkRequest: EnterpriseLogworkRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'enterpriseLogworkRequest' is not null or undefined
      assertParamExists(
        "logwork",
        "enterpriseLogworkRequest",
        enterpriseLogworkRequest,
      );
      const localVarPath = `/api/v1/agent/logwork`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        enterpriseLogworkRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AgentApi - functional programming interface
 * @export
 */
export const AgentApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AgentApiAxiosParamCreator(configuration);
  return {
    /**
     * Đồng bộ công
     * @summary [Agent] Đồng bộ công
     * @param {EnterpriseLogworkRequest} enterpriseLogworkRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async logwork(
      enterpriseLogworkRequest: EnterpriseLogworkRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseListEmployeeCheckinResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.logwork(
        enterpriseLogworkRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * AgentApi - factory interface
 * @export
 */
export const AgentApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AgentApiFp(configuration);
  return {
    /**
     * Đồng bộ công
     * @summary [Agent] Đồng bộ công
     * @param {EnterpriseLogworkRequest} enterpriseLogworkRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logwork(
      enterpriseLogworkRequest: EnterpriseLogworkRequest,
      options?: any,
    ): AxiosPromise<BaseResponseListEmployeeCheckinResponse> {
      return localVarFp
        .logwork(enterpriseLogworkRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for logwork operation in AgentApi.
 * @export
 * @interface AgentApiLogworkRequest
 */
export interface AgentApiLogworkRequest {
  /**
   *
   * @type {EnterpriseLogworkRequest}
   * @memberof AgentApiLogwork
   */
  readonly enterpriseLogworkRequest: EnterpriseLogworkRequest;
}

/**
 * AgentApi - object-oriented interface
 * @export
 * @class AgentApi
 * @extends {BaseAPI}
 */
export class AgentApi extends BaseAPI {
  /**
   * Đồng bộ công
   * @summary [Agent] Đồng bộ công
   * @param {AgentApiLogworkRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AgentApi
   */
  public logwork(
    requestParameters: AgentApiLogworkRequest,
    options?: AxiosRequestConfig,
  ) {
    return AgentApiFp(this.configuration)
      .logwork(requestParameters.enterpriseLogworkRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CMSEnterpriseApi - axios parameter creator
 * @export
 */
export const CMSEnterpriseApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Duyệt
     * @summary [CMS] Duyệt
     * @param {string} userId
     * @param {string} userName
     * @param {string} id
     * @param {ApproveRegistrationRequest} approveRegistrationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approveRegistration: async (
      userId: string,
      userName: string,
      id: string,
      approveRegistrationRequest: ApproveRegistrationRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("approveRegistration", "userId", userId);
      // verify required parameter 'userName' is not null or undefined
      assertParamExists("approveRegistration", "userName", userName);
      // verify required parameter 'id' is not null or undefined
      assertParamExists("approveRegistration", "id", id);
      // verify required parameter 'approveRegistrationRequest' is not null or undefined
      assertParamExists(
        "approveRegistration",
        "approveRegistrationRequest",
        approveRegistrationRequest,
      );
      const localVarPath = `/api/v1/cms/registration/{id}/approve`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      if (userName != null) {
        localVarHeaderParameter["user-name"] = String(userName);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        approveRegistrationRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Chi tiết giao dịch
     * @summary [CMS] Chi tiết giao dịch
     * @param {string} userId
     * @param {string} userName
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsAdvanceRequestDetail: async (
      userId: string,
      userName: string,
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("cmsAdvanceRequestDetail", "userId", userId);
      // verify required parameter 'userName' is not null or undefined
      assertParamExists("cmsAdvanceRequestDetail", "userName", userName);
      // verify required parameter 'id' is not null or undefined
      assertParamExists("cmsAdvanceRequestDetail", "id", id);
      const localVarPath = `/api/v1/cms/advance-request/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      if (userName != null) {
        localVarHeaderParameter["user-name"] = String(userName);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Tải file excel chi tiết thống kê số tiền đã ứng, đã chi, phí dịch vụ của doanh nghiệp
     * @summary [CMS] Tải chi tiết thống kê đối tác
     * @param {string} userId
     * @param {string} userName
     * @param {string} id
     * @param {number} fromDate
     * @param {number} toDate
     * @param {string} [employeeCode]
     * @param {string} [employeeName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsDownloadEnterpriseStatisticGroupByEmployee: async (
      userId: string,
      userName: string,
      id: string,
      fromDate: number,
      toDate: number,
      employeeCode?: string,
      employeeName?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists(
        "cmsDownloadEnterpriseStatisticGroupByEmployee",
        "userId",
        userId,
      );
      // verify required parameter 'userName' is not null or undefined
      assertParamExists(
        "cmsDownloadEnterpriseStatisticGroupByEmployee",
        "userName",
        userName,
      );
      // verify required parameter 'id' is not null or undefined
      assertParamExists(
        "cmsDownloadEnterpriseStatisticGroupByEmployee",
        "id",
        id,
      );
      // verify required parameter 'fromDate' is not null or undefined
      assertParamExists(
        "cmsDownloadEnterpriseStatisticGroupByEmployee",
        "fromDate",
        fromDate,
      );
      // verify required parameter 'toDate' is not null or undefined
      assertParamExists(
        "cmsDownloadEnterpriseStatisticGroupByEmployee",
        "toDate",
        toDate,
      );
      const localVarPath =
        `/api/v1/cms/statistic/enterprise/{id}/export`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(id)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (fromDate !== undefined) {
        localVarQueryParameter["fromDate"] = fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter["toDate"] = toDate;
      }

      if (employeeCode !== undefined) {
        localVarQueryParameter["employeeCode"] = employeeCode;
      }

      if (employeeName !== undefined) {
        localVarQueryParameter["employeeName"] = employeeName;
      }

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      if (userName != null) {
        localVarHeaderParameter["user-name"] = String(userName);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Tải file thống kê các doanh nghiệp và số tiền đã ứng, đã chi, phí dịch vụ
     * @summary [CMS] Tải chi tiết thống kê
     * @param {string} userId
     * @param {string} userName
     * @param {number} fromDate
     * @param {number} toDate
     * @param {string} [enterpriseCode]
     * @param {string} [enterpriseName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsDownloadStatisticGroupByEnterprise: async (
      userId: string,
      userName: string,
      fromDate: number,
      toDate: number,
      enterpriseCode?: string,
      enterpriseName?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists(
        "cmsDownloadStatisticGroupByEnterprise",
        "userId",
        userId,
      );
      // verify required parameter 'userName' is not null or undefined
      assertParamExists(
        "cmsDownloadStatisticGroupByEnterprise",
        "userName",
        userName,
      );
      // verify required parameter 'fromDate' is not null or undefined
      assertParamExists(
        "cmsDownloadStatisticGroupByEnterprise",
        "fromDate",
        fromDate,
      );
      // verify required parameter 'toDate' is not null or undefined
      assertParamExists(
        "cmsDownloadStatisticGroupByEnterprise",
        "toDate",
        toDate,
      );
      const localVarPath = `/api/v1/cms/statistic/enterprise/export`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (fromDate !== undefined) {
        localVarQueryParameter["fromDate"] = fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter["toDate"] = toDate;
      }

      if (enterpriseCode !== undefined) {
        localVarQueryParameter["enterpriseCode"] = enterpriseCode;
      }

      if (enterpriseName !== undefined) {
        localVarQueryParameter["enterpriseName"] = enterpriseName;
      }

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      if (userName != null) {
        localVarHeaderParameter["user-name"] = String(userName);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Thống kê số tiền đã ứng, đã chi, phí dịch vụ của doanh nghiệp
     * @summary [CMS] Chi tiết thống kê đối tác
     * @param {string} userId
     * @param {string} userName
     * @param {string} id
     * @param {number} fromDate
     * @param {number} toDate
     * @param {string} [employeeCode]
     * @param {string} [employeeName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsEnterpriseStatisticGroupByEmployee: async (
      userId: string,
      userName: string,
      id: string,
      fromDate: number,
      toDate: number,
      employeeCode?: string,
      employeeName?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists(
        "cmsEnterpriseStatisticGroupByEmployee",
        "userId",
        userId,
      );
      // verify required parameter 'userName' is not null or undefined
      assertParamExists(
        "cmsEnterpriseStatisticGroupByEmployee",
        "userName",
        userName,
      );
      // verify required parameter 'id' is not null or undefined
      assertParamExists("cmsEnterpriseStatisticGroupByEmployee", "id", id);
      // verify required parameter 'fromDate' is not null or undefined
      assertParamExists(
        "cmsEnterpriseStatisticGroupByEmployee",
        "fromDate",
        fromDate,
      );
      // verify required parameter 'toDate' is not null or undefined
      assertParamExists(
        "cmsEnterpriseStatisticGroupByEmployee",
        "toDate",
        toDate,
      );
      const localVarPath = `/api/v1/cms/statistic/enterprise/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (fromDate !== undefined) {
        localVarQueryParameter["fromDate"] = fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter["toDate"] = toDate;
      }

      if (employeeCode !== undefined) {
        localVarQueryParameter["employeeCode"] = employeeCode;
      }

      if (employeeName !== undefined) {
        localVarQueryParameter["employeeName"] = employeeName;
      }

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      if (userName != null) {
        localVarHeaderParameter["user-name"] = String(userName);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Danh sách giao dịch
     * @summary [CMS] Danh sách giao dịch
     * @param {string} userId
     * @param {string} userName
     * @param {number} fromDate
     * @param {number} toDate
     * @param {number} page
     * @param {number} size
     * @param {string} [code]
     * @param {string} [enterpriseName]
     * @param {string} [toAccount]
     * @param {'APPROVED' | 'REJECTED' | 'WAITING_APPROVAL' | 'CANCELED' | 'EXPIRED'} [status]
     * @param {'SUCCESS' | 'FAILED' | 'PROCESSING' | 'TODO' | 'NONE'} [paymentStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsListAdvanceRequestInfo: async (
      userId: string,
      userName: string,
      fromDate: number,
      toDate: number,
      page: number,
      size: number,
      code?: string,
      enterpriseName?: string,
      toAccount?: string,
      status?:
        | "APPROVED"
        | "REJECTED"
        | "WAITING_APPROVAL"
        | "CANCELED"
        | "EXPIRED",
      paymentStatus?: "SUCCESS" | "FAILED" | "PROCESSING" | "TODO" | "NONE",
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("cmsListAdvanceRequestInfo", "userId", userId);
      // verify required parameter 'userName' is not null or undefined
      assertParamExists("cmsListAdvanceRequestInfo", "userName", userName);
      // verify required parameter 'fromDate' is not null or undefined
      assertParamExists("cmsListAdvanceRequestInfo", "fromDate", fromDate);
      // verify required parameter 'toDate' is not null or undefined
      assertParamExists("cmsListAdvanceRequestInfo", "toDate", toDate);
      // verify required parameter 'page' is not null or undefined
      assertParamExists("cmsListAdvanceRequestInfo", "page", page);
      // verify required parameter 'size' is not null or undefined
      assertParamExists("cmsListAdvanceRequestInfo", "size", size);
      const localVarPath = `/api/v1/cms/advance-request`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (code !== undefined) {
        localVarQueryParameter["code"] = code;
      }

      if (enterpriseName !== undefined) {
        localVarQueryParameter["enterpriseName"] = enterpriseName;
      }

      if (toAccount !== undefined) {
        localVarQueryParameter["toAccount"] = toAccount;
      }

      if (fromDate !== undefined) {
        localVarQueryParameter["fromDate"] = fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter["toDate"] = toDate;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (paymentStatus !== undefined) {
        localVarQueryParameter["paymentStatus"] = paymentStatus;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      if (userName != null) {
        localVarHeaderParameter["user-name"] = String(userName);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Biểu đồ chi lương
     * @summary [CMS] Biểu đồ
     * @param {string} userId
     * @param {string} userName
     * @param {number} fromDate
     * @param {number} toDate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsPaidChart: async (
      userId: string,
      userName: string,
      fromDate: number,
      toDate: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("cmsPaidChart", "userId", userId);
      // verify required parameter 'userName' is not null or undefined
      assertParamExists("cmsPaidChart", "userName", userName);
      // verify required parameter 'fromDate' is not null or undefined
      assertParamExists("cmsPaidChart", "fromDate", fromDate);
      // verify required parameter 'toDate' is not null or undefined
      assertParamExists("cmsPaidChart", "toDate", toDate);
      const localVarPath = `/api/v1/cms/chart`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (fromDate !== undefined) {
        localVarQueryParameter["fromDate"] = fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter["toDate"] = toDate;
      }

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      if (userName != null) {
        localVarHeaderParameter["user-name"] = String(userName);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Lấy thông tin thống kê doanh nghiệp chi lương
     * @summary [CMS] Thống kê
     * @param {string} userId
     * @param {string} userName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsStatistic: async (
      userId: string,
      userName: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("cmsStatistic", "userId", userId);
      // verify required parameter 'userName' is not null or undefined
      assertParamExists("cmsStatistic", "userName", userName);
      const localVarPath = `/api/v1/cms/statistic`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      if (userName != null) {
        localVarHeaderParameter["user-name"] = String(userName);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Thống kê các doanh nghiệp và số tiền đã ứng, đã chi, phí dịch vụ
     * @summary [CMS] Chi tiết thống kê
     * @param {string} userId
     * @param {string} userName
     * @param {number} fromDate
     * @param {number} toDate
     * @param {string} [enterpriseCode]
     * @param {string} [enterpriseName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsStatisticGroupByEnterprise: async (
      userId: string,
      userName: string,
      fromDate: number,
      toDate: number,
      enterpriseCode?: string,
      enterpriseName?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("cmsStatisticGroupByEnterprise", "userId", userId);
      // verify required parameter 'userName' is not null or undefined
      assertParamExists("cmsStatisticGroupByEnterprise", "userName", userName);
      // verify required parameter 'fromDate' is not null or undefined
      assertParamExists("cmsStatisticGroupByEnterprise", "fromDate", fromDate);
      // verify required parameter 'toDate' is not null or undefined
      assertParamExists("cmsStatisticGroupByEnterprise", "toDate", toDate);
      const localVarPath = `/api/v1/cms/statistic/enterprise`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (fromDate !== undefined) {
        localVarQueryParameter["fromDate"] = fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter["toDate"] = toDate;
      }

      if (enterpriseCode !== undefined) {
        localVarQueryParameter["enterpriseCode"] = enterpriseCode;
      }

      if (enterpriseName !== undefined) {
        localVarQueryParameter["enterpriseName"] = enterpriseName;
      }

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      if (userName != null) {
        localVarHeaderParameter["user-name"] = String(userName);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Lấy chi tiết doanh nghiệp tham gia chi lương linh hoạt
     * @summary [CMS] Chi tiết doanh nghiêp
     * @param {string} userId
     * @param {string} userName
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    detailEnterprise: async (
      userId: string,
      userName: string,
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("detailEnterprise", "userId", userId);
      // verify required parameter 'userName' is not null or undefined
      assertParamExists("detailEnterprise", "userName", userName);
      // verify required parameter 'id' is not null or undefined
      assertParamExists("detailEnterprise", "id", id);
      const localVarPath = `/api/v1/cms/enterprise/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      if (userName != null) {
        localVarHeaderParameter["user-name"] = String(userName);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Lấy chi tiết yêu cầu chờ duyệt
     * @summary [CMS] Chi tiết yêu cầu chờ duyệt
     * @param {string} userId
     * @param {string} userName
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    detailRegistrationRequest: async (
      userId: string,
      userName: string,
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("detailRegistrationRequest", "userId", userId);
      // verify required parameter 'userName' is not null or undefined
      assertParamExists("detailRegistrationRequest", "userName", userName);
      // verify required parameter 'id' is not null or undefined
      assertParamExists("detailRegistrationRequest", "id", id);
      const localVarPath = `/api/v1/cms/registration/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      if (userName != null) {
        localVarHeaderParameter["user-name"] = String(userName);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Chỉnh sửa doanh nghiệp tham gia chi lương linh hoạt
     * @summary [CMS] Chỉnh sửa doanh nghiêp
     * @param {string} userId
     * @param {string} userName
     * @param {string} id
     * @param {CmsEnterpriseRequest} cmsEnterpriseRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editEnterprise: async (
      userId: string,
      userName: string,
      id: string,
      cmsEnterpriseRequest: CmsEnterpriseRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("editEnterprise", "userId", userId);
      // verify required parameter 'userName' is not null or undefined
      assertParamExists("editEnterprise", "userName", userName);
      // verify required parameter 'id' is not null or undefined
      assertParamExists("editEnterprise", "id", id);
      // verify required parameter 'cmsEnterpriseRequest' is not null or undefined
      assertParamExists(
        "editEnterprise",
        "cmsEnterpriseRequest",
        cmsEnterpriseRequest,
      );
      const localVarPath = `/api/v1/cms/enterprise/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      if (userName != null) {
        localVarHeaderParameter["user-name"] = String(userName);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        cmsEnterpriseRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Lấy danh sách doanh nghiệp tham gia chi lương linh hoạt
     * @summary [CMS] Danh sách doanh nghiêp
     * @param {string} userId
     * @param {string} userName
     * @param {number} page
     * @param {number} size
     * @param {string} [enterpriseName]
     * @param {string} [enterpriseCode]
     * @param {string} [ownerName]
     * @param {boolean} [activated]
     * @param {boolean} [cmsLocked]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listEnterprise: async (
      userId: string,
      userName: string,
      page: number,
      size: number,
      enterpriseName?: string,
      enterpriseCode?: string,
      ownerName?: string,
      activated?: boolean,
      cmsLocked?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("listEnterprise", "userId", userId);
      // verify required parameter 'userName' is not null or undefined
      assertParamExists("listEnterprise", "userName", userName);
      // verify required parameter 'page' is not null or undefined
      assertParamExists("listEnterprise", "page", page);
      // verify required parameter 'size' is not null or undefined
      assertParamExists("listEnterprise", "size", size);
      const localVarPath = `/api/v1/cms/enterprise`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (enterpriseName !== undefined) {
        localVarQueryParameter["enterpriseName"] = enterpriseName;
      }

      if (enterpriseCode !== undefined) {
        localVarQueryParameter["enterpriseCode"] = enterpriseCode;
      }

      if (ownerName !== undefined) {
        localVarQueryParameter["ownerName"] = ownerName;
      }

      if (activated !== undefined) {
        localVarQueryParameter["activated"] = activated;
      }

      if (cmsLocked !== undefined) {
        localVarQueryParameter["cmsLocked"] = cmsLocked;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      if (userName != null) {
        localVarHeaderParameter["user-name"] = String(userName);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Lấy danh sách chờ duyệt
     * @summary [CMS] Danh sách chờ duyệt
     * @param {string} userId
     * @param {string} userName
     * @param {number} page
     * @param {number} size
     * @param {string} [enterpriseName]
     * @param {string} [enterpriseCode]
     * @param {string} [ownerName]
     * @param {number} [fromDate]
     * @param {number} [toDate]
     * @param {'WAITING_APPROVAL' | 'APPROVED' | 'REJECTED' | 'CANCEL'} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listRegistrationRequest: async (
      userId: string,
      userName: string,
      page: number,
      size: number,
      enterpriseName?: string,
      enterpriseCode?: string,
      ownerName?: string,
      fromDate?: number,
      toDate?: number,
      status?: "WAITING_APPROVAL" | "APPROVED" | "REJECTED" | "CANCEL",
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("listRegistrationRequest", "userId", userId);
      // verify required parameter 'userName' is not null or undefined
      assertParamExists("listRegistrationRequest", "userName", userName);
      // verify required parameter 'page' is not null or undefined
      assertParamExists("listRegistrationRequest", "page", page);
      // verify required parameter 'size' is not null or undefined
      assertParamExists("listRegistrationRequest", "size", size);
      const localVarPath = `/api/v1/cms/registration`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (enterpriseName !== undefined) {
        localVarQueryParameter["enterpriseName"] = enterpriseName;
      }

      if (enterpriseCode !== undefined) {
        localVarQueryParameter["enterpriseCode"] = enterpriseCode;
      }

      if (ownerName !== undefined) {
        localVarQueryParameter["ownerName"] = ownerName;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (fromDate !== undefined) {
        localVarQueryParameter["fromDate"] = fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter["toDate"] = toDate;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      if (userName != null) {
        localVarHeaderParameter["user-name"] = String(userName);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Vô hiệu hóa doanh nghiệp tham gia chi lương linh hoạt
     * @summary [CMS] Vô hiệu hóa doanh nghiêp
     * @param {string} userId
     * @param {string} userName
     * @param {string} id
     * @param {CmsLockEnterpriseRequest} cmsLockEnterpriseRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    lockEnterprise: async (
      userId: string,
      userName: string,
      id: string,
      cmsLockEnterpriseRequest: CmsLockEnterpriseRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("lockEnterprise", "userId", userId);
      // verify required parameter 'userName' is not null or undefined
      assertParamExists("lockEnterprise", "userName", userName);
      // verify required parameter 'id' is not null or undefined
      assertParamExists("lockEnterprise", "id", id);
      // verify required parameter 'cmsLockEnterpriseRequest' is not null or undefined
      assertParamExists(
        "lockEnterprise",
        "cmsLockEnterpriseRequest",
        cmsLockEnterpriseRequest,
      );
      const localVarPath = `/api/v1/cms/enterprise/{id}/lock`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      if (userName != null) {
        localVarHeaderParameter["user-name"] = String(userName);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        cmsLockEnterpriseRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Từ chối
     * @summary [CMS] Từ chối
     * @param {string} userId
     * @param {string} userName
     * @param {string} id
     * @param {RejectRegistrationRequest} rejectRegistrationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rejectRegistration: async (
      userId: string,
      userName: string,
      id: string,
      rejectRegistrationRequest: RejectRegistrationRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("rejectRegistration", "userId", userId);
      // verify required parameter 'userName' is not null or undefined
      assertParamExists("rejectRegistration", "userName", userName);
      // verify required parameter 'id' is not null or undefined
      assertParamExists("rejectRegistration", "id", id);
      // verify required parameter 'rejectRegistrationRequest' is not null or undefined
      assertParamExists(
        "rejectRegistration",
        "rejectRegistrationRequest",
        rejectRegistrationRequest,
      );
      const localVarPath = `/api/v1/cms/registration/{id}/reject`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      if (userName != null) {
        localVarHeaderParameter["user-name"] = String(userName);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        rejectRegistrationRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Kích hoạt doanh nghiệp tham gia chi lương linh hoạt
     * @summary [CMS] Kích hoạt doanh nghiêp
     * @param {string} userId
     * @param {string} userName
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unlockEnterprise: async (
      userId: string,
      userName: string,
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("unlockEnterprise", "userId", userId);
      // verify required parameter 'userName' is not null or undefined
      assertParamExists("unlockEnterprise", "userName", userName);
      // verify required parameter 'id' is not null or undefined
      assertParamExists("unlockEnterprise", "id", id);
      const localVarPath = `/api/v1/cms/enterprise/{id}/unlock`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      if (userName != null) {
        localVarHeaderParameter["user-name"] = String(userName);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CMSEnterpriseApi - functional programming interface
 * @export
 */
export const CMSEnterpriseApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    CMSEnterpriseApiAxiosParamCreator(configuration);
  return {
    /**
     * Duyệt
     * @summary [CMS] Duyệt
     * @param {string} userId
     * @param {string} userName
     * @param {string} id
     * @param {ApproveRegistrationRequest} approveRegistrationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async approveRegistration(
      userId: string,
      userName: string,
      id: string,
      approveRegistrationRequest: ApproveRegistrationRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseObject>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.approveRegistration(
          userId,
          userName,
          id,
          approveRegistrationRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Chi tiết giao dịch
     * @summary [CMS] Chi tiết giao dịch
     * @param {string} userId
     * @param {string} userName
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cmsAdvanceRequestDetail(
      userId: string,
      userName: string,
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseCmsAdvanceRequestDetail>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cmsAdvanceRequestDetail(
          userId,
          userName,
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Tải file excel chi tiết thống kê số tiền đã ứng, đã chi, phí dịch vụ của doanh nghiệp
     * @summary [CMS] Tải chi tiết thống kê đối tác
     * @param {string} userId
     * @param {string} userName
     * @param {string} id
     * @param {number} fromDate
     * @param {number} toDate
     * @param {string} [employeeCode]
     * @param {string} [employeeName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cmsDownloadEnterpriseStatisticGroupByEmployee(
      userId: string,
      userName: string,
      id: string,
      fromDate: number,
      toDate: number,
      employeeCode?: string,
      employeeName?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cmsDownloadEnterpriseStatisticGroupByEmployee(
          userId,
          userName,
          id,
          fromDate,
          toDate,
          employeeCode,
          employeeName,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Tải file thống kê các doanh nghiệp và số tiền đã ứng, đã chi, phí dịch vụ
     * @summary [CMS] Tải chi tiết thống kê
     * @param {string} userId
     * @param {string} userName
     * @param {number} fromDate
     * @param {number} toDate
     * @param {string} [enterpriseCode]
     * @param {string} [enterpriseName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cmsDownloadStatisticGroupByEnterprise(
      userId: string,
      userName: string,
      fromDate: number,
      toDate: number,
      enterpriseCode?: string,
      enterpriseName?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cmsDownloadStatisticGroupByEnterprise(
          userId,
          userName,
          fromDate,
          toDate,
          enterpriseCode,
          enterpriseName,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Thống kê số tiền đã ứng, đã chi, phí dịch vụ của doanh nghiệp
     * @summary [CMS] Chi tiết thống kê đối tác
     * @param {string} userId
     * @param {string} userName
     * @param {string} id
     * @param {number} fromDate
     * @param {number} toDate
     * @param {string} [employeeCode]
     * @param {string} [employeeName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cmsEnterpriseStatisticGroupByEmployee(
      userId: string,
      userName: string,
      id: string,
      fromDate: number,
      toDate: number,
      employeeCode?: string,
      employeeName?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseCmsEnterpriseStatisticResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cmsEnterpriseStatisticGroupByEmployee(
          userId,
          userName,
          id,
          fromDate,
          toDate,
          employeeCode,
          employeeName,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Danh sách giao dịch
     * @summary [CMS] Danh sách giao dịch
     * @param {string} userId
     * @param {string} userName
     * @param {number} fromDate
     * @param {number} toDate
     * @param {number} page
     * @param {number} size
     * @param {string} [code]
     * @param {string} [enterpriseName]
     * @param {string} [toAccount]
     * @param {'APPROVED' | 'REJECTED' | 'WAITING_APPROVAL' | 'CANCELED' | 'EXPIRED'} [status]
     * @param {'SUCCESS' | 'FAILED' | 'PROCESSING' | 'TODO' | 'NONE'} [paymentStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cmsListAdvanceRequestInfo(
      userId: string,
      userName: string,
      fromDate: number,
      toDate: number,
      page: number,
      size: number,
      code?: string,
      enterpriseName?: string,
      toAccount?: string,
      status?:
        | "APPROVED"
        | "REJECTED"
        | "WAITING_APPROVAL"
        | "CANCELED"
        | "EXPIRED",
      paymentStatus?: "SUCCESS" | "FAILED" | "PROCESSING" | "TODO" | "NONE",
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponsePageDtoListCmsAdvanceRequestInfo>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cmsListAdvanceRequestInfo(
          userId,
          userName,
          fromDate,
          toDate,
          page,
          size,
          code,
          enterpriseName,
          toAccount,
          status,
          paymentStatus,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Biểu đồ chi lương
     * @summary [CMS] Biểu đồ
     * @param {string} userId
     * @param {string} userName
     * @param {number} fromDate
     * @param {number} toDate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cmsPaidChart(
      userId: string,
      userName: string,
      fromDate: number,
      toDate: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseListCmsPaidChartItem>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.cmsPaidChart(
        userId,
        userName,
        fromDate,
        toDate,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Lấy thông tin thống kê doanh nghiệp chi lương
     * @summary [CMS] Thống kê
     * @param {string} userId
     * @param {string} userName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cmsStatistic(
      userId: string,
      userName: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseCmsStatisticResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.cmsStatistic(
        userId,
        userName,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Thống kê các doanh nghiệp và số tiền đã ứng, đã chi, phí dịch vụ
     * @summary [CMS] Chi tiết thống kê
     * @param {string} userId
     * @param {string} userName
     * @param {number} fromDate
     * @param {number} toDate
     * @param {string} [enterpriseCode]
     * @param {string} [enterpriseName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cmsStatisticGroupByEnterprise(
      userId: string,
      userName: string,
      fromDate: number,
      toDate: number,
      enterpriseCode?: string,
      enterpriseName?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseListCmsEnterprisePaidInfo>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cmsStatisticGroupByEnterprise(
          userId,
          userName,
          fromDate,
          toDate,
          enterpriseCode,
          enterpriseName,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Lấy chi tiết doanh nghiệp tham gia chi lương linh hoạt
     * @summary [CMS] Chi tiết doanh nghiêp
     * @param {string} userId
     * @param {string} userName
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async detailEnterprise(
      userId: string,
      userName: string,
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseCmsEnterpriseDetail>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.detailEnterprise(
          userId,
          userName,
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Lấy chi tiết yêu cầu chờ duyệt
     * @summary [CMS] Chi tiết yêu cầu chờ duyệt
     * @param {string} userId
     * @param {string} userName
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async detailRegistrationRequest(
      userId: string,
      userName: string,
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseRegistrationDetail>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.detailRegistrationRequest(
          userId,
          userName,
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Chỉnh sửa doanh nghiệp tham gia chi lương linh hoạt
     * @summary [CMS] Chỉnh sửa doanh nghiêp
     * @param {string} userId
     * @param {string} userName
     * @param {string} id
     * @param {CmsEnterpriseRequest} cmsEnterpriseRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async editEnterprise(
      userId: string,
      userName: string,
      id: string,
      cmsEnterpriseRequest: CmsEnterpriseRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseObject>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.editEnterprise(
        userId,
        userName,
        id,
        cmsEnterpriseRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Lấy danh sách doanh nghiệp tham gia chi lương linh hoạt
     * @summary [CMS] Danh sách doanh nghiêp
     * @param {string} userId
     * @param {string} userName
     * @param {number} page
     * @param {number} size
     * @param {string} [enterpriseName]
     * @param {string} [enterpriseCode]
     * @param {string} [ownerName]
     * @param {boolean} [activated]
     * @param {boolean} [cmsLocked]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listEnterprise(
      userId: string,
      userName: string,
      page: number,
      size: number,
      enterpriseName?: string,
      enterpriseCode?: string,
      ownerName?: string,
      activated?: boolean,
      cmsLocked?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponsePageDtoListCmsEnterpriseInfo>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listEnterprise(
        userId,
        userName,
        page,
        size,
        enterpriseName,
        enterpriseCode,
        ownerName,
        activated,
        cmsLocked,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Lấy danh sách chờ duyệt
     * @summary [CMS] Danh sách chờ duyệt
     * @param {string} userId
     * @param {string} userName
     * @param {number} page
     * @param {number} size
     * @param {string} [enterpriseName]
     * @param {string} [enterpriseCode]
     * @param {string} [ownerName]
     * @param {number} [fromDate]
     * @param {number} [toDate]
     * @param {'WAITING_APPROVAL' | 'APPROVED' | 'REJECTED' | 'CANCEL'} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listRegistrationRequest(
      userId: string,
      userName: string,
      page: number,
      size: number,
      enterpriseName?: string,
      enterpriseCode?: string,
      ownerName?: string,
      fromDate?: number,
      toDate?: number,
      status?: "WAITING_APPROVAL" | "APPROVED" | "REJECTED" | "CANCEL",
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponsePageDtoListRegistrationInfo>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listRegistrationRequest(
          userId,
          userName,
          page,
          size,
          enterpriseName,
          enterpriseCode,
          ownerName,
          fromDate,
          toDate,
          status,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Vô hiệu hóa doanh nghiệp tham gia chi lương linh hoạt
     * @summary [CMS] Vô hiệu hóa doanh nghiêp
     * @param {string} userId
     * @param {string} userName
     * @param {string} id
     * @param {CmsLockEnterpriseRequest} cmsLockEnterpriseRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async lockEnterprise(
      userId: string,
      userName: string,
      id: string,
      cmsLockEnterpriseRequest: CmsLockEnterpriseRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseObject>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.lockEnterprise(
        userId,
        userName,
        id,
        cmsLockEnterpriseRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Từ chối
     * @summary [CMS] Từ chối
     * @param {string} userId
     * @param {string} userName
     * @param {string} id
     * @param {RejectRegistrationRequest} rejectRegistrationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async rejectRegistration(
      userId: string,
      userName: string,
      id: string,
      rejectRegistrationRequest: RejectRegistrationRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseObject>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.rejectRegistration(
          userId,
          userName,
          id,
          rejectRegistrationRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Kích hoạt doanh nghiệp tham gia chi lương linh hoạt
     * @summary [CMS] Kích hoạt doanh nghiêp
     * @param {string} userId
     * @param {string} userName
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async unlockEnterprise(
      userId: string,
      userName: string,
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseObject>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.unlockEnterprise(
          userId,
          userName,
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * CMSEnterpriseApi - factory interface
 * @export
 */
export const CMSEnterpriseApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CMSEnterpriseApiFp(configuration);
  return {
    /**
     * Duyệt
     * @summary [CMS] Duyệt
     * @param {string} userId
     * @param {string} userName
     * @param {string} id
     * @param {ApproveRegistrationRequest} approveRegistrationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approveRegistration(
      userId: string,
      userName: string,
      id: string,
      approveRegistrationRequest: ApproveRegistrationRequest,
      options?: any,
    ): AxiosPromise<BaseResponseObject> {
      return localVarFp
        .approveRegistration(
          userId,
          userName,
          id,
          approveRegistrationRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Chi tiết giao dịch
     * @summary [CMS] Chi tiết giao dịch
     * @param {string} userId
     * @param {string} userName
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsAdvanceRequestDetail(
      userId: string,
      userName: string,
      id: string,
      options?: any,
    ): AxiosPromise<BaseResponseCmsAdvanceRequestDetail> {
      return localVarFp
        .cmsAdvanceRequestDetail(userId, userName, id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Tải file excel chi tiết thống kê số tiền đã ứng, đã chi, phí dịch vụ của doanh nghiệp
     * @summary [CMS] Tải chi tiết thống kê đối tác
     * @param {string} userId
     * @param {string} userName
     * @param {string} id
     * @param {number} fromDate
     * @param {number} toDate
     * @param {string} [employeeCode]
     * @param {string} [employeeName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsDownloadEnterpriseStatisticGroupByEmployee(
      userId: string,
      userName: string,
      id: string,
      fromDate: number,
      toDate: number,
      employeeCode?: string,
      employeeName?: string,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .cmsDownloadEnterpriseStatisticGroupByEmployee(
          userId,
          userName,
          id,
          fromDate,
          toDate,
          employeeCode,
          employeeName,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Tải file thống kê các doanh nghiệp và số tiền đã ứng, đã chi, phí dịch vụ
     * @summary [CMS] Tải chi tiết thống kê
     * @param {string} userId
     * @param {string} userName
     * @param {number} fromDate
     * @param {number} toDate
     * @param {string} [enterpriseCode]
     * @param {string} [enterpriseName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsDownloadStatisticGroupByEnterprise(
      userId: string,
      userName: string,
      fromDate: number,
      toDate: number,
      enterpriseCode?: string,
      enterpriseName?: string,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .cmsDownloadStatisticGroupByEnterprise(
          userId,
          userName,
          fromDate,
          toDate,
          enterpriseCode,
          enterpriseName,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Thống kê số tiền đã ứng, đã chi, phí dịch vụ của doanh nghiệp
     * @summary [CMS] Chi tiết thống kê đối tác
     * @param {string} userId
     * @param {string} userName
     * @param {string} id
     * @param {number} fromDate
     * @param {number} toDate
     * @param {string} [employeeCode]
     * @param {string} [employeeName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsEnterpriseStatisticGroupByEmployee(
      userId: string,
      userName: string,
      id: string,
      fromDate: number,
      toDate: number,
      employeeCode?: string,
      employeeName?: string,
      options?: any,
    ): AxiosPromise<BaseResponseCmsEnterpriseStatisticResponse> {
      return localVarFp
        .cmsEnterpriseStatisticGroupByEmployee(
          userId,
          userName,
          id,
          fromDate,
          toDate,
          employeeCode,
          employeeName,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Danh sách giao dịch
     * @summary [CMS] Danh sách giao dịch
     * @param {string} userId
     * @param {string} userName
     * @param {number} fromDate
     * @param {number} toDate
     * @param {number} page
     * @param {number} size
     * @param {string} [code]
     * @param {string} [enterpriseName]
     * @param {string} [toAccount]
     * @param {'APPROVED' | 'REJECTED' | 'WAITING_APPROVAL' | 'CANCELED' | 'EXPIRED'} [status]
     * @param {'SUCCESS' | 'FAILED' | 'PROCESSING' | 'TODO' | 'NONE'} [paymentStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsListAdvanceRequestInfo(
      userId: string,
      userName: string,
      fromDate: number,
      toDate: number,
      page: number,
      size: number,
      code?: string,
      enterpriseName?: string,
      toAccount?: string,
      status?:
        | "APPROVED"
        | "REJECTED"
        | "WAITING_APPROVAL"
        | "CANCELED"
        | "EXPIRED",
      paymentStatus?: "SUCCESS" | "FAILED" | "PROCESSING" | "TODO" | "NONE",
      options?: any,
    ): AxiosPromise<BaseResponsePageDtoListCmsAdvanceRequestInfo> {
      return localVarFp
        .cmsListAdvanceRequestInfo(
          userId,
          userName,
          fromDate,
          toDate,
          page,
          size,
          code,
          enterpriseName,
          toAccount,
          status,
          paymentStatus,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Biểu đồ chi lương
     * @summary [CMS] Biểu đồ
     * @param {string} userId
     * @param {string} userName
     * @param {number} fromDate
     * @param {number} toDate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsPaidChart(
      userId: string,
      userName: string,
      fromDate: number,
      toDate: number,
      options?: any,
    ): AxiosPromise<BaseResponseListCmsPaidChartItem> {
      return localVarFp
        .cmsPaidChart(userId, userName, fromDate, toDate, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Lấy thông tin thống kê doanh nghiệp chi lương
     * @summary [CMS] Thống kê
     * @param {string} userId
     * @param {string} userName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsStatistic(
      userId: string,
      userName: string,
      options?: any,
    ): AxiosPromise<BaseResponseCmsStatisticResponse> {
      return localVarFp
        .cmsStatistic(userId, userName, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Thống kê các doanh nghiệp và số tiền đã ứng, đã chi, phí dịch vụ
     * @summary [CMS] Chi tiết thống kê
     * @param {string} userId
     * @param {string} userName
     * @param {number} fromDate
     * @param {number} toDate
     * @param {string} [enterpriseCode]
     * @param {string} [enterpriseName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsStatisticGroupByEnterprise(
      userId: string,
      userName: string,
      fromDate: number,
      toDate: number,
      enterpriseCode?: string,
      enterpriseName?: string,
      options?: any,
    ): AxiosPromise<BaseResponseListCmsEnterprisePaidInfo> {
      return localVarFp
        .cmsStatisticGroupByEnterprise(
          userId,
          userName,
          fromDate,
          toDate,
          enterpriseCode,
          enterpriseName,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Lấy chi tiết doanh nghiệp tham gia chi lương linh hoạt
     * @summary [CMS] Chi tiết doanh nghiêp
     * @param {string} userId
     * @param {string} userName
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    detailEnterprise(
      userId: string,
      userName: string,
      id: string,
      options?: any,
    ): AxiosPromise<BaseResponseCmsEnterpriseDetail> {
      return localVarFp
        .detailEnterprise(userId, userName, id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Lấy chi tiết yêu cầu chờ duyệt
     * @summary [CMS] Chi tiết yêu cầu chờ duyệt
     * @param {string} userId
     * @param {string} userName
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    detailRegistrationRequest(
      userId: string,
      userName: string,
      id: string,
      options?: any,
    ): AxiosPromise<BaseResponseRegistrationDetail> {
      return localVarFp
        .detailRegistrationRequest(userId, userName, id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Chỉnh sửa doanh nghiệp tham gia chi lương linh hoạt
     * @summary [CMS] Chỉnh sửa doanh nghiêp
     * @param {string} userId
     * @param {string} userName
     * @param {string} id
     * @param {CmsEnterpriseRequest} cmsEnterpriseRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editEnterprise(
      userId: string,
      userName: string,
      id: string,
      cmsEnterpriseRequest: CmsEnterpriseRequest,
      options?: any,
    ): AxiosPromise<BaseResponseObject> {
      return localVarFp
        .editEnterprise(userId, userName, id, cmsEnterpriseRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Lấy danh sách doanh nghiệp tham gia chi lương linh hoạt
     * @summary [CMS] Danh sách doanh nghiêp
     * @param {string} userId
     * @param {string} userName
     * @param {number} page
     * @param {number} size
     * @param {string} [enterpriseName]
     * @param {string} [enterpriseCode]
     * @param {string} [ownerName]
     * @param {boolean} [activated]
     * @param {boolean} [cmsLocked]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listEnterprise(
      userId: string,
      userName: string,
      page: number,
      size: number,
      enterpriseName?: string,
      enterpriseCode?: string,
      ownerName?: string,
      activated?: boolean,
      cmsLocked?: boolean,
      options?: any,
    ): AxiosPromise<BaseResponsePageDtoListCmsEnterpriseInfo> {
      return localVarFp
        .listEnterprise(
          userId,
          userName,
          page,
          size,
          enterpriseName,
          enterpriseCode,
          ownerName,
          activated,
          cmsLocked,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Lấy danh sách chờ duyệt
     * @summary [CMS] Danh sách chờ duyệt
     * @param {string} userId
     * @param {string} userName
     * @param {number} page
     * @param {number} size
     * @param {string} [enterpriseName]
     * @param {string} [enterpriseCode]
     * @param {string} [ownerName]
     * @param {number} [fromDate]
     * @param {number} [toDate]
     * @param {'WAITING_APPROVAL' | 'APPROVED' | 'REJECTED' | 'CANCEL'} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listRegistrationRequest(
      userId: string,
      userName: string,
      page: number,
      size: number,
      enterpriseName?: string,
      enterpriseCode?: string,
      ownerName?: string,
      fromDate?: number,
      toDate?: number,
      status?: "WAITING_APPROVAL" | "APPROVED" | "REJECTED" | "CANCEL",
      options?: any,
    ): AxiosPromise<BaseResponsePageDtoListRegistrationInfo> {
      return localVarFp
        .listRegistrationRequest(
          userId,
          userName,
          page,
          size,
          enterpriseName,
          enterpriseCode,
          ownerName,
          fromDate,
          toDate,
          status,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Vô hiệu hóa doanh nghiệp tham gia chi lương linh hoạt
     * @summary [CMS] Vô hiệu hóa doanh nghiêp
     * @param {string} userId
     * @param {string} userName
     * @param {string} id
     * @param {CmsLockEnterpriseRequest} cmsLockEnterpriseRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    lockEnterprise(
      userId: string,
      userName: string,
      id: string,
      cmsLockEnterpriseRequest: CmsLockEnterpriseRequest,
      options?: any,
    ): AxiosPromise<BaseResponseObject> {
      return localVarFp
        .lockEnterprise(userId, userName, id, cmsLockEnterpriseRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Từ chối
     * @summary [CMS] Từ chối
     * @param {string} userId
     * @param {string} userName
     * @param {string} id
     * @param {RejectRegistrationRequest} rejectRegistrationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rejectRegistration(
      userId: string,
      userName: string,
      id: string,
      rejectRegistrationRequest: RejectRegistrationRequest,
      options?: any,
    ): AxiosPromise<BaseResponseObject> {
      return localVarFp
        .rejectRegistration(
          userId,
          userName,
          id,
          rejectRegistrationRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Kích hoạt doanh nghiệp tham gia chi lương linh hoạt
     * @summary [CMS] Kích hoạt doanh nghiêp
     * @param {string} userId
     * @param {string} userName
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unlockEnterprise(
      userId: string,
      userName: string,
      id: string,
      options?: any,
    ): AxiosPromise<BaseResponseObject> {
      return localVarFp
        .unlockEnterprise(userId, userName, id, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for approveRegistration operation in CMSEnterpriseApi.
 * @export
 * @interface CMSEnterpriseApiApproveRegistrationRequest
 */
export interface CMSEnterpriseApiApproveRegistrationRequest {
  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiApproveRegistration
   */
  readonly userId: string;

  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiApproveRegistration
   */
  readonly userName: string;

  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiApproveRegistration
   */
  readonly id: string;

  /**
   *
   * @type {ApproveRegistrationRequest}
   * @memberof CMSEnterpriseApiApproveRegistration
   */
  readonly approveRegistrationRequest: ApproveRegistrationRequest;
}

/**
 * Request parameters for cmsAdvanceRequestDetail operation in CMSEnterpriseApi.
 * @export
 * @interface CMSEnterpriseApiCmsAdvanceRequestDetailRequest
 */
export interface CMSEnterpriseApiCmsAdvanceRequestDetailRequest {
  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiCmsAdvanceRequestDetail
   */
  readonly userId: string;

  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiCmsAdvanceRequestDetail
   */
  readonly userName: string;

  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiCmsAdvanceRequestDetail
   */
  readonly id: string;
}

/**
 * Request parameters for cmsDownloadEnterpriseStatisticGroupByEmployee operation in CMSEnterpriseApi.
 * @export
 * @interface CMSEnterpriseApiCmsDownloadEnterpriseStatisticGroupByEmployeeRequest
 */
export interface CMSEnterpriseApiCmsDownloadEnterpriseStatisticGroupByEmployeeRequest {
  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiCmsDownloadEnterpriseStatisticGroupByEmployee
   */
  readonly userId: string;

  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiCmsDownloadEnterpriseStatisticGroupByEmployee
   */
  readonly userName: string;

  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiCmsDownloadEnterpriseStatisticGroupByEmployee
   */
  readonly id: string;

  /**
   *
   * @type {number}
   * @memberof CMSEnterpriseApiCmsDownloadEnterpriseStatisticGroupByEmployee
   */
  readonly fromDate: number;

  /**
   *
   * @type {number}
   * @memberof CMSEnterpriseApiCmsDownloadEnterpriseStatisticGroupByEmployee
   */
  readonly toDate: number;

  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiCmsDownloadEnterpriseStatisticGroupByEmployee
   */
  readonly employeeCode?: string;

  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiCmsDownloadEnterpriseStatisticGroupByEmployee
   */
  readonly employeeName?: string;
}

/**
 * Request parameters for cmsDownloadStatisticGroupByEnterprise operation in CMSEnterpriseApi.
 * @export
 * @interface CMSEnterpriseApiCmsDownloadStatisticGroupByEnterpriseRequest
 */
export interface CMSEnterpriseApiCmsDownloadStatisticGroupByEnterpriseRequest {
  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiCmsDownloadStatisticGroupByEnterprise
   */
  readonly userId: string;

  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiCmsDownloadStatisticGroupByEnterprise
   */
  readonly userName: string;

  /**
   *
   * @type {number}
   * @memberof CMSEnterpriseApiCmsDownloadStatisticGroupByEnterprise
   */
  readonly fromDate: number;

  /**
   *
   * @type {number}
   * @memberof CMSEnterpriseApiCmsDownloadStatisticGroupByEnterprise
   */
  readonly toDate: number;

  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiCmsDownloadStatisticGroupByEnterprise
   */
  readonly enterpriseCode?: string;

  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiCmsDownloadStatisticGroupByEnterprise
   */
  readonly enterpriseName?: string;
}

/**
 * Request parameters for cmsEnterpriseStatisticGroupByEmployee operation in CMSEnterpriseApi.
 * @export
 * @interface CMSEnterpriseApiCmsEnterpriseStatisticGroupByEmployeeRequest
 */
export interface CMSEnterpriseApiCmsEnterpriseStatisticGroupByEmployeeRequest {
  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiCmsEnterpriseStatisticGroupByEmployee
   */
  readonly userId: string;

  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiCmsEnterpriseStatisticGroupByEmployee
   */
  readonly userName: string;

  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiCmsEnterpriseStatisticGroupByEmployee
   */
  readonly id: string;

  /**
   *
   * @type {number}
   * @memberof CMSEnterpriseApiCmsEnterpriseStatisticGroupByEmployee
   */
  readonly fromDate: number;

  /**
   *
   * @type {number}
   * @memberof CMSEnterpriseApiCmsEnterpriseStatisticGroupByEmployee
   */
  readonly toDate: number;

  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiCmsEnterpriseStatisticGroupByEmployee
   */
  readonly employeeCode?: string;

  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiCmsEnterpriseStatisticGroupByEmployee
   */
  readonly employeeName?: string;
}

/**
 * Request parameters for cmsListAdvanceRequestInfo operation in CMSEnterpriseApi.
 * @export
 * @interface CMSEnterpriseApiCmsListAdvanceRequestInfoRequest
 */
export interface CMSEnterpriseApiCmsListAdvanceRequestInfoRequest {
  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiCmsListAdvanceRequestInfo
   */
  readonly userId: string;

  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiCmsListAdvanceRequestInfo
   */
  readonly userName: string;

  /**
   *
   * @type {number}
   * @memberof CMSEnterpriseApiCmsListAdvanceRequestInfo
   */
  readonly fromDate: number;

  /**
   *
   * @type {number}
   * @memberof CMSEnterpriseApiCmsListAdvanceRequestInfo
   */
  readonly toDate: number;

  /**
   *
   * @type {number}
   * @memberof CMSEnterpriseApiCmsListAdvanceRequestInfo
   */
  readonly page: number;

  /**
   *
   * @type {number}
   * @memberof CMSEnterpriseApiCmsListAdvanceRequestInfo
   */
  readonly size: number;

  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiCmsListAdvanceRequestInfo
   */
  readonly code?: string;

  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiCmsListAdvanceRequestInfo
   */
  readonly enterpriseName?: string;

  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiCmsListAdvanceRequestInfo
   */
  readonly toAccount?: string;

  /**
   *
   * @type {'APPROVED' | 'REJECTED' | 'WAITING_APPROVAL' | 'CANCELED' | 'EXPIRED'}
   * @memberof CMSEnterpriseApiCmsListAdvanceRequestInfo
   */
  readonly status?:
    | "APPROVED"
    | "REJECTED"
    | "WAITING_APPROVAL"
    | "CANCELED"
    | "EXPIRED";

  /**
   *
   * @type {'SUCCESS' | 'FAILED' | 'PROCESSING' | 'TODO' | 'NONE'}
   * @memberof CMSEnterpriseApiCmsListAdvanceRequestInfo
   */
  readonly paymentStatus?:
    | "SUCCESS"
    | "FAILED"
    | "PROCESSING"
    | "TODO"
    | "NONE";
}

/**
 * Request parameters for cmsPaidChart operation in CMSEnterpriseApi.
 * @export
 * @interface CMSEnterpriseApiCmsPaidChartRequest
 */
export interface CMSEnterpriseApiCmsPaidChartRequest {
  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiCmsPaidChart
   */
  readonly userId: string;

  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiCmsPaidChart
   */
  readonly userName: string;

  /**
   *
   * @type {number}
   * @memberof CMSEnterpriseApiCmsPaidChart
   */
  readonly fromDate: number;

  /**
   *
   * @type {number}
   * @memberof CMSEnterpriseApiCmsPaidChart
   */
  readonly toDate: number;
}

/**
 * Request parameters for cmsStatistic operation in CMSEnterpriseApi.
 * @export
 * @interface CMSEnterpriseApiCmsStatisticRequest
 */
export interface CMSEnterpriseApiCmsStatisticRequest {
  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiCmsStatistic
   */
  readonly userId: string;

  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiCmsStatistic
   */
  readonly userName: string;
}

/**
 * Request parameters for cmsStatisticGroupByEnterprise operation in CMSEnterpriseApi.
 * @export
 * @interface CMSEnterpriseApiCmsStatisticGroupByEnterpriseRequest
 */
export interface CMSEnterpriseApiCmsStatisticGroupByEnterpriseRequest {
  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiCmsStatisticGroupByEnterprise
   */
  readonly userId: string;

  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiCmsStatisticGroupByEnterprise
   */
  readonly userName: string;

  /**
   *
   * @type {number}
   * @memberof CMSEnterpriseApiCmsStatisticGroupByEnterprise
   */
  readonly fromDate: number;

  /**
   *
   * @type {number}
   * @memberof CMSEnterpriseApiCmsStatisticGroupByEnterprise
   */
  readonly toDate: number;

  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiCmsStatisticGroupByEnterprise
   */
  readonly enterpriseCode?: string;

  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiCmsStatisticGroupByEnterprise
   */
  readonly enterpriseName?: string;
}

/**
 * Request parameters for detailEnterprise operation in CMSEnterpriseApi.
 * @export
 * @interface CMSEnterpriseApiDetailEnterpriseRequest
 */
export interface CMSEnterpriseApiDetailEnterpriseRequest {
  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiDetailEnterprise
   */
  readonly userId: string;

  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiDetailEnterprise
   */
  readonly userName: string;

  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiDetailEnterprise
   */
  readonly id: string;
}

/**
 * Request parameters for detailRegistrationRequest operation in CMSEnterpriseApi.
 * @export
 * @interface CMSEnterpriseApiDetailRegistrationRequestRequest
 */
export interface CMSEnterpriseApiDetailRegistrationRequestRequest {
  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiDetailRegistrationRequest
   */
  readonly userId: string;

  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiDetailRegistrationRequest
   */
  readonly userName: string;

  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiDetailRegistrationRequest
   */
  readonly id: string;
}

/**
 * Request parameters for editEnterprise operation in CMSEnterpriseApi.
 * @export
 * @interface CMSEnterpriseApiEditEnterpriseRequest
 */
export interface CMSEnterpriseApiEditEnterpriseRequest {
  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiEditEnterprise
   */
  readonly userId: string;

  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiEditEnterprise
   */
  readonly userName: string;

  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiEditEnterprise
   */
  readonly id: string;

  /**
   *
   * @type {CmsEnterpriseRequest}
   * @memberof CMSEnterpriseApiEditEnterprise
   */
  readonly cmsEnterpriseRequest: CmsEnterpriseRequest;
}

/**
 * Request parameters for listEnterprise operation in CMSEnterpriseApi.
 * @export
 * @interface CMSEnterpriseApiListEnterpriseRequest
 */
export interface CMSEnterpriseApiListEnterpriseRequest {
  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiListEnterprise
   */
  readonly userId: string;

  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiListEnterprise
   */
  readonly userName: string;

  /**
   *
   * @type {number}
   * @memberof CMSEnterpriseApiListEnterprise
   */
  readonly page: number;

  /**
   *
   * @type {number}
   * @memberof CMSEnterpriseApiListEnterprise
   */
  readonly size: number;

  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiListEnterprise
   */
  readonly enterpriseName?: string;

  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiListEnterprise
   */
  readonly enterpriseCode?: string;

  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiListEnterprise
   */
  readonly ownerName?: string;

  /**
   *
   * @type {boolean}
   * @memberof CMSEnterpriseApiListEnterprise
   */
  readonly activated?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof CMSEnterpriseApiListEnterprise
   */
  readonly cmsLocked?: boolean;
}

/**
 * Request parameters for listRegistrationRequest operation in CMSEnterpriseApi.
 * @export
 * @interface CMSEnterpriseApiListRegistrationRequestRequest
 */
export interface CMSEnterpriseApiListRegistrationRequestRequest {
  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiListRegistrationRequest
   */
  readonly userId: string;

  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiListRegistrationRequest
   */
  readonly userName: string;

  /**
   *
   * @type {number}
   * @memberof CMSEnterpriseApiListRegistrationRequest
   */
  readonly page: number;

  /**
   *
   * @type {number}
   * @memberof CMSEnterpriseApiListRegistrationRequest
   */
  readonly size: number;

  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiListRegistrationRequest
   */
  readonly enterpriseName?: string;

  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiListRegistrationRequest
   */
  readonly enterpriseCode?: string;

  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiListRegistrationRequest
   */
  readonly ownerName?: string;

  /**
   *
   * @type {number}
   * @memberof CMSEnterpriseApiListRegistrationRequest
   */
  readonly fromDate?: number;

  /**
   *
   * @type {number}
   * @memberof CMSEnterpriseApiListRegistrationRequest
   */
  readonly toDate?: number;

  /**
   *
   * @type {'WAITING_APPROVAL' | 'APPROVED' | 'REJECTED' | 'CANCEL'}
   * @memberof CMSEnterpriseApiListRegistrationRequest
   */
  readonly status?: "WAITING_APPROVAL" | "APPROVED" | "REJECTED" | "CANCEL";
}

/**
 * Request parameters for lockEnterprise operation in CMSEnterpriseApi.
 * @export
 * @interface CMSEnterpriseApiLockEnterpriseRequest
 */
export interface CMSEnterpriseApiLockEnterpriseRequest {
  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiLockEnterprise
   */
  readonly userId: string;

  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiLockEnterprise
   */
  readonly userName: string;

  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiLockEnterprise
   */
  readonly id: string;

  /**
   *
   * @type {CmsLockEnterpriseRequest}
   * @memberof CMSEnterpriseApiLockEnterprise
   */
  readonly cmsLockEnterpriseRequest: CmsLockEnterpriseRequest;
}

/**
 * Request parameters for rejectRegistration operation in CMSEnterpriseApi.
 * @export
 * @interface CMSEnterpriseApiRejectRegistrationRequest
 */
export interface CMSEnterpriseApiRejectRegistrationRequest {
  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiRejectRegistration
   */
  readonly userId: string;

  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiRejectRegistration
   */
  readonly userName: string;

  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiRejectRegistration
   */
  readonly id: string;

  /**
   *
   * @type {RejectRegistrationRequest}
   * @memberof CMSEnterpriseApiRejectRegistration
   */
  readonly rejectRegistrationRequest: RejectRegistrationRequest;
}

/**
 * Request parameters for unlockEnterprise operation in CMSEnterpriseApi.
 * @export
 * @interface CMSEnterpriseApiUnlockEnterpriseRequest
 */
export interface CMSEnterpriseApiUnlockEnterpriseRequest {
  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiUnlockEnterprise
   */
  readonly userId: string;

  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiUnlockEnterprise
   */
  readonly userName: string;

  /**
   *
   * @type {string}
   * @memberof CMSEnterpriseApiUnlockEnterprise
   */
  readonly id: string;
}

/**
 * CMSEnterpriseApi - object-oriented interface
 * @export
 * @class CMSEnterpriseApi
 * @extends {BaseAPI}
 */
export class CMSEnterpriseApi extends BaseAPI {
  /**
   * Duyệt
   * @summary [CMS] Duyệt
   * @param {CMSEnterpriseApiApproveRegistrationRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSEnterpriseApi
   */
  public approveRegistration(
    requestParameters: CMSEnterpriseApiApproveRegistrationRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSEnterpriseApiFp(this.configuration)
      .approveRegistration(
        requestParameters.userId,
        requestParameters.userName,
        requestParameters.id,
        requestParameters.approveRegistrationRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Chi tiết giao dịch
   * @summary [CMS] Chi tiết giao dịch
   * @param {CMSEnterpriseApiCmsAdvanceRequestDetailRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSEnterpriseApi
   */
  public cmsAdvanceRequestDetail(
    requestParameters: CMSEnterpriseApiCmsAdvanceRequestDetailRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSEnterpriseApiFp(this.configuration)
      .cmsAdvanceRequestDetail(
        requestParameters.userId,
        requestParameters.userName,
        requestParameters.id,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Tải file excel chi tiết thống kê số tiền đã ứng, đã chi, phí dịch vụ của doanh nghiệp
   * @summary [CMS] Tải chi tiết thống kê đối tác
   * @param {CMSEnterpriseApiCmsDownloadEnterpriseStatisticGroupByEmployeeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSEnterpriseApi
   */
  public cmsDownloadEnterpriseStatisticGroupByEmployee(
    requestParameters: CMSEnterpriseApiCmsDownloadEnterpriseStatisticGroupByEmployeeRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSEnterpriseApiFp(this.configuration)
      .cmsDownloadEnterpriseStatisticGroupByEmployee(
        requestParameters.userId,
        requestParameters.userName,
        requestParameters.id,
        requestParameters.fromDate,
        requestParameters.toDate,
        requestParameters.employeeCode,
        requestParameters.employeeName,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Tải file thống kê các doanh nghiệp và số tiền đã ứng, đã chi, phí dịch vụ
   * @summary [CMS] Tải chi tiết thống kê
   * @param {CMSEnterpriseApiCmsDownloadStatisticGroupByEnterpriseRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSEnterpriseApi
   */
  public cmsDownloadStatisticGroupByEnterprise(
    requestParameters: CMSEnterpriseApiCmsDownloadStatisticGroupByEnterpriseRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSEnterpriseApiFp(this.configuration)
      .cmsDownloadStatisticGroupByEnterprise(
        requestParameters.userId,
        requestParameters.userName,
        requestParameters.fromDate,
        requestParameters.toDate,
        requestParameters.enterpriseCode,
        requestParameters.enterpriseName,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Thống kê số tiền đã ứng, đã chi, phí dịch vụ của doanh nghiệp
   * @summary [CMS] Chi tiết thống kê đối tác
   * @param {CMSEnterpriseApiCmsEnterpriseStatisticGroupByEmployeeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSEnterpriseApi
   */
  public cmsEnterpriseStatisticGroupByEmployee(
    requestParameters: CMSEnterpriseApiCmsEnterpriseStatisticGroupByEmployeeRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSEnterpriseApiFp(this.configuration)
      .cmsEnterpriseStatisticGroupByEmployee(
        requestParameters.userId,
        requestParameters.userName,
        requestParameters.id,
        requestParameters.fromDate,
        requestParameters.toDate,
        requestParameters.employeeCode,
        requestParameters.employeeName,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Danh sách giao dịch
   * @summary [CMS] Danh sách giao dịch
   * @param {CMSEnterpriseApiCmsListAdvanceRequestInfoRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSEnterpriseApi
   */
  public cmsListAdvanceRequestInfo(
    requestParameters: CMSEnterpriseApiCmsListAdvanceRequestInfoRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSEnterpriseApiFp(this.configuration)
      .cmsListAdvanceRequestInfo(
        requestParameters.userId,
        requestParameters.userName,
        requestParameters.fromDate,
        requestParameters.toDate,
        requestParameters.page,
        requestParameters.size,
        requestParameters.code,
        requestParameters.enterpriseName,
        requestParameters.toAccount,
        requestParameters.status,
        requestParameters.paymentStatus,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Biểu đồ chi lương
   * @summary [CMS] Biểu đồ
   * @param {CMSEnterpriseApiCmsPaidChartRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSEnterpriseApi
   */
  public cmsPaidChart(
    requestParameters: CMSEnterpriseApiCmsPaidChartRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSEnterpriseApiFp(this.configuration)
      .cmsPaidChart(
        requestParameters.userId,
        requestParameters.userName,
        requestParameters.fromDate,
        requestParameters.toDate,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Lấy thông tin thống kê doanh nghiệp chi lương
   * @summary [CMS] Thống kê
   * @param {CMSEnterpriseApiCmsStatisticRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSEnterpriseApi
   */
  public cmsStatistic(
    requestParameters: CMSEnterpriseApiCmsStatisticRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSEnterpriseApiFp(this.configuration)
      .cmsStatistic(
        requestParameters.userId,
        requestParameters.userName,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Thống kê các doanh nghiệp và số tiền đã ứng, đã chi, phí dịch vụ
   * @summary [CMS] Chi tiết thống kê
   * @param {CMSEnterpriseApiCmsStatisticGroupByEnterpriseRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSEnterpriseApi
   */
  public cmsStatisticGroupByEnterprise(
    requestParameters: CMSEnterpriseApiCmsStatisticGroupByEnterpriseRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSEnterpriseApiFp(this.configuration)
      .cmsStatisticGroupByEnterprise(
        requestParameters.userId,
        requestParameters.userName,
        requestParameters.fromDate,
        requestParameters.toDate,
        requestParameters.enterpriseCode,
        requestParameters.enterpriseName,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Lấy chi tiết doanh nghiệp tham gia chi lương linh hoạt
   * @summary [CMS] Chi tiết doanh nghiêp
   * @param {CMSEnterpriseApiDetailEnterpriseRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSEnterpriseApi
   */
  public detailEnterprise(
    requestParameters: CMSEnterpriseApiDetailEnterpriseRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSEnterpriseApiFp(this.configuration)
      .detailEnterprise(
        requestParameters.userId,
        requestParameters.userName,
        requestParameters.id,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Lấy chi tiết yêu cầu chờ duyệt
   * @summary [CMS] Chi tiết yêu cầu chờ duyệt
   * @param {CMSEnterpriseApiDetailRegistrationRequestRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSEnterpriseApi
   */
  public detailRegistrationRequest(
    requestParameters: CMSEnterpriseApiDetailRegistrationRequestRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSEnterpriseApiFp(this.configuration)
      .detailRegistrationRequest(
        requestParameters.userId,
        requestParameters.userName,
        requestParameters.id,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Chỉnh sửa doanh nghiệp tham gia chi lương linh hoạt
   * @summary [CMS] Chỉnh sửa doanh nghiêp
   * @param {CMSEnterpriseApiEditEnterpriseRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSEnterpriseApi
   */
  public editEnterprise(
    requestParameters: CMSEnterpriseApiEditEnterpriseRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSEnterpriseApiFp(this.configuration)
      .editEnterprise(
        requestParameters.userId,
        requestParameters.userName,
        requestParameters.id,
        requestParameters.cmsEnterpriseRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Lấy danh sách doanh nghiệp tham gia chi lương linh hoạt
   * @summary [CMS] Danh sách doanh nghiêp
   * @param {CMSEnterpriseApiListEnterpriseRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSEnterpriseApi
   */
  public listEnterprise(
    requestParameters: CMSEnterpriseApiListEnterpriseRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSEnterpriseApiFp(this.configuration)
      .listEnterprise(
        requestParameters.userId,
        requestParameters.userName,
        requestParameters.page,
        requestParameters.size,
        requestParameters.enterpriseName,
        requestParameters.enterpriseCode,
        requestParameters.ownerName,
        requestParameters.activated,
        requestParameters.cmsLocked,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Lấy danh sách chờ duyệt
   * @summary [CMS] Danh sách chờ duyệt
   * @param {CMSEnterpriseApiListRegistrationRequestRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSEnterpriseApi
   */
  public listRegistrationRequest(
    requestParameters: CMSEnterpriseApiListRegistrationRequestRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSEnterpriseApiFp(this.configuration)
      .listRegistrationRequest(
        requestParameters.userId,
        requestParameters.userName,
        requestParameters.page,
        requestParameters.size,
        requestParameters.enterpriseName,
        requestParameters.enterpriseCode,
        requestParameters.ownerName,
        requestParameters.fromDate,
        requestParameters.toDate,
        requestParameters.status,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Vô hiệu hóa doanh nghiệp tham gia chi lương linh hoạt
   * @summary [CMS] Vô hiệu hóa doanh nghiêp
   * @param {CMSEnterpriseApiLockEnterpriseRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSEnterpriseApi
   */
  public lockEnterprise(
    requestParameters: CMSEnterpriseApiLockEnterpriseRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSEnterpriseApiFp(this.configuration)
      .lockEnterprise(
        requestParameters.userId,
        requestParameters.userName,
        requestParameters.id,
        requestParameters.cmsLockEnterpriseRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Từ chối
   * @summary [CMS] Từ chối
   * @param {CMSEnterpriseApiRejectRegistrationRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSEnterpriseApi
   */
  public rejectRegistration(
    requestParameters: CMSEnterpriseApiRejectRegistrationRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSEnterpriseApiFp(this.configuration)
      .rejectRegistration(
        requestParameters.userId,
        requestParameters.userName,
        requestParameters.id,
        requestParameters.rejectRegistrationRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Kích hoạt doanh nghiệp tham gia chi lương linh hoạt
   * @summary [CMS] Kích hoạt doanh nghiêp
   * @param {CMSEnterpriseApiUnlockEnterpriseRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSEnterpriseApi
   */
  public unlockEnterprise(
    requestParameters: CMSEnterpriseApiUnlockEnterpriseRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSEnterpriseApiFp(this.configuration)
      .unlockEnterprise(
        requestParameters.userId,
        requestParameters.userName,
        requestParameters.id,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * EmployeeApi - axios parameter creator
 * @export
 */
export const EmployeeApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Thêm nhân viên
     * @summary Thêm nhân viên
     * @param {EmployeeRequest} employeeRequest
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addEmployee: async (
      employeeRequest: EmployeeRequest,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'employeeRequest' is not null or undefined
      assertParamExists("addEmployee", "employeeRequest", employeeRequest);
      const localVarPath = `/api/v1/smart-bank/employee`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (enterpriseId != null) {
        localVarHeaderParameter["enterprise-id"] = String(enterpriseId);
      }

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      if (userName != null) {
        localVarHeaderParameter["user-name"] = String(userName);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        employeeRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Thêm hàng loạt nhân viên (hoặc chỉnh sửa thông tin cũ)
     * @summary Thêm hàng loạt nhân viên
     * @param {BulkRequestEmployeeRequest} bulkRequestEmployeeRequest
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bulkAddEmployee: async (
      bulkRequestEmployeeRequest: BulkRequestEmployeeRequest,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bulkRequestEmployeeRequest' is not null or undefined
      assertParamExists(
        "bulkAddEmployee",
        "bulkRequestEmployeeRequest",
        bulkRequestEmployeeRequest,
      );
      const localVarPath = `/api/v1/smart-bank/employee/bulk`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (enterpriseId != null) {
        localVarHeaderParameter["enterprise-id"] = String(enterpriseId);
      }

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      if (userName != null) {
        localVarHeaderParameter["user-name"] = String(userName);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bulkRequestEmployeeRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Đổi loại ứng lương cho 1 danh sách nhân viên
     * @summary Cập nhật hàng hoạt loại ứng lương
     * @param {BulkUpdateAdvanceTypeRequest} bulkUpdateAdvanceTypeRequest
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bulkUpdateSalaryAdvanceType: async (
      bulkUpdateAdvanceTypeRequest: BulkUpdateAdvanceTypeRequest,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bulkUpdateAdvanceTypeRequest' is not null or undefined
      assertParamExists(
        "bulkUpdateSalaryAdvanceType",
        "bulkUpdateAdvanceTypeRequest",
        bulkUpdateAdvanceTypeRequest,
      );
      const localVarPath = `/api/v1/smart-bank/employee/bulk/salary-advance-type`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (enterpriseId != null) {
        localVarHeaderParameter["enterprise-id"] = String(enterpriseId);
      }

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      if (userName != null) {
        localVarHeaderParameter["user-name"] = String(userName);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bulkUpdateAdvanceTypeRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Chỉnh sửa nhân viên
     * @summary Chỉnh sửa nhân viên
     * @param {string} id
     * @param {EmployeeRequest} employeeRequest
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editEmployee: async (
      id: string,
      employeeRequest: EmployeeRequest,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("editEmployee", "id", id);
      // verify required parameter 'employeeRequest' is not null or undefined
      assertParamExists("editEmployee", "employeeRequest", employeeRequest);
      const localVarPath = `/api/v1/smart-bank/employee/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (enterpriseId != null) {
        localVarHeaderParameter["enterprise-id"] = String(enterpriseId);
      }

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      if (userName != null) {
        localVarHeaderParameter["user-name"] = String(userName);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        employeeRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Tạo file excel cập nhật danh sách nhân viên
     * @summary Tạo file cập nhật danh sách nhân viên
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateTemplateFile1: async (
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/smart-bank/employee/template`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (enterpriseId != null) {
        localVarHeaderParameter["enterprise-id"] = String(enterpriseId);
      }

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      if (userName != null) {
        localVarHeaderParameter["user-name"] = String(userName);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Lấy chi tiết nhân viên
     * @summary Chi tiết nhân viên
     * @param {string} id
     * @param {string} [enterpriseId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEmployee: async (
      id: string,
      enterpriseId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getEmployee", "id", id);
      const localVarPath = `/api/v1/smart-bank/employee/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (enterpriseId != null) {
        localVarHeaderParameter["enterprise-id"] = String(enterpriseId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Lấy danh sách nhân viên
     * @summary Danh sách nhân viên
     * @param {number} page
     * @param {number} size
     * @param {string} [enterpriseId]
     * @param {number} [startDate]
     * @param {number} [endDate]
     * @param {string} [salaryAdvanceTypeId]
     * @param {boolean} [activated]
     * @param {string} [keyword]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListEmployee: async (
      page: number,
      size: number,
      enterpriseId?: string,
      startDate?: number,
      endDate?: number,
      salaryAdvanceTypeId?: string,
      activated?: boolean,
      keyword?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'page' is not null or undefined
      assertParamExists("getListEmployee", "page", page);
      // verify required parameter 'size' is not null or undefined
      assertParamExists("getListEmployee", "size", size);
      const localVarPath = `/api/v1/smart-bank/employee`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (startDate !== undefined) {
        localVarQueryParameter["startDate"] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter["endDate"] = endDate;
      }

      if (salaryAdvanceTypeId !== undefined) {
        localVarQueryParameter["salaryAdvanceTypeId"] = salaryAdvanceTypeId;
      }

      if (activated !== undefined) {
        localVarQueryParameter["activated"] = activated;
      }

      if (keyword !== undefined) {
        localVarQueryParameter["keyword"] = keyword;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (enterpriseId != null) {
        localVarHeaderParameter["enterprise-id"] = String(enterpriseId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Danh sách yêu cầu ứng lương theo nhân viên
     * @summary Danh sách yêu cầu ứng lương theo nhân viên
     * @param {string} id
     * @param {number} page
     * @param {number} size
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {'APPROVED' | 'REJECTED' | 'WAITING_APPROVAL' | 'CANCELED' | 'EXPIRED'} [status]
     * @param {number} [fromDate]
     * @param {number} [toDate]
     * @param {string} [keyword]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSalaryAdvanceListByEmployee: async (
      id: string,
      page: number,
      size: number,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      status?:
        | "APPROVED"
        | "REJECTED"
        | "WAITING_APPROVAL"
        | "CANCELED"
        | "EXPIRED",
      fromDate?: number,
      toDate?: number,
      keyword?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getSalaryAdvanceListByEmployee", "id", id);
      // verify required parameter 'page' is not null or undefined
      assertParamExists("getSalaryAdvanceListByEmployee", "page", page);
      // verify required parameter 'size' is not null or undefined
      assertParamExists("getSalaryAdvanceListByEmployee", "size", size);
      const localVarPath =
        `/api/v1/smart-bank/employee/{id}/salary-advance`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(id)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (fromDate !== undefined) {
        localVarQueryParameter["fromDate"] = fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter["toDate"] = toDate;
      }

      if (keyword !== undefined) {
        localVarQueryParameter["keyword"] = keyword;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (enterpriseId != null) {
        localVarHeaderParameter["enterprise-id"] = String(enterpriseId);
      }

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      if (userName != null) {
        localVarHeaderParameter["user-name"] = String(userName);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Tạo hoặc cập nhật danh sách nhân viên từ excel
     * @summary Upload nhân viên từ excel
     * @param {File} file
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadEmployeeFile: async (
      file: File,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'file' is not null or undefined
      assertParamExists("uploadEmployeeFile", "file", file);
      const localVarPath = `/api/v1/smart-bank/employee/upload`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (enterpriseId != null) {
        localVarHeaderParameter["enterprise-id"] = String(enterpriseId);
      }

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      if (userName != null) {
        localVarHeaderParameter["user-name"] = String(userName);
      }

      if (file !== undefined) {
        localVarFormParams.append("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * EmployeeApi - functional programming interface
 * @export
 */
export const EmployeeApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = EmployeeApiAxiosParamCreator(configuration);
  return {
    /**
     * Thêm nhân viên
     * @summary Thêm nhân viên
     * @param {EmployeeRequest} employeeRequest
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addEmployee(
      employeeRequest: EmployeeRequest,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseCreatedResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addEmployee(
        employeeRequest,
        enterpriseId,
        userId,
        userName,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Thêm hàng loạt nhân viên (hoặc chỉnh sửa thông tin cũ)
     * @summary Thêm hàng loạt nhân viên
     * @param {BulkRequestEmployeeRequest} bulkRequestEmployeeRequest
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bulkAddEmployee(
      bulkRequestEmployeeRequest: BulkRequestEmployeeRequest,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseObject>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bulkAddEmployee(
        bulkRequestEmployeeRequest,
        enterpriseId,
        userId,
        userName,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Đổi loại ứng lương cho 1 danh sách nhân viên
     * @summary Cập nhật hàng hoạt loại ứng lương
     * @param {BulkUpdateAdvanceTypeRequest} bulkUpdateAdvanceTypeRequest
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bulkUpdateSalaryAdvanceType(
      bulkUpdateAdvanceTypeRequest: BulkUpdateAdvanceTypeRequest,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseObject>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.bulkUpdateSalaryAdvanceType(
          bulkUpdateAdvanceTypeRequest,
          enterpriseId,
          userId,
          userName,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Chỉnh sửa nhân viên
     * @summary Chỉnh sửa nhân viên
     * @param {string} id
     * @param {EmployeeRequest} employeeRequest
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async editEmployee(
      id: string,
      employeeRequest: EmployeeRequest,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseObject>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.editEmployee(
        id,
        employeeRequest,
        enterpriseId,
        userId,
        userName,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Tạo file excel cập nhật danh sách nhân viên
     * @summary Tạo file cập nhật danh sách nhân viên
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generateTemplateFile1(
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.generateTemplateFile1(
          enterpriseId,
          userId,
          userName,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Lấy chi tiết nhân viên
     * @summary Chi tiết nhân viên
     * @param {string} id
     * @param {string} [enterpriseId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEmployee(
      id: string,
      enterpriseId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseEnterpriseEmployeeDetail>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEmployee(
        id,
        enterpriseId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Lấy danh sách nhân viên
     * @summary Danh sách nhân viên
     * @param {number} page
     * @param {number} size
     * @param {string} [enterpriseId]
     * @param {number} [startDate]
     * @param {number} [endDate]
     * @param {string} [salaryAdvanceTypeId]
     * @param {boolean} [activated]
     * @param {string} [keyword]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getListEmployee(
      page: number,
      size: number,
      enterpriseId?: string,
      startDate?: number,
      endDate?: number,
      salaryAdvanceTypeId?: string,
      activated?: boolean,
      keyword?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponsePageDtoListEnterpriseEmployeeInfo>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getListEmployee(
        page,
        size,
        enterpriseId,
        startDate,
        endDate,
        salaryAdvanceTypeId,
        activated,
        keyword,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Danh sách yêu cầu ứng lương theo nhân viên
     * @summary Danh sách yêu cầu ứng lương theo nhân viên
     * @param {string} id
     * @param {number} page
     * @param {number} size
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {'APPROVED' | 'REJECTED' | 'WAITING_APPROVAL' | 'CANCELED' | 'EXPIRED'} [status]
     * @param {number} [fromDate]
     * @param {number} [toDate]
     * @param {string} [keyword]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSalaryAdvanceListByEmployee(
      id: string,
      page: number,
      size: number,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      status?:
        | "APPROVED"
        | "REJECTED"
        | "WAITING_APPROVAL"
        | "CANCELED"
        | "EXPIRED",
      fromDate?: number,
      toDate?: number,
      keyword?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponsePageDtoListSalaryAdvanceByEmployeeInfo>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSalaryAdvanceListByEmployee(
          id,
          page,
          size,
          enterpriseId,
          userId,
          userName,
          status,
          fromDate,
          toDate,
          keyword,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Tạo hoặc cập nhật danh sách nhân viên từ excel
     * @summary Upload nhân viên từ excel
     * @param {File} file
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadEmployeeFile(
      file: File,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseUploadEmployeeResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.uploadEmployeeFile(
          file,
          enterpriseId,
          userId,
          userName,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * EmployeeApi - factory interface
 * @export
 */
export const EmployeeApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = EmployeeApiFp(configuration);
  return {
    /**
     * Thêm nhân viên
     * @summary Thêm nhân viên
     * @param {EmployeeRequest} employeeRequest
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addEmployee(
      employeeRequest: EmployeeRequest,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: any,
    ): AxiosPromise<BaseResponseCreatedResponse> {
      return localVarFp
        .addEmployee(employeeRequest, enterpriseId, userId, userName, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Thêm hàng loạt nhân viên (hoặc chỉnh sửa thông tin cũ)
     * @summary Thêm hàng loạt nhân viên
     * @param {BulkRequestEmployeeRequest} bulkRequestEmployeeRequest
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bulkAddEmployee(
      bulkRequestEmployeeRequest: BulkRequestEmployeeRequest,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: any,
    ): AxiosPromise<BaseResponseObject> {
      return localVarFp
        .bulkAddEmployee(
          bulkRequestEmployeeRequest,
          enterpriseId,
          userId,
          userName,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Đổi loại ứng lương cho 1 danh sách nhân viên
     * @summary Cập nhật hàng hoạt loại ứng lương
     * @param {BulkUpdateAdvanceTypeRequest} bulkUpdateAdvanceTypeRequest
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bulkUpdateSalaryAdvanceType(
      bulkUpdateAdvanceTypeRequest: BulkUpdateAdvanceTypeRequest,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: any,
    ): AxiosPromise<BaseResponseObject> {
      return localVarFp
        .bulkUpdateSalaryAdvanceType(
          bulkUpdateAdvanceTypeRequest,
          enterpriseId,
          userId,
          userName,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Chỉnh sửa nhân viên
     * @summary Chỉnh sửa nhân viên
     * @param {string} id
     * @param {EmployeeRequest} employeeRequest
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editEmployee(
      id: string,
      employeeRequest: EmployeeRequest,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: any,
    ): AxiosPromise<BaseResponseObject> {
      return localVarFp
        .editEmployee(
          id,
          employeeRequest,
          enterpriseId,
          userId,
          userName,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Tạo file excel cập nhật danh sách nhân viên
     * @summary Tạo file cập nhật danh sách nhân viên
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateTemplateFile1(
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .generateTemplateFile1(enterpriseId, userId, userName, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Lấy chi tiết nhân viên
     * @summary Chi tiết nhân viên
     * @param {string} id
     * @param {string} [enterpriseId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEmployee(
      id: string,
      enterpriseId?: string,
      options?: any,
    ): AxiosPromise<BaseResponseEnterpriseEmployeeDetail> {
      return localVarFp
        .getEmployee(id, enterpriseId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Lấy danh sách nhân viên
     * @summary Danh sách nhân viên
     * @param {number} page
     * @param {number} size
     * @param {string} [enterpriseId]
     * @param {number} [startDate]
     * @param {number} [endDate]
     * @param {string} [salaryAdvanceTypeId]
     * @param {boolean} [activated]
     * @param {string} [keyword]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListEmployee(
      page: number,
      size: number,
      enterpriseId?: string,
      startDate?: number,
      endDate?: number,
      salaryAdvanceTypeId?: string,
      activated?: boolean,
      keyword?: string,
      options?: any,
    ): AxiosPromise<BaseResponsePageDtoListEnterpriseEmployeeInfo> {
      return localVarFp
        .getListEmployee(
          page,
          size,
          enterpriseId,
          startDate,
          endDate,
          salaryAdvanceTypeId,
          activated,
          keyword,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Danh sách yêu cầu ứng lương theo nhân viên
     * @summary Danh sách yêu cầu ứng lương theo nhân viên
     * @param {string} id
     * @param {number} page
     * @param {number} size
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {'APPROVED' | 'REJECTED' | 'WAITING_APPROVAL' | 'CANCELED' | 'EXPIRED'} [status]
     * @param {number} [fromDate]
     * @param {number} [toDate]
     * @param {string} [keyword]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSalaryAdvanceListByEmployee(
      id: string,
      page: number,
      size: number,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      status?:
        | "APPROVED"
        | "REJECTED"
        | "WAITING_APPROVAL"
        | "CANCELED"
        | "EXPIRED",
      fromDate?: number,
      toDate?: number,
      keyword?: string,
      options?: any,
    ): AxiosPromise<BaseResponsePageDtoListSalaryAdvanceByEmployeeInfo> {
      return localVarFp
        .getSalaryAdvanceListByEmployee(
          id,
          page,
          size,
          enterpriseId,
          userId,
          userName,
          status,
          fromDate,
          toDate,
          keyword,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Tạo hoặc cập nhật danh sách nhân viên từ excel
     * @summary Upload nhân viên từ excel
     * @param {File} file
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadEmployeeFile(
      file: File,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: any,
    ): AxiosPromise<BaseResponseUploadEmployeeResponse> {
      return localVarFp
        .uploadEmployeeFile(file, enterpriseId, userId, userName, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for addEmployee operation in EmployeeApi.
 * @export
 * @interface EmployeeApiAddEmployeeRequest
 */
export interface EmployeeApiAddEmployeeRequest {
  /**
   *
   * @type {EmployeeRequest}
   * @memberof EmployeeApiAddEmployee
   */
  readonly employeeRequest: EmployeeRequest;

  /**
   *
   * @type {string}
   * @memberof EmployeeApiAddEmployee
   */
  readonly enterpriseId?: string;

  /**
   *
   * @type {string}
   * @memberof EmployeeApiAddEmployee
   */
  readonly userId?: string;

  /**
   *
   * @type {string}
   * @memberof EmployeeApiAddEmployee
   */
  readonly userName?: string;
}

/**
 * Request parameters for bulkAddEmployee operation in EmployeeApi.
 * @export
 * @interface EmployeeApiBulkAddEmployeeRequest
 */
export interface EmployeeApiBulkAddEmployeeRequest {
  /**
   *
   * @type {BulkRequestEmployeeRequest}
   * @memberof EmployeeApiBulkAddEmployee
   */
  readonly bulkRequestEmployeeRequest: BulkRequestEmployeeRequest;

  /**
   *
   * @type {string}
   * @memberof EmployeeApiBulkAddEmployee
   */
  readonly enterpriseId?: string;

  /**
   *
   * @type {string}
   * @memberof EmployeeApiBulkAddEmployee
   */
  readonly userId?: string;

  /**
   *
   * @type {string}
   * @memberof EmployeeApiBulkAddEmployee
   */
  readonly userName?: string;
}

/**
 * Request parameters for bulkUpdateSalaryAdvanceType operation in EmployeeApi.
 * @export
 * @interface EmployeeApiBulkUpdateSalaryAdvanceTypeRequest
 */
export interface EmployeeApiBulkUpdateSalaryAdvanceTypeRequest {
  /**
   *
   * @type {BulkUpdateAdvanceTypeRequest}
   * @memberof EmployeeApiBulkUpdateSalaryAdvanceType
   */
  readonly bulkUpdateAdvanceTypeRequest: BulkUpdateAdvanceTypeRequest;

  /**
   *
   * @type {string}
   * @memberof EmployeeApiBulkUpdateSalaryAdvanceType
   */
  readonly enterpriseId?: string;

  /**
   *
   * @type {string}
   * @memberof EmployeeApiBulkUpdateSalaryAdvanceType
   */
  readonly userId?: string;

  /**
   *
   * @type {string}
   * @memberof EmployeeApiBulkUpdateSalaryAdvanceType
   */
  readonly userName?: string;
}

/**
 * Request parameters for editEmployee operation in EmployeeApi.
 * @export
 * @interface EmployeeApiEditEmployeeRequest
 */
export interface EmployeeApiEditEmployeeRequest {
  /**
   *
   * @type {string}
   * @memberof EmployeeApiEditEmployee
   */
  readonly id: string;

  /**
   *
   * @type {EmployeeRequest}
   * @memberof EmployeeApiEditEmployee
   */
  readonly employeeRequest: EmployeeRequest;

  /**
   *
   * @type {string}
   * @memberof EmployeeApiEditEmployee
   */
  readonly enterpriseId?: string;

  /**
   *
   * @type {string}
   * @memberof EmployeeApiEditEmployee
   */
  readonly userId?: string;

  /**
   *
   * @type {string}
   * @memberof EmployeeApiEditEmployee
   */
  readonly userName?: string;
}

/**
 * Request parameters for generateTemplateFile1 operation in EmployeeApi.
 * @export
 * @interface EmployeeApiGenerateTemplateFile1Request
 */
export interface EmployeeApiGenerateTemplateFile1Request {
  /**
   *
   * @type {string}
   * @memberof EmployeeApiGenerateTemplateFile1
   */
  readonly enterpriseId?: string;

  /**
   *
   * @type {string}
   * @memberof EmployeeApiGenerateTemplateFile1
   */
  readonly userId?: string;

  /**
   *
   * @type {string}
   * @memberof EmployeeApiGenerateTemplateFile1
   */
  readonly userName?: string;
}

/**
 * Request parameters for getEmployee operation in EmployeeApi.
 * @export
 * @interface EmployeeApiGetEmployeeRequest
 */
export interface EmployeeApiGetEmployeeRequest {
  /**
   *
   * @type {string}
   * @memberof EmployeeApiGetEmployee
   */
  readonly id: string;

  /**
   *
   * @type {string}
   * @memberof EmployeeApiGetEmployee
   */
  readonly enterpriseId?: string;
}

/**
 * Request parameters for getListEmployee operation in EmployeeApi.
 * @export
 * @interface EmployeeApiGetListEmployeeRequest
 */
export interface EmployeeApiGetListEmployeeRequest {
  /**
   *
   * @type {number}
   * @memberof EmployeeApiGetListEmployee
   */
  readonly page: number;

  /**
   *
   * @type {number}
   * @memberof EmployeeApiGetListEmployee
   */
  readonly size: number;

  /**
   *
   * @type {string}
   * @memberof EmployeeApiGetListEmployee
   */
  readonly enterpriseId?: string;

  /**
   *
   * @type {number}
   * @memberof EmployeeApiGetListEmployee
   */
  readonly startDate?: number;

  /**
   *
   * @type {number}
   * @memberof EmployeeApiGetListEmployee
   */
  readonly endDate?: number;

  /**
   *
   * @type {string}
   * @memberof EmployeeApiGetListEmployee
   */
  readonly salaryAdvanceTypeId?: string;

  /**
   *
   * @type {boolean}
   * @memberof EmployeeApiGetListEmployee
   */
  readonly activated?: boolean;

  /**
   *
   * @type {string}
   * @memberof EmployeeApiGetListEmployee
   */
  readonly keyword?: string;
}

/**
 * Request parameters for getSalaryAdvanceListByEmployee operation in EmployeeApi.
 * @export
 * @interface EmployeeApiGetSalaryAdvanceListByEmployeeRequest
 */
export interface EmployeeApiGetSalaryAdvanceListByEmployeeRequest {
  /**
   *
   * @type {string}
   * @memberof EmployeeApiGetSalaryAdvanceListByEmployee
   */
  readonly id: string;

  /**
   *
   * @type {number}
   * @memberof EmployeeApiGetSalaryAdvanceListByEmployee
   */
  readonly page: number;

  /**
   *
   * @type {number}
   * @memberof EmployeeApiGetSalaryAdvanceListByEmployee
   */
  readonly size: number;

  /**
   *
   * @type {string}
   * @memberof EmployeeApiGetSalaryAdvanceListByEmployee
   */
  readonly enterpriseId?: string;

  /**
   *
   * @type {string}
   * @memberof EmployeeApiGetSalaryAdvanceListByEmployee
   */
  readonly userId?: string;

  /**
   *
   * @type {string}
   * @memberof EmployeeApiGetSalaryAdvanceListByEmployee
   */
  readonly userName?: string;

  /**
   *
   * @type {'APPROVED' | 'REJECTED' | 'WAITING_APPROVAL' | 'CANCELED' | 'EXPIRED'}
   * @memberof EmployeeApiGetSalaryAdvanceListByEmployee
   */
  readonly status?:
    | "APPROVED"
    | "REJECTED"
    | "WAITING_APPROVAL"
    | "CANCELED"
    | "EXPIRED";

  /**
   *
   * @type {number}
   * @memberof EmployeeApiGetSalaryAdvanceListByEmployee
   */
  readonly fromDate?: number;

  /**
   *
   * @type {number}
   * @memberof EmployeeApiGetSalaryAdvanceListByEmployee
   */
  readonly toDate?: number;

  /**
   *
   * @type {string}
   * @memberof EmployeeApiGetSalaryAdvanceListByEmployee
   */
  readonly keyword?: string;
}

/**
 * Request parameters for uploadEmployeeFile operation in EmployeeApi.
 * @export
 * @interface EmployeeApiUploadEmployeeFileRequest
 */
export interface EmployeeApiUploadEmployeeFileRequest {
  /**
   *
   * @type {File}
   * @memberof EmployeeApiUploadEmployeeFile
   */
  readonly file: File;

  /**
   *
   * @type {string}
   * @memberof EmployeeApiUploadEmployeeFile
   */
  readonly enterpriseId?: string;

  /**
   *
   * @type {string}
   * @memberof EmployeeApiUploadEmployeeFile
   */
  readonly userId?: string;

  /**
   *
   * @type {string}
   * @memberof EmployeeApiUploadEmployeeFile
   */
  readonly userName?: string;
}

/**
 * EmployeeApi - object-oriented interface
 * @export
 * @class EmployeeApi
 * @extends {BaseAPI}
 */
export class EmployeeApi extends BaseAPI {
  /**
   * Thêm nhân viên
   * @summary Thêm nhân viên
   * @param {EmployeeApiAddEmployeeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmployeeApi
   */
  public addEmployee(
    requestParameters: EmployeeApiAddEmployeeRequest,
    options?: AxiosRequestConfig,
  ) {
    return EmployeeApiFp(this.configuration)
      .addEmployee(
        requestParameters.employeeRequest,
        requestParameters.enterpriseId,
        requestParameters.userId,
        requestParameters.userName,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Thêm hàng loạt nhân viên (hoặc chỉnh sửa thông tin cũ)
   * @summary Thêm hàng loạt nhân viên
   * @param {EmployeeApiBulkAddEmployeeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmployeeApi
   */
  public bulkAddEmployee(
    requestParameters: EmployeeApiBulkAddEmployeeRequest,
    options?: AxiosRequestConfig,
  ) {
    return EmployeeApiFp(this.configuration)
      .bulkAddEmployee(
        requestParameters.bulkRequestEmployeeRequest,
        requestParameters.enterpriseId,
        requestParameters.userId,
        requestParameters.userName,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Đổi loại ứng lương cho 1 danh sách nhân viên
   * @summary Cập nhật hàng hoạt loại ứng lương
   * @param {EmployeeApiBulkUpdateSalaryAdvanceTypeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmployeeApi
   */
  public bulkUpdateSalaryAdvanceType(
    requestParameters: EmployeeApiBulkUpdateSalaryAdvanceTypeRequest,
    options?: AxiosRequestConfig,
  ) {
    return EmployeeApiFp(this.configuration)
      .bulkUpdateSalaryAdvanceType(
        requestParameters.bulkUpdateAdvanceTypeRequest,
        requestParameters.enterpriseId,
        requestParameters.userId,
        requestParameters.userName,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Chỉnh sửa nhân viên
   * @summary Chỉnh sửa nhân viên
   * @param {EmployeeApiEditEmployeeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmployeeApi
   */
  public editEmployee(
    requestParameters: EmployeeApiEditEmployeeRequest,
    options?: AxiosRequestConfig,
  ) {
    return EmployeeApiFp(this.configuration)
      .editEmployee(
        requestParameters.id,
        requestParameters.employeeRequest,
        requestParameters.enterpriseId,
        requestParameters.userId,
        requestParameters.userName,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Tạo file excel cập nhật danh sách nhân viên
   * @summary Tạo file cập nhật danh sách nhân viên
   * @param {EmployeeApiGenerateTemplateFile1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmployeeApi
   */
  public generateTemplateFile1(
    requestParameters: EmployeeApiGenerateTemplateFile1Request = {},
    options?: AxiosRequestConfig,
  ) {
    return EmployeeApiFp(this.configuration)
      .generateTemplateFile1(
        requestParameters.enterpriseId,
        requestParameters.userId,
        requestParameters.userName,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Lấy chi tiết nhân viên
   * @summary Chi tiết nhân viên
   * @param {EmployeeApiGetEmployeeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmployeeApi
   */
  public getEmployee(
    requestParameters: EmployeeApiGetEmployeeRequest,
    options?: AxiosRequestConfig,
  ) {
    return EmployeeApiFp(this.configuration)
      .getEmployee(
        requestParameters.id,
        requestParameters.enterpriseId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Lấy danh sách nhân viên
   * @summary Danh sách nhân viên
   * @param {EmployeeApiGetListEmployeeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmployeeApi
   */
  public getListEmployee(
    requestParameters: EmployeeApiGetListEmployeeRequest,
    options?: AxiosRequestConfig,
  ) {
    return EmployeeApiFp(this.configuration)
      .getListEmployee(
        requestParameters.page,
        requestParameters.size,
        requestParameters.enterpriseId,
        requestParameters.startDate,
        requestParameters.endDate,
        requestParameters.salaryAdvanceTypeId,
        requestParameters.activated,
        requestParameters.keyword,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Danh sách yêu cầu ứng lương theo nhân viên
   * @summary Danh sách yêu cầu ứng lương theo nhân viên
   * @param {EmployeeApiGetSalaryAdvanceListByEmployeeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmployeeApi
   */
  public getSalaryAdvanceListByEmployee(
    requestParameters: EmployeeApiGetSalaryAdvanceListByEmployeeRequest,
    options?: AxiosRequestConfig,
  ) {
    return EmployeeApiFp(this.configuration)
      .getSalaryAdvanceListByEmployee(
        requestParameters.id,
        requestParameters.page,
        requestParameters.size,
        requestParameters.enterpriseId,
        requestParameters.userId,
        requestParameters.userName,
        requestParameters.status,
        requestParameters.fromDate,
        requestParameters.toDate,
        requestParameters.keyword,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Tạo hoặc cập nhật danh sách nhân viên từ excel
   * @summary Upload nhân viên từ excel
   * @param {EmployeeApiUploadEmployeeFileRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmployeeApi
   */
  public uploadEmployeeFile(
    requestParameters: EmployeeApiUploadEmployeeFileRequest,
    options?: AxiosRequestConfig,
  ) {
    return EmployeeApiFp(this.configuration)
      .uploadEmployeeFile(
        requestParameters.file,
        requestParameters.enterpriseId,
        requestParameters.userId,
        requestParameters.userName,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * EnterpriseApi - axios parameter creator
 * @export
 */
export const EnterpriseApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Bật tính năng chi lương (Doanh nghiệp)
     * @summary Kích hoạt
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activateEnterprise: async (
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/smart-bank/enterprise/activate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (enterpriseId != null) {
        localVarHeaderParameter["enterprise-id"] = String(enterpriseId);
      }

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      if (userName != null) {
        localVarHeaderParameter["user-name"] = String(userName);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Kiểm tra doanh nghiệp có tham gia chi lương
     * @summary Kiểm tra doanh nghiệp có tham gia chi lương
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkEnterprise: async (
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/smart-bank/enterprise`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (enterpriseId != null) {
        localVarHeaderParameter["enterprise-id"] = String(enterpriseId);
      }

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      if (userName != null) {
        localVarHeaderParameter["user-name"] = String(userName);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Tắt tính năng chi lương (Doanh nghiệp)
     * @summary Hủy kích hoạt
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deactivateEnterprise: async (
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/smart-bank/enterprise/deactivate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (enterpriseId != null) {
        localVarHeaderParameter["enterprise-id"] = String(enterpriseId);
      }

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      if (userName != null) {
        localVarHeaderParameter["user-name"] = String(userName);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Đăng ký tham gia chi lương
     * @summary Đăng ký tham gia chi lương
     * @param {EnterpriseRegistrationRequest} enterpriseRegistrationRequest
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registerEnterprise: async (
      enterpriseRegistrationRequest: EnterpriseRegistrationRequest,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'enterpriseRegistrationRequest' is not null or undefined
      assertParamExists(
        "registerEnterprise",
        "enterpriseRegistrationRequest",
        enterpriseRegistrationRequest,
      );
      const localVarPath = `/api/v1/smart-bank/enterprise`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (enterpriseId != null) {
        localVarHeaderParameter["enterprise-id"] = String(enterpriseId);
      }

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      if (userName != null) {
        localVarHeaderParameter["user-name"] = String(userName);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        enterpriseRegistrationRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * EnterpriseApi - functional programming interface
 * @export
 */
export const EnterpriseApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    EnterpriseApiAxiosParamCreator(configuration);
  return {
    /**
     * Bật tính năng chi lương (Doanh nghiệp)
     * @summary Kích hoạt
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async activateEnterprise(
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseObject>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.activateEnterprise(
          enterpriseId,
          userId,
          userName,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Kiểm tra doanh nghiệp có tham gia chi lương
     * @summary Kiểm tra doanh nghiệp có tham gia chi lương
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkEnterprise(
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseCheckEnterpriseResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.checkEnterprise(
        enterpriseId,
        userId,
        userName,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Tắt tính năng chi lương (Doanh nghiệp)
     * @summary Hủy kích hoạt
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deactivateEnterprise(
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseObject>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deactivateEnterprise(
          enterpriseId,
          userId,
          userName,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Đăng ký tham gia chi lương
     * @summary Đăng ký tham gia chi lương
     * @param {EnterpriseRegistrationRequest} enterpriseRegistrationRequest
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async registerEnterprise(
      enterpriseRegistrationRequest: EnterpriseRegistrationRequest,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseObject>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.registerEnterprise(
          enterpriseRegistrationRequest,
          enterpriseId,
          userId,
          userName,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * EnterpriseApi - factory interface
 * @export
 */
export const EnterpriseApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = EnterpriseApiFp(configuration);
  return {
    /**
     * Bật tính năng chi lương (Doanh nghiệp)
     * @summary Kích hoạt
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activateEnterprise(
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: any,
    ): AxiosPromise<BaseResponseObject> {
      return localVarFp
        .activateEnterprise(enterpriseId, userId, userName, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Kiểm tra doanh nghiệp có tham gia chi lương
     * @summary Kiểm tra doanh nghiệp có tham gia chi lương
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkEnterprise(
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: any,
    ): AxiosPromise<BaseResponseCheckEnterpriseResponse> {
      return localVarFp
        .checkEnterprise(enterpriseId, userId, userName, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Tắt tính năng chi lương (Doanh nghiệp)
     * @summary Hủy kích hoạt
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deactivateEnterprise(
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: any,
    ): AxiosPromise<BaseResponseObject> {
      return localVarFp
        .deactivateEnterprise(enterpriseId, userId, userName, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Đăng ký tham gia chi lương
     * @summary Đăng ký tham gia chi lương
     * @param {EnterpriseRegistrationRequest} enterpriseRegistrationRequest
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registerEnterprise(
      enterpriseRegistrationRequest: EnterpriseRegistrationRequest,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: any,
    ): AxiosPromise<BaseResponseObject> {
      return localVarFp
        .registerEnterprise(
          enterpriseRegistrationRequest,
          enterpriseId,
          userId,
          userName,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for activateEnterprise operation in EnterpriseApi.
 * @export
 * @interface EnterpriseApiActivateEnterpriseRequest
 */
export interface EnterpriseApiActivateEnterpriseRequest {
  /**
   *
   * @type {string}
   * @memberof EnterpriseApiActivateEnterprise
   */
  readonly enterpriseId?: string;

  /**
   *
   * @type {string}
   * @memberof EnterpriseApiActivateEnterprise
   */
  readonly userId?: string;

  /**
   *
   * @type {string}
   * @memberof EnterpriseApiActivateEnterprise
   */
  readonly userName?: string;
}

/**
 * Request parameters for checkEnterprise operation in EnterpriseApi.
 * @export
 * @interface EnterpriseApiCheckEnterpriseRequest
 */
export interface EnterpriseApiCheckEnterpriseRequest {
  /**
   *
   * @type {string}
   * @memberof EnterpriseApiCheckEnterprise
   */
  readonly enterpriseId?: string;

  /**
   *
   * @type {string}
   * @memberof EnterpriseApiCheckEnterprise
   */
  readonly userId?: string;

  /**
   *
   * @type {string}
   * @memberof EnterpriseApiCheckEnterprise
   */
  readonly userName?: string;
}

/**
 * Request parameters for deactivateEnterprise operation in EnterpriseApi.
 * @export
 * @interface EnterpriseApiDeactivateEnterpriseRequest
 */
export interface EnterpriseApiDeactivateEnterpriseRequest {
  /**
   *
   * @type {string}
   * @memberof EnterpriseApiDeactivateEnterprise
   */
  readonly enterpriseId?: string;

  /**
   *
   * @type {string}
   * @memberof EnterpriseApiDeactivateEnterprise
   */
  readonly userId?: string;

  /**
   *
   * @type {string}
   * @memberof EnterpriseApiDeactivateEnterprise
   */
  readonly userName?: string;
}

/**
 * Request parameters for registerEnterprise operation in EnterpriseApi.
 * @export
 * @interface EnterpriseApiRegisterEnterpriseRequest
 */
export interface EnterpriseApiRegisterEnterpriseRequest {
  /**
   *
   * @type {EnterpriseRegistrationRequest}
   * @memberof EnterpriseApiRegisterEnterprise
   */
  readonly enterpriseRegistrationRequest: EnterpriseRegistrationRequest;

  /**
   *
   * @type {string}
   * @memberof EnterpriseApiRegisterEnterprise
   */
  readonly enterpriseId?: string;

  /**
   *
   * @type {string}
   * @memberof EnterpriseApiRegisterEnterprise
   */
  readonly userId?: string;

  /**
   *
   * @type {string}
   * @memberof EnterpriseApiRegisterEnterprise
   */
  readonly userName?: string;
}

/**
 * EnterpriseApi - object-oriented interface
 * @export
 * @class EnterpriseApi
 * @extends {BaseAPI}
 */
export class EnterpriseApi extends BaseAPI {
  /**
   * Bật tính năng chi lương (Doanh nghiệp)
   * @summary Kích hoạt
   * @param {EnterpriseApiActivateEnterpriseRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnterpriseApi
   */
  public activateEnterprise(
    requestParameters: EnterpriseApiActivateEnterpriseRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return EnterpriseApiFp(this.configuration)
      .activateEnterprise(
        requestParameters.enterpriseId,
        requestParameters.userId,
        requestParameters.userName,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Kiểm tra doanh nghiệp có tham gia chi lương
   * @summary Kiểm tra doanh nghiệp có tham gia chi lương
   * @param {EnterpriseApiCheckEnterpriseRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnterpriseApi
   */
  public checkEnterprise(
    requestParameters: EnterpriseApiCheckEnterpriseRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return EnterpriseApiFp(this.configuration)
      .checkEnterprise(
        requestParameters.enterpriseId,
        requestParameters.userId,
        requestParameters.userName,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Tắt tính năng chi lương (Doanh nghiệp)
   * @summary Hủy kích hoạt
   * @param {EnterpriseApiDeactivateEnterpriseRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnterpriseApi
   */
  public deactivateEnterprise(
    requestParameters: EnterpriseApiDeactivateEnterpriseRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return EnterpriseApiFp(this.configuration)
      .deactivateEnterprise(
        requestParameters.enterpriseId,
        requestParameters.userId,
        requestParameters.userName,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Đăng ký tham gia chi lương
   * @summary Đăng ký tham gia chi lương
   * @param {EnterpriseApiRegisterEnterpriseRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnterpriseApi
   */
  public registerEnterprise(
    requestParameters: EnterpriseApiRegisterEnterpriseRequest,
    options?: AxiosRequestConfig,
  ) {
    return EnterpriseApiFp(this.configuration)
      .registerEnterprise(
        requestParameters.enterpriseRegistrationRequest,
        requestParameters.enterpriseId,
        requestParameters.userId,
        requestParameters.userName,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * EnterpriseConfigApi - axios parameter creator
 * @export
 */
export const EnterpriseConfigApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Chỉnh sửa cấu hình doanh nghiệp
     * @summary Chỉnh sửa cấu hình doanh nghiệp
     * @param {EnterpriseConfigRequest} enterpriseConfigRequest
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editEnterpriseConfig: async (
      enterpriseConfigRequest: EnterpriseConfigRequest,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'enterpriseConfigRequest' is not null or undefined
      assertParamExists(
        "editEnterpriseConfig",
        "enterpriseConfigRequest",
        enterpriseConfigRequest,
      );
      const localVarPath = `/api/v1/smart-bank/enterprise/configuration`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (enterpriseId != null) {
        localVarHeaderParameter["enterprise-id"] = String(enterpriseId);
      }

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      if (userName != null) {
        localVarHeaderParameter["user-name"] = String(userName);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        enterpriseConfigRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Lấy thông tin cấu hình doanh nghiệp
     * @summary Lấy thông tin cấu hình doanh nghiệp
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEnterpriseConfig: async (
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/smart-bank/enterprise/configuration`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (enterpriseId != null) {
        localVarHeaderParameter["enterprise-id"] = String(enterpriseId);
      }

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      if (userName != null) {
        localVarHeaderParameter["user-name"] = String(userName);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * EnterpriseConfigApi - functional programming interface
 * @export
 */
export const EnterpriseConfigApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    EnterpriseConfigApiAxiosParamCreator(configuration);
  return {
    /**
     * Chỉnh sửa cấu hình doanh nghiệp
     * @summary Chỉnh sửa cấu hình doanh nghiệp
     * @param {EnterpriseConfigRequest} enterpriseConfigRequest
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async editEnterpriseConfig(
      enterpriseConfigRequest: EnterpriseConfigRequest,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseObject>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.editEnterpriseConfig(
          enterpriseConfigRequest,
          enterpriseId,
          userId,
          userName,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Lấy thông tin cấu hình doanh nghiệp
     * @summary Lấy thông tin cấu hình doanh nghiệp
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEnterpriseConfig(
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseEnterpriseConfigDetail>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getEnterpriseConfig(
          enterpriseId,
          userId,
          userName,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * EnterpriseConfigApi - factory interface
 * @export
 */
export const EnterpriseConfigApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = EnterpriseConfigApiFp(configuration);
  return {
    /**
     * Chỉnh sửa cấu hình doanh nghiệp
     * @summary Chỉnh sửa cấu hình doanh nghiệp
     * @param {EnterpriseConfigRequest} enterpriseConfigRequest
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editEnterpriseConfig(
      enterpriseConfigRequest: EnterpriseConfigRequest,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: any,
    ): AxiosPromise<BaseResponseObject> {
      return localVarFp
        .editEnterpriseConfig(
          enterpriseConfigRequest,
          enterpriseId,
          userId,
          userName,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Lấy thông tin cấu hình doanh nghiệp
     * @summary Lấy thông tin cấu hình doanh nghiệp
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEnterpriseConfig(
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: any,
    ): AxiosPromise<BaseResponseEnterpriseConfigDetail> {
      return localVarFp
        .getEnterpriseConfig(enterpriseId, userId, userName, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for editEnterpriseConfig operation in EnterpriseConfigApi.
 * @export
 * @interface EnterpriseConfigApiEditEnterpriseConfigRequest
 */
export interface EnterpriseConfigApiEditEnterpriseConfigRequest {
  /**
   *
   * @type {EnterpriseConfigRequest}
   * @memberof EnterpriseConfigApiEditEnterpriseConfig
   */
  readonly enterpriseConfigRequest: EnterpriseConfigRequest;

  /**
   *
   * @type {string}
   * @memberof EnterpriseConfigApiEditEnterpriseConfig
   */
  readonly enterpriseId?: string;

  /**
   *
   * @type {string}
   * @memberof EnterpriseConfigApiEditEnterpriseConfig
   */
  readonly userId?: string;

  /**
   *
   * @type {string}
   * @memberof EnterpriseConfigApiEditEnterpriseConfig
   */
  readonly userName?: string;
}

/**
 * Request parameters for getEnterpriseConfig operation in EnterpriseConfigApi.
 * @export
 * @interface EnterpriseConfigApiGetEnterpriseConfigRequest
 */
export interface EnterpriseConfigApiGetEnterpriseConfigRequest {
  /**
   *
   * @type {string}
   * @memberof EnterpriseConfigApiGetEnterpriseConfig
   */
  readonly enterpriseId?: string;

  /**
   *
   * @type {string}
   * @memberof EnterpriseConfigApiGetEnterpriseConfig
   */
  readonly userId?: string;

  /**
   *
   * @type {string}
   * @memberof EnterpriseConfigApiGetEnterpriseConfig
   */
  readonly userName?: string;
}

/**
 * EnterpriseConfigApi - object-oriented interface
 * @export
 * @class EnterpriseConfigApi
 * @extends {BaseAPI}
 */
export class EnterpriseConfigApi extends BaseAPI {
  /**
   * Chỉnh sửa cấu hình doanh nghiệp
   * @summary Chỉnh sửa cấu hình doanh nghiệp
   * @param {EnterpriseConfigApiEditEnterpriseConfigRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnterpriseConfigApi
   */
  public editEnterpriseConfig(
    requestParameters: EnterpriseConfigApiEditEnterpriseConfigRequest,
    options?: AxiosRequestConfig,
  ) {
    return EnterpriseConfigApiFp(this.configuration)
      .editEnterpriseConfig(
        requestParameters.enterpriseConfigRequest,
        requestParameters.enterpriseId,
        requestParameters.userId,
        requestParameters.userName,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Lấy thông tin cấu hình doanh nghiệp
   * @summary Lấy thông tin cấu hình doanh nghiệp
   * @param {EnterpriseConfigApiGetEnterpriseConfigRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnterpriseConfigApi
   */
  public getEnterpriseConfig(
    requestParameters: EnterpriseConfigApiGetEnterpriseConfigRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return EnterpriseConfigApiFp(this.configuration)
      .getEnterpriseConfig(
        requestParameters.enterpriseId,
        requestParameters.userId,
        requestParameters.userName,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * LogworkApi - axios parameter creator
 * @export
 */
export const LogworkApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Tải lên bảng công
     * @summary Tải lên bảng công
     * @param {string} periodId
     * @param {LogworkRequest} logworkRequest
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createLogwork: async (
      periodId: string,
      logworkRequest: LogworkRequest,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'periodId' is not null or undefined
      assertParamExists("createLogwork", "periodId", periodId);
      // verify required parameter 'logworkRequest' is not null or undefined
      assertParamExists("createLogwork", "logworkRequest", logworkRequest);
      const localVarPath =
        `/api/v1/smart-bank/salary-period/{periodId}/logwork`.replace(
          `{${"periodId"}}`,
          encodeURIComponent(String(periodId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (enterpriseId != null) {
        localVarHeaderParameter["enterprise-id"] = String(enterpriseId);
      }

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      if (userName != null) {
        localVarHeaderParameter["user-name"] = String(userName);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        logworkRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Tải template excel dữ liệu bảng công
     * @summary Tải template excel dữ liệu công
     * @param {string} periodId
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateTemplateFile: async (
      periodId: string,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'periodId' is not null or undefined
      assertParamExists("generateTemplateFile", "periodId", periodId);
      const localVarPath =
        `/api/v1/smart-bank/salary-period/{periodId}/logwork/template`.replace(
          `{${"periodId"}}`,
          encodeURIComponent(String(periodId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (enterpriseId != null) {
        localVarHeaderParameter["enterprise-id"] = String(enterpriseId);
      }

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      if (userName != null) {
        localVarHeaderParameter["user-name"] = String(userName);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Xem danh sách chấm công của kỳ công
     * @summary Xem danh sách logwork
     * @param {string} periodId
     * @param {number} page
     * @param {number} size
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {string} [keyword]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logworkList: async (
      periodId: string,
      page: number,
      size: number,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      keyword?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'periodId' is not null or undefined
      assertParamExists("logworkList", "periodId", periodId);
      // verify required parameter 'page' is not null or undefined
      assertParamExists("logworkList", "page", page);
      // verify required parameter 'size' is not null or undefined
      assertParamExists("logworkList", "size", size);
      const localVarPath =
        `/api/v1/smart-bank/salary-period/{periodId}/logwork`.replace(
          `{${"periodId"}}`,
          encodeURIComponent(String(periodId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (keyword !== undefined) {
        localVarQueryParameter["keyword"] = keyword;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (enterpriseId != null) {
        localVarHeaderParameter["enterprise-id"] = String(enterpriseId);
      }

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      if (userName != null) {
        localVarHeaderParameter["user-name"] = String(userName);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Chỉnh sửa hoặc tạo mới 1 ngày công (override dữ liệu cũ)
     * @summary Chỉnh sửa ngày công
     * @param {UpdateLogworkRequest} updateLogworkRequest
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLogwork: async (
      updateLogworkRequest: UpdateLogworkRequest,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateLogworkRequest' is not null or undefined
      assertParamExists(
        "updateLogwork",
        "updateLogworkRequest",
        updateLogworkRequest,
      );
      const localVarPath = `/api/v1/smart-bank/salary-period/logwork`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (enterpriseId != null) {
        localVarHeaderParameter["enterprise-id"] = String(enterpriseId);
      }

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      if (userName != null) {
        localVarHeaderParameter["user-name"] = String(userName);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateLogworkRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Tải lên bảng công
     * @summary Tải file excel dữ liệu công
     * @param {string} periodId
     * @param {File} file
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadLogworkFile: async (
      periodId: string,
      file: File,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'periodId' is not null or undefined
      assertParamExists("uploadLogworkFile", "periodId", periodId);
      // verify required parameter 'file' is not null or undefined
      assertParamExists("uploadLogworkFile", "file", file);
      const localVarPath =
        `/api/v1/smart-bank/salary-period/{periodId}/logwork/upload`.replace(
          `{${"periodId"}}`,
          encodeURIComponent(String(periodId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (enterpriseId != null) {
        localVarHeaderParameter["enterprise-id"] = String(enterpriseId);
      }

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      if (userName != null) {
        localVarHeaderParameter["user-name"] = String(userName);
      }

      if (file !== undefined) {
        localVarFormParams.append("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LogworkApi - functional programming interface
 * @export
 */
export const LogworkApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = LogworkApiAxiosParamCreator(configuration);
  return {
    /**
     * Tải lên bảng công
     * @summary Tải lên bảng công
     * @param {string} periodId
     * @param {LogworkRequest} logworkRequest
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createLogwork(
      periodId: string,
      logworkRequest: LogworkRequest,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseObject>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createLogwork(
        periodId,
        logworkRequest,
        enterpriseId,
        userId,
        userName,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Tải template excel dữ liệu bảng công
     * @summary Tải template excel dữ liệu công
     * @param {string} periodId
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generateTemplateFile(
      periodId: string,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.generateTemplateFile(
          periodId,
          enterpriseId,
          userId,
          userName,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Xem danh sách chấm công của kỳ công
     * @summary Xem danh sách logwork
     * @param {string} periodId
     * @param {number} page
     * @param {number} size
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {string} [keyword]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async logworkList(
      periodId: string,
      page: number,
      size: number,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      keyword?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseLogworkTableResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.logworkList(
        periodId,
        page,
        size,
        enterpriseId,
        userId,
        userName,
        keyword,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Chỉnh sửa hoặc tạo mới 1 ngày công (override dữ liệu cũ)
     * @summary Chỉnh sửa ngày công
     * @param {UpdateLogworkRequest} updateLogworkRequest
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateLogwork(
      updateLogworkRequest: UpdateLogworkRequest,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseObject>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateLogwork(
        updateLogworkRequest,
        enterpriseId,
        userId,
        userName,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Tải lên bảng công
     * @summary Tải file excel dữ liệu công
     * @param {string} periodId
     * @param {File} file
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadLogworkFile(
      periodId: string,
      file: File,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseUploadLogworkResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.uploadLogworkFile(
          periodId,
          file,
          enterpriseId,
          userId,
          userName,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * LogworkApi - factory interface
 * @export
 */
export const LogworkApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = LogworkApiFp(configuration);
  return {
    /**
     * Tải lên bảng công
     * @summary Tải lên bảng công
     * @param {string} periodId
     * @param {LogworkRequest} logworkRequest
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createLogwork(
      periodId: string,
      logworkRequest: LogworkRequest,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: any,
    ): AxiosPromise<BaseResponseObject> {
      return localVarFp
        .createLogwork(
          periodId,
          logworkRequest,
          enterpriseId,
          userId,
          userName,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Tải template excel dữ liệu bảng công
     * @summary Tải template excel dữ liệu công
     * @param {string} periodId
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateTemplateFile(
      periodId: string,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .generateTemplateFile(periodId, enterpriseId, userId, userName, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Xem danh sách chấm công của kỳ công
     * @summary Xem danh sách logwork
     * @param {string} periodId
     * @param {number} page
     * @param {number} size
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {string} [keyword]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logworkList(
      periodId: string,
      page: number,
      size: number,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      keyword?: string,
      options?: any,
    ): AxiosPromise<BaseResponseLogworkTableResponse> {
      return localVarFp
        .logworkList(
          periodId,
          page,
          size,
          enterpriseId,
          userId,
          userName,
          keyword,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Chỉnh sửa hoặc tạo mới 1 ngày công (override dữ liệu cũ)
     * @summary Chỉnh sửa ngày công
     * @param {UpdateLogworkRequest} updateLogworkRequest
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLogwork(
      updateLogworkRequest: UpdateLogworkRequest,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: any,
    ): AxiosPromise<BaseResponseObject> {
      return localVarFp
        .updateLogwork(
          updateLogworkRequest,
          enterpriseId,
          userId,
          userName,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Tải lên bảng công
     * @summary Tải file excel dữ liệu công
     * @param {string} periodId
     * @param {File} file
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadLogworkFile(
      periodId: string,
      file: File,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: any,
    ): AxiosPromise<BaseResponseUploadLogworkResponse> {
      return localVarFp
        .uploadLogworkFile(
          periodId,
          file,
          enterpriseId,
          userId,
          userName,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createLogwork operation in LogworkApi.
 * @export
 * @interface LogworkApiCreateLogworkRequest
 */
export interface LogworkApiCreateLogworkRequest {
  /**
   *
   * @type {string}
   * @memberof LogworkApiCreateLogwork
   */
  readonly periodId: string;

  /**
   *
   * @type {LogworkRequest}
   * @memberof LogworkApiCreateLogwork
   */
  readonly logworkRequest: LogworkRequest;

  /**
   *
   * @type {string}
   * @memberof LogworkApiCreateLogwork
   */
  readonly enterpriseId?: string;

  /**
   *
   * @type {string}
   * @memberof LogworkApiCreateLogwork
   */
  readonly userId?: string;

  /**
   *
   * @type {string}
   * @memberof LogworkApiCreateLogwork
   */
  readonly userName?: string;
}

/**
 * Request parameters for generateTemplateFile operation in LogworkApi.
 * @export
 * @interface LogworkApiGenerateTemplateFileRequest
 */
export interface LogworkApiGenerateTemplateFileRequest {
  /**
   *
   * @type {string}
   * @memberof LogworkApiGenerateTemplateFile
   */
  readonly periodId: string;

  /**
   *
   * @type {string}
   * @memberof LogworkApiGenerateTemplateFile
   */
  readonly enterpriseId?: string;

  /**
   *
   * @type {string}
   * @memberof LogworkApiGenerateTemplateFile
   */
  readonly userId?: string;

  /**
   *
   * @type {string}
   * @memberof LogworkApiGenerateTemplateFile
   */
  readonly userName?: string;
}

/**
 * Request parameters for logworkList operation in LogworkApi.
 * @export
 * @interface LogworkApiLogworkListRequest
 */
export interface LogworkApiLogworkListRequest {
  /**
   *
   * @type {string}
   * @memberof LogworkApiLogworkList
   */
  readonly periodId: string;

  /**
   *
   * @type {number}
   * @memberof LogworkApiLogworkList
   */
  readonly page: number;

  /**
   *
   * @type {number}
   * @memberof LogworkApiLogworkList
   */
  readonly size: number;

  /**
   *
   * @type {string}
   * @memberof LogworkApiLogworkList
   */
  readonly enterpriseId?: string;

  /**
   *
   * @type {string}
   * @memberof LogworkApiLogworkList
   */
  readonly userId?: string;

  /**
   *
   * @type {string}
   * @memberof LogworkApiLogworkList
   */
  readonly userName?: string;

  /**
   *
   * @type {string}
   * @memberof LogworkApiLogworkList
   */
  readonly keyword?: string;
}

/**
 * Request parameters for updateLogwork operation in LogworkApi.
 * @export
 * @interface LogworkApiUpdateLogworkRequest
 */
export interface LogworkApiUpdateLogworkRequest {
  /**
   *
   * @type {UpdateLogworkRequest}
   * @memberof LogworkApiUpdateLogwork
   */
  readonly updateLogworkRequest: UpdateLogworkRequest;

  /**
   *
   * @type {string}
   * @memberof LogworkApiUpdateLogwork
   */
  readonly enterpriseId?: string;

  /**
   *
   * @type {string}
   * @memberof LogworkApiUpdateLogwork
   */
  readonly userId?: string;

  /**
   *
   * @type {string}
   * @memberof LogworkApiUpdateLogwork
   */
  readonly userName?: string;
}

/**
 * Request parameters for uploadLogworkFile operation in LogworkApi.
 * @export
 * @interface LogworkApiUploadLogworkFileRequest
 */
export interface LogworkApiUploadLogworkFileRequest {
  /**
   *
   * @type {string}
   * @memberof LogworkApiUploadLogworkFile
   */
  readonly periodId: string;

  /**
   *
   * @type {File}
   * @memberof LogworkApiUploadLogworkFile
   */
  readonly file: File;

  /**
   *
   * @type {string}
   * @memberof LogworkApiUploadLogworkFile
   */
  readonly enterpriseId?: string;

  /**
   *
   * @type {string}
   * @memberof LogworkApiUploadLogworkFile
   */
  readonly userId?: string;

  /**
   *
   * @type {string}
   * @memberof LogworkApiUploadLogworkFile
   */
  readonly userName?: string;
}

/**
 * LogworkApi - object-oriented interface
 * @export
 * @class LogworkApi
 * @extends {BaseAPI}
 */
export class LogworkApi extends BaseAPI {
  /**
   * Tải lên bảng công
   * @summary Tải lên bảng công
   * @param {LogworkApiCreateLogworkRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogworkApi
   */
  public createLogwork(
    requestParameters: LogworkApiCreateLogworkRequest,
    options?: AxiosRequestConfig,
  ) {
    return LogworkApiFp(this.configuration)
      .createLogwork(
        requestParameters.periodId,
        requestParameters.logworkRequest,
        requestParameters.enterpriseId,
        requestParameters.userId,
        requestParameters.userName,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Tải template excel dữ liệu bảng công
   * @summary Tải template excel dữ liệu công
   * @param {LogworkApiGenerateTemplateFileRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogworkApi
   */
  public generateTemplateFile(
    requestParameters: LogworkApiGenerateTemplateFileRequest,
    options?: AxiosRequestConfig,
  ) {
    return LogworkApiFp(this.configuration)
      .generateTemplateFile(
        requestParameters.periodId,
        requestParameters.enterpriseId,
        requestParameters.userId,
        requestParameters.userName,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Xem danh sách chấm công của kỳ công
   * @summary Xem danh sách logwork
   * @param {LogworkApiLogworkListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogworkApi
   */
  public logworkList(
    requestParameters: LogworkApiLogworkListRequest,
    options?: AxiosRequestConfig,
  ) {
    return LogworkApiFp(this.configuration)
      .logworkList(
        requestParameters.periodId,
        requestParameters.page,
        requestParameters.size,
        requestParameters.enterpriseId,
        requestParameters.userId,
        requestParameters.userName,
        requestParameters.keyword,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Chỉnh sửa hoặc tạo mới 1 ngày công (override dữ liệu cũ)
   * @summary Chỉnh sửa ngày công
   * @param {LogworkApiUpdateLogworkRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogworkApi
   */
  public updateLogwork(
    requestParameters: LogworkApiUpdateLogworkRequest,
    options?: AxiosRequestConfig,
  ) {
    return LogworkApiFp(this.configuration)
      .updateLogwork(
        requestParameters.updateLogworkRequest,
        requestParameters.enterpriseId,
        requestParameters.userId,
        requestParameters.userName,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Tải lên bảng công
   * @summary Tải file excel dữ liệu công
   * @param {LogworkApiUploadLogworkFileRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogworkApi
   */
  public uploadLogworkFile(
    requestParameters: LogworkApiUploadLogworkFileRequest,
    options?: AxiosRequestConfig,
  ) {
    return LogworkApiFp(this.configuration)
      .uploadLogworkFile(
        requestParameters.periodId,
        requestParameters.file,
        requestParameters.enterpriseId,
        requestParameters.userId,
        requestParameters.userName,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SalaryAdvanceRequestApi - axios parameter creator
 * @export
 */
export const SalaryAdvanceRequestApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Duyệt yêu cầu ứng lương
     * @summary Duyệt yêu cầu ứng lương
     * @param {string} id
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approveSalaryAdvance: async (
      id: string,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("approveSalaryAdvance", "id", id);
      const localVarPath =
        `/api/v1/smart-bank/salary-advance/{id}/approve`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(id)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (enterpriseId != null) {
        localVarHeaderParameter["enterprise-id"] = String(enterpriseId);
      }

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      if (userName != null) {
        localVarHeaderParameter["user-name"] = String(userName);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Chi tiết yêu cầu ứng lương
     * @summary Chi tiết yêu cầu ứng lương
     * @param {string} id
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSalaryAdvanceDetail: async (
      id: string,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getSalaryAdvanceDetail", "id", id);
      const localVarPath = `/api/v1/smart-bank/salary-advance/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (enterpriseId != null) {
        localVarHeaderParameter["enterprise-id"] = String(enterpriseId);
      }

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      if (userName != null) {
        localVarHeaderParameter["user-name"] = String(userName);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Danh sách yêu cầu ứng lương
     * @summary Danh sách yêu cầu ứng lương
     * @param {number} page
     * @param {number} size
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {'APPROVED' | 'REJECTED' | 'WAITING_APPROVAL' | 'CANCELED' | 'EXPIRED'} [status]
     * @param {number} [fromDate]
     * @param {number} [toDate]
     * @param {string} [keyword]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSalaryAdvanceList: async (
      page: number,
      size: number,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      status?:
        | "APPROVED"
        | "REJECTED"
        | "WAITING_APPROVAL"
        | "CANCELED"
        | "EXPIRED",
      fromDate?: number,
      toDate?: number,
      keyword?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'page' is not null or undefined
      assertParamExists("getSalaryAdvanceList", "page", page);
      // verify required parameter 'size' is not null or undefined
      assertParamExists("getSalaryAdvanceList", "size", size);
      const localVarPath = `/api/v1/smart-bank/salary-advance`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (fromDate !== undefined) {
        localVarQueryParameter["fromDate"] = fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter["toDate"] = toDate;
      }

      if (keyword !== undefined) {
        localVarQueryParameter["keyword"] = keyword;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (enterpriseId != null) {
        localVarHeaderParameter["enterprise-id"] = String(enterpriseId);
      }

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      if (userName != null) {
        localVarHeaderParameter["user-name"] = String(userName);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Từ chối yêu cầu ứng lương
     * @summary Từ chối yêu cầu ứng lương
     * @param {string} id
     * @param {RejectSalaryAdvanceRequest} rejectSalaryAdvanceRequest
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rejectSalaryAdvance: async (
      id: string,
      rejectSalaryAdvanceRequest: RejectSalaryAdvanceRequest,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("rejectSalaryAdvance", "id", id);
      // verify required parameter 'rejectSalaryAdvanceRequest' is not null or undefined
      assertParamExists(
        "rejectSalaryAdvance",
        "rejectSalaryAdvanceRequest",
        rejectSalaryAdvanceRequest,
      );
      const localVarPath =
        `/api/v1/smart-bank/salary-advance/{id}/reject`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(id)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (enterpriseId != null) {
        localVarHeaderParameter["enterprise-id"] = String(enterpriseId);
      }

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      if (userName != null) {
        localVarHeaderParameter["user-name"] = String(userName);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        rejectSalaryAdvanceRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SalaryAdvanceRequestApi - functional programming interface
 * @export
 */
export const SalaryAdvanceRequestApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    SalaryAdvanceRequestApiAxiosParamCreator(configuration);
  return {
    /**
     * Duyệt yêu cầu ứng lương
     * @summary Duyệt yêu cầu ứng lương
     * @param {string} id
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async approveSalaryAdvance(
      id: string,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseObject>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.approveSalaryAdvance(
          id,
          enterpriseId,
          userId,
          userName,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Chi tiết yêu cầu ứng lương
     * @summary Chi tiết yêu cầu ứng lương
     * @param {string} id
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSalaryAdvanceDetail(
      id: string,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseSalaryAdvanceDetail>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSalaryAdvanceDetail(
          id,
          enterpriseId,
          userId,
          userName,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Danh sách yêu cầu ứng lương
     * @summary Danh sách yêu cầu ứng lương
     * @param {number} page
     * @param {number} size
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {'APPROVED' | 'REJECTED' | 'WAITING_APPROVAL' | 'CANCELED' | 'EXPIRED'} [status]
     * @param {number} [fromDate]
     * @param {number} [toDate]
     * @param {string} [keyword]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSalaryAdvanceList(
      page: number,
      size: number,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      status?:
        | "APPROVED"
        | "REJECTED"
        | "WAITING_APPROVAL"
        | "CANCELED"
        | "EXPIRED",
      fromDate?: number,
      toDate?: number,
      keyword?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponsePageDtoListSalaryAdvanceInfo>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSalaryAdvanceList(
          page,
          size,
          enterpriseId,
          userId,
          userName,
          status,
          fromDate,
          toDate,
          keyword,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Từ chối yêu cầu ứng lương
     * @summary Từ chối yêu cầu ứng lương
     * @param {string} id
     * @param {RejectSalaryAdvanceRequest} rejectSalaryAdvanceRequest
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async rejectSalaryAdvance(
      id: string,
      rejectSalaryAdvanceRequest: RejectSalaryAdvanceRequest,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseObject>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.rejectSalaryAdvance(
          id,
          rejectSalaryAdvanceRequest,
          enterpriseId,
          userId,
          userName,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * SalaryAdvanceRequestApi - factory interface
 * @export
 */
export const SalaryAdvanceRequestApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SalaryAdvanceRequestApiFp(configuration);
  return {
    /**
     * Duyệt yêu cầu ứng lương
     * @summary Duyệt yêu cầu ứng lương
     * @param {string} id
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approveSalaryAdvance(
      id: string,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: any,
    ): AxiosPromise<BaseResponseObject> {
      return localVarFp
        .approveSalaryAdvance(id, enterpriseId, userId, userName, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Chi tiết yêu cầu ứng lương
     * @summary Chi tiết yêu cầu ứng lương
     * @param {string} id
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSalaryAdvanceDetail(
      id: string,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: any,
    ): AxiosPromise<BaseResponseSalaryAdvanceDetail> {
      return localVarFp
        .getSalaryAdvanceDetail(id, enterpriseId, userId, userName, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Danh sách yêu cầu ứng lương
     * @summary Danh sách yêu cầu ứng lương
     * @param {number} page
     * @param {number} size
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {'APPROVED' | 'REJECTED' | 'WAITING_APPROVAL' | 'CANCELED' | 'EXPIRED'} [status]
     * @param {number} [fromDate]
     * @param {number} [toDate]
     * @param {string} [keyword]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSalaryAdvanceList(
      page: number,
      size: number,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      status?:
        | "APPROVED"
        | "REJECTED"
        | "WAITING_APPROVAL"
        | "CANCELED"
        | "EXPIRED",
      fromDate?: number,
      toDate?: number,
      keyword?: string,
      options?: any,
    ): AxiosPromise<BaseResponsePageDtoListSalaryAdvanceInfo> {
      return localVarFp
        .getSalaryAdvanceList(
          page,
          size,
          enterpriseId,
          userId,
          userName,
          status,
          fromDate,
          toDate,
          keyword,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Từ chối yêu cầu ứng lương
     * @summary Từ chối yêu cầu ứng lương
     * @param {string} id
     * @param {RejectSalaryAdvanceRequest} rejectSalaryAdvanceRequest
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rejectSalaryAdvance(
      id: string,
      rejectSalaryAdvanceRequest: RejectSalaryAdvanceRequest,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: any,
    ): AxiosPromise<BaseResponseObject> {
      return localVarFp
        .rejectSalaryAdvance(
          id,
          rejectSalaryAdvanceRequest,
          enterpriseId,
          userId,
          userName,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for approveSalaryAdvance operation in SalaryAdvanceRequestApi.
 * @export
 * @interface SalaryAdvanceRequestApiApproveSalaryAdvanceRequest
 */
export interface SalaryAdvanceRequestApiApproveSalaryAdvanceRequest {
  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceRequestApiApproveSalaryAdvance
   */
  readonly id: string;

  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceRequestApiApproveSalaryAdvance
   */
  readonly enterpriseId?: string;

  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceRequestApiApproveSalaryAdvance
   */
  readonly userId?: string;

  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceRequestApiApproveSalaryAdvance
   */
  readonly userName?: string;
}

/**
 * Request parameters for getSalaryAdvanceDetail operation in SalaryAdvanceRequestApi.
 * @export
 * @interface SalaryAdvanceRequestApiGetSalaryAdvanceDetailRequest
 */
export interface SalaryAdvanceRequestApiGetSalaryAdvanceDetailRequest {
  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceRequestApiGetSalaryAdvanceDetail
   */
  readonly id: string;

  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceRequestApiGetSalaryAdvanceDetail
   */
  readonly enterpriseId?: string;

  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceRequestApiGetSalaryAdvanceDetail
   */
  readonly userId?: string;

  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceRequestApiGetSalaryAdvanceDetail
   */
  readonly userName?: string;
}

/**
 * Request parameters for getSalaryAdvanceList operation in SalaryAdvanceRequestApi.
 * @export
 * @interface SalaryAdvanceRequestApiGetSalaryAdvanceListRequest
 */
export interface SalaryAdvanceRequestApiGetSalaryAdvanceListRequest {
  /**
   *
   * @type {number}
   * @memberof SalaryAdvanceRequestApiGetSalaryAdvanceList
   */
  readonly page: number;

  /**
   *
   * @type {number}
   * @memberof SalaryAdvanceRequestApiGetSalaryAdvanceList
   */
  readonly size: number;

  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceRequestApiGetSalaryAdvanceList
   */
  readonly enterpriseId?: string;

  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceRequestApiGetSalaryAdvanceList
   */
  readonly userId?: string;

  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceRequestApiGetSalaryAdvanceList
   */
  readonly userName?: string;

  /**
   *
   * @type {'APPROVED' | 'REJECTED' | 'WAITING_APPROVAL' | 'CANCELED' | 'EXPIRED'}
   * @memberof SalaryAdvanceRequestApiGetSalaryAdvanceList
   */
  readonly status?:
    | "APPROVED"
    | "REJECTED"
    | "WAITING_APPROVAL"
    | "CANCELED"
    | "EXPIRED";

  /**
   *
   * @type {number}
   * @memberof SalaryAdvanceRequestApiGetSalaryAdvanceList
   */
  readonly fromDate?: number;

  /**
   *
   * @type {number}
   * @memberof SalaryAdvanceRequestApiGetSalaryAdvanceList
   */
  readonly toDate?: number;

  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceRequestApiGetSalaryAdvanceList
   */
  readonly keyword?: string;
}

/**
 * Request parameters for rejectSalaryAdvance operation in SalaryAdvanceRequestApi.
 * @export
 * @interface SalaryAdvanceRequestApiRejectSalaryAdvanceRequest
 */
export interface SalaryAdvanceRequestApiRejectSalaryAdvanceRequest {
  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceRequestApiRejectSalaryAdvance
   */
  readonly id: string;

  /**
   *
   * @type {RejectSalaryAdvanceRequest}
   * @memberof SalaryAdvanceRequestApiRejectSalaryAdvance
   */
  readonly rejectSalaryAdvanceRequest: RejectSalaryAdvanceRequest;

  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceRequestApiRejectSalaryAdvance
   */
  readonly enterpriseId?: string;

  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceRequestApiRejectSalaryAdvance
   */
  readonly userId?: string;

  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceRequestApiRejectSalaryAdvance
   */
  readonly userName?: string;
}

/**
 * SalaryAdvanceRequestApi - object-oriented interface
 * @export
 * @class SalaryAdvanceRequestApi
 * @extends {BaseAPI}
 */
export class SalaryAdvanceRequestApi extends BaseAPI {
  /**
   * Duyệt yêu cầu ứng lương
   * @summary Duyệt yêu cầu ứng lương
   * @param {SalaryAdvanceRequestApiApproveSalaryAdvanceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SalaryAdvanceRequestApi
   */
  public approveSalaryAdvance(
    requestParameters: SalaryAdvanceRequestApiApproveSalaryAdvanceRequest,
    options?: AxiosRequestConfig,
  ) {
    return SalaryAdvanceRequestApiFp(this.configuration)
      .approveSalaryAdvance(
        requestParameters.id,
        requestParameters.enterpriseId,
        requestParameters.userId,
        requestParameters.userName,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Chi tiết yêu cầu ứng lương
   * @summary Chi tiết yêu cầu ứng lương
   * @param {SalaryAdvanceRequestApiGetSalaryAdvanceDetailRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SalaryAdvanceRequestApi
   */
  public getSalaryAdvanceDetail(
    requestParameters: SalaryAdvanceRequestApiGetSalaryAdvanceDetailRequest,
    options?: AxiosRequestConfig,
  ) {
    return SalaryAdvanceRequestApiFp(this.configuration)
      .getSalaryAdvanceDetail(
        requestParameters.id,
        requestParameters.enterpriseId,
        requestParameters.userId,
        requestParameters.userName,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Danh sách yêu cầu ứng lương
   * @summary Danh sách yêu cầu ứng lương
   * @param {SalaryAdvanceRequestApiGetSalaryAdvanceListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SalaryAdvanceRequestApi
   */
  public getSalaryAdvanceList(
    requestParameters: SalaryAdvanceRequestApiGetSalaryAdvanceListRequest,
    options?: AxiosRequestConfig,
  ) {
    return SalaryAdvanceRequestApiFp(this.configuration)
      .getSalaryAdvanceList(
        requestParameters.page,
        requestParameters.size,
        requestParameters.enterpriseId,
        requestParameters.userId,
        requestParameters.userName,
        requestParameters.status,
        requestParameters.fromDate,
        requestParameters.toDate,
        requestParameters.keyword,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Từ chối yêu cầu ứng lương
   * @summary Từ chối yêu cầu ứng lương
   * @param {SalaryAdvanceRequestApiRejectSalaryAdvanceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SalaryAdvanceRequestApi
   */
  public rejectSalaryAdvance(
    requestParameters: SalaryAdvanceRequestApiRejectSalaryAdvanceRequest,
    options?: AxiosRequestConfig,
  ) {
    return SalaryAdvanceRequestApiFp(this.configuration)
      .rejectSalaryAdvance(
        requestParameters.id,
        requestParameters.rejectSalaryAdvanceRequest,
        requestParameters.enterpriseId,
        requestParameters.userId,
        requestParameters.userName,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SalaryAdvanceTypeApi - axios parameter creator
 * @export
 */
export const SalaryAdvanceTypeApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Thêm loại ứng lương
     * @summary Thêm loại ứng lương
     * @param {SalaryAdvanceTypeRequest} salaryAdvanceTypeRequest
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addAdvanceType: async (
      salaryAdvanceTypeRequest: SalaryAdvanceTypeRequest,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'salaryAdvanceTypeRequest' is not null or undefined
      assertParamExists(
        "addAdvanceType",
        "salaryAdvanceTypeRequest",
        salaryAdvanceTypeRequest,
      );
      const localVarPath = `/api/v1/smart-bank/salary-advance-type`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (enterpriseId != null) {
        localVarHeaderParameter["enterprise-id"] = String(enterpriseId);
      }

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      if (userName != null) {
        localVarHeaderParameter["user-name"] = String(userName);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        salaryAdvanceTypeRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Xóa loại ứng lương
     * @summary Xóa loại ứng lương
     * @param {string} id
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAdvanceType: async (
      id: string,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("deleteAdvanceType", "id", id);
      const localVarPath =
        `/api/v1/smart-bank/salary-advance-type/{id}`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(id)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (enterpriseId != null) {
        localVarHeaderParameter["enterprise-id"] = String(enterpriseId);
      }

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      if (userName != null) {
        localVarHeaderParameter["user-name"] = String(userName);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Chỉnh sửa loại ứng lương
     * @summary Chỉnh sửa loại ứng lương
     * @param {string} id
     * @param {SalaryAdvanceTypeRequest} salaryAdvanceTypeRequest
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editAdvanceType: async (
      id: string,
      salaryAdvanceTypeRequest: SalaryAdvanceTypeRequest,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("editAdvanceType", "id", id);
      // verify required parameter 'salaryAdvanceTypeRequest' is not null or undefined
      assertParamExists(
        "editAdvanceType",
        "salaryAdvanceTypeRequest",
        salaryAdvanceTypeRequest,
      );
      const localVarPath =
        `/api/v1/smart-bank/salary-advance-type/{id}`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(id)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (enterpriseId != null) {
        localVarHeaderParameter["enterprise-id"] = String(enterpriseId);
      }

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      if (userName != null) {
        localVarHeaderParameter["user-name"] = String(userName);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        salaryAdvanceTypeRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Lấy chi tiết loại ứng lương
     * @summary Chi tiết loại ứng lương
     * @param {string} id
     * @param {string} [enterpriseId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAdvanceType: async (
      id: string,
      enterpriseId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getAdvanceType", "id", id);
      const localVarPath =
        `/api/v1/smart-bank/salary-advance-type/{id}`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(id)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (enterpriseId != null) {
        localVarHeaderParameter["enterprise-id"] = String(enterpriseId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Lấy danh sách loại ứng lương
     * @summary Danh sách loại ứng lương
     * @param {number} page
     * @param {number} size
     * @param {string} [enterpriseId]
     * @param {'AUTO' | 'MANUAL'} [advanceForm]
     * @param {string} [keyword]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListAdvanceType: async (
      page: number,
      size: number,
      enterpriseId?: string,
      advanceForm?: "AUTO" | "MANUAL",
      keyword?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'page' is not null or undefined
      assertParamExists("getListAdvanceType", "page", page);
      // verify required parameter 'size' is not null or undefined
      assertParamExists("getListAdvanceType", "size", size);
      const localVarPath = `/api/v1/smart-bank/salary-advance-type`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (advanceForm !== undefined) {
        localVarQueryParameter["advanceForm"] = advanceForm;
      }

      if (keyword !== undefined) {
        localVarQueryParameter["keyword"] = keyword;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (enterpriseId != null) {
        localVarHeaderParameter["enterprise-id"] = String(enterpriseId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SalaryAdvanceTypeApi - functional programming interface
 * @export
 */
export const SalaryAdvanceTypeApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    SalaryAdvanceTypeApiAxiosParamCreator(configuration);
  return {
    /**
     * Thêm loại ứng lương
     * @summary Thêm loại ứng lương
     * @param {SalaryAdvanceTypeRequest} salaryAdvanceTypeRequest
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addAdvanceType(
      salaryAdvanceTypeRequest: SalaryAdvanceTypeRequest,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseCreatedResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addAdvanceType(
        salaryAdvanceTypeRequest,
        enterpriseId,
        userId,
        userName,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Xóa loại ứng lương
     * @summary Xóa loại ứng lương
     * @param {string} id
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteAdvanceType(
      id: string,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseObject>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteAdvanceType(
          id,
          enterpriseId,
          userId,
          userName,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Chỉnh sửa loại ứng lương
     * @summary Chỉnh sửa loại ứng lương
     * @param {string} id
     * @param {SalaryAdvanceTypeRequest} salaryAdvanceTypeRequest
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async editAdvanceType(
      id: string,
      salaryAdvanceTypeRequest: SalaryAdvanceTypeRequest,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseObject>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.editAdvanceType(
        id,
        salaryAdvanceTypeRequest,
        enterpriseId,
        userId,
        userName,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Lấy chi tiết loại ứng lương
     * @summary Chi tiết loại ứng lương
     * @param {string} id
     * @param {string} [enterpriseId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAdvanceType(
      id: string,
      enterpriseId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseSalaryAdvanceTypeDetail>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAdvanceType(
        id,
        enterpriseId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Lấy danh sách loại ứng lương
     * @summary Danh sách loại ứng lương
     * @param {number} page
     * @param {number} size
     * @param {string} [enterpriseId]
     * @param {'AUTO' | 'MANUAL'} [advanceForm]
     * @param {string} [keyword]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getListAdvanceType(
      page: number,
      size: number,
      enterpriseId?: string,
      advanceForm?: "AUTO" | "MANUAL",
      keyword?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponsePageDtoListSalaryAdvanceTypeInfo>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getListAdvanceType(
          page,
          size,
          enterpriseId,
          advanceForm,
          keyword,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * SalaryAdvanceTypeApi - factory interface
 * @export
 */
export const SalaryAdvanceTypeApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SalaryAdvanceTypeApiFp(configuration);
  return {
    /**
     * Thêm loại ứng lương
     * @summary Thêm loại ứng lương
     * @param {SalaryAdvanceTypeRequest} salaryAdvanceTypeRequest
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addAdvanceType(
      salaryAdvanceTypeRequest: SalaryAdvanceTypeRequest,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: any,
    ): AxiosPromise<BaseResponseCreatedResponse> {
      return localVarFp
        .addAdvanceType(
          salaryAdvanceTypeRequest,
          enterpriseId,
          userId,
          userName,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Xóa loại ứng lương
     * @summary Xóa loại ứng lương
     * @param {string} id
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAdvanceType(
      id: string,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: any,
    ): AxiosPromise<BaseResponseObject> {
      return localVarFp
        .deleteAdvanceType(id, enterpriseId, userId, userName, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Chỉnh sửa loại ứng lương
     * @summary Chỉnh sửa loại ứng lương
     * @param {string} id
     * @param {SalaryAdvanceTypeRequest} salaryAdvanceTypeRequest
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editAdvanceType(
      id: string,
      salaryAdvanceTypeRequest: SalaryAdvanceTypeRequest,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: any,
    ): AxiosPromise<BaseResponseObject> {
      return localVarFp
        .editAdvanceType(
          id,
          salaryAdvanceTypeRequest,
          enterpriseId,
          userId,
          userName,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Lấy chi tiết loại ứng lương
     * @summary Chi tiết loại ứng lương
     * @param {string} id
     * @param {string} [enterpriseId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAdvanceType(
      id: string,
      enterpriseId?: string,
      options?: any,
    ): AxiosPromise<BaseResponseSalaryAdvanceTypeDetail> {
      return localVarFp
        .getAdvanceType(id, enterpriseId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Lấy danh sách loại ứng lương
     * @summary Danh sách loại ứng lương
     * @param {number} page
     * @param {number} size
     * @param {string} [enterpriseId]
     * @param {'AUTO' | 'MANUAL'} [advanceForm]
     * @param {string} [keyword]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListAdvanceType(
      page: number,
      size: number,
      enterpriseId?: string,
      advanceForm?: "AUTO" | "MANUAL",
      keyword?: string,
      options?: any,
    ): AxiosPromise<BaseResponsePageDtoListSalaryAdvanceTypeInfo> {
      return localVarFp
        .getListAdvanceType(
          page,
          size,
          enterpriseId,
          advanceForm,
          keyword,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for addAdvanceType operation in SalaryAdvanceTypeApi.
 * @export
 * @interface SalaryAdvanceTypeApiAddAdvanceTypeRequest
 */
export interface SalaryAdvanceTypeApiAddAdvanceTypeRequest {
  /**
   *
   * @type {SalaryAdvanceTypeRequest}
   * @memberof SalaryAdvanceTypeApiAddAdvanceType
   */
  readonly salaryAdvanceTypeRequest: SalaryAdvanceTypeRequest;

  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceTypeApiAddAdvanceType
   */
  readonly enterpriseId?: string;

  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceTypeApiAddAdvanceType
   */
  readonly userId?: string;

  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceTypeApiAddAdvanceType
   */
  readonly userName?: string;
}

/**
 * Request parameters for deleteAdvanceType operation in SalaryAdvanceTypeApi.
 * @export
 * @interface SalaryAdvanceTypeApiDeleteAdvanceTypeRequest
 */
export interface SalaryAdvanceTypeApiDeleteAdvanceTypeRequest {
  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceTypeApiDeleteAdvanceType
   */
  readonly id: string;

  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceTypeApiDeleteAdvanceType
   */
  readonly enterpriseId?: string;

  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceTypeApiDeleteAdvanceType
   */
  readonly userId?: string;

  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceTypeApiDeleteAdvanceType
   */
  readonly userName?: string;
}

/**
 * Request parameters for editAdvanceType operation in SalaryAdvanceTypeApi.
 * @export
 * @interface SalaryAdvanceTypeApiEditAdvanceTypeRequest
 */
export interface SalaryAdvanceTypeApiEditAdvanceTypeRequest {
  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceTypeApiEditAdvanceType
   */
  readonly id: string;

  /**
   *
   * @type {SalaryAdvanceTypeRequest}
   * @memberof SalaryAdvanceTypeApiEditAdvanceType
   */
  readonly salaryAdvanceTypeRequest: SalaryAdvanceTypeRequest;

  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceTypeApiEditAdvanceType
   */
  readonly enterpriseId?: string;

  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceTypeApiEditAdvanceType
   */
  readonly userId?: string;

  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceTypeApiEditAdvanceType
   */
  readonly userName?: string;
}

/**
 * Request parameters for getAdvanceType operation in SalaryAdvanceTypeApi.
 * @export
 * @interface SalaryAdvanceTypeApiGetAdvanceTypeRequest
 */
export interface SalaryAdvanceTypeApiGetAdvanceTypeRequest {
  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceTypeApiGetAdvanceType
   */
  readonly id: string;

  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceTypeApiGetAdvanceType
   */
  readonly enterpriseId?: string;
}

/**
 * Request parameters for getListAdvanceType operation in SalaryAdvanceTypeApi.
 * @export
 * @interface SalaryAdvanceTypeApiGetListAdvanceTypeRequest
 */
export interface SalaryAdvanceTypeApiGetListAdvanceTypeRequest {
  /**
   *
   * @type {number}
   * @memberof SalaryAdvanceTypeApiGetListAdvanceType
   */
  readonly page: number;

  /**
   *
   * @type {number}
   * @memberof SalaryAdvanceTypeApiGetListAdvanceType
   */
  readonly size: number;

  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceTypeApiGetListAdvanceType
   */
  readonly enterpriseId?: string;

  /**
   *
   * @type {'AUTO' | 'MANUAL'}
   * @memberof SalaryAdvanceTypeApiGetListAdvanceType
   */
  readonly advanceForm?: "AUTO" | "MANUAL";

  /**
   *
   * @type {string}
   * @memberof SalaryAdvanceTypeApiGetListAdvanceType
   */
  readonly keyword?: string;
}

/**
 * SalaryAdvanceTypeApi - object-oriented interface
 * @export
 * @class SalaryAdvanceTypeApi
 * @extends {BaseAPI}
 */
export class SalaryAdvanceTypeApi extends BaseAPI {
  /**
   * Thêm loại ứng lương
   * @summary Thêm loại ứng lương
   * @param {SalaryAdvanceTypeApiAddAdvanceTypeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SalaryAdvanceTypeApi
   */
  public addAdvanceType(
    requestParameters: SalaryAdvanceTypeApiAddAdvanceTypeRequest,
    options?: AxiosRequestConfig,
  ) {
    return SalaryAdvanceTypeApiFp(this.configuration)
      .addAdvanceType(
        requestParameters.salaryAdvanceTypeRequest,
        requestParameters.enterpriseId,
        requestParameters.userId,
        requestParameters.userName,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Xóa loại ứng lương
   * @summary Xóa loại ứng lương
   * @param {SalaryAdvanceTypeApiDeleteAdvanceTypeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SalaryAdvanceTypeApi
   */
  public deleteAdvanceType(
    requestParameters: SalaryAdvanceTypeApiDeleteAdvanceTypeRequest,
    options?: AxiosRequestConfig,
  ) {
    return SalaryAdvanceTypeApiFp(this.configuration)
      .deleteAdvanceType(
        requestParameters.id,
        requestParameters.enterpriseId,
        requestParameters.userId,
        requestParameters.userName,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Chỉnh sửa loại ứng lương
   * @summary Chỉnh sửa loại ứng lương
   * @param {SalaryAdvanceTypeApiEditAdvanceTypeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SalaryAdvanceTypeApi
   */
  public editAdvanceType(
    requestParameters: SalaryAdvanceTypeApiEditAdvanceTypeRequest,
    options?: AxiosRequestConfig,
  ) {
    return SalaryAdvanceTypeApiFp(this.configuration)
      .editAdvanceType(
        requestParameters.id,
        requestParameters.salaryAdvanceTypeRequest,
        requestParameters.enterpriseId,
        requestParameters.userId,
        requestParameters.userName,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Lấy chi tiết loại ứng lương
   * @summary Chi tiết loại ứng lương
   * @param {SalaryAdvanceTypeApiGetAdvanceTypeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SalaryAdvanceTypeApi
   */
  public getAdvanceType(
    requestParameters: SalaryAdvanceTypeApiGetAdvanceTypeRequest,
    options?: AxiosRequestConfig,
  ) {
    return SalaryAdvanceTypeApiFp(this.configuration)
      .getAdvanceType(
        requestParameters.id,
        requestParameters.enterpriseId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Lấy danh sách loại ứng lương
   * @summary Danh sách loại ứng lương
   * @param {SalaryAdvanceTypeApiGetListAdvanceTypeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SalaryAdvanceTypeApi
   */
  public getListAdvanceType(
    requestParameters: SalaryAdvanceTypeApiGetListAdvanceTypeRequest,
    options?: AxiosRequestConfig,
  ) {
    return SalaryAdvanceTypeApiFp(this.configuration)
      .getListAdvanceType(
        requestParameters.page,
        requestParameters.size,
        requestParameters.enterpriseId,
        requestParameters.advanceForm,
        requestParameters.keyword,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SalaryPeriodApi - axios parameter creator
 * @export
 */
export const SalaryPeriodApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Thêm mới kỳ công
     * @summary Thêm kỳ công
     * @param {SalaryPeriodRequest} salaryPeriodRequest
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addSalaryPeriod: async (
      salaryPeriodRequest: SalaryPeriodRequest,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'salaryPeriodRequest' is not null or undefined
      assertParamExists(
        "addSalaryPeriod",
        "salaryPeriodRequest",
        salaryPeriodRequest,
      );
      const localVarPath = `/api/v1/smart-bank/salary-period`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (enterpriseId != null) {
        localVarHeaderParameter["enterprise-id"] = String(enterpriseId);
      }

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      if (userName != null) {
        localVarHeaderParameter["user-name"] = String(userName);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        salaryPeriodRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Xóa kỳ công
     * @summary Xóa kỳ công
     * @param {string} id
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSalaryPeriod: async (
      id: string,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("deleteSalaryPeriod", "id", id);
      const localVarPath = `/api/v1/smart-bank/salary-period/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (enterpriseId != null) {
        localVarHeaderParameter["enterprise-id"] = String(enterpriseId);
      }

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      if (userName != null) {
        localVarHeaderParameter["user-name"] = String(userName);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Sửa kỳ công
     * @summary Sửa kỳ công
     * @param {string} id
     * @param {SalaryPeriodRequest} salaryPeriodRequest
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editSalaryPeriod: async (
      id: string,
      salaryPeriodRequest: SalaryPeriodRequest,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("editSalaryPeriod", "id", id);
      // verify required parameter 'salaryPeriodRequest' is not null or undefined
      assertParamExists(
        "editSalaryPeriod",
        "salaryPeriodRequest",
        salaryPeriodRequest,
      );
      const localVarPath = `/api/v1/smart-bank/salary-period/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (enterpriseId != null) {
        localVarHeaderParameter["enterprise-id"] = String(enterpriseId);
      }

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      if (userName != null) {
        localVarHeaderParameter["user-name"] = String(userName);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        salaryPeriodRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Xem danh sách kỳ công
     * @summary Danh sách kỳ công
     * @param {number} page
     * @param {number} size
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {string} [keyword]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListPeriod: async (
      page: number,
      size: number,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      keyword?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'page' is not null or undefined
      assertParamExists("getListPeriod", "page", page);
      // verify required parameter 'size' is not null or undefined
      assertParamExists("getListPeriod", "size", size);
      const localVarPath = `/api/v1/smart-bank/salary-period`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (keyword !== undefined) {
        localVarQueryParameter["keyword"] = keyword;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (enterpriseId != null) {
        localVarHeaderParameter["enterprise-id"] = String(enterpriseId);
      }

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      if (userName != null) {
        localVarHeaderParameter["user-name"] = String(userName);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SalaryPeriodApi - functional programming interface
 * @export
 */
export const SalaryPeriodApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    SalaryPeriodApiAxiosParamCreator(configuration);
  return {
    /**
     * Thêm mới kỳ công
     * @summary Thêm kỳ công
     * @param {SalaryPeriodRequest} salaryPeriodRequest
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addSalaryPeriod(
      salaryPeriodRequest: SalaryPeriodRequest,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseCreatedResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addSalaryPeriod(
        salaryPeriodRequest,
        enterpriseId,
        userId,
        userName,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Xóa kỳ công
     * @summary Xóa kỳ công
     * @param {string} id
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteSalaryPeriod(
      id: string,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseObject>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteSalaryPeriod(
          id,
          enterpriseId,
          userId,
          userName,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Sửa kỳ công
     * @summary Sửa kỳ công
     * @param {string} id
     * @param {SalaryPeriodRequest} salaryPeriodRequest
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async editSalaryPeriod(
      id: string,
      salaryPeriodRequest: SalaryPeriodRequest,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseObject>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.editSalaryPeriod(
          id,
          salaryPeriodRequest,
          enterpriseId,
          userId,
          userName,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Xem danh sách kỳ công
     * @summary Danh sách kỳ công
     * @param {number} page
     * @param {number} size
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {string} [keyword]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getListPeriod(
      page: number,
      size: number,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      keyword?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponsePageDtoListSalaryPeriodInfo>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getListPeriod(
        page,
        size,
        enterpriseId,
        userId,
        userName,
        keyword,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * SalaryPeriodApi - factory interface
 * @export
 */
export const SalaryPeriodApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SalaryPeriodApiFp(configuration);
  return {
    /**
     * Thêm mới kỳ công
     * @summary Thêm kỳ công
     * @param {SalaryPeriodRequest} salaryPeriodRequest
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addSalaryPeriod(
      salaryPeriodRequest: SalaryPeriodRequest,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: any,
    ): AxiosPromise<BaseResponseCreatedResponse> {
      return localVarFp
        .addSalaryPeriod(
          salaryPeriodRequest,
          enterpriseId,
          userId,
          userName,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Xóa kỳ công
     * @summary Xóa kỳ công
     * @param {string} id
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSalaryPeriod(
      id: string,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: any,
    ): AxiosPromise<BaseResponseObject> {
      return localVarFp
        .deleteSalaryPeriod(id, enterpriseId, userId, userName, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Sửa kỳ công
     * @summary Sửa kỳ công
     * @param {string} id
     * @param {SalaryPeriodRequest} salaryPeriodRequest
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editSalaryPeriod(
      id: string,
      salaryPeriodRequest: SalaryPeriodRequest,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      options?: any,
    ): AxiosPromise<BaseResponseObject> {
      return localVarFp
        .editSalaryPeriod(
          id,
          salaryPeriodRequest,
          enterpriseId,
          userId,
          userName,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Xem danh sách kỳ công
     * @summary Danh sách kỳ công
     * @param {number} page
     * @param {number} size
     * @param {string} [enterpriseId]
     * @param {string} [userId]
     * @param {string} [userName]
     * @param {string} [keyword]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListPeriod(
      page: number,
      size: number,
      enterpriseId?: string,
      userId?: string,
      userName?: string,
      keyword?: string,
      options?: any,
    ): AxiosPromise<BaseResponsePageDtoListSalaryPeriodInfo> {
      return localVarFp
        .getListPeriod(
          page,
          size,
          enterpriseId,
          userId,
          userName,
          keyword,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for addSalaryPeriod operation in SalaryPeriodApi.
 * @export
 * @interface SalaryPeriodApiAddSalaryPeriodRequest
 */
export interface SalaryPeriodApiAddSalaryPeriodRequest {
  /**
   *
   * @type {SalaryPeriodRequest}
   * @memberof SalaryPeriodApiAddSalaryPeriod
   */
  readonly salaryPeriodRequest: SalaryPeriodRequest;

  /**
   *
   * @type {string}
   * @memberof SalaryPeriodApiAddSalaryPeriod
   */
  readonly enterpriseId?: string;

  /**
   *
   * @type {string}
   * @memberof SalaryPeriodApiAddSalaryPeriod
   */
  readonly userId?: string;

  /**
   *
   * @type {string}
   * @memberof SalaryPeriodApiAddSalaryPeriod
   */
  readonly userName?: string;
}

/**
 * Request parameters for deleteSalaryPeriod operation in SalaryPeriodApi.
 * @export
 * @interface SalaryPeriodApiDeleteSalaryPeriodRequest
 */
export interface SalaryPeriodApiDeleteSalaryPeriodRequest {
  /**
   *
   * @type {string}
   * @memberof SalaryPeriodApiDeleteSalaryPeriod
   */
  readonly id: string;

  /**
   *
   * @type {string}
   * @memberof SalaryPeriodApiDeleteSalaryPeriod
   */
  readonly enterpriseId?: string;

  /**
   *
   * @type {string}
   * @memberof SalaryPeriodApiDeleteSalaryPeriod
   */
  readonly userId?: string;

  /**
   *
   * @type {string}
   * @memberof SalaryPeriodApiDeleteSalaryPeriod
   */
  readonly userName?: string;
}

/**
 * Request parameters for editSalaryPeriod operation in SalaryPeriodApi.
 * @export
 * @interface SalaryPeriodApiEditSalaryPeriodRequest
 */
export interface SalaryPeriodApiEditSalaryPeriodRequest {
  /**
   *
   * @type {string}
   * @memberof SalaryPeriodApiEditSalaryPeriod
   */
  readonly id: string;

  /**
   *
   * @type {SalaryPeriodRequest}
   * @memberof SalaryPeriodApiEditSalaryPeriod
   */
  readonly salaryPeriodRequest: SalaryPeriodRequest;

  /**
   *
   * @type {string}
   * @memberof SalaryPeriodApiEditSalaryPeriod
   */
  readonly enterpriseId?: string;

  /**
   *
   * @type {string}
   * @memberof SalaryPeriodApiEditSalaryPeriod
   */
  readonly userId?: string;

  /**
   *
   * @type {string}
   * @memberof SalaryPeriodApiEditSalaryPeriod
   */
  readonly userName?: string;
}

/**
 * Request parameters for getListPeriod operation in SalaryPeriodApi.
 * @export
 * @interface SalaryPeriodApiGetListPeriodRequest
 */
export interface SalaryPeriodApiGetListPeriodRequest {
  /**
   *
   * @type {number}
   * @memberof SalaryPeriodApiGetListPeriod
   */
  readonly page: number;

  /**
   *
   * @type {number}
   * @memberof SalaryPeriodApiGetListPeriod
   */
  readonly size: number;

  /**
   *
   * @type {string}
   * @memberof SalaryPeriodApiGetListPeriod
   */
  readonly enterpriseId?: string;

  /**
   *
   * @type {string}
   * @memberof SalaryPeriodApiGetListPeriod
   */
  readonly userId?: string;

  /**
   *
   * @type {string}
   * @memberof SalaryPeriodApiGetListPeriod
   */
  readonly userName?: string;

  /**
   *
   * @type {string}
   * @memberof SalaryPeriodApiGetListPeriod
   */
  readonly keyword?: string;
}

/**
 * SalaryPeriodApi - object-oriented interface
 * @export
 * @class SalaryPeriodApi
 * @extends {BaseAPI}
 */
export class SalaryPeriodApi extends BaseAPI {
  /**
   * Thêm mới kỳ công
   * @summary Thêm kỳ công
   * @param {SalaryPeriodApiAddSalaryPeriodRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SalaryPeriodApi
   */
  public addSalaryPeriod(
    requestParameters: SalaryPeriodApiAddSalaryPeriodRequest,
    options?: AxiosRequestConfig,
  ) {
    return SalaryPeriodApiFp(this.configuration)
      .addSalaryPeriod(
        requestParameters.salaryPeriodRequest,
        requestParameters.enterpriseId,
        requestParameters.userId,
        requestParameters.userName,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Xóa kỳ công
   * @summary Xóa kỳ công
   * @param {SalaryPeriodApiDeleteSalaryPeriodRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SalaryPeriodApi
   */
  public deleteSalaryPeriod(
    requestParameters: SalaryPeriodApiDeleteSalaryPeriodRequest,
    options?: AxiosRequestConfig,
  ) {
    return SalaryPeriodApiFp(this.configuration)
      .deleteSalaryPeriod(
        requestParameters.id,
        requestParameters.enterpriseId,
        requestParameters.userId,
        requestParameters.userName,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Sửa kỳ công
   * @summary Sửa kỳ công
   * @param {SalaryPeriodApiEditSalaryPeriodRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SalaryPeriodApi
   */
  public editSalaryPeriod(
    requestParameters: SalaryPeriodApiEditSalaryPeriodRequest,
    options?: AxiosRequestConfig,
  ) {
    return SalaryPeriodApiFp(this.configuration)
      .editSalaryPeriod(
        requestParameters.id,
        requestParameters.salaryPeriodRequest,
        requestParameters.enterpriseId,
        requestParameters.userId,
        requestParameters.userName,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Xem danh sách kỳ công
   * @summary Danh sách kỳ công
   * @param {SalaryPeriodApiGetListPeriodRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SalaryPeriodApi
   */
  public getListPeriod(
    requestParameters: SalaryPeriodApiGetListPeriodRequest,
    options?: AxiosRequestConfig,
  ) {
    return SalaryPeriodApiFp(this.configuration)
      .getListPeriod(
        requestParameters.page,
        requestParameters.size,
        requestParameters.enterpriseId,
        requestParameters.userId,
        requestParameters.userName,
        requestParameters.keyword,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * StatisticApi - axios parameter creator
 * @export
 */
export const StatisticApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Xuất excel thống kê danh sách chi lương cho từng nhân viên trong từng thời kỳ
     * @summary Xuất excel thống kê chi lương
     * @param {string} [enterpriseId]
     * @param {string} [keyword]
     * @param {string} [salaryPeriodId]
     * @param {number} [page]
     * @param {number} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportPaidList: async (
      enterpriseId?: string,
      keyword?: string,
      salaryPeriodId?: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/smart-bank/statistic/paid-list/export`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (keyword !== undefined) {
        localVarQueryParameter["keyword"] = keyword;
      }

      if (salaryPeriodId !== undefined) {
        localVarQueryParameter["salaryPeriodId"] = salaryPeriodId;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (enterpriseId != null) {
        localVarHeaderParameter["enterprise-id"] = String(enterpriseId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Biểu đồ chi lương từng thời kỳ
     * @summary Biểu đồ chi lương
     * @param {string} [enterpriseId]
     * @param {number} [fromDate]
     * @param {number} [toDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaidChart: async (
      enterpriseId?: string,
      fromDate?: number,
      toDate?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/smart-bank/statistic/paid-chart`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (fromDate !== undefined) {
        localVarQueryParameter["fromDate"] = fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter["toDate"] = toDate;
      }

      if (enterpriseId != null) {
        localVarHeaderParameter["enterprise-id"] = String(enterpriseId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Thống kê danh sách chi lương cho từng nhân viên trong từng thời kỳ
     * @summary Thống kê chi lương
     * @param {string} [enterpriseId]
     * @param {string} [keyword]
     * @param {string} [salaryPeriodId]
     * @param {number} [page]
     * @param {number} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaidList: async (
      enterpriseId?: string,
      keyword?: string,
      salaryPeriodId?: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/smart-bank/statistic/paid-list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (keyword !== undefined) {
        localVarQueryParameter["keyword"] = keyword;
      }

      if (salaryPeriodId !== undefined) {
        localVarQueryParameter["salaryPeriodId"] = salaryPeriodId;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (enterpriseId != null) {
        localVarHeaderParameter["enterprise-id"] = String(enterpriseId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Dashboard thống kê số nhân viên, số lương đã ứng, số lương đã chi (trong kỳ lương hiện tại)
     * @summary Thống kê chung
     * @param {string} [enterpriseId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalStatisticCurrentPeriod: async (
      enterpriseId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/smart-bank/statistic/common`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (enterpriseId != null) {
        localVarHeaderParameter["enterprise-id"] = String(enterpriseId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * StatisticApi - functional programming interface
 * @export
 */
export const StatisticApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    StatisticApiAxiosParamCreator(configuration);
  return {
    /**
     * Xuất excel thống kê danh sách chi lương cho từng nhân viên trong từng thời kỳ
     * @summary Xuất excel thống kê chi lương
     * @param {string} [enterpriseId]
     * @param {string} [keyword]
     * @param {string} [salaryPeriodId]
     * @param {number} [page]
     * @param {number} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportPaidList(
      enterpriseId?: string,
      keyword?: string,
      salaryPeriodId?: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.exportPaidList(
        enterpriseId,
        keyword,
        salaryPeriodId,
        page,
        size,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Biểu đồ chi lương từng thời kỳ
     * @summary Biểu đồ chi lương
     * @param {string} [enterpriseId]
     * @param {number} [fromDate]
     * @param {number} [toDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPaidChart(
      enterpriseId?: string,
      fromDate?: number,
      toDate?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponsePaidChartResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPaidChart(
        enterpriseId,
        fromDate,
        toDate,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Thống kê danh sách chi lương cho từng nhân viên trong từng thời kỳ
     * @summary Thống kê chi lương
     * @param {string} [enterpriseId]
     * @param {string} [keyword]
     * @param {string} [salaryPeriodId]
     * @param {number} [page]
     * @param {number} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPaidList(
      enterpriseId?: string,
      keyword?: string,
      salaryPeriodId?: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponsePageDtoListEmployeePaidInfo>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPaidList(
        enterpriseId,
        keyword,
        salaryPeriodId,
        page,
        size,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Dashboard thống kê số nhân viên, số lương đã ứng, số lương đã chi (trong kỳ lương hiện tại)
     * @summary Thống kê chung
     * @param {string} [enterpriseId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTotalStatisticCurrentPeriod(
      enterpriseId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseTotalStatisticResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getTotalStatisticCurrentPeriod(
          enterpriseId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * StatisticApi - factory interface
 * @export
 */
export const StatisticApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = StatisticApiFp(configuration);
  return {
    /**
     * Xuất excel thống kê danh sách chi lương cho từng nhân viên trong từng thời kỳ
     * @summary Xuất excel thống kê chi lương
     * @param {string} [enterpriseId]
     * @param {string} [keyword]
     * @param {string} [salaryPeriodId]
     * @param {number} [page]
     * @param {number} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportPaidList(
      enterpriseId?: string,
      keyword?: string,
      salaryPeriodId?: string,
      page?: number,
      size?: number,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .exportPaidList(
          enterpriseId,
          keyword,
          salaryPeriodId,
          page,
          size,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Biểu đồ chi lương từng thời kỳ
     * @summary Biểu đồ chi lương
     * @param {string} [enterpriseId]
     * @param {number} [fromDate]
     * @param {number} [toDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaidChart(
      enterpriseId?: string,
      fromDate?: number,
      toDate?: number,
      options?: any,
    ): AxiosPromise<BaseResponsePaidChartResponse> {
      return localVarFp
        .getPaidChart(enterpriseId, fromDate, toDate, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Thống kê danh sách chi lương cho từng nhân viên trong từng thời kỳ
     * @summary Thống kê chi lương
     * @param {string} [enterpriseId]
     * @param {string} [keyword]
     * @param {string} [salaryPeriodId]
     * @param {number} [page]
     * @param {number} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaidList(
      enterpriseId?: string,
      keyword?: string,
      salaryPeriodId?: string,
      page?: number,
      size?: number,
      options?: any,
    ): AxiosPromise<BaseResponsePageDtoListEmployeePaidInfo> {
      return localVarFp
        .getPaidList(enterpriseId, keyword, salaryPeriodId, page, size, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Dashboard thống kê số nhân viên, số lương đã ứng, số lương đã chi (trong kỳ lương hiện tại)
     * @summary Thống kê chung
     * @param {string} [enterpriseId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalStatisticCurrentPeriod(
      enterpriseId?: string,
      options?: any,
    ): AxiosPromise<BaseResponseTotalStatisticResponse> {
      return localVarFp
        .getTotalStatisticCurrentPeriod(enterpriseId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for exportPaidList operation in StatisticApi.
 * @export
 * @interface StatisticApiExportPaidListRequest
 */
export interface StatisticApiExportPaidListRequest {
  /**
   *
   * @type {string}
   * @memberof StatisticApiExportPaidList
   */
  readonly enterpriseId?: string;

  /**
   *
   * @type {string}
   * @memberof StatisticApiExportPaidList
   */
  readonly keyword?: string;

  /**
   *
   * @type {string}
   * @memberof StatisticApiExportPaidList
   */
  readonly salaryPeriodId?: string;

  /**
   *
   * @type {number}
   * @memberof StatisticApiExportPaidList
   */
  readonly page?: number;

  /**
   *
   * @type {number}
   * @memberof StatisticApiExportPaidList
   */
  readonly size?: number;
}

/**
 * Request parameters for getPaidChart operation in StatisticApi.
 * @export
 * @interface StatisticApiGetPaidChartRequest
 */
export interface StatisticApiGetPaidChartRequest {
  /**
   *
   * @type {string}
   * @memberof StatisticApiGetPaidChart
   */
  readonly enterpriseId?: string;

  /**
   *
   * @type {number}
   * @memberof StatisticApiGetPaidChart
   */
  readonly fromDate?: number;

  /**
   *
   * @type {number}
   * @memberof StatisticApiGetPaidChart
   */
  readonly toDate?: number;
}

/**
 * Request parameters for getPaidList operation in StatisticApi.
 * @export
 * @interface StatisticApiGetPaidListRequest
 */
export interface StatisticApiGetPaidListRequest {
  /**
   *
   * @type {string}
   * @memberof StatisticApiGetPaidList
   */
  readonly enterpriseId?: string;

  /**
   *
   * @type {string}
   * @memberof StatisticApiGetPaidList
   */
  readonly keyword?: string;

  /**
   *
   * @type {string}
   * @memberof StatisticApiGetPaidList
   */
  readonly salaryPeriodId?: string;

  /**
   *
   * @type {number}
   * @memberof StatisticApiGetPaidList
   */
  readonly page?: number;

  /**
   *
   * @type {number}
   * @memberof StatisticApiGetPaidList
   */
  readonly size?: number;
}

/**
 * Request parameters for getTotalStatisticCurrentPeriod operation in StatisticApi.
 * @export
 * @interface StatisticApiGetTotalStatisticCurrentPeriodRequest
 */
export interface StatisticApiGetTotalStatisticCurrentPeriodRequest {
  /**
   *
   * @type {string}
   * @memberof StatisticApiGetTotalStatisticCurrentPeriod
   */
  readonly enterpriseId?: string;
}

/**
 * StatisticApi - object-oriented interface
 * @export
 * @class StatisticApi
 * @extends {BaseAPI}
 */
export class StatisticApi extends BaseAPI {
  /**
   * Xuất excel thống kê danh sách chi lương cho từng nhân viên trong từng thời kỳ
   * @summary Xuất excel thống kê chi lương
   * @param {StatisticApiExportPaidListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticApi
   */
  public exportPaidList(
    requestParameters: StatisticApiExportPaidListRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return StatisticApiFp(this.configuration)
      .exportPaidList(
        requestParameters.enterpriseId,
        requestParameters.keyword,
        requestParameters.salaryPeriodId,
        requestParameters.page,
        requestParameters.size,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Biểu đồ chi lương từng thời kỳ
   * @summary Biểu đồ chi lương
   * @param {StatisticApiGetPaidChartRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticApi
   */
  public getPaidChart(
    requestParameters: StatisticApiGetPaidChartRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return StatisticApiFp(this.configuration)
      .getPaidChart(
        requestParameters.enterpriseId,
        requestParameters.fromDate,
        requestParameters.toDate,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Thống kê danh sách chi lương cho từng nhân viên trong từng thời kỳ
   * @summary Thống kê chi lương
   * @param {StatisticApiGetPaidListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticApi
   */
  public getPaidList(
    requestParameters: StatisticApiGetPaidListRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return StatisticApiFp(this.configuration)
      .getPaidList(
        requestParameters.enterpriseId,
        requestParameters.keyword,
        requestParameters.salaryPeriodId,
        requestParameters.page,
        requestParameters.size,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Dashboard thống kê số nhân viên, số lương đã ứng, số lương đã chi (trong kỳ lương hiện tại)
   * @summary Thống kê chung
   * @param {StatisticApiGetTotalStatisticCurrentPeriodRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticApi
   */
  public getTotalStatisticCurrentPeriod(
    requestParameters: StatisticApiGetTotalStatisticCurrentPeriodRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return StatisticApiFp(this.configuration)
      .getTotalStatisticCurrentPeriod(requestParameters.enterpriseId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UserApiApi - axios parameter creator
 * @export
 */
export const UserApiApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Đồng ý T&C
     * @summary Đồng ý T&C
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    agreeTermAndCondition: async (
      userId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("agreeTermAndCondition", "userId", userId);
      const localVarPath = `/api/v1/user/enterprise/term-and-condition/agree`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Kiểm tra T&C
     * @summary Kiểm tra T&C
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkTermAndCondition: async (
      userId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("checkTermAndCondition", "userId", userId);
      const localVarPath = `/api/v1/user/enterprise/term-and-condition/check`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Xác minh, đăng ký nhân viên và trả về thông tin
     * @summary Xác minh và trả về thông tin nhân viên
     * @param {string} userId
     * @param {string} id
     * @param {VerifyUserV2Request} verifyUserV2Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEmployeeInfo: async (
      userId: string,
      id: string,
      verifyUserV2Request: VerifyUserV2Request,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("getEmployeeInfo", "userId", userId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getEmployeeInfo", "id", id);
      // verify required parameter 'verifyUserV2Request' is not null or undefined
      assertParamExists(
        "getEmployeeInfo",
        "verifyUserV2Request",
        verifyUserV2Request,
      );
      const localVarPath = `/api/v1/user/enterprise/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        verifyUserV2Request,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Lấy danh sách doanh nghiệp nhân viên tham gia theo cách mới
     * @summary Lấy danh sách doanh nghiệp nhân viên tham gia
     * @param {string} userId
     * @param {RegisterUserV2Request} registerUserV2Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListEnterpriseEmployeeAccess: async (
      userId: string,
      registerUserV2Request: RegisterUserV2Request,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("getListEnterpriseEmployeeAccess", "userId", userId);
      // verify required parameter 'registerUserV2Request' is not null or undefined
      assertParamExists(
        "getListEnterpriseEmployeeAccess",
        "registerUserV2Request",
        registerUserV2Request,
      );
      const localVarPath = `/api/v1/user/enterprise`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        registerUserV2Request,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserApiApi - functional programming interface
 * @export
 */
export const UserApiApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UserApiApiAxiosParamCreator(configuration);
  return {
    /**
     * Đồng ý T&C
     * @summary Đồng ý T&C
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async agreeTermAndCondition(
      userId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseObject>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.agreeTermAndCondition(userId, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Kiểm tra T&C
     * @summary Kiểm tra T&C
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkTermAndCondition(
      userId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseCheckTermAndConditionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.checkTermAndCondition(userId, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Xác minh, đăng ký nhân viên và trả về thông tin
     * @summary Xác minh và trả về thông tin nhân viên
     * @param {string} userId
     * @param {string} id
     * @param {VerifyUserV2Request} verifyUserV2Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEmployeeInfo(
      userId: string,
      id: string,
      verifyUserV2Request: VerifyUserV2Request,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseEmployeeInfo>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEmployeeInfo(
        userId,
        id,
        verifyUserV2Request,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Lấy danh sách doanh nghiệp nhân viên tham gia theo cách mới
     * @summary Lấy danh sách doanh nghiệp nhân viên tham gia
     * @param {string} userId
     * @param {RegisterUserV2Request} registerUserV2Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getListEnterpriseEmployeeAccess(
      userId: string,
      registerUserV2Request: RegisterUserV2Request,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseListUserEnterpriseInfo>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getListEnterpriseEmployeeAccess(
          userId,
          registerUserV2Request,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * UserApiApi - factory interface
 * @export
 */
export const UserApiApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = UserApiApiFp(configuration);
  return {
    /**
     * Đồng ý T&C
     * @summary Đồng ý T&C
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    agreeTermAndCondition(
      userId: string,
      options?: any,
    ): AxiosPromise<BaseResponseObject> {
      return localVarFp
        .agreeTermAndCondition(userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Kiểm tra T&C
     * @summary Kiểm tra T&C
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkTermAndCondition(
      userId: string,
      options?: any,
    ): AxiosPromise<BaseResponseCheckTermAndConditionResponse> {
      return localVarFp
        .checkTermAndCondition(userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Xác minh, đăng ký nhân viên và trả về thông tin
     * @summary Xác minh và trả về thông tin nhân viên
     * @param {string} userId
     * @param {string} id
     * @param {VerifyUserV2Request} verifyUserV2Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEmployeeInfo(
      userId: string,
      id: string,
      verifyUserV2Request: VerifyUserV2Request,
      options?: any,
    ): AxiosPromise<BaseResponseEmployeeInfo> {
      return localVarFp
        .getEmployeeInfo(userId, id, verifyUserV2Request, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Lấy danh sách doanh nghiệp nhân viên tham gia theo cách mới
     * @summary Lấy danh sách doanh nghiệp nhân viên tham gia
     * @param {string} userId
     * @param {RegisterUserV2Request} registerUserV2Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListEnterpriseEmployeeAccess(
      userId: string,
      registerUserV2Request: RegisterUserV2Request,
      options?: any,
    ): AxiosPromise<BaseResponseListUserEnterpriseInfo> {
      return localVarFp
        .getListEnterpriseEmployeeAccess(userId, registerUserV2Request, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for agreeTermAndCondition operation in UserApiApi.
 * @export
 * @interface UserApiApiAgreeTermAndConditionRequest
 */
export interface UserApiApiAgreeTermAndConditionRequest {
  /**
   *
   * @type {string}
   * @memberof UserApiApiAgreeTermAndCondition
   */
  readonly userId: string;
}

/**
 * Request parameters for checkTermAndCondition operation in UserApiApi.
 * @export
 * @interface UserApiApiCheckTermAndConditionRequest
 */
export interface UserApiApiCheckTermAndConditionRequest {
  /**
   *
   * @type {string}
   * @memberof UserApiApiCheckTermAndCondition
   */
  readonly userId: string;
}

/**
 * Request parameters for getEmployeeInfo operation in UserApiApi.
 * @export
 * @interface UserApiApiGetEmployeeInfoRequest
 */
export interface UserApiApiGetEmployeeInfoRequest {
  /**
   *
   * @type {string}
   * @memberof UserApiApiGetEmployeeInfo
   */
  readonly userId: string;

  /**
   *
   * @type {string}
   * @memberof UserApiApiGetEmployeeInfo
   */
  readonly id: string;

  /**
   *
   * @type {VerifyUserV2Request}
   * @memberof UserApiApiGetEmployeeInfo
   */
  readonly verifyUserV2Request: VerifyUserV2Request;
}

/**
 * Request parameters for getListEnterpriseEmployeeAccess operation in UserApiApi.
 * @export
 * @interface UserApiApiGetListEnterpriseEmployeeAccessRequest
 */
export interface UserApiApiGetListEnterpriseEmployeeAccessRequest {
  /**
   *
   * @type {string}
   * @memberof UserApiApiGetListEnterpriseEmployeeAccess
   */
  readonly userId: string;

  /**
   *
   * @type {RegisterUserV2Request}
   * @memberof UserApiApiGetListEnterpriseEmployeeAccess
   */
  readonly registerUserV2Request: RegisterUserV2Request;
}

/**
 * UserApiApi - object-oriented interface
 * @export
 * @class UserApiApi
 * @extends {BaseAPI}
 */
export class UserApiApi extends BaseAPI {
  /**
   * Đồng ý T&C
   * @summary Đồng ý T&C
   * @param {UserApiApiAgreeTermAndConditionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiApi
   */
  public agreeTermAndCondition(
    requestParameters: UserApiApiAgreeTermAndConditionRequest,
    options?: AxiosRequestConfig,
  ) {
    return UserApiApiFp(this.configuration)
      .agreeTermAndCondition(requestParameters.userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Kiểm tra T&C
   * @summary Kiểm tra T&C
   * @param {UserApiApiCheckTermAndConditionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiApi
   */
  public checkTermAndCondition(
    requestParameters: UserApiApiCheckTermAndConditionRequest,
    options?: AxiosRequestConfig,
  ) {
    return UserApiApiFp(this.configuration)
      .checkTermAndCondition(requestParameters.userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Xác minh, đăng ký nhân viên và trả về thông tin
   * @summary Xác minh và trả về thông tin nhân viên
   * @param {UserApiApiGetEmployeeInfoRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiApi
   */
  public getEmployeeInfo(
    requestParameters: UserApiApiGetEmployeeInfoRequest,
    options?: AxiosRequestConfig,
  ) {
    return UserApiApiFp(this.configuration)
      .getEmployeeInfo(
        requestParameters.userId,
        requestParameters.id,
        requestParameters.verifyUserV2Request,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Lấy danh sách doanh nghiệp nhân viên tham gia theo cách mới
   * @summary Lấy danh sách doanh nghiệp nhân viên tham gia
   * @param {UserApiApiGetListEnterpriseEmployeeAccessRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiApi
   */
  public getListEnterpriseEmployeeAccess(
    requestParameters: UserApiApiGetListEnterpriseEmployeeAccessRequest,
    options?: AxiosRequestConfig,
  ) {
    return UserApiApiFp(this.configuration)
      .getListEnterpriseEmployeeAccess(
        requestParameters.userId,
        requestParameters.registerUserV2Request,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UserSalaryAdvanceApi - axios parameter creator
 * @export
 */
export const UserSalaryAdvanceApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Tính phí ứng lương
     * @summary Tính phí ứng lương
     * @param {string} userId
     * @param {string} enterpriseId
     * @param {AdvanceRequest} advanceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calculateFee: async (
      userId: string,
      enterpriseId: string,
      advanceRequest: AdvanceRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("calculateFee", "userId", userId);
      // verify required parameter 'enterpriseId' is not null or undefined
      assertParamExists("calculateFee", "enterpriseId", enterpriseId);
      // verify required parameter 'advanceRequest' is not null or undefined
      assertParamExists("calculateFee", "advanceRequest", advanceRequest);
      const localVarPath =
        `/api/v1/user/enterprise/{enterpriseId}/salary-advance/fee`.replace(
          `{${"enterpriseId"}}`,
          encodeURIComponent(String(enterpriseId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        advanceRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Gửi yêu cầu ứng lương
     * @summary Ứng lương
     * @param {string} userId
     * @param {string} enterpriseId
     * @param {AdvanceRequest} advanceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salaryAdvance: async (
      userId: string,
      enterpriseId: string,
      advanceRequest: AdvanceRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("salaryAdvance", "userId", userId);
      // verify required parameter 'enterpriseId' is not null or undefined
      assertParamExists("salaryAdvance", "enterpriseId", enterpriseId);
      // verify required parameter 'advanceRequest' is not null or undefined
      assertParamExists("salaryAdvance", "advanceRequest", advanceRequest);
      const localVarPath =
        `/api/v1/user/enterprise/{enterpriseId}/salary-advance`.replace(
          `{${"enterpriseId"}}`,
          encodeURIComponent(String(enterpriseId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        advanceRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Danh sách lịch sử ứng lương
     * @summary Lịch sử ứng lương
     * @param {string} userId
     * @param {string} enterpriseId
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salaryAdvanceHistory: async (
      userId: string,
      enterpriseId: string,
      startDate?: string,
      endDate?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("salaryAdvanceHistory", "userId", userId);
      // verify required parameter 'enterpriseId' is not null or undefined
      assertParamExists("salaryAdvanceHistory", "enterpriseId", enterpriseId);
      const localVarPath =
        `/api/v1/user/enterprise/{enterpriseId}/salary-advance`.replace(
          `{${"enterpriseId"}}`,
          encodeURIComponent(String(enterpriseId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (startDate !== undefined) {
        localVarQueryParameter["startDate"] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter["endDate"] = endDate;
      }

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Chi tiết lịch sử ứng lương
     * @summary Chi tiết lịch sử ứng lương
     * @param {string} userId
     * @param {string} enterpriseId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salaryAdvanceHistoryDetail: async (
      userId: string,
      enterpriseId: string,
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("salaryAdvanceHistoryDetail", "userId", userId);
      // verify required parameter 'enterpriseId' is not null or undefined
      assertParamExists(
        "salaryAdvanceHistoryDetail",
        "enterpriseId",
        enterpriseId,
      );
      // verify required parameter 'id' is not null or undefined
      assertParamExists("salaryAdvanceHistoryDetail", "id", id);
      const localVarPath =
        `/api/v1/user/enterprise/{enterpriseId}/salary-advance/{id}`
          .replace(
            `{${"enterpriseId"}}`,
            encodeURIComponent(String(enterpriseId)),
          )
          .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserSalaryAdvanceApi - functional programming interface
 * @export
 */
export const UserSalaryAdvanceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    UserSalaryAdvanceApiAxiosParamCreator(configuration);
  return {
    /**
     * Tính phí ứng lương
     * @summary Tính phí ứng lương
     * @param {string} userId
     * @param {string} enterpriseId
     * @param {AdvanceRequest} advanceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async calculateFee(
      userId: string,
      enterpriseId: string,
      advanceRequest: AdvanceRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseAdvanceFeeInfo>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.calculateFee(
        userId,
        enterpriseId,
        advanceRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Gửi yêu cầu ứng lương
     * @summary Ứng lương
     * @param {string} userId
     * @param {string} enterpriseId
     * @param {AdvanceRequest} advanceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async salaryAdvance(
      userId: string,
      enterpriseId: string,
      advanceRequest: AdvanceRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseCreateAdvanceResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.salaryAdvance(
        userId,
        enterpriseId,
        advanceRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Danh sách lịch sử ứng lương
     * @summary Lịch sử ứng lương
     * @param {string} userId
     * @param {string} enterpriseId
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async salaryAdvanceHistory(
      userId: string,
      enterpriseId: string,
      startDate?: string,
      endDate?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseListAdvanceHistoryInfo>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.salaryAdvanceHistory(
          userId,
          enterpriseId,
          startDate,
          endDate,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Chi tiết lịch sử ứng lương
     * @summary Chi tiết lịch sử ứng lương
     * @param {string} userId
     * @param {string} enterpriseId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async salaryAdvanceHistoryDetail(
      userId: string,
      enterpriseId: string,
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseAdvanceHistoryDetail>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.salaryAdvanceHistoryDetail(
          userId,
          enterpriseId,
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * UserSalaryAdvanceApi - factory interface
 * @export
 */
export const UserSalaryAdvanceApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = UserSalaryAdvanceApiFp(configuration);
  return {
    /**
     * Tính phí ứng lương
     * @summary Tính phí ứng lương
     * @param {string} userId
     * @param {string} enterpriseId
     * @param {AdvanceRequest} advanceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calculateFee(
      userId: string,
      enterpriseId: string,
      advanceRequest: AdvanceRequest,
      options?: any,
    ): AxiosPromise<BaseResponseAdvanceFeeInfo> {
      return localVarFp
        .calculateFee(userId, enterpriseId, advanceRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Gửi yêu cầu ứng lương
     * @summary Ứng lương
     * @param {string} userId
     * @param {string} enterpriseId
     * @param {AdvanceRequest} advanceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salaryAdvance(
      userId: string,
      enterpriseId: string,
      advanceRequest: AdvanceRequest,
      options?: any,
    ): AxiosPromise<BaseResponseCreateAdvanceResponse> {
      return localVarFp
        .salaryAdvance(userId, enterpriseId, advanceRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Danh sách lịch sử ứng lương
     * @summary Lịch sử ứng lương
     * @param {string} userId
     * @param {string} enterpriseId
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salaryAdvanceHistory(
      userId: string,
      enterpriseId: string,
      startDate?: string,
      endDate?: string,
      options?: any,
    ): AxiosPromise<BaseResponseListAdvanceHistoryInfo> {
      return localVarFp
        .salaryAdvanceHistory(userId, enterpriseId, startDate, endDate, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Chi tiết lịch sử ứng lương
     * @summary Chi tiết lịch sử ứng lương
     * @param {string} userId
     * @param {string} enterpriseId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salaryAdvanceHistoryDetail(
      userId: string,
      enterpriseId: string,
      id: string,
      options?: any,
    ): AxiosPromise<BaseResponseAdvanceHistoryDetail> {
      return localVarFp
        .salaryAdvanceHistoryDetail(userId, enterpriseId, id, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for calculateFee operation in UserSalaryAdvanceApi.
 * @export
 * @interface UserSalaryAdvanceApiCalculateFeeRequest
 */
export interface UserSalaryAdvanceApiCalculateFeeRequest {
  /**
   *
   * @type {string}
   * @memberof UserSalaryAdvanceApiCalculateFee
   */
  readonly userId: string;

  /**
   *
   * @type {string}
   * @memberof UserSalaryAdvanceApiCalculateFee
   */
  readonly enterpriseId: string;

  /**
   *
   * @type {AdvanceRequest}
   * @memberof UserSalaryAdvanceApiCalculateFee
   */
  readonly advanceRequest: AdvanceRequest;
}

/**
 * Request parameters for salaryAdvance operation in UserSalaryAdvanceApi.
 * @export
 * @interface UserSalaryAdvanceApiSalaryAdvanceRequest
 */
export interface UserSalaryAdvanceApiSalaryAdvanceRequest {
  /**
   *
   * @type {string}
   * @memberof UserSalaryAdvanceApiSalaryAdvance
   */
  readonly userId: string;

  /**
   *
   * @type {string}
   * @memberof UserSalaryAdvanceApiSalaryAdvance
   */
  readonly enterpriseId: string;

  /**
   *
   * @type {AdvanceRequest}
   * @memberof UserSalaryAdvanceApiSalaryAdvance
   */
  readonly advanceRequest: AdvanceRequest;
}

/**
 * Request parameters for salaryAdvanceHistory operation in UserSalaryAdvanceApi.
 * @export
 * @interface UserSalaryAdvanceApiSalaryAdvanceHistoryRequest
 */
export interface UserSalaryAdvanceApiSalaryAdvanceHistoryRequest {
  /**
   *
   * @type {string}
   * @memberof UserSalaryAdvanceApiSalaryAdvanceHistory
   */
  readonly userId: string;

  /**
   *
   * @type {string}
   * @memberof UserSalaryAdvanceApiSalaryAdvanceHistory
   */
  readonly enterpriseId: string;

  /**
   *
   * @type {string}
   * @memberof UserSalaryAdvanceApiSalaryAdvanceHistory
   */
  readonly startDate?: string;

  /**
   *
   * @type {string}
   * @memberof UserSalaryAdvanceApiSalaryAdvanceHistory
   */
  readonly endDate?: string;
}

/**
 * Request parameters for salaryAdvanceHistoryDetail operation in UserSalaryAdvanceApi.
 * @export
 * @interface UserSalaryAdvanceApiSalaryAdvanceHistoryDetailRequest
 */
export interface UserSalaryAdvanceApiSalaryAdvanceHistoryDetailRequest {
  /**
   *
   * @type {string}
   * @memberof UserSalaryAdvanceApiSalaryAdvanceHistoryDetail
   */
  readonly userId: string;

  /**
   *
   * @type {string}
   * @memberof UserSalaryAdvanceApiSalaryAdvanceHistoryDetail
   */
  readonly enterpriseId: string;

  /**
   *
   * @type {string}
   * @memberof UserSalaryAdvanceApiSalaryAdvanceHistoryDetail
   */
  readonly id: string;
}

/**
 * UserSalaryAdvanceApi - object-oriented interface
 * @export
 * @class UserSalaryAdvanceApi
 * @extends {BaseAPI}
 */
export class UserSalaryAdvanceApi extends BaseAPI {
  /**
   * Tính phí ứng lương
   * @summary Tính phí ứng lương
   * @param {UserSalaryAdvanceApiCalculateFeeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserSalaryAdvanceApi
   */
  public calculateFee(
    requestParameters: UserSalaryAdvanceApiCalculateFeeRequest,
    options?: AxiosRequestConfig,
  ) {
    return UserSalaryAdvanceApiFp(this.configuration)
      .calculateFee(
        requestParameters.userId,
        requestParameters.enterpriseId,
        requestParameters.advanceRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Gửi yêu cầu ứng lương
   * @summary Ứng lương
   * @param {UserSalaryAdvanceApiSalaryAdvanceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserSalaryAdvanceApi
   */
  public salaryAdvance(
    requestParameters: UserSalaryAdvanceApiSalaryAdvanceRequest,
    options?: AxiosRequestConfig,
  ) {
    return UserSalaryAdvanceApiFp(this.configuration)
      .salaryAdvance(
        requestParameters.userId,
        requestParameters.enterpriseId,
        requestParameters.advanceRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Danh sách lịch sử ứng lương
   * @summary Lịch sử ứng lương
   * @param {UserSalaryAdvanceApiSalaryAdvanceHistoryRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserSalaryAdvanceApi
   */
  public salaryAdvanceHistory(
    requestParameters: UserSalaryAdvanceApiSalaryAdvanceHistoryRequest,
    options?: AxiosRequestConfig,
  ) {
    return UserSalaryAdvanceApiFp(this.configuration)
      .salaryAdvanceHistory(
        requestParameters.userId,
        requestParameters.enterpriseId,
        requestParameters.startDate,
        requestParameters.endDate,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Chi tiết lịch sử ứng lương
   * @summary Chi tiết lịch sử ứng lương
   * @param {UserSalaryAdvanceApiSalaryAdvanceHistoryDetailRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserSalaryAdvanceApi
   */
  public salaryAdvanceHistoryDetail(
    requestParameters: UserSalaryAdvanceApiSalaryAdvanceHistoryDetailRequest,
    options?: AxiosRequestConfig,
  ) {
    return UserSalaryAdvanceApiFp(this.configuration)
      .salaryAdvanceHistoryDetail(
        requestParameters.userId,
        requestParameters.enterpriseId,
        requestParameters.id,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UserSalaryPeriodApi - axios parameter creator
 * @export
 */
export const UserSalaryPeriodApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Xem bảng công hiện tại
     * @summary Bảng công hiện tại
     * @param {string} userId
     * @param {string} enterpriseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCurrentLogworkTable: async (
      userId: string,
      enterpriseId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("getCurrentLogworkTable", "userId", userId);
      // verify required parameter 'enterpriseId' is not null or undefined
      assertParamExists("getCurrentLogworkTable", "enterpriseId", enterpriseId);
      const localVarPath =
        `/api/v1/user/enterprise/{enterpriseId}/salary-period/current`.replace(
          `{${"enterpriseId"}}`,
          encodeURIComponent(String(enterpriseId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Xem bảng công
     * @summary Bảng công
     * @param {string} userId
     * @param {string} enterpriseId
     * @param {string} periodId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLogworkTable: async (
      userId: string,
      enterpriseId: string,
      periodId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("getLogworkTable", "userId", userId);
      // verify required parameter 'enterpriseId' is not null or undefined
      assertParamExists("getLogworkTable", "enterpriseId", enterpriseId);
      // verify required parameter 'periodId' is not null or undefined
      assertParamExists("getLogworkTable", "periodId", periodId);
      const localVarPath =
        `/api/v1/user/enterprise/{enterpriseId}/salary-period/{periodId}`
          .replace(
            `{${"enterpriseId"}}`,
            encodeURIComponent(String(enterpriseId)),
          )
          .replace(`{${"periodId"}}`, encodeURIComponent(String(periodId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (userId != null) {
        localVarHeaderParameter["user-id"] = String(userId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Danh sách kỳ công
     * @summary Danh sách kỳ công
     * @param {string} enterpriseId
     * @param {number} page
     * @param {number} size
     * @param {string} [keyword]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listSalaryPeriod: async (
      enterpriseId: string,
      page: number,
      size: number,
      keyword?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'enterpriseId' is not null or undefined
      assertParamExists("listSalaryPeriod", "enterpriseId", enterpriseId);
      // verify required parameter 'page' is not null or undefined
      assertParamExists("listSalaryPeriod", "page", page);
      // verify required parameter 'size' is not null or undefined
      assertParamExists("listSalaryPeriod", "size", size);
      const localVarPath =
        `/api/v1/user/enterprise/{enterpriseId}/salary-period`.replace(
          `{${"enterpriseId"}}`,
          encodeURIComponent(String(enterpriseId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (keyword !== undefined) {
        localVarQueryParameter["keyword"] = keyword;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserSalaryPeriodApi - functional programming interface
 * @export
 */
export const UserSalaryPeriodApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    UserSalaryPeriodApiAxiosParamCreator(configuration);
  return {
    /**
     * Xem bảng công hiện tại
     * @summary Bảng công hiện tại
     * @param {string} userId
     * @param {string} enterpriseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCurrentLogworkTable(
      userId: string,
      enterpriseId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseUserLogworkByPeriodInfo>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCurrentLogworkTable(
          userId,
          enterpriseId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Xem bảng công
     * @summary Bảng công
     * @param {string} userId
     * @param {string} enterpriseId
     * @param {string} periodId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLogworkTable(
      userId: string,
      enterpriseId: string,
      periodId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseUserLogworkByPeriodInfo>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getLogworkTable(
        userId,
        enterpriseId,
        periodId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Danh sách kỳ công
     * @summary Danh sách kỳ công
     * @param {string} enterpriseId
     * @param {number} page
     * @param {number} size
     * @param {string} [keyword]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listSalaryPeriod(
      enterpriseId: string,
      page: number,
      size: number,
      keyword?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponsePageDtoListShortSalaryPeriodInfo>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listSalaryPeriod(
          enterpriseId,
          page,
          size,
          keyword,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * UserSalaryPeriodApi - factory interface
 * @export
 */
export const UserSalaryPeriodApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = UserSalaryPeriodApiFp(configuration);
  return {
    /**
     * Xem bảng công hiện tại
     * @summary Bảng công hiện tại
     * @param {string} userId
     * @param {string} enterpriseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCurrentLogworkTable(
      userId: string,
      enterpriseId: string,
      options?: any,
    ): AxiosPromise<BaseResponseUserLogworkByPeriodInfo> {
      return localVarFp
        .getCurrentLogworkTable(userId, enterpriseId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Xem bảng công
     * @summary Bảng công
     * @param {string} userId
     * @param {string} enterpriseId
     * @param {string} periodId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLogworkTable(
      userId: string,
      enterpriseId: string,
      periodId: string,
      options?: any,
    ): AxiosPromise<BaseResponseUserLogworkByPeriodInfo> {
      return localVarFp
        .getLogworkTable(userId, enterpriseId, periodId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Danh sách kỳ công
     * @summary Danh sách kỳ công
     * @param {string} enterpriseId
     * @param {number} page
     * @param {number} size
     * @param {string} [keyword]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listSalaryPeriod(
      enterpriseId: string,
      page: number,
      size: number,
      keyword?: string,
      options?: any,
    ): AxiosPromise<BaseResponsePageDtoListShortSalaryPeriodInfo> {
      return localVarFp
        .listSalaryPeriod(enterpriseId, page, size, keyword, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getCurrentLogworkTable operation in UserSalaryPeriodApi.
 * @export
 * @interface UserSalaryPeriodApiGetCurrentLogworkTableRequest
 */
export interface UserSalaryPeriodApiGetCurrentLogworkTableRequest {
  /**
   *
   * @type {string}
   * @memberof UserSalaryPeriodApiGetCurrentLogworkTable
   */
  readonly userId: string;

  /**
   *
   * @type {string}
   * @memberof UserSalaryPeriodApiGetCurrentLogworkTable
   */
  readonly enterpriseId: string;
}

/**
 * Request parameters for getLogworkTable operation in UserSalaryPeriodApi.
 * @export
 * @interface UserSalaryPeriodApiGetLogworkTableRequest
 */
export interface UserSalaryPeriodApiGetLogworkTableRequest {
  /**
   *
   * @type {string}
   * @memberof UserSalaryPeriodApiGetLogworkTable
   */
  readonly userId: string;

  /**
   *
   * @type {string}
   * @memberof UserSalaryPeriodApiGetLogworkTable
   */
  readonly enterpriseId: string;

  /**
   *
   * @type {string}
   * @memberof UserSalaryPeriodApiGetLogworkTable
   */
  readonly periodId: string;
}

/**
 * Request parameters for listSalaryPeriod operation in UserSalaryPeriodApi.
 * @export
 * @interface UserSalaryPeriodApiListSalaryPeriodRequest
 */
export interface UserSalaryPeriodApiListSalaryPeriodRequest {
  /**
   *
   * @type {string}
   * @memberof UserSalaryPeriodApiListSalaryPeriod
   */
  readonly enterpriseId: string;

  /**
   *
   * @type {number}
   * @memberof UserSalaryPeriodApiListSalaryPeriod
   */
  readonly page: number;

  /**
   *
   * @type {number}
   * @memberof UserSalaryPeriodApiListSalaryPeriod
   */
  readonly size: number;

  /**
   *
   * @type {string}
   * @memberof UserSalaryPeriodApiListSalaryPeriod
   */
  readonly keyword?: string;
}

/**
 * UserSalaryPeriodApi - object-oriented interface
 * @export
 * @class UserSalaryPeriodApi
 * @extends {BaseAPI}
 */
export class UserSalaryPeriodApi extends BaseAPI {
  /**
   * Xem bảng công hiện tại
   * @summary Bảng công hiện tại
   * @param {UserSalaryPeriodApiGetCurrentLogworkTableRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserSalaryPeriodApi
   */
  public getCurrentLogworkTable(
    requestParameters: UserSalaryPeriodApiGetCurrentLogworkTableRequest,
    options?: AxiosRequestConfig,
  ) {
    return UserSalaryPeriodApiFp(this.configuration)
      .getCurrentLogworkTable(
        requestParameters.userId,
        requestParameters.enterpriseId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Xem bảng công
   * @summary Bảng công
   * @param {UserSalaryPeriodApiGetLogworkTableRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserSalaryPeriodApi
   */
  public getLogworkTable(
    requestParameters: UserSalaryPeriodApiGetLogworkTableRequest,
    options?: AxiosRequestConfig,
  ) {
    return UserSalaryPeriodApiFp(this.configuration)
      .getLogworkTable(
        requestParameters.userId,
        requestParameters.enterpriseId,
        requestParameters.periodId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Danh sách kỳ công
   * @summary Danh sách kỳ công
   * @param {UserSalaryPeriodApiListSalaryPeriodRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserSalaryPeriodApi
   */
  public listSalaryPeriod(
    requestParameters: UserSalaryPeriodApiListSalaryPeriodRequest,
    options?: AxiosRequestConfig,
  ) {
    return UserSalaryPeriodApiFp(this.configuration)
      .listSalaryPeriod(
        requestParameters.enterpriseId,
        requestParameters.page,
        requestParameters.size,
        requestParameters.keyword,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
