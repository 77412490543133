export enum NavlinkPath {
  // group authenticate
  authenticate = "/authenticate",
  login = "/authenticate/login",
  register = "/authenticate/register",
  forgetPassword = "/authenticate/forget-assword",
  memberForgetPassword = "/authenticate/member-forget-password",
  guideLoginQr = "/authenticate/guide-login-qr",

  // group home page
  home = "/",
  dashboard = "/dashboard",
  balance = "/balance",
  newPayment = "/new-payment",
  payPOS = "/pay-pos",
  transactionsManagement = "/transactions-management",
  statistic = "/statistic",
  support = "/support",
  customerManagement = "/customer-management",
  customerGroupDetail = "/customer-management/customer-group-detail",
  public = "/public",

  // feeReport
  feeReport = "/fee-report",
  feeReportDetail = "/fee-report/detail-fee-report",
  feeReportDetailTransactionHistory = "/fee-report/detail-fee-report/transactions-management",

  forControl = "/for-control",

  // setting
  setting = "/setting",
  indenticateAccountSetting = "/setting/account-identification",
  accountInfo = "/setting/account-info",
  changePassword = "/setting/change-password",
  receivedMoneyAccount = "/setting/received-money-account",
  receivedMoneyAccountDetail = "/setting/received-money-account/account-detail",
  notifyEmail = "/setting/notify-email",
  settingPayment = "/setting/payment-setting",
  // member management
  memberManagement = "/setting/member-management",
  memberManagementCreate = "/setting/member-management/create-user",
  memberManagementUpdate = "/setting/member-management/update-user",

  // paypage
  payPage = "/paypage",
  createPayPage = "/paypage/create-paypage",
  paymentPayPage = "/public/paypage/:id",
  waittingPaymentPayPage = "/public/paypage/waiting-payment",
  payPageDetail = "/paypage/paypage-detail",
  payPageDetailInfo = "/paypage/paypage-detail/paypage-detail-info",
  payPageHistoryTransaction = "/paypage/paypage-detail/paypage-history-transaction",
  payPageDetailHistoryTransaction = "/paypage/paypage-detail/paypage-history-transaction-detail",

  // paylink
  payLink = "/paylink",
  createPayLink = "/paylink/create-paylink",
  paymentPayLink = "/public/paylink/:id",
  waittingPaymentPayLink = "/public/paylink/waiting-payment",

  // Information Product
  productManagement = "/products",
  categoryDetail = "/products/category-detail",

  // payBox
  payBox = "/pay-box",
  payBoxDetail = "/pay-box/:name",

  // payout
  payout = "/payout",
  // payoutDashboard = "/payout/dashboard",
  detailPayout = "/payout/detail-payout",
  createPayout = "/payout/create-payout",

  // paySubscription
  paySubscription = "/pay-subscription",
  paySubscriptionDetail = "/pay-subscription/pay-subscription-detail",
  paySubscriptionBillDetail = "/pay-subscription/pay-subscription-detail/pay-subscription-bill-detail",
  paySubscriptionCreate = "/pay-subscription/create-pay-subscription",
  paySubscriptionUpdate = "/pay-subscription/update-pay-subscription",
  paySubscriptionPlan = "/pay-subscription-plan",
  paySubscriptionPlanCreate = "/pay-subscription-plan/create-pay-subscription-plan",
  paySubscriptionPlanUpdate = "/pay-subscription-plan/update-pay-subscription-plan",
  paySubscriptionPlanDetail = "/pay-subscription-plan/pay-subscription-plan-detail",

  // payButton
  payButton = "/pay-button",
  payButtonDetail = "/pay-button/pay-button-detail",
  createPayButton = "/pay-button/create-paybutton",
  updatePayButton = "/pay-button/update-pay-button",

  // payPOS
  // payPos = "/pay-pos",
  // payPosRegisterService = "/pay-pos-devices/register-sevice",
  // payPosOrderService = "/pay-pos-devices/order-service",
  payPosDevice = "/pay-pos-devices",
  // payPosDeviceHistoryPayment = "/pay-pos-device-history-payment",

  payPosHistoryPayment = "/pay-pos-history-payment",

  // pay gate
  payGate = "/pay-gate",
  payGatePayment = "/pay-gate/payment",
  payGateWebhooks = "/pay-gate/webhooks",
  payGateTools = "/pay-gate/tools",
  payGateToolsTransferMoney = "/pay-gate/tools/transfer-money",

  // identification
  identification = "/account-identification",

  // for control
  forControlDetail = "/for-control/for-control-detail",

  //napas public
  napasCheckin = "/public/napas",

  //utilities
  ultilities = "/ultilities",
  vietQrPro = "/ultilities/vietqr-pro",
  vietQrProTransaction = "/ultilities/vietqr-pro/transactions-management",
  appMarket = "/ultilities/app-market",
  flexibleSalaryPayment = "/ultilities/flexible-salary-payment",
  flexibleSalaryPaymentStatistic = `${flexibleSalaryPayment}/statistic`,
  flexibleSalaryPaymentStatisticDetail = `${flexibleSalaryPaymentStatistic}/detail-statistic`,
  flexibleSalaryPaymentEnterpriseConfiguration = `${flexibleSalaryPayment}/enterprise-configuration`,
  flexibleSalaryPaymentTypeOfFlexibleSalary = `${flexibleSalaryPayment}/type-of-advance-salaries`,
  flexibleSalaryPaymentTypeOfFlexibleSalaryDetail = `${flexibleSalaryPaymentTypeOfFlexibleSalary}/detail-type-of-advance-salaries`,
  flexibleSalaryPaymentTypeOfFlexibleSalaryCreate = `${flexibleSalaryPaymentTypeOfFlexibleSalary}/create-type-of-advance-salaries`,
  flexibleSalaryPaymentTypeOfFlexibleSalaryUpdate = `${flexibleSalaryPaymentTypeOfFlexibleSalary}/update-type-of-advance-salaries`,
  flexibleSalaryPaymentStaff = `${flexibleSalaryPayment}/staff`,
  flexibleSalaryPaymentStaffDetail = `${flexibleSalaryPaymentStaff}/detail-staff`,
  flexibleSalaryPaymentStaffCreate = `${flexibleSalaryPaymentStaff}/create-staff`,
  flexibleSalaryPaymentStaffUpdate = `${flexibleSalaryPaymentStaff}/update-staff`,
  flexibleSalaryPaymentStaffImportExcel = `${flexibleSalaryPaymentStaff}/import-excel`,
  flexibleSalaryPaymentPeriod = `${flexibleSalaryPayment}/period`,
  flexibleSalaryPaymentPeriodDetail = `${flexibleSalaryPaymentPeriod}/detail-period`,
  flexibleSalaryPaymentPeriodDetailImportExcel = `${flexibleSalaryPaymentPeriodDetail}/import-excel`,
  flexibleSalaryPaymentRequestAdvanceSalaries = `${flexibleSalaryPayment}/request-advance-salaries`,
  flexibleSalaryPaymentRequestAdvanceSalariesDetail = `${flexibleSalaryPaymentRequestAdvanceSalaries}/detail-advance-salaries`,
}
