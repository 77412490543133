import { PaymentType } from "./PaylinkModel";
export interface PosDeviceModel {
  createdBy: string;
  createdAt: string;
  modifiedBy: string;
  modifiedAt: string;
  id: number;
  name: string;
  model: string;
  price: number;
  stock: number;
  available: boolean;
  description: string;
  warranty: number;
  discount: number;
  mainPhotoUrl: string;
  photos: PosDevicePhotoModel[];
  vat: number;
}

export interface PosDevicePhotoModel {
  id: number;
  url: string;
}

export interface PagePosPortalModel {
  id: string;
  deviceId: string;
  model: PosPortalTypeModel;
  status: PosPortalStatus;
  revenue: number;
  transactionNotSettlementNumber: number;
}

export enum PosPortalTypeModel {
  P8 = "P8",
  P12 = "P12",
}

export enum PosPortalStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export interface PagePosPortalHistoryModel {
  id: string;
  createdAt: string;
  amount: number;
  status: PosPortalHistoryStatus;
  finish: boolean;
  customerName: string;
  staffName: string;
  deviceId: string;
  accountNumber: string;
  settlementTime: string;
}

export enum PosPortalHistoryStatus {
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  TIMEOUT = "TIMEOUT",
  CANCEL = "CANCEL",
  REFUNDED = "REFUNDED",
  CREATED = "CREATED",
}

export interface PagePosPortalDetailModel {
  id: string;
  finish: boolean;
  transactionCode: string;
  createdAt: string;
  amount: number;
  paymentType: PaymentType;
  deviceName: string;
  status: PosPortalHistoryStatus;
  staffName: string;
  customerName: string;
  customerEmail: string;
  accountNumber: string;
}
