import { AppBloc } from "@/services/base-bloc";
import { FC, useCallback, useEffect, useState } from "react";
import { AppContext } from "./AppContext";

export const AppContextProvider: FC<{
  children: React.ReactNode;
  appProps: AppBloc;
}> = ({
  children,
  appProps,
}: {
  children: React.ReactNode;
  appProps: AppBloc;
}) => {
  const [_appProps, _setAppProps] = useState<AppBloc>(appProps);

  const handleAppProps = useCallback(() => _setAppProps(appProps), [_appProps]);

  useEffect(() => {
    handleAppProps();
    return () => {
      _appProps?.session.closeAll();
    };
  }, []);

  return (
    <AppContext.Provider value={_appProps}>{children}</AppContext.Provider>
  );
};
