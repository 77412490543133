import { IconClose } from "../icons-component/icon-component";
import "./welcom-message.css";
import "@/styles";

export const WelcomMessage = ({
  onClose,
  onClickDashboard,
  onClickActive,
}: {
  onClickDashboard: () => void;
  onClickActive: () => void;
  onClose: () => void;
}) => {
  return (
    <div className="overlay-1">
      <div className="welcom-message animation-faded--in">
        <div
          className="row"
          style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <div
            onClick={() => {
              onClose();
            }}
            style={{
              width: "24px",
              height: "24px",
              cursor: "pointer",
            }}
          >
            <IconClose color="#2B2B3A" />
          </div>
        </div>
        <div
          className="column"
          style={{
            justifyContent: "flex-start",
            alignItems: "flex-start",
            maxWidth: "480px",
            width: "100%",
            gap: "16px",
          }}
        >
          <span
            className="fontSize--30 lineHeight--38 fontWeight--500"
            style={{
              color: "var(--umee-color-ink-100)",
              width: "100%",
            }}
          >
            Chào mừng bạn đến với Kienlongbank Pay Dashboard
          </span>
          <div></div>
          <span
            className="fontSize--16 lineHeight--20 fontWeight--400"
            style={{
              color: "var(--umee-color-ink-100)",
              width: "100%",
            }}
          >
            Chỉ 1 bước nữa thôi là bạn có thể trải nghiệm đầy đủ tính năng của
            Kienlongbank Pay Dashboard và nhận thanh toán từ khách hàng.
          </span>
          <span
            className="fontSize--16 lineHeight--20 fontWeight--400"
            style={{
              color: "var(--umee-color-ink-100)",
              width: "100%",
            }}
          >
            Vui lòng ấn vào kích hoạt tài khoản và bổ sung thêm thông tin chi
            tiết để hoàn tất việc xác minh tài khoản.
          </span>
          <div></div>
          <div></div>
          <div className="row" style={{ gap: "16px" }}>
            <div
              className="button-1"
              onClick={() => {
                onClickActive();
              }}
              style={{
                width: "auto",
              }}
            >
              <span className="fontSize--16 lineHeight--20 fontWeight--600">
                Kích hoạt tài khoản
              </span>
            </div>
            <div
              className="button-1"
              onClick={() => {
                onClickDashboard();
              }}
              style={{
                width: "auto",
                backgroundColor: "#FFFFFF",
                border: " 1px solid #D1D5DB",
              }}
            >
              <span
                className="fontSize--16 lineHeight--20 fontWeight--500"
                style={{ color: "#374151" }}
              >
                Dùng thử Dashboard
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
