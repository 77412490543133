export const IconSearch = ({ color = "#B8C3E1" }: { color?: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.49114 3.875C6.37922 3.875 3.875 6.36393 3.875 9.4115C3.875 12.4591 6.37922 14.948 9.49114 14.948C10.9966 14.948 12.3605 14.3653 13.3683 13.417C14.4422 12.4064 15.1073 10.9852 15.1073 9.4115C15.1073 6.36393 12.6031 3.875 9.49114 3.875ZM2.125 9.4115C2.125 5.37713 5.43315 2.125 9.49114 2.125C13.5491 2.125 16.8573 5.37713 16.8573 9.4115C16.8573 11.1656 16.23 12.7743 15.1887 14.0301L21.6149 20.3775C21.9587 20.7171 21.9621 21.2711 21.6225 21.6149C21.2829 21.9587 20.7289 21.9621 20.3851 21.6225L13.9186 15.2354C12.6857 16.1538 11.1514 16.698 9.49114 16.698C5.43315 16.698 2.125 13.4459 2.125 9.4115Z"
        fill={color}
      />
    </svg>
  );
};

export const IconRefresh = ({ color = "#6b7280" }: { color?: string }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.48915 2.27267C9.121 1.74244 10.8788 1.74244 12.5107 2.27267C14.1425 2.80289 15.5646 3.83611 16.5732 5.22425C16.6789 5.36976 16.7795 5.51839 16.8749 5.66986V3.33333C16.8749 2.98816 17.1547 2.70833 17.4999 2.70833C17.8451 2.70833 18.1249 2.98816 18.1249 3.33333V10C18.1249 10.3452 17.8451 10.625 17.4999 10.625C17.1547 10.625 16.8749 10.3452 16.8749 10C16.8749 8.54814 16.4153 7.13355 15.5619 5.95898C14.7085 4.7844 13.5052 3.91014 12.1244 3.46149C10.7436 3.01284 9.25622 3.01284 7.87542 3.46149C6.49462 3.91014 5.2913 4.7844 4.43792 5.95898C4.23503 6.23823 3.84418 6.30014 3.56492 6.09725C3.28567 5.89436 3.22376 5.5035 3.42665 5.22425C4.43519 3.83611 5.8573 2.80289 7.48915 2.27267ZM2.5 9.375C2.84518 9.375 3.125 9.65482 3.125 10C3.125 11.4519 3.58463 12.8664 4.43801 14.041C5.29139 15.2156 6.49471 16.0899 7.87551 16.5385C9.25631 16.9872 10.7437 16.9872 12.1245 16.5385C13.5053 16.0899 14.7086 15.2156 15.562 14.041C15.7649 13.7618 16.1557 13.6999 16.435 13.9028C16.7142 14.1056 16.7762 14.4965 16.5733 14.7758C15.5647 16.1639 14.1426 17.1971 12.5108 17.7273C10.8789 18.2576 9.12109 18.2576 7.48924 17.7273C5.85738 17.1971 4.43528 16.1639 3.42674 14.7758C3.32101 14.6302 3.22041 14.4816 3.125 14.3301V16.6667C3.125 17.0118 2.84518 17.2917 2.5 17.2917C2.15482 17.2917 1.875 17.0118 1.875 16.6667V10C1.875 9.65482 2.15482 9.375 2.5 9.375Z"
        fill={color}
      />
    </svg>
  );
};

export const IconExcel = ({ color = "#1C64F2" }: { color?: string }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.7085 2.5C2.7085 2.15482 2.98832 1.875 3.3335 1.875H12.9168C13.0767 1.875 13.2306 1.9363 13.3467 2.04628L17.0967 5.59891C17.2213 5.71694 17.2918 5.88101 17.2918 6.05263V17.5C17.2918 17.8452 17.012 18.125 16.6668 18.125H3.3335C2.98832 18.125 2.7085 17.8452 2.7085 17.5V2.5ZM3.9585 3.125V16.875H16.0418V6.32146L12.6678 3.125H3.9585ZM7.29183 7.5C7.29183 7.15482 7.57165 6.875 7.91683 6.875H12.0835C12.4287 6.875 12.7085 7.15482 12.7085 7.5C12.7085 7.84518 12.4287 8.125 12.0835 8.125H8.54183V10.2083H12.0835C12.4287 10.2083 12.7085 10.4882 12.7085 10.8333C12.7085 11.1785 12.4287 11.4583 12.0835 11.4583H8.54183V13.5417H12.0835C12.4287 13.5417 12.7085 13.8215 12.7085 14.1667C12.7085 14.5118 12.4287 14.7917 12.0835 14.7917H7.91683C7.57165 14.7917 7.29183 14.5118 7.29183 14.1667V7.5Z"
        fill={color}
      />
    </svg>
  );
};
