import { KienlongPayClient } from "@/api/kienlong_pay_http_client";
import { ErrorModel } from "@/models/ErrorModel";
import {
  QrLoginModel,
  QrLoginInfoModel,
  QrUserInfoModel,
} from "@/models/LoginQrModel";
import { LoginByQrRepository } from "../LoginByQrRepository";
import {
  mapQrLoginInfoModel,
  mapQrLoginModel,
  mapQrUserInfoModel,
} from "@/adapters/login_qr_adapter";
import { hashString } from "@/utils/hashString";

export class LoginByQrRepositoryImp implements LoginByQrRepository {
  private client: KienlongPayClient;
  constructor(kienlongPayClient: KienlongPayClient) {
    this.client = kienlongPayClient;
  }

  public async requestLoginQr({
    deviceName,
    location,
    operatingSystem,
  }: {
    deviceName: string;
    location: string;
    operatingSystem: string;
  }): Promise<QrLoginModel> {
    try {
      let resp = await this.client.loginByQRController.qrLoginRequest({
        qrLoginRequest: {
          deviceName: deviceName,
          location: location,
          operatingSystem: operatingSystem,
        },
      });
      if (resp.data.code === 0) {
        return mapQrLoginModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async queryLoginQr({
    sessionId,
    sessionKey,
  }: {
    sessionId: string;
    sessionKey: string;
  }): Promise<QrLoginInfoModel> {
    try {
      let resp = await this.client.loginByQRController.qrLoginGetInfo({
        qrLoginGetInfoRequest: {
          sessionId: sessionId,
          sessionKey: sessionKey,
        },
      });
      if (resp.data.code === 0) {
        return mapQrLoginInfoModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getLoginQrToken({
    sessionId,
    sessionKey,
  }: {
    sessionId: string;
    sessionKey: string;
  }): Promise<{
    refreshToken: string;
    token: string;
  }> {
    try {
      let resp = await this.client.loginByQRController.qrLoginGetToken({
        qrLoginGetTokenRequest: {
          sessionId: sessionId,
          sessionKey: sessionKey,
        },
      });
      if (resp.data.code === 0) {
        return {
          refreshToken: resp.data.data?.refreshToken ?? "",
          token: resp.data.data?.token ?? "",
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async qrRegisterSetPassword({
    sessionId,
    sessionKey,
    password,
  }: {
    sessionId: string;
    sessionKey: string;
    password: string;
  }): Promise<boolean> {
    try {
      let resp = await this.client.loginByQRController.qrRegisterSetPassword({
        qrRegisterSetPasswordRequest: {
          password: hashString(password),
          sessionId: sessionId,
          sessionKey: sessionKey,
        },
      });
      if (resp.data.code === 0) {
        return resp.data.data?.success ?? false;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async qrRegisterGetNewUserToken({
    sessionId,
    sessionKey,
  }: {
    sessionId: string;
    sessionKey: string;
  }): Promise<{
    refreshToken: string;
    token: string;
  }> {
    try {
      let resp =
        await this.client.loginByQRController.qrRegisterGetNewUserToken({
          qrRegisterGetTokenRequest: {
            sessionId: sessionId,
            sessionKey: sessionKey,
          },
        });
      if (resp.data.code === 0) {
        return {
          refreshToken: resp.data.data?.refreshToken ?? "",
          token: resp.data.data?.token ?? "",
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async qrLoginGetUserInfo({
    sessionId,
    sessionKey,
  }: {
    sessionId: string;
    sessionKey: string;
  }): Promise<QrUserInfoModel> {
    try {
      let resp = await this.client.loginByQRController.qrLoginGetUserInfo({
        qrLoginGetUserInfoRequest: {
          sessionId: sessionId,
          sessionKey: sessionKey,
        },
      });
      if (resp.data.code === 0) {
        return mapQrUserInfoModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
