export const IconPaySubscription = ({
  color = "#3366FF",
}: {
  color?: string;
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2387 2C15.7693 2 16.1994 2.43012 16.1994 2.96071L16.1994 5.84284C16.1994 6.37342 15.7693 6.80354 15.2387 6.80354C14.7081 6.80354 14.278 6.37342 14.278 5.84283L14.278 2.96071C14.278 2.43012 14.7081 2 15.2387 2Z"
        fill={color}
      />
      <path
        d="M19.573 9.95166H3V13.1528C3 15.8701 3 17.2288 3.84416 18.0729C4.68831 18.9171 5.7965 18.9171 8.5138 18.9171H11.4579C11.278 18.3678 11.1808 17.781 11.1808 17.1716C11.1808 14.0757 13.6905 11.5659 16.7864 11.5659C17.8008 11.5659 18.7522 11.8353 19.573 12.3065V9.95166Z"
        fill={color}
      />
      <path
        d="M9.47447 3.85997H13.3173L13.3173 5.78138C13.3173 6.84255 14.1776 7.7028 15.2387 7.7028C16.2999 7.7028 17.1601 6.84255 17.1601 5.78138L17.1602 3.95848C17.8537 4.06156 18.2972 4.27249 18.7288 4.70412C19.5648 5.54005 19.5729 6.19379 19.573 8.84505H3.00086C3.00854 6.63896 3.08487 5.46342 3.84416 4.70412C4.29577 4.25252 4.82294 4.04251 5.63164 3.94485L5.63164 5.78139C5.63164 6.84256 6.49188 7.70281 7.55305 7.70281C8.61422 7.70281 9.47447 6.84256 9.47447 5.78139L9.47447 3.85997Z"
        fill={color}
      />
      <path
        d="M8.51377 2.96071C8.51377 2.43013 8.08364 2 7.55306 2C7.02247 2 6.59235 2.43013 6.59235 2.96071L6.59235 5.84284C6.59235 6.37342 7.02247 6.80355 7.55305 6.80355C8.08364 6.80355 8.51377 6.37342 8.51377 5.84284V2.96071Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.2593 17.1715C21.2593 19.6418 19.2568 21.6443 16.7866 21.6443C14.3163 21.6443 12.3138 19.6418 12.3138 17.1715C12.3138 14.7013 14.3163 12.6988 16.7866 12.6988C19.2568 12.6988 21.2593 14.7013 21.2593 17.1715ZM16.8725 14.0182C17.0316 14.0182 17.1574 14.1765 17.1574 14.3355V14.7596H18.0897C18.2487 14.7596 18.4247 14.8822 18.4247 15.0554V15.1615C18.4247 15.3205 18.2705 15.4487 18.1115 15.4487H16.0443C15.8852 15.4487 15.7262 15.5547 15.6202 15.6607C15.4612 15.8197 15.4082 15.9787 15.4082 16.1377C15.4082 16.2968 15.5142 16.4558 15.6202 16.5618C15.7262 16.6678 15.8852 16.7738 16.0443 16.7738H17.4754C17.8465 16.7738 18.1984 16.9051 18.4825 17.1979C18.7372 17.4603 18.9066 17.7809 18.9066 18.152C18.9066 18.523 18.8006 18.8411 18.5886 19.1061C18.3765 19.3711 18.0055 19.5301 17.6344 19.5301H17.2634V20.0072C17.2634 20.1662 17.1044 20.3252 16.9454 20.3252H16.7863C16.6273 20.3252 16.4683 20.1662 16.4683 20.0072V19.5301H15.3022C15.1431 19.5301 14.9841 19.3711 14.9841 19.2121V19.0531C14.9841 18.8941 15.0901 18.7881 15.2492 18.7881H17.4754C17.7455 18.7881 17.9072 18.6762 17.9983 18.5707C18.0894 18.4652 18.1645 18.311 18.1645 18.152C18.1645 17.9528 18.0493 17.7738 17.9651 17.707L17.9622 17.7048C17.8774 17.6375 17.7241 17.5159 17.4754 17.5159H16.0443C15.7262 17.5159 15.3552 17.3569 15.0901 17.0919C14.8251 16.8798 14.6661 16.5088 14.6661 16.1377C14.6661 15.7667 14.8514 15.4215 15.0901 15.1836C15.3468 14.9279 15.6732 14.7596 16.0443 14.7596H16.4153V14.3355C16.4153 14.1765 16.5545 14.0182 16.7135 14.0182H16.8725Z"
        fill={color}
      />
    </svg>
  );
};
