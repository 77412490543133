import React, { useEffect, useState } from "react";
import { useAppBloc } from "@/contexts/app";
import { bottombarId } from "@/services/constants";

const BottomBarCustom = () => {
  const { appBloc } = useAppBloc();
  // const [_isShowBottombar, _setShowBottomBar] = useState(false);
  const [_bottomBar, _setBottomBar] = useState<JSX.Element | null>(null);

  useEffect(() => {
    appBloc.session.bottomBarComponent.subscribe((v) => {
      _setBottomBar(v);
      // if (v !== null) {
      //   _setShowBottomBar(true);
      // } else {
      //   _setShowBottomBar(false);
      // }
    });
  }, []);

  if (_bottomBar === null) {
    return <></>;
  }
  return (
    <div
      id={bottombarId}
      className="column"
      style={{
        width: "100%",
        maxHeight: "76px",
        backgroundColor: "#FFFFFF",
        borderTop: "1px solid var(--color-alias-outline)",
      }}
    >
      {_bottomBar}
    </div>
  );
};

export default React.memo(BottomBarCustom, (prevProps, nextProps) => {
  return true;
});
