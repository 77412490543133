import { EnterpriseConfigDetail } from "@/api/generated-sources/flexsalary";
import {
  EnterpriseConfigCurrency,
  EnterpriseConfigDetailModel,
  EnterpriseConfigPaymentSource,
} from "../models/EnterpriseConfigDetailModel";
import { mapAuditDataModel } from "./audit-data-adapter";
import { EnterpriseRegistrationRequestProgramType } from "../models/EnterpriseModel";

export function mapEnterpriseConfigDetailModel(
  dto?: EnterpriseConfigDetail,
): EnterpriseConfigDetailModel {
  return {
    paymentSource:
      EnterpriseConfigPaymentSource[
        dto?.paymentSource as keyof typeof EnterpriseConfigPaymentSource
      ],
    accountName: dto?.accountName ?? "",
    accountNumber: dto?.accountNumber ?? "",
    audit: dto?.audit?.map((d) => mapAuditDataModel(d)) ?? [],
    bankName: dto?.bankName ?? "",
    currency:
      EnterpriseConfigCurrency[
        dto?.currency as keyof typeof EnterpriseConfigCurrency
      ],
    limitAmount: dto?.limitAmount,
    periodEndDate: dto?.periodEndDate,
    periodStartDate: dto?.periodStartDate,
    paySalaryDate: dto?.paySalaryDate,
    workingDays: dto?.workingDays,
    disableAdvanceBeforeEndDate: dto?.disableAdvanceBeforeEndDate,
    programType:
      EnterpriseRegistrationRequestProgramType[
        dto?.programType as keyof typeof EnterpriseRegistrationRequestProgramType
      ],
  };
}
