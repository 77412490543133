export enum PayboxStaticQrType {
  BANK = "BANK",
  E_WALLET = "E_WALLET",
  UMEE = "UMEE",
}

export interface PayboxStaticQrModel {
  id: string;
  name: string;
  shortName: string;
  qrCode: string;
  deviceId: string;
  accountNumber: string;
  accountName: string;
  content: string;
  shopName: string;
  icon: string;
  defaultQr: boolean;
  staticQrType: PayboxStaticQrType;
}
