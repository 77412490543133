import { BLoC } from "@/domains/bloc";
import { BehaviorSubject } from "rxjs";
import { AppRepository } from "./repository";
import { AppSession } from "./sessions";
import { AppStorage } from "./storages";
import React from "react";
import { kienlongPayConfig } from "@/api/kienlong_pay_http_client";
import { payboxConfig } from "@/api/paybox_http_client";
import { storageConfig } from "@/api/storage_http_client";
import { flexSalaryConfig } from "@/api/flexsalary_http_client";
import { Environment } from "./environment";

export class BaseBloc<T> {
  public stream: BehaviorSubject<T>;
  private _state: T;

  constructor({
    initState,
    callback = () => {},
  }: {
    initState: T;
    callback?: () => void | Promise<void>;
  }) {
    this._state = initState;
    this.stream = new BehaviorSubject<T>(this._state);
    callback();
  }

  set state(s: T) {
    this._state = s;
  }

  get state(): T {
    return this._state;
  }

  public upDateState(s?: T) {
    if (s !== undefined) {
      this._state = s;
    }
    this.stream.next(this._state);
  }
}

export function useBaseBloc<T>(
  data: T,
): [T, (v?: T) => void, stream: BehaviorSubject<T>] {
  const _bloc = React.useRef<BaseBloc<T>>(
    new BaseBloc<T>({ initState: data }),
  ).current;
  const [_state, _setState] = React.useState<T>(_bloc.state);

  function updateState(s?: T) {
    _bloc.upDateState(s);
  }

  React.useEffect(() => {
    _bloc.stream.subscribe((v) => {
      _setState({ ...v });
    });

    return () => {
      _bloc.stream.complete();
    };
  }, [_bloc.stream]);

  return [_bloc.state, updateState, _bloc.stream];
}

export class AppBloc extends BLoC {
  private static _instance: AppBloc;
  public declare storage: AppStorage;
  public declare session: AppSession;
  public declare repository: AppRepository;

  constructor() {
    super();
    if (!AppBloc._instance) {
      AppBloc._instance = this;
      this.storage = new AppStorage();
      this.repository = new AppRepository(
        kienlongPayConfig.basePath,
        payboxConfig.basePath,
        storageConfig.basePath,
        Environment.basePath,
      );
      this.session = new AppSession();
    }
    return AppBloc._instance;
  }
}
