import { CustomerModel } from "./CustomersModel";
import { PaySubscriptionPlanModel } from "./PaySubscriptionPlanModel ";
import { PaymentType } from "./PaylinkModel";

export interface PaySubscriptionInvoiceModel {
  id: string;
  status: PaySubscriptionInvoiceStatus;
  plan: PaySubscriptionPlanModel;
  customer: CustomerModel;
  subscriptionId: string;
  txnNumber: string;
  amount: number;
  paymentDate: string;
  expiredDate: string;
  url: string;
  paymentType: PaymentType;
}

export enum PaySubscriptionInvoiceStatus {
  NONE = "NONE",
  CREATED = "CREATED",
  SUCCESS = "SUCCESS",
  CANCELED = "CANCELED",
  FAIL = "FAIL",
  TIMEOUT = "TIMEOUT",
  PENDING = "PENDING",
}
