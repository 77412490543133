export const IconPayGate = ({ color = "#000000" }: { color: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.801 12L6.51975 4.75H17.4802L20.199 12L17.4802 19.25H6.51975L3.801 12ZM8.25 12C8.25 9.92893 9.92893 8.25 12 8.25C14.0711 8.25 15.75 9.92893 15.75 12C15.75 14.0711 14.0711 15.75 12 15.75C9.92893 15.75 8.25 14.0711 8.25 12Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 3.25C5.68737 3.25 5.40753 3.44393 5.29775 3.73666L2.29775 11.7367C2.23408 11.9064 2.23408 12.0936 2.29775 12.2633L5.29775 20.2633C5.40753 20.5561 5.68737 20.75 6 20.75H18C18.3126 20.75 18.5925 20.5561 18.7022 20.2633L21.7022 12.2633C21.7659 12.0936 21.7659 11.9064 21.7022 11.7367L18.7022 3.73666C18.5925 3.44393 18.3126 3.25 18 3.25H6ZM3.801 12L6.51975 4.75H17.4802L20.199 12L17.4802 19.25H6.51975L3.801 12Z"
        fill={color}
      />
    </svg>
  );
};
