import {
  PosModelPhotoDto,
  PosModelResponse,
} from "@/api/generated-sources/kienlong-pay/api";
import { PosDeviceModel, PosDevicePhotoModel } from "@/models/PosModelModel";

export function mapPosDeviceModel(
  dto: PosModelResponse | undefined,
): PosDeviceModel {
  return {
    createdBy: dto?.createdBy ?? "",
    createdAt: dto?.createdAt ?? "",
    modifiedBy: dto?.modifiedBy ?? "",
    modifiedAt: dto?.modifiedAt ?? "",
    id: dto?.id ?? 0,
    name: dto?.name ?? "",
    model: dto?.model ?? "",
    price: dto?.price ?? 0,
    stock: dto?.stock ?? 0,
    available: dto?.available ?? false,
    description: dto?.description ?? "",
    warranty: dto?.warranty ?? 0,
    discount: dto?.discount ?? 0,
    mainPhotoUrl: dto?.mainPhotoUrl ?? "",
    photos:
      dto?.photos?.map((permission) => mapPosDevicePhotoModel(permission)) ||
      [],
    vat: dto?.vat ?? 0,
  };
}

export function mapPosDevicePhotoModel(
  dto: PosModelPhotoDto,
): PosDevicePhotoModel {
  return {
    id: dto?.id ?? 0,
    url: dto?.url ?? "",
  };
}
