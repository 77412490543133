import "./message-custom.css";
import "@/styles";
import { iconCheckCircle } from "@/services/assets";
import { useEffect } from "react";

export const SuccessMessage = ({
  title = "Thành công",
  content,
  onClose = () => {},
  onClick = () => {},
  duration = 10000,
}: {
  title?: string | null;
  content: string;
  onClose?: () => void;
  onClick?: () => void;
  duration?: number;
}) => {
  useEffect(() => {
    setTimeout(() => {
      onClose();
    }, duration);
  }, []);

  return (
    <div className="wrap-custom-message">
      <div
        onClick={() => {
          onClick();
        }}
        className="custom-message"
        style={{ position: "relative", backgroundColor: "#F3FAF7" }}
      >
        <div
          className="custom-message__tag"
          style={{ backgroundColor: "#31C48D" }}
        ></div>

        <img
          alt=""
          src={iconCheckCircle}
          style={{ height: "32px", width: "32px" }}
        />

        <div
          className="column"
          style={{
            width: "100%",
            gap: "8px",
            alignItems: "flex-start",
            justifyContent: "center",
          }}
        >
          <span
            className="fontSize--16 lineHeight--24 fontWeight--700"
            style={{
              color: "#31C48D",
              width: "100%",
            }}
          >
            {title}
          </span>
          <span
            className="fontSize--14 lineHeight--20 fontWeight--400"
            style={{
              color: "#374151",
              width: "100%",
              whiteSpace: "initial",
              wordBreak: "break-word",
              wordWrap: "break-word",
            }}
          >
            {content}
          </span>
        </div>
      </div>
    </div>
  );
};
