import { mapShopSettingModel } from "@/adapters/shop_adapter";
import { KienlongPayClient } from "@/api/kienlong_pay_http_client";
import { ErrorModel } from "@/models/ErrorModel";
import { Languages } from "@/models/Languages";
import { ShopSettingModel } from "@/models/ShopModel";
import { ShopSettingRepository } from "../ShopSettingRepository";

export class ShopSettingRepositoryImp implements ShopSettingRepository {
  private client: KienlongPayClient;
  constructor(kienlongPayClient: KienlongPayClient) {
    this.client = kienlongPayClient;
  }

  public async getShop(): Promise<ShopSettingModel> {
    try {
      let resp = await this.client.shopSettingController.getShopSetting();
      if (resp.data.code === 0) {
        return mapShopSettingModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async updateShop({
    backgroundColor,
    language,
    logo,
    payments,
    shopName,
  }: {
    backgroundColor?: string;
    language?: Languages;
    logo?: string;
    payments?: number[];
    shopName?: string;
  }): Promise<ShopSettingModel> {
    try {
      let resp = await this.client.shopSettingController.updateShopSetting({
        updateShopSettingRequest: {
          backgroundColor: backgroundColor,
          defaultLanguage: language,
          logo: logo,
          paymentIds: payments,
          shopName: shopName,
        },
      });
      if (resp.data.code === 0) {
        return mapShopSettingModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
