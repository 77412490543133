import { SupportResponse } from "@/api/generated-sources/kienlong-pay";
import { SupportContentType, SupportModel } from "@/models/SupportModel";

export function mapSupportModel(
  dto: SupportResponse | undefined,
): SupportModel {
  return {
    content:
      SupportContentType[
        dto?.content ?? ("CONTENT_1" as keyof typeof SupportContentType)
      ],
    createdAt: dto?.createdAt ?? "",
    createdBy: dto?.createdBy ?? "",
    description: dto?.description ?? "",
    email: dto?.email ?? "",
    id: dto?.id ?? "",
    merchantId: dto?.merchantId ?? "",
    modifiedAt: dto?.modifiedAt ?? "",
    modifiedBy: dto?.modifiedBy ?? "",
    phoneNo: dto?.phoneNo ?? "",
    username: dto?.username ?? "",
  };
}
