import { BankModel } from "@/models/BankModel";
import {
  BankAccountResponse,
  BankResponse,
  LinkAccountInfo,
} from "./../api/generated-sources/kienlong-pay/api";
import { BankAccountDto } from "@/api/generated-sources/kienlong-pay";
import {
  BankAccountType,
  BankAccountModel,
  LinkAccountInfoModel,
} from "@/models/BankAccountModel";
import { PayboxBankAccountModel } from "@/models/PayboxBankAccountModel";

export function mapBankAccountModal(
  dto: BankAccountDto | BankAccountResponse | undefined,
): BankAccountModel {
  if (dto && "qrCode" in dto) {
    return {
      accountName: dto?.accountName ?? "",
      accountNo: dto?.accountNumber ?? "",
      bankType:
        BankAccountType[
          (dto?.bankType ?? "UMEE") as keyof typeof BankAccountType
        ],
      id: dto?.id ?? "",
      defaultAccount: false,
      qrCode: dto?.qrCode,
    };
  } else if (dto && "defaultAccount" in dto) {
    return {
      accountName: dto?.accountName ?? "",
      accountNo: dto?.accountNo ?? "",
      bankType:
        BankAccountType[
          (dto?.bankType ?? "UMEE") as keyof typeof BankAccountType
        ],
      id: dto?.id ?? "",
      defaultAccount: dto.defaultAccount ?? false,
      qrCode: undefined,
      defaultFeeAccount: dto.defaultFeeAccount ?? false,
    };
  }
  return {
    accountName: "",
    accountNo: "",
    bankType: BankAccountType.KLB,
    id: "",
    defaultAccount: false,
    qrCode: undefined,
  };
}
export function mapPayBoxBankAccount(
  dto: BankAccountResponse | undefined,
): PayboxBankAccountModel {
  return {
    accountName: dto?.accountName ?? "",
    accountNumber: dto?.accountNumber ?? "",
    bankType:
      BankAccountType[
        (dto?.bankType ?? "UMEE") as keyof typeof BankAccountType
      ],
    id: dto?.id ?? "",
    qrCode: dto?.qrCode ?? "",
  };
}

export function mapBankAccountIdenticaltionModel(
  dto: LinkAccountInfo | undefined,
): LinkAccountInfoModel {
  return {
    bankAccountName: dto?.bankAccountName ?? "",
    bankAccountNo: dto?.bankAccountNo ?? "",
    branch:
      BankAccountType[(dto?.branch ?? "KLB") as keyof typeof BankAccountType],
  };
}

export function mapBankModel(dto: BankResponse | undefined): BankModel {
  return {
    benId: dto?.benId ?? "",
    citad: dto?.citad ?? "",
    commonName: dto?.commonName ?? "",
    id: dto?.id ?? 0,
    logo: dto?.logo ?? "",
    name: dto?.name ?? "",
    napasCode: dto?.napasCode ?? "",
    napasSupported: dto?.napasSupported ?? false,
    shortName: dto?.shortName ?? "",
    swift: dto?.swift ?? "",
    vietQrSupported: dto?.vietQrSupported ?? false,
    icon: dto?.icon ?? "",
    code: dto?.code ?? "",
  };
}
