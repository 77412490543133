import {
  EmployeePaidInfo,
  TotalStatisticResponse,
} from "@/api/generated-sources/flexsalary";
import {
  EmployeePaidInfoModel,
  TotalStatisticResponseModel,
} from "../models/StatisticModel";

export function mapTotalStatisticResponseModel(
  dto?: TotalStatisticResponse,
): TotalStatisticResponseModel {
  return {
    numberOfEmployees: dto?.numberOfEmployees,
    percentAdvanceRequestAmount: dto?.percentAdvanceRequestAmount,
    percentTotalPaidAmount: dto?.percentTotalPaidAmount,
    totalAdvanceRequestAmount: dto?.totalAdvanceRequestAmount,
    totalPaidAmount: dto?.totalPaidAmount,
  };
}

export function mapGetPaidListModel(
  dto?: EmployeePaidInfo,
): EmployeePaidInfoModel {
  return {
    employeeCode: dto?.employeeCode,
    employeeId: dto?.employeeId,
    employeeName: dto?.employeeName,
    employeePosition: dto?.employeePosition,
    salaryPeriodName: dto?.salaryPeriodName,
    totalAdvancedAmount: dto?.totalAdvancedAmount,
    totalPaidAmount: dto?.totalPaidAmount,
  };
}
