import { IdentifyInfoDto } from "@/api/generated-sources/kienlong-pay";
import { IdentifyInfoModel } from "@/models/IdentifyInfoModel";

export function mapIdenticalInfoModal(
  dto: IdentifyInfoDto | undefined,
): IdentifyInfoModel {
  return {
    address: dto?.address ?? "",
    backUrl: dto?.backUrl ?? "",
    frontUrl: dto?.frontUrl ?? "",
    id: dto?.id ?? 0,
    issueBy: dto?.issueBy ?? "",
    issueDate: dto?.issueDate ?? "",
    name: dto?.name ?? "",
    national: dto?.national ?? "",
    no: dto?.no ?? "",
    sex: dto?.sex ?? "",
    type: dto?.type ?? "",
  };
}
