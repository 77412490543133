import {
  Configuration,
  ConfigurationParameters,
  EmployeeApi,
  EnterpriseApi,
  EnterpriseConfigApi,
  LogworkApi,
  SalaryAdvanceRequestApi,
  SalaryAdvanceTypeApi,
  SalaryPeriodApi,
  StatisticApi,
} from "./generated-sources/flexsalary";
import { SalaryAdvanceRequestRepository } from "./flexsalary-repositories/SalaryAdvanceRequestReposiroty";
import { SalaryPeriodRepository } from "@/api/flexsalary-repositories/SalaryPeriodRepository.ts";
import { SalaryPeriodRepositoryImp } from "@/api/flexsalary-repositories/implements/SalaryPeriodRepositoryImp.ts";
import { EnterpriseConfigRepository } from "./flexsalary-repositories/EnterpriseConfigRepository";
import { EnterpriseConfigRepositoryImp } from "./flexsalary-repositories/implements/EnterpriseConfigRepositoryImp";
import { LogworkRepository } from "./flexsalary-repositories/LogworkRepository";
import { EmployeeRepository } from "./flexsalary-repositories/EmployeeRepository";
import { EmployeeRepositoryImp } from "./flexsalary-repositories/implements/EmployeeRepositoryImp";
import { LogworkRepositoryImp } from "./flexsalary-repositories/implements/LogworkRepositoryImp";
import { SalaryAdvanceTypeRepositoryImp } from "./flexsalary-repositories/implements/SalaryAdvanceTypeRepositoryImp";
import { SalaryAdvanceTypeRepository } from "./flexsalary-repositories/SalaryAdvanceTypeRepository";
import { SalaryAdvanceRequestReposirotyImp } from "./flexsalary-repositories/implements/SalaryAdvanceRequestReposirotyImp";
import { EnterpriseRepository } from "./flexsalary-repositories/EnterpriseRepository";
import { EnterpriseRepositoryImp } from "./flexsalary-repositories/implements/EnterpriseRepositoryImp";
import { StatisticRepository } from "./flexsalary-repositories/StatisticRepository";
import { StatisticRepositoryImp } from "./flexsalary-repositories/implements/StatisticRepositoryImp";

export const flexSalaryConfig: ConfigurationParameters = {
  basePath: "https://api.dev.kienlongbank.co/ewa-middleware",
};

export class FlexSalaryClient {
  private config: Configuration;
  public readonly salaryAdvanceRequest: SalaryAdvanceRequestApi;
  public readonly salaryPeriod: SalaryPeriodApi;
  public readonly enterpriseConfig: EnterpriseConfigApi;
  public readonly logwork: LogworkApi;
  public readonly employee: EmployeeApi;
  public readonly salaryAdvanceType: SalaryAdvanceTypeApi;
  public readonly enterPrice: EnterpriseApi;
  public readonly statistic: StatisticApi;

  constructor(cf: Configuration) {
    this.config = cf;
    this.salaryAdvanceRequest = new SalaryAdvanceRequestApi(cf, cf.basePath);
    this.salaryPeriod = new SalaryPeriodApi(cf, cf.basePath);
    this.enterpriseConfig = new EnterpriseConfigApi(cf, cf.basePath);
    this.logwork = new LogworkApi(cf, cf.basePath);
    this.employee = new EmployeeApi(cf, cf.basePath);
    this.salaryAdvanceType = new SalaryAdvanceTypeApi(cf, cf.basePath);
    this.enterPrice = new EnterpriseApi(cf, cf.basePath);
    this.statistic = new StatisticApi(cf, cf.basePath);
  }
}

export class FlexSalaryHttpClient {
  static flexSalaryClient: FlexSalaryClient;
  public readonly salaryAdvanceRequest: SalaryAdvanceRequestRepository;
  public readonly salaryPeriod: SalaryPeriodRepository;
  public readonly enterpiseConfig: EnterpriseConfigRepository;
  public readonly logwork: LogworkRepository;
  public readonly employee: EmployeeRepository;
  public readonly salaryAdvanceType: SalaryAdvanceTypeRepository;
  public readonly enterPrice: EnterpriseRepository;
  public readonly statistic: StatisticRepository;

  constructor(basePath?: string) {
    if (basePath) {
      flexSalaryConfig.basePath = basePath;
    }
    FlexSalaryHttpClient.flexSalaryClient = new FlexSalaryClient(
      new Configuration(flexSalaryConfig),
    );
    this.salaryAdvanceType = new SalaryAdvanceTypeRepositoryImp(
      FlexSalaryHttpClient.flexSalaryClient,
    );
    this.salaryAdvanceRequest = new SalaryAdvanceRequestReposirotyImp(
      FlexSalaryHttpClient.flexSalaryClient,
    );
    this.salaryPeriod = new SalaryPeriodRepositoryImp(
      FlexSalaryHttpClient.flexSalaryClient,
    );
    this.enterPrice = new EnterpriseRepositoryImp(
      FlexSalaryHttpClient.flexSalaryClient,
    );
    this.enterpiseConfig = new EnterpriseConfigRepositoryImp(
      FlexSalaryHttpClient.flexSalaryClient,
    );

    this.logwork = new LogworkRepositoryImp(
      FlexSalaryHttpClient.flexSalaryClient,
    );
    this.employee = new EmployeeRepositoryImp(
      FlexSalaryHttpClient.flexSalaryClient,
    );
    this.statistic = new StatisticRepositoryImp(
      FlexSalaryHttpClient.flexSalaryClient,
    );
  }
}
