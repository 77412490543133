import { CardCustom } from "@/components/card-custom/CardCustom";
import { useAppBloc } from "@/contexts/app";
import ErrorPage403 from "@/pages/40x/403";
import { SettingOption } from "@/pages/setting/components/SettingOption";
import { iconToolTransferMoney } from "@/services/assets";
import { NavlinkPath } from "@/services/router-config";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "../style.css";

const PayGateTools = () => {
  const { appBloc } = useAppBloc();
  const { t } = useTranslation();
  const navigate = useNavigate();

  let checkRole = appBloc.session.routersAndRoles.filter((d) =>
    d.routerPaths.includes(NavlinkPath.payGateTools),
  ).length;

  if (!checkRole) {
    return <ErrorPage403 content={`${t("notification.access_denied")}`} />;
  }

  return (
    <CardCustom
      style={{
        padding: "0px",
        gap: "0px",
        width: "100%",
      }}
    >
      {/* heade */}
      <div className="column webhooks__card__header">
        <span className="text-base-medium">Tools</span>
        <span
          className="text-sm"
          onClick={() => {
            if (!appBloc.storage.testMode) {
              appBloc.storage.testMode = true;
              setTimeout(() => {
                window.location.reload();
              }, 300);
            }
          }}
        >
          {t("technical-integration.just-one-env")}
          <span
            className="fontWeight--500"
            style={{ color: "var(--color-global-brand-90)" }}
          >
            Test Mode
          </span>
        </span>
      </div>

      {/* body */}
      <div className="payment-integrate__tools__list-account">
        <SettingOption
          content={t("technical-integration.create-transfer-money-test")}
          title={t("technical-integration.transfer-money")}
          icon={<img alt="" src={iconToolTransferMoney} />}
          onTap={() => {
            navigate(NavlinkPath.payGateToolsTransferMoney, {
              relative: "route",
            });
          }}
        />
      </div>
    </CardCustom>
  );
};

export default PayGateTools;
