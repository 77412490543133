import axios, { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { kienlongPayConfig } from "@/api/kienlong_pay_http_client";
import { ErrorModel } from "@/models/ErrorModel";
import { payboxConfig } from "@/api/paybox_http_client";
import { LoginStatus } from "@/models/LoginStatus";
import { AppBloc } from "./base-bloc";
import { Environment } from "./environment";
import { flexSalaryConfig } from "@/api/flexsalary_http_client";
export function onResponse(res: AxiosResponse) {
  return Promise.resolve(res);
}

export function onRequest(req: InternalAxiosRequestConfig) {
  if (req.headers !== undefined) {
    req.headers["Accept-Language"] = new AppBloc().storage.language;
    if (
      (req.url ?? "").startsWith(Environment.hostApiStorage + "/media") ||
      (req.url ?? "").startsWith(Environment.hostApiStorageTestMode + "/media")
    ) {
      if (kienlongPayConfig.accessToken !== undefined) {
        req.headers["Authorization"] = undefined;
      }
    } else if (
      (req.url ?? "").startsWith(kienlongPayConfig.basePath ?? "") ||
      (req.url ?? "").startsWith(Environment.hostApiKlbPay)
    ) {
      if (kienlongPayConfig.accessToken !== undefined) {
        req.headers["Authorization"] =
          `Bearer ${kienlongPayConfig.accessToken}`;
      }
    }

    // orther api host
    // ---------------
    else if ((req.url ?? "").startsWith(payboxConfig.basePath ?? "")) {
      if (kienlongPayConfig.accessToken !== undefined) {
        req.headers["Authorization"] =
          `Bearer ${kienlongPayConfig.accessToken}`;
      }
    } else if (
      (req.url ?? "").startsWith(flexSalaryConfig.basePath ?? "") &&
      kienlongPayConfig.accessToken !== undefined
    ) {
      req.headers["Authorization"] = `Bearer ${kienlongPayConfig.accessToken}`;
    }
  }
  return Promise.resolve(req);
}
export async function onError(e: any) {
  console.error(">>>ERROR", e);
  const { config, code } = e;
  if (code === "ERR_NETWORK") e.message = `Vui lòng kiểm tra lại kết nối mạng`;
  let originalReq = { ...config };

  const appBloc = new AppBloc();
  switch (e.response.status.toString()) {
    case "401":
      try {
        if (
          appBloc.storage.refreshToken.length > 0 &&
          appBloc.storage.countRefreshToken < 20
        ) {
          kienlongPayConfig.accessToken = undefined;
          appBloc.storage.countRefreshToken =
            appBloc.storage.countRefreshToken + 1;
          let retRefresh =
            await appBloc.repository.kienlongPayHttpClient.authRepository.refreshToken(
              { refreshToken: appBloc.storage.refreshToken },
            );
          if (retRefresh.refreshToken && retRefresh.token) {
            appBloc.storage.accessToken = retRefresh.token;
            appBloc.storage.refreshToken = retRefresh.refreshToken;
            kienlongPayConfig.accessToken = retRefresh.token;
            if (originalReq) {
              originalReq.headers.Authorization = `Bearer ${retRefresh.token}`;
              setTimeout(() => {
                appBloc.storage.countRefreshToken = 0;
              }, 20000);
              return Promise.resolve(axios(originalReq));
            }
          }
        }
        throw new ErrorModel({ code: -1, message: "Expire Refresh token" });
      } catch (error) {
        if ((error as ErrorModel).code.toString() === "122") {
          appBloc.session.error.next(
            new ErrorModel({ code: 122, message: e.response.data.message }),
          );
        } else if ((error as ErrorModel).code.toString() === "10") {
          appBloc.session.error.next(
            new ErrorModel({ code: 10, message: e.response.data.message }),
          );
        } else {
          appBloc.session.error.next(
            new ErrorModel({
              code: e.response.status,
              message: e.response.data.message,
            }),
          );
        }
        appBloc.session.loginStatus.next(LoginStatus.Expired);
        return Promise.reject(e);
      }
    case "403":
      appBloc.session.error.next(
        new ErrorModel({
          code: e.response.data.code,
          message: e.response.data.message,
        }),
      );
      return Promise.reject(
        new ErrorModel({
          code: e.response.data.code,
          message: e.response.data.message,
        }),
      );
  }
  if (e.request.responseURL.startsWith(Environment.hostApiFlexSalary)) {
    appBloc.session.error.next(
      new ErrorModel({
        code: e.response.data.code,
        message: e.response.data.message,
      }),
    );
    return Promise.reject(
      new ErrorModel({
        code: e.response.data.code,
        message: e.response.data.message,
      }),
    );
  } else {
    appBloc.session.error.next(
      new ErrorModel({ code: e.response.status, message: e.message }),
    );
    return Promise.reject(e);
  }
}
