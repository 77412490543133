export interface AuditDataModel {
  userId: string;
  userName: string;
  auditTime: number;
  changeType: AuditDataChangeType;
  changes: Array<AuditRecordModel>;
}

export enum AuditDataChangeType {
  CREATED = "CREATED",
  UPDATED = "UPDATED",
  DELETED = "DELETED",
}

export interface AuditRecordModel {
  key: string;
  newValue: string;
}
