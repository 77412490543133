import KienlongBankLoader from "@/components/loader/KienlongBankLoader";

export const KienlongPageLoading = () => {
  return (
    <div
      className="column"
      style={{
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <KienlongBankLoader height={120} width={120} />
    </div>
  );
};
