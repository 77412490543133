import { KienlongPayClient } from "@/api/kienlong_pay_http_client";
import { ErrorModel } from "@/models/ErrorModel";
import { AuditRepository } from "../AuditRepository";

export class AuditRepositoryImp implements AuditRepository {
  private client: KienlongPayClient;
  constructor(kienlongPayClient: KienlongPayClient) {
    this.client = kienlongPayClient;
  }

  public async getVersion(
    module: "RESPONSE_MESSAGE" | "SYSTEM_PARAMETER",
  ): Promise<number> {
    try {
      let resp = await this.client.auditController.getCurrentVersion({
        module: module,
      });
      if (resp.data.code === 0) {
        return resp.data.data?.version ?? -1;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
