import { ShopSettingResponse } from "@/api/generated-sources/kienlong-pay";
import { Languages } from "@/models/Languages";
import { ShopSettingModel } from "@/models/ShopModel";
import { mapPaymentMethodInfoModel } from "./payment_adapter";

export function mapShopSettingModel(
  dto: ShopSettingResponse | undefined,
): ShopSettingModel {
  return {
    backgroundColor: dto?.backgroundColor ?? "#000000",
    defaultLanguage:
      Languages[dto?.defaultLanguage ?? ("VI" as keyof typeof Languages)],
    id: dto?.id ?? "",
    logo: dto?.logo ?? "",
    payments: dto?.payments?.map((d) => mapPaymentMethodInfoModel(d)) ?? [],
    shopName: dto?.shopName ?? "",
  };
}
