import { TabBarCustomer } from "@/components/tab-bar-custom/TabBarCustom";
import { useAppBloc } from "@/contexts/app";
import { useCallback, useEffect, useLayoutEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { NavlinkPath } from "@/services/router-config";

const PayGatePage: React.FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { appBloc } = useAppBloc();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const changeTab = useCallback(
    (idx: number) => {
      switch (idx) {
        case 0:
          return navigate(NavlinkPath.payGatePayment);
        case 1:
          return navigate(NavlinkPath.payGateWebhooks);
        case 2:
          return navigate(NavlinkPath.payGateTools);
      }
    },
    [navigate],
  );

  const Breadcrumb = useMemo(() => {
    let idx = 0;
    let title = "";
    if (location.pathname.startsWith(NavlinkPath.payGatePayment)) {
      idx = 0;
      title = t("technical-integration.payment-integration");
    } else if (location.pathname.startsWith(NavlinkPath.payGateWebhooks)) {
      idx = 1;
      title = "Webhooks";
    } else if (location.pathname.startsWith(NavlinkPath.payGateTools)) {
      idx = 2;
      title = "Tools";
    }

    return (
      <div
        className="column"
        style={{
          width: "100%",
          gap: "16px",
          alignItems: "flex-start",
        }}
      >
        <span
          className="fontSize--20 lineHeight--28 fontWeight--500"
          style={{
            color: "var(--color-global-gray-100)",
          }}
        >
          {title}
        </span>
        <TabBarCustomer
          data={[
            <div
              className="column"
              key="tabs-1"
              style={{
                padding: "8px 12px 16px 12px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span className="fontSize--14 lineHeight--20 fontWeight--500">
                {t("technical-integration.payment-integration")}
              </span>
            </div>,
            <div
              className="column"
              key="tabs-2"
              style={{
                padding: "8px 12px 16px 12px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span className="fontSize--14 lineHeight--20 fontWeight--500">
                Webhooks
              </span>
            </div>,
            <div
              className="column"
              key="tabs-3"
              style={{
                padding: "8px 12px 16px 12px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span className="fontSize--14 lineHeight--20 fontWeight--500">
                Tools
              </span>
            </div>,
          ]}
          autoNext
          defaultIndex={idx}
          onChange={(v) => {
            changeTab(v);
          }}
        />
      </div>
    );
  }, [changeTab, location.pathname, t]);

  useEffect(() => {
    // if (appBloc.session.error.value?.code.toString() !== "10") {
    //   appBloc.session.breadcrumb.next(Breadcrumb);
    // }

    appBloc.session.breadcrumb.next(Breadcrumb);

    return () => {
      appBloc.session.breadcrumb.next(null);
    };
  }, [Breadcrumb]);

  // if (appBloc.session.error.value?.code.toString() === "10") {
  //   appBloc.session.breadcrumb.next(null);
  //   return <ErrorPage403 content={`${t("notification.access_denied")}`} />;
  // }

  return (
    <div
      className="column"
      style={{
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {children}
    </div>
  );
};

export default PayGatePage;
