import { SalaryPeriodInfoModel } from "@/api/flexsalary-repositories/models/SalaryPeriodInfoModel.ts";
import { SalaryPeriodInfo } from "@/api/generated-sources/flexsalary";

export function mapSalaryPeriodInfoModel(
  dto?: SalaryPeriodInfo,
): SalaryPeriodInfoModel {
  return {
    id: dto?.id ?? "",
    name: dto?.name ?? "",
    endDate: dto?.endDate ?? "",
    startDate: dto?.startDate ?? "",
    workingDays: dto?.workingDays ?? -1,
  };
}
