import { PayBoxTransactionType } from "@/models/PayBoxTransactionModel";
import { TransactionResponse } from "@/api/generated-sources/kienlong-pay";
import { BankAccountType } from "@/models/BankAccountModel";
import { PaymentType } from "@/models/PaylinkModel";
import {
  TransactionResponseModel,
  TransactionStatusType,
} from "@/models/TransactionModel";

export function mapTransacionResponseModel(
  dto: TransactionResponse | undefined,
): TransactionResponseModel {
  return {
    amount: dto?.amount ?? 0,
    completeTime: dto?.completeTime ?? "",
    createDateTime: dto?.createDateTime ?? "",
    depositTime: dto?.depositTime ?? "",
    description: dto?.description ?? "",
    id: dto?.id ?? "",
    paymentType:
      PaymentType[(dto?.paymentType ?? "VIET_QR") as keyof typeof PaymentType],
    refTransactionId: dto?.refTransactionId ?? "",
    status:
      TransactionStatusType[
        (dto?.status ?? "NONE") as keyof typeof TransactionStatusType
      ],
    virtualAccount: dto?.virtualAccount ?? "",
    bankType: BankAccountType[dto?.bankType as keyof typeof BankAccountType],
    callbackAfter: dto?.callbackAfter ?? 0,
    failUrl: dto?.failUrl ?? "",
    successUrl: dto?.successUrl ?? "",
    payLinkCode: dto?.payLinkCode ?? "",
    customerName: dto?.customerName ?? "",
    customerPhone: dto?.customerPhone ?? "",
    merchantCode: dto?.merchantCode ?? "",
    merchantId: dto?.merchantId ?? "",
    merchantName: dto?.merchantName ?? "",
    payboxTransactionType:
      PayBoxTransactionType[
        (dto?.payboxTransactionType ??
          "UMEE") as keyof typeof PayBoxTransactionType
      ],
    customerAddress: dto?.customerAddress ?? "",
    customerEmail: dto?.customerEmail ?? "",
    txnNumber: dto?.txnNumber ?? "",
    accountName: dto?.accountName ?? "",
    accountNo: dto?.accountNo ?? "",
    forControl: dto?.forControl ?? false,
    interBankTrace: dto?.interBankTrace ?? "",
    payPageCode: dto?.payPageCode ?? "",
    actualAmount: dto?.actualAmount ?? 0,
    chargeAmount: dto?.chargeAmount ?? 0,
    vatChargeAmount: dto?.vatChargeAmount ?? 0,
  };
}
