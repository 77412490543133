export enum QrLoginStatus {
  PENDING = "PENDING",
  INPUT_DATA = "INPUT_DATA",
  FAILED = "FAILED",
  APPROVE = "APPROVE",
  USED = "USED",
}
export enum QrUserInfoStatus {
  CREATED = "CREATED",
  UPDATE_PASSWORD = "UPDATE_PASSWORD",
  SUBMIT_INFO = "SUBMIT_INFO",
  VERIFIED_OTP = "VERIFIED_OTP",
}

export interface QrLoginModel {
  sessionId: string;
  sessionKey: string;
  timeout: number;
  dateTime: string;
  expireTime: string;
  location: string;
  deviceName: string;
  operatingSystem: string;
  phone: string;
  fullName: string;
  userAvatar: string;
  merchantName: string;
  merchantCode: string;
  merchantAvatar: string;
  status: QrLoginStatus;
  qrCode: string;
}

export interface QrLoginInfoModel {
  sessionId: string;
  sessionKey: string;
  timeout: number;
  dateTime: string;
  expireTime: string;
  location: string;
  deviceName: string;
  operatingSystem: string;
  phone: string;
  fullName: string;
  userAvatar: string;
  merchantName: string;
  merchantCode: string;
  merchantAvatar: string;
  status: QrLoginStatus;
  isNewAccount: boolean;
}

export interface QrUserInfoModel {
  sessionId: string;
  sessionKey: string;
  userId: string;
  phoneNumber: string;
  idCardType: string;
  idCardNo: string;
  fullName: string;
  birthday: string;
  permanentAddress: string;
  fullAddress: string;
  gender: string;
  idCardIssueBy: string;
  idCardIssueDate: string;
  branchCode: string;
  faceIdUrl: string;
  idCardFrontSideUrl: string;
  idCardBackSideUrl: string;
  avatarUrl: string;
  cifNo: string;
  status: QrUserInfoStatus;
}
