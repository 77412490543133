import { useEffect, useId, useState } from "react";
import "./input-toggle.css";

export const InputToggle = ({
  defaultValue,
  onChange,
  onColor = "#00bc3c",
  offColor = "#d6dff6",
  isLock = false,
}: {
  defaultValue: boolean;
  onChange: (v: boolean) => void;
  onColor?: string;
  offColor?: string;
  isLock?: boolean;
}) => {
  const id = useId();
  const [_value, _setValue] = useState(defaultValue);

  useEffect(() => {
    _setValue(defaultValue);
  }, [defaultValue]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        pointerEvents: isLock ? "none" : "auto",
      }}
    >
      <input
        checked={_value}
        onChange={(e) => {
          onChange(e.currentTarget.checked ?? _value);
          _setValue(e.currentTarget.checked ?? _value);
        }}
        id={id}
        type={"checkbox"}
        className="input-togle"
      />
      <label
        style={{ background: _value ? onColor : offColor }}
        htmlFor={id}
        className="input-toggle-label"
      ></label>
    </div>
  );
};
