import {
  CustomerDto,
  CustomerGroupDto,
  CustomerInfo,
  CustomerInfoForm,
} from "@/api/generated-sources/kienlong-pay";
import {
  CustomerInfoFormModel,
  CustomerInfoModel,
  CustomerModel,
} from "@/models/CustomersModel";
import { GroupCustomerModel } from "@/models/GroupCustomerModel";

export function mapCustomerInfoFormModal(
  dto: CustomerInfoForm | undefined,
): CustomerInfoFormModel {
  return {
    fillAddress: dto?.fillAddress ?? false,
    fillEmail: dto?.fillEmail ?? false,
    fillName: dto?.fillName ?? false,
    fillPhone: dto?.fillPhone ?? false,
    id: dto?.id ?? "",
  };
}

export function mapCustomermModal(dto: CustomerDto | undefined): CustomerModel {
  return {
    address: dto?.address ?? "",
    customerGroupId: dto?.customerGroupId ?? "",
    customerGroupName: dto?.customerGroupName ?? "",
    email: dto?.email ?? "",
    fullName: dto?.fullName ?? "",
    id: dto?.id ?? "",
    phone: dto?.phone ?? "",
    code: dto?.code ?? "",
  };
}

export function mapCustomerInfoModal(
  dto: CustomerInfo | undefined,
): CustomerInfoModel {
  return {
    address: dto?.address ?? "",
    email: dto?.email ?? "",
    fullName: dto?.fullName ?? "",
    phone: dto?.phone ?? "",
  };
}

export function mapGroupCustomerModel(
  dto: CustomerGroupDto | undefined,
): GroupCustomerModel {
  return {
    name: dto?.name ?? "",
    totalCustomer: dto?.totalCustomer ?? 0,
    id: dto?.id ?? "",
  };
}
