import { FlexSalaryClient } from "@/api/flexsalary_http_client";
import { ErrorModel } from "@/models/ErrorModel";
import { EmployeeRepository } from "../EmployeeRepository";
import {
  mapEmployeeDetailModel,
  mapEmployeeInfoModel,
  mapEmployeeSalaryAdvanceModel,
  mapUploadEmployeeRecordModel,
  mapUploadEmployeeRecordWithStatusModel,
} from "../adapters/employee-adapter";
import {
  EmployeeDetailModel,
  EmployeeInfoModel,
  EmployeeRequestModel,
  EmployeeSalaryAdvanceModel,
  SalaryAdvanceByEmployeeInfoStatus,
  UploadEmployeeRecordModel,
  UploadEmployeeRecordWithStatusModel,
} from "../models/EmployeeModel";

export class EmployeeRepositoryImp implements EmployeeRepository {
  private _c: FlexSalaryClient;
  constructor(flexsalaryClient: FlexSalaryClient) {
    this._c = flexsalaryClient;
  }

  public async addEmployee({
    activated,
    code,
    name,
    phoneNumber,
    salaryAdvanceTypeId,
    birthDate,
    email,
    idCardNumber,
    baseAmount,
    salaryAccount,
    position,
    salaryAdvanceTypeName,
  }: {
    code: string;
    name: string;
    birthDate?: string;
    idCardNumber?: string;
    phoneNumber: string;
    email?: string;
    salaryAdvanceTypeId: string;
    baseAmount: number;
    activated: boolean;
    salaryAccount: string;
    salaryAdvanceTypeName?: string;
    position: string;
  }): Promise<string> {
    try {
      let resp = await this._c.employee.addEmployee({
        employeeRequest: {
          activated: activated,
          code: code,
          name: name,
          phoneNumber: phoneNumber,
          salaryAdvanceTypeId: salaryAdvanceTypeId,
          birthDate: birthDate,
          email: email,
          idCardNumber: idCardNumber,
          baseAmount: baseAmount,
          salaryAccount: salaryAccount,
          position: position,
          salaryAdvanceTypeName: salaryAdvanceTypeName,
        },
      });
      if (resp.data.code === 20000) {
        return resp.data.data?.id ?? "";
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async updateSalaryAdvanceType({
    employeeIds,
    salaryAdvanceTypeId,
  }: {
    employeeIds: Array<string>;
    salaryAdvanceTypeId: string;
  }): Promise<object | undefined> {
    try {
      let resp = await this._c.employee.bulkUpdateSalaryAdvanceType({
        bulkUpdateAdvanceTypeRequest: {
          employeeIds: employeeIds,
          salaryAdvanceTypeId: salaryAdvanceTypeId,
        },
      });

      return resp.data.data;
    } catch (error) {
      throw error;
    }
  }

  public async editEmployee({
    activated,
    code,
    name,
    phoneNumber,
    salaryAdvanceTypeId,
    birthDate,
    email,
    idCardNumber,
    id,
    baseAmount,
    salaryAccount,
    position,
    salaryAdvanceTypeName,
  }: {
    code: string;
    name: string;
    birthDate?: string;
    idCardNumber?: string;
    phoneNumber: string;
    email?: string;
    salaryAdvanceTypeId: string;
    baseAmount: number;
    activated: boolean;
    salaryAccount: string;
    id: string;
    salaryAdvanceTypeName?: string;
    position: string;
  }): Promise<void> {
    try {
      let resp = await this._c.employee.editEmployee({
        employeeRequest: {
          activated: activated,
          code: code,
          name: name,
          phoneNumber: phoneNumber,
          salaryAdvanceTypeId: salaryAdvanceTypeId,
          birthDate: birthDate,
          email: email,
          baseAmount: baseAmount,
          salaryAccount: salaryAccount,
          idCardNumber: idCardNumber,
          position: position,
          salaryAdvanceTypeName: salaryAdvanceTypeName,
        },
        id: id,
      });
      if (resp.data.code === 20000) {
        return;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async detailEmployee({
    id,
  }: {
    id: string;
  }): Promise<EmployeeDetailModel> {
    try {
      let resp = await this._c.employee.getEmployee({
        id: id,
      });
      if (resp.data.code === 20000) {
        return mapEmployeeDetailModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async listEmployee({
    page = 0,
    size = 10,
    keyword,
    salaryAdvanceTypeId,
    startDate,
    endDate,
  }: {
    keyword?: string;
    page?: number;
    size?: number;
    salaryAdvanceTypeId?: string;
    startDate?: number;
    endDate?: number;
  }): Promise<{
    page: number;
    size: number;
    totalElements: number;
    totalPages: number;
    data: Array<EmployeeInfoModel>;
  }> {
    try {
      let resp = await this._c.employee.getListEmployee({
        page: page,
        size: size,
        endDate: endDate,
        keyword: keyword,
        salaryAdvanceTypeId: salaryAdvanceTypeId,
        startDate: startDate,
      });
      if (resp.data.code === 20000) {
        return {
          page: resp.data.data?.page ?? 0,
          size: resp.data.data?.size ?? 0,
          totalElements: resp.data.data?.totalElements ?? 0,
          totalPages: resp.data.data?.totalPages ?? 0,
          data: resp.data.data?.data?.map((d) => mapEmployeeInfoModel(d)) ?? [],
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async historySalaryAdvance({
    id,
    page,
    size,
    status,
    fromDate,
    toDate,
    keyword,
  }: {
    id: string;
    page: number;
    size: number;
    status?: SalaryAdvanceByEmployeeInfoStatus;
    fromDate?: number;
    toDate?: number;
    keyword?: string;
  }): Promise<{
    page: number;
    size: number;
    totalElements: number;
    totalPages: number;
    data: Array<EmployeeSalaryAdvanceModel>;
  }> {
    try {
      let resp = await this._c.employee.getSalaryAdvanceListByEmployee({
        page: page,
        size: size,
        id: id,
        keyword: keyword,
        status: status,
        fromDate: fromDate,
        toDate: toDate,
      });
      if (resp.data.code === 20000) {
        return {
          page: resp.data.data?.page ?? 0,
          size: resp.data.data?.size ?? 0,
          totalElements: resp.data.data?.totalElements ?? 0,
          totalPages: resp.data.data?.totalPages ?? 0,
          data:
            resp.data.data?.data?.map((d) =>
              mapEmployeeSalaryAdvanceModel(d),
            ) ?? [],
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
  public async bulkAddEmployee({
    data,
  }: {
    data: Array<EmployeeRequestModel>;
  }): Promise<object | undefined> {
    try {
      let resp = await this._c.employee.bulkAddEmployee({
        bulkRequestEmployeeRequest: {
          data: data,
        },
      });
      if (resp.data.code === 20000) {
        return resp.data;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async generateTemplateFile(): Promise<File> {
    try {
      let resp = await this._c.employee.generateTemplateFile1(
        {},
        {
          responseType: "arraybuffer",
        },
      );
      return resp.data as File;
    } catch (error) {
      throw error;
    }
  }

  public async uploadFile({ file }: { file: File }): Promise<{
    validItems: Array<UploadEmployeeRecordModel>;
    invalidItems: Array<UploadEmployeeRecordWithStatusModel>;
  }> {
    try {
      let resp = await this._c.employee.uploadEmployeeFile({
        file: file,
      });
      if (resp.data.code === 20000) {
        return {
          validItems:
            resp.data.data?.validItems?.map((d) =>
              mapUploadEmployeeRecordModel(d),
            ) ?? [],
          invalidItems:
            resp.data.data?.invalidItems?.map((d) =>
              mapUploadEmployeeRecordWithStatusModel(d),
            ) ?? [],
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
