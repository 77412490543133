import { CustomStorage, Storage } from "@/domains/storage";
import { cipherDecryptAES, cipherEncryptAES } from "../utils/hashString";

class WrapLocalStorage extends CustomStorage {
  public get sizeStorage(): number {
    return localStorage.length;
  }

  public getItem({ key }: { key: string }): string {
    return localStorage.getItem(key) ?? "";
  }
  public setItem({ key, value }: { key: string; value: string }): void {
    return localStorage.setItem(key, value);
  }

  public removeItem({ key }: { key: string }) {
    return localStorage.removeItem(key);
  }

  public clearStorage() {
    return localStorage.clear();
  }
}

class WrapSessionStorage extends CustomStorage {
  public get sizeStorage(): number {
    return sessionStorage.length;
  }

  public getItem({ key }: { key: string }): string {
    return sessionStorage.getItem(key) ?? "";
  }
  public setItem({ key, value }: { key: string; value: string }): void {
    return sessionStorage.setItem(key, value);
  }

  public removeItem({ key }: { key: string }) {
    return sessionStorage.removeItem(key);
  }

  public clearStorage() {
    return sessionStorage.clear();
  }
}

enum KeysStorage {
  accessToken = "accessToken",
  refreshToken = "refreshToken",
  isLogin = "isLogin",
  isIdenticatedAccount = "isIdenticatedAccount",
  userInfo = "userInfo",
  isShowWelcomMessage = "isShowWelcomMessage",
  language = "language",
  testMode = "testMode",
  countRefreshToken = "countRefreshToken",
  customMerchantCode = "customMerchantCode",
}

class AppStorage extends Storage {
  private _localStore: WrapLocalStorage;
  private _sessionStore: WrapSessionStorage;
  private _packageName: string = "__Umee-Pay";
  constructor() {
    super();
    this._localStore = new WrapLocalStorage();
    this._sessionStore = new WrapSessionStorage();
  }
  _getKey(key: string): string {
    return `${this._packageName}-${key}`;
  }

  // Merchant Code
  public get customMerchantCode(): string {
    return (
      this._localStore?.getItem({
        key: `${this._getKey(KeysStorage.customMerchantCode)}`,
      }) ?? ""
    );
  }

  public set customMerchantCode(v: string) {
    this._localStore?.setItem({
      key: `${this._getKey(KeysStorage.customMerchantCode)}`,
      value: v,
    });
  }

  // access token
  public get accessToken(): string {
    return (
      this._sessionStore?.getItem({
        key: `${this._getKey(KeysStorage.accessToken)}`,
      }) ?? ""
    );
  }

  public set accessToken(v: string) {
    this._sessionStore?.setItem({
      key: `${this._getKey(KeysStorage.accessToken)}`,
      value: v,
    });
  }

  // refresh token
  public get refreshToken(): string {
    let value = "";
    try {
      value = cipherDecryptAES(
        this._sessionStore?.getItem({
          key: `${this._getKey(KeysStorage.refreshToken)}`,
        }) ?? "",
      );
    } catch (error) {
      value = "";
    }
    return value;
  }

  public set refreshToken(v: string) {
    let value = "";
    try {
      value = cipherEncryptAES(v);
    } catch (error) {
      value = "";
    }
    this._sessionStore?.setItem({
      key: `${this._getKey(KeysStorage.refreshToken)}`,
      value: value,
    });
  }

  // isLogin
  public get isLogin(): boolean {
    return (
      (this._sessionStore?.getItem({
        key: `${this._getKey(KeysStorage.isLogin)}`,
      }) ?? "") === "true"
    );
  }

  public set isLogin(v: boolean) {
    this._sessionStore?.setItem({
      key: `${this._getKey(KeysStorage.isLogin)}`,
      value: v.toString(),
    });
  }

  // isShowWelcomMessage
  public get isShowWelcomMessage(): boolean {
    return (
      (this._sessionStore?.getItem({
        key: `${this._getKey(KeysStorage.isShowWelcomMessage)}`,
      }) ?? "") === "true"
    );
  }

  public set isShowWelcomMessage(v: boolean) {
    this._sessionStore?.setItem({
      key: `${this._getKey(KeysStorage.isShowWelcomMessage)}`,
      value: v.toString(),
    });
  }

  // isMobile
  public get isMobile(): boolean {
    return (
      (this._localStore?.getItem({
        key: `${this._packageName}-isMobile`,
      }) ?? "") === "true"
    );
  }

  public set isMobile(v: boolean) {
    this._localStore?.setItem({
      key: `${this._packageName}-isMobile`,
      value: v.toString(),
    });
  }

  // isRememberLogin
  public get isRememberLogin(): boolean {
    return (
      (this._localStore?.getItem({
        key: `${this._packageName}-isRememberLogin`,
      }) ?? "") === "true"
    );
  }

  public set isRememberLogin(v: boolean) {
    this._localStore?.setItem({
      key: `${this._packageName}-isRememberLogin`,
      value: v.toString(),
    });
  }

  // user info
  public get merchantData(): any {
    let obStr =
      this._localStore?.getItem({
        key: `${this._getKey(KeysStorage.userInfo)}`,
      }) ?? "";
    return obStr === "" ? null : JSON.parse(obStr);
  }

  public set merchantData(v: any) {
    this._localStore?.setItem({
      key: `${this._getKey(KeysStorage.userInfo)}`,
      value: JSON.stringify(v),
    });
  }

  // isIdenticatedAccount
  public get isIdenticatedAccount(): boolean {
    return (
      (this._sessionStore?.getItem({
        key: `${this._getKey(KeysStorage.isIdenticatedAccount)}`,
      }) ?? "") === "true"
    );
  }

  public set isIdenticatedAccount(v: boolean) {
    this._sessionStore?.setItem({
      key: `${this._getKey(KeysStorage.isIdenticatedAccount)}`,
      value: v.toString(),
    });
  }

  // language
  public get language(): string {
    if (
      this._localStore?.getItem({
        key: `${this._getKey(KeysStorage.language)}`,
      }) === ""
    ) {
      return "vi";
    }
    return this._localStore?.getItem({
      key: `${this._getKey(KeysStorage.language)}`,
    });
  }

  public set language(v: string) {
    this._localStore?.setItem({
      key: `${this._getKey(KeysStorage.language)}`,
      value: v,
    });
  }

  // testMode
  public get testMode(): boolean {
    return (
      (this._sessionStore?.getItem({
        key: `${this._getKey(KeysStorage.testMode)}`,
      }) ?? "") === "true"
    );
  }

  public set testMode(v: boolean) {
    this._sessionStore?.setItem({
      key: `${this._getKey(KeysStorage.testMode)}`,
      value: v.toString(),
    });
  }

  // countRefreshToken
  public get countRefreshToken(): number {
    if (
      this._sessionStore?.getItem({
        key: `${this._getKey(KeysStorage.countRefreshToken)}`,
      }) === ""
    ) {
      return 0;
    }
    return parseInt(
      this._sessionStore?.getItem({
        key: `${this._getKey(KeysStorage.countRefreshToken)}`,
      }),
    );
  }

  public set countRefreshToken(v: number) {
    this._sessionStore?.setItem({
      key: `${this._getKey(KeysStorage.countRefreshToken)}`,
      value: v.toString(),
    });
  }

  public clearLocalStorage() {
    let tmp = this.language;
    let tmp2 = this.customMerchantCode;
    this._localStore?.clearStorage();
    this.language = tmp;
    this.customMerchantCode = tmp2;
  }

  public clearSessionStorage() {
    this._sessionStore?.clearStorage();
  }

  // clear all local data
  public clearAll() {
    this.clearSessionStorage();
    this.clearLocalStorage();
  }
}
export { AppStorage };
