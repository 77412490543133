import {
  GetPayLinkBankTransferResponse,
  GetPayLinkNapasResponse,
  GetPayLinkVietQRResponse,
  PayLinkDto,
  PayLinkResponse,
  PayLinkStatusResponse,
} from "@/api/generated-sources/kienlong-pay";
import { BankAccountType } from "@/models/BankAccountModel";
import { CustomerInfoType } from "@/models/CustomersModel";
import {
  PayLinkBankTransferModel,
  PaylinkStatusType,
  PaylinkExpireType,
  PaylinkDetailModel,
  PayLinkVietQRModel,
  PayLinkModel,
  PaylinkNapasInfoModel,
  PaylinkStatusModel,
  PaymentType,
} from "@/models/PaylinkModel";
import {
  mapCustomerInfoFormModal,
  mapCustomermModal,
} from "./customers_adapter";
import { mapShopSettingModel } from "./shop_adapter";

export function mapPaylinkDetailModal(
  dto?: PayLinkResponse | undefined,
): PaylinkDetailModel {
  return {
    amount: dto?.amount ?? 0,
    customerInfoForm: mapCustomerInfoFormModal(dto?.customerInfoForm),
    customerInfoType:
      CustomerInfoType[
        (dto?.customerInfoType ?? "NO_REQUIRE") as keyof typeof CustomerInfoType
      ],
    description: dto?.description ?? "",
    fileUrl: dto?.fileUrl ?? "",
    status:
      PaylinkStatusType[
        (dto?.status ?? "NONE") as keyof typeof PaylinkStatusType
      ],
    contactEmail: dto?.contactEmail ?? "",
    contactPhone: dto?.contactPhone ?? "",
    customerDto: mapCustomermModal(dto?.customerDto),
    payLinkCode: dto?.payLinkCode ?? "",
    payLinkUrl: dto?.payLinkUrl ?? "",
    qrCode: dto?.qrCode ?? "",
    merchantCode: dto?.merchantCode ?? "",
    merchantName: dto?.merchantName ?? "",
    createTime: dto?.createTime ?? "",
    expireAt: dto?.expireAt ?? "",
    transactionId: dto?.transactionId ?? "",
    shopSetting: mapShopSettingModel(dto?.shopSetting),
  };
}

export function mapPayLinkBankTransferModal(
  dto?: GetPayLinkBankTransferResponse | undefined,
): PayLinkBankTransferModel {
  return {
    accountName: dto?.accountName ?? "",
    accountNo: dto?.accountNo ?? "",
    amount: dto?.amount ?? 0,
    bankName: dto?.bankName ?? "",
    content: dto?.content ?? "",
    description: dto?.description ?? "",
    failUrl: dto?.failUrl ?? "",
    merchantCode: dto?.merchantCode ?? "",
    merchantLogoUrl: dto?.merchantLogoUrl ?? "",
    redirectAfter: dto?.redirectAfter ?? 0,
    shopName: dto?.shopName ?? "",
    status:
      PaylinkStatusType[
        (dto?.status ?? "NONE") as keyof typeof PaylinkStatusType
      ],
    successUrl: dto?.successUrl ?? "",
  };
}

export function mapPayLinkVietQRModal(
  dto?: GetPayLinkVietQRResponse | undefined,
): PayLinkVietQRModel {
  return {
    amount: dto?.amount ?? 0,
    description: dto?.description ?? "",
    failUrl: dto?.failUrl ?? "",
    merchantCode: dto?.merchantCode ?? "",
    merchantLogoUrl: dto?.merchantLogoUrl ?? "",
    redirectAfter: dto?.redirectAfter ?? 0,
    shopName: dto?.shopName ?? "",
    status:
      PaylinkStatusType[
        (dto?.status ?? "NONE") as keyof typeof PaylinkStatusType
      ],
    successUrl: dto?.successUrl ?? "",
    qrContent: dto?.qrContent ?? "",
    accountName: dto?.accountName ?? "",
    accountNo: dto?.accountNo ?? "",
    shopSetting: mapShopSettingModel(dto?.shopSetting),
  };
}

export function mapPagePayLinkModel(
  dto?: PayLinkDto | undefined,
): PayLinkModel {
  return {
    createdAt: dto?.createdAt ?? "",
    payLinkCode: dto?.payLinkCode ?? "",
    refTransactionId: dto?.refTransactionId ?? "",
    amount: dto?.amount ?? 0,
    customerInfo: dto?.customerInfo
      ? {
          fullName: dto?.customerInfo?.fullName ?? "",
          phone: dto?.customerInfo?.phone ?? "",
          email: dto?.customerInfo?.email ?? "",

          address: dto?.customerInfo?.address ?? "",
        }
      : undefined,
    fileUrl: dto?.fileUrl ?? "",
    notify: dto?.totalSendMailSms ?? 0,
    payLinkUrl: dto?.payLinkUrl ?? "",
    createBy: dto?.createBy ?? "",
    expireType:
      PaylinkExpireType[
        (dto?.expireType ?? "NO_EXPIRE") as keyof typeof PaylinkExpireType
      ],
    accountName: dto?.accountName ? dto?.accountName : "",
    expireAt: dto?.expireAt ? dto?.expireAt : "",
    accountNo: dto?.accountNo ? dto?.accountNo : "",
    status:
      PaylinkStatusType[
        dto?.status ?? ("NONE" as keyof typeof PaylinkStatusType)
      ],
    bankType:
      BankAccountType[dto?.bankType ?? ("KLB" as keyof typeof BankAccountType)],
    id: dto?.payLinkCode ?? `${Date.now()}`,
    totalSendMailSms: dto?.totalSendMailSms ?? 0,
  };
}

export function mapPayLinkNapasModal(
  dto?: GetPayLinkNapasResponse | undefined,
): PaylinkNapasInfoModel {
  return {
    amount: dto?.amount ?? 0,
    description: dto?.description ?? "",
    failUrl: dto?.failUrl ?? "",
    merchantCode: dto?.merchantCode ?? "",
    merchantLogoUrl: dto?.merchantLogoUrl ?? "",
    redirectAfter: dto?.redirectAfter ?? 0,
    shopName: dto?.shopName ?? "",
    status:
      PaylinkStatusType[
        (dto?.status ?? "NONE") as keyof typeof PaylinkStatusType
      ],
    successUrl: dto?.successUrl ?? "",
    accountName: dto?.accountName ?? "",
    accountNo: dto?.accountNo ?? "",
    shopSetting: mapShopSettingModel(dto?.shopSetting),
    formContent: dto?.formContent ?? "",
  };
}

export function mapPayLinkStatusModel(
  dto?: PayLinkStatusResponse,
): PaylinkStatusModel {
  return {
    failUrl: dto?.failUrl ?? "",
    payLinkCode: dto?.payLinkCode ?? "",
    redirectAfter: dto?.redirectAfter ?? 0,
    status:
      PaylinkStatusType[
        (dto?.status ?? "NONE") as keyof typeof PaylinkStatusType
      ],
    successUrl: dto?.successUrl ?? "",
    accountName: dto?.accountName ?? "",
    amount: dto?.amount ?? 0,
    dateTime: dto?.dateTime ?? "",
    description: dto?.description ?? "",
    paymentType:
      PaymentType[(dto?.paymentType ?? "NONE") as keyof typeof PaymentType],
    rtxNumber: dto?.rtxNumber ?? "",
  };
}
