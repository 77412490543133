import {
  EnterpriseEmployeeDetail,
  EnterpriseEmployeeInfo,
  SalaryAdvanceByEmployeeInfo,
  UploadEmployeeRecord,
  UploadEmployeeRecordWithStatus,
} from "@/api/generated-sources/flexsalary";
import {
  EmployeeDetailModel,
  EmployeeInfoModel,
  EmployeeSalaryAdvanceModel,
  SalaryAdvanceByEmployeeInfoPaymentStatus,
  SalaryAdvanceByEmployeeInfoStatus,
  UploadEmployeeRecordModel,
  UploadEmployeeRecordWithStatusModel,
} from "../models/EmployeeModel";
import { mapSalaryAdvanceTypeInfoModel } from "./salary-adapter";

export function mapEmployeeDetailModel(
  dto?: EnterpriseEmployeeDetail,
): EmployeeDetailModel {
  return {
    id: dto?.id ?? "",
    activated: dto?.activated ?? false,
    advancedAmount: dto?.advancedAmount ?? 0,
    birthDate: dto?.birthDate ?? "",
    code: dto?.code ?? "",
    email: dto?.email ?? "",
    idCardNumber: dto?.idCardNumber ?? "",
    name: dto?.name ?? "",
    phoneNumber: dto?.phoneNumber ?? "",
    salaryAdvanceMaxAmount: dto?.salaryAdvanceMaxAmount ?? 0,
    salaryAdvanceType: mapSalaryAdvanceTypeInfoModel(dto?.salaryAdvanceType),
    salaryAccount: dto?.salaryAccount ?? "",
    baseAmount: dto?.baseAmount ?? 0,
    position: dto?.position,
  };
}

export function mapEmployeeInfoModel(
  dto: EnterpriseEmployeeInfo,
): EmployeeInfoModel {
  return {
    id: dto?.id ?? "",
    code: dto?.code ?? "",
    name: dto.name ?? "",
    phoneNumber: dto?.phoneNumber ?? "",
    email: dto?.email ?? "",
    activated: dto?.activated,
    salaryAccount: dto?.salaryAccount,
  };
}

export function mapEmployeeSalaryAdvanceModel(
  dto: SalaryAdvanceByEmployeeInfo,
): EmployeeSalaryAdvanceModel {
  return {
    id: dto?.id ?? "",
    createdDate: dto?.createdDate,
    lastApproveReject: dto?.lastApproveReject,
    lastApproveRejectByName: dto.lastApproveRejectByName,
    paymentStatus:
      SalaryAdvanceByEmployeeInfoPaymentStatus[
        dto.paymentStatus as keyof typeof SalaryAdvanceByEmployeeInfoPaymentStatus
      ],
    status:
      SalaryAdvanceByEmployeeInfoStatus[
        dto.status as keyof typeof SalaryAdvanceByEmployeeInfoStatus
      ],
  };
}

export function mapUploadEmployeeRecordModel(
  dto: UploadEmployeeRecord,
): UploadEmployeeRecordModel {
  return {
    name: dto.name,
    baseAmount: dto.baseAmount,
    birthDate: dto.birthDate,
    code: dto.code,
    email: dto.email,
    idCardNumber: dto.idCardNumber,
    phoneNumber: dto.phoneNumber,
    salaryAccount: dto.salaryAccount,
    salaryAdvanceTypeId: dto.salaryAdvanceTypeId,
    salaryAdvanceTypeName: dto.salaryAdvanceTypeName,
    position: dto.position,
  };
}

export function mapUploadEmployeeRecordWithStatusModel(
  dto: UploadEmployeeRecordWithStatus,
): UploadEmployeeRecordWithStatusModel {
  return {
    name: dto.name,
    baseAmount: dto.baseAmount,
    birthDate: dto.birthDate,
    code: dto.code,
    email: dto.email,
    idCardNumber: dto.idCardNumber,
    phoneNumber: dto.phoneNumber,
    salaryAccount: dto.salaryAccount,
    salaryAdvanceTypeId: dto.salaryAdvanceTypeId,
    salaryAdvanceTypeName: dto.salaryAdvanceTypeName,
    status: dto.status,
    position: dto.position,
  };
}
