import {
  AdminResponse,
  ForControlDto,
  ForControlHistoryDto,
} from "@/api/generated-sources/kienlong-pay";
import {
  ForControlAdminModel,
  ForControlAdminOffice,
  ForControlAdminStatus,
  ForControlCommandType,
  ForControlHistoryModel,
  ForControlModel,
  ForControlServiceType,
  ForControlStatus,
} from "@/models/ForControlModel";
import { TransactionStatusType } from "@/models/TransactionModel";

export function mapForControlModel(dto?: ForControlDto): ForControlModel {
  return {
    id: dto?.id ?? 0,
    actualAccount: dto?.actualAccount ?? "",
    amount: dto?.amount ?? 0,
    bankName: dto?.bankName ?? "",
    bankTxnNumber: dto?.bankTxnNumber ?? "",
    contact: dto?.contact ?? "",
    createdAt: dto?.createdAt ?? "",
    createdBy: dto?.createdBy ?? "",
    description: dto?.description ?? "",
    documents: dto?.documents ?? [],
    forControlCode: dto?.forControlCode ?? "",
    merchantCode: dto?.merchantCode ?? "",
    merchantName: dto?.merchantName ?? "",
    modifiedAt: dto?.modifiedAt ?? "",
    modifiedBy: dto?.modifiedBy ?? "",
    note: dto?.note ?? "",
    partnerTxnNumber: dto?.partnerTxnNumber ?? "",
    reason: dto?.reason ?? "",
    serviceType:
      ForControlServiceType[
        dto?.serviceType ?? ("" as keyof typeof ForControlServiceType)
      ],
    status:
      ForControlStatus[dto?.status ?? ("" as keyof typeof ForControlStatus)],
    transactionDate: dto?.completeTransactionDate ?? dto?.transactionDate ?? "",
    txnNumber: dto?.txnNumber ?? "",
    virtualAccount: dto?.virtualAccount ?? "",
    adminDocuments: dto?.adminDocuments ?? [],
    transactionStatus:
      TransactionStatusType[
        dto?.transactionStatus ?? ("NONE" as keyof typeof TransactionStatusType)
      ],
    accountNo: dto?.accountNo ?? "",
    fromAccount: dto?.fromAccount ?? "",
    bin: dto?.bin ?? "",
    fromBin: dto?.fromBin ?? "",
  };
}

export function mapForControlHistoryModel(
  dto?: ForControlHistoryDto,
): ForControlHistoryModel {
  return {
    createdAt: dto?.createdAt ?? "",
    createdBy: dto?.createdBy ?? "",
    forControlCommand:
      ForControlCommandType[
        dto?.forControlCommand ??
          ("CREATE" as keyof typeof ForControlCommandType)
      ],
    forControlStatus:
      ForControlStatus[
        dto?.forControlStatus ??
          ("WAITING_SOLVE" as keyof typeof ForControlStatus)
      ],
    note: dto?.note ?? "",
    email: dto?.email ?? "",
    phone: dto?.phone ?? "",
  };
}
export function mapForControlAdminModel(
  dto?: AdminResponse,
): ForControlAdminModel {
  return {
    id: dto?.id ?? "",
    description: dto?.description ?? "",
    email: dto?.email ?? "",
    fullName: dto?.fullName ?? "",
    loggedIn: dto?.loggedIn ?? false,
    office:
      ForControlAdminOffice[
        dto?.office ?? ("BRANCH" as keyof typeof ForControlAdminOffice)
      ],
    phone: dto?.phone ?? "",
    roleGroupId: dto?.roleGroupId ?? 0,
    roleGroupName: dto?.roleGroupName ?? "",
    staffId: dto?.staffId ?? "",
    status:
      ForControlAdminStatus[
        dto?.status ?? ("NOT_LOGGED_IN" as keyof typeof ForControlAdminStatus)
      ],
    username: dto?.username ?? "",
  };
}
