import { SalaryAdvanceTypeInfoModel } from "./SalaryModel";

export interface EmployeeDetailModel {
  id: string;
  code: string;
  name: string;
  phoneNumber: string;
  email: string;
  salaryAdvanceMaxAmount: number;
  idCardNumber: string;
  salaryAdvanceType: SalaryAdvanceTypeInfoModel;
  birthDate: string;
  activated: boolean;
  advancedAmount: number;
  salaryAccount: string;
  baseAmount: number;
  position?: string;
}

export interface EmployeeInfoModel {
  id: string;
  code: string;
  name: string;
  phoneNumber?: string;
  email: string;
  salaryAccount?: string;
  activated?: boolean;
}

export interface EmployeeSalaryAdvanceModel {
  id?: string;
  createdDate?: number;
  status?: SalaryAdvanceByEmployeeInfoStatus;
  paymentStatus?: SalaryAdvanceByEmployeeInfoPaymentStatus;
  lastApproveReject?: number;
  lastApproveRejectByName?: string;
}

export enum SalaryAdvanceByEmployeeInfoStatus {
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  WAITING_APPROVAL = "WAITING_APPROVAL",
  CANCELED = "CANCELED",
  EXPIRED = "EXPIRED",
}
export enum SalaryAdvanceByEmployeeInfoPaymentStatus {
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  PROCESSING = "PROCESSING",
  TODO = "TODO",
  NONE = "NONE",
}

export interface EmployeeRequestModel {
  code: string;
  name: string;
  birthDate?: string;
  idCardNumber?: string;
  phoneNumber: string;
  email?: string;
  salaryAdvanceTypeId: string;
  salaryAdvanceTypeName?: string;
  baseAmount: number;
  activated: boolean;
  salaryAccount: string;
  position: string;
}

export interface UploadEmployeeRecordModel {
  code?: string;
  name?: string;
  birthDate?: string;
  idCardNumber?: string;
  phoneNumber?: string;
  email?: string;
  baseAmount?: number;
  salaryAccount?: string;
  salaryAdvanceTypeId?: string;
  salaryAdvanceTypeName?: string;
  position?: string;
}

export interface UploadEmployeeRecordWithStatusModel {
  code?: string;
  name?: string;
  birthDate?: string;
  idCardNumber?: string;
  phoneNumber?: string;
  email?: string;
  baseAmount?: number;
  salaryAccount?: string;
  salaryAdvanceTypeId?: string;
  salaryAdvanceTypeName?: string;
  status?: Array<string>;
  position?: string;
}
