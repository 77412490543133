export const IconUltilities = ({ color = "#FFFFFF" }: { color?: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 3.9C2.25 2.98873 2.98873 2.25 3.9 2.25H9.3C10.2113 2.25 10.95 2.98873 10.95 3.9V9.3C10.95 10.2113 10.2113 10.95 9.3 10.95H3.9C2.98873 10.95 2.25 10.2113 2.25 9.3V3.9ZM3.9 3.75C3.81716 3.75 3.75 3.81716 3.75 3.9V9.3C3.75 9.38284 3.81716 9.45 3.9 9.45H9.3C9.38284 9.45 9.45 9.38284 9.45 9.3V3.9C9.45 3.81716 9.38284 3.75 9.3 3.75H3.9ZM13.05 3.9C13.05 2.98873 13.7887 2.25 14.7 2.25H20.1C21.0113 2.25 21.75 2.98873 21.75 3.9V9.3C21.75 10.2113 21.0113 10.95 20.1 10.95H14.7C13.7887 10.95 13.05 10.2113 13.05 9.3V3.9ZM14.7 3.75C14.6172 3.75 14.55 3.81716 14.55 3.9V9.3C14.55 9.38284 14.6172 9.45 14.7 9.45H20.1C20.1828 9.45 20.25 9.38284 20.25 9.3V3.9C20.25 3.81716 20.1828 3.75 20.1 3.75H14.7ZM2.25 14.7C2.25 13.7887 2.98873 13.05 3.9 13.05H9.3C10.2113 13.05 10.95 13.7887 10.95 14.7V20.1C10.95 21.0113 10.2113 21.75 9.3 21.75H3.9C2.98873 21.75 2.25 21.0113 2.25 20.1V14.7ZM3.9 14.55C3.81716 14.55 3.75 14.6172 3.75 14.7V20.1C3.75 20.1828 3.81716 20.25 3.9 20.25H9.3C9.38284 20.25 9.45 20.1828 9.45 20.1V14.7C9.45 14.6172 9.38284 14.55 9.3 14.55H3.9ZM13.05 13.8C13.05 13.3858 13.3858 13.05 13.8 13.05H21C21.4142 13.05 21.75 13.3858 21.75 13.8C21.75 14.2142 21.4142 14.55 21 14.55H13.8C13.3858 14.55 13.05 14.2142 13.05 13.8ZM16.65 17.4C16.65 16.9858 16.9858 16.65 17.4 16.65H21C21.4142 16.65 21.75 16.9858 21.75 17.4C21.75 17.8142 21.4142 18.15 21 18.15H17.4C16.9858 18.15 16.65 17.8142 16.65 17.4ZM13.05 21C13.05 20.5858 13.3858 20.25 13.8 20.25H21C21.4142 20.25 21.75 20.5858 21.75 21C21.75 21.4142 21.4142 21.75 21 21.75H13.8C13.3858 21.75 13.05 21.4142 13.05 21Z"
        fill={color}
      />
      <path
        d="M3.75 14.7C3.75 14.6172 3.81716 14.55 3.9 14.55H9.3C9.38284 14.55 9.45 14.6172 9.45 14.7V20.1C9.45 20.1828 9.38284 20.25 9.3 20.25H3.9C3.81716 20.25 3.75 20.1828 3.75 20.1V14.7Z"
        fill={color}
      />
      <path
        d="M3.75 3.9C3.75 3.81716 3.81716 3.75 3.9 3.75H9.3C9.38284 3.75 9.45 3.81716 9.45 3.9V9.3C9.45 9.38284 9.38284 9.45 9.3 9.45H3.9C3.81716 9.45 3.75 9.38284 3.75 9.3V3.9Z"
        fill={color}
      />
      <path
        d="M14.55 3.9C14.55 3.81716 14.6172 3.75 14.7 3.75H20.1C20.1828 3.75 20.25 3.81716 20.25 3.9V9.3C20.25 9.38284 20.1828 9.45 20.1 9.45H14.7C14.6172 9.45 14.55 9.38284 14.55 9.3V3.9Z"
        fill={color}
      />
    </svg>
  );
};
