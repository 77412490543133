export interface BankAccountModel {
  id: string;
  accountNo: string;
  bankType: BankAccountType;
  accountName: string;
  defaultAccount: boolean;
  qrCode?: string;
  defaultFeeAccount?: boolean;
}

export enum BankAccountType {
  KLB = "KLB",
  UMEE = "UMEE",
}

export interface BankTransactionDetailModel {
  transactionNo: string;
  dateTime: string;
  amount: number;
  transGroup: string;
  coefficient: number;
  detail: string;
  currency: string;
}

export interface BankInfoModel {
  id: number;
  name: string;
  shortName: string;
  commonName: string;
  citad: string;
  napasSupported: boolean;
  napasCode: string;
  benId: string;
  swift: string;
  vietQrSupported: boolean;
  logo: string;
  icon: string;
  code: string;
}

export interface BankAccountInfoModel {
  accountName: string;
  accountNo: string;
  bank: BankInfoModel;
}

export interface LinkAccountInfoModel {
  bankAccountName: string;
  bankAccountNo: string;
  branch: BankAccountType;
}

export interface FeeBankAccountInputModel {
  accountNo: string;
  defaultFeeAccount: boolean;
}
