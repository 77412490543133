import { PortalTransactionControllerApi } from "./generated-sources/kienlong-pay/api";
import {
  DeviceControllerApi,
  StaticQrControllerApi,
} from "./generated-sources/paybox/api";
import {
  Configuration,
  ConfigurationParameters,
} from "./generated-sources/paybox";
import { PayBoxDeviceRepositoryImp } from "./paybox-repositories/implements/PayboxDeviceRepositoryImp";
import { PayboxStaticQrRepositoryImp } from "./paybox-repositories/implements/PayboxStaticQrRepositoryImp";
import { PayBoxDeviceRepository } from "./paybox-repositories/PayboxDeviceRepository";
import { PayboxStaticQrRepository } from "./paybox-repositories/PayboxStaticQrRepository";
import { Environment } from "@/services/environment";

export class PayboxClient {
  private config: Configuration;

  public readonly payBoxDeviceController: DeviceControllerApi;
  public readonly payBoxTransactionController: PortalTransactionControllerApi;
  public readonly payBoxStaticQrController: StaticQrControllerApi;

  constructor(cf: Configuration) {
    this.config = cf;
    this.payBoxDeviceController = new DeviceControllerApi(cf, cf.basePath);
    this.payBoxTransactionController = new PortalTransactionControllerApi(
      cf,
      cf.basePath,
    );
    this.payBoxStaticQrController = new StaticQrControllerApi(cf, cf.basePath);
  }
}

export const payboxConfig: ConfigurationParameters = {
  basePath: Environment.hostApiPayBox,
};

export class PayboxHttpClient {
  public readonly payBoxDeviceRepository: PayBoxDeviceRepository;
  public readonly payboxStaticQrRepository: PayboxStaticQrRepository;

  private static uniPayboxClient: PayboxClient;
  constructor(basePath?: string) {
    if (basePath) {
      payboxConfig.basePath = basePath;
    }
    PayboxHttpClient.uniPayboxClient = new PayboxClient(
      new Configuration(payboxConfig),
    );
    this.payBoxDeviceRepository = new PayBoxDeviceRepositoryImp(
      PayboxHttpClient.uniPayboxClient,
    );
    this.payboxStaticQrRepository = new PayboxStaticQrRepositoryImp(
      PayboxHttpClient.uniPayboxClient,
    );
  }
}
