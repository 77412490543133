import { FC, useCallback, useEffect, useState } from "react";
import { ViewportContext, ViewportProps } from "./ViewPortContext";

export const ViewportContextProvider: FC<{ children: React.ReactNode }> = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [dimension, setDimension] = useState<ViewportProps>({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const handleResize = useCallback(
    () =>
      setDimension({
        height: window.innerHeight,
        width: window.innerWidth,
      }),
    [dimension],
  );

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    document.addEventListener(
      "touchstart",
      (e) => {
        if (e.touches.length > 1) {
          e.preventDefault();
        }
      },
      {
        passive: false,
      },
    );
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("touchstart", () => {
        console.debug("done touch start");
      });
    };
  }, []);

  return (
    <ViewportContext.Provider value={dimension}>
      {children}
    </ViewportContext.Provider>
  );
};
