export interface PermissionModel {
  id: number;
  name: string;
  description: string;
  scope: PermissionScopeType;
}

export enum PermissionScopeType {
  ADMIN = "ADMIN",
  MERCHANT = "MERCHANT",
  ROOT_ADMIN = "ROOT_ADMIN",
  INTERNAL_SERVICE = "INTERNAL_SERVICE",
}

// export enum ActionType {
//   OWNER = "OWNER",
//   MANAGER = "MANAGER",
//   FINANCE = "FINANCE",
//   OPERATOR = "OPERATOR",
//   CASHIER = "CASHIER",
// }
export interface PermissionListModel {
  id: number;
  name: string;
  description: string;
  actions: PermissionModel[];
}

export interface PermissionGroupModel {
  id: number;
  name: string;
  description: string;
  permissions: PermissionListModel[];
}
