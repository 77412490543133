export const IconForControl = ({ color = "#FFFFFF" }: { color?: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6926 11.8247H9.90513C9.437 11.8247 9.05746 11.4813 9.05746 10.9811L9.09953 8.18806C9.09953 7.36653 8.34433 7.1159 7.78401 7.68495L2.57479 13.4219C2.27785 13.761 2.04926 14.1892 2.51925 14.6643L7.84714 20.9251C8.36178 21.5567 9.11014 21.2212 9.05746 20.3181L9.05749 17.1798C9.05748 16.6796 9.43698 16.4324 9.90513 16.4324H10.6926C11.1607 16.4324 11.5449 15.8686 11.5449 15.3683V12.7926C11.5449 12.2923 11.1607 11.8247 10.6926 11.8247Z"
        fill={color}
      />
      <path
        d="M12.8566 7.19736H13.6861C14.1792 7.19736 14.579 6.85397 14.579 6.35375L14.5347 3.56073C14.5347 2.7392 15.3302 2.48857 15.9205 3.05762L21.4079 8.79455C21.7207 9.13369 21.9615 9.56188 21.4664 10.0369L15.854 16.2978C15.3118 16.9294 14.5235 16.5938 14.579 15.6908L14.579 12.5525C14.579 12.0523 14.1792 11.8051 13.6861 11.8051H12.8566C12.3634 11.8051 11.9588 11.2412 11.9588 10.741V8.16522C11.9588 7.665 12.3634 7.19736 12.8566 7.19736Z"
        fill={color}
      />
    </svg>
  );
};
