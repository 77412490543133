import {
  QrLoginGetInfoResponse,
  QrLoginResponse,
  QrUserInfoResponse,
} from "@/api/generated-sources/kienlong-pay";
import {
  QrLoginModel,
  QrLoginStatus,
  QrLoginInfoModel,
  QrUserInfoModel,
  QrUserInfoStatus,
} from "@/models/LoginQrModel";

export function mapQrLoginModel(dto?: QrLoginResponse): QrLoginModel {
  return {
    dateTime: dto?.dateTime ?? "",
    deviceName: dto?.deviceName ?? "",
    expireTime: dto?.expireTime ?? "",
    fullName: dto?.fullName ?? "",
    location: dto?.location ?? "",
    merchantAvatar: dto?.merchantAvatar ?? "",
    merchantCode: dto?.merchantCode ?? "",
    merchantName: dto?.merchantName ?? "",
    operatingSystem: dto?.operatingSystem ?? "",
    phone: dto?.phone ?? "",
    qrCode: dto?.qrCode ?? "",
    sessionId: dto?.sessionId ?? "",
    sessionKey: dto?.sessionKey ?? "",
    status:
      QrLoginStatus[dto?.status ?? ("FAILED" as keyof typeof QrLoginStatus)],
    timeout: dto?.timeout ?? 0,
    userAvatar: dto?.userAvatar ?? "",
  };
}

export function mapQrLoginInfoModel(
  dto?: QrLoginGetInfoResponse,
): QrLoginInfoModel {
  return {
    dateTime: dto?.dateTime ?? "",
    deviceName: dto?.deviceName ?? "",
    expireTime: dto?.expireTime ?? "",
    fullName: dto?.fullName ?? "",
    location: dto?.location ?? "",
    merchantAvatar: dto?.merchantAvatar ?? "",
    merchantCode: dto?.merchantCode ?? "",
    merchantName: dto?.merchantName ?? "",
    operatingSystem: dto?.operatingSystem ?? "",
    phone: dto?.phone ?? "",
    sessionId: dto?.sessionId ?? "",
    sessionKey: dto?.sessionKey ?? "",
    status:
      QrLoginStatus[dto?.status ?? ("FAILED" as keyof typeof QrLoginStatus)],
    timeout: dto?.timeout ?? 0,
    userAvatar: dto?.userAvatar ?? "",
    isNewAccount: dto?.isNewAccount ?? false,
  };
}

export function mapQrUserInfoModel(dto?: QrUserInfoResponse): QrUserInfoModel {
  return {
    avatarUrl: dto?.avatarUrl ?? "",
    birthday: dto?.birthday ?? "",
    branchCode: dto?.branchCode ?? "",
    cifNo: dto?.cifNo ?? "",
    faceIdUrl: dto?.faceIdUrl ?? "",
    fullAddress: dto?.hometown ?? "",
    fullName: dto?.fullName ?? "",
    gender: dto?.gender ?? "",
    idCardBackSideUrl: dto?.idCardBackSideUrl ?? "",
    idCardFrontSideUrl: dto?.idCardFrontSideUrl ?? "",
    idCardIssueBy: dto?.idCardIssueDate ?? "",
    idCardIssueDate: dto?.idCardIssueDate ?? "",
    idCardNo: dto?.idCardNo ?? "",
    idCardType: dto?.idCardType ?? "",
    permanentAddress: dto?.permanentAddress ?? "",
    phoneNumber: dto?.phoneNumber ?? "",
    sessionId: dto?.sessionId ?? "",
    sessionKey: dto?.sessionKey ?? "",
    status:
      QrUserInfoStatus[
        dto?.status ?? ("CREATED" as keyof typeof QrUserInfoStatus)
      ],
    userId: dto?.userId ?? "",
  };
}
