import { TransactionStatusType } from "@/models/TransactionModel";
export interface PayBoxTransactionModel {
  transactionId: string;
  createDateTime: string;
  amount: string | number;
  virtualAccount: string;
  status: TransactionStatusType;
}

export interface PagePayBoxTransactionModel {
  pageNumber: number;
  pageSize: number;
  totalSize: number;
  totalPage: number;
  items: PayBoxTransactionModel[];
}

export enum PayBoxTransactionType {
  UMEE = "UMEE",
  PAYBOX = "PAYBOX",
  POS = "POS",
  USEE = "USEE",
}
