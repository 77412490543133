import { KienlongPayClient } from "@/api/kienlong_pay_http_client";
import { ErrorModel } from "@/models/ErrorModel";
import { TransactionDashboard } from "@/models/TransactionDashoard";
import { DashboardRepository } from "../DashboardRepository";

export class DashboardRepositoryImp implements DashboardRepository {
  private client: KienlongPayClient;
  constructor(kienlongPayClient: KienlongPayClient) {
    this.client = kienlongPayClient;
  }

  public async getTotalUmeePayTransaction(): Promise<TransactionDashboard> {
    try {
      let resp =
        await this.client.portalDashboardController.getTotalTransaction();
      if (resp.data.code === 0) {
        return {
          amount: resp.data.data?.amount ?? 0,
          total: resp.data.data?.total ?? 0,
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getTotalBankTransaction(): Promise<TransactionDashboard> {
    try {
      let resp =
        await this.client.portalDashboardController.getTotalTransactionFromBank();
      if (resp.data.code === 0) {
        return {
          amount: resp.data.data?.amount ?? 0,
          total: resp.data.data?.total ?? 0,
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getTodayUmeePayTransaction(): Promise<TransactionDashboard> {
    try {
      let resp =
        await this.client.portalDashboardController.getTotalTodayTransactions();
      if (resp.data.code === 0) {
        return {
          amount: resp.data.data?.amount ?? 0,
          total: resp.data.data?.total ?? 0,
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getTodayBankTransaction(): Promise<TransactionDashboard> {
    try {
      let resp =
        await this.client.portalDashboardController.getTotalTodayTransactionsFromBank();
      if (resp.data.code === 0) {
        return {
          amount: resp.data.data?.amount ?? 0,
          total: resp.data.data?.total ?? 0,
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getTransactionStatistic({
    from,
    to,
  }: {
    from: string;
    to: string;
  }): Promise<any | null> {
    try {
      let resp =
        await this.client.portalDashboardController.getTransactionStatistic({
          from,
          to,
        });
      if (resp.data.code === 0) {
        return {
          statistics: resp?.data?.data?.statistics ?? [],
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
  public async getTransactionStatisticFromBank({
    from,
    to,
  }: {
    from: string;
    to: string;
  }): Promise<any | null> {
    try {
      let resp =
        await this.client.portalDashboardController.getTransactionStatisticFromBank(
          {
            from,
            to,
          },
        );
      if (resp.data.code === 0) {
        return {
          statistics: resp?.data?.data?.statistics ?? [],
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
