import { BankAccountType } from "./BankAccountModel";
import {
  CustomerInfoFormModel,
  CustomerInfoModel,
  CustomerInfoType,
  CustomerModel,
} from "./CustomersModel";
import { ShopSettingModel } from "./ShopModel";

export interface PaylinkDetailModel {
  payLinkCode: string;
  payLinkUrl: string;
  qrCode: string;
  amount: number;
  description: string;
  fileUrl: string;
  contactEmail: string;
  contactPhone: string;
  customerDto: CustomerModel;
  status: PaylinkStatusType;
  customerInfoType: CustomerInfoType;
  customerInfoForm: CustomerInfoFormModel;
  merchantName: string;
  merchantCode: string;
  createTime: string;
  expireAt: string;
  transactionId: string;
  shopSetting: ShopSettingModel;
}

export enum PaylinkExpireType {
  MINUTE_10 = "MINUTE_10",
  HOUR_1 = "HOUR_1",
  HOUR_12 = "HOUR_12",
  DAY_1 = "DAY_1",
  DAY_7 = "DAY_7",
  DAY_30 = "DAY_30",
  NO_EXPIRE = "NO_EXPIRE",
}

export enum PaylinkStatusType {
  NONE = "NONE",
  CREATED = "CREATED",
  SUCCESS = "SUCCESS",
  CANCELED = "CANCELED",
  FAIL = "FAIL",
  TIMEOUT = "TIMEOUT",
  PENDING = "PENDING",
}

export interface PaylinkStatusModel {
  payLinkCode: string;
  status: PaylinkStatusType;
  successUrl: string;
  failUrl: string;
  redirectAfter: number;
  rtxNumber: string;
  dateTime: string;
  paymentType: PaymentType;
  description: string;
  accountName: string;
  amount: number;
}

export interface PayLinkBankTransferModel {
  shopName: string;
  merchantCode: string;
  merchantLogoUrl: string;
  amount: number;
  description: string;
  bankName: string;
  accountNo: string;
  accountName: string;
  content: string;
  successUrl: string;
  failUrl: string;
  redirectAfter: number;
  status: PaylinkStatusType;
}

export interface PayLinkVietQRModel {
  shopName: string;
  merchantCode: string;
  merchantLogoUrl: string;
  qrContent: string;
  amount: number;
  description: string;
  successUrl: string;
  failUrl: string;
  redirectAfter: number;
  status: PaylinkStatusType;
  accountNo: string;
  accountName: string;
  shopSetting: ShopSettingModel;
}

export enum PaymentType {
  VIET_QR = "VIET_QR",
  ATM_CARD = "ATM_CARD",
  BANKING = "BANKING",
}

export interface CustomerInfoModelView {
  fullName?: string;
  email?: string;
  phone?: string;
  address?: string;
}

export interface PayLinkModel {
  payLinkCode: string;
  refTransactionId: string;
  accountNo: string;
  accountName: string;
  amount: number;
  customerInfo?: CustomerInfoModel;
  fileUrl: string;
  notify: number;
  payLinkUrl: string;
  createBy: string;
  expireType: PaylinkExpireType;
  status: PaylinkStatusType;
  createdAt: string;
  expireAt: string;
  bankType: BankAccountType;
  id: string;
  totalSendMailSms: number;
}

export interface PaylinkNapasInfoModel {
  shopName: string;
  merchantCode: string;
  merchantLogoUrl: string;
  formContent: string;
  amount: number;
  description: string;
  successUrl: string;
  failUrl: string;
  redirectAfter: number;
  status: PaylinkStatusType;
  accountNo: string;
  accountName: string;
  shopSetting: ShopSettingModel;
}
