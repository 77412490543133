import { CardCustom } from "@/components/card-custom/CardCustom";
import { Environment } from "@/services/environment";
import { ReactNode } from "react";

interface ErrorPage403Props {
  content?: ReactNode;
}
const ErrorPage403: React.FC<ErrorPage403Props> = ({
  content = "Bạn không có quyền truy cập trang này",
}) => {
  return (
    <CardCustom
      style={{
        width: "100%",
        gap: "0px",
        padding: "100px 0px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        alt=""
        src={`${Environment.hostApp}/images/403.png`}
        style={{ width: "240px", aspectRatio: 1 }}
      />
      <span
        className="fontSize--20 lineHeight--28 fontWeight--400"
        style={{ color: "var(--color-alias-secondary-on-theme)" }}
      >
        {content}
      </span>
    </CardCustom>
  );
};

export default ErrorPage403;
