import { SystemParamResponse } from "@/api/generated-sources/kienlong-pay";
import {
  SystemParamDataType,
  SystemParamGroup,
  SystemParamModel,
} from "@/models/SystemParamModel";

export function mapSystemParamModel(
  dto: SystemParamResponse | undefined,
): SystemParamModel {
  return {
    createBy: dto?.createBy ?? "",
    createdAt: dto?.createBy ?? "",
    dataType:
      SystemParamDataType[
        dto?.dataType ?? ("STRING" as keyof typeof SystemParamDataType)
      ],
    description: dto?.description ?? "",
    group:
      SystemParamGroup[
        dto?.group ?? ("PARAMETER_SYSTEM" as keyof typeof SystemParamGroup)
      ],
    id: dto?.id ?? 0,
    modifiedAt: dto?.modifiedAt ?? "",
    modifiedBy: dto?.modifiedBy ?? "",
    name: dto?.name ?? "",
    value: dto?.value ?? "",
  };
}
