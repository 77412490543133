import { IconArrow } from "@/components/icons-component/icon-component";
import { useEffect, useMemo, useState } from "react";
import { BaseBloc } from "@/services/base-bloc";
import "./nav-link-expand-custom.css";

interface _CustomNavLinkExpand {
  isExpand: boolean;
}

const CustomNavLinkExpand = ({
  title,
  path,
  icon,
  isSelected,
  children,
}: {
  title: string;
  path: string;
  icon: React.ReactNode;
  isSelected: boolean;
  children?: React.ReactNode;
}) => {
  const _bloc = useMemo(() => {
    return new BaseBloc<_CustomNavLinkExpand>({
      initState: {
        isExpand: false,
      },
    });
  }, []);

  const [_state, _setState] = useState(_bloc.state);

  useEffect(() => {
    _bloc.stream.subscribe((v) => {
      _setState({ ...v });
    });
    return () => {
      _bloc.stream.complete();
    };
  }, []);

  return (
    <>
      <div
        className="nav-link-expand-custom-group__element"
        style={{
          backgroundColor: "transparent",
        }}
        onClick={() => {
          _bloc.state.isExpand = !_state.isExpand;
          _bloc.upDateState();
        }}
      >
        <div
          className="row"
          style={{
            gap: "12px",
          }}
        >
          {icon}
          <span className="nav-link-expand-custom-group__element__title">
            {title}
          </span>
        </div>
        <div
          className={`nav-link-expand-custom-group__element__icon ${
            _state.isExpand
              ? "nav-link-expand-custom-group__element__icon--selected"
              : ""
          }`}
        >
          <IconArrow color="#3366FF" />
        </div>
      </div>
      {
        <div
          className={`nav-link-expand-custom-group__element__value ${
            _state.isExpand
              ? "nav-link-expand-custom-group__element__value--expand"
              : ""
          }`}
        >
          {children}
        </div>
      }
    </>
  );
};

export default CustomNavLinkExpand;
