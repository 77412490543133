import { mapPaymentMethodInfoModel } from "@/adapters/payment_adapter";
import { KienlongPayClient } from "@/api/kienlong_pay_http_client";
import { ErrorModel } from "@/models/ErrorModel";
import { PaymentMethodInfoModel } from "@/models/Payment";
import { PaymentMethodRepository } from "../PaymentMethodRepository";

export class PaymentMethodRepositoryImp implements PaymentMethodRepository {
  private client: KienlongPayClient;
  constructor(kienlongPayClient: KienlongPayClient) {
    this.client = kienlongPayClient;
  }

  public async getlistMethods(): Promise<PaymentMethodInfoModel[]> {
    try {
      let resp =
        await this.client.paymentMethodController.getListPaymentMethod();
      if (resp.data.code === 0) {
        return (
          resp.data.data?.payments?.map((d) => mapPaymentMethodInfoModel(d)) ??
          []
        );
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
