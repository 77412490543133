import {
  BankAccountResponse,
  PaySubscriptionPlanResponse,
} from "@/api/generated-sources/kienlong-pay";
import {
  PaySubscriptionPlanModel,
  PaySubscriptionPlanPaymentType,
  PaySubscriptionPlanStatus,
  PaySubscriptionPlanType,
} from "@/models/PaySubscriptionPlanModel ";
import { DayOfWeek, TimeType } from "@/models/TimeModel";
import { mapBankAccountModal } from "./bank_account_adapter";

export function mapPaySubscriptionPlanModel(
  dto?: PaySubscriptionPlanResponse | undefined,
): PaySubscriptionPlanModel {
  return {
    id: dto?.id ?? "",
    code: dto?.code ?? "",
    name: dto?.name ?? "",
    description: dto?.description ?? "",
    status:
      PaySubscriptionPlanStatus[
        dto?.status ?? ("INACTIVE" as keyof typeof PaySubscriptionPlanStatus)
      ],
    paymentType:
      PaySubscriptionPlanPaymentType[
        dto?.paymentType ??
          ("PREPAID" as keyof typeof PaySubscriptionPlanPaymentType)
      ],
    cycleValue: dto?.cycleValue ?? 0,
    timeType: TimeType[dto?.timeType ?? ("DAY" as keyof typeof TimeType)],
    type: PaySubscriptionPlanType[
      dto?.type ??
        ("RECURRING_QUANTITY" as keyof typeof PaySubscriptionPlanType)
    ],
    fixedAmount: dto?.fixedAmount ?? 0,
    expirationDays: dto?.expirationDays ?? 0,
    createdAt: dto?.createdAt ?? "",
    callbackUrl: dto?.callbackUrl ?? "",
    modifiedAt: dto?.modifiedAt ?? "",
    fixedPaymentTime: dto?.fixedPaymentTime ?? false,
    fixedTime: {
      dayOfWeek:
        DayOfWeek[
          dto?.fixedTime?.dayOfWeek ?? ("SUNDAY" as keyof typeof DayOfWeek)
        ],
      dayOfMonth: dto?.fixedTime?.dayOfMonth ?? 0,
      dayOfYear: dto?.fixedTime?.dayOfYear ?? "",
    },
    bankAccount: mapBankAccountModal(
      dto?.bankAccount satisfies BankAccountResponse | undefined,
    ),
  };
}
