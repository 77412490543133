export const breakPoint = 1024;
export const specialChars = new RegExp(/[#!@$%^*]/);

export const headerId: string = "kienlongbank-pay-header";
export const sidebarId: string = "kienlongbank-pay-sidebar";
export const breadcrumbId: string = "kienlongbank-pay-breadcrumb";
export const bottombarId: string = "kienlongbank-pay-bottombar";
export const bodyId: string = "kienlongbank-pay-body";
export const loaderId: string = "kienlongbank-pay-loader";
export const messageId: string = "kienlongbank-pay-message";
export const modalId: string = "kienlongbank-pay-modal";
