export interface WebhookUrlModel {
  webhookUrl: string;
  status?: WebhookUrlStatus;
}

export enum WebhookUrlStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}
