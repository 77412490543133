import { PaymentHistoryDetail } from "@/api/generated-sources/flexsalary";
import { PaymentHistoryDetailModel } from "../models/PaymentModel";

export function mapPaymentHistoryDetailModel(
  dto?: PaymentHistoryDetail,
): PaymentHistoryDetailModel {
  return {
    id: dto?.id ?? "",
    amount: dto?.amount ?? 0,
    completedTime: dto?.completedTime ?? 0,
    createdDate: dto?.createdDate ?? 0,
    description: dto?.description ?? "",
    fromAccount: dto?.fromAccount ?? "",
    toAccount: dto?.toAccount ?? "",
    transactionNumber: dto?.transactionNumber ?? "",
    transactionStatus: dto?.transactionStatus ?? "",
  };
}
