export interface BusinessOwnerModel {
  id: number;
  name: string;
  identifyNumber: string;
  funPercent: number;
  position: string;
}

export enum BusinessType {
  PERSONAL = "PERSONAL",
  ORGANIZATION = "ORGANIZATION",
}

export enum BusinessProductsType {
  VEHICLE_SALE = "VEHICLE_SALE",
  LOGISTICS = "LOGISTICS",
  RESIDENT_AND_FOOD_SERVICE = "RESIDENT_AND_FOOD_SERVICE",
  INFORMATION_AND_MEDIA = "INFORMATION_AND_MEDIA",
  FINANCE_AND_INSURANCE = "FINANCE_AND_INSURANCE",
  REAL_ESTATE = "REAL_ESTATE",
  ADMINISTRATIVE_AND_SUPPORT_SERVICE = "ADMINISTRATIVE_AND_SUPPORT_SERVICE",
  EDUCATION = "EDUCATION",
  PUBLIC_HEALTH = "PUBLIC_HEALTH",
  HEALTH_CARE_AND_BEAUTY = "HEALTH_CARE_AND_BEAUTY",
  ART_AND_ENTERTAINMENT = "ART_AND_ENTERTAINMENT",
  OTHER = "OTHER",
}

export enum RevenueType {
  REVENUE_TYPE_1 = "REVENUE_TYPE_1",
  REVENUE_TYPE_2 = "REVENUE_TYPE_2",
  REVENUE_TYPE_3 = "REVENUE_TYPE_3",
  REVENUE_TYPE_4 = "REVENUE_TYPE_4",
}

export enum FeeType {
  IMMEDIATELY = "IMMEDIATELY",
  LATER = "LATER",
}
