import { TransactionStatusType } from "./TransactionModel";

export interface ForControlModel {
  id: number;
  createdBy: string;
  createdAt: string;
  modifiedBy: string;
  modifiedAt: string;
  forControlCode: string;
  merchantCode: string;
  merchantName: string;
  contact: string;
  reason: string;
  transactionDate: string;
  txnNumber: string;
  partnerTxnNumber: string;
  bankTxnNumber: string;
  bankName: string;
  amount: number;
  description: string;
  serviceType: ForControlServiceType;
  virtualAccount: string;
  actualAccount: string;
  status: ForControlStatus;
  note: string;
  documents: Array<string>;
  adminDocuments: Array<string>;
  transactionStatus: TransactionStatusType;
  fromAccount: string;
  fromBin: string;
  accountNo: string;
  bin: string;
}

export enum ForControlServiceType {
  COBO = "COBO",
  POBO = "POBO",
  COBO_INTER_CARD = "COBO_INTER_CARD",
  COBO_DEBIT_DOMESTIC_CARD = "COBO_DEBIT_DOMESTIC_CARD",
  COBO_CREDIT_DOMESTIC_CARD = "COBO_CREDIT_DOMESTIC_CARD",
  POBO_INTER_BANK = "POBO_INTER_BANK",
}

export enum ForControlStatus {
  CREATE = "CREATE",
  WAITING_SOLVE = "WAITING_SOLVE",
  SOLVING = "SOLVING",
  SUCCESS = "SUCCESS",
  DENY = "DENY",
}

export interface ForControlHistoryModel {
  forControlCommand: ForControlCommandType;
  note: string;
  forControlStatus: ForControlStatus;
  createdBy: string;
  createdAt: string;
  email: string;
  phone: string;
}

export enum ForControlCommandType {
  CREATE = "CREATE",
  SEND_APPROVE = "SEND_APPROVE",
  SOLVING = "SOLVING",
  APPROVE = "APPROVE",
  DENY = "DENY",
}

export interface ForControlAdminModel {
  id: string;
  username: string;
  fullName: string;
  email: string;
  status: ForControlAdminStatus;
  phone: string;
  loggedIn: boolean;
  staffId: string;
  office: ForControlAdminOffice;
  description: string;
  roleGroupName: string;
  roleGroupId: number;
}

export enum ForControlAdminStatus {
  BLOCKED = "BLOCKED",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  NOT_LOGGED_IN = "NOT_LOGGED_IN",
}

export enum ForControlAdminOffice {
  HEADQUARTER = "HEADQUARTER",
  BRANCH = "BRANCH",
  TRANSACTION_OFFICE = "TRANSACTION_OFFICE",
}
