/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from "./configuration";
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "./base";

/**
 *
 * @export
 * @interface AddResourceRequest
 */
export interface AddResourceRequest {
  /**
   *
   * @type {string}
   * @memberof AddResourceRequest
   */
  key: string;
  /**
   *
   * @type {string}
   * @memberof AddResourceRequest
   */
  value: string;
}
/**
 *
 * @export
 * @interface CheckDeviceBeforeLinkRequest
 */
export interface CheckDeviceBeforeLinkRequest {
  /**
   *
   * @type {string}
   * @memberof CheckDeviceBeforeLinkRequest
   */
  hardwareId: string;
  /**
   *
   * @type {string}
   * @memberof CheckDeviceBeforeLinkRequest
   */
  pairingKey: string;
}
/**
 *
 * @export
 * @interface DeleteDeviceResponse
 */
export interface DeleteDeviceResponse {
  /**
   *
   * @type {boolean}
   * @memberof DeleteDeviceResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface DeleteManufacturerTokenResponse
 */
export interface DeleteManufacturerTokenResponse {
  /**
   *
   * @type {boolean}
   * @memberof DeleteManufacturerTokenResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface DeleteStaticQrByIdResponse
 */
export interface DeleteStaticQrByIdResponse {
  /**
   *
   * @type {boolean}
   * @memberof DeleteStaticQrByIdResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface DeleteVersionResponse
 */
export interface DeleteVersionResponse {
  /**
   *
   * @type {boolean}
   * @memberof DeleteVersionResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface DeviceResponse
 */
export interface DeviceResponse {
  /**
   *
   * @type {string}
   * @memberof DeviceResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceResponse
   */
  hardwareId?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceResponse
   */
  displayName?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceResponse
   */
  manToken?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceResponse
   */
  model?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceResponse
   */
  location?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceResponse
   */
  version?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceResponse
   */
  hwVersion?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceResponse
   */
  bankAccountId?: string;
  /**
   *
   * @type {boolean}
   * @memberof DeviceResponse
   */
  online?: boolean;
}
/**
 *
 * @export
 * @interface DownloadVersionResponse
 */
export interface DownloadVersionResponse {
  /**
   *
   * @type {string}
   * @memberof DownloadVersionResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof DownloadVersionResponse
   */
  model?: string;
  /**
   *
   * @type {string}
   * @memberof DownloadVersionResponse
   */
  fwVersion?: string;
  /**
   *
   * @type {string}
   * @memberof DownloadVersionResponse
   */
  createTime?: string;
  /**
   *
   * @type {string}
   * @memberof DownloadVersionResponse
   */
  filePath?: string;
  /**
   *
   * @type {string}
   * @memberof DownloadVersionResponse
   */
  contentType?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof DownloadVersionResponse
   */
  file?: Array<string>;
}
/**
 *
 * @export
 * @interface ForceUpdateRequest
 */
export interface ForceUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof ForceUpdateRequest
   */
  merchantId?: string;
  /**
   *
   * @type {string}
   * @memberof ForceUpdateRequest
   */
  deviceId?: string;
}
/**
 *
 * @export
 * @interface ForceUpdateResponse
 */
export interface ForceUpdateResponse {
  /**
   *
   * @type {{ [key: string]: boolean; }}
   * @memberof ForceUpdateResponse
   */
  result?: { [key: string]: boolean };
}
/**
 *
 * @export
 * @interface GetAllDeviceResponse
 */
export interface GetAllDeviceResponse {
  /**
   *
   * @type {PageResponseDeviceDeviceResponse}
   * @memberof GetAllDeviceResponse
   */
  devices?: PageResponseDeviceDeviceResponse;
}
/**
 *
 * @export
 * @interface GetAllResourcesResponse
 */
export interface GetAllResourcesResponse {
  /**
   *
   * @type {Array<ResourceResponse>}
   * @memberof GetAllResourcesResponse
   */
  resources?: Array<ResourceResponse>;
}
/**
 *
 * @export
 * @interface GetAllStaticQrResponse
 */
export interface GetAllStaticQrResponse {
  /**
   *
   * @type {PageResponseStaticQrStaticQrResponse}
   * @memberof GetAllStaticQrResponse
   */
  qrList?: PageResponseStaticQrStaticQrResponse;
}
/**
 *
 * @export
 * @interface GetLatestVersionResponse
 */
export interface GetLatestVersionResponse {
  /**
   *
   * @type {boolean}
   * @memberof GetLatestVersionResponse
   */
  update?: boolean;
  /**
   *
   * @type {string}
   * @memberof GetLatestVersionResponse
   */
  fwVersion?: string;
  /**
   *
   * @type {string}
   * @memberof GetLatestVersionResponse
   */
  displayName?: string;
}
/**
 *
 * @export
 * @interface GetManufacturerTokenResponse
 */
export interface GetManufacturerTokenResponse {
  /**
   *
   * @type {Array<ManufacturerTokenResponse>}
   * @memberof GetManufacturerTokenResponse
   */
  manTokens?: Array<ManufacturerTokenResponse>;
}
/**
 *
 * @export
 * @interface GetVersionsResponse
 */
export interface GetVersionsResponse {
  /**
   *
   * @type {PageResponseVersionVersionResponse}
   * @memberof GetVersionsResponse
   */
  versions?: PageResponseVersionVersionResponse;
}
/**
 *
 * @export
 * @interface LinkWithUserRequest
 */
export interface LinkWithUserRequest {
  /**
   *
   * @type {string}
   * @memberof LinkWithUserRequest
   */
  hardwareId: string;
  /**
   *
   * @type {string}
   * @memberof LinkWithUserRequest
   */
  pairingKey: string;
}
/**
 *
 * @export
 * @interface LinkWithUserResponse
 */
export interface LinkWithUserResponse {
  /**
   *
   * @type {boolean}
   * @memberof LinkWithUserResponse
   */
  success?: boolean;
  /**
   *
   * @type {string}
   * @memberof LinkWithUserResponse
   */
  deviceId?: string;
  /**
   *
   * @type {string}
   * @memberof LinkWithUserResponse
   */
  displayName?: string;
}
/**
 *
 * @export
 * @interface ManufacturerTokenResponse
 */
export interface ManufacturerTokenResponse {
  /**
   *
   * @type {string}
   * @memberof ManufacturerTokenResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ManufacturerTokenResponse
   */
  manToken?: string;
  /**
   *
   * @type {number}
   * @memberof ManufacturerTokenResponse
   */
  quantity?: number;
}
/**
 *
 * @export
 * @interface NotifyTransactionRequest
 */
export interface NotifyTransactionRequest {
  /**
   *
   * @type {string}
   * @memberof NotifyTransactionRequest
   */
  merchantId?: string;
  /**
   *
   * @type {string}
   * @memberof NotifyTransactionRequest
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof NotifyTransactionRequest
   */
  deviceId: string;
  /**
   *
   * @type {string}
   * @memberof NotifyTransactionRequest
   */
  status?: NotifyTransactionRequestStatusEnum;
  /**
   *
   * @type {string}
   * @memberof NotifyTransactionRequest
   */
  type?: NotifyTransactionRequestTypeEnum;
  /**
   *
   * @type {number}
   * @memberof NotifyTransactionRequest
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof NotifyTransactionRequest
   */
  createTime?: string;
  /**
   *
   * @type {string}
   * @memberof NotifyTransactionRequest
   */
  actualAccount?: string;
  /**
   *
   * @type {string}
   * @memberof NotifyTransactionRequest
   */
  virtualAccount?: string;
  /**
   *
   * @type {string}
   * @memberof NotifyTransactionRequest
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof NotifyTransactionRequest
   */
  timeout?: number;
  /**
   *
   * @type {string}
   * @memberof NotifyTransactionRequest
   */
  qrContent?: string;
}

export const NotifyTransactionRequestStatusEnum = {
  None: "NONE",
  Created: "CREATED",
  Success: "SUCCESS",
  Canceled: "CANCELED",
  Fail: "FAIL",
  Timeout: "TIMEOUT",
} as const;

export type NotifyTransactionRequestStatusEnum =
  (typeof NotifyTransactionRequestStatusEnum)[keyof typeof NotifyTransactionRequestStatusEnum];
export const NotifyTransactionRequestTypeEnum = {
  Umee: "UMEE",
  Paybox: "PAYBOX",
  Pos: "POS",
  Usee: "USEE",
} as const;

export type NotifyTransactionRequestTypeEnum =
  (typeof NotifyTransactionRequestTypeEnum)[keyof typeof NotifyTransactionRequestTypeEnum];

/**
 *
 * @export
 * @interface PageResponseDeviceDeviceResponse
 */
export interface PageResponseDeviceDeviceResponse {
  /**
   *
   * @type {Array<DeviceResponse>}
   * @memberof PageResponseDeviceDeviceResponse
   */
  content?: Array<DeviceResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponseDeviceDeviceResponse
   */
  totalPages?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseDeviceDeviceResponse
   */
  totalElements?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseDeviceDeviceResponse
   */
  numberOfElements?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseDeviceDeviceResponse
   */
  size?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseDeviceDeviceResponse
   */
  number?: number;
}
/**
 *
 * @export
 * @interface PageResponseStaticQrStaticQrResponse
 */
export interface PageResponseStaticQrStaticQrResponse {
  /**
   *
   * @type {Array<StaticQrResponse>}
   * @memberof PageResponseStaticQrStaticQrResponse
   */
  content?: Array<StaticQrResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponseStaticQrStaticQrResponse
   */
  totalPages?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseStaticQrStaticQrResponse
   */
  totalElements?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseStaticQrStaticQrResponse
   */
  numberOfElements?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseStaticQrStaticQrResponse
   */
  size?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseStaticQrStaticQrResponse
   */
  number?: number;
}
/**
 *
 * @export
 * @interface PageResponseVersionVersionResponse
 */
export interface PageResponseVersionVersionResponse {
  /**
   *
   * @type {Array<object>}
   * @memberof PageResponseVersionVersionResponse
   */
  content?: Array<object>;
  /**
   *
   * @type {number}
   * @memberof PageResponseVersionVersionResponse
   */
  totalPages?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseVersionVersionResponse
   */
  totalElements?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseVersionVersionResponse
   */
  numberOfElements?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseVersionVersionResponse
   */
  size?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseVersionVersionResponse
   */
  number?: number;
}
/**
 *
 * @export
 * @interface PairWithUmeeResponse
 */
export interface PairWithUmeeResponse {
  /**
   *
   * @type {string}
   * @memberof PairWithUmeeResponse
   */
  umeeAccessKey?: string;
}
/**
 *
 * @export
 * @interface PreviewQrResponse
 */
export interface PreviewQrResponse {
  /**
   *
   * @type {string}
   * @memberof PreviewQrResponse
   */
  preview?: string;
}
/**
 *
 * @export
 * @interface RegisterDeviceRequest
 */
export interface RegisterDeviceRequest {
  /**
   *
   * @type {string}
   * @memberof RegisterDeviceRequest
   */
  hardwareId: string;
  /**
   *
   * @type {string}
   * @memberof RegisterDeviceRequest
   */
  manToken: string;
  /**
   *
   * @type {string}
   * @memberof RegisterDeviceRequest
   */
  model: string;
  /**
   *
   * @type {string}
   * @memberof RegisterDeviceRequest
   */
  location?: string;
  /**
   *
   * @type {string}
   * @memberof RegisterDeviceRequest
   */
  hwVersion?: string;
}
/**
 *
 * @export
 * @interface RegisterDeviceResponse
 */
export interface RegisterDeviceResponse {
  /**
   *
   * @type {string}
   * @memberof RegisterDeviceResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof RegisterDeviceResponse
   */
  hardwareId?: string;
  /**
   *
   * @type {string}
   * @memberof RegisterDeviceResponse
   */
  manToken?: string;
  /**
   *
   * @type {string}
   * @memberof RegisterDeviceResponse
   */
  deviceAccessKey?: string;
  /**
   *
   * @type {string}
   * @memberof RegisterDeviceResponse
   */
  model?: string;
  /**
   *
   * @type {string}
   * @memberof RegisterDeviceResponse
   */
  location?: string;
  /**
   *
   * @type {string}
   * @memberof RegisterDeviceResponse
   */
  hwVersion?: string;
}
/**
 *
 * @export
 * @interface ResourceResponse
 */
export interface ResourceResponse {
  /**
   *
   * @type {string}
   * @memberof ResourceResponse
   */
  key?: string;
  /**
   *
   * @type {string}
   * @memberof ResourceResponse
   */
  value?: string;
  /**
   *
   * @type {string}
   * @memberof ResourceResponse
   */
  createdDate?: string;
  /**
   *
   * @type {string}
   * @memberof ResourceResponse
   */
  lastModifiedDate?: string;
}
/**
 *
 * @export
 * @interface ResponseBaseDeleteDeviceResponse
 */
export interface ResponseBaseDeleteDeviceResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseDeleteDeviceResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseDeleteDeviceResponse
   */
  message?: string;
  /**
   *
   * @type {DeleteDeviceResponse}
   * @memberof ResponseBaseDeleteDeviceResponse
   */
  data?: DeleteDeviceResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseDeleteManufacturerTokenResponse
 */
export interface ResponseBaseDeleteManufacturerTokenResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseDeleteManufacturerTokenResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseDeleteManufacturerTokenResponse
   */
  message?: string;
  /**
   *
   * @type {DeleteManufacturerTokenResponse}
   * @memberof ResponseBaseDeleteManufacturerTokenResponse
   */
  data?: DeleteManufacturerTokenResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseDeleteStaticQrByIdResponse
 */
export interface ResponseBaseDeleteStaticQrByIdResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseDeleteStaticQrByIdResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseDeleteStaticQrByIdResponse
   */
  message?: string;
  /**
   *
   * @type {DeleteStaticQrByIdResponse}
   * @memberof ResponseBaseDeleteStaticQrByIdResponse
   */
  data?: DeleteStaticQrByIdResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseDeleteVersionResponse
 */
export interface ResponseBaseDeleteVersionResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseDeleteVersionResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseDeleteVersionResponse
   */
  message?: string;
  /**
   *
   * @type {DeleteVersionResponse}
   * @memberof ResponseBaseDeleteVersionResponse
   */
  data?: DeleteVersionResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseDeviceResponse
 */
export interface ResponseBaseDeviceResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseDeviceResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseDeviceResponse
   */
  message?: string;
  /**
   *
   * @type {DeviceResponse}
   * @memberof ResponseBaseDeviceResponse
   */
  data?: DeviceResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseDownloadVersionResponse
 */
export interface ResponseBaseDownloadVersionResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseDownloadVersionResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseDownloadVersionResponse
   */
  message?: string;
  /**
   *
   * @type {DownloadVersionResponse}
   * @memberof ResponseBaseDownloadVersionResponse
   */
  data?: DownloadVersionResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseForceUpdateResponse
 */
export interface ResponseBaseForceUpdateResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseForceUpdateResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseForceUpdateResponse
   */
  message?: string;
  /**
   *
   * @type {ForceUpdateResponse}
   * @memberof ResponseBaseForceUpdateResponse
   */
  data?: ForceUpdateResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGetAllDeviceResponse
 */
export interface ResponseBaseGetAllDeviceResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseGetAllDeviceResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetAllDeviceResponse
   */
  message?: string;
  /**
   *
   * @type {GetAllDeviceResponse}
   * @memberof ResponseBaseGetAllDeviceResponse
   */
  data?: GetAllDeviceResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGetAllResourcesResponse
 */
export interface ResponseBaseGetAllResourcesResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseGetAllResourcesResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetAllResourcesResponse
   */
  message?: string;
  /**
   *
   * @type {GetAllResourcesResponse}
   * @memberof ResponseBaseGetAllResourcesResponse
   */
  data?: GetAllResourcesResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGetAllStaticQrResponse
 */
export interface ResponseBaseGetAllStaticQrResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseGetAllStaticQrResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetAllStaticQrResponse
   */
  message?: string;
  /**
   *
   * @type {GetAllStaticQrResponse}
   * @memberof ResponseBaseGetAllStaticQrResponse
   */
  data?: GetAllStaticQrResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGetLatestVersionResponse
 */
export interface ResponseBaseGetLatestVersionResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseGetLatestVersionResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetLatestVersionResponse
   */
  message?: string;
  /**
   *
   * @type {GetLatestVersionResponse}
   * @memberof ResponseBaseGetLatestVersionResponse
   */
  data?: GetLatestVersionResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGetManufacturerTokenResponse
 */
export interface ResponseBaseGetManufacturerTokenResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseGetManufacturerTokenResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetManufacturerTokenResponse
   */
  message?: string;
  /**
   *
   * @type {GetManufacturerTokenResponse}
   * @memberof ResponseBaseGetManufacturerTokenResponse
   */
  data?: GetManufacturerTokenResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGetVersionsResponse
 */
export interface ResponseBaseGetVersionsResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseGetVersionsResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetVersionsResponse
   */
  message?: string;
  /**
   *
   * @type {GetVersionsResponse}
   * @memberof ResponseBaseGetVersionsResponse
   */
  data?: GetVersionsResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseLinkWithUserResponse
 */
export interface ResponseBaseLinkWithUserResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseLinkWithUserResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseLinkWithUserResponse
   */
  message?: string;
  /**
   *
   * @type {LinkWithUserResponse}
   * @memberof ResponseBaseLinkWithUserResponse
   */
  data?: LinkWithUserResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseManufacturerTokenResponse
 */
export interface ResponseBaseManufacturerTokenResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseManufacturerTokenResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseManufacturerTokenResponse
   */
  message?: string;
  /**
   *
   * @type {ManufacturerTokenResponse}
   * @memberof ResponseBaseManufacturerTokenResponse
   */
  data?: ManufacturerTokenResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePairWithUmeeResponse
 */
export interface ResponseBasePairWithUmeeResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePairWithUmeeResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePairWithUmeeResponse
   */
  message?: string;
  /**
   *
   * @type {PairWithUmeeResponse}
   * @memberof ResponseBasePairWithUmeeResponse
   */
  data?: PairWithUmeeResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePreviewQrResponse
 */
export interface ResponseBasePreviewQrResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePreviewQrResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePreviewQrResponse
   */
  message?: string;
  /**
   *
   * @type {PreviewQrResponse}
   * @memberof ResponseBasePreviewQrResponse
   */
  data?: PreviewQrResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseRegisterDeviceResponse
 */
export interface ResponseBaseRegisterDeviceResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseRegisterDeviceResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseRegisterDeviceResponse
   */
  message?: string;
  /**
   *
   * @type {RegisterDeviceResponse}
   * @memberof ResponseBaseRegisterDeviceResponse
   */
  data?: RegisterDeviceResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseResourceResponse
 */
export interface ResponseBaseResourceResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseResourceResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseResourceResponse
   */
  message?: string;
  /**
   *
   * @type {ResourceResponse}
   * @memberof ResponseBaseResourceResponse
   */
  data?: ResourceResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseSetupBankAccountResponse
 */
export interface ResponseBaseSetupBankAccountResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseSetupBankAccountResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseSetupBankAccountResponse
   */
  message?: string;
  /**
   *
   * @type {SetupBankAccountResponse}
   * @memberof ResponseBaseSetupBankAccountResponse
   */
  data?: SetupBankAccountResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseStaticQrResponse
 */
export interface ResponseBaseStaticQrResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseStaticQrResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseStaticQrResponse
   */
  message?: string;
  /**
   *
   * @type {StaticQrResponse}
   * @memberof ResponseBaseStaticQrResponse
   */
  data?: StaticQrResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseStatusResponse
 */
export interface ResponseBaseStatusResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseStatusResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseStatusResponse
   */
  message?: string;
  /**
   *
   * @type {StatusResponse}
   * @memberof ResponseBaseStatusResponse
   */
  data?: StatusResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseUmeePostPairResponse
 */
export interface ResponseBaseUmeePostPairResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseUmeePostPairResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseUmeePostPairResponse
   */
  message?: string;
  /**
   *
   * @type {UmeePostPairResponse}
   * @memberof ResponseBaseUmeePostPairResponse
   */
  data?: UmeePostPairResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseUpdateDeviceResponse
 */
export interface ResponseBaseUpdateDeviceResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseUpdateDeviceResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseUpdateDeviceResponse
   */
  message?: string;
  /**
   *
   * @type {UpdateDeviceResponse}
   * @memberof ResponseBaseUpdateDeviceResponse
   */
  data?: UpdateDeviceResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseValidateQrResponse
 */
export interface ResponseBaseValidateQrResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseValidateQrResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseValidateQrResponse
   */
  message?: string;
  /**
   *
   * @type {ValidateQrResponse}
   * @memberof ResponseBaseValidateQrResponse
   */
  data?: ValidateQrResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseVersionResponse
 */
export interface ResponseBaseVersionResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseVersionResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseVersionResponse
   */
  message?: string;
  /**
   *
   * @type {object}
   * @memberof ResponseBaseVersionResponse
   */
  data?: object;
}
/**
 *
 * @export
 * @interface SetupBankAccountResponse
 */
export interface SetupBankAccountResponse {
  /**
   *
   * @type {boolean}
   * @memberof SetupBankAccountResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface ShowTransactionRequest
 */
export interface ShowTransactionRequest {
  /**
   *
   * @type {string}
   * @memberof ShowTransactionRequest
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ShowTransactionRequest
   */
  deviceId?: string;
  /**
   *
   * @type {string}
   * @memberof ShowTransactionRequest
   */
  status?: ShowTransactionRequestStatusEnum;
  /**
   *
   * @type {string}
   * @memberof ShowTransactionRequest
   */
  type?: ShowTransactionRequestTypeEnum;
  /**
   *
   * @type {number}
   * @memberof ShowTransactionRequest
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof ShowTransactionRequest
   */
  createTime?: string;
  /**
   *
   * @type {string}
   * @memberof ShowTransactionRequest
   */
  actualAccount?: string;
  /**
   *
   * @type {string}
   * @memberof ShowTransactionRequest
   */
  virtualAccount?: string;
  /**
   *
   * @type {string}
   * @memberof ShowTransactionRequest
   */
  accountName?: string;
  /**
   *
   * @type {string}
   * @memberof ShowTransactionRequest
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof ShowTransactionRequest
   */
  timeout?: number;
  /**
   *
   * @type {string}
   * @memberof ShowTransactionRequest
   */
  qrContent?: string;
}

export const ShowTransactionRequestStatusEnum = {
  None: "NONE",
  Created: "CREATED",
  Success: "SUCCESS",
  Canceled: "CANCELED",
  Fail: "FAIL",
  Timeout: "TIMEOUT",
} as const;

export type ShowTransactionRequestStatusEnum =
  (typeof ShowTransactionRequestStatusEnum)[keyof typeof ShowTransactionRequestStatusEnum];
export const ShowTransactionRequestTypeEnum = {
  Umee: "UMEE",
  Paybox: "PAYBOX",
  Pos: "POS",
  Usee: "USEE",
} as const;

export type ShowTransactionRequestTypeEnum =
  (typeof ShowTransactionRequestTypeEnum)[keyof typeof ShowTransactionRequestTypeEnum];

/**
 *
 * @export
 * @interface StaticQrResponse
 */
export interface StaticQrResponse {
  /**
   *
   * @type {string}
   * @memberof StaticQrResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof StaticQrResponse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof StaticQrResponse
   */
  qrCode?: string;
  /**
   *
   * @type {string}
   * @memberof StaticQrResponse
   */
  deviceId?: string;
  /**
   *
   * @type {string}
   * @memberof StaticQrResponse
   */
  accountNumber?: string;
  /**
   *
   * @type {string}
   * @memberof StaticQrResponse
   */
  accountName?: string;
  /**
   *
   * @type {string}
   * @memberof StaticQrResponse
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof StaticQrResponse
   */
  shopName?: string;
  /**
   *
   * @type {string}
   * @memberof StaticQrResponse
   */
  icon?: string;
  /**
   *
   * @type {string}
   * @memberof StaticQrResponse
   */
  shortName?: string;
  /**
   *
   * @type {boolean}
   * @memberof StaticQrResponse
   */
  defaultQr?: boolean;
  /**
   *
   * @type {string}
   * @memberof StaticQrResponse
   */
  staticQrType?: StaticQrResponseStaticQrTypeEnum;
}

export const StaticQrResponseStaticQrTypeEnum = {
  Bank: "BANK",
  EWallet: "E_WALLET",
  Umee: "UMEE",
} as const;

export type StaticQrResponseStaticQrTypeEnum =
  (typeof StaticQrResponseStaticQrTypeEnum)[keyof typeof StaticQrResponseStaticQrTypeEnum];

/**
 *
 * @export
 * @interface StatusResponse
 */
export interface StatusResponse {
  /**
   *
   * @type {boolean}
   * @memberof StatusResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface UmeeAuthenticateRequest
 */
export interface UmeeAuthenticateRequest {
  /**
   *
   * @type {string}
   * @memberof UmeeAuthenticateRequest
   */
  deviceId: string;
  /**
   *
   * @type {string}
   * @memberof UmeeAuthenticateRequest
   */
  accessKey: string;
}
/**
 *
 * @export
 * @interface UmeePostPairRequest
 */
export interface UmeePostPairRequest {
  /**
   *
   * @type {string}
   * @memberof UmeePostPairRequest
   */
  deviceId: string;
  /**
   *
   * @type {string}
   * @memberof UmeePostPairRequest
   */
  pairingKey: string;
  /**
   *
   * @type {string}
   * @memberof UmeePostPairRequest
   */
  refUserId?: string;
  /**
   *
   * @type {string}
   * @memberof UmeePostPairRequest
   */
  shopName?: string;
  /**
   *
   * @type {string}
   * @memberof UmeePostPairRequest
   */
  targetAccount?: string;
  /**
   *
   * @type {string}
   * @memberof UmeePostPairRequest
   */
  targetAccountName?: string;
}
/**
 *
 * @export
 * @interface UmeePostPairResponse
 */
export interface UmeePostPairResponse {
  /**
   *
   * @type {string}
   * @memberof UmeePostPairResponse
   */
  umeeAccessKey?: string;
}
/**
 *
 * @export
 * @interface UmeeUnpairRequest
 */
export interface UmeeUnpairRequest {
  /**
   *
   * @type {string}
   * @memberof UmeeUnpairRequest
   */
  deviceId: string;
  /**
   *
   * @type {string}
   * @memberof UmeeUnpairRequest
   */
  accessKey: string;
}
/**
 *
 * @export
 * @interface UpdateDeviceRequest
 */
export interface UpdateDeviceRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateDeviceRequest
   */
  location?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateDeviceRequest
   */
  displayName?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateDeviceRequest
   */
  bankAccountId?: string;
}
/**
 *
 * @export
 * @interface UpdateDeviceResponse
 */
export interface UpdateDeviceResponse {
  /**
   *
   * @type {boolean}
   * @memberof UpdateDeviceResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface ValidateQrResponse
 */
export interface ValidateQrResponse {
  /**
   *
   * @type {string}
   * @memberof ValidateQrResponse
   */
  qrId?: string;
}

/**
 * DeviceControllerApi - axios parameter creator
 * @export
 */
export const DeviceControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Check device trươc khi link
     * @param {CheckDeviceBeforeLinkRequest} checkDeviceBeforeLinkRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkDeviceBeforeLink: async (
      checkDeviceBeforeLinkRequest: CheckDeviceBeforeLinkRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'checkDeviceBeforeLinkRequest' is not null or undefined
      assertParamExists(
        "checkDeviceBeforeLink",
        "checkDeviceBeforeLinkRequest",
        checkDeviceBeforeLinkRequest,
      );
      const localVarPath = `/api/device/v1/link/check`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        checkDeviceBeforeLinkRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Hủy liên kết tk ngân hàng đến device
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteBankAccount: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("deleteBankAccount", "id", id);
      const localVarPath = `/api/device/v1/deleteBankAccount/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Xóa thiết bị
     * @summary Xóa 1 thiết bị
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDevice: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("deleteDevice", "id", id);
      const localVarPath = `/api/device/v1/delete/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Lấy tất cả thiết bị
     * @summary Lấy tất cả thiết bị
     * @param {string} [id]
     * @param {string} [name]
     * @param {string} [hardwareId]
     * @param {string} [manToken]
     * @param {string} [model]
     * @param {string} [location]
     * @param {string} [refUserId]
     * @param {boolean} [online]
     * @param {number} [size]
     * @param {number} [page]
     * @param {string} [sortBy]
     * @param {string} [sortDir]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllDevices: async (
      id?: string,
      name?: string,
      hardwareId?: string,
      manToken?: string,
      model?: string,
      location?: string,
      refUserId?: string,
      online?: boolean,
      size?: number,
      page?: number,
      sortBy?: string,
      sortDir?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/device/v1/getAll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (id !== undefined) {
        localVarQueryParameter["id"] = id;
      }

      if (name !== undefined) {
        localVarQueryParameter["name"] = name;
      }

      if (hardwareId !== undefined) {
        localVarQueryParameter["hardwareId"] = hardwareId;
      }

      if (manToken !== undefined) {
        localVarQueryParameter["manToken"] = manToken;
      }

      if (model !== undefined) {
        localVarQueryParameter["model"] = model;
      }

      if (location !== undefined) {
        localVarQueryParameter["location"] = location;
      }

      if (refUserId !== undefined) {
        localVarQueryParameter["refUserId"] = refUserId;
      }

      if (online !== undefined) {
        localVarQueryParameter["online"] = online;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter["sortBy"] = sortBy;
      }

      if (sortDir !== undefined) {
        localVarQueryParameter["sortDir"] = sortDir;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Lấy thiết bị
     * @summary Lấy 1 thiết bị
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDevice: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getDevice", "id", id);
      const localVarPath = `/api/device/v1/get/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Kết nối thiết bị với user
     * @summary Kết nối thiết bị với user
     * @param {LinkWithUserRequest} linkWithUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    linkWithUser: async (
      linkWithUserRequest: LinkWithUserRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'linkWithUserRequest' is not null or undefined
      assertParamExists(
        "linkWithUser",
        "linkWithUserRequest",
        linkWithUserRequest,
      );
      const localVarPath = `/api/device/v1/link`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        linkWithUserRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Thêm mới 1 thiết bị
     * @summary Thêm mới 1 thiết bị
     * @param {RegisterDeviceRequest} registerDeviceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registerDevice: async (
      registerDeviceRequest: RegisterDeviceRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'registerDeviceRequest' is not null or undefined
      assertParamExists(
        "registerDevice",
        "registerDeviceRequest",
        registerDeviceRequest,
      );
      const localVarPath = `/api/device/v1/register`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        registerDeviceRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Liên kết tk ngân hàng đến device
     * @param {string} merchantId
     * @param {string} bankAccountId
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setUpBankAccount: async (
      merchantId: string,
      bankAccountId: string,
      deviceId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'merchantId' is not null or undefined
      assertParamExists("setUpBankAccount", "merchantId", merchantId);
      // verify required parameter 'bankAccountId' is not null or undefined
      assertParamExists("setUpBankAccount", "bankAccountId", bankAccountId);
      // verify required parameter 'deviceId' is not null or undefined
      assertParamExists("setUpBankAccount", "deviceId", deviceId);
      const localVarPath = `/api/device/v1/setupBankAccount`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (merchantId !== undefined) {
        localVarQueryParameter["merchantId"] = merchantId;
      }

      if (bankAccountId !== undefined) {
        localVarQueryParameter["bankAccountId"] = bankAccountId;
      }

      if (deviceId !== undefined) {
        localVarQueryParameter["deviceId"] = deviceId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Update thiết bị
     * @summary Update thiết bị
     * @param {string} id
     * @param {UpdateDeviceRequest} updateDeviceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDevice: async (
      id: string,
      updateDeviceRequest: UpdateDeviceRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("updateDevice", "id", id);
      // verify required parameter 'updateDeviceRequest' is not null or undefined
      assertParamExists(
        "updateDevice",
        "updateDeviceRequest",
        updateDeviceRequest,
      );
      const localVarPath = `/api/device/v1/update/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateDeviceRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DeviceControllerApi - functional programming interface
 * @export
 */
export const DeviceControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    DeviceControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Check device trươc khi link
     * @param {CheckDeviceBeforeLinkRequest} checkDeviceBeforeLinkRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkDeviceBeforeLink(
      checkDeviceBeforeLinkRequest: CheckDeviceBeforeLinkRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseDeviceResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.checkDeviceBeforeLink(
          checkDeviceBeforeLinkRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Hủy liên kết tk ngân hàng đến device
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteBankAccount(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteBankAccount(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Xóa thiết bị
     * @summary Xóa 1 thiết bị
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteDevice(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseDeleteDeviceResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDevice(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Lấy tất cả thiết bị
     * @summary Lấy tất cả thiết bị
     * @param {string} [id]
     * @param {string} [name]
     * @param {string} [hardwareId]
     * @param {string} [manToken]
     * @param {string} [model]
     * @param {string} [location]
     * @param {string} [refUserId]
     * @param {boolean} [online]
     * @param {number} [size]
     * @param {number} [page]
     * @param {string} [sortBy]
     * @param {string} [sortDir]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllDevices(
      id?: string,
      name?: string,
      hardwareId?: string,
      manToken?: string,
      model?: string,
      location?: string,
      refUserId?: string,
      online?: boolean,
      size?: number,
      page?: number,
      sortBy?: string,
      sortDir?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetAllDeviceResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllDevices(
        id,
        name,
        hardwareId,
        manToken,
        model,
        location,
        refUserId,
        online,
        size,
        page,
        sortBy,
        sortDir,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Lấy thiết bị
     * @summary Lấy 1 thiết bị
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDevice(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseDeviceResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDevice(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Kết nối thiết bị với user
     * @summary Kết nối thiết bị với user
     * @param {LinkWithUserRequest} linkWithUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async linkWithUser(
      linkWithUserRequest: LinkWithUserRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseLinkWithUserResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.linkWithUser(
        linkWithUserRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Thêm mới 1 thiết bị
     * @summary Thêm mới 1 thiết bị
     * @param {RegisterDeviceRequest} registerDeviceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async registerDevice(
      registerDeviceRequest: RegisterDeviceRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseRegisterDeviceResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.registerDevice(
        registerDeviceRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Liên kết tk ngân hàng đến device
     * @param {string} merchantId
     * @param {string} bankAccountId
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setUpBankAccount(
      merchantId: string,
      bankAccountId: string,
      deviceId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseSetupBankAccountResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.setUpBankAccount(
          merchantId,
          bankAccountId,
          deviceId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Update thiết bị
     * @summary Update thiết bị
     * @param {string} id
     * @param {UpdateDeviceRequest} updateDeviceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateDevice(
      id: string,
      updateDeviceRequest: UpdateDeviceRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseUpdateDeviceResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateDevice(
        id,
        updateDeviceRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * DeviceControllerApi - factory interface
 * @export
 */
export const DeviceControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DeviceControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Check device trươc khi link
     * @param {CheckDeviceBeforeLinkRequest} checkDeviceBeforeLinkRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkDeviceBeforeLink(
      checkDeviceBeforeLinkRequest: CheckDeviceBeforeLinkRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseDeviceResponse> {
      return localVarFp
        .checkDeviceBeforeLink(checkDeviceBeforeLinkRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Hủy liên kết tk ngân hàng đến device
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteBankAccount(
      id: string,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .deleteBankAccount(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Xóa thiết bị
     * @summary Xóa 1 thiết bị
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDevice(
      id: string,
      options?: any,
    ): AxiosPromise<ResponseBaseDeleteDeviceResponse> {
      return localVarFp
        .deleteDevice(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Lấy tất cả thiết bị
     * @summary Lấy tất cả thiết bị
     * @param {string} [id]
     * @param {string} [name]
     * @param {string} [hardwareId]
     * @param {string} [manToken]
     * @param {string} [model]
     * @param {string} [location]
     * @param {string} [refUserId]
     * @param {boolean} [online]
     * @param {number} [size]
     * @param {number} [page]
     * @param {string} [sortBy]
     * @param {string} [sortDir]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllDevices(
      id?: string,
      name?: string,
      hardwareId?: string,
      manToken?: string,
      model?: string,
      location?: string,
      refUserId?: string,
      online?: boolean,
      size?: number,
      page?: number,
      sortBy?: string,
      sortDir?: string,
      options?: any,
    ): AxiosPromise<ResponseBaseGetAllDeviceResponse> {
      return localVarFp
        .getAllDevices(
          id,
          name,
          hardwareId,
          manToken,
          model,
          location,
          refUserId,
          online,
          size,
          page,
          sortBy,
          sortDir,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * - Lấy thiết bị
     * @summary Lấy 1 thiết bị
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDevice(
      id: string,
      options?: any,
    ): AxiosPromise<ResponseBaseDeviceResponse> {
      return localVarFp
        .getDevice(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Kết nối thiết bị với user
     * @summary Kết nối thiết bị với user
     * @param {LinkWithUserRequest} linkWithUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    linkWithUser(
      linkWithUserRequest: LinkWithUserRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseLinkWithUserResponse> {
      return localVarFp
        .linkWithUser(linkWithUserRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Thêm mới 1 thiết bị
     * @summary Thêm mới 1 thiết bị
     * @param {RegisterDeviceRequest} registerDeviceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registerDevice(
      registerDeviceRequest: RegisterDeviceRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseRegisterDeviceResponse> {
      return localVarFp
        .registerDevice(registerDeviceRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Liên kết tk ngân hàng đến device
     * @param {string} merchantId
     * @param {string} bankAccountId
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setUpBankAccount(
      merchantId: string,
      bankAccountId: string,
      deviceId: string,
      options?: any,
    ): AxiosPromise<ResponseBaseSetupBankAccountResponse> {
      return localVarFp
        .setUpBankAccount(merchantId, bankAccountId, deviceId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Update thiết bị
     * @summary Update thiết bị
     * @param {string} id
     * @param {UpdateDeviceRequest} updateDeviceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDevice(
      id: string,
      updateDeviceRequest: UpdateDeviceRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseUpdateDeviceResponse> {
      return localVarFp
        .updateDevice(id, updateDeviceRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for checkDeviceBeforeLink operation in DeviceControllerApi.
 * @export
 * @interface DeviceControllerApiCheckDeviceBeforeLinkRequest
 */
export interface DeviceControllerApiCheckDeviceBeforeLinkRequest {
  /**
   *
   * @type {CheckDeviceBeforeLinkRequest}
   * @memberof DeviceControllerApiCheckDeviceBeforeLink
   */
  readonly checkDeviceBeforeLinkRequest: CheckDeviceBeforeLinkRequest;
}

/**
 * Request parameters for deleteBankAccount operation in DeviceControllerApi.
 * @export
 * @interface DeviceControllerApiDeleteBankAccountRequest
 */
export interface DeviceControllerApiDeleteBankAccountRequest {
  /**
   *
   * @type {string}
   * @memberof DeviceControllerApiDeleteBankAccount
   */
  readonly id: string;
}

/**
 * Request parameters for deleteDevice operation in DeviceControllerApi.
 * @export
 * @interface DeviceControllerApiDeleteDeviceRequest
 */
export interface DeviceControllerApiDeleteDeviceRequest {
  /**
   *
   * @type {string}
   * @memberof DeviceControllerApiDeleteDevice
   */
  readonly id: string;
}

/**
 * Request parameters for getAllDevices operation in DeviceControllerApi.
 * @export
 * @interface DeviceControllerApiGetAllDevicesRequest
 */
export interface DeviceControllerApiGetAllDevicesRequest {
  /**
   *
   * @type {string}
   * @memberof DeviceControllerApiGetAllDevices
   */
  readonly id?: string;

  /**
   *
   * @type {string}
   * @memberof DeviceControllerApiGetAllDevices
   */
  readonly name?: string;

  /**
   *
   * @type {string}
   * @memberof DeviceControllerApiGetAllDevices
   */
  readonly hardwareId?: string;

  /**
   *
   * @type {string}
   * @memberof DeviceControllerApiGetAllDevices
   */
  readonly manToken?: string;

  /**
   *
   * @type {string}
   * @memberof DeviceControllerApiGetAllDevices
   */
  readonly model?: string;

  /**
   *
   * @type {string}
   * @memberof DeviceControllerApiGetAllDevices
   */
  readonly location?: string;

  /**
   *
   * @type {string}
   * @memberof DeviceControllerApiGetAllDevices
   */
  readonly refUserId?: string;

  /**
   *
   * @type {boolean}
   * @memberof DeviceControllerApiGetAllDevices
   */
  readonly online?: boolean;

  /**
   *
   * @type {number}
   * @memberof DeviceControllerApiGetAllDevices
   */
  readonly size?: number;

  /**
   *
   * @type {number}
   * @memberof DeviceControllerApiGetAllDevices
   */
  readonly page?: number;

  /**
   *
   * @type {string}
   * @memberof DeviceControllerApiGetAllDevices
   */
  readonly sortBy?: string;

  /**
   *
   * @type {string}
   * @memberof DeviceControllerApiGetAllDevices
   */
  readonly sortDir?: string;
}

/**
 * Request parameters for getDevice operation in DeviceControllerApi.
 * @export
 * @interface DeviceControllerApiGetDeviceRequest
 */
export interface DeviceControllerApiGetDeviceRequest {
  /**
   *
   * @type {string}
   * @memberof DeviceControllerApiGetDevice
   */
  readonly id: string;
}

/**
 * Request parameters for linkWithUser operation in DeviceControllerApi.
 * @export
 * @interface DeviceControllerApiLinkWithUserRequest
 */
export interface DeviceControllerApiLinkWithUserRequest {
  /**
   *
   * @type {LinkWithUserRequest}
   * @memberof DeviceControllerApiLinkWithUser
   */
  readonly linkWithUserRequest: LinkWithUserRequest;
}

/**
 * Request parameters for registerDevice operation in DeviceControllerApi.
 * @export
 * @interface DeviceControllerApiRegisterDeviceRequest
 */
export interface DeviceControllerApiRegisterDeviceRequest {
  /**
   *
   * @type {RegisterDeviceRequest}
   * @memberof DeviceControllerApiRegisterDevice
   */
  readonly registerDeviceRequest: RegisterDeviceRequest;
}

/**
 * Request parameters for setUpBankAccount operation in DeviceControllerApi.
 * @export
 * @interface DeviceControllerApiSetUpBankAccountRequest
 */
export interface DeviceControllerApiSetUpBankAccountRequest {
  /**
   *
   * @type {string}
   * @memberof DeviceControllerApiSetUpBankAccount
   */
  readonly merchantId: string;

  /**
   *
   * @type {string}
   * @memberof DeviceControllerApiSetUpBankAccount
   */
  readonly bankAccountId: string;

  /**
   *
   * @type {string}
   * @memberof DeviceControllerApiSetUpBankAccount
   */
  readonly deviceId: string;
}

/**
 * Request parameters for updateDevice operation in DeviceControllerApi.
 * @export
 * @interface DeviceControllerApiUpdateDeviceRequest
 */
export interface DeviceControllerApiUpdateDeviceRequest {
  /**
   *
   * @type {string}
   * @memberof DeviceControllerApiUpdateDevice
   */
  readonly id: string;

  /**
   *
   * @type {UpdateDeviceRequest}
   * @memberof DeviceControllerApiUpdateDevice
   */
  readonly updateDeviceRequest: UpdateDeviceRequest;
}

/**
 * DeviceControllerApi - object-oriented interface
 * @export
 * @class DeviceControllerApi
 * @extends {BaseAPI}
 */
export class DeviceControllerApi extends BaseAPI {
  /**
   *
   * @summary Check device trươc khi link
   * @param {DeviceControllerApiCheckDeviceBeforeLinkRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceControllerApi
   */
  public checkDeviceBeforeLink(
    requestParameters: DeviceControllerApiCheckDeviceBeforeLinkRequest,
    options?: AxiosRequestConfig,
  ) {
    return DeviceControllerApiFp(this.configuration)
      .checkDeviceBeforeLink(
        requestParameters.checkDeviceBeforeLinkRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Hủy liên kết tk ngân hàng đến device
   * @param {DeviceControllerApiDeleteBankAccountRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceControllerApi
   */
  public deleteBankAccount(
    requestParameters: DeviceControllerApiDeleteBankAccountRequest,
    options?: AxiosRequestConfig,
  ) {
    return DeviceControllerApiFp(this.configuration)
      .deleteBankAccount(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Xóa thiết bị
   * @summary Xóa 1 thiết bị
   * @param {DeviceControllerApiDeleteDeviceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceControllerApi
   */
  public deleteDevice(
    requestParameters: DeviceControllerApiDeleteDeviceRequest,
    options?: AxiosRequestConfig,
  ) {
    return DeviceControllerApiFp(this.configuration)
      .deleteDevice(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Lấy tất cả thiết bị
   * @summary Lấy tất cả thiết bị
   * @param {DeviceControllerApiGetAllDevicesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceControllerApi
   */
  public getAllDevices(
    requestParameters: DeviceControllerApiGetAllDevicesRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return DeviceControllerApiFp(this.configuration)
      .getAllDevices(
        requestParameters.id,
        requestParameters.name,
        requestParameters.hardwareId,
        requestParameters.manToken,
        requestParameters.model,
        requestParameters.location,
        requestParameters.refUserId,
        requestParameters.online,
        requestParameters.size,
        requestParameters.page,
        requestParameters.sortBy,
        requestParameters.sortDir,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Lấy thiết bị
   * @summary Lấy 1 thiết bị
   * @param {DeviceControllerApiGetDeviceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceControllerApi
   */
  public getDevice(
    requestParameters: DeviceControllerApiGetDeviceRequest,
    options?: AxiosRequestConfig,
  ) {
    return DeviceControllerApiFp(this.configuration)
      .getDevice(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Kết nối thiết bị với user
   * @summary Kết nối thiết bị với user
   * @param {DeviceControllerApiLinkWithUserRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceControllerApi
   */
  public linkWithUser(
    requestParameters: DeviceControllerApiLinkWithUserRequest,
    options?: AxiosRequestConfig,
  ) {
    return DeviceControllerApiFp(this.configuration)
      .linkWithUser(requestParameters.linkWithUserRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Thêm mới 1 thiết bị
   * @summary Thêm mới 1 thiết bị
   * @param {DeviceControllerApiRegisterDeviceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceControllerApi
   */
  public registerDevice(
    requestParameters: DeviceControllerApiRegisterDeviceRequest,
    options?: AxiosRequestConfig,
  ) {
    return DeviceControllerApiFp(this.configuration)
      .registerDevice(requestParameters.registerDeviceRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Liên kết tk ngân hàng đến device
   * @param {DeviceControllerApiSetUpBankAccountRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceControllerApi
   */
  public setUpBankAccount(
    requestParameters: DeviceControllerApiSetUpBankAccountRequest,
    options?: AxiosRequestConfig,
  ) {
    return DeviceControllerApiFp(this.configuration)
      .setUpBankAccount(
        requestParameters.merchantId,
        requestParameters.bankAccountId,
        requestParameters.deviceId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Update thiết bị
   * @summary Update thiết bị
   * @param {DeviceControllerApiUpdateDeviceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceControllerApi
   */
  public updateDevice(
    requestParameters: DeviceControllerApiUpdateDeviceRequest,
    options?: AxiosRequestConfig,
  ) {
    return DeviceControllerApiFp(this.configuration)
      .updateDevice(
        requestParameters.id,
        requestParameters.updateDeviceRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ManufacturerControllerApi - axios parameter creator
 * @export
 */
export const ManufacturerControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * - Xóa manufacturer token
     * @summary Xóa manufacturer token
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("_delete", "id", id);
      const localVarPath = `/api/manufacturer/v1/delete/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Tạo mới 1 manufacturer token
     * @summary Tạo mới 1 manufacturer token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createManToken: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/manufacturer/v1/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Get tất cả manufacturer token
     * @summary Get tất cả manufacturer token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getManToken: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/manufacturer/v1/getAll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ManufacturerControllerApi - functional programming interface
 * @export
 */
export const ManufacturerControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    ManufacturerControllerApiAxiosParamCreator(configuration);
  return {
    /**
     * - Xóa manufacturer token
     * @summary Xóa manufacturer token
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async _delete(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseDeleteManufacturerTokenResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator._delete(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Tạo mới 1 manufacturer token
     * @summary Tạo mới 1 manufacturer token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createManToken(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseManufacturerTokenResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createManToken(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Get tất cả manufacturer token
     * @summary Get tất cả manufacturer token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getManToken(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetManufacturerTokenResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getManToken(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * ManufacturerControllerApi - factory interface
 * @export
 */
export const ManufacturerControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ManufacturerControllerApiFp(configuration);
  return {
    /**
     * - Xóa manufacturer token
     * @summary Xóa manufacturer token
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(
      id: string,
      options?: any,
    ): AxiosPromise<ResponseBaseDeleteManufacturerTokenResponse> {
      return localVarFp
        ._delete(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Tạo mới 1 manufacturer token
     * @summary Tạo mới 1 manufacturer token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createManToken(
      options?: any,
    ): AxiosPromise<ResponseBaseManufacturerTokenResponse> {
      return localVarFp
        .createManToken(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Get tất cả manufacturer token
     * @summary Get tất cả manufacturer token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getManToken(
      options?: any,
    ): AxiosPromise<ResponseBaseGetManufacturerTokenResponse> {
      return localVarFp
        .getManToken(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for _delete operation in ManufacturerControllerApi.
 * @export
 * @interface ManufacturerControllerApiDeleteRequest
 */
export interface ManufacturerControllerApiDeleteRequest {
  /**
   *
   * @type {string}
   * @memberof ManufacturerControllerApiDelete
   */
  readonly id: string;
}

/**
 * ManufacturerControllerApi - object-oriented interface
 * @export
 * @class ManufacturerControllerApi
 * @extends {BaseAPI}
 */
export class ManufacturerControllerApi extends BaseAPI {
  /**
   * - Xóa manufacturer token
   * @summary Xóa manufacturer token
   * @param {ManufacturerControllerApiDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ManufacturerControllerApi
   */
  public _delete(
    requestParameters: ManufacturerControllerApiDeleteRequest,
    options?: AxiosRequestConfig,
  ) {
    return ManufacturerControllerApiFp(this.configuration)
      ._delete(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Tạo mới 1 manufacturer token
   * @summary Tạo mới 1 manufacturer token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ManufacturerControllerApi
   */
  public createManToken(options?: AxiosRequestConfig) {
    return ManufacturerControllerApiFp(this.configuration)
      .createManToken(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Get tất cả manufacturer token
   * @summary Get tất cả manufacturer token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ManufacturerControllerApi
   */
  public getManToken(options?: AxiosRequestConfig) {
    return ManufacturerControllerApiFp(this.configuration)
      .getManToken(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ResourceControllerApi - axios parameter creator
 * @export
 */
export const ResourceControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Thêm mới 1 cặp K-V
     * @param {AddResourceRequest} addResourceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addResource: async (
      addResourceRequest: AddResourceRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'addResourceRequest' is not null or undefined
      assertParamExists(
        "addResource",
        "addResourceRequest",
        addResourceRequest,
      );
      const localVarPath = `/api/resource/v1/add`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        addResourceRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Thêm mới 1 cặp K-V (file)
     * @param {string} key
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addResourceFile: async (
      key: string,
      file: File,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'key' is not null or undefined
      assertParamExists("addResourceFile", "key", key);
      // verify required parameter 'file' is not null or undefined
      assertParamExists("addResourceFile", "file", file);
      const localVarPath = `/api/resource/v1/addFile`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (key !== undefined) {
        localVarFormParams.append("key", key as any);
      }

      if (file !== undefined) {
        localVarFormParams.append("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xóa 1 tài nguyên từ Key
     * @param {string} key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteResource: async (
      key: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'key' is not null or undefined
      assertParamExists("deleteResource", "key", key);
      const localVarPath = `/api/resource/v1/delete/{key}`.replace(
        `{${"key"}}`,
        encodeURIComponent(String(key)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy tất cả tài nguyên
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllResources: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/resource/v1/getAll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy tài nguyên từ Key
     * @param {string} key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getResource: async (
      key: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'key' is not null or undefined
      assertParamExists("getResource", "key", key);
      const localVarPath = `/api/resource/v1/get/{key}`.replace(
        `{${"key"}}`,
        encodeURIComponent(String(key)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ResourceControllerApi - functional programming interface
 * @export
 */
export const ResourceControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    ResourceControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Thêm mới 1 cặp K-V
     * @param {AddResourceRequest} addResourceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addResource(
      addResourceRequest: AddResourceRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseResourceResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addResource(
        addResourceRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Thêm mới 1 cặp K-V (file)
     * @param {string} key
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addResourceFile(
      key: string,
      file: File,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseResourceResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addResourceFile(
        key,
        file,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Xóa 1 tài nguyên từ Key
     * @param {string} key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteResource(
      key: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteResource(
        key,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy tất cả tài nguyên
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllResources(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetAllResourcesResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllResources(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy tài nguyên từ Key
     * @param {string} key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getResource(
      key: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseResourceResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getResource(
        key,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * ResourceControllerApi - factory interface
 * @export
 */
export const ResourceControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ResourceControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Thêm mới 1 cặp K-V
     * @param {AddResourceRequest} addResourceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addResource(
      addResourceRequest: AddResourceRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseResourceResponse> {
      return localVarFp
        .addResource(addResourceRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Thêm mới 1 cặp K-V (file)
     * @param {string} key
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addResourceFile(
      key: string,
      file: File,
      options?: any,
    ): AxiosPromise<ResponseBaseResourceResponse> {
      return localVarFp
        .addResourceFile(key, file, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xóa 1 tài nguyên từ Key
     * @param {string} key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteResource(
      key: string,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .deleteResource(key, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy tất cả tài nguyên
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllResources(
      options?: any,
    ): AxiosPromise<ResponseBaseGetAllResourcesResponse> {
      return localVarFp
        .getAllResources(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy tài nguyên từ Key
     * @param {string} key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getResource(
      key: string,
      options?: any,
    ): AxiosPromise<ResponseBaseResourceResponse> {
      return localVarFp
        .getResource(key, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for addResource operation in ResourceControllerApi.
 * @export
 * @interface ResourceControllerApiAddResourceRequest
 */
export interface ResourceControllerApiAddResourceRequest {
  /**
   *
   * @type {AddResourceRequest}
   * @memberof ResourceControllerApiAddResource
   */
  readonly addResourceRequest: AddResourceRequest;
}

/**
 * Request parameters for addResourceFile operation in ResourceControllerApi.
 * @export
 * @interface ResourceControllerApiAddResourceFileRequest
 */
export interface ResourceControllerApiAddResourceFileRequest {
  /**
   *
   * @type {string}
   * @memberof ResourceControllerApiAddResourceFile
   */
  readonly key: string;

  /**
   *
   * @type {File}
   * @memberof ResourceControllerApiAddResourceFile
   */
  readonly file: File;
}

/**
 * Request parameters for deleteResource operation in ResourceControllerApi.
 * @export
 * @interface ResourceControllerApiDeleteResourceRequest
 */
export interface ResourceControllerApiDeleteResourceRequest {
  /**
   *
   * @type {string}
   * @memberof ResourceControllerApiDeleteResource
   */
  readonly key: string;
}

/**
 * Request parameters for getResource operation in ResourceControllerApi.
 * @export
 * @interface ResourceControllerApiGetResourceRequest
 */
export interface ResourceControllerApiGetResourceRequest {
  /**
   *
   * @type {string}
   * @memberof ResourceControllerApiGetResource
   */
  readonly key: string;
}

/**
 * ResourceControllerApi - object-oriented interface
 * @export
 * @class ResourceControllerApi
 * @extends {BaseAPI}
 */
export class ResourceControllerApi extends BaseAPI {
  /**
   *
   * @summary Thêm mới 1 cặp K-V
   * @param {ResourceControllerApiAddResourceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResourceControllerApi
   */
  public addResource(
    requestParameters: ResourceControllerApiAddResourceRequest,
    options?: AxiosRequestConfig,
  ) {
    return ResourceControllerApiFp(this.configuration)
      .addResource(requestParameters.addResourceRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Thêm mới 1 cặp K-V (file)
   * @param {ResourceControllerApiAddResourceFileRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResourceControllerApi
   */
  public addResourceFile(
    requestParameters: ResourceControllerApiAddResourceFileRequest,
    options?: AxiosRequestConfig,
  ) {
    return ResourceControllerApiFp(this.configuration)
      .addResourceFile(requestParameters.key, requestParameters.file, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xóa 1 tài nguyên từ Key
   * @param {ResourceControllerApiDeleteResourceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResourceControllerApi
   */
  public deleteResource(
    requestParameters: ResourceControllerApiDeleteResourceRequest,
    options?: AxiosRequestConfig,
  ) {
    return ResourceControllerApiFp(this.configuration)
      .deleteResource(requestParameters.key, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy tất cả tài nguyên
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResourceControllerApi
   */
  public getAllResources(options?: AxiosRequestConfig) {
    return ResourceControllerApiFp(this.configuration)
      .getAllResources(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy tài nguyên từ Key
   * @param {ResourceControllerApiGetResourceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResourceControllerApi
   */
  public getResource(
    requestParameters: ResourceControllerApiGetResourceRequest,
    options?: AxiosRequestConfig,
  ) {
    return ResourceControllerApiFp(this.configuration)
      .getResource(requestParameters.key, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * StaticQrControllerApi - axios parameter creator
 * @export
 */
export const StaticQrControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Thêm mới mã qr
     * @param {string} deviceId
     * @param {string} [accountNumber]
     * @param {string} [accountName]
     * @param {string} [shopName]
     * @param {string} [content]
     * @param {string} [icon]
     * @param {string} [name]
     * @param {string} [shortName]
     * @param {string} [bankBin]
     * @param {string} [bankCode]
     * @param {string} [qrId]
     * @param {boolean} [defaultQr]
     * @param {string} [staticQrType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addQr: async (
      deviceId: string,
      accountNumber?: string,
      accountName?: string,
      shopName?: string,
      content?: string,
      icon?: string,
      name?: string,
      shortName?: string,
      bankBin?: string,
      bankCode?: string,
      qrId?: string,
      defaultQr?: boolean,
      staticQrType?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deviceId' is not null or undefined
      assertParamExists("addQr", "deviceId", deviceId);
      const localVarPath = `/api/staticQr/v1/add`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (deviceId !== undefined) {
        localVarFormParams.append("deviceId", deviceId as any);
      }

      if (accountNumber !== undefined) {
        localVarFormParams.append("accountNumber", accountNumber as any);
      }

      if (accountName !== undefined) {
        localVarFormParams.append("accountName", accountName as any);
      }

      if (shopName !== undefined) {
        localVarFormParams.append("shopName", shopName as any);
      }

      if (content !== undefined) {
        localVarFormParams.append("content", content as any);
      }

      if (icon !== undefined) {
        localVarFormParams.append("icon", icon as any);
      }

      if (name !== undefined) {
        localVarFormParams.append("name", name as any);
      }

      if (shortName !== undefined) {
        localVarFormParams.append("shortName", shortName as any);
      }

      if (bankBin !== undefined) {
        localVarFormParams.append("bankBin", bankBin as any);
      }

      if (bankCode !== undefined) {
        localVarFormParams.append("bankCode", bankCode as any);
      }

      if (qrId !== undefined) {
        localVarFormParams.append("qrId", qrId as any);
      }

      if (defaultQr !== undefined) {
        localVarFormParams.append("defaultQr", defaultQr as any);
      }

      if (defaultQr !== undefined) {
        localVarFormParams.append("defaultQr", defaultQr as any);
      }

      if (staticQrType !== undefined) {
        localVarFormParams.append("staticQrType", staticQrType as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xóa mã QR theo ID
     * @param {string} id
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteQrById: async (
      id: string,
      body: object,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("deleteQrById", "id", id);
      // verify required parameter 'body' is not null or undefined
      assertParamExists("deleteQrById", "body", body);
      const localVarPath = `/api/staticQr/v1/delete/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy tất cả mã qr
     * @param {string} [id]
     * @param {string} [deviceId]
     * @param {string} [name]
     * @param {string} [merchantId]
     * @param {'BANK' | 'E_WALLET' | 'UMEE'} [type]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllQr: async (
      id?: string,
      deviceId?: string,
      name?: string,
      merchantId?: string,
      type?: "BANK" | "E_WALLET" | "UMEE",
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/staticQr/v1/getAll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (id !== undefined) {
        localVarQueryParameter["id"] = id;
      }

      if (deviceId !== undefined) {
        localVarQueryParameter["deviceId"] = deviceId;
      }

      if (name !== undefined) {
        localVarQueryParameter["name"] = name;
      }

      if (merchantId !== undefined) {
        localVarQueryParameter["merchantId"] = merchantId;
      }

      if (type !== undefined) {
        localVarQueryParameter["type"] = type;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy mã QR theo ID
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getQrById: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getQrById", "id", id);
      const localVarPath = `/api/staticQr/v1/get/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Preview mã qr
     * @param {string} [staticQrId]
     * @param {string} [accountNumber]
     * @param {string} [accountName]
     * @param {string} [shopName]
     * @param {string} [icon]
     * @param {string} [name]
     * @param {string} [shortName]
     * @param {string} [bankBin]
     * @param {string} [bankCode]
     * @param {string} [qrId]
     * @param {string} [staticQrType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    preview: async (
      staticQrId?: string,
      accountNumber?: string,
      accountName?: string,
      shopName?: string,
      icon?: string,
      name?: string,
      shortName?: string,
      bankBin?: string,
      bankCode?: string,
      qrId?: string,
      staticQrType?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/staticQr/v1/preview`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (staticQrId !== undefined) {
        localVarFormParams.append("staticQrId", staticQrId as any);
      }

      if (accountNumber !== undefined) {
        localVarFormParams.append("accountNumber", accountNumber as any);
      }

      if (accountName !== undefined) {
        localVarFormParams.append("accountName", accountName as any);
      }

      if (shopName !== undefined) {
        localVarFormParams.append("shopName", shopName as any);
      }

      if (icon !== undefined) {
        localVarFormParams.append("icon", icon as any);
      }

      if (name !== undefined) {
        localVarFormParams.append("name", name as any);
      }

      if (shortName !== undefined) {
        localVarFormParams.append("shortName", shortName as any);
      }

      if (bankBin !== undefined) {
        localVarFormParams.append("bankBin", bankBin as any);
      }

      if (bankCode !== undefined) {
        localVarFormParams.append("bankCode", bankCode as any);
      }

      if (qrId !== undefined) {
        localVarFormParams.append("qrId", qrId as any);
      }

      if (staticQrType !== undefined) {
        localVarFormParams.append("staticQrType", staticQrType as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update mã QR theo ID
     * @param {string} id
     * @param {string} icon
     * @param {string} [accountNumber]
     * @param {string} [accountName]
     * @param {string} [shopName]
     * @param {string} [name]
     * @param {string} [shortName]
     * @param {string} [bankBin]
     * @param {string} [bankCode]
     * @param {string} [qrId]
     * @param {string} [staticQrType]
     * @param {boolean} [defaultQr]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateQrById: async (
      id: string,
      icon: string,
      accountNumber?: string,
      accountName?: string,
      shopName?: string,
      name?: string,
      shortName?: string,
      bankBin?: string,
      bankCode?: string,
      qrId?: string,
      staticQrType?: string,
      defaultQr?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("updateQrById", "id", id);
      // verify required parameter 'icon' is not null or undefined
      assertParamExists("updateQrById", "icon", icon);
      const localVarPath = `/api/staticQr/v1/update/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (accountNumber !== undefined) {
        localVarFormParams.append("accountNumber", accountNumber as any);
      }

      if (accountName !== undefined) {
        localVarFormParams.append("accountName", accountName as any);
      }

      if (shopName !== undefined) {
        localVarFormParams.append("shopName", shopName as any);
      }

      if (icon !== undefined) {
        localVarFormParams.append("icon", icon as any);
      }

      if (name !== undefined) {
        localVarFormParams.append("name", name as any);
      }

      if (shortName !== undefined) {
        localVarFormParams.append("shortName", shortName as any);
      }

      if (bankBin !== undefined) {
        localVarFormParams.append("bankBin", bankBin as any);
      }

      if (bankCode !== undefined) {
        localVarFormParams.append("bankCode", bankCode as any);
      }

      if (qrId !== undefined) {
        localVarFormParams.append("qrId", qrId as any);
      }

      if (staticQrType !== undefined) {
        localVarFormParams.append("staticQrType", staticQrType as any);
      }

      if (defaultQr !== undefined) {
        localVarFormParams.append("defaultQr", defaultQr as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Validate and detect QR
     * @param {File} image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validate: async (
      image: File,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'image' is not null or undefined
      assertParamExists("validate", "image", image);
      const localVarPath = `/api/staticQr/v1/validate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (image !== undefined) {
        localVarFormParams.append("image", image as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * StaticQrControllerApi - functional programming interface
 * @export
 */
export const StaticQrControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    StaticQrControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Thêm mới mã qr
     * @param {string} deviceId
     * @param {string} [accountNumber]
     * @param {string} [accountName]
     * @param {string} [shopName]
     * @param {string} [content]
     * @param {string} [icon]
     * @param {string} [name]
     * @param {string} [shortName]
     * @param {string} [bankBin]
     * @param {string} [bankCode]
     * @param {string} [qrId]
     * @param {boolean} [defaultQr]
     * @param {string} [staticQrType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addQr(
      deviceId: string,
      accountNumber?: string,
      accountName?: string,
      shopName?: string,
      content?: string,
      icon?: string,
      name?: string,
      shortName?: string,
      bankBin?: string,
      bankCode?: string,
      qrId?: string,
      defaultQr?: boolean,
      staticQrType?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStaticQrResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addQr(
        deviceId,
        accountNumber,
        accountName,
        shopName,
        content,
        icon,
        name,
        shortName,
        bankBin,
        bankCode,
        qrId,
        defaultQr,
        staticQrType,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Xóa mã QR theo ID
     * @param {string} id
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteQrById(
      id: string,
      body: object,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseDeleteStaticQrByIdResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteQrById(
        id,
        body,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy tất cả mã qr
     * @param {string} [id]
     * @param {string} [deviceId]
     * @param {string} [name]
     * @param {string} [merchantId]
     * @param {'BANK' | 'E_WALLET' | 'UMEE'} [type]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllQr(
      id?: string,
      deviceId?: string,
      name?: string,
      merchantId?: string,
      type?: "BANK" | "E_WALLET" | "UMEE",
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetAllStaticQrResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllQr(
        id,
        deviceId,
        name,
        merchantId,
        type,
        page,
        size,
        sort,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy mã QR theo ID
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getQrById(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStaticQrResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getQrById(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Preview mã qr
     * @param {string} [staticQrId]
     * @param {string} [accountNumber]
     * @param {string} [accountName]
     * @param {string} [shopName]
     * @param {string} [icon]
     * @param {string} [name]
     * @param {string} [shortName]
     * @param {string} [bankBin]
     * @param {string} [bankCode]
     * @param {string} [qrId]
     * @param {string} [staticQrType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async preview(
      staticQrId?: string,
      accountNumber?: string,
      accountName?: string,
      shopName?: string,
      icon?: string,
      name?: string,
      shortName?: string,
      bankBin?: string,
      bankCode?: string,
      qrId?: string,
      staticQrType?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePreviewQrResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.preview(
        staticQrId,
        accountNumber,
        accountName,
        shopName,
        icon,
        name,
        shortName,
        bankBin,
        bankCode,
        qrId,
        staticQrType,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update mã QR theo ID
     * @param {string} id
     * @param {string} icon
     * @param {string} [accountNumber]
     * @param {string} [accountName]
     * @param {string} [shopName]
     * @param {string} [name]
     * @param {string} [shortName]
     * @param {string} [bankBin]
     * @param {string} [bankCode]
     * @param {string} [qrId]
     * @param {string} [staticQrType]
     * @param {boolean} [defaultQr]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateQrById(
      id: string,
      icon: string,
      accountNumber?: string,
      accountName?: string,
      shopName?: string,
      name?: string,
      shortName?: string,
      bankBin?: string,
      bankCode?: string,
      qrId?: string,
      staticQrType?: string,
      defaultQr?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStaticQrResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateQrById(
        id,
        icon,
        accountNumber,
        accountName,
        shopName,
        name,
        shortName,
        bankBin,
        bankCode,
        qrId,
        staticQrType,
        defaultQr,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Validate and detect QR
     * @param {File} image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async validate(
      image: File,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseValidateQrResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.validate(
        image,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * StaticQrControllerApi - factory interface
 * @export
 */
export const StaticQrControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = StaticQrControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Thêm mới mã qr
     * @param {string} deviceId
     * @param {string} [accountNumber]
     * @param {string} [accountName]
     * @param {string} [shopName]
     * @param {string} [content]
     * @param {string} [icon]
     * @param {string} [name]
     * @param {string} [shortName]
     * @param {string} [bankBin]
     * @param {string} [bankCode]
     * @param {string} [qrId]
     * @param {boolean} [defaultQr]
     * @param {string} [staticQrType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addQr(
      deviceId: string,
      accountNumber?: string,
      accountName?: string,
      shopName?: string,
      content?: string,
      icon?: string,
      name?: string,
      shortName?: string,
      bankBin?: string,
      bankCode?: string,
      qrId?: string,
      defaultQr?: boolean,
      staticQrType?: string,
      options?: any,
    ): AxiosPromise<ResponseBaseStaticQrResponse> {
      return localVarFp
        .addQr(
          deviceId,
          accountNumber,
          accountName,
          shopName,
          content,
          icon,
          name,
          shortName,
          bankBin,
          bankCode,
          qrId,
          defaultQr,
          staticQrType,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xóa mã QR theo ID
     * @param {string} id
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteQrById(
      id: string,
      body: object,
      options?: any,
    ): AxiosPromise<ResponseBaseDeleteStaticQrByIdResponse> {
      return localVarFp
        .deleteQrById(id, body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy tất cả mã qr
     * @param {string} [id]
     * @param {string} [deviceId]
     * @param {string} [name]
     * @param {string} [merchantId]
     * @param {'BANK' | 'E_WALLET' | 'UMEE'} [type]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllQr(
      id?: string,
      deviceId?: string,
      name?: string,
      merchantId?: string,
      type?: "BANK" | "E_WALLET" | "UMEE",
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any,
    ): AxiosPromise<ResponseBaseGetAllStaticQrResponse> {
      return localVarFp
        .getAllQr(
          id,
          deviceId,
          name,
          merchantId,
          type,
          page,
          size,
          sort,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy mã QR theo ID
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getQrById(
      id: string,
      options?: any,
    ): AxiosPromise<ResponseBaseStaticQrResponse> {
      return localVarFp
        .getQrById(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Preview mã qr
     * @param {string} [staticQrId]
     * @param {string} [accountNumber]
     * @param {string} [accountName]
     * @param {string} [shopName]
     * @param {string} [icon]
     * @param {string} [name]
     * @param {string} [shortName]
     * @param {string} [bankBin]
     * @param {string} [bankCode]
     * @param {string} [qrId]
     * @param {string} [staticQrType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    preview(
      staticQrId?: string,
      accountNumber?: string,
      accountName?: string,
      shopName?: string,
      icon?: string,
      name?: string,
      shortName?: string,
      bankBin?: string,
      bankCode?: string,
      qrId?: string,
      staticQrType?: string,
      options?: any,
    ): AxiosPromise<ResponseBasePreviewQrResponse> {
      return localVarFp
        .preview(
          staticQrId,
          accountNumber,
          accountName,
          shopName,
          icon,
          name,
          shortName,
          bankBin,
          bankCode,
          qrId,
          staticQrType,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update mã QR theo ID
     * @param {string} id
     * @param {string} icon
     * @param {string} [accountNumber]
     * @param {string} [accountName]
     * @param {string} [shopName]
     * @param {string} [name]
     * @param {string} [shortName]
     * @param {string} [bankBin]
     * @param {string} [bankCode]
     * @param {string} [qrId]
     * @param {string} [staticQrType]
     * @param {boolean} [defaultQr]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateQrById(
      id: string,
      icon: string,
      accountNumber?: string,
      accountName?: string,
      shopName?: string,
      name?: string,
      shortName?: string,
      bankBin?: string,
      bankCode?: string,
      qrId?: string,
      staticQrType?: string,
      defaultQr?: boolean,
      options?: any,
    ): AxiosPromise<ResponseBaseStaticQrResponse> {
      return localVarFp
        .updateQrById(
          id,
          icon,
          accountNumber,
          accountName,
          shopName,
          name,
          shortName,
          bankBin,
          bankCode,
          qrId,
          staticQrType,
          defaultQr,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Validate and detect QR
     * @param {File} image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validate(
      image: File,
      options?: any,
    ): AxiosPromise<ResponseBaseValidateQrResponse> {
      return localVarFp
        .validate(image, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for addQr operation in StaticQrControllerApi.
 * @export
 * @interface StaticQrControllerApiAddQrRequest
 */
export interface StaticQrControllerApiAddQrRequest {
  /**
   *
   * @type {string}
   * @memberof StaticQrControllerApiAddQr
   */
  readonly deviceId: string;

  /**
   *
   * @type {string}
   * @memberof StaticQrControllerApiAddQr
   */
  readonly accountNumber?: string;

  /**
   *
   * @type {string}
   * @memberof StaticQrControllerApiAddQr
   */
  readonly accountName?: string;

  /**
   *
   * @type {string}
   * @memberof StaticQrControllerApiAddQr
   */
  readonly shopName?: string;

  /**
   *
   * @type {string}
   * @memberof StaticQrControllerApiAddQr
   */
  readonly content?: string;

  /**
   *
   * @type {string}
   * @memberof StaticQrControllerApiAddQr
   */
  readonly icon?: string;

  /**
   *
   * @type {string}
   * @memberof StaticQrControllerApiAddQr
   */
  readonly name?: string;

  /**
   *
   * @type {string}
   * @memberof StaticQrControllerApiAddQr
   */
  readonly shortName?: string;

  /**
   *
   * @type {string}
   * @memberof StaticQrControllerApiAddQr
   */
  readonly bankBin?: string;

  /**
   *
   * @type {string}
   * @memberof StaticQrControllerApiAddQr
   */
  readonly bankCode?: string;

  /**
   *
   * @type {File}
   * @memberof StaticQrControllerApiAddQr
   */
  readonly qrId?: string;

  /**
   *
   * @type {boolean}
   * @memberof StaticQrControllerApiAddQr
   */
  readonly defaultQr?: boolean;

  /**
   *
   * @type {string}
   * @memberof StaticQrControllerApiAddQr
   */
  readonly staticQrType?: string;
}

/**
 * Request parameters for deleteQrById operation in StaticQrControllerApi.
 * @export
 * @interface StaticQrControllerApiDeleteQrByIdRequest
 */
export interface StaticQrControllerApiDeleteQrByIdRequest {
  /**
   *
   * @type {string}
   * @memberof StaticQrControllerApiDeleteQrById
   */
  readonly id: string;

  /**
   *
   * @type {object}
   * @memberof StaticQrControllerApiDeleteQrById
   */
  readonly body: object;
}

/**
 * Request parameters for getAllQr operation in StaticQrControllerApi.
 * @export
 * @interface StaticQrControllerApiGetAllQrRequest
 */
export interface StaticQrControllerApiGetAllQrRequest {
  /**
   *
   * @type {string}
   * @memberof StaticQrControllerApiGetAllQr
   */
  readonly id?: string;

  /**
   *
   * @type {string}
   * @memberof StaticQrControllerApiGetAllQr
   */
  readonly deviceId?: string;

  /**
   *
   * @type {string}
   * @memberof StaticQrControllerApiGetAllQr
   */
  readonly name?: string;

  /**
   *
   * @type {string}
   * @memberof StaticQrControllerApiGetAllQr
   */
  readonly merchantId?: string;

  /**
   *
   * @type {'BANK' | 'E_WALLET' | 'UMEE'}
   * @memberof StaticQrControllerApiGetAllQr
   */
  readonly type?: "BANK" | "E_WALLET" | "UMEE";

  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof StaticQrControllerApiGetAllQr
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof StaticQrControllerApiGetAllQr
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof StaticQrControllerApiGetAllQr
   */
  readonly sort?: Array<string>;
}

/**
 * Request parameters for getQrById operation in StaticQrControllerApi.
 * @export
 * @interface StaticQrControllerApiGetQrByIdRequest
 */
export interface StaticQrControllerApiGetQrByIdRequest {
  /**
   *
   * @type {string}
   * @memberof StaticQrControllerApiGetQrById
   */
  readonly id: string;
}

/**
 * Request parameters for preview operation in StaticQrControllerApi.
 * @export
 * @interface StaticQrControllerApiPreviewRequest
 */
export interface StaticQrControllerApiPreviewRequest {
  /**
   *
   * @type {string}
   * @memberof StaticQrControllerApiPreview
   */
  readonly staticQrId?: string;

  /**
   *
   * @type {string}
   * @memberof StaticQrControllerApiPreview
   */
  readonly accountNumber?: string;

  /**
   *
   * @type {string}
   * @memberof StaticQrControllerApiPreview
   */
  readonly accountName?: string;

  /**
   *
   * @type {string}
   * @memberof StaticQrControllerApiPreview
   */
  readonly shopName?: string;

  /**
   *
   * @type {string}
   * @memberof StaticQrControllerApiPreview
   */
  readonly icon?: string;

  /**
   *
   * @type {string}
   * @memberof StaticQrControllerApiPreview
   */
  readonly name?: string;

  /**
   *
   * @type {string}
   * @memberof StaticQrControllerApiPreview
   */
  readonly shortName?: string;

  /**
   *
   * @type {string}
   * @memberof StaticQrControllerApiPreview
   */
  readonly bankBin?: string;

  /**
   *
   * @type {string}
   * @memberof StaticQrControllerApiPreview
   */
  readonly bankCode?: string;

  /**
   *
   * @type {string}
   * @memberof StaticQrControllerApiPreview
   */
  readonly qrId?: string;

  /**
   *
   * @type {string}
   * @memberof StaticQrControllerApiPreview
   */
  readonly staticQrType?: string;
}

/**
 * Request parameters for updateQrById operation in StaticQrControllerApi.
 * @export
 * @interface StaticQrControllerApiUpdateQrByIdRequest
 */
export interface StaticQrControllerApiUpdateQrByIdRequest {
  /**
   *
   * @type {string}
   * @memberof StaticQrControllerApiUpdateQrById
   */
  readonly id: string;

  /**
   *
   * @type {string}
   * @memberof StaticQrControllerApiUpdateQrById
   */
  readonly icon: string;

  /**
   *
   * @type {string}
   * @memberof StaticQrControllerApiUpdateQrById
   */
  readonly accountNumber?: string;

  /**
   *
   * @type {string}
   * @memberof StaticQrControllerApiUpdateQrById
   */
  readonly accountName?: string;

  /**
   *
   * @type {string}
   * @memberof StaticQrControllerApiUpdateQrById
   */
  readonly shopName?: string;

  /**
   *
   * @type {string}
   * @memberof StaticQrControllerApiUpdateQrById
   */
  readonly name?: string;

  /**
   *
   * @type {string}
   * @memberof StaticQrControllerApiUpdateQrById
   */
  readonly shortName?: string;

  /**
   *
   * @type {string}
   * @memberof StaticQrControllerApiUpdateQrById
   */
  readonly bankBin?: string;

  /**
   *
   * @type {string}
   * @memberof StaticQrControllerApiUpdateQrById
   */
  readonly bankCode?: string;

  /**
   *
   * @type {string}
   * @memberof StaticQrControllerApiUpdateQrById
   */
  readonly qrId?: string;

  /**
   *
   * @type {string}
   * @memberof StaticQrControllerApiUpdateQrById
   */
  readonly staticQrType?: string;

  /**
   *
   * @type {boolean}
   * @memberof StaticQrControllerApiUpdateQrById
   */
  readonly defaultQr?: boolean;
}

/**
 * Request parameters for validate operation in StaticQrControllerApi.
 * @export
 * @interface StaticQrControllerApiValidateRequest
 */
export interface StaticQrControllerApiValidateRequest {
  /**
   *
   * @type {File}
   * @memberof StaticQrControllerApiValidate
   */
  readonly image: File;
}

/**
 * StaticQrControllerApi - object-oriented interface
 * @export
 * @class StaticQrControllerApi
 * @extends {BaseAPI}
 */
export class StaticQrControllerApi extends BaseAPI {
  /**
   *
   * @summary Thêm mới mã qr
   * @param {StaticQrControllerApiAddQrRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StaticQrControllerApi
   */
  public addQr(
    requestParameters: StaticQrControllerApiAddQrRequest,
    options?: AxiosRequestConfig,
  ) {
    return StaticQrControllerApiFp(this.configuration)
      .addQr(
        requestParameters.deviceId,
        requestParameters.accountNumber,
        requestParameters.accountName,
        requestParameters.shopName,
        requestParameters.content,
        requestParameters.icon,
        requestParameters.name,
        requestParameters.shortName,
        requestParameters.bankBin,
        requestParameters.bankCode,
        requestParameters.qrId,
        requestParameters.defaultQr,
        requestParameters.staticQrType,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xóa mã QR theo ID
   * @param {StaticQrControllerApiDeleteQrByIdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StaticQrControllerApi
   */
  public deleteQrById(
    requestParameters: StaticQrControllerApiDeleteQrByIdRequest,
    options?: AxiosRequestConfig,
  ) {
    return StaticQrControllerApiFp(this.configuration)
      .deleteQrById(requestParameters.id, requestParameters.body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy tất cả mã qr
   * @param {StaticQrControllerApiGetAllQrRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StaticQrControllerApi
   */
  public getAllQr(
    requestParameters: StaticQrControllerApiGetAllQrRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return StaticQrControllerApiFp(this.configuration)
      .getAllQr(
        requestParameters.id,
        requestParameters.deviceId,
        requestParameters.name,
        requestParameters.merchantId,
        requestParameters.type,
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy mã QR theo ID
   * @param {StaticQrControllerApiGetQrByIdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StaticQrControllerApi
   */
  public getQrById(
    requestParameters: StaticQrControllerApiGetQrByIdRequest,
    options?: AxiosRequestConfig,
  ) {
    return StaticQrControllerApiFp(this.configuration)
      .getQrById(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Preview mã qr
   * @param {StaticQrControllerApiPreviewRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StaticQrControllerApi
   */
  public preview(
    requestParameters: StaticQrControllerApiPreviewRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return StaticQrControllerApiFp(this.configuration)
      .preview(
        requestParameters.staticQrId,
        requestParameters.accountNumber,
        requestParameters.accountName,
        requestParameters.shopName,
        requestParameters.icon,
        requestParameters.name,
        requestParameters.shortName,
        requestParameters.bankBin,
        requestParameters.bankCode,
        requestParameters.qrId,
        requestParameters.staticQrType,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update mã QR theo ID
   * @param {StaticQrControllerApiUpdateQrByIdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StaticQrControllerApi
   */
  public updateQrById(
    requestParameters: StaticQrControllerApiUpdateQrByIdRequest,
    options?: AxiosRequestConfig,
  ) {
    return StaticQrControllerApiFp(this.configuration)
      .updateQrById(
        requestParameters.id,
        requestParameters.icon,
        requestParameters.accountNumber,
        requestParameters.accountName,
        requestParameters.shopName,
        requestParameters.name,
        requestParameters.shortName,
        requestParameters.bankBin,
        requestParameters.bankCode,
        requestParameters.qrId,
        requestParameters.staticQrType,
        requestParameters.defaultQr,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Validate and detect QR
   * @param {StaticQrControllerApiValidateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StaticQrControllerApi
   */
  public validate(
    requestParameters: StaticQrControllerApiValidateRequest,
    options?: AxiosRequestConfig,
  ) {
    return StaticQrControllerApiFp(this.configuration)
      .validate(requestParameters.image, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * TransactionControllerApi - axios parameter creator
 * @export
 */
export const TransactionControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Notify Transaction To Device
     * @param {NotifyTransactionRequest} notifyTransactionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notifyTransaction: async (
      notifyTransactionRequest: NotifyTransactionRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'notifyTransactionRequest' is not null or undefined
      assertParamExists(
        "notifyTransaction",
        "notifyTransactionRequest",
        notifyTransactionRequest,
      );
      const localVarPath = `/api/transaction/v1/notify`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        notifyTransactionRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Show Transaction To Device
     * @param {ShowTransactionRequest} showTransactionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    showTransaction: async (
      showTransactionRequest: ShowTransactionRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'showTransactionRequest' is not null or undefined
      assertParamExists(
        "showTransaction",
        "showTransactionRequest",
        showTransactionRequest,
      );
      const localVarPath = `/api/transaction/v1/show`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        showTransactionRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TransactionControllerApi - functional programming interface
 * @export
 */
export const TransactionControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    TransactionControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Notify Transaction To Device
     * @param {NotifyTransactionRequest} notifyTransactionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notifyTransaction(
      notifyTransactionRequest: NotifyTransactionRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.notifyTransaction(
          notifyTransactionRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Show Transaction To Device
     * @param {ShowTransactionRequest} showTransactionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async showTransaction(
      showTransactionRequest: ShowTransactionRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.showTransaction(
        showTransactionRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * TransactionControllerApi - factory interface
 * @export
 */
export const TransactionControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TransactionControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Notify Transaction To Device
     * @param {NotifyTransactionRequest} notifyTransactionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notifyTransaction(
      notifyTransactionRequest: NotifyTransactionRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .notifyTransaction(notifyTransactionRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Show Transaction To Device
     * @param {ShowTransactionRequest} showTransactionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    showTransaction(
      showTransactionRequest: ShowTransactionRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .showTransaction(showTransactionRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for notifyTransaction operation in TransactionControllerApi.
 * @export
 * @interface TransactionControllerApiNotifyTransactionRequest
 */
export interface TransactionControllerApiNotifyTransactionRequest {
  /**
   *
   * @type {NotifyTransactionRequest}
   * @memberof TransactionControllerApiNotifyTransaction
   */
  readonly notifyTransactionRequest: NotifyTransactionRequest;
}

/**
 * Request parameters for showTransaction operation in TransactionControllerApi.
 * @export
 * @interface TransactionControllerApiShowTransactionRequest
 */
export interface TransactionControllerApiShowTransactionRequest {
  /**
   *
   * @type {ShowTransactionRequest}
   * @memberof TransactionControllerApiShowTransaction
   */
  readonly showTransactionRequest: ShowTransactionRequest;
}

/**
 * TransactionControllerApi - object-oriented interface
 * @export
 * @class TransactionControllerApi
 * @extends {BaseAPI}
 */
export class TransactionControllerApi extends BaseAPI {
  /**
   *
   * @summary Notify Transaction To Device
   * @param {TransactionControllerApiNotifyTransactionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionControllerApi
   */
  public notifyTransaction(
    requestParameters: TransactionControllerApiNotifyTransactionRequest,
    options?: AxiosRequestConfig,
  ) {
    return TransactionControllerApiFp(this.configuration)
      .notifyTransaction(requestParameters.notifyTransactionRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Show Transaction To Device
   * @param {TransactionControllerApiShowTransactionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionControllerApi
   */
  public showTransaction(
    requestParameters: TransactionControllerApiShowTransactionRequest,
    options?: AxiosRequestConfig,
  ) {
    return TransactionControllerApiFp(this.configuration)
      .showTransaction(requestParameters.showTransactionRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UmeeControllerApi - axios parameter creator
 * @export
 */
export const UmeeControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Xác thực umee
     * @param {UmeeAuthenticateRequest} umeeAuthenticateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authenticate: async (
      umeeAuthenticateRequest: UmeeAuthenticateRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'umeeAuthenticateRequest' is not null or undefined
      assertParamExists(
        "authenticate",
        "umeeAuthenticateRequest",
        umeeAuthenticateRequest,
      );
      const localVarPath = `/api/umee/v1/authenticate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        umeeAuthenticateRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Pair With Umee
     * @summary Pair With Umee
     * @param {string} deviceId
     * @param {string} pairingKey
     * @param {string} [refUserId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pairWithUmee: async (
      deviceId: string,
      pairingKey: string,
      refUserId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deviceId' is not null or undefined
      assertParamExists("pairWithUmee", "deviceId", deviceId);
      // verify required parameter 'pairingKey' is not null or undefined
      assertParamExists("pairWithUmee", "pairingKey", pairingKey);
      const localVarPath = `/api/umee/v1/pair`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (deviceId !== undefined) {
        localVarQueryParameter["deviceId"] = deviceId;
      }

      if (pairingKey !== undefined) {
        localVarQueryParameter["pairingKey"] = pairingKey;
      }

      if (refUserId !== undefined) {
        localVarQueryParameter["refUserId"] = refUserId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Pair With Umee using POST
     * @param {UmeePostPairRequest} umeePostPairRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pairWithUmeeV2: async (
      umeePostPairRequest: UmeePostPairRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'umeePostPairRequest' is not null or undefined
      assertParamExists(
        "pairWithUmeeV2",
        "umeePostPairRequest",
        umeePostPairRequest,
      );
      const localVarPath = `/api/umee/v1/pair`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        umeePostPairRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy thông tin thiết bị Paybox
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    umeeGetDevice: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("umeeGetDevice", "id", id);
      const localVarPath = `/api/umee/v1/getDevice/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Umee unpair
     * @param {UmeeUnpairRequest} umeeUnpairRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unpairWithUmee: async (
      umeeUnpairRequest: UmeeUnpairRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'umeeUnpairRequest' is not null or undefined
      assertParamExists(
        "unpairWithUmee",
        "umeeUnpairRequest",
        umeeUnpairRequest,
      );
      const localVarPath = `/api/umee/v1/unpair`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        umeeUnpairRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UmeeControllerApi - functional programming interface
 * @export
 */
export const UmeeControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    UmeeControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Xác thực umee
     * @param {UmeeAuthenticateRequest} umeeAuthenticateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authenticate(
      umeeAuthenticateRequest: UmeeAuthenticateRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authenticate(
        umeeAuthenticateRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Pair With Umee
     * @summary Pair With Umee
     * @param {string} deviceId
     * @param {string} pairingKey
     * @param {string} [refUserId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async pairWithUmee(
      deviceId: string,
      pairingKey: string,
      refUserId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePairWithUmeeResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.pairWithUmee(
        deviceId,
        pairingKey,
        refUserId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Pair With Umee using POST
     * @param {UmeePostPairRequest} umeePostPairRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async pairWithUmeeV2(
      umeePostPairRequest: UmeePostPairRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseUmeePostPairResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.pairWithUmeeV2(
        umeePostPairRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy thông tin thiết bị Paybox
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async umeeGetDevice(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseDeviceResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.umeeGetDevice(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Umee unpair
     * @param {UmeeUnpairRequest} umeeUnpairRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async unpairWithUmee(
      umeeUnpairRequest: UmeeUnpairRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.unpairWithUmee(
        umeeUnpairRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * UmeeControllerApi - factory interface
 * @export
 */
export const UmeeControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = UmeeControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Xác thực umee
     * @param {UmeeAuthenticateRequest} umeeAuthenticateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authenticate(
      umeeAuthenticateRequest: UmeeAuthenticateRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .authenticate(umeeAuthenticateRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Pair With Umee
     * @summary Pair With Umee
     * @param {string} deviceId
     * @param {string} pairingKey
     * @param {string} [refUserId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pairWithUmee(
      deviceId: string,
      pairingKey: string,
      refUserId?: string,
      options?: any,
    ): AxiosPromise<ResponseBasePairWithUmeeResponse> {
      return localVarFp
        .pairWithUmee(deviceId, pairingKey, refUserId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Pair With Umee using POST
     * @param {UmeePostPairRequest} umeePostPairRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pairWithUmeeV2(
      umeePostPairRequest: UmeePostPairRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseUmeePostPairResponse> {
      return localVarFp
        .pairWithUmeeV2(umeePostPairRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy thông tin thiết bị Paybox
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    umeeGetDevice(
      id: string,
      options?: any,
    ): AxiosPromise<ResponseBaseDeviceResponse> {
      return localVarFp
        .umeeGetDevice(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Umee unpair
     * @param {UmeeUnpairRequest} umeeUnpairRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unpairWithUmee(
      umeeUnpairRequest: UmeeUnpairRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .unpairWithUmee(umeeUnpairRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for authenticate operation in UmeeControllerApi.
 * @export
 * @interface UmeeControllerApiAuthenticateRequest
 */
export interface UmeeControllerApiAuthenticateRequest {
  /**
   *
   * @type {UmeeAuthenticateRequest}
   * @memberof UmeeControllerApiAuthenticate
   */
  readonly umeeAuthenticateRequest: UmeeAuthenticateRequest;
}

/**
 * Request parameters for pairWithUmee operation in UmeeControllerApi.
 * @export
 * @interface UmeeControllerApiPairWithUmeeRequest
 */
export interface UmeeControllerApiPairWithUmeeRequest {
  /**
   *
   * @type {string}
   * @memberof UmeeControllerApiPairWithUmee
   */
  readonly deviceId: string;

  /**
   *
   * @type {string}
   * @memberof UmeeControllerApiPairWithUmee
   */
  readonly pairingKey: string;

  /**
   *
   * @type {string}
   * @memberof UmeeControllerApiPairWithUmee
   */
  readonly refUserId?: string;
}

/**
 * Request parameters for pairWithUmeeV2 operation in UmeeControllerApi.
 * @export
 * @interface UmeeControllerApiPairWithUmeeV2Request
 */
export interface UmeeControllerApiPairWithUmeeV2Request {
  /**
   *
   * @type {UmeePostPairRequest}
   * @memberof UmeeControllerApiPairWithUmeeV2
   */
  readonly umeePostPairRequest: UmeePostPairRequest;
}

/**
 * Request parameters for umeeGetDevice operation in UmeeControllerApi.
 * @export
 * @interface UmeeControllerApiUmeeGetDeviceRequest
 */
export interface UmeeControllerApiUmeeGetDeviceRequest {
  /**
   *
   * @type {string}
   * @memberof UmeeControllerApiUmeeGetDevice
   */
  readonly id: string;
}

/**
 * Request parameters for unpairWithUmee operation in UmeeControllerApi.
 * @export
 * @interface UmeeControllerApiUnpairWithUmeeRequest
 */
export interface UmeeControllerApiUnpairWithUmeeRequest {
  /**
   *
   * @type {UmeeUnpairRequest}
   * @memberof UmeeControllerApiUnpairWithUmee
   */
  readonly umeeUnpairRequest: UmeeUnpairRequest;
}

/**
 * UmeeControllerApi - object-oriented interface
 * @export
 * @class UmeeControllerApi
 * @extends {BaseAPI}
 */
export class UmeeControllerApi extends BaseAPI {
  /**
   *
   * @summary Xác thực umee
   * @param {UmeeControllerApiAuthenticateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UmeeControllerApi
   */
  public authenticate(
    requestParameters: UmeeControllerApiAuthenticateRequest,
    options?: AxiosRequestConfig,
  ) {
    return UmeeControllerApiFp(this.configuration)
      .authenticate(requestParameters.umeeAuthenticateRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Pair With Umee
   * @summary Pair With Umee
   * @param {UmeeControllerApiPairWithUmeeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UmeeControllerApi
   */
  public pairWithUmee(
    requestParameters: UmeeControllerApiPairWithUmeeRequest,
    options?: AxiosRequestConfig,
  ) {
    return UmeeControllerApiFp(this.configuration)
      .pairWithUmee(
        requestParameters.deviceId,
        requestParameters.pairingKey,
        requestParameters.refUserId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Pair With Umee using POST
   * @param {UmeeControllerApiPairWithUmeeV2Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UmeeControllerApi
   */
  public pairWithUmeeV2(
    requestParameters: UmeeControllerApiPairWithUmeeV2Request,
    options?: AxiosRequestConfig,
  ) {
    return UmeeControllerApiFp(this.configuration)
      .pairWithUmeeV2(requestParameters.umeePostPairRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy thông tin thiết bị Paybox
   * @param {UmeeControllerApiUmeeGetDeviceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UmeeControllerApi
   */
  public umeeGetDevice(
    requestParameters: UmeeControllerApiUmeeGetDeviceRequest,
    options?: AxiosRequestConfig,
  ) {
    return UmeeControllerApiFp(this.configuration)
      .umeeGetDevice(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Umee unpair
   * @param {UmeeControllerApiUnpairWithUmeeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UmeeControllerApi
   */
  public unpairWithUmee(
    requestParameters: UmeeControllerApiUnpairWithUmeeRequest,
    options?: AxiosRequestConfig,
  ) {
    return UmeeControllerApiFp(this.configuration)
      .unpairWithUmee(requestParameters.umeeUnpairRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UmeePayControllerApi - axios parameter creator
 * @export
 */
export const UmeePayControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * - Lấy tất cả thiết bị
     * @summary Lấy tất cả thiết bị
     * @param {string} [id]
     * @param {string} [name]
     * @param {string} [hardwareId]
     * @param {string} [manToken]
     * @param {string} [model]
     * @param {string} [location]
     * @param {string} [refUserId]
     * @param {boolean} [online]
     * @param {number} [size]
     * @param {number} [page]
     * @param {string} [sortBy]
     * @param {string} [sortDir]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upGetAllDevices: async (
      id?: string,
      name?: string,
      hardwareId?: string,
      manToken?: string,
      model?: string,
      location?: string,
      refUserId?: string,
      online?: boolean,
      size?: number,
      page?: number,
      sortBy?: string,
      sortDir?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/umee-pay/device/v1/getAll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (id !== undefined) {
        localVarQueryParameter["id"] = id;
      }

      if (name !== undefined) {
        localVarQueryParameter["name"] = name;
      }

      if (hardwareId !== undefined) {
        localVarQueryParameter["hardwareId"] = hardwareId;
      }

      if (manToken !== undefined) {
        localVarQueryParameter["manToken"] = manToken;
      }

      if (model !== undefined) {
        localVarQueryParameter["model"] = model;
      }

      if (location !== undefined) {
        localVarQueryParameter["location"] = location;
      }

      if (refUserId !== undefined) {
        localVarQueryParameter["refUserId"] = refUserId;
      }

      if (online !== undefined) {
        localVarQueryParameter["online"] = online;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter["sortBy"] = sortBy;
      }

      if (sortDir !== undefined) {
        localVarQueryParameter["sortDir"] = sortDir;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Notify Transaction To Device
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upGetDevice: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("upGetDevice", "id", id);
      const localVarPath = `/api/umee-pay/device/v1/get/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UmeePayControllerApi - functional programming interface
 * @export
 */
export const UmeePayControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    UmeePayControllerApiAxiosParamCreator(configuration);
  return {
    /**
     * - Lấy tất cả thiết bị
     * @summary Lấy tất cả thiết bị
     * @param {string} [id]
     * @param {string} [name]
     * @param {string} [hardwareId]
     * @param {string} [manToken]
     * @param {string} [model]
     * @param {string} [location]
     * @param {string} [refUserId]
     * @param {boolean} [online]
     * @param {number} [size]
     * @param {number} [page]
     * @param {string} [sortBy]
     * @param {string} [sortDir]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async upGetAllDevices(
      id?: string,
      name?: string,
      hardwareId?: string,
      manToken?: string,
      model?: string,
      location?: string,
      refUserId?: string,
      online?: boolean,
      size?: number,
      page?: number,
      sortBy?: string,
      sortDir?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetAllDeviceResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.upGetAllDevices(
        id,
        name,
        hardwareId,
        manToken,
        model,
        location,
        refUserId,
        online,
        size,
        page,
        sortBy,
        sortDir,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Notify Transaction To Device
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async upGetDevice(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseDeviceResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.upGetDevice(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * UmeePayControllerApi - factory interface
 * @export
 */
export const UmeePayControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = UmeePayControllerApiFp(configuration);
  return {
    /**
     * - Lấy tất cả thiết bị
     * @summary Lấy tất cả thiết bị
     * @param {string} [id]
     * @param {string} [name]
     * @param {string} [hardwareId]
     * @param {string} [manToken]
     * @param {string} [model]
     * @param {string} [location]
     * @param {string} [refUserId]
     * @param {boolean} [online]
     * @param {number} [size]
     * @param {number} [page]
     * @param {string} [sortBy]
     * @param {string} [sortDir]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upGetAllDevices(
      id?: string,
      name?: string,
      hardwareId?: string,
      manToken?: string,
      model?: string,
      location?: string,
      refUserId?: string,
      online?: boolean,
      size?: number,
      page?: number,
      sortBy?: string,
      sortDir?: string,
      options?: any,
    ): AxiosPromise<ResponseBaseGetAllDeviceResponse> {
      return localVarFp
        .upGetAllDevices(
          id,
          name,
          hardwareId,
          manToken,
          model,
          location,
          refUserId,
          online,
          size,
          page,
          sortBy,
          sortDir,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Notify Transaction To Device
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upGetDevice(
      id: string,
      options?: any,
    ): AxiosPromise<ResponseBaseDeviceResponse> {
      return localVarFp
        .upGetDevice(id, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for upGetAllDevices operation in UmeePayControllerApi.
 * @export
 * @interface UmeePayControllerApiUpGetAllDevicesRequest
 */
export interface UmeePayControllerApiUpGetAllDevicesRequest {
  /**
   *
   * @type {string}
   * @memberof UmeePayControllerApiUpGetAllDevices
   */
  readonly id?: string;

  /**
   *
   * @type {string}
   * @memberof UmeePayControllerApiUpGetAllDevices
   */
  readonly name?: string;

  /**
   *
   * @type {string}
   * @memberof UmeePayControllerApiUpGetAllDevices
   */
  readonly hardwareId?: string;

  /**
   *
   * @type {string}
   * @memberof UmeePayControllerApiUpGetAllDevices
   */
  readonly manToken?: string;

  /**
   *
   * @type {string}
   * @memberof UmeePayControllerApiUpGetAllDevices
   */
  readonly model?: string;

  /**
   *
   * @type {string}
   * @memberof UmeePayControllerApiUpGetAllDevices
   */
  readonly location?: string;

  /**
   *
   * @type {string}
   * @memberof UmeePayControllerApiUpGetAllDevices
   */
  readonly refUserId?: string;

  /**
   *
   * @type {boolean}
   * @memberof UmeePayControllerApiUpGetAllDevices
   */
  readonly online?: boolean;

  /**
   *
   * @type {number}
   * @memberof UmeePayControllerApiUpGetAllDevices
   */
  readonly size?: number;

  /**
   *
   * @type {number}
   * @memberof UmeePayControllerApiUpGetAllDevices
   */
  readonly page?: number;

  /**
   *
   * @type {string}
   * @memberof UmeePayControllerApiUpGetAllDevices
   */
  readonly sortBy?: string;

  /**
   *
   * @type {string}
   * @memberof UmeePayControllerApiUpGetAllDevices
   */
  readonly sortDir?: string;
}

/**
 * Request parameters for upGetDevice operation in UmeePayControllerApi.
 * @export
 * @interface UmeePayControllerApiUpGetDeviceRequest
 */
export interface UmeePayControllerApiUpGetDeviceRequest {
  /**
   *
   * @type {string}
   * @memberof UmeePayControllerApiUpGetDevice
   */
  readonly id: string;
}

/**
 * UmeePayControllerApi - object-oriented interface
 * @export
 * @class UmeePayControllerApi
 * @extends {BaseAPI}
 */
export class UmeePayControllerApi extends BaseAPI {
  /**
   * - Lấy tất cả thiết bị
   * @summary Lấy tất cả thiết bị
   * @param {UmeePayControllerApiUpGetAllDevicesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UmeePayControllerApi
   */
  public upGetAllDevices(
    requestParameters: UmeePayControllerApiUpGetAllDevicesRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return UmeePayControllerApiFp(this.configuration)
      .upGetAllDevices(
        requestParameters.id,
        requestParameters.name,
        requestParameters.hardwareId,
        requestParameters.manToken,
        requestParameters.model,
        requestParameters.location,
        requestParameters.refUserId,
        requestParameters.online,
        requestParameters.size,
        requestParameters.page,
        requestParameters.sortBy,
        requestParameters.sortDir,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Notify Transaction To Device
   * @param {UmeePayControllerApiUpGetDeviceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UmeePayControllerApi
   */
  public upGetDevice(
    requestParameters: UmeePayControllerApiUpGetDeviceRequest,
    options?: AxiosRequestConfig,
  ) {
    return UmeePayControllerApiFp(this.configuration)
      .upGetDevice(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * VersionControllerApi - axios parameter creator
 * @export
 */
export const VersionControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Thêm mới version
     * @param {string} model
     * @param {string} version
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addVersion: async (
      model: string,
      version: string,
      file: File,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'model' is not null or undefined
      assertParamExists("addVersion", "model", model);
      // verify required parameter 'version' is not null or undefined
      assertParamExists("addVersion", "version", version);
      // verify required parameter 'file' is not null or undefined
      assertParamExists("addVersion", "file", file);
      const localVarPath = `/api/version/v1/add`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (model !== undefined) {
        localVarFormParams.append("model", model as any);
      }

      if (version !== undefined) {
        localVarFormParams.append("version", version as any);
      }

      if (file !== undefined) {
        localVarFormParams.append("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xóa version
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteVersion: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("deleteVersion", "id", id);
      const localVarPath = `/api/version/v1/get/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Check version
     * @param {string} hardwareId
     * @param {string} model
     * @param {string} version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadVersion: async (
      hardwareId: string,
      model: string,
      version: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'hardwareId' is not null or undefined
      assertParamExists("downloadVersion", "hardwareId", hardwareId);
      // verify required parameter 'model' is not null or undefined
      assertParamExists("downloadVersion", "model", model);
      // verify required parameter 'version' is not null or undefined
      assertParamExists("downloadVersion", "version", version);
      const localVarPath = `/api/version/v1/download`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (hardwareId !== undefined) {
        localVarQueryParameter["hardwareId"] = hardwareId;
      }

      if (model !== undefined) {
        localVarQueryParameter["model"] = model;
      }

      if (version !== undefined) {
        localVarQueryParameter["version"] = version;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Force Update
     * @param {ForceUpdateRequest} forceUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    forceUpdateVersion: async (
      forceUpdateRequest: ForceUpdateRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'forceUpdateRequest' is not null or undefined
      assertParamExists(
        "forceUpdateVersion",
        "forceUpdateRequest",
        forceUpdateRequest,
      );
      const localVarPath = `/api/version/v1/forceUpdate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        forceUpdateRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get latest version
     * @param {string} hardwareId
     * @param {string} model
     * @param {string} version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLatestVersion: async (
      hardwareId: string,
      model: string,
      version: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'hardwareId' is not null or undefined
      assertParamExists("getLatestVersion", "hardwareId", hardwareId);
      // verify required parameter 'model' is not null or undefined
      assertParamExists("getLatestVersion", "model", model);
      // verify required parameter 'version' is not null or undefined
      assertParamExists("getLatestVersion", "version", version);
      const localVarPath = `/api/version/v1/getLatest`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (hardwareId !== undefined) {
        localVarQueryParameter["hardwareId"] = hardwareId;
      }

      if (model !== undefined) {
        localVarQueryParameter["model"] = model;
      }

      if (version !== undefined) {
        localVarQueryParameter["version"] = version;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get versions
     * @param {string} [model]
     * @param {string} [version]
     * @param {number} [size]
     * @param {number} [page]
     * @param {string} [sortBy]
     * @param {string} [sortDir]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVersions: async (
      model?: string,
      version?: string,
      size?: number,
      page?: number,
      sortBy?: string,
      sortDir?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/version/v1/getAll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (model !== undefined) {
        localVarQueryParameter["model"] = model;
      }

      if (version !== undefined) {
        localVarQueryParameter["version"] = version;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter["sortBy"] = sortBy;
      }

      if (sortDir !== undefined) {
        localVarQueryParameter["sortDir"] = sortDir;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * VersionControllerApi - functional programming interface
 * @export
 */
export const VersionControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    VersionControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Thêm mới version
     * @param {string} model
     * @param {string} version
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addVersion(
      model: string,
      version: string,
      file: File,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseVersionResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addVersion(
        model,
        version,
        file,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Xóa version
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteVersion(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseDeleteVersionResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteVersion(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Check version
     * @param {string} hardwareId
     * @param {string} model
     * @param {string} version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async downloadVersion(
      hardwareId: string,
      model: string,
      version: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseDownloadVersionResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.downloadVersion(
        hardwareId,
        model,
        version,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Force Update
     * @param {ForceUpdateRequest} forceUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async forceUpdateVersion(
      forceUpdateRequest: ForceUpdateRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseForceUpdateResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.forceUpdateVersion(
          forceUpdateRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get latest version
     * @param {string} hardwareId
     * @param {string} model
     * @param {string} version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLatestVersion(
      hardwareId: string,
      model: string,
      version: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetLatestVersionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getLatestVersion(
          hardwareId,
          model,
          version,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get versions
     * @param {string} [model]
     * @param {string} [version]
     * @param {number} [size]
     * @param {number} [page]
     * @param {string} [sortBy]
     * @param {string} [sortDir]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getVersions(
      model?: string,
      version?: string,
      size?: number,
      page?: number,
      sortBy?: string,
      sortDir?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetVersionsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getVersions(
        model,
        version,
        size,
        page,
        sortBy,
        sortDir,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * VersionControllerApi - factory interface
 * @export
 */
export const VersionControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = VersionControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Thêm mới version
     * @param {string} model
     * @param {string} version
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addVersion(
      model: string,
      version: string,
      file: File,
      options?: any,
    ): AxiosPromise<ResponseBaseVersionResponse> {
      return localVarFp
        .addVersion(model, version, file, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xóa version
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteVersion(
      id: string,
      options?: any,
    ): AxiosPromise<ResponseBaseDeleteVersionResponse> {
      return localVarFp
        .deleteVersion(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Check version
     * @param {string} hardwareId
     * @param {string} model
     * @param {string} version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadVersion(
      hardwareId: string,
      model: string,
      version: string,
      options?: any,
    ): AxiosPromise<ResponseBaseDownloadVersionResponse> {
      return localVarFp
        .downloadVersion(hardwareId, model, version, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Force Update
     * @param {ForceUpdateRequest} forceUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    forceUpdateVersion(
      forceUpdateRequest: ForceUpdateRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseForceUpdateResponse> {
      return localVarFp
        .forceUpdateVersion(forceUpdateRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get latest version
     * @param {string} hardwareId
     * @param {string} model
     * @param {string} version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLatestVersion(
      hardwareId: string,
      model: string,
      version: string,
      options?: any,
    ): AxiosPromise<ResponseBaseGetLatestVersionResponse> {
      return localVarFp
        .getLatestVersion(hardwareId, model, version, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get versions
     * @param {string} [model]
     * @param {string} [version]
     * @param {number} [size]
     * @param {number} [page]
     * @param {string} [sortBy]
     * @param {string} [sortDir]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVersions(
      model?: string,
      version?: string,
      size?: number,
      page?: number,
      sortBy?: string,
      sortDir?: string,
      options?: any,
    ): AxiosPromise<ResponseBaseGetVersionsResponse> {
      return localVarFp
        .getVersions(model, version, size, page, sortBy, sortDir, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for addVersion operation in VersionControllerApi.
 * @export
 * @interface VersionControllerApiAddVersionRequest
 */
export interface VersionControllerApiAddVersionRequest {
  /**
   *
   * @type {string}
   * @memberof VersionControllerApiAddVersion
   */
  readonly model: string;

  /**
   *
   * @type {string}
   * @memberof VersionControllerApiAddVersion
   */
  readonly version: string;

  /**
   *
   * @type {File}
   * @memberof VersionControllerApiAddVersion
   */
  readonly file: File;
}

/**
 * Request parameters for deleteVersion operation in VersionControllerApi.
 * @export
 * @interface VersionControllerApiDeleteVersionRequest
 */
export interface VersionControllerApiDeleteVersionRequest {
  /**
   *
   * @type {string}
   * @memberof VersionControllerApiDeleteVersion
   */
  readonly id: string;
}

/**
 * Request parameters for downloadVersion operation in VersionControllerApi.
 * @export
 * @interface VersionControllerApiDownloadVersionRequest
 */
export interface VersionControllerApiDownloadVersionRequest {
  /**
   *
   * @type {string}
   * @memberof VersionControllerApiDownloadVersion
   */
  readonly hardwareId: string;

  /**
   *
   * @type {string}
   * @memberof VersionControllerApiDownloadVersion
   */
  readonly model: string;

  /**
   *
   * @type {string}
   * @memberof VersionControllerApiDownloadVersion
   */
  readonly version: string;
}

/**
 * Request parameters for forceUpdateVersion operation in VersionControllerApi.
 * @export
 * @interface VersionControllerApiForceUpdateVersionRequest
 */
export interface VersionControllerApiForceUpdateVersionRequest {
  /**
   *
   * @type {ForceUpdateRequest}
   * @memberof VersionControllerApiForceUpdateVersion
   */
  readonly forceUpdateRequest: ForceUpdateRequest;
}

/**
 * Request parameters for getLatestVersion operation in VersionControllerApi.
 * @export
 * @interface VersionControllerApiGetLatestVersionRequest
 */
export interface VersionControllerApiGetLatestVersionRequest {
  /**
   *
   * @type {string}
   * @memberof VersionControllerApiGetLatestVersion
   */
  readonly hardwareId: string;

  /**
   *
   * @type {string}
   * @memberof VersionControllerApiGetLatestVersion
   */
  readonly model: string;

  /**
   *
   * @type {string}
   * @memberof VersionControllerApiGetLatestVersion
   */
  readonly version: string;
}

/**
 * Request parameters for getVersions operation in VersionControllerApi.
 * @export
 * @interface VersionControllerApiGetVersionsRequest
 */
export interface VersionControllerApiGetVersionsRequest {
  /**
   *
   * @type {string}
   * @memberof VersionControllerApiGetVersions
   */
  readonly model?: string;

  /**
   *
   * @type {string}
   * @memberof VersionControllerApiGetVersions
   */
  readonly version?: string;

  /**
   *
   * @type {number}
   * @memberof VersionControllerApiGetVersions
   */
  readonly size?: number;

  /**
   *
   * @type {number}
   * @memberof VersionControllerApiGetVersions
   */
  readonly page?: number;

  /**
   *
   * @type {string}
   * @memberof VersionControllerApiGetVersions
   */
  readonly sortBy?: string;

  /**
   *
   * @type {string}
   * @memberof VersionControllerApiGetVersions
   */
  readonly sortDir?: string;
}

/**
 * VersionControllerApi - object-oriented interface
 * @export
 * @class VersionControllerApi
 * @extends {BaseAPI}
 */
export class VersionControllerApi extends BaseAPI {
  /**
   *
   * @summary Thêm mới version
   * @param {VersionControllerApiAddVersionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VersionControllerApi
   */
  public addVersion(
    requestParameters: VersionControllerApiAddVersionRequest,
    options?: AxiosRequestConfig,
  ) {
    return VersionControllerApiFp(this.configuration)
      .addVersion(
        requestParameters.model,
        requestParameters.version,
        requestParameters.file,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xóa version
   * @param {VersionControllerApiDeleteVersionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VersionControllerApi
   */
  public deleteVersion(
    requestParameters: VersionControllerApiDeleteVersionRequest,
    options?: AxiosRequestConfig,
  ) {
    return VersionControllerApiFp(this.configuration)
      .deleteVersion(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Check version
   * @param {VersionControllerApiDownloadVersionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VersionControllerApi
   */
  public downloadVersion(
    requestParameters: VersionControllerApiDownloadVersionRequest,
    options?: AxiosRequestConfig,
  ) {
    return VersionControllerApiFp(this.configuration)
      .downloadVersion(
        requestParameters.hardwareId,
        requestParameters.model,
        requestParameters.version,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Force Update
   * @param {VersionControllerApiForceUpdateVersionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VersionControllerApi
   */
  public forceUpdateVersion(
    requestParameters: VersionControllerApiForceUpdateVersionRequest,
    options?: AxiosRequestConfig,
  ) {
    return VersionControllerApiFp(this.configuration)
      .forceUpdateVersion(requestParameters.forceUpdateRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get latest version
   * @param {VersionControllerApiGetLatestVersionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VersionControllerApi
   */
  public getLatestVersion(
    requestParameters: VersionControllerApiGetLatestVersionRequest,
    options?: AxiosRequestConfig,
  ) {
    return VersionControllerApiFp(this.configuration)
      .getLatestVersion(
        requestParameters.hardwareId,
        requestParameters.model,
        requestParameters.version,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get versions
   * @param {VersionControllerApiGetVersionsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VersionControllerApi
   */
  public getVersions(
    requestParameters: VersionControllerApiGetVersionsRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return VersionControllerApiFp(this.configuration)
      .getVersions(
        requestParameters.model,
        requestParameters.version,
        requestParameters.size,
        requestParameters.page,
        requestParameters.sortBy,
        requestParameters.sortDir,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
