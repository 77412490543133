import { FlexSalaryClient } from "@/api/flexsalary_http_client";
import {
  EnterpriseConfigDetailModel,
  EnterpriseConfigPaymentSource,
} from "../models/EnterpriseConfigDetailModel";
import { ErrorModel } from "@/models/ErrorModel";
import { mapEnterpriseConfigDetailModel } from "../adapters/enterprise-config-adapter";
import { EnterpriseConfigRepository } from "../EnterpriseConfigRepository";

export class EnterpriseConfigRepositoryImp
  implements EnterpriseConfigRepository
{
  private _c: FlexSalaryClient;
  constructor(flexsalaryClient: FlexSalaryClient) {
    this._c = flexsalaryClient;
  }

  public async getConfig(): Promise<EnterpriseConfigDetailModel> {
    try {
      let resp = await this._c.enterpriseConfig.getEnterpriseConfig();
      if (resp.data.code === 20000) {
        return mapEnterpriseConfigDetailModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async editConfig({
    limitAmount,
    periodStartDate,
    periodEndDate,
    paySalaryDate,
    workingDays,
    disableAdvanceBeforeEndDate,
  }: {
    limitAmount: number;
    periodStartDate: number;
    periodEndDate: number;
    paySalaryDate: number;
    workingDays: number;
    disableAdvanceBeforeEndDate: number;
  }): Promise<void> {
    try {
      let resp = await this._c.enterpriseConfig.editEnterpriseConfig({
        enterpriseConfigRequest: {
          disableAdvanceBeforeEndDate: disableAdvanceBeforeEndDate,
          limitAmount: limitAmount,
          paySalaryDate: paySalaryDate,
          periodEndDate: periodEndDate,
          periodStartDate: periodStartDate,
          workingDays: workingDays,
        },
      });
      if (resp.data.code === 20000) {
        return;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
