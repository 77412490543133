import { AccessTokenResponseCustom } from "@/api/generated-sources/kienlong-pay";
import { AccessTokenCustomModel } from "@/models/AccesstokenCustomModal";

export function mapAccessTokenModel(
  dto: AccessTokenResponseCustom | undefined,
): AccessTokenCustomModel {
  return {
    expiresIn: dto?.expiresIn,
    idToken: dto?.idToken,
    notBeforePolicy: dto?.notBeforePolicy,
    otherClaims: dto?.otherClaims,
    refreshExpiresIn: dto?.refreshExpiresIn,
    refreshToken: dto?.refreshToken,
    scope: dto?.scope,
    sessionState: dto?.sessionState,
    token: dto?.token,
    tokenType: dto?.tokenType,
  };
}
