import { KienlongPayClient } from "@/api/kienlong_pay_http_client";
import { BankAccountType } from "@/models/BankAccountModel";
import { ErrorModel } from "@/models/ErrorModel";
import { ToolRepository } from "../ToolRepository";

export class ToolRepositoryImp implements ToolRepository {
  private client: KienlongPayClient;
  constructor(kienlongPayClient: KienlongPayClient) {
    this.client = kienlongPayClient;
  }

  public async inquiry({
    accountNo,
    branch,
  }: {
    accountNo: string;
    branch: BankAccountType;
  }): Promise<string> {
    try {
      let resp = await this.client.toolController.inquiry({
        toolInquiryRequest: {
          accountNo: accountNo,
          branch: branch,
        },
      });
      if (resp.data.code === 0) {
        return resp?.data?.data?.displayName ?? "";
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async deposit({
    accountNo,
    branch,
    amount,
    content,
  }: {
    amount: number;
    content: string;
    accountNo: string;
    branch: BankAccountType;
  }): Promise<boolean> {
    try {
      let resp = await this.client.toolController.deposit({
        toolDepositRequest: {
          accountNo: accountNo,
          amount: amount,
          branch: branch,
          content: content,
        },
      });
      if (resp.data.code === 0) {
        return resp?.data?.data?.success ?? false;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async scanQr({ image }: { image: File }): Promise<{
    accountNo: string;
    branch: BankAccountType;
    amount: number;
    content: string;
  }> {
    try {
      let resp = await this.client.toolController.scanQr({
        image: image,
      });
      if (resp.data.code === 0) {
        return {
          accountNo: resp.data.data?.accountNo ?? "",
          amount: resp.data.data?.amount ?? 0,
          branch:
            BankAccountType[
              resp.data.data?.branch as keyof typeof BankAccountType
            ],
          content: resp.data.data?.content ?? "",
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
