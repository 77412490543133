import React from "react";
import { NavLink } from "react-router-dom";
import "./nav-link-custom.css";

export const CustomNavLink = ({
  title,
  path,
  icon,
  isSelected,
}: {
  title: string;
  path: string;
  icon: React.ReactNode;
  isSelected: boolean;
}) => {
  return (
    <NavLink
      className="nav-link-custom-group__element"
      to={`${path}`}
      replace={true}
      style={{
        backgroundColor: isSelected ? "#3366FF" : undefined,
        textDecoration: "none",
      }}
    >
      {icon}
      <span
        className={`nav-link-custom-group__element__title ${
          isSelected ? "nav-link-custom-group__element__title--selected" : ""
        }`}
      >
        {title}
      </span>
    </NavLink>
  );
};

export const CustomNavLinkGroup: React.FunctionComponent<{
  children: React.ReactNode;
  title: string;
}> = ({ title, children }: { title: string; children: React.ReactNode }) => {
  return (
    <div className="nav-link-custom-group">
      {title.length > 0 && (
        <div className="nav-link-custom-group__title">
          <span className="nav-link-custom-group__title__content">
            {title.toUpperCase()}
          </span>
        </div>
      )}
      {children}
    </div>
  );
};
