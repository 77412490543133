import { mapTransacionResponseModel } from "@/adapters/transaction_adapter";
import { KienlongPayClient } from "@/api/kienlong_pay_http_client";
import { ErrorModel } from "@/models/ErrorModel";
import {
  PageTransactionResponseModel,
  StatisticInfoModel,
} from "@/models/PageTransactionModel";
import { PaymentType } from "@/models/PaylinkModel";
import { SortDirectionType } from "@/models/Sort";
import {
  PeriodBankTransaction,
  TransactionResponseModel,
  TransactionStatusStatistical,
  TransactionStatusType,
  TransactionType,
} from "@/models/TransactionModel";
import { TransactionRepository } from "../TransactionRepository";

export class TransactionRepositoryImp implements TransactionRepository {
  private client: KienlongPayClient;
  constructor(kienlongPayClient: KienlongPayClient) {
    this.client = kienlongPayClient;
  }

  public async getAllTransaction({
    fromDate,
    keyword,
    page,
    pageSize,
    refTransactionId,
    sortBy,
    sortDirection,
    status,
    toDate,
    transactionId,
    type,
    payboxDeviceId,
    paymentType,
    bankAccountId,
    isStatistic,
  }: {
    fromDate?: string;
    keyword?: string;
    page?: number;
    pageSize?: number;
    refTransactionId?: string;
    sortBy?: string;
    sortDirection?: SortDirectionType;
    status?: TransactionStatusType;
    toDate?: string;
    transactionId?: string;
    type?: TransactionType;
    payboxDeviceId?: string;
    paymentType?: PaymentType;
    bankAccountId?: string;
    isStatistic: number;
  }): Promise<PageTransactionResponseModel> {
    try {
      let resp =
        await this.client.portalTransactionController.getAllTransactions({
          fromDate: fromDate,
          keyword: keyword,
          page: page,
          pageSize: pageSize,
          refTransactionId: refTransactionId,
          sortBy: sortBy,
          sortDirection: sortDirection,
          status: status,
          toDate: toDate,
          transactionId: transactionId,
          payboxDeviceId: payboxDeviceId,
          type: type,
          paymentType: paymentType,
          bankAccountId: bankAccountId,
          isStatistic: isStatistic,
        });
      if (resp.data.code === 0) {
        return {
          pageNumber: resp.data.data?.pageNumber ?? 0,
          pageSize: resp.data.data?.pageSize ?? 0,
          totalPage: resp.data.data?.totalPage ?? 0,
          totalSize: resp.data.data?.totalSize ?? 0,
          items:
            resp.data.data?.items?.map((d): TransactionResponseModel => {
              return mapTransacionResponseModel(d);
            }) ?? [],
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async exportToExcel({
    fromDate,
    keyword,
    refTransactionId,
    status,
    toDate,
    transactionId,
    isStatistic,
    bankAccountId,
    merchantCode,
    merchantId,
    paymentType,
    type,
  }: {
    fromDate?: string;
    keyword?: string;
    refTransactionId?: string;
    status?: TransactionStatusType;
    toDate?: string;
    transactionId?: string;
    isStatistic?: boolean;
    bankAccountId?: string;
    merchantCode?: string;
    merchantId?: string;
    paymentType?: PaymentType;
    type?: TransactionType;
  }): Promise<File> {
    try {
      let resp = await this.client.portalTransactionController.exportToExcel(
        {
          fromDate: fromDate,
          keyword: keyword,
          refTransactionId: refTransactionId,
          toDate: toDate,
          transactionId: transactionId,
          status: status,
          isStatistic: isStatistic,
          bankAccountId,
          merchantCode,
          merchantId,
          paymentType,
          type,
        },
        { responseType: "arraybuffer" },
      );
      return resp.data;
    } catch (error) {
      throw error;
    }
  }

  public async exportFromBank({
    fromDate,
    toDate,
    accountNo,
    period,
  }: {
    fromDate?: string;
    toDate?: string;
    accountNo?: string;
    period?: PeriodBankTransaction;
  }): Promise<File> {
    try {
      let resp =
        await this.client.portalTransactionController.exportToExcelFromBank({
          fromDate: fromDate,
          toDate: toDate,
          accountNo: accountNo,
          period: period,
        });
      return resp.data;
    } catch (error) {
      throw error;
    }
  }

  public async getAllTransactionBank({
    fromDate,
    page,
    pageSize,
    sortBy,
    sortDirection = SortDirectionType.DESC,
    toDate,
    accountNo,
    period,
  }: {
    fromDate?: string;
    page?: number;
    pageSize?: number;
    sortBy?: string;
    sortDirection?: SortDirectionType;
    toDate?: string;
    accountNo?: string;
    period?: PeriodBankTransaction;
  }): Promise<PageTransactionResponseModel> {
    try {
      let resp = await this.client.portalTransactionController.getAllFromBank({
        fromDate: fromDate,
        page: page,
        pageSize: pageSize,
        sortBy: sortBy,
        sortDirection: sortDirection,
        toDate: toDate,
        accountNo: accountNo,
        period: period,
      });
      if (resp.data.code === 0) {
        return {
          pageNumber: resp.data.data?.pageNumber ?? 0,
          pageSize: resp.data.data?.pageSize ?? 0,
          totalPage: resp.data.data?.totalPage ?? 0,
          totalSize: resp.data.data?.totalSize ?? 0,
          items:
            resp.data.data?.items?.map((d): TransactionResponseModel => {
              return mapTransacionResponseModel(d);
            }) ?? [],
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getStatisticInfo({
    fromDate,
    toDate,
    bankAccountId,
    paymentType,
    status,
    stransactionType,
  }: {
    fromDate?: string;
    toDate?: string;
    bankAccountId?: string;
    paymentType?: PaymentType;
    status?: TransactionStatusStatistical;
    stransactionType?: TransactionType;
  }): Promise<StatisticInfoModel> {
    try {
      let resp = await this.client.portalTransactionController.getStatisticInfo(
        {
          fromDate: fromDate,
          toDate: toDate,
          bankAccountId: bankAccountId,
          paymentType: paymentType,
          status: status,
          type: stransactionType,
        },
      );

      if (resp.data.code === 0) {
        return {
          totalRevenue: resp.data.data?.totalRevenue ?? 0,
          numOfSuccessfulTransactions:
            resp.data.data?.numOfSuccessfulTransactions ?? 0,
          numOfCancelledTransactions:
            resp.data.data?.numOfCancelledTransactions ?? 0,
          numOfFailedTransactions: resp.data.data?.numOfFailedTransactions ?? 0,
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
