import {
  mapPayPosModel,
  mappingPayPosDetailTransaction,
  mappingPayPosHistoryTransaction,
} from "@/adapters/paypos_adapter";
import { PayPosDeviceHistoryPaymentStatus } from "@/models/PayPosModel";

import { KienlongPayClient } from "@/api/kienlong_pay_http_client";
import { ErrorModel } from "@/models/ErrorModel";
import { PaymentType } from "@/models/PaylinkModel";

import { PayPosStatusType } from "@/models/PayPosModel";
import {
  PagePosPortalDetailModel,
  PagePosPortalHistoryModel,
  PagePosPortalModel,
} from "@/models/PosModelModel";
import { SortDirectionType } from "@/models/Sort";
import { PayPosRepository } from "../PayPosRepository";

export class PayPosRepositoryImp implements PayPosRepository {
  private client: KienlongPayClient;
  constructor(kienlongPayClient: KienlongPayClient) {
    this.client = kienlongPayClient;
  }

  public async getListPos({
    keyword,
    modeName,
    status,
    page,
    pageSize,
    sortDirection,
    sortBy,
  }: {
    keyword?: string;
    modeName?: string;
    status?: PayPosStatusType;
    page?: number;
    pageSize?: number;
    sortDirection?: SortDirectionType;
    sortBy?: string;
  }): Promise<{
    pageNumber: number;
    pageSize: number;
    totalSize: number;
    totalPage: number;
    items: Array<PagePosPortalModel>;
  }> {
    try {
      let resp = await this.client.payPosController.getListPos({
        keyword: keyword,
        status: status,
        page: page,
        pageSize: pageSize,
        sortDirection: sortDirection,
        sortBy: sortBy,
      });
      if (resp.data.code === 0) {
        return {
          pageNumber: resp.data.data?.pageNumber ?? 0,
          pageSize: resp.data.data?.pageSize ?? 0,
          totalPage: resp.data.data?.totalPage ?? 0,
          totalSize: resp.data.data?.totalSize ?? 0,
          items:
            resp.data.data?.items?.map((d) => {
              return mapPayPosModel(d);
            }) ?? [],
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getTransactionHistory({
    paymentType,
    keyword,
    terminalId,
    finish,
    status,
    createdFrom,
    createdTo,
    page,
    pageSize,
    sortDirection,
    sortBy,
  }: {
    paymentType: PaymentType;
    keyword: string;
    terminalId: string;
    finish?: boolean;
    status?: PayPosDeviceHistoryPaymentStatus[];
    createdFrom: string;
    createdTo: string;
    page: number;
    pageSize: number;
    sortDirection: SortDirectionType;
    sortBy: string;
  }): Promise<{
    pageNumber: number;
    pageSize: number;
    totalSize: number;
    totalPage: number;
    items: PagePosPortalHistoryModel[];
  }> {
    try {
      let resp =
        await this.client.payPosController.getPosListTransactionInPortal({
          keyword: keyword,
          createdFrom: createdFrom,
          createdTo: createdTo,
          page: page,
          pageSize: pageSize,
          sortDirection: sortDirection,
          sortBy: sortBy,
        });
      if (resp.data.code === 0) {
        return {
          pageNumber: resp.data.data?.pageNumber ?? 0,
          pageSize: resp.data.data?.pageSize ?? 0,
          totalPage: resp.data.data?.totalPage ?? 0,
          totalSize: resp.data.data?.totalSize ?? 0,
          items:
            resp.data.data?.items?.map((d) => {
              return mappingPayPosHistoryTransaction(d);
            }) ?? [],
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getDetailTransaction({
    id,
    paymentType,
  }: {
    id: string;
    paymentType: any;
  }): Promise<PagePosPortalDetailModel> {
    try {
      let resp = await this.client.payPosController.getDetailPosTransaction({
        id: id,
      });
      if (resp.data.code === 0 && resp?.data?.data) {
        return mappingPayPosDetailTransaction(resp?.data?.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
