import {
  PayOutReceiverDTO,
  SinglePayOutDetailResponse,
  SinglePayOutResponse,
  TransactionResponse,
} from "@/api/generated-sources/kienlong-pay";
import { BankAccountType } from "@/models/BankAccountModel";
import { PayBoxTransactionType } from "@/models/PayBoxTransactionModel";
import { PaymentType } from "@/models/PaylinkModel";
import {
  PayoutReceiverGroupType,
  PayoutReceiverModel,
  PayoutStatusType,
  PayoutTransactionModel,
  SinglePayoutModel,
  SinglePayoutType,
} from "@/models/PayoutModel";
import { TransactionStatusType } from "@/models/TransactionModel";

export function mapPayoutReceiver(
  dto: PayOutReceiverDTO | undefined,
): PayoutReceiverModel {
  return {
    accountName: dto?.accountName ?? "",
    accountNo: dto?.accountNo ?? "",
    bankBin: dto?.bankBin ?? "",
    bankName: dto?.bankName ?? "",
    group:
      PayoutReceiverGroupType[
        (dto?.group ?? "OTHER") as keyof typeof PayoutReceiverGroupType
      ],
    id: dto?.id ?? 0,
    bankLogo: dto?.bankLogo ?? "",
  };
}

// export function mapBatchPayoutModel(dto: BatchPayOutResponse): BatchPayoutModel {
//   return {
//     createdAt: dto?.createdAt ?? "",
//     createdBy: dto?.createdBy ?? "",
//     fee: dto?.fee ?? 0,
//     id: dto?.id ?? "",
//     name: dto?.name ?? "",
//     status:
//       PayoutStatusType[
//       (dto?.status ?? "FAILED") as keyof typeof PayoutStatusType
//       ],
//     totalAmount: 0,
//     modifiedAt: dto?.modifiedAt ?? '',
//     modifiedBy: dto?.modifiedBy ?? '',
//     totalReceiver: dto?.totalReceiver ?? 0,
//   };
// }

export function mapPayoutTransaction(
  dto: TransactionResponse | undefined,
): PayoutTransactionModel {
  return {
    amount: dto?.amount ?? 0,
    bankType:
      BankAccountType[dto?.bankType ?? ("KLB" as keyof typeof BankAccountType)],
    callbackAfter: dto?.callbackAfter ?? 0,
    completeTime: dto?.completeTime ?? "",
    createDateTime: dto?.createDateTime ?? "",
    customerName: dto?.customerName ?? "",
    customerPhone: dto?.customerPhone ?? "",
    depositTime: dto?.depositTime ?? "",
    description: dto?.description ?? "",
    failUrl: dto?.failUrl ?? "",
    id: dto?.id ?? "",
    merchantCode: dto?.merchantCode ?? "",
    merchantId: dto?.merchantId ?? "",
    merchantName: dto?.merchantName ?? "",
    payboxTransactionType:
      PayBoxTransactionType[
        dto?.payboxTransactionType ??
          ("PAYBOX" as keyof typeof PayBoxTransactionType)
      ],
    payLinkCode: dto?.payLinkCode ?? "",
    paymentType:
      PaymentType[dto?.paymentType ?? ("VIET_QR" as keyof typeof PaymentType)],
    refTransactionId: dto?.refTransactionId ?? "",
    status:
      TransactionStatusType[
        dto?.status ?? ("FAIL" as keyof typeof TransactionStatusType)
      ],
    successUrl: dto?.successUrl ?? "",
    virtualAccount: dto?.virtualAccount ?? "",
  };
}

export function mapSinglePayout(
  dto: SinglePayOutResponse | undefined,
): SinglePayoutModel {
  return {
    amount: dto?.amount ?? 0,
    content: dto?.content ?? "",
    denyContent: dto?.denyContent ?? "",
    fromAccountName: dto?.fromAccountName ?? "",
    fromAccountNo: dto?.fromAccountNo ?? "",
    fromBranch:
      BankAccountType[
        dto?.fromBranch ?? ("KLB" as keyof typeof BankAccountType)
      ],
    id: dto?.id ?? "",
    status:
      PayoutStatusType[
        dto?.status ?? ("FAILED" as keyof typeof PayoutStatusType)
      ],
    toAccountName: dto?.toAccountName ?? "",
    toAccountNo: dto?.toAccountNo ?? "",
    toBankBin: dto?.toBankBin ?? "",
    toBankLogo: dto?.toBankLogo ?? "",
    toBankName: dto?.toBankName ?? "",
    type: SinglePayoutType[
      dto?.type ?? ("SINGLE" as keyof typeof SinglePayoutType)
    ],
    txnNumber: dto?.txnNumber ?? "",
    transactionTime: dto?.transactionTime ?? "",
  };
}

export function mapSinglePayoutDetail(
  dto: SinglePayOutDetailResponse | undefined,
): SinglePayoutModel & {
  transaction: PayoutTransactionModel;
} {
  return {
    amount: dto?.amount ?? 0,
    content: dto?.content ?? "",
    denyContent: dto?.denyContent ?? "",
    fromAccountName: dto?.fromAccountName ?? "",
    fromAccountNo: dto?.fromAccountNo ?? "",
    fromBranch:
      BankAccountType[
        dto?.fromBranch ?? ("KLB" as keyof typeof BankAccountType)
      ],
    id: dto?.id ?? "",
    status:
      PayoutStatusType[
        dto?.status ?? ("FAILED" as keyof typeof PayoutStatusType)
      ],
    toAccountName: dto?.toAccountName ?? "",
    toAccountNo: dto?.toAccountNo ?? "",
    toBankBin: dto?.toBankBin ?? "",
    toBankLogo: dto?.toBankLogo ?? "",
    toBankName: dto?.toBankName ?? "",
    type: SinglePayoutType[
      dto?.type ?? ("SINGLE" as keyof typeof SinglePayoutType)
    ],
    transaction: mapPayoutTransaction(dto?.transaction),
    txnNumber: dto?.txnNumber ?? "",
    transactionTime: dto?.transactionTime ?? "",
  };
}
