import { KienlongPayClient } from "@/api/kienlong_pay_http_client";
import { ForcontrolRepository } from "../ForcontrolRepository";
import {
  mapForControlAdminModel,
  mapForControlHistoryModel,
  mapForControlModel,
} from "@/adapters/for_control_adapter";
import { ErrorModel } from "@/models/ErrorModel";
import {
  ForControlAdminModel,
  ForControlHistoryModel,
  ForControlModel,
  ForControlStatus,
} from "@/models/ForControlModel";

export class ForControlRepositoryImp implements ForcontrolRepository {
  private client: KienlongPayClient;
  constructor(kienlongPayClient: KienlongPayClient) {
    this.client = kienlongPayClient;
  }

  public async getAllForcontrol({
    page,
    size,
    keyword,
    fromDate,
    toDate,
    status,
  }: {
    page?: number;
    size?: number;
    keyword?: string;
    fromDate?: string;
    toDate?: string;
    status?: ForControlStatus;
  }): Promise<{
    pageNumber?: number;
    pageSize?: number;
    totalSize?: number;
    totalPage?: number;
    items?: Array<ForControlModel>;
  }> {
    try {
      let resp = await this.client.forControl.portalGetAll({
        page: page,
        size: size,
        fromDate: fromDate,
        toDate: toDate,
        status: status,
        keyword: keyword,
        sort: ["createdAt,desc"],
      });
      if (resp.data.code === 0) {
        return {
          pageNumber: resp.data.data?.pageNumber ?? 0,
          pageSize: resp.data.data?.pageSize ?? 0,
          totalPage: resp.data.data?.totalPage ?? 0,
          totalSize: resp.data.data?.totalSize ?? 0,
          items: resp.data.data?.items?.map((d) => mapForControlModel(d)) ?? [],
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getDetailForControl({ id }: { id: number }): Promise<{
    admin: ForControlAdminModel;
    forcontrol: ForControlModel;
  }> {
    try {
      let resp = await this.client.forControl.getDetailForControl({
        id: id,
      });
      if (resp.data.code === 0) {
        return {
          forcontrol: mapForControlModel(resp.data.data?.forControl),
          admin: mapForControlAdminModel(resp.data.data?.admin),
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async addForControl({
    reason,
    transactionId,
    documentUrls,
  }: {
    reason?: string;
    transactionId?: string;
    documentUrls?: string[];
  }): Promise<ForControlModel> {
    try {
      let resp = await this.client.forControl.addForControl({
        addForControlPortalRequest: {
          documentUrls: documentUrls,
          reason: reason,
          transactionId: transactionId,
        },
      });
      if (resp.data.code === 0) {
        return mapForControlModel(resp.data.data?.forControl);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getAllHistoryForControl({
    code,
    page,
    size,
  }: {
    code: string;
    page?: number;
    size?: number;
  }): Promise<{
    pageNumber?: number;
    pageSize?: number;
    totalSize?: number;
    totalPage?: number;
    items?: Array<ForControlHistoryModel>;
  }> {
    try {
      let resp = await this.client.forControl.portalHistoryGetAll({
        code: code,
        page: page,
        size: size,
        sort: ["createdAt,desc"],
      });
      if (resp.data.code === 0) {
        return {
          pageNumber: resp.data.data?.pageNumber ?? 0,
          pageSize: resp.data.data?.pageSize ?? 0,
          totalPage: resp.data.data?.totalPage ?? 0,
          totalSize: resp.data.data?.totalSize ?? 0,
          items:
            resp.data.data?.items?.map((d) => mapForControlHistoryModel(d)) ??
            [],
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async deleteForControl({ id }: { id: number }): Promise<boolean> {
    try {
      let resp = await this.client.forControl.deleteForControl({
        id: id,
      });
      if (resp.data.code === 0) {
        return resp.data.data?.success ?? false;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async exportReportPortal({
    keyword,
    fromDate,
    toDate,
    status,
  }: {
    keyword?: string;
    fromDate?: string;
    toDate?: string;
    status?: ForControlStatus;
  }): Promise<File> {
    try {
      let resp = await this.client.forControl.exportReportPortal(
        {
          fromDate: fromDate,
          keyword: keyword,
          status: status,
          toDate: toDate,
        },
        { responseType: "arraybuffer" },
      );
      if ((resp as any).data.code && (resp as any).data.code !== 0) {
        throw new ErrorModel({
          code: (resp as any).data.code,
          message: (resp as any).data.message,
        });
      }
      return resp.data;
    } catch (error) {
      throw error;
    }
  }
}
