import { mapCategoryModel } from "@/adapters/category_adapter";
import { mapProductModel } from "@/adapters/product_adapter";
import { KienlongPayClient } from "@/api/kienlong_pay_http_client";
import { CategoryModel } from "@/models/CategoryModel";
import { ErrorModel } from "@/models/ErrorModel";
import {
  ProductModel,
  ProductPriceType,
  ProductStockStatus,
  ProductStorageType,
} from "@/models/ProductModal";
import { SortDirectionType } from "@/models/Sort";
import { ProductRepository } from "../ProductRepository";

export class ProductRepositoryImp implements ProductRepository {
  private client: KienlongPayClient;
  constructor(kienlongPayClient: KienlongPayClient) {
    this.client = kienlongPayClient;
  }

  public async createCategory({
    name,
    description,
    products,
  }: {
    name: string;
    description?: string;
    products?: Array<number>;
  }): Promise<CategoryModel> {
    try {
      let resp = await this.client.portalProductController.createCategory({
        createCategoryRequest: {
          name: name,
          description: description,
          products: products,
        },
      });
      if (resp.data.code === 0) {
        return {
          createdAt: resp.data.data?.createdAt ?? "",
          createdBy: resp.data.data?.createdBy ?? "",
          description: resp.data.data?.description ?? "",
          id: resp.data.data?.id ?? 0,
          modifiedAt: resp.data.data?.modifiedAt ?? "",
          modifiedBy: resp.data.data?.modifiedBy ?? "",
          name: resp.data.data?.name ?? "",
          productCount: resp.data.data?.productCount ?? 0,
          code: resp.data.data?.code ?? "",
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async updateCategory({
    categoryId,
    name,
    description,
    products,
  }: {
    categoryId: number;
    name?: string;
    description?: string;
    products?: number[];
  }): Promise<CategoryModel> {
    try {
      let resp = await this.client.portalProductController.updateCategory({
        cateId: categoryId,
        updateCategoryRequest: {
          name: name,
          products: products,
          description: description,
        },
      });
      if (resp.data.code === 0) {
        return {
          createdAt: resp.data.data?.createdAt ?? "",
          createdBy: resp.data.data?.createdBy ?? "",
          description: resp.data.data?.description ?? "",
          id: resp.data.data?.id ?? 0,
          modifiedAt: resp.data.data?.modifiedAt ?? "",
          modifiedBy: resp.data.data?.modifiedBy ?? "",
          name: resp.data.data?.name ?? "",
          productCount: resp.data.data?.productCount ?? 0,
          code: resp.data.data?.code ?? "",
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getAllCategory({
    queryKey,
    page,
    pageSize,
  }: {
    queryKey?: string;
    page?: number;
    pageSize?: number;
  }): Promise<{
    pageNumber: number;
    pageSize: number;
    totalSize: number;
    totalPage: number;
    items: Array<CategoryModel>;
  }> {
    try {
      let resp = await this.client.portalProductController.getAllCategories({
        query: queryKey,
        page: page,
        size: pageSize,
        sort: ["createdAt,desc"],
      });
      if (resp.data.code === 0) {
        return {
          pageNumber: resp.data.data?.pageNumber ?? 0,
          pageSize: resp.data.data?.pageSize ?? 0,
          totalSize: resp.data.data?.totalSize ?? 0,
          totalPage: resp.data.data?.totalPage ?? 0,
          items:
            resp.data.data?.items?.map((d) => {
              return mapCategoryModel(d);
            }) ?? [],
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async createProduct({
    name,
    priceType,
    storageType,
    cateId,
    description,
    imageUrl,
    price,
    storageQuantity,
  }: {
    name: string;
    priceType: ProductPriceType;
    storageType: ProductStorageType;
    cateId?: number;
    description?: string;
    imageUrl?: string;
    price?: number;
    storageQuantity?: number;
  }): Promise<ProductModel> {
    try {
      let resp = await this.client.portalProductController.createProduct({
        createProductRequest: {
          name: name,
          priceType: priceType,
          storageType: storageType,
          cateId: cateId,
          description: description,
          imageUrl: imageUrl,
          price: price,
          storageQuantity: storageQuantity,
        },
      });
      if (resp.data.code === 0) {
        return mapProductModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async updateProduct({
    prodId,
    name,
    priceType,
    storageType,
    cateId,
    description,
    imageUrl,
    price,
    storageQuantity,
  }: {
    prodId: number;
    name: string;
    priceType: ProductPriceType;
    storageType: ProductStorageType;
    cateId?: number;
    description?: string;
    imageUrl?: string;
    price?: number;
    storageQuantity?: number;
  }): Promise<ProductModel> {
    try {
      let resp = await this.client.portalProductController.updateProduct({
        prodId: prodId,
        updateProductRequest: {
          name: name,
          priceType: priceType,
          storageType: storageType,
          cateId: cateId,
          description: description,
          imageUrl: imageUrl,
          price: price,
          storageQuantity: storageQuantity,
        },
      });
      if (resp.data.code === 0) {
        return mapProductModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async deleteProduct({
    prodId,
  }: {
    prodId: number;
  }): Promise<ProductModel> {
    try {
      let resp = await this.client.portalProductController.deleteProduct({
        prodId: prodId,
      });
      if (resp.data.code === 0) {
        return mapProductModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getAllProducts({
    categoryId,
    createdFrom,
    createdTo,
    page,
    pageSize,
    query,
    sortBy,
    sortDirection,
    status,
    haveCate,
  }: {
    categoryId?: number;
    createdFrom?: string;
    createdTo?: string;
    page?: number;
    pageSize?: number;
    query?: string;
    sortBy?: string;
    sortDirection: SortDirectionType;
    status?: ProductStockStatus;
    haveCate?: boolean;
  }): Promise<{
    pageNumber: number;
    pageSize: number;
    totalSize: number;
    totalPage: number;
    items: Array<ProductModel>;
  }> {
    try {
      let resp = await this.client.portalProductController.getAllProduct({
        categoryId: categoryId,
        createdFrom: createdFrom,
        createdTo: createdTo,
        page: page,
        pageSize: pageSize,
        query: query,
        sortBy: sortBy,
        sortDirection: sortDirection,
        status: status,
        haveCate: haveCate,
      });
      if (resp.data.code === 0) {
        return {
          pageNumber: resp.data.data?.pageNumber ?? 0,
          pageSize: resp.data.data?.pageSize ?? 0,
          totalSize: resp.data.data?.totalSize ?? 0,
          totalPage: resp.data.data?.totalPage ?? 0,
          items:
            resp.data.data?.items?.map((d) => {
              return mapProductModel(d);
            }) ?? [],
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getDetailProduct({
    prodId,
  }: {
    prodId: number;
  }): Promise<ProductModel> {
    try {
      let resp = await this.client.portalProductController.getProductDetail({
        prodId: prodId,
      });
      if (resp.data.code === 0) {
        return mapProductModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async deleteCategory({
    id,
  }: {
    id: number;
  }): Promise<{ success: boolean }> {
    try {
      let resp = await this.client.portalProductController.deleteCategory({
        id: id,
      });
      if (resp.data.code === 0 && resp.data.data) {
        return {
          success: resp?.data.data.success ?? false,
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async addProductToCategory({
    cateId,
    name,
    products,
  }: {
    cateId: number;
    name?: string;
    products: Array<number>;
  }): Promise<{ success: boolean }> {
    try {
      let resp =
        await this.client.portalProductController.addProductsToCategory({
          addProductsToCateRequest: {
            cateId: cateId,
            prodIds: products,
            name: name,
          },
        });
      if (resp.data.code === 0 && resp.data.data) {
        return {
          success: resp?.data.data.success ?? false,
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getDetailCategory({
    cateId,
  }: {
    cateId: number;
  }): Promise<CategoryModel> {
    try {
      let resp = await this.client.portalProductController.getDetailCategory({
        id: cateId,
      });
      if (resp.data.code === 0) {
        return mapCategoryModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
