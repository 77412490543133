import { mapSupportModel } from "@/adapters/support_adapter";
import { KienlongPayClient } from "@/api/kienlong_pay_http_client";
import { ErrorModel } from "@/models/ErrorModel";
import { SupportContentType, SupportModel } from "@/models/SupportModel";
import { SupportRepository } from "../SupportRepository";

export class SupportRepositoryImp implements SupportRepository {
  private client: KienlongPayClient;
  constructor(kienlongPayClient: KienlongPayClient) {
    this.client = kienlongPayClient;
  }

  public async created({
    content,
    description,
    email,
    phoneNo,
    username,
  }: {
    content: SupportContentType;
    description: string;
    email: string;
    phoneNo: string;
    username: string;
  }): Promise<SupportModel> {
    try {
      let resp = await this.client.supportController.create({
        createSupportRequest: {
          content: content,
          description: description,
          email: email,
          phoneNo: phoneNo,
          username: username,
        },
      });
      if (resp.data.code === 0) {
        return mapSupportModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
