import { GetMerchantCredentialResponse } from "@/api/generated-sources/kienlong-pay";
import {
  MerchantCredentialModel,
  MerchantCredentialType,
} from "@/models/CredentialModel";

export function mapMerchantCredentialModal(
  dto: GetMerchantCredentialResponse | undefined,
): MerchantCredentialModel {
  return {
    host: dto?.host ?? "",
    clientId: dto?.clientId ?? "",
    encryptKey: dto?.encryptKey ?? "",
    secretKey: dto?.secretKey ?? "",
    status: dto?.status
      ? MerchantCredentialType[
          dto.status as keyof typeof MerchantCredentialType
        ]
      : undefined,
  };
}
