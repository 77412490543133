import { FlexSalaryClient } from "@/api/flexsalary_http_client";
import { ErrorModel } from "@/models/ErrorModel";
import {
  SalaryAdvanceType,
  SalaryAdvanceTypeDetailModel,
  SalaryAdvanceTypeInfoModel,
} from "../models/SalaryModel";
import {
  mapSalaryAdvanceTypeDetailModel,
  mapSalaryAdvanceTypeInfoModel,
} from "../adapters/salary-adapter";
import { SalaryAdvanceTypeRepository } from "../SalaryAdvanceTypeRepository";

export class SalaryAdvanceTypeRepositoryImp
  implements SalaryAdvanceTypeRepository
{
  private client: FlexSalaryClient;
  constructor(flexsalaryClient: FlexSalaryClient) {
    this.client = flexsalaryClient;
  }

  public async addAdvanceType({
    advanceForm,
    advancePercent,
    frequencyLimit,
    isLimitFrequency,
    name,
  }: {
    advanceForm: SalaryAdvanceType;
    advancePercent: number;
    frequencyLimit: number;
    isLimitFrequency: boolean;
    name: string;
  }): Promise<string> {
    try {
      let resp = await this.client.salaryAdvanceType.addAdvanceType({
        salaryAdvanceTypeRequest: {
          advanceForm: advanceForm,
          advancePercent: advancePercent,
          frequencyLimit: frequencyLimit,
          isLimitFrequency: isLimitFrequency,
          name: name,
        },
      });
      if (resp.data.code === 20000) {
        return resp.data.data?.id ?? "";
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async editAdvanceType({
    advanceForm,
    advancePercent,
    frequencyLimit,
    isLimitFrequency,
    name,
    id,
  }: {
    advanceForm: SalaryAdvanceType;
    advancePercent: number;
    frequencyLimit: number;
    isLimitFrequency: boolean;
    name: string;
    id: string;
  }): Promise<void> {
    try {
      let resp = await this.client.salaryAdvanceType.editAdvanceType({
        salaryAdvanceTypeRequest: {
          advanceForm: advanceForm,
          advancePercent: advancePercent,
          frequencyLimit: frequencyLimit,
          isLimitFrequency: isLimitFrequency,
          name: name,
        },
        id: id,
      });
      if (resp.data.code === 20000) {
        return;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async deleteAdvanceType({ id }: { id: string }): Promise<void> {
    try {
      let resp = await this.client.salaryAdvanceType.deleteAdvanceType({
        id: id,
      });
      if (resp.data.code === 20000) {
        return;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async detailAdvanceType({
    id,
  }: {
    id: string;
  }): Promise<SalaryAdvanceTypeDetailModel> {
    try {
      let resp = await this.client.salaryAdvanceType.getAdvanceType({
        id: id,
      });
      if (resp.data.code === 20000) {
        return mapSalaryAdvanceTypeDetailModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async listAdvanceType({
    page = 0,
    size = 10,
    keyword,
    advanceForm,
  }: {
    keyword?: string;
    page?: number;
    size?: number;
    advanceForm: SalaryAdvanceType;
  }): Promise<{
    page: number;
    size: number;
    totalElements: number;
    totalPages: number;
    data: Array<SalaryAdvanceTypeInfoModel>;
  }> {
    try {
      let resp = await this.client.salaryAdvanceType.getListAdvanceType({
        page: page,
        size: size,
        keyword: keyword,
        advanceForm: advanceForm,
      });
      if (resp.data.code === 20000) {
        return {
          page: resp.data.data?.page ?? 0,
          size: resp.data.data?.size ?? 0,
          totalElements: resp.data.data?.totalElements ?? 0,
          totalPages: resp.data.data?.totalPages ?? 0,
          data:
            resp.data.data?.data?.map((d) =>
              mapSalaryAdvanceTypeInfoModel(d),
            ) ?? [],
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
