import { FlexSalaryClient } from "@/api/flexsalary_http_client";
import { ErrorModel } from "@/models/ErrorModel";
import { StatisticRepository } from "../StatisticRepository";
import {
  mapGetPaidListModel,
  mapTotalStatisticResponseModel,
} from "../adapters/statistic-adapter";
import {
  EmployeePaidInfoModel,
  PaidChartItemModel,
  TotalStatisticResponseModel,
} from "../models/StatisticModel";

export class StatisticRepositoryImp implements StatisticRepository {
  private _c: FlexSalaryClient;
  constructor(flexsalaryClient: FlexSalaryClient) {
    this._c = flexsalaryClient;
  }

  public async getPaidChart({
    fromDate,
    toDate,
  }: {
    fromDate?: number;
    toDate?: number;
  }): Promise<Array<PaidChartItemModel>> {
    try {
      let resp = await this._c.statistic.getPaidChart({
        fromDate: fromDate,
        toDate: toDate,
      });
      if (resp.data.code === 20000) {
        return resp.data.data?.paidList ?? [];
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getTotalStatisticCurrentPeriod(): Promise<TotalStatisticResponseModel> {
    try {
      let resp = await this._c.statistic.getTotalStatisticCurrentPeriod();
      if (resp.data.code === 20000) {
        return mapTotalStatisticResponseModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getPaidList({
    keyword,
    salaryPeriodId,
    page,
    size,
  }: {
    keyword?: string;
    salaryPeriodId?: string;
    page?: number;
    size?: number;
  }): Promise<{
    pageNumber: number;
    pageSize: number;
    totalPages: number;
    totalElements: number;
    items: EmployeePaidInfoModel[];
  }> {
    try {
      let resp = await this._c.statistic.getPaidList({
        keyword: keyword,
        salaryPeriodId: salaryPeriodId,
        page: page,
        size: size,
      });
      if (resp.data.code === 20000) {
        return {
          pageNumber: resp.data.data?.page ?? 0,
          pageSize: resp.data.data?.size ?? 0,
          totalPages: resp.data.data?.totalPages ?? 0,
          totalElements: resp.data.data?.totalElements ?? 0,
          items: resp.data.data?.data?.map((d) => mapGetPaidListModel(d)) ?? [],
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getPaidListExportToExcel({
    keyword,
    salaryPeriodId,
    page,
    size,
  }: {
    keyword?: string;
    salaryPeriodId?: string;
    page?: number;
    size?: number;
  }): Promise<File> {
    try {
      let resp = await this._c.statistic.exportPaidList(
        {
          keyword: keyword,
          salaryPeriodId: salaryPeriodId,
          page: page,
          size: size,
        },
        { responseType: "arraybuffer" },
      );
      return resp.data as File;
    } catch (error) {
      throw error;
    }
  }
}
