import { kienlongPayConfig } from "@/api/kienlong_pay_http_client";
import { payboxConfig } from "@/api/paybox_http_client";
import { storageConfig } from "@/api/storage_http_client";
import { FutureComponent } from "@/components/future-component/FutureComponent";
import { AppBloc } from "@/services/base-bloc";
import { AppRepository } from "@/services/repository";
import "@/styles";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { AppContextProvider } from "./contexts/app";
import { ViewportContextProvider } from "./contexts/viewport";
import "./index.css";
import { Environment } from "./services/environment";
import i18n from "./services/i18n";
import { flexSalaryConfig } from "./api/flexsalary_http_client";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
  {
    identifierPrefix: Environment.componentPrefix,
    onRecoverableError(error, errorInfo) {
      console.error(error);
      console.error(errorInfo);
    },
  },
);

const appBloc = new AppBloc();

flexSalaryConfig.basePath = Environment.hostApiFlexSalary;

appBloc.repository = new AppRepository(
  kienlongPayConfig.basePath,
  payboxConfig.basePath,
  storageConfig.basePath,
  Environment.hostApiFlexSalary,
);

if (
  appBloc.storage.testMode &&
  (appBloc.storage.isLogin ||
    window.location.pathname.startsWith("/test/public"))
) {
  kienlongPayConfig.basePath = Environment.hostApiKlbPayTestMode;
  storageConfig.basePath = Environment.hostApiStorageTestMode;
  payboxConfig.basePath = Environment.hostApiPayBoxTestMode;
  flexSalaryConfig.basePath = Environment.hostApiFlexSalary;
  appBloc.repository = new AppRepository(
    kienlongPayConfig.basePath,
    payboxConfig.basePath,
    storageConfig.basePath,
    Environment.hostApiFlexSalary,
  );
}

i18n.changeLanguage(appBloc.storage.language).finally(() => {
  root.render(
    <BrowserRouter future={{ v7_startTransition: true }}>
      <I18nextProvider i18n={i18n}>
        <FutureComponent>
          <AppContextProvider appProps={appBloc}>
            <ViewportContextProvider>
              <App />,
            </ViewportContextProvider>
          </AppContextProvider>
        </FutureComponent>
      </I18nextProvider>
    </BrowserRouter>,
  );
});
