import { StaticQrResponse } from "@/api/generated-sources/paybox";
import {
  PayboxStaticQrModel,
  PayboxStaticQrType,
} from "@/models/PayboxStaticQrModel";

export function mapPayboxStaticQrModel(
  dto: StaticQrResponse | undefined,
): PayboxStaticQrModel {
  return {
    accountName: dto?.accountName ?? "",
    accountNumber: dto?.accountNumber ?? "",
    shortName: dto?.shortName ?? "",
    content: dto?.content ?? "",
    deviceId: dto?.deviceId ?? "",
    id: dto?.id ?? "",
    // icon: dto?. ?? "",
    icon: dto?.icon ?? "",
    staticQrType:
      PayboxStaticQrType[
        (dto?.staticQrType ?? "BANK") as keyof typeof PayboxStaticQrType
      ],
    shopName: dto?.shopName ?? "",
    qrCode: dto?.qrCode ?? "",
    name: dto?.name ?? "",
    defaultQr: dto?.defaultQr ?? false,
  };
}
