import { CustomerModel } from "@/models/CustomersModel";
import { ErrorModel } from "@/models/ErrorModel";
import { CustomerRepository } from "../CustomerRepository";
import {
  mapCustomermModal,
  mapGroupCustomerModel,
} from "@/adapters/customers_adapter";
import { GroupCustomerModel } from "@/models/GroupCustomerModel";
import { KienlongPayClient } from "@/api/kienlong_pay_http_client";
import { SortDirectionType } from "@/models/Sort";
import { CustomerGroupResponse } from "@/api/generated-sources/kienlong-pay";

export class CustomerRepositoryImp implements CustomerRepository {
  private client: KienlongPayClient;
  constructor(kienlongPayClient: KienlongPayClient) {
    this.client = kienlongPayClient;
  }

  public async createCustomer({
    name,
    email,
    phone,
    address,
    groupId,
  }: {
    name: string;
    email: string;
    phone: string;
    address: string;
    groupId?: string;
  }): Promise<CustomerModel> {
    try {
      let resp = await this.client.customerController.createCustomer({
        createCustomerRequest: {
          address: address,
          email: email,
          name: name,
          phone: phone,
          groupId: groupId,
        },
      });
      if (resp.data.code === 0) {
        return mapCustomermModal(resp.data?.data?.customer);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async createCustomerGroup({
    name,
    customerIds,
  }: {
    name: string;
    customerIds: string[];
  }): Promise<GroupCustomerModel> {
    try {
      let resp = await this.client.customerController.createCustomerGroup({
        createCustomerGroupRequest: {
          name: name,
          customerIds: customerIds,
        },
      });
      if (resp.data.code === 0) {
        return mapGroupCustomerModel(resp.data.data?.customerGroup);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getListCustomer({
    keyword,
    page,
    pageSize,
    sortBy,
    sortDirection = SortDirectionType.DESC,
    haveGroup,
    groupId,
  }: {
    keyword?: string;
    page?: number;
    pageSize?: number;
    sortBy?: string;
    sortDirection?: SortDirectionType;
    haveGroup?: boolean;
    groupId?: string;
  }): Promise<{
    pageNumber: number;
    pageSize: number;
    totalSize: number;
    totalPage: number;
    items: CustomerModel[];
  }> {
    try {
      let resp = await this.client.customerController.getListCustomer({
        keyword: keyword,
        page: page,
        pageSize: pageSize,
        sortBy: "code",
        sortDirection: sortDirection,
        haveGroup: haveGroup,
        groupId: groupId,
      });
      if (resp.data.code === 0) {
        return {
          items:
            resp.data?.data?.items?.map((d, i) => mapCustomermModal(d)) ?? [],
          pageNumber: resp.data.data?.pageNumber ?? 0,
          pageSize: resp.data.data?.pageSize ?? 0,
          totalSize: resp.data.data?.totalSize ?? 0,
          totalPage: resp.data.data?.totalPage ?? 0,
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getListGroupCustomer({
    groupName,
    page,
    pageSize,
    sortBy,
    sortDirection = SortDirectionType.DESC,
  }: {
    groupName?: string;
    page?: number;
    pageSize?: number;
    sortBy?: string;
    sortDirection?: SortDirectionType;
  }): Promise<{
    pageNumber: number;
    pageSize: number;
    totalSize: number;
    totalPage: number;
    items: GroupCustomerModel[];
  }> {
    try {
      let resp = await this.client.customerController.getListCustomerGroup({
        groupName: groupName,
        page: page,
        pageSize: pageSize,
        sortBy: sortBy,
        sortDirection: sortDirection,
      });
      if (resp.data.code === 0) {
        return {
          items:
            resp.data.data?.items?.map<GroupCustomerModel>((d, i) => {
              return mapGroupCustomerModel(d);
            }) ?? [],
          pageNumber: resp.data.data?.pageNumber ?? 0,
          pageSize: resp.data.data?.pageSize ?? 0,
          totalSize: resp.data.data?.totalSize ?? 0,
          totalPage: resp.data.data?.totalPage ?? 0,
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getGroupDetailCustomer({
    customerGroupId,
  }: {
    customerGroupId: string;
  }): Promise<CustomerGroupResponse> {
    try {
      let resp = await this.client.customerController.getDetailCustomerGroup({
        customerGroupId,
      });
      if (resp.data.code === 0) {
        return {
          customerGroup: mapGroupCustomerModel(resp.data.data?.customerGroup),
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async updateGroup({
    id,
    name,
    customerIds,
  }: {
    id: string;
    name: string;
    customerIds?: [];
  }): Promise<GroupCustomerModel> {
    try {
      let resp = await this.client.customerController.updateCustomerGroup({
        updateCustomerGroupRequest: {
          id,
          name,
          customerIds,
        },
      });
      if (resp.data.code === 0) {
        return mapGroupCustomerModel(resp.data.data?.customerGroup);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async deleteGroupCustomer({ id }: { id: string }): Promise<boolean> {
    try {
      let resp = await this.client.customerController.deleteCustomerGroup({
        id,
      });
      if (resp.data.code === 0 && resp.data.data) {
        return resp?.data.data.success ?? false;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
  public async updateCustomer({
    id,
    address,
    email,
    groupId,
    name,
    phone,
  }: {
    id: string;
    address?: string;
    email?: string;
    groupId?: string;
    name?: string;
    phone?: string;
  }): Promise<CustomerModel> {
    try {
      let resp = await this.client.customerController.updateCustomer({
        updateCustomerRequest: {
          id: id,
          address: address,
          email: email,
          groupId: groupId,
          name: name,
          phone: phone,
        },
      });
      if (resp.data.code === 0) {
        return mapCustomermModal(resp.data.data?.customer);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
  public async deleteCustomer({ id }: { id: string }): Promise<boolean> {
    try {
      let resp = await this.client.customerController.deleteCustomer({
        id,
      });
      if (resp.data.code === 0 && resp.data.data) {
        return resp?.data.data.success ?? false;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async addCustomersToGroup({
    customerId,
    groupId,
    name,
  }: {
    customerId: string[];
    groupId: string;
    name: string;
  }): Promise<boolean> {
    try {
      let resp = await this.client.customerController.addCustomersToGroup({
        addCustomersToGroupRequest: {
          customerId: customerId,
          groupId: groupId,
          name: name,
        },
      });
      if (resp.data.code === 0 && resp.data.data) {
        return resp?.data.data.success ?? false;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getDetailGroupCustomer({
    customerGroupId,
  }: {
    customerGroupId: string;
  }): Promise<GroupCustomerModel> {
    try {
      let resp = await this.client.customerController.getDetailCustomerGroup({
        customerGroupId: customerGroupId,
      });
      if (resp.data.code === 0) {
        return mapGroupCustomerModel(resp.data.data?.customerGroup);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getListGroupCustomerCache({
    groupName,
    page,
    pageSize,
    sortBy,
    sortDirection = SortDirectionType.DESC,
  }: {
    groupName?: string;
    page?: number;
    pageSize?: number;
    sortBy?: string;
    sortDirection?: SortDirectionType;
  }): Promise<{
    pageNumber: number;
    pageSize: number;
    totalSize: number;
    totalPage: number;
    items: GroupCustomerModel[];
  }> {
    try {
      let resp = await this.client.customerController.getListCustomerGroupCache(
        {
          groupName: groupName,
          page: page,
          pageSize: pageSize,
          sortBy: sortBy,
          sortDirection: sortDirection,
        },
      );
      if (resp.data.code === 0) {
        return {
          items:
            resp.data.data?.items?.map<GroupCustomerModel>((d, i) => {
              return mapGroupCustomerModel(d);
            }) ?? [],
          pageNumber: resp.data.data?.pageNumber ?? 0,
          pageSize: resp.data.data?.pageSize ?? 0,
          totalSize: resp.data.data?.totalSize ?? 0,
          totalPage: resp.data.data?.totalPage ?? 0,
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
