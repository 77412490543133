import { createContext } from "react";

interface ViewportProps {
  width: number;
  height: number;
}

const ViewportContext = createContext<ViewportProps>({
  height: window.innerHeight,
  width: window.innerWidth,
});

export type { ViewportProps };
export { ViewportContext };
