import { mapSystemParamModel } from "@/adapters/system_param_adapder";
import { mapUserModel } from "@/adapters/user_adapter";
import { KienlongPayClient } from "@/api/kienlong_pay_http_client";
import { ErrorModel } from "@/models/ErrorModel";
import { SystemParamModel } from "@/models/SystemParamModel";
import { UserModel, UserStatusType } from "@/models/UserModel";
import { SettingRepository } from "../SettingRepository";

export class SettingRepositoryImp implements SettingRepository {
  private client: KienlongPayClient;
  constructor(kienlongPayClient: KienlongPayClient) {
    this.client = kienlongPayClient;
  }

  public async updateEmail({
    allowEmail,
    email,
  }: {
    allowEmail: boolean;
    email: string;
  }): Promise<boolean> {
    try {
      let resp = await this.client.portalSettingControleller.updateContactEmail(
        {
          updateContactEmailRequest: {
            email: email,
            allowEmail: allowEmail,
          },
        },
      );
      if (resp.data.code === 0) {
        return resp.data.data?.success ?? false;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async createMember({
    bankAccountIds,
    email,
    name,
    phone,
    roleGroupId,
  }: {
    bankAccountIds: string[];
    email: string;
    name: string;
    phone: string;
    roleGroupId: number;
  }): Promise<UserModel> {
    try {
      let resp = await this.client.portalSettingControleller.createMember({
        portalCreateMemberRequest: {
          bankAccountIds: bankAccountIds,
          email: email,
          name: name,
          phone: phone,
          roleGroupId: roleGroupId,
        },
      });
      if (resp.data.code === 0) {
        return mapUserModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async updateMember({
    bankAccountIds,
    name,
    status,
    roleGroupId,
    memberId,
    email,
  }: {
    memberId: string;
    bankAccountIds?: string[];
    name?: string;
    status?: UserStatusType;
    roleGroupId: number;
    email: string;
  }): Promise<UserModel> {
    try {
      let resp = await this.client.portalSettingControleller.updateMember({
        memberId: memberId,
        portalUpdateMemberRequest: {
          bankAccountIds: bankAccountIds,
          name: name,
          roleGroupId: roleGroupId,
          status: status,
          email: email,
        },
      });
      if (resp.data.code === 0) {
        return mapUserModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async deleteMember({
    memberId,
  }: {
    memberId: string;
  }): Promise<UserModel> {
    try {
      let resp = await this.client.portalSettingControleller.deleteMember({
        memberId: memberId,
      });
      if (resp.data.code === 0) {
        return mapUserModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getSysContactParams(): Promise<SystemParamModel[]> {
    try {
      let resp =
        await this.client.portalSettingControleller.getSysContactParams();
      if (resp.data.code === 0) {
        return (
          resp.data.data?.parameters?.map((d) => mapSystemParamModel(d)) ?? []
        );
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
