import {
  FeeReportDto,
  MonthlyFeeReportDto,
} from "@/api/generated-sources/kienlong-pay";
import {
  FeeReportCalculatePer,
  FeeReportFeeMethod,
  FeeReportModel,
  FeeReportServiceType,
  MonthlyFeeReportModel,
  MonthlyFeeReportStatus,
} from "@/models/FeeModel";

export function mapMonthlyFeeReportModel(
  dto?: MonthlyFeeReportDto | undefined,
): MonthlyFeeReportModel {
  return {
    createdBy: dto?.createdBy ?? "",
    createdDate: dto?.createdDate ?? "",
    feeReports: dto?.feeReports?.map((d) => mapFeeReportModel(d)) ?? [],
    id: dto?.id ?? Date.now(),
    lastModifiedBy: dto?.lastModifiedBy ?? "",
    lastModifiedDate: dto?.lastModifiedDate ?? "",
    merchantName: dto?.merchantName ?? "",
    prefix: dto?.prefix ?? "",
    status:
      MonthlyFeeReportStatus[
        dto?.status ??
          ("WAITING_PAYMENT" as keyof typeof MonthlyFeeReportStatus)
      ],
    time: dto?.time ?? "",
    totalFeeMoney: dto?.totalFeeMoney ?? -1,
    totalTransaction: dto?.totalTransaction ?? -1,
    feeMoney: dto?.feeMoney ?? -1,
    moneyVAT: dto?.moneyVAT ?? -1,
  };
}

export function mapFeeReportModel(
  dto?: FeeReportDto | undefined,
): FeeReportModel {
  return {
    activeFeeDate: dto?.activeFeeDate ?? "",
    amountTransaction: dto?.amountTransaction ?? 0,
    calculatePer:
      FeeReportCalculatePer[
        dto?.calculatePer ?? ("NONE" as keyof typeof FeeReportCalculatePer)
      ],
    feeCode: dto?.feeCode ?? "",
    feeMethod:
      FeeReportFeeMethod[
        dto?.feeMethod ?? ("BRACKET_FEE" as keyof typeof FeeReportFeeMethod)
      ],
    feeMoney: dto?.feeMoney ?? 0,
    moneyVAT: dto?.moneyVAT ?? 0,
    prefix: dto?.prefix ?? "",
    serviceType:
      FeeReportServiceType[
        (dto?.serviceType ?? "COBO") as keyof typeof FeeReportServiceType
      ],
    time: dto?.time ?? "",
    totalFeeMoney: dto?.totalFeeMoney ?? 0,
    id: dto?.id ?? "",
  };
}
