import { SalaryPeriodRepository } from "@/api/flexsalary-repositories/SalaryPeriodRepository.ts";
import { FlexSalaryClient } from "@/api/flexsalary_http_client.ts";
import { ErrorModel } from "@/models/ErrorModel.ts";
import { SalaryPeriodInfoModel } from "@/api/flexsalary-repositories/models/SalaryPeriodInfoModel.ts";
import { mapSalaryPeriodInfoModel } from "@/api/flexsalary-repositories/adapters/salary-period-adapter.ts";

export class SalaryPeriodRepositoryImp implements SalaryPeriodRepository {
  private _c: FlexSalaryClient;
  constructor(flexsalaryClient: FlexSalaryClient) {
    this._c = flexsalaryClient;
  }

  public async listPeriod({
    page = 0,
    size = 10,
    keyword,
  }: {
    page?: number;
    size?: number;
    keyword?: string;
  }): Promise<{
    pageNumber: number;
    pageSize: number;
    totalPages: number;
    totalElements: number;
    items: SalaryPeriodInfoModel[];
  }> {
    try {
      let resp = await this._c.salaryPeriod.getListPeriod({
        page: page,
        size: size,
        keyword: keyword,
      });
      if (resp.data.code === 20000) {
        return {
          pageNumber: resp.data.data?.page ?? 0,
          pageSize: resp.data.data?.size ?? 0,
          totalPages: resp.data.data?.totalPages ?? 0,
          totalElements: resp.data.data?.totalElements ?? 0,
          items:
            resp.data.data?.data?.map((d) => mapSalaryPeriodInfoModel(d)) ?? [],
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async addSalaryPeriod({
    endDate,
    month,
    startDate,
    workingDays,
    paySalaryDate,
  }: {
    month: string;
    startDate: string;
    endDate: string;
    paySalaryDate: string;
    workingDays: number;
  }): Promise<string> {
    try {
      let resp = await this._c.salaryPeriod.addSalaryPeriod({
        salaryPeriodRequest: {
          endDate: endDate,
          month: month,
          startDate: startDate,
          workingDays: workingDays,
          paySalaryDate: paySalaryDate,
        },
      });
      if (resp.data.code === 20000) {
        return resp.data.data?.id ?? "";
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
