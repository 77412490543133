import { Environment } from "./environment";

export const iconPath = `${Environment.hostApp}/icons`;
export const imagePath = `${Environment.hostApp}/images`;
export const filePath = `${Environment.hostApp}/files`;
// import icons
export const iconLogoEx = `${iconPath}/icon-logo-example.png`;
export const iconLogout = `${iconPath}/icon-logout.png`;
export const iconSecurity = `${iconPath}/icon-security.png`;
export const iconUser = `${iconPath}/icon-user.png`;
export const iconShopHouse = `${iconPath}/icon-shop-house.png`;
export const iconCard = `${iconPath}/icon-card.png`;
export const iconDashboard = `${iconPath}/icon-dashboard.png`;
export const iconWallet = `${iconPath}/icon-wallet.png`;
export const iconOpenEye = `${iconPath}/icon-open-eye.svg`;
export const iconAlert = `${iconPath}/icon-alert.png`;
export const iconCircleEmpty = `${iconPath}/icon-circle-empty.svg`;
export const iconCheckCircle = `${iconPath}/icon-check-circle.svg`;
export const iconCloseCircle = `${iconPath}/icon-close-circle.svg`;
export const iconCloseEye = `${iconPath}/icon-close-eye.svg`;

export const iconKienlongBank = `${iconPath}/icon-kienlong-bank.svg`;
export const iconUmeeBank = `${iconPath}/icon-umee-bank.svg`;

export const iconIdenticateAccountSetting = `${iconPath}/icon-identicate-account-setting.svg`;
export const iconAccountInfoSetting = `${iconPath}/icon-account-info-setting.svg`;
export const iconCardRoundSetting = `${iconPath}/icon-card-round-setting.svg`;
export const iconUpload = `${iconPath}/icon-upload.svg`;
export const iconDocument = `${iconPath}/icon-document.svg`;
export const iconVietQr = `${iconPath}/icon-viet-qr.svg`;
export const iconAppMarket = `${iconPath}/icon-app-market.svg`;
export const iconVietQrRound = `${iconPath}/icon-viet-qr-round.svg`;
export const iconTechnicalIntegrate = `${iconPath}/icon-technical-integrate.svg`;

export const iconPdfFile = `${iconPath}/icon-pdf-file.svg`;

// bank for paylink
export const iconACBbank = `${iconPath}/icon-acb-bank.svg`;
export const iconAgribank = `${iconPath}/icon-agri-bank.svg`;
export const iconBIDVbank = `${iconPath}/icon-bidv-bank.svg`;
export const iconEXIMbank = `${iconPath}/icon-exim-bank.svg`;
export const iconHDbank = `${iconPath}/icon-hd-bank.svg`;
export const iconKienlongBank2 = `${iconPath}/icon-kien-long-bank.svg`;
export const iconMBbank = `${iconPath}/icon-mb-bank.svg`;
export const iconMSBbank = `${iconPath}/icon-msb-bank.svg`;
export const iconOCBbank = `${iconPath}/icon-ocb-bank.svg`;
export const iconOceanBank = `${iconPath}/icon-ocean-bank.svg`;
export const iconSacombank = `${iconPath}/icon-sacom-bank.svg`;
export const iconSCBbank = `${iconPath}/icon-scb-bank.svg`;
export const iconSeAbank = `${iconPath}/icon-seA-bank.svg`;
export const iconSHBbank = `${iconPath}/icon-shb-bank.svg`;
export const iconTechcombank = `${iconPath}/icon-techcom-bank.svg`;
export const iconTPbank = `${iconPath}/icon-tp-bank.svg`;
export const iconVIBbank = `${iconPath}/icon-vib-bank.svg`;
export const iconVietcombank = `${iconPath}/icon-vietcom-bank.svg`;
export const iconVietinbank = `${iconPath}/icon-vietin-bank.svg`;

export const iconVnFlag = `${iconPath}/icon-vn-flag.svg`;
export const iconUsFlag = `${iconPath}/icon-us-flag.svg`;

export const iconNotify = `${iconPath}/icon-notify.svg`;
export const iconAlert2 = `${iconPath}/icon-alert-2.svg`;

export const iconKienlongX40 = `${iconPath}/icon-kienlong-x40.svg`;
export const iconUmeeX40 = `${iconPath}/icon-umee-x40.svg`;

export const iconMenu = `${iconPath}/icon-menu.svg`;

export const iconTelephone = `${iconPath}/icon-telephone.svg`;
export const iconMail2 = `${iconPath}/icon-mail-2.svg`;
export const iconMailSetting = `${iconPath}/icon-mail-setting.svg`;
export const iconPasswordSetting = `${iconPath}/icon-password-setting.svg`;
export const iconMemberSetting = `${iconPath}/icon-member-setting.svg`;

export const iconProductEmpty = `${iconPath}/icon-product-empty.svg`;

export const iconSuccess = `${iconPath}/icon-success.svg`;
export const iconCheckError = `${iconPath}/icon-check-error.svg`;

export const iconIllus = `${iconPath}/icon-illus.svg`;
export const iconToolTransferMoney = `${iconPath}/icon-tool-transfer-money.svg`;
export const iconZaloPay = `${iconPath}/banks/icon-zalo-pay.png`;
export const iconMomo = `${iconPath}/banks/icon-momo.png`;

export const iconAtmCard = `${iconPath}/icon-atm-card.svg`;
export const iconBank = `${iconPath}/icon-bank.svg`;

export const iconVietQrProX40 = `${iconPath}/icon-vietqr-pro-x40.svg`;
export const iconAppMarketX40 = `${iconPath}/icon-app-market-x40.svg`;
export const iconFlexibleSalaryPaymentX40 = `${iconPath}/icon-flexible-salary-payment-x40.svg`;
export const iconCurrencyX20 = `${iconPath}/icon-currency-x20.svg`;
export const iconMoneyV2X20 = `${iconPath}/icon-money-v2-x20.svg`;
export const iconCheckV2X20 = `${iconPath}/icon-check-v2-x20.svg`;

// import images
export const imgRegisterSuccess = `${imagePath}/register-success.svg`;
export const imgBannerDashBoard = `${imagePath}/baner-dashboard.svg`;
export const imgBanerDepositPayment = `${imagePath}/baner-deposit-payment.svg`;
export const imgCCCDBackend = `${imagePath}/cccd-backend.png`;
export const imgCCCDFrontend = `${imagePath}/cccd-frontend.png`;
export const imgMobileAppStore = `${imagePath}/mobile-app-store.svg`;
export const imgGooglePlayStore = `${imagePath}/google-play-app.svg`;
export const imgBanerIdenticateAccount = `${imagePath}/baner-identicate-account.png`;
export const imgBanerIdenticateAccount2 = `${imagePath}/baner-identicate-account-2.png`;

// import images advertisement
export const imgAdvertisement1 = `${imagePath}/advertisement1.png`;
export const imgAdvertisement2 = `${imagePath}/advertisement2.png`;

export const imgBanerNotIdenticate = `${imagePath}/img-baner-not-identicate.png`;

export const imgEmptyCustomer = `${imagePath}/img-empty-customer.png`;
export const imgBackgroundLoginV2 = `${imagePath}/login-background-v2.svg`;
const listIconBank = [
  { urlIcon: "/icons//banks/icon-momo.png", nameIcon: "MoMo" },
  { urlIcon: "/icons/banks/icon-zalo-pay.png", nameIcon: "ZaloPay" },
  { urlIcon: "/icons/banks/icon-abbank.svg", nameIcon: "ABBANK" },
  { urlIcon: "/icons/banks/icon-acb.svg", nameIcon: "ACB" },
  { urlIcon: "/icons/banks/icon-agribank.svg", nameIcon: "Agribank" },
  { urlIcon: "/icons/banks/icon-bidv.svg", nameIcon: "BIDV" },
  { urlIcon: "/icons/banks/icon-bacabank.svg", nameIcon: "BacABank" },
  { urlIcon: "/icons/banks/icon-baovietbank.svg", nameIcon: "BaoVietBank" },
  { urlIcon: "/icons/banks/icon-cake.svg", nameIcon: "CAKE" },
  { urlIcon: "/icons/banks/icon-cbbank.svg", nameIcon: "CBBank" },
  { urlIcon: "/icons/banks/icon-cimb.svg", nameIcon: "CIMB" },
  { urlIcon: "/icons/banks/icon-coopbank.svg", nameIcon: "COOPBANK" },
  { urlIcon: "/icons/banks/icon-dbsbank.svg", nameIcon: "DBSBank" },
  { urlIcon: "/icons/banks/icon-dongabank.svg", nameIcon: "DongABank" },
  { urlIcon: "/icons/banks/icon-eximbank.svg", nameIcon: "Eximbank" },
  { urlIcon: "/icons/banks/icon-gpbank.svg", nameIcon: "GPBank" },
  { urlIcon: "/icons/banks/icon-hdbank.svg", nameIcon: "HDBank" },
  { urlIcon: "/icons/banks/icon-hsbc.svg", nameIcon: "HSBC" },
  { urlIcon: "/icons/banks/icon-hongleong.svg", nameIcon: "HongLeong" },
  { urlIcon: "/icons/banks/icon-ibkhcm.svg", nameIcon: "IBKHCM" },
  { urlIcon: "/icons/banks/icon-ibkhn.svg", nameIcon: "IBKHN" },
  {
    urlIcon: "/icons/banks/icon-kienlongbank.svg",
    nameIcon: "KienLongBank",
  },
  { urlIcon: "/icons/banks/icon-mbbank.svg", nameIcon: "MBBank" },
  { urlIcon: "/icons/banks/icon-msb.svg", nameIcon: "MSB" },
  { urlIcon: "/icons/banks/icon-ncb.svg", nameIcon: "NCB" },
  { urlIcon: "/icons/banks/icon-namabank.svg", nameIcon: "NamABank" },
  { urlIcon: "/icons/banks/icon-nonghyup.svg", nameIcon: "Nonghyup" },
  { urlIcon: "/icons/banks/icon-ocb.svg", nameIcon: "OCB" },
  { urlIcon: "/icons/banks/icon-oceanbank.svg", nameIcon: "Oceanbank" },
  { urlIcon: "/icons/banks/icon-pgbank.svg", nameIcon: "PGBank" },
  { urlIcon: "/icons/banks/icon-pvcombank.svg", nameIcon: "PVcomBank" },
  { urlIcon: "/icons/banks/icon-scb.svg", nameIcon: "SCB" },
  { urlIcon: "/icons/banks/icon-shb.svg", nameIcon: "SHB" },
  { urlIcon: "/icons/banks/icon-sacombank.svg", nameIcon: "Sacombank" },
  { urlIcon: "/icons/banks/icon-saigonbank.svg", nameIcon: "SaigonBank" },
  { urlIcon: "/icons/banks/icon-seabank.svg", nameIcon: "SeABank" },
  { urlIcon: "/icons/banks/icon-shinhanbank.svg", nameIcon: "ShinhanBank" },
  { urlIcon: "/icons/banks/icon-tpbank.svg", nameIcon: "TPBank" },
  { urlIcon: "/icons/banks/icon-techcombank.svg", nameIcon: "Techcombank" },
  { urlIcon: "/icons/banks/icon-ubank.svg", nameIcon: "Ubank" },
  { urlIcon: "/icons/banks/icon-umee.svg", nameIcon: "Umee" },
  { urlIcon: "/icons/banks/icon-vib.svg", nameIcon: "VIB" },
  { urlIcon: "/icons/banks/icon-vpbank.svg", nameIcon: "VPBank" },
  { urlIcon: "/icons/banks/icon-vrb.svg", nameIcon: "VRB" },
  { urlIcon: "/icons/banks/icon-vietabank.svg", nameIcon: "VietABank" },
  { urlIcon: "/icons/banks/icon-vietbank.svg", nameIcon: "VietBank" },
  { urlIcon: "/icons/banks/icon-vietcombank.svg", nameIcon: "Vietcombank" },
  { urlIcon: "/icons/banks/icon-vietinbank.svg", nameIcon: "VietinBank" },
  {
    urlIcon: "/icons/banks/icon-viettel-money.svg",
    nameIcon: "Viettel money",
  },
  { urlIcon: "/icons/banks/icon-woori.svg", nameIcon: "Woori" },
];
export { listIconBank };

export const imgHeaderBackgroundPaymentMobile = `${imagePath}/img-header-backgound-payment-mobile.svg`;
export const imgHeaderBackgroundPaymentWeb = `${imagePath}/img-header-backgound-payment-web.svg`;
export const imgNoData = `${imagePath}/no-data.png`;
export const imgLinkingAccount = `${imagePath}/img-linking-account.svg`;
export const imgBannerSendEmailSuccess = `${imagePath}/img-banner-send-email-success.svg`;
export const imgBannerSendEmailFail = `${imagePath}/img-banner-send-email-fail.svg`;
export const imgQrFailed = `${imagePath}/img-qr-failed.png`;
export const imgActivateSuccess = `${imagePath}/img-activate-success.svg`;

export const imgVietQrProIllus = `${imagePath}/img-viet-qr-pro-illus.png`;
export const imgBannerFlexibleSalaryPayment = `${imagePath}/img-banner-flexible-salary-payment.svg`;
export const imgTickSuccess = `${imagePath}/transaction-success.png`;
export const imgAutoApprove = `${imagePath}/img-auto-approve.svg`;
