import { StorageClient } from "@/api/storage_http_client";
import { ErrorModel } from "@/models/ErrorModel";
import { TypeFileMedia } from "@/models/MediaModel";
import { MediaRepository } from "../MediaRepository";

export class MediaRepositoryImp implements MediaRepository {
  private client: StorageClient;
  constructor(storageClient: StorageClient) {
    this.client = storageClient;
  }

  public async uploadFile({
    bucket,
    fileType,
    file,
  }: {
    bucket: string;
    fileType: TypeFileMedia;
    file: File;
  }): Promise<string> {
    try {
      let resp = await this.client.mediaController.upload({
        fileType: fileType,
        bucketName: bucket,
        file: file,
      });

      if (resp.data.previewUrl !== undefined) {
        return resp.data.previewUrl;
      } else {
        throw new ErrorModel({
          code: resp.status,
          message: "Can not upload this media",
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
