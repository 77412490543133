import { KienlongPayClient } from "@/api/kienlong_pay_http_client";
import { FeeReportRepository } from "../FeeReportRepository";
import {
  MonthlyFeeReportModel,
  MonthlyFeeReportStatus,
} from "@/models/FeeModel";
import { mapMonthlyFeeReportModel } from "@/adapters/fee_report_adapter";
import { ErrorModel } from "@/models/ErrorModel";
import { PageTransactionResponseModel } from "@/models/PageTransactionModel";
import { TransactionResponseModel } from "@/models/TransactionModel";
import { mapTransacionResponseModel } from "@/adapters/transaction_adapter";

export class FeeReportRepositoryImp implements FeeReportRepository {
  private client: KienlongPayClient;
  constructor(kienlongPayClient: KienlongPayClient) {
    this.client = kienlongPayClient;
  }

  public async getReportFeeMerchant({
    fromDate,
    toDate,
    page,
    size,
    sort,
    status,
  }: {
    fromDate: string;
    toDate: string;
    page?: number;
    size?: number;
    sort?: string[];
    status?: MonthlyFeeReportStatus;
  }): Promise<{
    pageNumber: number;
    pageSize: number;
    totalSize: number;
    totalPage: number;
    items: Array<MonthlyFeeReportModel>;
    totalMoney: number;
  }> {
    try {
      let resp =
        await this.client.feeReportController.getReportFeeMerchantPortal({
          fromDate: fromDate,
          toDate: toDate,
          page: page,
          size: size,
          sort: sort,
          status: status,
        });
      if (resp.data.code === 0) {
        return {
          items:
            resp.data.data?.feeReports?.items?.map((d) =>
              mapMonthlyFeeReportModel(d),
            ) ?? [],
          pageNumber: resp.data.data?.feeReports?.pageNumber ?? 0,
          pageSize: resp.data.data?.feeReports?.pageSize ?? 0,
          totalPage: resp.data.data?.feeReports?.totalPage ?? 0,
          totalSize: resp.data.data?.feeReports?.totalSize ?? 0,
          totalMoney: resp.data.data?.totalMoney ?? 0,
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getReportFeeDetail({
    id,
  }: {
    id: number;
  }): Promise<MonthlyFeeReportModel> {
    try {
      let resp = await this.client.feeReportController.getReportFeeDetailPortal(
        {
          id: id,
        },
      );
      if (resp.data.code === 0) {
        return mapMonthlyFeeReportModel(resp.data.data?.feeReport);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async paymentReportFeeDetail({ id }: { id: number }): Promise<string> {
    try {
      let resp = await this.client.feeReportController.payFeeMerchant({
        id: id,
      });
      if (resp.data.code === 0) {
        return resp?.data.data?.payLinkUrl ?? "";
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async exportReportPortal({
    fromDate,
    toDate,
    status,
  }: {
    fromDate: string;
    toDate: string;
    status?: MonthlyFeeReportStatus;
  }): Promise<File> {
    try {
      let resp = await this.client.feeReportController.exportReportFeePortal(
        {
          fromDate: fromDate,
          status: status,
          toDate: toDate,
        },
        { responseType: "arraybuffer" },
      );
      return resp.data;
    } catch (error) {
      throw error;
    }
  }

  public async getTransactionFeeReportPortal({
    feeReportId,
    page,
    size,
    sort,
  }: {
    feeReportId?: string;
    page?: number;
    size?: number;
    sort?: string[];
  }): Promise<PageTransactionResponseModel> {
    try {
      let resp =
        await this.client.feeReportController.getTransactionFeeReportPortal({
          feeReportId,
          page,
          size,
          sort,
        });
      if (resp.data.code === 0) {
        return {
          pageNumber: resp.data.data?.pageNumber ?? 0,
          pageSize: resp.data.data?.pageSize ?? 0,
          totalPage: resp.data.data?.totalPage ?? 0,
          totalSize: resp.data.data?.totalSize ?? 0,
          items:
            resp.data.data?.items?.map((d): TransactionResponseModel => {
              return mapTransacionResponseModel(d);
            }) ?? [],
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
