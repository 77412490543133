import { IconArrow } from "@/components/icons-component/icon-component";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";

import "./dropdown-custom.css";

interface MenuProps {
  value: string | undefined;
  label: string;
  [key: string]: any;
}

interface DropDownCustomProp {
  defaultMenu: MenuProps[];
  value: string;
  onchange?: (props: any) => void;
  style?: React.CSSProperties;
  className?: string;
  placeHolder?: string;
  readOnly?: boolean;
}

const DropDownCustom: React.FC<DropDownCustomProp> = ({
  defaultMenu,
  value,
  className,
  onchange,
  placeHolder = "Trạng thái",
  style,
  readOnly = true,
}) => {
  const [menus, setMenus] = useState<MenuProps[]>(defaultMenu);

  const [valueInput, setValueInput] = useState(value);
  const [isFocus, setIsFocus] = useState(false);
  useEffect(() => {
    setMenus(defaultMenu);
  }, [defaultMenu]);

  useEffect(() => {
    setValueInput(value);
  }, [value]);

  const handleOnSelected = useCallback(
    (value: MenuProps) => {
      setValueInput(value.label);
      onchange?.(value);
    },
    [onchange],
  );

  const handleOnChangeInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setValueInput(value);
      let menuEle: MenuProps[] = [];
      const newMenus = [...defaultMenu];
      if (menus) {
        if (value?.length > 0) {
          menuEle = newMenus?.filter((menu) => menu?.label?.includes(value));
        } else {
          menuEle = defaultMenu;
        }
        setMenus(menuEle);
      }
    },
    [defaultMenu, menus],
  );

  return (
    <div className={`form-selected-custom ${className}`} style={style}>
      <input
        readOnly={readOnly}
        className="selected-custom"
        type="text"
        value={valueInput}
        placeholder={placeHolder}
        onFocus={() => {
          setTimeout(() => {
            setIsFocus(true);
          }, 120);
        }}
        onBlur={() => {
          setTimeout(() => {
            setIsFocus(false);
          }, 120);
        }}
        onChange={handleOnChangeInput}
      />
      <div
        className={`icon-arrow-selected-custom ${
          isFocus ? "is-toggle-icon" : ""
        }`}
      >
        <IconArrow color="#6b7280" />
      </div>

      {defaultMenu?.length > 0 && (
        <ul className={`value-list ${isFocus ? "toggle-list" : ""}`}>
          {menus?.map((itemMenu) => {
            return (
              <li
                key={`item-menu-${itemMenu?.label}`}
                className="value-list-item"
                onClick={(e) => {
                  e.stopPropagation();
                  handleOnSelected(itemMenu);
                }}
              >
                {itemMenu?.label}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default React.memo(DropDownCustom);
