export enum SupportContentType {
  CONTENT_1 = "CONTENT_1",
  CONTENT_2 = "CONTENT_2",
  CONTENT_3 = "CONTENT_3",
}

export interface SupportModel {
  id: string;
  username: string;
  phoneNo: string;
  email: string;
  content: SupportContentType;
  description: string;
  merchantId: string;
  createdBy: string;
  createdAt: string;
  modifiedBy: string;
  modifiedAt: string;
}
