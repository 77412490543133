import { VietQrProCustomerResponse } from "@/api/generated-sources/kienlong-pay";
import { VietQrProModel } from "@/models/VietQrProModel";

export function mapVietQrProModel(
  dto: VietQrProCustomerResponse | undefined,
): VietQrProModel {
  return {
    id: dto?.id ?? 0,
    name: dto?.name ?? "",
    active: dto?.active ?? false,
    accountNo: dto?.accountNo ?? "",
    accountName: dto?.accountName ?? "",
    bankName: dto?.bankName ?? "",
    createAt: dto?.createAt ?? "",
    revenue: dto?.revenue ?? 0,
  };
}
