import {
  mapPayoutReceiver,
  mapSinglePayout,
  mapSinglePayoutDetail,
} from "@/adapters/payout_adapter";
import { KienlongPayClient } from "@/api/kienlong_pay_http_client";
import { ErrorModel } from "@/models/ErrorModel";
import {
  PayoutReceiverGroupType,
  PayoutReceiverModel,
  PayoutStatusType,
  PayoutTransactionModel,
  SinglePayoutModel,
} from "@/models/PayoutModel";
import { SortDirectionType } from "@/models/Sort";
import { PayoutRepository } from "../PayoutRepository";

export class PayoutRepositoryImp implements PayoutRepository {
  private client: KienlongPayClient;
  constructor(kienlongPayClient: KienlongPayClient) {
    this.client = kienlongPayClient;
  }

  public async approveSinglePayOut({
    payoutId,
    bankAccountId,
  }: {
    payoutId: string;
    bankAccountId: string;
  }): Promise<{
    sessionId: string;
    time: string;
    timeout: number;
    phone: string;
  }> {
    try {
      let resp = await this.client.payoutController.approveSinglePayOut({
        approveSinglePayOutRequest: {
          bankAccountId: bankAccountId,
          payOutId: payoutId,
        },
      });
      if (resp.data.code === 0) {
        return {
          sessionId: resp.data.data?.sessionId ?? "",
          time: resp.data.data?.time ?? "",
          timeout: resp.data.data?.timeout ?? 0,
          phone: resp.data.data?.phone ?? "",
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async verifySinglePayOut({
    payoutId,
    otp,
    sessionId,
    failUrl,
    redirectAfter,
    refTransactionId,
    successUrl,
  }: {
    payoutId: string;
    otp: string;
    sessionId: string;
    failUrl?: string;
    redirectAfter?: number;
    refTransactionId?: string;
    successUrl?: string;
  }): Promise<boolean> {
    try {
      let resp = await this.client.payoutController.verifySinglePayOut({
        verifyOTPSinglePayOutRequest: {
          otp: otp,
          sessionId: sessionId,
          failUrl: failUrl,
          redirectAfter: redirectAfter,
          refTransactionId: refTransactionId,
          successUrl: successUrl,
          payOutId: payoutId,
        },
      });
      if (resp.data.code === 0) {
        return resp.data.data?.success ?? false;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async createSinglePayOut({
    saveReceiver,
    accountNo,
    amount,
    bin,
    content,
  }: {
    saveReceiver: boolean;
    accountNo: string;
    amount: number;
    bin: string;
    content: string;
  }): Promise<SinglePayoutModel> {
    try {
      let resp = await this.client.payoutController.createSinglePayOut({
        createSinglePayOutRequest: {
          saveReceiver: saveReceiver,
          accountNo: accountNo,
          amount: amount,
          bin: bin,
          content: content,
        },
      });
      if (resp.data.code === 0) {
        return mapSinglePayout(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async disapproveSinglePayOut({
    payoutId,
    denyContent,
  }: {
    payoutId: string;
    denyContent: string;
  }): Promise<boolean> {
    try {
      let resp = await this.client.payoutController.disapproveSinglePayOut({
        disapproveSinglePayOutRequest: {
          denyContent: denyContent,
          payOutId: payoutId,
        },
      });
      if (resp.data.code === 0) {
        return resp.data.data?.success ?? false;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getSinglePayoutDetail({
    payoutId,
  }: {
    payoutId: string;
  }): Promise<
    SinglePayoutModel & {
      transaction: PayoutTransactionModel;
    }
  > {
    try {
      let resp = await this.client.payoutController.getSinglePayoutDetail({
        payOutId: payoutId,
      });
      if (resp.data.code === 0) {
        return mapSinglePayoutDetail(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getAllSinglePayOuts({
    createdFrom,
    createdTo,
    page,
    pageSize,
    sortBy,
    sortDirection = SortDirectionType.DESC,
    status,
    fromAccount,
    toAccount,
    transactionId,
  }: {
    createdFrom?: string;
    createdTo?: string;
    page?: number;
    pageSize?: number;
    sortBy?: string;
    sortDirection?: SortDirectionType;
    status?: PayoutStatusType;
    fromAccount?: string;
    toAccount?: string;
    transactionId?: string;
  }): Promise<{
    pageNumber: number;
    pageSize: number;
    totalSize: number;
    totalPage: number;
    items: SinglePayoutModel[];
  }> {
    try {
      let resp = await this.client.payoutController.getAllSinglePayOuts({
        createdFrom: createdFrom,
        createdTo: createdTo,
        page: page,
        pageSize: pageSize,
        sortBy: sortBy,
        sortDirection: sortDirection,
        status: status,
        fromAccount: fromAccount,
        toAccount: toAccount,
        transactionId: transactionId,
      });
      if (resp.data.code === 0) {
        return {
          items:
            resp.data.data?.items?.map((d): SinglePayoutModel => {
              return mapSinglePayout(d);
            }) ?? [],
          pageNumber: resp.data.data?.pageNumber ?? 0,
          pageSize: resp.data.data?.pageSize ?? 0,
          totalPage: resp.data.data?.totalPage ?? 0,
          totalSize: resp.data.data?.totalSize ?? 0,
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async createReceiver({
    group,
    bankBin,
    accountNo,
  }: {
    group: PayoutReceiverGroupType;
    bankBin: string;
    accountNo: string;
  }): Promise<PayoutReceiverModel> {
    try {
      let resp = await this.client.payoutController.createReceiver({
        createReceiverRequest: {
          accountNo: accountNo,
          bankBin: bankBin,
          group: group,
        },
      });
      if (resp.data.code === 0) {
        return mapPayoutReceiver(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async updateReceiver({
    group,
    bankBin,
    accountNo,
    receiverId,
  }: {
    receiverId: number;
    group: PayoutReceiverGroupType;
    bankBin: string;
    accountNo: string;
  }): Promise<PayoutReceiverModel> {
    try {
      let resp = await this.client.payoutController.updateReceiver({
        receiverId: receiverId,
        updateReceiverRequest: {
          accountNo: accountNo,
          bankBin: bankBin,
          group: group,
        },
      });
      if (resp.data.code === 0) {
        return mapPayoutReceiver(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async deleteReceiver({
    receiverId,
  }: {
    receiverId: number;
  }): Promise<PayoutReceiverModel> {
    try {
      let resp = await this.client.payoutController.deleteReceiver({
        receiverId: receiverId,
        body: {},
      });
      if (resp.data.code === 0) {
        return mapPayoutReceiver(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getAllReceiver({
    group,
    page,
    pageSize,
    query,
    sortBy,
    sortDirection = SortDirectionType.DESC,
  }: {
    group?: PayoutReceiverGroupType;
    page?: number;
    pageSize?: number;
    query?: string;
    sortBy?: string;
    sortDirection: SortDirectionType;
  }): Promise<{
    pageNumber: number;
    pageSize: number;
    totalSize: number;
    totalPage: number;
    items: PayoutReceiverModel[];
  }> {
    try {
      let resp = await this.client.payoutController.getAllSavedReceivers({
        group: group,
        page: page,
        pageSize: pageSize,
        query: query,
        sortBy: sortBy,
        sortDirection: sortDirection,
      });
      if (resp.data.code === 0) {
        return {
          items:
            resp.data.data?.items?.map((d): PayoutReceiverModel => {
              return mapPayoutReceiver(d);
            }) ?? [],
          pageNumber: resp.data.data?.pageNumber ?? 0,
          pageSize: resp.data.data?.pageSize ?? 0,
          totalPage: resp.data.data?.totalPage ?? 0,
          totalSize: resp.data.data?.totalSize ?? 0,
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  //   public async uploadBatchFile({ file }: { file: File }): Promise<
  //     {
  //       id: number;
  //       name: string;
  //       bankBin: string;
  //       bankName: string;
  //       accountNo: string;
  //       accountName: string;
  //       group: PayoutReceiverGroupType;
  //       content: string;
  //       amount: number;
  //       valid: boolean;
  //     }[]
  //   > {
  //     try {
  //       let resp = await this.client.payoutController.uploadBatchFile({
  //         file: file,
  //       });
  //       if (resp.data.code === 0) {
  //         return (
  //           resp.data.data?.items?.map((d) => {
  //             return {
  //               accountName: d.accountName ?? "",
  //               accountNo: d.accountNo ?? "",
  //               amount: d.amount ?? 0,
  //               bankBin: d.bankBin ?? "",
  //               bankName: d.bankName ?? "",
  //               content: d.content ?? "",
  //               group:
  //                 PayoutReceiverGroupType[
  //                 (d.group ??
  //                   "CUSTOMER") as keyof typeof PayoutReceiverGroupType
  //                 ],
  //               id: d.id ?? 0,
  //               name: d.accountName ?? "",
  //               valid: d.valid ?? false,
  //             };
  //           }) ?? []
  //         );
  //       } else {
  //         throw new ErrorModel({
  //           code: resp.data.code,
  //           message: resp.data.message,
  //         });
  //       }
  //     } catch (error) {
  //       throw error;
  //     }
  //   }
}
