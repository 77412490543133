import {
  SalaryAdvanceDetailModel,
  SalaryAdvanceInfoModel,
  SalaryAdvancePaymentStatus,
  SalaryAdvanceStatus,
  SalaryAdvanceType,
  SalaryAdvanceTypeDetailModel,
  SalaryAdvanceTypeInfoModel,
  SalaryPeriodInfoModel,
} from "@/api/flexsalary-repositories/models/SalaryModel";
import {
  SalaryAdvanceDetail,
  SalaryAdvanceInfo,
  SalaryAdvanceTypeDetail,
  SalaryAdvanceTypeInfo,
  SalaryPeriodInfo,
} from "@/api/generated-sources/flexsalary";
import { mapAuditDataModel } from "./audit-data-adapter";
import { EnterpriseConfigCurrency } from "../models/EnterpriseConfigDetailModel";
import { mapPaymentHistoryDetailModel } from "./payment-adapter";

export function mapSalaryPeriodInfoModel(
  dto?: SalaryPeriodInfo,
): SalaryPeriodInfoModel {
  return {
    id: dto?.id ?? "",
    name: dto?.name ?? "",
    endDate: dto?.endDate ?? "",
    startDate: dto?.startDate ?? "",
    workingDays: dto?.workingDays ?? -1,
  };
}

export function mapSalaryAdvanceTypeInfoModel(
  dto?: SalaryAdvanceTypeInfo,
): SalaryAdvanceTypeInfoModel {
  return {
    advanceForm:
      SalaryAdvanceType[dto?.advanceForm as keyof typeof SalaryAdvanceType],
    advancePercent: dto?.advancePercent ?? 0,
    frequencyLimit: dto?.frequencyLimit ?? 0,
    id: dto?.id ?? "",
    limitFrequency: dto?.limitFrequency ?? false,
    name: dto?.name ?? "",
  };
}

export function mapSalaryAdvanceTypeDetailModel(
  dto?: SalaryAdvanceTypeDetail,
): SalaryAdvanceTypeDetailModel {
  return {
    advanceForm:
      SalaryAdvanceType[dto?.advanceForm as keyof typeof SalaryAdvanceType],
    advancePercent: dto?.advancePercent ?? 0,
    frequencyLimit: dto?.frequencyLimit ?? 0,
    id: dto?.id ?? "",
    limitFrequency: dto?.limitFrequency ?? false,
    name: dto?.name ?? "",
    audit: dto?.audit?.map((d) => mapAuditDataModel(d)) ?? [],
  };
}

export function mapSalaryAdvanceDetail(
  dto?: SalaryAdvanceDetail,
): SalaryAdvanceDetailModel {
  return {
    id: dto?.id ?? "",
    advancedAmount: dto?.advancedAmount ?? 0,
    amount: dto?.amount ?? 0,
    audit: dto?.audit?.map((d) => mapAuditDataModel(d)) ?? [],
    code: dto?.code ?? "",
    createdDate: dto?.createdDate ?? 0,
    currency:
      EnterpriseConfigCurrency[
        dto?.currency as keyof typeof EnterpriseConfigCurrency
      ],
    employeeCode: dto?.employeeCode ?? "",
    employeeName: dto?.employeeName ?? "",
    paymentData:
      dto?.paymentData?.map((d) => mapPaymentHistoryDetailModel(d)) ?? [],
    paymentStatus:
      SalaryAdvancePaymentStatus[
        dto?.paymentStatus as keyof typeof SalaryAdvancePaymentStatus
      ],
    pendingAmount: dto?.pendingAmount ?? 0,
    periodName: dto?.periodName ?? "",
    rejectReason: dto?.rejectReason ?? "",
    salaryAdvanceMaxAmount: dto?.salaryAdvanceMaxAmount ?? 0,
    status:
      SalaryAdvanceStatus[dto?.status as keyof typeof SalaryAdvanceStatus],
    beneficiaryAccount: dto?.beneficiaryAccount,
    periodEndDate: dto?.periodEndDate,
    periodStartDate: dto?.periodStartDate,
  };
}

export function mapSalaryAdvanceInfoModel(
  dto?: SalaryAdvanceInfo,
): SalaryAdvanceInfoModel {
  return {
    id: dto?.id ?? "",
    amount: dto?.amount ?? 0,
    code: dto?.code ?? "",
    createdDate: dto?.createdDate ?? 0,
    currency:
      EnterpriseConfigCurrency[
        dto?.currency as keyof typeof EnterpriseConfigCurrency
      ],
    employeeCode: dto?.employeeCode ?? "",
    employeeName: dto?.employeeName ?? "",
    paymentStatus:
      SalaryAdvancePaymentStatus[
        dto?.paymentStatus as keyof typeof SalaryAdvancePaymentStatus
      ],
    status:
      SalaryAdvanceStatus[dto?.status as keyof typeof SalaryAdvanceStatus],
    periodName: dto?.periodName,
  };
}
