import { FlexSalaryClient } from "@/api/flexsalary_http_client";
import { SalaryAdvanceRequestRepository } from "../SalaryAdvanceRequestReposiroty";
import { ErrorModel } from "@/models/ErrorModel";
import {
  mapSalaryAdvanceDetail,
  mapSalaryAdvanceInfoModel,
} from "../adapters/salary-adapter";
import {
  SalaryAdvanceDetailModel,
  SalaryAdvanceInfoModel,
  SalaryAdvancePaymentStatus,
  SalaryAdvanceStatus,
} from "../models/SalaryModel";

export class SalaryAdvanceRequestReposirotyImp
  implements SalaryAdvanceRequestRepository
{
  private client: FlexSalaryClient;
  constructor(flexsalaryClient: FlexSalaryClient) {
    this.client = flexsalaryClient;
  }

  public async approveSalaryAdvance({ id }: { id: string }): Promise<void> {
    try {
      let resp = await this.client.salaryAdvanceRequest.approveSalaryAdvance({
        id: id,
      });
      if (resp.data.code === 20000) {
        return;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async rejectSalaryAdvance({
    id,
    description,
  }: {
    id: string;
    description: string;
  }): Promise<void> {
    try {
      let resp = await this.client.salaryAdvanceRequest.rejectSalaryAdvance({
        id: id,
        rejectSalaryAdvanceRequest: {
          description: description,
        },
      });
      if (resp.data.code === 20000) {
        return;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async detailSalaryAdvance({
    id,
  }: {
    id: string;
  }): Promise<SalaryAdvanceDetailModel> {
    try {
      let resp = await this.client.salaryAdvanceRequest.getSalaryAdvanceDetail({
        id: id,
      });
      if (resp.data.code === 20000) {
        return mapSalaryAdvanceDetail(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async listSalaryAdvance({
    page = 0,
    size = 10,
    keyword,
    status,
    fromDate,
    toDate,
  }: {
    keyword?: string;
    fromDate?: number;
    toDate?: number;
    page?: number;
    size?: number;
    status?: SalaryAdvanceStatus;
  }): Promise<{
    page: number;
    size: number;
    totalElements: number;
    totalPages: number;
    data: Array<SalaryAdvanceInfoModel>;
  }> {
    try {
      let resp = await this.client.salaryAdvanceRequest.getSalaryAdvanceList({
        page: page,
        size: size,
        fromDate: fromDate,
        toDate: toDate,
        keyword: keyword,
        status: status,
      });
      if (resp.data.code === 20000) {
        return {
          page: resp.data.data?.page ?? 0,
          size: resp.data.data?.size ?? 0,
          totalElements: resp.data.data?.totalElements ?? 0,
          totalPages: resp.data.data?.totalPages ?? 0,
          data:
            resp.data.data?.data?.map((d) => mapSalaryAdvanceInfoModel(d)) ??
            [],
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
