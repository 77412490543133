import { DeviceResponse } from "@/api/generated-sources/paybox";
import {
  PayBoxDeviceModel,
  PayBoxLinkMerchantModel,
} from "@/models/PayBoxDeviceModel";
import { LinkWithUserResponse } from "./../api/generated-sources/paybox/api";

export function mapPayBoxModel(
  dto: DeviceResponse | undefined,
): PayBoxDeviceModel {
  return {
    id: dto?.id ?? "",
    hardwareId: dto?.hardwareId ?? "",
    displayName: dto?.displayName ?? "",
    model: dto?.model ?? "",
    version: dto?.version ?? "",
    online: dto?.online ?? false,
    bankAccountId: dto?.bankAccountId ?? "",
    hwVersion: dto?.hwVersion ?? "",
  };
}

export function mapLinkPayboxDeviceResponseModel(
  dto: LinkWithUserResponse | undefined,
): PayBoxLinkMerchantModel {
  return {
    displayName: dto?.displayName ?? "",
    success: dto?.success ?? false,
    deviceId: dto?.deviceId ?? "",
  };
}
