import axios from "axios";
import { KienlongPayHttpClient } from "@/api/kienlong_pay_http_client";
import { StorageHttpClient } from "@/api/storage_http_client";
import { onError, onRequest, onResponse } from "./intercepter";
import { PayboxHttpClient } from "@/api/paybox_http_client";
import { Repository } from "@/domains/repository";
import { FlexSalaryHttpClient } from "@/api/flexsalary_http_client.ts";

export class AppRepository extends Repository {
  kienlongPayHttpClient: KienlongPayHttpClient;
  storageHttpClient: StorageHttpClient;
  payboxHttpClient: PayboxHttpClient;
  flexSalaryClient: FlexSalaryHttpClient;
  constructor(
    basePathKienlongbankPay?: string,
    basePathPaybox?: string,
    basePathStorage?: string,
    basePathFlexSalary?: string,
  ) {
    super();
    axios.interceptors.request.clear();
    axios.interceptors.response.clear();
    axios.defaults.timeout = 60 * 1000;
    axios.interceptors.request.use(onRequest, onError, { synchronous: false });
    axios.interceptors.response.use(onResponse, onError, {
      synchronous: false,
    });
    this.kienlongPayHttpClient = new KienlongPayHttpClient(
      basePathKienlongbankPay,
    );
    this.storageHttpClient = new StorageHttpClient(basePathStorage);
    this.payboxHttpClient = new PayboxHttpClient(basePathPaybox);
    this.flexSalaryClient = new FlexSalaryHttpClient(basePathFlexSalary);
  }
}
