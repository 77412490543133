import { PayBoxTransactionModel } from "@/models/PayBoxTransactionModel";
import { TransactionStatusType } from "@/models/TransactionModel";

export const dataTablePayBoxTransactionHistory = (() => {
  const arrPayboxTransaction: PayBoxTransactionModel[] = [];
  for (let index = 0; index <= 10; index++) {
    arrPayboxTransaction.push({
      transactionId: `ADMIN-DEV${Math.floor(Math.random() * 1234)}`,
      createDateTime: "11",
      amount: 1000000000,
      virtualAccount: "83082002",
      status: Object.values(TransactionStatusType)[
        Math.floor(Math.random() * Object.values(TransactionStatusType).length)
      ],
    });
  }
  return arrPayboxTransaction;
})();
