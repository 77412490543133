import { BusinessOwner } from "@/api/generated-sources/kienlong-pay";
import { BusinessOwnerModel } from "@/models/BusinessOwnerModel";

export function mapBusinessOwnerModal(
  dto: BusinessOwner | undefined,
): BusinessOwnerModel {
  return {
    funPercent: dto?.funPercent ?? 0,
    id: dto?.id ?? 0,
    identifyNumber: dto?.identifyNumber ?? "",
    name: dto?.name ?? "",
    position: dto?.position ?? "",
  };
}
