import { ErrorModel } from "@/models/ErrorModel";
import { KienlongPayClient } from "@/api/kienlong_pay_http_client";
import { PayButtonRepository } from "../PayButtonRepository";
import {
  ExternalButton,
  payButtonMapping,
  PayButtonModel,
  PayButtonThemeType,
} from "@/models/PayButtonModel";

export class PayButtonRepositoryImp implements PayButtonRepository {
  private client: KienlongPayClient;
  constructor(kienlongPayClient: KienlongPayClient) {
    this.client = kienlongPayClient;
  }

  public async getList({
    keyword,
    page,
    pageSize = 20,
    sortDirection = "ASC",
    sortBy = "id",
  }: {
    keyword?: string;
    page?: number;
    pageSize?: number;
    sortDirection?: "ASC" | "DESC";
    sortBy?: string;
  }): Promise<{
    pageNumber: number;
    pageSize: number;
    totalSize: number;
    totalPage: number;
    items: Array<PayButtonModel>;
  }> {
    try {
      let resp = await this.client.payButtonController.getList1({
        page: page,
        pageSize: pageSize,
        sortBy: sortBy,
        sortDirection: sortDirection,
        keyword: keyword,
      });
      if (resp.data.code === 0) {
        return {
          items:
            resp.data.data?.items?.map((d) => {
              return payButtonMapping(d);
            }) ?? [],
          pageNumber: resp.data.data?.pageNumber ?? 0,
          pageSize: resp.data.data?.pageSize ?? 0,
          totalPage: resp.data.data?.totalPage ?? 0,
          totalSize: resp.data.data?.totalSize ?? 0,
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async createPayButton({
    payPageId,
    title,
    label,
    theme,
  }: {
    payPageId: number;
    title: string;
    label: string;
    theme: (typeof PayButtonThemeType)[keyof typeof PayButtonThemeType];
  }): Promise<any> {
    try {
      let resp = await this.client.payButtonController.createPayButton({
        createPayButtonRequest: {
          payPageId,
          title,
          label,
          theme,
        },
      });
      if (resp.data.code === 0) {
        return payButtonMapping(resp.data.data ?? {});
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async updatePayButton({
    payPageId,
    payButtonId,
    title,
    label,
    theme,
  }: {
    payPageId: number;
    payButtonId: string;
    title: string;
    label: string;
    theme: (typeof PayButtonThemeType)[keyof typeof PayButtonThemeType];
  }): Promise<PayButtonModel> {
    try {
      let resp = await this.client.payButtonController.updatePayButton({
        updatePayButtonRequest: {
          payPageId,
          payButtonId,
          title,
          label,
          theme,
        },
      });
      if (resp.data.code === 0) {
        return payButtonMapping(resp.data.data ?? {});
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async deletePayButton({
    id,
  }: {
    id: string;
  }): Promise<{ success: boolean }> {
    try {
      let resp = await this.client.payButtonController.deletePayButton({
        deletePayButtonRequest: {
          id,
        },
      });
      if (resp.data.code === 0 && resp.data.data) {
        return {
          success: resp?.data.data.success ?? false,
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getPayButton({ id }: { id: string }): Promise<PayButtonModel> {
    try {
      let resp = await this.client.payButtonController.getPayButton({
        id,
      });
      if (resp.data.code === 0) {
        return payButtonMapping(resp.data.data ?? {});
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
  public async getPayButtonByCode({
    code,
  }: {
    code: string;
  }): Promise<PayButtonModel> {
    try {
      let resp = await this.client.payButtonController.getPayButtonByCode({
        code,
      });
      if (resp.data.code === 0) {
        return payButtonMapping(resp.data.data ?? {});
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
  public async getExternalButton({
    code,
  }: {
    code: string;
  }): Promise<ExternalButton> {
    try {
      let resp = await this.client.payButtonController.getExternalButton({
        code,
      });
      if (resp.data.code === 0) {
        return {
          label: resp.data.data?.label ?? "",
          referencePageUrl: resp.data.data?.referencePageUrl ?? "",
          theme: resp.data.data?.theme ?? "LIGHT",
          title: resp.data.data?.title ?? "",
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
