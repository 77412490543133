import { mapBankAccountModal } from "@/adapters/bank_account_adapter";
import { mapMerchantCredentialModal } from "@/adapters/credential_adapter";
import { mapProfileModal } from "@/adapters/profile_adapter";
import { mapUserModel } from "@/adapters/user_adapter";
import {
  BankAccountDto,
  Configuration,
  MerchantControllerApi,
  MerchantDto,
} from "@/api/generated-sources/kienlong-pay";
import { KienlongPayClient } from "@/api/kienlong_pay_http_client";
import {
  BusinessProductsType,
  BusinessType,
  FeeType,
  RevenueType,
} from "@/models/BusinessOwnerModel";
import { MerchantCredentialModel } from "@/models/CredentialModel";
import { ErrorModel } from "@/models/ErrorModel";
import {
  MerchantModel,
  MerchantStatusType,
  MerchantSubmitInfoModel,
} from "@/models/MerchantModel";
import { UserModel } from "@/models/UserModel";
import { MerchantReposiroty } from "../MerchantRepository";
import { EnterprizePosition } from "@/models/EnterprizePositionModel";
import { Environment } from "@/services/environment";
import { VietQrProStatusType } from "@/models/VietQrProModel";

export class MerchantReposirotyImp implements MerchantReposiroty {
  private client: KienlongPayClient;
  constructor(kienlongPayClient: KienlongPayClient) {
    this.client = kienlongPayClient;
  }

  public async getMerchant(): Promise<MerchantModel> {
    let _client = new MerchantControllerApi(
      new Configuration({
        basePath: Environment.hostApiKlbPay,
      }),
    );
    try {
      let resp = await _client.getMerchant1();
      if (resp.data.code === 0 && resp.data.data?.merchant !== undefined) {
        let merchant: MerchantDto = resp.data.data?.merchant;
        return {
          id: merchant.id,
          accountNo: merchant.accountNo ?? "",
          bankAccounts:
            merchant.bankAccounts?.map((d, i) => {
              return mapBankAccountModal(d satisfies BankAccountDto);
            }) ?? [],
          createDateTime: merchant.createDateTime ?? "",
          credential: mapMerchantCredentialModal(merchant.credential),
          merchantCode: merchant.merchantCode ?? "",
          name: merchant.name ?? "",
          profile: mapProfileModal(merchant.profile),
          status:
            MerchantStatusType[
              (merchant.status ?? "In") as keyof typeof MerchantStatusType
            ],
          allowEmail: merchant.allowEmail ?? false,
          webhookUrl: merchant.webhookUrl ?? "",
          vietQrProStatus:
            VietQrProStatusType[
              (merchant.vietQrProStatus ??
                "INACTIVE") as keyof typeof VietQrProStatusType
            ],
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async submitMerchantInfo({
    name,
    businessType,
    businessProducts,
    product,
    maxPrice,
    revenueType,
    website,
    address,
    email,
    personal,
    enterprise,
  }: {
    name: string;
    businessType: BusinessType;
    businessProducts?: BusinessProductsType[];
    product: string;
    maxPrice: number;
    revenueType: RevenueType;
    website?: string;
    address?: string;
    email: string;
    personal?: {
      companyName: string;
      address: string;
      phone: string;
      frontUrl: string;
      backUrl: string;
    };
    enterprise?: {
      fullName: string;
      idNo: string;
      position: EnterprizePosition;
      fundPercent: number;
      representFrontUrl: string;
      representBackUrl: string;
      ownerFrontUrl: string;
      ownerbackUrl: string;
      documentUrls?: Array<string>;
    };
  }): Promise<MerchantModel> {
    try {
      let _client = new MerchantControllerApi(
        new Configuration({
          basePath: Environment.hostApiKlbPay,
        }),
      );
      let resp = await _client.submitInfo({
        submitMerchantInfoRequest: {
          address: address,
          businessType: businessType,
          email: email,
          maxPrice: maxPrice,
          name: name,
          product: product,
          revenueType: revenueType,
          businessProducts: businessProducts,
          enterpriseMerchant: enterprise,
          personalMerchant: personal,
          website: website,
        },
      });
      if (resp.data.code === 0 && resp.data.data?.merchant !== undefined) {
        let merchant: MerchantDto = resp.data.data?.merchant;
        return {
          id: merchant.id,
          accountNo: merchant.accountNo ?? "",
          bankAccounts:
            merchant.bankAccounts?.map((d, i) => {
              return mapBankAccountModal(d satisfies BankAccountDto);
            }) ?? [],
          createDateTime: merchant.createDateTime ?? "",
          credential: mapMerchantCredentialModal(merchant.credential),
          merchantCode: merchant.merchantCode ?? "",
          name: merchant.name ?? "",
          profile: mapProfileModal(merchant.profile),
          status:
            MerchantStatusType[
              (merchant.status ?? "CREATED") as keyof typeof MerchantStatusType
            ],
          allowEmail: merchant.allowEmail ?? false,
          webhookUrl: merchant.webhookUrl ?? "",
          vietQrProStatus:
            VietQrProStatusType[
              (merchant.vietQrProStatus ??
                "INACTIVE") as keyof typeof VietQrProStatusType
            ],
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
  public async submitMerchantInfoV2({
    name,
    businessType,
    businessProducts,
    product,
    maxPrice,
    revenueType,
    website,
    address,
    email,
    personal,
    enterprise,
    taxIdNo,
    feeType,
  }: {
    name: string;
    businessType: BusinessType;
    businessProducts: BusinessProductsType[];
    product: string;
    maxPrice: number;
    revenueType: RevenueType;
    website?: string;
    address?: string;
    email: string;
    personal?: {
      companyName: string;
      address: string;
      phone: string;
      frontUrl: string;
      backUrl: string;
    };
    enterprise?: {
      fullName: string;
      idNo: string;
      position: EnterprizePosition;
      fundPercent: number;
      representFrontUrl: string;
      representBackUrl: string;
      ownerFrontUrl: string;
      ownerbackUrl: string;
      documentUrls?: Array<string>;
    };
    taxIdNo: string;
    feeType: FeeType;
  }): Promise<MerchantModel> {
    try {
      let _client = new MerchantControllerApi(
        new Configuration({
          basePath: Environment.hostApiKlbPay,
        }),
      );
      let resp = await _client.submitInfoV2({
        portalSubmitInfoV2Request: {
          address: address,
          businessType: businessType,
          email: email,
          maxPrice: maxPrice,
          name: name,
          product: product,
          revenueType: revenueType,
          businessProducts: businessProducts,
          enterpriseMerchant: enterprise,
          personalMerchant: personal,
          website: website,
          taxIdNo: taxIdNo,
          chargeType: feeType,
        },
      });
      if (resp.data.code === 0 && resp.data.data?.merchant !== undefined) {
        let merchant: MerchantDto = resp.data.data?.merchant;
        return {
          id: merchant.id,
          accountNo: merchant.accountNo ?? "",
          bankAccounts:
            merchant.bankAccounts?.map((d, i) => {
              return mapBankAccountModal(d satisfies BankAccountDto);
            }) ?? [],
          createDateTime: merchant.createDateTime ?? "",
          credential: mapMerchantCredentialModal(merchant.credential),
          merchantCode: merchant.merchantCode ?? "",
          name: merchant.name ?? "",
          profile: mapProfileModal(merchant.profile),
          status:
            MerchantStatusType[
              (merchant.status ?? "CREATED") as keyof typeof MerchantStatusType
            ],
          allowEmail: merchant.allowEmail ?? false,
          webhookUrl: merchant.webhookUrl ?? "",
          vietQrProStatus:
            VietQrProStatusType[
              (merchant.vietQrProStatus ??
                "INACTIVE") as keyof typeof VietQrProStatusType
            ],
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
  public async getCredential(): Promise<MerchantCredentialModel> {
    try {
      let resp = await this.client.merchantController.getCredential();
      if (resp.data.code === 0) {
        return mapMerchantCredentialModal(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async refreshCredential(): Promise<MerchantCredentialModel> {
    try {
      let resp = await this.client.merchantController.refreshCredential();
      if (resp.data.code === 0) {
        return mapMerchantCredentialModal(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getAllMembers(): Promise<UserModel[]> {
    try {
      let resp = await this.client.merchantController.getAllMerchantMembers();
      if (resp.data.code === 0) {
        return resp.data?.data?.items?.map((item) => mapUserModel(item)) ?? [];
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getMember({
    memberId,
  }: {
    memberId: string;
  }): Promise<UserModel> {
    try {
      let resp = await this.client.merchantController.getMember({
        memberId: memberId,
      });
      if (resp.data.code === 0) {
        return mapUserModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async updateWebhook({
    webhookUrl,
    webhookKey,
  }: {
    webhookUrl: string;
    webhookKey: string;
  }): Promise<boolean> {
    try {
      let resp = await this.client.merchantController.updateWebhook({
        updateWebhookRequest: {
          apiKey: webhookKey,
          url: webhookUrl,
        },
      });
      if (resp.data.code === 0) {
        return resp.data.data?.success ?? false;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getWebhookInfo(): Promise<{ url: string; key: string }> {
    try {
      let resp = await this.client.merchantController.getWebhookInfo();
      if (resp.data.code === 0) {
        return {
          key: resp.data.data?.webhookKey ?? "",
          url: resp.data.data?.webhookUrl ?? "",
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getSubmitInfo(): Promise<MerchantSubmitInfoModel> {
    try {
      let _client = new MerchantControllerApi(
        new Configuration({
          basePath: Environment.hostApiKlbPay,
        }),
      );
      let resp = await _client.getSubmitInfo();
      if (resp.data.code === 0) {
        return {
          businessType:
            BusinessType[
              (resp.data.data?.businessType ??
                "PERSONAL") as keyof typeof BusinessType
            ],
          maxPrice: resp.data.data?.maxPrice ?? 0,
          name: resp.data.data?.name ?? "",
          product: resp.data.data?.product ?? "",
          revenueType:
            RevenueType[
              (resp.data.data?.revenueType ??
                "REVENUE_TYPE_1") as keyof typeof RevenueType
            ],
          email: resp.data.data?.email ?? "",
          personalMerchant: {
            frontUrl: resp.data.data?.personalMerchant?.frontUrl ?? "",
            backUrl: resp.data.data?.personalMerchant?.backUrl ?? "",
          },
          address: resp.data.data?.address ?? "",
          businessProducts:
            resp.data.data?.businessProducts?.map((d, i) => {
              return BusinessProductsType[
                (d ?? "OTHER") as keyof typeof BusinessProductsType
              ];
            }) ?? [],
          enterpriseMerchant: {
            position:
              EnterprizePosition[
                resp.data.data?.enterpriseMerchant
                  ?.position as keyof typeof EnterprizePosition
              ],
            fundPercent: resp.data.data?.enterpriseMerchant?.fundPercent ?? 0,
            representFrontUrl:
              resp.data.data?.enterpriseMerchant?.representFrontUrl ?? "",
            representBackUrl:
              resp.data.data?.enterpriseMerchant?.representBackUrl ?? "",
            documentUrls:
              resp.data.data?.enterpriseMerchant?.documentUrls ?? [],
          },
          taxIdNo: resp.data.data?.taxIdNo ?? "",
          website: resp.data.data?.website ?? "",
          feeType:
            FeeType[
              (resp.data.data?.chargeType ??
                "IMMEDIATELY") as keyof typeof FeeType
            ],
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
