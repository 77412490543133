import { BusinessType, FeeType } from "@/models/BusinessOwnerModel";
import { RevenueType } from "@/models/BusinessOwnerModel";
import { BusinessProductsType } from "@/models/BusinessOwnerModel";
import { BankAccountModel } from "./BankAccountModel";
import { MerchantCredentialModel } from "./CredentialModel";
import { ProfileModel } from "./ProfileModel";
import { EnterprizePosition } from "./EnterprizePositionModel";
import { VietQrProStatusType } from "./VietQrProModel";

export interface MerchantModel {
  id?: string;
  status: MerchantStatusType;
  credential: MerchantCredentialModel;
  merchantCode: string;
  createDateTime: string;
  name: string;
  accountNo: string;
  profile: ProfileModel;
  bankAccounts: Array<BankAccountModel>;
  allowEmail: boolean;
  webhookUrl: string;
  vietQrProStatus: VietQrProStatusType;
}

export enum MerchantStatusType {
  CREATED = "CREATED",
  SUBMIT_INFO = "SUBMIT_INFO",
  LINKED_ACCOUNT = "LINKED_ACCOUNT",
  INACTIVE = "INACTIVE",
  ACTIVE = "ACTIVE",
  DENIED = "DENIED",
  OPEN_ACCOUNT = "OPEN_ACCOUNT",
}

export interface MerchantSubmitInfoModel {
  businessType: BusinessType;
  name: string;
  businessProducts: BusinessProductsType[];
  product: string;
  maxPrice: number;
  revenueType: RevenueType;
  email: string;
  website?: string;
  address?: string;
  personalMerchant: {
    frontUrl: string;
    backUrl: string;
  };
  taxIdNo?: string;
  feeType?: FeeType;
  enterpriseMerchant: {
    position: EnterprizePosition;
    fundPercent: number;
    representFrontUrl: string;
    representBackUrl: string;
    documentUrls: Array<string>;
  };
}
