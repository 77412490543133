import {
  PermissionGroup,
  PermissionList,
} from "@/api/generated-sources/kienlong-pay/api";
import { PermissionResponse } from "@/api/generated-sources/kienlong-pay";
import {
  PermissionGroupModel,
  PermissionListModel,
  PermissionModel,
  PermissionScopeType,
} from "@/models/PermissionModel";

export function mapPermissionModel(dto: PermissionResponse): PermissionModel {
  return {
    id: dto?.id || 0,
    name: dto?.name || "",
    description: dto?.description || "",
    scope:
      PermissionScopeType[
        dto.scope ?? ("MERCHANT" as keyof typeof PermissionScopeType)
      ],
  };
}

export function mapPermissionGroupModel(
  dto: PermissionGroup | undefined,
): PermissionGroupModel {
  return {
    id: dto?.id || 0,
    name: dto?.name || "",
    description: dto?.description || "",
    permissions:
      dto?.permissions?.map((permission) =>
        mapPermissionListModel(permission),
      ) || [],
  };
}

export function mapPermissionListModel(
  dto: PermissionList,
): PermissionListModel {
  return {
    id: dto?.id || 0,
    name: dto?.name || "",
    description: dto?.description || "",
    actions: dto?.actions?.map((action) => mapPermissionModel(action)) || [],
  };
}
