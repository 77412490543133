import { CustomerModel } from "./CustomersModel";
import { PaySubscriptionPlanModel } from "./PaySubscriptionPlanModel ";
import { TimeType } from "./TimeModel";

export interface PaySubscriptionModel {
  id: string;
  status: PaySubscriptionStatus;
  code: string;
  plan: PaySubscriptionPlanModel;
  customer: CustomerModel;
  createdAt: string;
  numOfCycles: number;
  numOfPayments: number;
  prePaymentDate: string;
  nextPaymentDate: string;
  trialTimeType: TimeType;
  trialTimeValue: number;
  allowTrial: boolean;
}

export enum PaySubscriptionStatus {
  TRIAL = "TRIAL",
  ACTIVE = "ACTIVE",
  PAUSED = "PAUSED",
  PENDING_CANCELLATION = "PENDING_CANCELLATION",
  CANCELLED = "CANCELLED",
  EXPIRED = "EXPIRED",
  PAYMENT_EXPIRED = "PAYMENT_EXPIRED",
  CREATE_INVOICE_FAILED = "CREATE_INVOICE_FAILED",
}

export enum PaySubscriptionCancelType {
  EXPIRATION_CANCEL = "EXPIRATION_CANCEL",
  IMMEDIATE_CANCEL = "IMMEDIATE_CANCEL",
}
