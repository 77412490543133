import { KienlongPayClient } from "@/api/kienlong_pay_http_client";
import {
  mapPayLinkBankTransferModal,
  mapPaylinkDetailModal,
  mapPayLinkNapasModal,
  mapPayLinkStatusModel,
  mapPayLinkVietQRModal,
  mapPagePayLinkModel,
} from "@/adapters/paylink_adapter";

import { PaylinkRepository } from "../PaylinkRepository";
import {
  PayLinkBankTransferModel,
  PaylinkDetailModel,
  PaylinkExpireType,
  PaylinkNapasInfoModel,
  PaylinkStatusModel,
  PaylinkStatusType,
  PayLinkVietQRModel,
} from "@/models/PaylinkModel";
import { ErrorModel } from "@/models/ErrorModel";
import {
  CustomerInfoFormModel,
  CustomerInfoType,
} from "@/models/CustomersModel";
import { PagePayLinkModel } from "@/models/PagePaylinkModel";

export class PaylinkRepositoryImp implements PaylinkRepository {
  private client: KienlongPayClient;
  constructor(kienlongPayClient: KienlongPayClient) {
    this.client = kienlongPayClient;
  }

  public async getPaylinkDetail({
    paylinkCode,
  }: {
    paylinkCode: string;
  }): Promise<PaylinkDetailModel> {
    try {
      let resp = await this.client.payLinkController.getPayLinkDetail({
        payLinkCode: paylinkCode,
      });
      if (resp.data.code === 0) {
        return mapPaylinkDetailModal(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getBankTransferInfo({
    paylinkCode,
  }: {
    paylinkCode: string;
  }): Promise<PayLinkBankTransferModel> {
    try {
      let resp = await this.client.payLinkController.payBankTransfer({
        payLinkCode: paylinkCode,
      });
      if (resp.data.code === 0) {
        return mapPayLinkBankTransferModal(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getPaylinkStatus({
    paylinkCode,
  }: {
    paylinkCode: string;
  }): Promise<PaylinkStatusModel> {
    try {
      let resp = await this.client.payLinkController.queryPayLink({
        payLinkCode: paylinkCode,
      });
      if (resp.data.code === 0) {
        return mapPayLinkStatusModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getVietQrTransferInfo({
    paylinkCode,
  }: {
    paylinkCode: string;
  }): Promise<PayLinkVietQRModel> {
    try {
      let resp = await this.client.payLinkController.payVietQR({
        payLinkCode: paylinkCode,
      });
      if (resp.data.code === 0) {
        return mapPayLinkVietQRModal(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async createPaylink({
    amount,
    refTransactionId,
    bankAccountId,
    expireType,
    description,
    fileUrl,
    customerInfoType,
    customerInfoForm,
    successUrl,
    failUrl,
    redirectAfter,
    contactEmail,
    contactPhone,
  }: {
    amount: number;
    refTransactionId?: string;
    bankAccountId?: string;
    expireType?: PaylinkExpireType;
    description: string;
    fileUrl?: string;
    customerInfoType: CustomerInfoType;
    customerInfoForm?: CustomerInfoFormModel;
    successUrl?: string;
    failUrl?: string;
    redirectAfter?: number;
    contactEmail?: string;
    contactPhone?: string;
  }): Promise<PaylinkDetailModel> {
    try {
      let resp = await this.client.payLinkController.createPayLink({
        createPayLinkRequest: {
          amount: amount,
          customerInfoType: customerInfoType,
          description: description,
          bankAccountId: bankAccountId,
          customerInfoForm: customerInfoForm,
          expireType: expireType,
          failUrl: failUrl,
          fileUrl: fileUrl,
          redirectAfter: redirectAfter,
          refTransactionId: refTransactionId,
          successUrl: successUrl,
          contactEmail: contactEmail,
          contactPhone: contactPhone,
        },
      });
      if (resp.data.code === 0) {
        return mapPaylinkDetailModal(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getListPaylink({
    payLinkCode,
    refTransactionId,
    phone,
    requestDateFrom,
    requestDateTo,
    status,
    page,
    pageSize = 20,
    sortDirection = "DESC",
    sortBy = "createdAt",
  }: {
    payLinkCode?: string;
    refTransactionId?: string;
    phone?: string;
    requestDateFrom?: string;
    requestDateTo?: string;
    status?: PaylinkStatusType;
    page?: number;
    pageSize?: number;
    sortDirection?: "ASC" | "DESC";
    sortBy?: string;
  }): Promise<PagePayLinkModel> {
    try {
      let resp = await this.client.payLinkController.getListPayLink({
        keyword: payLinkCode,
        page: page,
        pageSize: pageSize,
        phone: phone,
        requestDateFrom: requestDateFrom,
        requestDateTo: requestDateTo,
        sortBy: sortBy,
        sortDirection: sortDirection,
        status: status,
      });
      if (resp.data.code === 0) {
        return {
          pageNumber: resp.data?.data?.pageNumber,
          pageSize: resp.data?.data?.pageSize,
          totalPage: resp.data?.data?.totalPage,
          totalSize: resp.data?.data?.totalSize,
          items:
            resp.data?.data?.items?.map((d) => {
              return mapPagePayLinkModel(d);
            }) ?? [],
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async updateCustomerInfo({
    paylinkCode,
    fullName,
    phone,
    email,
    address,
  }: {
    paylinkCode: string;
    fullName?: string;
    phone?: string;
    email?: string;
    address?: string;
  }): Promise<boolean> {
    try {
      let resp = await this.client.payLinkController.updateCustomerInfo({
        updateCustomerInfoRequest: {
          payLinkCode: paylinkCode,
          customer: {
            address: address,
            email: email,
            fullName: fullName,
            phone: phone,
          },
        },
      });
      if (resp.data.code === 0) {
        return resp.data.data?.success ?? false;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async sendEmailSms({
    payLinkCode,
    receiver,
    receiverIds,
    sendEmail,
    sendSms,
  }: {
    payLinkCode: string;
    receiver: "CUSTOMER" | "CUSTOMER_GROUP";
    receiverIds: string[];
    sendEmail: boolean;
    sendSms: boolean;
  }): Promise<boolean> {
    try {
      let resp = await this.client.payLinkController.sendEmailSms1({
        sendEmailSmsPayLinkRequest: {
          payLinkCode: payLinkCode,
          receiver: receiver,
          receiverIds: receiverIds,
          sendEmail: sendEmail,
          sendSms: sendSms,
        },
      });
      if (resp.data.code === 0) {
        return resp.data.data?.success ?? false;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async payNapas({
    payLinkCode,
  }: {
    payLinkCode: string;
  }): Promise<PaylinkNapasInfoModel> {
    try {
      let resp = await this.client.payLinkController.payNapas({
        payLinkCode: payLinkCode,
      });
      if (resp.data.code === 0) {
        return mapPayLinkNapasModal(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async notifyTransactionNapas({
    napasResult,
  }: {
    napasResult: string;
  }): Promise<boolean> {
    try {
      let resp = await this.client.payLinkController.notifyTransaction({
        napasResult: napasResult,
      });
      if (resp.data.code === 0) {
        return resp.data.data?.status ?? false;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
