import { mapBankModel } from "@/adapters/bank_account_adapter";
import { KienlongPayClient } from "@/api/kienlong_pay_http_client";
import { BankModel } from "@/models/BankModel";
import { ErrorModel } from "@/models/ErrorModel";
import { SortDirectionType } from "@/models/Sort";
import { BankRepository } from "../BankRepository";

export class BankRepositoryImp implements BankRepository {
  private client: KienlongPayClient;
  constructor(kienlongPayClient: KienlongPayClient) {
    this.client = kienlongPayClient;
  }

  public async getAllBanks({
    code,
    name,
    napasSupported,
    page = 0,
    pageSize = 200,
    sortBy,
    sortDirection = SortDirectionType.DESC,
  }: {
    code?: string;
    name?: string;
    napasSupported?: boolean;
    page?: number;
    pageSize?: number;
    sortBy?: string;
    sortDirection: SortDirectionType;
  }): Promise<{
    pageNumber: number;
    pageSize: number;
    totalSize: number;
    totalPage: number;
    items: BankModel[];
  }> {
    try {
      let resp = await this.client.bankController.getAllBanks({
        code: code,
        name: name,
        napasSupported: napasSupported,
        page: page,
        pageSize: pageSize,
        sortBy: sortBy,
        sortDirection: sortDirection,
      });
      if (resp.data.code === 0) {
        return {
          pageNumber: resp.data.data?.pageNumber ?? 0,
          pageSize: resp.data.data?.pageSize ?? 0,
          totalPage: resp.data.data?.totalPage ?? 0,
          totalSize: resp.data.data?.totalSize ?? 0,
          items:
            resp.data.data?.items?.map((d): BankModel => mapBankModel(d)) ?? [],
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
