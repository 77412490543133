import { PermissionModel } from "@/models/PermissionModel";
import { NavlinkPath } from "@/services/router-config";

function checkRouterWithRole(id: number): string[] {
  switch (id) {
    // họ 12xx
    case 1200:
      return [NavlinkPath.dashboard.toString()];
    case 1201:
      return [NavlinkPath.balance.toString()];
    case 1202:
      return [NavlinkPath.transactionsManagement.toString()];
    //   họ 13xx
    case 1300:
      return [NavlinkPath.statistic.toString()];

    //   họ 14xx
    case 1400:
      return [
        NavlinkPath.payGate.toString(),
        NavlinkPath.payGatePayment.toString(),
      ];
    case 1401:
      return [
        NavlinkPath.payGate.toString(),
        NavlinkPath.payGateWebhooks.toString(),
      ];
    case 1402:
      return [
        NavlinkPath.payGate.toString(),
        NavlinkPath.payGateTools.toString(),
        NavlinkPath.payGateToolsTransferMoney.toString(),
      ];

    //   họ 15xx
    case 1500:
      return [
        NavlinkPath.payPage.toString(),
        NavlinkPath.createPayPage.toString(),
        NavlinkPath.payPageDetail.toString(),
        NavlinkPath.payPageDetailInfo.toString(),
        NavlinkPath.payPageHistoryTransaction.toString(),
        NavlinkPath.payPageDetailHistoryTransaction.toString(),
      ];
    case 1501:
      return [
        NavlinkPath.payLink.toString(),
        NavlinkPath.createPayLink.toString(),
      ];
    case 1502:
      return [NavlinkPath.payBox.toString()];
    case 1504:
      return [
        NavlinkPath.paySubscription.toString(),
        NavlinkPath.paySubscriptionDetail.toString(),
        NavlinkPath.paySubscriptionBillDetail.toString(),
        NavlinkPath.paySubscriptionCreate.toString(),
        NavlinkPath.paySubscriptionUpdate.toString(),
        NavlinkPath.paySubscriptionPlan.toString(),
        NavlinkPath.paySubscriptionPlanCreate.toString(),
        NavlinkPath.paySubscriptionPlanUpdate.toString(),
        NavlinkPath.paySubscriptionPlanDetail.toString(),
      ];
    case 1505:
      return [
        NavlinkPath.payButton.toString(),
        NavlinkPath.createPayButton.toString(),
        NavlinkPath.payButtonDetail.toString(),
        NavlinkPath.updatePayButton.toString(),
      ];
    case 1506:
      return [NavlinkPath.payPOS.toString()];

    //   họ 16xx
    case 1600:
      return [
        NavlinkPath.payout.toString(),
        NavlinkPath.createPayout.toString(),
        NavlinkPath.detailPayout.toString(),
      ];
    case 1605:
      return [
        NavlinkPath.feeReport.toString(),
        NavlinkPath.feeReportDetail.toString(),
        NavlinkPath.feeReportDetailTransactionHistory.toString(),
      ];

    //   họ 17xx
    case 1700:
      return [
        NavlinkPath.productManagement.toString(),
        NavlinkPath.categoryDetail.toString(),
      ];

    //   họ 18xx
    case 1800:
      return [
        NavlinkPath.customerManagement.toString(),
        NavlinkPath.customerGroupDetail.toString(),
      ];

    //   họ 19xx
    case 1900:
      return [
        NavlinkPath.indenticateAccountSetting.toString(),
        NavlinkPath.identification.toString(),
      ];
    case 1901:
      return [NavlinkPath.accountInfo.toString()];
    case 1902:
      return [
        NavlinkPath.receivedMoneyAccount.toString(),
        NavlinkPath.receivedMoneyAccountDetail.toString(),
      ];
    case 1903:
      return [
        NavlinkPath.memberManagement.toString(),
        NavlinkPath.memberManagementCreate.toString(),
        NavlinkPath.memberManagementUpdate.toString(),
      ];
    case 1904:
      return [NavlinkPath.changePassword.toString()];
    case 1905:
      return [NavlinkPath.notifyEmail.toString()];
    case 1906:
      return [NavlinkPath.settingPayment.toString()];

    //   họ 20xx
    case 2000:
      return [NavlinkPath.support.toString()];

    //   họ 22xx
    case 2200:
      return [
        NavlinkPath.forControl.toString(),
        NavlinkPath.forControlDetail.toString(),
      ];

    //   họ 24xx
    case 2400:
      return [
        NavlinkPath.vietQrPro.toString(),
        NavlinkPath.vietQrProTransaction.toString(),
      ];
    case 2401:
      return [NavlinkPath.appMarket.toString()];
    case 2402:
      return [
        NavlinkPath.flexibleSalaryPayment.toString(),
        NavlinkPath.flexibleSalaryPaymentStatistic.toString(),
        NavlinkPath.flexibleSalaryPaymentStatisticDetail.toString(),
        NavlinkPath.flexibleSalaryPaymentEnterpriseConfiguration.toString(),
        NavlinkPath.flexibleSalaryPaymentTypeOfFlexibleSalary.toString(),
        NavlinkPath.flexibleSalaryPaymentTypeOfFlexibleSalaryDetail.toString(),
        NavlinkPath.flexibleSalaryPaymentTypeOfFlexibleSalaryCreate.toString(),
        NavlinkPath.flexibleSalaryPaymentTypeOfFlexibleSalaryUpdate.toString(),
        NavlinkPath.flexibleSalaryPaymentStaff.toString(),
        NavlinkPath.flexibleSalaryPaymentStaffDetail.toString(),
        NavlinkPath.flexibleSalaryPaymentStaffCreate.toString(),
        NavlinkPath.flexibleSalaryPaymentStaffUpdate.toString(),
        NavlinkPath.flexibleSalaryPaymentStaffImportExcel.toString(),
        NavlinkPath.flexibleSalaryPaymentPeriod.toString(),
        NavlinkPath.flexibleSalaryPaymentPeriodDetail.toString(),
        NavlinkPath.flexibleSalaryPaymentPeriodDetailImportExcel.toString(),
        NavlinkPath.flexibleSalaryPaymentRequestAdvanceSalaries.toString(),
        NavlinkPath.flexibleSalaryPaymentRequestAdvanceSalariesDetail.toString(),
      ];

    default:
      return [""];
  }
}

export function mappingRoutersAndRoles(roles: PermissionModel[]): {
  id: number;
  routerPaths: string[];
}[] {
  let temp: {
    id: number;
    routerPaths: string[];
  }[] = [];

  for (let i = 0; i < roles.length; i++) {
    const element = roles[i];
    temp.push({
      id: element.id,
      routerPaths: checkRouterWithRole(element.id),
    });
  }

  return temp;
}
