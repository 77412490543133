import { appMessage } from "@/components/app-message/app-message";
import BottomBarCustom from "@/components/bottom-bar/BottomBarCustom";
import { KienlongPageLoading } from "@/components/page-loading/KienlongPageLoading";
import PageNotFound from "@/components/page-not-found/PageNotFound";
import { WelcomMessage } from "@/components/welcom-umee/WelcomMessage";
import { useAppBloc } from "@/contexts/app";
import { MerchantStatusType } from "@/models/MerchantModel";
import { useEffect, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { NavlinkPath } from "@/services/router-config";
import { BreadCrumbCustom } from "@/components/breadcrumb/BreadCrumbCustom";
import { HeaderCustom } from "@/components/header/HeaderCustom";
import { SidebarCustom } from "@/components/side-bar/SideBarCustom";
import "./home.css";
import { bodyId } from "@/services/constants";
import { useTranslation } from "react-i18next";
import { BaseBloc } from "@/services/base-bloc";
import { StreamBuilder } from "@/components/stream-builder/StreamBuilder";
import ErrorPage403 from "../40x/403";
import { routerIntercepter } from "./utils/routerIntercepter";
import { mappingRoutersAndRoles } from "./utils/mappingRoutersAndRoles";
import { PopupCustom2 } from "@/components/popup-custom/PopupCustom2";
import { iconAlert } from "@/services/assets";

interface RouterDataProps {
  pathname: string;
  search: string;
  hash: string;
  state: any;
  key: string;
  disAllowed: boolean;
  isMounted: boolean;
}

const Home: React.FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { appBloc } = useAppBloc();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const cupit = useMemo<BaseBloc<RouterDataProps>>(() => {
    return new BaseBloc<RouterDataProps>({
      initState: { ...location, disAllowed: false, isMounted: false },
    });
  }, []);

  const { isLoading, isError, data, isSuccess } = useQuery(
    ["get-merchant-in-layout"],
    {
      refetchOnWindowFocus: false,
      retry: false,
      // enabled:false,
      queryFn: async () => {
        let retMerchant =
          await appBloc.repository.kienlongPayHttpClient.merchantRepository.getMerchant();
        appBloc.storage.merchantData = { ...retMerchant };
        if (
          appBloc.storage.testMode === false &&
          retMerchant?.status !== MerchantStatusType.ACTIVE
        ) {
          appBloc.storage.testMode = true;
          setTimeout(() => {
            return window.location.reload();
          }, 120);
          return null;
        }
        if (retMerchant?.status === MerchantStatusType.CREATED) {
          appBloc.storage.isIdenticatedAccount = false;
          if (appBloc.storage.isShowWelcomMessage === false) {
            appBloc.storage.isShowWelcomMessage = true;
            appBloc.session.message.next(
              <WelcomMessage
                onClickActive={() => {
                  navigate(`${NavlinkPath.indenticateAccountSetting}`);
                  appBloc.session.isShowMessage.next(false);
                }}
                onClickDashboard={() => {
                  if (appBloc.storage.testMode) {
                    appBloc.session.isShowMessage.next(false);
                  } else {
                    appBloc.storage.testMode = true;
                    setTimeout(() => {
                      window.location.reload();
                    }, 300);
                  }
                }}
                onClose={() => {
                  appBloc.session.isShowMessage.next(false);
                }}
              />,
            );
            appBloc.session.isShowMessage.next(true);
          }
        } else {
          appBloc.storage.isIdenticatedAccount = true;
        }
        return retMerchant;
      },
      onError(err: any) {
        appMessage.error({ title: `${t("fail")}`, content: `${err.message}` });
        console.error(err);
        appBloc.storage.isIdenticatedAccount = false;
      },
    },
  );

  const {
    isLoading: isLoadingGetRole,
    data: dataRoleGroupd,
    isSuccess: isSuccessGetRoleGroups,
  } = useQuery(["get-roles-in-layout"], {
    refetchOnWindowFocus: false,
    retry: false,
    queryFn: async () => {
      // let refPers =
      // await appBloc.repository.kienlongPayHttpClient.roleRepository.allReferencePermission();
      const userPers =
        await appBloc.repository.kienlongPayHttpClient.roleRepository.listCurrentPermission();
      return userPers;
    },
  });

  useEffect(() => {
    if (
      (data && data.status === MerchantStatusType.CREATED) ||
      appBloc.storage.testMode
    ) {
      if (
        location.pathname.includes(NavlinkPath.changePassword) ||
        location.pathname.includes(NavlinkPath.payBox) ||
        location.pathname.includes(NavlinkPath.payBox) ||
        // location.pathname.includes(NavlinkPath.payPosRegisterService) ||
        // location.pathname.includes(NavlinkPath.payPosOrderService) ||
        location.pathname.includes(NavlinkPath.payPosDevice) ||
        location.pathname.includes(NavlinkPath.payPosHistoryPayment)
      ) {
        navigate(NavlinkPath.dashboard);
        setTimeout(() => {
          appMessage.warning({
            title: "Thông báo",
            content: "Tính năng không dùng cho Test",
          });
        }, 200);
      }
    }
    appBloc.session.error.next(null);
  }, [location]);

  useEffect(() => {
    if (isSuccess && isSuccessGetRoleGroups && dataRoleGroupd !== undefined) {
      // phase mouting;
      cupit.state.isMounted = false;
      cupit.upDateState();

      appBloc.session.routersAndRoles = mappingRoutersAndRoles(dataRoleGroupd);

      // phase checking;
      const next = routerIntercepter({
        path: location.pathname,
        roles: appBloc.session.routersAndRoles,
      });

      if (!next) {
        // If page has tabBar must have breadcrumb
        if (!location.pathname.startsWith(NavlinkPath.payGate)) {
          appBloc.session.breadcrumb.next(null);
        }

        appBloc.session.bottomBarComponent.next(null);
        appBloc.session.queueModal.next(null);
        appBloc.session.queueModal.next(
          <PopupCustom2
            onConfirm={() => {
              appBloc.session.queueModal.next(null);
            }}
            title={`${t("notification.attention")}`}
            subTitle={`${t("notification.access_denied")}`}
            labelConfirm={`${t("agree")}`}
            img={iconAlert}
            styleImg={{
              width: "100px",
              height: "100px",
              aspectRatio: "100 / 100",
            }}
          />,
        );
      }

      // phase mounted
      cupit.state = { ...location, disAllowed: !next, isMounted: true };
      cupit.upDateState();
    }
  }, [isSuccess, location, isSuccessGetRoleGroups, dataRoleGroupd]);

  if (isLoading || isLoadingGetRole) {
    return <></>;
    // return <KienlongPageLoading />;
  }

  if (isError) {
    return <PageNotFound />;
  }

  return (
    <section className="layout-custom">
      {/* sizeBar */}
      <SidebarCustom />

      {/* layout right */}
      <section className="sub-layout-custom">
        {/* header */}
        <HeaderCustom merchantStatus={data!.status} />

        {/* breadCrump */}
        <BreadCrumbCustom />

        {/* body */}
        <main id={bodyId} className="layout-content-custom scroll-bar-element">
          <StreamBuilder<RouterDataProps, any, any>
            initData={cupit.state}
            stream={cupit.stream}
            selector={(pre, next) => {
              return (
                pre?.disAllowed !== next?.disAllowed ||
                pre?.isMounted !== next?.isMounted
              );
            }}
            builder={(c, snap) => {
              if (snap?.data?.isMounted === false) {
                return <KienlongPageLoading />;
              }
              if (
                snap?.data?.disAllowed === true &&
                !location.pathname.startsWith(NavlinkPath.payGate)
              ) {
                return <ErrorPage403 />;
              }
              return children;
            }}
          />
        </main>

        {/* bottom bar */}
        <BottomBarCustom />
      </section>
    </section>
  );
};

export default Home;
