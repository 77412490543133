import { BankAccountModel } from "./BankAccountModel";
import { FixedTimeModel, TimeType } from "./TimeModel";

export interface PaySubscriptionPlanModel {
  id: string;
  code: string;
  name: string;
  description: string;
  status?: PaySubscriptionPlanStatus;
  cycleValue: number;
  timeType: TimeType;
  type: PaySubscriptionPlanType;
  fixedAmount: number;
  expirationDays: number;
  createdAt: string;
  callbackUrl: string;
  modifiedAt: string;
  fixedPaymentTime: boolean;
  fixedTime: FixedTimeModel;
  paymentType: PaySubscriptionPlanPaymentType;
  bankAccount: BankAccountModel;
}

export enum PaySubscriptionPlanCreateStatus {
  CREATED = "CREATED",
  ACTIVE = "ACTIVE",
}

export enum PaySubscriptionPlanStatus {
  CREATED = "CREATED",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum PaySubscriptionPlanType {
  RECURRING_QUANTITY = "RECURRING_QUANTITY",
  METERED_USAGE = "METERED_USAGE",
}

export enum PaySubscriptionPlanPaymentType {
  PREPAID = "PREPAID",
  POSTPAID = "POSTPAID",
}
