import { AuditDataModel } from "./AuditModel";
import { EnterpriseRegistrationRequestProgramType } from "./EnterpriseModel";

export interface EnterpriseConfigDetailModel {
  paymentSource: EnterpriseConfigPaymentSource;
  accountNumber: string;
  accountName: string;
  bankName: string;
  limitAmount?: number;
  currency?: EnterpriseConfigCurrency;
  audit?: Array<AuditDataModel>;
  periodStartDate?: number;
  periodEndDate?: number;
  paySalaryDate?: number;
  programType?: EnterpriseRegistrationRequestProgramType;
  workingDays?: number;
  disableAdvanceBeforeEndDate?: number;
}

export enum EnterpriseConfigCurrency {
  VND = "VND",
}

export enum EnterpriseConfigPaymentSource {
  BUSINESS_ACCOUNT = "BUSINESS_ACCOUNT",
  KLB_CREDIT_LINE = "KLB_CREDIT_LINE",
}
