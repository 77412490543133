import { PayInfoStatusResponse } from "@/api/generated-sources/kienlong-pay";
import { PaymentType } from "@/models/PaylinkModel";
import { PayInfoModel, PayInfoStatus } from "@/models/PayInfoModel";
import {
  GetBankTransferInfoResponse,
  GetVietQRInfoResponse,
} from "@/api/generated-sources/kienlong-pay";
import { BankTransferInfoModel, VietQrInfoModel } from "@/models/PayInfoModel";
import { mapShopSettingModel } from "./shop_adapter";

export function mapBankTransferInfoModel(
  dto?: GetBankTransferInfoResponse | undefined,
): BankTransferInfoModel {
  return {
    accountName: dto?.accountName ?? "",
    accountNo: dto?.accountNo ?? "",
    amount: dto?.amount ?? 0,
    bankName: dto?.bankName ?? "",
    content: dto?.content ?? "",
    description: dto?.description ?? "",
    failUrl: dto?.failUrl ?? "",
    merchantCode: dto?.merchantCode ?? "",
    merchantLogoUrl: dto?.merchantLogoUrl ?? "",
    redirectAfter: dto?.redirectAfter ?? 5,
    shopName: dto?.shopName ?? "",
    status:
      PayInfoStatus[(dto?.status ?? "NONE") as keyof typeof PayInfoStatus],
    successUrl: dto?.successUrl ?? "",
  };
}

export function mapVietQrInfoModel(
  dto?: GetVietQRInfoResponse | undefined,
): VietQrInfoModel {
  return {
    description: dto?.description ?? "",
    failUrl: dto?.failUrl ?? "",
    merchantCode: dto?.merchantCode ?? "",
    merchantLogoUrl: dto?.merchantLogoUrl ?? "",
    redirectAfter: dto?.redirectAfter ?? 5,
    shopName: dto?.shopName ?? "",
    status:
      PayInfoStatus[(dto?.status ?? "NONE") as keyof typeof PayInfoStatus],
    successUrl: dto?.successUrl ?? "",
    amount: dto?.amount ?? 0,
    qrContent: dto?.qrContent ?? "",
    bankAccountName: dto?.bankAccountName ?? "",
    bankAccountNo: dto?.bankAccountNo ?? "",
    email: dto?.email ?? "",
    phone: dto?.phone ?? "",
    shopSetting: mapShopSettingModel(dto?.shopSetting),
    virtualAccount: dto?.virtualAccount ?? "",
  };
}

export function mapPayInfoModel(dto?: PayInfoStatusResponse): PayInfoModel {
  return {
    accountName: dto?.accountName ?? "",
    amount: dto?.amount ?? 0,
    dateTime: dto?.dateTime ?? "",
    description: dto?.description ?? "",
    failUrl: dto?.failUrl ?? "",
    paymentType:
      PaymentType[
        (dto?.paymentType ?? PaymentType.VIET_QR) as keyof typeof PaymentType
      ],
    redirectAfter: dto?.redirectAfter ?? 5,
    rtxNumber: dto?.rtxNumber ?? "",
    status:
      PayInfoStatus[
        (dto?.status ?? PayInfoStatus.NONE) as keyof typeof PayInfoStatus
      ],
    successUrl: dto?.successUrl ?? "",
  };
}
