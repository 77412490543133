import { mapPayboxStaticQrModel } from "@/adapters/paybox_static_qr_adapter";
import { PayboxClient } from "@/api/paybox_http_client";
import { ErrorModel } from "@/models/ErrorModel";
import {
  PayboxStaticQrModel,
  PayboxStaticQrType,
} from "@/models/PayboxStaticQrModel";
import { PayboxStaticQrRepository } from "../PayboxStaticQrRepository";

export class PayboxStaticQrRepositoryImp implements PayboxStaticQrRepository {
  private client: PayboxClient;
  constructor(payboxClient: PayboxClient) {
    this.client = payboxClient;
  }

  async getAll({
    deviceId,
  }: {
    deviceId: string;
  }): Promise<PayboxStaticQrModel[]> {
    try {
      let resp = await this.client.payBoxStaticQrController.getAllQr({
        deviceId: deviceId,
      });
      if (resp.data.code === 0) {
        return (
          resp.data?.data?.qrList?.content?.map((qr) =>
            mapPayboxStaticQrModel(qr),
          ) ?? []
        );
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
  async preview({
    staticQrId,
    accountNumber,
    accountName,
    shopName,
    icon,
    name,
    shortName,
    bankBin,
    bankCode,
    qrId,
    staticQrType,
  }: {
    staticQrId?: string;
    accountNumber?: string;
    accountName?: string;
    shopName?: string;
    icon?: string;
    name?: string;
    shortName?: string;
    bankBin?: string;
    bankCode?: string;
    qrId?: string;
    staticQrType: PayboxStaticQrType;
  }): Promise<string> {
    try {
      let resp = await this.client.payBoxStaticQrController.preview({
        staticQrId,
        accountNumber,
        accountName,
        shopName,
        icon,
        name,
        shortName,
        bankBin,
        bankCode,
        qrId,
        staticQrType,
      });
      if (resp.data.code === 0) {
        return resp.data.data?.preview ?? "";
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  async addStaticQr({
    deviceId,
    accountNumber,
    accountName,
    shopName,
    content,
    icon,
    name,
    shortName,
    bankBin,
    bankCode,
    qrId,
    staticQrType,
    defaultQr,
  }: {
    deviceId: string;
    accountNumber?: string;
    accountName?: string;
    shopName?: string;
    content?: string;
    icon?: string;
    name?: string;
    shortName?: string;
    bankBin?: string;
    bankCode?: string;
    qrId?: string;
    staticQrType?: PayboxStaticQrType;
    defaultQr?: boolean;
  }): Promise<PayboxStaticQrModel | undefined> {
    try {
      let resp = await this.client.payBoxStaticQrController.addQr({
        deviceId,
        accountNumber,
        accountName,
        shopName,
        content,
        icon,
        name,
        shortName,
        bankBin,
        bankCode,
        qrId,
        staticQrType,
        defaultQr,
      });
      if (resp.data.code === 0) {
        return mapPayboxStaticQrModel(resp.data?.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  async deletePayboxStaticQR({ id }: { id: string }): Promise<boolean> {
    try {
      let resp = await this.client.payBoxStaticQrController.deleteQrById({
        id: id,
        body: {},
      });
      if (resp.data.code === 0) {
        return resp.data.data?.success ?? false;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  async updatePayboxStaticQR({
    id,
    accountNumber,
    accountName,
    shopName,
    icon,
    name,
    shortName,
    bankBin,
    bankCode,
    qrId,
    staticQrType,
    defaultQr,
  }: {
    id: string;
    accountNumber?: string;
    accountName?: string;
    shopName?: string;
    icon: string;
    name?: string;
    shortName?: string;
    bankBin?: string;
    bankCode?: string;
    qrId: string;
    staticQrType: PayboxStaticQrType;
    defaultQr?: boolean;
  }): Promise<PayboxStaticQrModel | undefined> {
    try {
      let resp = await this.client.payBoxStaticQrController.updateQrById({
        id,
        accountNumber,
        accountName,
        shopName,
        icon,
        name,
        shortName,
        bankBin,
        bankCode,
        qrId,
        staticQrType,
        defaultQr,
      });
      if (resp.data.code === 0) {
        return mapPayboxStaticQrModel(resp.data?.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  async validateImagePayboxStaticQR({
    image,
  }: {
    image: File;
  }): Promise<string> {
    try {
      let resp = await this.client.payBoxStaticQrController.validate({
        image,
      });
      if (resp.data.code === 0) {
        return resp.data.data?.qrId ?? "";
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
