export interface CustomerInfoFormModel {
  id: string;
  fillName: boolean;
  fillPhone: boolean;
  fillEmail: boolean;
  fillAddress: boolean;
}

export enum CustomerInfoType {
  NO_REQUIRE = "NO_REQUIRE",
  AUTO_FILL = "AUTO_FILL",
  REQUIRE_INPUT = "REQUIRE_INPUT",
  PARTNER_FILL = "PARTNER_FILL",
}

export interface CustomerModel {
  id: string;
  fullName: string;
  email: string;
  phone: string;
  address: string;
  customerGroupId: string;
  customerGroupName: string;
  code: string;
}

export interface CustomerGroupModel {
  id: string;
  name: string;
  customers: CustomerModel[];
}

export interface CustomerInfoModel {
  fullName: string;
  email: string;
  phone: string;
  address: string;
  id?: string;
}
