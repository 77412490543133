import { IconLink } from "@/components/icons-component/icon-link";
import { IconListTransfer } from "@/components/icons-component/icon-list-transfer";
import { IconPayGate } from "@/components/icons-component/icon-pay-gate";
import { IconPaybox } from "@/components/icons-component/icon-paybox";
import { IconPayButton } from "@/components/icons-component/icon-paybutton";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import "@/styles";
import { NavlinkPath } from "@/services/router-config";
import {
  IconCustomer,
  IconDashBoard,
  IconMenuProduct,
  IconPayout,
  IconPos,
  IconSetting,
  IconStatistic,
  IconSupport,
  IconWallet,
} from "../icons-component/icon-component";
import { CustomNavLink, CustomNavLinkGroup } from "./components/CustomNavLink";
import CustomNavLinkExpand from "./components/CustomNavLinkExpand";
import "./side-bar-custom.css";
import { IconFile } from "@/components/icons-component/icon-file";
import { IconForControl } from "@/components/icons-component/icon-for-control";
import { sidebarId } from "@/services/constants";
import { Environment } from "@/services/environment";
import { IconPaySubscription } from "../icons-component/icon-pay-subscription";
import { IconUltilities } from "../icons-component/icon-utilities";

export const SidebarCustom = () => {
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    let sidebarNav = document.getElementById(sidebarId);
    document.addEventListener("click", (e) => {
      if (sidebarNav && e.clientX > 264) {
        sidebarNav.style.left = "-264px";
      }
    });

    return () => {
      document.removeEventListener("click", () => {});
    };
  }, []);
  return (
    <aside id={sidebarId} className="side-bar-custom">
      {/* logo */}
      <div id={`kienlongbank-pay-sidebar-logo`} className="column">
        <img
          alt=""
          src={`${Environment.hostApp}/icons/icon-kienlongbank-logo-header.svg`}
        />
      </div>

      {/* Navigate link */}
      <nav
        id={`kienlongbank-pay-sidebar-NavLinks`}
        className="column side-bar-custom-link scroll-bar-element"
        style={{
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <CustomNavLinkGroup title="">
          <CustomNavLink
            path={NavlinkPath.dashboard}
            isSelected={location.pathname.startsWith(NavlinkPath.dashboard)}
            title={t("side-bar.dashboard") ?? ""}
            icon={
              <IconDashBoard
                color={
                  location.pathname.startsWith(NavlinkPath.dashboard)
                    ? "#FFFFFF"
                    : "#3366FF"
                }
              />
            }
          />
          {Environment.onBalance && (
            <CustomNavLink
              path={NavlinkPath.balance}
              isSelected={location.pathname.startsWith(NavlinkPath.balance)}
              title={t("side-bar.management-balance") ?? ""}
              icon={
                <IconWallet
                  color={
                    location.pathname.startsWith(NavlinkPath.balance)
                      ? "#FFFFFF"
                      : "#3366FF"
                  }
                />
              }
            />
          )}
          {Environment.onTransaction && (
            <CustomNavLink
              path={NavlinkPath.transactionsManagement}
              isSelected={location.pathname.startsWith(
                NavlinkPath.transactionsManagement,
              )}
              title={t("side-bar.transactions-management") ?? ""}
              icon={
                <IconListTransfer
                  color={
                    location.pathname.startsWith(
                      NavlinkPath.transactionsManagement,
                    )
                      ? "#FFFFFF"
                      : "#3366FF"
                  }
                />
              }
            />
          )}
          {Environment.onStatistic && (
            <CustomNavLink
              path={NavlinkPath.statistic}
              isSelected={location.pathname.startsWith(NavlinkPath.statistic)}
              title={t("side-bar.statistic") ?? ""}
              icon={
                <IconStatistic
                  color={
                    location.pathname.startsWith(NavlinkPath.statistic)
                      ? "#FFFFFF"
                      : "#3366FF"
                  }
                />
              }
            />
          )}
          {Environment.onForControl && (
            <CustomNavLink
              path={NavlinkPath.forControl}
              isSelected={location.pathname.startsWith(NavlinkPath.forControl)}
              title={t("side-bar.for-control") ?? ""}
              icon={
                <IconForControl
                  color={
                    location.pathname.startsWith(NavlinkPath.forControl)
                      ? "#FFFFFF"
                      : "#3366FF"
                  }
                />
              }
            />
          )}
          {Environment.onFeeReport && (
            <CustomNavLink
              path={NavlinkPath.feeReport}
              isSelected={location.pathname.startsWith(NavlinkPath.feeReport)}
              title={t("side-bar.fee-report") ?? ""}
              icon={
                <IconFile
                  color={
                    location.pathname.startsWith(NavlinkPath.feeReport)
                      ? "#FFFFFF"
                      : "#3366FF"
                  }
                />
              }
            />
          )}
          {Environment.onUltilities && (
            <CustomNavLink
              path={NavlinkPath.ultilities}
              isSelected={location.pathname.startsWith(NavlinkPath.ultilities)}
              title={t("side-bar.ultilities") ?? ""}
              icon={
                <IconUltilities
                  color={
                    location.pathname.startsWith(NavlinkPath.ultilities)
                      ? "#FFFFFF"
                      : "#3366FF"
                  }
                />
              }
            />
          )}
        </CustomNavLinkGroup>

        {/* tích hợp */}
        {Environment.onPayGate && (
          <CustomNavLinkGroup
            title={t("side-bar.integration").toUpperCase() ?? ""}
          >
            {Environment.onPayGate && (
              <CustomNavLink
                path={NavlinkPath.payGatePayment}
                isSelected={location.pathname.startsWith(NavlinkPath.payGate)}
                title={t("side-bar.pay-gate") ?? ""}
                icon={
                  <IconPayGate
                    color={
                      location.pathname.startsWith(NavlinkPath.payGate)
                        ? "#FFFFFF"
                        : "#3366FF"
                    }
                  />
                }
              />
            )}
          </CustomNavLinkGroup>
        )}

        {/* payment receive */}
        <CustomNavLinkGroup
          title={t("side-bar.receiving-payment").toUpperCase() ?? ""}
        >
          {Environment.onPayPage && (
            <CustomNavLink
              path={NavlinkPath.payPage}
              isSelected={location.pathname.startsWith(NavlinkPath.payPage)}
              title="Pay Page"
              icon={
                <IconDashBoard
                  color={
                    location.pathname.startsWith(NavlinkPath.payPage)
                      ? "#FFFFFF"
                      : "#3366FF"
                  }
                />
              }
            />
          )}
          {Environment.onPayLink && (
            <CustomNavLink
              path={NavlinkPath.payLink}
              isSelected={location.pathname.startsWith(NavlinkPath.payLink)}
              title="Pay Link"
              icon={
                <IconLink
                  color={
                    location.pathname.startsWith(NavlinkPath.payLink)
                      ? "#FFFFFF"
                      : "#3366FF"
                  }
                />
              }
            />
          )}
          {Environment.onPayBox && (
            <CustomNavLink
              path={NavlinkPath.payBox}
              isSelected={location.pathname.startsWith(NavlinkPath.payBox)}
              title="Pay Box"
              icon={
                <IconPaybox
                  color={
                    location.pathname.startsWith(NavlinkPath.payBox)
                      ? "#FFFFFF"
                      : "#3366FF"
                  }
                />
              }
            />
          )}
          {Environment.onPaySubscription && (
            <CustomNavLinkExpand
              path={NavlinkPath.paySubscription}
              isSelected={
                NavlinkPath.paySubscription ===
                "/" + location.pathname.split("/")[1]
              }
              title="Pay Subscription"
              icon={<IconPaySubscription />}
            >
              <CustomNavLink
                path={NavlinkPath.paySubscription}
                icon={<div style={{ paddingLeft: "24px" }}></div>}
                isSelected={
                  NavlinkPath.paySubscription ===
                  "/" + location.pathname.split("/")[1]
                }
                title="Subscription"
              />
              <CustomNavLink
                path={NavlinkPath.paySubscriptionPlan}
                icon={<div style={{ paddingLeft: "24px" }}></div>}
                isSelected={
                  NavlinkPath.paySubscriptionPlan ===
                  "/" + location.pathname.split("/")[1]
                }
                title="Subscription Plan"
              />
            </CustomNavLinkExpand>
          )}
          {Environment.onPayButton && (
            <CustomNavLink
              path={NavlinkPath.payButton}
              isSelected={location.pathname.startsWith(NavlinkPath.payButton)}
              title="Pay Button"
              icon={
                <IconPayButton
                  color={
                    location.pathname.startsWith(NavlinkPath.payButton)
                      ? "#FFFFFF"
                      : "#3366FF"
                  }
                />
              }
            />
          )}
          {Environment.onPayPos && (
            <CustomNavLinkExpand
              path={NavlinkPath.payPosDevice}
              isSelected={location.pathname.startsWith(
                NavlinkPath.payPosDevice,
              )}
              title="Pay POS"
              icon={<IconPos color={"#3366FF"} />}
            >
              <CustomNavLink
                path={NavlinkPath.payPosDevice}
                icon={<div style={{ paddingLeft: "24px" }}></div>}
                isSelected={location.pathname.startsWith(
                  NavlinkPath.payPosDevice,
                )}
                title="Danh sách máy"
              />
              {/* <CustomNavLink
                path={NavlinkPath.payPosHistoryPayment}
                icon={<div style={{ paddingLeft: "24px" }}></div>}
                isSelected={location.pathname.startsWith(
                  NavlinkPath.payPosHistoryPayment,
                )}
                title="Lịch sử thanh toán"
              />
              <CustomNavLink
                path={NavlinkPath.payPosRegisterService}
                icon={<div style={{ paddingLeft: "24px" }}></div>}
                isSelected={location.pathname.startsWith(
                  NavlinkPath.payPosRegisterService,
                )}
                title="Đăng ký dịch vụ UniPOS"
              /> */}
            </CustomNavLinkExpand>
          )}
        </CustomNavLinkGroup>

        {/* chuyển tiền thanh toán */}
        {Environment.onPayOut && (
          <CustomNavLinkGroup
            title={
              t("side-bar.transfer-money", "Chuyển tiền").toUpperCase() ?? ""
            }
          >
            {Environment.onPayOut && (
              <CustomNavLink
                path={NavlinkPath.payout}
                isSelected={location.pathname.startsWith(NavlinkPath.payout)}
                title="Payout"
                icon={
                  <IconPayout
                    color={
                      location.pathname.startsWith(NavlinkPath.payout)
                        ? "#FFFFFF"
                        : "#3366FF"
                    }
                  />
                }
              />
            )}
          </CustomNavLinkGroup>
        )}

        {/* THÔNG TIN SẢN PHẨM */}
        {Environment.onProduct && (
          <CustomNavLinkGroup
            title={
              t("side-bar.product-info", "Thông tin sản phẩm").toUpperCase() ??
              ""
            }
          >
            {Environment.onProduct && (
              <CustomNavLink
                path={NavlinkPath.productManagement}
                isSelected={location.pathname.startsWith(
                  NavlinkPath.productManagement,
                )}
                title={
                  t("side-bar.product-management", "Quản lý sản phầm ") ?? ""
                }
                icon={
                  <IconMenuProduct
                    color={
                      location.pathname.startsWith(
                        NavlinkPath.productManagement,
                      )
                        ? "#FFFFFF"
                        : "#3366FF"
                    }
                  />
                }
              />
            )}
          </CustomNavLinkGroup>
        )}

        {/* Quản lý khách hàng */}
        {Environment.onCustomer && (
          <CustomNavLinkGroup
            title={
              t(
                "side-bar.customer-info",
                "Thông tin khách hàng",
              ).toUpperCase() ?? ""
            }
          >
            {Environment.onCustomer && (
              <CustomNavLink
                path={NavlinkPath.customerManagement}
                isSelected={location.pathname.startsWith(
                  NavlinkPath.customerManagement,
                )}
                title={t("side-bar.customer-management", "Quản lý khách hàng")}
                icon={
                  <IconCustomer
                    color={
                      location.pathname.startsWith(
                        NavlinkPath.customerManagement,
                      )
                        ? "#FFFFFF"
                        : "#3366FF"
                    }
                  />
                }
              />
            )}
          </CustomNavLinkGroup>
        )}

        {/* cài đặt */}
        <CustomNavLinkGroup
          title={t("side-bar.setting", "Cài đặt").toUpperCase() ?? ""}
        >
          <CustomNavLink
            path={NavlinkPath.setting}
            isSelected={location.pathname.startsWith(NavlinkPath.setting)}
            title={t("side-bar.setting", "Cài đặt") ?? ""}
            icon={
              <IconSetting
                color={
                  location.pathname.startsWith(NavlinkPath.setting)
                    ? "#FFFFFF"
                    : "#3366FF"
                }
              />
            }
          />
        </CustomNavLinkGroup>

        {/* trung tâm hỗ trợ */}
        {Environment.onSupport && (
          <CustomNavLinkGroup
            title={t("side-bar.support-center", "Trung tâm hỗ trợ")}
          >
            {Environment.onSupport && (
              <CustomNavLink
                path={NavlinkPath.support}
                isSelected={location.pathname === NavlinkPath.support}
                title={t("side-bar.support", "Yêu cầu hỗ trợ")}
                icon={
                  <IconSupport
                    color={
                      location.pathname === NavlinkPath.support
                        ? "#FFFFFF"
                        : "#3366FF"
                    }
                  />
                }
              />
            )}
          </CustomNavLinkGroup>
        )}
      </nav>
    </aside>
  );
};
