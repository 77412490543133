import { CSSProperties } from "react";
import "./card-custom.css";

export const CardCustom: React.FunctionComponent<{
  children: React.ReactNode;
  classNameOverlay?: string;
  style?: CSSProperties;
}> = ({
  children,
  classNameOverlay,
  style,
}: {
  children: React.ReactNode;
  classNameOverlay?: string;
  style?: CSSProperties;
}) => {
  return (
    <div
      className={
        classNameOverlay ? `card-custom ${classNameOverlay}` : `card-custom`
      }
      style={style}
    >
      {children}
    </div>
  );
};
