import { PaymentType } from "./PaylinkModel";

export interface PaymentMethodInfoModel {
  id: number;
  name: string;
  dynamicFee: number;
  fixedFee: number;
  paymentLimit: number;
  type: PaymentMethodInfoType;
  status: PaymentMethodInfoStatus;
  logo: string;
  paymentType: PaymentType;
}

export enum PaymentMethodInfoType {
  DOMESTIC = "DOMESTIC",
  INTERNATIONAL = "INTERNATIONAL",
}

export enum PaymentMethodInfoStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}
