import { AuditData } from "@/api/generated-sources/flexsalary";
import { AuditDataChangeType, AuditDataModel } from "../models/AuditModel";

export function mapAuditDataModel(dto?: AuditData): AuditDataModel {
  return {
    auditTime: dto?.auditTime ?? 0,
    changes:
      dto?.changes?.map((d) => {
        return {
          key: d.key ?? "",
          newValue: d.newValue ?? "",
        };
      }) ?? [],
    changeType:
      AuditDataChangeType[dto?.changeType as keyof typeof AuditDataChangeType],
    userId: dto?.userId ?? "",
    userName: dto?.userName ?? "",
  };
}
