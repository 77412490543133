import { BankAccountModel } from "./BankAccountModel";
import { RoleGroupModel } from "./RoleGroupModel";

export enum UserStatusType {
  BLOCKED = "BLOCKED",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  NOT_LOGGED_IN = "NOT_LOGGED_IN",
}

export interface UserModel {
  id: string;
  merchantCode: string;
  merchantName: string;
  username: string;
  fullName: string;
  email: string;
  status: UserStatusType;
  phone: string;
  roleGroup: RoleGroupModel;
  avatar: string;
  bankAccounts: Array<BankAccountModel>;
  isOwner: boolean;
  allowEmail: boolean;
}

export interface SubmitPasswordChangedRequest {
  password: string;
  token: string;
  sessionId: string;
}
