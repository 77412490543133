import { KienlongPayClient } from "@/api/kienlong_pay_http_client";
import { ErrorModel } from "@/models/ErrorModel";
import { CoreBankRepository } from "../CoreBankRepository";

export class CoreBankRepositoryImp implements CoreBankRepository {
  private client: KienlongPayClient;
  constructor(payboxClient: KienlongPayClient) {
    this.client = payboxClient;
  }

  async getBeneficiary({
    bin,
    accountNo,
  }: {
    bin: string;
    accountNo: string;
  }): Promise<string> {
    try {
      let resp = await this.client.coreBankController.getBeneficiary({
        bin: bin,
        accountNo: accountNo,
      });
      if (resp.data.code === 0 && resp.data.data?.accountName) {
        return resp.data.data.accountName;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
