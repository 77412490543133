import {
  mapPayPageOrderDetail,
  mapPayPageOrderItem,
  mapPayPageOrderModel,
} from "@/adapters/pay_page_adapter";
import { KienlongPayClient } from "@/api/kienlong_pay_http_client";
import { ErrorModel } from "@/models/ErrorModel";
import {
  PagePayOrderStatusType,
  PayPageOrderDetailModal,
  PayPageOrderItemModel,
  PayPageOrderModel,
} from "@/models/PayPageModel";
import { SortDirectionType } from "@/models/Sort";
import { PayPageDashBoardRepository } from "../PayPageDashboardRepository";

export class PayPageDashboardRepositoryImp
  implements PayPageDashBoardRepository
{
  private client: KienlongPayClient;
  constructor(kienlongPayClient: KienlongPayClient) {
    this.client = kienlongPayClient;
  }

  public async getAllOrderPayPage({
    from,
    id,
    page,
    pageSize,
    refId,
    sortBy,
    sortDirection,
    status,
    to,
  }: {
    from?: string;
    id?: string;
    page?: number;
    pageSize?: number;
    refId?: string;
    sortBy?: string;
    sortDirection?: SortDirectionType;
    status: PagePayOrderStatusType;
    to?: string;
  }): Promise<{
    pageNumber?: number;
    pageSize?: number;
    totalSize?: number;
    totalPage?: number;
    items?: PayPageOrderModel[];
  }> {
    try {
      let resp = await this.client.payPageDashBoardController.getAllOrders({
        from: from,
        id: id,
        page: page,
        pageSize: pageSize,
        refId: refId,
        sortBy: sortBy,
        sortDirection: sortDirection,
        status: status,
        to: to,
      });
      if (resp.data.code === 0) {
        return {
          items:
            resp.data.data?.items?.map((d) => {
              return mapPayPageOrderModel(d);
            }) ?? [],
          pageNumber: resp.data.data?.pageNumber,
          pageSize: resp.data.data?.pageSize,
          totalSize: resp.data.data?.totalSize,
          totalPage: resp.data.data?.totalPage,
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getDetailOrderPayPage({
    orderId,
  }: {
    orderId: string;
  }): Promise<PayPageOrderDetailModal> {
    try {
      let resp = await this.client.payPageDashBoardController.getOrder({
        orderId: orderId,
      });
      if (resp.data.code === 0) {
        return mapPayPageOrderDetail(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async updateOrderStatus({
    orderId,
    status,
  }: {
    orderId: string;
    status: PagePayOrderStatusType;
  }): Promise<boolean> {
    try {
      let resp = await this.client.payPageDashBoardController.updateOrderStatus(
        {
          updateOrderStatusRequest: { orderId: orderId, status },
        },
      );
      if (resp.data.code === 0) {
        return resp.data.data?.success ?? false;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getListAllOrderItemPayPage({
    orderId,
    page,
    pageSize,
  }: {
    orderId: string;
    page?: number;
    pageSize?: number;
  }): Promise<{
    pageNumber?: number;
    pageSize?: number;
    totalSize?: number;
    totalPage?: number;
    items?: PayPageOrderItemModel[];
  }> {
    try {
      let resp = await this.client.payPageDashBoardController.getOrderItems({
        orderId: orderId,
        size: pageSize,
        page: page,
        sort: ["createdAt,desc"],
      });
      if (resp.data.code === 0) {
        return {
          items:
            resp.data.data?.items?.map((d) => {
              return mapPayPageOrderItem(d);
            }) ?? [],
          pageNumber: resp.data.data?.pageNumber,
          pageSize: resp.data.data?.pageSize,
          totalSize: resp.data.data?.totalSize,
          totalPage: resp.data.data?.totalPage,
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
