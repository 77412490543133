export interface MerchantCredentialModel {
  encryptKey: string;
  secretKey: string;
  clientId: string;
  host: string;
  status?: MerchantCredentialType;
}

export enum MerchantCredentialType {
  ACTIVE = "ACTIVE",
  REVOKE = "REVOKE",
}
