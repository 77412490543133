import { BankAccountType } from "./BankAccountModel";
import { PayBoxTransactionType } from "./PayBoxTransactionModel";
import { PaymentType } from "./PaylinkModel";
import { TransactionStatusType } from "./TransactionModel";

export interface PayoutReceiverModel {
  group: PayoutReceiverGroupType;
  id: number;
  bankLogo: string;
  bankBin: string;
  bankName: string;
  accountNo: string;
  accountName: string;
}

export enum PayoutReceiverGroupType {
  CUSTOMER = "CUSTOMER",
  EMPLOYEE = "EMPLOYEE",
  MERCHANT = "MERCHANT",
  FRIEND = "FRIEND",
  FAMILY = "FAMILY",
  OTHER = "OTHER",
}

export enum PayoutStatusType {
  WAITING = "WAITING",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  TRANSFERRING = "TRANSFERRING",
  DENIED = "DENIED",
}

// export interface BatchPayoutModel {
//   id: string;
//   name: string;
//   fee: number;
//   totalAmount: number;
//   totalReceiver: number;
//   createdAt: string;
//   createdBy: string;
//   modifiedAt: string;
//   modifiedBy: string;
//   status: PayoutStatusType;
//   receivers: SinglePayoutModel[];
// }

export interface SinglePayoutModel {
  id: string;
  amount: number;
  content: string;
  type: SinglePayoutType;
  status: PayoutStatusType;
  fromBranch: BankAccountType;
  fromAccountNo: string;
  fromAccountName: string;
  toBankBin: string;
  toBankLogo: string;
  toBankName: string;
  toAccountNo: string;
  toAccountName: string;
  denyContent: string;
  txnNumber: string;
  transactionTime: string;
}

export enum SinglePayoutType {
  SINGLE = "SINGLE",
  BATCH = "BATCH",
}

export interface PayoutTransactionModel {
  id: string;
  status: TransactionStatusType;
  amount: number;
  refTransactionId: string;
  createDateTime: string;
  depositTime: string;
  completeTime: string;
  virtualAccount: string;
  description: string;
  paymentType: PaymentType;
  bankType: BankAccountType;
  successUrl: string;
  failUrl: string;
  callbackAfter: number;
  merchantId: string;
  merchantCode: string;
  merchantName: string;
  customerName: string;
  customerPhone: string;
  payLinkCode: string;
  payboxTransactionType: PayBoxTransactionType;
}

export interface PagePayoutModel {
  pageNumber: number;
  pageSize: number;
  totalSize: number;
  totalPage: number;
  items: SinglePayoutModel[];
}
