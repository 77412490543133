import { mapVietQrProModel } from "@/adapters/vietqr_pro_adapter";
import { KienlongPayClient } from "@/api/kienlong_pay_http_client";
import { ErrorModel } from "@/models/ErrorModel";
import {
  PortalNextStateVietQrProRequestActionType,
  VietQrProModel,
} from "@/models/VietQrProModel";
import { VietQrProRepository } from "../VietQrProRepository";

export class VietQrProRepositoryImp implements VietQrProRepository {
  private client: KienlongPayClient;
  constructor(kienlongPayClient: KienlongPayClient) {
    this.client = kienlongPayClient;
  }

  public async nextStatePermitBankAccount({
    id,
  }: {
    id: number;
  }): Promise<boolean> {
    try {
      let resp = await this.client.vietQrPro.nextStatePermitBankAccount({
        id: id,
      });
      if (resp.data.code === 0) {
        return resp.data.data?.success ?? false;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async nextStateVietQrPro({
    action,
  }: {
    action: PortalNextStateVietQrProRequestActionType;
  }): Promise<boolean> {
    try {
      let resp = await this.client.vietQrPro.nextStateVietQrPro({
        portalNextStateVietQrProRequest: {
          action: action,
        },
      });
      if (resp.data.code === 0) {
        return resp.data.data?.success ?? false;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getAllCustomerVietQrPro({
    keyword,
    page,
    pageSize,
    fromDate,
    toDate,
  }: {
    keyword?: string;
    page?: number;
    pageSize?: number;
    fromDate?: string;
    toDate?: string;
  }): Promise<{
    pageNumber: number;
    pageSize: number;
    totalSize: number;
    totalPage: number;
    items: Array<VietQrProModel>;
  }> {
    try {
      let resp = await this.client.vietQrPro.getAllCustomerVietQrPro({
        keyword: keyword,
        page: page,
        size: pageSize,
        sort: ["createdAt,desc"],
        fromDate: fromDate,
        toDate: toDate,
      });
      if (resp.data.code === 0) {
        return {
          pageNumber: resp.data.data?.pageNumber ?? 0,
          pageSize: resp.data.data?.pageSize ?? 0,
          totalSize: resp.data.data?.totalSize ?? 0,
          totalPage: resp.data.data?.totalPage ?? 0,
          items:
            resp.data.data?.items?.map((d) => {
              return mapVietQrProModel(d);
            }) ?? [],
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
