export const IconLink = ({ color = "white" }: { color: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 5C2.25 4.0335 3.0335 3.25 4 3.25H14C14.9665 3.25 15.75 4.0335 15.75 5V15C15.75 15.9665 14.9665 16.75 14 16.75H12C11.5858 16.75 11.25 16.4142 11.25 16C11.25 15.5858 11.5858 15.25 12 15.25H14C14.1381 15.25 14.25 15.1381 14.25 15V5C14.25 4.86193 14.1381 4.75 14 4.75H4C3.86193 4.75 3.75 4.86193 3.75 5V15C3.75 15.1381 3.86193 15.25 4 15.25H6C6.41421 15.25 6.75 15.5858 6.75 16C6.75 16.4142 6.41421 16.75 6 16.75H4C3.0335 16.75 2.25 15.9665 2.25 15V5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 8.75C9.86193 8.75 9.75 8.86193 9.75 9V19C9.75 19.1381 9.86193 19.25 10 19.25H20C20.1381 19.25 20.25 19.1381 20.25 19V9C20.25 8.86193 20.1381 8.75 20 8.75H18C17.5858 8.75 17.25 8.41421 17.25 8C17.25 7.58579 17.5858 7.25 18 7.25H20C20.9665 7.25 21.75 8.0335 21.75 9V19C21.75 19.9665 20.9665 20.75 20 20.75H10C9.0335 20.75 8.25 19.9665 8.25 19V9C8.25 8.0335 9.0335 7.25 10 7.25H12C12.4142 7.25 12.75 7.58579 12.75 8C12.75 8.41421 12.4142 8.75 12 8.75H10Z"
        fill={color}
      />
    </svg>
  );
};
