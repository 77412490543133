/* tslint:disable */
/* eslint-disable */
/**
 * Umee-Pay-Service
 * Umee-Pay-Service API
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from "./configuration";
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "./base";

/**
 *
 * @export
 * @interface AccessTokenResponseCustom
 */
export interface AccessTokenResponseCustom {
  /**
   *
   * @type {{ [key: string]: object; }}
   * @memberof AccessTokenResponseCustom
   */
  otherClaims?: { [key: string]: object };
  /**
   *
   * @type {string}
   * @memberof AccessTokenResponseCustom
   */
  token?: string;
  /**
   *
   * @type {number}
   * @memberof AccessTokenResponseCustom
   */
  expiresIn?: number;
  /**
   *
   * @type {number}
   * @memberof AccessTokenResponseCustom
   */
  refreshExpiresIn?: number;
  /**
   *
   * @type {string}
   * @memberof AccessTokenResponseCustom
   */
  refreshToken?: string;
  /**
   *
   * @type {string}
   * @memberof AccessTokenResponseCustom
   */
  tokenType?: string;
  /**
   *
   * @type {string}
   * @memberof AccessTokenResponseCustom
   */
  idToken?: string;
  /**
   *
   * @type {number}
   * @memberof AccessTokenResponseCustom
   */
  notBeforePolicy?: number;
  /**
   *
   * @type {string}
   * @memberof AccessTokenResponseCustom
   */
  sessionState?: string;
  /**
   *
   * @type {string}
   * @memberof AccessTokenResponseCustom
   */
  scope?: string;
}
/**
 *
 * @export
 * @interface AddCustomerToGroupRequest
 */
export interface AddCustomerToGroupRequest {
  /**
   *
   * @type {string}
   * @memberof AddCustomerToGroupRequest
   */
  customerId: string;
  /**
   *
   * @type {string}
   * @memberof AddCustomerToGroupRequest
   */
  groupId: string;
}
/**
 *
 * @export
 * @interface AddCustomersToGroupRequest
 */
export interface AddCustomersToGroupRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof AddCustomersToGroupRequest
   */
  customerId: Array<string>;
  /**
   *
   * @type {string}
   * @memberof AddCustomersToGroupRequest
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof AddCustomersToGroupRequest
   */
  groupId: string;
}
/**
 *
 * @export
 * @interface AddFeeMerchantRequest
 */
export interface AddFeeMerchantRequest {
  /**
   *
   * @type {string}
   * @memberof AddFeeMerchantRequest
   */
  merchantId: string;
  /**
   *
   * @type {string}
   * @memberof AddFeeMerchantRequest
   */
  feeId: string;
  /**
   *
   * @type {string}
   * @memberof AddFeeMerchantRequest
   */
  effectiveDate: string;
}
/**
 *
 * @export
 * @interface AddFeeRequest
 */
export interface AddFeeRequest {
  /**
   *
   * @type {string}
   * @memberof AddFeeRequest
   */
  feeCode: string;
  /**
   *
   * @type {string}
   * @memberof AddFeeRequest
   */
  feeName: string;
  /**
   *
   * @type {number}
   * @memberof AddFeeRequest
   */
  percentVAT?: number;
  /**
   *
   * @type {string}
   * @memberof AddFeeRequest
   */
  businessType?: AddFeeRequestBusinessTypeEnum;
  /**
   *
   * @type {string}
   * @memberof AddFeeRequest
   */
  serviceType: AddFeeRequestServiceTypeEnum;
  /**
   *
   * @type {string}
   * @memberof AddFeeRequest
   */
  feeMethod: AddFeeRequestFeeMethodEnum;
  /**
   *
   * @type {string}
   * @memberof AddFeeRequest
   */
  calculatePer?: AddFeeRequestCalculatePerEnum;
  /**
   *
   * @type {string}
   * @memberof AddFeeRequest
   */
  fixedFeeMethod?: AddFeeRequestFixedFeeMethodEnum;
  /**
   *
   * @type {number}
   * @memberof AddFeeRequest
   */
  amount?: number;
  /**
   *
   * @type {number}
   * @memberof AddFeeRequest
   */
  minimumMoney?: number;
  /**
   *
   * @type {number}
   * @memberof AddFeeRequest
   */
  maxMoney?: number;
  /**
   *
   * @type {Array<BracketFee>}
   * @memberof AddFeeRequest
   */
  bracketFees?: Array<BracketFee>;
}

export const AddFeeRequestBusinessTypeEnum = {
  Personal: "PERSONAL",
  Organization: "ORGANIZATION",
} as const;

export type AddFeeRequestBusinessTypeEnum =
  (typeof AddFeeRequestBusinessTypeEnum)[keyof typeof AddFeeRequestBusinessTypeEnum];
export const AddFeeRequestServiceTypeEnum = {
  Cobo: "COBO",
  Pobo: "POBO",
  CoboInterCard: "COBO_INTER_CARD",
  CoboDebitDomesticCard: "COBO_DEBIT_DOMESTIC_CARD",
  CoboCreditDomesticCard: "COBO_CREDIT_DOMESTIC_CARD",
  PoboInterBank: "POBO_INTER_BANK",
} as const;

export type AddFeeRequestServiceTypeEnum =
  (typeof AddFeeRequestServiceTypeEnum)[keyof typeof AddFeeRequestServiceTypeEnum];
export const AddFeeRequestFeeMethodEnum = {
  Free: "FREE",
  FixedFee: "FIXED_FEE",
  BracketFee: "BRACKET_FEE",
} as const;

export type AddFeeRequestFeeMethodEnum =
  (typeof AddFeeRequestFeeMethodEnum)[keyof typeof AddFeeRequestFeeMethodEnum];
export const AddFeeRequestCalculatePerEnum = {
  PerMonth: "PER_MONTH",
  PerTransaction: "PER_TRANSACTION",
  None: "NONE",
} as const;

export type AddFeeRequestCalculatePerEnum =
  (typeof AddFeeRequestCalculatePerEnum)[keyof typeof AddFeeRequestCalculatePerEnum];
export const AddFeeRequestFixedFeeMethodEnum = {
  FixedFee: "FIXED_FEE",
  RatioFee: "RATIO_FEE",
  None: "NONE",
} as const;

export type AddFeeRequestFixedFeeMethodEnum =
  (typeof AddFeeRequestFixedFeeMethodEnum)[keyof typeof AddFeeRequestFixedFeeMethodEnum];

/**
 *
 * @export
 * @interface AddFeeResponse
 */
export interface AddFeeResponse {
  /**
   *
   * @type {boolean}
   * @memberof AddFeeResponse
   */
  status?: boolean;
  /**
   *
   * @type {FeeDto}
   * @memberof AddFeeResponse
   */
  fee?: FeeDto;
}
/**
 *
 * @export
 * @interface AddForControlPortalRequest
 */
export interface AddForControlPortalRequest {
  /**
   *
   * @type {string}
   * @memberof AddForControlPortalRequest
   */
  transactionId?: string;
  /**
   *
   * @type {string}
   * @memberof AddForControlPortalRequest
   */
  reason?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof AddForControlPortalRequest
   */
  documentUrls?: Array<string>;
}
/**
 *
 * @export
 * @interface AddProductsToCateRequest
 */
export interface AddProductsToCateRequest {
  /**
   *
   * @type {number}
   * @memberof AddProductsToCateRequest
   */
  cateId: number;
  /**
   *
   * @type {string}
   * @memberof AddProductsToCateRequest
   */
  name?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof AddProductsToCateRequest
   */
  prodIds: Array<number>;
}
/**
 *
 * @export
 * @interface AdminChangePasswordRequest
 */
export interface AdminChangePasswordRequest {
  /**
   *
   * @type {string}
   * @memberof AdminChangePasswordRequest
   */
  currentPassword: string;
  /**
   *
   * @type {string}
   * @memberof AdminChangePasswordRequest
   */
  newPassword: string;
}
/**
 *
 * @export
 * @interface AdminDetailResponse
 */
export interface AdminDetailResponse {
  /**
   *
   * @type {string}
   * @memberof AdminDetailResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof AdminDetailResponse
   */
  username?: string;
  /**
   *
   * @type {string}
   * @memberof AdminDetailResponse
   */
  fullName?: string;
  /**
   *
   * @type {string}
   * @memberof AdminDetailResponse
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof AdminDetailResponse
   */
  status?: AdminDetailResponseStatusEnum;
  /**
   *
   * @type {string}
   * @memberof AdminDetailResponse
   */
  phone?: string;
  /**
   *
   * @type {boolean}
   * @memberof AdminDetailResponse
   */
  loggedIn?: boolean;
  /**
   *
   * @type {string}
   * @memberof AdminDetailResponse
   */
  staffId?: string;
  /**
   *
   * @type {string}
   * @memberof AdminDetailResponse
   */
  office?: AdminDetailResponseOfficeEnum;
  /**
   *
   * @type {string}
   * @memberof AdminDetailResponse
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof AdminDetailResponse
   */
  roleGroupName?: string;
  /**
   *
   * @type {number}
   * @memberof AdminDetailResponse
   */
  roleGroupId?: number;
  /**
   *
   * @type {string}
   * @memberof AdminDetailResponse
   */
  createAt?: string;
  /**
   *
   * @type {string}
   * @memberof AdminDetailResponse
   */
  createdBy?: string;
  /**
   *
   * @type {string}
   * @memberof AdminDetailResponse
   */
  modifiedAt?: string;
  /**
   *
   * @type {string}
   * @memberof AdminDetailResponse
   */
  modifiedBy?: string;
  /**
   *
   * @type {string}
   * @memberof AdminDetailResponse
   */
  blockedAt?: string;
  /**
   *
   * @type {string}
   * @memberof AdminDetailResponse
   */
  blockedBy?: string;
  /**
   *
   * @type {string}
   * @memberof AdminDetailResponse
   */
  avatar?: string;
  /**
   *
   * @type {RoleGroupDetailResponse}
   * @memberof AdminDetailResponse
   */
  role?: RoleGroupDetailResponse;
}

export const AdminDetailResponseStatusEnum = {
  Blocked: "BLOCKED",
  Active: "ACTIVE",
  Inactive: "INACTIVE",
  NotLoggedIn: "NOT_LOGGED_IN",
} as const;

export type AdminDetailResponseStatusEnum =
  (typeof AdminDetailResponseStatusEnum)[keyof typeof AdminDetailResponseStatusEnum];
export const AdminDetailResponseOfficeEnum = {
  Headquarter: "HEADQUARTER",
  Branch: "BRANCH",
  TransactionOffice: "TRANSACTION_OFFICE",
} as const;

export type AdminDetailResponseOfficeEnum =
  (typeof AdminDetailResponseOfficeEnum)[keyof typeof AdminDetailResponseOfficeEnum];

/**
 *
 * @export
 * @interface AdminLoginRequest
 */
export interface AdminLoginRequest {
  /**
   *
   * @type {string}
   * @memberof AdminLoginRequest
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof AdminLoginRequest
   */
  password: string;
}
/**
 *
 * @export
 * @interface AdminLogoutRequest
 */
export interface AdminLogoutRequest {
  /**
   *
   * @type {string}
   * @memberof AdminLogoutRequest
   */
  refreshToken: string;
}
/**
 *
 * @export
 * @interface AdminRefreshTokenRequest
 */
export interface AdminRefreshTokenRequest {
  /**
   *
   * @type {string}
   * @memberof AdminRefreshTokenRequest
   */
  refreshToken: string;
}
/**
 *
 * @export
 * @interface AdminResponse
 */
export interface AdminResponse {
  /**
   *
   * @type {string}
   * @memberof AdminResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof AdminResponse
   */
  username?: string;
  /**
   *
   * @type {string}
   * @memberof AdminResponse
   */
  fullName?: string;
  /**
   *
   * @type {string}
   * @memberof AdminResponse
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof AdminResponse
   */
  status?: AdminResponseStatusEnum;
  /**
   *
   * @type {string}
   * @memberof AdminResponse
   */
  phone?: string;
  /**
   *
   * @type {boolean}
   * @memberof AdminResponse
   */
  loggedIn?: boolean;
  /**
   *
   * @type {string}
   * @memberof AdminResponse
   */
  staffId?: string;
  /**
   *
   * @type {string}
   * @memberof AdminResponse
   */
  office?: AdminResponseOfficeEnum;
  /**
   *
   * @type {string}
   * @memberof AdminResponse
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof AdminResponse
   */
  roleGroupName?: string;
  /**
   *
   * @type {number}
   * @memberof AdminResponse
   */
  roleGroupId?: number;
}

export const AdminResponseStatusEnum = {
  Blocked: "BLOCKED",
  Active: "ACTIVE",
  Inactive: "INACTIVE",
  NotLoggedIn: "NOT_LOGGED_IN",
} as const;

export type AdminResponseStatusEnum =
  (typeof AdminResponseStatusEnum)[keyof typeof AdminResponseStatusEnum];
export const AdminResponseOfficeEnum = {
  Headquarter: "HEADQUARTER",
  Branch: "BRANCH",
  TransactionOffice: "TRANSACTION_OFFICE",
} as const;

export type AdminResponseOfficeEnum =
  (typeof AdminResponseOfficeEnum)[keyof typeof AdminResponseOfficeEnum];

/**
 *
 * @export
 * @interface AdministratorDto
 */
export interface AdministratorDto {
  /**
   *
   * @type {string}
   * @memberof AdministratorDto
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof AdministratorDto
   */
  fullName?: string;
}
/**
 *
 * @export
 * @interface AffiliateResponse
 */
export interface AffiliateResponse {
  /**
   *
   * @type {number}
   * @memberof AffiliateResponse
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof AffiliateResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof AffiliateResponse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof AffiliateResponse
   */
  cif?: string;
  /**
   *
   * @type {string}
   * @memberof AffiliateResponse
   */
  department?: string;
}
/**
 *
 * @export
 * @interface AllSystemParamResponse
 */
export interface AllSystemParamResponse {
  /**
   *
   * @type {Array<SystemParamResponse>}
   * @memberof AllSystemParamResponse
   */
  parameters?: Array<SystemParamResponse>;
}
/**
 *
 * @export
 * @interface AppResponse
 */
export interface AppResponse {
  /**
   *
   * @type {string}
   * @memberof AppResponse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof AppResponse
   */
  provider?: string;
  /**
   *
   * @type {string}
   * @memberof AppResponse
   */
  appId?: string;
  /**
   *
   * @type {string}
   * @memberof AppResponse
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof AppResponse
   */
  logo?: string;
  /**
   *
   * @type {string}
   * @memberof AppResponse
   */
  appUrl?: string;
}
/**
 *
 * @export
 * @interface ApplicationContext
 */
export interface ApplicationContext {
  /**
   *
   * @type {ApplicationContext}
   * @memberof ApplicationContext
   */
  parent?: ApplicationContext;
  /**
   *
   * @type {string}
   * @memberof ApplicationContext
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationContext
   */
  displayName?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationContext
   */
  applicationName?: string;
  /**
   *
   * @type {number}
   * @memberof ApplicationContext
   */
  startupDate?: number;
  /**
   *
   * @type {object}
   * @memberof ApplicationContext
   */
  autowireCapableBeanFactory?: object;
  /**
   *
   * @type {Environment}
   * @memberof ApplicationContext
   */
  environment?: Environment;
  /**
   *
   * @type {number}
   * @memberof ApplicationContext
   */
  beanDefinitionCount?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof ApplicationContext
   */
  beanDefinitionNames?: Array<string>;
  /**
   *
   * @type {object}
   * @memberof ApplicationContext
   */
  parentBeanFactory?: object;
  /**
   *
   * @type {ApplicationContextClassLoader}
   * @memberof ApplicationContext
   */
  classLoader?: ApplicationContextClassLoader;
}
/**
 *
 * @export
 * @interface ApplicationContextClassLoader
 */
export interface ApplicationContextClassLoader {
  /**
   *
   * @type {ApplicationContextClassLoaderParent}
   * @memberof ApplicationContextClassLoader
   */
  parent?: ApplicationContextClassLoaderParent;
  /**
   *
   * @type {string}
   * @memberof ApplicationContextClassLoader
   */
  name?: string;
  /**
   *
   * @type {ApplicationContextClassLoaderParentUnnamedModule}
   * @memberof ApplicationContextClassLoader
   */
  unnamedModule?: ApplicationContextClassLoaderParentUnnamedModule;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationContextClassLoader
   */
  defaultAssertionStatus?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationContextClassLoader
   */
  registeredAsParallelCapable?: boolean;
  /**
   *
   * @type {Array<ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackagesInner>}
   * @memberof ApplicationContextClassLoader
   */
  definedPackages?: Array<ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackagesInner>;
}
/**
 *
 * @export
 * @interface ApplicationContextClassLoaderParent
 */
export interface ApplicationContextClassLoaderParent {
  /**
   *
   * @type {string}
   * @memberof ApplicationContextClassLoaderParent
   */
  name?: string;
  /**
   *
   * @type {ApplicationContextClassLoaderParentUnnamedModule}
   * @memberof ApplicationContextClassLoaderParent
   */
  unnamedModule?: ApplicationContextClassLoaderParentUnnamedModule;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationContextClassLoaderParent
   */
  defaultAssertionStatus?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationContextClassLoaderParent
   */
  registeredAsParallelCapable?: boolean;
  /**
   *
   * @type {Array<ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackagesInner>}
   * @memberof ApplicationContextClassLoaderParent
   */
  definedPackages?: Array<ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackagesInner>;
}
/**
 *
 * @export
 * @interface ApplicationContextClassLoaderParentUnnamedModule
 */
export interface ApplicationContextClassLoaderParentUnnamedModule {
  /**
   *
   * @type {object}
   * @memberof ApplicationContextClassLoaderParentUnnamedModule
   */
  layer?: object;
  /**
   *
   * @type {string}
   * @memberof ApplicationContextClassLoaderParentUnnamedModule
   */
  name?: string;
  /**
   *
   * @type {ApplicationContextClassLoaderParentUnnamedModuleDescriptor}
   * @memberof ApplicationContextClassLoaderParentUnnamedModule
   */
  descriptor?: ApplicationContextClassLoaderParentUnnamedModuleDescriptor;
  /**
   *
   * @type {ApplicationContextClassLoaderParentUnnamedModuleClassLoader}
   * @memberof ApplicationContextClassLoaderParentUnnamedModule
   */
  classLoader?: ApplicationContextClassLoaderParentUnnamedModuleClassLoader;
  /**
   *
   * @type {Array<object>}
   * @memberof ApplicationContextClassLoaderParentUnnamedModule
   */
  annotations?: Array<object>;
  /**
   *
   * @type {Array<object>}
   * @memberof ApplicationContextClassLoaderParentUnnamedModule
   */
  declaredAnnotations?: Array<object>;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationContextClassLoaderParentUnnamedModule
   */
  named?: boolean;
  /**
   *
   * @type {Set<string>}
   * @memberof ApplicationContextClassLoaderParentUnnamedModule
   */
  packages?: Set<string>;
}
/**
 *
 * @export
 * @interface ApplicationContextClassLoaderParentUnnamedModuleClassLoader
 */
export interface ApplicationContextClassLoaderParentUnnamedModuleClassLoader {
  /**
   *
   * @type {string}
   * @memberof ApplicationContextClassLoaderParentUnnamedModuleClassLoader
   */
  name?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationContextClassLoaderParentUnnamedModuleClassLoader
   */
  defaultAssertionStatus?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationContextClassLoaderParentUnnamedModuleClassLoader
   */
  registeredAsParallelCapable?: boolean;
  /**
   *
   * @type {Array<ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackagesInner>}
   * @memberof ApplicationContextClassLoaderParentUnnamedModuleClassLoader
   */
  definedPackages?: Array<ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackagesInner>;
}
/**
 *
 * @export
 * @interface ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackagesInner
 */
export interface ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackagesInner {
  /**
   *
   * @type {string}
   * @memberof ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackagesInner
   */
  name?: string;
  /**
   *
   * @type {Array<object>}
   * @memberof ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackagesInner
   */
  annotations?: Array<object>;
  /**
   *
   * @type {Array<object>}
   * @memberof ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackagesInner
   */
  declaredAnnotations?: Array<object>;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackagesInner
   */
  sealed?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackagesInner
   */
  specificationTitle?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackagesInner
   */
  specificationVersion?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackagesInner
   */
  specificationVendor?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackagesInner
   */
  implementationTitle?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackagesInner
   */
  implementationVersion?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackagesInner
   */
  implementationVendor?: string;
}
/**
 *
 * @export
 * @interface ApplicationContextClassLoaderParentUnnamedModuleDescriptor
 */
export interface ApplicationContextClassLoaderParentUnnamedModuleDescriptor {
  /**
   *
   * @type {boolean}
   * @memberof ApplicationContextClassLoaderParentUnnamedModuleDescriptor
   */
  open?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationContextClassLoaderParentUnnamedModuleDescriptor
   */
  automatic?: boolean;
}
/**
 *
 * @export
 * @interface ApproveForControlCmsRequest
 */
export interface ApproveForControlCmsRequest {
  /**
   *
   * @type {number}
   * @memberof ApproveForControlCmsRequest
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ApproveForControlCmsRequest
   */
  note?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApproveForControlCmsRequest
   */
  documentUrls?: Array<string>;
}
/**
 *
 * @export
 * @interface ApproveNapasSessionResponse
 */
export interface ApproveNapasSessionResponse {
  /**
   *
   * @type {boolean}
   * @memberof ApproveNapasSessionResponse
   */
  status?: boolean;
}
/**
 *
 * @export
 * @interface ApproveReconciliationSessionResponse
 */
export interface ApproveReconciliationSessionResponse {
  /**
   *
   * @type {boolean}
   * @memberof ApproveReconciliationSessionResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface ApproveSinglePayOutRequest
 */
export interface ApproveSinglePayOutRequest {
  /**
   *
   * @type {string}
   * @memberof ApproveSinglePayOutRequest
   */
  payOutId: string;
  /**
   *
   * @type {string}
   * @memberof ApproveSinglePayOutRequest
   */
  bankAccountId: string;
}
/**
 *
 * @export
 * @interface AuditVersionResponse
 */
export interface AuditVersionResponse {
  /**
   *
   * @type {number}
   * @memberof AuditVersionResponse
   */
  version?: number;
}
/**
 *
 * @export
 * @interface BankAccountDto
 */
export interface BankAccountDto {
  /**
   *
   * @type {string}
   * @memberof BankAccountDto
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof BankAccountDto
   */
  accountNo?: string;
  /**
   *
   * @type {string}
   * @memberof BankAccountDto
   */
  bankType?: BankAccountDtoBankTypeEnum;
  /**
   *
   * @type {string}
   * @memberof BankAccountDto
   */
  bankName?: string;
  /**
   *
   * @type {string}
   * @memberof BankAccountDto
   */
  accountName?: string;
  /**
   *
   * @type {boolean}
   * @memberof BankAccountDto
   */
  defaultAccount?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BankAccountDto
   */
  defaultFeeAccount?: boolean;
}

export const BankAccountDtoBankTypeEnum = {
  Klb: "KLB",
  Umee: "UMEE",
} as const;

export type BankAccountDtoBankTypeEnum =
  (typeof BankAccountDtoBankTypeEnum)[keyof typeof BankAccountDtoBankTypeEnum];

/**
 *
 * @export
 * @interface BankAccountResponse
 */
export interface BankAccountResponse {
  /**
   *
   * @type {string}
   * @memberof BankAccountResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof BankAccountResponse
   */
  accountNumber?: string;
  /**
   *
   * @type {string}
   * @memberof BankAccountResponse
   */
  accountName?: string;
  /**
   *
   * @type {string}
   * @memberof BankAccountResponse
   */
  bankType?: BankAccountResponseBankTypeEnum;
  /**
   *
   * @type {string}
   * @memberof BankAccountResponse
   */
  qrCode?: string;
}

export const BankAccountResponseBankTypeEnum = {
  Klb: "KLB",
  Umee: "UMEE",
} as const;

export type BankAccountResponseBankTypeEnum =
  (typeof BankAccountResponseBankTypeEnum)[keyof typeof BankAccountResponseBankTypeEnum];

/**
 *
 * @export
 * @interface BankResponse
 */
export interface BankResponse {
  /**
   *
   * @type {number}
   * @memberof BankResponse
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof BankResponse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof BankResponse
   */
  shortName?: string;
  /**
   *
   * @type {string}
   * @memberof BankResponse
   */
  commonName?: string;
  /**
   *
   * @type {string}
   * @memberof BankResponse
   */
  citad?: string;
  /**
   *
   * @type {boolean}
   * @memberof BankResponse
   */
  napasSupported?: boolean;
  /**
   *
   * @type {string}
   * @memberof BankResponse
   */
  napasCode?: string;
  /**
   *
   * @type {string}
   * @memberof BankResponse
   */
  benId?: string;
  /**
   *
   * @type {string}
   * @memberof BankResponse
   */
  swift?: string;
  /**
   *
   * @type {boolean}
   * @memberof BankResponse
   */
  vietQrSupported?: boolean;
  /**
   *
   * @type {string}
   * @memberof BankResponse
   */
  logo?: string;
  /**
   *
   * @type {string}
   * @memberof BankResponse
   */
  icon?: string;
  /**
   *
   * @type {string}
   * @memberof BankResponse
   */
  code?: string;
}
/**
 *
 * @export
 * @interface BatchPayOutDetailResponse
 */
export interface BatchPayOutDetailResponse {
  /**
   *
   * @type {string}
   * @memberof BatchPayOutDetailResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof BatchPayOutDetailResponse
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof BatchPayOutDetailResponse
   */
  fee?: number;
  /**
   *
   * @type {number}
   * @memberof BatchPayOutDetailResponse
   */
  totalAmount?: number;
  /**
   *
   * @type {number}
   * @memberof BatchPayOutDetailResponse
   */
  totalReceiver?: number;
  /**
   *
   * @type {string}
   * @memberof BatchPayOutDetailResponse
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof BatchPayOutDetailResponse
   */
  createdBy?: string;
  /**
   *
   * @type {string}
   * @memberof BatchPayOutDetailResponse
   */
  modifiedAt?: string;
  /**
   *
   * @type {string}
   * @memberof BatchPayOutDetailResponse
   */
  modifiedBy?: string;
  /**
   *
   * @type {string}
   * @memberof BatchPayOutDetailResponse
   */
  status?: BatchPayOutDetailResponseStatusEnum;
  /**
   *
   * @type {Array<SinglePayOutResponse>}
   * @memberof BatchPayOutDetailResponse
   */
  receivers?: Array<SinglePayOutResponse>;
}

export const BatchPayOutDetailResponseStatusEnum = {
  Waiting: "WAITING",
  Success: "SUCCESS",
  Failed: "FAILED",
  Transferring: "TRANSFERRING",
  Denied: "DENIED",
  Completed: "COMPLETED",
} as const;

export type BatchPayOutDetailResponseStatusEnum =
  (typeof BatchPayOutDetailResponseStatusEnum)[keyof typeof BatchPayOutDetailResponseStatusEnum];

/**
 *
 * @export
 * @interface BatchPayOutReceiverRequest
 */
export interface BatchPayOutReceiverRequest {
  /**
   *
   * @type {string}
   * @memberof BatchPayOutReceiverRequest
   */
  bin: string;
  /**
   *
   * @type {string}
   * @memberof BatchPayOutReceiverRequest
   */
  accountNo: string;
  /**
   *
   * @type {number}
   * @memberof BatchPayOutReceiverRequest
   */
  amount: number;
  /**
   *
   * @type {string}
   * @memberof BatchPayOutReceiverRequest
   */
  content: string;
}
/**
 *
 * @export
 * @interface BatchPayOutResponse
 */
export interface BatchPayOutResponse {
  /**
   *
   * @type {string}
   * @memberof BatchPayOutResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof BatchPayOutResponse
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof BatchPayOutResponse
   */
  fee?: number;
  /**
   *
   * @type {number}
   * @memberof BatchPayOutResponse
   */
  totalAmount?: number;
  /**
   *
   * @type {number}
   * @memberof BatchPayOutResponse
   */
  totalReceiver?: number;
  /**
   *
   * @type {string}
   * @memberof BatchPayOutResponse
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof BatchPayOutResponse
   */
  createdBy?: string;
  /**
   *
   * @type {string}
   * @memberof BatchPayOutResponse
   */
  modifiedAt?: string;
  /**
   *
   * @type {string}
   * @memberof BatchPayOutResponse
   */
  modifiedBy?: string;
  /**
   *
   * @type {string}
   * @memberof BatchPayOutResponse
   */
  status?: BatchPayOutResponseStatusEnum;
}

export const BatchPayOutResponseStatusEnum = {
  Waiting: "WAITING",
  Success: "SUCCESS",
  Failed: "FAILED",
  Transferring: "TRANSFERRING",
  Denied: "DENIED",
  Completed: "COMPLETED",
} as const;

export type BatchPayOutResponseStatusEnum =
  (typeof BatchPayOutResponseStatusEnum)[keyof typeof BatchPayOutResponseStatusEnum];

/**
 *
 * @export
 * @interface BracketFee
 */
export interface BracketFee {
  /**
   *
   * @type {number}
   * @memberof BracketFee
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof BracketFee
   */
  feeValue?: BracketFeeFeeValueEnum;
  /**
   *
   * @type {number}
   * @memberof BracketFee
   */
  valueFrom?: number;
  /**
   *
   * @type {number}
   * @memberof BracketFee
   */
  valueTo?: number;
  /**
   *
   * @type {number}
   * @memberof BracketFee
   */
  money?: number;
  /**
   *
   * @type {number}
   * @memberof BracketFee
   */
  amount?: number;
  /**
   *
   * @type {number}
   * @memberof BracketFee
   */
  chargeMin?: number;
}

export const BracketFeeFeeValueEnum = {
  TransactionValue: "TRANSACTION_VALUE",
} as const;

export type BracketFeeFeeValueEnum =
  (typeof BracketFeeFeeValueEnum)[keyof typeof BracketFeeFeeValueEnum];

/**
 *
 * @export
 * @interface BusinessOwner
 */
export interface BusinessOwner {
  /**
   *
   * @type {number}
   * @memberof BusinessOwner
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof BusinessOwner
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof BusinessOwner
   */
  identifyNumber?: string;
  /**
   *
   * @type {number}
   * @memberof BusinessOwner
   */
  funPercent?: number;
  /**
   *
   * @type {string}
   * @memberof BusinessOwner
   */
  position?: string;
}
/**
 *
 * @export
 * @interface CancelPaySubscriptionRequest
 */
export interface CancelPaySubscriptionRequest {
  /**
   *
   * @type {string}
   * @memberof CancelPaySubscriptionRequest
   */
  cancelType: CancelPaySubscriptionRequestCancelTypeEnum;
}

export const CancelPaySubscriptionRequestCancelTypeEnum = {
  ExpirationCancel: "EXPIRATION_CANCEL",
  ImmediateCancel: "IMMEDIATE_CANCEL",
} as const;

export type CancelPaySubscriptionRequestCancelTypeEnum =
  (typeof CancelPaySubscriptionRequestCancelTypeEnum)[keyof typeof CancelPaySubscriptionRequestCancelTypeEnum];

/**
 *
 * @export
 * @interface CancelTransactionRequest
 */
export interface CancelTransactionRequest {
  /**
   *
   * @type {string}
   * @memberof CancelTransactionRequest
   */
  transactionId: string;
}
/**
 *
 * @export
 * @interface CancelTransactionResponse
 */
export interface CancelTransactionResponse {
  /**
   *
   * @type {boolean}
   * @memberof CancelTransactionResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface CategoryResponse
 */
export interface CategoryResponse {
  /**
   *
   * @type {number}
   * @memberof CategoryResponse
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CategoryResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof CategoryResponse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof CategoryResponse
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof CategoryResponse
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof CategoryResponse
   */
  createdBy?: string;
  /**
   *
   * @type {string}
   * @memberof CategoryResponse
   */
  modifiedAt?: string;
  /**
   *
   * @type {string}
   * @memberof CategoryResponse
   */
  modifiedBy?: string;
  /**
   *
   * @type {number}
   * @memberof CategoryResponse
   */
  productCount?: number;
}
/**
 *
 * @export
 * @interface ChangePasswordRequest
 */
export interface ChangePasswordRequest {
  /**
   *
   * @type {string}
   * @memberof ChangePasswordRequest
   */
  sessionId: string;
  /**
   *
   * @type {string}
   * @memberof ChangePasswordRequest
   */
  token: string;
  /**
   *
   * @type {string}
   * @memberof ChangePasswordRequest
   */
  password: string;
}
/**
 *
 * @export
 * @interface ChangePayPageTitleRequest
 */
export interface ChangePayPageTitleRequest {
  /**
   *
   * @type {string}
   * @memberof ChangePayPageTitleRequest
   */
  name?: string;
}
/**
 *
 * @export
 * @interface ChangeProductStatusRequest
 */
export interface ChangeProductStatusRequest {
  /**
   *
   * @type {string}
   * @memberof ChangeProductStatusRequest
   */
  status?: ChangeProductStatusRequestStatusEnum;
}

export const ChangeProductStatusRequestStatusEnum = {
  Active: "ACTIVE",
  Inactive: "INACTIVE",
} as const;

export type ChangeProductStatusRequestStatusEnum =
  (typeof ChangeProductStatusRequestStatusEnum)[keyof typeof ChangeProductStatusRequestStatusEnum];

/**
 *
 * @export
 * @interface CheckAccountNoRequest
 */
export interface CheckAccountNoRequest {
  /**
   *
   * @type {string}
   * @memberof CheckAccountNoRequest
   */
  accountNo: string;
}
/**
 *
 * @export
 * @interface CheckAccountNoResponse
 */
export interface CheckAccountNoResponse {
  /**
   *
   * @type {string}
   * @memberof CheckAccountNoResponse
   */
  accountNo?: string;
  /**
   *
   * @type {string}
   * @memberof CheckAccountNoResponse
   */
  accountName?: string;
}
/**
 *
 * @export
 * @interface CheckBankAccountRequest
 */
export interface CheckBankAccountRequest {
  /**
   *
   * @type {string}
   * @memberof CheckBankAccountRequest
   */
  idCardNo: string;
  /**
   *
   * @type {string}
   * @memberof CheckBankAccountRequest
   */
  branch: CheckBankAccountRequestBranchEnum;
  /**
   *
   * @type {string}
   * @memberof CheckBankAccountRequest
   */
  accountNo: string;
}

export const CheckBankAccountRequestBranchEnum = {
  Klb: "KLB",
  Umee: "UMEE",
} as const;

export type CheckBankAccountRequestBranchEnum =
  (typeof CheckBankAccountRequestBranchEnum)[keyof typeof CheckBankAccountRequestBranchEnum];

/**
 *
 * @export
 * @interface CheckBankAccountResponse
 */
export interface CheckBankAccountResponse {
  /**
   *
   * @type {string}
   * @memberof CheckBankAccountResponse
   */
  branch?: CheckBankAccountResponseBranchEnum;
  /**
   *
   * @type {string}
   * @memberof CheckBankAccountResponse
   */
  accountNo?: string;
  /**
   *
   * @type {string}
   * @memberof CheckBankAccountResponse
   */
  accountName?: string;
}

export const CheckBankAccountResponseBranchEnum = {
  Klb: "KLB",
  Umee: "UMEE",
} as const;

export type CheckBankAccountResponseBranchEnum =
  (typeof CheckBankAccountResponseBranchEnum)[keyof typeof CheckBankAccountResponseBranchEnum];

/**
 *
 * @export
 * @interface CheckBankExistRequest
 */
export interface CheckBankExistRequest {
  /**
   *
   * @type {string}
   * @memberof CheckBankExistRequest
   */
  key: string;
  /**
   *
   * @type {string}
   * @memberof CheckBankExistRequest
   */
  value: string;
}
/**
 *
 * @export
 * @interface CheckBankExistResponse
 */
export interface CheckBankExistResponse {
  /**
   *
   * @type {boolean}
   * @memberof CheckBankExistResponse
   */
  existed?: boolean;
}
/**
 *
 * @export
 * @interface CheckPasswordRequest
 */
export interface CheckPasswordRequest {
  /**
   *
   * @type {string}
   * @memberof CheckPasswordRequest
   */
  password: string;
}
/**
 *
 * @export
 * @interface CheckPasswordResponse
 */
export interface CheckPasswordResponse {
  /**
   *
   * @type {string}
   * @memberof CheckPasswordResponse
   */
  sessionId?: string;
  /**
   *
   * @type {string}
   * @memberof CheckPasswordResponse
   */
  token?: string;
  /**
   *
   * @type {number}
   * @memberof CheckPasswordResponse
   */
  timeout?: number;
}
/**
 *
 * @export
 * @interface CheckProductResponse
 */
export interface CheckProductResponse {
  /**
   *
   * @type {boolean}
   * @memberof CheckProductResponse
   */
  usedByPaypage?: boolean;
}
/**
 *
 * @export
 * @interface CheckStaffIdResponse
 */
export interface CheckStaffIdResponse {
  /**
   *
   * @type {boolean}
   * @memberof CheckStaffIdResponse
   */
  exists?: boolean;
}
/**
 *
 * @export
 * @interface ClientLoginRequest
 */
export interface ClientLoginRequest {
  /**
   *
   * @type {string}
   * @memberof ClientLoginRequest
   */
  clientId: string;
  /**
   *
   * @type {string}
   * @memberof ClientLoginRequest
   */
  secretKey: string;
}
/**
 *
 * @export
 * @interface CmsApproveWebhookUrlRequest
 */
export interface CmsApproveWebhookUrlRequest {
  /**
   *
   * @type {string}
   * @memberof CmsApproveWebhookUrlRequest
   */
  merchantId: string;
  /**
   *
   * @type {string}
   * @memberof CmsApproveWebhookUrlRequest
   */
  status: CmsApproveWebhookUrlRequestStatusEnum;
}

export const CmsApproveWebhookUrlRequestStatusEnum = {
  Pending: "PENDING",
  Approved: "APPROVED",
  Rejected: "REJECTED",
} as const;

export type CmsApproveWebhookUrlRequestStatusEnum =
  (typeof CmsApproveWebhookUrlRequestStatusEnum)[keyof typeof CmsApproveWebhookUrlRequestStatusEnum];

/**
 *
 * @export
 * @interface CmsApproveWebhookUrlV2Request
 */
export interface CmsApproveWebhookUrlV2Request {
  /**
   *
   * @type {string}
   * @memberof CmsApproveWebhookUrlV2Request
   */
  merchantId: string;
}
/**
 *
 * @export
 * @interface CmsCreateAffiliateRequest
 */
export interface CmsCreateAffiliateRequest {
  /**
   *
   * @type {string}
   * @memberof CmsCreateAffiliateRequest
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof CmsCreateAffiliateRequest
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof CmsCreateAffiliateRequest
   */
  cif?: string;
  /**
   *
   * @type {string}
   * @memberof CmsCreateAffiliateRequest
   */
  department?: string;
}
/**
 *
 * @export
 * @interface CmsDisapproveMerchantRequest
 */
export interface CmsDisapproveMerchantRequest {
  /**
   *
   * @type {string}
   * @memberof CmsDisapproveMerchantRequest
   */
  reason: string;
}
/**
 *
 * @export
 * @interface CmsDisapproveWebhookUrlV2Request
 */
export interface CmsDisapproveWebhookUrlV2Request {
  /**
   *
   * @type {string}
   * @memberof CmsDisapproveWebhookUrlV2Request
   */
  merchantId: string;
  /**
   *
   * @type {string}
   * @memberof CmsDisapproveWebhookUrlV2Request
   */
  reason: string;
}
/**
 *
 * @export
 * @interface CmsEkycReconciliationFromBankResponse
 */
export interface CmsEkycReconciliationFromBankResponse {
  /**
   *
   * @type {ProfileReconciliation}
   * @memberof CmsEkycReconciliationFromBankResponse
   */
  ekycprofile?: ProfileReconciliation;
  /**
   *
   * @type {ProfileReconciliation}
   * @memberof CmsEkycReconciliationFromBankResponse
   */
  bankProfile?: ProfileReconciliation;
  /**
   *
   * @type {ValidateProfile}
   * @memberof CmsEkycReconciliationFromBankResponse
   */
  validate?: ValidateProfile;
  /**
   *
   * @type {number}
   * @memberof CmsEkycReconciliationFromBankResponse
   */
  percentConfidence?: number;
}
/**
 *
 * @export
 * @interface CmsListOrderResponse
 */
export interface CmsListOrderResponse {
  /**
   *
   * @type {string}
   * @memberof CmsListOrderResponse
   */
  orderId?: string;
  /**
   *
   * @type {number}
   * @memberof CmsListOrderResponse
   */
  total?: number;
  /**
   *
   * @type {string}
   * @memberof CmsListOrderResponse
   */
  createdAt?: string;
  /**
   *
   * @type {Array<PosOrderItemDto>}
   * @memberof CmsListOrderResponse
   */
  items?: Array<PosOrderItemDto>;
  /**
   *
   * @type {string}
   * @memberof CmsListOrderResponse
   */
  posOrderStatus?: CmsListOrderResponsePosOrderStatusEnum;
  /**
   *
   * @type {string}
   * @memberof CmsListOrderResponse
   */
  merchantId?: string;
  /**
   *
   * @type {string}
   * @memberof CmsListOrderResponse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof CmsListOrderResponse
   */
  address?: string;
}

export const CmsListOrderResponsePosOrderStatusEnum = {
  Delivering: "DELIVERING",
  Delivered: "DELIVERED",
  Ordered: "ORDERED",
  WaitingApproved: "WAITING_APPROVED",
  Approved: "APPROVED",
  Cancelled: "CANCELLED",
} as const;

export type CmsListOrderResponsePosOrderStatusEnum =
  (typeof CmsListOrderResponsePosOrderStatusEnum)[keyof typeof CmsListOrderResponsePosOrderStatusEnum];

/**
 *
 * @export
 * @interface CmsOrderDetailResponse
 */
export interface CmsOrderDetailResponse {
  /**
   *
   * @type {string}
   * @memberof CmsOrderDetailResponse
   */
  transactionCode?: string;
  /**
   *
   * @type {string}
   * @memberof CmsOrderDetailResponse
   */
  completeTime?: string;
  /**
   *
   * @type {number}
   * @memberof CmsOrderDetailResponse
   */
  total?: number;
  /**
   *
   * @type {string}
   * @memberof CmsOrderDetailResponse
   */
  paymentType?: CmsOrderDetailResponsePaymentTypeEnum;
  /**
   *
   * @type {string}
   * @memberof CmsOrderDetailResponse
   */
  posOrderStatus?: CmsOrderDetailResponsePosOrderStatusEnum;
  /**
   *
   * @type {Array<PosOrderItemDto>}
   * @memberof CmsOrderDetailResponse
   */
  items?: Array<PosOrderItemDto>;
}

export const CmsOrderDetailResponsePaymentTypeEnum = {
  VietQr: "VIET_QR",
  AtmCard: "ATM_CARD",
  Banking: "BANKING",
} as const;

export type CmsOrderDetailResponsePaymentTypeEnum =
  (typeof CmsOrderDetailResponsePaymentTypeEnum)[keyof typeof CmsOrderDetailResponsePaymentTypeEnum];
export const CmsOrderDetailResponsePosOrderStatusEnum = {
  Delivering: "DELIVERING",
  Delivered: "DELIVERED",
  Ordered: "ORDERED",
  WaitingApproved: "WAITING_APPROVED",
  Approved: "APPROVED",
  Cancelled: "CANCELLED",
} as const;

export type CmsOrderDetailResponsePosOrderStatusEnum =
  (typeof CmsOrderDetailResponsePosOrderStatusEnum)[keyof typeof CmsOrderDetailResponsePosOrderStatusEnum];

/**
 *
 * @export
 * @interface CmsSetStatePosOrderRequest
 */
export interface CmsSetStatePosOrderRequest {
  /**
   *
   * @type {string}
   * @memberof CmsSetStatePosOrderRequest
   */
  posOrderId: string;
  /**
   *
   * @type {string}
   * @memberof CmsSetStatePosOrderRequest
   */
  posOrderStatus: CmsSetStatePosOrderRequestPosOrderStatusEnum;
  /**
   *
   * @type {string}
   * @memberof CmsSetStatePosOrderRequest
   */
  appendNote?: string;
}

export const CmsSetStatePosOrderRequestPosOrderStatusEnum = {
  Delivering: "DELIVERING",
  Delivered: "DELIVERED",
  Ordered: "ORDERED",
  WaitingApproved: "WAITING_APPROVED",
  Approved: "APPROVED",
  Cancelled: "CANCELLED",
} as const;

export type CmsSetStatePosOrderRequestPosOrderStatusEnum =
  (typeof CmsSetStatePosOrderRequestPosOrderStatusEnum)[keyof typeof CmsSetStatePosOrderRequestPosOrderStatusEnum];

/**
 *
 * @export
 * @interface CmsUpdateAffiliateRequest
 */
export interface CmsUpdateAffiliateRequest {
  /**
   *
   * @type {number}
   * @memberof CmsUpdateAffiliateRequest
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof CmsUpdateAffiliateRequest
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof CmsUpdateAffiliateRequest
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof CmsUpdateAffiliateRequest
   */
  cif?: string;
  /**
   *
   * @type {string}
   * @memberof CmsUpdateAffiliateRequest
   */
  department?: string;
}
/**
 *
 * @export
 * @interface CmsUpdateCoboPoboContractRequest
 */
export interface CmsUpdateCoboPoboContractRequest {
  /**
   *
   * @type {string}
   * @memberof CmsUpdateCoboPoboContractRequest
   */
  merchantId: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CmsUpdateCoboPoboContractRequest
   */
  coboPoboContract: Array<string>;
}
/**
 *
 * @export
 * @interface CmsVietQrProNextStateRequest
 */
export interface CmsVietQrProNextStateRequest {
  /**
   *
   * @type {string}
   * @memberof CmsVietQrProNextStateRequest
   */
  action: CmsVietQrProNextStateRequestActionEnum;
  /**
   *
   * @type {string}
   * @memberof CmsVietQrProNextStateRequest
   */
  reason?: string;
}

export const CmsVietQrProNextStateRequestActionEnum = {
  Approve: "APPROVE",
  Reject: "REJECT",
} as const;

export type CmsVietQrProNextStateRequestActionEnum =
  (typeof CmsVietQrProNextStateRequestActionEnum)[keyof typeof CmsVietQrProNextStateRequestActionEnum];

/**
 *
 * @export
 * @interface CmsVietQrProResponse
 */
export interface CmsVietQrProResponse {
  /**
   *
   * @type {string}
   * @memberof CmsVietQrProResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CmsVietQrProResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof CmsVietQrProResponse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof CmsVietQrProResponse
   */
  requestTime?: string;
  /**
   *
   * @type {string}
   * @memberof CmsVietQrProResponse
   */
  status?: CmsVietQrProResponseStatusEnum;
}

export const CmsVietQrProResponseStatusEnum = {
  Inactive: "INACTIVE",
  Pending: "PENDING",
  Approved: "APPROVED",
  Lock: "LOCK",
  Rejected: "REJECTED",
} as const;

export type CmsVietQrProResponseStatusEnum =
  (typeof CmsVietQrProResponseStatusEnum)[keyof typeof CmsVietQrProResponseStatusEnum];

/**
 *
 * @export
 * @interface CmsWebhookUrlResponse
 */
export interface CmsWebhookUrlResponse {
  /**
   *
   * @type {string}
   * @memberof CmsWebhookUrlResponse
   */
  merchantId?: string;
  /**
   *
   * @type {string}
   * @memberof CmsWebhookUrlResponse
   */
  merchantCode?: string;
  /**
   *
   * @type {string}
   * @memberof CmsWebhookUrlResponse
   */
  merchantName?: string;
  /**
   *
   * @type {string}
   * @memberof CmsWebhookUrlResponse
   */
  webhookUrl?: string;
  /**
   *
   * @type {string}
   * @memberof CmsWebhookUrlResponse
   */
  modifyTime?: string;
  /**
   *
   * @type {string}
   * @memberof CmsWebhookUrlResponse
   */
  reliabilityRating?: string;
  /**
   *
   * @type {string}
   * @memberof CmsWebhookUrlResponse
   */
  status?: CmsWebhookUrlResponseStatusEnum;
}

export const CmsWebhookUrlResponseStatusEnum = {
  Pending: "PENDING",
  Approved: "APPROVED",
  Rejected: "REJECTED",
} as const;

export type CmsWebhookUrlResponseStatusEnum =
  (typeof CmsWebhookUrlResponseStatusEnum)[keyof typeof CmsWebhookUrlResponseStatusEnum];

/**
 *
 * @export
 * @interface CreateAdminRequest
 */
export interface CreateAdminRequest {
  /**
   *
   * @type {string}
   * @memberof CreateAdminRequest
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof CreateAdminRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof CreateAdminRequest
   */
  fullName: string;
  /**
   *
   * @type {string}
   * @memberof CreateAdminRequest
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof CreateAdminRequest
   */
  staffId?: string;
  /**
   *
   * @type {string}
   * @memberof CreateAdminRequest
   */
  office: CreateAdminRequestOfficeEnum;
  /**
   *
   * @type {string}
   * @memberof CreateAdminRequest
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof CreateAdminRequest
   */
  roleGroupId: number;
}

export const CreateAdminRequestOfficeEnum = {
  Headquarter: "HEADQUARTER",
  Branch: "BRANCH",
  TransactionOffice: "TRANSACTION_OFFICE",
} as const;

export type CreateAdminRequestOfficeEnum =
  (typeof CreateAdminRequestOfficeEnum)[keyof typeof CreateAdminRequestOfficeEnum];

/**
 *
 * @export
 * @interface CreateBankRequest
 */
export interface CreateBankRequest {
  /**
   *
   * @type {string}
   * @memberof CreateBankRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CreateBankRequest
   */
  shortName: string;
  /**
   *
   * @type {string}
   * @memberof CreateBankRequest
   */
  commonName: string;
  /**
   *
   * @type {string}
   * @memberof CreateBankRequest
   */
  citad: string;
  /**
   *
   * @type {boolean}
   * @memberof CreateBankRequest
   */
  napasSupported: boolean;
  /**
   *
   * @type {string}
   * @memberof CreateBankRequest
   */
  napasCode?: string;
  /**
   *
   * @type {string}
   * @memberof CreateBankRequest
   */
  benId?: string;
  /**
   *
   * @type {string}
   * @memberof CreateBankRequest
   */
  swift?: string;
  /**
   *
   * @type {boolean}
   * @memberof CreateBankRequest
   */
  vietQrSupported: boolean;
  /**
   *
   * @type {string}
   * @memberof CreateBankRequest
   */
  logo?: string;
}
/**
 *
 * @export
 * @interface CreateBatchPayOutRequest
 */
export interface CreateBatchPayOutRequest {
  /**
   *
   * @type {string}
   * @memberof CreateBatchPayOutRequest
   */
  name: string;
  /**
   *
   * @type {Array<BatchPayOutReceiverRequest>}
   * @memberof CreateBatchPayOutRequest
   */
  receiverInfos: Array<BatchPayOutReceiverRequest>;
}
/**
 *
 * @export
 * @interface CreateCategoryRequest
 */
export interface CreateCategoryRequest {
  /**
   *
   * @type {string}
   * @memberof CreateCategoryRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CreateCategoryRequest
   */
  description?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof CreateCategoryRequest
   */
  products?: Array<number>;
}
/**
 *
 * @export
 * @interface CreateCustomerGroupRequest
 */
export interface CreateCustomerGroupRequest {
  /**
   *
   * @type {string}
   * @memberof CreateCustomerGroupRequest
   */
  name: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CreateCustomerGroupRequest
   */
  customerIds?: Array<string>;
}
/**
 *
 * @export
 * @interface CreateCustomerRequest
 */
export interface CreateCustomerRequest {
  /**
   *
   * @type {string}
   * @memberof CreateCustomerRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CreateCustomerRequest
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof CreateCustomerRequest
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof CreateCustomerRequest
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof CreateCustomerRequest
   */
  groupId?: string;
}
/**
 *
 * @export
 * @interface CreateFAQRequest
 */
export interface CreateFAQRequest {
  /**
   *
   * @type {string}
   * @memberof CreateFAQRequest
   */
  question: string;
  /**
   *
   * @type {string}
   * @memberof CreateFAQRequest
   */
  answer: string;
}
/**
 *
 * @export
 * @interface CreatePayButtonRequest
 */
export interface CreatePayButtonRequest {
  /**
   *
   * @type {number}
   * @memberof CreatePayButtonRequest
   */
  payPageId: number;
  /**
   *
   * @type {string}
   * @memberof CreatePayButtonRequest
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof CreatePayButtonRequest
   */
  label: string;
  /**
   *
   * @type {string}
   * @memberof CreatePayButtonRequest
   */
  theme: CreatePayButtonRequestThemeEnum;
}

export const CreatePayButtonRequestThemeEnum = {
  Light: "LIGHT",
  Dark: "DARK",
} as const;

export type CreatePayButtonRequestThemeEnum =
  (typeof CreatePayButtonRequestThemeEnum)[keyof typeof CreatePayButtonRequestThemeEnum];

/**
 *
 * @export
 * @interface CreatePayLinkInternalRequest
 */
export interface CreatePayLinkInternalRequest {
  /**
   *
   * @type {string}
   * @memberof CreatePayLinkInternalRequest
   */
  subjectId?: string;
  /**
   *
   * @type {number}
   * @memberof CreatePayLinkInternalRequest
   */
  amount: number;
  /**
   *
   * @type {string}
   * @memberof CreatePayLinkInternalRequest
   */
  refTransactionId?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePayLinkInternalRequest
   */
  bankAccountId?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePayLinkInternalRequest
   */
  expireType?: CreatePayLinkInternalRequestExpireTypeEnum;
  /**
   *
   * @type {string}
   * @memberof CreatePayLinkInternalRequest
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof CreatePayLinkInternalRequest
   */
  fileUrl?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePayLinkInternalRequest
   */
  customerInfoType: CreatePayLinkInternalRequestCustomerInfoTypeEnum;
  /**
   *
   * @type {CustomerInfoForm}
   * @memberof CreatePayLinkInternalRequest
   */
  customerInfoForm?: CustomerInfoForm;
  /**
   *
   * @type {string}
   * @memberof CreatePayLinkInternalRequest
   */
  successUrl?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePayLinkInternalRequest
   */
  failUrl?: string;
  /**
   *
   * @type {number}
   * @memberof CreatePayLinkInternalRequest
   */
  redirectAfter?: number;
  /**
   *
   * @type {string}
   * @memberof CreatePayLinkInternalRequest
   */
  contactPhone?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePayLinkInternalRequest
   */
  contactEmail?: string;
}

export const CreatePayLinkInternalRequestExpireTypeEnum = {
  Minute10: "MINUTE_10",
  Hour1: "HOUR_1",
  Hour12: "HOUR_12",
  Day1: "DAY_1",
  Day7: "DAY_7",
  Day30: "DAY_30",
  NoExpire: "NO_EXPIRE",
  Custom: "CUSTOM",
  Second10: "SECOND_10",
} as const;

export type CreatePayLinkInternalRequestExpireTypeEnum =
  (typeof CreatePayLinkInternalRequestExpireTypeEnum)[keyof typeof CreatePayLinkInternalRequestExpireTypeEnum];
export const CreatePayLinkInternalRequestCustomerInfoTypeEnum = {
  NoRequire: "NO_REQUIRE",
  AutoFill: "AUTO_FILL",
  RequireInput: "REQUIRE_INPUT",
  PartnerFill: "PARTNER_FILL",
} as const;

export type CreatePayLinkInternalRequestCustomerInfoTypeEnum =
  (typeof CreatePayLinkInternalRequestCustomerInfoTypeEnum)[keyof typeof CreatePayLinkInternalRequestCustomerInfoTypeEnum];

/**
 *
 * @export
 * @interface CreatePayLinkRequest
 */
export interface CreatePayLinkRequest {
  /**
   *
   * @type {number}
   * @memberof CreatePayLinkRequest
   */
  amount: number;
  /**
   *
   * @type {string}
   * @memberof CreatePayLinkRequest
   */
  refTransactionId?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePayLinkRequest
   */
  bankAccountId?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePayLinkRequest
   */
  expireType?: CreatePayLinkRequestExpireTypeEnum;
  /**
   *
   * @type {string}
   * @memberof CreatePayLinkRequest
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof CreatePayLinkRequest
   */
  fileUrl?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePayLinkRequest
   */
  customerInfoType: CreatePayLinkRequestCustomerInfoTypeEnum;
  /**
   *
   * @type {CustomerInfoForm}
   * @memberof CreatePayLinkRequest
   */
  customerInfoForm?: CustomerInfoForm;
  /**
   *
   * @type {string}
   * @memberof CreatePayLinkRequest
   */
  successUrl?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePayLinkRequest
   */
  failUrl?: string;
  /**
   *
   * @type {number}
   * @memberof CreatePayLinkRequest
   */
  redirectAfter?: number;
  /**
   *
   * @type {string}
   * @memberof CreatePayLinkRequest
   */
  contactPhone?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePayLinkRequest
   */
  contactEmail?: string;
}

export const CreatePayLinkRequestExpireTypeEnum = {
  Minute10: "MINUTE_10",
  Hour1: "HOUR_1",
  Hour12: "HOUR_12",
  Day1: "DAY_1",
  Day7: "DAY_7",
  Day30: "DAY_30",
  NoExpire: "NO_EXPIRE",
  Custom: "CUSTOM",
  Second10: "SECOND_10",
} as const;

export type CreatePayLinkRequestExpireTypeEnum =
  (typeof CreatePayLinkRequestExpireTypeEnum)[keyof typeof CreatePayLinkRequestExpireTypeEnum];
export const CreatePayLinkRequestCustomerInfoTypeEnum = {
  NoRequire: "NO_REQUIRE",
  AutoFill: "AUTO_FILL",
  RequireInput: "REQUIRE_INPUT",
  PartnerFill: "PARTNER_FILL",
} as const;

export type CreatePayLinkRequestCustomerInfoTypeEnum =
  (typeof CreatePayLinkRequestCustomerInfoTypeEnum)[keyof typeof CreatePayLinkRequestCustomerInfoTypeEnum];

/**
 *
 * @export
 * @interface CreatePayPageRequest
 */
export interface CreatePayPageRequest {
  /**
   *
   * @type {string}
   * @memberof CreatePayPageRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CreatePayPageRequest
   */
  bankAccountId: string;
  /**
   *
   * @type {Array<number>}
   * @memberof CreatePayPageRequest
   */
  productIds: Array<number>;
  /**
   *
   * @type {string}
   * @memberof CreatePayPageRequest
   */
  contactEmail?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePayPageRequest
   */
  contactPhone?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePayPageRequest
   */
  periodType?: CreatePayPageRequestPeriodTypeEnum;
  /**
   *
   * @type {string}
   * @memberof CreatePayPageRequest
   */
  customerInfoType: CreatePayPageRequestCustomerInfoTypeEnum;
  /**
   *
   * @type {CustomerInfoForm}
   * @memberof CreatePayPageRequest
   */
  customerInfoForm?: CustomerInfoForm;
}

export const CreatePayPageRequestPeriodTypeEnum = {
  Daily: "DAILY",
  Weekly: "WEEKLY",
  Monthly: "MONTHLY",
  ThreeMonths: "THREE_MONTHS",
  SixMonths: "SIX_MONTHS",
  Yearly: "YEARLY",
} as const;

export type CreatePayPageRequestPeriodTypeEnum =
  (typeof CreatePayPageRequestPeriodTypeEnum)[keyof typeof CreatePayPageRequestPeriodTypeEnum];
export const CreatePayPageRequestCustomerInfoTypeEnum = {
  NoRequire: "NO_REQUIRE",
  AutoFill: "AUTO_FILL",
  RequireInput: "REQUIRE_INPUT",
  PartnerFill: "PARTNER_FILL",
} as const;

export type CreatePayPageRequestCustomerInfoTypeEnum =
  (typeof CreatePayPageRequestCustomerInfoTypeEnum)[keyof typeof CreatePayPageRequestCustomerInfoTypeEnum];

/**
 *
 * @export
 * @interface CreatePaySubscriptionPlanRequest
 */
export interface CreatePaySubscriptionPlanRequest {
  /**
   *
   * @type {string}
   * @memberof CreatePaySubscriptionPlanRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CreatePaySubscriptionPlanRequest
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePaySubscriptionPlanRequest
   */
  status: CreatePaySubscriptionPlanRequestStatusEnum;
  /**
   *
   * @type {number}
   * @memberof CreatePaySubscriptionPlanRequest
   */
  cycleValue: number;
  /**
   *
   * @type {string}
   * @memberof CreatePaySubscriptionPlanRequest
   */
  timeType: CreatePaySubscriptionPlanRequestTimeTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof CreatePaySubscriptionPlanRequest
   */
  isFixedPaymentTime: boolean;
  /**
   *
   * @type {PaySubscriptionPlanFixedTimeModel}
   * @memberof CreatePaySubscriptionPlanRequest
   */
  fixedTime?: PaySubscriptionPlanFixedTimeModel;
  /**
   *
   * @type {number}
   * @memberof CreatePaySubscriptionPlanRequest
   */
  expirationDays: number;
  /**
   *
   * @type {string}
   * @memberof CreatePaySubscriptionPlanRequest
   */
  type: CreatePaySubscriptionPlanRequestTypeEnum;
  /**
   *
   * @type {number}
   * @memberof CreatePaySubscriptionPlanRequest
   */
  fixedAmount?: number;
  /**
   *
   * @type {string}
   * @memberof CreatePaySubscriptionPlanRequest
   */
  bankAccountId: string;
  /**
   *
   * @type {string}
   * @memberof CreatePaySubscriptionPlanRequest
   */
  paymentType: CreatePaySubscriptionPlanRequestPaymentTypeEnum;
}

export const CreatePaySubscriptionPlanRequestStatusEnum = {
  Created: "CREATED",
  Active: "ACTIVE",
} as const;

export type CreatePaySubscriptionPlanRequestStatusEnum =
  (typeof CreatePaySubscriptionPlanRequestStatusEnum)[keyof typeof CreatePaySubscriptionPlanRequestStatusEnum];
export const CreatePaySubscriptionPlanRequestTimeTypeEnum = {
  Day: "DAY",
  Week: "WEEK",
  Month: "MONTH",
  Year: "YEAR",
} as const;

export type CreatePaySubscriptionPlanRequestTimeTypeEnum =
  (typeof CreatePaySubscriptionPlanRequestTimeTypeEnum)[keyof typeof CreatePaySubscriptionPlanRequestTimeTypeEnum];
export const CreatePaySubscriptionPlanRequestTypeEnum = {
  RecurringQuantity: "RECURRING_QUANTITY",
  MeteredUsage: "METERED_USAGE",
} as const;

export type CreatePaySubscriptionPlanRequestTypeEnum =
  (typeof CreatePaySubscriptionPlanRequestTypeEnum)[keyof typeof CreatePaySubscriptionPlanRequestTypeEnum];
export const CreatePaySubscriptionPlanRequestPaymentTypeEnum = {
  Prepaid: "PREPAID",
  Postpaid: "POSTPAID",
} as const;

export type CreatePaySubscriptionPlanRequestPaymentTypeEnum =
  (typeof CreatePaySubscriptionPlanRequestPaymentTypeEnum)[keyof typeof CreatePaySubscriptionPlanRequestPaymentTypeEnum];

/**
 *
 * @export
 * @interface CreatePaySubscriptionRequest
 */
export interface CreatePaySubscriptionRequest {
  /**
   *
   * @type {string}
   * @memberof CreatePaySubscriptionRequest
   */
  customerId: string;
  /**
   *
   * @type {string}
   * @memberof CreatePaySubscriptionRequest
   */
  planId: string;
  /**
   *
   * @type {boolean}
   * @memberof CreatePaySubscriptionRequest
   */
  allowTrial: boolean;
  /**
   *
   * @type {string}
   * @memberof CreatePaySubscriptionRequest
   */
  trialTimeType?: CreatePaySubscriptionRequestTrialTimeTypeEnum;
  /**
   *
   * @type {number}
   * @memberof CreatePaySubscriptionRequest
   */
  trialTimeValue?: number;
  /**
   *
   * @type {number}
   * @memberof CreatePaySubscriptionRequest
   */
  numOfCycles?: number;
}

export const CreatePaySubscriptionRequestTrialTimeTypeEnum = {
  Day: "DAY",
  Week: "WEEK",
  Month: "MONTH",
  Year: "YEAR",
} as const;

export type CreatePaySubscriptionRequestTrialTimeTypeEnum =
  (typeof CreatePaySubscriptionRequestTrialTimeTypeEnum)[keyof typeof CreatePaySubscriptionRequestTrialTimeTypeEnum];

/**
 *
 * @export
 * @interface CreatePaymentMethodRequest
 */
export interface CreatePaymentMethodRequest {
  /**
   *
   * @type {string}
   * @memberof CreatePaymentMethodRequest
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePaymentMethodRequest
   */
  nameEn: string;
  /**
   *
   * @type {number}
   * @memberof CreatePaymentMethodRequest
   */
  dynamicFee?: number;
  /**
   *
   * @type {number}
   * @memberof CreatePaymentMethodRequest
   */
  fixedFee?: number;
  /**
   *
   * @type {number}
   * @memberof CreatePaymentMethodRequest
   */
  paymentLimit?: number;
  /**
   *
   * @type {string}
   * @memberof CreatePaymentMethodRequest
   */
  type: CreatePaymentMethodRequestTypeEnum;
  /**
   *
   * @type {string}
   * @memberof CreatePaymentMethodRequest
   */
  paymentType: CreatePaymentMethodRequestPaymentTypeEnum;
}

export const CreatePaymentMethodRequestTypeEnum = {
  Domestic: "DOMESTIC",
  International: "INTERNATIONAL",
} as const;

export type CreatePaymentMethodRequestTypeEnum =
  (typeof CreatePaymentMethodRequestTypeEnum)[keyof typeof CreatePaymentMethodRequestTypeEnum];
export const CreatePaymentMethodRequestPaymentTypeEnum = {
  VietQr: "VIET_QR",
  AtmCard: "ATM_CARD",
  Banking: "BANKING",
} as const;

export type CreatePaymentMethodRequestPaymentTypeEnum =
  (typeof CreatePaymentMethodRequestPaymentTypeEnum)[keyof typeof CreatePaymentMethodRequestPaymentTypeEnum];

/**
 *
 * @export
 * @interface CreatePosDeviceRequest
 */
export interface CreatePosDeviceRequest {
  /**
   *
   * @type {string}
   * @memberof CreatePosDeviceRequest
   */
  terminalId: string;
  /**
   *
   * @type {string}
   * @memberof CreatePosDeviceRequest
   */
  posOrderId: string;
}
/**
 *
 * @export
 * @interface CreatePosModelRequest
 */
export interface CreatePosModelRequest {
  /**
   *
   * @type {string}
   * @memberof CreatePosModelRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CreatePosModelRequest
   */
  model: string;
  /**
   *
   * @type {number}
   * @memberof CreatePosModelRequest
   */
  price: number;
  /**
   *
   * @type {string}
   * @memberof CreatePosModelRequest
   */
  businessType?: CreatePosModelRequestBusinessTypeEnum;
  /**
   *
   * @type {number}
   * @memberof CreatePosModelRequest
   */
  stock: number;
  /**
   *
   * @type {boolean}
   * @memberof CreatePosModelRequest
   */
  available: boolean;
  /**
   *
   * @type {string}
   * @memberof CreatePosModelRequest
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof CreatePosModelRequest
   */
  warranty?: number;
  /**
   *
   * @type {string}
   * @memberof CreatePosModelRequest
   */
  mainPhotoUrl: string;
  /**
   *
   * @type {Array<PosModelPhotoDto>}
   * @memberof CreatePosModelRequest
   */
  photos?: Array<PosModelPhotoDto>;
  /**
   *
   * @type {number}
   * @memberof CreatePosModelRequest
   */
  vat?: number;
}

export const CreatePosModelRequestBusinessTypeEnum = {
  Personal: "PERSONAL",
  Organization: "ORGANIZATION",
} as const;

export type CreatePosModelRequestBusinessTypeEnum =
  (typeof CreatePosModelRequestBusinessTypeEnum)[keyof typeof CreatePosModelRequestBusinessTypeEnum];

/**
 *
 * @export
 * @interface CreateProductRequest
 */
export interface CreateProductRequest {
  /**
   *
   * @type {string}
   * @memberof CreateProductRequest
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof CreateProductRequest
   */
  cateId?: number;
  /**
   *
   * @type {string}
   * @memberof CreateProductRequest
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof CreateProductRequest
   */
  priceType: CreateProductRequestPriceTypeEnum;
  /**
   *
   * @type {number}
   * @memberof CreateProductRequest
   */
  price?: number;
  /**
   *
   * @type {string}
   * @memberof CreateProductRequest
   */
  storageType: CreateProductRequestStorageTypeEnum;
  /**
   *
   * @type {number}
   * @memberof CreateProductRequest
   */
  storageQuantity?: number;
  /**
   *
   * @type {string}
   * @memberof CreateProductRequest
   */
  imageUrl?: string;
}

export const CreateProductRequestPriceTypeEnum = {
  FixedPrice: "FIXED_PRICE",
  QuantityPrice: "QUANTITY_PRICE",
  CustomerPrice: "CUSTOMER_PRICE",
} as const;

export type CreateProductRequestPriceTypeEnum =
  (typeof CreateProductRequestPriceTypeEnum)[keyof typeof CreateProductRequestPriceTypeEnum];
export const CreateProductRequestStorageTypeEnum = {
  Limited: "LIMITED",
  Unlimited: "UNLIMITED",
} as const;

export type CreateProductRequestStorageTypeEnum =
  (typeof CreateProductRequestStorageTypeEnum)[keyof typeof CreateProductRequestStorageTypeEnum];

/**
 *
 * @export
 * @interface CreateReceiverRequest
 */
export interface CreateReceiverRequest {
  /**
   *
   * @type {string}
   * @memberof CreateReceiverRequest
   */
  group: CreateReceiverRequestGroupEnum;
  /**
   *
   * @type {string}
   * @memberof CreateReceiverRequest
   */
  bankBin: string;
  /**
   *
   * @type {string}
   * @memberof CreateReceiverRequest
   */
  accountNo: string;
}

export const CreateReceiverRequestGroupEnum = {
  Customer: "CUSTOMER",
  Employee: "EMPLOYEE",
  Merchant: "MERCHANT",
  Friend: "FRIEND",
  Family: "FAMILY",
  Other: "OTHER",
} as const;

export type CreateReceiverRequestGroupEnum =
  (typeof CreateReceiverRequestGroupEnum)[keyof typeof CreateReceiverRequestGroupEnum];

/**
 *
 * @export
 * @interface CreateResponseMessageRequest
 */
export interface CreateResponseMessageRequest {
  /**
   *
   * @type {number}
   * @memberof CreateResponseMessageRequest
   */
  code: number;
  /**
   *
   * @type {string}
   * @memberof CreateResponseMessageRequest
   */
  viContent: string;
  /**
   *
   * @type {string}
   * @memberof CreateResponseMessageRequest
   */
  enContent: string;
  /**
   *
   * @type {string}
   * @memberof CreateResponseMessageRequest
   */
  description?: string;
}
/**
 *
 * @export
 * @interface CreateRoleGroupRequest
 */
export interface CreateRoleGroupRequest {
  /**
   *
   * @type {string}
   * @memberof CreateRoleGroupRequest
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof CreateRoleGroupRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CreateRoleGroupRequest
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof CreateRoleGroupRequest
   */
  status: CreateRoleGroupRequestStatusEnum;
  /**
   *
   * @type {Array<number>}
   * @memberof CreateRoleGroupRequest
   */
  actionIds?: Array<number>;
}

export const CreateRoleGroupRequestStatusEnum = {
  Active: "ACTIVE",
  Closed: "CLOSED",
} as const;

export type CreateRoleGroupRequestStatusEnum =
  (typeof CreateRoleGroupRequestStatusEnum)[keyof typeof CreateRoleGroupRequestStatusEnum];

/**
 *
 * @export
 * @interface CreateSinglePayOutRequest
 */
export interface CreateSinglePayOutRequest {
  /**
   *
   * @type {string}
   * @memberof CreateSinglePayOutRequest
   */
  bin: string;
  /**
   *
   * @type {string}
   * @memberof CreateSinglePayOutRequest
   */
  accountNo: string;
  /**
   *
   * @type {number}
   * @memberof CreateSinglePayOutRequest
   */
  amount: number;
  /**
   *
   * @type {string}
   * @memberof CreateSinglePayOutRequest
   */
  content: string;
  /**
   *
   * @type {boolean}
   * @memberof CreateSinglePayOutRequest
   */
  saveReceiver?: boolean;
}
/**
 *
 * @export
 * @interface CreateSupportRequest
 */
export interface CreateSupportRequest {
  /**
   *
   * @type {string}
   * @memberof CreateSupportRequest
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof CreateSupportRequest
   */
  phoneNo: string;
  /**
   *
   * @type {string}
   * @memberof CreateSupportRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof CreateSupportRequest
   */
  content: CreateSupportRequestContentEnum;
  /**
   *
   * @type {string}
   * @memberof CreateSupportRequest
   */
  description: string;
}

export const CreateSupportRequestContentEnum = {
  _1: "CONTENT_1",
  _2: "CONTENT_2",
  _3: "CONTENT_3",
} as const;

export type CreateSupportRequestContentEnum =
  (typeof CreateSupportRequestContentEnum)[keyof typeof CreateSupportRequestContentEnum];

/**
 *
 * @export
 * @interface CreateSystemParamRequest
 */
export interface CreateSystemParamRequest {
  /**
   *
   * @type {string}
   * @memberof CreateSystemParamRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CreateSystemParamRequest
   */
  group: CreateSystemParamRequestGroupEnum;
  /**
   *
   * @type {string}
   * @memberof CreateSystemParamRequest
   */
  dataType: CreateSystemParamRequestDataTypeEnum;
  /**
   *
   * @type {string}
   * @memberof CreateSystemParamRequest
   */
  value: string;
  /**
   *
   * @type {string}
   * @memberof CreateSystemParamRequest
   */
  description?: string;
}

export const CreateSystemParamRequestGroupEnum = {
  System: "PARAMETER_SYSTEM",
  Connection: "PARAMETER_CONNECTION",
  Paybox: "PARAMETER_PAYBOX",
  Contact: "PARAMETER_CONTACT",
} as const;

export type CreateSystemParamRequestGroupEnum =
  (typeof CreateSystemParamRequestGroupEnum)[keyof typeof CreateSystemParamRequestGroupEnum];
export const CreateSystemParamRequestDataTypeEnum = {
  String: "STRING",
  Number: "NUMBER",
} as const;

export type CreateSystemParamRequestDataTypeEnum =
  (typeof CreateSystemParamRequestDataTypeEnum)[keyof typeof CreateSystemParamRequestDataTypeEnum];

/**
 *
 * @export
 * @interface CreateTransactionRequest
 */
export interface CreateTransactionRequest {
  /**
   *
   * @type {string}
   * @memberof CreateTransactionRequest
   */
  refTransactionId: string;
  /**
   *
   * @type {number}
   * @memberof CreateTransactionRequest
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof CreateTransactionRequest
   */
  description: string;
  /**
   *
   * @type {number}
   * @memberof CreateTransactionRequest
   */
  timeout?: number;
  /**
   *
   * @type {string}
   * @memberof CreateTransactionRequest
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof CreateTransactionRequest
   */
  language?: string;
  /**
   *
   * @type {CustomerInfo}
   * @memberof CreateTransactionRequest
   */
  customerInfo?: CustomerInfo;
  /**
   *
   * @type {string}
   * @memberof CreateTransactionRequest
   */
  successUrl?: string;
  /**
   *
   * @type {string}
   * @memberof CreateTransactionRequest
   */
  failUrl?: string;
  /**
   *
   * @type {number}
   * @memberof CreateTransactionRequest
   */
  redirectAfter?: number;
  /**
   *
   * @type {string}
   * @memberof CreateTransactionRequest
   */
  bankAccountNo?: string;
  /**
   *
   * @type {string}
   * @memberof CreateTransactionRequest
   */
  paymentType?: CreateTransactionRequestPaymentTypeEnum;
}

export const CreateTransactionRequestPaymentTypeEnum = {
  VietQr: "VIET_QR",
  AtmCard: "ATM_CARD",
  Banking: "BANKING",
} as const;

export type CreateTransactionRequestPaymentTypeEnum =
  (typeof CreateTransactionRequestPaymentTypeEnum)[keyof typeof CreateTransactionRequestPaymentTypeEnum];

/**
 *
 * @export
 * @interface CreateTransactionResponse
 */
export interface CreateTransactionResponse {
  /**
   *
   * @type {string}
   * @memberof CreateTransactionResponse
   */
  transactionId?: string;
  /**
   *
   * @type {string}
   * @memberof CreateTransactionResponse
   */
  refTransactionId?: string;
  /**
   *
   * @type {string}
   * @memberof CreateTransactionResponse
   */
  payLinkCode?: string;
  /**
   *
   * @type {number}
   * @memberof CreateTransactionResponse
   */
  timeout?: number;
  /**
   *
   * @type {string}
   * @memberof CreateTransactionResponse
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof CreateTransactionResponse
   */
  virtualAccount?: string;
  /**
   *
   * @type {string}
   * @memberof CreateTransactionResponse
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof CreateTransactionResponse
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof CreateTransactionResponse
   */
  qrCodeString?: string;
  /**
   *
   * @type {string}
   * @memberof CreateTransactionResponse
   */
  status?: CreateTransactionResponseStatusEnum;
  /**
   *
   * @type {string}
   * @memberof CreateTransactionResponse
   */
  time?: string;
  /**
   *
   * @type {string}
   * @memberof CreateTransactionResponse
   */
  accountName?: string;
}

export const CreateTransactionResponseStatusEnum = {
  None: "NONE",
  Created: "CREATED",
  Success: "SUCCESS",
  Canceled: "CANCELED",
  Fail: "FAIL",
  Timeout: "TIMEOUT",
  Pending: "PENDING",
} as const;

export type CreateTransactionResponseStatusEnum =
  (typeof CreateTransactionResponseStatusEnum)[keyof typeof CreateTransactionResponseStatusEnum];

/**
 *
 * @export
 * @interface CreateUserRequest
 */
export interface CreateUserRequest {
  /**
   *
   * @type {string}
   * @memberof CreateUserRequest
   */
  sessionId: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserRequest
   */
  otp: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserRequest
   */
  phone: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserRequest
   */
  password: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserRequest
   */
  affiliateCode?: string;
}
/**
 *
 * @export
 * @interface CredentialDto
 */
export interface CredentialDto {
  /**
   *
   * @type {string}
   * @memberof CredentialDto
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CredentialDto
   */
  status?: CredentialDtoStatusEnum;
  /**
   *
   * @type {string}
   * @memberof CredentialDto
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof CredentialDto
   */
  encryptKey?: string;
  /**
   *
   * @type {string}
   * @memberof CredentialDto
   */
  secretKey?: string;
}

export const CredentialDtoStatusEnum = {
  Active: "ACTIVE",
  Revoke: "REVOKE",
} as const;

export type CredentialDtoStatusEnum =
  (typeof CredentialDtoStatusEnum)[keyof typeof CredentialDtoStatusEnum];

/**
 *
 * @export
 * @interface CustomerDto
 */
export interface CustomerDto {
  /**
   *
   * @type {string}
   * @memberof CustomerDto
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CustomerDto
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof CustomerDto
   */
  fullName?: string;
  /**
   *
   * @type {string}
   * @memberof CustomerDto
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof CustomerDto
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof CustomerDto
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof CustomerDto
   */
  customerGroupId?: string;
  /**
   *
   * @type {string}
   * @memberof CustomerDto
   */
  customerGroupName?: string;
}
/**
 *
 * @export
 * @interface CustomerFillDto
 */
export interface CustomerFillDto {
  /**
   *
   * @type {string}
   * @memberof CustomerFillDto
   */
  fullName?: string;
  /**
   *
   * @type {string}
   * @memberof CustomerFillDto
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof CustomerFillDto
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof CustomerFillDto
   */
  address?: string;
}
/**
 *
 * @export
 * @interface CustomerGroupDto
 */
export interface CustomerGroupDto {
  /**
   *
   * @type {string}
   * @memberof CustomerGroupDto
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CustomerGroupDto
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof CustomerGroupDto
   */
  totalCustomer?: number;
}
/**
 *
 * @export
 * @interface CustomerGroupResponse
 */
export interface CustomerGroupResponse {
  /**
   *
   * @type {CustomerGroupDto}
   * @memberof CustomerGroupResponse
   */
  customerGroup?: CustomerGroupDto;
}
/**
 *
 * @export
 * @interface CustomerInfo
 */
export interface CustomerInfo {
  /**
   *
   * @type {string}
   * @memberof CustomerInfo
   */
  fullName: string;
  /**
   *
   * @type {string}
   * @memberof CustomerInfo
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof CustomerInfo
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof CustomerInfo
   */
  address?: string;
}
/**
 *
 * @export
 * @interface CustomerInfoForm
 */
export interface CustomerInfoForm {
  /**
   *
   * @type {string}
   * @memberof CustomerInfoForm
   */
  id?: string;
  /**
   *
   * @type {boolean}
   * @memberof CustomerInfoForm
   */
  fillName?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CustomerInfoForm
   */
  fillPhone?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CustomerInfoForm
   */
  fillEmail?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CustomerInfoForm
   */
  fillAddress?: boolean;
}
/**
 *
 * @export
 * @interface CustomerListResponse
 */
export interface CustomerListResponse {
  /**
   *
   * @type {Array<CustomerDto>}
   * @memberof CustomerListResponse
   */
  customers?: Array<CustomerDto>;
}
/**
 *
 * @export
 * @interface CustomerResponse
 */
export interface CustomerResponse {
  /**
   *
   * @type {CustomerDto}
   * @memberof CustomerResponse
   */
  customer?: CustomerDto;
}
/**
 *
 * @export
 * @interface DeletePayButtonRequest
 */
export interface DeletePayButtonRequest {
  /**
   *
   * @type {string}
   * @memberof DeletePayButtonRequest
   */
  id: string;
}
/**
 *
 * @export
 * @interface DeleteSftpFileResponse
 */
export interface DeleteSftpFileResponse {
  /**
   *
   * @type {boolean}
   * @memberof DeleteSftpFileResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface DenyForControlCmsRequest
 */
export interface DenyForControlCmsRequest {
  /**
   *
   * @type {number}
   * @memberof DenyForControlCmsRequest
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof DenyForControlCmsRequest
   */
  note?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof DenyForControlCmsRequest
   */
  documentUrls?: Array<string>;
}
/**
 *
 * @export
 * @interface DisableVirtualAccountRequest
 */
export interface DisableVirtualAccountRequest {
  /**
   *
   * @type {number}
   * @memberof DisableVirtualAccountRequest
   */
  order?: number;
}
/**
 *
 * @export
 * @interface DisapproveSinglePayOutRequest
 */
export interface DisapproveSinglePayOutRequest {
  /**
   *
   * @type {string}
   * @memberof DisapproveSinglePayOutRequest
   */
  payOutId: string;
  /**
   *
   * @type {string}
   * @memberof DisapproveSinglePayOutRequest
   */
  denyContent: string;
}
/**
 *
 * @export
 * @interface Document
 */
export interface Document {
  /**
   *
   * @type {number}
   * @memberof Document
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Document
   */
  fileName?: string;
  /**
   *
   * @type {string}
   * @memberof Document
   */
  type?: DocumentTypeEnum;
  /**
   *
   * @type {string}
   * @memberof Document
   */
  url?: string;
}

export const DocumentTypeEnum = {
  BusinessLicence: "BUSINESS_LICENCE",
  CoboPoboContract: "COBO_POBO_CONTRACT",
  ForControlDocument: "FOR_CONTROL_DOCUMENT",
} as const;

export type DocumentTypeEnum =
  (typeof DocumentTypeEnum)[keyof typeof DocumentTypeEnum];

/**
 *
 * @export
 * @interface EWalletResponse
 */
export interface EWalletResponse {
  /**
   *
   * @type {number}
   * @memberof EWalletResponse
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof EWalletResponse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof EWalletResponse
   */
  shortName?: string;
  /**
   *
   * @type {string}
   * @memberof EWalletResponse
   */
  commonName?: string;
  /**
   *
   * @type {string}
   * @memberof EWalletResponse
   */
  logo?: string;
  /**
   *
   * @type {string}
   * @memberof EWalletResponse
   */
  icon?: string;
}
/**
 *
 * @export
 * @interface EkycDto
 */
export interface EkycDto {
  /**
   *
   * @type {number}
   * @memberof EkycDto
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof EkycDto
   */
  frontUrl?: string;
  /**
   *
   * @type {string}
   * @memberof EkycDto
   */
  backUrl?: string;
  /**
   *
   * @type {string}
   * @memberof EkycDto
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof EkycDto
   */
  sex?: string;
  /**
   *
   * @type {string}
   * @memberof EkycDto
   */
  no?: string;
  /**
   *
   * @type {string}
   * @memberof EkycDto
   */
  idType?: string;
  /**
   *
   * @type {string}
   * @memberof EkycDto
   */
  document?: string;
  /**
   *
   * @type {string}
   * @memberof EkycDto
   */
  issueDate?: string;
  /**
   *
   * @type {string}
   * @memberof EkycDto
   */
  issueBy?: string;
  /**
   *
   * @type {string}
   * @memberof EkycDto
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof EkycDto
   */
  national?: string;
}
/**
 *
 * @export
 * @interface EkycResponse
 */
export interface EkycResponse {
  /**
   *
   * @type {string}
   * @memberof EkycResponse
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof EkycResponse
   */
  backUrl?: string;
  /**
   *
   * @type {string}
   * @memberof EkycResponse
   */
  birthday?: string;
  /**
   *
   * @type {string}
   * @memberof EkycResponse
   */
  characteristics?: string;
  /**
   *
   * @type {string}
   * @memberof EkycResponse
   */
  country?: string;
  /**
   *
   * @type {string}
   * @memberof EkycResponse
   */
  createAt?: string;
  /**
   *
   * @type {string}
   * @memberof EkycResponse
   */
  district?: string;
  /**
   *
   * @type {string}
   * @memberof EkycResponse
   */
  document?: string;
  /**
   *
   * @type {string}
   * @memberof EkycResponse
   */
  documentId?: string;
  /**
   *
   * @type {string}
   * @memberof EkycResponse
   */
  ethnicity?: string;
  /**
   *
   * @type {string}
   * @memberof EkycResponse
   */
  expiry?: string;
  /**
   *
   * @type {string}
   * @memberof EkycResponse
   */
  frontUrl?: string;
  /**
   *
   * @type {string}
   * @memberof EkycResponse
   */
  hometown?: string;
  /**
   *
   * @type {number}
   * @memberof EkycResponse
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof EkycResponse
   */
  idCheck?: string;
  /**
   *
   * @type {string}
   * @memberof EkycResponse
   */
  idLogic?: string;
  /**
   *
   * @type {string}
   * @memberof EkycResponse
   */
  idLogicMessage?: string;
  /**
   *
   * @type {string}
   * @memberof EkycResponse
   */
  idType?: string;
  /**
   *
   * @type {string}
   * @memberof EkycResponse
   */
  issueBy?: string;
  /**
   *
   * @type {string}
   * @memberof EkycResponse
   */
  issueDate?: string;
  /**
   *
   * @type {string}
   * @memberof EkycResponse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof EkycResponse
   */
  national?: string;
  /**
   *
   * @type {string}
   * @memberof EkycResponse
   */
  no?: string;
  /**
   *
   * @type {string}
   * @memberof EkycResponse
   */
  optionalData?: string;
  /**
   *
   * @type {string}
   * @memberof EkycResponse
   */
  passportType?: string;
  /**
   *
   * @type {string}
   * @memberof EkycResponse
   */
  province?: string;
  /**
   *
   * @type {string}
   * @memberof EkycResponse
   */
  religion?: string;
  /**
   *
   * @type {string}
   * @memberof EkycResponse
   */
  sex?: string;
  /**
   *
   * @type {string}
   * @memberof EkycResponse
   */
  street?: string;
}
/**
 *
 * @export
 * @interface EmergencyLockAccountRequest
 */
export interface EmergencyLockAccountRequest {
  /**
   *
   * @type {string}
   * @memberof EmergencyLockAccountRequest
   */
  sessionId: string;
  /**
   *
   * @type {string}
   * @memberof EmergencyLockAccountRequest
   */
  token: string;
}
/**
 *
 * @export
 * @interface EnableVirtualAccountRequest
 */
export interface EnableVirtualAccountRequest {
  /**
   *
   * @type {number}
   * @memberof EnableVirtualAccountRequest
   */
  order?: number;
  /**
   *
   * @type {number}
   * @memberof EnableVirtualAccountRequest
   */
  timeout?: number;
  /**
   *
   * @type {number}
   * @memberof EnableVirtualAccountRequest
   */
  fixAmount?: number;
  /**
   *
   * @type {string}
   * @memberof EnableVirtualAccountRequest
   */
  fixContent?: string;
  /**
   *
   * @type {string}
   * @memberof EnableVirtualAccountRequest
   */
  bankAccountNo?: string;
}
/**
 *
 * @export
 * @interface EncryptedBodyRequest
 */
export interface EncryptedBodyRequest {
  /**
   *
   * @type {string}
   * @memberof EncryptedBodyRequest
   */
  data: string;
}
/**
 *
 * @export
 * @interface EnterpriseMerchantDto
 */
export interface EnterpriseMerchantDto {
  /**
   *
   * @type {string}
   * @memberof EnterpriseMerchantDto
   */
  position: EnterpriseMerchantDtoPositionEnum;
  /**
   *
   * @type {number}
   * @memberof EnterpriseMerchantDto
   */
  fundPercent: number;
  /**
   *
   * @type {string}
   * @memberof EnterpriseMerchantDto
   */
  representFrontUrl: string;
  /**
   *
   * @type {string}
   * @memberof EnterpriseMerchantDto
   */
  representBackUrl: string;
  /**
   *
   * @type {Array<string>}
   * @memberof EnterpriseMerchantDto
   */
  documentUrls?: Array<string>;
}

export const EnterpriseMerchantDtoPositionEnum = {
  ChairmanOfTheBoard: "CHAIRMAN_OF_THE_BOARD",
  ChairmanOfTheMembersCouncil: "CHAIRMAN_OF_THE_MEMBERS_COUNCIL",
  DirectorGeneral: "DIRECTOR_GENERAL",
  Director: "DIRECTOR",
} as const;

export type EnterpriseMerchantDtoPositionEnum =
  (typeof EnterpriseMerchantDtoPositionEnum)[keyof typeof EnterpriseMerchantDtoPositionEnum];

/**
 *
 * @export
 * @interface Environment
 */
export interface Environment {
  /**
   *
   * @type {Array<string>}
   * @memberof Environment
   */
  activeProfiles?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof Environment
   */
  defaultProfiles?: Array<string>;
}
/**
 *
 * @export
 * @interface ExtendPaySubscriptionRequest
 */
export interface ExtendPaySubscriptionRequest {
  /**
   *
   * @type {number}
   * @memberof ExtendPaySubscriptionRequest
   */
  addNumOfCycles: number;
}
/**
 *
 * @export
 * @interface ExternalCreatePaySubscriptionPlanRequest
 */
export interface ExternalCreatePaySubscriptionPlanRequest {
  /**
   *
   * @type {string}
   * @memberof ExternalCreatePaySubscriptionPlanRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ExternalCreatePaySubscriptionPlanRequest
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalCreatePaySubscriptionPlanRequest
   */
  status: ExternalCreatePaySubscriptionPlanRequestStatusEnum;
  /**
   *
   * @type {number}
   * @memberof ExternalCreatePaySubscriptionPlanRequest
   */
  cycleValue: number;
  /**
   *
   * @type {string}
   * @memberof ExternalCreatePaySubscriptionPlanRequest
   */
  timeType: ExternalCreatePaySubscriptionPlanRequestTimeTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof ExternalCreatePaySubscriptionPlanRequest
   */
  isFixedPaymentTime: boolean;
  /**
   *
   * @type {PaySubscriptionPlanFixedTimeModel}
   * @memberof ExternalCreatePaySubscriptionPlanRequest
   */
  fixedTime?: PaySubscriptionPlanFixedTimeModel;
  /**
   *
   * @type {number}
   * @memberof ExternalCreatePaySubscriptionPlanRequest
   */
  expirationDays: number;
  /**
   *
   * @type {string}
   * @memberof ExternalCreatePaySubscriptionPlanRequest
   */
  type: ExternalCreatePaySubscriptionPlanRequestTypeEnum;
  /**
   *
   * @type {number}
   * @memberof ExternalCreatePaySubscriptionPlanRequest
   */
  fixedAmount?: number;
  /**
   *
   * @type {string}
   * @memberof ExternalCreatePaySubscriptionPlanRequest
   */
  bankAccountNo: string;
  /**
   *
   * @type {string}
   * @memberof ExternalCreatePaySubscriptionPlanRequest
   */
  paymentType: ExternalCreatePaySubscriptionPlanRequestPaymentTypeEnum;
}

export const ExternalCreatePaySubscriptionPlanRequestStatusEnum = {
  Created: "CREATED",
  Active: "ACTIVE",
} as const;

export type ExternalCreatePaySubscriptionPlanRequestStatusEnum =
  (typeof ExternalCreatePaySubscriptionPlanRequestStatusEnum)[keyof typeof ExternalCreatePaySubscriptionPlanRequestStatusEnum];
export const ExternalCreatePaySubscriptionPlanRequestTimeTypeEnum = {
  Day: "DAY",
  Week: "WEEK",
  Month: "MONTH",
  Year: "YEAR",
} as const;

export type ExternalCreatePaySubscriptionPlanRequestTimeTypeEnum =
  (typeof ExternalCreatePaySubscriptionPlanRequestTimeTypeEnum)[keyof typeof ExternalCreatePaySubscriptionPlanRequestTimeTypeEnum];
export const ExternalCreatePaySubscriptionPlanRequestTypeEnum = {
  RecurringQuantity: "RECURRING_QUANTITY",
  MeteredUsage: "METERED_USAGE",
} as const;

export type ExternalCreatePaySubscriptionPlanRequestTypeEnum =
  (typeof ExternalCreatePaySubscriptionPlanRequestTypeEnum)[keyof typeof ExternalCreatePaySubscriptionPlanRequestTypeEnum];
export const ExternalCreatePaySubscriptionPlanRequestPaymentTypeEnum = {
  Prepaid: "PREPAID",
  Postpaid: "POSTPAID",
} as const;

export type ExternalCreatePaySubscriptionPlanRequestPaymentTypeEnum =
  (typeof ExternalCreatePaySubscriptionPlanRequestPaymentTypeEnum)[keyof typeof ExternalCreatePaySubscriptionPlanRequestPaymentTypeEnum];

/**
 *
 * @export
 * @interface ExternalCreatePaySubscriptionRequest
 */
export interface ExternalCreatePaySubscriptionRequest {
  /**
   *
   * @type {string}
   * @memberof ExternalCreatePaySubscriptionRequest
   */
  customerName: string;
  /**
   *
   * @type {string}
   * @memberof ExternalCreatePaySubscriptionRequest
   */
  customerEmail: string;
  /**
   *
   * @type {string}
   * @memberof ExternalCreatePaySubscriptionRequest
   */
  customerPhone: string;
  /**
   *
   * @type {string}
   * @memberof ExternalCreatePaySubscriptionRequest
   */
  customerAddress?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalCreatePaySubscriptionRequest
   */
  planId: string;
  /**
   *
   * @type {boolean}
   * @memberof ExternalCreatePaySubscriptionRequest
   */
  allowTrial: boolean;
  /**
   *
   * @type {string}
   * @memberof ExternalCreatePaySubscriptionRequest
   */
  trialTimeType?: ExternalCreatePaySubscriptionRequestTrialTimeTypeEnum;
  /**
   *
   * @type {number}
   * @memberof ExternalCreatePaySubscriptionRequest
   */
  trialTimeValue?: number;
  /**
   *
   * @type {number}
   * @memberof ExternalCreatePaySubscriptionRequest
   */
  numOfCycles?: number;
}

export const ExternalCreatePaySubscriptionRequestTrialTimeTypeEnum = {
  Day: "DAY",
  Week: "WEEK",
  Month: "MONTH",
  Year: "YEAR",
} as const;

export type ExternalCreatePaySubscriptionRequestTrialTimeTypeEnum =
  (typeof ExternalCreatePaySubscriptionRequestTrialTimeTypeEnum)[keyof typeof ExternalCreatePaySubscriptionRequestTrialTimeTypeEnum];

/**
 *
 * @export
 * @interface ExternalGetAllPaySubscriptionInvoiceRequest
 */
export interface ExternalGetAllPaySubscriptionInvoiceRequest {
  /**
   *
   * @type {string}
   * @memberof ExternalGetAllPaySubscriptionInvoiceRequest
   */
  subscriptionId?: string;
  /**
   *
   * @type {number}
   * @memberof ExternalGetAllPaySubscriptionInvoiceRequest
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof ExternalGetAllPaySubscriptionInvoiceRequest
   */
  pageSize: number;
  /**
   *
   * @type {string}
   * @memberof ExternalGetAllPaySubscriptionInvoiceRequest
   */
  sortDirection: ExternalGetAllPaySubscriptionInvoiceRequestSortDirectionEnum;
  /**
   *
   * @type {string}
   * @memberof ExternalGetAllPaySubscriptionInvoiceRequest
   */
  sortBy: ExternalGetAllPaySubscriptionInvoiceRequestSortByEnum;
}

export const ExternalGetAllPaySubscriptionInvoiceRequestSortDirectionEnum = {
  Asc: "ASC",
  Desc: "DESC",
} as const;

export type ExternalGetAllPaySubscriptionInvoiceRequestSortDirectionEnum =
  (typeof ExternalGetAllPaySubscriptionInvoiceRequestSortDirectionEnum)[keyof typeof ExternalGetAllPaySubscriptionInvoiceRequestSortDirectionEnum];
export const ExternalGetAllPaySubscriptionInvoiceRequestSortByEnum = {
  CreatedAt: "CREATED_AT",
  ModifiedAt: "MODIFIED_AT",
  Subscription: "SUBSCRIPTION",
} as const;

export type ExternalGetAllPaySubscriptionInvoiceRequestSortByEnum =
  (typeof ExternalGetAllPaySubscriptionInvoiceRequestSortByEnum)[keyof typeof ExternalGetAllPaySubscriptionInvoiceRequestSortByEnum];

/**
 *
 * @export
 * @interface ExternalGetPaySubscriptionInvoiceRequest
 */
export interface ExternalGetPaySubscriptionInvoiceRequest {
  /**
   *
   * @type {string}
   * @memberof ExternalGetPaySubscriptionInvoiceRequest
   */
  id: string;
}
/**
 *
 * @export
 * @interface ExternalGetPaySubscriptionPlanRequest
 */
export interface ExternalGetPaySubscriptionPlanRequest {
  /**
   *
   * @type {string}
   * @memberof ExternalGetPaySubscriptionPlanRequest
   */
  id: string;
}
/**
 *
 * @export
 * @interface ExternalGetPaySubscriptionRequest
 */
export interface ExternalGetPaySubscriptionRequest {
  /**
   *
   * @type {string}
   * @memberof ExternalGetPaySubscriptionRequest
   */
  id: string;
}
/**
 *
 * @export
 * @interface ExternalPaySubscriptionInvoiceResponse
 */
export interface ExternalPaySubscriptionInvoiceResponse {
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionInvoiceResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionInvoiceResponse
   */
  subscriptionId?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionInvoiceResponse
   */
  txnNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionInvoiceResponse
   */
  status?: ExternalPaySubscriptionInvoiceResponseStatusEnum;
  /**
   *
   * @type {number}
   * @memberof ExternalPaySubscriptionInvoiceResponse
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionInvoiceResponse
   */
  paymentDate?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionInvoiceResponse
   */
  expiredDate?: string;
  /**
   *
   * @type {ExternalPaySubscriptionPlanResponse}
   * @memberof ExternalPaySubscriptionInvoiceResponse
   */
  plan?: ExternalPaySubscriptionPlanResponse;
  /**
   *
   * @type {CustomerDto}
   * @memberof ExternalPaySubscriptionInvoiceResponse
   */
  customer?: CustomerDto;
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionInvoiceResponse
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionInvoiceResponse
   */
  paymentType?: ExternalPaySubscriptionInvoiceResponsePaymentTypeEnum;
}

export const ExternalPaySubscriptionInvoiceResponseStatusEnum = {
  None: "NONE",
  Created: "CREATED",
  Success: "SUCCESS",
  Canceled: "CANCELED",
  Fail: "FAIL",
  Timeout: "TIMEOUT",
  Pending: "PENDING",
} as const;

export type ExternalPaySubscriptionInvoiceResponseStatusEnum =
  (typeof ExternalPaySubscriptionInvoiceResponseStatusEnum)[keyof typeof ExternalPaySubscriptionInvoiceResponseStatusEnum];
export const ExternalPaySubscriptionInvoiceResponsePaymentTypeEnum = {
  VietQr: "VIET_QR",
  AtmCard: "ATM_CARD",
  Banking: "BANKING",
} as const;

export type ExternalPaySubscriptionInvoiceResponsePaymentTypeEnum =
  (typeof ExternalPaySubscriptionInvoiceResponsePaymentTypeEnum)[keyof typeof ExternalPaySubscriptionInvoiceResponsePaymentTypeEnum];

/**
 *
 * @export
 * @interface ExternalPaySubscriptionPlanResponse
 */
export interface ExternalPaySubscriptionPlanResponse {
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionPlanResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionPlanResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionPlanResponse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionPlanResponse
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionPlanResponse
   */
  status?: ExternalPaySubscriptionPlanResponseStatusEnum;
  /**
   *
   * @type {number}
   * @memberof ExternalPaySubscriptionPlanResponse
   */
  cycleValue?: number;
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionPlanResponse
   */
  timeType?: ExternalPaySubscriptionPlanResponseTimeTypeEnum;
  /**
   *
   * @type {PaySubscriptionPlanFixedTimeModel}
   * @memberof ExternalPaySubscriptionPlanResponse
   */
  fixedTime?: PaySubscriptionPlanFixedTimeModel;
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionPlanResponse
   */
  type?: ExternalPaySubscriptionPlanResponseTypeEnum;
  /**
   *
   * @type {number}
   * @memberof ExternalPaySubscriptionPlanResponse
   */
  fixedAmount?: number;
  /**
   *
   * @type {number}
   * @memberof ExternalPaySubscriptionPlanResponse
   */
  expirationDays?: number;
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionPlanResponse
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionPlanResponse
   */
  callbackUrl?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionPlanResponse
   */
  modifiedAt?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionPlanResponse
   */
  paymentType?: ExternalPaySubscriptionPlanResponsePaymentTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionPlanResponse
   */
  bankAccountNo?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionPlanResponse
   */
  bankAccountName?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionPlanResponse
   */
  bankName?: string;
  /**
   *
   * @type {boolean}
   * @memberof ExternalPaySubscriptionPlanResponse
   */
  fixedPaymentTime?: boolean;
}

export const ExternalPaySubscriptionPlanResponseStatusEnum = {
  Created: "CREATED",
  Active: "ACTIVE",
  Inactive: "INACTIVE",
} as const;

export type ExternalPaySubscriptionPlanResponseStatusEnum =
  (typeof ExternalPaySubscriptionPlanResponseStatusEnum)[keyof typeof ExternalPaySubscriptionPlanResponseStatusEnum];
export const ExternalPaySubscriptionPlanResponseTimeTypeEnum = {
  Day: "DAY",
  Week: "WEEK",
  Month: "MONTH",
  Year: "YEAR",
} as const;

export type ExternalPaySubscriptionPlanResponseTimeTypeEnum =
  (typeof ExternalPaySubscriptionPlanResponseTimeTypeEnum)[keyof typeof ExternalPaySubscriptionPlanResponseTimeTypeEnum];
export const ExternalPaySubscriptionPlanResponseTypeEnum = {
  RecurringQuantity: "RECURRING_QUANTITY",
  MeteredUsage: "METERED_USAGE",
} as const;

export type ExternalPaySubscriptionPlanResponseTypeEnum =
  (typeof ExternalPaySubscriptionPlanResponseTypeEnum)[keyof typeof ExternalPaySubscriptionPlanResponseTypeEnum];
export const ExternalPaySubscriptionPlanResponsePaymentTypeEnum = {
  Prepaid: "PREPAID",
  Postpaid: "POSTPAID",
} as const;

export type ExternalPaySubscriptionPlanResponsePaymentTypeEnum =
  (typeof ExternalPaySubscriptionPlanResponsePaymentTypeEnum)[keyof typeof ExternalPaySubscriptionPlanResponsePaymentTypeEnum];

/**
 *
 * @export
 * @interface ExternalPaySubscriptionResponse
 */
export interface ExternalPaySubscriptionResponse {
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionResponse
   */
  status?: ExternalPaySubscriptionResponseStatusEnum;
  /**
   *
   * @type {ExternalPaySubscriptionPlanResponse}
   * @memberof ExternalPaySubscriptionResponse
   */
  plan?: ExternalPaySubscriptionPlanResponse;
  /**
   *
   * @type {CustomerDto}
   * @memberof ExternalPaySubscriptionResponse
   */
  customer?: CustomerDto;
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionResponse
   */
  createdAt?: string;
  /**
   *
   * @type {number}
   * @memberof ExternalPaySubscriptionResponse
   */
  numOfCycles?: number;
  /**
   *
   * @type {number}
   * @memberof ExternalPaySubscriptionResponse
   */
  numOfPayments?: number;
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionResponse
   */
  prePaymentDate?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionResponse
   */
  nextPaymentDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof ExternalPaySubscriptionResponse
   */
  allowTrial?: boolean;
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionResponse
   */
  trialTimeType?: ExternalPaySubscriptionResponseTrialTimeTypeEnum;
  /**
   *
   * @type {number}
   * @memberof ExternalPaySubscriptionResponse
   */
  trialTimeValue?: number;
}

export const ExternalPaySubscriptionResponseStatusEnum = {
  Trial: "TRIAL",
  Active: "ACTIVE",
  Paused: "PAUSED",
  PendingCancellation: "PENDING_CANCELLATION",
  Cancelled: "CANCELLED",
  Expired: "EXPIRED",
  PaymentExpired: "PAYMENT_EXPIRED",
  CreateInvoiceFailed: "CREATE_INVOICE_FAILED",
} as const;

export type ExternalPaySubscriptionResponseStatusEnum =
  (typeof ExternalPaySubscriptionResponseStatusEnum)[keyof typeof ExternalPaySubscriptionResponseStatusEnum];
export const ExternalPaySubscriptionResponseTrialTimeTypeEnum = {
  Day: "DAY",
  Week: "WEEK",
  Month: "MONTH",
  Year: "YEAR",
} as const;

export type ExternalPaySubscriptionResponseTrialTimeTypeEnum =
  (typeof ExternalPaySubscriptionResponseTrialTimeTypeEnum)[keyof typeof ExternalPaySubscriptionResponseTrialTimeTypeEnum];

/**
 *
 * @export
 * @interface ExternalUpdateStatePaySubscriptionPlanRequest
 */
export interface ExternalUpdateStatePaySubscriptionPlanRequest {
  /**
   *
   * @type {string}
   * @memberof ExternalUpdateStatePaySubscriptionPlanRequest
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ExternalUpdateStatePaySubscriptionPlanRequest
   */
  state: ExternalUpdateStatePaySubscriptionPlanRequestStateEnum;
}

export const ExternalUpdateStatePaySubscriptionPlanRequestStateEnum = {
  Created: "CREATED",
  Active: "ACTIVE",
  Inactive: "INACTIVE",
} as const;

export type ExternalUpdateStatePaySubscriptionPlanRequestStateEnum =
  (typeof ExternalUpdateStatePaySubscriptionPlanRequestStateEnum)[keyof typeof ExternalUpdateStatePaySubscriptionPlanRequestStateEnum];

/**
 *
 * @export
 * @interface ExternalUpdateStatePaySubscriptionRequest
 */
export interface ExternalUpdateStatePaySubscriptionRequest {
  /**
   *
   * @type {string}
   * @memberof ExternalUpdateStatePaySubscriptionRequest
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ExternalUpdateStatePaySubscriptionRequest
   */
  action: ExternalUpdateStatePaySubscriptionRequestActionEnum;
  /**
   *
   * @type {number}
   * @memberof ExternalUpdateStatePaySubscriptionRequest
   */
  addNumOfCycles?: number;
}

export const ExternalUpdateStatePaySubscriptionRequestActionEnum = {
  Pause: "PAUSE",
  PaymentExpire: "PAYMENT_EXPIRE",
  Paid: "PAID",
  Expire: "EXPIRE",
  Continue: "CONTINUE",
  ExtendPayment: "EXTEND_PAYMENT",
  ExtendCycles: "EXTEND_CYCLES",
  Restore: "RESTORE",
  ExpirationCancel: "EXPIRATION_CANCEL",
  ImmediateCancel: "IMMEDIATE_CANCEL",
  RecreateInvoice: "RECREATE_INVOICE",
  CreateInvoiceFailed: "CREATE_INVOICE_FAILED",
} as const;

export type ExternalUpdateStatePaySubscriptionRequestActionEnum =
  (typeof ExternalUpdateStatePaySubscriptionRequestActionEnum)[keyof typeof ExternalUpdateStatePaySubscriptionRequestActionEnum];

/**
 *
 * @export
 * @interface FeeBankAccountInputDto
 */
export interface FeeBankAccountInputDto {
  /**
   *
   * @type {string}
   * @memberof FeeBankAccountInputDto
   */
  accountNo?: string;
  /**
   *
   * @type {boolean}
   * @memberof FeeBankAccountInputDto
   */
  defaultFeeAccount?: boolean;
}
/**
 *
 * @export
 * @interface FeeCodeResponse
 */
export interface FeeCodeResponse {
  /**
   *
   * @type {string}
   * @memberof FeeCodeResponse
   */
  feeCode?: string;
}
/**
 *
 * @export
 * @interface FeeDto
 */
export interface FeeDto {
  /**
   *
   * @type {string}
   * @memberof FeeDto
   */
  createdDate?: string;
  /**
   *
   * @type {string}
   * @memberof FeeDto
   */
  lastModifiedDate?: string;
  /**
   *
   * @type {string}
   * @memberof FeeDto
   */
  createdBy?: string;
  /**
   *
   * @type {string}
   * @memberof FeeDto
   */
  lastModifiedBy?: string;
  /**
   *
   * @type {string}
   * @memberof FeeDto
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof FeeDto
   */
  feeCode?: string;
  /**
   *
   * @type {string}
   * @memberof FeeDto
   */
  feeName?: string;
  /**
   *
   * @type {string}
   * @memberof FeeDto
   */
  feeMethod?: FeeDtoFeeMethodEnum;
  /**
   *
   * @type {number}
   * @memberof FeeDto
   */
  percentVAT?: number;
  /**
   *
   * @type {string}
   * @memberof FeeDto
   */
  feeStatus?: FeeDtoFeeStatusEnum;
  /**
   *
   * @type {string}
   * @memberof FeeDto
   */
  businessType?: FeeDtoBusinessTypeEnum;
  /**
   *
   * @type {string}
   * @memberof FeeDto
   */
  serviceType?: FeeDtoServiceTypeEnum;
  /**
   *
   * @type {string}
   * @memberof FeeDto
   */
  approveBy?: string;
  /**
   *
   * @type {string}
   * @memberof FeeDto
   */
  approveDate?: string;
  /**
   *
   * @type {string}
   * @memberof FeeDto
   */
  calculatePer?: FeeDtoCalculatePerEnum;
  /**
   *
   * @type {string}
   * @memberof FeeDto
   */
  fixedFeeMethod?: FeeDtoFixedFeeMethodEnum;
  /**
   *
   * @type {number}
   * @memberof FeeDto
   */
  amount?: number;
  /**
   *
   * @type {Array<BracketFee>}
   * @memberof FeeDto
   */
  bracketFees?: Array<BracketFee>;
  /**
   *
   * @type {number}
   * @memberof FeeDto
   */
  minimumMoney?: number;
  /**
   *
   * @type {number}
   * @memberof FeeDto
   */
  maxMoney?: number;
  /**
   *
   * @type {string}
   * @memberof FeeDto
   */
  reasonDeny?: string;
}

export const FeeDtoFeeMethodEnum = {
  Free: "FREE",
  FixedFee: "FIXED_FEE",
  BracketFee: "BRACKET_FEE",
} as const;

export type FeeDtoFeeMethodEnum =
  (typeof FeeDtoFeeMethodEnum)[keyof typeof FeeDtoFeeMethodEnum];
export const FeeDtoFeeStatusEnum = {
  Created: "CREATED",
  Approved: "APPROVED",
  Denied: "DENIED",
} as const;

export type FeeDtoFeeStatusEnum =
  (typeof FeeDtoFeeStatusEnum)[keyof typeof FeeDtoFeeStatusEnum];
export const FeeDtoBusinessTypeEnum = {
  Personal: "PERSONAL",
  Organization: "ORGANIZATION",
} as const;

export type FeeDtoBusinessTypeEnum =
  (typeof FeeDtoBusinessTypeEnum)[keyof typeof FeeDtoBusinessTypeEnum];
export const FeeDtoServiceTypeEnum = {
  Cobo: "COBO",
  Pobo: "POBO",
  CoboInterCard: "COBO_INTER_CARD",
  CoboDebitDomesticCard: "COBO_DEBIT_DOMESTIC_CARD",
  CoboCreditDomesticCard: "COBO_CREDIT_DOMESTIC_CARD",
  PoboInterBank: "POBO_INTER_BANK",
} as const;

export type FeeDtoServiceTypeEnum =
  (typeof FeeDtoServiceTypeEnum)[keyof typeof FeeDtoServiceTypeEnum];
export const FeeDtoCalculatePerEnum = {
  PerMonth: "PER_MONTH",
  PerTransaction: "PER_TRANSACTION",
  None: "NONE",
} as const;

export type FeeDtoCalculatePerEnum =
  (typeof FeeDtoCalculatePerEnum)[keyof typeof FeeDtoCalculatePerEnum];
export const FeeDtoFixedFeeMethodEnum = {
  FixedFee: "FIXED_FEE",
  RatioFee: "RATIO_FEE",
  None: "NONE",
} as const;

export type FeeDtoFixedFeeMethodEnum =
  (typeof FeeDtoFixedFeeMethodEnum)[keyof typeof FeeDtoFixedFeeMethodEnum];

/**
 *
 * @export
 * @interface FeeHistoryDto
 */
export interface FeeHistoryDto {
  /**
   *
   * @type {string}
   * @memberof FeeHistoryDto
   */
  createdDate?: string;
  /**
   *
   * @type {string}
   * @memberof FeeHistoryDto
   */
  createdBy?: string;
  /**
   *
   * @type {string}
   * @memberof FeeHistoryDto
   */
  feeCommand?: FeeHistoryDtoFeeCommandEnum;
  /**
   *
   * @type {string}
   * @memberof FeeHistoryDto
   */
  note?: string;
  /**
   *
   * @type {string}
   * @memberof FeeHistoryDto
   */
  status?: FeeHistoryDtoStatusEnum;
  /**
   *
   * @type {string}
   * @memberof FeeHistoryDto
   */
  approveStatus?: FeeHistoryDtoApproveStatusEnum;
}

export const FeeHistoryDtoFeeCommandEnum = {
  Create: "CREATE",
  Update: "UPDATE",
  Approve: "APPROVE",
  Deny: "DENY",
} as const;

export type FeeHistoryDtoFeeCommandEnum =
  (typeof FeeHistoryDtoFeeCommandEnum)[keyof typeof FeeHistoryDtoFeeCommandEnum];
export const FeeHistoryDtoStatusEnum = {
  Created: "CREATED",
  Approved: "APPROVED",
  Denied: "DENIED",
} as const;

export type FeeHistoryDtoStatusEnum =
  (typeof FeeHistoryDtoStatusEnum)[keyof typeof FeeHistoryDtoStatusEnum];
export const FeeHistoryDtoApproveStatusEnum = {
  WaitingApproved: "WAITING_APPROVED",
  Approved: "APPROVED",
  NotApproved: "NOT_APPROVED",
} as const;

export type FeeHistoryDtoApproveStatusEnum =
  (typeof FeeHistoryDtoApproveStatusEnum)[keyof typeof FeeHistoryDtoApproveStatusEnum];

/**
 *
 * @export
 * @interface FeeNameDto
 */
export interface FeeNameDto {
  /**
   *
   * @type {string}
   * @memberof FeeNameDto
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof FeeNameDto
   */
  feeCode?: string;
  /**
   *
   * @type {string}
   * @memberof FeeNameDto
   */
  feeName?: string;
  /**
   *
   * @type {string}
   * @memberof FeeNameDto
   */
  serviceType?: FeeNameDtoServiceTypeEnum;
}

export const FeeNameDtoServiceTypeEnum = {
  Cobo: "COBO",
  Pobo: "POBO",
  CoboInterCard: "COBO_INTER_CARD",
  CoboDebitDomesticCard: "COBO_DEBIT_DOMESTIC_CARD",
  CoboCreditDomesticCard: "COBO_CREDIT_DOMESTIC_CARD",
  PoboInterBank: "POBO_INTER_BANK",
} as const;

export type FeeNameDtoServiceTypeEnum =
  (typeof FeeNameDtoServiceTypeEnum)[keyof typeof FeeNameDtoServiceTypeEnum];

/**
 *
 * @export
 * @interface FeeReportDto
 */
export interface FeeReportDto {
  /**
   *
   * @type {string}
   * @memberof FeeReportDto
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof FeeReportDto
   */
  prefix?: string;
  /**
   *
   * @type {string}
   * @memberof FeeReportDto
   */
  feeCode?: string;
  /**
   *
   * @type {string}
   * @memberof FeeReportDto
   */
  serviceType?: FeeReportDtoServiceTypeEnum;
  /**
   *
   * @type {string}
   * @memberof FeeReportDto
   */
  feeMethod?: FeeReportDtoFeeMethodEnum;
  /**
   *
   * @type {string}
   * @memberof FeeReportDto
   */
  calculatePer?: FeeReportDtoCalculatePerEnum;
  /**
   *
   * @type {number}
   * @memberof FeeReportDto
   */
  amountTransaction?: number;
  /**
   *
   * @type {number}
   * @memberof FeeReportDto
   */
  feeMoney?: number;
  /**
   *
   * @type {number}
   * @memberof FeeReportDto
   */
  moneyVAT?: number;
  /**
   *
   * @type {number}
   * @memberof FeeReportDto
   */
  totalFeeMoney?: number;
  /**
   *
   * @type {string}
   * @memberof FeeReportDto
   */
  activeFeeDate?: string;
  /**
   *
   * @type {string}
   * @memberof FeeReportDto
   */
  time?: string;
}

export const FeeReportDtoServiceTypeEnum = {
  Cobo: "COBO",
  Pobo: "POBO",
  CoboInterCard: "COBO_INTER_CARD",
  CoboDebitDomesticCard: "COBO_DEBIT_DOMESTIC_CARD",
  CoboCreditDomesticCard: "COBO_CREDIT_DOMESTIC_CARD",
  PoboInterBank: "POBO_INTER_BANK",
} as const;

export type FeeReportDtoServiceTypeEnum =
  (typeof FeeReportDtoServiceTypeEnum)[keyof typeof FeeReportDtoServiceTypeEnum];
export const FeeReportDtoFeeMethodEnum = {
  Free: "FREE",
  FixedFee: "FIXED_FEE",
  BracketFee: "BRACKET_FEE",
} as const;

export type FeeReportDtoFeeMethodEnum =
  (typeof FeeReportDtoFeeMethodEnum)[keyof typeof FeeReportDtoFeeMethodEnum];
export const FeeReportDtoCalculatePerEnum = {
  PerMonth: "PER_MONTH",
  PerTransaction: "PER_TRANSACTION",
  None: "NONE",
} as const;

export type FeeReportDtoCalculatePerEnum =
  (typeof FeeReportDtoCalculatePerEnum)[keyof typeof FeeReportDtoCalculatePerEnum];

/**
 *
 * @export
 * @interface ForControlDto
 */
export interface ForControlDto {
  /**
   *
   * @type {string}
   * @memberof ForControlDto
   */
  createdBy?: string;
  /**
   *
   * @type {string}
   * @memberof ForControlDto
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof ForControlDto
   */
  modifiedBy?: string;
  /**
   *
   * @type {string}
   * @memberof ForControlDto
   */
  modifiedAt?: string;
  /**
   *
   * @type {number}
   * @memberof ForControlDto
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ForControlDto
   */
  forControlCode?: string;
  /**
   *
   * @type {string}
   * @memberof ForControlDto
   */
  merchantCode?: string;
  /**
   *
   * @type {string}
   * @memberof ForControlDto
   */
  merchantName?: string;
  /**
   *
   * @type {string}
   * @memberof ForControlDto
   */
  contact?: string;
  /**
   *
   * @type {string}
   * @memberof ForControlDto
   */
  reason?: string;
  /**
   *
   * @type {string}
   * @memberof ForControlDto
   */
  transactionDate?: string;
  /**
   *
   * @type {string}
   * @memberof ForControlDto
   */
  completeTransactionDate?: string;
  /**
   *
   * @type {string}
   * @memberof ForControlDto
   */
  txnNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ForControlDto
   */
  partnerTxnNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ForControlDto
   */
  bankTxnNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ForControlDto
   */
  bankName?: string;
  /**
   *
   * @type {number}
   * @memberof ForControlDto
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof ForControlDto
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof ForControlDto
   */
  serviceType?: ForControlDtoServiceTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ForControlDto
   */
  virtualAccount?: string;
  /**
   *
   * @type {string}
   * @memberof ForControlDto
   */
  actualAccount?: string;
  /**
   *
   * @type {string}
   * @memberof ForControlDto
   */
  status?: ForControlDtoStatusEnum;
  /**
   *
   * @type {string}
   * @memberof ForControlDto
   */
  note?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ForControlDto
   */
  documents?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ForControlDto
   */
  adminDocuments?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ForControlDto
   */
  transactionStatus?: ForControlDtoTransactionStatusEnum;
  /**
   *
   * @type {string}
   * @memberof ForControlDto
   */
  fromAccount?: string;
  /**
   *
   * @type {string}
   * @memberof ForControlDto
   */
  fromBin?: string;
  /**
   *
   * @type {string}
   * @memberof ForControlDto
   */
  accountNo?: string;
  /**
   *
   * @type {string}
   * @memberof ForControlDto
   */
  bin?: string;
}

export const ForControlDtoServiceTypeEnum = {
  Cobo: "COBO",
  Pobo: "POBO",
  CoboInterCard: "COBO_INTER_CARD",
  CoboDebitDomesticCard: "COBO_DEBIT_DOMESTIC_CARD",
  CoboCreditDomesticCard: "COBO_CREDIT_DOMESTIC_CARD",
  PoboInterBank: "POBO_INTER_BANK",
} as const;

export type ForControlDtoServiceTypeEnum =
  (typeof ForControlDtoServiceTypeEnum)[keyof typeof ForControlDtoServiceTypeEnum];
export const ForControlDtoStatusEnum = {
  Create: "CREATE",
  WaitingSolve: "WAITING_SOLVE",
  Solving: "SOLVING",
  Success: "SUCCESS",
  Deny: "DENY",
} as const;

export type ForControlDtoStatusEnum =
  (typeof ForControlDtoStatusEnum)[keyof typeof ForControlDtoStatusEnum];
export const ForControlDtoTransactionStatusEnum = {
  None: "NONE",
  Created: "CREATED",
  Success: "SUCCESS",
  Canceled: "CANCELED",
  Fail: "FAIL",
  Timeout: "TIMEOUT",
  Pending: "PENDING",
} as const;

export type ForControlDtoTransactionStatusEnum =
  (typeof ForControlDtoTransactionStatusEnum)[keyof typeof ForControlDtoTransactionStatusEnum];

/**
 *
 * @export
 * @interface ForControlHistoryDto
 */
export interface ForControlHistoryDto {
  /**
   *
   * @type {string}
   * @memberof ForControlHistoryDto
   */
  forControlCommand?: ForControlHistoryDtoForControlCommandEnum;
  /**
   *
   * @type {string}
   * @memberof ForControlHistoryDto
   */
  note?: string;
  /**
   *
   * @type {string}
   * @memberof ForControlHistoryDto
   */
  forControlStatus?: ForControlHistoryDtoForControlStatusEnum;
  /**
   *
   * @type {string}
   * @memberof ForControlHistoryDto
   */
  createdBy?: string;
  /**
   *
   * @type {string}
   * @memberof ForControlHistoryDto
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof ForControlHistoryDto
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof ForControlHistoryDto
   */
  phone?: string;
}

export const ForControlHistoryDtoForControlCommandEnum = {
  Create: "CREATE",
  SendApprove: "SEND_APPROVE",
  Solving: "SOLVING",
  Approve: "APPROVE",
  Deny: "DENY",
} as const;

export type ForControlHistoryDtoForControlCommandEnum =
  (typeof ForControlHistoryDtoForControlCommandEnum)[keyof typeof ForControlHistoryDtoForControlCommandEnum];
export const ForControlHistoryDtoForControlStatusEnum = {
  Create: "CREATE",
  WaitingSolve: "WAITING_SOLVE",
  Solving: "SOLVING",
  Success: "SUCCESS",
  Deny: "DENY",
} as const;

export type ForControlHistoryDtoForControlStatusEnum =
  (typeof ForControlHistoryDtoForControlStatusEnum)[keyof typeof ForControlHistoryDtoForControlStatusEnum];

/**
 *
 * @export
 * @interface ForControlResponse
 */
export interface ForControlResponse {
  /**
   *
   * @type {ForControlDto}
   * @memberof ForControlResponse
   */
  forControl?: ForControlDto;
  /**
   *
   * @type {AdminResponse}
   * @memberof ForControlResponse
   */
  admin?: AdminResponse;
}
/**
 *
 * @export
 * @interface ForgotPasswordAdminRequest
 */
export interface ForgotPasswordAdminRequest {
  /**
   *
   * @type {string}
   * @memberof ForgotPasswordAdminRequest
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof ForgotPasswordAdminRequest
   */
  email?: string;
}
/**
 *
 * @export
 * @interface ForgotPasswordCheckOTPRequest
 */
export interface ForgotPasswordCheckOTPRequest {
  /**
   *
   * @type {string}
   * @memberof ForgotPasswordCheckOTPRequest
   */
  sessionId: string;
  /**
   *
   * @type {string}
   * @memberof ForgotPasswordCheckOTPRequest
   */
  otp: string;
  /**
   *
   * @type {string}
   * @memberof ForgotPasswordCheckOTPRequest
   */
  phone: string;
}
/**
 *
 * @export
 * @interface ForgotPasswordCheckOTPResponse
 */
export interface ForgotPasswordCheckOTPResponse {
  /**
   *
   * @type {string}
   * @memberof ForgotPasswordCheckOTPResponse
   */
  sessionId?: string;
  /**
   *
   * @type {string}
   * @memberof ForgotPasswordCheckOTPResponse
   */
  token?: string;
  /**
   *
   * @type {number}
   * @memberof ForgotPasswordCheckOTPResponse
   */
  timeout?: number;
}
/**
 *
 * @export
 * @interface ForgotPasswordCheckPhoneResponse
 */
export interface ForgotPasswordCheckPhoneResponse {
  /**
   *
   * @type {string}
   * @memberof ForgotPasswordCheckPhoneResponse
   */
  sessionId?: string;
  /**
   *
   * @type {number}
   * @memberof ForgotPasswordCheckPhoneResponse
   */
  timeout?: number;
}
/**
 *
 * @export
 * @interface ForgotPasswordSubmitRequest
 */
export interface ForgotPasswordSubmitRequest {
  /**
   *
   * @type {string}
   * @memberof ForgotPasswordSubmitRequest
   */
  sessionId: string;
  /**
   *
   * @type {string}
   * @memberof ForgotPasswordSubmitRequest
   */
  token: string;
  /**
   *
   * @type {string}
   * @memberof ForgotPasswordSubmitRequest
   */
  phone: string;
  /**
   *
   * @type {string}
   * @memberof ForgotPasswordSubmitRequest
   */
  password: string;
}
/**
 *
 * @export
 * @interface GetAllFeeNameDtoResponse
 */
export interface GetAllFeeNameDtoResponse {
  /**
   *
   * @type {Array<FeeNameDto>}
   * @memberof GetAllFeeNameDtoResponse
   */
  fee?: Array<FeeNameDto>;
}
/**
 *
 * @export
 * @interface GetAllFeeReportResponse
 */
export interface GetAllFeeReportResponse {
  /**
   *
   * @type {PageResponseMonthlyFeeReportDto}
   * @memberof GetAllFeeReportResponse
   */
  feeReports?: PageResponseMonthlyFeeReportDto;
  /**
   *
   * @type {number}
   * @memberof GetAllFeeReportResponse
   */
  totalMoney?: number;
}
/**
 *
 * @export
 * @interface GetAllPaySubscriptionPlanCacheResponse
 */
export interface GetAllPaySubscriptionPlanCacheResponse {
  /**
   *
   * @type {Array<PaySubscriptionPlanResponse>}
   * @memberof GetAllPaySubscriptionPlanCacheResponse
   */
  plans?: Array<PaySubscriptionPlanResponse>;
}
/**
 *
 * @export
 * @interface GetAllPayboxTransactionsResponse
 */
export interface GetAllPayboxTransactionsResponse {
  /**
   *
   * @type {PageResponsePayboxTransactionResponse}
   * @memberof GetAllPayboxTransactionsResponse
   */
  transactions?: PageResponsePayboxTransactionResponse;
}
/**
 *
 * @export
 * @interface GetAllPermissionResponse
 */
export interface GetAllPermissionResponse {
  /**
   *
   * @type {Array<PermissionGroup>}
   * @memberof GetAllPermissionResponse
   */
  permissionGroups?: Array<PermissionGroup>;
}
/**
 *
 * @export
 * @interface GetAllPortalPermissionResponse
 */
export interface GetAllPortalPermissionResponse {
  /**
   *
   * @type {Array<PermissionResponse>}
   * @memberof GetAllPortalPermissionResponse
   */
  permissions?: Array<PermissionResponse>;
}
/**
 *
 * @export
 * @interface GetBalanceBankAccountResponse
 */
export interface GetBalanceBankAccountResponse {
  /**
   *
   * @type {string}
   * @memberof GetBalanceBankAccountResponse
   */
  accountNumber?: string;
  /**
   *
   * @type {string}
   * @memberof GetBalanceBankAccountResponse
   */
  accountName?: string;
  /**
   *
   * @type {string}
   * @memberof GetBalanceBankAccountResponse
   */
  currency?: string;
  /**
   *
   * @type {number}
   * @memberof GetBalanceBankAccountResponse
   */
  balance?: number;
}
/**
 *
 * @export
 * @interface GetBankTransferInfoResponse
 */
export interface GetBankTransferInfoResponse {
  /**
   *
   * @type {string}
   * @memberof GetBankTransferInfoResponse
   */
  shopName?: string;
  /**
   *
   * @type {string}
   * @memberof GetBankTransferInfoResponse
   */
  merchantCode?: string;
  /**
   *
   * @type {string}
   * @memberof GetBankTransferInfoResponse
   */
  merchantLogoUrl?: string;
  /**
   *
   * @type {number}
   * @memberof GetBankTransferInfoResponse
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof GetBankTransferInfoResponse
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof GetBankTransferInfoResponse
   */
  bankName?: string;
  /**
   *
   * @type {string}
   * @memberof GetBankTransferInfoResponse
   */
  accountNo?: string;
  /**
   *
   * @type {string}
   * @memberof GetBankTransferInfoResponse
   */
  accountName?: string;
  /**
   *
   * @type {string}
   * @memberof GetBankTransferInfoResponse
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof GetBankTransferInfoResponse
   */
  successUrl?: string;
  /**
   *
   * @type {string}
   * @memberof GetBankTransferInfoResponse
   */
  failUrl?: string;
  /**
   *
   * @type {number}
   * @memberof GetBankTransferInfoResponse
   */
  redirectAfter?: number;
  /**
   *
   * @type {string}
   * @memberof GetBankTransferInfoResponse
   */
  status?: GetBankTransferInfoResponseStatusEnum;
}

export const GetBankTransferInfoResponseStatusEnum = {
  None: "NONE",
  Created: "CREATED",
  Success: "SUCCESS",
  Canceled: "CANCELED",
  Fail: "FAIL",
  Timeout: "TIMEOUT",
  Pending: "PENDING",
} as const;

export type GetBankTransferInfoResponseStatusEnum =
  (typeof GetBankTransferInfoResponseStatusEnum)[keyof typeof GetBankTransferInfoResponseStatusEnum];

/**
 *
 * @export
 * @interface GetBeneficiaryResponse
 */
export interface GetBeneficiaryResponse {
  /**
   *
   * @type {string}
   * @memberof GetBeneficiaryResponse
   */
  accountName?: string;
}
/**
 *
 * @export
 * @interface GetExternalButtonResponse
 */
export interface GetExternalButtonResponse {
  /**
   *
   * @type {string}
   * @memberof GetExternalButtonResponse
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof GetExternalButtonResponse
   */
  label?: string;
  /**
   *
   * @type {string}
   * @memberof GetExternalButtonResponse
   */
  theme?: GetExternalButtonResponseThemeEnum;
  /**
   *
   * @type {string}
   * @memberof GetExternalButtonResponse
   */
  referencePageUrl?: string;
}

export const GetExternalButtonResponseThemeEnum = {
  Light: "LIGHT",
  Dark: "DARK",
} as const;

export type GetExternalButtonResponseThemeEnum =
  (typeof GetExternalButtonResponseThemeEnum)[keyof typeof GetExternalButtonResponseThemeEnum];

/**
 *
 * @export
 * @interface GetFeeInfoResponse
 */
export interface GetFeeInfoResponse {
  /**
   *
   * @type {boolean}
   * @memberof GetFeeInfoResponse
   */
  status?: boolean;
  /**
   *
   * @type {FeeDto}
   * @memberof GetFeeInfoResponse
   */
  fee?: FeeDto;
}
/**
 *
 * @export
 * @interface GetLinkedAccountsFromBankResponse
 */
export interface GetLinkedAccountsFromBankResponse {
  /**
   *
   * @type {Array<LinkAccountInfo>}
   * @memberof GetLinkedAccountsFromBankResponse
   */
  bankAccounts?: Array<LinkAccountInfo>;
}
/**
 *
 * @export
 * @interface GetListAdminResponse
 */
export interface GetListAdminResponse {
  /**
   *
   * @type {string}
   * @memberof GetListAdminResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof GetListAdminResponse
   */
  username?: string;
  /**
   *
   * @type {string}
   * @memberof GetListAdminResponse
   */
  fullName?: string;
  /**
   *
   * @type {string}
   * @memberof GetListAdminResponse
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof GetListAdminResponse
   */
  status?: GetListAdminResponseStatusEnum;
  /**
   *
   * @type {string}
   * @memberof GetListAdminResponse
   */
  phone?: string;
  /**
   *
   * @type {boolean}
   * @memberof GetListAdminResponse
   */
  loggedIn?: boolean;
  /**
   *
   * @type {string}
   * @memberof GetListAdminResponse
   */
  staffId?: string;
  /**
   *
   * @type {string}
   * @memberof GetListAdminResponse
   */
  office?: GetListAdminResponseOfficeEnum;
  /**
   *
   * @type {string}
   * @memberof GetListAdminResponse
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof GetListAdminResponse
   */
  roleGroupName?: string;
  /**
   *
   * @type {number}
   * @memberof GetListAdminResponse
   */
  roleGroupId?: number;
}

export const GetListAdminResponseStatusEnum = {
  Blocked: "BLOCKED",
  Active: "ACTIVE",
  Inactive: "INACTIVE",
  NotLoggedIn: "NOT_LOGGED_IN",
} as const;

export type GetListAdminResponseStatusEnum =
  (typeof GetListAdminResponseStatusEnum)[keyof typeof GetListAdminResponseStatusEnum];
export const GetListAdminResponseOfficeEnum = {
  Headquarter: "HEADQUARTER",
  Branch: "BRANCH",
  TransactionOffice: "TRANSACTION_OFFICE",
} as const;

export type GetListAdminResponseOfficeEnum =
  (typeof GetListAdminResponseOfficeEnum)[keyof typeof GetListAdminResponseOfficeEnum];

/**
 *
 * @export
 * @interface GetListAppResponse
 */
export interface GetListAppResponse {
  /**
   *
   * @type {Array<AppResponse>}
   * @memberof GetListAppResponse
   */
  apps?: Array<AppResponse>;
}
/**
 *
 * @export
 * @interface GetListBankAccountResponse
 */
export interface GetListBankAccountResponse {
  /**
   *
   * @type {Array<BankAccountDto>}
   * @memberof GetListBankAccountResponse
   */
  bankAccounts?: Array<BankAccountDto>;
}
/**
 *
 * @export
 * @interface GetListPaymentMethodResponse
 */
export interface GetListPaymentMethodResponse {
  /**
   *
   * @type {Array<PaymentMethodInfo>}
   * @memberof GetListPaymentMethodResponse
   */
  payments?: Array<PaymentMethodInfo>;
}
/**
 *
 * @export
 * @interface GetMerchantCredentialResponse
 */
export interface GetMerchantCredentialResponse {
  /**
   *
   * @type {string}
   * @memberof GetMerchantCredentialResponse
   */
  encryptKey?: string;
  /**
   *
   * @type {string}
   * @memberof GetMerchantCredentialResponse
   */
  secretKey?: string;
  /**
   *
   * @type {string}
   * @memberof GetMerchantCredentialResponse
   */
  clientId?: string;
  /**
   *
   * @type {string}
   * @memberof GetMerchantCredentialResponse
   */
  status?: GetMerchantCredentialResponseStatusEnum;
  /**
   *
   * @type {string}
   * @memberof GetMerchantCredentialResponse
   */
  host?: string;
}

export const GetMerchantCredentialResponseStatusEnum = {
  Active: "ACTIVE",
  Revoke: "REVOKE",
} as const;

export type GetMerchantCredentialResponseStatusEnum =
  (typeof GetMerchantCredentialResponseStatusEnum)[keyof typeof GetMerchantCredentialResponseStatusEnum];

/**
 *
 * @export
 * @interface GetMerchantEkycInfoResponse
 */
export interface GetMerchantEkycInfoResponse {
  /**
   *
   * @type {string}
   * @memberof GetMerchantEkycInfoResponse
   */
  merchantName?: string;
  /**
   *
   * @type {string}
   * @memberof GetMerchantEkycInfoResponse
   */
  merchantCode?: string;
  /**
   *
   * @type {string}
   * @memberof GetMerchantEkycInfoResponse
   */
  businessType?: GetMerchantEkycInfoResponseBusinessTypeEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof GetMerchantEkycInfoResponse
   */
  businessProducts?: Array<GetMerchantEkycInfoResponseBusinessProductsEnum>;
  /**
   *
   * @type {string}
   * @memberof GetMerchantEkycInfoResponse
   */
  revenueType?: GetMerchantEkycInfoResponseRevenueTypeEnum;
  /**
   *
   * @type {string}
   * @memberof GetMerchantEkycInfoResponse
   */
  status?: GetMerchantEkycInfoResponseStatusEnum;
  /**
   *
   * @type {string}
   * @memberof GetMerchantEkycInfoResponse
   */
  disapprovedReason?: string;
}

export const GetMerchantEkycInfoResponseBusinessTypeEnum = {
  Personal: "PERSONAL",
  Organization: "ORGANIZATION",
} as const;

export type GetMerchantEkycInfoResponseBusinessTypeEnum =
  (typeof GetMerchantEkycInfoResponseBusinessTypeEnum)[keyof typeof GetMerchantEkycInfoResponseBusinessTypeEnum];
export const GetMerchantEkycInfoResponseBusinessProductsEnum = {
  VehicleSale: "VEHICLE_SALE",
  Logistics: "LOGISTICS",
  ResidentAndFoodService: "RESIDENT_AND_FOOD_SERVICE",
  InformationAndMedia: "INFORMATION_AND_MEDIA",
  FinanceAndInsurance: "FINANCE_AND_INSURANCE",
  RealEstate: "REAL_ESTATE",
  AdministrativeAndSupportService: "ADMINISTRATIVE_AND_SUPPORT_SERVICE",
  Education: "EDUCATION",
  PublicHealth: "PUBLIC_HEALTH",
  HealthCareAndBeauty: "HEALTH_CARE_AND_BEAUTY",
  ArtAndEntertainment: "ART_AND_ENTERTAINMENT",
  Other: "OTHER",
} as const;

export type GetMerchantEkycInfoResponseBusinessProductsEnum =
  (typeof GetMerchantEkycInfoResponseBusinessProductsEnum)[keyof typeof GetMerchantEkycInfoResponseBusinessProductsEnum];
export const GetMerchantEkycInfoResponseRevenueTypeEnum = {
  _1: "REVENUE_TYPE_1",
  _2: "REVENUE_TYPE_2",
  _3: "REVENUE_TYPE_3",
  _4: "REVENUE_TYPE_4",
} as const;

export type GetMerchantEkycInfoResponseRevenueTypeEnum =
  (typeof GetMerchantEkycInfoResponseRevenueTypeEnum)[keyof typeof GetMerchantEkycInfoResponseRevenueTypeEnum];
export const GetMerchantEkycInfoResponseStatusEnum = {
  Created: "CREATED",
  SubmitInfo: "SUBMIT_INFO",
  LinkedAccount: "LINKED_ACCOUNT",
  Inactive: "INACTIVE",
  Active: "ACTIVE",
  Denied: "DENIED",
  OpenAccount: "OPEN_ACCOUNT",
  Block: "BLOCK",
} as const;

export type GetMerchantEkycInfoResponseStatusEnum =
  (typeof GetMerchantEkycInfoResponseStatusEnum)[keyof typeof GetMerchantEkycInfoResponseStatusEnum];

/**
 *
 * @export
 * @interface GetMerchantIdResponse
 */
export interface GetMerchantIdResponse {
  /**
   *
   * @type {string}
   * @memberof GetMerchantIdResponse
   */
  merchantId?: string;
}
/**
 *
 * @export
 * @interface GetMerchantInfoFromKeycloakResponse
 */
export interface GetMerchantInfoFromKeycloakResponse {
  /**
   *
   * @type {string}
   * @memberof GetMerchantInfoFromKeycloakResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof GetMerchantInfoFromKeycloakResponse
   */
  fullName?: string;
  /**
   *
   * @type {string}
   * @memberof GetMerchantInfoFromKeycloakResponse
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof GetMerchantInfoFromKeycloakResponse
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof GetMerchantInfoFromKeycloakResponse
   */
  username?: string;
  /**
   *
   * @type {string}
   * @memberof GetMerchantInfoFromKeycloakResponse
   */
  merchantId?: string;
  /**
   *
   * @type {string}
   * @memberof GetMerchantInfoFromKeycloakResponse
   */
  merchantName?: string;
}
/**
 *
 * @export
 * @interface GetMerchantResponse
 */
export interface GetMerchantResponse {
  /**
   *
   * @type {MerchantDto}
   * @memberof GetMerchantResponse
   */
  merchant?: MerchantDto;
}
/**
 *
 * @export
 * @interface GetNapasDetailSessionResponse
 */
export interface GetNapasDetailSessionResponse {
  /**
   *
   * @type {Array<NapasSettleDto>}
   * @memberof GetNapasDetailSessionResponse
   */
  items?: Array<NapasSettleDto>;
  /**
   *
   * @type {number}
   * @memberof GetNapasDetailSessionResponse
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof GetNapasDetailSessionResponse
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof GetNapasDetailSessionResponse
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof GetNapasDetailSessionResponse
   */
  totalPage?: number;
  /**
   *
   * @type {number}
   * @memberof GetNapasDetailSessionResponse
   */
  totalAmount?: number;
  /**
   *
   * @type {number}
   * @memberof GetNapasDetailSessionResponse
   */
  totalChargeAmount?: number;
  /**
   *
   * @type {number}
   * @memberof GetNapasDetailSessionResponse
   */
  totalVatChargeAmount?: number;
  /**
   *
   * @type {number}
   * @memberof GetNapasDetailSessionResponse
   */
  totalActualAmount?: number;
  /**
   *
   * @type {number}
   * @memberof GetNapasDetailSessionResponse
   */
  totalTransaction?: number;
}
/**
 *
 * @export
 * @interface GetPageListPosPortalResponse
 */
export interface GetPageListPosPortalResponse {
  /**
   *
   * @type {string}
   * @memberof GetPageListPosPortalResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof GetPageListPosPortalResponse
   */
  deviceId?: string;
  /**
   *
   * @type {string}
   * @memberof GetPageListPosPortalResponse
   */
  model?: GetPageListPosPortalResponseModelEnum;
  /**
   *
   * @type {string}
   * @memberof GetPageListPosPortalResponse
   */
  status?: GetPageListPosPortalResponseStatusEnum;
  /**
   *
   * @type {number}
   * @memberof GetPageListPosPortalResponse
   */
  revenue?: number;
  /**
   *
   * @type {number}
   * @memberof GetPageListPosPortalResponse
   */
  transactionNotSettlementNumber?: number;
}

export const GetPageListPosPortalResponseModelEnum = {
  P8: "P8",
  P12: "P12",
} as const;

export type GetPageListPosPortalResponseModelEnum =
  (typeof GetPageListPosPortalResponseModelEnum)[keyof typeof GetPageListPosPortalResponseModelEnum];
export const GetPageListPosPortalResponseStatusEnum = {
  Active: "ACTIVE",
  Inactive: "INACTIVE",
} as const;

export type GetPageListPosPortalResponseStatusEnum =
  (typeof GetPageListPosPortalResponseStatusEnum)[keyof typeof GetPageListPosPortalResponseStatusEnum];

/**
 *
 * @export
 * @interface GetPayLinkBankTransferResponse
 */
export interface GetPayLinkBankTransferResponse {
  /**
   *
   * @type {string}
   * @memberof GetPayLinkBankTransferResponse
   */
  shopName?: string;
  /**
   *
   * @type {string}
   * @memberof GetPayLinkBankTransferResponse
   */
  merchantCode?: string;
  /**
   *
   * @type {string}
   * @memberof GetPayLinkBankTransferResponse
   */
  merchantLogoUrl?: string;
  /**
   *
   * @type {number}
   * @memberof GetPayLinkBankTransferResponse
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof GetPayLinkBankTransferResponse
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof GetPayLinkBankTransferResponse
   */
  bankName?: string;
  /**
   *
   * @type {string}
   * @memberof GetPayLinkBankTransferResponse
   */
  accountNo?: string;
  /**
   *
   * @type {string}
   * @memberof GetPayLinkBankTransferResponse
   */
  accountName?: string;
  /**
   *
   * @type {string}
   * @memberof GetPayLinkBankTransferResponse
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof GetPayLinkBankTransferResponse
   */
  successUrl?: string;
  /**
   *
   * @type {string}
   * @memberof GetPayLinkBankTransferResponse
   */
  failUrl?: string;
  /**
   *
   * @type {number}
   * @memberof GetPayLinkBankTransferResponse
   */
  redirectAfter?: number;
  /**
   *
   * @type {string}
   * @memberof GetPayLinkBankTransferResponse
   */
  status?: GetPayLinkBankTransferResponseStatusEnum;
}

export const GetPayLinkBankTransferResponseStatusEnum = {
  None: "NONE",
  Created: "CREATED",
  Success: "SUCCESS",
  Canceled: "CANCELED",
  Fail: "FAIL",
  Timeout: "TIMEOUT",
  Pending: "PENDING",
} as const;

export type GetPayLinkBankTransferResponseStatusEnum =
  (typeof GetPayLinkBankTransferResponseStatusEnum)[keyof typeof GetPayLinkBankTransferResponseStatusEnum];

/**
 *
 * @export
 * @interface GetPayLinkNapasResponse
 */
export interface GetPayLinkNapasResponse {
  /**
   *
   * @type {string}
   * @memberof GetPayLinkNapasResponse
   */
  shopName?: string;
  /**
   *
   * @type {string}
   * @memberof GetPayLinkNapasResponse
   */
  merchantCode?: string;
  /**
   *
   * @type {string}
   * @memberof GetPayLinkNapasResponse
   */
  merchantLogoUrl?: string;
  /**
   *
   * @type {string}
   * @memberof GetPayLinkNapasResponse
   */
  formContent?: string;
  /**
   *
   * @type {number}
   * @memberof GetPayLinkNapasResponse
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof GetPayLinkNapasResponse
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof GetPayLinkNapasResponse
   */
  successUrl?: string;
  /**
   *
   * @type {string}
   * @memberof GetPayLinkNapasResponse
   */
  failUrl?: string;
  /**
   *
   * @type {number}
   * @memberof GetPayLinkNapasResponse
   */
  redirectAfter?: number;
  /**
   *
   * @type {string}
   * @memberof GetPayLinkNapasResponse
   */
  status?: GetPayLinkNapasResponseStatusEnum;
  /**
   *
   * @type {string}
   * @memberof GetPayLinkNapasResponse
   */
  accountNo?: string;
  /**
   *
   * @type {string}
   * @memberof GetPayLinkNapasResponse
   */
  accountName?: string;
  /**
   *
   * @type {ShopSettingResponse}
   * @memberof GetPayLinkNapasResponse
   */
  shopSetting?: ShopSettingResponse;
}

export const GetPayLinkNapasResponseStatusEnum = {
  None: "NONE",
  Created: "CREATED",
  Success: "SUCCESS",
  Canceled: "CANCELED",
  Fail: "FAIL",
  Timeout: "TIMEOUT",
  Pending: "PENDING",
} as const;

export type GetPayLinkNapasResponseStatusEnum =
  (typeof GetPayLinkNapasResponseStatusEnum)[keyof typeof GetPayLinkNapasResponseStatusEnum];

/**
 *
 * @export
 * @interface GetPayLinkVietQRResponse
 */
export interface GetPayLinkVietQRResponse {
  /**
   *
   * @type {string}
   * @memberof GetPayLinkVietQRResponse
   */
  shopName?: string;
  /**
   *
   * @type {string}
   * @memberof GetPayLinkVietQRResponse
   */
  merchantCode?: string;
  /**
   *
   * @type {string}
   * @memberof GetPayLinkVietQRResponse
   */
  merchantLogoUrl?: string;
  /**
   *
   * @type {string}
   * @memberof GetPayLinkVietQRResponse
   */
  qrContent?: string;
  /**
   *
   * @type {number}
   * @memberof GetPayLinkVietQRResponse
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof GetPayLinkVietQRResponse
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof GetPayLinkVietQRResponse
   */
  successUrl?: string;
  /**
   *
   * @type {string}
   * @memberof GetPayLinkVietQRResponse
   */
  failUrl?: string;
  /**
   *
   * @type {number}
   * @memberof GetPayLinkVietQRResponse
   */
  redirectAfter?: number;
  /**
   *
   * @type {string}
   * @memberof GetPayLinkVietQRResponse
   */
  status?: GetPayLinkVietQRResponseStatusEnum;
  /**
   *
   * @type {string}
   * @memberof GetPayLinkVietQRResponse
   */
  accountNo?: string;
  /**
   *
   * @type {string}
   * @memberof GetPayLinkVietQRResponse
   */
  accountName?: string;
  /**
   *
   * @type {ShopSettingResponse}
   * @memberof GetPayLinkVietQRResponse
   */
  shopSetting?: ShopSettingResponse;
}

export const GetPayLinkVietQRResponseStatusEnum = {
  None: "NONE",
  Created: "CREATED",
  Success: "SUCCESS",
  Canceled: "CANCELED",
  Fail: "FAIL",
  Timeout: "TIMEOUT",
  Pending: "PENDING",
} as const;

export type GetPayLinkVietQRResponseStatusEnum =
  (typeof GetPayLinkVietQRResponseStatusEnum)[keyof typeof GetPayLinkVietQRResponseStatusEnum];

/**
 *
 * @export
 * @interface GetPosHistoryTransactionPortalResponse
 */
export interface GetPosHistoryTransactionPortalResponse {
  /**
   *
   * @type {string}
   * @memberof GetPosHistoryTransactionPortalResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof GetPosHistoryTransactionPortalResponse
   */
  createdAt?: string;
  /**
   *
   * @type {number}
   * @memberof GetPosHistoryTransactionPortalResponse
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof GetPosHistoryTransactionPortalResponse
   */
  status?: GetPosHistoryTransactionPortalResponseStatusEnum;
  /**
   *
   * @type {boolean}
   * @memberof GetPosHistoryTransactionPortalResponse
   */
  finish?: boolean;
  /**
   *
   * @type {string}
   * @memberof GetPosHistoryTransactionPortalResponse
   */
  customerName?: string;
  /**
   *
   * @type {string}
   * @memberof GetPosHistoryTransactionPortalResponse
   */
  staffName?: string;
  /**
   *
   * @type {string}
   * @memberof GetPosHistoryTransactionPortalResponse
   */
  deviceId?: string;
  /**
   *
   * @type {string}
   * @memberof GetPosHistoryTransactionPortalResponse
   */
  accountNumber?: string;
  /**
   *
   * @type {string}
   * @memberof GetPosHistoryTransactionPortalResponse
   */
  settlementTime?: string;
}

export const GetPosHistoryTransactionPortalResponseStatusEnum = {
  Success: "SUCCESS",
  Failed: "FAILED",
  Timeout: "TIMEOUT",
  Cancel: "CANCEL",
  Refunded: "REFUNDED",
  Created: "CREATED",
} as const;

export type GetPosHistoryTransactionPortalResponseStatusEnum =
  (typeof GetPosHistoryTransactionPortalResponseStatusEnum)[keyof typeof GetPosHistoryTransactionPortalResponseStatusEnum];

/**
 *
 * @export
 * @interface GetReportResponse
 */
export interface GetReportResponse {
  /**
   *
   * @type {Array<ReportItem>}
   * @memberof GetReportResponse
   */
  items?: Array<ReportItem>;
}
/**
 *
 * @export
 * @interface GetStatisticInfoResponse
 */
export interface GetStatisticInfoResponse {
  /**
   *
   * @type {number}
   * @memberof GetStatisticInfoResponse
   */
  totalRevenue?: number;
  /**
   *
   * @type {number}
   * @memberof GetStatisticInfoResponse
   */
  numOfSuccessfulTransactions?: number;
  /**
   *
   * @type {number}
   * @memberof GetStatisticInfoResponse
   */
  numOfFailedTransactions?: number;
  /**
   *
   * @type {number}
   * @memberof GetStatisticInfoResponse
   */
  numOfCancelledTransactions?: number;
}
/**
 *
 * @export
 * @interface GetSubmitInfoResponse
 */
export interface GetSubmitInfoResponse {
  /**
   *
   * @type {string}
   * @memberof GetSubmitInfoResponse
   */
  businessType?: GetSubmitInfoResponseBusinessTypeEnum;
  /**
   *
   * @type {string}
   * @memberof GetSubmitInfoResponse
   */
  name?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof GetSubmitInfoResponse
   */
  businessProducts?: Array<GetSubmitInfoResponseBusinessProductsEnum>;
  /**
   *
   * @type {string}
   * @memberof GetSubmitInfoResponse
   */
  product?: string;
  /**
   *
   * @type {number}
   * @memberof GetSubmitInfoResponse
   */
  maxPrice?: number;
  /**
   *
   * @type {string}
   * @memberof GetSubmitInfoResponse
   */
  revenueType?: GetSubmitInfoResponseRevenueTypeEnum;
  /**
   *
   * @type {string}
   * @memberof GetSubmitInfoResponse
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof GetSubmitInfoResponse
   */
  website?: string;
  /**
   *
   * @type {string}
   * @memberof GetSubmitInfoResponse
   */
  address?: string;
  /**
   *
   * @type {PersonalMerchantDto}
   * @memberof GetSubmitInfoResponse
   */
  personalMerchant?: PersonalMerchantDto;
  /**
   *
   * @type {string}
   * @memberof GetSubmitInfoResponse
   */
  taxIdNo?: string;
  /**
   *
   * @type {EnterpriseMerchantDto}
   * @memberof GetSubmitInfoResponse
   */
  enterpriseMerchant?: EnterpriseMerchantDto;
  /**
   *
   * @type {string}
   * @memberof GetSubmitInfoResponse
   */
  chargeType?: GetSubmitInfoResponseChargeTypeEnum;
}

export const GetSubmitInfoResponseBusinessTypeEnum = {
  Personal: "PERSONAL",
  Organization: "ORGANIZATION",
} as const;

export type GetSubmitInfoResponseBusinessTypeEnum =
  (typeof GetSubmitInfoResponseBusinessTypeEnum)[keyof typeof GetSubmitInfoResponseBusinessTypeEnum];
export const GetSubmitInfoResponseBusinessProductsEnum = {
  VehicleSale: "VEHICLE_SALE",
  Logistics: "LOGISTICS",
  ResidentAndFoodService: "RESIDENT_AND_FOOD_SERVICE",
  InformationAndMedia: "INFORMATION_AND_MEDIA",
  FinanceAndInsurance: "FINANCE_AND_INSURANCE",
  RealEstate: "REAL_ESTATE",
  AdministrativeAndSupportService: "ADMINISTRATIVE_AND_SUPPORT_SERVICE",
  Education: "EDUCATION",
  PublicHealth: "PUBLIC_HEALTH",
  HealthCareAndBeauty: "HEALTH_CARE_AND_BEAUTY",
  ArtAndEntertainment: "ART_AND_ENTERTAINMENT",
  Other: "OTHER",
} as const;

export type GetSubmitInfoResponseBusinessProductsEnum =
  (typeof GetSubmitInfoResponseBusinessProductsEnum)[keyof typeof GetSubmitInfoResponseBusinessProductsEnum];
export const GetSubmitInfoResponseRevenueTypeEnum = {
  _1: "REVENUE_TYPE_1",
  _2: "REVENUE_TYPE_2",
  _3: "REVENUE_TYPE_3",
  _4: "REVENUE_TYPE_4",
} as const;

export type GetSubmitInfoResponseRevenueTypeEnum =
  (typeof GetSubmitInfoResponseRevenueTypeEnum)[keyof typeof GetSubmitInfoResponseRevenueTypeEnum];
export const GetSubmitInfoResponseChargeTypeEnum = {
  Immediately: "IMMEDIATELY",
  Later: "LATER",
} as const;

export type GetSubmitInfoResponseChargeTypeEnum =
  (typeof GetSubmitInfoResponseChargeTypeEnum)[keyof typeof GetSubmitInfoResponseChargeTypeEnum];

/**
 *
 * @export
 * @interface GetTransactionRequest
 */
export interface GetTransactionRequest {
  /**
   *
   * @type {number}
   * @memberof GetTransactionRequest
   */
  size?: number;
  /**
   *
   * @type {number}
   * @memberof GetTransactionRequest
   */
  page?: number;
  /**
   * null nếu muốn lấy toàn bộ
   * @type {number}
   * @memberof GetTransactionRequest
   */
  order?: number;
  /**
   * null nếu muốn lấy toàn bộ
   * @type {string}
   * @memberof GetTransactionRequest
   */
  bankAccountNo?: string;
  /**
   *
   * @type {string}
   * @memberof GetTransactionRequest
   */
  fromDate?: string;
  /**
   *
   * @type {string}
   * @memberof GetTransactionRequest
   */
  toDate?: string;
}
/**
 *
 * @export
 * @interface GetTransactionStatisticResponse
 */
export interface GetTransactionStatisticResponse {
  /**
   *
   * @type {Array<TransactionDetailResponse>}
   * @memberof GetTransactionStatisticResponse
   */
  transactions?: Array<TransactionDetailResponse>;
}
/**
 *
 * @export
 * @interface GetVietQRInfoResponse
 */
export interface GetVietQRInfoResponse {
  /**
   *
   * @type {string}
   * @memberof GetVietQRInfoResponse
   */
  shopName?: string;
  /**
   *
   * @type {string}
   * @memberof GetVietQRInfoResponse
   */
  merchantCode?: string;
  /**
   *
   * @type {string}
   * @memberof GetVietQRInfoResponse
   */
  merchantLogoUrl?: string;
  /**
   *
   * @type {string}
   * @memberof GetVietQRInfoResponse
   */
  qrContent?: string;
  /**
   *
   * @type {number}
   * @memberof GetVietQRInfoResponse
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof GetVietQRInfoResponse
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof GetVietQRInfoResponse
   */
  successUrl?: string;
  /**
   *
   * @type {string}
   * @memberof GetVietQRInfoResponse
   */
  failUrl?: string;
  /**
   *
   * @type {number}
   * @memberof GetVietQRInfoResponse
   */
  redirectAfter?: number;
  /**
   *
   * @type {string}
   * @memberof GetVietQRInfoResponse
   */
  status?: GetVietQRInfoResponseStatusEnum;
  /**
   *
   * @type {string}
   * @memberof GetVietQRInfoResponse
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof GetVietQRInfoResponse
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof GetVietQRInfoResponse
   */
  bankAccountName?: string;
  /**
   *
   * @type {string}
   * @memberof GetVietQRInfoResponse
   */
  bankAccountNo?: string;
  /**
   *
   * @type {string}
   * @memberof GetVietQRInfoResponse
   */
  virtualAccount?: string;
  /**
   *
   * @type {ShopSettingResponse}
   * @memberof GetVietQRInfoResponse
   */
  shopSetting?: ShopSettingResponse;
}

export const GetVietQRInfoResponseStatusEnum = {
  None: "NONE",
  Created: "CREATED",
  Success: "SUCCESS",
  Canceled: "CANCELED",
  Fail: "FAIL",
  Timeout: "TIMEOUT",
  Pending: "PENDING",
} as const;

export type GetVietQRInfoResponseStatusEnum =
  (typeof GetVietQRInfoResponseStatusEnum)[keyof typeof GetVietQRInfoResponseStatusEnum];

/**
 *
 * @export
 * @interface GetWebhookInfoResponse
 */
export interface GetWebhookInfoResponse {
  /**
   *
   * @type {string}
   * @memberof GetWebhookInfoResponse
   */
  webhookUrl?: string;
  /**
   *
   * @type {string}
   * @memberof GetWebhookInfoResponse
   */
  webhookKey?: string;
}
/**
 *
 * @export
 * @interface IdentifyInfo
 */
export interface IdentifyInfo {
  /**
   *
   * @type {string}
   * @memberof IdentifyInfo
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof IdentifyInfo
   */
  backUrl?: string;
  /**
   *
   * @type {string}
   * @memberof IdentifyInfo
   */
  birthday?: string;
  /**
   *
   * @type {string}
   * @memberof IdentifyInfo
   */
  country?: string;
  /**
   *
   * @type {string}
   * @memberof IdentifyInfo
   */
  createAt?: string;
  /**
   *
   * @type {string}
   * @memberof IdentifyInfo
   */
  district?: string;
  /**
   *
   * @type {string}
   * @memberof IdentifyInfo
   */
  ethnicity?: string;
  /**
   *
   * @type {string}
   * @memberof IdentifyInfo
   */
  frontUrl?: string;
  /**
   *
   * @type {string}
   * @memberof IdentifyInfo
   */
  hometown?: string;
  /**
   *
   * @type {number}
   * @memberof IdentifyInfo
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof IdentifyInfo
   */
  idCheck?: string;
  /**
   *
   * @type {string}
   * @memberof IdentifyInfo
   */
  idLogic?: string;
  /**
   *
   * @type {string}
   * @memberof IdentifyInfo
   */
  idLogicMessage?: string;
  /**
   *
   * @type {string}
   * @memberof IdentifyInfo
   */
  idType?: string;
  /**
   *
   * @type {string}
   * @memberof IdentifyInfo
   */
  document?: string;
  /**
   *
   * @type {string}
   * @memberof IdentifyInfo
   */
  issueBy?: string;
  /**
   *
   * @type {string}
   * @memberof IdentifyInfo
   */
  issueDate?: string;
  /**
   *
   * @type {string}
   * @memberof IdentifyInfo
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof IdentifyInfo
   */
  national?: string;
  /**
   *
   * @type {string}
   * @memberof IdentifyInfo
   */
  no?: string;
  /**
   *
   * @type {string}
   * @memberof IdentifyInfo
   */
  optionalData?: string;
  /**
   *
   * @type {string}
   * @memberof IdentifyInfo
   */
  passportType?: string;
  /**
   *
   * @type {string}
   * @memberof IdentifyInfo
   */
  province?: string;
  /**
   *
   * @type {string}
   * @memberof IdentifyInfo
   */
  religion?: string;
  /**
   *
   * @type {string}
   * @memberof IdentifyInfo
   */
  sex?: string;
  /**
   *
   * @type {string}
   * @memberof IdentifyInfo
   */
  street?: string;
  /**
   *
   * @type {string}
   * @memberof IdentifyInfo
   */
  position?: IdentifyInfoPositionEnum;
  /**
   *
   * @type {number}
   * @memberof IdentifyInfo
   */
  fundPercent?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof IdentifyInfo
   */
  documentUrls?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof IdentifyInfo
   */
  gender?: IdentifyInfoGenderEnum;
}

export const IdentifyInfoPositionEnum = {
  ChairmanOfTheBoard: "CHAIRMAN_OF_THE_BOARD",
  ChairmanOfTheMembersCouncil: "CHAIRMAN_OF_THE_MEMBERS_COUNCIL",
  DirectorGeneral: "DIRECTOR_GENERAL",
  Director: "DIRECTOR",
} as const;

export type IdentifyInfoPositionEnum =
  (typeof IdentifyInfoPositionEnum)[keyof typeof IdentifyInfoPositionEnum];
export const IdentifyInfoGenderEnum = {
  Male: "MALE",
  Female: "FEMALE",
} as const;

export type IdentifyInfoGenderEnum =
  (typeof IdentifyInfoGenderEnum)[keyof typeof IdentifyInfoGenderEnum];

/**
 *
 * @export
 * @interface IdentifyInfoDto
 */
export interface IdentifyInfoDto {
  /**
   *
   * @type {number}
   * @memberof IdentifyInfoDto
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof IdentifyInfoDto
   */
  frontUrl?: string;
  /**
   *
   * @type {string}
   * @memberof IdentifyInfoDto
   */
  backUrl?: string;
  /**
   *
   * @type {string}
   * @memberof IdentifyInfoDto
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof IdentifyInfoDto
   */
  sex?: string;
  /**
   *
   * @type {string}
   * @memberof IdentifyInfoDto
   */
  no?: string;
  /**
   *
   * @type {string}
   * @memberof IdentifyInfoDto
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof IdentifyInfoDto
   */
  document?: string;
  /**
   *
   * @type {string}
   * @memberof IdentifyInfoDto
   */
  issueDate?: string;
  /**
   *
   * @type {string}
   * @memberof IdentifyInfoDto
   */
  issueBy?: string;
  /**
   *
   * @type {string}
   * @memberof IdentifyInfoDto
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof IdentifyInfoDto
   */
  national?: string;
}
/**
 *
 * @export
 * @interface InfoDevicePayPosResponse
 */
export interface InfoDevicePayPosResponse {
  /**
   *
   * @type {string}
   * @memberof InfoDevicePayPosResponse
   */
  posId?: string;
  /**
   *
   * @type {string}
   * @memberof InfoDevicePayPosResponse
   */
  terminalId?: string;
  /**
   *
   * @type {string}
   * @memberof InfoDevicePayPosResponse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof InfoDevicePayPosResponse
   */
  merchantId?: string;
  /**
   *
   * @type {number}
   * @memberof InfoDevicePayPosResponse
   */
  modelId?: number;
  /**
   *
   * @type {string}
   * @memberof InfoDevicePayPosResponse
   */
  posOderId?: string;
}
/**
 *
 * @export
 * @interface LinkAccountInfo
 */
export interface LinkAccountInfo {
  /**
   *
   * @type {string}
   * @memberof LinkAccountInfo
   */
  bankAccountName?: string;
  /**
   *
   * @type {string}
   * @memberof LinkAccountInfo
   */
  bankAccountNo?: string;
  /**
   *
   * @type {string}
   * @memberof LinkAccountInfo
   */
  branch?: LinkAccountInfoBranchEnum;
}

export const LinkAccountInfoBranchEnum = {
  Klb: "KLB",
  Umee: "UMEE",
} as const;

export type LinkAccountInfoBranchEnum =
  (typeof LinkAccountInfoBranchEnum)[keyof typeof LinkAccountInfoBranchEnum];

/**
 *
 * @export
 * @interface LinkAccountRequest
 */
export interface LinkAccountRequest {
  /**
   *
   * @type {string}
   * @memberof LinkAccountRequest
   */
  accountNo: string;
}
/**
 *
 * @export
 * @interface LinkAccountResponse
 */
export interface LinkAccountResponse {
  /**
   *
   * @type {string}
   * @memberof LinkAccountResponse
   */
  accountNo?: string;
  /**
   *
   * @type {string}
   * @memberof LinkAccountResponse
   */
  accountName?: string;
  /**
   *
   * @type {string}
   * @memberof LinkAccountResponse
   */
  phone?: string;
  /**
   *
   * @type {number}
   * @memberof LinkAccountResponse
   */
  expireTime?: number;
  /**
   *
   * @type {string}
   * @memberof LinkAccountResponse
   */
  sessionId?: string;
}
/**
 *
 * @export
 * @interface ListResponsePayOutUploadReceiverDTO
 */
export interface ListResponsePayOutUploadReceiverDTO {
  /**
   *
   * @type {Array<PayOutUploadReceiverDTO>}
   * @memberof ListResponsePayOutUploadReceiverDTO
   */
  items?: Array<PayOutUploadReceiverDTO>;
}
/**
 *
 * @export
 * @interface ListResponseUserResponse
 */
export interface ListResponseUserResponse {
  /**
   *
   * @type {Array<UserResponse>}
   * @memberof ListResponseUserResponse
   */
  items?: Array<UserResponse>;
}
/**
 *
 * @export
 * @interface ListSftpFileResponse
 */
export interface ListSftpFileResponse {
  /**
   *
   * @type {Array<string>}
   * @memberof ListSftpFileResponse
   */
  files?: Array<string>;
}
/**
 *
 * @export
 * @interface LoginAppRequest
 */
export interface LoginAppRequest {
  /**
   *
   * @type {string}
   * @memberof LoginAppRequest
   */
  appId: string;
  /**
   *
   * @type {string}
   * @memberof LoginAppRequest
   */
  sessionId: string;
  /**
   *
   * @type {string}
   * @memberof LoginAppRequest
   */
  phone: string;
  /**
   *
   * @type {string}
   * @memberof LoginAppRequest
   */
  customerName: string;
  /**
   *
   * @type {string}
   * @memberof LoginAppRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof LoginAppRequest
   */
  redirectUrl: string;
}
/**
 *
 * @export
 * @interface LoginAppResponse
 */
export interface LoginAppResponse {
  /**
   *
   * @type {string}
   * @memberof LoginAppResponse
   */
  appId?: string;
  /**
   *
   * @type {string}
   * @memberof LoginAppResponse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof LoginAppResponse
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof LoginAppResponse
   */
  provider?: string;
  /**
   *
   * @type {string}
   * @memberof LoginAppResponse
   */
  merchantUrl?: string;
  /**
   *
   * @type {string}
   * @memberof LoginAppResponse
   */
  sessionId?: string;
}
/**
 *
 * @export
 * @interface LogoutRequest
 */
export interface LogoutRequest {
  /**
   *
   * @type {string}
   * @memberof LogoutRequest
   */
  refreshToken?: string;
}
/**
 *
 * @export
 * @interface LogoutResponse
 */
export interface LogoutResponse {
  /**
   *
   * @type {boolean}
   * @memberof LogoutResponse
   */
  logout?: boolean;
}
/**
 *
 * @export
 * @interface MerchantDetailResponse
 */
export interface MerchantDetailResponse {
  /**
   *
   * @type {string}
   * @memberof MerchantDetailResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof MerchantDetailResponse
   */
  status?: MerchantDetailResponseStatusEnum;
  /**
   *
   * @type {string}
   * @memberof MerchantDetailResponse
   */
  merchantCode?: string;
  /**
   *
   * @type {string}
   * @memberof MerchantDetailResponse
   */
  accountNo?: string;
  /**
   *
   * @type {string}
   * @memberof MerchantDetailResponse
   */
  webhookUrl?: string;
  /**
   *
   * @type {string}
   * @memberof MerchantDetailResponse
   */
  webhookApiKey?: string;
  /**
   *
   * @type {string}
   * @memberof MerchantDetailResponse
   */
  requestAt?: string;
  /**
   *
   * @type {string}
   * @memberof MerchantDetailResponse
   */
  username?: string;
  /**
   *
   * @type {string}
   * @memberof MerchantDetailResponse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof MerchantDetailResponse
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof MerchantDetailResponse
   */
  createdBy?: string;
  /**
   *
   * @type {string}
   * @memberof MerchantDetailResponse
   */
  modifiedAt?: string;
  /**
   *
   * @type {string}
   * @memberof MerchantDetailResponse
   */
  modifiedBy?: string;
  /**
   *
   * @type {string}
   * @memberof MerchantDetailResponse
   */
  disapprovedReason?: string;
  /**
   *
   * @type {string}
   * @memberof MerchantDetailResponse
   */
  businessType?: MerchantDetailResponseBusinessTypeEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof MerchantDetailResponse
   */
  businessSector?: Array<MerchantDetailResponseBusinessSectorEnum>;
  /**
   *
   * @type {Array<MerchantFeeDto>}
   * @memberof MerchantDetailResponse
   */
  merchantFees?: Array<MerchantFeeDto>;
  /**
   *
   * @type {string}
   * @memberof MerchantDetailResponse
   */
  businessItems?: string;
  /**
   *
   * @type {AdminResponse}
   * @memberof MerchantDetailResponse
   */
  approvedBy?: AdminResponse;
  /**
   *
   * @type {string}
   * @memberof MerchantDetailResponse
   */
  approvedAt?: string;
  /**
   *
   * @type {boolean}
   * @memberof MerchantDetailResponse
   */
  allowEmail?: boolean;
  /**
   *
   * @type {number}
   * @memberof MerchantDetailResponse
   */
  allowedLimit?: number;
  /**
   *
   * @type {AffiliateResponse}
   * @memberof MerchantDetailResponse
   */
  affiliate?: AffiliateResponse;
  /**
   *
   * @type {string}
   * @memberof MerchantDetailResponse
   */
  ownerId?: string;
  /**
   *
   * @type {string}
   * @memberof MerchantDetailResponse
   */
  affiliateCode?: string;
  /**
   *
   * @type {string}
   * @memberof MerchantDetailResponse
   */
  vietQrProStatus?: MerchantDetailResponseVietQrProStatusEnum;
  /**
   *
   * @type {string}
   * @memberof MerchantDetailResponse
   */
  chargeType?: MerchantDetailResponseChargeTypeEnum;
  /**
   *
   * @type {ProfileResponse}
   * @memberof MerchantDetailResponse
   */
  profile?: ProfileResponse;
  /**
   *
   * @type {Array<BankAccountDto>}
   * @memberof MerchantDetailResponse
   */
  bankAccounts?: Array<BankAccountDto>;
  /**
   *
   * @type {UserResponse}
   * @memberof MerchantDetailResponse
   */
  owner?: UserResponse;
}

export const MerchantDetailResponseStatusEnum = {
  Created: "CREATED",
  SubmitInfo: "SUBMIT_INFO",
  LinkedAccount: "LINKED_ACCOUNT",
  Inactive: "INACTIVE",
  Active: "ACTIVE",
  Denied: "DENIED",
  OpenAccount: "OPEN_ACCOUNT",
  Block: "BLOCK",
} as const;

export type MerchantDetailResponseStatusEnum =
  (typeof MerchantDetailResponseStatusEnum)[keyof typeof MerchantDetailResponseStatusEnum];
export const MerchantDetailResponseBusinessTypeEnum = {
  Personal: "PERSONAL",
  Organization: "ORGANIZATION",
} as const;

export type MerchantDetailResponseBusinessTypeEnum =
  (typeof MerchantDetailResponseBusinessTypeEnum)[keyof typeof MerchantDetailResponseBusinessTypeEnum];
export const MerchantDetailResponseBusinessSectorEnum = {
  VehicleSale: "VEHICLE_SALE",
  Logistics: "LOGISTICS",
  ResidentAndFoodService: "RESIDENT_AND_FOOD_SERVICE",
  InformationAndMedia: "INFORMATION_AND_MEDIA",
  FinanceAndInsurance: "FINANCE_AND_INSURANCE",
  RealEstate: "REAL_ESTATE",
  AdministrativeAndSupportService: "ADMINISTRATIVE_AND_SUPPORT_SERVICE",
  Education: "EDUCATION",
  PublicHealth: "PUBLIC_HEALTH",
  HealthCareAndBeauty: "HEALTH_CARE_AND_BEAUTY",
  ArtAndEntertainment: "ART_AND_ENTERTAINMENT",
  Other: "OTHER",
} as const;

export type MerchantDetailResponseBusinessSectorEnum =
  (typeof MerchantDetailResponseBusinessSectorEnum)[keyof typeof MerchantDetailResponseBusinessSectorEnum];
export const MerchantDetailResponseVietQrProStatusEnum = {
  Inactive: "INACTIVE",
  Pending: "PENDING",
  Approved: "APPROVED",
  Lock: "LOCK",
  Rejected: "REJECTED",
} as const;

export type MerchantDetailResponseVietQrProStatusEnum =
  (typeof MerchantDetailResponseVietQrProStatusEnum)[keyof typeof MerchantDetailResponseVietQrProStatusEnum];
export const MerchantDetailResponseChargeTypeEnum = {
  Immediately: "IMMEDIATELY",
  Later: "LATER",
} as const;

export type MerchantDetailResponseChargeTypeEnum =
  (typeof MerchantDetailResponseChargeTypeEnum)[keyof typeof MerchantDetailResponseChargeTypeEnum];

/**
 *
 * @export
 * @interface MerchantDto
 */
export interface MerchantDto {
  /**
   *
   * @type {string}
   * @memberof MerchantDto
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof MerchantDto
   */
  status?: MerchantDtoStatusEnum;
  /**
   *
   * @type {CredentialDto}
   * @memberof MerchantDto
   */
  credential?: CredentialDto;
  /**
   *
   * @type {string}
   * @memberof MerchantDto
   */
  webhookUrl?: string;
  /**
   *
   * @type {string}
   * @memberof MerchantDto
   */
  merchantCode?: string;
  /**
   *
   * @type {string}
   * @memberof MerchantDto
   */
  createDateTime?: string;
  /**
   *
   * @type {string}
   * @memberof MerchantDto
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof MerchantDto
   */
  accountNo?: string;
  /**
   *
   * @type {boolean}
   * @memberof MerchantDto
   */
  allowEmail?: boolean;
  /**
   *
   * @type {ProfileDto}
   * @memberof MerchantDto
   */
  profile?: ProfileDto;
  /**
   *
   * @type {Array<BankAccountDto>}
   * @memberof MerchantDto
   */
  bankAccounts?: Array<BankAccountDto>;
  /**
   *
   * @type {string}
   * @memberof MerchantDto
   */
  affiliateCode?: string;
  /**
   *
   * @type {boolean}
   * @memberof MerchantDto
   */
  registerQr?: boolean;
  /**
   *
   * @type {string}
   * @memberof MerchantDto
   */
  vietQrProStatus?: MerchantDtoVietQrProStatusEnum;
  /**
   *
   * @type {string}
   * @memberof MerchantDto
   */
  chargeType?: MerchantDtoChargeTypeEnum;
}

export const MerchantDtoStatusEnum = {
  Created: "CREATED",
  SubmitInfo: "SUBMIT_INFO",
  LinkedAccount: "LINKED_ACCOUNT",
  Inactive: "INACTIVE",
  Active: "ACTIVE",
  Denied: "DENIED",
  OpenAccount: "OPEN_ACCOUNT",
  Block: "BLOCK",
} as const;

export type MerchantDtoStatusEnum =
  (typeof MerchantDtoStatusEnum)[keyof typeof MerchantDtoStatusEnum];
export const MerchantDtoVietQrProStatusEnum = {
  Inactive: "INACTIVE",
  Pending: "PENDING",
  Approved: "APPROVED",
  Lock: "LOCK",
  Rejected: "REJECTED",
} as const;

export type MerchantDtoVietQrProStatusEnum =
  (typeof MerchantDtoVietQrProStatusEnum)[keyof typeof MerchantDtoVietQrProStatusEnum];
export const MerchantDtoChargeTypeEnum = {
  Immediately: "IMMEDIATELY",
  Later: "LATER",
} as const;

export type MerchantDtoChargeTypeEnum =
  (typeof MerchantDtoChargeTypeEnum)[keyof typeof MerchantDtoChargeTypeEnum];

/**
 *
 * @export
 * @interface MerchantFeeDto
 */
export interface MerchantFeeDto {
  /**
   *
   * @type {number}
   * @memberof MerchantFeeDto
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof MerchantFeeDto
   */
  merchantId?: string;
  /**
   *
   * @type {FeeNameDto}
   * @memberof MerchantFeeDto
   */
  fee?: FeeNameDto;
  /**
   *
   * @type {string}
   * @memberof MerchantFeeDto
   */
  effectiveDate?: string;
}
/**
 *
 * @export
 * @interface MerchantHistoryDto
 */
export interface MerchantHistoryDto {
  /**
   *
   * @type {string}
   * @memberof MerchantHistoryDto
   */
  merchantCommand?: MerchantHistoryDtoMerchantCommandEnum;
  /**
   *
   * @type {string}
   * @memberof MerchantHistoryDto
   */
  note?: string;
  /**
   *
   * @type {string}
   * @memberof MerchantHistoryDto
   */
  merchantStatus?: MerchantHistoryDtoMerchantStatusEnum;
  /**
   *
   * @type {string}
   * @memberof MerchantHistoryDto
   */
  createdBy?: string;
  /**
   *
   * @type {string}
   * @memberof MerchantHistoryDto
   */
  createdAt?: string;
}

export const MerchantHistoryDtoMerchantCommandEnum = {
  Create: "CREATE",
  AddFee: "ADD_FEE",
  RemoveFee: "REMOVE_FEE",
  Update: "UPDATE",
  SubmitInfo: "SUBMIT_INFO",
  Active: "ACTIVE",
  Block: "BLOCK",
  Deny: "DENY",
  Unlock: "UNLOCK",
} as const;

export type MerchantHistoryDtoMerchantCommandEnum =
  (typeof MerchantHistoryDtoMerchantCommandEnum)[keyof typeof MerchantHistoryDtoMerchantCommandEnum];
export const MerchantHistoryDtoMerchantStatusEnum = {
  Created: "CREATED",
  SubmitInfo: "SUBMIT_INFO",
  LinkedAccount: "LINKED_ACCOUNT",
  Inactive: "INACTIVE",
  Active: "ACTIVE",
  Denied: "DENIED",
  OpenAccount: "OPEN_ACCOUNT",
  Block: "BLOCK",
} as const;

export type MerchantHistoryDtoMerchantStatusEnum =
  (typeof MerchantHistoryDtoMerchantStatusEnum)[keyof typeof MerchantHistoryDtoMerchantStatusEnum];

/**
 *
 * @export
 * @interface MerchantInfoResponse
 */
export interface MerchantInfoResponse {
  /**
   *
   * @type {string}
   * @memberof MerchantInfoResponse
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof MerchantInfoResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof MerchantInfoResponse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof MerchantInfoResponse
   */
  merchantAddress?: string;
  /**
   *
   * @type {string}
   * @memberof MerchantInfoResponse
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof MerchantInfoResponse
   */
  merchantStatus?: MerchantInfoResponseMerchantStatusEnum;
}

export const MerchantInfoResponseMerchantStatusEnum = {
  Created: "CREATED",
  SubmitInfo: "SUBMIT_INFO",
  LinkedAccount: "LINKED_ACCOUNT",
  Inactive: "INACTIVE",
  Active: "ACTIVE",
  Denied: "DENIED",
  OpenAccount: "OPEN_ACCOUNT",
  Block: "BLOCK",
} as const;

export type MerchantInfoResponseMerchantStatusEnum =
  (typeof MerchantInfoResponseMerchantStatusEnum)[keyof typeof MerchantInfoResponseMerchantStatusEnum];

/**
 *
 * @export
 * @interface MerchantResponse
 */
export interface MerchantResponse {
  /**
   *
   * @type {string}
   * @memberof MerchantResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof MerchantResponse
   */
  status?: MerchantResponseStatusEnum;
  /**
   *
   * @type {string}
   * @memberof MerchantResponse
   */
  merchantCode?: string;
  /**
   *
   * @type {string}
   * @memberof MerchantResponse
   */
  accountNo?: string;
  /**
   *
   * @type {string}
   * @memberof MerchantResponse
   */
  webhookUrl?: string;
  /**
   *
   * @type {string}
   * @memberof MerchantResponse
   */
  webhookApiKey?: string;
  /**
   *
   * @type {string}
   * @memberof MerchantResponse
   */
  requestAt?: string;
  /**
   *
   * @type {string}
   * @memberof MerchantResponse
   */
  username?: string;
  /**
   *
   * @type {string}
   * @memberof MerchantResponse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof MerchantResponse
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof MerchantResponse
   */
  createdBy?: string;
  /**
   *
   * @type {string}
   * @memberof MerchantResponse
   */
  modifiedAt?: string;
  /**
   *
   * @type {string}
   * @memberof MerchantResponse
   */
  modifiedBy?: string;
  /**
   *
   * @type {string}
   * @memberof MerchantResponse
   */
  disapprovedReason?: string;
  /**
   *
   * @type {string}
   * @memberof MerchantResponse
   */
  businessType?: MerchantResponseBusinessTypeEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof MerchantResponse
   */
  businessSector?: Array<MerchantResponseBusinessSectorEnum>;
  /**
   *
   * @type {Array<MerchantFeeDto>}
   * @memberof MerchantResponse
   */
  merchantFees?: Array<MerchantFeeDto>;
  /**
   *
   * @type {string}
   * @memberof MerchantResponse
   */
  businessItems?: string;
  /**
   *
   * @type {AdminResponse}
   * @memberof MerchantResponse
   */
  approvedBy?: AdminResponse;
  /**
   *
   * @type {string}
   * @memberof MerchantResponse
   */
  approvedAt?: string;
  /**
   *
   * @type {boolean}
   * @memberof MerchantResponse
   */
  allowEmail?: boolean;
  /**
   *
   * @type {number}
   * @memberof MerchantResponse
   */
  allowedLimit?: number;
  /**
   *
   * @type {AffiliateResponse}
   * @memberof MerchantResponse
   */
  affiliate?: AffiliateResponse;
  /**
   *
   * @type {string}
   * @memberof MerchantResponse
   */
  ownerId?: string;
  /**
   *
   * @type {string}
   * @memberof MerchantResponse
   */
  affiliateCode?: string;
  /**
   *
   * @type {string}
   * @memberof MerchantResponse
   */
  vietQrProStatus?: MerchantResponseVietQrProStatusEnum;
  /**
   *
   * @type {string}
   * @memberof MerchantResponse
   */
  chargeType?: MerchantResponseChargeTypeEnum;
}

export const MerchantResponseStatusEnum = {
  Created: "CREATED",
  SubmitInfo: "SUBMIT_INFO",
  LinkedAccount: "LINKED_ACCOUNT",
  Inactive: "INACTIVE",
  Active: "ACTIVE",
  Denied: "DENIED",
  OpenAccount: "OPEN_ACCOUNT",
  Block: "BLOCK",
} as const;

export type MerchantResponseStatusEnum =
  (typeof MerchantResponseStatusEnum)[keyof typeof MerchantResponseStatusEnum];
export const MerchantResponseBusinessTypeEnum = {
  Personal: "PERSONAL",
  Organization: "ORGANIZATION",
} as const;

export type MerchantResponseBusinessTypeEnum =
  (typeof MerchantResponseBusinessTypeEnum)[keyof typeof MerchantResponseBusinessTypeEnum];
export const MerchantResponseBusinessSectorEnum = {
  VehicleSale: "VEHICLE_SALE",
  Logistics: "LOGISTICS",
  ResidentAndFoodService: "RESIDENT_AND_FOOD_SERVICE",
  InformationAndMedia: "INFORMATION_AND_MEDIA",
  FinanceAndInsurance: "FINANCE_AND_INSURANCE",
  RealEstate: "REAL_ESTATE",
  AdministrativeAndSupportService: "ADMINISTRATIVE_AND_SUPPORT_SERVICE",
  Education: "EDUCATION",
  PublicHealth: "PUBLIC_HEALTH",
  HealthCareAndBeauty: "HEALTH_CARE_AND_BEAUTY",
  ArtAndEntertainment: "ART_AND_ENTERTAINMENT",
  Other: "OTHER",
} as const;

export type MerchantResponseBusinessSectorEnum =
  (typeof MerchantResponseBusinessSectorEnum)[keyof typeof MerchantResponseBusinessSectorEnum];
export const MerchantResponseVietQrProStatusEnum = {
  Inactive: "INACTIVE",
  Pending: "PENDING",
  Approved: "APPROVED",
  Lock: "LOCK",
  Rejected: "REJECTED",
} as const;

export type MerchantResponseVietQrProStatusEnum =
  (typeof MerchantResponseVietQrProStatusEnum)[keyof typeof MerchantResponseVietQrProStatusEnum];
export const MerchantResponseChargeTypeEnum = {
  Immediately: "IMMEDIATELY",
  Later: "LATER",
} as const;

export type MerchantResponseChargeTypeEnum =
  (typeof MerchantResponseChargeTypeEnum)[keyof typeof MerchantResponseChargeTypeEnum];

/**
 *
 * @export
 * @interface MonthlyFeeReportDto
 */
export interface MonthlyFeeReportDto {
  /**
   *
   * @type {string}
   * @memberof MonthlyFeeReportDto
   */
  createdDate?: string;
  /**
   *
   * @type {string}
   * @memberof MonthlyFeeReportDto
   */
  lastModifiedDate?: string;
  /**
   *
   * @type {string}
   * @memberof MonthlyFeeReportDto
   */
  createdBy?: string;
  /**
   *
   * @type {string}
   * @memberof MonthlyFeeReportDto
   */
  lastModifiedBy?: string;
  /**
   *
   * @type {number}
   * @memberof MonthlyFeeReportDto
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof MonthlyFeeReportDto
   */
  prefix?: string;
  /**
   *
   * @type {string}
   * @memberof MonthlyFeeReportDto
   */
  merchantName?: string;
  /**
   *
   * @type {number}
   * @memberof MonthlyFeeReportDto
   */
  totalTransaction?: number;
  /**
   *
   * @type {number}
   * @memberof MonthlyFeeReportDto
   */
  feeMoney?: number;
  /**
   *
   * @type {number}
   * @memberof MonthlyFeeReportDto
   */
  moneyVAT?: number;
  /**
   *
   * @type {number}
   * @memberof MonthlyFeeReportDto
   */
  totalFeeMoney?: number;
  /**
   *
   * @type {string}
   * @memberof MonthlyFeeReportDto
   */
  status?: MonthlyFeeReportDtoStatusEnum;
  /**
   *
   * @type {string}
   * @memberof MonthlyFeeReportDto
   */
  time?: string;
  /**
   *
   * @type {string}
   * @memberof MonthlyFeeReportDto
   */
  rtxnNo?: string;
  /**
   *
   * @type {Array<FeeReportDto>}
   * @memberof MonthlyFeeReportDto
   */
  feeReports?: Array<FeeReportDto>;
}

export const MonthlyFeeReportDtoStatusEnum = {
  WaitingPayment: "WAITING_PAYMENT",
  Paid: "PAID",
  Provisional: "PROVISIONAL",
} as const;

export type MonthlyFeeReportDtoStatusEnum =
  (typeof MonthlyFeeReportDtoStatusEnum)[keyof typeof MonthlyFeeReportDtoStatusEnum];

/**
 *
 * @export
 * @interface MonthlyFeeReportResponse
 */
export interface MonthlyFeeReportResponse {
  /**
   *
   * @type {MonthlyFeeReportDto}
   * @memberof MonthlyFeeReportResponse
   */
  feeReport?: MonthlyFeeReportDto;
}
/**
 *
 * @export
 * @interface NapasNotifyTransactionRequest
 */
export interface NapasNotifyTransactionRequest {
  /**
   *
   * @type {string}
   * @memberof NapasNotifyTransactionRequest
   */
  data?: string;
  /**
   *
   * @type {string}
   * @memberof NapasNotifyTransactionRequest
   */
  checksum?: string;
  /**
   *
   * @type {string}
   * @memberof NapasNotifyTransactionRequest
   */
  submit?: string;
}
/**
 *
 * @export
 * @interface NapasNotifyTransactionResponse
 */
export interface NapasNotifyTransactionResponse {
  /**
   *
   * @type {boolean}
   * @memberof NapasNotifyTransactionResponse
   */
  status?: boolean;
}
/**
 *
 * @export
 * @interface NapasSessionDto
 */
export interface NapasSessionDto {
  /**
   *
   * @type {string}
   * @memberof NapasSessionDto
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof NapasSessionDto
   */
  date?: string;
  /**
   *
   * @type {string}
   * @memberof NapasSessionDto
   */
  sessionStatus?: NapasSessionDtoSessionStatusEnum;
  /**
   *
   * @type {string}
   * @memberof NapasSessionDto
   */
  approvedAt?: string;
  /**
   *
   * @type {AdministratorDto}
   * @memberof NapasSessionDto
   */
  approvedBy?: AdministratorDto;
}

export const NapasSessionDtoSessionStatusEnum = {
  Created: "CREATED",
  Conciliated: "CONCILIATED",
  Approved: "APPROVED",
  Validated: "VALIDATED",
  Settled: "SETTLED",
} as const;

export type NapasSessionDtoSessionStatusEnum =
  (typeof NapasSessionDtoSessionStatusEnum)[keyof typeof NapasSessionDtoSessionStatusEnum];

/**
 *
 * @export
 * @interface NapasSettleDto
 */
export interface NapasSettleDto {
  /**
   *
   * @type {string}
   * @memberof NapasSettleDto
   */
  accountNumber?: string;
  /**
   *
   * @type {string}
   * @memberof NapasSettleDto
   */
  merchantName?: string;
  /**
   *
   * @type {number}
   * @memberof NapasSettleDto
   */
  numberOfTransaction?: number;
  /**
   *
   * @type {number}
   * @memberof NapasSettleDto
   */
  actualAmount?: number;
  /**
   *
   * @type {number}
   * @memberof NapasSettleDto
   */
  chargeAmount?: number;
  /**
   *
   * @type {number}
   * @memberof NapasSettleDto
   */
  vatChargeAmount?: number;
  /**
   *
   * @type {string}
   * @memberof NapasSettleDto
   */
  merchantId?: string;
  /**
   *
   * @type {number}
   * @memberof NapasSettleDto
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof NapasSettleDto
   */
  accountName?: string;
}
/**
 *
 * @export
 * @interface OTPPayOutResponse
 */
export interface OTPPayOutResponse {
  /**
   *
   * @type {string}
   * @memberof OTPPayOutResponse
   */
  sessionId?: string;
  /**
   *
   * @type {string}
   * @memberof OTPPayOutResponse
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof OTPPayOutResponse
   */
  time?: string;
  /**
   *
   * @type {number}
   * @memberof OTPPayOutResponse
   */
  timeout?: number;
}
/**
 *
 * @export
 * @interface OrderDetailResponse
 */
export interface OrderDetailResponse {
  /**
   *
   * @type {string}
   * @memberof OrderDetailResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof OrderDetailResponse
   */
  referenceId?: string;
  /**
   *
   * @type {string}
   * @memberof OrderDetailResponse
   */
  transactionId?: string;
  /**
   *
   * @type {number}
   * @memberof OrderDetailResponse
   */
  amount?: number;
  /**
   *
   * @type {number}
   * @memberof OrderDetailResponse
   */
  attempts?: number;
  /**
   *
   * @type {number}
   * @memberof OrderDetailResponse
   */
  fee?: number;
  /**
   *
   * @type {number}
   * @memberof OrderDetailResponse
   */
  actuallyReceived?: number;
  /**
   *
   * @type {string}
   * @memberof OrderDetailResponse
   */
  status?: OrderDetailResponseStatusEnum;
  /**
   *
   * @type {string}
   * @memberof OrderDetailResponse
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof OrderDetailResponse
   */
  customerName?: string;
  /**
   *
   * @type {string}
   * @memberof OrderDetailResponse
   */
  customerEmail?: string;
  /**
   *
   * @type {string}
   * @memberof OrderDetailResponse
   */
  customerPhone?: string;
  /**
   *
   * @type {string}
   * @memberof OrderDetailResponse
   */
  customerAddress?: string;
  /**
   *
   * @type {Array<OrderItemResponse>}
   * @memberof OrderDetailResponse
   */
  items?: Array<OrderItemResponse>;
  /**
   *
   * @type {TransactionResponse}
   * @memberof OrderDetailResponse
   */
  transaction?: TransactionResponse;
}

export const OrderDetailResponseStatusEnum = {
  Created: "CREATED",
  Paid: "PAID",
  Cancel: "CANCEL",
} as const;

export type OrderDetailResponseStatusEnum =
  (typeof OrderDetailResponseStatusEnum)[keyof typeof OrderDetailResponseStatusEnum];

/**
 *
 * @export
 * @interface OrderItemResponse
 */
export interface OrderItemResponse {
  /**
   *
   * @type {number}
   * @memberof OrderItemResponse
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof OrderItemResponse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof OrderItemResponse
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof OrderItemResponse
   */
  imageUrl?: string;
  /**
   *
   * @type {number}
   * @memberof OrderItemResponse
   */
  price?: number;
  /**
   *
   * @type {number}
   * @memberof OrderItemResponse
   */
  quantity?: number;
}
/**
 *
 * @export
 * @interface OrderResponse
 */
export interface OrderResponse {
  /**
   *
   * @type {string}
   * @memberof OrderResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof OrderResponse
   */
  referenceId?: string;
  /**
   *
   * @type {string}
   * @memberof OrderResponse
   */
  transactionId?: string;
  /**
   *
   * @type {number}
   * @memberof OrderResponse
   */
  amount?: number;
  /**
   *
   * @type {number}
   * @memberof OrderResponse
   */
  attempts?: number;
  /**
   *
   * @type {number}
   * @memberof OrderResponse
   */
  fee?: number;
  /**
   *
   * @type {number}
   * @memberof OrderResponse
   */
  actuallyReceived?: number;
  /**
   *
   * @type {string}
   * @memberof OrderResponse
   */
  status?: OrderResponseStatusEnum;
  /**
   *
   * @type {string}
   * @memberof OrderResponse
   */
  createdAt?: string;
}

export const OrderResponseStatusEnum = {
  Created: "CREATED",
  Paid: "PAID",
  Cancel: "CANCEL",
} as const;

export type OrderResponseStatusEnum =
  (typeof OrderResponseStatusEnum)[keyof typeof OrderResponseStatusEnum];

/**
 *
 * @export
 * @interface OrganizationLinkAccountRequest
 */
export interface OrganizationLinkAccountRequest {
  /**
   *
   * @type {Array<FeeBankAccountInputDto>}
   * @memberof OrganizationLinkAccountRequest
   */
  listAccountNo: Array<FeeBankAccountInputDto>;
}
/**
 *
 * @export
 * @interface PageResponseAffiliateResponse
 */
export interface PageResponseAffiliateResponse {
  /**
   *
   * @type {Array<AffiliateResponse>}
   * @memberof PageResponseAffiliateResponse
   */
  items?: Array<AffiliateResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponseAffiliateResponse
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseAffiliateResponse
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseAffiliateResponse
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseAffiliateResponse
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponseBankResponse
 */
export interface PageResponseBankResponse {
  /**
   *
   * @type {Array<BankResponse>}
   * @memberof PageResponseBankResponse
   */
  items?: Array<BankResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponseBankResponse
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseBankResponse
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseBankResponse
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseBankResponse
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponseBatchPayOutResponse
 */
export interface PageResponseBatchPayOutResponse {
  /**
   *
   * @type {Array<BatchPayOutResponse>}
   * @memberof PageResponseBatchPayOutResponse
   */
  items?: Array<BatchPayOutResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponseBatchPayOutResponse
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseBatchPayOutResponse
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseBatchPayOutResponse
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseBatchPayOutResponse
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponseCategoryResponse
 */
export interface PageResponseCategoryResponse {
  /**
   *
   * @type {Array<CategoryResponse>}
   * @memberof PageResponseCategoryResponse
   */
  items?: Array<CategoryResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponseCategoryResponse
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseCategoryResponse
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseCategoryResponse
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseCategoryResponse
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponseCmsListOrderResponse
 */
export interface PageResponseCmsListOrderResponse {
  /**
   *
   * @type {Array<CmsListOrderResponse>}
   * @memberof PageResponseCmsListOrderResponse
   */
  items?: Array<CmsListOrderResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponseCmsListOrderResponse
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseCmsListOrderResponse
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseCmsListOrderResponse
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseCmsListOrderResponse
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponseCmsVietQrProResponse
 */
export interface PageResponseCmsVietQrProResponse {
  /**
   *
   * @type {Array<CmsVietQrProResponse>}
   * @memberof PageResponseCmsVietQrProResponse
   */
  items?: Array<CmsVietQrProResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponseCmsVietQrProResponse
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseCmsVietQrProResponse
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseCmsVietQrProResponse
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseCmsVietQrProResponse
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponseCmsWebhookUrlResponse
 */
export interface PageResponseCmsWebhookUrlResponse {
  /**
   *
   * @type {Array<CmsWebhookUrlResponse>}
   * @memberof PageResponseCmsWebhookUrlResponse
   */
  items?: Array<CmsWebhookUrlResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponseCmsWebhookUrlResponse
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseCmsWebhookUrlResponse
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseCmsWebhookUrlResponse
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseCmsWebhookUrlResponse
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponseCustomerDto
 */
export interface PageResponseCustomerDto {
  /**
   *
   * @type {Array<CustomerDto>}
   * @memberof PageResponseCustomerDto
   */
  items?: Array<CustomerDto>;
  /**
   *
   * @type {number}
   * @memberof PageResponseCustomerDto
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseCustomerDto
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseCustomerDto
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseCustomerDto
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponseCustomerGroupDto
 */
export interface PageResponseCustomerGroupDto {
  /**
   *
   * @type {Array<CustomerGroupDto>}
   * @memberof PageResponseCustomerGroupDto
   */
  items?: Array<CustomerGroupDto>;
  /**
   *
   * @type {number}
   * @memberof PageResponseCustomerGroupDto
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseCustomerGroupDto
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseCustomerGroupDto
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseCustomerGroupDto
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponseEWalletResponse
 */
export interface PageResponseEWalletResponse {
  /**
   *
   * @type {Array<EWalletResponse>}
   * @memberof PageResponseEWalletResponse
   */
  items?: Array<EWalletResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponseEWalletResponse
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseEWalletResponse
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseEWalletResponse
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseEWalletResponse
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponseExternalPaySubscriptionInvoiceResponse
 */
export interface PageResponseExternalPaySubscriptionInvoiceResponse {
  /**
   *
   * @type {Array<ExternalPaySubscriptionInvoiceResponse>}
   * @memberof PageResponseExternalPaySubscriptionInvoiceResponse
   */
  items?: Array<ExternalPaySubscriptionInvoiceResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponseExternalPaySubscriptionInvoiceResponse
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseExternalPaySubscriptionInvoiceResponse
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseExternalPaySubscriptionInvoiceResponse
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseExternalPaySubscriptionInvoiceResponse
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponseFeeDto
 */
export interface PageResponseFeeDto {
  /**
   *
   * @type {Array<FeeDto>}
   * @memberof PageResponseFeeDto
   */
  items?: Array<FeeDto>;
  /**
   *
   * @type {number}
   * @memberof PageResponseFeeDto
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseFeeDto
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseFeeDto
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseFeeDto
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponseFeeHistoryDto
 */
export interface PageResponseFeeHistoryDto {
  /**
   *
   * @type {Array<FeeHistoryDto>}
   * @memberof PageResponseFeeHistoryDto
   */
  items?: Array<FeeHistoryDto>;
  /**
   *
   * @type {number}
   * @memberof PageResponseFeeHistoryDto
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseFeeHistoryDto
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseFeeHistoryDto
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseFeeHistoryDto
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponseForControlDto
 */
export interface PageResponseForControlDto {
  /**
   *
   * @type {Array<ForControlDto>}
   * @memberof PageResponseForControlDto
   */
  items?: Array<ForControlDto>;
  /**
   *
   * @type {number}
   * @memberof PageResponseForControlDto
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseForControlDto
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseForControlDto
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseForControlDto
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponseForControlHistoryDto
 */
export interface PageResponseForControlHistoryDto {
  /**
   *
   * @type {Array<ForControlHistoryDto>}
   * @memberof PageResponseForControlHistoryDto
   */
  items?: Array<ForControlHistoryDto>;
  /**
   *
   * @type {number}
   * @memberof PageResponseForControlHistoryDto
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseForControlHistoryDto
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseForControlHistoryDto
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseForControlHistoryDto
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponseGetListAdminResponse
 */
export interface PageResponseGetListAdminResponse {
  /**
   *
   * @type {Array<GetListAdminResponse>}
   * @memberof PageResponseGetListAdminResponse
   */
  items?: Array<GetListAdminResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponseGetListAdminResponse
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseGetListAdminResponse
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseGetListAdminResponse
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseGetListAdminResponse
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponseGetPageListPosPortalResponse
 */
export interface PageResponseGetPageListPosPortalResponse {
  /**
   *
   * @type {Array<GetPageListPosPortalResponse>}
   * @memberof PageResponseGetPageListPosPortalResponse
   */
  items?: Array<GetPageListPosPortalResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponseGetPageListPosPortalResponse
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseGetPageListPosPortalResponse
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseGetPageListPosPortalResponse
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseGetPageListPosPortalResponse
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponseGetPosHistoryTransactionPortalResponse
 */
export interface PageResponseGetPosHistoryTransactionPortalResponse {
  /**
   *
   * @type {Array<GetPosHistoryTransactionPortalResponse>}
   * @memberof PageResponseGetPosHistoryTransactionPortalResponse
   */
  items?: Array<GetPosHistoryTransactionPortalResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponseGetPosHistoryTransactionPortalResponse
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseGetPosHistoryTransactionPortalResponse
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseGetPosHistoryTransactionPortalResponse
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseGetPosHistoryTransactionPortalResponse
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponseGetReportResponse
 */
export interface PageResponseGetReportResponse {
  /**
   *
   * @type {Array<GetReportResponse>}
   * @memberof PageResponseGetReportResponse
   */
  items?: Array<GetReportResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponseGetReportResponse
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseGetReportResponse
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseGetReportResponse
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseGetReportResponse
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponseInfoDevicePayPosResponse
 */
export interface PageResponseInfoDevicePayPosResponse {
  /**
   *
   * @type {Array<InfoDevicePayPosResponse>}
   * @memberof PageResponseInfoDevicePayPosResponse
   */
  items?: Array<InfoDevicePayPosResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponseInfoDevicePayPosResponse
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseInfoDevicePayPosResponse
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseInfoDevicePayPosResponse
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseInfoDevicePayPosResponse
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponseMerchantFeeDto
 */
export interface PageResponseMerchantFeeDto {
  /**
   *
   * @type {Array<MerchantFeeDto>}
   * @memberof PageResponseMerchantFeeDto
   */
  items?: Array<MerchantFeeDto>;
  /**
   *
   * @type {number}
   * @memberof PageResponseMerchantFeeDto
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseMerchantFeeDto
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseMerchantFeeDto
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseMerchantFeeDto
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponseMerchantHistoryDto
 */
export interface PageResponseMerchantHistoryDto {
  /**
   *
   * @type {Array<MerchantHistoryDto>}
   * @memberof PageResponseMerchantHistoryDto
   */
  items?: Array<MerchantHistoryDto>;
  /**
   *
   * @type {number}
   * @memberof PageResponseMerchantHistoryDto
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseMerchantHistoryDto
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseMerchantHistoryDto
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseMerchantHistoryDto
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponseMerchantResponse
 */
export interface PageResponseMerchantResponse {
  /**
   *
   * @type {Array<MerchantResponse>}
   * @memberof PageResponseMerchantResponse
   */
  items?: Array<MerchantResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponseMerchantResponse
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseMerchantResponse
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseMerchantResponse
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseMerchantResponse
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponseMonthlyFeeReportDto
 */
export interface PageResponseMonthlyFeeReportDto {
  /**
   *
   * @type {Array<MonthlyFeeReportDto>}
   * @memberof PageResponseMonthlyFeeReportDto
   */
  items?: Array<MonthlyFeeReportDto>;
  /**
   *
   * @type {number}
   * @memberof PageResponseMonthlyFeeReportDto
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseMonthlyFeeReportDto
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseMonthlyFeeReportDto
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseMonthlyFeeReportDto
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponseNapasSessionDto
 */
export interface PageResponseNapasSessionDto {
  /**
   *
   * @type {Array<NapasSessionDto>}
   * @memberof PageResponseNapasSessionDto
   */
  items?: Array<NapasSessionDto>;
  /**
   *
   * @type {number}
   * @memberof PageResponseNapasSessionDto
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseNapasSessionDto
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseNapasSessionDto
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseNapasSessionDto
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponseOrderItemResponse
 */
export interface PageResponseOrderItemResponse {
  /**
   *
   * @type {Array<OrderItemResponse>}
   * @memberof PageResponseOrderItemResponse
   */
  items?: Array<OrderItemResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponseOrderItemResponse
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseOrderItemResponse
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseOrderItemResponse
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseOrderItemResponse
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponseOrderResponse
 */
export interface PageResponseOrderResponse {
  /**
   *
   * @type {Array<OrderResponse>}
   * @memberof PageResponseOrderResponse
   */
  items?: Array<OrderResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponseOrderResponse
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseOrderResponse
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseOrderResponse
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseOrderResponse
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponsePayButtonResponse
 */
export interface PageResponsePayButtonResponse {
  /**
   *
   * @type {Array<PayButtonResponse>}
   * @memberof PageResponsePayButtonResponse
   */
  items?: Array<PayButtonResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponsePayButtonResponse
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponsePayButtonResponse
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponsePayButtonResponse
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponsePayButtonResponse
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponsePayLinkDto
 */
export interface PageResponsePayLinkDto {
  /**
   *
   * @type {Array<PayLinkDto>}
   * @memberof PageResponsePayLinkDto
   */
  items?: Array<PayLinkDto>;
  /**
   *
   * @type {number}
   * @memberof PageResponsePayLinkDto
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponsePayLinkDto
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponsePayLinkDto
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponsePayLinkDto
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponsePayOutReceiverDTO
 */
export interface PageResponsePayOutReceiverDTO {
  /**
   *
   * @type {Array<PayOutReceiverDTO>}
   * @memberof PageResponsePayOutReceiverDTO
   */
  items?: Array<PayOutReceiverDTO>;
  /**
   *
   * @type {number}
   * @memberof PageResponsePayOutReceiverDTO
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponsePayOutReceiverDTO
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponsePayOutReceiverDTO
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponsePayOutReceiverDTO
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponsePayPageResponse
 */
export interface PageResponsePayPageResponse {
  /**
   *
   * @type {Array<PayPageResponse>}
   * @memberof PageResponsePayPageResponse
   */
  items?: Array<PayPageResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponsePayPageResponse
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponsePayPageResponse
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponsePayPageResponse
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponsePayPageResponse
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponsePayPageTransactionResponse
 */
export interface PageResponsePayPageTransactionResponse {
  /**
   *
   * @type {Array<PayPageTransactionResponse>}
   * @memberof PageResponsePayPageTransactionResponse
   */
  items?: Array<PayPageTransactionResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponsePayPageTransactionResponse
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponsePayPageTransactionResponse
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponsePayPageTransactionResponse
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponsePayPageTransactionResponse
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponsePaySubscriptionInvoiceResponse
 */
export interface PageResponsePaySubscriptionInvoiceResponse {
  /**
   *
   * @type {Array<PaySubscriptionInvoiceResponse>}
   * @memberof PageResponsePaySubscriptionInvoiceResponse
   */
  items?: Array<PaySubscriptionInvoiceResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponsePaySubscriptionInvoiceResponse
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponsePaySubscriptionInvoiceResponse
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponsePaySubscriptionInvoiceResponse
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponsePaySubscriptionInvoiceResponse
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponsePaySubscriptionPlanResponse
 */
export interface PageResponsePaySubscriptionPlanResponse {
  /**
   *
   * @type {Array<PaySubscriptionPlanResponse>}
   * @memberof PageResponsePaySubscriptionPlanResponse
   */
  items?: Array<PaySubscriptionPlanResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponsePaySubscriptionPlanResponse
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponsePaySubscriptionPlanResponse
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponsePaySubscriptionPlanResponse
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponsePaySubscriptionPlanResponse
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponsePaySubscriptionResponse
 */
export interface PageResponsePaySubscriptionResponse {
  /**
   *
   * @type {Array<PaySubscriptionResponse>}
   * @memberof PageResponsePaySubscriptionResponse
   */
  items?: Array<PaySubscriptionResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponsePaySubscriptionResponse
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponsePaySubscriptionResponse
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponsePaySubscriptionResponse
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponsePaySubscriptionResponse
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponsePayboxTransactionResponse
 */
export interface PageResponsePayboxTransactionResponse {
  /**
   *
   * @type {Array<PayboxTransactionResponse>}
   * @memberof PageResponsePayboxTransactionResponse
   */
  items?: Array<PayboxTransactionResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponsePayboxTransactionResponse
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponsePayboxTransactionResponse
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponsePayboxTransactionResponse
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponsePayboxTransactionResponse
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponsePaymentTransactionResponse
 */
export interface PageResponsePaymentTransactionResponse {
  /**
   *
   * @type {Array<PaymentTransactionResponse>}
   * @memberof PageResponsePaymentTransactionResponse
   */
  items?: Array<PaymentTransactionResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponsePaymentTransactionResponse
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponsePaymentTransactionResponse
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponsePaymentTransactionResponse
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponsePaymentTransactionResponse
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponsePosModelResponse
 */
export interface PageResponsePosModelResponse {
  /**
   *
   * @type {Array<PosModelResponse>}
   * @memberof PageResponsePosModelResponse
   */
  items?: Array<PosModelResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponsePosModelResponse
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponsePosModelResponse
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponsePosModelResponse
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponsePosModelResponse
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponsePosOrderListResponse
 */
export interface PageResponsePosOrderListResponse {
  /**
   *
   * @type {Array<PosOrderListResponse>}
   * @memberof PageResponsePosOrderListResponse
   */
  items?: Array<PosOrderListResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponsePosOrderListResponse
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponsePosOrderListResponse
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponsePosOrderListResponse
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponsePosOrderListResponse
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponseProductResponse
 */
export interface PageResponseProductResponse {
  /**
   *
   * @type {Array<ProductResponse>}
   * @memberof PageResponseProductResponse
   */
  items?: Array<ProductResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponseProductResponse
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseProductResponse
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseProductResponse
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseProductResponse
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponseResponseMessageResponse
 */
export interface PageResponseResponseMessageResponse {
  /**
   *
   * @type {Array<ResponseMessageResponse>}
   * @memberof PageResponseResponseMessageResponse
   */
  items?: Array<ResponseMessageResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponseResponseMessageResponse
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseResponseMessageResponse
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseResponseMessageResponse
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseResponseMessageResponse
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponseRoleGroupResponse
 */
export interface PageResponseRoleGroupResponse {
  /**
   *
   * @type {Array<RoleGroupResponse>}
   * @memberof PageResponseRoleGroupResponse
   */
  items?: Array<RoleGroupResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponseRoleGroupResponse
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseRoleGroupResponse
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseRoleGroupResponse
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseRoleGroupResponse
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponseSinglePayOutResponse
 */
export interface PageResponseSinglePayOutResponse {
  /**
   *
   * @type {Array<SinglePayOutResponse>}
   * @memberof PageResponseSinglePayOutResponse
   */
  items?: Array<SinglePayOutResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponseSinglePayOutResponse
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseSinglePayOutResponse
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseSinglePayOutResponse
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseSinglePayOutResponse
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponseSupportResponse
 */
export interface PageResponseSupportResponse {
  /**
   *
   * @type {Array<SupportResponse>}
   * @memberof PageResponseSupportResponse
   */
  items?: Array<SupportResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponseSupportResponse
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseSupportResponse
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseSupportResponse
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseSupportResponse
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponseSystemParamResponse
 */
export interface PageResponseSystemParamResponse {
  /**
   *
   * @type {Array<SystemParamResponse>}
   * @memberof PageResponseSystemParamResponse
   */
  items?: Array<SystemParamResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponseSystemParamResponse
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseSystemParamResponse
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseSystemParamResponse
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseSystemParamResponse
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponseTransactionInSettlementDetail
 */
export interface PageResponseTransactionInSettlementDetail {
  /**
   *
   * @type {Array<TransactionInSettlementDetail>}
   * @memberof PageResponseTransactionInSettlementDetail
   */
  items?: Array<TransactionInSettlementDetail>;
  /**
   *
   * @type {number}
   * @memberof PageResponseTransactionInSettlementDetail
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseTransactionInSettlementDetail
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseTransactionInSettlementDetail
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseTransactionInSettlementDetail
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponseTransactionResponse
 */
export interface PageResponseTransactionResponse {
  /**
   *
   * @type {Array<TransactionResponse>}
   * @memberof PageResponseTransactionResponse
   */
  items?: Array<TransactionResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponseTransactionResponse
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseTransactionResponse
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseTransactionResponse
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseTransactionResponse
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponseUpdateFAQResponse
 */
export interface PageResponseUpdateFAQResponse {
  /**
   *
   * @type {Array<UpdateFAQResponse>}
   * @memberof PageResponseUpdateFAQResponse
   */
  items?: Array<UpdateFAQResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponseUpdateFAQResponse
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseUpdateFAQResponse
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseUpdateFAQResponse
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseUpdateFAQResponse
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponseUserResponse
 */
export interface PageResponseUserResponse {
  /**
   *
   * @type {Array<UserResponse>}
   * @memberof PageResponseUserResponse
   */
  items?: Array<UserResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponseUserResponse
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseUserResponse
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseUserResponse
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseUserResponse
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponseVietQrProCustomerResponse
 */
export interface PageResponseVietQrProCustomerResponse {
  /**
   *
   * @type {Array<VietQrProCustomerResponse>}
   * @memberof PageResponseVietQrProCustomerResponse
   */
  items?: Array<VietQrProCustomerResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponseVietQrProCustomerResponse
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseVietQrProCustomerResponse
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseVietQrProCustomerResponse
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseVietQrProCustomerResponse
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PageResponseVietQrProHistoryChangeResponse
 */
export interface PageResponseVietQrProHistoryChangeResponse {
  /**
   *
   * @type {Array<VietQrProHistoryChangeResponse>}
   * @memberof PageResponseVietQrProHistoryChangeResponse
   */
  items?: Array<VietQrProHistoryChangeResponse>;
  /**
   *
   * @type {number}
   * @memberof PageResponseVietQrProHistoryChangeResponse
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseVietQrProHistoryChangeResponse
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseVietQrProHistoryChangeResponse
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseVietQrProHistoryChangeResponse
   */
  totalPage?: number;
}
/**
 *
 * @export
 * @interface PayButtonResponse
 */
export interface PayButtonResponse {
  /**
   *
   * @type {string}
   * @memberof PayButtonResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof PayButtonResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof PayButtonResponse
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof PayButtonResponse
   */
  label?: string;
  /**
   *
   * @type {string}
   * @memberof PayButtonResponse
   */
  theme?: PayButtonResponseThemeEnum;
  /**
   *
   * @type {number}
   * @memberof PayButtonResponse
   */
  numOfPayments?: number;
  /**
   *
   * @type {number}
   * @memberof PayButtonResponse
   */
  numOfClicks?: number;
  /**
   *
   * @type {string}
   * @memberof PayButtonResponse
   */
  script?: string;
  /**
   *
   * @type {PayPageInfo}
   * @memberof PayButtonResponse
   */
  payPageInfo?: PayPageInfo;
}

export const PayButtonResponseThemeEnum = {
  Light: "LIGHT",
  Dark: "DARK",
} as const;

export type PayButtonResponseThemeEnum =
  (typeof PayButtonResponseThemeEnum)[keyof typeof PayButtonResponseThemeEnum];

/**
 *
 * @export
 * @interface PayFeeReportCmsRequest
 */
export interface PayFeeReportCmsRequest {
  /**
   *
   * @type {number}
   * @memberof PayFeeReportCmsRequest
   */
  feeReportId: number;
}
/**
 *
 * @export
 * @interface PayInfoStatusResponse
 */
export interface PayInfoStatusResponse {
  /**
   *
   * @type {string}
   * @memberof PayInfoStatusResponse
   */
  status?: PayInfoStatusResponseStatusEnum;
  /**
   *
   * @type {string}
   * @memberof PayInfoStatusResponse
   */
  successUrl?: string;
  /**
   *
   * @type {string}
   * @memberof PayInfoStatusResponse
   */
  failUrl?: string;
  /**
   *
   * @type {number}
   * @memberof PayInfoStatusResponse
   */
  redirectAfter?: number;
  /**
   *
   * @type {string}
   * @memberof PayInfoStatusResponse
   */
  rtxNumber?: string;
  /**
   *
   * @type {string}
   * @memberof PayInfoStatusResponse
   */
  dateTime?: string;
  /**
   *
   * @type {string}
   * @memberof PayInfoStatusResponse
   */
  paymentType?: PayInfoStatusResponsePaymentTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PayInfoStatusResponse
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof PayInfoStatusResponse
   */
  accountName?: string;
  /**
   *
   * @type {number}
   * @memberof PayInfoStatusResponse
   */
  amount?: number;
}

export const PayInfoStatusResponseStatusEnum = {
  None: "NONE",
  Created: "CREATED",
  Success: "SUCCESS",
  Canceled: "CANCELED",
  Fail: "FAIL",
  Timeout: "TIMEOUT",
  Pending: "PENDING",
} as const;

export type PayInfoStatusResponseStatusEnum =
  (typeof PayInfoStatusResponseStatusEnum)[keyof typeof PayInfoStatusResponseStatusEnum];
export const PayInfoStatusResponsePaymentTypeEnum = {
  VietQr: "VIET_QR",
  AtmCard: "ATM_CARD",
  Banking: "BANKING",
} as const;

export type PayInfoStatusResponsePaymentTypeEnum =
  (typeof PayInfoStatusResponsePaymentTypeEnum)[keyof typeof PayInfoStatusResponsePaymentTypeEnum];

/**
 *
 * @export
 * @interface PayLinkDto
 */
export interface PayLinkDto {
  /**
   *
   * @type {string}
   * @memberof PayLinkDto
   */
  payLinkCode?: string;
  /**
   *
   * @type {string}
   * @memberof PayLinkDto
   */
  refTransactionId?: string;
  /**
   *
   * @type {string}
   * @memberof PayLinkDto
   */
  accountNo?: string;
  /**
   *
   * @type {string}
   * @memberof PayLinkDto
   */
  accountName?: string;
  /**
   *
   * @type {string}
   * @memberof PayLinkDto
   */
  bankType?: PayLinkDtoBankTypeEnum;
  /**
   *
   * @type {number}
   * @memberof PayLinkDto
   */
  amount?: number;
  /**
   *
   * @type {CustomerInfo}
   * @memberof PayLinkDto
   */
  customerInfo?: CustomerInfo;
  /**
   *
   * @type {string}
   * @memberof PayLinkDto
   */
  fileUrl?: string;
  /**
   *
   * @type {string}
   * @memberof PayLinkDto
   */
  payLinkUrl?: string;
  /**
   *
   * @type {string}
   * @memberof PayLinkDto
   */
  createBy?: string;
  /**
   *
   * @type {string}
   * @memberof PayLinkDto
   */
  expireType?: PayLinkDtoExpireTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PayLinkDto
   */
  status?: PayLinkDtoStatusEnum;
  /**
   *
   * @type {string}
   * @memberof PayLinkDto
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof PayLinkDto
   */
  expireAt?: string;
  /**
   *
   * @type {number}
   * @memberof PayLinkDto
   */
  totalSendMailSms?: number;
}

export const PayLinkDtoBankTypeEnum = {
  Klb: "KLB",
  Umee: "UMEE",
} as const;

export type PayLinkDtoBankTypeEnum =
  (typeof PayLinkDtoBankTypeEnum)[keyof typeof PayLinkDtoBankTypeEnum];
export const PayLinkDtoExpireTypeEnum = {
  Minute10: "MINUTE_10",
  Hour1: "HOUR_1",
  Hour12: "HOUR_12",
  Day1: "DAY_1",
  Day7: "DAY_7",
  Day30: "DAY_30",
  NoExpire: "NO_EXPIRE",
  Custom: "CUSTOM",
  Second10: "SECOND_10",
} as const;

export type PayLinkDtoExpireTypeEnum =
  (typeof PayLinkDtoExpireTypeEnum)[keyof typeof PayLinkDtoExpireTypeEnum];
export const PayLinkDtoStatusEnum = {
  None: "NONE",
  Created: "CREATED",
  Success: "SUCCESS",
  Canceled: "CANCELED",
  Fail: "FAIL",
  Timeout: "TIMEOUT",
  Pending: "PENDING",
} as const;

export type PayLinkDtoStatusEnum =
  (typeof PayLinkDtoStatusEnum)[keyof typeof PayLinkDtoStatusEnum];

/**
 *
 * @export
 * @interface PayLinkResponse
 */
export interface PayLinkResponse {
  /**
   *
   * @type {string}
   * @memberof PayLinkResponse
   */
  merchantName?: string;
  /**
   *
   * @type {string}
   * @memberof PayLinkResponse
   */
  merchantCode?: string;
  /**
   *
   * @type {string}
   * @memberof PayLinkResponse
   */
  payLinkCode?: string;
  /**
   *
   * @type {string}
   * @memberof PayLinkResponse
   */
  payLinkUrl?: string;
  /**
   *
   * @type {string}
   * @memberof PayLinkResponse
   */
  qrCode?: string;
  /**
   *
   * @type {number}
   * @memberof PayLinkResponse
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof PayLinkResponse
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof PayLinkResponse
   */
  fileUrl?: string;
  /**
   *
   * @type {string}
   * @memberof PayLinkResponse
   */
  contactEmail?: string;
  /**
   *
   * @type {string}
   * @memberof PayLinkResponse
   */
  contactPhone?: string;
  /**
   *
   * @type {CustomerDto}
   * @memberof PayLinkResponse
   */
  customerDto?: CustomerDto;
  /**
   *
   * @type {string}
   * @memberof PayLinkResponse
   */
  status?: PayLinkResponseStatusEnum;
  /**
   *
   * @type {string}
   * @memberof PayLinkResponse
   */
  customerInfoType?: PayLinkResponseCustomerInfoTypeEnum;
  /**
   *
   * @type {CustomerInfoForm}
   * @memberof PayLinkResponse
   */
  customerInfoForm?: CustomerInfoForm;
  /**
   *
   * @type {string}
   * @memberof PayLinkResponse
   */
  createTime?: string;
  /**
   *
   * @type {string}
   * @memberof PayLinkResponse
   */
  expireAt?: string;
  /**
   *
   * @type {string}
   * @memberof PayLinkResponse
   */
  transactionId?: string;
  /**
   *
   * @type {ShopSettingResponse}
   * @memberof PayLinkResponse
   */
  shopSetting?: ShopSettingResponse;
}

export const PayLinkResponseStatusEnum = {
  None: "NONE",
  Created: "CREATED",
  Success: "SUCCESS",
  Canceled: "CANCELED",
  Fail: "FAIL",
  Timeout: "TIMEOUT",
  Pending: "PENDING",
} as const;

export type PayLinkResponseStatusEnum =
  (typeof PayLinkResponseStatusEnum)[keyof typeof PayLinkResponseStatusEnum];
export const PayLinkResponseCustomerInfoTypeEnum = {
  NoRequire: "NO_REQUIRE",
  AutoFill: "AUTO_FILL",
  RequireInput: "REQUIRE_INPUT",
  PartnerFill: "PARTNER_FILL",
} as const;

export type PayLinkResponseCustomerInfoTypeEnum =
  (typeof PayLinkResponseCustomerInfoTypeEnum)[keyof typeof PayLinkResponseCustomerInfoTypeEnum];

/**
 *
 * @export
 * @interface PayLinkStatusResponse
 */
export interface PayLinkStatusResponse {
  /**
   *
   * @type {string}
   * @memberof PayLinkStatusResponse
   */
  payLinkCode?: string;
  /**
   *
   * @type {string}
   * @memberof PayLinkStatusResponse
   */
  status?: PayLinkStatusResponseStatusEnum;
  /**
   *
   * @type {string}
   * @memberof PayLinkStatusResponse
   */
  successUrl?: string;
  /**
   *
   * @type {string}
   * @memberof PayLinkStatusResponse
   */
  failUrl?: string;
  /**
   *
   * @type {number}
   * @memberof PayLinkStatusResponse
   */
  redirectAfter?: number;
  /**
   *
   * @type {string}
   * @memberof PayLinkStatusResponse
   */
  rtxNumber?: string;
  /**
   *
   * @type {string}
   * @memberof PayLinkStatusResponse
   */
  dateTime?: string;
  /**
   *
   * @type {string}
   * @memberof PayLinkStatusResponse
   */
  paymentType?: PayLinkStatusResponsePaymentTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PayLinkStatusResponse
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof PayLinkStatusResponse
   */
  accountName?: string;
  /**
   *
   * @type {number}
   * @memberof PayLinkStatusResponse
   */
  amount?: number;
}

export const PayLinkStatusResponseStatusEnum = {
  None: "NONE",
  Created: "CREATED",
  Success: "SUCCESS",
  Canceled: "CANCELED",
  Fail: "FAIL",
  Timeout: "TIMEOUT",
  Pending: "PENDING",
} as const;

export type PayLinkStatusResponseStatusEnum =
  (typeof PayLinkStatusResponseStatusEnum)[keyof typeof PayLinkStatusResponseStatusEnum];
export const PayLinkStatusResponsePaymentTypeEnum = {
  VietQr: "VIET_QR",
  AtmCard: "ATM_CARD",
  Banking: "BANKING",
} as const;

export type PayLinkStatusResponsePaymentTypeEnum =
  (typeof PayLinkStatusResponsePaymentTypeEnum)[keyof typeof PayLinkStatusResponsePaymentTypeEnum];

/**
 *
 * @export
 * @interface PayLinkUrlResponse
 */
export interface PayLinkUrlResponse {
  /**
   *
   * @type {string}
   * @memberof PayLinkUrlResponse
   */
  payLinkUrl?: string;
}
/**
 *
 * @export
 * @interface PayOutReceiverDTO
 */
export interface PayOutReceiverDTO {
  /**
   *
   * @type {number}
   * @memberof PayOutReceiverDTO
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof PayOutReceiverDTO
   */
  bankLogo?: string;
  /**
   *
   * @type {string}
   * @memberof PayOutReceiverDTO
   */
  bankBin?: string;
  /**
   *
   * @type {string}
   * @memberof PayOutReceiverDTO
   */
  bankName?: string;
  /**
   *
   * @type {string}
   * @memberof PayOutReceiverDTO
   */
  accountNo?: string;
  /**
   *
   * @type {string}
   * @memberof PayOutReceiverDTO
   */
  accountName?: string;
  /**
   *
   * @type {string}
   * @memberof PayOutReceiverDTO
   */
  group?: PayOutReceiverDTOGroupEnum;
}

export const PayOutReceiverDTOGroupEnum = {
  Customer: "CUSTOMER",
  Employee: "EMPLOYEE",
  Merchant: "MERCHANT",
  Friend: "FRIEND",
  Family: "FAMILY",
  Other: "OTHER",
} as const;

export type PayOutReceiverDTOGroupEnum =
  (typeof PayOutReceiverDTOGroupEnum)[keyof typeof PayOutReceiverDTOGroupEnum];

/**
 *
 * @export
 * @interface PayOutUploadReceiverDTO
 */
export interface PayOutUploadReceiverDTO {
  /**
   *
   * @type {number}
   * @memberof PayOutUploadReceiverDTO
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof PayOutUploadReceiverDTO
   */
  bankLogo?: string;
  /**
   *
   * @type {string}
   * @memberof PayOutUploadReceiverDTO
   */
  bankBin?: string;
  /**
   *
   * @type {string}
   * @memberof PayOutUploadReceiverDTO
   */
  bankName?: string;
  /**
   *
   * @type {string}
   * @memberof PayOutUploadReceiverDTO
   */
  accountNo?: string;
  /**
   *
   * @type {string}
   * @memberof PayOutUploadReceiverDTO
   */
  accountName?: string;
  /**
   *
   * @type {string}
   * @memberof PayOutUploadReceiverDTO
   */
  group?: PayOutUploadReceiverDTOGroupEnum;
  /**
   *
   * @type {number}
   * @memberof PayOutUploadReceiverDTO
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof PayOutUploadReceiverDTO
   */
  content?: string;
  /**
   *
   * @type {boolean}
   * @memberof PayOutUploadReceiverDTO
   */
  valid?: boolean;
}

export const PayOutUploadReceiverDTOGroupEnum = {
  Customer: "CUSTOMER",
  Employee: "EMPLOYEE",
  Merchant: "MERCHANT",
  Friend: "FRIEND",
  Family: "FAMILY",
  Other: "OTHER",
} as const;

export type PayOutUploadReceiverDTOGroupEnum =
  (typeof PayOutUploadReceiverDTOGroupEnum)[keyof typeof PayOutUploadReceiverDTOGroupEnum];

/**
 *
 * @export
 * @interface PayPageDetailResponse
 */
export interface PayPageDetailResponse {
  /**
   *
   * @type {number}
   * @memberof PayPageDetailResponse
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof PayPageDetailResponse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PayPageDetailResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof PayPageDetailResponse
   */
  url?: string;
  /**
   *
   * @type {BankAccountResponse}
   * @memberof PayPageDetailResponse
   */
  bankAccount?: BankAccountResponse;
  /**
   *
   * @type {number}
   * @memberof PayPageDetailResponse
   */
  revenue?: number;
  /**
   *
   * @type {number}
   * @memberof PayPageDetailResponse
   */
  totalTransactions?: number;
  /**
   *
   * @type {number}
   * @memberof PayPageDetailResponse
   */
  totalProducts?: number;
  /**
   *
   * @type {string}
   * @memberof PayPageDetailResponse
   */
  status?: PayPageDetailResponseStatusEnum;
  /**
   *
   * @type {string}
   * @memberof PayPageDetailResponse
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof PayPageDetailResponse
   */
  createdBy?: string;
  /**
   *
   * @type {string}
   * @memberof PayPageDetailResponse
   */
  modifiedAt?: string;
  /**
   *
   * @type {string}
   * @memberof PayPageDetailResponse
   */
  modifiedBy?: string;
  /**
   *
   * @type {string}
   * @memberof PayPageDetailResponse
   */
  periodType?: PayPageDetailResponsePeriodTypeEnum;
  /**
   *
   * @type {number}
   * @memberof PayPageDetailResponse
   */
  totalSendMailSms?: number;
  /**
   *
   * @type {Array<ProductResponse>}
   * @memberof PayPageDetailResponse
   */
  products?: Array<ProductResponse>;
  /**
   *
   * @type {string}
   * @memberof PayPageDetailResponse
   */
  customerInfoType?: PayPageDetailResponseCustomerInfoTypeEnum;
  /**
   *
   * @type {CustomerInfoForm}
   * @memberof PayPageDetailResponse
   */
  customerInfoForm?: CustomerInfoForm;
  /**
   *
   * @type {CustomerDto}
   * @memberof PayPageDetailResponse
   */
  customer?: CustomerDto;
  /**
   *
   * @type {string}
   * @memberof PayPageDetailResponse
   */
  contactEmail?: string;
  /**
   *
   * @type {string}
   * @memberof PayPageDetailResponse
   */
  contactPhone?: string;
  /**
   *
   * @type {string}
   * @memberof PayPageDetailResponse
   */
  merchantName?: string;
  /**
   *
   * @type {string}
   * @memberof PayPageDetailResponse
   */
  merchantCode?: string;
  /**
   *
   * @type {ShopSettingResponse}
   * @memberof PayPageDetailResponse
   */
  shopSetting?: ShopSettingResponse;
}

export const PayPageDetailResponseStatusEnum = {
  Active: "ACTIVE",
  Inactive: "INACTIVE",
} as const;

export type PayPageDetailResponseStatusEnum =
  (typeof PayPageDetailResponseStatusEnum)[keyof typeof PayPageDetailResponseStatusEnum];
export const PayPageDetailResponsePeriodTypeEnum = {
  Daily: "DAILY",
  Weekly: "WEEKLY",
  Monthly: "MONTHLY",
  ThreeMonths: "THREE_MONTHS",
  SixMonths: "SIX_MONTHS",
  Yearly: "YEARLY",
} as const;

export type PayPageDetailResponsePeriodTypeEnum =
  (typeof PayPageDetailResponsePeriodTypeEnum)[keyof typeof PayPageDetailResponsePeriodTypeEnum];
export const PayPageDetailResponseCustomerInfoTypeEnum = {
  NoRequire: "NO_REQUIRE",
  AutoFill: "AUTO_FILL",
  RequireInput: "REQUIRE_INPUT",
  PartnerFill: "PARTNER_FILL",
} as const;

export type PayPageDetailResponseCustomerInfoTypeEnum =
  (typeof PayPageDetailResponseCustomerInfoTypeEnum)[keyof typeof PayPageDetailResponseCustomerInfoTypeEnum];

/**
 *
 * @export
 * @interface PayPageInfo
 */
export interface PayPageInfo {
  /**
   *
   * @type {number}
   * @memberof PayPageInfo
   */
  payPageId?: number;
  /**
   *
   * @type {string}
   * @memberof PayPageInfo
   */
  payPageCode?: string;
  /**
   *
   * @type {string}
   * @memberof PayPageInfo
   */
  payPageName?: string;
}
/**
 *
 * @export
 * @interface PayPageOrderRequest
 */
export interface PayPageOrderRequest {
  /**
   *
   * @type {string}
   * @memberof PayPageOrderRequest
   */
  trackingButton?: string;
  /**
   *
   * @type {Array<PayPageProductRequest>}
   * @memberof PayPageOrderRequest
   */
  items: Array<PayPageProductRequest>;
  /**
   *
   * @type {string}
   * @memberof PayPageOrderRequest
   */
  customerName?: string;
  /**
   *
   * @type {string}
   * @memberof PayPageOrderRequest
   */
  customerEmail?: string;
  /**
   *
   * @type {string}
   * @memberof PayPageOrderRequest
   */
  customerPhone?: string;
  /**
   *
   * @type {string}
   * @memberof PayPageOrderRequest
   */
  customerAddress?: string;
  /**
   *
   * @type {string}
   * @memberof PayPageOrderRequest
   */
  note?: string;
  /**
   *
   * @type {boolean}
   * @memberof PayPageOrderRequest
   */
  periodic?: boolean;
  /**
   *
   * @type {string}
   * @memberof PayPageOrderRequest
   */
  paymentType: PayPageOrderRequestPaymentTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PayPageOrderRequest
   */
  refTransactionId?: string;
  /**
   *
   * @type {string}
   * @memberof PayPageOrderRequest
   */
  successUrl?: string;
  /**
   *
   * @type {string}
   * @memberof PayPageOrderRequest
   */
  failUrl?: string;
  /**
   *
   * @type {number}
   * @memberof PayPageOrderRequest
   */
  redirectAfter?: number;
}

export const PayPageOrderRequestPaymentTypeEnum = {
  VietQr: "VIET_QR",
  AtmCard: "ATM_CARD",
  Banking: "BANKING",
} as const;

export type PayPageOrderRequestPaymentTypeEnum =
  (typeof PayPageOrderRequestPaymentTypeEnum)[keyof typeof PayPageOrderRequestPaymentTypeEnum];

/**
 *
 * @export
 * @interface PayPageProductRequest
 */
export interface PayPageProductRequest {
  /**
   *
   * @type {number}
   * @memberof PayPageProductRequest
   */
  productId: number;
  /**
   *
   * @type {number}
   * @memberof PayPageProductRequest
   */
  quantity?: number;
  /**
   *
   * @type {number}
   * @memberof PayPageProductRequest
   */
  price?: number;
}
/**
 *
 * @export
 * @interface PayPageResponse
 */
export interface PayPageResponse {
  /**
   *
   * @type {number}
   * @memberof PayPageResponse
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof PayPageResponse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PayPageResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof PayPageResponse
   */
  url?: string;
  /**
   *
   * @type {BankAccountResponse}
   * @memberof PayPageResponse
   */
  bankAccount?: BankAccountResponse;
  /**
   *
   * @type {number}
   * @memberof PayPageResponse
   */
  revenue?: number;
  /**
   *
   * @type {number}
   * @memberof PayPageResponse
   */
  totalTransactions?: number;
  /**
   *
   * @type {number}
   * @memberof PayPageResponse
   */
  totalProducts?: number;
  /**
   *
   * @type {string}
   * @memberof PayPageResponse
   */
  status?: PayPageResponseStatusEnum;
  /**
   *
   * @type {string}
   * @memberof PayPageResponse
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof PayPageResponse
   */
  createdBy?: string;
  /**
   *
   * @type {string}
   * @memberof PayPageResponse
   */
  modifiedAt?: string;
  /**
   *
   * @type {string}
   * @memberof PayPageResponse
   */
  modifiedBy?: string;
  /**
   *
   * @type {string}
   * @memberof PayPageResponse
   */
  periodType?: PayPageResponsePeriodTypeEnum;
  /**
   *
   * @type {number}
   * @memberof PayPageResponse
   */
  totalSendMailSms?: number;
}

export const PayPageResponseStatusEnum = {
  Active: "ACTIVE",
  Inactive: "INACTIVE",
} as const;

export type PayPageResponseStatusEnum =
  (typeof PayPageResponseStatusEnum)[keyof typeof PayPageResponseStatusEnum];
export const PayPageResponsePeriodTypeEnum = {
  Daily: "DAILY",
  Weekly: "WEEKLY",
  Monthly: "MONTHLY",
  ThreeMonths: "THREE_MONTHS",
  SixMonths: "SIX_MONTHS",
  Yearly: "YEARLY",
} as const;

export type PayPageResponsePeriodTypeEnum =
  (typeof PayPageResponsePeriodTypeEnum)[keyof typeof PayPageResponsePeriodTypeEnum];

/**
 *
 * @export
 * @interface PayPageRevenueResponse
 */
export interface PayPageRevenueResponse {
  /**
   *
   * @type {number}
   * @memberof PayPageRevenueResponse
   */
  totalTransactions?: number;
  /**
   *
   * @type {number}
   * @memberof PayPageRevenueResponse
   */
  totalRevenue?: number;
}
/**
 *
 * @export
 * @interface PayPageTransactionResponse
 */
export interface PayPageTransactionResponse {
  /**
   *
   * @type {string}
   * @memberof PayPageTransactionResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof PayPageTransactionResponse
   */
  status?: PayPageTransactionResponseStatusEnum;
  /**
   *
   * @type {string}
   * @memberof PayPageTransactionResponse
   */
  statusVi?: string;
  /**
   *
   * @type {string}
   * @memberof PayPageTransactionResponse
   */
  statusViPortal?: string;
  /**
   *
   * @type {number}
   * @memberof PayPageTransactionResponse
   */
  amount?: number;
  /**
   *
   * @type {number}
   * @memberof PayPageTransactionResponse
   */
  chargeAmount?: number;
  /**
   *
   * @type {number}
   * @memberof PayPageTransactionResponse
   */
  fee?: number;
  /**
   *
   * @type {number}
   * @memberof PayPageTransactionResponse
   */
  vatChargeAmount?: number;
  /**
   *
   * @type {number}
   * @memberof PayPageTransactionResponse
   */
  actualAmount?: number;
  /**
   *
   * @type {string}
   * @memberof PayPageTransactionResponse
   */
  refTransactionId?: string;
  /**
   *
   * @type {string}
   * @memberof PayPageTransactionResponse
   */
  createDateTime?: string;
  /**
   *
   * @type {string}
   * @memberof PayPageTransactionResponse
   */
  depositTime?: string;
  /**
   *
   * @type {string}
   * @memberof PayPageTransactionResponse
   */
  completeTime?: string;
  /**
   *
   * @type {string}
   * @memberof PayPageTransactionResponse
   */
  virtualAccount?: string;
  /**
   *
   * @type {string}
   * @memberof PayPageTransactionResponse
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof PayPageTransactionResponse
   */
  paymentType?: PayPageTransactionResponsePaymentTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PayPageTransactionResponse
   */
  bankType?: PayPageTransactionResponseBankTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PayPageTransactionResponse
   */
  successUrl?: string;
  /**
   *
   * @type {string}
   * @memberof PayPageTransactionResponse
   */
  failUrl?: string;
  /**
   *
   * @type {number}
   * @memberof PayPageTransactionResponse
   */
  callbackAfter?: number;
  /**
   *
   * @type {string}
   * @memberof PayPageTransactionResponse
   */
  merchantId?: string;
  /**
   *
   * @type {string}
   * @memberof PayPageTransactionResponse
   */
  merchantCode?: string;
  /**
   *
   * @type {string}
   * @memberof PayPageTransactionResponse
   */
  merchantName?: string;
  /**
   *
   * @type {string}
   * @memberof PayPageTransactionResponse
   */
  customerName?: string;
  /**
   *
   * @type {string}
   * @memberof PayPageTransactionResponse
   */
  customerPhone?: string;
  /**
   *
   * @type {string}
   * @memberof PayPageTransactionResponse
   */
  customerEmail?: string;
  /**
   *
   * @type {string}
   * @memberof PayPageTransactionResponse
   */
  customerAddress?: string;
  /**
   *
   * @type {string}
   * @memberof PayPageTransactionResponse
   */
  payLinkCode?: string;
  /**
   *
   * @type {string}
   * @memberof PayPageTransactionResponse
   */
  payPageCode?: string;
  /**
   *
   * @type {string}
   * @memberof PayPageTransactionResponse
   */
  payboxTransactionType?: PayPageTransactionResponsePayboxTransactionTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PayPageTransactionResponse
   */
  txnNumber?: string;
  /**
   *
   * @type {string}
   * @memberof PayPageTransactionResponse
   */
  accountName?: string;
  /**
   *
   * @type {string}
   * @memberof PayPageTransactionResponse
   */
  accountNo?: string;
  /**
   *
   * @type {string}
   * @memberof PayPageTransactionResponse
   */
  interBankTrace?: string;
  /**
   *
   * @type {string}
   * @memberof PayPageTransactionResponse
   */
  orderId?: string;
  /**
   *
   * @type {string}
   * @memberof PayPageTransactionResponse
   */
  orderStatus?: PayPageTransactionResponseOrderStatusEnum;
  /**
   *
   * @type {boolean}
   * @memberof PayPageTransactionResponse
   */
  forControl?: boolean;
}

export const PayPageTransactionResponseStatusEnum = {
  None: "NONE",
  Created: "CREATED",
  Success: "SUCCESS",
  Canceled: "CANCELED",
  Fail: "FAIL",
  Timeout: "TIMEOUT",
  Pending: "PENDING",
} as const;

export type PayPageTransactionResponseStatusEnum =
  (typeof PayPageTransactionResponseStatusEnum)[keyof typeof PayPageTransactionResponseStatusEnum];
export const PayPageTransactionResponsePaymentTypeEnum = {
  VietQr: "VIET_QR",
  AtmCard: "ATM_CARD",
  Banking: "BANKING",
} as const;

export type PayPageTransactionResponsePaymentTypeEnum =
  (typeof PayPageTransactionResponsePaymentTypeEnum)[keyof typeof PayPageTransactionResponsePaymentTypeEnum];
export const PayPageTransactionResponseBankTypeEnum = {
  Klb: "KLB",
  Umee: "UMEE",
} as const;

export type PayPageTransactionResponseBankTypeEnum =
  (typeof PayPageTransactionResponseBankTypeEnum)[keyof typeof PayPageTransactionResponseBankTypeEnum];
export const PayPageTransactionResponsePayboxTransactionTypeEnum = {
  Umee: "UMEE",
  Paybox: "PAYBOX",
  Pos: "POS",
  Usee: "USEE",
} as const;

export type PayPageTransactionResponsePayboxTransactionTypeEnum =
  (typeof PayPageTransactionResponsePayboxTransactionTypeEnum)[keyof typeof PayPageTransactionResponsePayboxTransactionTypeEnum];
export const PayPageTransactionResponseOrderStatusEnum = {
  Created: "CREATED",
  Paid: "PAID",
  Cancel: "CANCEL",
} as const;

export type PayPageTransactionResponseOrderStatusEnum =
  (typeof PayPageTransactionResponseOrderStatusEnum)[keyof typeof PayPageTransactionResponseOrderStatusEnum];

/**
 *
 * @export
 * @interface PayPosTransactionDetailResponse
 */
export interface PayPosTransactionDetailResponse {
  /**
   *
   * @type {string}
   * @memberof PayPosTransactionDetailResponse
   */
  id?: string;
  /**
   *
   * @type {boolean}
   * @memberof PayPosTransactionDetailResponse
   */
  finish?: boolean;
  /**
   *
   * @type {string}
   * @memberof PayPosTransactionDetailResponse
   */
  transactionCode?: string;
  /**
   *
   * @type {string}
   * @memberof PayPosTransactionDetailResponse
   */
  createdAt?: string;
  /**
   *
   * @type {number}
   * @memberof PayPosTransactionDetailResponse
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof PayPosTransactionDetailResponse
   */
  paymentType?: PayPosTransactionDetailResponsePaymentTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PayPosTransactionDetailResponse
   */
  deviceName?: string;
  /**
   *
   * @type {string}
   * @memberof PayPosTransactionDetailResponse
   */
  status?: PayPosTransactionDetailResponseStatusEnum;
  /**
   *
   * @type {string}
   * @memberof PayPosTransactionDetailResponse
   */
  staffName?: string;
  /**
   *
   * @type {string}
   * @memberof PayPosTransactionDetailResponse
   */
  customerName?: string;
  /**
   *
   * @type {string}
   * @memberof PayPosTransactionDetailResponse
   */
  customerEmail?: string;
  /**
   *
   * @type {string}
   * @memberof PayPosTransactionDetailResponse
   */
  accountNumber?: string;
}

export const PayPosTransactionDetailResponsePaymentTypeEnum = {
  VietQr: "VIET_QR",
  AtmCard: "ATM_CARD",
  Banking: "BANKING",
} as const;

export type PayPosTransactionDetailResponsePaymentTypeEnum =
  (typeof PayPosTransactionDetailResponsePaymentTypeEnum)[keyof typeof PayPosTransactionDetailResponsePaymentTypeEnum];
export const PayPosTransactionDetailResponseStatusEnum = {
  Success: "SUCCESS",
  Failed: "FAILED",
  Timeout: "TIMEOUT",
  Cancel: "CANCEL",
  Refunded: "REFUNDED",
  Created: "CREATED",
} as const;

export type PayPosTransactionDetailResponseStatusEnum =
  (typeof PayPosTransactionDetailResponseStatusEnum)[keyof typeof PayPosTransactionDetailResponseStatusEnum];

/**
 *
 * @export
 * @interface PaySubscriptionInvoiceResponse
 */
export interface PaySubscriptionInvoiceResponse {
  /**
   *
   * @type {string}
   * @memberof PaySubscriptionInvoiceResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof PaySubscriptionInvoiceResponse
   */
  subscriptionId?: string;
  /**
   *
   * @type {string}
   * @memberof PaySubscriptionInvoiceResponse
   */
  txnNumber?: string;
  /**
   *
   * @type {string}
   * @memberof PaySubscriptionInvoiceResponse
   */
  status?: PaySubscriptionInvoiceResponseStatusEnum;
  /**
   *
   * @type {number}
   * @memberof PaySubscriptionInvoiceResponse
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof PaySubscriptionInvoiceResponse
   */
  paymentDate?: string;
  /**
   *
   * @type {string}
   * @memberof PaySubscriptionInvoiceResponse
   */
  expiredDate?: string;
  /**
   *
   * @type {PaySubscriptionPlanResponse}
   * @memberof PaySubscriptionInvoiceResponse
   */
  plan?: PaySubscriptionPlanResponse;
  /**
   *
   * @type {CustomerDto}
   * @memberof PaySubscriptionInvoiceResponse
   */
  customer?: CustomerDto;
  /**
   *
   * @type {string}
   * @memberof PaySubscriptionInvoiceResponse
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof PaySubscriptionInvoiceResponse
   */
  paymentType?: PaySubscriptionInvoiceResponsePaymentTypeEnum;
}

export const PaySubscriptionInvoiceResponseStatusEnum = {
  None: "NONE",
  Created: "CREATED",
  Success: "SUCCESS",
  Canceled: "CANCELED",
  Fail: "FAIL",
  Timeout: "TIMEOUT",
  Pending: "PENDING",
} as const;

export type PaySubscriptionInvoiceResponseStatusEnum =
  (typeof PaySubscriptionInvoiceResponseStatusEnum)[keyof typeof PaySubscriptionInvoiceResponseStatusEnum];
export const PaySubscriptionInvoiceResponsePaymentTypeEnum = {
  VietQr: "VIET_QR",
  AtmCard: "ATM_CARD",
  Banking: "BANKING",
} as const;

export type PaySubscriptionInvoiceResponsePaymentTypeEnum =
  (typeof PaySubscriptionInvoiceResponsePaymentTypeEnum)[keyof typeof PaySubscriptionInvoiceResponsePaymentTypeEnum];

/**
 *
 * @export
 * @interface PaySubscriptionPlanFixedTimeModel
 */
export interface PaySubscriptionPlanFixedTimeModel {
  /**
   *
   * @type {string}
   * @memberof PaySubscriptionPlanFixedTimeModel
   */
  dayOfWeek?: PaySubscriptionPlanFixedTimeModelDayOfWeekEnum;
  /**
   *
   * @type {number}
   * @memberof PaySubscriptionPlanFixedTimeModel
   */
  dayOfMonth?: number;
  /**
   *
   * @type {string}
   * @memberof PaySubscriptionPlanFixedTimeModel
   */
  dayOfYear?: string;
}

export const PaySubscriptionPlanFixedTimeModelDayOfWeekEnum = {
  Sunday: "SUNDAY",
  Monday: "MONDAY",
  Tuesday: "TUESDAY",
  Wednesday: "WEDNESDAY",
  Thursday: "THURSDAY",
  Friday: "FRIDAY",
  Saturday: "SATURDAY",
} as const;

export type PaySubscriptionPlanFixedTimeModelDayOfWeekEnum =
  (typeof PaySubscriptionPlanFixedTimeModelDayOfWeekEnum)[keyof typeof PaySubscriptionPlanFixedTimeModelDayOfWeekEnum];

/**
 *
 * @export
 * @interface PaySubscriptionPlanResponse
 */
export interface PaySubscriptionPlanResponse {
  /**
   *
   * @type {string}
   * @memberof PaySubscriptionPlanResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof PaySubscriptionPlanResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof PaySubscriptionPlanResponse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PaySubscriptionPlanResponse
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof PaySubscriptionPlanResponse
   */
  status?: PaySubscriptionPlanResponseStatusEnum;
  /**
   *
   * @type {number}
   * @memberof PaySubscriptionPlanResponse
   */
  cycleValue?: number;
  /**
   *
   * @type {string}
   * @memberof PaySubscriptionPlanResponse
   */
  timeType?: PaySubscriptionPlanResponseTimeTypeEnum;
  /**
   *
   * @type {PaySubscriptionPlanFixedTimeModel}
   * @memberof PaySubscriptionPlanResponse
   */
  fixedTime?: PaySubscriptionPlanFixedTimeModel;
  /**
   *
   * @type {string}
   * @memberof PaySubscriptionPlanResponse
   */
  type?: PaySubscriptionPlanResponseTypeEnum;
  /**
   *
   * @type {number}
   * @memberof PaySubscriptionPlanResponse
   */
  fixedAmount?: number;
  /**
   *
   * @type {number}
   * @memberof PaySubscriptionPlanResponse
   */
  expirationDays?: number;
  /**
   *
   * @type {string}
   * @memberof PaySubscriptionPlanResponse
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof PaySubscriptionPlanResponse
   */
  callbackUrl?: string;
  /**
   *
   * @type {string}
   * @memberof PaySubscriptionPlanResponse
   */
  modifiedAt?: string;
  /**
   *
   * @type {string}
   * @memberof PaySubscriptionPlanResponse
   */
  paymentType?: PaySubscriptionPlanResponsePaymentTypeEnum;
  /**
   *
   * @type {BankAccountDto}
   * @memberof PaySubscriptionPlanResponse
   */
  bankAccount?: BankAccountDto;
  /**
   *
   * @type {boolean}
   * @memberof PaySubscriptionPlanResponse
   */
  fixedPaymentTime?: boolean;
}

export const PaySubscriptionPlanResponseStatusEnum = {
  Created: "CREATED",
  Active: "ACTIVE",
  Inactive: "INACTIVE",
} as const;

export type PaySubscriptionPlanResponseStatusEnum =
  (typeof PaySubscriptionPlanResponseStatusEnum)[keyof typeof PaySubscriptionPlanResponseStatusEnum];
export const PaySubscriptionPlanResponseTimeTypeEnum = {
  Day: "DAY",
  Week: "WEEK",
  Month: "MONTH",
  Year: "YEAR",
} as const;

export type PaySubscriptionPlanResponseTimeTypeEnum =
  (typeof PaySubscriptionPlanResponseTimeTypeEnum)[keyof typeof PaySubscriptionPlanResponseTimeTypeEnum];
export const PaySubscriptionPlanResponseTypeEnum = {
  RecurringQuantity: "RECURRING_QUANTITY",
  MeteredUsage: "METERED_USAGE",
} as const;

export type PaySubscriptionPlanResponseTypeEnum =
  (typeof PaySubscriptionPlanResponseTypeEnum)[keyof typeof PaySubscriptionPlanResponseTypeEnum];
export const PaySubscriptionPlanResponsePaymentTypeEnum = {
  Prepaid: "PREPAID",
  Postpaid: "POSTPAID",
} as const;

export type PaySubscriptionPlanResponsePaymentTypeEnum =
  (typeof PaySubscriptionPlanResponsePaymentTypeEnum)[keyof typeof PaySubscriptionPlanResponsePaymentTypeEnum];

/**
 *
 * @export
 * @interface PaySubscriptionResponse
 */
export interface PaySubscriptionResponse {
  /**
   *
   * @type {string}
   * @memberof PaySubscriptionResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof PaySubscriptionResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof PaySubscriptionResponse
   */
  status?: PaySubscriptionResponseStatusEnum;
  /**
   *
   * @type {PaySubscriptionPlanResponse}
   * @memberof PaySubscriptionResponse
   */
  plan?: PaySubscriptionPlanResponse;
  /**
   *
   * @type {CustomerDto}
   * @memberof PaySubscriptionResponse
   */
  customer?: CustomerDto;
  /**
   *
   * @type {string}
   * @memberof PaySubscriptionResponse
   */
  createdAt?: string;
  /**
   *
   * @type {number}
   * @memberof PaySubscriptionResponse
   */
  numOfCycles?: number;
  /**
   *
   * @type {number}
   * @memberof PaySubscriptionResponse
   */
  numOfPayments?: number;
  /**
   *
   * @type {string}
   * @memberof PaySubscriptionResponse
   */
  prePaymentDate?: string;
  /**
   *
   * @type {string}
   * @memberof PaySubscriptionResponse
   */
  nextPaymentDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof PaySubscriptionResponse
   */
  allowTrial?: boolean;
  /**
   *
   * @type {string}
   * @memberof PaySubscriptionResponse
   */
  trialTimeType?: PaySubscriptionResponseTrialTimeTypeEnum;
  /**
   *
   * @type {number}
   * @memberof PaySubscriptionResponse
   */
  trialTimeValue?: number;
}

export const PaySubscriptionResponseStatusEnum = {
  Trial: "TRIAL",
  Active: "ACTIVE",
  Paused: "PAUSED",
  PendingCancellation: "PENDING_CANCELLATION",
  Cancelled: "CANCELLED",
  Expired: "EXPIRED",
  PaymentExpired: "PAYMENT_EXPIRED",
  CreateInvoiceFailed: "CREATE_INVOICE_FAILED",
} as const;

export type PaySubscriptionResponseStatusEnum =
  (typeof PaySubscriptionResponseStatusEnum)[keyof typeof PaySubscriptionResponseStatusEnum];
export const PaySubscriptionResponseTrialTimeTypeEnum = {
  Day: "DAY",
  Week: "WEEK",
  Month: "MONTH",
  Year: "YEAR",
} as const;

export type PaySubscriptionResponseTrialTimeTypeEnum =
  (typeof PaySubscriptionResponseTrialTimeTypeEnum)[keyof typeof PaySubscriptionResponseTrialTimeTypeEnum];

/**
 *
 * @export
 * @interface PayboxDeviceResponse
 */
export interface PayboxDeviceResponse {
  /**
   *
   * @type {string}
   * @memberof PayboxDeviceResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof PayboxDeviceResponse
   */
  hardwareId?: string;
  /**
   *
   * @type {string}
   * @memberof PayboxDeviceResponse
   */
  displayName?: string;
  /**
   *
   * @type {string}
   * @memberof PayboxDeviceResponse
   */
  manToken?: string;
  /**
   *
   * @type {string}
   * @memberof PayboxDeviceResponse
   */
  model?: string;
  /**
   *
   * @type {string}
   * @memberof PayboxDeviceResponse
   */
  location?: string;
  /**
   *
   * @type {string}
   * @memberof PayboxDeviceResponse
   */
  version?: string;
  /**
   *
   * @type {string}
   * @memberof PayboxDeviceResponse
   */
  hwVersion?: string;
  /**
   *
   * @type {string}
   * @memberof PayboxDeviceResponse
   */
  bankAccountId?: string;
  /**
   *
   * @type {boolean}
   * @memberof PayboxDeviceResponse
   */
  online?: boolean;
}
/**
 *
 * @export
 * @interface PayboxTransactionResponse
 */
export interface PayboxTransactionResponse {
  /**
   *
   * @type {string}
   * @memberof PayboxTransactionResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof PayboxTransactionResponse
   */
  status?: PayboxTransactionResponseStatusEnum;
  /**
   *
   * @type {string}
   * @memberof PayboxTransactionResponse
   */
  deviceId?: string;
  /**
   *
   * @type {number}
   * @memberof PayboxTransactionResponse
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof PayboxTransactionResponse
   */
  createTime?: string;
  /**
   *
   * @type {string}
   * @memberof PayboxTransactionResponse
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof PayboxTransactionResponse
   */
  bin?: string;
  /**
   *
   * @type {string}
   * @memberof PayboxTransactionResponse
   */
  virtualAccount?: string;
  /**
   *
   * @type {string}
   * @memberof PayboxTransactionResponse
   */
  actualAccount?: string;
  /**
   *
   * @type {number}
   * @memberof PayboxTransactionResponse
   */
  millisecond?: number;
}

export const PayboxTransactionResponseStatusEnum = {
  None: "NONE",
  Created: "CREATED",
  Success: "SUCCESS",
  Canceled: "CANCELED",
  Fail: "FAIL",
  Timeout: "TIMEOUT",
  Pending: "PENDING",
} as const;

export type PayboxTransactionResponseStatusEnum =
  (typeof PayboxTransactionResponseStatusEnum)[keyof typeof PayboxTransactionResponseStatusEnum];

/**
 *
 * @export
 * @interface PaymentMethodInfo
 */
export interface PaymentMethodInfo {
  /**
   *
   * @type {number}
   * @memberof PaymentMethodInfo
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof PaymentMethodInfo
   */
  logo?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentMethodInfo
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof PaymentMethodInfo
   */
  dynamicFee?: number;
  /**
   *
   * @type {number}
   * @memberof PaymentMethodInfo
   */
  fixedFee?: number;
  /**
   *
   * @type {number}
   * @memberof PaymentMethodInfo
   */
  paymentLimit?: number;
  /**
   *
   * @type {string}
   * @memberof PaymentMethodInfo
   */
  type?: PaymentMethodInfoTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PaymentMethodInfo
   */
  status?: PaymentMethodInfoStatusEnum;
  /**
   *
   * @type {string}
   * @memberof PaymentMethodInfo
   */
  paymentType?: PaymentMethodInfoPaymentTypeEnum;
}

export const PaymentMethodInfoTypeEnum = {
  Domestic: "DOMESTIC",
  International: "INTERNATIONAL",
} as const;

export type PaymentMethodInfoTypeEnum =
  (typeof PaymentMethodInfoTypeEnum)[keyof typeof PaymentMethodInfoTypeEnum];
export const PaymentMethodInfoStatusEnum = {
  Active: "ACTIVE",
  Inactive: "INACTIVE",
} as const;

export type PaymentMethodInfoStatusEnum =
  (typeof PaymentMethodInfoStatusEnum)[keyof typeof PaymentMethodInfoStatusEnum];
export const PaymentMethodInfoPaymentTypeEnum = {
  VietQr: "VIET_QR",
  AtmCard: "ATM_CARD",
  Banking: "BANKING",
} as const;

export type PaymentMethodInfoPaymentTypeEnum =
  (typeof PaymentMethodInfoPaymentTypeEnum)[keyof typeof PaymentMethodInfoPaymentTypeEnum];

/**
 *
 * @export
 * @interface PaymentTransactionResponse
 */
export interface PaymentTransactionResponse {
  /**
   *
   * @type {string}
   * @memberof PaymentTransactionResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentTransactionResponse
   */
  status?: PaymentTransactionResponseStatusEnum;
  /**
   *
   * @type {number}
   * @memberof PaymentTransactionResponse
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof PaymentTransactionResponse
   */
  refTransactionId?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentTransactionResponse
   */
  createDateTime?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentTransactionResponse
   */
  completeTime?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentTransactionResponse
   */
  virtualAccount?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentTransactionResponse
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentTransactionResponse
   */
  paymentType?: PaymentTransactionResponsePaymentTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PaymentTransactionResponse
   */
  txnNumber?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentTransactionResponse
   */
  accountName?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentTransactionResponse
   */
  accountNo?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentTransactionResponse
   */
  interBankTrace?: string;
}

export const PaymentTransactionResponseStatusEnum = {
  None: "NONE",
  Created: "CREATED",
  Success: "SUCCESS",
  Canceled: "CANCELED",
  Fail: "FAIL",
  Timeout: "TIMEOUT",
  Pending: "PENDING",
} as const;

export type PaymentTransactionResponseStatusEnum =
  (typeof PaymentTransactionResponseStatusEnum)[keyof typeof PaymentTransactionResponseStatusEnum];
export const PaymentTransactionResponsePaymentTypeEnum = {
  VietQr: "VIET_QR",
  AtmCard: "ATM_CARD",
  Banking: "BANKING",
} as const;

export type PaymentTransactionResponsePaymentTypeEnum =
  (typeof PaymentTransactionResponsePaymentTypeEnum)[keyof typeof PaymentTransactionResponsePaymentTypeEnum];

/**
 *
 * @export
 * @interface PermissionGroup
 */
export interface PermissionGroup {
  /**
   *
   * @type {number}
   * @memberof PermissionGroup
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof PermissionGroup
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PermissionGroup
   */
  description?: string;
  /**
   *
   * @type {Array<PermissionList>}
   * @memberof PermissionGroup
   */
  permissions?: Array<PermissionList>;
}
/**
 *
 * @export
 * @interface PermissionList
 */
export interface PermissionList {
  /**
   *
   * @type {number}
   * @memberof PermissionList
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof PermissionList
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PermissionList
   */
  description?: string;
  /**
   *
   * @type {Array<PermissionResponse>}
   * @memberof PermissionList
   */
  actions?: Array<PermissionResponse>;
}
/**
 *
 * @export
 * @interface PermissionResponse
 */
export interface PermissionResponse {
  /**
   *
   * @type {number}
   * @memberof PermissionResponse
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof PermissionResponse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PermissionResponse
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof PermissionResponse
   */
  scope?: PermissionResponseScopeEnum;
}

export const PermissionResponseScopeEnum = {
  Admin: "ADMIN",
  Merchant: "MERCHANT",
  RootAdmin: "ROOT_ADMIN",
  InternalService: "INTERNAL_SERVICE",
} as const;

export type PermissionResponseScopeEnum =
  (typeof PermissionResponseScopeEnum)[keyof typeof PermissionResponseScopeEnum];

/**
 *
 * @export
 * @interface PersonalMerchantDto
 */
export interface PersonalMerchantDto {
  /**
   *
   * @type {string}
   * @memberof PersonalMerchantDto
   */
  frontUrl: string;
  /**
   *
   * @type {string}
   * @memberof PersonalMerchantDto
   */
  backUrl: string;
}
/**
 *
 * @export
 * @interface PortalCreateMemberRequest
 */
export interface PortalCreateMemberRequest {
  /**
   *
   * @type {string}
   * @memberof PortalCreateMemberRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof PortalCreateMemberRequest
   */
  phone: string;
  /**
   *
   * @type {string}
   * @memberof PortalCreateMemberRequest
   */
  email: string;
  /**
   *
   * @type {number}
   * @memberof PortalCreateMemberRequest
   */
  roleGroupId: number;
  /**
   *
   * @type {Array<string>}
   * @memberof PortalCreateMemberRequest
   */
  bankAccountIds: Array<string>;
}
/**
 *
 * @export
 * @interface PortalGetAllRoleGroupResponse
 */
export interface PortalGetAllRoleGroupResponse {
  /**
   *
   * @type {Array<RoleGroupDetailResponse>}
   * @memberof PortalGetAllRoleGroupResponse
   */
  roleGroups?: Array<RoleGroupDetailResponse>;
}
/**
 *
 * @export
 * @interface PortalLoginOTPResponse
 */
export interface PortalLoginOTPResponse {
  /**
   *
   * @type {string}
   * @memberof PortalLoginOTPResponse
   */
  timestamp?: string;
  /**
   *
   * @type {number}
   * @memberof PortalLoginOTPResponse
   */
  timeout?: number;
  /**
   *
   * @type {string}
   * @memberof PortalLoginOTPResponse
   */
  sessionId?: string;
}
/**
 *
 * @export
 * @interface PortalLoginRequest
 */
export interface PortalLoginRequest {
  /**
   *
   * @type {string}
   * @memberof PortalLoginRequest
   */
  phone: string;
  /**
   *
   * @type {string}
   * @memberof PortalLoginRequest
   */
  password: string;
}
/**
 *
 * @export
 * @interface PortalMemberLoginOTPResponse
 */
export interface PortalMemberLoginOTPResponse {
  /**
   *
   * @type {string}
   * @memberof PortalMemberLoginOTPResponse
   */
  timestamp?: string;
  /**
   *
   * @type {number}
   * @memberof PortalMemberLoginOTPResponse
   */
  timeout?: number;
  /**
   *
   * @type {string}
   * @memberof PortalMemberLoginOTPResponse
   */
  sessionId?: string;
}
/**
 *
 * @export
 * @interface PortalMemberLoginRequest
 */
export interface PortalMemberLoginRequest {
  /**
   *
   * @type {string}
   * @memberof PortalMemberLoginRequest
   */
  merchantCode: string;
  /**
   *
   * @type {string}
   * @memberof PortalMemberLoginRequest
   */
  phone: string;
  /**
   *
   * @type {string}
   * @memberof PortalMemberLoginRequest
   */
  password: string;
}
/**
 *
 * @export
 * @interface PortalMemberResetPassGetOTPRequest
 */
export interface PortalMemberResetPassGetOTPRequest {
  /**
   *
   * @type {string}
   * @memberof PortalMemberResetPassGetOTPRequest
   */
  merchantCode: string;
  /**
   *
   * @type {string}
   * @memberof PortalMemberResetPassGetOTPRequest
   */
  phone: string;
}
/**
 *
 * @export
 * @interface PortalMemberResetPassGetOTPResponse
 */
export interface PortalMemberResetPassGetOTPResponse {
  /**
   *
   * @type {string}
   * @memberof PortalMemberResetPassGetOTPResponse
   */
  sessionId?: string;
  /**
   *
   * @type {number}
   * @memberof PortalMemberResetPassGetOTPResponse
   */
  timeout?: number;
}
/**
 *
 * @export
 * @interface PortalMemberResetPassRequest
 */
export interface PortalMemberResetPassRequest {
  /**
   *
   * @type {string}
   * @memberof PortalMemberResetPassRequest
   */
  merchantCode: string;
  /**
   *
   * @type {string}
   * @memberof PortalMemberResetPassRequest
   */
  phone: string;
  /**
   *
   * @type {string}
   * @memberof PortalMemberResetPassRequest
   */
  password: string;
  /**
   *
   * @type {string}
   * @memberof PortalMemberResetPassRequest
   */
  sessionId: string;
  /**
   *
   * @type {string}
   * @memberof PortalMemberResetPassRequest
   */
  token: string;
}
/**
 *
 * @export
 * @interface PortalMemberResetPassVerifyOTPRequest
 */
export interface PortalMemberResetPassVerifyOTPRequest {
  /**
   *
   * @type {string}
   * @memberof PortalMemberResetPassVerifyOTPRequest
   */
  merchantCode: string;
  /**
   *
   * @type {string}
   * @memberof PortalMemberResetPassVerifyOTPRequest
   */
  phone: string;
  /**
   *
   * @type {string}
   * @memberof PortalMemberResetPassVerifyOTPRequest
   */
  sessionId: string;
  /**
   *
   * @type {string}
   * @memberof PortalMemberResetPassVerifyOTPRequest
   */
  otp: string;
}
/**
 *
 * @export
 * @interface PortalMemberVerifyOTPLoginRequest
 */
export interface PortalMemberVerifyOTPLoginRequest {
  /**
   *
   * @type {string}
   * @memberof PortalMemberVerifyOTPLoginRequest
   */
  merchantCode: string;
  /**
   *
   * @type {string}
   * @memberof PortalMemberVerifyOTPLoginRequest
   */
  phone: string;
  /**
   *
   * @type {string}
   * @memberof PortalMemberVerifyOTPLoginRequest
   */
  password: string;
  /**
   *
   * @type {string}
   * @memberof PortalMemberVerifyOTPLoginRequest
   */
  sessionId: string;
  /**
   *
   * @type {string}
   * @memberof PortalMemberVerifyOTPLoginRequest
   */
  otp: string;
}
/**
 *
 * @export
 * @interface PortalNextStateVietQrProRequest
 */
export interface PortalNextStateVietQrProRequest {
  /**
   *
   * @type {string}
   * @memberof PortalNextStateVietQrProRequest
   */
  action: PortalNextStateVietQrProRequestActionEnum;
}

export const PortalNextStateVietQrProRequestActionEnum = {
  RequestActivation: "REQUEST_ACTIVATION",
  Enable: "ENABLE",
  Disable: "DISABLE",
} as const;

export type PortalNextStateVietQrProRequestActionEnum =
  (typeof PortalNextStateVietQrProRequestActionEnum)[keyof typeof PortalNextStateVietQrProRequestActionEnum];

/**
 *
 * @export
 * @interface PortalPlacePosOrderRequest
 */
export interface PortalPlacePosOrderRequest {
  /**
   *
   * @type {Array<PosOrderItemDto>}
   * @memberof PortalPlacePosOrderRequest
   */
  itemList: Array<PosOrderItemDto>;
  /**
   *
   * @type {string}
   * @memberof PortalPlacePosOrderRequest
   */
  receiverName: string;
  /**
   *
   * @type {string}
   * @memberof PortalPlacePosOrderRequest
   */
  phone: string;
  /**
   *
   * @type {string}
   * @memberof PortalPlacePosOrderRequest
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof PortalPlacePosOrderRequest
   */
  note?: string;
  /**
   *
   * @type {string}
   * @memberof PortalPlacePosOrderRequest
   */
  createdAt: string;
}
/**
 *
 * @export
 * @interface PortalPlacePosOrderResponse
 */
export interface PortalPlacePosOrderResponse {
  /**
   *
   * @type {CreateTransactionResponse}
   * @memberof PortalPlacePosOrderResponse
   */
  transaction?: CreateTransactionResponse;
  /**
   *
   * @type {PosOrderResponse}
   * @memberof PortalPlacePosOrderResponse
   */
  posOrder?: PosOrderResponse;
}
/**
 *
 * @export
 * @interface PortalRefreshTokenRequest
 */
export interface PortalRefreshTokenRequest {
  /**
   *
   * @type {string}
   * @memberof PortalRefreshTokenRequest
   */
  refreshToken: string;
}
/**
 *
 * @export
 * @interface PortalSubmitInfoV2Request
 */
export interface PortalSubmitInfoV2Request {
  /**
   *
   * @type {string}
   * @memberof PortalSubmitInfoV2Request
   */
  businessType: PortalSubmitInfoV2RequestBusinessTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PortalSubmitInfoV2Request
   */
  name: string;
  /**
   *
   * @type {Array<string>}
   * @memberof PortalSubmitInfoV2Request
   */
  businessProducts: Array<PortalSubmitInfoV2RequestBusinessProductsEnum>;
  /**
   *
   * @type {string}
   * @memberof PortalSubmitInfoV2Request
   */
  product: string;
  /**
   *
   * @type {number}
   * @memberof PortalSubmitInfoV2Request
   */
  maxPrice: number;
  /**
   *
   * @type {string}
   * @memberof PortalSubmitInfoV2Request
   */
  revenueType: PortalSubmitInfoV2RequestRevenueTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PortalSubmitInfoV2Request
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof PortalSubmitInfoV2Request
   */
  website?: string;
  /**
   *
   * @type {string}
   * @memberof PortalSubmitInfoV2Request
   */
  address?: string;
  /**
   *
   * @type {PersonalMerchantDto}
   * @memberof PortalSubmitInfoV2Request
   */
  personalMerchant?: PersonalMerchantDto;
  /**
   *
   * @type {string}
   * @memberof PortalSubmitInfoV2Request
   */
  taxIdNo?: string;
  /**
   *
   * @type {EnterpriseMerchantDto}
   * @memberof PortalSubmitInfoV2Request
   */
  enterpriseMerchant?: EnterpriseMerchantDto;
  /**
   *
   * @type {string}
   * @memberof PortalSubmitInfoV2Request
   */
  chargeType?: PortalSubmitInfoV2RequestChargeTypeEnum;
}

export const PortalSubmitInfoV2RequestBusinessTypeEnum = {
  Personal: "PERSONAL",
  Organization: "ORGANIZATION",
} as const;

export type PortalSubmitInfoV2RequestBusinessTypeEnum =
  (typeof PortalSubmitInfoV2RequestBusinessTypeEnum)[keyof typeof PortalSubmitInfoV2RequestBusinessTypeEnum];
export const PortalSubmitInfoV2RequestBusinessProductsEnum = {
  VehicleSale: "VEHICLE_SALE",
  Logistics: "LOGISTICS",
  ResidentAndFoodService: "RESIDENT_AND_FOOD_SERVICE",
  InformationAndMedia: "INFORMATION_AND_MEDIA",
  FinanceAndInsurance: "FINANCE_AND_INSURANCE",
  RealEstate: "REAL_ESTATE",
  AdministrativeAndSupportService: "ADMINISTRATIVE_AND_SUPPORT_SERVICE",
  Education: "EDUCATION",
  PublicHealth: "PUBLIC_HEALTH",
  HealthCareAndBeauty: "HEALTH_CARE_AND_BEAUTY",
  ArtAndEntertainment: "ART_AND_ENTERTAINMENT",
  Other: "OTHER",
} as const;

export type PortalSubmitInfoV2RequestBusinessProductsEnum =
  (typeof PortalSubmitInfoV2RequestBusinessProductsEnum)[keyof typeof PortalSubmitInfoV2RequestBusinessProductsEnum];
export const PortalSubmitInfoV2RequestRevenueTypeEnum = {
  _1: "REVENUE_TYPE_1",
  _2: "REVENUE_TYPE_2",
  _3: "REVENUE_TYPE_3",
  _4: "REVENUE_TYPE_4",
} as const;

export type PortalSubmitInfoV2RequestRevenueTypeEnum =
  (typeof PortalSubmitInfoV2RequestRevenueTypeEnum)[keyof typeof PortalSubmitInfoV2RequestRevenueTypeEnum];
export const PortalSubmitInfoV2RequestChargeTypeEnum = {
  Immediately: "IMMEDIATELY",
  Later: "LATER",
} as const;

export type PortalSubmitInfoV2RequestChargeTypeEnum =
  (typeof PortalSubmitInfoV2RequestChargeTypeEnum)[keyof typeof PortalSubmitInfoV2RequestChargeTypeEnum];

/**
 *
 * @export
 * @interface PortalTotalTransactionResponse
 */
export interface PortalTotalTransactionResponse {
  /**
   *
   * @type {number}
   * @memberof PortalTotalTransactionResponse
   */
  total?: number;
  /**
   *
   * @type {number}
   * @memberof PortalTotalTransactionResponse
   */
  amount?: number;
}
/**
 *
 * @export
 * @interface PortalTransactionStatisticResponse
 */
export interface PortalTransactionStatisticResponse {
  /**
   *
   * @type {{ [key: string]: { [key: string]: number; }; }}
   * @memberof PortalTransactionStatisticResponse
   */
  statistics?: { [key: string]: { [key: string]: number } };
}
/**
 *
 * @export
 * @interface PortalUpdateMemberRequest
 */
export interface PortalUpdateMemberRequest {
  /**
   *
   * @type {string}
   * @memberof PortalUpdateMemberRequest
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PortalUpdateMemberRequest
   */
  email?: string;
  /**
   *
   * @type {number}
   * @memberof PortalUpdateMemberRequest
   */
  roleGroupId: number;
  /**
   *
   * @type {Array<string>}
   * @memberof PortalUpdateMemberRequest
   */
  bankAccountIds?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof PortalUpdateMemberRequest
   */
  status?: PortalUpdateMemberRequestStatusEnum;
}

export const PortalUpdateMemberRequestStatusEnum = {
  Blocked: "BLOCKED",
  Active: "ACTIVE",
  Inactive: "INACTIVE",
  NotLoggedIn: "NOT_LOGGED_IN",
} as const;

export type PortalUpdateMemberRequestStatusEnum =
  (typeof PortalUpdateMemberRequestStatusEnum)[keyof typeof PortalUpdateMemberRequestStatusEnum];

/**
 *
 * @export
 * @interface PortalUpdatePosOrderRequest
 */
export interface PortalUpdatePosOrderRequest {
  /**
   *
   * @type {string}
   * @memberof PortalUpdatePosOrderRequest
   */
  orderId: string;
  /**
   *
   * @type {string}
   * @memberof PortalUpdatePosOrderRequest
   */
  receiverName?: string;
  /**
   *
   * @type {string}
   * @memberof PortalUpdatePosOrderRequest
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof PortalUpdatePosOrderRequest
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof PortalUpdatePosOrderRequest
   */
  note?: string;
}
/**
 *
 * @export
 * @interface PortalUpdateWebhookUrlRequest
 */
export interface PortalUpdateWebhookUrlRequest {
  /**
   *
   * @type {string}
   * @memberof PortalUpdateWebhookUrlRequest
   */
  webhookUrl: string;
}
/**
 *
 * @export
 * @interface PortalVerifyOTPLoginRequest
 */
export interface PortalVerifyOTPLoginRequest {
  /**
   *
   * @type {string}
   * @memberof PortalVerifyOTPLoginRequest
   */
  phone: string;
  /**
   *
   * @type {string}
   * @memberof PortalVerifyOTPLoginRequest
   */
  password: string;
  /**
   *
   * @type {string}
   * @memberof PortalVerifyOTPLoginRequest
   */
  sessionId: string;
  /**
   *
   * @type {string}
   * @memberof PortalVerifyOTPLoginRequest
   */
  otp: string;
}
/**
 *
 * @export
 * @interface PosCheckTransactionResponse
 */
export interface PosCheckTransactionResponse {
  /**
   *
   * @type {string}
   * @memberof PosCheckTransactionResponse
   */
  transactionId?: string;
  /**
   *
   * @type {string}
   * @memberof PosCheckTransactionResponse
   */
  status?: PosCheckTransactionResponseStatusEnum;
}

export const PosCheckTransactionResponseStatusEnum = {
  None: "NONE",
  Created: "CREATED",
  Success: "SUCCESS",
  Canceled: "CANCELED",
  Fail: "FAIL",
  Timeout: "TIMEOUT",
  Pending: "PENDING",
} as const;

export type PosCheckTransactionResponseStatusEnum =
  (typeof PosCheckTransactionResponseStatusEnum)[keyof typeof PosCheckTransactionResponseStatusEnum];

/**
 *
 * @export
 * @interface PosCreateTransactionRequest
 */
export interface PosCreateTransactionRequest {
  /**
   *
   * @type {string}
   * @memberof PosCreateTransactionRequest
   */
  deviceId?: string;
  /**
   *
   * @type {number}
   * @memberof PosCreateTransactionRequest
   */
  amount: number;
  /**
   *
   * @type {string}
   * @memberof PosCreateTransactionRequest
   */
  content?: string;
  /**
   *
   * @type {number}
   * @memberof PosCreateTransactionRequest
   */
  timeout?: number;
}
/**
 *
 * @export
 * @interface PosModelPhotoDto
 */
export interface PosModelPhotoDto {
  /**
   *
   * @type {number}
   * @memberof PosModelPhotoDto
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof PosModelPhotoDto
   */
  url: string;
}
/**
 *
 * @export
 * @interface PosModelResponse
 */
export interface PosModelResponse {
  /**
   *
   * @type {string}
   * @memberof PosModelResponse
   */
  createdBy?: string;
  /**
   *
   * @type {string}
   * @memberof PosModelResponse
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof PosModelResponse
   */
  modifiedBy?: string;
  /**
   *
   * @type {string}
   * @memberof PosModelResponse
   */
  modifiedAt?: string;
  /**
   *
   * @type {number}
   * @memberof PosModelResponse
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof PosModelResponse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PosModelResponse
   */
  model?: string;
  /**
   *
   * @type {number}
   * @memberof PosModelResponse
   */
  price?: number;
  /**
   *
   * @type {number}
   * @memberof PosModelResponse
   */
  stock?: number;
  /**
   *
   * @type {boolean}
   * @memberof PosModelResponse
   */
  available?: boolean;
  /**
   *
   * @type {string}
   * @memberof PosModelResponse
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof PosModelResponse
   */
  warranty?: number;
  /**
   *
   * @type {number}
   * @memberof PosModelResponse
   */
  discount?: number;
  /**
   *
   * @type {string}
   * @memberof PosModelResponse
   */
  mainPhotoUrl?: string;
  /**
   *
   * @type {Array<PosModelPhotoDto>}
   * @memberof PosModelResponse
   */
  photos?: Array<PosModelPhotoDto>;
  /**
   *
   * @type {number}
   * @memberof PosModelResponse
   */
  vat?: number;
}
/**
 *
 * @export
 * @interface PosOrderItemDto
 */
export interface PosOrderItemDto {
  /**
   *
   * @type {number}
   * @memberof PosOrderItemDto
   */
  itemId?: number;
  /**
   *
   * @type {number}
   * @memberof PosOrderItemDto
   */
  posModelId: number;
  /**
   *
   * @type {string}
   * @memberof PosOrderItemDto
   */
  posModelName?: string;
  /**
   *
   * @type {number}
   * @memberof PosOrderItemDto
   */
  unitPrice?: number;
  /**
   *
   * @type {number}
   * @memberof PosOrderItemDto
   */
  quantity: number;
  /**
   *
   * @type {number}
   * @memberof PosOrderItemDto
   */
  subtotal?: number;
  /**
   *
   * @type {string}
   * @memberof PosOrderItemDto
   */
  urlMainPhoto?: string;
  /**
   *
   * @type {string}
   * @memberof PosOrderItemDto
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof PosOrderItemDto
   */
  vat?: number;
}
/**
 *
 * @export
 * @interface PosOrderListResponse
 */
export interface PosOrderListResponse {
  /**
   *
   * @type {string}
   * @memberof PosOrderListResponse
   */
  orderId?: string;
  /**
   *
   * @type {number}
   * @memberof PosOrderListResponse
   */
  total?: number;
  /**
   *
   * @type {string}
   * @memberof PosOrderListResponse
   */
  createdAt?: string;
  /**
   *
   * @type {Array<PosOrderItemDto>}
   * @memberof PosOrderListResponse
   */
  items?: Array<PosOrderItemDto>;
  /**
   *
   * @type {string}
   * @memberof PosOrderListResponse
   */
  posOrderStatusPayment?: PosOrderListResponsePosOrderStatusPaymentEnum;
  /**
   *
   * @type {string}
   * @memberof PosOrderListResponse
   */
  posOrderStatus?: PosOrderListResponsePosOrderStatusEnum;
  /**
   *
   * @type {string}
   * @memberof PosOrderListResponse
   */
  merchantId?: string;
}

export const PosOrderListResponsePosOrderStatusPaymentEnum = {
  WaitingPayment: "WAITING_PAYMENT",
  Cancelled: "CANCELLED",
  TimeOut: "TIME_OUT",
  Paid: "PAID",
} as const;

export type PosOrderListResponsePosOrderStatusPaymentEnum =
  (typeof PosOrderListResponsePosOrderStatusPaymentEnum)[keyof typeof PosOrderListResponsePosOrderStatusPaymentEnum];
export const PosOrderListResponsePosOrderStatusEnum = {
  Delivering: "DELIVERING",
  Delivered: "DELIVERED",
  Ordered: "ORDERED",
  WaitingApproved: "WAITING_APPROVED",
  Approved: "APPROVED",
  Cancelled: "CANCELLED",
} as const;

export type PosOrderListResponsePosOrderStatusEnum =
  (typeof PosOrderListResponsePosOrderStatusEnum)[keyof typeof PosOrderListResponsePosOrderStatusEnum];

/**
 *
 * @export
 * @interface PosOrderResponse
 */
export interface PosOrderResponse {
  /**
   *
   * @type {string}
   * @memberof PosOrderResponse
   */
  orderId?: string;
  /**
   *
   * @type {number}
   * @memberof PosOrderResponse
   */
  total?: number;
  /**
   *
   * @type {Array<PosOrderItemDto>}
   * @memberof PosOrderResponse
   */
  items?: Array<PosOrderItemDto>;
  /**
   *
   * @type {string}
   * @memberof PosOrderResponse
   */
  posOrderStatusPayment?: PosOrderResponsePosOrderStatusPaymentEnum;
  /**
   *
   * @type {string}
   * @memberof PosOrderResponse
   */
  posOrderStatus?: PosOrderResponsePosOrderStatusEnum;
  /**
   *
   * @type {string}
   * @memberof PosOrderResponse
   */
  receiverName?: string;
  /**
   *
   * @type {string}
   * @memberof PosOrderResponse
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof PosOrderResponse
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof PosOrderResponse
   */
  note?: string;
  /**
   *
   * @type {string}
   * @memberof PosOrderResponse
   */
  merchantId?: string;
  /**
   *
   * @type {string}
   * @memberof PosOrderResponse
   */
  transactionCode?: string;
  /**
   *
   * @type {string}
   * @memberof PosOrderResponse
   */
  completeTime?: string;
  /**
   *
   * @type {string}
   * @memberof PosOrderResponse
   */
  businessType?: PosOrderResponseBusinessTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PosOrderResponse
   */
  representative?: string;
  /**
   *
   * @type {string}
   * @memberof PosOrderResponse
   */
  nameUI?: string;
  /**
   *
   * @type {string}
   * @memberof PosOrderResponse
   */
  phoneRepresentative?: string;
  /**
   *
   * @type {string}
   * @memberof PosOrderResponse
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof PosOrderResponse
   */
  addressRepresentative?: string;
  /**
   *
   * @type {string}
   * @memberof PosOrderResponse
   */
  createdAt?: string;
}

export const PosOrderResponsePosOrderStatusPaymentEnum = {
  WaitingPayment: "WAITING_PAYMENT",
  Cancelled: "CANCELLED",
  TimeOut: "TIME_OUT",
  Paid: "PAID",
} as const;

export type PosOrderResponsePosOrderStatusPaymentEnum =
  (typeof PosOrderResponsePosOrderStatusPaymentEnum)[keyof typeof PosOrderResponsePosOrderStatusPaymentEnum];
export const PosOrderResponsePosOrderStatusEnum = {
  Delivering: "DELIVERING",
  Delivered: "DELIVERED",
  Ordered: "ORDERED",
  WaitingApproved: "WAITING_APPROVED",
  Approved: "APPROVED",
  Cancelled: "CANCELLED",
} as const;

export type PosOrderResponsePosOrderStatusEnum =
  (typeof PosOrderResponsePosOrderStatusEnum)[keyof typeof PosOrderResponsePosOrderStatusEnum];
export const PosOrderResponseBusinessTypeEnum = {
  Personal: "PERSONAL",
  Organization: "ORGANIZATION",
} as const;

export type PosOrderResponseBusinessTypeEnum =
  (typeof PosOrderResponseBusinessTypeEnum)[keyof typeof PosOrderResponseBusinessTypeEnum];

/**
 *
 * @export
 * @interface ProductResponse
 */
export interface ProductResponse {
  /**
   *
   * @type {number}
   * @memberof ProductResponse
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ProductResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ProductResponse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ProductResponse
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof ProductResponse
   */
  imageUrl?: string;
  /**
   *
   * @type {string}
   * @memberof ProductResponse
   */
  priceType?: ProductResponsePriceTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ProductResponse
   */
  storageType?: ProductResponseStorageTypeEnum;
  /**
   *
   * @type {number}
   * @memberof ProductResponse
   */
  storageQuantity?: number;
  /**
   *
   * @type {number}
   * @memberof ProductResponse
   */
  sold?: number;
  /**
   *
   * @type {number}
   * @memberof ProductResponse
   */
  price?: number;
  /**
   *
   * @type {number}
   * @memberof ProductResponse
   */
  revenue?: number;
  /**
   *
   * @type {string}
   * @memberof ProductResponse
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof ProductResponse
   */
  createdBy?: string;
  /**
   *
   * @type {string}
   * @memberof ProductResponse
   */
  modifiedAt?: string;
  /**
   *
   * @type {string}
   * @memberof ProductResponse
   */
  modifiedBy?: string;
  /**
   *
   * @type {number}
   * @memberof ProductResponse
   */
  categoryId?: number;
  /**
   *
   * @type {string}
   * @memberof ProductResponse
   */
  categoryName?: string;
  /**
   *
   * @type {string}
   * @memberof ProductResponse
   */
  status?: ProductResponseStatusEnum;
  /**
   *
   * @type {string}
   * @memberof ProductResponse
   */
  stockStatus?: ProductResponseStockStatusEnum;
  /**
   *
   * @type {boolean}
   * @memberof ProductResponse
   */
  hasPayPage?: boolean;
}

export const ProductResponsePriceTypeEnum = {
  FixedPrice: "FIXED_PRICE",
  QuantityPrice: "QUANTITY_PRICE",
  CustomerPrice: "CUSTOMER_PRICE",
} as const;

export type ProductResponsePriceTypeEnum =
  (typeof ProductResponsePriceTypeEnum)[keyof typeof ProductResponsePriceTypeEnum];
export const ProductResponseStorageTypeEnum = {
  Limited: "LIMITED",
  Unlimited: "UNLIMITED",
} as const;

export type ProductResponseStorageTypeEnum =
  (typeof ProductResponseStorageTypeEnum)[keyof typeof ProductResponseStorageTypeEnum];
export const ProductResponseStatusEnum = {
  Active: "ACTIVE",
  Inactive: "INACTIVE",
} as const;

export type ProductResponseStatusEnum =
  (typeof ProductResponseStatusEnum)[keyof typeof ProductResponseStatusEnum];
export const ProductResponseStockStatusEnum = {
  Stocking: "STOCKING",
  OutOfStock: "OUT_OF_STOCK",
  Pending: "PENDING",
} as const;

export type ProductResponseStockStatusEnum =
  (typeof ProductResponseStockStatusEnum)[keyof typeof ProductResponseStockStatusEnum];

/**
 *
 * @export
 * @interface ProfileDto
 */
export interface ProfileDto {
  /**
   *
   * @type {string}
   * @memberof ProfileDto
   */
  businessType?: ProfileDtoBusinessTypeEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof ProfileDto
   */
  businessSectors?: Array<ProfileDtoBusinessSectorsEnum>;
  /**
   *
   * @type {string}
   * @memberof ProfileDto
   */
  businessItems?: string;
  /**
   *
   * @type {number}
   * @memberof ProfileDto
   */
  transactionMaxAmount?: number;
  /**
   *
   * @type {string}
   * @memberof ProfileDto
   */
  revenueType?: ProfileDtoRevenueTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ProfileDto
   */
  contactEmail?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileDto
   */
  websiteUrl?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileDto
   */
  storeAddress?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileDto
   */
  companyName?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileDto
   */
  companyAddress?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileDto
   */
  companyPhone?: string;
  /**
   *
   * @type {IdentifyInfoDto}
   * @memberof ProfileDto
   */
  representativeInfo?: IdentifyInfoDto;
  /**
   *
   * @type {IdentifyInfoDto}
   * @memberof ProfileDto
   */
  ownerInfo?: IdentifyInfoDto;
  /**
   *
   * @type {EkycDto}
   * @memberof ProfileDto
   */
  repEkyc?: EkycDto;
  /**
   *
   * @type {EkycDto}
   * @memberof ProfileDto
   */
  ownerEkyc?: EkycDto;
  /**
   *
   * @type {Array<Document>}
   * @memberof ProfileDto
   */
  documents?: Array<Document>;
  /**
   *
   * @type {Array<BusinessOwner>}
   * @memberof ProfileDto
   */
  owners?: Array<BusinessOwner>;
}

export const ProfileDtoBusinessTypeEnum = {
  Personal: "PERSONAL",
  Organization: "ORGANIZATION",
} as const;

export type ProfileDtoBusinessTypeEnum =
  (typeof ProfileDtoBusinessTypeEnum)[keyof typeof ProfileDtoBusinessTypeEnum];
export const ProfileDtoBusinessSectorsEnum = {
  VehicleSale: "VEHICLE_SALE",
  Logistics: "LOGISTICS",
  ResidentAndFoodService: "RESIDENT_AND_FOOD_SERVICE",
  InformationAndMedia: "INFORMATION_AND_MEDIA",
  FinanceAndInsurance: "FINANCE_AND_INSURANCE",
  RealEstate: "REAL_ESTATE",
  AdministrativeAndSupportService: "ADMINISTRATIVE_AND_SUPPORT_SERVICE",
  Education: "EDUCATION",
  PublicHealth: "PUBLIC_HEALTH",
  HealthCareAndBeauty: "HEALTH_CARE_AND_BEAUTY",
  ArtAndEntertainment: "ART_AND_ENTERTAINMENT",
  Other: "OTHER",
} as const;

export type ProfileDtoBusinessSectorsEnum =
  (typeof ProfileDtoBusinessSectorsEnum)[keyof typeof ProfileDtoBusinessSectorsEnum];
export const ProfileDtoRevenueTypeEnum = {
  _1: "REVENUE_TYPE_1",
  _2: "REVENUE_TYPE_2",
  _3: "REVENUE_TYPE_3",
  _4: "REVENUE_TYPE_4",
} as const;

export type ProfileDtoRevenueTypeEnum =
  (typeof ProfileDtoRevenueTypeEnum)[keyof typeof ProfileDtoRevenueTypeEnum];

/**
 *
 * @export
 * @interface ProfileReconciliation
 */
export interface ProfileReconciliation {
  /**
   *
   * @type {string}
   * @memberof ProfileReconciliation
   */
  fullname?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileReconciliation
   */
  birthday?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileReconciliation
   */
  gender?: ProfileReconciliationGenderEnum;
  /**
   *
   * @type {string}
   * @memberof ProfileReconciliation
   */
  idCardNo?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileReconciliation
   */
  idCardIssueDate?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileReconciliation
   */
  resCity?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileReconciliation
   */
  resAddr?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileReconciliation
   */
  hometown?: string;
}

export const ProfileReconciliationGenderEnum = {
  Male: "MALE",
  Female: "FEMALE",
} as const;

export type ProfileReconciliationGenderEnum =
  (typeof ProfileReconciliationGenderEnum)[keyof typeof ProfileReconciliationGenderEnum];

/**
 *
 * @export
 * @interface ProfileResponse
 */
export interface ProfileResponse {
  /**
   *
   * @type {string}
   * @memberof ProfileResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileResponse
   */
  businessType?: ProfileResponseBusinessTypeEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof ProfileResponse
   */
  businessSectors?: Array<ProfileResponseBusinessSectorsEnum>;
  /**
   *
   * @type {string}
   * @memberof ProfileResponse
   */
  businessItems?: string;
  /**
   *
   * @type {number}
   * @memberof ProfileResponse
   */
  transactionMaxAmount?: number;
  /**
   *
   * @type {string}
   * @memberof ProfileResponse
   */
  revenueType?: ProfileResponseRevenueTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ProfileResponse
   */
  websiteUrl?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileResponse
   */
  storeAddress?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileResponse
   */
  companyName?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileResponse
   */
  companyAddress?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileResponse
   */
  companyPhone?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileResponse
   */
  contactEmail?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileResponse
   */
  cifNo?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileResponse
   */
  taxIdNo?: string;
  /**
   *
   * @type {number}
   * @memberof ProfileResponse
   */
  allowedLimit?: number;
  /**
   *
   * @type {IdentifyInfo}
   * @memberof ProfileResponse
   */
  repIdInfo?: IdentifyInfo;
  /**
   *
   * @type {IdentifyInfo}
   * @memberof ProfileResponse
   */
  ownerIdInfo?: IdentifyInfo;
  /**
   *
   * @type {Array<Document>}
   * @memberof ProfileResponse
   */
  documents?: Array<Document>;
  /**
   *
   * @type {Array<BusinessOwner>}
   * @memberof ProfileResponse
   */
  owners?: Array<BusinessOwner>;
}

export const ProfileResponseBusinessTypeEnum = {
  Personal: "PERSONAL",
  Organization: "ORGANIZATION",
} as const;

export type ProfileResponseBusinessTypeEnum =
  (typeof ProfileResponseBusinessTypeEnum)[keyof typeof ProfileResponseBusinessTypeEnum];
export const ProfileResponseBusinessSectorsEnum = {
  VehicleSale: "VEHICLE_SALE",
  Logistics: "LOGISTICS",
  ResidentAndFoodService: "RESIDENT_AND_FOOD_SERVICE",
  InformationAndMedia: "INFORMATION_AND_MEDIA",
  FinanceAndInsurance: "FINANCE_AND_INSURANCE",
  RealEstate: "REAL_ESTATE",
  AdministrativeAndSupportService: "ADMINISTRATIVE_AND_SUPPORT_SERVICE",
  Education: "EDUCATION",
  PublicHealth: "PUBLIC_HEALTH",
  HealthCareAndBeauty: "HEALTH_CARE_AND_BEAUTY",
  ArtAndEntertainment: "ART_AND_ENTERTAINMENT",
  Other: "OTHER",
} as const;

export type ProfileResponseBusinessSectorsEnum =
  (typeof ProfileResponseBusinessSectorsEnum)[keyof typeof ProfileResponseBusinessSectorsEnum];
export const ProfileResponseRevenueTypeEnum = {
  _1: "REVENUE_TYPE_1",
  _2: "REVENUE_TYPE_2",
  _3: "REVENUE_TYPE_3",
  _4: "REVENUE_TYPE_4",
} as const;

export type ProfileResponseRevenueTypeEnum =
  (typeof ProfileResponseRevenueTypeEnum)[keyof typeof ProfileResponseRevenueTypeEnum];

/**
 *
 * @export
 * @interface QrLoginGetInfoRequest
 */
export interface QrLoginGetInfoRequest {
  /**
   *
   * @type {string}
   * @memberof QrLoginGetInfoRequest
   */
  sessionId: string;
  /**
   *
   * @type {string}
   * @memberof QrLoginGetInfoRequest
   */
  sessionKey: string;
}
/**
 *
 * @export
 * @interface QrLoginGetInfoResponse
 */
export interface QrLoginGetInfoResponse {
  /**
   *
   * @type {string}
   * @memberof QrLoginGetInfoResponse
   */
  sessionId?: string;
  /**
   *
   * @type {string}
   * @memberof QrLoginGetInfoResponse
   */
  sessionKey?: string;
  /**
   *
   * @type {number}
   * @memberof QrLoginGetInfoResponse
   */
  timeout?: number;
  /**
   *
   * @type {string}
   * @memberof QrLoginGetInfoResponse
   */
  dateTime?: string;
  /**
   *
   * @type {string}
   * @memberof QrLoginGetInfoResponse
   */
  expireTime?: string;
  /**
   *
   * @type {string}
   * @memberof QrLoginGetInfoResponse
   */
  location?: string;
  /**
   *
   * @type {string}
   * @memberof QrLoginGetInfoResponse
   */
  deviceName?: string;
  /**
   *
   * @type {string}
   * @memberof QrLoginGetInfoResponse
   */
  operatingSystem?: string;
  /**
   *
   * @type {string}
   * @memberof QrLoginGetInfoResponse
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof QrLoginGetInfoResponse
   */
  fullName?: string;
  /**
   *
   * @type {string}
   * @memberof QrLoginGetInfoResponse
   */
  userAvatar?: string;
  /**
   *
   * @type {string}
   * @memberof QrLoginGetInfoResponse
   */
  merchantName?: string;
  /**
   *
   * @type {string}
   * @memberof QrLoginGetInfoResponse
   */
  merchantCode?: string;
  /**
   *
   * @type {string}
   * @memberof QrLoginGetInfoResponse
   */
  merchantAvatar?: string;
  /**
   *
   * @type {string}
   * @memberof QrLoginGetInfoResponse
   */
  status?: QrLoginGetInfoResponseStatusEnum;
  /**
   *
   * @type {boolean}
   * @memberof QrLoginGetInfoResponse
   */
  isNewAccount?: boolean;
}

export const QrLoginGetInfoResponseStatusEnum = {
  Pending: "PENDING",
  InputData: "INPUT_DATA",
  Failed: "FAILED",
  Approve: "APPROVE",
  Used: "USED",
} as const;

export type QrLoginGetInfoResponseStatusEnum =
  (typeof QrLoginGetInfoResponseStatusEnum)[keyof typeof QrLoginGetInfoResponseStatusEnum];

/**
 *
 * @export
 * @interface QrLoginGetTokenRequest
 */
export interface QrLoginGetTokenRequest {
  /**
   *
   * @type {string}
   * @memberof QrLoginGetTokenRequest
   */
  sessionId: string;
  /**
   *
   * @type {string}
   * @memberof QrLoginGetTokenRequest
   */
  sessionKey: string;
}
/**
 *
 * @export
 * @interface QrLoginGetUserInfoRequest
 */
export interface QrLoginGetUserInfoRequest {
  /**
   *
   * @type {string}
   * @memberof QrLoginGetUserInfoRequest
   */
  sessionId: string;
  /**
   *
   * @type {string}
   * @memberof QrLoginGetUserInfoRequest
   */
  sessionKey: string;
}
/**
 *
 * @export
 * @interface QrLoginRequest
 */
export interface QrLoginRequest {
  /**
   *
   * @type {string}
   * @memberof QrLoginRequest
   */
  deviceName: string;
  /**
   *
   * @type {string}
   * @memberof QrLoginRequest
   */
  operatingSystem: string;
  /**
   *
   * @type {string}
   * @memberof QrLoginRequest
   */
  location: string;
}
/**
 *
 * @export
 * @interface QrLoginResponse
 */
export interface QrLoginResponse {
  /**
   *
   * @type {string}
   * @memberof QrLoginResponse
   */
  sessionId?: string;
  /**
   *
   * @type {string}
   * @memberof QrLoginResponse
   */
  sessionKey?: string;
  /**
   *
   * @type {number}
   * @memberof QrLoginResponse
   */
  timeout?: number;
  /**
   *
   * @type {string}
   * @memberof QrLoginResponse
   */
  dateTime?: string;
  /**
   *
   * @type {string}
   * @memberof QrLoginResponse
   */
  expireTime?: string;
  /**
   *
   * @type {string}
   * @memberof QrLoginResponse
   */
  location?: string;
  /**
   *
   * @type {string}
   * @memberof QrLoginResponse
   */
  deviceName?: string;
  /**
   *
   * @type {string}
   * @memberof QrLoginResponse
   */
  operatingSystem?: string;
  /**
   *
   * @type {string}
   * @memberof QrLoginResponse
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof QrLoginResponse
   */
  fullName?: string;
  /**
   *
   * @type {string}
   * @memberof QrLoginResponse
   */
  userAvatar?: string;
  /**
   *
   * @type {string}
   * @memberof QrLoginResponse
   */
  merchantName?: string;
  /**
   *
   * @type {string}
   * @memberof QrLoginResponse
   */
  merchantCode?: string;
  /**
   *
   * @type {string}
   * @memberof QrLoginResponse
   */
  merchantAvatar?: string;
  /**
   *
   * @type {string}
   * @memberof QrLoginResponse
   */
  status?: QrLoginResponseStatusEnum;
  /**
   *
   * @type {string}
   * @memberof QrLoginResponse
   */
  qrCode?: string;
}

export const QrLoginResponseStatusEnum = {
  Pending: "PENDING",
  InputData: "INPUT_DATA",
  Failed: "FAILED",
  Approve: "APPROVE",
  Used: "USED",
} as const;

export type QrLoginResponseStatusEnum =
  (typeof QrLoginResponseStatusEnum)[keyof typeof QrLoginResponseStatusEnum];

/**
 *
 * @export
 * @interface QrRegisterGetTokenRequest
 */
export interface QrRegisterGetTokenRequest {
  /**
   *
   * @type {string}
   * @memberof QrRegisterGetTokenRequest
   */
  sessionId: string;
  /**
   *
   * @type {string}
   * @memberof QrRegisterGetTokenRequest
   */
  sessionKey: string;
}
/**
 *
 * @export
 * @interface QrRegisterSetPasswordRequest
 */
export interface QrRegisterSetPasswordRequest {
  /**
   *
   * @type {string}
   * @memberof QrRegisterSetPasswordRequest
   */
  sessionId: string;
  /**
   *
   * @type {string}
   * @memberof QrRegisterSetPasswordRequest
   */
  sessionKey: string;
  /**
   *
   * @type {string}
   * @memberof QrRegisterSetPasswordRequest
   */
  password: string;
}
/**
 *
 * @export
 * @interface QrUserInfoResponse
 */
export interface QrUserInfoResponse {
  /**
   *
   * @type {string}
   * @memberof QrUserInfoResponse
   */
  sessionId?: string;
  /**
   *
   * @type {string}
   * @memberof QrUserInfoResponse
   */
  sessionKey?: string;
  /**
   *
   * @type {string}
   * @memberof QrUserInfoResponse
   */
  userId?: string;
  /**
   *
   * @type {string}
   * @memberof QrUserInfoResponse
   */
  phoneNumber?: string;
  /**
   *
   * @type {string}
   * @memberof QrUserInfoResponse
   */
  idCardType?: string;
  /**
   *
   * @type {string}
   * @memberof QrUserInfoResponse
   */
  idCardNo?: string;
  /**
   *
   * @type {string}
   * @memberof QrUserInfoResponse
   */
  fullName?: string;
  /**
   *
   * @type {string}
   * @memberof QrUserInfoResponse
   */
  birthday?: string;
  /**
   *
   * @type {string}
   * @memberof QrUserInfoResponse
   */
  permanentAddress?: string;
  /**
   *
   * @type {string}
   * @memberof QrUserInfoResponse
   */
  hometown?: string;
  /**
   *
   * @type {string}
   * @memberof QrUserInfoResponse
   */
  gender?: string;
  /**
   *
   * @type {string}
   * @memberof QrUserInfoResponse
   */
  idCardIssueBy?: string;
  /**
   *
   * @type {string}
   * @memberof QrUserInfoResponse
   */
  idCardIssueDate?: string;
  /**
   *
   * @type {string}
   * @memberof QrUserInfoResponse
   */
  branchCode?: string;
  /**
   *
   * @type {string}
   * @memberof QrUserInfoResponse
   */
  faceIdUrl?: string;
  /**
   *
   * @type {string}
   * @memberof QrUserInfoResponse
   */
  idCardFrontSideUrl?: string;
  /**
   *
   * @type {string}
   * @memberof QrUserInfoResponse
   */
  idCardBackSideUrl?: string;
  /**
   *
   * @type {string}
   * @memberof QrUserInfoResponse
   */
  avatarUrl?: string;
  /**
   *
   * @type {string}
   * @memberof QrUserInfoResponse
   */
  cifNo?: string;
  /**
   *
   * @type {string}
   * @memberof QrUserInfoResponse
   */
  status?: QrUserInfoResponseStatusEnum;
}

export const QrUserInfoResponseStatusEnum = {
  Created: "CREATED",
  UpdatePassword: "UPDATE_PASSWORD",
  SubmitInfo: "SUBMIT_INFO",
  VerifiedOtp: "VERIFIED_OTP",
} as const;

export type QrUserInfoResponseStatusEnum =
  (typeof QrUserInfoResponseStatusEnum)[keyof typeof QrUserInfoResponseStatusEnum];

/**
 *
 * @export
 * @interface QueryTransactionRequest
 */
export interface QueryTransactionRequest {
  /**
   *
   * @type {string}
   * @memberof QueryTransactionRequest
   */
  transactionId: string;
}
/**
 *
 * @export
 * @interface QueryTransactionResponse
 */
export interface QueryTransactionResponse {
  /**
   *
   * @type {string}
   * @memberof QueryTransactionResponse
   */
  status?: QueryTransactionResponseStatusEnum;
  /**
   *
   * @type {string}
   * @memberof QueryTransactionResponse
   */
  refTransactionId?: string;
  /**
   *
   * @type {number}
   * @memberof QueryTransactionResponse
   */
  amount?: number;
}

export const QueryTransactionResponseStatusEnum = {
  None: "NONE",
  Created: "CREATED",
  Success: "SUCCESS",
  Canceled: "CANCELED",
  Fail: "FAIL",
  Timeout: "TIMEOUT",
  Pending: "PENDING",
} as const;

export type QueryTransactionResponseStatusEnum =
  (typeof QueryTransactionResponseStatusEnum)[keyof typeof QueryTransactionResponseStatusEnum];

/**
 *
 * @export
 * @interface RedirectView
 */
export interface RedirectView {
  /**
   *
   * @type {ApplicationContext}
   * @memberof RedirectView
   */
  applicationContext?: ApplicationContext;
  /**
   *
   * @type {RedirectViewServletContext}
   * @memberof RedirectView
   */
  servletContext?: RedirectViewServletContext;
  /**
   *
   * @type {string}
   * @memberof RedirectView
   */
  contentType?: string;
  /**
   *
   * @type {string}
   * @memberof RedirectView
   */
  requestContextAttribute?: string;
  /**
   *
   * @type {{ [key: string]: object; }}
   * @memberof RedirectView
   */
  staticAttributes?: { [key: string]: object };
  /**
   *
   * @type {boolean}
   * @memberof RedirectView
   */
  exposePathVariables?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof RedirectView
   */
  exposeContextBeansAsAttributes?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof RedirectView
   */
  exposedContextBeanNames?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof RedirectView
   */
  beanName?: string;
  /**
   *
   * @type {string}
   * @memberof RedirectView
   */
  url?: string;
  /**
   *
   * @type {boolean}
   * @memberof RedirectView
   */
  contextRelative?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof RedirectView
   */
  http10Compatible?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof RedirectView
   */
  exposeModelAttributes?: boolean;
  /**
   *
   * @type {string}
   * @memberof RedirectView
   */
  encodingScheme?: string;
  /**
   *
   * @type {string}
   * @memberof RedirectView
   */
  statusCode?: RedirectViewStatusCodeEnum;
  /**
   *
   * @type {boolean}
   * @memberof RedirectView
   */
  expandUriTemplateVariables?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof RedirectView
   */
  propagateQueryParams?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof RedirectView
   */
  hosts?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof RedirectView
   */
  redirectView?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof RedirectView
   */
  propagateQueryProperties?: boolean;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof RedirectView
   */
  attributes?: { [key: string]: string };
  /**
   *
   * @type {string}
   * @memberof RedirectView
   */
  attributesCSV?: string;
  /**
   *
   * @type {{ [key: string]: object; }}
   * @memberof RedirectView
   */
  attributesMap?: { [key: string]: object };
}

export const RedirectViewStatusCodeEnum = {
  _100Continue: "100 CONTINUE",
  _101SwitchingProtocols: "101 SWITCHING_PROTOCOLS",
  _102Processing: "102 PROCESSING",
  _103Checkpoint: "103 CHECKPOINT",
  _200Ok: "200 OK",
  _201Created: "201 CREATED",
  _202Accepted: "202 ACCEPTED",
  _203NonAuthoritativeInformation: "203 NON_AUTHORITATIVE_INFORMATION",
  _204NoContent: "204 NO_CONTENT",
  _205ResetContent: "205 RESET_CONTENT",
  _206PartialContent: "206 PARTIAL_CONTENT",
  _207MultiStatus: "207 MULTI_STATUS",
  _208AlreadyReported: "208 ALREADY_REPORTED",
  _226ImUsed: "226 IM_USED",
  _300MultipleChoices: "300 MULTIPLE_CHOICES",
  _301MovedPermanently: "301 MOVED_PERMANENTLY",
  _302Found: "302 FOUND",
  _302MovedTemporarily: "302 MOVED_TEMPORARILY",
  _303SeeOther: "303 SEE_OTHER",
  _304NotModified: "304 NOT_MODIFIED",
  _305UseProxy: "305 USE_PROXY",
  _307TemporaryRedirect: "307 TEMPORARY_REDIRECT",
  _308PermanentRedirect: "308 PERMANENT_REDIRECT",
  _400BadRequest: "400 BAD_REQUEST",
  _401Unauthorized: "401 UNAUTHORIZED",
  _402PaymentRequired: "402 PAYMENT_REQUIRED",
  _403Forbidden: "403 FORBIDDEN",
  _404NotFound: "404 NOT_FOUND",
  _405MethodNotAllowed: "405 METHOD_NOT_ALLOWED",
  _406NotAcceptable: "406 NOT_ACCEPTABLE",
  _407ProxyAuthenticationRequired: "407 PROXY_AUTHENTICATION_REQUIRED",
  _408RequestTimeout: "408 REQUEST_TIMEOUT",
  _409Conflict: "409 CONFLICT",
  _410Gone: "410 GONE",
  _411LengthRequired: "411 LENGTH_REQUIRED",
  _412PreconditionFailed: "412 PRECONDITION_FAILED",
  _413PayloadTooLarge: "413 PAYLOAD_TOO_LARGE",
  _413RequestEntityTooLarge: "413 REQUEST_ENTITY_TOO_LARGE",
  _414UriTooLong: "414 URI_TOO_LONG",
  _414RequestUriTooLong: "414 REQUEST_URI_TOO_LONG",
  _415UnsupportedMediaType: "415 UNSUPPORTED_MEDIA_TYPE",
  _416RequestedRangeNotSatisfiable: "416 REQUESTED_RANGE_NOT_SATISFIABLE",
  _417ExpectationFailed: "417 EXPECTATION_FAILED",
  _418IAmATeapot: "418 I_AM_A_TEAPOT",
  _419InsufficientSpaceOnResource: "419 INSUFFICIENT_SPACE_ON_RESOURCE",
  _420MethodFailure: "420 METHOD_FAILURE",
  _421DestinationLocked: "421 DESTINATION_LOCKED",
  _422UnprocessableEntity: "422 UNPROCESSABLE_ENTITY",
  _423Locked: "423 LOCKED",
  _424FailedDependency: "424 FAILED_DEPENDENCY",
  _425TooEarly: "425 TOO_EARLY",
  _426UpgradeRequired: "426 UPGRADE_REQUIRED",
  _428PreconditionRequired: "428 PRECONDITION_REQUIRED",
  _429TooManyRequests: "429 TOO_MANY_REQUESTS",
  _431RequestHeaderFieldsTooLarge: "431 REQUEST_HEADER_FIELDS_TOO_LARGE",
  _451UnavailableForLegalReasons: "451 UNAVAILABLE_FOR_LEGAL_REASONS",
  _500InternalServerError: "500 INTERNAL_SERVER_ERROR",
  _501NotImplemented: "501 NOT_IMPLEMENTED",
  _502BadGateway: "502 BAD_GATEWAY",
  _503ServiceUnavailable: "503 SERVICE_UNAVAILABLE",
  _504GatewayTimeout: "504 GATEWAY_TIMEOUT",
  _505HttpVersionNotSupported: "505 HTTP_VERSION_NOT_SUPPORTED",
  _506VariantAlsoNegotiates: "506 VARIANT_ALSO_NEGOTIATES",
  _507InsufficientStorage: "507 INSUFFICIENT_STORAGE",
  _508LoopDetected: "508 LOOP_DETECTED",
  _509BandwidthLimitExceeded: "509 BANDWIDTH_LIMIT_EXCEEDED",
  _510NotExtended: "510 NOT_EXTENDED",
  _511NetworkAuthenticationRequired: "511 NETWORK_AUTHENTICATION_REQUIRED",
} as const;

export type RedirectViewStatusCodeEnum =
  (typeof RedirectViewStatusCodeEnum)[keyof typeof RedirectViewStatusCodeEnum];

/**
 *
 * @export
 * @interface RedirectViewServletContext
 */
export interface RedirectViewServletContext {
  /**
   *
   * @type {ApplicationContextClassLoaderParentUnnamedModuleClassLoader}
   * @memberof RedirectViewServletContext
   */
  classLoader?: ApplicationContextClassLoaderParentUnnamedModuleClassLoader;
  /**
   *
   * @type {number}
   * @memberof RedirectViewServletContext
   */
  majorVersion?: number;
  /**
   *
   * @type {number}
   * @memberof RedirectViewServletContext
   */
  minorVersion?: number;
  /**
   *
   * @type {string}
   * @memberof RedirectViewServletContext
   */
  serverInfo?: string;
  /**
   *
   * @type {string}
   * @memberof RedirectViewServletContext
   */
  servletContextName?: string;
  /**
   *
   * @type {number}
   * @memberof RedirectViewServletContext
   */
  effectiveMajorVersion?: number;
  /**
   *
   * @type {number}
   * @memberof RedirectViewServletContext
   */
  effectiveMinorVersion?: number;
  /**
   *
   * @type {object}
   * @memberof RedirectViewServletContext
   * @deprecated
   */
  servlets?: object;
  /**
   *
   * @type {object}
   * @memberof RedirectViewServletContext
   * @deprecated
   */
  servletNames?: object;
  /**
   *
   * @type {string}
   * @memberof RedirectViewServletContext
   */
  contextPath?: string;
  /**
   *
   * @type {object}
   * @memberof RedirectViewServletContext
   */
  attributeNames?: object;
  /**
   *
   * @type {object}
   * @memberof RedirectViewServletContext
   */
  initParameterNames?: object;
  /**
   *
   * @type {number}
   * @memberof RedirectViewServletContext
   */
  sessionTimeout?: number;
  /**
   *
   * @type {Set<string>}
   * @memberof RedirectViewServletContext
   */
  sessionTrackingModes?: Set<RedirectViewServletContextSessionTrackingModesEnum>;
  /**
   *
   * @type {string}
   * @memberof RedirectViewServletContext
   */
  responseCharacterEncoding?: string;
  /**
   *
   * @type {{ [key: string]: RedirectViewServletContextServletRegistrationsValue; }}
   * @memberof RedirectViewServletContext
   */
  servletRegistrations?: {
    [key: string]: RedirectViewServletContextServletRegistrationsValue;
  };
  /**
   *
   * @type {{ [key: string]: RedirectViewServletContextFilterRegistrationsValue; }}
   * @memberof RedirectViewServletContext
   */
  filterRegistrations?: {
    [key: string]: RedirectViewServletContextFilterRegistrationsValue;
  };
  /**
   *
   * @type {RedirectViewServletContextSessionCookieConfig}
   * @memberof RedirectViewServletContext
   */
  sessionCookieConfig?: RedirectViewServletContextSessionCookieConfig;
  /**
   *
   * @type {Set<string>}
   * @memberof RedirectViewServletContext
   */
  defaultSessionTrackingModes?: Set<RedirectViewServletContextDefaultSessionTrackingModesEnum>;
  /**
   *
   * @type {Set<string>}
   * @memberof RedirectViewServletContext
   */
  effectiveSessionTrackingModes?: Set<RedirectViewServletContextEffectiveSessionTrackingModesEnum>;
  /**
   *
   * @type {RedirectViewServletContextJspConfigDescriptor}
   * @memberof RedirectViewServletContext
   */
  jspConfigDescriptor?: RedirectViewServletContextJspConfigDescriptor;
  /**
   *
   * @type {string}
   * @memberof RedirectViewServletContext
   */
  virtualServerName?: string;
  /**
   *
   * @type {string}
   * @memberof RedirectViewServletContext
   */
  requestCharacterEncoding?: string;
}

export const RedirectViewServletContextSessionTrackingModesEnum = {
  Cookie: "COOKIE",
  Url: "URL",
  Ssl: "SSL",
} as const;

export type RedirectViewServletContextSessionTrackingModesEnum =
  (typeof RedirectViewServletContextSessionTrackingModesEnum)[keyof typeof RedirectViewServletContextSessionTrackingModesEnum];
export const RedirectViewServletContextDefaultSessionTrackingModesEnum = {
  Cookie: "COOKIE",
  Url: "URL",
  Ssl: "SSL",
} as const;

export type RedirectViewServletContextDefaultSessionTrackingModesEnum =
  (typeof RedirectViewServletContextDefaultSessionTrackingModesEnum)[keyof typeof RedirectViewServletContextDefaultSessionTrackingModesEnum];
export const RedirectViewServletContextEffectiveSessionTrackingModesEnum = {
  Cookie: "COOKIE",
  Url: "URL",
  Ssl: "SSL",
} as const;

export type RedirectViewServletContextEffectiveSessionTrackingModesEnum =
  (typeof RedirectViewServletContextEffectiveSessionTrackingModesEnum)[keyof typeof RedirectViewServletContextEffectiveSessionTrackingModesEnum];

/**
 *
 * @export
 * @interface RedirectViewServletContextFilterRegistrationsValue
 */
export interface RedirectViewServletContextFilterRegistrationsValue {
  /**
   *
   * @type {Array<string>}
   * @memberof RedirectViewServletContextFilterRegistrationsValue
   */
  servletNameMappings?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof RedirectViewServletContextFilterRegistrationsValue
   */
  urlPatternMappings?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof RedirectViewServletContextFilterRegistrationsValue
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof RedirectViewServletContextFilterRegistrationsValue
   */
  className?: string;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof RedirectViewServletContextFilterRegistrationsValue
   */
  initParameters?: { [key: string]: string };
}
/**
 *
 * @export
 * @interface RedirectViewServletContextJspConfigDescriptor
 */
export interface RedirectViewServletContextJspConfigDescriptor {
  /**
   *
   * @type {Array<RedirectViewServletContextJspConfigDescriptorTaglibsInner>}
   * @memberof RedirectViewServletContextJspConfigDescriptor
   */
  taglibs?: Array<RedirectViewServletContextJspConfigDescriptorTaglibsInner>;
  /**
   *
   * @type {Array<RedirectViewServletContextJspConfigDescriptorJspPropertyGroupsInner>}
   * @memberof RedirectViewServletContextJspConfigDescriptor
   */
  jspPropertyGroups?: Array<RedirectViewServletContextJspConfigDescriptorJspPropertyGroupsInner>;
}
/**
 *
 * @export
 * @interface RedirectViewServletContextJspConfigDescriptorJspPropertyGroupsInner
 */
export interface RedirectViewServletContextJspConfigDescriptorJspPropertyGroupsInner {
  /**
   *
   * @type {string}
   * @memberof RedirectViewServletContextJspConfigDescriptorJspPropertyGroupsInner
   */
  buffer?: string;
  /**
   *
   * @type {string}
   * @memberof RedirectViewServletContextJspConfigDescriptorJspPropertyGroupsInner
   */
  defaultContentType?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof RedirectViewServletContextJspConfigDescriptorJspPropertyGroupsInner
   */
  urlPatterns?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof RedirectViewServletContextJspConfigDescriptorJspPropertyGroupsInner
   */
  elIgnored?: string;
  /**
   *
   * @type {string}
   * @memberof RedirectViewServletContextJspConfigDescriptorJspPropertyGroupsInner
   */
  pageEncoding?: string;
  /**
   *
   * @type {string}
   * @memberof RedirectViewServletContextJspConfigDescriptorJspPropertyGroupsInner
   */
  isXml?: string;
  /**
   *
   * @type {string}
   * @memberof RedirectViewServletContextJspConfigDescriptorJspPropertyGroupsInner
   */
  scriptingInvalid?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof RedirectViewServletContextJspConfigDescriptorJspPropertyGroupsInner
   */
  includePreludes?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof RedirectViewServletContextJspConfigDescriptorJspPropertyGroupsInner
   */
  includeCodas?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof RedirectViewServletContextJspConfigDescriptorJspPropertyGroupsInner
   */
  deferredSyntaxAllowedAsLiteral?: string;
  /**
   *
   * @type {string}
   * @memberof RedirectViewServletContextJspConfigDescriptorJspPropertyGroupsInner
   */
  trimDirectiveWhitespaces?: string;
  /**
   *
   * @type {string}
   * @memberof RedirectViewServletContextJspConfigDescriptorJspPropertyGroupsInner
   */
  errorOnUndeclaredNamespace?: string;
}
/**
 *
 * @export
 * @interface RedirectViewServletContextJspConfigDescriptorTaglibsInner
 */
export interface RedirectViewServletContextJspConfigDescriptorTaglibsInner {
  /**
   *
   * @type {string}
   * @memberof RedirectViewServletContextJspConfigDescriptorTaglibsInner
   */
  taglibURI?: string;
  /**
   *
   * @type {string}
   * @memberof RedirectViewServletContextJspConfigDescriptorTaglibsInner
   */
  taglibLocation?: string;
}
/**
 *
 * @export
 * @interface RedirectViewServletContextServletRegistrationsValue
 */
export interface RedirectViewServletContextServletRegistrationsValue {
  /**
   *
   * @type {Array<string>}
   * @memberof RedirectViewServletContextServletRegistrationsValue
   */
  mappings?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof RedirectViewServletContextServletRegistrationsValue
   */
  runAsRole?: string;
  /**
   *
   * @type {string}
   * @memberof RedirectViewServletContextServletRegistrationsValue
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof RedirectViewServletContextServletRegistrationsValue
   */
  className?: string;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof RedirectViewServletContextServletRegistrationsValue
   */
  initParameters?: { [key: string]: string };
}
/**
 *
 * @export
 * @interface RedirectViewServletContextSessionCookieConfig
 */
export interface RedirectViewServletContextSessionCookieConfig {
  /**
   *
   * @type {string}
   * @memberof RedirectViewServletContextSessionCookieConfig
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof RedirectViewServletContextSessionCookieConfig
   */
  path?: string;
  /**
   *
   * @type {string}
   * @memberof RedirectViewServletContextSessionCookieConfig
   */
  comment?: string;
  /**
   *
   * @type {string}
   * @memberof RedirectViewServletContextSessionCookieConfig
   */
  domain?: string;
  /**
   *
   * @type {boolean}
   * @memberof RedirectViewServletContextSessionCookieConfig
   */
  secure?: boolean;
  /**
   *
   * @type {number}
   * @memberof RedirectViewServletContextSessionCookieConfig
   */
  maxAge?: number;
  /**
   *
   * @type {boolean}
   * @memberof RedirectViewServletContextSessionCookieConfig
   */
  httpOnly?: boolean;
}
/**
 *
 * @export
 * @interface RegisterAppRequest
 */
export interface RegisterAppRequest {
  /**
   *
   * @type {string}
   * @memberof RegisterAppRequest
   */
  merchantId: string;
  /**
   *
   * @type {string}
   * @memberof RegisterAppRequest
   */
  appUrl: string;
  /**
   *
   * @type {string}
   * @memberof RegisterAppRequest
   */
  successUrl: string;
  /**
   *
   * @type {string}
   * @memberof RegisterAppRequest
   */
  failUrl: string;
  /**
   *
   * @type {string}
   * @memberof RegisterAppRequest
   */
  logoUrl: string;
  /**
   *
   * @type {string}
   * @memberof RegisterAppRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof RegisterAppRequest
   */
  provider: string;
  /**
   *
   * @type {string}
   * @memberof RegisterAppRequest
   */
  description: string;
}
/**
 *
 * @export
 * @interface RegisterAppResponse
 */
export interface RegisterAppResponse {
  /**
   *
   * @type {string}
   * @memberof RegisterAppResponse
   */
  appId?: string;
  /**
   *
   * @type {string}
   * @memberof RegisterAppResponse
   */
  appUrl?: string;
  /**
   *
   * @type {string}
   * @memberof RegisterAppResponse
   */
  successUrl?: string;
  /**
   *
   * @type {string}
   * @memberof RegisterAppResponse
   */
  failUrl?: string;
  /**
   *
   * @type {string}
   * @memberof RegisterAppResponse
   */
  logoUrl?: string;
  /**
   *
   * @type {string}
   * @memberof RegisterAppResponse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof RegisterAppResponse
   */
  description?: string;
}
/**
 *
 * @export
 * @interface ReportDetail
 */
export interface ReportDetail {
  /**
   *
   * @type {string}
   * @memberof ReportDetail
   */
  cardNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ReportDetail
   */
  napasTxn?: string;
  /**
   *
   * @type {string}
   * @memberof ReportDetail
   */
  completeTime?: string;
  /**
   *
   * @type {string}
   * @memberof ReportDetail
   */
  currency?: string;
  /**
   *
   * @type {number}
   * @memberof ReportDetail
   */
  amount?: number;
}
/**
 *
 * @export
 * @interface ReportItem
 */
export interface ReportItem {
  /**
   *
   * @type {string}
   * @memberof ReportItem
   */
  txn?: string;
  /**
   *
   * @type {number}
   * @memberof ReportItem
   */
  variance?: number;
  /**
   *
   * @type {string}
   * @memberof ReportItem
   */
  status?: ReportItemStatusEnum;
  /**
   *
   * @type {ReportDetail}
   * @memberof ReportItem
   */
  klbpay?: ReportDetail;
  /**
   *
   * @type {ReportDetail}
   * @memberof ReportItem
   */
  napas?: ReportDetail;
}

export const ReportItemStatusEnum = {
  Pass: "PASS",
  Conflict: "CONFLICT",
} as const;

export type ReportItemStatusEnum =
  (typeof ReportItemStatusEnum)[keyof typeof ReportItemStatusEnum];

/**
 *
 * @export
 * @interface ResolvePaymentDataRequest
 */
export interface ResolvePaymentDataRequest {
  /**
   *
   * @type {string}
   * @memberof ResolvePaymentDataRequest
   */
  clientId: string;
  /**
   *
   * @type {number}
   * @memberof ResolvePaymentDataRequest
   */
  timestamp: number;
  /**
   *
   * @type {string}
   * @memberof ResolvePaymentDataRequest
   */
  signature: string;
  /**
   *
   * @type {string}
   * @memberof ResolvePaymentDataRequest
   */
  data: string;
}
/**
 *
 * @export
 * @interface ResolvePaymentDataResponse
 */
export interface ResolvePaymentDataResponse {
  /**
   *
   * @type {string}
   * @memberof ResolvePaymentDataResponse
   */
  appId?: string;
  /**
   *
   * @type {string}
   * @memberof ResolvePaymentDataResponse
   */
  sessionId?: string;
  /**
   *
   * @type {string}
   * @memberof ResolvePaymentDataResponse
   */
  successUrl?: string;
  /**
   *
   * @type {string}
   * @memberof ResolvePaymentDataResponse
   */
  failUrl?: string;
  /**
   *
   * @type {number}
   * @memberof ResolvePaymentDataResponse
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof ResolvePaymentDataResponse
   */
  orderId?: string;
  /**
   *
   * @type {string}
   * @memberof ResolvePaymentDataResponse
   */
  provider?: string;
  /**
   *
   * @type {string}
   * @memberof ResolvePaymentDataResponse
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof ResolvePaymentDataResponse
   */
  accountNo?: string;
  /**
   *
   * @type {string}
   * @memberof ResolvePaymentDataResponse
   */
  virtualAccount?: string;
  /**
   *
   * @type {string}
   * @memberof ResolvePaymentDataResponse
   */
  refTransactionId?: string;
  /**
   *
   * @type {string}
   * @memberof ResolvePaymentDataResponse
   */
  time?: string;
}
/**
 *
 * @export
 * @interface ResponseBaseAccessTokenResponseCustom
 */
export interface ResponseBaseAccessTokenResponseCustom {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseAccessTokenResponseCustom
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseAccessTokenResponseCustom
   */
  message?: string;
  /**
   *
   * @type {AccessTokenResponseCustom}
   * @memberof ResponseBaseAccessTokenResponseCustom
   */
  data?: AccessTokenResponseCustom;
}
/**
 *
 * @export
 * @interface ResponseBaseAddFeeResponse
 */
export interface ResponseBaseAddFeeResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseAddFeeResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseAddFeeResponse
   */
  message?: string;
  /**
   *
   * @type {AddFeeResponse}
   * @memberof ResponseBaseAddFeeResponse
   */
  data?: AddFeeResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseAdminDetailResponse
 */
export interface ResponseBaseAdminDetailResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseAdminDetailResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseAdminDetailResponse
   */
  message?: string;
  /**
   *
   * @type {AdminDetailResponse}
   * @memberof ResponseBaseAdminDetailResponse
   */
  data?: AdminDetailResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseAdminResponse
 */
export interface ResponseBaseAdminResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseAdminResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseAdminResponse
   */
  message?: string;
  /**
   *
   * @type {AdminResponse}
   * @memberof ResponseBaseAdminResponse
   */
  data?: AdminResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseAffiliateResponse
 */
export interface ResponseBaseAffiliateResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseAffiliateResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseAffiliateResponse
   */
  message?: string;
  /**
   *
   * @type {AffiliateResponse}
   * @memberof ResponseBaseAffiliateResponse
   */
  data?: AffiliateResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseAllSystemParamResponse
 */
export interface ResponseBaseAllSystemParamResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseAllSystemParamResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseAllSystemParamResponse
   */
  message?: string;
  /**
   *
   * @type {AllSystemParamResponse}
   * @memberof ResponseBaseAllSystemParamResponse
   */
  data?: AllSystemParamResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseApproveNapasSessionResponse
 */
export interface ResponseBaseApproveNapasSessionResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseApproveNapasSessionResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseApproveNapasSessionResponse
   */
  message?: string;
  /**
   *
   * @type {ApproveNapasSessionResponse}
   * @memberof ResponseBaseApproveNapasSessionResponse
   */
  data?: ApproveNapasSessionResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseApproveReconciliationSessionResponse
 */
export interface ResponseBaseApproveReconciliationSessionResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseApproveReconciliationSessionResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseApproveReconciliationSessionResponse
   */
  message?: string;
  /**
   *
   * @type {ApproveReconciliationSessionResponse}
   * @memberof ResponseBaseApproveReconciliationSessionResponse
   */
  data?: ApproveReconciliationSessionResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseAuditVersionResponse
 */
export interface ResponseBaseAuditVersionResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseAuditVersionResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseAuditVersionResponse
   */
  message?: string;
  /**
   *
   * @type {AuditVersionResponse}
   * @memberof ResponseBaseAuditVersionResponse
   */
  data?: AuditVersionResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseBankAccountResponse
 */
export interface ResponseBaseBankAccountResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseBankAccountResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseBankAccountResponse
   */
  message?: string;
  /**
   *
   * @type {BankAccountResponse}
   * @memberof ResponseBaseBankAccountResponse
   */
  data?: BankAccountResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseBankResponse
 */
export interface ResponseBaseBankResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseBankResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseBankResponse
   */
  message?: string;
  /**
   *
   * @type {BankResponse}
   * @memberof ResponseBaseBankResponse
   */
  data?: BankResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseBatchPayOutDetailResponse
 */
export interface ResponseBaseBatchPayOutDetailResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseBatchPayOutDetailResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseBatchPayOutDetailResponse
   */
  message?: string;
  /**
   *
   * @type {BatchPayOutDetailResponse}
   * @memberof ResponseBaseBatchPayOutDetailResponse
   */
  data?: BatchPayOutDetailResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseBatchPayOutResponse
 */
export interface ResponseBaseBatchPayOutResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseBatchPayOutResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseBatchPayOutResponse
   */
  message?: string;
  /**
   *
   * @type {BatchPayOutResponse}
   * @memberof ResponseBaseBatchPayOutResponse
   */
  data?: BatchPayOutResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseCancelTransactionResponse
 */
export interface ResponseBaseCancelTransactionResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseCancelTransactionResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseCancelTransactionResponse
   */
  message?: string;
  /**
   *
   * @type {CancelTransactionResponse}
   * @memberof ResponseBaseCancelTransactionResponse
   */
  data?: CancelTransactionResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseCategoryResponse
 */
export interface ResponseBaseCategoryResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseCategoryResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseCategoryResponse
   */
  message?: string;
  /**
   *
   * @type {CategoryResponse}
   * @memberof ResponseBaseCategoryResponse
   */
  data?: CategoryResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseCheckAccountNoResponse
 */
export interface ResponseBaseCheckAccountNoResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseCheckAccountNoResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseCheckAccountNoResponse
   */
  message?: string;
  /**
   *
   * @type {CheckAccountNoResponse}
   * @memberof ResponseBaseCheckAccountNoResponse
   */
  data?: CheckAccountNoResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseCheckBankAccountResponse
 */
export interface ResponseBaseCheckBankAccountResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseCheckBankAccountResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseCheckBankAccountResponse
   */
  message?: string;
  /**
   *
   * @type {CheckBankAccountResponse}
   * @memberof ResponseBaseCheckBankAccountResponse
   */
  data?: CheckBankAccountResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseCheckBankExistResponse
 */
export interface ResponseBaseCheckBankExistResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseCheckBankExistResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseCheckBankExistResponse
   */
  message?: string;
  /**
   *
   * @type {CheckBankExistResponse}
   * @memberof ResponseBaseCheckBankExistResponse
   */
  data?: CheckBankExistResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseCheckPasswordResponse
 */
export interface ResponseBaseCheckPasswordResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseCheckPasswordResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseCheckPasswordResponse
   */
  message?: string;
  /**
   *
   * @type {CheckPasswordResponse}
   * @memberof ResponseBaseCheckPasswordResponse
   */
  data?: CheckPasswordResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseCheckProductResponse
 */
export interface ResponseBaseCheckProductResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseCheckProductResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseCheckProductResponse
   */
  message?: string;
  /**
   *
   * @type {CheckProductResponse}
   * @memberof ResponseBaseCheckProductResponse
   */
  data?: CheckProductResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseCheckStaffIdResponse
 */
export interface ResponseBaseCheckStaffIdResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseCheckStaffIdResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseCheckStaffIdResponse
   */
  message?: string;
  /**
   *
   * @type {CheckStaffIdResponse}
   * @memberof ResponseBaseCheckStaffIdResponse
   */
  data?: CheckStaffIdResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseCmsEkycReconciliationFromBankResponse
 */
export interface ResponseBaseCmsEkycReconciliationFromBankResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseCmsEkycReconciliationFromBankResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseCmsEkycReconciliationFromBankResponse
   */
  message?: string;
  /**
   *
   * @type {CmsEkycReconciliationFromBankResponse}
   * @memberof ResponseBaseCmsEkycReconciliationFromBankResponse
   */
  data?: CmsEkycReconciliationFromBankResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseCmsOrderDetailResponse
 */
export interface ResponseBaseCmsOrderDetailResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseCmsOrderDetailResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseCmsOrderDetailResponse
   */
  message?: string;
  /**
   *
   * @type {CmsOrderDetailResponse}
   * @memberof ResponseBaseCmsOrderDetailResponse
   */
  data?: CmsOrderDetailResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseCmsVietQrProResponse
 */
export interface ResponseBaseCmsVietQrProResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseCmsVietQrProResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseCmsVietQrProResponse
   */
  message?: string;
  /**
   *
   * @type {CmsVietQrProResponse}
   * @memberof ResponseBaseCmsVietQrProResponse
   */
  data?: CmsVietQrProResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseCmsWebhookUrlResponse
 */
export interface ResponseBaseCmsWebhookUrlResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseCmsWebhookUrlResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseCmsWebhookUrlResponse
   */
  message?: string;
  /**
   *
   * @type {CmsWebhookUrlResponse}
   * @memberof ResponseBaseCmsWebhookUrlResponse
   */
  data?: CmsWebhookUrlResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseCreateTransactionResponse
 */
export interface ResponseBaseCreateTransactionResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseCreateTransactionResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseCreateTransactionResponse
   */
  message?: string;
  /**
   *
   * @type {CreateTransactionResponse}
   * @memberof ResponseBaseCreateTransactionResponse
   */
  data?: CreateTransactionResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseCustomerGroupResponse
 */
export interface ResponseBaseCustomerGroupResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseCustomerGroupResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseCustomerGroupResponse
   */
  message?: string;
  /**
   *
   * @type {CustomerGroupResponse}
   * @memberof ResponseBaseCustomerGroupResponse
   */
  data?: CustomerGroupResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseCustomerListResponse
 */
export interface ResponseBaseCustomerListResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseCustomerListResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseCustomerListResponse
   */
  message?: string;
  /**
   *
   * @type {CustomerListResponse}
   * @memberof ResponseBaseCustomerListResponse
   */
  data?: CustomerListResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseCustomerResponse
 */
export interface ResponseBaseCustomerResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseCustomerResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseCustomerResponse
   */
  message?: string;
  /**
   *
   * @type {CustomerResponse}
   * @memberof ResponseBaseCustomerResponse
   */
  data?: CustomerResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseDeleteSftpFileResponse
 */
export interface ResponseBaseDeleteSftpFileResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseDeleteSftpFileResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseDeleteSftpFileResponse
   */
  message?: string;
  /**
   *
   * @type {DeleteSftpFileResponse}
   * @memberof ResponseBaseDeleteSftpFileResponse
   */
  data?: DeleteSftpFileResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseEWalletResponse
 */
export interface ResponseBaseEWalletResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseEWalletResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseEWalletResponse
   */
  message?: string;
  /**
   *
   * @type {EWalletResponse}
   * @memberof ResponseBaseEWalletResponse
   */
  data?: EWalletResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseEkycResponse
 */
export interface ResponseBaseEkycResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseEkycResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseEkycResponse
   */
  message?: string;
  /**
   *
   * @type {EkycResponse}
   * @memberof ResponseBaseEkycResponse
   */
  data?: EkycResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseExternalPaySubscriptionInvoiceResponse
 */
export interface ResponseBaseExternalPaySubscriptionInvoiceResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseExternalPaySubscriptionInvoiceResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseExternalPaySubscriptionInvoiceResponse
   */
  message?: string;
  /**
   *
   * @type {ExternalPaySubscriptionInvoiceResponse}
   * @memberof ResponseBaseExternalPaySubscriptionInvoiceResponse
   */
  data?: ExternalPaySubscriptionInvoiceResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseExternalPaySubscriptionPlanResponse
 */
export interface ResponseBaseExternalPaySubscriptionPlanResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseExternalPaySubscriptionPlanResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseExternalPaySubscriptionPlanResponse
   */
  message?: string;
  /**
   *
   * @type {ExternalPaySubscriptionPlanResponse}
   * @memberof ResponseBaseExternalPaySubscriptionPlanResponse
   */
  data?: ExternalPaySubscriptionPlanResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseExternalPaySubscriptionResponse
 */
export interface ResponseBaseExternalPaySubscriptionResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseExternalPaySubscriptionResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseExternalPaySubscriptionResponse
   */
  message?: string;
  /**
   *
   * @type {ExternalPaySubscriptionResponse}
   * @memberof ResponseBaseExternalPaySubscriptionResponse
   */
  data?: ExternalPaySubscriptionResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseFeeCodeResponse
 */
export interface ResponseBaseFeeCodeResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseFeeCodeResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseFeeCodeResponse
   */
  message?: string;
  /**
   *
   * @type {FeeCodeResponse}
   * @memberof ResponseBaseFeeCodeResponse
   */
  data?: FeeCodeResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseForControlResponse
 */
export interface ResponseBaseForControlResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseForControlResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseForControlResponse
   */
  message?: string;
  /**
   *
   * @type {ForControlResponse}
   * @memberof ResponseBaseForControlResponse
   */
  data?: ForControlResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseForgotPasswordCheckOTPResponse
 */
export interface ResponseBaseForgotPasswordCheckOTPResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseForgotPasswordCheckOTPResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseForgotPasswordCheckOTPResponse
   */
  message?: string;
  /**
   *
   * @type {ForgotPasswordCheckOTPResponse}
   * @memberof ResponseBaseForgotPasswordCheckOTPResponse
   */
  data?: ForgotPasswordCheckOTPResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseForgotPasswordCheckPhoneResponse
 */
export interface ResponseBaseForgotPasswordCheckPhoneResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseForgotPasswordCheckPhoneResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseForgotPasswordCheckPhoneResponse
   */
  message?: string;
  /**
   *
   * @type {ForgotPasswordCheckPhoneResponse}
   * @memberof ResponseBaseForgotPasswordCheckPhoneResponse
   */
  data?: ForgotPasswordCheckPhoneResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGetAllFeeNameDtoResponse
 */
export interface ResponseBaseGetAllFeeNameDtoResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseGetAllFeeNameDtoResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetAllFeeNameDtoResponse
   */
  message?: string;
  /**
   *
   * @type {GetAllFeeNameDtoResponse}
   * @memberof ResponseBaseGetAllFeeNameDtoResponse
   */
  data?: GetAllFeeNameDtoResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGetAllFeeReportResponse
 */
export interface ResponseBaseGetAllFeeReportResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseGetAllFeeReportResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetAllFeeReportResponse
   */
  message?: string;
  /**
   *
   * @type {GetAllFeeReportResponse}
   * @memberof ResponseBaseGetAllFeeReportResponse
   */
  data?: GetAllFeeReportResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGetAllPaySubscriptionPlanCacheResponse
 */
export interface ResponseBaseGetAllPaySubscriptionPlanCacheResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseGetAllPaySubscriptionPlanCacheResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetAllPaySubscriptionPlanCacheResponse
   */
  message?: string;
  /**
   *
   * @type {GetAllPaySubscriptionPlanCacheResponse}
   * @memberof ResponseBaseGetAllPaySubscriptionPlanCacheResponse
   */
  data?: GetAllPaySubscriptionPlanCacheResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGetAllPayboxTransactionsResponse
 */
export interface ResponseBaseGetAllPayboxTransactionsResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseGetAllPayboxTransactionsResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetAllPayboxTransactionsResponse
   */
  message?: string;
  /**
   *
   * @type {GetAllPayboxTransactionsResponse}
   * @memberof ResponseBaseGetAllPayboxTransactionsResponse
   */
  data?: GetAllPayboxTransactionsResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGetAllPermissionResponse
 */
export interface ResponseBaseGetAllPermissionResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseGetAllPermissionResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetAllPermissionResponse
   */
  message?: string;
  /**
   *
   * @type {GetAllPermissionResponse}
   * @memberof ResponseBaseGetAllPermissionResponse
   */
  data?: GetAllPermissionResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGetAllPortalPermissionResponse
 */
export interface ResponseBaseGetAllPortalPermissionResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseGetAllPortalPermissionResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetAllPortalPermissionResponse
   */
  message?: string;
  /**
   *
   * @type {GetAllPortalPermissionResponse}
   * @memberof ResponseBaseGetAllPortalPermissionResponse
   */
  data?: GetAllPortalPermissionResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGetBalanceBankAccountResponse
 */
export interface ResponseBaseGetBalanceBankAccountResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseGetBalanceBankAccountResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetBalanceBankAccountResponse
   */
  message?: string;
  /**
   *
   * @type {GetBalanceBankAccountResponse}
   * @memberof ResponseBaseGetBalanceBankAccountResponse
   */
  data?: GetBalanceBankAccountResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGetBankTransferInfoResponse
 */
export interface ResponseBaseGetBankTransferInfoResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseGetBankTransferInfoResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetBankTransferInfoResponse
   */
  message?: string;
  /**
   *
   * @type {GetBankTransferInfoResponse}
   * @memberof ResponseBaseGetBankTransferInfoResponse
   */
  data?: GetBankTransferInfoResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGetBeneficiaryResponse
 */
export interface ResponseBaseGetBeneficiaryResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseGetBeneficiaryResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetBeneficiaryResponse
   */
  message?: string;
  /**
   *
   * @type {GetBeneficiaryResponse}
   * @memberof ResponseBaseGetBeneficiaryResponse
   */
  data?: GetBeneficiaryResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGetExternalButtonResponse
 */
export interface ResponseBaseGetExternalButtonResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseGetExternalButtonResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetExternalButtonResponse
   */
  message?: string;
  /**
   *
   * @type {GetExternalButtonResponse}
   * @memberof ResponseBaseGetExternalButtonResponse
   */
  data?: GetExternalButtonResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGetFeeInfoResponse
 */
export interface ResponseBaseGetFeeInfoResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseGetFeeInfoResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetFeeInfoResponse
   */
  message?: string;
  /**
   *
   * @type {GetFeeInfoResponse}
   * @memberof ResponseBaseGetFeeInfoResponse
   */
  data?: GetFeeInfoResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGetLinkedAccountsFromBankResponse
 */
export interface ResponseBaseGetLinkedAccountsFromBankResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseGetLinkedAccountsFromBankResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetLinkedAccountsFromBankResponse
   */
  message?: string;
  /**
   *
   * @type {GetLinkedAccountsFromBankResponse}
   * @memberof ResponseBaseGetLinkedAccountsFromBankResponse
   */
  data?: GetLinkedAccountsFromBankResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGetListAppResponse
 */
export interface ResponseBaseGetListAppResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseGetListAppResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetListAppResponse
   */
  message?: string;
  /**
   *
   * @type {GetListAppResponse}
   * @memberof ResponseBaseGetListAppResponse
   */
  data?: GetListAppResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGetListBankAccountResponse
 */
export interface ResponseBaseGetListBankAccountResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseGetListBankAccountResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetListBankAccountResponse
   */
  message?: string;
  /**
   *
   * @type {GetListBankAccountResponse}
   * @memberof ResponseBaseGetListBankAccountResponse
   */
  data?: GetListBankAccountResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGetListPaymentMethodResponse
 */
export interface ResponseBaseGetListPaymentMethodResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseGetListPaymentMethodResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetListPaymentMethodResponse
   */
  message?: string;
  /**
   *
   * @type {GetListPaymentMethodResponse}
   * @memberof ResponseBaseGetListPaymentMethodResponse
   */
  data?: GetListPaymentMethodResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGetMerchantCredentialResponse
 */
export interface ResponseBaseGetMerchantCredentialResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseGetMerchantCredentialResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetMerchantCredentialResponse
   */
  message?: string;
  /**
   *
   * @type {GetMerchantCredentialResponse}
   * @memberof ResponseBaseGetMerchantCredentialResponse
   */
  data?: GetMerchantCredentialResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGetMerchantEkycInfoResponse
 */
export interface ResponseBaseGetMerchantEkycInfoResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseGetMerchantEkycInfoResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetMerchantEkycInfoResponse
   */
  message?: string;
  /**
   *
   * @type {GetMerchantEkycInfoResponse}
   * @memberof ResponseBaseGetMerchantEkycInfoResponse
   */
  data?: GetMerchantEkycInfoResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGetMerchantIdResponse
 */
export interface ResponseBaseGetMerchantIdResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseGetMerchantIdResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetMerchantIdResponse
   */
  message?: string;
  /**
   *
   * @type {GetMerchantIdResponse}
   * @memberof ResponseBaseGetMerchantIdResponse
   */
  data?: GetMerchantIdResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGetMerchantInfoFromKeycloakResponse
 */
export interface ResponseBaseGetMerchantInfoFromKeycloakResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseGetMerchantInfoFromKeycloakResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetMerchantInfoFromKeycloakResponse
   */
  message?: string;
  /**
   *
   * @type {GetMerchantInfoFromKeycloakResponse}
   * @memberof ResponseBaseGetMerchantInfoFromKeycloakResponse
   */
  data?: GetMerchantInfoFromKeycloakResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGetMerchantResponse
 */
export interface ResponseBaseGetMerchantResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseGetMerchantResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetMerchantResponse
   */
  message?: string;
  /**
   *
   * @type {GetMerchantResponse}
   * @memberof ResponseBaseGetMerchantResponse
   */
  data?: GetMerchantResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGetNapasDetailSessionResponse
 */
export interface ResponseBaseGetNapasDetailSessionResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseGetNapasDetailSessionResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetNapasDetailSessionResponse
   */
  message?: string;
  /**
   *
   * @type {GetNapasDetailSessionResponse}
   * @memberof ResponseBaseGetNapasDetailSessionResponse
   */
  data?: GetNapasDetailSessionResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGetPayLinkBankTransferResponse
 */
export interface ResponseBaseGetPayLinkBankTransferResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseGetPayLinkBankTransferResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetPayLinkBankTransferResponse
   */
  message?: string;
  /**
   *
   * @type {GetPayLinkBankTransferResponse}
   * @memberof ResponseBaseGetPayLinkBankTransferResponse
   */
  data?: GetPayLinkBankTransferResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGetPayLinkNapasResponse
 */
export interface ResponseBaseGetPayLinkNapasResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseGetPayLinkNapasResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetPayLinkNapasResponse
   */
  message?: string;
  /**
   *
   * @type {GetPayLinkNapasResponse}
   * @memberof ResponseBaseGetPayLinkNapasResponse
   */
  data?: GetPayLinkNapasResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGetPayLinkVietQRResponse
 */
export interface ResponseBaseGetPayLinkVietQRResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseGetPayLinkVietQRResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetPayLinkVietQRResponse
   */
  message?: string;
  /**
   *
   * @type {GetPayLinkVietQRResponse}
   * @memberof ResponseBaseGetPayLinkVietQRResponse
   */
  data?: GetPayLinkVietQRResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGetStatisticInfoResponse
 */
export interface ResponseBaseGetStatisticInfoResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseGetStatisticInfoResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetStatisticInfoResponse
   */
  message?: string;
  /**
   *
   * @type {GetStatisticInfoResponse}
   * @memberof ResponseBaseGetStatisticInfoResponse
   */
  data?: GetStatisticInfoResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGetSubmitInfoResponse
 */
export interface ResponseBaseGetSubmitInfoResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseGetSubmitInfoResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetSubmitInfoResponse
   */
  message?: string;
  /**
   *
   * @type {GetSubmitInfoResponse}
   * @memberof ResponseBaseGetSubmitInfoResponse
   */
  data?: GetSubmitInfoResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGetTransactionStatisticResponse
 */
export interface ResponseBaseGetTransactionStatisticResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseGetTransactionStatisticResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetTransactionStatisticResponse
   */
  message?: string;
  /**
   *
   * @type {GetTransactionStatisticResponse}
   * @memberof ResponseBaseGetTransactionStatisticResponse
   */
  data?: GetTransactionStatisticResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGetVietQRInfoResponse
 */
export interface ResponseBaseGetVietQRInfoResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseGetVietQRInfoResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetVietQRInfoResponse
   */
  message?: string;
  /**
   *
   * @type {GetVietQRInfoResponse}
   * @memberof ResponseBaseGetVietQRInfoResponse
   */
  data?: GetVietQRInfoResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseGetWebhookInfoResponse
 */
export interface ResponseBaseGetWebhookInfoResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseGetWebhookInfoResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseGetWebhookInfoResponse
   */
  message?: string;
  /**
   *
   * @type {GetWebhookInfoResponse}
   * @memberof ResponseBaseGetWebhookInfoResponse
   */
  data?: GetWebhookInfoResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseInfoDevicePayPosResponse
 */
export interface ResponseBaseInfoDevicePayPosResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseInfoDevicePayPosResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseInfoDevicePayPosResponse
   */
  message?: string;
  /**
   *
   * @type {InfoDevicePayPosResponse}
   * @memberof ResponseBaseInfoDevicePayPosResponse
   */
  data?: InfoDevicePayPosResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseLinkAccountResponse
 */
export interface ResponseBaseLinkAccountResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseLinkAccountResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseLinkAccountResponse
   */
  message?: string;
  /**
   *
   * @type {LinkAccountResponse}
   * @memberof ResponseBaseLinkAccountResponse
   */
  data?: LinkAccountResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseListResponsePayOutUploadReceiverDTO
 */
export interface ResponseBaseListResponsePayOutUploadReceiverDTO {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseListResponsePayOutUploadReceiverDTO
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseListResponsePayOutUploadReceiverDTO
   */
  message?: string;
  /**
   *
   * @type {ListResponsePayOutUploadReceiverDTO}
   * @memberof ResponseBaseListResponsePayOutUploadReceiverDTO
   */
  data?: ListResponsePayOutUploadReceiverDTO;
}
/**
 *
 * @export
 * @interface ResponseBaseListResponseUserResponse
 */
export interface ResponseBaseListResponseUserResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseListResponseUserResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseListResponseUserResponse
   */
  message?: string;
  /**
   *
   * @type {ListResponseUserResponse}
   * @memberof ResponseBaseListResponseUserResponse
   */
  data?: ListResponseUserResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseListSftpFileResponse
 */
export interface ResponseBaseListSftpFileResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseListSftpFileResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseListSftpFileResponse
   */
  message?: string;
  /**
   *
   * @type {ListSftpFileResponse}
   * @memberof ResponseBaseListSftpFileResponse
   */
  data?: ListSftpFileResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseLoginAppResponse
 */
export interface ResponseBaseLoginAppResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseLoginAppResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseLoginAppResponse
   */
  message?: string;
  /**
   *
   * @type {LoginAppResponse}
   * @memberof ResponseBaseLoginAppResponse
   */
  data?: LoginAppResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseLogoutResponse
 */
export interface ResponseBaseLogoutResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseLogoutResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseLogoutResponse
   */
  message?: string;
  /**
   *
   * @type {LogoutResponse}
   * @memberof ResponseBaseLogoutResponse
   */
  data?: LogoutResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseMerchantDetailResponse
 */
export interface ResponseBaseMerchantDetailResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseMerchantDetailResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseMerchantDetailResponse
   */
  message?: string;
  /**
   *
   * @type {MerchantDetailResponse}
   * @memberof ResponseBaseMerchantDetailResponse
   */
  data?: MerchantDetailResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseMerchantInfoResponse
 */
export interface ResponseBaseMerchantInfoResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseMerchantInfoResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseMerchantInfoResponse
   */
  message?: string;
  /**
   *
   * @type {MerchantInfoResponse}
   * @memberof ResponseBaseMerchantInfoResponse
   */
  data?: MerchantInfoResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseMerchantResponse
 */
export interface ResponseBaseMerchantResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseMerchantResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseMerchantResponse
   */
  message?: string;
  /**
   *
   * @type {MerchantResponse}
   * @memberof ResponseBaseMerchantResponse
   */
  data?: MerchantResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseMonthlyFeeReportResponse
 */
export interface ResponseBaseMonthlyFeeReportResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseMonthlyFeeReportResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseMonthlyFeeReportResponse
   */
  message?: string;
  /**
   *
   * @type {MonthlyFeeReportResponse}
   * @memberof ResponseBaseMonthlyFeeReportResponse
   */
  data?: MonthlyFeeReportResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseNapasNotifyTransactionResponse
 */
export interface ResponseBaseNapasNotifyTransactionResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseNapasNotifyTransactionResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseNapasNotifyTransactionResponse
   */
  message?: string;
  /**
   *
   * @type {NapasNotifyTransactionResponse}
   * @memberof ResponseBaseNapasNotifyTransactionResponse
   */
  data?: NapasNotifyTransactionResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseOTPPayOutResponse
 */
export interface ResponseBaseOTPPayOutResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseOTPPayOutResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseOTPPayOutResponse
   */
  message?: string;
  /**
   *
   * @type {OTPPayOutResponse}
   * @memberof ResponseBaseOTPPayOutResponse
   */
  data?: OTPPayOutResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseObject
 */
export interface ResponseBaseObject {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseObject
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseObject
   */
  message?: string;
  /**
   *
   * @type {object}
   * @memberof ResponseBaseObject
   */
  data?: object;
}
/**
 *
 * @export
 * @interface ResponseBaseOrderDetailResponse
 */
export interface ResponseBaseOrderDetailResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseOrderDetailResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseOrderDetailResponse
   */
  message?: string;
  /**
   *
   * @type {OrderDetailResponse}
   * @memberof ResponseBaseOrderDetailResponse
   */
  data?: OrderDetailResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseOrderResponse
 */
export interface ResponseBaseOrderResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseOrderResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseOrderResponse
   */
  message?: string;
  /**
   *
   * @type {OrderResponse}
   * @memberof ResponseBaseOrderResponse
   */
  data?: OrderResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponseAffiliateResponse
 */
export interface ResponseBasePageResponseAffiliateResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponseAffiliateResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseAffiliateResponse
   */
  message?: string;
  /**
   *
   * @type {PageResponseAffiliateResponse}
   * @memberof ResponseBasePageResponseAffiliateResponse
   */
  data?: PageResponseAffiliateResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponseBankResponse
 */
export interface ResponseBasePageResponseBankResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponseBankResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseBankResponse
   */
  message?: string;
  /**
   *
   * @type {PageResponseBankResponse}
   * @memberof ResponseBasePageResponseBankResponse
   */
  data?: PageResponseBankResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponseBatchPayOutResponse
 */
export interface ResponseBasePageResponseBatchPayOutResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponseBatchPayOutResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseBatchPayOutResponse
   */
  message?: string;
  /**
   *
   * @type {PageResponseBatchPayOutResponse}
   * @memberof ResponseBasePageResponseBatchPayOutResponse
   */
  data?: PageResponseBatchPayOutResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponseCategoryResponse
 */
export interface ResponseBasePageResponseCategoryResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponseCategoryResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseCategoryResponse
   */
  message?: string;
  /**
   *
   * @type {PageResponseCategoryResponse}
   * @memberof ResponseBasePageResponseCategoryResponse
   */
  data?: PageResponseCategoryResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponseCmsListOrderResponse
 */
export interface ResponseBasePageResponseCmsListOrderResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponseCmsListOrderResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseCmsListOrderResponse
   */
  message?: string;
  /**
   *
   * @type {PageResponseCmsListOrderResponse}
   * @memberof ResponseBasePageResponseCmsListOrderResponse
   */
  data?: PageResponseCmsListOrderResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponseCmsVietQrProResponse
 */
export interface ResponseBasePageResponseCmsVietQrProResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponseCmsVietQrProResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseCmsVietQrProResponse
   */
  message?: string;
  /**
   *
   * @type {PageResponseCmsVietQrProResponse}
   * @memberof ResponseBasePageResponseCmsVietQrProResponse
   */
  data?: PageResponseCmsVietQrProResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponseCmsWebhookUrlResponse
 */
export interface ResponseBasePageResponseCmsWebhookUrlResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponseCmsWebhookUrlResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseCmsWebhookUrlResponse
   */
  message?: string;
  /**
   *
   * @type {PageResponseCmsWebhookUrlResponse}
   * @memberof ResponseBasePageResponseCmsWebhookUrlResponse
   */
  data?: PageResponseCmsWebhookUrlResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponseCustomerDto
 */
export interface ResponseBasePageResponseCustomerDto {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponseCustomerDto
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseCustomerDto
   */
  message?: string;
  /**
   *
   * @type {PageResponseCustomerDto}
   * @memberof ResponseBasePageResponseCustomerDto
   */
  data?: PageResponseCustomerDto;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponseCustomerGroupDto
 */
export interface ResponseBasePageResponseCustomerGroupDto {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponseCustomerGroupDto
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseCustomerGroupDto
   */
  message?: string;
  /**
   *
   * @type {PageResponseCustomerGroupDto}
   * @memberof ResponseBasePageResponseCustomerGroupDto
   */
  data?: PageResponseCustomerGroupDto;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponseEWalletResponse
 */
export interface ResponseBasePageResponseEWalletResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponseEWalletResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseEWalletResponse
   */
  message?: string;
  /**
   *
   * @type {PageResponseEWalletResponse}
   * @memberof ResponseBasePageResponseEWalletResponse
   */
  data?: PageResponseEWalletResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponseExternalPaySubscriptionInvoiceResponse
 */
export interface ResponseBasePageResponseExternalPaySubscriptionInvoiceResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponseExternalPaySubscriptionInvoiceResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseExternalPaySubscriptionInvoiceResponse
   */
  message?: string;
  /**
   *
   * @type {PageResponseExternalPaySubscriptionInvoiceResponse}
   * @memberof ResponseBasePageResponseExternalPaySubscriptionInvoiceResponse
   */
  data?: PageResponseExternalPaySubscriptionInvoiceResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponseFeeDto
 */
export interface ResponseBasePageResponseFeeDto {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponseFeeDto
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseFeeDto
   */
  message?: string;
  /**
   *
   * @type {PageResponseFeeDto}
   * @memberof ResponseBasePageResponseFeeDto
   */
  data?: PageResponseFeeDto;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponseFeeHistoryDto
 */
export interface ResponseBasePageResponseFeeHistoryDto {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponseFeeHistoryDto
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseFeeHistoryDto
   */
  message?: string;
  /**
   *
   * @type {PageResponseFeeHistoryDto}
   * @memberof ResponseBasePageResponseFeeHistoryDto
   */
  data?: PageResponseFeeHistoryDto;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponseForControlDto
 */
export interface ResponseBasePageResponseForControlDto {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponseForControlDto
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseForControlDto
   */
  message?: string;
  /**
   *
   * @type {PageResponseForControlDto}
   * @memberof ResponseBasePageResponseForControlDto
   */
  data?: PageResponseForControlDto;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponseForControlHistoryDto
 */
export interface ResponseBasePageResponseForControlHistoryDto {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponseForControlHistoryDto
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseForControlHistoryDto
   */
  message?: string;
  /**
   *
   * @type {PageResponseForControlHistoryDto}
   * @memberof ResponseBasePageResponseForControlHistoryDto
   */
  data?: PageResponseForControlHistoryDto;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponseGetListAdminResponse
 */
export interface ResponseBasePageResponseGetListAdminResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponseGetListAdminResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseGetListAdminResponse
   */
  message?: string;
  /**
   *
   * @type {PageResponseGetListAdminResponse}
   * @memberof ResponseBasePageResponseGetListAdminResponse
   */
  data?: PageResponseGetListAdminResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponseGetPageListPosPortalResponse
 */
export interface ResponseBasePageResponseGetPageListPosPortalResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponseGetPageListPosPortalResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseGetPageListPosPortalResponse
   */
  message?: string;
  /**
   *
   * @type {PageResponseGetPageListPosPortalResponse}
   * @memberof ResponseBasePageResponseGetPageListPosPortalResponse
   */
  data?: PageResponseGetPageListPosPortalResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponseGetPosHistoryTransactionPortalResponse
 */
export interface ResponseBasePageResponseGetPosHistoryTransactionPortalResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponseGetPosHistoryTransactionPortalResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseGetPosHistoryTransactionPortalResponse
   */
  message?: string;
  /**
   *
   * @type {PageResponseGetPosHistoryTransactionPortalResponse}
   * @memberof ResponseBasePageResponseGetPosHistoryTransactionPortalResponse
   */
  data?: PageResponseGetPosHistoryTransactionPortalResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponseGetReportResponse
 */
export interface ResponseBasePageResponseGetReportResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponseGetReportResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseGetReportResponse
   */
  message?: string;
  /**
   *
   * @type {PageResponseGetReportResponse}
   * @memberof ResponseBasePageResponseGetReportResponse
   */
  data?: PageResponseGetReportResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponseInfoDevicePayPosResponse
 */
export interface ResponseBasePageResponseInfoDevicePayPosResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponseInfoDevicePayPosResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseInfoDevicePayPosResponse
   */
  message?: string;
  /**
   *
   * @type {PageResponseInfoDevicePayPosResponse}
   * @memberof ResponseBasePageResponseInfoDevicePayPosResponse
   */
  data?: PageResponseInfoDevicePayPosResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponseMerchantFeeDto
 */
export interface ResponseBasePageResponseMerchantFeeDto {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponseMerchantFeeDto
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseMerchantFeeDto
   */
  message?: string;
  /**
   *
   * @type {PageResponseMerchantFeeDto}
   * @memberof ResponseBasePageResponseMerchantFeeDto
   */
  data?: PageResponseMerchantFeeDto;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponseMerchantHistoryDto
 */
export interface ResponseBasePageResponseMerchantHistoryDto {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponseMerchantHistoryDto
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseMerchantHistoryDto
   */
  message?: string;
  /**
   *
   * @type {PageResponseMerchantHistoryDto}
   * @memberof ResponseBasePageResponseMerchantHistoryDto
   */
  data?: PageResponseMerchantHistoryDto;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponseMerchantResponse
 */
export interface ResponseBasePageResponseMerchantResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponseMerchantResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseMerchantResponse
   */
  message?: string;
  /**
   *
   * @type {PageResponseMerchantResponse}
   * @memberof ResponseBasePageResponseMerchantResponse
   */
  data?: PageResponseMerchantResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponseNapasSessionDto
 */
export interface ResponseBasePageResponseNapasSessionDto {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponseNapasSessionDto
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseNapasSessionDto
   */
  message?: string;
  /**
   *
   * @type {PageResponseNapasSessionDto}
   * @memberof ResponseBasePageResponseNapasSessionDto
   */
  data?: PageResponseNapasSessionDto;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponseOrderItemResponse
 */
export interface ResponseBasePageResponseOrderItemResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponseOrderItemResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseOrderItemResponse
   */
  message?: string;
  /**
   *
   * @type {PageResponseOrderItemResponse}
   * @memberof ResponseBasePageResponseOrderItemResponse
   */
  data?: PageResponseOrderItemResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponseOrderResponse
 */
export interface ResponseBasePageResponseOrderResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponseOrderResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseOrderResponse
   */
  message?: string;
  /**
   *
   * @type {PageResponseOrderResponse}
   * @memberof ResponseBasePageResponseOrderResponse
   */
  data?: PageResponseOrderResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponsePayButtonResponse
 */
export interface ResponseBasePageResponsePayButtonResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponsePayButtonResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponsePayButtonResponse
   */
  message?: string;
  /**
   *
   * @type {PageResponsePayButtonResponse}
   * @memberof ResponseBasePageResponsePayButtonResponse
   */
  data?: PageResponsePayButtonResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponsePayLinkDto
 */
export interface ResponseBasePageResponsePayLinkDto {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponsePayLinkDto
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponsePayLinkDto
   */
  message?: string;
  /**
   *
   * @type {PageResponsePayLinkDto}
   * @memberof ResponseBasePageResponsePayLinkDto
   */
  data?: PageResponsePayLinkDto;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponsePayOutReceiverDTO
 */
export interface ResponseBasePageResponsePayOutReceiverDTO {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponsePayOutReceiverDTO
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponsePayOutReceiverDTO
   */
  message?: string;
  /**
   *
   * @type {PageResponsePayOutReceiverDTO}
   * @memberof ResponseBasePageResponsePayOutReceiverDTO
   */
  data?: PageResponsePayOutReceiverDTO;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponsePayPageResponse
 */
export interface ResponseBasePageResponsePayPageResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponsePayPageResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponsePayPageResponse
   */
  message?: string;
  /**
   *
   * @type {PageResponsePayPageResponse}
   * @memberof ResponseBasePageResponsePayPageResponse
   */
  data?: PageResponsePayPageResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponsePayPageTransactionResponse
 */
export interface ResponseBasePageResponsePayPageTransactionResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponsePayPageTransactionResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponsePayPageTransactionResponse
   */
  message?: string;
  /**
   *
   * @type {PageResponsePayPageTransactionResponse}
   * @memberof ResponseBasePageResponsePayPageTransactionResponse
   */
  data?: PageResponsePayPageTransactionResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponsePaySubscriptionInvoiceResponse
 */
export interface ResponseBasePageResponsePaySubscriptionInvoiceResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponsePaySubscriptionInvoiceResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponsePaySubscriptionInvoiceResponse
   */
  message?: string;
  /**
   *
   * @type {PageResponsePaySubscriptionInvoiceResponse}
   * @memberof ResponseBasePageResponsePaySubscriptionInvoiceResponse
   */
  data?: PageResponsePaySubscriptionInvoiceResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponsePaySubscriptionPlanResponse
 */
export interface ResponseBasePageResponsePaySubscriptionPlanResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponsePaySubscriptionPlanResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponsePaySubscriptionPlanResponse
   */
  message?: string;
  /**
   *
   * @type {PageResponsePaySubscriptionPlanResponse}
   * @memberof ResponseBasePageResponsePaySubscriptionPlanResponse
   */
  data?: PageResponsePaySubscriptionPlanResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponsePaySubscriptionResponse
 */
export interface ResponseBasePageResponsePaySubscriptionResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponsePaySubscriptionResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponsePaySubscriptionResponse
   */
  message?: string;
  /**
   *
   * @type {PageResponsePaySubscriptionResponse}
   * @memberof ResponseBasePageResponsePaySubscriptionResponse
   */
  data?: PageResponsePaySubscriptionResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponsePaymentTransactionResponse
 */
export interface ResponseBasePageResponsePaymentTransactionResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponsePaymentTransactionResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponsePaymentTransactionResponse
   */
  message?: string;
  /**
   *
   * @type {PageResponsePaymentTransactionResponse}
   * @memberof ResponseBasePageResponsePaymentTransactionResponse
   */
  data?: PageResponsePaymentTransactionResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponsePosModelResponse
 */
export interface ResponseBasePageResponsePosModelResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponsePosModelResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponsePosModelResponse
   */
  message?: string;
  /**
   *
   * @type {PageResponsePosModelResponse}
   * @memberof ResponseBasePageResponsePosModelResponse
   */
  data?: PageResponsePosModelResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponsePosOrderListResponse
 */
export interface ResponseBasePageResponsePosOrderListResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponsePosOrderListResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponsePosOrderListResponse
   */
  message?: string;
  /**
   *
   * @type {PageResponsePosOrderListResponse}
   * @memberof ResponseBasePageResponsePosOrderListResponse
   */
  data?: PageResponsePosOrderListResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponseProductResponse
 */
export interface ResponseBasePageResponseProductResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponseProductResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseProductResponse
   */
  message?: string;
  /**
   *
   * @type {PageResponseProductResponse}
   * @memberof ResponseBasePageResponseProductResponse
   */
  data?: PageResponseProductResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponseResponseMessageResponse
 */
export interface ResponseBasePageResponseResponseMessageResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponseResponseMessageResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseResponseMessageResponse
   */
  message?: string;
  /**
   *
   * @type {PageResponseResponseMessageResponse}
   * @memberof ResponseBasePageResponseResponseMessageResponse
   */
  data?: PageResponseResponseMessageResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponseRoleGroupResponse
 */
export interface ResponseBasePageResponseRoleGroupResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponseRoleGroupResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseRoleGroupResponse
   */
  message?: string;
  /**
   *
   * @type {PageResponseRoleGroupResponse}
   * @memberof ResponseBasePageResponseRoleGroupResponse
   */
  data?: PageResponseRoleGroupResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponseSinglePayOutResponse
 */
export interface ResponseBasePageResponseSinglePayOutResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponseSinglePayOutResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseSinglePayOutResponse
   */
  message?: string;
  /**
   *
   * @type {PageResponseSinglePayOutResponse}
   * @memberof ResponseBasePageResponseSinglePayOutResponse
   */
  data?: PageResponseSinglePayOutResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponseSupportResponse
 */
export interface ResponseBasePageResponseSupportResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponseSupportResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseSupportResponse
   */
  message?: string;
  /**
   *
   * @type {PageResponseSupportResponse}
   * @memberof ResponseBasePageResponseSupportResponse
   */
  data?: PageResponseSupportResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponseSystemParamResponse
 */
export interface ResponseBasePageResponseSystemParamResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponseSystemParamResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseSystemParamResponse
   */
  message?: string;
  /**
   *
   * @type {PageResponseSystemParamResponse}
   * @memberof ResponseBasePageResponseSystemParamResponse
   */
  data?: PageResponseSystemParamResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponseTransactionResponse
 */
export interface ResponseBasePageResponseTransactionResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponseTransactionResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseTransactionResponse
   */
  message?: string;
  /**
   *
   * @type {PageResponseTransactionResponse}
   * @memberof ResponseBasePageResponseTransactionResponse
   */
  data?: PageResponseTransactionResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponseUpdateFAQResponse
 */
export interface ResponseBasePageResponseUpdateFAQResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponseUpdateFAQResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseUpdateFAQResponse
   */
  message?: string;
  /**
   *
   * @type {PageResponseUpdateFAQResponse}
   * @memberof ResponseBasePageResponseUpdateFAQResponse
   */
  data?: PageResponseUpdateFAQResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponseUserResponse
 */
export interface ResponseBasePageResponseUserResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponseUserResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseUserResponse
   */
  message?: string;
  /**
   *
   * @type {PageResponseUserResponse}
   * @memberof ResponseBasePageResponseUserResponse
   */
  data?: PageResponseUserResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponseVietQrProCustomerResponse
 */
export interface ResponseBasePageResponseVietQrProCustomerResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponseVietQrProCustomerResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseVietQrProCustomerResponse
   */
  message?: string;
  /**
   *
   * @type {PageResponseVietQrProCustomerResponse}
   * @memberof ResponseBasePageResponseVietQrProCustomerResponse
   */
  data?: PageResponseVietQrProCustomerResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePageResponseVietQrProHistoryChangeResponse
 */
export interface ResponseBasePageResponseVietQrProHistoryChangeResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePageResponseVietQrProHistoryChangeResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePageResponseVietQrProHistoryChangeResponse
   */
  message?: string;
  /**
   *
   * @type {PageResponseVietQrProHistoryChangeResponse}
   * @memberof ResponseBasePageResponseVietQrProHistoryChangeResponse
   */
  data?: PageResponseVietQrProHistoryChangeResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePayButtonResponse
 */
export interface ResponseBasePayButtonResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePayButtonResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePayButtonResponse
   */
  message?: string;
  /**
   *
   * @type {PayButtonResponse}
   * @memberof ResponseBasePayButtonResponse
   */
  data?: PayButtonResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePayInfoStatusResponse
 */
export interface ResponseBasePayInfoStatusResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePayInfoStatusResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePayInfoStatusResponse
   */
  message?: string;
  /**
   *
   * @type {PayInfoStatusResponse}
   * @memberof ResponseBasePayInfoStatusResponse
   */
  data?: PayInfoStatusResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePayLinkResponse
 */
export interface ResponseBasePayLinkResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePayLinkResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePayLinkResponse
   */
  message?: string;
  /**
   *
   * @type {PayLinkResponse}
   * @memberof ResponseBasePayLinkResponse
   */
  data?: PayLinkResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePayLinkStatusResponse
 */
export interface ResponseBasePayLinkStatusResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePayLinkStatusResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePayLinkStatusResponse
   */
  message?: string;
  /**
   *
   * @type {PayLinkStatusResponse}
   * @memberof ResponseBasePayLinkStatusResponse
   */
  data?: PayLinkStatusResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePayLinkUrlResponse
 */
export interface ResponseBasePayLinkUrlResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePayLinkUrlResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePayLinkUrlResponse
   */
  message?: string;
  /**
   *
   * @type {PayLinkUrlResponse}
   * @memberof ResponseBasePayLinkUrlResponse
   */
  data?: PayLinkUrlResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePayOutReceiverDTO
 */
export interface ResponseBasePayOutReceiverDTO {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePayOutReceiverDTO
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePayOutReceiverDTO
   */
  message?: string;
  /**
   *
   * @type {PayOutReceiverDTO}
   * @memberof ResponseBasePayOutReceiverDTO
   */
  data?: PayOutReceiverDTO;
}
/**
 *
 * @export
 * @interface ResponseBasePayPageDetailResponse
 */
export interface ResponseBasePayPageDetailResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePayPageDetailResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePayPageDetailResponse
   */
  message?: string;
  /**
   *
   * @type {PayPageDetailResponse}
   * @memberof ResponseBasePayPageDetailResponse
   */
  data?: PayPageDetailResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePayPageResponse
 */
export interface ResponseBasePayPageResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePayPageResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePayPageResponse
   */
  message?: string;
  /**
   *
   * @type {PayPageResponse}
   * @memberof ResponseBasePayPageResponse
   */
  data?: PayPageResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePayPageRevenueResponse
 */
export interface ResponseBasePayPageRevenueResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePayPageRevenueResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePayPageRevenueResponse
   */
  message?: string;
  /**
   *
   * @type {PayPageRevenueResponse}
   * @memberof ResponseBasePayPageRevenueResponse
   */
  data?: PayPageRevenueResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePayPosTransactionDetailResponse
 */
export interface ResponseBasePayPosTransactionDetailResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePayPosTransactionDetailResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePayPosTransactionDetailResponse
   */
  message?: string;
  /**
   *
   * @type {PayPosTransactionDetailResponse}
   * @memberof ResponseBasePayPosTransactionDetailResponse
   */
  data?: PayPosTransactionDetailResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePaySubscriptionInvoiceResponse
 */
export interface ResponseBasePaySubscriptionInvoiceResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePaySubscriptionInvoiceResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePaySubscriptionInvoiceResponse
   */
  message?: string;
  /**
   *
   * @type {PaySubscriptionInvoiceResponse}
   * @memberof ResponseBasePaySubscriptionInvoiceResponse
   */
  data?: PaySubscriptionInvoiceResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePaySubscriptionPlanResponse
 */
export interface ResponseBasePaySubscriptionPlanResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePaySubscriptionPlanResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePaySubscriptionPlanResponse
   */
  message?: string;
  /**
   *
   * @type {PaySubscriptionPlanResponse}
   * @memberof ResponseBasePaySubscriptionPlanResponse
   */
  data?: PaySubscriptionPlanResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePaySubscriptionResponse
 */
export interface ResponseBasePaySubscriptionResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePaySubscriptionResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePaySubscriptionResponse
   */
  message?: string;
  /**
   *
   * @type {PaySubscriptionResponse}
   * @memberof ResponseBasePaySubscriptionResponse
   */
  data?: PaySubscriptionResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePaymentMethodInfo
 */
export interface ResponseBasePaymentMethodInfo {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePaymentMethodInfo
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePaymentMethodInfo
   */
  message?: string;
  /**
   *
   * @type {PaymentMethodInfo}
   * @memberof ResponseBasePaymentMethodInfo
   */
  data?: PaymentMethodInfo;
}
/**
 *
 * @export
 * @interface ResponseBasePortalGetAllRoleGroupResponse
 */
export interface ResponseBasePortalGetAllRoleGroupResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePortalGetAllRoleGroupResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePortalGetAllRoleGroupResponse
   */
  message?: string;
  /**
   *
   * @type {PortalGetAllRoleGroupResponse}
   * @memberof ResponseBasePortalGetAllRoleGroupResponse
   */
  data?: PortalGetAllRoleGroupResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePortalLoginOTPResponse
 */
export interface ResponseBasePortalLoginOTPResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePortalLoginOTPResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePortalLoginOTPResponse
   */
  message?: string;
  /**
   *
   * @type {PortalLoginOTPResponse}
   * @memberof ResponseBasePortalLoginOTPResponse
   */
  data?: PortalLoginOTPResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePortalMemberLoginOTPResponse
 */
export interface ResponseBasePortalMemberLoginOTPResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePortalMemberLoginOTPResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePortalMemberLoginOTPResponse
   */
  message?: string;
  /**
   *
   * @type {PortalMemberLoginOTPResponse}
   * @memberof ResponseBasePortalMemberLoginOTPResponse
   */
  data?: PortalMemberLoginOTPResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePortalMemberResetPassGetOTPResponse
 */
export interface ResponseBasePortalMemberResetPassGetOTPResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePortalMemberResetPassGetOTPResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePortalMemberResetPassGetOTPResponse
   */
  message?: string;
  /**
   *
   * @type {PortalMemberResetPassGetOTPResponse}
   * @memberof ResponseBasePortalMemberResetPassGetOTPResponse
   */
  data?: PortalMemberResetPassGetOTPResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePortalPlacePosOrderResponse
 */
export interface ResponseBasePortalPlacePosOrderResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePortalPlacePosOrderResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePortalPlacePosOrderResponse
   */
  message?: string;
  /**
   *
   * @type {PortalPlacePosOrderResponse}
   * @memberof ResponseBasePortalPlacePosOrderResponse
   */
  data?: PortalPlacePosOrderResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePortalTotalTransactionResponse
 */
export interface ResponseBasePortalTotalTransactionResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePortalTotalTransactionResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePortalTotalTransactionResponse
   */
  message?: string;
  /**
   *
   * @type {PortalTotalTransactionResponse}
   * @memberof ResponseBasePortalTotalTransactionResponse
   */
  data?: PortalTotalTransactionResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePortalTransactionStatisticResponse
 */
export interface ResponseBasePortalTransactionStatisticResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePortalTransactionStatisticResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePortalTransactionStatisticResponse
   */
  message?: string;
  /**
   *
   * @type {PortalTransactionStatisticResponse}
   * @memberof ResponseBasePortalTransactionStatisticResponse
   */
  data?: PortalTransactionStatisticResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePosCheckTransactionResponse
 */
export interface ResponseBasePosCheckTransactionResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePosCheckTransactionResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePosCheckTransactionResponse
   */
  message?: string;
  /**
   *
   * @type {PosCheckTransactionResponse}
   * @memberof ResponseBasePosCheckTransactionResponse
   */
  data?: PosCheckTransactionResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePosModelResponse
 */
export interface ResponseBasePosModelResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePosModelResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePosModelResponse
   */
  message?: string;
  /**
   *
   * @type {PosModelResponse}
   * @memberof ResponseBasePosModelResponse
   */
  data?: PosModelResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePosOrderResponse
 */
export interface ResponseBasePosOrderResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePosOrderResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePosOrderResponse
   */
  message?: string;
  /**
   *
   * @type {PosOrderResponse}
   * @memberof ResponseBasePosOrderResponse
   */
  data?: PosOrderResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseProductResponse
 */
export interface ResponseBaseProductResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseProductResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseProductResponse
   */
  message?: string;
  /**
   *
   * @type {ProductResponse}
   * @memberof ResponseBaseProductResponse
   */
  data?: ProductResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseQrLoginGetInfoResponse
 */
export interface ResponseBaseQrLoginGetInfoResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseQrLoginGetInfoResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseQrLoginGetInfoResponse
   */
  message?: string;
  /**
   *
   * @type {QrLoginGetInfoResponse}
   * @memberof ResponseBaseQrLoginGetInfoResponse
   */
  data?: QrLoginGetInfoResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseQrLoginResponse
 */
export interface ResponseBaseQrLoginResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseQrLoginResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseQrLoginResponse
   */
  message?: string;
  /**
   *
   * @type {QrLoginResponse}
   * @memberof ResponseBaseQrLoginResponse
   */
  data?: QrLoginResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseQrUserInfoResponse
 */
export interface ResponseBaseQrUserInfoResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseQrUserInfoResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseQrUserInfoResponse
   */
  message?: string;
  /**
   *
   * @type {QrUserInfoResponse}
   * @memberof ResponseBaseQrUserInfoResponse
   */
  data?: QrUserInfoResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseQueryTransactionResponse
 */
export interface ResponseBaseQueryTransactionResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseQueryTransactionResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseQueryTransactionResponse
   */
  message?: string;
  /**
   *
   * @type {QueryTransactionResponse}
   * @memberof ResponseBaseQueryTransactionResponse
   */
  data?: QueryTransactionResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseRegisterAppResponse
 */
export interface ResponseBaseRegisterAppResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseRegisterAppResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseRegisterAppResponse
   */
  message?: string;
  /**
   *
   * @type {RegisterAppResponse}
   * @memberof ResponseBaseRegisterAppResponse
   */
  data?: RegisterAppResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseResolvePaymentDataResponse
 */
export interface ResponseBaseResolvePaymentDataResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseResolvePaymentDataResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseResolvePaymentDataResponse
   */
  message?: string;
  /**
   *
   * @type {ResolvePaymentDataResponse}
   * @memberof ResponseBaseResolvePaymentDataResponse
   */
  data?: ResolvePaymentDataResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseResponseData
 */
export interface ResponseBaseResponseData {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseResponseData
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseResponseData
   */
  message?: string;
  /**
   *
   * @type {object}
   * @memberof ResponseBaseResponseData
   */
  data?: object;
}
/**
 *
 * @export
 * @interface ResponseBaseResponseMessageResponse
 */
export interface ResponseBaseResponseMessageResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseResponseMessageResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseResponseMessageResponse
   */
  message?: string;
  /**
   *
   * @type {ResponseMessageResponse}
   * @memberof ResponseBaseResponseMessageResponse
   */
  data?: ResponseMessageResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseRoleGroupDetailResponse
 */
export interface ResponseBaseRoleGroupDetailResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseRoleGroupDetailResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseRoleGroupDetailResponse
   */
  message?: string;
  /**
   *
   * @type {RoleGroupDetailResponse}
   * @memberof ResponseBaseRoleGroupDetailResponse
   */
  data?: RoleGroupDetailResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseRoleGroupResponse
 */
export interface ResponseBaseRoleGroupResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseRoleGroupResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseRoleGroupResponse
   */
  message?: string;
  /**
   *
   * @type {RoleGroupResponse}
   * @memberof ResponseBaseRoleGroupResponse
   */
  data?: RoleGroupResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseSettleNapasSessionResponse
 */
export interface ResponseBaseSettleNapasSessionResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseSettleNapasSessionResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseSettleNapasSessionResponse
   */
  message?: string;
  /**
   *
   * @type {SettleNapasSessionResponse}
   * @memberof ResponseBaseSettleNapasSessionResponse
   */
  data?: SettleNapasSessionResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseSettlementDetailResponse
 */
export interface ResponseBaseSettlementDetailResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseSettlementDetailResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseSettlementDetailResponse
   */
  message?: string;
  /**
   *
   * @type {SettlementDetailResponse}
   * @memberof ResponseBaseSettlementDetailResponse
   */
  data?: SettlementDetailResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseShopSettingResponse
 */
export interface ResponseBaseShopSettingResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseShopSettingResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseShopSettingResponse
   */
  message?: string;
  /**
   *
   * @type {ShopSettingResponse}
   * @memberof ResponseBaseShopSettingResponse
   */
  data?: ShopSettingResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseSinglePayOutDetailResponse
 */
export interface ResponseBaseSinglePayOutDetailResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseSinglePayOutDetailResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseSinglePayOutDetailResponse
   */
  message?: string;
  /**
   *
   * @type {SinglePayOutDetailResponse}
   * @memberof ResponseBaseSinglePayOutDetailResponse
   */
  data?: SinglePayOutDetailResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseSinglePayOutResponse
 */
export interface ResponseBaseSinglePayOutResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseSinglePayOutResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseSinglePayOutResponse
   */
  message?: string;
  /**
   *
   * @type {SinglePayOutResponse}
   * @memberof ResponseBaseSinglePayOutResponse
   */
  data?: SinglePayOutResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseStatusFeeResponse
 */
export interface ResponseBaseStatusFeeResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseStatusFeeResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseStatusFeeResponse
   */
  message?: string;
  /**
   *
   * @type {StatusFeeResponse}
   * @memberof ResponseBaseStatusFeeResponse
   */
  data?: StatusFeeResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseStatusMerchantFeeResponse
 */
export interface ResponseBaseStatusMerchantFeeResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseStatusMerchantFeeResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseStatusMerchantFeeResponse
   */
  message?: string;
  /**
   *
   * @type {StatusMerchantFeeResponse}
   * @memberof ResponseBaseStatusMerchantFeeResponse
   */
  data?: StatusMerchantFeeResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseStatusResponse
 */
export interface ResponseBaseStatusResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseStatusResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseStatusResponse
   */
  message?: string;
  /**
   *
   * @type {StatusResponse}
   * @memberof ResponseBaseStatusResponse
   */
  data?: StatusResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseString
 */
export interface ResponseBaseString {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseString
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseString
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseString
   */
  data?: string;
}
/**
 *
 * @export
 * @interface ResponseBaseSubmitLinkAccountResponse
 */
export interface ResponseBaseSubmitLinkAccountResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseSubmitLinkAccountResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseSubmitLinkAccountResponse
   */
  message?: string;
  /**
   *
   * @type {SubmitLinkAccountResponse}
   * @memberof ResponseBaseSubmitLinkAccountResponse
   */
  data?: SubmitLinkAccountResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseSubmitProfileInfoResponse
 */
export interface ResponseBaseSubmitProfileInfoResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseSubmitProfileInfoResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseSubmitProfileInfoResponse
   */
  message?: string;
  /**
   *
   * @type {SubmitProfileInfoResponse}
   * @memberof ResponseBaseSubmitProfileInfoResponse
   */
  data?: SubmitProfileInfoResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseSupportResponse
 */
export interface ResponseBaseSupportResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseSupportResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseSupportResponse
   */
  message?: string;
  /**
   *
   * @type {SupportResponse}
   * @memberof ResponseBaseSupportResponse
   */
  data?: SupportResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseSystemParamResponse
 */
export interface ResponseBaseSystemParamResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseSystemParamResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseSystemParamResponse
   */
  message?: string;
  /**
   *
   * @type {SystemParamResponse}
   * @memberof ResponseBaseSystemParamResponse
   */
  data?: SystemParamResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseToolDepositResponse
 */
export interface ResponseBaseToolDepositResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseToolDepositResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseToolDepositResponse
   */
  message?: string;
  /**
   *
   * @type {ToolDepositResponse}
   * @memberof ResponseBaseToolDepositResponse
   */
  data?: ToolDepositResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseToolInquiryResponse
 */
export interface ResponseBaseToolInquiryResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseToolInquiryResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseToolInquiryResponse
   */
  message?: string;
  /**
   *
   * @type {ToolInquiryResponse}
   * @memberof ResponseBaseToolInquiryResponse
   */
  data?: ToolInquiryResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseToolScanQrResponse
 */
export interface ResponseBaseToolScanQrResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseToolScanQrResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseToolScanQrResponse
   */
  message?: string;
  /**
   *
   * @type {ToolScanQrResponse}
   * @memberof ResponseBaseToolScanQrResponse
   */
  data?: ToolScanQrResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseTransactionResponse
 */
export interface ResponseBaseTransactionResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseTransactionResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseTransactionResponse
   */
  message?: string;
  /**
   *
   * @type {TransactionResponse}
   * @memberof ResponseBaseTransactionResponse
   */
  data?: TransactionResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseUSeeCheckTransactionResponse
 */
export interface ResponseBaseUSeeCheckTransactionResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseUSeeCheckTransactionResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseUSeeCheckTransactionResponse
   */
  message?: string;
  /**
   *
   * @type {USeeCheckTransactionResponse}
   * @memberof ResponseBaseUSeeCheckTransactionResponse
   */
  data?: USeeCheckTransactionResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseUSeeGetDeviceResponse
 */
export interface ResponseBaseUSeeGetDeviceResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseUSeeGetDeviceResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseUSeeGetDeviceResponse
   */
  message?: string;
  /**
   *
   * @type {USeeGetDeviceResponse}
   * @memberof ResponseBaseUSeeGetDeviceResponse
   */
  data?: USeeGetDeviceResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseUSeeGetDevicesResponse
 */
export interface ResponseBaseUSeeGetDevicesResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseUSeeGetDevicesResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseUSeeGetDevicesResponse
   */
  message?: string;
  /**
   *
   * @type {USeeGetDevicesResponse}
   * @memberof ResponseBaseUSeeGetDevicesResponse
   */
  data?: USeeGetDevicesResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseUSeeGetPairingKeyResponse
 */
export interface ResponseBaseUSeeGetPairingKeyResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseUSeeGetPairingKeyResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseUSeeGetPairingKeyResponse
   */
  message?: string;
  /**
   *
   * @type {USeeGetPairingKeyResponse}
   * @memberof ResponseBaseUSeeGetPairingKeyResponse
   */
  data?: USeeGetPairingKeyResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseUSeeLinkMerchantResponse
 */
export interface ResponseBaseUSeeLinkMerchantResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseUSeeLinkMerchantResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseUSeeLinkMerchantResponse
   */
  message?: string;
  /**
   *
   * @type {USeeLinkMerchantResponse}
   * @memberof ResponseBaseUSeeLinkMerchantResponse
   */
  data?: USeeLinkMerchantResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseUmeeGetDeviceResponse
 */
export interface ResponseBaseUmeeGetDeviceResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseUmeeGetDeviceResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseUmeeGetDeviceResponse
   */
  message?: string;
  /**
   *
   * @type {UmeeGetDeviceResponse}
   * @memberof ResponseBaseUmeeGetDeviceResponse
   */
  data?: UmeeGetDeviceResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseUmeePairResponse
 */
export interface ResponseBaseUmeePairResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseUmeePairResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseUmeePairResponse
   */
  message?: string;
  /**
   *
   * @type {UmeePairResponse}
   * @memberof ResponseBaseUmeePairResponse
   */
  data?: UmeePairResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseUpdateFAQResponse
 */
export interface ResponseBaseUpdateFAQResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseUpdateFAQResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseUpdateFAQResponse
   */
  message?: string;
  /**
   *
   * @type {UpdateFAQResponse}
   * @memberof ResponseBaseUpdateFAQResponse
   */
  data?: UpdateFAQResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseUpdateMerchantResponse
 */
export interface ResponseBaseUpdateMerchantResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseUpdateMerchantResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseUpdateMerchantResponse
   */
  message?: string;
  /**
   *
   * @type {UpdateMerchantResponse}
   * @memberof ResponseBaseUpdateMerchantResponse
   */
  data?: UpdateMerchantResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseUpdateWebhookResponse
 */
export interface ResponseBaseUpdateWebhookResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseUpdateWebhookResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseUpdateWebhookResponse
   */
  message?: string;
  /**
   *
   * @type {UpdateWebhookResponse}
   * @memberof ResponseBaseUpdateWebhookResponse
   */
  data?: UpdateWebhookResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseUploadSftpFileResponse
 */
export interface ResponseBaseUploadSftpFileResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseUploadSftpFileResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseUploadSftpFileResponse
   */
  message?: string;
  /**
   *
   * @type {UploadSftpFileResponse}
   * @memberof ResponseBaseUploadSftpFileResponse
   */
  data?: UploadSftpFileResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseUserResponse
 */
export interface ResponseBaseUserResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseUserResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseUserResponse
   */
  message?: string;
  /**
   *
   * @type {UserResponse}
   * @memberof ResponseBaseUserResponse
   */
  data?: UserResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseValidNapasSessionResponse
 */
export interface ResponseBaseValidNapasSessionResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseValidNapasSessionResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseValidNapasSessionResponse
   */
  message?: string;
  /**
   *
   * @type {ValidNapasSessionResponse}
   * @memberof ResponseBaseValidNapasSessionResponse
   */
  data?: ValidNapasSessionResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseVerifyLinkAccountResponse
 */
export interface ResponseBaseVerifyLinkAccountResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseVerifyLinkAccountResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseVerifyLinkAccountResponse
   */
  message?: string;
  /**
   *
   * @type {VerifyLinkAccountResponse}
   * @memberof ResponseBaseVerifyLinkAccountResponse
   */
  data?: VerifyLinkAccountResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseVerifyOtpLinkAccountResponse
 */
export interface ResponseBaseVerifyOtpLinkAccountResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseVerifyOtpLinkAccountResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseVerifyOtpLinkAccountResponse
   */
  message?: string;
  /**
   *
   * @type {VerifyOtpLinkAccountResponse}
   * @memberof ResponseBaseVerifyOtpLinkAccountResponse
   */
  data?: VerifyOtpLinkAccountResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseVirtualAccountResponse
 */
export interface ResponseBaseVirtualAccountResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseVirtualAccountResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseVirtualAccountResponse
   */
  message?: string;
  /**
   *
   * @type {VirtualAccountResponse}
   * @memberof ResponseBaseVirtualAccountResponse
   */
  data?: VirtualAccountResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseWebhookUrlResponse
 */
export interface ResponseBaseWebhookUrlResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseWebhookUrlResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseWebhookUrlResponse
   */
  message?: string;
  /**
   *
   * @type {WebhookUrlResponse}
   * @memberof ResponseBaseWebhookUrlResponse
   */
  data?: WebhookUrlResponse;
}
/**
 *
 * @export
 * @interface ResponseMessageResponse
 */
export interface ResponseMessageResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseMessageResponse
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof ResponseMessageResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseMessageResponse
   */
  viContent?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseMessageResponse
   */
  enContent?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseMessageResponse
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseMessageResponse
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseMessageResponse
   */
  createdBy?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseMessageResponse
   */
  modifiedAt?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseMessageResponse
   */
  modifiedBy?: string;
}
/**
 *
 * @export
 * @interface RoleGroupDetailResponse
 */
export interface RoleGroupDetailResponse {
  /**
   *
   * @type {number}
   * @memberof RoleGroupDetailResponse
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof RoleGroupDetailResponse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof RoleGroupDetailResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof RoleGroupDetailResponse
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof RoleGroupDetailResponse
   */
  status?: RoleGroupDetailResponseStatusEnum;
  /**
   *
   * @type {string}
   * @memberof RoleGroupDetailResponse
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof RoleGroupDetailResponse
   */
  createdBy?: string;
  /**
   *
   * @type {string}
   * @memberof RoleGroupDetailResponse
   */
  modifiedAt?: string;
  /**
   *
   * @type {string}
   * @memberof RoleGroupDetailResponse
   */
  modifiedBy?: string;
  /**
   *
   * @type {Array<PermissionResponse>}
   * @memberof RoleGroupDetailResponse
   */
  actions?: Array<PermissionResponse>;
  /**
   *
   * @type {boolean}
   * @memberof RoleGroupDetailResponse
   */
  currentUsed?: boolean;
}

export const RoleGroupDetailResponseStatusEnum = {
  Active: "ACTIVE",
  Closed: "CLOSED",
} as const;

export type RoleGroupDetailResponseStatusEnum =
  (typeof RoleGroupDetailResponseStatusEnum)[keyof typeof RoleGroupDetailResponseStatusEnum];

/**
 *
 * @export
 * @interface RoleGroupResponse
 */
export interface RoleGroupResponse {
  /**
   *
   * @type {number}
   * @memberof RoleGroupResponse
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof RoleGroupResponse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof RoleGroupResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof RoleGroupResponse
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof RoleGroupResponse
   */
  status?: RoleGroupResponseStatusEnum;
  /**
   *
   * @type {string}
   * @memberof RoleGroupResponse
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof RoleGroupResponse
   */
  createdBy?: string;
  /**
   *
   * @type {string}
   * @memberof RoleGroupResponse
   */
  modifiedAt?: string;
  /**
   *
   * @type {string}
   * @memberof RoleGroupResponse
   */
  modifiedBy?: string;
}

export const RoleGroupResponseStatusEnum = {
  Active: "ACTIVE",
  Closed: "CLOSED",
} as const;

export type RoleGroupResponseStatusEnum =
  (typeof RoleGroupResponseStatusEnum)[keyof typeof RoleGroupResponseStatusEnum];

/**
 *
 * @export
 * @interface RootAdminLoginRequest
 */
export interface RootAdminLoginRequest {
  /**
   *
   * @type {string}
   * @memberof RootAdminLoginRequest
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof RootAdminLoginRequest
   */
  password: string;
}
/**
 *
 * @export
 * @interface SendEmailFeeReportRequest
 */
export interface SendEmailFeeReportRequest {
  /**
   *
   * @type {number}
   * @memberof SendEmailFeeReportRequest
   */
  feeReportId: number;
}
/**
 *
 * @export
 * @interface SendEmailSmsPayLinkRequest
 */
export interface SendEmailSmsPayLinkRequest {
  /**
   *
   * @type {boolean}
   * @memberof SendEmailSmsPayLinkRequest
   */
  sendEmail?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SendEmailSmsPayLinkRequest
   */
  sendSms?: boolean;
  /**
   *
   * @type {string}
   * @memberof SendEmailSmsPayLinkRequest
   */
  payLinkCode: string;
  /**
   *
   * @type {string}
   * @memberof SendEmailSmsPayLinkRequest
   */
  receiver: SendEmailSmsPayLinkRequestReceiverEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof SendEmailSmsPayLinkRequest
   */
  receiverIds?: Array<string>;
}

export const SendEmailSmsPayLinkRequestReceiverEnum = {
  Customer: "CUSTOMER",
  Admin: "ADMIN",
  User: "USER",
  CustomerGroup: "CUSTOMER_GROUP",
} as const;

export type SendEmailSmsPayLinkRequestReceiverEnum =
  (typeof SendEmailSmsPayLinkRequestReceiverEnum)[keyof typeof SendEmailSmsPayLinkRequestReceiverEnum];

/**
 *
 * @export
 * @interface SendEmailSmsPayPageRequest
 */
export interface SendEmailSmsPayPageRequest {
  /**
   *
   * @type {boolean}
   * @memberof SendEmailSmsPayPageRequest
   */
  sendEmail?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SendEmailSmsPayPageRequest
   */
  sendSms?: boolean;
  /**
   *
   * @type {string}
   * @memberof SendEmailSmsPayPageRequest
   */
  payPageCode: string;
  /**
   *
   * @type {string}
   * @memberof SendEmailSmsPayPageRequest
   */
  receiver: SendEmailSmsPayPageRequestReceiverEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof SendEmailSmsPayPageRequest
   */
  receiverIds?: Array<string>;
}

export const SendEmailSmsPayPageRequestReceiverEnum = {
  Customer: "CUSTOMER",
  Admin: "ADMIN",
  User: "USER",
  CustomerGroup: "CUSTOMER_GROUP",
} as const;

export type SendEmailSmsPayPageRequestReceiverEnum =
  (typeof SendEmailSmsPayPageRequestReceiverEnum)[keyof typeof SendEmailSmsPayPageRequestReceiverEnum];

/**
 *
 * @export
 * @interface SettleNapasSessionResponse
 */
export interface SettleNapasSessionResponse {
  /**
   *
   * @type {boolean}
   * @memberof SettleNapasSessionResponse
   */
  status?: boolean;
}
/**
 *
 * @export
 * @interface SettlementDetailResponse
 */
export interface SettlementDetailResponse {
  /**
   *
   * @type {string}
   * @memberof SettlementDetailResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof SettlementDetailResponse
   */
  merchantId?: string;
  /**
   *
   * @type {string}
   * @memberof SettlementDetailResponse
   */
  terminalId?: string;
  /**
   *
   * @type {number}
   * @memberof SettlementDetailResponse
   */
  amount?: number;
  /**
   *
   * @type {number}
   * @memberof SettlementDetailResponse
   */
  numberTransaction?: number;
  /**
   *
   * @type {string}
   * @memberof SettlementDetailResponse
   */
  status?: SettlementDetailResponseStatusEnum;
  /**
   *
   * @type {PageResponseTransactionInSettlementDetail}
   * @memberof SettlementDetailResponse
   */
  pageTransaction?: PageResponseTransactionInSettlementDetail;
}

export const SettlementDetailResponseStatusEnum = {
  Success: "SUCCESS",
  Fail: "FAIL",
  CutOver: "CUT_OVER",
} as const;

export type SettlementDetailResponseStatusEnum =
  (typeof SettlementDetailResponseStatusEnum)[keyof typeof SettlementDetailResponseStatusEnum];

/**
 *
 * @export
 * @interface ShopSettingResponse
 */
export interface ShopSettingResponse {
  /**
   *
   * @type {string}
   * @memberof ShopSettingResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ShopSettingResponse
   */
  logo?: string;
  /**
   *
   * @type {string}
   * @memberof ShopSettingResponse
   */
  shopName?: string;
  /**
   *
   * @type {string}
   * @memberof ShopSettingResponse
   */
  backgroundColor?: string;
  /**
   *
   * @type {string}
   * @memberof ShopSettingResponse
   */
  defaultLanguage?: ShopSettingResponseDefaultLanguageEnum;
  /**
   *
   * @type {Array<PaymentMethodInfo>}
   * @memberof ShopSettingResponse
   */
  payments?: Array<PaymentMethodInfo>;
}

export const ShopSettingResponseDefaultLanguageEnum = {
  En: "EN",
  Vi: "VI",
} as const;

export type ShopSettingResponseDefaultLanguageEnum =
  (typeof ShopSettingResponseDefaultLanguageEnum)[keyof typeof ShopSettingResponseDefaultLanguageEnum];

/**
 *
 * @export
 * @interface SinglePayOutDetailResponse
 */
export interface SinglePayOutDetailResponse {
  /**
   *
   * @type {string}
   * @memberof SinglePayOutDetailResponse
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof SinglePayOutDetailResponse
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof SinglePayOutDetailResponse
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof SinglePayOutDetailResponse
   */
  type?: SinglePayOutDetailResponseTypeEnum;
  /**
   *
   * @type {string}
   * @memberof SinglePayOutDetailResponse
   */
  status?: SinglePayOutDetailResponseStatusEnum;
  /**
   *
   * @type {string}
   * @memberof SinglePayOutDetailResponse
   */
  fromBranch?: SinglePayOutDetailResponseFromBranchEnum;
  /**
   *
   * @type {string}
   * @memberof SinglePayOutDetailResponse
   */
  fromAccountNo?: string;
  /**
   *
   * @type {string}
   * @memberof SinglePayOutDetailResponse
   */
  fromAccountName?: string;
  /**
   *
   * @type {string}
   * @memberof SinglePayOutDetailResponse
   */
  toBankBin?: string;
  /**
   *
   * @type {string}
   * @memberof SinglePayOutDetailResponse
   */
  toBankLogo?: string;
  /**
   *
   * @type {string}
   * @memberof SinglePayOutDetailResponse
   */
  toBankName?: string;
  /**
   *
   * @type {string}
   * @memberof SinglePayOutDetailResponse
   */
  toAccountNo?: string;
  /**
   *
   * @type {string}
   * @memberof SinglePayOutDetailResponse
   */
  toAccountName?: string;
  /**
   *
   * @type {string}
   * @memberof SinglePayOutDetailResponse
   */
  denyContent?: string;
  /**
   *
   * @type {string}
   * @memberof SinglePayOutDetailResponse
   */
  txnNumber?: string;
  /**
   *
   * @type {string}
   * @memberof SinglePayOutDetailResponse
   */
  transactionTime?: string;
  /**
   *
   * @type {TransactionResponse}
   * @memberof SinglePayOutDetailResponse
   */
  transaction?: TransactionResponse;
}

export const SinglePayOutDetailResponseTypeEnum = {
  Single: "SINGLE",
  Batch: "BATCH",
} as const;

export type SinglePayOutDetailResponseTypeEnum =
  (typeof SinglePayOutDetailResponseTypeEnum)[keyof typeof SinglePayOutDetailResponseTypeEnum];
export const SinglePayOutDetailResponseStatusEnum = {
  Waiting: "WAITING",
  Success: "SUCCESS",
  Failed: "FAILED",
  Transferring: "TRANSFERRING",
  Denied: "DENIED",
} as const;

export type SinglePayOutDetailResponseStatusEnum =
  (typeof SinglePayOutDetailResponseStatusEnum)[keyof typeof SinglePayOutDetailResponseStatusEnum];
export const SinglePayOutDetailResponseFromBranchEnum = {
  Klb: "KLB",
  Umee: "UMEE",
} as const;

export type SinglePayOutDetailResponseFromBranchEnum =
  (typeof SinglePayOutDetailResponseFromBranchEnum)[keyof typeof SinglePayOutDetailResponseFromBranchEnum];

/**
 *
 * @export
 * @interface SinglePayOutResponse
 */
export interface SinglePayOutResponse {
  /**
   *
   * @type {string}
   * @memberof SinglePayOutResponse
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof SinglePayOutResponse
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof SinglePayOutResponse
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof SinglePayOutResponse
   */
  type?: SinglePayOutResponseTypeEnum;
  /**
   *
   * @type {string}
   * @memberof SinglePayOutResponse
   */
  status?: SinglePayOutResponseStatusEnum;
  /**
   *
   * @type {string}
   * @memberof SinglePayOutResponse
   */
  fromBranch?: SinglePayOutResponseFromBranchEnum;
  /**
   *
   * @type {string}
   * @memberof SinglePayOutResponse
   */
  fromAccountNo?: string;
  /**
   *
   * @type {string}
   * @memberof SinglePayOutResponse
   */
  fromAccountName?: string;
  /**
   *
   * @type {string}
   * @memberof SinglePayOutResponse
   */
  toBankBin?: string;
  /**
   *
   * @type {string}
   * @memberof SinglePayOutResponse
   */
  toBankLogo?: string;
  /**
   *
   * @type {string}
   * @memberof SinglePayOutResponse
   */
  toBankName?: string;
  /**
   *
   * @type {string}
   * @memberof SinglePayOutResponse
   */
  toAccountNo?: string;
  /**
   *
   * @type {string}
   * @memberof SinglePayOutResponse
   */
  toAccountName?: string;
  /**
   *
   * @type {string}
   * @memberof SinglePayOutResponse
   */
  denyContent?: string;
  /**
   *
   * @type {string}
   * @memberof SinglePayOutResponse
   */
  txnNumber?: string;
  /**
   *
   * @type {string}
   * @memberof SinglePayOutResponse
   */
  transactionTime?: string;
}

export const SinglePayOutResponseTypeEnum = {
  Single: "SINGLE",
  Batch: "BATCH",
} as const;

export type SinglePayOutResponseTypeEnum =
  (typeof SinglePayOutResponseTypeEnum)[keyof typeof SinglePayOutResponseTypeEnum];
export const SinglePayOutResponseStatusEnum = {
  Waiting: "WAITING",
  Success: "SUCCESS",
  Failed: "FAILED",
  Transferring: "TRANSFERRING",
  Denied: "DENIED",
} as const;

export type SinglePayOutResponseStatusEnum =
  (typeof SinglePayOutResponseStatusEnum)[keyof typeof SinglePayOutResponseStatusEnum];
export const SinglePayOutResponseFromBranchEnum = {
  Klb: "KLB",
  Umee: "UMEE",
} as const;

export type SinglePayOutResponseFromBranchEnum =
  (typeof SinglePayOutResponseFromBranchEnum)[keyof typeof SinglePayOutResponseFromBranchEnum];

/**
 *
 * @export
 * @interface StatusFeeResponse
 */
export interface StatusFeeResponse {
  /**
   *
   * @type {boolean}
   * @memberof StatusFeeResponse
   */
  status?: boolean;
  /**
   *
   * @type {FeeDto}
   * @memberof StatusFeeResponse
   */
  fee?: FeeDto;
}
/**
 *
 * @export
 * @interface StatusMerchantFeeResponse
 */
export interface StatusMerchantFeeResponse {
  /**
   *
   * @type {boolean}
   * @memberof StatusMerchantFeeResponse
   */
  status?: boolean;
  /**
   *
   * @type {MerchantFeeDto}
   * @memberof StatusMerchantFeeResponse
   */
  merchantFeeDto?: MerchantFeeDto;
}
/**
 *
 * @export
 * @interface StatusResponse
 */
export interface StatusResponse {
  /**
   *
   * @type {boolean}
   * @memberof StatusResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface SubmitLinkAccountRequest
 */
export interface SubmitLinkAccountRequest {
  /**
   *
   * @type {string}
   * @memberof SubmitLinkAccountRequest
   */
  idCardNo: string;
  /**
   *
   * @type {string}
   * @memberof SubmitLinkAccountRequest
   */
  accountNo: string;
  /**
   *
   * @type {string}
   * @memberof SubmitLinkAccountRequest
   */
  branch: SubmitLinkAccountRequestBranchEnum;
  /**
   *
   * @type {string}
   * @memberof SubmitLinkAccountRequest
   */
  accountName: string;
}

export const SubmitLinkAccountRequestBranchEnum = {
  Klb: "KLB",
  Umee: "UMEE",
} as const;

export type SubmitLinkAccountRequestBranchEnum =
  (typeof SubmitLinkAccountRequestBranchEnum)[keyof typeof SubmitLinkAccountRequestBranchEnum];

/**
 *
 * @export
 * @interface SubmitLinkAccountResponse
 */
export interface SubmitLinkAccountResponse {
  /**
   *
   * @type {string}
   * @memberof SubmitLinkAccountResponse
   */
  sessionId?: string;
  /**
   *
   * @type {string}
   * @memberof SubmitLinkAccountResponse
   */
  phone?: string;
}
/**
 *
 * @export
 * @interface SubmitMerchantInfoRequest
 */
export interface SubmitMerchantInfoRequest {
  /**
   *
   * @type {string}
   * @memberof SubmitMerchantInfoRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof SubmitMerchantInfoRequest
   */
  businessType: SubmitMerchantInfoRequestBusinessTypeEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof SubmitMerchantInfoRequest
   */
  businessProducts?: Array<SubmitMerchantInfoRequestBusinessProductsEnum>;
  /**
   *
   * @type {string}
   * @memberof SubmitMerchantInfoRequest
   */
  product: string;
  /**
   *
   * @type {number}
   * @memberof SubmitMerchantInfoRequest
   */
  maxPrice: number;
  /**
   *
   * @type {string}
   * @memberof SubmitMerchantInfoRequest
   */
  revenueType: SubmitMerchantInfoRequestRevenueTypeEnum;
  /**
   *
   * @type {string}
   * @memberof SubmitMerchantInfoRequest
   */
  website?: string;
  /**
   *
   * @type {string}
   * @memberof SubmitMerchantInfoRequest
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof SubmitMerchantInfoRequest
   */
  email: string;
  /**
   *
   * @type {PersonalMerchantDto}
   * @memberof SubmitMerchantInfoRequest
   */
  personalMerchant?: PersonalMerchantDto;
  /**
   *
   * @type {EnterpriseMerchantDto}
   * @memberof SubmitMerchantInfoRequest
   */
  enterpriseMerchant?: EnterpriseMerchantDto;
}

export const SubmitMerchantInfoRequestBusinessTypeEnum = {
  Personal: "PERSONAL",
  Organization: "ORGANIZATION",
} as const;

export type SubmitMerchantInfoRequestBusinessTypeEnum =
  (typeof SubmitMerchantInfoRequestBusinessTypeEnum)[keyof typeof SubmitMerchantInfoRequestBusinessTypeEnum];
export const SubmitMerchantInfoRequestBusinessProductsEnum = {
  VehicleSale: "VEHICLE_SALE",
  Logistics: "LOGISTICS",
  ResidentAndFoodService: "RESIDENT_AND_FOOD_SERVICE",
  InformationAndMedia: "INFORMATION_AND_MEDIA",
  FinanceAndInsurance: "FINANCE_AND_INSURANCE",
  RealEstate: "REAL_ESTATE",
  AdministrativeAndSupportService: "ADMINISTRATIVE_AND_SUPPORT_SERVICE",
  Education: "EDUCATION",
  PublicHealth: "PUBLIC_HEALTH",
  HealthCareAndBeauty: "HEALTH_CARE_AND_BEAUTY",
  ArtAndEntertainment: "ART_AND_ENTERTAINMENT",
  Other: "OTHER",
} as const;

export type SubmitMerchantInfoRequestBusinessProductsEnum =
  (typeof SubmitMerchantInfoRequestBusinessProductsEnum)[keyof typeof SubmitMerchantInfoRequestBusinessProductsEnum];
export const SubmitMerchantInfoRequestRevenueTypeEnum = {
  _1: "REVENUE_TYPE_1",
  _2: "REVENUE_TYPE_2",
  _3: "REVENUE_TYPE_3",
  _4: "REVENUE_TYPE_4",
} as const;

export type SubmitMerchantInfoRequestRevenueTypeEnum =
  (typeof SubmitMerchantInfoRequestRevenueTypeEnum)[keyof typeof SubmitMerchantInfoRequestRevenueTypeEnum];

/**
 *
 * @export
 * @interface SubmitProfileInfoResponse
 */
export interface SubmitProfileInfoResponse {
  /**
   *
   * @type {MerchantDto}
   * @memberof SubmitProfileInfoResponse
   */
  merchant?: MerchantDto;
}
/**
 *
 * @export
 * @interface SupportResponse
 */
export interface SupportResponse {
  /**
   *
   * @type {string}
   * @memberof SupportResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof SupportResponse
   */
  username?: string;
  /**
   *
   * @type {string}
   * @memberof SupportResponse
   */
  phoneNo?: string;
  /**
   *
   * @type {string}
   * @memberof SupportResponse
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof SupportResponse
   */
  content?: SupportResponseContentEnum;
  /**
   *
   * @type {string}
   * @memberof SupportResponse
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof SupportResponse
   */
  merchantId?: string;
  /**
   *
   * @type {string}
   * @memberof SupportResponse
   */
  createdBy?: string;
  /**
   *
   * @type {string}
   * @memberof SupportResponse
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof SupportResponse
   */
  modifiedBy?: string;
  /**
   *
   * @type {string}
   * @memberof SupportResponse
   */
  modifiedAt?: string;
}

export const SupportResponseContentEnum = {
  _1: "CONTENT_1",
  _2: "CONTENT_2",
  _3: "CONTENT_3",
} as const;

export type SupportResponseContentEnum =
  (typeof SupportResponseContentEnum)[keyof typeof SupportResponseContentEnum];

/**
 *
 * @export
 * @interface SystemParamResponse
 */
export interface SystemParamResponse {
  /**
   *
   * @type {number}
   * @memberof SystemParamResponse
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof SystemParamResponse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof SystemParamResponse
   */
  value?: string;
  /**
   *
   * @type {string}
   * @memberof SystemParamResponse
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof SystemParamResponse
   */
  dataType?: SystemParamResponseDataTypeEnum;
  /**
   *
   * @type {string}
   * @memberof SystemParamResponse
   */
  group?: SystemParamResponseGroupEnum;
  /**
   *
   * @type {string}
   * @memberof SystemParamResponse
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof SystemParamResponse
   */
  createBy?: string;
  /**
   *
   * @type {string}
   * @memberof SystemParamResponse
   */
  modifiedAt?: string;
  /**
   *
   * @type {string}
   * @memberof SystemParamResponse
   */
  modifiedBy?: string;
}

export const SystemParamResponseDataTypeEnum = {
  String: "STRING",
  Number: "NUMBER",
} as const;

export type SystemParamResponseDataTypeEnum =
  (typeof SystemParamResponseDataTypeEnum)[keyof typeof SystemParamResponseDataTypeEnum];
export const SystemParamResponseGroupEnum = {
  System: "PARAMETER_SYSTEM",
  Connection: "PARAMETER_CONNECTION",
  Paybox: "PARAMETER_PAYBOX",
  Contact: "PARAMETER_CONTACT",
} as const;

export type SystemParamResponseGroupEnum =
  (typeof SystemParamResponseGroupEnum)[keyof typeof SystemParamResponseGroupEnum];

/**
 *
 * @export
 * @interface ToolDepositRequest
 */
export interface ToolDepositRequest {
  /**
   *
   * @type {string}
   * @memberof ToolDepositRequest
   */
  accountNo: string;
  /**
   *
   * @type {string}
   * @memberof ToolDepositRequest
   */
  branch: ToolDepositRequestBranchEnum;
  /**
   *
   * @type {number}
   * @memberof ToolDepositRequest
   */
  amount: number;
  /**
   *
   * @type {string}
   * @memberof ToolDepositRequest
   */
  content: string;
}

export const ToolDepositRequestBranchEnum = {
  Klb: "KLB",
  Umee: "UMEE",
} as const;

export type ToolDepositRequestBranchEnum =
  (typeof ToolDepositRequestBranchEnum)[keyof typeof ToolDepositRequestBranchEnum];

/**
 *
 * @export
 * @interface ToolDepositResponse
 */
export interface ToolDepositResponse {
  /**
   *
   * @type {string}
   * @memberof ToolDepositResponse
   */
  transactionId?: string;
  /**
   *
   * @type {boolean}
   * @memberof ToolDepositResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface ToolInquiryRequest
 */
export interface ToolInquiryRequest {
  /**
   *
   * @type {string}
   * @memberof ToolInquiryRequest
   */
  accountNo: string;
  /**
   *
   * @type {string}
   * @memberof ToolInquiryRequest
   */
  branch: ToolInquiryRequestBranchEnum;
}

export const ToolInquiryRequestBranchEnum = {
  Klb: "KLB",
  Umee: "UMEE",
} as const;

export type ToolInquiryRequestBranchEnum =
  (typeof ToolInquiryRequestBranchEnum)[keyof typeof ToolInquiryRequestBranchEnum];

/**
 *
 * @export
 * @interface ToolInquiryResponse
 */
export interface ToolInquiryResponse {
  /**
   *
   * @type {string}
   * @memberof ToolInquiryResponse
   */
  displayName?: string;
}
/**
 *
 * @export
 * @interface ToolScanQrResponse
 */
export interface ToolScanQrResponse {
  /**
   *
   * @type {string}
   * @memberof ToolScanQrResponse
   */
  accountNo?: string;
  /**
   *
   * @type {string}
   * @memberof ToolScanQrResponse
   */
  branch?: ToolScanQrResponseBranchEnum;
  /**
   *
   * @type {number}
   * @memberof ToolScanQrResponse
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof ToolScanQrResponse
   */
  content?: string;
}

export const ToolScanQrResponseBranchEnum = {
  Klb: "KLB",
  Umee: "UMEE",
} as const;

export type ToolScanQrResponseBranchEnum =
  (typeof ToolScanQrResponseBranchEnum)[keyof typeof ToolScanQrResponseBranchEnum];

/**
 *
 * @export
 * @interface TransactionDetailResponse
 */
export interface TransactionDetailResponse {
  /**
   *
   * @type {string}
   * @memberof TransactionDetailResponse
   */
  transactionNo?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionDetailResponse
   */
  dateTime?: string;
  /**
   *
   * @type {number}
   * @memberof TransactionDetailResponse
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof TransactionDetailResponse
   */
  transGroup?: string;
  /**
   *
   * @type {number}
   * @memberof TransactionDetailResponse
   */
  coefficient?: number;
  /**
   *
   * @type {string}
   * @memberof TransactionDetailResponse
   */
  detail?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionDetailResponse
   */
  currency?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionDetailResponse
   */
  dateTimeTypeLocalDateTime?: string;
}
/**
 *
 * @export
 * @interface TransactionInSettlementDetail
 */
export interface TransactionInSettlementDetail {
  /**
   *
   * @type {string}
   * @memberof TransactionInSettlementDetail
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionInSettlementDetail
   */
  deviceId?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionInSettlementDetail
   */
  terminalId?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionInSettlementDetail
   */
  cardNumber?: string;
  /**
   *
   * @type {number}
   * @memberof TransactionInSettlementDetail
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof TransactionInSettlementDetail
   */
  approveCode?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionInSettlementDetail
   */
  refNumber?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionInSettlementDetail
   */
  applicationLabel?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionInSettlementDetail
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionInSettlementDetail
   */
  status?: TransactionInSettlementDetailStatusEnum;
  /**
   *
   * @type {string}
   * @memberof TransactionInSettlementDetail
   */
  cardOwner?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionInSettlementDetail
   */
  nameStaff?: string;
  /**
   *
   * @type {boolean}
   * @memberof TransactionInSettlementDetail
   */
  finish?: boolean;
}

export const TransactionInSettlementDetailStatusEnum = {
  Success: "SUCCESS",
  Failed: "FAILED",
  Timeout: "TIMEOUT",
  Cancel: "CANCEL",
  Refunded: "REFUNDED",
  Created: "CREATED",
} as const;

export type TransactionInSettlementDetailStatusEnum =
  (typeof TransactionInSettlementDetailStatusEnum)[keyof typeof TransactionInSettlementDetailStatusEnum];

/**
 *
 * @export
 * @interface TransactionResponse
 */
export interface TransactionResponse {
  /**
   *
   * @type {string}
   * @memberof TransactionResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionResponse
   */
  status?: TransactionResponseStatusEnum;
  /**
   *
   * @type {string}
   * @memberof TransactionResponse
   */
  statusVi?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionResponse
   */
  statusViPortal?: string;
  /**
   *
   * @type {number}
   * @memberof TransactionResponse
   */
  amount?: number;
  /**
   *
   * @type {number}
   * @memberof TransactionResponse
   */
  chargeAmount?: number;
  /**
   *
   * @type {number}
   * @memberof TransactionResponse
   */
  fee?: number;
  /**
   *
   * @type {number}
   * @memberof TransactionResponse
   */
  vatChargeAmount?: number;
  /**
   *
   * @type {number}
   * @memberof TransactionResponse
   */
  actualAmount?: number;
  /**
   *
   * @type {string}
   * @memberof TransactionResponse
   */
  refTransactionId?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionResponse
   */
  createDateTime?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionResponse
   */
  depositTime?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionResponse
   */
  completeTime?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionResponse
   */
  virtualAccount?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionResponse
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionResponse
   */
  paymentType?: TransactionResponsePaymentTypeEnum;
  /**
   *
   * @type {string}
   * @memberof TransactionResponse
   */
  bankType?: TransactionResponseBankTypeEnum;
  /**
   *
   * @type {string}
   * @memberof TransactionResponse
   */
  successUrl?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionResponse
   */
  failUrl?: string;
  /**
   *
   * @type {number}
   * @memberof TransactionResponse
   */
  callbackAfter?: number;
  /**
   *
   * @type {string}
   * @memberof TransactionResponse
   */
  merchantId?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionResponse
   */
  merchantCode?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionResponse
   */
  merchantName?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionResponse
   */
  customerName?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionResponse
   */
  customerPhone?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionResponse
   */
  customerEmail?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionResponse
   */
  customerAddress?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionResponse
   */
  payLinkCode?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionResponse
   */
  payPageCode?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionResponse
   */
  payboxTransactionType?: TransactionResponsePayboxTransactionTypeEnum;
  /**
   *
   * @type {string}
   * @memberof TransactionResponse
   */
  txnNumber?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionResponse
   */
  accountName?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionResponse
   */
  accountNo?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionResponse
   */
  interBankTrace?: string;
  /**
   *
   * @type {boolean}
   * @memberof TransactionResponse
   */
  forControl?: boolean;
}

export const TransactionResponseStatusEnum = {
  None: "NONE",
  Created: "CREATED",
  Success: "SUCCESS",
  Canceled: "CANCELED",
  Fail: "FAIL",
  Timeout: "TIMEOUT",
  Pending: "PENDING",
} as const;

export type TransactionResponseStatusEnum =
  (typeof TransactionResponseStatusEnum)[keyof typeof TransactionResponseStatusEnum];
export const TransactionResponsePaymentTypeEnum = {
  VietQr: "VIET_QR",
  AtmCard: "ATM_CARD",
  Banking: "BANKING",
} as const;

export type TransactionResponsePaymentTypeEnum =
  (typeof TransactionResponsePaymentTypeEnum)[keyof typeof TransactionResponsePaymentTypeEnum];
export const TransactionResponseBankTypeEnum = {
  Klb: "KLB",
  Umee: "UMEE",
} as const;

export type TransactionResponseBankTypeEnum =
  (typeof TransactionResponseBankTypeEnum)[keyof typeof TransactionResponseBankTypeEnum];
export const TransactionResponsePayboxTransactionTypeEnum = {
  Umee: "UMEE",
  Paybox: "PAYBOX",
  Pos: "POS",
  Usee: "USEE",
} as const;

export type TransactionResponsePayboxTransactionTypeEnum =
  (typeof TransactionResponsePayboxTransactionTypeEnum)[keyof typeof TransactionResponsePayboxTransactionTypeEnum];

/**
 *
 * @export
 * @interface USeeCancelTransactionRequest
 */
export interface USeeCancelTransactionRequest {
  /**
   *
   * @type {string}
   * @memberof USeeCancelTransactionRequest
   */
  transactionId: string;
  /**
   *
   * @type {string}
   * @memberof USeeCancelTransactionRequest
   */
  reason?: string;
}
/**
 *
 * @export
 * @interface USeeCheckTransactionResponse
 */
export interface USeeCheckTransactionResponse {
  /**
   *
   * @type {string}
   * @memberof USeeCheckTransactionResponse
   */
  transactionId?: string;
  /**
   *
   * @type {string}
   * @memberof USeeCheckTransactionResponse
   */
  status?: USeeCheckTransactionResponseStatusEnum;
}

export const USeeCheckTransactionResponseStatusEnum = {
  None: "NONE",
  Created: "CREATED",
  Success: "SUCCESS",
  Canceled: "CANCELED",
  Fail: "FAIL",
  Timeout: "TIMEOUT",
  Pending: "PENDING",
} as const;

export type USeeCheckTransactionResponseStatusEnum =
  (typeof USeeCheckTransactionResponseStatusEnum)[keyof typeof USeeCheckTransactionResponseStatusEnum];

/**
 *
 * @export
 * @interface USeeCreateTransactionRequest
 */
export interface USeeCreateTransactionRequest {
  /**
   *
   * @type {string}
   * @memberof USeeCreateTransactionRequest
   */
  deviceId?: string;
  /**
   *
   * @type {number}
   * @memberof USeeCreateTransactionRequest
   */
  amount: number;
  /**
   *
   * @type {string}
   * @memberof USeeCreateTransactionRequest
   */
  content?: string;
  /**
   *
   * @type {number}
   * @memberof USeeCreateTransactionRequest
   */
  timeout?: number;
  /**
   *
   * @type {string}
   * @memberof USeeCreateTransactionRequest
   */
  successCallBack?: string;
  /**
   *
   * @type {string}
   * @memberof USeeCreateTransactionRequest
   */
  failCallBack?: string;
}
/**
 *
 * @export
 * @interface USeeGetDeviceResponse
 */
export interface USeeGetDeviceResponse {
  /**
   *
   * @type {PayboxDeviceResponse}
   * @memberof USeeGetDeviceResponse
   */
  device?: PayboxDeviceResponse;
}
/**
 *
 * @export
 * @interface USeeGetDevicesResponse
 */
export interface USeeGetDevicesResponse {
  /**
   *
   * @type {Array<PayboxDeviceResponse>}
   * @memberof USeeGetDevicesResponse
   */
  devices?: Array<PayboxDeviceResponse>;
}
/**
 *
 * @export
 * @interface USeeGetPairingKeyResponse
 */
export interface USeeGetPairingKeyResponse {
  /**
   *
   * @type {string}
   * @memberof USeeGetPairingKeyResponse
   */
  pairingKey?: string;
}
/**
 *
 * @export
 * @interface USeeLinkMerchantResponse
 */
export interface USeeLinkMerchantResponse {
  /**
   *
   * @type {string}
   * @memberof USeeLinkMerchantResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof USeeLinkMerchantResponse
   */
  sessionId?: string;
}
/**
 *
 * @export
 * @interface UmeeGetDeviceRequest
 */
export interface UmeeGetDeviceRequest {
  /**
   *
   * @type {string}
   * @memberof UmeeGetDeviceRequest
   */
  deviceId: string;
  /**
   *
   * @type {string}
   * @memberof UmeeGetDeviceRequest
   */
  accessKey: string;
}
/**
 *
 * @export
 * @interface UmeeGetDeviceResponse
 */
export interface UmeeGetDeviceResponse {
  /**
   *
   * @type {PayboxDeviceResponse}
   * @memberof UmeeGetDeviceResponse
   */
  deviceResponse?: PayboxDeviceResponse;
}
/**
 *
 * @export
 * @interface UmeeNotifyRequest
 */
export interface UmeeNotifyRequest {
  /**
   *
   * @type {string}
   * @memberof UmeeNotifyRequest
   */
  deviceId: string;
  /**
   *
   * @type {string}
   * @memberof UmeeNotifyRequest
   */
  accessKey: string;
  /**
   *
   * @type {number}
   * @memberof UmeeNotifyRequest
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof UmeeNotifyRequest
   */
  content?: string;
  /**
   *
   * @type {boolean}
   * @memberof UmeeNotifyRequest
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface UmeePairRequest
 */
export interface UmeePairRequest {
  /**
   *
   * @type {string}
   * @memberof UmeePairRequest
   */
  deviceId: string;
  /**
   *
   * @type {string}
   * @memberof UmeePairRequest
   */
  pairingKey: string;
  /**
   *
   * @type {string}
   * @memberof UmeePairRequest
   */
  refUserId?: string;
  /**
   *
   * @type {string}
   * @memberof UmeePairRequest
   */
  shopName?: string;
  /**
   *
   * @type {string}
   * @memberof UmeePairRequest
   */
  targetAccount?: string;
  /**
   *
   * @type {string}
   * @memberof UmeePairRequest
   */
  targetAccountName?: string;
}
/**
 *
 * @export
 * @interface UmeePairResponse
 */
export interface UmeePairResponse {
  /**
   *
   * @type {string}
   * @memberof UmeePairResponse
   */
  umeeAccessKey?: string;
}
/**
 *
 * @export
 * @interface UmeeShowTransactionRequest
 */
export interface UmeeShowTransactionRequest {
  /**
   *
   * @type {string}
   * @memberof UmeeShowTransactionRequest
   */
  deviceId?: string;
  /**
   *
   * @type {string}
   * @memberof UmeeShowTransactionRequest
   */
  accessKey?: string;
  /**
   *
   * @type {number}
   * @memberof UmeeShowTransactionRequest
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof UmeeShowTransactionRequest
   */
  bin?: string;
  /**
   *
   * @type {string}
   * @memberof UmeeShowTransactionRequest
   */
  targetAccount?: string;
  /**
   *
   * @type {string}
   * @memberof UmeeShowTransactionRequest
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof UmeeShowTransactionRequest
   */
  qrContent?: string;
  /**
   *
   * @type {string}
   * @memberof UmeeShowTransactionRequest
   */
  accountName?: string;
}
/**
 *
 * @export
 * @interface UmeeUnpairRequest
 */
export interface UmeeUnpairRequest {
  /**
   *
   * @type {string}
   * @memberof UmeeUnpairRequest
   */
  deviceId: string;
  /**
   *
   * @type {string}
   * @memberof UmeeUnpairRequest
   */
  accessKey: string;
}
/**
 *
 * @export
 * @interface UpdateAdminProfileRequest
 */
export interface UpdateAdminProfileRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateAdminProfileRequest
   */
  avatarUrl: string;
}
/**
 *
 * @export
 * @interface UpdateAdminRequest
 */
export interface UpdateAdminRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateAdminRequest
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateAdminRequest
   */
  fullName?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateAdminRequest
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateAdminRequest
   */
  staffId?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateAdminRequest
   */
  office?: UpdateAdminRequestOfficeEnum;
  /**
   *
   * @type {string}
   * @memberof UpdateAdminRequest
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof UpdateAdminRequest
   */
  roleGroupId: number;
}

export const UpdateAdminRequestOfficeEnum = {
  Headquarter: "HEADQUARTER",
  Branch: "BRANCH",
  TransactionOffice: "TRANSACTION_OFFICE",
} as const;

export type UpdateAdminRequestOfficeEnum =
  (typeof UpdateAdminRequestOfficeEnum)[keyof typeof UpdateAdminRequestOfficeEnum];

/**
 *
 * @export
 * @interface UpdateBankRequest
 */
export interface UpdateBankRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateBankRequest
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateBankRequest
   */
  shortName?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateBankRequest
   */
  commonName?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateBankRequest
   */
  citad?: string;
  /**
   *
   * @type {boolean}
   * @memberof UpdateBankRequest
   */
  napasSupported?: boolean;
  /**
   *
   * @type {string}
   * @memberof UpdateBankRequest
   */
  napasCode?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateBankRequest
   */
  benId?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateBankRequest
   */
  swift?: string;
  /**
   *
   * @type {boolean}
   * @memberof UpdateBankRequest
   */
  vietQrSupported?: boolean;
  /**
   *
   * @type {string}
   * @memberof UpdateBankRequest
   */
  logo?: string;
}
/**
 *
 * @export
 * @interface UpdateCategoryRequest
 */
export interface UpdateCategoryRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateCategoryRequest
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCategoryRequest
   */
  description?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof UpdateCategoryRequest
   */
  products?: Array<number>;
}
/**
 *
 * @export
 * @interface UpdateContactEmailRequest
 */
export interface UpdateContactEmailRequest {
  /**
   *
   * @type {boolean}
   * @memberof UpdateContactEmailRequest
   */
  allowEmail: boolean;
  /**
   *
   * @type {string}
   * @memberof UpdateContactEmailRequest
   */
  email: string;
}
/**
 *
 * @export
 * @interface UpdateCustomerGroupRequest
 */
export interface UpdateCustomerGroupRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateCustomerGroupRequest
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCustomerGroupRequest
   */
  name?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateCustomerGroupRequest
   */
  customerIds?: Array<string>;
}
/**
 *
 * @export
 * @interface UpdateCustomerInfoRequest
 */
export interface UpdateCustomerInfoRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateCustomerInfoRequest
   */
  payLinkCode: string;
  /**
   *
   * @type {CustomerFillDto}
   * @memberof UpdateCustomerInfoRequest
   */
  customer: CustomerFillDto;
}
/**
 *
 * @export
 * @interface UpdateCustomerRequest
 */
export interface UpdateCustomerRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateCustomerRequest
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCustomerRequest
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCustomerRequest
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCustomerRequest
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCustomerRequest
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCustomerRequest
   */
  groupId?: string;
}
/**
 *
 * @export
 * @interface UpdateDefaultBankAccountRequest
 */
export interface UpdateDefaultBankAccountRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateDefaultBankAccountRequest
   */
  bankAccountId?: string;
}
/**
 *
 * @export
 * @interface UpdateFAQRequest
 */
export interface UpdateFAQRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateFAQRequest
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UpdateFAQRequest
   */
  question: string;
  /**
   *
   * @type {string}
   * @memberof UpdateFAQRequest
   */
  answer: string;
}
/**
 *
 * @export
 * @interface UpdateFAQResponse
 */
export interface UpdateFAQResponse {
  /**
   *
   * @type {string}
   * @memberof UpdateFAQResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateFAQResponse
   */
  question?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateFAQResponse
   */
  answer?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateFAQResponse
   */
  modifiedBy?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateFAQResponse
   */
  modifiedAt?: string;
}
/**
 *
 * @export
 * @interface UpdateFeeRequest
 */
export interface UpdateFeeRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateFeeRequest
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UpdateFeeRequest
   */
  feeName: string;
  /**
   *
   * @type {number}
   * @memberof UpdateFeeRequest
   */
  percentVAT?: number;
  /**
   *
   * @type {string}
   * @memberof UpdateFeeRequest
   */
  businessType?: UpdateFeeRequestBusinessTypeEnum;
  /**
   *
   * @type {string}
   * @memberof UpdateFeeRequest
   */
  serviceType: UpdateFeeRequestServiceTypeEnum;
  /**
   *
   * @type {string}
   * @memberof UpdateFeeRequest
   */
  feeMethod: UpdateFeeRequestFeeMethodEnum;
  /**
   *
   * @type {string}
   * @memberof UpdateFeeRequest
   */
  calculatePer?: UpdateFeeRequestCalculatePerEnum;
  /**
   *
   * @type {string}
   * @memberof UpdateFeeRequest
   */
  fixedFeeMethod?: UpdateFeeRequestFixedFeeMethodEnum;
  /**
   *
   * @type {number}
   * @memberof UpdateFeeRequest
   */
  amount?: number;
  /**
   *
   * @type {number}
   * @memberof UpdateFeeRequest
   */
  minimumMoney?: number;
  /**
   *
   * @type {number}
   * @memberof UpdateFeeRequest
   */
  maxMoney?: number;
  /**
   *
   * @type {Array<BracketFee>}
   * @memberof UpdateFeeRequest
   */
  bracketFees?: Array<BracketFee>;
}

export const UpdateFeeRequestBusinessTypeEnum = {
  Personal: "PERSONAL",
  Organization: "ORGANIZATION",
} as const;

export type UpdateFeeRequestBusinessTypeEnum =
  (typeof UpdateFeeRequestBusinessTypeEnum)[keyof typeof UpdateFeeRequestBusinessTypeEnum];
export const UpdateFeeRequestServiceTypeEnum = {
  Cobo: "COBO",
  Pobo: "POBO",
  CoboInterCard: "COBO_INTER_CARD",
  CoboDebitDomesticCard: "COBO_DEBIT_DOMESTIC_CARD",
  CoboCreditDomesticCard: "COBO_CREDIT_DOMESTIC_CARD",
  PoboInterBank: "POBO_INTER_BANK",
} as const;

export type UpdateFeeRequestServiceTypeEnum =
  (typeof UpdateFeeRequestServiceTypeEnum)[keyof typeof UpdateFeeRequestServiceTypeEnum];
export const UpdateFeeRequestFeeMethodEnum = {
  Free: "FREE",
  FixedFee: "FIXED_FEE",
  BracketFee: "BRACKET_FEE",
} as const;

export type UpdateFeeRequestFeeMethodEnum =
  (typeof UpdateFeeRequestFeeMethodEnum)[keyof typeof UpdateFeeRequestFeeMethodEnum];
export const UpdateFeeRequestCalculatePerEnum = {
  PerMonth: "PER_MONTH",
  PerTransaction: "PER_TRANSACTION",
  None: "NONE",
} as const;

export type UpdateFeeRequestCalculatePerEnum =
  (typeof UpdateFeeRequestCalculatePerEnum)[keyof typeof UpdateFeeRequestCalculatePerEnum];
export const UpdateFeeRequestFixedFeeMethodEnum = {
  FixedFee: "FIXED_FEE",
  RatioFee: "RATIO_FEE",
  None: "NONE",
} as const;

export type UpdateFeeRequestFixedFeeMethodEnum =
  (typeof UpdateFeeRequestFixedFeeMethodEnum)[keyof typeof UpdateFeeRequestFixedFeeMethodEnum];

/**
 *
 * @export
 * @interface UpdateMerchantRequest
 */
export interface UpdateMerchantRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateMerchantRequest
   */
  merchantName?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateMerchantRequest
   */
  accountNo?: string;
}
/**
 *
 * @export
 * @interface UpdateMerchantResponse
 */
export interface UpdateMerchantResponse {
  /**
   *
   * @type {MerchantDto}
   * @memberof UpdateMerchantResponse
   */
  merchant?: MerchantDto;
}
/**
 *
 * @export
 * @interface UpdateOrderStatusRequest
 */
export interface UpdateOrderStatusRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateOrderStatusRequest
   */
  orderId: string;
  /**
   *
   * @type {string}
   * @memberof UpdateOrderStatusRequest
   */
  status: UpdateOrderStatusRequestStatusEnum;
}

export const UpdateOrderStatusRequestStatusEnum = {
  Created: "CREATED",
  Paid: "PAID",
  Cancel: "CANCEL",
} as const;

export type UpdateOrderStatusRequestStatusEnum =
  (typeof UpdateOrderStatusRequestStatusEnum)[keyof typeof UpdateOrderStatusRequestStatusEnum];

/**
 *
 * @export
 * @interface UpdatePayButtonRequest
 */
export interface UpdatePayButtonRequest {
  /**
   *
   * @type {string}
   * @memberof UpdatePayButtonRequest
   */
  payButtonId: string;
  /**
   *
   * @type {number}
   * @memberof UpdatePayButtonRequest
   */
  payPageId?: number;
  /**
   *
   * @type {string}
   * @memberof UpdatePayButtonRequest
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof UpdatePayButtonRequest
   */
  label?: string;
  /**
   *
   * @type {string}
   * @memberof UpdatePayButtonRequest
   */
  theme?: UpdatePayButtonRequestThemeEnum;
}

export const UpdatePayButtonRequestThemeEnum = {
  Light: "LIGHT",
  Dark: "DARK",
} as const;

export type UpdatePayButtonRequestThemeEnum =
  (typeof UpdatePayButtonRequestThemeEnum)[keyof typeof UpdatePayButtonRequestThemeEnum];

/**
 *
 * @export
 * @interface UpdatePaySubscriptionPlanRequest
 */
export interface UpdatePaySubscriptionPlanRequest {
  /**
   *
   * @type {string}
   * @memberof UpdatePaySubscriptionPlanRequest
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UpdatePaySubscriptionPlanRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof UpdatePaySubscriptionPlanRequest
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof UpdatePaySubscriptionPlanRequest
   */
  status: UpdatePaySubscriptionPlanRequestStatusEnum;
  /**
   *
   * @type {number}
   * @memberof UpdatePaySubscriptionPlanRequest
   */
  cycleValue: number;
  /**
   *
   * @type {string}
   * @memberof UpdatePaySubscriptionPlanRequest
   */
  timeType: UpdatePaySubscriptionPlanRequestTimeTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof UpdatePaySubscriptionPlanRequest
   */
  isFixedPaymentTime: boolean;
  /**
   *
   * @type {PaySubscriptionPlanFixedTimeModel}
   * @memberof UpdatePaySubscriptionPlanRequest
   */
  fixedTime?: PaySubscriptionPlanFixedTimeModel;
  /**
   *
   * @type {number}
   * @memberof UpdatePaySubscriptionPlanRequest
   */
  expirationDays: number;
  /**
   *
   * @type {string}
   * @memberof UpdatePaySubscriptionPlanRequest
   */
  type: UpdatePaySubscriptionPlanRequestTypeEnum;
  /**
   *
   * @type {number}
   * @memberof UpdatePaySubscriptionPlanRequest
   */
  fixedAmount?: number;
  /**
   *
   * @type {string}
   * @memberof UpdatePaySubscriptionPlanRequest
   */
  bankAccountId: string;
  /**
   *
   * @type {string}
   * @memberof UpdatePaySubscriptionPlanRequest
   */
  paymentType: UpdatePaySubscriptionPlanRequestPaymentTypeEnum;
}

export const UpdatePaySubscriptionPlanRequestStatusEnum = {
  Created: "CREATED",
  Active: "ACTIVE",
  Inactive: "INACTIVE",
} as const;

export type UpdatePaySubscriptionPlanRequestStatusEnum =
  (typeof UpdatePaySubscriptionPlanRequestStatusEnum)[keyof typeof UpdatePaySubscriptionPlanRequestStatusEnum];
export const UpdatePaySubscriptionPlanRequestTimeTypeEnum = {
  Day: "DAY",
  Week: "WEEK",
  Month: "MONTH",
  Year: "YEAR",
} as const;

export type UpdatePaySubscriptionPlanRequestTimeTypeEnum =
  (typeof UpdatePaySubscriptionPlanRequestTimeTypeEnum)[keyof typeof UpdatePaySubscriptionPlanRequestTimeTypeEnum];
export const UpdatePaySubscriptionPlanRequestTypeEnum = {
  RecurringQuantity: "RECURRING_QUANTITY",
  MeteredUsage: "METERED_USAGE",
} as const;

export type UpdatePaySubscriptionPlanRequestTypeEnum =
  (typeof UpdatePaySubscriptionPlanRequestTypeEnum)[keyof typeof UpdatePaySubscriptionPlanRequestTypeEnum];
export const UpdatePaySubscriptionPlanRequestPaymentTypeEnum = {
  Prepaid: "PREPAID",
  Postpaid: "POSTPAID",
} as const;

export type UpdatePaySubscriptionPlanRequestPaymentTypeEnum =
  (typeof UpdatePaySubscriptionPlanRequestPaymentTypeEnum)[keyof typeof UpdatePaySubscriptionPlanRequestPaymentTypeEnum];

/**
 *
 * @export
 * @interface UpdatePaymentMethodRequest
 */
export interface UpdatePaymentMethodRequest {
  /**
   *
   * @type {number}
   * @memberof UpdatePaymentMethodRequest
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof UpdatePaymentMethodRequest
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof UpdatePaymentMethodRequest
   */
  nameEn?: string;
  /**
   *
   * @type {number}
   * @memberof UpdatePaymentMethodRequest
   */
  dynamicFee?: number;
  /**
   *
   * @type {number}
   * @memberof UpdatePaymentMethodRequest
   */
  fixedFee?: number;
  /**
   *
   * @type {number}
   * @memberof UpdatePaymentMethodRequest
   */
  paymentLimit?: number;
  /**
   *
   * @type {string}
   * @memberof UpdatePaymentMethodRequest
   */
  type?: UpdatePaymentMethodRequestTypeEnum;
  /**
   *
   * @type {string}
   * @memberof UpdatePaymentMethodRequest
   */
  status?: UpdatePaymentMethodRequestStatusEnum;
  /**
   *
   * @type {string}
   * @memberof UpdatePaymentMethodRequest
   */
  paymentType?: UpdatePaymentMethodRequestPaymentTypeEnum;
}

export const UpdatePaymentMethodRequestTypeEnum = {
  Domestic: "DOMESTIC",
  International: "INTERNATIONAL",
} as const;

export type UpdatePaymentMethodRequestTypeEnum =
  (typeof UpdatePaymentMethodRequestTypeEnum)[keyof typeof UpdatePaymentMethodRequestTypeEnum];
export const UpdatePaymentMethodRequestStatusEnum = {
  Active: "ACTIVE",
  Inactive: "INACTIVE",
} as const;

export type UpdatePaymentMethodRequestStatusEnum =
  (typeof UpdatePaymentMethodRequestStatusEnum)[keyof typeof UpdatePaymentMethodRequestStatusEnum];
export const UpdatePaymentMethodRequestPaymentTypeEnum = {
  VietQr: "VIET_QR",
  AtmCard: "ATM_CARD",
  Banking: "BANKING",
} as const;

export type UpdatePaymentMethodRequestPaymentTypeEnum =
  (typeof UpdatePaymentMethodRequestPaymentTypeEnum)[keyof typeof UpdatePaymentMethodRequestPaymentTypeEnum];

/**
 *
 * @export
 * @interface UpdatePosDeviceRequest
 */
export interface UpdatePosDeviceRequest {
  /**
   *
   * @type {string}
   * @memberof UpdatePosDeviceRequest
   */
  payPosId: string;
  /**
   *
   * @type {string}
   * @memberof UpdatePosDeviceRequest
   */
  merchantId?: string;
  /**
   *
   * @type {string}
   * @memberof UpdatePosDeviceRequest
   */
  beneficiaryAccount?: string;
  /**
   *
   * @type {string}
   * @memberof UpdatePosDeviceRequest
   */
  status?: UpdatePosDeviceRequestStatusEnum;
  /**
   *
   * @type {number}
   * @memberof UpdatePosDeviceRequest
   */
  modelId?: number;
  /**
   *
   * @type {string}
   * @memberof UpdatePosDeviceRequest
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof UpdatePosDeviceRequest
   */
  posOrderId?: string;
}

export const UpdatePosDeviceRequestStatusEnum = {
  Active: "ACTIVE",
  Inactive: "INACTIVE",
} as const;

export type UpdatePosDeviceRequestStatusEnum =
  (typeof UpdatePosDeviceRequestStatusEnum)[keyof typeof UpdatePosDeviceRequestStatusEnum];

/**
 *
 * @export
 * @interface UpdatePosModelRequest
 */
export interface UpdatePosModelRequest {
  /**
   *
   * @type {number}
   * @memberof UpdatePosModelRequest
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof UpdatePosModelRequest
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof UpdatePosModelRequest
   */
  model?: string;
  /**
   *
   * @type {number}
   * @memberof UpdatePosModelRequest
   */
  price?: number;
  /**
   *
   * @type {number}
   * @memberof UpdatePosModelRequest
   */
  stock?: number;
  /**
   *
   * @type {boolean}
   * @memberof UpdatePosModelRequest
   */
  available?: boolean;
  /**
   *
   * @type {string}
   * @memberof UpdatePosModelRequest
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof UpdatePosModelRequest
   */
  warranty?: number;
  /**
   *
   * @type {number}
   * @memberof UpdatePosModelRequest
   */
  discount?: number;
  /**
   *
   * @type {string}
   * @memberof UpdatePosModelRequest
   */
  mainPhotoUrl?: string;
  /**
   *
   * @type {Array<PosModelPhotoDto>}
   * @memberof UpdatePosModelRequest
   */
  photos?: Array<PosModelPhotoDto>;
  /**
   *
   * @type {number}
   * @memberof UpdatePosModelRequest
   */
  vat?: number;
}
/**
 *
 * @export
 * @interface UpdateProductRequest
 */
export interface UpdateProductRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateProductRequest
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof UpdateProductRequest
   */
  cateId?: number;
  /**
   *
   * @type {string}
   * @memberof UpdateProductRequest
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateProductRequest
   */
  priceType?: UpdateProductRequestPriceTypeEnum;
  /**
   *
   * @type {number}
   * @memberof UpdateProductRequest
   */
  price?: number;
  /**
   *
   * @type {string}
   * @memberof UpdateProductRequest
   */
  storageType?: UpdateProductRequestStorageTypeEnum;
  /**
   *
   * @type {number}
   * @memberof UpdateProductRequest
   */
  storageQuantity?: number;
  /**
   *
   * @type {string}
   * @memberof UpdateProductRequest
   */
  imageUrl?: string;
}

export const UpdateProductRequestPriceTypeEnum = {
  FixedPrice: "FIXED_PRICE",
  QuantityPrice: "QUANTITY_PRICE",
  CustomerPrice: "CUSTOMER_PRICE",
} as const;

export type UpdateProductRequestPriceTypeEnum =
  (typeof UpdateProductRequestPriceTypeEnum)[keyof typeof UpdateProductRequestPriceTypeEnum];
export const UpdateProductRequestStorageTypeEnum = {
  Limited: "LIMITED",
  Unlimited: "UNLIMITED",
} as const;

export type UpdateProductRequestStorageTypeEnum =
  (typeof UpdateProductRequestStorageTypeEnum)[keyof typeof UpdateProductRequestStorageTypeEnum];

/**
 *
 * @export
 * @interface UpdateReceiverRequest
 */
export interface UpdateReceiverRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateReceiverRequest
   */
  group?: UpdateReceiverRequestGroupEnum;
  /**
   *
   * @type {string}
   * @memberof UpdateReceiverRequest
   */
  bankBin?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateReceiverRequest
   */
  accountNo?: string;
}

export const UpdateReceiverRequestGroupEnum = {
  Customer: "CUSTOMER",
  Employee: "EMPLOYEE",
  Merchant: "MERCHANT",
  Friend: "FRIEND",
  Family: "FAMILY",
  Other: "OTHER",
} as const;

export type UpdateReceiverRequestGroupEnum =
  (typeof UpdateReceiverRequestGroupEnum)[keyof typeof UpdateReceiverRequestGroupEnum];

/**
 *
 * @export
 * @interface UpdateResponseMessageRequest
 */
export interface UpdateResponseMessageRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateResponseMessageRequest
   */
  viContent?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateResponseMessageRequest
   */
  enContent?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateResponseMessageRequest
   */
  description?: string;
}
/**
 *
 * @export
 * @interface UpdateRoleGroupRequest
 */
export interface UpdateRoleGroupRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateRoleGroupRequest
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateRoleGroupRequest
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateRoleGroupRequest
   */
  status?: UpdateRoleGroupRequestStatusEnum;
  /**
   *
   * @type {Array<number>}
   * @memberof UpdateRoleGroupRequest
   */
  actionIds?: Array<number>;
}

export const UpdateRoleGroupRequestStatusEnum = {
  Active: "ACTIVE",
  Closed: "CLOSED",
} as const;

export type UpdateRoleGroupRequestStatusEnum =
  (typeof UpdateRoleGroupRequestStatusEnum)[keyof typeof UpdateRoleGroupRequestStatusEnum];

/**
 *
 * @export
 * @interface UpdateShopSettingRequest
 */
export interface UpdateShopSettingRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateShopSettingRequest
   */
  shopName?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateShopSettingRequest
   */
  logo?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateShopSettingRequest
   */
  backgroundColor?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateShopSettingRequest
   */
  defaultLanguage?: UpdateShopSettingRequestDefaultLanguageEnum;
  /**
   *
   * @type {Array<number>}
   * @memberof UpdateShopSettingRequest
   */
  paymentIds?: Array<number>;
}

export const UpdateShopSettingRequestDefaultLanguageEnum = {
  En: "EN",
  Vi: "VI",
} as const;

export type UpdateShopSettingRequestDefaultLanguageEnum =
  (typeof UpdateShopSettingRequestDefaultLanguageEnum)[keyof typeof UpdateShopSettingRequestDefaultLanguageEnum];

/**
 *
 * @export
 * @interface UpdateSystemParamRequest
 */
export interface UpdateSystemParamRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateSystemParamRequest
   */
  dataType: UpdateSystemParamRequestDataTypeEnum;
  /**
   *
   * @type {string}
   * @memberof UpdateSystemParamRequest
   */
  value: string;
  /**
   *
   * @type {string}
   * @memberof UpdateSystemParamRequest
   */
  description?: string;
}

export const UpdateSystemParamRequestDataTypeEnum = {
  String: "STRING",
  Number: "NUMBER",
} as const;

export type UpdateSystemParamRequestDataTypeEnum =
  (typeof UpdateSystemParamRequestDataTypeEnum)[keyof typeof UpdateSystemParamRequestDataTypeEnum];

/**
 *
 * @export
 * @interface UpdateUserInfoRequest
 */
export interface UpdateUserInfoRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateUserInfoRequest
   */
  avatar?: string;
}
/**
 *
 * @export
 * @interface UpdateWebhookRequest
 */
export interface UpdateWebhookRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateWebhookRequest
   */
  url: string;
  /**
   *
   * @type {string}
   * @memberof UpdateWebhookRequest
   */
  apiKey: string;
}
/**
 *
 * @export
 * @interface UpdateWebhookResponse
 */
export interface UpdateWebhookResponse {
  /**
   *
   * @type {boolean}
   * @memberof UpdateWebhookResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface UploadSftpFileResponse
 */
export interface UploadSftpFileResponse {
  /**
   *
   * @type {boolean}
   * @memberof UploadSftpFileResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface UserResponse
 */
export interface UserResponse {
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  merchantCode?: string;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  merchantName?: string;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  username?: string;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  fullName?: string;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  status?: UserResponseStatusEnum;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  phone?: string;
  /**
   *
   * @type {RoleGroupResponse}
   * @memberof UserResponse
   */
  roleGroup?: RoleGroupResponse;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  avatar?: string;
  /**
   *
   * @type {boolean}
   * @memberof UserResponse
   */
  isOwner?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserResponse
   */
  allowEmail?: boolean;
  /**
   *
   * @type {Array<BankAccountDto>}
   * @memberof UserResponse
   */
  bankAccounts?: Array<BankAccountDto>;
}

export const UserResponseStatusEnum = {
  Blocked: "BLOCKED",
  Active: "ACTIVE",
  Inactive: "INACTIVE",
  NotLoggedIn: "NOT_LOGGED_IN",
} as const;

export type UserResponseStatusEnum =
  (typeof UserResponseStatusEnum)[keyof typeof UserResponseStatusEnum];

/**
 *
 * @export
 * @interface ValidNapasSessionResponse
 */
export interface ValidNapasSessionResponse {
  /**
   *
   * @type {boolean}
   * @memberof ValidNapasSessionResponse
   */
  status?: boolean;
}
/**
 *
 * @export
 * @interface ValidateProfile
 */
export interface ValidateProfile {
  /**
   *
   * @type {boolean}
   * @memberof ValidateProfile
   */
  fullname?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ValidateProfile
   */
  birthday?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ValidateProfile
   */
  gender?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ValidateProfile
   */
  idCardNo?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ValidateProfile
   */
  idCardIssueDate?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ValidateProfile
   */
  resCity?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ValidateProfile
   */
  resAddr?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ValidateProfile
   */
  hometown?: boolean;
}
/**
 *
 * @export
 * @interface VerifyLinkAccountRequest
 */
export interface VerifyLinkAccountRequest {
  /**
   *
   * @type {string}
   * @memberof VerifyLinkAccountRequest
   */
  sessionId: string;
  /**
   *
   * @type {string}
   * @memberof VerifyLinkAccountRequest
   */
  accountNo: string;
  /**
   *
   * @type {string}
   * @memberof VerifyLinkAccountRequest
   */
  otp: string;
}
/**
 *
 * @export
 * @interface VerifyLinkAccountResponse
 */
export interface VerifyLinkAccountResponse {
  /**
   *
   * @type {boolean}
   * @memberof VerifyLinkAccountResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface VerifyOTPSinglePayOutRequest
 */
export interface VerifyOTPSinglePayOutRequest {
  /**
   *
   * @type {string}
   * @memberof VerifyOTPSinglePayOutRequest
   */
  payOutId: string;
  /**
   *
   * @type {string}
   * @memberof VerifyOTPSinglePayOutRequest
   */
  sessionId: string;
  /**
   *
   * @type {string}
   * @memberof VerifyOTPSinglePayOutRequest
   */
  otp: string;
  /**
   *
   * @type {string}
   * @memberof VerifyOTPSinglePayOutRequest
   */
  refTransactionId?: string;
  /**
   *
   * @type {string}
   * @memberof VerifyOTPSinglePayOutRequest
   */
  successUrl?: string;
  /**
   *
   * @type {string}
   * @memberof VerifyOTPSinglePayOutRequest
   */
  failUrl?: string;
  /**
   *
   * @type {number}
   * @memberof VerifyOTPSinglePayOutRequest
   */
  redirectAfter?: number;
}
/**
 *
 * @export
 * @interface VerifyOtpLinkAccountRequest
 */
export interface VerifyOtpLinkAccountRequest {
  /**
   *
   * @type {string}
   * @memberof VerifyOtpLinkAccountRequest
   */
  sessionId: string;
  /**
   *
   * @type {string}
   * @memberof VerifyOtpLinkAccountRequest
   */
  otp: string;
  /**
   *
   * @type {string}
   * @memberof VerifyOtpLinkAccountRequest
   */
  branch: VerifyOtpLinkAccountRequestBranchEnum;
  /**
   *
   * @type {string}
   * @memberof VerifyOtpLinkAccountRequest
   */
  accountNo: string;
  /**
   *
   * @type {string}
   * @memberof VerifyOtpLinkAccountRequest
   */
  accountName: string;
}

export const VerifyOtpLinkAccountRequestBranchEnum = {
  Klb: "KLB",
  Umee: "UMEE",
} as const;

export type VerifyOtpLinkAccountRequestBranchEnum =
  (typeof VerifyOtpLinkAccountRequestBranchEnum)[keyof typeof VerifyOtpLinkAccountRequestBranchEnum];

/**
 *
 * @export
 * @interface VerifyOtpLinkAccountResponse
 */
export interface VerifyOtpLinkAccountResponse {
  /**
   *
   * @type {BankAccountDto}
   * @memberof VerifyOtpLinkAccountResponse
   */
  bankAccount?: BankAccountDto;
}
/**
 *
 * @export
 * @interface VietQrProCustomerResponse
 */
export interface VietQrProCustomerResponse {
  /**
   *
   * @type {number}
   * @memberof VietQrProCustomerResponse
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof VietQrProCustomerResponse
   */
  name?: string;
  /**
   *
   * @type {boolean}
   * @memberof VietQrProCustomerResponse
   */
  active?: boolean;
  /**
   *
   * @type {string}
   * @memberof VietQrProCustomerResponse
   */
  accountNo?: string;
  /**
   *
   * @type {string}
   * @memberof VietQrProCustomerResponse
   */
  accountName?: string;
  /**
   *
   * @type {string}
   * @memberof VietQrProCustomerResponse
   */
  bankName?: string;
  /**
   *
   * @type {string}
   * @memberof VietQrProCustomerResponse
   */
  createAt?: string;
  /**
   *
   * @type {number}
   * @memberof VietQrProCustomerResponse
   */
  revenue?: number;
}
/**
 *
 * @export
 * @interface VietQrProHistoryChangeResponse
 */
export interface VietQrProHistoryChangeResponse {
  /**
   *
   * @type {string}
   * @memberof VietQrProHistoryChangeResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof VietQrProHistoryChangeResponse
   */
  merchantId?: string;
  /**
   *
   * @type {string}
   * @memberof VietQrProHistoryChangeResponse
   */
  action?: VietQrProHistoryChangeResponseActionEnum;
  /**
   *
   * @type {string}
   * @memberof VietQrProHistoryChangeResponse
   */
  reason?: string;
  /**
   *
   * @type {string}
   * @memberof VietQrProHistoryChangeResponse
   */
  createAt?: string;
  /**
   *
   * @type {string}
   * @memberof VietQrProHistoryChangeResponse
   */
  createBy?: string;
}

export const VietQrProHistoryChangeResponseActionEnum = {
  Approve: "APPROVE",
  Reject: "REJECT",
} as const;

export type VietQrProHistoryChangeResponseActionEnum =
  (typeof VietQrProHistoryChangeResponseActionEnum)[keyof typeof VietQrProHistoryChangeResponseActionEnum];

/**
 *
 * @export
 * @interface VirtualAccountResponse
 */
export interface VirtualAccountResponse {
  /**
   *
   * @type {number}
   * @memberof VirtualAccountResponse
   */
  order?: number;
  /**
   *
   * @type {string}
   * @memberof VirtualAccountResponse
   */
  virtualAccount?: string;
  /**
   *
   * @type {string}
   * @memberof VirtualAccountResponse
   */
  bankAccountNo?: string;
  /**
   *
   * @type {number}
   * @memberof VirtualAccountResponse
   */
  fixAmount?: number;
  /**
   *
   * @type {string}
   * @memberof VirtualAccountResponse
   */
  fixContent?: string;
  /**
   *
   * @type {string}
   * @memberof VirtualAccountResponse
   */
  qrContent?: string;
  /**
   *
   * @type {number}
   * @memberof VirtualAccountResponse
   */
  timeout?: number;
}
/**
 *
 * @export
 * @interface WebhookUrlResponse
 */
export interface WebhookUrlResponse {
  /**
   *
   * @type {string}
   * @memberof WebhookUrlResponse
   */
  webhookUrl?: string;
  /**
   *
   * @type {string}
   * @memberof WebhookUrlResponse
   */
  status?: WebhookUrlResponseStatusEnum;
}

export const WebhookUrlResponseStatusEnum = {
  Pending: "PENDING",
  Approved: "APPROVED",
  Rejected: "REJECTED",
} as const;

export type WebhookUrlResponseStatusEnum =
  (typeof WebhookUrlResponseStatusEnum)[keyof typeof WebhookUrlResponseStatusEnum];

/**
 *
 * @export
 * @interface WriteSlRequest
 */
export interface WriteSlRequest {
  /**
   *
   * @type {Array<ReportDetail>}
   * @memberof WriteSlRequest
   */
  list: Array<ReportDetail>;
}

/**
 * AdminControllerApi - axios parameter creator
 * @export
 */
export const AdminControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Vô hiện hóa quản trị viên
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blockAdmin: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("blockAdmin", "id", id);
      const localVarPath = `/api/admin/cms/v1/block/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Kiểm tra mã nhân viên đã tồn tại
     * @param {string} staffId
     * @param {string} [adminId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkStaffId: async (
      staffId: string,
      adminId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'staffId' is not null or undefined
      assertParamExists("checkStaffId", "staffId", staffId);
      const localVarPath = `/api/admin/cms/check-staff-if`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (staffId !== undefined) {
        localVarQueryParameter["staffId"] = staffId;
      }

      if (adminId !== undefined) {
        localVarQueryParameter["adminId"] = adminId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Tạo mới quản trị viên
     * @param {CreateAdminRequest} createAdminRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAdmin: async (
      createAdminRequest: CreateAdminRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createAdminRequest' is not null or undefined
      assertParamExists(
        "createAdmin",
        "createAdminRequest",
        createAdminRequest,
      );
      const localVarPath = `/api/admin/cms/v1/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createAdminRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Xóa quản trị viên nếu chưa từng đăng nhập vào hệ thống
     * @summary Xóa quản trị viên
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAdmin: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("deleteAdmin", "id", id);
      const localVarPath = `/api/admin/cms/v1/delete/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Forgot password admin
     * @param {ForgotPasswordAdminRequest} forgotPasswordAdminRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    forgotPassword: async (
      forgotPasswordAdminRequest: ForgotPasswordAdminRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'forgotPasswordAdminRequest' is not null or undefined
      assertParamExists(
        "forgotPassword",
        "forgotPasswordAdminRequest",
        forgotPasswordAdminRequest,
      );
      const localVarPath = `/api/admin/cms/v1/forgotPassword`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        forgotPasswordAdminRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xem chi tiết quản trị viên
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAdminDetail: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getAdminDetail", "id", id);
      const localVarPath = `/api/admin/cms/v1/getDetail/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xem danh sách quản trị viên
     * @param {string} [username]
     * @param {string} [fullName]
     * @param {string} [email]
     * @param {string} [staffId]
     * @param {'HEADQUARTER' | 'BRANCH' | 'TRANSACTION_OFFICE'} [office]
     * @param {'BLOCKED' | 'ACTIVE' | 'INACTIVE' | 'NOT_LOGGED_IN'} [status]
     * @param {string} [page]
     * @param {string} [pageSize]
     * @param {string} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAll2: async (
      username?: string,
      fullName?: string,
      email?: string,
      staffId?: string,
      office?: "HEADQUARTER" | "BRANCH" | "TRANSACTION_OFFICE",
      status?: "BLOCKED" | "ACTIVE" | "INACTIVE" | "NOT_LOGGED_IN",
      page?: string,
      pageSize?: string,
      sortDirection?: string,
      sortBy?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/admin/cms/v1/getAll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (username !== undefined) {
        localVarQueryParameter["username"] = username;
      }

      if (fullName !== undefined) {
        localVarQueryParameter["fullName"] = fullName;
      }

      if (email !== undefined) {
        localVarQueryParameter["email"] = email;
      }

      if (staffId !== undefined) {
        localVarQueryParameter["staffId"] = staffId;
      }

      if (office !== undefined) {
        localVarQueryParameter["office"] = office;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter["sortDirection"] = sortDirection;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter["sortBy"] = sortBy;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Tạo mới quản trị viên
     * @param {CreateAdminRequest} createAdminRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rootCreateAdmin: async (
      createAdminRequest: CreateAdminRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createAdminRequest' is not null or undefined
      assertParamExists(
        "rootCreateAdmin",
        "createAdminRequest",
        createAdminRequest,
      );
      const localVarPath = `/api/admin/cms/v1/root/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createAdminRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Kích hoạt (mở khóa) quản trị viên
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unblockAdmin: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("unblockAdmin", "id", id);
      const localVarPath = `/api/admin/cms/v1/unblock/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Chỉnh sửa quản trị viên
     * @param {string} id
     * @param {UpdateAdminRequest} updateAdminRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAdmin: async (
      id: string,
      updateAdminRequest: UpdateAdminRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("updateAdmin", "id", id);
      // verify required parameter 'updateAdminRequest' is not null or undefined
      assertParamExists(
        "updateAdmin",
        "updateAdminRequest",
        updateAdminRequest,
      );
      const localVarPath = `/api/admin/cms/v1/update/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateAdminRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AdminControllerApi - functional programming interface
 * @export
 */
export const AdminControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    AdminControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Vô hiện hóa quản trị viên
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async blockAdmin(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseAdminResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.blockAdmin(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Kiểm tra mã nhân viên đã tồn tại
     * @param {string} staffId
     * @param {string} [adminId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkStaffId(
      staffId: string,
      adminId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseCheckStaffIdResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.checkStaffId(
        staffId,
        adminId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Tạo mới quản trị viên
     * @param {CreateAdminRequest} createAdminRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createAdmin(
      createAdminRequest: CreateAdminRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseAdminResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createAdmin(
        createAdminRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Xóa quản trị viên nếu chưa từng đăng nhập vào hệ thống
     * @summary Xóa quản trị viên
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteAdmin(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseAdminResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdmin(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Forgot password admin
     * @param {ForgotPasswordAdminRequest} forgotPasswordAdminRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async forgotPassword(
      forgotPasswordAdminRequest: ForgotPasswordAdminRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.forgotPassword(
        forgotPasswordAdminRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Xem chi tiết quản trị viên
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAdminDetail(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseAdminDetailResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminDetail(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Xem danh sách quản trị viên
     * @param {string} [username]
     * @param {string} [fullName]
     * @param {string} [email]
     * @param {string} [staffId]
     * @param {'HEADQUARTER' | 'BRANCH' | 'TRANSACTION_OFFICE'} [office]
     * @param {'BLOCKED' | 'ACTIVE' | 'INACTIVE' | 'NOT_LOGGED_IN'} [status]
     * @param {string} [page]
     * @param {string} [pageSize]
     * @param {string} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAll2(
      username?: string,
      fullName?: string,
      email?: string,
      staffId?: string,
      office?: "HEADQUARTER" | "BRANCH" | "TRANSACTION_OFFICE",
      status?: "BLOCKED" | "ACTIVE" | "INACTIVE" | "NOT_LOGGED_IN",
      page?: string,
      pageSize?: string,
      sortDirection?: string,
      sortBy?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseGetListAdminResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAll2(
        username,
        fullName,
        email,
        staffId,
        office,
        status,
        page,
        pageSize,
        sortDirection,
        sortBy,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Tạo mới quản trị viên
     * @param {CreateAdminRequest} createAdminRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async rootCreateAdmin(
      createAdminRequest: CreateAdminRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseAdminResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.rootCreateAdmin(
        createAdminRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Kích hoạt (mở khóa) quản trị viên
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async unblockAdmin(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseAdminResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.unblockAdmin(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Chỉnh sửa quản trị viên
     * @param {string} id
     * @param {UpdateAdminRequest} updateAdminRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateAdmin(
      id: string,
      updateAdminRequest: UpdateAdminRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseAdminResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateAdmin(
        id,
        updateAdminRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * AdminControllerApi - factory interface
 * @export
 */
export const AdminControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AdminControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Vô hiện hóa quản trị viên
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blockAdmin(
      id: string,
      options?: any,
    ): AxiosPromise<ResponseBaseAdminResponse> {
      return localVarFp
        .blockAdmin(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Kiểm tra mã nhân viên đã tồn tại
     * @param {string} staffId
     * @param {string} [adminId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkStaffId(
      staffId: string,
      adminId?: string,
      options?: any,
    ): AxiosPromise<ResponseBaseCheckStaffIdResponse> {
      return localVarFp
        .checkStaffId(staffId, adminId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Tạo mới quản trị viên
     * @param {CreateAdminRequest} createAdminRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAdmin(
      createAdminRequest: CreateAdminRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseAdminResponse> {
      return localVarFp
        .createAdmin(createAdminRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Xóa quản trị viên nếu chưa từng đăng nhập vào hệ thống
     * @summary Xóa quản trị viên
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAdmin(
      id: string,
      options?: any,
    ): AxiosPromise<ResponseBaseAdminResponse> {
      return localVarFp
        .deleteAdmin(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Forgot password admin
     * @param {ForgotPasswordAdminRequest} forgotPasswordAdminRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    forgotPassword(
      forgotPasswordAdminRequest: ForgotPasswordAdminRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .forgotPassword(forgotPasswordAdminRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xem chi tiết quản trị viên
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAdminDetail(
      id: string,
      options?: any,
    ): AxiosPromise<ResponseBaseAdminDetailResponse> {
      return localVarFp
        .getAdminDetail(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xem danh sách quản trị viên
     * @param {string} [username]
     * @param {string} [fullName]
     * @param {string} [email]
     * @param {string} [staffId]
     * @param {'HEADQUARTER' | 'BRANCH' | 'TRANSACTION_OFFICE'} [office]
     * @param {'BLOCKED' | 'ACTIVE' | 'INACTIVE' | 'NOT_LOGGED_IN'} [status]
     * @param {string} [page]
     * @param {string} [pageSize]
     * @param {string} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAll2(
      username?: string,
      fullName?: string,
      email?: string,
      staffId?: string,
      office?: "HEADQUARTER" | "BRANCH" | "TRANSACTION_OFFICE",
      status?: "BLOCKED" | "ACTIVE" | "INACTIVE" | "NOT_LOGGED_IN",
      page?: string,
      pageSize?: string,
      sortDirection?: string,
      sortBy?: string,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseGetListAdminResponse> {
      return localVarFp
        .getAll2(
          username,
          fullName,
          email,
          staffId,
          office,
          status,
          page,
          pageSize,
          sortDirection,
          sortBy,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Tạo mới quản trị viên
     * @param {CreateAdminRequest} createAdminRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rootCreateAdmin(
      createAdminRequest: CreateAdminRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseAdminResponse> {
      return localVarFp
        .rootCreateAdmin(createAdminRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Kích hoạt (mở khóa) quản trị viên
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unblockAdmin(
      id: string,
      options?: any,
    ): AxiosPromise<ResponseBaseAdminResponse> {
      return localVarFp
        .unblockAdmin(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Chỉnh sửa quản trị viên
     * @param {string} id
     * @param {UpdateAdminRequest} updateAdminRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAdmin(
      id: string,
      updateAdminRequest: UpdateAdminRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseAdminResponse> {
      return localVarFp
        .updateAdmin(id, updateAdminRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for blockAdmin operation in AdminControllerApi.
 * @export
 * @interface AdminControllerApiBlockAdminRequest
 */
export interface AdminControllerApiBlockAdminRequest {
  /**
   *
   * @type {string}
   * @memberof AdminControllerApiBlockAdmin
   */
  readonly id: string;
}

/**
 * Request parameters for checkStaffId operation in AdminControllerApi.
 * @export
 * @interface AdminControllerApiCheckStaffIdRequest
 */
export interface AdminControllerApiCheckStaffIdRequest {
  /**
   *
   * @type {string}
   * @memberof AdminControllerApiCheckStaffId
   */
  readonly staffId: string;

  /**
   *
   * @type {string}
   * @memberof AdminControllerApiCheckStaffId
   */
  readonly adminId?: string;
}

/**
 * Request parameters for createAdmin operation in AdminControllerApi.
 * @export
 * @interface AdminControllerApiCreateAdminRequest
 */
export interface AdminControllerApiCreateAdminRequest {
  /**
   *
   * @type {CreateAdminRequest}
   * @memberof AdminControllerApiCreateAdmin
   */
  readonly createAdminRequest: CreateAdminRequest;
}

/**
 * Request parameters for deleteAdmin operation in AdminControllerApi.
 * @export
 * @interface AdminControllerApiDeleteAdminRequest
 */
export interface AdminControllerApiDeleteAdminRequest {
  /**
   *
   * @type {string}
   * @memberof AdminControllerApiDeleteAdmin
   */
  readonly id: string;
}

/**
 * Request parameters for forgotPassword operation in AdminControllerApi.
 * @export
 * @interface AdminControllerApiForgotPasswordRequest
 */
export interface AdminControllerApiForgotPasswordRequest {
  /**
   *
   * @type {ForgotPasswordAdminRequest}
   * @memberof AdminControllerApiForgotPassword
   */
  readonly forgotPasswordAdminRequest: ForgotPasswordAdminRequest;
}

/**
 * Request parameters for getAdminDetail operation in AdminControllerApi.
 * @export
 * @interface AdminControllerApiGetAdminDetailRequest
 */
export interface AdminControllerApiGetAdminDetailRequest {
  /**
   *
   * @type {string}
   * @memberof AdminControllerApiGetAdminDetail
   */
  readonly id: string;
}

/**
 * Request parameters for getAll2 operation in AdminControllerApi.
 * @export
 * @interface AdminControllerApiGetAll2Request
 */
export interface AdminControllerApiGetAll2Request {
  /**
   *
   * @type {string}
   * @memberof AdminControllerApiGetAll2
   */
  readonly username?: string;

  /**
   *
   * @type {string}
   * @memberof AdminControllerApiGetAll2
   */
  readonly fullName?: string;

  /**
   *
   * @type {string}
   * @memberof AdminControllerApiGetAll2
   */
  readonly email?: string;

  /**
   *
   * @type {string}
   * @memberof AdminControllerApiGetAll2
   */
  readonly staffId?: string;

  /**
   *
   * @type {'HEADQUARTER' | 'BRANCH' | 'TRANSACTION_OFFICE'}
   * @memberof AdminControllerApiGetAll2
   */
  readonly office?: "HEADQUARTER" | "BRANCH" | "TRANSACTION_OFFICE";

  /**
   *
   * @type {'BLOCKED' | 'ACTIVE' | 'INACTIVE' | 'NOT_LOGGED_IN'}
   * @memberof AdminControllerApiGetAll2
   */
  readonly status?: "BLOCKED" | "ACTIVE" | "INACTIVE" | "NOT_LOGGED_IN";

  /**
   *
   * @type {string}
   * @memberof AdminControllerApiGetAll2
   */
  readonly page?: string;

  /**
   *
   * @type {string}
   * @memberof AdminControllerApiGetAll2
   */
  readonly pageSize?: string;

  /**
   *
   * @type {string}
   * @memberof AdminControllerApiGetAll2
   */
  readonly sortDirection?: string;

  /**
   *
   * @type {string}
   * @memberof AdminControllerApiGetAll2
   */
  readonly sortBy?: string;
}

/**
 * Request parameters for rootCreateAdmin operation in AdminControllerApi.
 * @export
 * @interface AdminControllerApiRootCreateAdminRequest
 */
export interface AdminControllerApiRootCreateAdminRequest {
  /**
   *
   * @type {CreateAdminRequest}
   * @memberof AdminControllerApiRootCreateAdmin
   */
  readonly createAdminRequest: CreateAdminRequest;
}

/**
 * Request parameters for unblockAdmin operation in AdminControllerApi.
 * @export
 * @interface AdminControllerApiUnblockAdminRequest
 */
export interface AdminControllerApiUnblockAdminRequest {
  /**
   *
   * @type {string}
   * @memberof AdminControllerApiUnblockAdmin
   */
  readonly id: string;
}

/**
 * Request parameters for updateAdmin operation in AdminControllerApi.
 * @export
 * @interface AdminControllerApiUpdateAdminRequest
 */
export interface AdminControllerApiUpdateAdminRequest {
  /**
   *
   * @type {string}
   * @memberof AdminControllerApiUpdateAdmin
   */
  readonly id: string;

  /**
   *
   * @type {UpdateAdminRequest}
   * @memberof AdminControllerApiUpdateAdmin
   */
  readonly updateAdminRequest: UpdateAdminRequest;
}

/**
 * AdminControllerApi - object-oriented interface
 * @export
 * @class AdminControllerApi
 * @extends {BaseAPI}
 */
export class AdminControllerApi extends BaseAPI {
  /**
   *
   * @summary Vô hiện hóa quản trị viên
   * @param {AdminControllerApiBlockAdminRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminControllerApi
   */
  public blockAdmin(
    requestParameters: AdminControllerApiBlockAdminRequest,
    options?: AxiosRequestConfig,
  ) {
    return AdminControllerApiFp(this.configuration)
      .blockAdmin(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Kiểm tra mã nhân viên đã tồn tại
   * @param {AdminControllerApiCheckStaffIdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminControllerApi
   */
  public checkStaffId(
    requestParameters: AdminControllerApiCheckStaffIdRequest,
    options?: AxiosRequestConfig,
  ) {
    return AdminControllerApiFp(this.configuration)
      .checkStaffId(
        requestParameters.staffId,
        requestParameters.adminId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Tạo mới quản trị viên
   * @param {AdminControllerApiCreateAdminRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminControllerApi
   */
  public createAdmin(
    requestParameters: AdminControllerApiCreateAdminRequest,
    options?: AxiosRequestConfig,
  ) {
    return AdminControllerApiFp(this.configuration)
      .createAdmin(requestParameters.createAdminRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Xóa quản trị viên nếu chưa từng đăng nhập vào hệ thống
   * @summary Xóa quản trị viên
   * @param {AdminControllerApiDeleteAdminRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminControllerApi
   */
  public deleteAdmin(
    requestParameters: AdminControllerApiDeleteAdminRequest,
    options?: AxiosRequestConfig,
  ) {
    return AdminControllerApiFp(this.configuration)
      .deleteAdmin(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Forgot password admin
   * @param {AdminControllerApiForgotPasswordRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminControllerApi
   */
  public forgotPassword(
    requestParameters: AdminControllerApiForgotPasswordRequest,
    options?: AxiosRequestConfig,
  ) {
    return AdminControllerApiFp(this.configuration)
      .forgotPassword(requestParameters.forgotPasswordAdminRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xem chi tiết quản trị viên
   * @param {AdminControllerApiGetAdminDetailRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminControllerApi
   */
  public getAdminDetail(
    requestParameters: AdminControllerApiGetAdminDetailRequest,
    options?: AxiosRequestConfig,
  ) {
    return AdminControllerApiFp(this.configuration)
      .getAdminDetail(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xem danh sách quản trị viên
   * @param {AdminControllerApiGetAll2Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminControllerApi
   */
  public getAll2(
    requestParameters: AdminControllerApiGetAll2Request = {},
    options?: AxiosRequestConfig,
  ) {
    return AdminControllerApiFp(this.configuration)
      .getAll2(
        requestParameters.username,
        requestParameters.fullName,
        requestParameters.email,
        requestParameters.staffId,
        requestParameters.office,
        requestParameters.status,
        requestParameters.page,
        requestParameters.pageSize,
        requestParameters.sortDirection,
        requestParameters.sortBy,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Tạo mới quản trị viên
   * @param {AdminControllerApiRootCreateAdminRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminControllerApi
   */
  public rootCreateAdmin(
    requestParameters: AdminControllerApiRootCreateAdminRequest,
    options?: AxiosRequestConfig,
  ) {
    return AdminControllerApiFp(this.configuration)
      .rootCreateAdmin(requestParameters.createAdminRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Kích hoạt (mở khóa) quản trị viên
   * @param {AdminControllerApiUnblockAdminRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminControllerApi
   */
  public unblockAdmin(
    requestParameters: AdminControllerApiUnblockAdminRequest,
    options?: AxiosRequestConfig,
  ) {
    return AdminControllerApiFp(this.configuration)
      .unblockAdmin(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Chỉnh sửa quản trị viên
   * @param {AdminControllerApiUpdateAdminRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminControllerApi
   */
  public updateAdmin(
    requestParameters: AdminControllerApiUpdateAdminRequest,
    options?: AxiosRequestConfig,
  ) {
    return AdminControllerApiFp(this.configuration)
      .updateAdmin(
        requestParameters.id,
        requestParameters.updateAdminRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AffiliateControllerApi - axios parameter creator
 * @export
 */
export const AffiliateControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Tạo một mã giới thiệu
     * @param {CmsCreateAffiliateRequest} cmsCreateAffiliateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsCreateAffiliate: async (
      cmsCreateAffiliateRequest: CmsCreateAffiliateRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'cmsCreateAffiliateRequest' is not null or undefined
      assertParamExists(
        "cmsCreateAffiliate",
        "cmsCreateAffiliateRequest",
        cmsCreateAffiliateRequest,
      );
      const localVarPath = `/api/affiliate/cms/v1/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        cmsCreateAffiliateRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy mã giới thiệu theo id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsGetAffiliateById: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("cmsGetAffiliateById", "id", id);
      const localVarPath = `/api/affiliate/cms/v1/get/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy tất cả mã giới thiệu
     * @param {string} [id]
     * @param {string} [code]
     * @param {string} [name]
     * @param {string} [cif]
     * @param {string} [department]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsGetAffiliates: async (
      id?: string,
      code?: string,
      name?: string,
      cif?: string,
      department?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/affiliate/cms/v1/getAll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (id !== undefined) {
        localVarQueryParameter["id"] = id;
      }

      if (code !== undefined) {
        localVarQueryParameter["code"] = code;
      }

      if (name !== undefined) {
        localVarQueryParameter["name"] = name;
      }

      if (cif !== undefined) {
        localVarQueryParameter["cif"] = cif;
      }

      if (department !== undefined) {
        localVarQueryParameter["department"] = department;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Chỉnh sửa một mã giới thiệu
     * @param {CmsUpdateAffiliateRequest} cmsUpdateAffiliateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsUpdateAffiliate: async (
      cmsUpdateAffiliateRequest: CmsUpdateAffiliateRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'cmsUpdateAffiliateRequest' is not null or undefined
      assertParamExists(
        "cmsUpdateAffiliate",
        "cmsUpdateAffiliateRequest",
        cmsUpdateAffiliateRequest,
      );
      const localVarPath = `/api/affiliate/cms/v1/update`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        cmsUpdateAffiliateRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xóa một mã giới thiệu
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAffiliate: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("deleteAffiliate", "id", id);
      const localVarPath = `/api/affiliate/cms/v1/delete/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy mã giới thiệu theo code
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAffiliateByCode: async (
      code: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists("getAffiliateByCode", "code", code);
      const localVarPath = `/api/affiliate/v1/get/{code}`.replace(
        `{${"code"}}`,
        encodeURIComponent(String(code)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AffiliateControllerApi - functional programming interface
 * @export
 */
export const AffiliateControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    AffiliateControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Tạo một mã giới thiệu
     * @param {CmsCreateAffiliateRequest} cmsCreateAffiliateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cmsCreateAffiliate(
      cmsCreateAffiliateRequest: CmsCreateAffiliateRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseAffiliateResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cmsCreateAffiliate(
          cmsCreateAffiliateRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy mã giới thiệu theo id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cmsGetAffiliateById(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseAffiliateResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cmsGetAffiliateById(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy tất cả mã giới thiệu
     * @param {string} [id]
     * @param {string} [code]
     * @param {string} [name]
     * @param {string} [cif]
     * @param {string} [department]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cmsGetAffiliates(
      id?: string,
      code?: string,
      name?: string,
      cif?: string,
      department?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseAffiliateResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cmsGetAffiliates(
          id,
          code,
          name,
          cif,
          department,
          page,
          size,
          sort,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Chỉnh sửa một mã giới thiệu
     * @param {CmsUpdateAffiliateRequest} cmsUpdateAffiliateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cmsUpdateAffiliate(
      cmsUpdateAffiliateRequest: CmsUpdateAffiliateRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseAffiliateResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cmsUpdateAffiliate(
          cmsUpdateAffiliateRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Xóa một mã giới thiệu
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteAffiliate(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAffiliate(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy mã giới thiệu theo code
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAffiliateByCode(
      code: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseAffiliateResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAffiliateByCode(code, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * AffiliateControllerApi - factory interface
 * @export
 */
export const AffiliateControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AffiliateControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Tạo một mã giới thiệu
     * @param {CmsCreateAffiliateRequest} cmsCreateAffiliateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsCreateAffiliate(
      cmsCreateAffiliateRequest: CmsCreateAffiliateRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseAffiliateResponse> {
      return localVarFp
        .cmsCreateAffiliate(cmsCreateAffiliateRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy mã giới thiệu theo id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsGetAffiliateById(
      id: number,
      options?: any,
    ): AxiosPromise<ResponseBaseAffiliateResponse> {
      return localVarFp
        .cmsGetAffiliateById(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy tất cả mã giới thiệu
     * @param {string} [id]
     * @param {string} [code]
     * @param {string} [name]
     * @param {string} [cif]
     * @param {string} [department]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsGetAffiliates(
      id?: string,
      code?: string,
      name?: string,
      cif?: string,
      department?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseAffiliateResponse> {
      return localVarFp
        .cmsGetAffiliates(
          id,
          code,
          name,
          cif,
          department,
          page,
          size,
          sort,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Chỉnh sửa một mã giới thiệu
     * @param {CmsUpdateAffiliateRequest} cmsUpdateAffiliateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsUpdateAffiliate(
      cmsUpdateAffiliateRequest: CmsUpdateAffiliateRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseAffiliateResponse> {
      return localVarFp
        .cmsUpdateAffiliate(cmsUpdateAffiliateRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xóa một mã giới thiệu
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAffiliate(
      id: number,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .deleteAffiliate(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy mã giới thiệu theo code
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAffiliateByCode(
      code: string,
      options?: any,
    ): AxiosPromise<ResponseBaseAffiliateResponse> {
      return localVarFp
        .getAffiliateByCode(code, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for cmsCreateAffiliate operation in AffiliateControllerApi.
 * @export
 * @interface AffiliateControllerApiCmsCreateAffiliateRequest
 */
export interface AffiliateControllerApiCmsCreateAffiliateRequest {
  /**
   *
   * @type {CmsCreateAffiliateRequest}
   * @memberof AffiliateControllerApiCmsCreateAffiliate
   */
  readonly cmsCreateAffiliateRequest: CmsCreateAffiliateRequest;
}

/**
 * Request parameters for cmsGetAffiliateById operation in AffiliateControllerApi.
 * @export
 * @interface AffiliateControllerApiCmsGetAffiliateByIdRequest
 */
export interface AffiliateControllerApiCmsGetAffiliateByIdRequest {
  /**
   *
   * @type {number}
   * @memberof AffiliateControllerApiCmsGetAffiliateById
   */
  readonly id: number;
}

/**
 * Request parameters for cmsGetAffiliates operation in AffiliateControllerApi.
 * @export
 * @interface AffiliateControllerApiCmsGetAffiliatesRequest
 */
export interface AffiliateControllerApiCmsGetAffiliatesRequest {
  /**
   *
   * @type {string}
   * @memberof AffiliateControllerApiCmsGetAffiliates
   */
  readonly id?: string;

  /**
   *
   * @type {string}
   * @memberof AffiliateControllerApiCmsGetAffiliates
   */
  readonly code?: string;

  /**
   *
   * @type {string}
   * @memberof AffiliateControllerApiCmsGetAffiliates
   */
  readonly name?: string;

  /**
   *
   * @type {string}
   * @memberof AffiliateControllerApiCmsGetAffiliates
   */
  readonly cif?: string;

  /**
   *
   * @type {string}
   * @memberof AffiliateControllerApiCmsGetAffiliates
   */
  readonly department?: string;

  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof AffiliateControllerApiCmsGetAffiliates
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof AffiliateControllerApiCmsGetAffiliates
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof AffiliateControllerApiCmsGetAffiliates
   */
  readonly sort?: Array<string>;
}

/**
 * Request parameters for cmsUpdateAffiliate operation in AffiliateControllerApi.
 * @export
 * @interface AffiliateControllerApiCmsUpdateAffiliateRequest
 */
export interface AffiliateControllerApiCmsUpdateAffiliateRequest {
  /**
   *
   * @type {CmsUpdateAffiliateRequest}
   * @memberof AffiliateControllerApiCmsUpdateAffiliate
   */
  readonly cmsUpdateAffiliateRequest: CmsUpdateAffiliateRequest;
}

/**
 * Request parameters for deleteAffiliate operation in AffiliateControllerApi.
 * @export
 * @interface AffiliateControllerApiDeleteAffiliateRequest
 */
export interface AffiliateControllerApiDeleteAffiliateRequest {
  /**
   *
   * @type {number}
   * @memberof AffiliateControllerApiDeleteAffiliate
   */
  readonly id: number;
}

/**
 * Request parameters for getAffiliateByCode operation in AffiliateControllerApi.
 * @export
 * @interface AffiliateControllerApiGetAffiliateByCodeRequest
 */
export interface AffiliateControllerApiGetAffiliateByCodeRequest {
  /**
   *
   * @type {string}
   * @memberof AffiliateControllerApiGetAffiliateByCode
   */
  readonly code: string;
}

/**
 * AffiliateControllerApi - object-oriented interface
 * @export
 * @class AffiliateControllerApi
 * @extends {BaseAPI}
 */
export class AffiliateControllerApi extends BaseAPI {
  /**
   *
   * @summary Tạo một mã giới thiệu
   * @param {AffiliateControllerApiCmsCreateAffiliateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AffiliateControllerApi
   */
  public cmsCreateAffiliate(
    requestParameters: AffiliateControllerApiCmsCreateAffiliateRequest,
    options?: AxiosRequestConfig,
  ) {
    return AffiliateControllerApiFp(this.configuration)
      .cmsCreateAffiliate(requestParameters.cmsCreateAffiliateRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy mã giới thiệu theo id
   * @param {AffiliateControllerApiCmsGetAffiliateByIdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AffiliateControllerApi
   */
  public cmsGetAffiliateById(
    requestParameters: AffiliateControllerApiCmsGetAffiliateByIdRequest,
    options?: AxiosRequestConfig,
  ) {
    return AffiliateControllerApiFp(this.configuration)
      .cmsGetAffiliateById(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy tất cả mã giới thiệu
   * @param {AffiliateControllerApiCmsGetAffiliatesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AffiliateControllerApi
   */
  public cmsGetAffiliates(
    requestParameters: AffiliateControllerApiCmsGetAffiliatesRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return AffiliateControllerApiFp(this.configuration)
      .cmsGetAffiliates(
        requestParameters.id,
        requestParameters.code,
        requestParameters.name,
        requestParameters.cif,
        requestParameters.department,
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Chỉnh sửa một mã giới thiệu
   * @param {AffiliateControllerApiCmsUpdateAffiliateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AffiliateControllerApi
   */
  public cmsUpdateAffiliate(
    requestParameters: AffiliateControllerApiCmsUpdateAffiliateRequest,
    options?: AxiosRequestConfig,
  ) {
    return AffiliateControllerApiFp(this.configuration)
      .cmsUpdateAffiliate(requestParameters.cmsUpdateAffiliateRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xóa một mã giới thiệu
   * @param {AffiliateControllerApiDeleteAffiliateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AffiliateControllerApi
   */
  public deleteAffiliate(
    requestParameters: AffiliateControllerApiDeleteAffiliateRequest,
    options?: AxiosRequestConfig,
  ) {
    return AffiliateControllerApiFp(this.configuration)
      .deleteAffiliate(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy mã giới thiệu theo code
   * @param {AffiliateControllerApiGetAffiliateByCodeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AffiliateControllerApi
   */
  public getAffiliateByCode(
    requestParameters: AffiliateControllerApiGetAffiliateByCodeRequest,
    options?: AxiosRequestConfig,
  ) {
    return AffiliateControllerApiFp(this.configuration)
      .getAffiliateByCode(requestParameters.code, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ApiReconciliationAtmCardFromNapasApi - axios parameter creator
 * @export
 */
export const ApiReconciliationAtmCardFromNapasApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Duyệt phiên đối soát
     * @param {string} sessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approveSession1: async (
      sessionId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'sessionId' is not null or undefined
      assertParamExists("approveSession1", "sessionId", sessionId);
      const localVarPath =
        `/api/napas/cms/reconciliation/v1/{sessionId}/approve`.replace(
          `{${"sessionId"}}`,
          encodeURIComponent(String(sessionId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Decrypt File PGP to DAT
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    decryptFile: async (
      file: File,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'file' is not null or undefined
      assertParamExists("decryptFile", "file", file);
      const localVarPath = `/api/napas/cms/reconciliation/v1/decryptFile`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (file !== undefined) {
        localVarFormParams.append("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xóa file từ tệp OUTBOX
     * @param {string} [fileName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFile: async (
      fileName?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/napas/cms/reconciliation/v1/delete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (fileName !== undefined) {
        localVarQueryParameter["fileName"] = fileName;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Tải về file thành công hoặc xử lý từ inbox
     * @param {string} [fileName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadInbox: async (
      fileName?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/napas/cms/reconciliation/v1/download`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (fileName !== undefined) {
        localVarQueryParameter["fileName"] = fileName;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Encrypt File DAT to PGP
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    encryptFile: async (
      file: File,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'file' is not null or undefined
      assertParamExists("encryptFile", "file", file);
      const localVarPath = `/api/napas/cms/reconciliation/v1/encryptFile`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (file !== undefined) {
        localVarFormParams.append("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy danh sách đối soát
     * @param {string} fileName
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReport: async (
      fileName: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'fileName' is not null or undefined
      assertParamExists("getReport", "fileName", fileName);
      const localVarPath = `/api/napas/cms/reconciliation/v1/getReport`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (fileName !== undefined) {
        localVarQueryParameter["fileName"] = fileName;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy danh sách file trong folder
     * @param {'INBOX' | 'OUTBOX'} folderType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listFile: async (
      folderType: "INBOX" | "OUTBOX",
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'folderType' is not null or undefined
      assertParamExists("listFile", "folderType", folderType);
      const localVarPath = `/api/napas/cms/reconciliation/v1/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (folderType !== undefined) {
        localVarQueryParameter["folderType"] = folderType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Tải file sai lệch lên outbox
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadOutbox: async (
      file: File,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'file' is not null or undefined
      assertParamExists("uploadOutbox", "file", file);
      const localVarPath = `/api/napas/cms/reconciliation/v1/upload`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (file !== undefined) {
        localVarFormParams.append("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy danh sách đối soát
     * @param {WriteSlRequest} writeSlRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    writeSl: async (
      writeSlRequest: WriteSlRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'writeSlRequest' is not null or undefined
      assertParamExists("writeSl", "writeSlRequest", writeSlRequest);
      const localVarPath = `/api/napas/cms/reconciliation/v1/writeSl`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        writeSlRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ApiReconciliationAtmCardFromNapasApi - functional programming interface
 * @export
 */
export const ApiReconciliationAtmCardFromNapasApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    ApiReconciliationAtmCardFromNapasApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Duyệt phiên đối soát
     * @param {string} sessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async approveSession1(
      sessionId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseApproveReconciliationSessionResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.approveSession1(
        sessionId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Decrypt File PGP to DAT
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async decryptFile(
      file: File,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.decryptFile(
        file,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Xóa file từ tệp OUTBOX
     * @param {string} [fileName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteFile(
      fileName?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseDeleteSftpFileResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFile(
        fileName,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Tải về file thành công hoặc xử lý từ inbox
     * @param {string} [fileName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async downloadInbox(
      fileName?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.downloadInbox(
        fileName,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Encrypt File DAT to PGP
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async encryptFile(
      file: File,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.encryptFile(
        file,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy danh sách đối soát
     * @param {string} fileName
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getReport(
      fileName: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseGetReportResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getReport(
        fileName,
        page,
        size,
        sort,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy danh sách file trong folder
     * @param {'INBOX' | 'OUTBOX'} folderType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listFile(
      folderType: "INBOX" | "OUTBOX",
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseListSftpFileResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listFile(
        folderType,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Tải file sai lệch lên outbox
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadOutbox(
      file: File,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseUploadSftpFileResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.uploadOutbox(
        file,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy danh sách đối soát
     * @param {WriteSlRequest} writeSlRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async writeSl(
      writeSlRequest: WriteSlRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.writeSl(
        writeSlRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * ApiReconciliationAtmCardFromNapasApi - factory interface
 * @export
 */
export const ApiReconciliationAtmCardFromNapasApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ApiReconciliationAtmCardFromNapasApiFp(configuration);
  return {
    /**
     *
     * @summary Duyệt phiên đối soát
     * @param {string} sessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approveSession1(
      sessionId: string,
      options?: any,
    ): AxiosPromise<ResponseBaseApproveReconciliationSessionResponse> {
      return localVarFp
        .approveSession1(sessionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Decrypt File PGP to DAT
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    decryptFile(file: File, options?: any): AxiosPromise<File> {
      return localVarFp
        .decryptFile(file, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xóa file từ tệp OUTBOX
     * @param {string} [fileName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFile(
      fileName?: string,
      options?: any,
    ): AxiosPromise<ResponseBaseDeleteSftpFileResponse> {
      return localVarFp
        .deleteFile(fileName, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Tải về file thành công hoặc xử lý từ inbox
     * @param {string} [fileName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadInbox(fileName?: string, options?: any): AxiosPromise<File> {
      return localVarFp
        .downloadInbox(fileName, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Encrypt File DAT to PGP
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    encryptFile(file: File, options?: any): AxiosPromise<File> {
      return localVarFp
        .encryptFile(file, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy danh sách đối soát
     * @param {string} fileName
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReport(
      fileName: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseGetReportResponse> {
      return localVarFp
        .getReport(fileName, page, size, sort, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy danh sách file trong folder
     * @param {'INBOX' | 'OUTBOX'} folderType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listFile(
      folderType: "INBOX" | "OUTBOX",
      options?: any,
    ): AxiosPromise<ResponseBaseListSftpFileResponse> {
      return localVarFp
        .listFile(folderType, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Tải file sai lệch lên outbox
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadOutbox(
      file: File,
      options?: any,
    ): AxiosPromise<ResponseBaseUploadSftpFileResponse> {
      return localVarFp
        .uploadOutbox(file, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy danh sách đối soát
     * @param {WriteSlRequest} writeSlRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    writeSl(writeSlRequest: WriteSlRequest, options?: any): AxiosPromise<File> {
      return localVarFp
        .writeSl(writeSlRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for approveSession1 operation in ApiReconciliationAtmCardFromNapasApi.
 * @export
 * @interface ApiReconciliationAtmCardFromNapasApiApproveSession1Request
 */
export interface ApiReconciliationAtmCardFromNapasApiApproveSession1Request {
  /**
   *
   * @type {string}
   * @memberof ApiReconciliationAtmCardFromNapasApiApproveSession1
   */
  readonly sessionId: string;
}

/**
 * Request parameters for decryptFile operation in ApiReconciliationAtmCardFromNapasApi.
 * @export
 * @interface ApiReconciliationAtmCardFromNapasApiDecryptFileRequest
 */
export interface ApiReconciliationAtmCardFromNapasApiDecryptFileRequest {
  /**
   *
   * @type {File}
   * @memberof ApiReconciliationAtmCardFromNapasApiDecryptFile
   */
  readonly file: File;
}

/**
 * Request parameters for deleteFile operation in ApiReconciliationAtmCardFromNapasApi.
 * @export
 * @interface ApiReconciliationAtmCardFromNapasApiDeleteFileRequest
 */
export interface ApiReconciliationAtmCardFromNapasApiDeleteFileRequest {
  /**
   *
   * @type {string}
   * @memberof ApiReconciliationAtmCardFromNapasApiDeleteFile
   */
  readonly fileName?: string;
}

/**
 * Request parameters for downloadInbox operation in ApiReconciliationAtmCardFromNapasApi.
 * @export
 * @interface ApiReconciliationAtmCardFromNapasApiDownloadInboxRequest
 */
export interface ApiReconciliationAtmCardFromNapasApiDownloadInboxRequest {
  /**
   *
   * @type {string}
   * @memberof ApiReconciliationAtmCardFromNapasApiDownloadInbox
   */
  readonly fileName?: string;
}

/**
 * Request parameters for encryptFile operation in ApiReconciliationAtmCardFromNapasApi.
 * @export
 * @interface ApiReconciliationAtmCardFromNapasApiEncryptFileRequest
 */
export interface ApiReconciliationAtmCardFromNapasApiEncryptFileRequest {
  /**
   *
   * @type {File}
   * @memberof ApiReconciliationAtmCardFromNapasApiEncryptFile
   */
  readonly file: File;
}

/**
 * Request parameters for getReport operation in ApiReconciliationAtmCardFromNapasApi.
 * @export
 * @interface ApiReconciliationAtmCardFromNapasApiGetReportRequest
 */
export interface ApiReconciliationAtmCardFromNapasApiGetReportRequest {
  /**
   *
   * @type {string}
   * @memberof ApiReconciliationAtmCardFromNapasApiGetReport
   */
  readonly fileName: string;

  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof ApiReconciliationAtmCardFromNapasApiGetReport
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof ApiReconciliationAtmCardFromNapasApiGetReport
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof ApiReconciliationAtmCardFromNapasApiGetReport
   */
  readonly sort?: Array<string>;
}

/**
 * Request parameters for listFile operation in ApiReconciliationAtmCardFromNapasApi.
 * @export
 * @interface ApiReconciliationAtmCardFromNapasApiListFileRequest
 */
export interface ApiReconciliationAtmCardFromNapasApiListFileRequest {
  /**
   *
   * @type {'INBOX' | 'OUTBOX'}
   * @memberof ApiReconciliationAtmCardFromNapasApiListFile
   */
  readonly folderType: "INBOX" | "OUTBOX";
}

/**
 * Request parameters for uploadOutbox operation in ApiReconciliationAtmCardFromNapasApi.
 * @export
 * @interface ApiReconciliationAtmCardFromNapasApiUploadOutboxRequest
 */
export interface ApiReconciliationAtmCardFromNapasApiUploadOutboxRequest {
  /**
   *
   * @type {File}
   * @memberof ApiReconciliationAtmCardFromNapasApiUploadOutbox
   */
  readonly file: File;
}

/**
 * Request parameters for writeSl operation in ApiReconciliationAtmCardFromNapasApi.
 * @export
 * @interface ApiReconciliationAtmCardFromNapasApiWriteSlRequest
 */
export interface ApiReconciliationAtmCardFromNapasApiWriteSlRequest {
  /**
   *
   * @type {WriteSlRequest}
   * @memberof ApiReconciliationAtmCardFromNapasApiWriteSl
   */
  readonly writeSlRequest: WriteSlRequest;
}

/**
 * ApiReconciliationAtmCardFromNapasApi - object-oriented interface
 * @export
 * @class ApiReconciliationAtmCardFromNapasApi
 * @extends {BaseAPI}
 */
export class ApiReconciliationAtmCardFromNapasApi extends BaseAPI {
  /**
   *
   * @summary Duyệt phiên đối soát
   * @param {ApiReconciliationAtmCardFromNapasApiApproveSession1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiReconciliationAtmCardFromNapasApi
   */
  public approveSession1(
    requestParameters: ApiReconciliationAtmCardFromNapasApiApproveSession1Request,
    options?: AxiosRequestConfig,
  ) {
    return ApiReconciliationAtmCardFromNapasApiFp(this.configuration)
      .approveSession1(requestParameters.sessionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Decrypt File PGP to DAT
   * @param {ApiReconciliationAtmCardFromNapasApiDecryptFileRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiReconciliationAtmCardFromNapasApi
   */
  public decryptFile(
    requestParameters: ApiReconciliationAtmCardFromNapasApiDecryptFileRequest,
    options?: AxiosRequestConfig,
  ) {
    return ApiReconciliationAtmCardFromNapasApiFp(this.configuration)
      .decryptFile(requestParameters.file, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xóa file từ tệp OUTBOX
   * @param {ApiReconciliationAtmCardFromNapasApiDeleteFileRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiReconciliationAtmCardFromNapasApi
   */
  public deleteFile(
    requestParameters: ApiReconciliationAtmCardFromNapasApiDeleteFileRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return ApiReconciliationAtmCardFromNapasApiFp(this.configuration)
      .deleteFile(requestParameters.fileName, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Tải về file thành công hoặc xử lý từ inbox
   * @param {ApiReconciliationAtmCardFromNapasApiDownloadInboxRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiReconciliationAtmCardFromNapasApi
   */
  public downloadInbox(
    requestParameters: ApiReconciliationAtmCardFromNapasApiDownloadInboxRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return ApiReconciliationAtmCardFromNapasApiFp(this.configuration)
      .downloadInbox(requestParameters.fileName, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Encrypt File DAT to PGP
   * @param {ApiReconciliationAtmCardFromNapasApiEncryptFileRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiReconciliationAtmCardFromNapasApi
   */
  public encryptFile(
    requestParameters: ApiReconciliationAtmCardFromNapasApiEncryptFileRequest,
    options?: AxiosRequestConfig,
  ) {
    return ApiReconciliationAtmCardFromNapasApiFp(this.configuration)
      .encryptFile(requestParameters.file, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy danh sách đối soát
   * @param {ApiReconciliationAtmCardFromNapasApiGetReportRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiReconciliationAtmCardFromNapasApi
   */
  public getReport(
    requestParameters: ApiReconciliationAtmCardFromNapasApiGetReportRequest,
    options?: AxiosRequestConfig,
  ) {
    return ApiReconciliationAtmCardFromNapasApiFp(this.configuration)
      .getReport(
        requestParameters.fileName,
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy danh sách file trong folder
   * @param {ApiReconciliationAtmCardFromNapasApiListFileRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiReconciliationAtmCardFromNapasApi
   */
  public listFile(
    requestParameters: ApiReconciliationAtmCardFromNapasApiListFileRequest,
    options?: AxiosRequestConfig,
  ) {
    return ApiReconciliationAtmCardFromNapasApiFp(this.configuration)
      .listFile(requestParameters.folderType, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Tải file sai lệch lên outbox
   * @param {ApiReconciliationAtmCardFromNapasApiUploadOutboxRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiReconciliationAtmCardFromNapasApi
   */
  public uploadOutbox(
    requestParameters: ApiReconciliationAtmCardFromNapasApiUploadOutboxRequest,
    options?: AxiosRequestConfig,
  ) {
    return ApiReconciliationAtmCardFromNapasApiFp(this.configuration)
      .uploadOutbox(requestParameters.file, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy danh sách đối soát
   * @param {ApiReconciliationAtmCardFromNapasApiWriteSlRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiReconciliationAtmCardFromNapasApi
   */
  public writeSl(
    requestParameters: ApiReconciliationAtmCardFromNapasApiWriteSlRequest,
    options?: AxiosRequestConfig,
  ) {
    return ApiReconciliationAtmCardFromNapasApiFp(this.configuration)
      .writeSl(requestParameters.writeSlRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AppStoreControllerApi - axios parameter creator
 * @export
 */
export const AppStoreControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Disable ứng dụng của merchant
     * @param {RegisterAppRequest} registerAppRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    disableApp: async (
      registerAppRequest: RegisterAppRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'registerAppRequest' is not null or undefined
      assertParamExists("disableApp", "registerAppRequest", registerAppRequest);
      const localVarPath = `/api/store/cms/v1/disableApp`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        registerAppRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Enable ứng dụng của merchant
     * @param {RegisterAppRequest} registerAppRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    enableApp: async (
      registerAppRequest: RegisterAppRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'registerAppRequest' is not null or undefined
      assertParamExists("enableApp", "registerAppRequest", registerAppRequest);
      const localVarPath = `/api/store/cms/v1/enableApp`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        registerAppRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy danh sách ứng dụng
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAppList: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/store/internal/v1/getAppList`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Đăng nhập vào ứng dụng
     * @param {LoginAppRequest} loginAppRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    login: async (
      loginAppRequest: LoginAppRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'loginAppRequest' is not null or undefined
      assertParamExists("login", "loginAppRequest", loginAppRequest);
      const localVarPath = `/api/store/internal/v1/login`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        loginAppRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Đăng ký ứng dụng của merchant
     * @param {RegisterAppRequest} registerAppRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registerApp: async (
      registerAppRequest: RegisterAppRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'registerAppRequest' is not null or undefined
      assertParamExists(
        "registerApp",
        "registerAppRequest",
        registerAppRequest,
      );
      const localVarPath = `/api/store/cms/v1/registerApp`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        registerAppRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Giải mã thông tin thanh toán
     * @param {ResolvePaymentDataRequest} resolvePaymentDataRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resolvePaymentData: async (
      resolvePaymentDataRequest: ResolvePaymentDataRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'resolvePaymentDataRequest' is not null or undefined
      assertParamExists(
        "resolvePaymentData",
        "resolvePaymentDataRequest",
        resolvePaymentDataRequest,
      );
      const localVarPath = `/api/store/internal/v1/resolvePaymentData`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        resolvePaymentDataRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Cập nhật ứng dụng của merchant
     * @param {RegisterAppRequest} registerAppRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateApp: async (
      registerAppRequest: RegisterAppRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'registerAppRequest' is not null or undefined
      assertParamExists("updateApp", "registerAppRequest", registerAppRequest);
      const localVarPath = `/api/store/cms/v1/updateApp`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        registerAppRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AppStoreControllerApi - functional programming interface
 * @export
 */
export const AppStoreControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    AppStoreControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Disable ứng dụng của merchant
     * @param {RegisterAppRequest} registerAppRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async disableApp(
      registerAppRequest: RegisterAppRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseRegisterAppResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.disableApp(
        registerAppRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Enable ứng dụng của merchant
     * @param {RegisterAppRequest} registerAppRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async enableApp(
      registerAppRequest: RegisterAppRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseRegisterAppResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.enableApp(
        registerAppRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy danh sách ứng dụng
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAppList(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetListAppResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAppList(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Đăng nhập vào ứng dụng
     * @param {LoginAppRequest} loginAppRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async login(
      loginAppRequest: LoginAppRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseLoginAppResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.login(
        loginAppRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Đăng ký ứng dụng của merchant
     * @param {RegisterAppRequest} registerAppRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async registerApp(
      registerAppRequest: RegisterAppRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseRegisterAppResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.registerApp(
        registerAppRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Giải mã thông tin thanh toán
     * @param {ResolvePaymentDataRequest} resolvePaymentDataRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async resolvePaymentData(
      resolvePaymentDataRequest: ResolvePaymentDataRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseResolvePaymentDataResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.resolvePaymentData(
          resolvePaymentDataRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Cập nhật ứng dụng của merchant
     * @param {RegisterAppRequest} registerAppRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateApp(
      registerAppRequest: RegisterAppRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseRegisterAppResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateApp(
        registerAppRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * AppStoreControllerApi - factory interface
 * @export
 */
export const AppStoreControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AppStoreControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Disable ứng dụng của merchant
     * @param {RegisterAppRequest} registerAppRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    disableApp(
      registerAppRequest: RegisterAppRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseRegisterAppResponse> {
      return localVarFp
        .disableApp(registerAppRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Enable ứng dụng của merchant
     * @param {RegisterAppRequest} registerAppRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    enableApp(
      registerAppRequest: RegisterAppRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseRegisterAppResponse> {
      return localVarFp
        .enableApp(registerAppRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy danh sách ứng dụng
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAppList(options?: any): AxiosPromise<ResponseBaseGetListAppResponse> {
      return localVarFp
        .getAppList(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Đăng nhập vào ứng dụng
     * @param {LoginAppRequest} loginAppRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    login(
      loginAppRequest: LoginAppRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseLoginAppResponse> {
      return localVarFp
        .login(loginAppRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Đăng ký ứng dụng của merchant
     * @param {RegisterAppRequest} registerAppRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registerApp(
      registerAppRequest: RegisterAppRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseRegisterAppResponse> {
      return localVarFp
        .registerApp(registerAppRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Giải mã thông tin thanh toán
     * @param {ResolvePaymentDataRequest} resolvePaymentDataRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resolvePaymentData(
      resolvePaymentDataRequest: ResolvePaymentDataRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseResolvePaymentDataResponse> {
      return localVarFp
        .resolvePaymentData(resolvePaymentDataRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Cập nhật ứng dụng của merchant
     * @param {RegisterAppRequest} registerAppRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateApp(
      registerAppRequest: RegisterAppRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseRegisterAppResponse> {
      return localVarFp
        .updateApp(registerAppRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for disableApp operation in AppStoreControllerApi.
 * @export
 * @interface AppStoreControllerApiDisableAppRequest
 */
export interface AppStoreControllerApiDisableAppRequest {
  /**
   *
   * @type {RegisterAppRequest}
   * @memberof AppStoreControllerApiDisableApp
   */
  readonly registerAppRequest: RegisterAppRequest;
}

/**
 * Request parameters for enableApp operation in AppStoreControllerApi.
 * @export
 * @interface AppStoreControllerApiEnableAppRequest
 */
export interface AppStoreControllerApiEnableAppRequest {
  /**
   *
   * @type {RegisterAppRequest}
   * @memberof AppStoreControllerApiEnableApp
   */
  readonly registerAppRequest: RegisterAppRequest;
}

/**
 * Request parameters for login operation in AppStoreControllerApi.
 * @export
 * @interface AppStoreControllerApiLoginRequest
 */
export interface AppStoreControllerApiLoginRequest {
  /**
   *
   * @type {LoginAppRequest}
   * @memberof AppStoreControllerApiLogin
   */
  readonly loginAppRequest: LoginAppRequest;
}

/**
 * Request parameters for registerApp operation in AppStoreControllerApi.
 * @export
 * @interface AppStoreControllerApiRegisterAppRequest
 */
export interface AppStoreControllerApiRegisterAppRequest {
  /**
   *
   * @type {RegisterAppRequest}
   * @memberof AppStoreControllerApiRegisterApp
   */
  readonly registerAppRequest: RegisterAppRequest;
}

/**
 * Request parameters for resolvePaymentData operation in AppStoreControllerApi.
 * @export
 * @interface AppStoreControllerApiResolvePaymentDataRequest
 */
export interface AppStoreControllerApiResolvePaymentDataRequest {
  /**
   *
   * @type {ResolvePaymentDataRequest}
   * @memberof AppStoreControllerApiResolvePaymentData
   */
  readonly resolvePaymentDataRequest: ResolvePaymentDataRequest;
}

/**
 * Request parameters for updateApp operation in AppStoreControllerApi.
 * @export
 * @interface AppStoreControllerApiUpdateAppRequest
 */
export interface AppStoreControllerApiUpdateAppRequest {
  /**
   *
   * @type {RegisterAppRequest}
   * @memberof AppStoreControllerApiUpdateApp
   */
  readonly registerAppRequest: RegisterAppRequest;
}

/**
 * AppStoreControllerApi - object-oriented interface
 * @export
 * @class AppStoreControllerApi
 * @extends {BaseAPI}
 */
export class AppStoreControllerApi extends BaseAPI {
  /**
   *
   * @summary Disable ứng dụng của merchant
   * @param {AppStoreControllerApiDisableAppRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppStoreControllerApi
   */
  public disableApp(
    requestParameters: AppStoreControllerApiDisableAppRequest,
    options?: AxiosRequestConfig,
  ) {
    return AppStoreControllerApiFp(this.configuration)
      .disableApp(requestParameters.registerAppRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Enable ứng dụng của merchant
   * @param {AppStoreControllerApiEnableAppRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppStoreControllerApi
   */
  public enableApp(
    requestParameters: AppStoreControllerApiEnableAppRequest,
    options?: AxiosRequestConfig,
  ) {
    return AppStoreControllerApiFp(this.configuration)
      .enableApp(requestParameters.registerAppRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy danh sách ứng dụng
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppStoreControllerApi
   */
  public getAppList(options?: AxiosRequestConfig) {
    return AppStoreControllerApiFp(this.configuration)
      .getAppList(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Đăng nhập vào ứng dụng
   * @param {AppStoreControllerApiLoginRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppStoreControllerApi
   */
  public login(
    requestParameters: AppStoreControllerApiLoginRequest,
    options?: AxiosRequestConfig,
  ) {
    return AppStoreControllerApiFp(this.configuration)
      .login(requestParameters.loginAppRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Đăng ký ứng dụng của merchant
   * @param {AppStoreControllerApiRegisterAppRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppStoreControllerApi
   */
  public registerApp(
    requestParameters: AppStoreControllerApiRegisterAppRequest,
    options?: AxiosRequestConfig,
  ) {
    return AppStoreControllerApiFp(this.configuration)
      .registerApp(requestParameters.registerAppRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Giải mã thông tin thanh toán
   * @param {AppStoreControllerApiResolvePaymentDataRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppStoreControllerApi
   */
  public resolvePaymentData(
    requestParameters: AppStoreControllerApiResolvePaymentDataRequest,
    options?: AxiosRequestConfig,
  ) {
    return AppStoreControllerApiFp(this.configuration)
      .resolvePaymentData(requestParameters.resolvePaymentDataRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Cập nhật ứng dụng của merchant
   * @param {AppStoreControllerApiUpdateAppRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppStoreControllerApi
   */
  public updateApp(
    requestParameters: AppStoreControllerApiUpdateAppRequest,
    options?: AxiosRequestConfig,
  ) {
    return AppStoreControllerApiFp(this.configuration)
      .updateApp(requestParameters.registerAppRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AuditControllerApi - axios parameter creator
 * @export
 */
export const AuditControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Lấy version của 1 module
     * @param {'RESPONSE_MESSAGE' | 'SYSTEM_PARAMETER'} module
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCurrentVersion: async (
      module: "RESPONSE_MESSAGE" | "SYSTEM_PARAMETER",
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'module' is not null or undefined
      assertParamExists("getCurrentVersion", "module", module);
      const localVarPath = `/api/audit/v1/currentVersion`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (module !== undefined) {
        localVarQueryParameter["module"] = module;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AuditControllerApi - functional programming interface
 * @export
 */
export const AuditControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    AuditControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Lấy version của 1 module
     * @param {'RESPONSE_MESSAGE' | 'SYSTEM_PARAMETER'} module
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCurrentVersion(
      module: "RESPONSE_MESSAGE" | "SYSTEM_PARAMETER",
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseAuditVersionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCurrentVersion(module, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * AuditControllerApi - factory interface
 * @export
 */
export const AuditControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AuditControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Lấy version của 1 module
     * @param {'RESPONSE_MESSAGE' | 'SYSTEM_PARAMETER'} module
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCurrentVersion(
      module: "RESPONSE_MESSAGE" | "SYSTEM_PARAMETER",
      options?: any,
    ): AxiosPromise<ResponseBaseAuditVersionResponse> {
      return localVarFp
        .getCurrentVersion(module, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getCurrentVersion operation in AuditControllerApi.
 * @export
 * @interface AuditControllerApiGetCurrentVersionRequest
 */
export interface AuditControllerApiGetCurrentVersionRequest {
  /**
   *
   * @type {'RESPONSE_MESSAGE' | 'SYSTEM_PARAMETER'}
   * @memberof AuditControllerApiGetCurrentVersion
   */
  readonly module: "RESPONSE_MESSAGE" | "SYSTEM_PARAMETER";
}

/**
 * AuditControllerApi - object-oriented interface
 * @export
 * @class AuditControllerApi
 * @extends {BaseAPI}
 */
export class AuditControllerApi extends BaseAPI {
  /**
   *
   * @summary Lấy version của 1 module
   * @param {AuditControllerApiGetCurrentVersionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuditControllerApi
   */
  public getCurrentVersion(
    requestParameters: AuditControllerApiGetCurrentVersionRequest,
    options?: AxiosRequestConfig,
  ) {
    return AuditControllerApiFp(this.configuration)
      .getCurrentVersion(requestParameters.module, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AuthControllerApi - axios parameter creator
 * @export
 */
export const AuthControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * - Change admin password
     * @summary Change admin password
     * @param {AdminChangePasswordRequest} adminChangePasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminChangePassword: async (
      adminChangePasswordRequest: AdminChangePasswordRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'adminChangePasswordRequest' is not null or undefined
      assertParamExists(
        "adminChangePassword",
        "adminChangePasswordRequest",
        adminChangePasswordRequest,
      );
      const localVarPath = `/api/auth/cms/v1/changePassword`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        adminChangePasswordRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Quản trị viên login với số username và password được cấp
     * @summary Administrator login
     * @param {AdminLoginRequest} adminLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminLogin: async (
      adminLoginRequest: AdminLoginRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'adminLoginRequest' is not null or undefined
      assertParamExists("adminLogin", "adminLoginRequest", adminLoginRequest);
      const localVarPath = `/api/auth/cms/v1/login`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        adminLoginRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Admin logout
     * @summary Admin logout
     * @param {AdminLogoutRequest} adminLogoutRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminLogout: async (
      adminLogoutRequest: AdminLogoutRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'adminLogoutRequest' is not null or undefined
      assertParamExists(
        "adminLogout",
        "adminLogoutRequest",
        adminLogoutRequest,
      );
      const localVarPath = `/api/auth/cms/v1/logout`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        adminLogoutRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Client login oauth
     * @summary Client login
     * @param {ClientLoginRequest} clientLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientLogin: async (
      clientLoginRequest: ClientLoginRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'clientLoginRequest' is not null or undefined
      assertParamExists(
        "clientLogin",
        "clientLoginRequest",
        clientLoginRequest,
      );
      const localVarPath = `/api/auth/client/v1/login`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        clientLoginRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Refresh access token
     * @summary Refresh access token
     * @param {AdminRefreshTokenRequest} adminRefreshTokenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsRefreshToken: async (
      adminRefreshTokenRequest: AdminRefreshTokenRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'adminRefreshTokenRequest' is not null or undefined
      assertParamExists(
        "cmsRefreshToken",
        "adminRefreshTokenRequest",
        adminRefreshTokenRequest,
      );
      const localVarPath = `/api/auth/cms/v1/refreshToken`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        adminRefreshTokenRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Emergency Lock Account
     * @param {EmergencyLockAccountRequest} emergencyLockAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    emergencyLockAccount: async (
      emergencyLockAccountRequest: EmergencyLockAccountRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'emergencyLockAccountRequest' is not null or undefined
      assertParamExists(
        "emergencyLockAccount",
        "emergencyLockAccountRequest",
        emergencyLockAccountRequest,
      );
      const localVarPath = `/api/auth/emergencyLockAccount`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        emergencyLockAccountRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Lấy profile user đang đăng nhập
     * @summary Lấy profile user đang đăng nhập
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAdminProfile: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/auth/cms/v1/profile`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Lấy profile user đang đăng nhập
     * @summary Lấy profile user đang đăng nhập
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMerchantId: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/auth/v1/getMerchantId`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary logout
     * @param {LogoutRequest} logoutRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logout: async (
      logoutRequest: LogoutRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'logoutRequest' is not null or undefined
      assertParamExists("logout", "logoutRequest", logoutRequest);
      const localVarPath = `/api/auth/v1/logout`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        logoutRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Login với merchantId, phone, password. Nhận OTP
     * @summary [Portal Member][Login] Step 1 - Get OTP
     * @param {PortalMemberLoginRequest} portalMemberLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    portalMemberLogin: async (
      portalMemberLoginRequest: PortalMemberLoginRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'portalMemberLoginRequest' is not null or undefined
      assertParamExists(
        "portalMemberLogin",
        "portalMemberLoginRequest",
        portalMemberLoginRequest,
      );
      const localVarPath = `/api/auth/portal/member/v1/login`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        portalMemberLoginRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Xác thực OTP để đăng nhập
     * @summary [Portal Member][Login] Step 2 - Verify OTP
     * @param {PortalMemberVerifyOTPLoginRequest} portalMemberVerifyOTPLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    portalMemberLoginCheckOTP: async (
      portalMemberVerifyOTPLoginRequest: PortalMemberVerifyOTPLoginRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'portalMemberVerifyOTPLoginRequest' is not null or undefined
      assertParamExists(
        "portalMemberLoginCheckOTP",
        "portalMemberVerifyOTPLoginRequest",
        portalMemberVerifyOTPLoginRequest,
      );
      const localVarPath = `/api/auth/portal/member/v1/verifyOtp`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        portalMemberVerifyOTPLoginRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [Portal Member][Reset Password] Step 3: Reset Password
     * @param {PortalMemberResetPassRequest} portalMemberResetPassRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    portalMemberResetPass: async (
      portalMemberResetPassRequest: PortalMemberResetPassRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'portalMemberResetPassRequest' is not null or undefined
      assertParamExists(
        "portalMemberResetPass",
        "portalMemberResetPassRequest",
        portalMemberResetPassRequest,
      );
      const localVarPath = `/api/auth/portal/member/v1/resetPassword/submit`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        portalMemberResetPassRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [Portal Member][Reset Password] Step 1: Get OTP
     * @param {PortalMemberResetPassGetOTPRequest} portalMemberResetPassGetOTPRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    portalMemberResetPassGetOtp: async (
      portalMemberResetPassGetOTPRequest: PortalMemberResetPassGetOTPRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'portalMemberResetPassGetOTPRequest' is not null or undefined
      assertParamExists(
        "portalMemberResetPassGetOtp",
        "portalMemberResetPassGetOTPRequest",
        portalMemberResetPassGetOTPRequest,
      );
      const localVarPath = `/api/auth/portal/member/v1/resetPassword/getOtp`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        portalMemberResetPassGetOTPRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [Portal Member][Reset Password] Step 2: Verify OTP
     * @param {PortalMemberResetPassVerifyOTPRequest} portalMemberResetPassVerifyOTPRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    portalMemberResetPassVerifyOtp: async (
      portalMemberResetPassVerifyOTPRequest: PortalMemberResetPassVerifyOTPRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'portalMemberResetPassVerifyOTPRequest' is not null or undefined
      assertParamExists(
        "portalMemberResetPassVerifyOtp",
        "portalMemberResetPassVerifyOTPRequest",
        portalMemberResetPassVerifyOTPRequest,
      );
      const localVarPath = `/api/auth/portal/member/v1/resetPassword/verifyOtp`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        portalMemberResetPassVerifyOTPRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - refresh access token
     * @summary User refresh access token
     * @param {PortalRefreshTokenRequest} portalRefreshTokenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshToken: async (
      portalRefreshTokenRequest: PortalRefreshTokenRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'portalRefreshTokenRequest' is not null or undefined
      assertParamExists(
        "refreshToken",
        "portalRefreshTokenRequest",
        portalRefreshTokenRequest,
      );
      const localVarPath = `/api/auth/portal/v1/refreshToken`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        portalRefreshTokenRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Super admin login
     * @summary Super admin login
     * @param {RootAdminLoginRequest} rootAdminLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rootLogin: async (
      rootAdminLoginRequest: RootAdminLoginRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'rootAdminLoginRequest' is not null or undefined
      assertParamExists(
        "rootLogin",
        "rootAdminLoginRequest",
        rootAdminLoginRequest,
      );
      const localVarPath = `/api/auth/root/v1/login`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        rootAdminLoginRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Update profile user đang đăng nhập
     * @summary Update profile user đang đăng nhập
     * @param {UpdateAdminProfileRequest} updateAdminProfileRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAdminProfile: async (
      updateAdminProfileRequest: UpdateAdminProfileRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateAdminProfileRequest' is not null or undefined
      assertParamExists(
        "updateAdminProfile",
        "updateAdminProfileRequest",
        updateAdminProfileRequest,
      );
      const localVarPath = `/api/auth/cms/v1/profile`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateAdminProfileRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Xác thực OTP để đăng nhập
     * @summary User login Step 2 - Verify OTP
     * @param {PortalVerifyOTPLoginRequest} portalVerifyOTPLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userLoginCheckOTPV2: async (
      portalVerifyOTPLoginRequest: PortalVerifyOTPLoginRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'portalVerifyOTPLoginRequest' is not null or undefined
      assertParamExists(
        "userLoginCheckOTPV2",
        "portalVerifyOTPLoginRequest",
        portalVerifyOTPLoginRequest,
      );
      const localVarPath = `/api/auth/portal/v2/verifyOtp`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        portalVerifyOTPLoginRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - login với username là số điện thoại. Nhận OTP
     * @summary User login step 1 - Get OTP
     * @param {PortalLoginRequest} portalLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userLoginV2: async (
      portalLoginRequest: PortalLoginRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'portalLoginRequest' is not null or undefined
      assertParamExists(
        "userLoginV2",
        "portalLoginRequest",
        portalLoginRequest,
      );
      const localVarPath = `/api/auth/portal/v2/login`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        portalLoginRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AuthControllerApi - functional programming interface
 * @export
 */
export const AuthControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    AuthControllerApiAxiosParamCreator(configuration);
  return {
    /**
     * - Change admin password
     * @summary Change admin password
     * @param {AdminChangePasswordRequest} adminChangePasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminChangePassword(
      adminChangePasswordRequest: AdminChangePasswordRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminChangePassword(
          adminChangePasswordRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Quản trị viên login với số username và password được cấp
     * @summary Administrator login
     * @param {AdminLoginRequest} adminLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminLogin(
      adminLoginRequest: AdminLoginRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseAccessTokenResponseCustom>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminLogin(
        adminLoginRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Admin logout
     * @summary Admin logout
     * @param {AdminLogoutRequest} adminLogoutRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminLogout(
      adminLogoutRequest: AdminLogoutRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminLogout(
        adminLogoutRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Client login oauth
     * @summary Client login
     * @param {ClientLoginRequest} clientLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientLogin(
      clientLoginRequest: ClientLoginRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseAccessTokenResponseCustom>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientLogin(
        clientLoginRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Refresh access token
     * @summary Refresh access token
     * @param {AdminRefreshTokenRequest} adminRefreshTokenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cmsRefreshToken(
      adminRefreshTokenRequest: AdminRefreshTokenRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseAccessTokenResponseCustom>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.cmsRefreshToken(
        adminRefreshTokenRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Emergency Lock Account
     * @param {EmergencyLockAccountRequest} emergencyLockAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async emergencyLockAccount(
      emergencyLockAccountRequest: EmergencyLockAccountRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.emergencyLockAccount(
          emergencyLockAccountRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Lấy profile user đang đăng nhập
     * @summary Lấy profile user đang đăng nhập
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAdminProfile(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseAdminDetailResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAdminProfile(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Lấy profile user đang đăng nhập
     * @summary Lấy profile user đang đăng nhập
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMerchantId(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetMerchantIdResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getMerchantId(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary logout
     * @param {LogoutRequest} logoutRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async logout(
      logoutRequest: LogoutRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseLogoutResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.logout(
        logoutRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Login với merchantId, phone, password. Nhận OTP
     * @summary [Portal Member][Login] Step 1 - Get OTP
     * @param {PortalMemberLoginRequest} portalMemberLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async portalMemberLogin(
      portalMemberLoginRequest: PortalMemberLoginRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePortalMemberLoginOTPResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.portalMemberLogin(
          portalMemberLoginRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Xác thực OTP để đăng nhập
     * @summary [Portal Member][Login] Step 2 - Verify OTP
     * @param {PortalMemberVerifyOTPLoginRequest} portalMemberVerifyOTPLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async portalMemberLoginCheckOTP(
      portalMemberVerifyOTPLoginRequest: PortalMemberVerifyOTPLoginRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseAccessTokenResponseCustom>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.portalMemberLoginCheckOTP(
          portalMemberVerifyOTPLoginRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary [Portal Member][Reset Password] Step 3: Reset Password
     * @param {PortalMemberResetPassRequest} portalMemberResetPassRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async portalMemberResetPass(
      portalMemberResetPassRequest: PortalMemberResetPassRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.portalMemberResetPass(
          portalMemberResetPassRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary [Portal Member][Reset Password] Step 1: Get OTP
     * @param {PortalMemberResetPassGetOTPRequest} portalMemberResetPassGetOTPRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async portalMemberResetPassGetOtp(
      portalMemberResetPassGetOTPRequest: PortalMemberResetPassGetOTPRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePortalMemberResetPassGetOTPResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.portalMemberResetPassGetOtp(
          portalMemberResetPassGetOTPRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary [Portal Member][Reset Password] Step 2: Verify OTP
     * @param {PortalMemberResetPassVerifyOTPRequest} portalMemberResetPassVerifyOTPRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async portalMemberResetPassVerifyOtp(
      portalMemberResetPassVerifyOTPRequest: PortalMemberResetPassVerifyOTPRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseForgotPasswordCheckOTPResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.portalMemberResetPassVerifyOtp(
          portalMemberResetPassVerifyOTPRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - refresh access token
     * @summary User refresh access token
     * @param {PortalRefreshTokenRequest} portalRefreshTokenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async refreshToken(
      portalRefreshTokenRequest: PortalRefreshTokenRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseAccessTokenResponseCustom>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.refreshToken(
        portalRefreshTokenRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Super admin login
     * @summary Super admin login
     * @param {RootAdminLoginRequest} rootAdminLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async rootLogin(
      rootAdminLoginRequest: RootAdminLoginRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseAccessTokenResponseCustom>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.rootLogin(
        rootAdminLoginRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Update profile user đang đăng nhập
     * @summary Update profile user đang đăng nhập
     * @param {UpdateAdminProfileRequest} updateAdminProfileRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateAdminProfile(
      updateAdminProfileRequest: UpdateAdminProfileRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseAdminDetailResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateAdminProfile(
          updateAdminProfileRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Xác thực OTP để đăng nhập
     * @summary User login Step 2 - Verify OTP
     * @param {PortalVerifyOTPLoginRequest} portalVerifyOTPLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userLoginCheckOTPV2(
      portalVerifyOTPLoginRequest: PortalVerifyOTPLoginRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseAccessTokenResponseCustom>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.userLoginCheckOTPV2(
          portalVerifyOTPLoginRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - login với username là số điện thoại. Nhận OTP
     * @summary User login step 1 - Get OTP
     * @param {PortalLoginRequest} portalLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userLoginV2(
      portalLoginRequest: PortalLoginRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePortalLoginOTPResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userLoginV2(
        portalLoginRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * AuthControllerApi - factory interface
 * @export
 */
export const AuthControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AuthControllerApiFp(configuration);
  return {
    /**
     * - Change admin password
     * @summary Change admin password
     * @param {AdminChangePasswordRequest} adminChangePasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminChangePassword(
      adminChangePasswordRequest: AdminChangePasswordRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .adminChangePassword(adminChangePasswordRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Quản trị viên login với số username và password được cấp
     * @summary Administrator login
     * @param {AdminLoginRequest} adminLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminLogin(
      adminLoginRequest: AdminLoginRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseAccessTokenResponseCustom> {
      return localVarFp
        .adminLogin(adminLoginRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Admin logout
     * @summary Admin logout
     * @param {AdminLogoutRequest} adminLogoutRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminLogout(
      adminLogoutRequest: AdminLogoutRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .adminLogout(adminLogoutRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Client login oauth
     * @summary Client login
     * @param {ClientLoginRequest} clientLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientLogin(
      clientLoginRequest: ClientLoginRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseAccessTokenResponseCustom> {
      return localVarFp
        .clientLogin(clientLoginRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Refresh access token
     * @summary Refresh access token
     * @param {AdminRefreshTokenRequest} adminRefreshTokenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsRefreshToken(
      adminRefreshTokenRequest: AdminRefreshTokenRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseAccessTokenResponseCustom> {
      return localVarFp
        .cmsRefreshToken(adminRefreshTokenRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Emergency Lock Account
     * @param {EmergencyLockAccountRequest} emergencyLockAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    emergencyLockAccount(
      emergencyLockAccountRequest: EmergencyLockAccountRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .emergencyLockAccount(emergencyLockAccountRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Lấy profile user đang đăng nhập
     * @summary Lấy profile user đang đăng nhập
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAdminProfile(
      options?: any,
    ): AxiosPromise<ResponseBaseAdminDetailResponse> {
      return localVarFp
        .getAdminProfile(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Lấy profile user đang đăng nhập
     * @summary Lấy profile user đang đăng nhập
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMerchantId(
      options?: any,
    ): AxiosPromise<ResponseBaseGetMerchantIdResponse> {
      return localVarFp
        .getMerchantId(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary logout
     * @param {LogoutRequest} logoutRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logout(
      logoutRequest: LogoutRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseLogoutResponse> {
      return localVarFp
        .logout(logoutRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Login với merchantId, phone, password. Nhận OTP
     * @summary [Portal Member][Login] Step 1 - Get OTP
     * @param {PortalMemberLoginRequest} portalMemberLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    portalMemberLogin(
      portalMemberLoginRequest: PortalMemberLoginRequest,
      options?: any,
    ): AxiosPromise<ResponseBasePortalMemberLoginOTPResponse> {
      return localVarFp
        .portalMemberLogin(portalMemberLoginRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Xác thực OTP để đăng nhập
     * @summary [Portal Member][Login] Step 2 - Verify OTP
     * @param {PortalMemberVerifyOTPLoginRequest} portalMemberVerifyOTPLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    portalMemberLoginCheckOTP(
      portalMemberVerifyOTPLoginRequest: PortalMemberVerifyOTPLoginRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseAccessTokenResponseCustom> {
      return localVarFp
        .portalMemberLoginCheckOTP(portalMemberVerifyOTPLoginRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [Portal Member][Reset Password] Step 3: Reset Password
     * @param {PortalMemberResetPassRequest} portalMemberResetPassRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    portalMemberResetPass(
      portalMemberResetPassRequest: PortalMemberResetPassRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .portalMemberResetPass(portalMemberResetPassRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [Portal Member][Reset Password] Step 1: Get OTP
     * @param {PortalMemberResetPassGetOTPRequest} portalMemberResetPassGetOTPRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    portalMemberResetPassGetOtp(
      portalMemberResetPassGetOTPRequest: PortalMemberResetPassGetOTPRequest,
      options?: any,
    ): AxiosPromise<ResponseBasePortalMemberResetPassGetOTPResponse> {
      return localVarFp
        .portalMemberResetPassGetOtp(
          portalMemberResetPassGetOTPRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [Portal Member][Reset Password] Step 2: Verify OTP
     * @param {PortalMemberResetPassVerifyOTPRequest} portalMemberResetPassVerifyOTPRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    portalMemberResetPassVerifyOtp(
      portalMemberResetPassVerifyOTPRequest: PortalMemberResetPassVerifyOTPRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseForgotPasswordCheckOTPResponse> {
      return localVarFp
        .portalMemberResetPassVerifyOtp(
          portalMemberResetPassVerifyOTPRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * - refresh access token
     * @summary User refresh access token
     * @param {PortalRefreshTokenRequest} portalRefreshTokenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshToken(
      portalRefreshTokenRequest: PortalRefreshTokenRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseAccessTokenResponseCustom> {
      return localVarFp
        .refreshToken(portalRefreshTokenRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Super admin login
     * @summary Super admin login
     * @param {RootAdminLoginRequest} rootAdminLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rootLogin(
      rootAdminLoginRequest: RootAdminLoginRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseAccessTokenResponseCustom> {
      return localVarFp
        .rootLogin(rootAdminLoginRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Update profile user đang đăng nhập
     * @summary Update profile user đang đăng nhập
     * @param {UpdateAdminProfileRequest} updateAdminProfileRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAdminProfile(
      updateAdminProfileRequest: UpdateAdminProfileRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseAdminDetailResponse> {
      return localVarFp
        .updateAdminProfile(updateAdminProfileRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Xác thực OTP để đăng nhập
     * @summary User login Step 2 - Verify OTP
     * @param {PortalVerifyOTPLoginRequest} portalVerifyOTPLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userLoginCheckOTPV2(
      portalVerifyOTPLoginRequest: PortalVerifyOTPLoginRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseAccessTokenResponseCustom> {
      return localVarFp
        .userLoginCheckOTPV2(portalVerifyOTPLoginRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - login với username là số điện thoại. Nhận OTP
     * @summary User login step 1 - Get OTP
     * @param {PortalLoginRequest} portalLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userLoginV2(
      portalLoginRequest: PortalLoginRequest,
      options?: any,
    ): AxiosPromise<ResponseBasePortalLoginOTPResponse> {
      return localVarFp
        .userLoginV2(portalLoginRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for adminChangePassword operation in AuthControllerApi.
 * @export
 * @interface AuthControllerApiAdminChangePasswordRequest
 */
export interface AuthControllerApiAdminChangePasswordRequest {
  /**
   *
   * @type {AdminChangePasswordRequest}
   * @memberof AuthControllerApiAdminChangePassword
   */
  readonly adminChangePasswordRequest: AdminChangePasswordRequest;
}

/**
 * Request parameters for adminLogin operation in AuthControllerApi.
 * @export
 * @interface AuthControllerApiAdminLoginRequest
 */
export interface AuthControllerApiAdminLoginRequest {
  /**
   *
   * @type {AdminLoginRequest}
   * @memberof AuthControllerApiAdminLogin
   */
  readonly adminLoginRequest: AdminLoginRequest;
}

/**
 * Request parameters for adminLogout operation in AuthControllerApi.
 * @export
 * @interface AuthControllerApiAdminLogoutRequest
 */
export interface AuthControllerApiAdminLogoutRequest {
  /**
   *
   * @type {AdminLogoutRequest}
   * @memberof AuthControllerApiAdminLogout
   */
  readonly adminLogoutRequest: AdminLogoutRequest;
}

/**
 * Request parameters for clientLogin operation in AuthControllerApi.
 * @export
 * @interface AuthControllerApiClientLoginRequest
 */
export interface AuthControllerApiClientLoginRequest {
  /**
   *
   * @type {ClientLoginRequest}
   * @memberof AuthControllerApiClientLogin
   */
  readonly clientLoginRequest: ClientLoginRequest;
}

/**
 * Request parameters for cmsRefreshToken operation in AuthControllerApi.
 * @export
 * @interface AuthControllerApiCmsRefreshTokenRequest
 */
export interface AuthControllerApiCmsRefreshTokenRequest {
  /**
   *
   * @type {AdminRefreshTokenRequest}
   * @memberof AuthControllerApiCmsRefreshToken
   */
  readonly adminRefreshTokenRequest: AdminRefreshTokenRequest;
}

/**
 * Request parameters for emergencyLockAccount operation in AuthControllerApi.
 * @export
 * @interface AuthControllerApiEmergencyLockAccountRequest
 */
export interface AuthControllerApiEmergencyLockAccountRequest {
  /**
   *
   * @type {EmergencyLockAccountRequest}
   * @memberof AuthControllerApiEmergencyLockAccount
   */
  readonly emergencyLockAccountRequest: EmergencyLockAccountRequest;
}

/**
 * Request parameters for logout operation in AuthControllerApi.
 * @export
 * @interface AuthControllerApiLogoutRequest
 */
export interface AuthControllerApiLogoutRequest {
  /**
   *
   * @type {LogoutRequest}
   * @memberof AuthControllerApiLogout
   */
  readonly logoutRequest: LogoutRequest;
}

/**
 * Request parameters for portalMemberLogin operation in AuthControllerApi.
 * @export
 * @interface AuthControllerApiPortalMemberLoginRequest
 */
export interface AuthControllerApiPortalMemberLoginRequest {
  /**
   *
   * @type {PortalMemberLoginRequest}
   * @memberof AuthControllerApiPortalMemberLogin
   */
  readonly portalMemberLoginRequest: PortalMemberLoginRequest;
}

/**
 * Request parameters for portalMemberLoginCheckOTP operation in AuthControllerApi.
 * @export
 * @interface AuthControllerApiPortalMemberLoginCheckOTPRequest
 */
export interface AuthControllerApiPortalMemberLoginCheckOTPRequest {
  /**
   *
   * @type {PortalMemberVerifyOTPLoginRequest}
   * @memberof AuthControllerApiPortalMemberLoginCheckOTP
   */
  readonly portalMemberVerifyOTPLoginRequest: PortalMemberVerifyOTPLoginRequest;
}

/**
 * Request parameters for portalMemberResetPass operation in AuthControllerApi.
 * @export
 * @interface AuthControllerApiPortalMemberResetPassRequest
 */
export interface AuthControllerApiPortalMemberResetPassRequest {
  /**
   *
   * @type {PortalMemberResetPassRequest}
   * @memberof AuthControllerApiPortalMemberResetPass
   */
  readonly portalMemberResetPassRequest: PortalMemberResetPassRequest;
}

/**
 * Request parameters for portalMemberResetPassGetOtp operation in AuthControllerApi.
 * @export
 * @interface AuthControllerApiPortalMemberResetPassGetOtpRequest
 */
export interface AuthControllerApiPortalMemberResetPassGetOtpRequest {
  /**
   *
   * @type {PortalMemberResetPassGetOTPRequest}
   * @memberof AuthControllerApiPortalMemberResetPassGetOtp
   */
  readonly portalMemberResetPassGetOTPRequest: PortalMemberResetPassGetOTPRequest;
}

/**
 * Request parameters for portalMemberResetPassVerifyOtp operation in AuthControllerApi.
 * @export
 * @interface AuthControllerApiPortalMemberResetPassVerifyOtpRequest
 */
export interface AuthControllerApiPortalMemberResetPassVerifyOtpRequest {
  /**
   *
   * @type {PortalMemberResetPassVerifyOTPRequest}
   * @memberof AuthControllerApiPortalMemberResetPassVerifyOtp
   */
  readonly portalMemberResetPassVerifyOTPRequest: PortalMemberResetPassVerifyOTPRequest;
}

/**
 * Request parameters for refreshToken operation in AuthControllerApi.
 * @export
 * @interface AuthControllerApiRefreshTokenRequest
 */
export interface AuthControllerApiRefreshTokenRequest {
  /**
   *
   * @type {PortalRefreshTokenRequest}
   * @memberof AuthControllerApiRefreshToken
   */
  readonly portalRefreshTokenRequest: PortalRefreshTokenRequest;
}

/**
 * Request parameters for rootLogin operation in AuthControllerApi.
 * @export
 * @interface AuthControllerApiRootLoginRequest
 */
export interface AuthControllerApiRootLoginRequest {
  /**
   *
   * @type {RootAdminLoginRequest}
   * @memberof AuthControllerApiRootLogin
   */
  readonly rootAdminLoginRequest: RootAdminLoginRequest;
}

/**
 * Request parameters for updateAdminProfile operation in AuthControllerApi.
 * @export
 * @interface AuthControllerApiUpdateAdminProfileRequest
 */
export interface AuthControllerApiUpdateAdminProfileRequest {
  /**
   *
   * @type {UpdateAdminProfileRequest}
   * @memberof AuthControllerApiUpdateAdminProfile
   */
  readonly updateAdminProfileRequest: UpdateAdminProfileRequest;
}

/**
 * Request parameters for userLoginCheckOTPV2 operation in AuthControllerApi.
 * @export
 * @interface AuthControllerApiUserLoginCheckOTPV2Request
 */
export interface AuthControllerApiUserLoginCheckOTPV2Request {
  /**
   *
   * @type {PortalVerifyOTPLoginRequest}
   * @memberof AuthControllerApiUserLoginCheckOTPV2
   */
  readonly portalVerifyOTPLoginRequest: PortalVerifyOTPLoginRequest;
}

/**
 * Request parameters for userLoginV2 operation in AuthControllerApi.
 * @export
 * @interface AuthControllerApiUserLoginV2Request
 */
export interface AuthControllerApiUserLoginV2Request {
  /**
   *
   * @type {PortalLoginRequest}
   * @memberof AuthControllerApiUserLoginV2
   */
  readonly portalLoginRequest: PortalLoginRequest;
}

/**
 * AuthControllerApi - object-oriented interface
 * @export
 * @class AuthControllerApi
 * @extends {BaseAPI}
 */
export class AuthControllerApi extends BaseAPI {
  /**
   * - Change admin password
   * @summary Change admin password
   * @param {AuthControllerApiAdminChangePasswordRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthControllerApi
   */
  public adminChangePassword(
    requestParameters: AuthControllerApiAdminChangePasswordRequest,
    options?: AxiosRequestConfig,
  ) {
    return AuthControllerApiFp(this.configuration)
      .adminChangePassword(
        requestParameters.adminChangePasswordRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Quản trị viên login với số username và password được cấp
   * @summary Administrator login
   * @param {AuthControllerApiAdminLoginRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthControllerApi
   */
  public adminLogin(
    requestParameters: AuthControllerApiAdminLoginRequest,
    options?: AxiosRequestConfig,
  ) {
    return AuthControllerApiFp(this.configuration)
      .adminLogin(requestParameters.adminLoginRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Admin logout
   * @summary Admin logout
   * @param {AuthControllerApiAdminLogoutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthControllerApi
   */
  public adminLogout(
    requestParameters: AuthControllerApiAdminLogoutRequest,
    options?: AxiosRequestConfig,
  ) {
    return AuthControllerApiFp(this.configuration)
      .adminLogout(requestParameters.adminLogoutRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Client login oauth
   * @summary Client login
   * @param {AuthControllerApiClientLoginRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthControllerApi
   */
  public clientLogin(
    requestParameters: AuthControllerApiClientLoginRequest,
    options?: AxiosRequestConfig,
  ) {
    return AuthControllerApiFp(this.configuration)
      .clientLogin(requestParameters.clientLoginRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Refresh access token
   * @summary Refresh access token
   * @param {AuthControllerApiCmsRefreshTokenRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthControllerApi
   */
  public cmsRefreshToken(
    requestParameters: AuthControllerApiCmsRefreshTokenRequest,
    options?: AxiosRequestConfig,
  ) {
    return AuthControllerApiFp(this.configuration)
      .cmsRefreshToken(requestParameters.adminRefreshTokenRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Emergency Lock Account
   * @param {AuthControllerApiEmergencyLockAccountRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthControllerApi
   */
  public emergencyLockAccount(
    requestParameters: AuthControllerApiEmergencyLockAccountRequest,
    options?: AxiosRequestConfig,
  ) {
    return AuthControllerApiFp(this.configuration)
      .emergencyLockAccount(
        requestParameters.emergencyLockAccountRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Lấy profile user đang đăng nhập
   * @summary Lấy profile user đang đăng nhập
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthControllerApi
   */
  public getAdminProfile(options?: AxiosRequestConfig) {
    return AuthControllerApiFp(this.configuration)
      .getAdminProfile(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Lấy profile user đang đăng nhập
   * @summary Lấy profile user đang đăng nhập
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthControllerApi
   */
  public getMerchantId(options?: AxiosRequestConfig) {
    return AuthControllerApiFp(this.configuration)
      .getMerchantId(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary logout
   * @param {AuthControllerApiLogoutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthControllerApi
   */
  public logout(
    requestParameters: AuthControllerApiLogoutRequest,
    options?: AxiosRequestConfig,
  ) {
    return AuthControllerApiFp(this.configuration)
      .logout(requestParameters.logoutRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Login với merchantId, phone, password. Nhận OTP
   * @summary [Portal Member][Login] Step 1 - Get OTP
   * @param {AuthControllerApiPortalMemberLoginRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthControllerApi
   */
  public portalMemberLogin(
    requestParameters: AuthControllerApiPortalMemberLoginRequest,
    options?: AxiosRequestConfig,
  ) {
    return AuthControllerApiFp(this.configuration)
      .portalMemberLogin(requestParameters.portalMemberLoginRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Xác thực OTP để đăng nhập
   * @summary [Portal Member][Login] Step 2 - Verify OTP
   * @param {AuthControllerApiPortalMemberLoginCheckOTPRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthControllerApi
   */
  public portalMemberLoginCheckOTP(
    requestParameters: AuthControllerApiPortalMemberLoginCheckOTPRequest,
    options?: AxiosRequestConfig,
  ) {
    return AuthControllerApiFp(this.configuration)
      .portalMemberLoginCheckOTP(
        requestParameters.portalMemberVerifyOTPLoginRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [Portal Member][Reset Password] Step 3: Reset Password
   * @param {AuthControllerApiPortalMemberResetPassRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthControllerApi
   */
  public portalMemberResetPass(
    requestParameters: AuthControllerApiPortalMemberResetPassRequest,
    options?: AxiosRequestConfig,
  ) {
    return AuthControllerApiFp(this.configuration)
      .portalMemberResetPass(
        requestParameters.portalMemberResetPassRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [Portal Member][Reset Password] Step 1: Get OTP
   * @param {AuthControllerApiPortalMemberResetPassGetOtpRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthControllerApi
   */
  public portalMemberResetPassGetOtp(
    requestParameters: AuthControllerApiPortalMemberResetPassGetOtpRequest,
    options?: AxiosRequestConfig,
  ) {
    return AuthControllerApiFp(this.configuration)
      .portalMemberResetPassGetOtp(
        requestParameters.portalMemberResetPassGetOTPRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [Portal Member][Reset Password] Step 2: Verify OTP
   * @param {AuthControllerApiPortalMemberResetPassVerifyOtpRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthControllerApi
   */
  public portalMemberResetPassVerifyOtp(
    requestParameters: AuthControllerApiPortalMemberResetPassVerifyOtpRequest,
    options?: AxiosRequestConfig,
  ) {
    return AuthControllerApiFp(this.configuration)
      .portalMemberResetPassVerifyOtp(
        requestParameters.portalMemberResetPassVerifyOTPRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - refresh access token
   * @summary User refresh access token
   * @param {AuthControllerApiRefreshTokenRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthControllerApi
   */
  public refreshToken(
    requestParameters: AuthControllerApiRefreshTokenRequest,
    options?: AxiosRequestConfig,
  ) {
    return AuthControllerApiFp(this.configuration)
      .refreshToken(requestParameters.portalRefreshTokenRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Super admin login
   * @summary Super admin login
   * @param {AuthControllerApiRootLoginRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthControllerApi
   */
  public rootLogin(
    requestParameters: AuthControllerApiRootLoginRequest,
    options?: AxiosRequestConfig,
  ) {
    return AuthControllerApiFp(this.configuration)
      .rootLogin(requestParameters.rootAdminLoginRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Update profile user đang đăng nhập
   * @summary Update profile user đang đăng nhập
   * @param {AuthControllerApiUpdateAdminProfileRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthControllerApi
   */
  public updateAdminProfile(
    requestParameters: AuthControllerApiUpdateAdminProfileRequest,
    options?: AxiosRequestConfig,
  ) {
    return AuthControllerApiFp(this.configuration)
      .updateAdminProfile(requestParameters.updateAdminProfileRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Xác thực OTP để đăng nhập
   * @summary User login Step 2 - Verify OTP
   * @param {AuthControllerApiUserLoginCheckOTPV2Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthControllerApi
   */
  public userLoginCheckOTPV2(
    requestParameters: AuthControllerApiUserLoginCheckOTPV2Request,
    options?: AxiosRequestConfig,
  ) {
    return AuthControllerApiFp(this.configuration)
      .userLoginCheckOTPV2(
        requestParameters.portalVerifyOTPLoginRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - login với username là số điện thoại. Nhận OTP
   * @summary User login step 1 - Get OTP
   * @param {AuthControllerApiUserLoginV2Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthControllerApi
   */
  public userLoginV2(
    requestParameters: AuthControllerApiUserLoginV2Request,
    options?: AxiosRequestConfig,
  ) {
    return AuthControllerApiFp(this.configuration)
      .userLoginV2(requestParameters.portalLoginRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * BankAccountControllerApi - axios parameter creator
 * @export
 */
export const BankAccountControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Liên kết tài khoản - Kiểm tra
     * @param {CheckBankAccountRequest} checkBankAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkBankAccount: async (
      checkBankAccountRequest: CheckBankAccountRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'checkBankAccountRequest' is not null or undefined
      assertParamExists(
        "checkBankAccount",
        "checkBankAccountRequest",
        checkBankAccountRequest,
      );
      const localVarPath = `/api/bankAccount/portal/v1/link/check`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        checkBankAccountRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Liên kết tài khoản - Kiểm tra OTP
     * @param {VerifyOtpLinkAccountRequest} verifyOtpLinkAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkOTPLinkAccount: async (
      verifyOtpLinkAccountRequest: VerifyOtpLinkAccountRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'verifyOtpLinkAccountRequest' is not null or undefined
      assertParamExists(
        "checkOTPLinkAccount",
        "verifyOtpLinkAccountRequest",
        verifyOtpLinkAccountRequest,
      );
      const localVarPath = `/api/bankAccount/portal/v1/link/verifyOTP`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        verifyOtpLinkAccountRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xoá bank account
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteBankAccount: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("deleteBankAccount", "id", id);
      const localVarPath = `/api/bankAccount/portal/v1/delete/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xuất file excel sao kê theo tài khoản
     * @param {string} bankAccountId
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportTransactionStatisticToExcel: async (
      bankAccountId: string,
      fromDate?: string,
      toDate?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bankAccountId' is not null or undefined
      assertParamExists(
        "exportTransactionStatisticToExcel",
        "bankAccountId",
        bankAccountId,
      );
      const localVarPath =
        `/api/bankAccount/portal/v1/exportTransactionStatistic/{bankAccountId}`.replace(
          `{${"bankAccountId"}}`,
          encodeURIComponent(String(bankAccountId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (fromDate !== undefined) {
        localVarQueryParameter["fromDate"] = fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter["toDate"] = toDate;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy số dư tài khoản
     * @param {string} bankAccountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBalance: async (
      bankAccountId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bankAccountId' is not null or undefined
      assertParamExists("getBalance", "bankAccountId", bankAccountId);
      const localVarPath =
        `/api/bankAccount/portal/v1/getBalance/{bankAccountId}`.replace(
          `{${"bankAccountId"}}`,
          encodeURIComponent(String(bankAccountId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get 1 Bank Account
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBankAccount: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getBankAccount", "id", id);
      const localVarPath = `/api/bankAccount/portal/v1/get/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy danh sách bank account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListBankAccount: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/bankAccount/portal/v1/getList`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Sao kê theo tài khoản
     * @param {string} bankAccountId
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransactionStatistic1: async (
      bankAccountId: string,
      fromDate?: string,
      toDate?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bankAccountId' is not null or undefined
      assertParamExists(
        "getTransactionStatistic1",
        "bankAccountId",
        bankAccountId,
      );
      const localVarPath =
        `/api/bankAccount/portal/v1/getTransactionStatistic/{bankAccountId}`.replace(
          `{${"bankAccountId"}}`,
          encodeURIComponent(String(bankAccountId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (fromDate !== undefined) {
        localVarQueryParameter["fromDate"] = fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter["toDate"] = toDate;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter["sortDirection"] = sortDirection;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter["sortBy"] = sortBy;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy lịch sử giao dịch gần đây ở môi trường sandbox của user với tài khoản nhận tiền ở sandbox
     * @param {string} bankAccountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransactionStatisticSandBox: async (
      bankAccountId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bankAccountId' is not null or undefined
      assertParamExists(
        "getTransactionStatisticSandBox",
        "bankAccountId",
        bankAccountId,
      );
      const localVarPath =
        `/api/bankAccount/portal/v1/getTransactionStatistic/sandbox/{bankAccountId}`.replace(
          `{${"bankAccountId"}}`,
          encodeURIComponent(String(bankAccountId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy danh sách tài khoản liên kết của doanh nghiệp
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationGetLinkedAccountFromBank: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/bankAccount/portal/v1/organization/getLinkedAccountFromBank`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy danh sách tài khoản đã liên kết của doanh nghiệp chưa liên kết với klb
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationGetUnlinkedAccounts: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/bankAccount/portal/v1/organization/getUnlinkedAccounts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Liên kết tài khoản của doanh nghiệp
     * @param {OrganizationLinkAccountRequest} organizationLinkAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationLinkAccount: async (
      organizationLinkAccountRequest: OrganizationLinkAccountRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationLinkAccountRequest' is not null or undefined
      assertParamExists(
        "organizationLinkAccount",
        "organizationLinkAccountRequest",
        organizationLinkAccountRequest,
      );
      const localVarPath = `/api/bankAccount/portal/v1/organization/linkAccount`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        organizationLinkAccountRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Liên kết tài khoản - Gửi yêu cầu
     * @param {SubmitLinkAccountRequest} submitLinkAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    submitLinkAccount: async (
      submitLinkAccountRequest: SubmitLinkAccountRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'submitLinkAccountRequest' is not null or undefined
      assertParamExists(
        "submitLinkAccount",
        "submitLinkAccountRequest",
        submitLinkAccountRequest,
      );
      const localVarPath = `/api/bankAccount/portal/v1/link/submit`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        submitLinkAccountRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Chọn tài khoản mặc định
     * @param {UpdateDefaultBankAccountRequest} updateDefaultBankAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDefaultBankAccount: async (
      updateDefaultBankAccountRequest: UpdateDefaultBankAccountRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateDefaultBankAccountRequest' is not null or undefined
      assertParamExists(
        "updateDefaultBankAccount",
        "updateDefaultBankAccountRequest",
        updateDefaultBankAccountRequest,
      );
      const localVarPath = `/api/bankAccount/portal/v1/updateDefault`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateDefaultBankAccountRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BankAccountControllerApi - functional programming interface
 * @export
 */
export const BankAccountControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    BankAccountControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Liên kết tài khoản - Kiểm tra
     * @param {CheckBankAccountRequest} checkBankAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkBankAccount(
      checkBankAccountRequest: CheckBankAccountRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseCheckBankAccountResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.checkBankAccount(
          checkBankAccountRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Liên kết tài khoản - Kiểm tra OTP
     * @param {VerifyOtpLinkAccountRequest} verifyOtpLinkAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkOTPLinkAccount(
      verifyOtpLinkAccountRequest: VerifyOtpLinkAccountRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseVerifyOtpLinkAccountResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.checkOTPLinkAccount(
          verifyOtpLinkAccountRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Xoá bank account
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteBankAccount(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetListBankAccountResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteBankAccount(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Xuất file excel sao kê theo tài khoản
     * @param {string} bankAccountId
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportTransactionStatisticToExcel(
      bankAccountId: string,
      fromDate?: string,
      toDate?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.exportTransactionStatisticToExcel(
          bankAccountId,
          fromDate,
          toDate,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy số dư tài khoản
     * @param {string} bankAccountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBalance(
      bankAccountId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetBalanceBankAccountResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBalance(
        bankAccountId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get 1 Bank Account
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBankAccount(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseBankAccountResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBankAccount(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy danh sách bank account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getListBankAccount(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetListBankAccountResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getListBankAccount(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Sao kê theo tài khoản
     * @param {string} bankAccountId
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTransactionStatistic1(
      bankAccountId: string,
      fromDate?: string,
      toDate?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetTransactionStatisticResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getTransactionStatistic1(
          bankAccountId,
          fromDate,
          toDate,
          page,
          pageSize,
          sortDirection,
          sortBy,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy lịch sử giao dịch gần đây ở môi trường sandbox của user với tài khoản nhận tiền ở sandbox
     * @param {string} bankAccountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTransactionStatisticSandBox(
      bankAccountId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetTransactionStatisticResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getTransactionStatisticSandBox(
          bankAccountId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy danh sách tài khoản liên kết của doanh nghiệp
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationGetLinkedAccountFromBank(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetLinkedAccountsFromBankResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.organizationGetLinkedAccountFromBank(
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy danh sách tài khoản đã liên kết của doanh nghiệp chưa liên kết với klb
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationGetUnlinkedAccounts(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetLinkedAccountsFromBankResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.organizationGetUnlinkedAccounts(
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Liên kết tài khoản của doanh nghiệp
     * @param {OrganizationLinkAccountRequest} organizationLinkAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationLinkAccount(
      organizationLinkAccountRequest: OrganizationLinkAccountRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.organizationLinkAccount(
          organizationLinkAccountRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Liên kết tài khoản - Gửi yêu cầu
     * @param {SubmitLinkAccountRequest} submitLinkAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async submitLinkAccount(
      submitLinkAccountRequest: SubmitLinkAccountRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseSubmitLinkAccountResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.submitLinkAccount(
          submitLinkAccountRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Chọn tài khoản mặc định
     * @param {UpdateDefaultBankAccountRequest} updateDefaultBankAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateDefaultBankAccount(
      updateDefaultBankAccountRequest: UpdateDefaultBankAccountRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateDefaultBankAccount(
          updateDefaultBankAccountRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * BankAccountControllerApi - factory interface
 * @export
 */
export const BankAccountControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = BankAccountControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Liên kết tài khoản - Kiểm tra
     * @param {CheckBankAccountRequest} checkBankAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkBankAccount(
      checkBankAccountRequest: CheckBankAccountRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseCheckBankAccountResponse> {
      return localVarFp
        .checkBankAccount(checkBankAccountRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Liên kết tài khoản - Kiểm tra OTP
     * @param {VerifyOtpLinkAccountRequest} verifyOtpLinkAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkOTPLinkAccount(
      verifyOtpLinkAccountRequest: VerifyOtpLinkAccountRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseVerifyOtpLinkAccountResponse> {
      return localVarFp
        .checkOTPLinkAccount(verifyOtpLinkAccountRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xoá bank account
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteBankAccount(
      id: string,
      options?: any,
    ): AxiosPromise<ResponseBaseGetListBankAccountResponse> {
      return localVarFp
        .deleteBankAccount(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xuất file excel sao kê theo tài khoản
     * @param {string} bankAccountId
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportTransactionStatisticToExcel(
      bankAccountId: string,
      fromDate?: string,
      toDate?: string,
      options?: any,
    ): AxiosPromise<File> {
      return localVarFp
        .exportTransactionStatisticToExcel(
          bankAccountId,
          fromDate,
          toDate,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy số dư tài khoản
     * @param {string} bankAccountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBalance(
      bankAccountId: string,
      options?: any,
    ): AxiosPromise<ResponseBaseGetBalanceBankAccountResponse> {
      return localVarFp
        .getBalance(bankAccountId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get 1 Bank Account
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBankAccount(
      id: string,
      options?: any,
    ): AxiosPromise<ResponseBaseBankAccountResponse> {
      return localVarFp
        .getBankAccount(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy danh sách bank account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListBankAccount(
      options?: any,
    ): AxiosPromise<ResponseBaseGetListBankAccountResponse> {
      return localVarFp
        .getListBankAccount(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Sao kê theo tài khoản
     * @param {string} bankAccountId
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransactionStatistic1(
      bankAccountId: string,
      fromDate?: string,
      toDate?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: any,
    ): AxiosPromise<ResponseBaseGetTransactionStatisticResponse> {
      return localVarFp
        .getTransactionStatistic1(
          bankAccountId,
          fromDate,
          toDate,
          page,
          pageSize,
          sortDirection,
          sortBy,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy lịch sử giao dịch gần đây ở môi trường sandbox của user với tài khoản nhận tiền ở sandbox
     * @param {string} bankAccountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransactionStatisticSandBox(
      bankAccountId: string,
      options?: any,
    ): AxiosPromise<ResponseBaseGetTransactionStatisticResponse> {
      return localVarFp
        .getTransactionStatisticSandBox(bankAccountId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy danh sách tài khoản liên kết của doanh nghiệp
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationGetLinkedAccountFromBank(
      options?: any,
    ): AxiosPromise<ResponseBaseGetLinkedAccountsFromBankResponse> {
      return localVarFp
        .organizationGetLinkedAccountFromBank(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy danh sách tài khoản đã liên kết của doanh nghiệp chưa liên kết với klb
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationGetUnlinkedAccounts(
      options?: any,
    ): AxiosPromise<ResponseBaseGetLinkedAccountsFromBankResponse> {
      return localVarFp
        .organizationGetUnlinkedAccounts(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Liên kết tài khoản của doanh nghiệp
     * @param {OrganizationLinkAccountRequest} organizationLinkAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationLinkAccount(
      organizationLinkAccountRequest: OrganizationLinkAccountRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .organizationLinkAccount(organizationLinkAccountRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Liên kết tài khoản - Gửi yêu cầu
     * @param {SubmitLinkAccountRequest} submitLinkAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    submitLinkAccount(
      submitLinkAccountRequest: SubmitLinkAccountRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseSubmitLinkAccountResponse> {
      return localVarFp
        .submitLinkAccount(submitLinkAccountRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Chọn tài khoản mặc định
     * @param {UpdateDefaultBankAccountRequest} updateDefaultBankAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDefaultBankAccount(
      updateDefaultBankAccountRequest: UpdateDefaultBankAccountRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .updateDefaultBankAccount(updateDefaultBankAccountRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for checkBankAccount operation in BankAccountControllerApi.
 * @export
 * @interface BankAccountControllerApiCheckBankAccountRequest
 */
export interface BankAccountControllerApiCheckBankAccountRequest {
  /**
   *
   * @type {CheckBankAccountRequest}
   * @memberof BankAccountControllerApiCheckBankAccount
   */
  readonly checkBankAccountRequest: CheckBankAccountRequest;
}

/**
 * Request parameters for checkOTPLinkAccount operation in BankAccountControllerApi.
 * @export
 * @interface BankAccountControllerApiCheckOTPLinkAccountRequest
 */
export interface BankAccountControllerApiCheckOTPLinkAccountRequest {
  /**
   *
   * @type {VerifyOtpLinkAccountRequest}
   * @memberof BankAccountControllerApiCheckOTPLinkAccount
   */
  readonly verifyOtpLinkAccountRequest: VerifyOtpLinkAccountRequest;
}

/**
 * Request parameters for deleteBankAccount operation in BankAccountControllerApi.
 * @export
 * @interface BankAccountControllerApiDeleteBankAccountRequest
 */
export interface BankAccountControllerApiDeleteBankAccountRequest {
  /**
   *
   * @type {string}
   * @memberof BankAccountControllerApiDeleteBankAccount
   */
  readonly id: string;
}

/**
 * Request parameters for exportTransactionStatisticToExcel operation in BankAccountControllerApi.
 * @export
 * @interface BankAccountControllerApiExportTransactionStatisticToExcelRequest
 */
export interface BankAccountControllerApiExportTransactionStatisticToExcelRequest {
  /**
   *
   * @type {string}
   * @memberof BankAccountControllerApiExportTransactionStatisticToExcel
   */
  readonly bankAccountId: string;

  /**
   *
   * @type {string}
   * @memberof BankAccountControllerApiExportTransactionStatisticToExcel
   */
  readonly fromDate?: string;

  /**
   *
   * @type {string}
   * @memberof BankAccountControllerApiExportTransactionStatisticToExcel
   */
  readonly toDate?: string;
}

/**
 * Request parameters for getBalance operation in BankAccountControllerApi.
 * @export
 * @interface BankAccountControllerApiGetBalanceRequest
 */
export interface BankAccountControllerApiGetBalanceRequest {
  /**
   *
   * @type {string}
   * @memberof BankAccountControllerApiGetBalance
   */
  readonly bankAccountId: string;
}

/**
 * Request parameters for getBankAccount operation in BankAccountControllerApi.
 * @export
 * @interface BankAccountControllerApiGetBankAccountRequest
 */
export interface BankAccountControllerApiGetBankAccountRequest {
  /**
   *
   * @type {string}
   * @memberof BankAccountControllerApiGetBankAccount
   */
  readonly id: string;
}

/**
 * Request parameters for getTransactionStatistic1 operation in BankAccountControllerApi.
 * @export
 * @interface BankAccountControllerApiGetTransactionStatistic1Request
 */
export interface BankAccountControllerApiGetTransactionStatistic1Request {
  /**
   *
   * @type {string}
   * @memberof BankAccountControllerApiGetTransactionStatistic1
   */
  readonly bankAccountId: string;

  /**
   *
   * @type {string}
   * @memberof BankAccountControllerApiGetTransactionStatistic1
   */
  readonly fromDate?: string;

  /**
   *
   * @type {string}
   * @memberof BankAccountControllerApiGetTransactionStatistic1
   */
  readonly toDate?: string;

  /**
   *
   * @type {number}
   * @memberof BankAccountControllerApiGetTransactionStatistic1
   */
  readonly page?: number;

  /**
   *
   * @type {number}
   * @memberof BankAccountControllerApiGetTransactionStatistic1
   */
  readonly pageSize?: number;

  /**
   *
   * @type {'ASC' | 'DESC'}
   * @memberof BankAccountControllerApiGetTransactionStatistic1
   */
  readonly sortDirection?: "ASC" | "DESC";

  /**
   *
   * @type {string}
   * @memberof BankAccountControllerApiGetTransactionStatistic1
   */
  readonly sortBy?: string;
}

/**
 * Request parameters for getTransactionStatisticSandBox operation in BankAccountControllerApi.
 * @export
 * @interface BankAccountControllerApiGetTransactionStatisticSandBoxRequest
 */
export interface BankAccountControllerApiGetTransactionStatisticSandBoxRequest {
  /**
   *
   * @type {string}
   * @memberof BankAccountControllerApiGetTransactionStatisticSandBox
   */
  readonly bankAccountId: string;
}

/**
 * Request parameters for organizationLinkAccount operation in BankAccountControllerApi.
 * @export
 * @interface BankAccountControllerApiOrganizationLinkAccountRequest
 */
export interface BankAccountControllerApiOrganizationLinkAccountRequest {
  /**
   *
   * @type {OrganizationLinkAccountRequest}
   * @memberof BankAccountControllerApiOrganizationLinkAccount
   */
  readonly organizationLinkAccountRequest: OrganizationLinkAccountRequest;
}

/**
 * Request parameters for submitLinkAccount operation in BankAccountControllerApi.
 * @export
 * @interface BankAccountControllerApiSubmitLinkAccountRequest
 */
export interface BankAccountControllerApiSubmitLinkAccountRequest {
  /**
   *
   * @type {SubmitLinkAccountRequest}
   * @memberof BankAccountControllerApiSubmitLinkAccount
   */
  readonly submitLinkAccountRequest: SubmitLinkAccountRequest;
}

/**
 * Request parameters for updateDefaultBankAccount operation in BankAccountControllerApi.
 * @export
 * @interface BankAccountControllerApiUpdateDefaultBankAccountRequest
 */
export interface BankAccountControllerApiUpdateDefaultBankAccountRequest {
  /**
   *
   * @type {UpdateDefaultBankAccountRequest}
   * @memberof BankAccountControllerApiUpdateDefaultBankAccount
   */
  readonly updateDefaultBankAccountRequest: UpdateDefaultBankAccountRequest;
}

/**
 * BankAccountControllerApi - object-oriented interface
 * @export
 * @class BankAccountControllerApi
 * @extends {BaseAPI}
 */
export class BankAccountControllerApi extends BaseAPI {
  /**
   *
   * @summary Liên kết tài khoản - Kiểm tra
   * @param {BankAccountControllerApiCheckBankAccountRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BankAccountControllerApi
   */
  public checkBankAccount(
    requestParameters: BankAccountControllerApiCheckBankAccountRequest,
    options?: AxiosRequestConfig,
  ) {
    return BankAccountControllerApiFp(this.configuration)
      .checkBankAccount(requestParameters.checkBankAccountRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Liên kết tài khoản - Kiểm tra OTP
   * @param {BankAccountControllerApiCheckOTPLinkAccountRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BankAccountControllerApi
   */
  public checkOTPLinkAccount(
    requestParameters: BankAccountControllerApiCheckOTPLinkAccountRequest,
    options?: AxiosRequestConfig,
  ) {
    return BankAccountControllerApiFp(this.configuration)
      .checkOTPLinkAccount(
        requestParameters.verifyOtpLinkAccountRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xoá bank account
   * @param {BankAccountControllerApiDeleteBankAccountRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BankAccountControllerApi
   */
  public deleteBankAccount(
    requestParameters: BankAccountControllerApiDeleteBankAccountRequest,
    options?: AxiosRequestConfig,
  ) {
    return BankAccountControllerApiFp(this.configuration)
      .deleteBankAccount(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xuất file excel sao kê theo tài khoản
   * @param {BankAccountControllerApiExportTransactionStatisticToExcelRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BankAccountControllerApi
   */
  public exportTransactionStatisticToExcel(
    requestParameters: BankAccountControllerApiExportTransactionStatisticToExcelRequest,
    options?: AxiosRequestConfig,
  ) {
    return BankAccountControllerApiFp(this.configuration)
      .exportTransactionStatisticToExcel(
        requestParameters.bankAccountId,
        requestParameters.fromDate,
        requestParameters.toDate,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy số dư tài khoản
   * @param {BankAccountControllerApiGetBalanceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BankAccountControllerApi
   */
  public getBalance(
    requestParameters: BankAccountControllerApiGetBalanceRequest,
    options?: AxiosRequestConfig,
  ) {
    return BankAccountControllerApiFp(this.configuration)
      .getBalance(requestParameters.bankAccountId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get 1 Bank Account
   * @param {BankAccountControllerApiGetBankAccountRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BankAccountControllerApi
   */
  public getBankAccount(
    requestParameters: BankAccountControllerApiGetBankAccountRequest,
    options?: AxiosRequestConfig,
  ) {
    return BankAccountControllerApiFp(this.configuration)
      .getBankAccount(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy danh sách bank account
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BankAccountControllerApi
   */
  public getListBankAccount(options?: AxiosRequestConfig) {
    return BankAccountControllerApiFp(this.configuration)
      .getListBankAccount(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Sao kê theo tài khoản
   * @param {BankAccountControllerApiGetTransactionStatistic1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BankAccountControllerApi
   */
  public getTransactionStatistic1(
    requestParameters: BankAccountControllerApiGetTransactionStatistic1Request,
    options?: AxiosRequestConfig,
  ) {
    return BankAccountControllerApiFp(this.configuration)
      .getTransactionStatistic1(
        requestParameters.bankAccountId,
        requestParameters.fromDate,
        requestParameters.toDate,
        requestParameters.page,
        requestParameters.pageSize,
        requestParameters.sortDirection,
        requestParameters.sortBy,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy lịch sử giao dịch gần đây ở môi trường sandbox của user với tài khoản nhận tiền ở sandbox
   * @param {BankAccountControllerApiGetTransactionStatisticSandBoxRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BankAccountControllerApi
   */
  public getTransactionStatisticSandBox(
    requestParameters: BankAccountControllerApiGetTransactionStatisticSandBoxRequest,
    options?: AxiosRequestConfig,
  ) {
    return BankAccountControllerApiFp(this.configuration)
      .getTransactionStatisticSandBox(requestParameters.bankAccountId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy danh sách tài khoản liên kết của doanh nghiệp
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BankAccountControllerApi
   */
  public organizationGetLinkedAccountFromBank(options?: AxiosRequestConfig) {
    return BankAccountControllerApiFp(this.configuration)
      .organizationGetLinkedAccountFromBank(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy danh sách tài khoản đã liên kết của doanh nghiệp chưa liên kết với klb
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BankAccountControllerApi
   */
  public organizationGetUnlinkedAccounts(options?: AxiosRequestConfig) {
    return BankAccountControllerApiFp(this.configuration)
      .organizationGetUnlinkedAccounts(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Liên kết tài khoản của doanh nghiệp
   * @param {BankAccountControllerApiOrganizationLinkAccountRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BankAccountControllerApi
   */
  public organizationLinkAccount(
    requestParameters: BankAccountControllerApiOrganizationLinkAccountRequest,
    options?: AxiosRequestConfig,
  ) {
    return BankAccountControllerApiFp(this.configuration)
      .organizationLinkAccount(
        requestParameters.organizationLinkAccountRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Liên kết tài khoản - Gửi yêu cầu
   * @param {BankAccountControllerApiSubmitLinkAccountRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BankAccountControllerApi
   */
  public submitLinkAccount(
    requestParameters: BankAccountControllerApiSubmitLinkAccountRequest,
    options?: AxiosRequestConfig,
  ) {
    return BankAccountControllerApiFp(this.configuration)
      .submitLinkAccount(requestParameters.submitLinkAccountRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Chọn tài khoản mặc định
   * @param {BankAccountControllerApiUpdateDefaultBankAccountRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BankAccountControllerApi
   */
  public updateDefaultBankAccount(
    requestParameters: BankAccountControllerApiUpdateDefaultBankAccountRequest,
    options?: AxiosRequestConfig,
  ) {
    return BankAccountControllerApiFp(this.configuration)
      .updateDefaultBankAccount(
        requestParameters.updateDefaultBankAccountRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * BankControllerApi - axios parameter creator
 * @export
 */
export const BankControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * - Kiểm tra bank đã tồn tại? Key: name | shortName | commonName | citad | napasCode | benId | swift
     * @summary Kiểm tra bank đã tồn tại?
     * @param {CheckBankExistRequest} checkBankExistRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkBankExist: async (
      checkBankExistRequest: CheckBankExistRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'checkBankExistRequest' is not null or undefined
      assertParamExists(
        "checkBankExist",
        "checkBankExistRequest",
        checkBankExistRequest,
      );
      const localVarPath = `/api/bank/cms/v1/checking`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        checkBankExistRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Tạo mới mã ngân hàng
     * @summary Tạo mới mã ngân hàng
     * @param {CreateBankRequest} createBankRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createBank: async (
      createBankRequest: CreateBankRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createBankRequest' is not null or undefined
      assertParamExists("createBank", "createBankRequest", createBankRequest);
      const localVarPath = `/api/bank/cms/v1/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createBankRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Xóa mã ngân hàng
     * @summary Xóa mã ngân hàng
     * @param {number} bankId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteBank: async (
      bankId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bankId' is not null or undefined
      assertParamExists("deleteBank", "bankId", bankId);
      const localVarPath = `/api/bank/cms/v1/delete/{bankId}`.replace(
        `{${"bankId"}}`,
        encodeURIComponent(String(bankId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Export danh sách ngân hàng ra Excel
     * @summary Export danh sách ngân hàng ra Excel
     * @param {string} [name]
     * @param {string} [code]
     * @param {boolean} [napasSupported]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportBanksToExcel: async (
      name?: string,
      code?: string,
      napasSupported?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/bank/cms/v1/exportToExcel`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (name !== undefined) {
        localVarQueryParameter["name"] = name;
      }

      if (code !== undefined) {
        localVarQueryParameter["code"] = code;
      }

      if (napasSupported !== undefined) {
        localVarQueryParameter["napasSupported"] = napasSupported;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Lấy tất cả danh sách ngân hàng
     * @summary Lấy tất cả danh sách ngân hàng
     * @param {string} [name]
     * @param {string} [code]
     * @param {boolean} [napasSupported]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllBanks: async (
      name?: string,
      code?: string,
      napasSupported?: boolean,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/bank/v1/getAll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (name !== undefined) {
        localVarQueryParameter["name"] = name;
      }

      if (code !== undefined) {
        localVarQueryParameter["code"] = code;
      }

      if (napasSupported !== undefined) {
        localVarQueryParameter["napasSupported"] = napasSupported;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter["sortDirection"] = sortDirection;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter["sortBy"] = sortBy;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Đồng bộ mã ngân hàng từ Việt QR
     * @summary Đồng bộ mã ngân hàng từ Việt QR
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    syncBankFromVietQr: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/bank/cms/v1/syncVietQr`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Cập nhật mã ngân hàng
     * @summary Cập nhật mã ngân hàng
     * @param {number} bankId
     * @param {UpdateBankRequest} updateBankRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBank: async (
      bankId: number,
      updateBankRequest: UpdateBankRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bankId' is not null or undefined
      assertParamExists("updateBank", "bankId", bankId);
      // verify required parameter 'updateBankRequest' is not null or undefined
      assertParamExists("updateBank", "updateBankRequest", updateBankRequest);
      const localVarPath = `/api/bank/cms/v1/update/{bankId}`.replace(
        `{${"bankId"}}`,
        encodeURIComponent(String(bankId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateBankRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Cập nhật hàng loạt icon từ files ảnh
     * @summary Cập nhật icon từ files ảnh
     * @param {Array<File>} icons
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBankIcon: async (
      icons: Array<File>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'icons' is not null or undefined
      assertParamExists("updateBankIcon", "icons", icons);
      const localVarPath = `/api/bank/v1/updateIcon`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (icons) {
        localVarQueryParameter["icons"] = icons;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BankControllerApi - functional programming interface
 * @export
 */
export const BankControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    BankControllerApiAxiosParamCreator(configuration);
  return {
    /**
     * - Kiểm tra bank đã tồn tại? Key: name | shortName | commonName | citad | napasCode | benId | swift
     * @summary Kiểm tra bank đã tồn tại?
     * @param {CheckBankExistRequest} checkBankExistRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkBankExist(
      checkBankExistRequest: CheckBankExistRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseCheckBankExistResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.checkBankExist(
        checkBankExistRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Tạo mới mã ngân hàng
     * @summary Tạo mới mã ngân hàng
     * @param {CreateBankRequest} createBankRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createBank(
      createBankRequest: CreateBankRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseBankResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createBank(
        createBankRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Xóa mã ngân hàng
     * @summary Xóa mã ngân hàng
     * @param {number} bankId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteBank(
      bankId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBank(
        bankId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Export danh sách ngân hàng ra Excel
     * @summary Export danh sách ngân hàng ra Excel
     * @param {string} [name]
     * @param {string} [code]
     * @param {boolean} [napasSupported]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportBanksToExcel(
      name?: string,
      code?: string,
      napasSupported?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.exportBanksToExcel(
          name,
          code,
          napasSupported,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Lấy tất cả danh sách ngân hàng
     * @summary Lấy tất cả danh sách ngân hàng
     * @param {string} [name]
     * @param {string} [code]
     * @param {boolean} [napasSupported]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllBanks(
      name?: string,
      code?: string,
      napasSupported?: boolean,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseBankResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllBanks(
        name,
        code,
        napasSupported,
        page,
        pageSize,
        sortDirection,
        sortBy,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Đồng bộ mã ngân hàng từ Việt QR
     * @summary Đồng bộ mã ngân hàng từ Việt QR
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async syncBankFromVietQr(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.syncBankFromVietQr(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Cập nhật mã ngân hàng
     * @summary Cập nhật mã ngân hàng
     * @param {number} bankId
     * @param {UpdateBankRequest} updateBankRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateBank(
      bankId: number,
      updateBankRequest: UpdateBankRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateBank(
        bankId,
        updateBankRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Cập nhật hàng loạt icon từ files ảnh
     * @summary Cập nhật icon từ files ảnh
     * @param {Array<File>} icons
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateBankIcon(
      icons: Array<File>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateBankIcon(
        icons,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * BankControllerApi - factory interface
 * @export
 */
export const BankControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = BankControllerApiFp(configuration);
  return {
    /**
     * - Kiểm tra bank đã tồn tại? Key: name | shortName | commonName | citad | napasCode | benId | swift
     * @summary Kiểm tra bank đã tồn tại?
     * @param {CheckBankExistRequest} checkBankExistRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkBankExist(
      checkBankExistRequest: CheckBankExistRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseCheckBankExistResponse> {
      return localVarFp
        .checkBankExist(checkBankExistRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Tạo mới mã ngân hàng
     * @summary Tạo mới mã ngân hàng
     * @param {CreateBankRequest} createBankRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createBank(
      createBankRequest: CreateBankRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseBankResponse> {
      return localVarFp
        .createBank(createBankRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Xóa mã ngân hàng
     * @summary Xóa mã ngân hàng
     * @param {number} bankId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteBank(
      bankId: number,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .deleteBank(bankId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Export danh sách ngân hàng ra Excel
     * @summary Export danh sách ngân hàng ra Excel
     * @param {string} [name]
     * @param {string} [code]
     * @param {boolean} [napasSupported]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportBanksToExcel(
      name?: string,
      code?: string,
      napasSupported?: boolean,
      options?: any,
    ): AxiosPromise<File> {
      return localVarFp
        .exportBanksToExcel(name, code, napasSupported, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Lấy tất cả danh sách ngân hàng
     * @summary Lấy tất cả danh sách ngân hàng
     * @param {string} [name]
     * @param {string} [code]
     * @param {boolean} [napasSupported]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllBanks(
      name?: string,
      code?: string,
      napasSupported?: boolean,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseBankResponse> {
      return localVarFp
        .getAllBanks(
          name,
          code,
          napasSupported,
          page,
          pageSize,
          sortDirection,
          sortBy,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * - Đồng bộ mã ngân hàng từ Việt QR
     * @summary Đồng bộ mã ngân hàng từ Việt QR
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    syncBankFromVietQr(
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .syncBankFromVietQr(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Cập nhật mã ngân hàng
     * @summary Cập nhật mã ngân hàng
     * @param {number} bankId
     * @param {UpdateBankRequest} updateBankRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBank(
      bankId: number,
      updateBankRequest: UpdateBankRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .updateBank(bankId, updateBankRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Cập nhật hàng loạt icon từ files ảnh
     * @summary Cập nhật icon từ files ảnh
     * @param {Array<File>} icons
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBankIcon(
      icons: Array<File>,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .updateBankIcon(icons, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for checkBankExist operation in BankControllerApi.
 * @export
 * @interface BankControllerApiCheckBankExistRequest
 */
export interface BankControllerApiCheckBankExistRequest {
  /**
   *
   * @type {CheckBankExistRequest}
   * @memberof BankControllerApiCheckBankExist
   */
  readonly checkBankExistRequest: CheckBankExistRequest;
}

/**
 * Request parameters for createBank operation in BankControllerApi.
 * @export
 * @interface BankControllerApiCreateBankRequest
 */
export interface BankControllerApiCreateBankRequest {
  /**
   *
   * @type {CreateBankRequest}
   * @memberof BankControllerApiCreateBank
   */
  readonly createBankRequest: CreateBankRequest;
}

/**
 * Request parameters for deleteBank operation in BankControllerApi.
 * @export
 * @interface BankControllerApiDeleteBankRequest
 */
export interface BankControllerApiDeleteBankRequest {
  /**
   *
   * @type {number}
   * @memberof BankControllerApiDeleteBank
   */
  readonly bankId: number;
}

/**
 * Request parameters for exportBanksToExcel operation in BankControllerApi.
 * @export
 * @interface BankControllerApiExportBanksToExcelRequest
 */
export interface BankControllerApiExportBanksToExcelRequest {
  /**
   *
   * @type {string}
   * @memberof BankControllerApiExportBanksToExcel
   */
  readonly name?: string;

  /**
   *
   * @type {string}
   * @memberof BankControllerApiExportBanksToExcel
   */
  readonly code?: string;

  /**
   *
   * @type {boolean}
   * @memberof BankControllerApiExportBanksToExcel
   */
  readonly napasSupported?: boolean;
}

/**
 * Request parameters for getAllBanks operation in BankControllerApi.
 * @export
 * @interface BankControllerApiGetAllBanksRequest
 */
export interface BankControllerApiGetAllBanksRequest {
  /**
   *
   * @type {string}
   * @memberof BankControllerApiGetAllBanks
   */
  readonly name?: string;

  /**
   *
   * @type {string}
   * @memberof BankControllerApiGetAllBanks
   */
  readonly code?: string;

  /**
   *
   * @type {boolean}
   * @memberof BankControllerApiGetAllBanks
   */
  readonly napasSupported?: boolean;

  /**
   *
   * @type {number}
   * @memberof BankControllerApiGetAllBanks
   */
  readonly page?: number;

  /**
   *
   * @type {number}
   * @memberof BankControllerApiGetAllBanks
   */
  readonly pageSize?: number;

  /**
   *
   * @type {'ASC' | 'DESC'}
   * @memberof BankControllerApiGetAllBanks
   */
  readonly sortDirection?: "ASC" | "DESC";

  /**
   *
   * @type {string}
   * @memberof BankControllerApiGetAllBanks
   */
  readonly sortBy?: string;
}

/**
 * Request parameters for updateBank operation in BankControllerApi.
 * @export
 * @interface BankControllerApiUpdateBankRequest
 */
export interface BankControllerApiUpdateBankRequest {
  /**
   *
   * @type {number}
   * @memberof BankControllerApiUpdateBank
   */
  readonly bankId: number;

  /**
   *
   * @type {UpdateBankRequest}
   * @memberof BankControllerApiUpdateBank
   */
  readonly updateBankRequest: UpdateBankRequest;
}

/**
 * Request parameters for updateBankIcon operation in BankControllerApi.
 * @export
 * @interface BankControllerApiUpdateBankIconRequest
 */
export interface BankControllerApiUpdateBankIconRequest {
  /**
   *
   * @type {Array<File>}
   * @memberof BankControllerApiUpdateBankIcon
   */
  readonly icons: Array<File>;
}

/**
 * BankControllerApi - object-oriented interface
 * @export
 * @class BankControllerApi
 * @extends {BaseAPI}
 */
export class BankControllerApi extends BaseAPI {
  /**
   * - Kiểm tra bank đã tồn tại? Key: name | shortName | commonName | citad | napasCode | benId | swift
   * @summary Kiểm tra bank đã tồn tại?
   * @param {BankControllerApiCheckBankExistRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BankControllerApi
   */
  public checkBankExist(
    requestParameters: BankControllerApiCheckBankExistRequest,
    options?: AxiosRequestConfig,
  ) {
    return BankControllerApiFp(this.configuration)
      .checkBankExist(requestParameters.checkBankExistRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Tạo mới mã ngân hàng
   * @summary Tạo mới mã ngân hàng
   * @param {BankControllerApiCreateBankRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BankControllerApi
   */
  public createBank(
    requestParameters: BankControllerApiCreateBankRequest,
    options?: AxiosRequestConfig,
  ) {
    return BankControllerApiFp(this.configuration)
      .createBank(requestParameters.createBankRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Xóa mã ngân hàng
   * @summary Xóa mã ngân hàng
   * @param {BankControllerApiDeleteBankRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BankControllerApi
   */
  public deleteBank(
    requestParameters: BankControllerApiDeleteBankRequest,
    options?: AxiosRequestConfig,
  ) {
    return BankControllerApiFp(this.configuration)
      .deleteBank(requestParameters.bankId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Export danh sách ngân hàng ra Excel
   * @summary Export danh sách ngân hàng ra Excel
   * @param {BankControllerApiExportBanksToExcelRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BankControllerApi
   */
  public exportBanksToExcel(
    requestParameters: BankControllerApiExportBanksToExcelRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return BankControllerApiFp(this.configuration)
      .exportBanksToExcel(
        requestParameters.name,
        requestParameters.code,
        requestParameters.napasSupported,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Lấy tất cả danh sách ngân hàng
   * @summary Lấy tất cả danh sách ngân hàng
   * @param {BankControllerApiGetAllBanksRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BankControllerApi
   */
  public getAllBanks(
    requestParameters: BankControllerApiGetAllBanksRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return BankControllerApiFp(this.configuration)
      .getAllBanks(
        requestParameters.name,
        requestParameters.code,
        requestParameters.napasSupported,
        requestParameters.page,
        requestParameters.pageSize,
        requestParameters.sortDirection,
        requestParameters.sortBy,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Đồng bộ mã ngân hàng từ Việt QR
   * @summary Đồng bộ mã ngân hàng từ Việt QR
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BankControllerApi
   */
  public syncBankFromVietQr(options?: AxiosRequestConfig) {
    return BankControllerApiFp(this.configuration)
      .syncBankFromVietQr(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Cập nhật mã ngân hàng
   * @summary Cập nhật mã ngân hàng
   * @param {BankControllerApiUpdateBankRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BankControllerApi
   */
  public updateBank(
    requestParameters: BankControllerApiUpdateBankRequest,
    options?: AxiosRequestConfig,
  ) {
    return BankControllerApiFp(this.configuration)
      .updateBank(
        requestParameters.bankId,
        requestParameters.updateBankRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Cập nhật hàng loạt icon từ files ảnh
   * @summary Cập nhật icon từ files ảnh
   * @param {BankControllerApiUpdateBankIconRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BankControllerApi
   */
  public updateBankIcon(
    requestParameters: BankControllerApiUpdateBankIconRequest,
    options?: AxiosRequestConfig,
  ) {
    return BankControllerApiFp(this.configuration)
      .updateBankIcon(requestParameters.icons, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CMSMerchantControllerApi - axios parameter creator
 * @export
 */
export const CMSMerchantControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary [CMS] Khai báo biểu phí cho Merchant
     * @param {AddFeeMerchantRequest} addFeeMerchantRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addFeeMerchant: async (
      addFeeMerchantRequest: AddFeeMerchantRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'addFeeMerchantRequest' is not null or undefined
      assertParamExists(
        "addFeeMerchant",
        "addFeeMerchantRequest",
        addFeeMerchantRequest,
      );
      const localVarPath = `/api/merchant/cms/v1/cms/addFee`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        addFeeMerchantRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Duyệt đối tác
     * @summary Duyệt đối tác
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approveMerchant: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("approveMerchant", "id", id);
      const localVarPath = `/api/merchant/cms/v1/approve/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Vô hiệu hóa thành viên đối tác
     * @summary Vô hiệu hóa thành viên đối tác
     * @param {string} memberId
     * @param {string} reason
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blockMerchantMember: async (
      memberId: string,
      reason: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'memberId' is not null or undefined
      assertParamExists("blockMerchantMember", "memberId", memberId);
      // verify required parameter 'reason' is not null or undefined
      assertParamExists("blockMerchantMember", "reason", reason);
      const localVarPath =
        `/api/merchant/cms/v1/blockMember/{memberId}`.replace(
          `{${"memberId"}}`,
          encodeURIComponent(String(memberId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (reason !== undefined) {
        localVarQueryParameter["reason"] = reason;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Khóa đối tác doanh nghiệp
     * @param {string} id
     * @param {string} reason
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blockOrgMerchant: async (
      id: string,
      reason: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("blockOrgMerchant", "id", id);
      // verify required parameter 'reason' is not null or undefined
      assertParamExists("blockOrgMerchant", "reason", reason);
      const localVarPath = `/api/merchant/cms/v1/blockOrg/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (reason !== undefined) {
        localVarQueryParameter["reason"] = reason;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Từ chối duyệt đối tác
     * @summary Từ chối duyệt đối tác
     * @param {string} id
     * @param {CmsDisapproveMerchantRequest} cmsDisapproveMerchantRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    disapproveMerchant: async (
      id: string,
      cmsDisapproveMerchantRequest: CmsDisapproveMerchantRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("disapproveMerchant", "id", id);
      // verify required parameter 'cmsDisapproveMerchantRequest' is not null or undefined
      assertParamExists(
        "disapproveMerchant",
        "cmsDisapproveMerchantRequest",
        cmsDisapproveMerchantRequest,
      );
      const localVarPath = `/api/merchant/cms/v1/disapprove/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        cmsDisapproveMerchantRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Tải Hợp đồng
     * @param {string} merchantId
     * @param {'COOPERATION_CONTRACT_FOR_KLBP_SERVICE_SUPPLY'} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    download: async (
      merchantId: string,
      type: "COOPERATION_CONTRACT_FOR_KLBP_SERVICE_SUPPLY",
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'merchantId' is not null or undefined
      assertParamExists("download", "merchantId", merchantId);
      // verify required parameter 'type' is not null or undefined
      assertParamExists("download", "type", type);
      const localVarPath = `/api/merchant/cms/v1/contract/download`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (merchantId !== undefined) {
        localVarQueryParameter["merchantId"] = merchantId;
      }

      if (type !== undefined) {
        localVarQueryParameter["type"] = type;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy thông tin định danh theo số tài khoản
     * @param {string} merchantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ekycReconciliationFromBank: async (
      merchantId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'merchantId' is not null or undefined
      assertParamExists("ekycReconciliationFromBank", "merchantId", merchantId);
      const localVarPath = `/api/merchant/cms/v1/ekycReconciliation`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (merchantId !== undefined) {
        localVarQueryParameter["merchantId"] = merchantId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Xuất file danh sách đối tác
     * @summary Xuất file danh sách đối tác
     * @param {string} [username]
     * @param {string} [name]
     * @param {string} [requestDateFrom]
     * @param {string} [requestDateTo]
     * @param {'PERSONAL' | 'ORGANIZATION'} [businessType]
     * @param {'CREATED' | 'SUBMIT_INFO' | 'LINKED_ACCOUNT' | 'INACTIVE' | 'ACTIVE' | 'DENIED' | 'OPEN_ACCOUNT' | 'BLOCK'} [status]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportExcelMerchant: async (
      username?: string,
      name?: string,
      requestDateFrom?: string,
      requestDateTo?: string,
      businessType?: "PERSONAL" | "ORGANIZATION",
      status?:
        | "CREATED"
        | "SUBMIT_INFO"
        | "LINKED_ACCOUNT"
        | "INACTIVE"
        | "ACTIVE"
        | "DENIED"
        | "OPEN_ACCOUNT"
        | "BLOCK",
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/merchant/cms/v1/exportExcel`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (username !== undefined) {
        localVarQueryParameter["username"] = username;
      }

      if (name !== undefined) {
        localVarQueryParameter["name"] = name;
      }

      if (requestDateFrom !== undefined) {
        localVarQueryParameter["requestDateFrom"] = requestDateFrom;
      }

      if (requestDateTo !== undefined) {
        localVarQueryParameter["requestDateTo"] = requestDateTo;
      }

      if (businessType !== undefined) {
        localVarQueryParameter["businessType"] = businessType;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter["sortDirection"] = sortDirection;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter["sortBy"] = sortBy;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Lấy danh sách thành viên đối tác
     * @summary Lấy danh sách thành viên của đối tác
     * @param {string} [merchantId]
     * @param {string} [username]
     * @param {string} [merchantName]
     * @param {'BLOCKED' | 'ACTIVE' | 'INACTIVE' | 'NOT_LOGGED_IN'} [status]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllMembers: async (
      merchantId?: string,
      username?: string,
      merchantName?: string,
      status?: "BLOCKED" | "ACTIVE" | "INACTIVE" | "NOT_LOGGED_IN",
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/merchant/cms/v1/getMembers`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (merchantId !== undefined) {
        localVarQueryParameter["merchantId"] = merchantId;
      }

      if (username !== undefined) {
        localVarQueryParameter["username"] = username;
      }

      if (merchantName !== undefined) {
        localVarQueryParameter["merchantName"] = merchantName;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter["sortDirection"] = sortDirection;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter["sortBy"] = sortBy;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Xem danh sách tất cả cả merchant
     * @summary Xem danh sách tất cả cả merchant
     * @param {string} [username]
     * @param {string} [name]
     * @param {string} [requestDateFrom]
     * @param {string} [requestDateTo]
     * @param {'PERSONAL' | 'ORGANIZATION'} [businessType]
     * @param {'CREATED' | 'SUBMIT_INFO' | 'LINKED_ACCOUNT' | 'INACTIVE' | 'ACTIVE' | 'DENIED' | 'OPEN_ACCOUNT' | 'BLOCK'} [status]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllMerchant: async (
      username?: string,
      name?: string,
      requestDateFrom?: string,
      requestDateTo?: string,
      businessType?: "PERSONAL" | "ORGANIZATION",
      status?:
        | "CREATED"
        | "SUBMIT_INFO"
        | "LINKED_ACCOUNT"
        | "INACTIVE"
        | "ACTIVE"
        | "DENIED"
        | "OPEN_ACCOUNT"
        | "BLOCK",
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/merchant/cms/v1/getAll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (username !== undefined) {
        localVarQueryParameter["username"] = username;
      }

      if (name !== undefined) {
        localVarQueryParameter["name"] = name;
      }

      if (requestDateFrom !== undefined) {
        localVarQueryParameter["requestDateFrom"] =
          (requestDateFrom as any) instanceof Date
            ? (requestDateFrom as any).toISOString()
            : requestDateFrom;
      }

      if (requestDateTo !== undefined) {
        localVarQueryParameter["requestDateTo"] =
          (requestDateTo as any) instanceof Date
            ? (requestDateTo as any).toISOString()
            : requestDateTo;
      }

      if (businessType !== undefined) {
        localVarQueryParameter["businessType"] = businessType;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter["sortDirection"] = sortDirection;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter["sortBy"] = sortBy;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [CMS] Lấy danh sách phí gán của merchant
     * @param {string} merchantId
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllMerchantFee: async (
      merchantId: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'merchantId' is not null or undefined
      assertParamExists("getAllMerchantFee", "merchantId", merchantId);
      const localVarPath =
        `/api/merchant/cms/v1/merchantFee/{merchantId}`.replace(
          `{${"merchantId"}}`,
          encodeURIComponent(String(merchantId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get lịch sử thay đổi của merchant cms
     * @param {string} merchantCode
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHistoryCms: async (
      merchantCode: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'merchantCode' is not null or undefined
      assertParamExists("getHistoryCms", "merchantCode", merchantCode);
      const localVarPath =
        `/api/merchant/cms/v1/history/{merchantCode}`.replace(
          `{${"merchantCode"}}`,
          encodeURIComponent(String(merchantCode)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Xem chi tiết đối tác
     * @summary Xem chi tiết đối tác
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMerchantDetail: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getMerchantDetail", "id", id);
      const localVarPath = `/api/merchant/cms/v1/getDetail/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [CMS] Gỡ biểu phí đã khai báo của Merchant
     * @param {number} merchantFeeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeFeeMerchant: async (
      merchantFeeId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'merchantFeeId' is not null or undefined
      assertParamExists("removeFeeMerchant", "merchantFeeId", merchantFeeId);
      const localVarPath =
        `/api/merchant/cms/v1/removeFee/{merchantFeeId}`.replace(
          `{${"merchantFeeId"}}`,
          encodeURIComponent(String(merchantFeeId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Vô hiệu hóa thành viên đối tác
     * @summary Kích hoạt thành viên đối tác
     * @param {string} memberId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unblockMerchantMember: async (
      memberId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'memberId' is not null or undefined
      assertParamExists("unblockMerchantMember", "memberId", memberId);
      const localVarPath =
        `/api/merchant/cms/v1/unblockMember/{memberId}`.replace(
          `{${"memberId"}}`,
          encodeURIComponent(String(memberId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Cập nhật hợp đồng thu chi hộ
     * @param {CmsUpdateCoboPoboContractRequest} cmsUpdateCoboPoboContractRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCoboPoboContract: async (
      cmsUpdateCoboPoboContractRequest: CmsUpdateCoboPoboContractRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'cmsUpdateCoboPoboContractRequest' is not null or undefined
      assertParamExists(
        "updateCoboPoboContract",
        "cmsUpdateCoboPoboContractRequest",
        cmsUpdateCoboPoboContractRequest,
      );
      const localVarPath = `/api/merchant/cms/v1/updateCoboPoboContract`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        cmsUpdateCoboPoboContractRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CMSMerchantControllerApi - functional programming interface
 * @export
 */
export const CMSMerchantControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    CMSMerchantControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary [CMS] Khai báo biểu phí cho Merchant
     * @param {AddFeeMerchantRequest} addFeeMerchantRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addFeeMerchant(
      addFeeMerchantRequest: AddFeeMerchantRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusMerchantFeeResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addFeeMerchant(
        addFeeMerchantRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Duyệt đối tác
     * @summary Duyệt đối tác
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async approveMerchant(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseMerchantResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.approveMerchant(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Vô hiệu hóa thành viên đối tác
     * @summary Vô hiệu hóa thành viên đối tác
     * @param {string} memberId
     * @param {string} reason
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async blockMerchantMember(
      memberId: string,
      reason: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.blockMerchantMember(
          memberId,
          reason,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Khóa đối tác doanh nghiệp
     * @param {string} id
     * @param {string} reason
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async blockOrgMerchant(
      id: string,
      reason: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.blockOrgMerchant(id, reason, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Từ chối duyệt đối tác
     * @summary Từ chối duyệt đối tác
     * @param {string} id
     * @param {CmsDisapproveMerchantRequest} cmsDisapproveMerchantRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async disapproveMerchant(
      id: string,
      cmsDisapproveMerchantRequest: CmsDisapproveMerchantRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseMerchantResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.disapproveMerchant(
          id,
          cmsDisapproveMerchantRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Tải Hợp đồng
     * @param {string} merchantId
     * @param {'COOPERATION_CONTRACT_FOR_KLBP_SERVICE_SUPPLY'} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async download(
      merchantId: string,
      type: "COOPERATION_CONTRACT_FOR_KLBP_SERVICE_SUPPLY",
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.download(
        merchantId,
        type,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy thông tin định danh theo số tài khoản
     * @param {string} merchantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async ekycReconciliationFromBank(
      merchantId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseCmsEkycReconciliationFromBankResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.ekycReconciliationFromBank(
          merchantId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Xuất file danh sách đối tác
     * @summary Xuất file danh sách đối tác
     * @param {string} [username]
     * @param {string} [name]
     * @param {string} [requestDateFrom]
     * @param {string} [requestDateTo]
     * @param {'PERSONAL' | 'ORGANIZATION'} [businessType]
     * @param {'CREATED' | 'SUBMIT_INFO' | 'LINKED_ACCOUNT' | 'INACTIVE' | 'ACTIVE' | 'DENIED' | 'OPEN_ACCOUNT' | 'BLOCK'} [status]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportExcelMerchant(
      username?: string,
      name?: string,
      requestDateFrom?: string,
      requestDateTo?: string,
      businessType?: "PERSONAL" | "ORGANIZATION",
      status?:
        | "CREATED"
        | "SUBMIT_INFO"
        | "LINKED_ACCOUNT"
        | "INACTIVE"
        | "ACTIVE"
        | "DENIED"
        | "OPEN_ACCOUNT"
        | "BLOCK",
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.exportExcelMerchant(
          username,
          name,
          requestDateFrom,
          requestDateTo,
          businessType,
          status,
          sortDirection,
          sortBy,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Lấy danh sách thành viên đối tác
     * @summary Lấy danh sách thành viên của đối tác
     * @param {string} [merchantId]
     * @param {string} [username]
     * @param {string} [merchantName]
     * @param {'BLOCKED' | 'ACTIVE' | 'INACTIVE' | 'NOT_LOGGED_IN'} [status]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllMembers(
      merchantId?: string,
      username?: string,
      merchantName?: string,
      status?: "BLOCKED" | "ACTIVE" | "INACTIVE" | "NOT_LOGGED_IN",
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseUserResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllMembers(
        merchantId,
        username,
        merchantName,
        status,
        page,
        pageSize,
        sortDirection,
        sortBy,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Xem danh sách tất cả cả merchant
     * @summary Xem danh sách tất cả cả merchant
     * @param {string} [username]
     * @param {string} [name]
     * @param {string} [requestDateFrom]
     * @param {string} [requestDateTo]
     * @param {'PERSONAL' | 'ORGANIZATION'} [businessType]
     * @param {'CREATED' | 'SUBMIT_INFO' | 'LINKED_ACCOUNT' | 'INACTIVE' | 'ACTIVE' | 'DENIED' | 'OPEN_ACCOUNT' | 'BLOCK'} [status]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllMerchant(
      username?: string,
      name?: string,
      requestDateFrom?: string,
      requestDateTo?: string,
      businessType?: "PERSONAL" | "ORGANIZATION",
      status?:
        | "CREATED"
        | "SUBMIT_INFO"
        | "LINKED_ACCOUNT"
        | "INACTIVE"
        | "ACTIVE"
        | "DENIED"
        | "OPEN_ACCOUNT"
        | "BLOCK",
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseMerchantResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllMerchant(
        username,
        name,
        requestDateFrom,
        requestDateTo,
        businessType,
        status,
        page,
        pageSize,
        sortDirection,
        sortBy,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary [CMS] Lấy danh sách phí gán của merchant
     * @param {string} merchantId
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllMerchantFee(
      merchantId: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseMerchantFeeDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllMerchantFee(
          merchantId,
          page,
          size,
          sort,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get lịch sử thay đổi của merchant cms
     * @param {string} merchantCode
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getHistoryCms(
      merchantCode: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseMerchantHistoryDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getHistoryCms(
        merchantCode,
        page,
        size,
        sort,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Xem chi tiết đối tác
     * @summary Xem chi tiết đối tác
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMerchantDetail(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseMerchantDetailResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getMerchantDetail(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary [CMS] Gỡ biểu phí đã khai báo của Merchant
     * @param {number} merchantFeeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async removeFeeMerchant(
      merchantFeeId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusMerchantFeeResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.removeFeeMerchant(
          merchantFeeId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Vô hiệu hóa thành viên đối tác
     * @summary Kích hoạt thành viên đối tác
     * @param {string} memberId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async unblockMerchantMember(
      memberId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.unblockMerchantMember(
          memberId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Cập nhật hợp đồng thu chi hộ
     * @param {CmsUpdateCoboPoboContractRequest} cmsUpdateCoboPoboContractRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateCoboPoboContract(
      cmsUpdateCoboPoboContractRequest: CmsUpdateCoboPoboContractRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateCoboPoboContract(
          cmsUpdateCoboPoboContractRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * CMSMerchantControllerApi - factory interface
 * @export
 */
export const CMSMerchantControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CMSMerchantControllerApiFp(configuration);
  return {
    /**
     *
     * @summary [CMS] Khai báo biểu phí cho Merchant
     * @param {AddFeeMerchantRequest} addFeeMerchantRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addFeeMerchant(
      addFeeMerchantRequest: AddFeeMerchantRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusMerchantFeeResponse> {
      return localVarFp
        .addFeeMerchant(addFeeMerchantRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Duyệt đối tác
     * @summary Duyệt đối tác
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approveMerchant(
      id: string,
      options?: any,
    ): AxiosPromise<ResponseBaseMerchantResponse> {
      return localVarFp
        .approveMerchant(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Vô hiệu hóa thành viên đối tác
     * @summary Vô hiệu hóa thành viên đối tác
     * @param {string} memberId
     * @param {string} reason
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blockMerchantMember(
      memberId: string,
      reason: string,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .blockMerchantMember(memberId, reason, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Khóa đối tác doanh nghiệp
     * @param {string} id
     * @param {string} reason
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blockOrgMerchant(
      id: string,
      reason: string,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .blockOrgMerchant(id, reason, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Từ chối duyệt đối tác
     * @summary Từ chối duyệt đối tác
     * @param {string} id
     * @param {CmsDisapproveMerchantRequest} cmsDisapproveMerchantRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    disapproveMerchant(
      id: string,
      cmsDisapproveMerchantRequest: CmsDisapproveMerchantRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseMerchantResponse> {
      return localVarFp
        .disapproveMerchant(id, cmsDisapproveMerchantRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Tải Hợp đồng
     * @param {string} merchantId
     * @param {'COOPERATION_CONTRACT_FOR_KLBP_SERVICE_SUPPLY'} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    download(
      merchantId: string,
      type: "COOPERATION_CONTRACT_FOR_KLBP_SERVICE_SUPPLY",
      options?: any,
    ): AxiosPromise<File> {
      return localVarFp
        .download(merchantId, type, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy thông tin định danh theo số tài khoản
     * @param {string} merchantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ekycReconciliationFromBank(
      merchantId: string,
      options?: any,
    ): AxiosPromise<ResponseBaseCmsEkycReconciliationFromBankResponse> {
      return localVarFp
        .ekycReconciliationFromBank(merchantId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Xuất file danh sách đối tác
     * @summary Xuất file danh sách đối tác
     * @param {string} [username]
     * @param {string} [name]
     * @param {string} [requestDateFrom]
     * @param {string} [requestDateTo]
     * @param {'PERSONAL' | 'ORGANIZATION'} [businessType]
     * @param {'CREATED' | 'SUBMIT_INFO' | 'LINKED_ACCOUNT' | 'INACTIVE' | 'ACTIVE' | 'DENIED' | 'OPEN_ACCOUNT' | 'BLOCK'} [status]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportExcelMerchant(
      username?: string,
      name?: string,
      requestDateFrom?: string,
      requestDateTo?: string,
      businessType?: "PERSONAL" | "ORGANIZATION",
      status?:
        | "CREATED"
        | "SUBMIT_INFO"
        | "LINKED_ACCOUNT"
        | "INACTIVE"
        | "ACTIVE"
        | "DENIED"
        | "OPEN_ACCOUNT"
        | "BLOCK",
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: any,
    ): AxiosPromise<File> {
      return localVarFp
        .exportExcelMerchant(
          username,
          name,
          requestDateFrom,
          requestDateTo,
          businessType,
          status,
          sortDirection,
          sortBy,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * - Lấy danh sách thành viên đối tác
     * @summary Lấy danh sách thành viên của đối tác
     * @param {string} [merchantId]
     * @param {string} [username]
     * @param {string} [merchantName]
     * @param {'BLOCKED' | 'ACTIVE' | 'INACTIVE' | 'NOT_LOGGED_IN'} [status]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllMembers(
      merchantId?: string,
      username?: string,
      merchantName?: string,
      status?: "BLOCKED" | "ACTIVE" | "INACTIVE" | "NOT_LOGGED_IN",
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseUserResponse> {
      return localVarFp
        .getAllMembers(
          merchantId,
          username,
          merchantName,
          status,
          page,
          pageSize,
          sortDirection,
          sortBy,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * - Xem danh sách tất cả cả merchant
     * @summary Xem danh sách tất cả cả merchant
     * @param {string} [username]
     * @param {string} [name]
     * @param {string} [requestDateFrom]
     * @param {string} [requestDateTo]
     * @param {'PERSONAL' | 'ORGANIZATION'} [businessType]
     * @param {'CREATED' | 'SUBMIT_INFO' | 'LINKED_ACCOUNT' | 'INACTIVE' | 'ACTIVE' | 'DENIED' | 'OPEN_ACCOUNT' | 'BLOCK'} [status]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllMerchant(
      username?: string,
      name?: string,
      requestDateFrom?: string,
      requestDateTo?: string,
      businessType?: "PERSONAL" | "ORGANIZATION",
      status?:
        | "CREATED"
        | "SUBMIT_INFO"
        | "LINKED_ACCOUNT"
        | "INACTIVE"
        | "ACTIVE"
        | "DENIED"
        | "OPEN_ACCOUNT"
        | "BLOCK",
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseMerchantResponse> {
      return localVarFp
        .getAllMerchant(
          username,
          name,
          requestDateFrom,
          requestDateTo,
          businessType,
          status,
          page,
          pageSize,
          sortDirection,
          sortBy,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [CMS] Lấy danh sách phí gán của merchant
     * @param {string} merchantId
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllMerchantFee(
      merchantId: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseMerchantFeeDto> {
      return localVarFp
        .getAllMerchantFee(merchantId, page, size, sort, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get lịch sử thay đổi của merchant cms
     * @param {string} merchantCode
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHistoryCms(
      merchantCode: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseMerchantHistoryDto> {
      return localVarFp
        .getHistoryCms(merchantCode, page, size, sort, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Xem chi tiết đối tác
     * @summary Xem chi tiết đối tác
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMerchantDetail(
      id: string,
      options?: any,
    ): AxiosPromise<ResponseBaseMerchantDetailResponse> {
      return localVarFp
        .getMerchantDetail(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [CMS] Gỡ biểu phí đã khai báo của Merchant
     * @param {number} merchantFeeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeFeeMerchant(
      merchantFeeId: number,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusMerchantFeeResponse> {
      return localVarFp
        .removeFeeMerchant(merchantFeeId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Vô hiệu hóa thành viên đối tác
     * @summary Kích hoạt thành viên đối tác
     * @param {string} memberId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unblockMerchantMember(
      memberId: string,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .unblockMerchantMember(memberId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Cập nhật hợp đồng thu chi hộ
     * @param {CmsUpdateCoboPoboContractRequest} cmsUpdateCoboPoboContractRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCoboPoboContract(
      cmsUpdateCoboPoboContractRequest: CmsUpdateCoboPoboContractRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .updateCoboPoboContract(cmsUpdateCoboPoboContractRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for addFeeMerchant operation in CMSMerchantControllerApi.
 * @export
 * @interface CMSMerchantControllerApiAddFeeMerchantRequest
 */
export interface CMSMerchantControllerApiAddFeeMerchantRequest {
  /**
   *
   * @type {AddFeeMerchantRequest}
   * @memberof CMSMerchantControllerApiAddFeeMerchant
   */
  readonly addFeeMerchantRequest: AddFeeMerchantRequest;
}

/**
 * Request parameters for approveMerchant operation in CMSMerchantControllerApi.
 * @export
 * @interface CMSMerchantControllerApiApproveMerchantRequest
 */
export interface CMSMerchantControllerApiApproveMerchantRequest {
  /**
   *
   * @type {string}
   * @memberof CMSMerchantControllerApiApproveMerchant
   */
  readonly id: string;
}

/**
 * Request parameters for blockMerchantMember operation in CMSMerchantControllerApi.
 * @export
 * @interface CMSMerchantControllerApiBlockMerchantMemberRequest
 */
export interface CMSMerchantControllerApiBlockMerchantMemberRequest {
  /**
   *
   * @type {string}
   * @memberof CMSMerchantControllerApiBlockMerchantMember
   */
  readonly memberId: string;

  /**
   *
   * @type {string}
   * @memberof CMSMerchantControllerApiBlockMerchantMember
   */
  readonly reason: string;
}

/**
 * Request parameters for blockOrgMerchant operation in CMSMerchantControllerApi.
 * @export
 * @interface CMSMerchantControllerApiBlockOrgMerchantRequest
 */
export interface CMSMerchantControllerApiBlockOrgMerchantRequest {
  /**
   *
   * @type {string}
   * @memberof CMSMerchantControllerApiBlockOrgMerchant
   */
  readonly id: string;

  /**
   *
   * @type {string}
   * @memberof CMSMerchantControllerApiBlockOrgMerchant
   */
  readonly reason: string;
}

/**
 * Request parameters for disapproveMerchant operation in CMSMerchantControllerApi.
 * @export
 * @interface CMSMerchantControllerApiDisapproveMerchantRequest
 */
export interface CMSMerchantControllerApiDisapproveMerchantRequest {
  /**
   *
   * @type {string}
   * @memberof CMSMerchantControllerApiDisapproveMerchant
   */
  readonly id: string;

  /**
   *
   * @type {CmsDisapproveMerchantRequest}
   * @memberof CMSMerchantControllerApiDisapproveMerchant
   */
  readonly cmsDisapproveMerchantRequest: CmsDisapproveMerchantRequest;
}

/**
 * Request parameters for download operation in CMSMerchantControllerApi.
 * @export
 * @interface CMSMerchantControllerApiDownloadRequest
 */
export interface CMSMerchantControllerApiDownloadRequest {
  /**
   *
   * @type {string}
   * @memberof CMSMerchantControllerApiDownload
   */
  readonly merchantId: string;

  /**
   *
   * @type {'COOPERATION_CONTRACT_FOR_KLBP_SERVICE_SUPPLY'}
   * @memberof CMSMerchantControllerApiDownload
   */
  readonly type: "COOPERATION_CONTRACT_FOR_KLBP_SERVICE_SUPPLY";
}

/**
 * Request parameters for ekycReconciliationFromBank operation in CMSMerchantControllerApi.
 * @export
 * @interface CMSMerchantControllerApiEkycReconciliationFromBankRequest
 */
export interface CMSMerchantControllerApiEkycReconciliationFromBankRequest {
  /**
   *
   * @type {string}
   * @memberof CMSMerchantControllerApiEkycReconciliationFromBank
   */
  readonly merchantId: string;
}

/**
 * Request parameters for exportExcelMerchant operation in CMSMerchantControllerApi.
 * @export
 * @interface CMSMerchantControllerApiExportExcelMerchantRequest
 */
export interface CMSMerchantControllerApiExportExcelMerchantRequest {
  /**
   *
   * @type {string}
   * @memberof CMSMerchantControllerApiExportExcelMerchant
   */
  readonly username?: string;

  /**
   *
   * @type {string}
   * @memberof CMSMerchantControllerApiExportExcelMerchant
   */
  readonly name?: string;

  /**
   *
   * @type {string}
   * @memberof CMSMerchantControllerApiExportExcelMerchant
   */
  readonly requestDateFrom?: string;

  /**
   *
   * @type {string}
   * @memberof CMSMerchantControllerApiExportExcelMerchant
   */
  readonly requestDateTo?: string;

  /**
   *
   * @type {'PERSONAL' | 'ORGANIZATION'}
   * @memberof CMSMerchantControllerApiExportExcelMerchant
   */
  readonly businessType?: "PERSONAL" | "ORGANIZATION";

  /**
   *
   * @type {'CREATED' | 'SUBMIT_INFO' | 'LINKED_ACCOUNT' | 'INACTIVE' | 'ACTIVE' | 'DENIED' | 'OPEN_ACCOUNT' | 'BLOCK'}
   * @memberof CMSMerchantControllerApiExportExcelMerchant
   */
  readonly status?:
    | "CREATED"
    | "SUBMIT_INFO"
    | "LINKED_ACCOUNT"
    | "INACTIVE"
    | "ACTIVE"
    | "DENIED"
    | "OPEN_ACCOUNT"
    | "BLOCK";

  /**
   *
   * @type {'ASC' | 'DESC'}
   * @memberof CMSMerchantControllerApiExportExcelMerchant
   */
  readonly sortDirection?: "ASC" | "DESC";

  /**
   *
   * @type {string}
   * @memberof CMSMerchantControllerApiExportExcelMerchant
   */
  readonly sortBy?: string;
}

/**
 * Request parameters for getAllMembers operation in CMSMerchantControllerApi.
 * @export
 * @interface CMSMerchantControllerApiGetAllMembersRequest
 */
export interface CMSMerchantControllerApiGetAllMembersRequest {
  /**
   *
   * @type {string}
   * @memberof CMSMerchantControllerApiGetAllMembers
   */
  readonly merchantId?: string;

  /**
   *
   * @type {string}
   * @memberof CMSMerchantControllerApiGetAllMembers
   */
  readonly username?: string;

  /**
   *
   * @type {string}
   * @memberof CMSMerchantControllerApiGetAllMembers
   */
  readonly merchantName?: string;

  /**
   *
   * @type {'BLOCKED' | 'ACTIVE' | 'INACTIVE' | 'NOT_LOGGED_IN'}
   * @memberof CMSMerchantControllerApiGetAllMembers
   */
  readonly status?: "BLOCKED" | "ACTIVE" | "INACTIVE" | "NOT_LOGGED_IN";

  /**
   *
   * @type {number}
   * @memberof CMSMerchantControllerApiGetAllMembers
   */
  readonly page?: number;

  /**
   *
   * @type {number}
   * @memberof CMSMerchantControllerApiGetAllMembers
   */
  readonly pageSize?: number;

  /**
   *
   * @type {'ASC' | 'DESC'}
   * @memberof CMSMerchantControllerApiGetAllMembers
   */
  readonly sortDirection?: "ASC" | "DESC";

  /**
   *
   * @type {string}
   * @memberof CMSMerchantControllerApiGetAllMembers
   */
  readonly sortBy?: string;
}

/**
 * Request parameters for getAllMerchant operation in CMSMerchantControllerApi.
 * @export
 * @interface CMSMerchantControllerApiGetAllMerchantRequest
 */
export interface CMSMerchantControllerApiGetAllMerchantRequest {
  /**
   *
   * @type {string}
   * @memberof CMSMerchantControllerApiGetAllMerchant
   */
  readonly username?: string;

  /**
   *
   * @type {string}
   * @memberof CMSMerchantControllerApiGetAllMerchant
   */
  readonly name?: string;

  /**
   *
   * @type {string}
   * @memberof CMSMerchantControllerApiGetAllMerchant
   */
  readonly requestDateFrom?: string;

  /**
   *
   * @type {string}
   * @memberof CMSMerchantControllerApiGetAllMerchant
   */
  readonly requestDateTo?: string;

  /**
   *
   * @type {'PERSONAL' | 'ORGANIZATION'}
   * @memberof CMSMerchantControllerApiGetAllMerchant
   */
  readonly businessType?: "PERSONAL" | "ORGANIZATION";

  /**
   *
   * @type {'CREATED' | 'SUBMIT_INFO' | 'LINKED_ACCOUNT' | 'INACTIVE' | 'ACTIVE' | 'DENIED' | 'OPEN_ACCOUNT' | 'BLOCK'}
   * @memberof CMSMerchantControllerApiGetAllMerchant
   */
  readonly status?:
    | "CREATED"
    | "SUBMIT_INFO"
    | "LINKED_ACCOUNT"
    | "INACTIVE"
    | "ACTIVE"
    | "DENIED"
    | "OPEN_ACCOUNT"
    | "BLOCK";

  /**
   *
   * @type {number}
   * @memberof CMSMerchantControllerApiGetAllMerchant
   */
  readonly page?: number;

  /**
   *
   * @type {number}
   * @memberof CMSMerchantControllerApiGetAllMerchant
   */
  readonly pageSize?: number;

  /**
   *
   * @type {'ASC' | 'DESC'}
   * @memberof CMSMerchantControllerApiGetAllMerchant
   */
  readonly sortDirection?: "ASC" | "DESC";

  /**
   *
   * @type {string}
   * @memberof CMSMerchantControllerApiGetAllMerchant
   */
  readonly sortBy?: string;
}

/**
 * Request parameters for getAllMerchantFee operation in CMSMerchantControllerApi.
 * @export
 * @interface CMSMerchantControllerApiGetAllMerchantFeeRequest
 */
export interface CMSMerchantControllerApiGetAllMerchantFeeRequest {
  /**
   *
   * @type {string}
   * @memberof CMSMerchantControllerApiGetAllMerchantFee
   */
  readonly merchantId: string;

  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof CMSMerchantControllerApiGetAllMerchantFee
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof CMSMerchantControllerApiGetAllMerchantFee
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof CMSMerchantControllerApiGetAllMerchantFee
   */
  readonly sort?: Array<string>;
}

/**
 * Request parameters for getHistoryCms operation in CMSMerchantControllerApi.
 * @export
 * @interface CMSMerchantControllerApiGetHistoryCmsRequest
 */
export interface CMSMerchantControllerApiGetHistoryCmsRequest {
  /**
   *
   * @type {string}
   * @memberof CMSMerchantControllerApiGetHistoryCms
   */
  readonly merchantCode: string;

  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof CMSMerchantControllerApiGetHistoryCms
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof CMSMerchantControllerApiGetHistoryCms
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof CMSMerchantControllerApiGetHistoryCms
   */
  readonly sort?: Array<string>;
}

/**
 * Request parameters for getMerchantDetail operation in CMSMerchantControllerApi.
 * @export
 * @interface CMSMerchantControllerApiGetMerchantDetailRequest
 */
export interface CMSMerchantControllerApiGetMerchantDetailRequest {
  /**
   *
   * @type {string}
   * @memberof CMSMerchantControllerApiGetMerchantDetail
   */
  readonly id: string;
}

/**
 * Request parameters for removeFeeMerchant operation in CMSMerchantControllerApi.
 * @export
 * @interface CMSMerchantControllerApiRemoveFeeMerchantRequest
 */
export interface CMSMerchantControllerApiRemoveFeeMerchantRequest {
  /**
   *
   * @type {number}
   * @memberof CMSMerchantControllerApiRemoveFeeMerchant
   */
  readonly merchantFeeId: number;
}

/**
 * Request parameters for unblockMerchantMember operation in CMSMerchantControllerApi.
 * @export
 * @interface CMSMerchantControllerApiUnblockMerchantMemberRequest
 */
export interface CMSMerchantControllerApiUnblockMerchantMemberRequest {
  /**
   *
   * @type {string}
   * @memberof CMSMerchantControllerApiUnblockMerchantMember
   */
  readonly memberId: string;
}

/**
 * Request parameters for updateCoboPoboContract operation in CMSMerchantControllerApi.
 * @export
 * @interface CMSMerchantControllerApiUpdateCoboPoboContractRequest
 */
export interface CMSMerchantControllerApiUpdateCoboPoboContractRequest {
  /**
   *
   * @type {CmsUpdateCoboPoboContractRequest}
   * @memberof CMSMerchantControllerApiUpdateCoboPoboContract
   */
  readonly cmsUpdateCoboPoboContractRequest: CmsUpdateCoboPoboContractRequest;
}

/**
 * CMSMerchantControllerApi - object-oriented interface
 * @export
 * @class CMSMerchantControllerApi
 * @extends {BaseAPI}
 */
export class CMSMerchantControllerApi extends BaseAPI {
  /**
   *
   * @summary [CMS] Khai báo biểu phí cho Merchant
   * @param {CMSMerchantControllerApiAddFeeMerchantRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSMerchantControllerApi
   */
  public addFeeMerchant(
    requestParameters: CMSMerchantControllerApiAddFeeMerchantRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSMerchantControllerApiFp(this.configuration)
      .addFeeMerchant(requestParameters.addFeeMerchantRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Duyệt đối tác
   * @summary Duyệt đối tác
   * @param {CMSMerchantControllerApiApproveMerchantRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSMerchantControllerApi
   */
  public approveMerchant(
    requestParameters: CMSMerchantControllerApiApproveMerchantRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSMerchantControllerApiFp(this.configuration)
      .approveMerchant(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Vô hiệu hóa thành viên đối tác
   * @summary Vô hiệu hóa thành viên đối tác
   * @param {CMSMerchantControllerApiBlockMerchantMemberRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSMerchantControllerApi
   */
  public blockMerchantMember(
    requestParameters: CMSMerchantControllerApiBlockMerchantMemberRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSMerchantControllerApiFp(this.configuration)
      .blockMerchantMember(
        requestParameters.memberId,
        requestParameters.reason,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Khóa đối tác doanh nghiệp
   * @param {CMSMerchantControllerApiBlockOrgMerchantRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSMerchantControllerApi
   */
  public blockOrgMerchant(
    requestParameters: CMSMerchantControllerApiBlockOrgMerchantRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSMerchantControllerApiFp(this.configuration)
      .blockOrgMerchant(requestParameters.id, requestParameters.reason, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Từ chối duyệt đối tác
   * @summary Từ chối duyệt đối tác
   * @param {CMSMerchantControllerApiDisapproveMerchantRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSMerchantControllerApi
   */
  public disapproveMerchant(
    requestParameters: CMSMerchantControllerApiDisapproveMerchantRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSMerchantControllerApiFp(this.configuration)
      .disapproveMerchant(
        requestParameters.id,
        requestParameters.cmsDisapproveMerchantRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Tải Hợp đồng
   * @param {CMSMerchantControllerApiDownloadRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSMerchantControllerApi
   */
  public download(
    requestParameters: CMSMerchantControllerApiDownloadRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSMerchantControllerApiFp(this.configuration)
      .download(requestParameters.merchantId, requestParameters.type, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy thông tin định danh theo số tài khoản
   * @param {CMSMerchantControllerApiEkycReconciliationFromBankRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSMerchantControllerApi
   */
  public ekycReconciliationFromBank(
    requestParameters: CMSMerchantControllerApiEkycReconciliationFromBankRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSMerchantControllerApiFp(this.configuration)
      .ekycReconciliationFromBank(requestParameters.merchantId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Xuất file danh sách đối tác
   * @summary Xuất file danh sách đối tác
   * @param {CMSMerchantControllerApiExportExcelMerchantRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSMerchantControllerApi
   */
  public exportExcelMerchant(
    requestParameters: CMSMerchantControllerApiExportExcelMerchantRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return CMSMerchantControllerApiFp(this.configuration)
      .exportExcelMerchant(
        requestParameters.username,
        requestParameters.name,
        requestParameters.requestDateFrom,
        requestParameters.requestDateTo,
        requestParameters.businessType,
        requestParameters.status,
        requestParameters.sortDirection,
        requestParameters.sortBy,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Lấy danh sách thành viên đối tác
   * @summary Lấy danh sách thành viên của đối tác
   * @param {CMSMerchantControllerApiGetAllMembersRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSMerchantControllerApi
   */
  public getAllMembers(
    requestParameters: CMSMerchantControllerApiGetAllMembersRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return CMSMerchantControllerApiFp(this.configuration)
      .getAllMembers(
        requestParameters.merchantId,
        requestParameters.username,
        requestParameters.merchantName,
        requestParameters.status,
        requestParameters.page,
        requestParameters.pageSize,
        requestParameters.sortDirection,
        requestParameters.sortBy,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Xem danh sách tất cả cả merchant
   * @summary Xem danh sách tất cả cả merchant
   * @param {CMSMerchantControllerApiGetAllMerchantRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSMerchantControllerApi
   */
  public getAllMerchant(
    requestParameters: CMSMerchantControllerApiGetAllMerchantRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return CMSMerchantControllerApiFp(this.configuration)
      .getAllMerchant(
        requestParameters.username,
        requestParameters.name,
        requestParameters.requestDateFrom,
        requestParameters.requestDateTo,
        requestParameters.businessType,
        requestParameters.status,
        requestParameters.page,
        requestParameters.pageSize,
        requestParameters.sortDirection,
        requestParameters.sortBy,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [CMS] Lấy danh sách phí gán của merchant
   * @param {CMSMerchantControllerApiGetAllMerchantFeeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSMerchantControllerApi
   */
  public getAllMerchantFee(
    requestParameters: CMSMerchantControllerApiGetAllMerchantFeeRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSMerchantControllerApiFp(this.configuration)
      .getAllMerchantFee(
        requestParameters.merchantId,
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get lịch sử thay đổi của merchant cms
   * @param {CMSMerchantControllerApiGetHistoryCmsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSMerchantControllerApi
   */
  public getHistoryCms(
    requestParameters: CMSMerchantControllerApiGetHistoryCmsRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSMerchantControllerApiFp(this.configuration)
      .getHistoryCms(
        requestParameters.merchantCode,
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Xem chi tiết đối tác
   * @summary Xem chi tiết đối tác
   * @param {CMSMerchantControllerApiGetMerchantDetailRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSMerchantControllerApi
   */
  public getMerchantDetail(
    requestParameters: CMSMerchantControllerApiGetMerchantDetailRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSMerchantControllerApiFp(this.configuration)
      .getMerchantDetail(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [CMS] Gỡ biểu phí đã khai báo của Merchant
   * @param {CMSMerchantControllerApiRemoveFeeMerchantRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSMerchantControllerApi
   */
  public removeFeeMerchant(
    requestParameters: CMSMerchantControllerApiRemoveFeeMerchantRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSMerchantControllerApiFp(this.configuration)
      .removeFeeMerchant(requestParameters.merchantFeeId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Vô hiệu hóa thành viên đối tác
   * @summary Kích hoạt thành viên đối tác
   * @param {CMSMerchantControllerApiUnblockMerchantMemberRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSMerchantControllerApi
   */
  public unblockMerchantMember(
    requestParameters: CMSMerchantControllerApiUnblockMerchantMemberRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSMerchantControllerApiFp(this.configuration)
      .unblockMerchantMember(requestParameters.memberId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Cập nhật hợp đồng thu chi hộ
   * @param {CMSMerchantControllerApiUpdateCoboPoboContractRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSMerchantControllerApi
   */
  public updateCoboPoboContract(
    requestParameters: CMSMerchantControllerApiUpdateCoboPoboContractRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSMerchantControllerApiFp(this.configuration)
      .updateCoboPoboContract(
        requestParameters.cmsUpdateCoboPoboContractRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CMSPOSDeviceApi - axios parameter creator
 * @export
 */
export const CMSPOSDeviceApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Tạo thiết bị paypos cho merchant
     * @param {CreatePosDeviceRequest} createPosDeviceRequest
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    createPayPosDevice: async (
      createPosDeviceRequest: CreatePosDeviceRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createPosDeviceRequest' is not null or undefined
      assertParamExists(
        "createPayPosDevice",
        "createPosDeviceRequest",
        createPosDeviceRequest,
      );
      const localVarPath = `/api/paypos/cms/v1/device/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createPosDeviceRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xóa thiết bị bằng id
     * @param {string} terminalId
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    deletePayPosDevice: async (
      terminalId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'terminalId' is not null or undefined
      assertParamExists("deletePayPosDevice", "terminalId", terminalId);
      const localVarPath =
        `/api/paypos/cms/v1/device/delete/{terminalId}`.replace(
          `{${"terminalId"}}`,
          encodeURIComponent(String(terminalId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy danh sách máy POS
     * @param {string} [terminalId]
     * @param {'ACTIVE' | 'INACTIVE'} [status]
     * @param {string} [merchantId]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    getListPos1: async (
      terminalId?: string,
      status?: "ACTIVE" | "INACTIVE",
      merchantId?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/paypos/cms/v1/device/getAll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (terminalId !== undefined) {
        localVarQueryParameter["terminalId"] = terminalId;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (merchantId !== undefined) {
        localVarQueryParameter["merchantId"] = merchantId;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter["sortDirection"] = sortDirection;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter["sortBy"] = sortBy;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update thiết bị paypos cho merchant
     * @param {UpdatePosDeviceRequest} updatePosDeviceRequest
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    updatePayPosDevice: async (
      updatePosDeviceRequest: UpdatePosDeviceRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updatePosDeviceRequest' is not null or undefined
      assertParamExists(
        "updatePayPosDevice",
        "updatePosDeviceRequest",
        updatePosDeviceRequest,
      );
      const localVarPath = `/api/paypos/cms/v1/device/update`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updatePosDeviceRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CMSPOSDeviceApi - functional programming interface
 * @export
 */
export const CMSPOSDeviceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    CMSPOSDeviceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Tạo thiết bị paypos cho merchant
     * @param {CreatePosDeviceRequest} createPosDeviceRequest
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async createPayPosDevice(
      createPosDeviceRequest: CreatePosDeviceRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseInfoDevicePayPosResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createPayPosDevice(
          createPosDeviceRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Xóa thiết bị bằng id
     * @param {string} terminalId
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async deletePayPosDevice(
      terminalId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deletePayPosDevice(terminalId, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy danh sách máy POS
     * @param {string} [terminalId]
     * @param {'ACTIVE' | 'INACTIVE'} [status]
     * @param {string} [merchantId]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async getListPos1(
      terminalId?: string,
      status?: "ACTIVE" | "INACTIVE",
      merchantId?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseInfoDevicePayPosResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getListPos1(
        terminalId,
        status,
        merchantId,
        page,
        pageSize,
        sortDirection,
        sortBy,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update thiết bị paypos cho merchant
     * @param {UpdatePosDeviceRequest} updatePosDeviceRequest
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async updatePayPosDevice(
      updatePosDeviceRequest: UpdatePosDeviceRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseInfoDevicePayPosResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updatePayPosDevice(
          updatePosDeviceRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * CMSPOSDeviceApi - factory interface
 * @export
 */
export const CMSPOSDeviceApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CMSPOSDeviceApiFp(configuration);
  return {
    /**
     *
     * @summary Tạo thiết bị paypos cho merchant
     * @param {CreatePosDeviceRequest} createPosDeviceRequest
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    createPayPosDevice(
      createPosDeviceRequest: CreatePosDeviceRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseInfoDevicePayPosResponse> {
      return localVarFp
        .createPayPosDevice(createPosDeviceRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xóa thiết bị bằng id
     * @param {string} terminalId
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    deletePayPosDevice(
      terminalId: string,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .deletePayPosDevice(terminalId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy danh sách máy POS
     * @param {string} [terminalId]
     * @param {'ACTIVE' | 'INACTIVE'} [status]
     * @param {string} [merchantId]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    getListPos1(
      terminalId?: string,
      status?: "ACTIVE" | "INACTIVE",
      merchantId?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseInfoDevicePayPosResponse> {
      return localVarFp
        .getListPos1(
          terminalId,
          status,
          merchantId,
          page,
          pageSize,
          sortDirection,
          sortBy,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update thiết bị paypos cho merchant
     * @param {UpdatePosDeviceRequest} updatePosDeviceRequest
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    updatePayPosDevice(
      updatePosDeviceRequest: UpdatePosDeviceRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseInfoDevicePayPosResponse> {
      return localVarFp
        .updatePayPosDevice(updatePosDeviceRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createPayPosDevice operation in CMSPOSDeviceApi.
 * @export
 * @interface CMSPOSDeviceApiCreatePayPosDeviceRequest
 */
export interface CMSPOSDeviceApiCreatePayPosDeviceRequest {
  /**
   *
   * @type {CreatePosDeviceRequest}
   * @memberof CMSPOSDeviceApiCreatePayPosDevice
   */
  readonly createPosDeviceRequest: CreatePosDeviceRequest;
}

/**
 * Request parameters for deletePayPosDevice operation in CMSPOSDeviceApi.
 * @export
 * @interface CMSPOSDeviceApiDeletePayPosDeviceRequest
 */
export interface CMSPOSDeviceApiDeletePayPosDeviceRequest {
  /**
   *
   * @type {string}
   * @memberof CMSPOSDeviceApiDeletePayPosDevice
   */
  readonly terminalId: string;
}

/**
 * Request parameters for getListPos1 operation in CMSPOSDeviceApi.
 * @export
 * @interface CMSPOSDeviceApiGetListPos1Request
 */
export interface CMSPOSDeviceApiGetListPos1Request {
  /**
   *
   * @type {string}
   * @memberof CMSPOSDeviceApiGetListPos1
   */
  readonly terminalId?: string;

  /**
   *
   * @type {'ACTIVE' | 'INACTIVE'}
   * @memberof CMSPOSDeviceApiGetListPos1
   */
  readonly status?: "ACTIVE" | "INACTIVE";

  /**
   *
   * @type {string}
   * @memberof CMSPOSDeviceApiGetListPos1
   */
  readonly merchantId?: string;

  /**
   *
   * @type {number}
   * @memberof CMSPOSDeviceApiGetListPos1
   */
  readonly page?: number;

  /**
   *
   * @type {number}
   * @memberof CMSPOSDeviceApiGetListPos1
   */
  readonly pageSize?: number;

  /**
   *
   * @type {'ASC' | 'DESC'}
   * @memberof CMSPOSDeviceApiGetListPos1
   */
  readonly sortDirection?: "ASC" | "DESC";

  /**
   *
   * @type {string}
   * @memberof CMSPOSDeviceApiGetListPos1
   */
  readonly sortBy?: string;
}

/**
 * Request parameters for updatePayPosDevice operation in CMSPOSDeviceApi.
 * @export
 * @interface CMSPOSDeviceApiUpdatePayPosDeviceRequest
 */
export interface CMSPOSDeviceApiUpdatePayPosDeviceRequest {
  /**
   *
   * @type {UpdatePosDeviceRequest}
   * @memberof CMSPOSDeviceApiUpdatePayPosDevice
   */
  readonly updatePosDeviceRequest: UpdatePosDeviceRequest;
}

/**
 * CMSPOSDeviceApi - object-oriented interface
 * @export
 * @class CMSPOSDeviceApi
 * @extends {BaseAPI}
 */
export class CMSPOSDeviceApi extends BaseAPI {
  /**
   *
   * @summary Tạo thiết bị paypos cho merchant
   * @param {CMSPOSDeviceApiCreatePayPosDeviceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof CMSPOSDeviceApi
   */
  public createPayPosDevice(
    requestParameters: CMSPOSDeviceApiCreatePayPosDeviceRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSPOSDeviceApiFp(this.configuration)
      .createPayPosDevice(requestParameters.createPosDeviceRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xóa thiết bị bằng id
   * @param {CMSPOSDeviceApiDeletePayPosDeviceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof CMSPOSDeviceApi
   */
  public deletePayPosDevice(
    requestParameters: CMSPOSDeviceApiDeletePayPosDeviceRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSPOSDeviceApiFp(this.configuration)
      .deletePayPosDevice(requestParameters.terminalId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy danh sách máy POS
   * @param {CMSPOSDeviceApiGetListPos1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof CMSPOSDeviceApi
   */
  public getListPos1(
    requestParameters: CMSPOSDeviceApiGetListPos1Request = {},
    options?: AxiosRequestConfig,
  ) {
    return CMSPOSDeviceApiFp(this.configuration)
      .getListPos1(
        requestParameters.terminalId,
        requestParameters.status,
        requestParameters.merchantId,
        requestParameters.page,
        requestParameters.pageSize,
        requestParameters.sortDirection,
        requestParameters.sortBy,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update thiết bị paypos cho merchant
   * @param {CMSPOSDeviceApiUpdatePayPosDeviceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof CMSPOSDeviceApi
   */
  public updatePayPosDevice(
    requestParameters: CMSPOSDeviceApiUpdatePayPosDeviceRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSPOSDeviceApiFp(this.configuration)
      .updatePayPosDevice(requestParameters.updatePosDeviceRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CMSRoleGroupControllerApi - axios parameter creator
 * @export
 */
export const CMSRoleGroupControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Tạo mới nhóm quyền quản trị viên
     * @param {CreateRoleGroupRequest} createRoleGroupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAdminRoleGroup: async (
      createRoleGroupRequest: CreateRoleGroupRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createRoleGroupRequest' is not null or undefined
      assertParamExists(
        "createAdminRoleGroup",
        "createRoleGroupRequest",
        createRoleGroupRequest,
      );
      const localVarPath = `/api/roleGroup/cms/v1/create/admin`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createRoleGroupRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Tạo mới nhóm quyền đối tác
     * @param {CreateRoleGroupRequest} createRoleGroupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMerchantRoleGroup: async (
      createRoleGroupRequest: CreateRoleGroupRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createRoleGroupRequest' is not null or undefined
      assertParamExists(
        "createMerchantRoleGroup",
        "createRoleGroupRequest",
        createRoleGroupRequest,
      );
      const localVarPath = `/api/roleGroup/cms/v1/create/merchant`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createRoleGroupRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xóa nhóm quyền
     * @param {number} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteRoleGroup: async (
      groupId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists("deleteRoleGroup", "groupId", groupId);
      const localVarPath = `/api/roleGroup/cms/v1/delete/{groupId}`.replace(
        `{${"groupId"}}`,
        encodeURIComponent(String(groupId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xóa nhóm quyền
     * @param {number} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteRoleGroupAdmin: async (
      groupId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists("deleteRoleGroupAdmin", "groupId", groupId);
      const localVarPath =
        `/api/roleGroup/cms/v1/delete/admin/{groupId}`.replace(
          `{${"groupId"}}`,
          encodeURIComponent(String(groupId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xem danh sách nhóm permissions quản trị viên
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllAdminPermissions: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/roleGroup/cms/v1/getPermissions/admin`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xem danh sách nhóm quyền quản trị viên
     * @param {string} [code]
     * @param {string} [name]
     * @param {'ACTIVE' | 'CLOSED'} [status]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllAdminRoleGroups: async (
      code?: string,
      name?: string,
      status?: "ACTIVE" | "CLOSED",
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/roleGroup/cms/v1/getAll/admin`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (code !== undefined) {
        localVarQueryParameter["code"] = code;
      }

      if (name !== undefined) {
        localVarQueryParameter["name"] = name;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter["sortDirection"] = sortDirection;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter["sortBy"] = sortBy;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xem danh sách nhóm permissions đối tác
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllMerchantPermissions: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/roleGroup/cms/v1/getPermissions/merchant`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xem danh sách nhóm quyền đối tác
     * @param {string} [code]
     * @param {string} [name]
     * @param {'ACTIVE' | 'CLOSED'} [status]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllMerchantRoleGroups: async (
      code?: string,
      name?: string,
      status?: "ACTIVE" | "CLOSED",
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/roleGroup/cms/v1/getAll/merchant`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (code !== undefined) {
        localVarQueryParameter["code"] = code;
      }

      if (name !== undefined) {
        localVarQueryParameter["name"] = name;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter["sortDirection"] = sortDirection;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter["sortBy"] = sortBy;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xem chi tiết nhóm quyền
     * @param {number} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRoleGroupDetail: async (
      groupId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists("getRoleGroupDetail", "groupId", groupId);
      const localVarPath = `/api/roleGroup/cms/v1/getDetail/{groupId}`.replace(
        `{${"groupId"}}`,
        encodeURIComponent(String(groupId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xem chi tiết nhóm quyền admin
     * @param {number} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRoleGroupDetailAdmin: async (
      groupId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists("getRoleGroupDetailAdmin", "groupId", groupId);
      const localVarPath =
        `/api/roleGroup/cms/v1/getDetail/admin/{groupId}`.replace(
          `{${"groupId"}}`,
          encodeURIComponent(String(groupId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xem chi tiết nhóm quyền bằng mã nhóm quyền
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRoleGroupDetailByCode: async (
      code: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists("getRoleGroupDetailByCode", "code", code);
      const localVarPath = `/api/roleGroup/cms/v1/getByCode/{code}`.replace(
        `{${"code"}}`,
        encodeURIComponent(String(code)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xem chi tiết nhóm quyền admin bằng mã nhóm quyền
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRoleGroupDetailByCodeAdmin: async (
      code: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists("getRoleGroupDetailByCodeAdmin", "code", code);
      const localVarPath =
        `/api/roleGroup/cms/v1/getByCode/admin/{code}`.replace(
          `{${"code"}}`,
          encodeURIComponent(String(code)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Chỉnh sửa nhóm quyền
     * @param {number} groupId
     * @param {UpdateRoleGroupRequest} updateRoleGroupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateRoleGroup: async (
      groupId: number,
      updateRoleGroupRequest: UpdateRoleGroupRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists("updateRoleGroup", "groupId", groupId);
      // verify required parameter 'updateRoleGroupRequest' is not null or undefined
      assertParamExists(
        "updateRoleGroup",
        "updateRoleGroupRequest",
        updateRoleGroupRequest,
      );
      const localVarPath = `/api/roleGroup/cms/v1/update/{groupId}`.replace(
        `{${"groupId"}}`,
        encodeURIComponent(String(groupId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateRoleGroupRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Chỉnh sửa nhóm quyền admin
     * @param {number} groupId
     * @param {UpdateRoleGroupRequest} updateRoleGroupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateRoleGroupAdmin: async (
      groupId: number,
      updateRoleGroupRequest: UpdateRoleGroupRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists("updateRoleGroupAdmin", "groupId", groupId);
      // verify required parameter 'updateRoleGroupRequest' is not null or undefined
      assertParamExists(
        "updateRoleGroupAdmin",
        "updateRoleGroupRequest",
        updateRoleGroupRequest,
      );
      const localVarPath =
        `/api/roleGroup/cms/v1/update/admin/{groupId}`.replace(
          `{${"groupId"}}`,
          encodeURIComponent(String(groupId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateRoleGroupRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CMSRoleGroupControllerApi - functional programming interface
 * @export
 */
export const CMSRoleGroupControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    CMSRoleGroupControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Tạo mới nhóm quyền quản trị viên
     * @param {CreateRoleGroupRequest} createRoleGroupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createAdminRoleGroup(
      createRoleGroupRequest: CreateRoleGroupRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseRoleGroupResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createAdminRoleGroup(
          createRoleGroupRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Tạo mới nhóm quyền đối tác
     * @param {CreateRoleGroupRequest} createRoleGroupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createMerchantRoleGroup(
      createRoleGroupRequest: CreateRoleGroupRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseRoleGroupResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createMerchantRoleGroup(
          createRoleGroupRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Xóa nhóm quyền
     * @param {number} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteRoleGroup(
      groupId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseRoleGroupResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRoleGroup(
        groupId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Xóa nhóm quyền
     * @param {number} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteRoleGroupAdmin(
      groupId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseRoleGroupResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteRoleGroupAdmin(groupId, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Xem danh sách nhóm permissions quản trị viên
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllAdminPermissions(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetAllPermissionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllAdminPermissions(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Xem danh sách nhóm quyền quản trị viên
     * @param {string} [code]
     * @param {string} [name]
     * @param {'ACTIVE' | 'CLOSED'} [status]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllAdminRoleGroups(
      code?: string,
      name?: string,
      status?: "ACTIVE" | "CLOSED",
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseRoleGroupResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllAdminRoleGroups(
          code,
          name,
          status,
          page,
          pageSize,
          sortDirection,
          sortBy,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Xem danh sách nhóm permissions đối tác
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllMerchantPermissions(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetAllPermissionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllMerchantPermissions(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Xem danh sách nhóm quyền đối tác
     * @param {string} [code]
     * @param {string} [name]
     * @param {'ACTIVE' | 'CLOSED'} [status]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllMerchantRoleGroups(
      code?: string,
      name?: string,
      status?: "ACTIVE" | "CLOSED",
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseRoleGroupResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllMerchantRoleGroups(
          code,
          name,
          status,
          page,
          pageSize,
          sortDirection,
          sortBy,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Xem chi tiết nhóm quyền
     * @param {number} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRoleGroupDetail(
      groupId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseRoleGroupDetailResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getRoleGroupDetail(groupId, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Xem chi tiết nhóm quyền admin
     * @param {number} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRoleGroupDetailAdmin(
      groupId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseRoleGroupDetailResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getRoleGroupDetailAdmin(
          groupId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Xem chi tiết nhóm quyền bằng mã nhóm quyền
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRoleGroupDetailByCode(
      code: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseRoleGroupDetailResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getRoleGroupDetailByCode(code, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Xem chi tiết nhóm quyền admin bằng mã nhóm quyền
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRoleGroupDetailByCodeAdmin(
      code: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseRoleGroupDetailResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getRoleGroupDetailByCodeAdmin(
          code,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Chỉnh sửa nhóm quyền
     * @param {number} groupId
     * @param {UpdateRoleGroupRequest} updateRoleGroupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateRoleGroup(
      groupId: number,
      updateRoleGroupRequest: UpdateRoleGroupRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseRoleGroupResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateRoleGroup(
        groupId,
        updateRoleGroupRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Chỉnh sửa nhóm quyền admin
     * @param {number} groupId
     * @param {UpdateRoleGroupRequest} updateRoleGroupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateRoleGroupAdmin(
      groupId: number,
      updateRoleGroupRequest: UpdateRoleGroupRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseRoleGroupResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateRoleGroupAdmin(
          groupId,
          updateRoleGroupRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * CMSRoleGroupControllerApi - factory interface
 * @export
 */
export const CMSRoleGroupControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CMSRoleGroupControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Tạo mới nhóm quyền quản trị viên
     * @param {CreateRoleGroupRequest} createRoleGroupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAdminRoleGroup(
      createRoleGroupRequest: CreateRoleGroupRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseRoleGroupResponse> {
      return localVarFp
        .createAdminRoleGroup(createRoleGroupRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Tạo mới nhóm quyền đối tác
     * @param {CreateRoleGroupRequest} createRoleGroupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMerchantRoleGroup(
      createRoleGroupRequest: CreateRoleGroupRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseRoleGroupResponse> {
      return localVarFp
        .createMerchantRoleGroup(createRoleGroupRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xóa nhóm quyền
     * @param {number} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteRoleGroup(
      groupId: number,
      options?: any,
    ): AxiosPromise<ResponseBaseRoleGroupResponse> {
      return localVarFp
        .deleteRoleGroup(groupId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xóa nhóm quyền
     * @param {number} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteRoleGroupAdmin(
      groupId: number,
      options?: any,
    ): AxiosPromise<ResponseBaseRoleGroupResponse> {
      return localVarFp
        .deleteRoleGroupAdmin(groupId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xem danh sách nhóm permissions quản trị viên
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllAdminPermissions(
      options?: any,
    ): AxiosPromise<ResponseBaseGetAllPermissionResponse> {
      return localVarFp
        .getAllAdminPermissions(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xem danh sách nhóm quyền quản trị viên
     * @param {string} [code]
     * @param {string} [name]
     * @param {'ACTIVE' | 'CLOSED'} [status]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllAdminRoleGroups(
      code?: string,
      name?: string,
      status?: "ACTIVE" | "CLOSED",
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseRoleGroupResponse> {
      return localVarFp
        .getAllAdminRoleGroups(
          code,
          name,
          status,
          page,
          pageSize,
          sortDirection,
          sortBy,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xem danh sách nhóm permissions đối tác
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllMerchantPermissions(
      options?: any,
    ): AxiosPromise<ResponseBaseGetAllPermissionResponse> {
      return localVarFp
        .getAllMerchantPermissions(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xem danh sách nhóm quyền đối tác
     * @param {string} [code]
     * @param {string} [name]
     * @param {'ACTIVE' | 'CLOSED'} [status]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllMerchantRoleGroups(
      code?: string,
      name?: string,
      status?: "ACTIVE" | "CLOSED",
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseRoleGroupResponse> {
      return localVarFp
        .getAllMerchantRoleGroups(
          code,
          name,
          status,
          page,
          pageSize,
          sortDirection,
          sortBy,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xem chi tiết nhóm quyền
     * @param {number} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRoleGroupDetail(
      groupId: number,
      options?: any,
    ): AxiosPromise<ResponseBaseRoleGroupDetailResponse> {
      return localVarFp
        .getRoleGroupDetail(groupId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xem chi tiết nhóm quyền admin
     * @param {number} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRoleGroupDetailAdmin(
      groupId: number,
      options?: any,
    ): AxiosPromise<ResponseBaseRoleGroupDetailResponse> {
      return localVarFp
        .getRoleGroupDetailAdmin(groupId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xem chi tiết nhóm quyền bằng mã nhóm quyền
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRoleGroupDetailByCode(
      code: string,
      options?: any,
    ): AxiosPromise<ResponseBaseRoleGroupDetailResponse> {
      return localVarFp
        .getRoleGroupDetailByCode(code, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xem chi tiết nhóm quyền admin bằng mã nhóm quyền
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRoleGroupDetailByCodeAdmin(
      code: string,
      options?: any,
    ): AxiosPromise<ResponseBaseRoleGroupDetailResponse> {
      return localVarFp
        .getRoleGroupDetailByCodeAdmin(code, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Chỉnh sửa nhóm quyền
     * @param {number} groupId
     * @param {UpdateRoleGroupRequest} updateRoleGroupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateRoleGroup(
      groupId: number,
      updateRoleGroupRequest: UpdateRoleGroupRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseRoleGroupResponse> {
      return localVarFp
        .updateRoleGroup(groupId, updateRoleGroupRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Chỉnh sửa nhóm quyền admin
     * @param {number} groupId
     * @param {UpdateRoleGroupRequest} updateRoleGroupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateRoleGroupAdmin(
      groupId: number,
      updateRoleGroupRequest: UpdateRoleGroupRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseRoleGroupResponse> {
      return localVarFp
        .updateRoleGroupAdmin(groupId, updateRoleGroupRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createAdminRoleGroup operation in CMSRoleGroupControllerApi.
 * @export
 * @interface CMSRoleGroupControllerApiCreateAdminRoleGroupRequest
 */
export interface CMSRoleGroupControllerApiCreateAdminRoleGroupRequest {
  /**
   *
   * @type {CreateRoleGroupRequest}
   * @memberof CMSRoleGroupControllerApiCreateAdminRoleGroup
   */
  readonly createRoleGroupRequest: CreateRoleGroupRequest;
}

/**
 * Request parameters for createMerchantRoleGroup operation in CMSRoleGroupControllerApi.
 * @export
 * @interface CMSRoleGroupControllerApiCreateMerchantRoleGroupRequest
 */
export interface CMSRoleGroupControllerApiCreateMerchantRoleGroupRequest {
  /**
   *
   * @type {CreateRoleGroupRequest}
   * @memberof CMSRoleGroupControllerApiCreateMerchantRoleGroup
   */
  readonly createRoleGroupRequest: CreateRoleGroupRequest;
}

/**
 * Request parameters for deleteRoleGroup operation in CMSRoleGroupControllerApi.
 * @export
 * @interface CMSRoleGroupControllerApiDeleteRoleGroupRequest
 */
export interface CMSRoleGroupControllerApiDeleteRoleGroupRequest {
  /**
   *
   * @type {number}
   * @memberof CMSRoleGroupControllerApiDeleteRoleGroup
   */
  readonly groupId: number;
}

/**
 * Request parameters for deleteRoleGroupAdmin operation in CMSRoleGroupControllerApi.
 * @export
 * @interface CMSRoleGroupControllerApiDeleteRoleGroupAdminRequest
 */
export interface CMSRoleGroupControllerApiDeleteRoleGroupAdminRequest {
  /**
   *
   * @type {number}
   * @memberof CMSRoleGroupControllerApiDeleteRoleGroupAdmin
   */
  readonly groupId: number;
}

/**
 * Request parameters for getAllAdminRoleGroups operation in CMSRoleGroupControllerApi.
 * @export
 * @interface CMSRoleGroupControllerApiGetAllAdminRoleGroupsRequest
 */
export interface CMSRoleGroupControllerApiGetAllAdminRoleGroupsRequest {
  /**
   *
   * @type {string}
   * @memberof CMSRoleGroupControllerApiGetAllAdminRoleGroups
   */
  readonly code?: string;

  /**
   *
   * @type {string}
   * @memberof CMSRoleGroupControllerApiGetAllAdminRoleGroups
   */
  readonly name?: string;

  /**
   *
   * @type {'ACTIVE' | 'CLOSED'}
   * @memberof CMSRoleGroupControllerApiGetAllAdminRoleGroups
   */
  readonly status?: "ACTIVE" | "CLOSED";

  /**
   *
   * @type {number}
   * @memberof CMSRoleGroupControllerApiGetAllAdminRoleGroups
   */
  readonly page?: number;

  /**
   *
   * @type {number}
   * @memberof CMSRoleGroupControllerApiGetAllAdminRoleGroups
   */
  readonly pageSize?: number;

  /**
   *
   * @type {'ASC' | 'DESC'}
   * @memberof CMSRoleGroupControllerApiGetAllAdminRoleGroups
   */
  readonly sortDirection?: "ASC" | "DESC";

  /**
   *
   * @type {string}
   * @memberof CMSRoleGroupControllerApiGetAllAdminRoleGroups
   */
  readonly sortBy?: string;
}

/**
 * Request parameters for getAllMerchantRoleGroups operation in CMSRoleGroupControllerApi.
 * @export
 * @interface CMSRoleGroupControllerApiGetAllMerchantRoleGroupsRequest
 */
export interface CMSRoleGroupControllerApiGetAllMerchantRoleGroupsRequest {
  /**
   *
   * @type {string}
   * @memberof CMSRoleGroupControllerApiGetAllMerchantRoleGroups
   */
  readonly code?: string;

  /**
   *
   * @type {string}
   * @memberof CMSRoleGroupControllerApiGetAllMerchantRoleGroups
   */
  readonly name?: string;

  /**
   *
   * @type {'ACTIVE' | 'CLOSED'}
   * @memberof CMSRoleGroupControllerApiGetAllMerchantRoleGroups
   */
  readonly status?: "ACTIVE" | "CLOSED";

  /**
   *
   * @type {number}
   * @memberof CMSRoleGroupControllerApiGetAllMerchantRoleGroups
   */
  readonly page?: number;

  /**
   *
   * @type {number}
   * @memberof CMSRoleGroupControllerApiGetAllMerchantRoleGroups
   */
  readonly pageSize?: number;

  /**
   *
   * @type {'ASC' | 'DESC'}
   * @memberof CMSRoleGroupControllerApiGetAllMerchantRoleGroups
   */
  readonly sortDirection?: "ASC" | "DESC";

  /**
   *
   * @type {string}
   * @memberof CMSRoleGroupControllerApiGetAllMerchantRoleGroups
   */
  readonly sortBy?: string;
}

/**
 * Request parameters for getRoleGroupDetail operation in CMSRoleGroupControllerApi.
 * @export
 * @interface CMSRoleGroupControllerApiGetRoleGroupDetailRequest
 */
export interface CMSRoleGroupControllerApiGetRoleGroupDetailRequest {
  /**
   *
   * @type {number}
   * @memberof CMSRoleGroupControllerApiGetRoleGroupDetail
   */
  readonly groupId: number;
}

/**
 * Request parameters for getRoleGroupDetailAdmin operation in CMSRoleGroupControllerApi.
 * @export
 * @interface CMSRoleGroupControllerApiGetRoleGroupDetailAdminRequest
 */
export interface CMSRoleGroupControllerApiGetRoleGroupDetailAdminRequest {
  /**
   *
   * @type {number}
   * @memberof CMSRoleGroupControllerApiGetRoleGroupDetailAdmin
   */
  readonly groupId: number;
}

/**
 * Request parameters for getRoleGroupDetailByCode operation in CMSRoleGroupControllerApi.
 * @export
 * @interface CMSRoleGroupControllerApiGetRoleGroupDetailByCodeRequest
 */
export interface CMSRoleGroupControllerApiGetRoleGroupDetailByCodeRequest {
  /**
   *
   * @type {string}
   * @memberof CMSRoleGroupControllerApiGetRoleGroupDetailByCode
   */
  readonly code: string;
}

/**
 * Request parameters for getRoleGroupDetailByCodeAdmin operation in CMSRoleGroupControllerApi.
 * @export
 * @interface CMSRoleGroupControllerApiGetRoleGroupDetailByCodeAdminRequest
 */
export interface CMSRoleGroupControllerApiGetRoleGroupDetailByCodeAdminRequest {
  /**
   *
   * @type {string}
   * @memberof CMSRoleGroupControllerApiGetRoleGroupDetailByCodeAdmin
   */
  readonly code: string;
}

/**
 * Request parameters for updateRoleGroup operation in CMSRoleGroupControllerApi.
 * @export
 * @interface CMSRoleGroupControllerApiUpdateRoleGroupRequest
 */
export interface CMSRoleGroupControllerApiUpdateRoleGroupRequest {
  /**
   *
   * @type {number}
   * @memberof CMSRoleGroupControllerApiUpdateRoleGroup
   */
  readonly groupId: number;

  /**
   *
   * @type {UpdateRoleGroupRequest}
   * @memberof CMSRoleGroupControllerApiUpdateRoleGroup
   */
  readonly updateRoleGroupRequest: UpdateRoleGroupRequest;
}

/**
 * Request parameters for updateRoleGroupAdmin operation in CMSRoleGroupControllerApi.
 * @export
 * @interface CMSRoleGroupControllerApiUpdateRoleGroupAdminRequest
 */
export interface CMSRoleGroupControllerApiUpdateRoleGroupAdminRequest {
  /**
   *
   * @type {number}
   * @memberof CMSRoleGroupControllerApiUpdateRoleGroupAdmin
   */
  readonly groupId: number;

  /**
   *
   * @type {UpdateRoleGroupRequest}
   * @memberof CMSRoleGroupControllerApiUpdateRoleGroupAdmin
   */
  readonly updateRoleGroupRequest: UpdateRoleGroupRequest;
}

/**
 * CMSRoleGroupControllerApi - object-oriented interface
 * @export
 * @class CMSRoleGroupControllerApi
 * @extends {BaseAPI}
 */
export class CMSRoleGroupControllerApi extends BaseAPI {
  /**
   *
   * @summary Tạo mới nhóm quyền quản trị viên
   * @param {CMSRoleGroupControllerApiCreateAdminRoleGroupRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSRoleGroupControllerApi
   */
  public createAdminRoleGroup(
    requestParameters: CMSRoleGroupControllerApiCreateAdminRoleGroupRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSRoleGroupControllerApiFp(this.configuration)
      .createAdminRoleGroup(requestParameters.createRoleGroupRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Tạo mới nhóm quyền đối tác
   * @param {CMSRoleGroupControllerApiCreateMerchantRoleGroupRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSRoleGroupControllerApi
   */
  public createMerchantRoleGroup(
    requestParameters: CMSRoleGroupControllerApiCreateMerchantRoleGroupRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSRoleGroupControllerApiFp(this.configuration)
      .createMerchantRoleGroup(
        requestParameters.createRoleGroupRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xóa nhóm quyền
   * @param {CMSRoleGroupControllerApiDeleteRoleGroupRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSRoleGroupControllerApi
   */
  public deleteRoleGroup(
    requestParameters: CMSRoleGroupControllerApiDeleteRoleGroupRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSRoleGroupControllerApiFp(this.configuration)
      .deleteRoleGroup(requestParameters.groupId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xóa nhóm quyền
   * @param {CMSRoleGroupControllerApiDeleteRoleGroupAdminRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSRoleGroupControllerApi
   */
  public deleteRoleGroupAdmin(
    requestParameters: CMSRoleGroupControllerApiDeleteRoleGroupAdminRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSRoleGroupControllerApiFp(this.configuration)
      .deleteRoleGroupAdmin(requestParameters.groupId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xem danh sách nhóm permissions quản trị viên
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSRoleGroupControllerApi
   */
  public getAllAdminPermissions(options?: AxiosRequestConfig) {
    return CMSRoleGroupControllerApiFp(this.configuration)
      .getAllAdminPermissions(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xem danh sách nhóm quyền quản trị viên
   * @param {CMSRoleGroupControllerApiGetAllAdminRoleGroupsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSRoleGroupControllerApi
   */
  public getAllAdminRoleGroups(
    requestParameters: CMSRoleGroupControllerApiGetAllAdminRoleGroupsRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return CMSRoleGroupControllerApiFp(this.configuration)
      .getAllAdminRoleGroups(
        requestParameters.code,
        requestParameters.name,
        requestParameters.status,
        requestParameters.page,
        requestParameters.pageSize,
        requestParameters.sortDirection,
        requestParameters.sortBy,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xem danh sách nhóm permissions đối tác
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSRoleGroupControllerApi
   */
  public getAllMerchantPermissions(options?: AxiosRequestConfig) {
    return CMSRoleGroupControllerApiFp(this.configuration)
      .getAllMerchantPermissions(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xem danh sách nhóm quyền đối tác
   * @param {CMSRoleGroupControllerApiGetAllMerchantRoleGroupsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSRoleGroupControllerApi
   */
  public getAllMerchantRoleGroups(
    requestParameters: CMSRoleGroupControllerApiGetAllMerchantRoleGroupsRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return CMSRoleGroupControllerApiFp(this.configuration)
      .getAllMerchantRoleGroups(
        requestParameters.code,
        requestParameters.name,
        requestParameters.status,
        requestParameters.page,
        requestParameters.pageSize,
        requestParameters.sortDirection,
        requestParameters.sortBy,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xem chi tiết nhóm quyền
   * @param {CMSRoleGroupControllerApiGetRoleGroupDetailRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSRoleGroupControllerApi
   */
  public getRoleGroupDetail(
    requestParameters: CMSRoleGroupControllerApiGetRoleGroupDetailRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSRoleGroupControllerApiFp(this.configuration)
      .getRoleGroupDetail(requestParameters.groupId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xem chi tiết nhóm quyền admin
   * @param {CMSRoleGroupControllerApiGetRoleGroupDetailAdminRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSRoleGroupControllerApi
   */
  public getRoleGroupDetailAdmin(
    requestParameters: CMSRoleGroupControllerApiGetRoleGroupDetailAdminRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSRoleGroupControllerApiFp(this.configuration)
      .getRoleGroupDetailAdmin(requestParameters.groupId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xem chi tiết nhóm quyền bằng mã nhóm quyền
   * @param {CMSRoleGroupControllerApiGetRoleGroupDetailByCodeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSRoleGroupControllerApi
   */
  public getRoleGroupDetailByCode(
    requestParameters: CMSRoleGroupControllerApiGetRoleGroupDetailByCodeRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSRoleGroupControllerApiFp(this.configuration)
      .getRoleGroupDetailByCode(requestParameters.code, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xem chi tiết nhóm quyền admin bằng mã nhóm quyền
   * @param {CMSRoleGroupControllerApiGetRoleGroupDetailByCodeAdminRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSRoleGroupControllerApi
   */
  public getRoleGroupDetailByCodeAdmin(
    requestParameters: CMSRoleGroupControllerApiGetRoleGroupDetailByCodeAdminRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSRoleGroupControllerApiFp(this.configuration)
      .getRoleGroupDetailByCodeAdmin(requestParameters.code, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Chỉnh sửa nhóm quyền
   * @param {CMSRoleGroupControllerApiUpdateRoleGroupRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSRoleGroupControllerApi
   */
  public updateRoleGroup(
    requestParameters: CMSRoleGroupControllerApiUpdateRoleGroupRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSRoleGroupControllerApiFp(this.configuration)
      .updateRoleGroup(
        requestParameters.groupId,
        requestParameters.updateRoleGroupRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Chỉnh sửa nhóm quyền admin
   * @param {CMSRoleGroupControllerApiUpdateRoleGroupAdminRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSRoleGroupControllerApi
   */
  public updateRoleGroupAdmin(
    requestParameters: CMSRoleGroupControllerApiUpdateRoleGroupAdminRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSRoleGroupControllerApiFp(this.configuration)
      .updateRoleGroupAdmin(
        requestParameters.groupId,
        requestParameters.updateRoleGroupRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CMSSystemParameterControllerApi - axios parameter creator
 * @export
 */
export const CMSSystemParameterControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * - Tạo mới tham số hệ thống
     * @summary Tạo mới tham số hệ thống
     * @param {CreateSystemParamRequest} createSystemParamRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createParameter: async (
      createSystemParamRequest: CreateSystemParamRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createSystemParamRequest' is not null or undefined
      assertParamExists(
        "createParameter",
        "createSystemParamRequest",
        createSystemParamRequest,
      );
      const localVarPath = `/api/parameter/cms/v1/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createSystemParamRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Xóa tham số hệ thống
     * @summary Xóa tham số hệ thống
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteParameter: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("deleteParameter", "id", id);
      const localVarPath = `/api/parameter/cms/v1/delete/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Xem tất cả các tham số hệ thống
     * @summary Xem tất cả tham số hệ thống
     * @param {'PARAMETER_SYSTEM' | 'PARAMETER_CONNECTION' | 'PARAMETER_PAYBOX' | 'PARAMETER_CONTACT'} [group]
     * @param {'STRING' | 'NUMBER'} [dataType]
     * @param {string} [name]
     * @param {string} [value]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllParameters: async (
      group?:
        | "PARAMETER_SYSTEM"
        | "PARAMETER_CONNECTION"
        | "PARAMETER_PAYBOX"
        | "PARAMETER_CONTACT",
      dataType?: "STRING" | "NUMBER",
      name?: string,
      value?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/parameter/cms/v1/getAll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (group !== undefined) {
        localVarQueryParameter["group"] = group;
      }

      if (dataType !== undefined) {
        localVarQueryParameter["dataType"] = dataType;
      }

      if (name !== undefined) {
        localVarQueryParameter["name"] = name;
      }

      if (value !== undefined) {
        localVarQueryParameter["value"] = value;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter["sortDirection"] = sortDirection;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter["sortBy"] = sortBy;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Lấy tham số hệ thống theo tên
     * @summary Lấy tham số hệ thống theo tên
     * @param {string} name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getParameterByName: async (
      name: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'name' is not null or undefined
      assertParamExists("getParameterByName", "name", name);
      const localVarPath = `/api/parameter/cms/v1/getByName`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (name !== undefined) {
        localVarQueryParameter["name"] = name;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Đồng bộ user Notifo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    syncNotifoAccount: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/parameter/cms/v1/sync-notifo-account`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Chỉnh sửa tham số hệ thống
     * @summary Chỉnh sửa tham số hệ thống
     * @param {number} id
     * @param {UpdateSystemParamRequest} updateSystemParamRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateParameter: async (
      id: number,
      updateSystemParamRequest: UpdateSystemParamRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("updateParameter", "id", id);
      // verify required parameter 'updateSystemParamRequest' is not null or undefined
      assertParamExists(
        "updateParameter",
        "updateSystemParamRequest",
        updateSystemParamRequest,
      );
      const localVarPath = `/api/parameter/cms/v1/update/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateSystemParamRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CMSSystemParameterControllerApi - functional programming interface
 * @export
 */
export const CMSSystemParameterControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    CMSSystemParameterControllerApiAxiosParamCreator(configuration);
  return {
    /**
     * - Tạo mới tham số hệ thống
     * @summary Tạo mới tham số hệ thống
     * @param {CreateSystemParamRequest} createSystemParamRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createParameter(
      createSystemParamRequest: CreateSystemParamRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseSystemParamResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createParameter(
        createSystemParamRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Xóa tham số hệ thống
     * @summary Xóa tham số hệ thống
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteParameter(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseSystemParamResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteParameter(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Xem tất cả các tham số hệ thống
     * @summary Xem tất cả tham số hệ thống
     * @param {'PARAMETER_SYSTEM' | 'PARAMETER_CONNECTION' | 'PARAMETER_PAYBOX' | 'PARAMETER_CONTACT'} [group]
     * @param {'STRING' | 'NUMBER'} [dataType]
     * @param {string} [name]
     * @param {string} [value]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllParameters(
      group?:
        | "PARAMETER_SYSTEM"
        | "PARAMETER_CONNECTION"
        | "PARAMETER_PAYBOX"
        | "PARAMETER_CONTACT",
      dataType?: "STRING" | "NUMBER",
      name?: string,
      value?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseSystemParamResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllParameters(
          group,
          dataType,
          name,
          value,
          page,
          pageSize,
          sortDirection,
          sortBy,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Lấy tham số hệ thống theo tên
     * @summary Lấy tham số hệ thống theo tên
     * @param {string} name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getParameterByName(
      name: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseSystemParamResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getParameterByName(name, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Đồng bộ user Notifo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async syncNotifoAccount(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.syncNotifoAccount(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Chỉnh sửa tham số hệ thống
     * @summary Chỉnh sửa tham số hệ thống
     * @param {number} id
     * @param {UpdateSystemParamRequest} updateSystemParamRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateParameter(
      id: number,
      updateSystemParamRequest: UpdateSystemParamRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseSystemParamResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateParameter(
        id,
        updateSystemParamRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * CMSSystemParameterControllerApi - factory interface
 * @export
 */
export const CMSSystemParameterControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CMSSystemParameterControllerApiFp(configuration);
  return {
    /**
     * - Tạo mới tham số hệ thống
     * @summary Tạo mới tham số hệ thống
     * @param {CreateSystemParamRequest} createSystemParamRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createParameter(
      createSystemParamRequest: CreateSystemParamRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseSystemParamResponse> {
      return localVarFp
        .createParameter(createSystemParamRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Xóa tham số hệ thống
     * @summary Xóa tham số hệ thống
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteParameter(
      id: number,
      options?: any,
    ): AxiosPromise<ResponseBaseSystemParamResponse> {
      return localVarFp
        .deleteParameter(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Xem tất cả các tham số hệ thống
     * @summary Xem tất cả tham số hệ thống
     * @param {'PARAMETER_SYSTEM' | 'PARAMETER_CONNECTION' | 'PARAMETER_PAYBOX' | 'PARAMETER_CONTACT'} [group]
     * @param {'STRING' | 'NUMBER'} [dataType]
     * @param {string} [name]
     * @param {string} [value]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllParameters(
      group?:
        | "PARAMETER_SYSTEM"
        | "PARAMETER_CONNECTION"
        | "PARAMETER_PAYBOX"
        | "PARAMETER_CONTACT",
      dataType?: "STRING" | "NUMBER",
      name?: string,
      value?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseSystemParamResponse> {
      return localVarFp
        .getAllParameters(
          group,
          dataType,
          name,
          value,
          page,
          pageSize,
          sortDirection,
          sortBy,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * - Lấy tham số hệ thống theo tên
     * @summary Lấy tham số hệ thống theo tên
     * @param {string} name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getParameterByName(
      name: string,
      options?: any,
    ): AxiosPromise<ResponseBaseSystemParamResponse> {
      return localVarFp
        .getParameterByName(name, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Đồng bộ user Notifo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    syncNotifoAccount(options?: any): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .syncNotifoAccount(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Chỉnh sửa tham số hệ thống
     * @summary Chỉnh sửa tham số hệ thống
     * @param {number} id
     * @param {UpdateSystemParamRequest} updateSystemParamRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateParameter(
      id: number,
      updateSystemParamRequest: UpdateSystemParamRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseSystemParamResponse> {
      return localVarFp
        .updateParameter(id, updateSystemParamRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createParameter operation in CMSSystemParameterControllerApi.
 * @export
 * @interface CMSSystemParameterControllerApiCreateParameterRequest
 */
export interface CMSSystemParameterControllerApiCreateParameterRequest {
  /**
   *
   * @type {CreateSystemParamRequest}
   * @memberof CMSSystemParameterControllerApiCreateParameter
   */
  readonly createSystemParamRequest: CreateSystemParamRequest;
}

/**
 * Request parameters for deleteParameter operation in CMSSystemParameterControllerApi.
 * @export
 * @interface CMSSystemParameterControllerApiDeleteParameterRequest
 */
export interface CMSSystemParameterControllerApiDeleteParameterRequest {
  /**
   *
   * @type {number}
   * @memberof CMSSystemParameterControllerApiDeleteParameter
   */
  readonly id: number;
}

/**
 * Request parameters for getAllParameters operation in CMSSystemParameterControllerApi.
 * @export
 * @interface CMSSystemParameterControllerApiGetAllParametersRequest
 */
export interface CMSSystemParameterControllerApiGetAllParametersRequest {
  /**
   *
   * @type {'PARAMETER_SYSTEM' | 'PARAMETER_CONNECTION' | 'PARAMETER_PAYBOX' | 'PARAMETER_CONTACT'}
   * @memberof CMSSystemParameterControllerApiGetAllParameters
   */
  readonly group?:
    | "PARAMETER_SYSTEM"
    | "PARAMETER_CONNECTION"
    | "PARAMETER_PAYBOX"
    | "PARAMETER_CONTACT";

  /**
   *
   * @type {'STRING' | 'NUMBER'}
   * @memberof CMSSystemParameterControllerApiGetAllParameters
   */
  readonly dataType?: "STRING" | "NUMBER";

  /**
   *
   * @type {string}
   * @memberof CMSSystemParameterControllerApiGetAllParameters
   */
  readonly name?: string;

  /**
   *
   * @type {string}
   * @memberof CMSSystemParameterControllerApiGetAllParameters
   */
  readonly value?: string;

  /**
   *
   * @type {number}
   * @memberof CMSSystemParameterControllerApiGetAllParameters
   */
  readonly page?: number;

  /**
   *
   * @type {number}
   * @memberof CMSSystemParameterControllerApiGetAllParameters
   */
  readonly pageSize?: number;

  /**
   *
   * @type {'ASC' | 'DESC'}
   * @memberof CMSSystemParameterControllerApiGetAllParameters
   */
  readonly sortDirection?: "ASC" | "DESC";

  /**
   *
   * @type {string}
   * @memberof CMSSystemParameterControllerApiGetAllParameters
   */
  readonly sortBy?: string;
}

/**
 * Request parameters for getParameterByName operation in CMSSystemParameterControllerApi.
 * @export
 * @interface CMSSystemParameterControllerApiGetParameterByNameRequest
 */
export interface CMSSystemParameterControllerApiGetParameterByNameRequest {
  /**
   *
   * @type {string}
   * @memberof CMSSystemParameterControllerApiGetParameterByName
   */
  readonly name: string;
}

/**
 * Request parameters for updateParameter operation in CMSSystemParameterControllerApi.
 * @export
 * @interface CMSSystemParameterControllerApiUpdateParameterRequest
 */
export interface CMSSystemParameterControllerApiUpdateParameterRequest {
  /**
   *
   * @type {number}
   * @memberof CMSSystemParameterControllerApiUpdateParameter
   */
  readonly id: number;

  /**
   *
   * @type {UpdateSystemParamRequest}
   * @memberof CMSSystemParameterControllerApiUpdateParameter
   */
  readonly updateSystemParamRequest: UpdateSystemParamRequest;
}

/**
 * CMSSystemParameterControllerApi - object-oriented interface
 * @export
 * @class CMSSystemParameterControllerApi
 * @extends {BaseAPI}
 */
export class CMSSystemParameterControllerApi extends BaseAPI {
  /**
   * - Tạo mới tham số hệ thống
   * @summary Tạo mới tham số hệ thống
   * @param {CMSSystemParameterControllerApiCreateParameterRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSSystemParameterControllerApi
   */
  public createParameter(
    requestParameters: CMSSystemParameterControllerApiCreateParameterRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSSystemParameterControllerApiFp(this.configuration)
      .createParameter(requestParameters.createSystemParamRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Xóa tham số hệ thống
   * @summary Xóa tham số hệ thống
   * @param {CMSSystemParameterControllerApiDeleteParameterRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSSystemParameterControllerApi
   */
  public deleteParameter(
    requestParameters: CMSSystemParameterControllerApiDeleteParameterRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSSystemParameterControllerApiFp(this.configuration)
      .deleteParameter(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Xem tất cả các tham số hệ thống
   * @summary Xem tất cả tham số hệ thống
   * @param {CMSSystemParameterControllerApiGetAllParametersRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSSystemParameterControllerApi
   */
  public getAllParameters(
    requestParameters: CMSSystemParameterControllerApiGetAllParametersRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return CMSSystemParameterControllerApiFp(this.configuration)
      .getAllParameters(
        requestParameters.group,
        requestParameters.dataType,
        requestParameters.name,
        requestParameters.value,
        requestParameters.page,
        requestParameters.pageSize,
        requestParameters.sortDirection,
        requestParameters.sortBy,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Lấy tham số hệ thống theo tên
   * @summary Lấy tham số hệ thống theo tên
   * @param {CMSSystemParameterControllerApiGetParameterByNameRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSSystemParameterControllerApi
   */
  public getParameterByName(
    requestParameters: CMSSystemParameterControllerApiGetParameterByNameRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSSystemParameterControllerApiFp(this.configuration)
      .getParameterByName(requestParameters.name, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Đồng bộ user Notifo
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSSystemParameterControllerApi
   */
  public syncNotifoAccount(options?: AxiosRequestConfig) {
    return CMSSystemParameterControllerApiFp(this.configuration)
      .syncNotifoAccount(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Chỉnh sửa tham số hệ thống
   * @summary Chỉnh sửa tham số hệ thống
   * @param {CMSSystemParameterControllerApiUpdateParameterRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSSystemParameterControllerApi
   */
  public updateParameter(
    requestParameters: CMSSystemParameterControllerApiUpdateParameterRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSSystemParameterControllerApiFp(this.configuration)
      .updateParameter(
        requestParameters.id,
        requestParameters.updateSystemParamRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CMSTransactionControllerApi - axios parameter creator
 * @export
 */
export const CMSTransactionControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Excel export giao dịch từ UmeePay
     * @param {string} [keyword]
     * @param {'NONE' | 'CREATED' | 'SUCCESS' | 'CANCELED' | 'FAIL' | 'TIMEOUT' | 'PENDING'} [status]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {string} [transactionId]
     * @param {string} [refTransactionId]
     * @param {string} [merchantId]
     * @param {string} [merchantCode]
     * @param {boolean} [isStatistic]
     * @param {'PAY_LINK' | 'PAY_PAGE' | 'PAY_BUTTON' | 'PAY_BOX' | 'PAY_POS' | 'PAY_SUBSCRIPTION' | 'PAY_OUT' | 'STATIC_VIRTUAL_ACCOUNT'} [type]
     * @param {'VIET_QR' | 'ATM_CARD' | 'BANKING'} [paymentType]
     * @param {string} [bankAccountId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsExportToExcel: async (
      keyword?: string,
      status?:
        | "NONE"
        | "CREATED"
        | "SUCCESS"
        | "CANCELED"
        | "FAIL"
        | "TIMEOUT"
        | "PENDING",
      fromDate?: string,
      toDate?: string,
      transactionId?: string,
      refTransactionId?: string,
      merchantId?: string,
      merchantCode?: string,
      isStatistic?: boolean,
      type?:
        | "PAY_LINK"
        | "PAY_PAGE"
        | "PAY_BUTTON"
        | "PAY_BOX"
        | "PAY_POS"
        | "PAY_SUBSCRIPTION"
        | "PAY_OUT"
        | "STATIC_VIRTUAL_ACCOUNT",
      paymentType?: "VIET_QR" | "ATM_CARD" | "BANKING",
      bankAccountId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/transaction/cms/v1/export`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (keyword !== undefined) {
        localVarQueryParameter["keyword"] = keyword;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (fromDate !== undefined) {
        localVarQueryParameter["fromDate"] = fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter["toDate"] = toDate;
      }

      if (transactionId !== undefined) {
        localVarQueryParameter["transactionId"] = transactionId;
      }

      if (refTransactionId !== undefined) {
        localVarQueryParameter["refTransactionId"] = refTransactionId;
      }

      if (merchantId !== undefined) {
        localVarQueryParameter["merchantId"] = merchantId;
      }

      if (merchantCode !== undefined) {
        localVarQueryParameter["merchantCode"] = merchantCode;
      }

      if (isStatistic !== undefined) {
        localVarQueryParameter["isStatistic"] = isStatistic;
      }

      if (type !== undefined) {
        localVarQueryParameter["type"] = type;
      }

      if (paymentType !== undefined) {
        localVarQueryParameter["paymentType"] = paymentType;
      }

      if (bankAccountId !== undefined) {
        localVarQueryParameter["bankAccountId"] = bankAccountId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Excel export giao dịch sao kê từ ngân hàng
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {'DAY_7' | 'MONTH_1' | 'MONTH_2' | 'MONTH_4'} [period]
     * @param {string} [accountNo]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsExportToExcelFromBank: async (
      fromDate?: string,
      toDate?: string,
      period?: "DAY_7" | "MONTH_1" | "MONTH_2" | "MONTH_4",
      accountNo?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/transaction/cms/v1/bankTransaction/export`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (fromDate !== undefined) {
        localVarQueryParameter["fromDate"] =
          (fromDate as any) instanceof Date
            ? (fromDate as any).toISOString()
            : fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter["toDate"] =
          (toDate as any) instanceof Date
            ? (toDate as any).toISOString()
            : toDate;
      }

      if (period !== undefined) {
        localVarQueryParameter["period"] = period;
      }

      if (accountNo !== undefined) {
        localVarQueryParameter["accountNo"] = accountNo;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get toàn bộ transaction sao kê từ ngân hàng
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {string} [merchantId]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {'DAY_7' | 'MONTH_1' | 'MONTH_2' | 'MONTH_4'} [period]
     * @param {string} [accountNo]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsGetAllFromBank: async (
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      merchantId?: string,
      fromDate?: string,
      toDate?: string,
      period?: "DAY_7" | "MONTH_1" | "MONTH_2" | "MONTH_4",
      accountNo?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/transaction/cms/v1/bankTransaction/getAll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter["sortDirection"] = sortDirection;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter["sortBy"] = sortBy;
      }

      if (merchantId !== undefined) {
        localVarQueryParameter["merchantId"] = merchantId;
      }

      if (fromDate !== undefined) {
        localVarQueryParameter["fromDate"] =
          (fromDate as any) instanceof Date
            ? (fromDate as any).toISOString()
            : fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter["toDate"] =
          (toDate as any) instanceof Date
            ? (toDate as any).toISOString()
            : toDate;
      }

      if (period !== undefined) {
        localVarQueryParameter["period"] = period;
      }

      if (accountNo !== undefined) {
        localVarQueryParameter["accountNo"] = accountNo;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get toàn bộ transaction
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {string} [keyword]
     * @param {'NONE' | 'CREATED' | 'SUCCESS' | 'CANCELED' | 'FAIL' | 'TIMEOUT' | 'PENDING'} [status]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {string} [transactionId]
     * @param {string} [refTransactionId]
     * @param {string} [merchantId]
     * @param {string} [payboxDeviceId]
     * @param {'PAY_LINK' | 'PAY_PAGE' | 'PAY_BUTTON' | 'PAY_BOX' | 'PAY_POS' | 'PAY_SUBSCRIPTION' | 'PAY_OUT' | 'STATIC_VIRTUAL_ACCOUNT'} [type]
     * @param {'VIET_QR' | 'ATM_CARD' | 'BANKING'} [paymentType]
     * @param {string} [bankAccountId]
     * @param {string} [bankAccountNo]
     * @param {string} [merchantCode]
     * @param {'COBO' | 'POBO' | 'COBO_INTER_CARD' | 'COBO_DEBIT_DOMESTIC_CARD' | 'COBO_CREDIT_DOMESTIC_CARD' | 'POBO_INTER_BANK'} [serviceType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsGetAllTransactions: async (
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      keyword?: string,
      status?:
        | "NONE"
        | "CREATED"
        | "SUCCESS"
        | "CANCELED"
        | "FAIL"
        | "TIMEOUT"
        | "PENDING",
      fromDate?: string,
      toDate?: string,
      transactionId?: string,
      refTransactionId?: string,
      merchantId?: string,
      payboxDeviceId?: string,
      type?:
        | "PAY_LINK"
        | "PAY_PAGE"
        | "PAY_BUTTON"
        | "PAY_BOX"
        | "PAY_POS"
        | "PAY_SUBSCRIPTION"
        | "PAY_OUT"
        | "STATIC_VIRTUAL_ACCOUNT",
      paymentType?: "VIET_QR" | "ATM_CARD" | "BANKING",
      bankAccountId?: string,
      bankAccountNo?: string,
      merchantCode?: string,
      serviceType?:
        | "COBO"
        | "POBO"
        | "COBO_INTER_CARD"
        | "COBO_DEBIT_DOMESTIC_CARD"
        | "COBO_CREDIT_DOMESTIC_CARD"
        | "POBO_INTER_BANK",
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/transaction/cms/v1/getAll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter["sortDirection"] = sortDirection;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter["sortBy"] = sortBy;
      }

      if (keyword !== undefined) {
        localVarQueryParameter["keyword"] = keyword;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (fromDate !== undefined) {
        localVarQueryParameter["fromDate"] =
          (fromDate as any) instanceof Date
            ? (fromDate as any).toISOString()
            : fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter["toDate"] =
          (toDate as any) instanceof Date
            ? (toDate as any).toISOString()
            : toDate;
      }

      if (transactionId !== undefined) {
        localVarQueryParameter["transactionId"] = transactionId;
      }

      if (refTransactionId !== undefined) {
        localVarQueryParameter["refTransactionId"] = refTransactionId;
      }

      if (merchantId !== undefined) {
        localVarQueryParameter["merchantId"] = merchantId;
      }

      if (payboxDeviceId !== undefined) {
        localVarQueryParameter["payboxDeviceId"] = payboxDeviceId;
      }

      if (type !== undefined) {
        localVarQueryParameter["type"] = type;
      }

      if (paymentType !== undefined) {
        localVarQueryParameter["paymentType"] = paymentType;
      }

      if (bankAccountId !== undefined) {
        localVarQueryParameter["bankAccountId"] = bankAccountId;
      }

      if (bankAccountNo !== undefined) {
        localVarQueryParameter["bankAccountNo"] = bankAccountNo;
      }

      if (merchantCode !== undefined) {
        localVarQueryParameter["merchantCode"] = merchantCode;
      }

      if (serviceType !== undefined) {
        localVarQueryParameter["serviceType"] = serviceType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CMSTransactionControllerApi - functional programming interface
 * @export
 */
export const CMSTransactionControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    CMSTransactionControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Excel export giao dịch từ UmeePay
     * @param {string} [keyword]
     * @param {'NONE' | 'CREATED' | 'SUCCESS' | 'CANCELED' | 'FAIL' | 'TIMEOUT' | 'PENDING'} [status]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {string} [transactionId]
     * @param {string} [refTransactionId]
     * @param {string} [merchantId]
     * @param {string} [merchantCode]
     * @param {boolean} [isStatistic]
     * @param {'PAY_LINK' | 'PAY_PAGE' | 'PAY_BUTTON' | 'PAY_BOX' | 'PAY_POS' | 'PAY_SUBSCRIPTION' | 'PAY_OUT' | 'STATIC_VIRTUAL_ACCOUNT'} [type]
     * @param {'VIET_QR' | 'ATM_CARD' | 'BANKING'} [paymentType]
     * @param {string} [bankAccountId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cmsExportToExcel(
      keyword?: string,
      status?:
        | "NONE"
        | "CREATED"
        | "SUCCESS"
        | "CANCELED"
        | "FAIL"
        | "TIMEOUT"
        | "PENDING",
      fromDate?: string,
      toDate?: string,
      transactionId?: string,
      refTransactionId?: string,
      merchantId?: string,
      merchantCode?: string,
      isStatistic?: boolean,
      type?:
        | "PAY_LINK"
        | "PAY_PAGE"
        | "PAY_BUTTON"
        | "PAY_BOX"
        | "PAY_POS"
        | "PAY_SUBSCRIPTION"
        | "PAY_OUT"
        | "STATIC_VIRTUAL_ACCOUNT",
      paymentType?: "VIET_QR" | "ATM_CARD" | "BANKING",
      bankAccountId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cmsExportToExcel(
          keyword,
          status,
          fromDate,
          toDate,
          transactionId,
          refTransactionId,
          merchantId,
          merchantCode,
          isStatistic,
          type,
          paymentType,
          bankAccountId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Excel export giao dịch sao kê từ ngân hàng
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {'DAY_7' | 'MONTH_1' | 'MONTH_2' | 'MONTH_4'} [period]
     * @param {string} [accountNo]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cmsExportToExcelFromBank(
      fromDate?: string,
      toDate?: string,
      period?: "DAY_7" | "MONTH_1" | "MONTH_2" | "MONTH_4",
      accountNo?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cmsExportToExcelFromBank(
          fromDate,
          toDate,
          period,
          accountNo,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get toàn bộ transaction sao kê từ ngân hàng
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {string} [merchantId]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {'DAY_7' | 'MONTH_1' | 'MONTH_2' | 'MONTH_4'} [period]
     * @param {string} [accountNo]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cmsGetAllFromBank(
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      merchantId?: string,
      fromDate?: string,
      toDate?: string,
      period?: "DAY_7" | "MONTH_1" | "MONTH_2" | "MONTH_4",
      accountNo?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseTransactionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cmsGetAllFromBank(
          page,
          pageSize,
          sortDirection,
          sortBy,
          merchantId,
          fromDate,
          toDate,
          period,
          accountNo,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get toàn bộ transaction
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {string} [keyword]
     * @param {'NONE' | 'CREATED' | 'SUCCESS' | 'CANCELED' | 'FAIL' | 'TIMEOUT' | 'PENDING'} [status]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {string} [transactionId]
     * @param {string} [refTransactionId]
     * @param {string} [merchantId]
     * @param {string} [payboxDeviceId]
     * @param {'PAY_LINK' | 'PAY_PAGE' | 'PAY_BUTTON' | 'PAY_BOX' | 'PAY_POS' | 'PAY_SUBSCRIPTION' | 'PAY_OUT' | 'STATIC_VIRTUAL_ACCOUNT'} [type]
     * @param {'VIET_QR' | 'ATM_CARD' | 'BANKING'} [paymentType]
     * @param {string} [bankAccountId]
     * @param {string} [bankAccountNo]
     * @param {string} [merchantCode]
     * @param {'COBO' | 'POBO' | 'COBO_INTER_CARD' | 'COBO_DEBIT_DOMESTIC_CARD' | 'COBO_CREDIT_DOMESTIC_CARD' | 'POBO_INTER_BANK'} [serviceType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cmsGetAllTransactions(
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      keyword?: string,
      status?:
        | "NONE"
        | "CREATED"
        | "SUCCESS"
        | "CANCELED"
        | "FAIL"
        | "TIMEOUT"
        | "PENDING",
      fromDate?: string,
      toDate?: string,
      transactionId?: string,
      refTransactionId?: string,
      merchantId?: string,
      payboxDeviceId?: string,
      type?:
        | "PAY_LINK"
        | "PAY_PAGE"
        | "PAY_BUTTON"
        | "PAY_BOX"
        | "PAY_POS"
        | "PAY_SUBSCRIPTION"
        | "PAY_OUT"
        | "STATIC_VIRTUAL_ACCOUNT",
      paymentType?: "VIET_QR" | "ATM_CARD" | "BANKING",
      bankAccountId?: string,
      bankAccountNo?: string,
      merchantCode?: string,
      serviceType?:
        | "COBO"
        | "POBO"
        | "COBO_INTER_CARD"
        | "COBO_DEBIT_DOMESTIC_CARD"
        | "COBO_CREDIT_DOMESTIC_CARD"
        | "POBO_INTER_BANK",
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseTransactionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cmsGetAllTransactions(
          page,
          pageSize,
          sortDirection,
          sortBy,
          keyword,
          status,
          fromDate,
          toDate,
          transactionId,
          refTransactionId,
          merchantId,
          payboxDeviceId,
          type,
          paymentType,
          bankAccountId,
          bankAccountNo,
          merchantCode,
          serviceType,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * CMSTransactionControllerApi - factory interface
 * @export
 */
export const CMSTransactionControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CMSTransactionControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Excel export giao dịch từ UmeePay
     * @param {string} [keyword]
     * @param {'NONE' | 'CREATED' | 'SUCCESS' | 'CANCELED' | 'FAIL' | 'TIMEOUT' | 'PENDING'} [status]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {string} [transactionId]
     * @param {string} [refTransactionId]
     * @param {string} [merchantId]
     * @param {string} [merchantCode]
     * @param {boolean} [isStatistic]
     * @param {'PAY_LINK' | 'PAY_PAGE' | 'PAY_BUTTON' | 'PAY_BOX' | 'PAY_POS' | 'PAY_SUBSCRIPTION' | 'PAY_OUT' | 'STATIC_VIRTUAL_ACCOUNT'} [type]
     * @param {'VIET_QR' | 'ATM_CARD' | 'BANKING'} [paymentType]
     * @param {string} [bankAccountId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsExportToExcel(
      keyword?: string,
      status?:
        | "NONE"
        | "CREATED"
        | "SUCCESS"
        | "CANCELED"
        | "FAIL"
        | "TIMEOUT"
        | "PENDING",
      fromDate?: string,
      toDate?: string,
      transactionId?: string,
      refTransactionId?: string,
      merchantId?: string,
      merchantCode?: string,
      isStatistic?: boolean,
      type?:
        | "PAY_LINK"
        | "PAY_PAGE"
        | "PAY_BUTTON"
        | "PAY_BOX"
        | "PAY_POS"
        | "PAY_SUBSCRIPTION"
        | "PAY_OUT"
        | "STATIC_VIRTUAL_ACCOUNT",
      paymentType?: "VIET_QR" | "ATM_CARD" | "BANKING",
      bankAccountId?: string,
      options?: any,
    ): AxiosPromise<File> {
      return localVarFp
        .cmsExportToExcel(
          keyword,
          status,
          fromDate,
          toDate,
          transactionId,
          refTransactionId,
          merchantId,
          merchantCode,
          isStatistic,
          type,
          paymentType,
          bankAccountId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Excel export giao dịch sao kê từ ngân hàng
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {'DAY_7' | 'MONTH_1' | 'MONTH_2' | 'MONTH_4'} [period]
     * @param {string} [accountNo]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsExportToExcelFromBank(
      fromDate?: string,
      toDate?: string,
      period?: "DAY_7" | "MONTH_1" | "MONTH_2" | "MONTH_4",
      accountNo?: string,
      options?: any,
    ): AxiosPromise<File> {
      return localVarFp
        .cmsExportToExcelFromBank(fromDate, toDate, period, accountNo, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get toàn bộ transaction sao kê từ ngân hàng
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {string} [merchantId]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {'DAY_7' | 'MONTH_1' | 'MONTH_2' | 'MONTH_4'} [period]
     * @param {string} [accountNo]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsGetAllFromBank(
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      merchantId?: string,
      fromDate?: string,
      toDate?: string,
      period?: "DAY_7" | "MONTH_1" | "MONTH_2" | "MONTH_4",
      accountNo?: string,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseTransactionResponse> {
      return localVarFp
        .cmsGetAllFromBank(
          page,
          pageSize,
          sortDirection,
          sortBy,
          merchantId,
          fromDate,
          toDate,
          period,
          accountNo,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get toàn bộ transaction
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {string} [keyword]
     * @param {'NONE' | 'CREATED' | 'SUCCESS' | 'CANCELED' | 'FAIL' | 'TIMEOUT' | 'PENDING'} [status]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {string} [transactionId]
     * @param {string} [refTransactionId]
     * @param {string} [merchantId]
     * @param {string} [payboxDeviceId]
     * @param {'PAY_LINK' | 'PAY_PAGE' | 'PAY_BUTTON' | 'PAY_BOX' | 'PAY_POS' | 'PAY_SUBSCRIPTION' | 'PAY_OUT' | 'STATIC_VIRTUAL_ACCOUNT'} [type]
     * @param {'VIET_QR' | 'ATM_CARD' | 'BANKING'} [paymentType]
     * @param {string} [bankAccountId]
     * @param {string} [bankAccountNo]
     * @param {string} [merchantCode]
     * @param {'COBO' | 'POBO' | 'COBO_INTER_CARD' | 'COBO_DEBIT_DOMESTIC_CARD' | 'COBO_CREDIT_DOMESTIC_CARD' | 'POBO_INTER_BANK'} [serviceType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsGetAllTransactions(
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      keyword?: string,
      status?:
        | "NONE"
        | "CREATED"
        | "SUCCESS"
        | "CANCELED"
        | "FAIL"
        | "TIMEOUT"
        | "PENDING",
      fromDate?: string,
      toDate?: string,
      transactionId?: string,
      refTransactionId?: string,
      merchantId?: string,
      payboxDeviceId?: string,
      type?:
        | "PAY_LINK"
        | "PAY_PAGE"
        | "PAY_BUTTON"
        | "PAY_BOX"
        | "PAY_POS"
        | "PAY_SUBSCRIPTION"
        | "PAY_OUT"
        | "STATIC_VIRTUAL_ACCOUNT",
      paymentType?: "VIET_QR" | "ATM_CARD" | "BANKING",
      bankAccountId?: string,
      bankAccountNo?: string,
      merchantCode?: string,
      serviceType?:
        | "COBO"
        | "POBO"
        | "COBO_INTER_CARD"
        | "COBO_DEBIT_DOMESTIC_CARD"
        | "COBO_CREDIT_DOMESTIC_CARD"
        | "POBO_INTER_BANK",
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseTransactionResponse> {
      return localVarFp
        .cmsGetAllTransactions(
          page,
          pageSize,
          sortDirection,
          sortBy,
          keyword,
          status,
          fromDate,
          toDate,
          transactionId,
          refTransactionId,
          merchantId,
          payboxDeviceId,
          type,
          paymentType,
          bankAccountId,
          bankAccountNo,
          merchantCode,
          serviceType,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for cmsExportToExcel operation in CMSTransactionControllerApi.
 * @export
 * @interface CMSTransactionControllerApiCmsExportToExcelRequest
 */
export interface CMSTransactionControllerApiCmsExportToExcelRequest {
  /**
   *
   * @type {string}
   * @memberof CMSTransactionControllerApiCmsExportToExcel
   */
  readonly keyword?: string;

  /**
   *
   * @type {'NONE' | 'CREATED' | 'SUCCESS' | 'CANCELED' | 'FAIL' | 'TIMEOUT' | 'PENDING'}
   * @memberof CMSTransactionControllerApiCmsExportToExcel
   */
  readonly status?:
    | "NONE"
    | "CREATED"
    | "SUCCESS"
    | "CANCELED"
    | "FAIL"
    | "TIMEOUT"
    | "PENDING";

  /**
   *
   * @type {string}
   * @memberof CMSTransactionControllerApiCmsExportToExcel
   */
  readonly fromDate?: string;

  /**
   *
   * @type {string}
   * @memberof CMSTransactionControllerApiCmsExportToExcel
   */
  readonly toDate?: string;

  /**
   *
   * @type {string}
   * @memberof CMSTransactionControllerApiCmsExportToExcel
   */
  readonly transactionId?: string;

  /**
   *
   * @type {string}
   * @memberof CMSTransactionControllerApiCmsExportToExcel
   */
  readonly refTransactionId?: string;

  /**
   *
   * @type {string}
   * @memberof CMSTransactionControllerApiCmsExportToExcel
   */
  readonly merchantId?: string;

  /**
   *
   * @type {string}
   * @memberof CMSTransactionControllerApiCmsExportToExcel
   */
  readonly merchantCode?: string;

  /**
   *
   * @type {boolean}
   * @memberof CMSTransactionControllerApiCmsExportToExcel
   */
  readonly isStatistic?: boolean;

  /**
   *
   * @type {'PAY_LINK' | 'PAY_PAGE' | 'PAY_BUTTON' | 'PAY_BOX' | 'PAY_POS' | 'PAY_SUBSCRIPTION' | 'PAY_OUT' | 'STATIC_VIRTUAL_ACCOUNT'}
   * @memberof CMSTransactionControllerApiCmsExportToExcel
   */
  readonly type?:
    | "PAY_LINK"
    | "PAY_PAGE"
    | "PAY_BUTTON"
    | "PAY_BOX"
    | "PAY_POS"
    | "PAY_SUBSCRIPTION"
    | "PAY_OUT"
    | "STATIC_VIRTUAL_ACCOUNT";

  /**
   *
   * @type {'VIET_QR' | 'ATM_CARD' | 'BANKING'}
   * @memberof CMSTransactionControllerApiCmsExportToExcel
   */
  readonly paymentType?: "VIET_QR" | "ATM_CARD" | "BANKING";

  /**
   *
   * @type {string}
   * @memberof CMSTransactionControllerApiCmsExportToExcel
   */
  readonly bankAccountId?: string;
}

/**
 * Request parameters for cmsExportToExcelFromBank operation in CMSTransactionControllerApi.
 * @export
 * @interface CMSTransactionControllerApiCmsExportToExcelFromBankRequest
 */
export interface CMSTransactionControllerApiCmsExportToExcelFromBankRequest {
  /**
   *
   * @type {string}
   * @memberof CMSTransactionControllerApiCmsExportToExcelFromBank
   */
  readonly fromDate?: string;

  /**
   *
   * @type {string}
   * @memberof CMSTransactionControllerApiCmsExportToExcelFromBank
   */
  readonly toDate?: string;

  /**
   *
   * @type {'DAY_7' | 'MONTH_1' | 'MONTH_2' | 'MONTH_4'}
   * @memberof CMSTransactionControllerApiCmsExportToExcelFromBank
   */
  readonly period?: "DAY_7" | "MONTH_1" | "MONTH_2" | "MONTH_4";

  /**
   *
   * @type {string}
   * @memberof CMSTransactionControllerApiCmsExportToExcelFromBank
   */
  readonly accountNo?: string;
}

/**
 * Request parameters for cmsGetAllFromBank operation in CMSTransactionControllerApi.
 * @export
 * @interface CMSTransactionControllerApiCmsGetAllFromBankRequest
 */
export interface CMSTransactionControllerApiCmsGetAllFromBankRequest {
  /**
   *
   * @type {number}
   * @memberof CMSTransactionControllerApiCmsGetAllFromBank
   */
  readonly page?: number;

  /**
   *
   * @type {number}
   * @memberof CMSTransactionControllerApiCmsGetAllFromBank
   */
  readonly pageSize?: number;

  /**
   *
   * @type {'ASC' | 'DESC'}
   * @memberof CMSTransactionControllerApiCmsGetAllFromBank
   */
  readonly sortDirection?: "ASC" | "DESC";

  /**
   *
   * @type {string}
   * @memberof CMSTransactionControllerApiCmsGetAllFromBank
   */
  readonly sortBy?: string;

  /**
   *
   * @type {string}
   * @memberof CMSTransactionControllerApiCmsGetAllFromBank
   */
  readonly merchantId?: string;

  /**
   *
   * @type {string}
   * @memberof CMSTransactionControllerApiCmsGetAllFromBank
   */
  readonly fromDate?: string;

  /**
   *
   * @type {string}
   * @memberof CMSTransactionControllerApiCmsGetAllFromBank
   */
  readonly toDate?: string;

  /**
   *
   * @type {'DAY_7' | 'MONTH_1' | 'MONTH_2' | 'MONTH_4'}
   * @memberof CMSTransactionControllerApiCmsGetAllFromBank
   */
  readonly period?: "DAY_7" | "MONTH_1" | "MONTH_2" | "MONTH_4";

  /**
   *
   * @type {string}
   * @memberof CMSTransactionControllerApiCmsGetAllFromBank
   */
  readonly accountNo?: string;
}

/**
 * Request parameters for cmsGetAllTransactions operation in CMSTransactionControllerApi.
 * @export
 * @interface CMSTransactionControllerApiCmsGetAllTransactionsRequest
 */
export interface CMSTransactionControllerApiCmsGetAllTransactionsRequest {
  /**
   *
   * @type {number}
   * @memberof CMSTransactionControllerApiCmsGetAllTransactions
   */
  readonly page?: number;

  /**
   *
   * @type {number}
   * @memberof CMSTransactionControllerApiCmsGetAllTransactions
   */
  readonly pageSize?: number;

  /**
   *
   * @type {'ASC' | 'DESC'}
   * @memberof CMSTransactionControllerApiCmsGetAllTransactions
   */
  readonly sortDirection?: "ASC" | "DESC";

  /**
   *
   * @type {string}
   * @memberof CMSTransactionControllerApiCmsGetAllTransactions
   */
  readonly sortBy?: string;

  /**
   *
   * @type {string}
   * @memberof CMSTransactionControllerApiCmsGetAllTransactions
   */
  readonly keyword?: string;

  /**
   *
   * @type {'NONE' | 'CREATED' | 'SUCCESS' | 'CANCELED' | 'FAIL' | 'TIMEOUT' | 'PENDING'}
   * @memberof CMSTransactionControllerApiCmsGetAllTransactions
   */
  readonly status?:
    | "NONE"
    | "CREATED"
    | "SUCCESS"
    | "CANCELED"
    | "FAIL"
    | "TIMEOUT"
    | "PENDING";

  /**
   *
   * @type {string}
   * @memberof CMSTransactionControllerApiCmsGetAllTransactions
   */
  readonly fromDate?: string;

  /**
   *
   * @type {string}
   * @memberof CMSTransactionControllerApiCmsGetAllTransactions
   */
  readonly toDate?: string;

  /**
   *
   * @type {string}
   * @memberof CMSTransactionControllerApiCmsGetAllTransactions
   */
  readonly transactionId?: string;

  /**
   *
   * @type {string}
   * @memberof CMSTransactionControllerApiCmsGetAllTransactions
   */
  readonly refTransactionId?: string;

  /**
   *
   * @type {string}
   * @memberof CMSTransactionControllerApiCmsGetAllTransactions
   */
  readonly merchantId?: string;

  /**
   *
   * @type {string}
   * @memberof CMSTransactionControllerApiCmsGetAllTransactions
   */
  readonly payboxDeviceId?: string;

  /**
   *
   * @type {'PAY_LINK' | 'PAY_PAGE' | 'PAY_BUTTON' | 'PAY_BOX' | 'PAY_POS' | 'PAY_SUBSCRIPTION' | 'PAY_OUT' | 'STATIC_VIRTUAL_ACCOUNT'}
   * @memberof CMSTransactionControllerApiCmsGetAllTransactions
   */
  readonly type?:
    | "PAY_LINK"
    | "PAY_PAGE"
    | "PAY_BUTTON"
    | "PAY_BOX"
    | "PAY_POS"
    | "PAY_SUBSCRIPTION"
    | "PAY_OUT"
    | "STATIC_VIRTUAL_ACCOUNT";

  /**
   *
   * @type {'VIET_QR' | 'ATM_CARD' | 'BANKING'}
   * @memberof CMSTransactionControllerApiCmsGetAllTransactions
   */
  readonly paymentType?: "VIET_QR" | "ATM_CARD" | "BANKING";

  /**
   *
   * @type {string}
   * @memberof CMSTransactionControllerApiCmsGetAllTransactions
   */
  readonly bankAccountId?: string;

  /**
   *
   * @type {string}
   * @memberof CMSTransactionControllerApiCmsGetAllTransactions
   */
  readonly bankAccountNo?: string;

  /**
   *
   * @type {string}
   * @memberof CMSTransactionControllerApiCmsGetAllTransactions
   */
  readonly merchantCode?: string;

  /**
   *
   * @type {'COBO' | 'POBO' | 'COBO_INTER_CARD' | 'COBO_DEBIT_DOMESTIC_CARD' | 'COBO_CREDIT_DOMESTIC_CARD' | 'POBO_INTER_BANK'}
   * @memberof CMSTransactionControllerApiCmsGetAllTransactions
   */
  readonly serviceType?:
    | "COBO"
    | "POBO"
    | "COBO_INTER_CARD"
    | "COBO_DEBIT_DOMESTIC_CARD"
    | "COBO_CREDIT_DOMESTIC_CARD"
    | "POBO_INTER_BANK";
}

/**
 * CMSTransactionControllerApi - object-oriented interface
 * @export
 * @class CMSTransactionControllerApi
 * @extends {BaseAPI}
 */
export class CMSTransactionControllerApi extends BaseAPI {
  /**
   *
   * @summary Excel export giao dịch từ UmeePay
   * @param {CMSTransactionControllerApiCmsExportToExcelRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSTransactionControllerApi
   */
  public cmsExportToExcel(
    requestParameters: CMSTransactionControllerApiCmsExportToExcelRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return CMSTransactionControllerApiFp(this.configuration)
      .cmsExportToExcel(
        requestParameters.keyword,
        requestParameters.status,
        requestParameters.fromDate,
        requestParameters.toDate,
        requestParameters.transactionId,
        requestParameters.refTransactionId,
        requestParameters.merchantId,
        requestParameters.merchantCode,
        requestParameters.isStatistic,
        requestParameters.type,
        requestParameters.paymentType,
        requestParameters.bankAccountId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Excel export giao dịch sao kê từ ngân hàng
   * @param {CMSTransactionControllerApiCmsExportToExcelFromBankRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSTransactionControllerApi
   */
  public cmsExportToExcelFromBank(
    requestParameters: CMSTransactionControllerApiCmsExportToExcelFromBankRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return CMSTransactionControllerApiFp(this.configuration)
      .cmsExportToExcelFromBank(
        requestParameters.fromDate,
        requestParameters.toDate,
        requestParameters.period,
        requestParameters.accountNo,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get toàn bộ transaction sao kê từ ngân hàng
   * @param {CMSTransactionControllerApiCmsGetAllFromBankRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSTransactionControllerApi
   */
  public cmsGetAllFromBank(
    requestParameters: CMSTransactionControllerApiCmsGetAllFromBankRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return CMSTransactionControllerApiFp(this.configuration)
      .cmsGetAllFromBank(
        requestParameters.page,
        requestParameters.pageSize,
        requestParameters.sortDirection,
        requestParameters.sortBy,
        requestParameters.merchantId,
        requestParameters.fromDate,
        requestParameters.toDate,
        requestParameters.period,
        requestParameters.accountNo,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get toàn bộ transaction
   * @param {CMSTransactionControllerApiCmsGetAllTransactionsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSTransactionControllerApi
   */
  public cmsGetAllTransactions(
    requestParameters: CMSTransactionControllerApiCmsGetAllTransactionsRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return CMSTransactionControllerApiFp(this.configuration)
      .cmsGetAllTransactions(
        requestParameters.page,
        requestParameters.pageSize,
        requestParameters.sortDirection,
        requestParameters.sortBy,
        requestParameters.keyword,
        requestParameters.status,
        requestParameters.fromDate,
        requestParameters.toDate,
        requestParameters.transactionId,
        requestParameters.refTransactionId,
        requestParameters.merchantId,
        requestParameters.payboxDeviceId,
        requestParameters.type,
        requestParameters.paymentType,
        requestParameters.bankAccountId,
        requestParameters.bankAccountNo,
        requestParameters.merchantCode,
        requestParameters.serviceType,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CMSVietQRProApi - axios parameter creator
 * @export
 */
export const CMSVietQRProApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary [CMS] Lấy danh sách Merchant
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("get", "id", id);
      const localVarPath = `/api/vietQrPro/cms/v1/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [CMS] Lấy danh sách Merchant
     * @param {string} [code]
     * @param {string} [name]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {'INACTIVE' | 'PENDING' | 'APPROVED' | 'LOCK' | 'REJECTED'} [status]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAll: async (
      code?: string,
      name?: string,
      fromDate?: string,
      toDate?: string,
      status?: "INACTIVE" | "PENDING" | "APPROVED" | "LOCK" | "REJECTED",
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/vietQrPro/cms/v1/getAll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (code !== undefined) {
        localVarQueryParameter["code"] = code;
      }

      if (name !== undefined) {
        localVarQueryParameter["name"] = name;
      }

      if (fromDate !== undefined) {
        localVarQueryParameter["fromDate"] =
          (fromDate as any) instanceof Date
            ? (fromDate as any).toISOString()
            : fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter["toDate"] =
          (toDate as any) instanceof Date
            ? (toDate as any).toISOString()
            : toDate;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [CMS] Lấy danh sách thay đổi
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllHistoryChanges: async (
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/vietQrPro/cms/v1/historyChanges`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [CMS] Lấy danh sách khách hàng của merchant
     * @param {string} id
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomers: async (
      id: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getCustomers", "id", id);
      const localVarPath = `/api/vietQrPro/cms/v1/{id}/customers`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [CMS] Đổi trạng thái VietQr Pro
     * @param {string} id
     * @param {CmsVietQrProNextStateRequest} cmsVietQrProNextStateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nextState: async (
      id: string,
      cmsVietQrProNextStateRequest: CmsVietQrProNextStateRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("nextState", "id", id);
      // verify required parameter 'cmsVietQrProNextStateRequest' is not null or undefined
      assertParamExists(
        "nextState",
        "cmsVietQrProNextStateRequest",
        cmsVietQrProNextStateRequest,
      );
      const localVarPath = `/api/vietQrPro/cms/v1/{id}/state`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        cmsVietQrProNextStateRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CMSVietQRProApi - functional programming interface
 * @export
 */
export const CMSVietQRProApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    CMSVietQRProApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary [CMS] Lấy danh sách Merchant
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async get(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseCmsVietQrProResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.get(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary [CMS] Lấy danh sách Merchant
     * @param {string} [code]
     * @param {string} [name]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {'INACTIVE' | 'PENDING' | 'APPROVED' | 'LOCK' | 'REJECTED'} [status]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAll(
      code?: string,
      name?: string,
      fromDate?: string,
      toDate?: string,
      status?: "INACTIVE" | "PENDING" | "APPROVED" | "LOCK" | "REJECTED",
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseCmsVietQrProResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(
        code,
        name,
        fromDate,
        toDate,
        status,
        page,
        size,
        sort,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary [CMS] Lấy danh sách thay đổi
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllHistoryChanges(
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseVietQrProHistoryChangeResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllHistoryChanges(
          page,
          size,
          sort,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary [CMS] Lấy danh sách khách hàng của merchant
     * @param {string} id
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCustomers(
      id: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseVietQrProCustomerResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomers(
        id,
        page,
        size,
        sort,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary [CMS] Đổi trạng thái VietQr Pro
     * @param {string} id
     * @param {CmsVietQrProNextStateRequest} cmsVietQrProNextStateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async nextState(
      id: string,
      cmsVietQrProNextStateRequest: CmsVietQrProNextStateRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.nextState(
        id,
        cmsVietQrProNextStateRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * CMSVietQRProApi - factory interface
 * @export
 */
export const CMSVietQRProApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CMSVietQRProApiFp(configuration);
  return {
    /**
     *
     * @summary [CMS] Lấy danh sách Merchant
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get(
      id: string,
      options?: any,
    ): AxiosPromise<ResponseBaseCmsVietQrProResponse> {
      return localVarFp
        .get(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [CMS] Lấy danh sách Merchant
     * @param {string} [code]
     * @param {string} [name]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {'INACTIVE' | 'PENDING' | 'APPROVED' | 'LOCK' | 'REJECTED'} [status]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAll(
      code?: string,
      name?: string,
      fromDate?: string,
      toDate?: string,
      status?: "INACTIVE" | "PENDING" | "APPROVED" | "LOCK" | "REJECTED",
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseCmsVietQrProResponse> {
      return localVarFp
        .getAll(code, name, fromDate, toDate, status, page, size, sort, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [CMS] Lấy danh sách thay đổi
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllHistoryChanges(
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseVietQrProHistoryChangeResponse> {
      return localVarFp
        .getAllHistoryChanges(page, size, sort, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [CMS] Lấy danh sách khách hàng của merchant
     * @param {string} id
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomers(
      id: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseVietQrProCustomerResponse> {
      return localVarFp
        .getCustomers(id, page, size, sort, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [CMS] Đổi trạng thái VietQr Pro
     * @param {string} id
     * @param {CmsVietQrProNextStateRequest} cmsVietQrProNextStateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nextState(
      id: string,
      cmsVietQrProNextStateRequest: CmsVietQrProNextStateRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .nextState(id, cmsVietQrProNextStateRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for get operation in CMSVietQRProApi.
 * @export
 * @interface CMSVietQRProApiGetRequest
 */
export interface CMSVietQRProApiGetRequest {
  /**
   *
   * @type {string}
   * @memberof CMSVietQRProApiGet
   */
  readonly id: string;
}

/**
 * Request parameters for getAll operation in CMSVietQRProApi.
 * @export
 * @interface CMSVietQRProApiGetAllRequest
 */
export interface CMSVietQRProApiGetAllRequest {
  /**
   *
   * @type {string}
   * @memberof CMSVietQRProApiGetAll
   */
  readonly code?: string;

  /**
   *
   * @type {string}
   * @memberof CMSVietQRProApiGetAll
   */
  readonly name?: string;

  /**
   *
   * @type {string}
   * @memberof CMSVietQRProApiGetAll
   */
  readonly fromDate?: string;

  /**
   *
   * @type {string}
   * @memberof CMSVietQRProApiGetAll
   */
  readonly toDate?: string;

  /**
   *
   * @type {'INACTIVE' | 'PENDING' | 'APPROVED' | 'LOCK' | 'REJECTED'}
   * @memberof CMSVietQRProApiGetAll
   */
  readonly status?: "INACTIVE" | "PENDING" | "APPROVED" | "LOCK" | "REJECTED";

  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof CMSVietQRProApiGetAll
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof CMSVietQRProApiGetAll
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof CMSVietQRProApiGetAll
   */
  readonly sort?: Array<string>;
}

/**
 * Request parameters for getAllHistoryChanges operation in CMSVietQRProApi.
 * @export
 * @interface CMSVietQRProApiGetAllHistoryChangesRequest
 */
export interface CMSVietQRProApiGetAllHistoryChangesRequest {
  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof CMSVietQRProApiGetAllHistoryChanges
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof CMSVietQRProApiGetAllHistoryChanges
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof CMSVietQRProApiGetAllHistoryChanges
   */
  readonly sort?: Array<string>;
}

/**
 * Request parameters for getCustomers operation in CMSVietQRProApi.
 * @export
 * @interface CMSVietQRProApiGetCustomersRequest
 */
export interface CMSVietQRProApiGetCustomersRequest {
  /**
   *
   * @type {string}
   * @memberof CMSVietQRProApiGetCustomers
   */
  readonly id: string;

  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof CMSVietQRProApiGetCustomers
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof CMSVietQRProApiGetCustomers
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof CMSVietQRProApiGetCustomers
   */
  readonly sort?: Array<string>;
}

/**
 * Request parameters for nextState operation in CMSVietQRProApi.
 * @export
 * @interface CMSVietQRProApiNextStateRequest
 */
export interface CMSVietQRProApiNextStateRequest {
  /**
   *
   * @type {string}
   * @memberof CMSVietQRProApiNextState
   */
  readonly id: string;

  /**
   *
   * @type {CmsVietQrProNextStateRequest}
   * @memberof CMSVietQRProApiNextState
   */
  readonly cmsVietQrProNextStateRequest: CmsVietQrProNextStateRequest;
}

/**
 * CMSVietQRProApi - object-oriented interface
 * @export
 * @class CMSVietQRProApi
 * @extends {BaseAPI}
 */
export class CMSVietQRProApi extends BaseAPI {
  /**
   *
   * @summary [CMS] Lấy danh sách Merchant
   * @param {CMSVietQRProApiGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSVietQRProApi
   */
  public get(
    requestParameters: CMSVietQRProApiGetRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSVietQRProApiFp(this.configuration)
      .get(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [CMS] Lấy danh sách Merchant
   * @param {CMSVietQRProApiGetAllRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSVietQRProApi
   */
  public getAll(
    requestParameters: CMSVietQRProApiGetAllRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return CMSVietQRProApiFp(this.configuration)
      .getAll(
        requestParameters.code,
        requestParameters.name,
        requestParameters.fromDate,
        requestParameters.toDate,
        requestParameters.status,
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [CMS] Lấy danh sách thay đổi
   * @param {CMSVietQRProApiGetAllHistoryChangesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSVietQRProApi
   */
  public getAllHistoryChanges(
    requestParameters: CMSVietQRProApiGetAllHistoryChangesRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return CMSVietQRProApiFp(this.configuration)
      .getAllHistoryChanges(
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [CMS] Lấy danh sách khách hàng của merchant
   * @param {CMSVietQRProApiGetCustomersRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSVietQRProApi
   */
  public getCustomers(
    requestParameters: CMSVietQRProApiGetCustomersRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSVietQRProApiFp(this.configuration)
      .getCustomers(
        requestParameters.id,
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [CMS] Đổi trạng thái VietQr Pro
   * @param {CMSVietQRProApiNextStateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CMSVietQRProApi
   */
  public nextState(
    requestParameters: CMSVietQRProApiNextStateRequest,
    options?: AxiosRequestConfig,
  ) {
    return CMSVietQRProApiFp(this.configuration)
      .nextState(
        requestParameters.id,
        requestParameters.cmsVietQrProNextStateRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CoreBankControllerApi - axios parameter creator
 * @export
 */
export const CoreBankControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Check người thụ hưởng bằng tài khoản ngân hàng
     * @param {string} bin
     * @param {string} accountNo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBeneficiary: async (
      bin: string,
      accountNo: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bin' is not null or undefined
      assertParamExists("getBeneficiary", "bin", bin);
      // verify required parameter 'accountNo' is not null or undefined
      assertParamExists("getBeneficiary", "accountNo", accountNo);
      const localVarPath = `/api/coreBank/v1/getBeneficiary`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (bin !== undefined) {
        localVarQueryParameter["bin"] = bin;
      }

      if (accountNo !== undefined) {
        localVarQueryParameter["accountNo"] = accountNo;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CoreBankControllerApi - functional programming interface
 * @export
 */
export const CoreBankControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    CoreBankControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Check người thụ hưởng bằng tài khoản ngân hàng
     * @param {string} bin
     * @param {string} accountNo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBeneficiary(
      bin: string,
      accountNo: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetBeneficiaryResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBeneficiary(
        bin,
        accountNo,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * CoreBankControllerApi - factory interface
 * @export
 */
export const CoreBankControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CoreBankControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Check người thụ hưởng bằng tài khoản ngân hàng
     * @param {string} bin
     * @param {string} accountNo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBeneficiary(
      bin: string,
      accountNo: string,
      options?: any,
    ): AxiosPromise<ResponseBaseGetBeneficiaryResponse> {
      return localVarFp
        .getBeneficiary(bin, accountNo, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getBeneficiary operation in CoreBankControllerApi.
 * @export
 * @interface CoreBankControllerApiGetBeneficiaryRequest
 */
export interface CoreBankControllerApiGetBeneficiaryRequest {
  /**
   *
   * @type {string}
   * @memberof CoreBankControllerApiGetBeneficiary
   */
  readonly bin: string;

  /**
   *
   * @type {string}
   * @memberof CoreBankControllerApiGetBeneficiary
   */
  readonly accountNo: string;
}

/**
 * CoreBankControllerApi - object-oriented interface
 * @export
 * @class CoreBankControllerApi
 * @extends {BaseAPI}
 */
export class CoreBankControllerApi extends BaseAPI {
  /**
   *
   * @summary Check người thụ hưởng bằng tài khoản ngân hàng
   * @param {CoreBankControllerApiGetBeneficiaryRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CoreBankControllerApi
   */
  public getBeneficiary(
    requestParameters: CoreBankControllerApiGetBeneficiaryRequest,
    options?: AxiosRequestConfig,
  ) {
    return CoreBankControllerApiFp(this.configuration)
      .getBeneficiary(
        requestParameters.bin,
        requestParameters.accountNo,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CustomerControllerApi - axios parameter creator
 * @export
 */
export const CustomerControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Thêm danh sách customer vào group
     * @param {AddCustomersToGroupRequest} addCustomersToGroupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addCustomersToGroup: async (
      addCustomersToGroupRequest: AddCustomersToGroupRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'addCustomersToGroupRequest' is not null or undefined
      assertParamExists(
        "addCustomersToGroup",
        "addCustomersToGroupRequest",
        addCustomersToGroupRequest,
      );
      const localVarPath = `/api/customer/v1/addCustomersToGroup`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        addCustomersToGroupRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Thêm customer vào customer group
     * @param {AddCustomerToGroupRequest} addCustomerToGroupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addUserToGroup: async (
      addCustomerToGroupRequest: AddCustomerToGroupRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'addCustomerToGroupRequest' is not null or undefined
      assertParamExists(
        "addUserToGroup",
        "addCustomerToGroupRequest",
        addCustomerToGroupRequest,
      );
      const localVarPath = `/api/customer/v1/addCustomerToGroup`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        addCustomerToGroupRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Tạo customer
     * @param {CreateCustomerRequest} createCustomerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCustomer: async (
      createCustomerRequest: CreateCustomerRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createCustomerRequest' is not null or undefined
      assertParamExists(
        "createCustomer",
        "createCustomerRequest",
        createCustomerRequest,
      );
      const localVarPath = `/api/customer/v1/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createCustomerRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Tạo customer group
     * @param {CreateCustomerGroupRequest} createCustomerGroupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCustomerGroup: async (
      createCustomerGroupRequest: CreateCustomerGroupRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createCustomerGroupRequest' is not null or undefined
      assertParamExists(
        "createCustomerGroup",
        "createCustomerGroupRequest",
        createCustomerGroupRequest,
      );
      const localVarPath = `/api/customer/v1/createGroup`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createCustomerGroupRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xóa khách hàng
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCustomer: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("deleteCustomer", "id", id);
      const localVarPath = `/api/customer/v1/delete/customer`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (id !== undefined) {
        localVarQueryParameter["id"] = id;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xóa nhóm khách hàng
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCustomerGroup: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("deleteCustomerGroup", "id", id);
      const localVarPath = `/api/customer/v1/delete/customerGroup`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (id !== undefined) {
        localVarQueryParameter["id"] = id;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy chi tiết customer
     * @param {string} customerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDetailCustomer: async (
      customerId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'customerId' is not null or undefined
      assertParamExists("getDetailCustomer", "customerId", customerId);
      const localVarPath = `/api/customer/v1/getDetail/{customerId}`.replace(
        `{${"customerId"}}`,
        encodeURIComponent(String(customerId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy chi tiết customer group
     * @param {string} customerGroupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDetailCustomerGroup: async (
      customerGroupId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'customerGroupId' is not null or undefined
      assertParamExists(
        "getDetailCustomerGroup",
        "customerGroupId",
        customerGroupId,
      );
      const localVarPath =
        `/api/customer/v1/getGroupDetail/{customerGroupId}`.replace(
          `{${"customerGroupId"}}`,
          encodeURIComponent(String(customerGroupId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy danh sách customer
     * @param {string} [keyword]
     * @param {boolean} [haveGroup]
     * @param {string} [groupId]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListCustomer: async (
      keyword?: string,
      haveGroup?: boolean,
      groupId?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/customer/v1/getList`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (keyword !== undefined) {
        localVarQueryParameter["keyword"] = keyword;
      }

      if (haveGroup !== undefined) {
        localVarQueryParameter["haveGroup"] = haveGroup;
      }

      if (groupId !== undefined) {
        localVarQueryParameter["groupId"] = groupId;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter["sortDirection"] = sortDirection;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter["sortBy"] = sortBy;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [groupName]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListCustomerGroup: async (
      groupName?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/customer/v1/getGroupList`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (groupName !== undefined) {
        localVarQueryParameter["groupName"] = groupName;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter["sortDirection"] = sortDirection;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter["sortBy"] = sortBy;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [groupName]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListCustomerGroupCache: async (
      groupName?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/customer/v1/getGroupListCache`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (groupName !== undefined) {
        localVarQueryParameter["groupName"] = groupName;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter["sortDirection"] = sortDirection;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter["sortBy"] = sortBy;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Cập nhật customer
     * @param {UpdateCustomerRequest} updateCustomerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCustomer: async (
      updateCustomerRequest: UpdateCustomerRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateCustomerRequest' is not null or undefined
      assertParamExists(
        "updateCustomer",
        "updateCustomerRequest",
        updateCustomerRequest,
      );
      const localVarPath = `/api/customer/v1/update`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateCustomerRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Cập nhật customer group
     * @param {UpdateCustomerGroupRequest} updateCustomerGroupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCustomerGroup: async (
      updateCustomerGroupRequest: UpdateCustomerGroupRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateCustomerGroupRequest' is not null or undefined
      assertParamExists(
        "updateCustomerGroup",
        "updateCustomerGroupRequest",
        updateCustomerGroupRequest,
      );
      const localVarPath = `/api/customer/v1/updateGroup`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateCustomerGroupRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CustomerControllerApi - functional programming interface
 * @export
 */
export const CustomerControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    CustomerControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Thêm danh sách customer vào group
     * @param {AddCustomersToGroupRequest} addCustomersToGroupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addCustomersToGroup(
      addCustomersToGroupRequest: AddCustomersToGroupRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.addCustomersToGroup(
          addCustomersToGroupRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Thêm customer vào customer group
     * @param {AddCustomerToGroupRequest} addCustomerToGroupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addUserToGroup(
      addCustomerToGroupRequest: AddCustomerToGroupRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseCustomerResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addUserToGroup(
        addCustomerToGroupRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Tạo customer
     * @param {CreateCustomerRequest} createCustomerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createCustomer(
      createCustomerRequest: CreateCustomerRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseCustomerResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createCustomer(
        createCustomerRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Tạo customer group
     * @param {CreateCustomerGroupRequest} createCustomerGroupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createCustomerGroup(
      createCustomerGroupRequest: CreateCustomerGroupRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseCustomerGroupResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createCustomerGroup(
          createCustomerGroupRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Xóa khách hàng
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteCustomer(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCustomer(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Xóa nhóm khách hàng
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteCustomerGroup(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteCustomerGroup(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy chi tiết customer
     * @param {string} customerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDetailCustomer(
      customerId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseCustomerListResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getDetailCustomer(customerId, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy chi tiết customer group
     * @param {string} customerGroupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDetailCustomerGroup(
      customerGroupId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseCustomerGroupResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getDetailCustomerGroup(
          customerGroupId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy danh sách customer
     * @param {string} [keyword]
     * @param {boolean} [haveGroup]
     * @param {string} [groupId]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getListCustomer(
      keyword?: string,
      haveGroup?: boolean,
      groupId?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseCustomerDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getListCustomer(
        keyword,
        haveGroup,
        groupId,
        page,
        pageSize,
        sortDirection,
        sortBy,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [groupName]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getListCustomerGroup(
      groupName?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseCustomerGroupDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getListCustomerGroup(
          groupName,
          page,
          pageSize,
          sortDirection,
          sortBy,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [groupName]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getListCustomerGroupCache(
      groupName?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseCustomerGroupDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getListCustomerGroupCache(
          groupName,
          page,
          pageSize,
          sortDirection,
          sortBy,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Cập nhật customer
     * @param {UpdateCustomerRequest} updateCustomerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateCustomer(
      updateCustomerRequest: UpdateCustomerRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseCustomerResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomer(
        updateCustomerRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Cập nhật customer group
     * @param {UpdateCustomerGroupRequest} updateCustomerGroupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateCustomerGroup(
      updateCustomerGroupRequest: UpdateCustomerGroupRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseCustomerGroupResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateCustomerGroup(
          updateCustomerGroupRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * CustomerControllerApi - factory interface
 * @export
 */
export const CustomerControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CustomerControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Thêm danh sách customer vào group
     * @param {AddCustomersToGroupRequest} addCustomersToGroupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addCustomersToGroup(
      addCustomersToGroupRequest: AddCustomersToGroupRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .addCustomersToGroup(addCustomersToGroupRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Thêm customer vào customer group
     * @param {AddCustomerToGroupRequest} addCustomerToGroupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addUserToGroup(
      addCustomerToGroupRequest: AddCustomerToGroupRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseCustomerResponse> {
      return localVarFp
        .addUserToGroup(addCustomerToGroupRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Tạo customer
     * @param {CreateCustomerRequest} createCustomerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCustomer(
      createCustomerRequest: CreateCustomerRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseCustomerResponse> {
      return localVarFp
        .createCustomer(createCustomerRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Tạo customer group
     * @param {CreateCustomerGroupRequest} createCustomerGroupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCustomerGroup(
      createCustomerGroupRequest: CreateCustomerGroupRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseCustomerGroupResponse> {
      return localVarFp
        .createCustomerGroup(createCustomerGroupRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xóa khách hàng
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCustomer(
      id: string,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .deleteCustomer(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xóa nhóm khách hàng
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCustomerGroup(
      id: string,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .deleteCustomerGroup(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy chi tiết customer
     * @param {string} customerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDetailCustomer(
      customerId: string,
      options?: any,
    ): AxiosPromise<ResponseBaseCustomerListResponse> {
      return localVarFp
        .getDetailCustomer(customerId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy chi tiết customer group
     * @param {string} customerGroupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDetailCustomerGroup(
      customerGroupId: string,
      options?: any,
    ): AxiosPromise<ResponseBaseCustomerGroupResponse> {
      return localVarFp
        .getDetailCustomerGroup(customerGroupId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy danh sách customer
     * @param {string} [keyword]
     * @param {boolean} [haveGroup]
     * @param {string} [groupId]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListCustomer(
      keyword?: string,
      haveGroup?: boolean,
      groupId?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseCustomerDto> {
      return localVarFp
        .getListCustomer(
          keyword,
          haveGroup,
          groupId,
          page,
          pageSize,
          sortDirection,
          sortBy,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [groupName]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListCustomerGroup(
      groupName?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseCustomerGroupDto> {
      return localVarFp
        .getListCustomerGroup(
          groupName,
          page,
          pageSize,
          sortDirection,
          sortBy,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [groupName]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListCustomerGroupCache(
      groupName?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseCustomerGroupDto> {
      return localVarFp
        .getListCustomerGroupCache(
          groupName,
          page,
          pageSize,
          sortDirection,
          sortBy,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Cập nhật customer
     * @param {UpdateCustomerRequest} updateCustomerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCustomer(
      updateCustomerRequest: UpdateCustomerRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseCustomerResponse> {
      return localVarFp
        .updateCustomer(updateCustomerRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Cập nhật customer group
     * @param {UpdateCustomerGroupRequest} updateCustomerGroupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCustomerGroup(
      updateCustomerGroupRequest: UpdateCustomerGroupRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseCustomerGroupResponse> {
      return localVarFp
        .updateCustomerGroup(updateCustomerGroupRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for addCustomersToGroup operation in CustomerControllerApi.
 * @export
 * @interface CustomerControllerApiAddCustomersToGroupRequest
 */
export interface CustomerControllerApiAddCustomersToGroupRequest {
  /**
   *
   * @type {AddCustomersToGroupRequest}
   * @memberof CustomerControllerApiAddCustomersToGroup
   */
  readonly addCustomersToGroupRequest: AddCustomersToGroupRequest;
}

/**
 * Request parameters for addUserToGroup operation in CustomerControllerApi.
 * @export
 * @interface CustomerControllerApiAddUserToGroupRequest
 */
export interface CustomerControllerApiAddUserToGroupRequest {
  /**
   *
   * @type {AddCustomerToGroupRequest}
   * @memberof CustomerControllerApiAddUserToGroup
   */
  readonly addCustomerToGroupRequest: AddCustomerToGroupRequest;
}

/**
 * Request parameters for createCustomer operation in CustomerControllerApi.
 * @export
 * @interface CustomerControllerApiCreateCustomerRequest
 */
export interface CustomerControllerApiCreateCustomerRequest {
  /**
   *
   * @type {CreateCustomerRequest}
   * @memberof CustomerControllerApiCreateCustomer
   */
  readonly createCustomerRequest: CreateCustomerRequest;
}

/**
 * Request parameters for createCustomerGroup operation in CustomerControllerApi.
 * @export
 * @interface CustomerControllerApiCreateCustomerGroupRequest
 */
export interface CustomerControllerApiCreateCustomerGroupRequest {
  /**
   *
   * @type {CreateCustomerGroupRequest}
   * @memberof CustomerControllerApiCreateCustomerGroup
   */
  readonly createCustomerGroupRequest: CreateCustomerGroupRequest;
}

/**
 * Request parameters for deleteCustomer operation in CustomerControllerApi.
 * @export
 * @interface CustomerControllerApiDeleteCustomerRequest
 */
export interface CustomerControllerApiDeleteCustomerRequest {
  /**
   *
   * @type {string}
   * @memberof CustomerControllerApiDeleteCustomer
   */
  readonly id: string;
}

/**
 * Request parameters for deleteCustomerGroup operation in CustomerControllerApi.
 * @export
 * @interface CustomerControllerApiDeleteCustomerGroupRequest
 */
export interface CustomerControllerApiDeleteCustomerGroupRequest {
  /**
   *
   * @type {string}
   * @memberof CustomerControllerApiDeleteCustomerGroup
   */
  readonly id: string;
}

/**
 * Request parameters for getDetailCustomer operation in CustomerControllerApi.
 * @export
 * @interface CustomerControllerApiGetDetailCustomerRequest
 */
export interface CustomerControllerApiGetDetailCustomerRequest {
  /**
   *
   * @type {string}
   * @memberof CustomerControllerApiGetDetailCustomer
   */
  readonly customerId: string;
}

/**
 * Request parameters for getDetailCustomerGroup operation in CustomerControllerApi.
 * @export
 * @interface CustomerControllerApiGetDetailCustomerGroupRequest
 */
export interface CustomerControllerApiGetDetailCustomerGroupRequest {
  /**
   *
   * @type {string}
   * @memberof CustomerControllerApiGetDetailCustomerGroup
   */
  readonly customerGroupId: string;
}

/**
 * Request parameters for getListCustomer operation in CustomerControllerApi.
 * @export
 * @interface CustomerControllerApiGetListCustomerRequest
 */
export interface CustomerControllerApiGetListCustomerRequest {
  /**
   *
   * @type {string}
   * @memberof CustomerControllerApiGetListCustomer
   */
  readonly keyword?: string;

  /**
   *
   * @type {boolean}
   * @memberof CustomerControllerApiGetListCustomer
   */
  readonly haveGroup?: boolean;

  /**
   *
   * @type {string}
   * @memberof CustomerControllerApiGetListCustomer
   */
  readonly groupId?: string;

  /**
   *
   * @type {number}
   * @memberof CustomerControllerApiGetListCustomer
   */
  readonly page?: number;

  /**
   *
   * @type {number}
   * @memberof CustomerControllerApiGetListCustomer
   */
  readonly pageSize?: number;

  /**
   *
   * @type {'ASC' | 'DESC'}
   * @memberof CustomerControllerApiGetListCustomer
   */
  readonly sortDirection?: "ASC" | "DESC";

  /**
   *
   * @type {string}
   * @memberof CustomerControllerApiGetListCustomer
   */
  readonly sortBy?: string;
}

/**
 * Request parameters for getListCustomerGroup operation in CustomerControllerApi.
 * @export
 * @interface CustomerControllerApiGetListCustomerGroupRequest
 */
export interface CustomerControllerApiGetListCustomerGroupRequest {
  /**
   *
   * @type {string}
   * @memberof CustomerControllerApiGetListCustomerGroup
   */
  readonly groupName?: string;

  /**
   *
   * @type {number}
   * @memberof CustomerControllerApiGetListCustomerGroup
   */
  readonly page?: number;

  /**
   *
   * @type {number}
   * @memberof CustomerControllerApiGetListCustomerGroup
   */
  readonly pageSize?: number;

  /**
   *
   * @type {'ASC' | 'DESC'}
   * @memberof CustomerControllerApiGetListCustomerGroup
   */
  readonly sortDirection?: "ASC" | "DESC";

  /**
   *
   * @type {string}
   * @memberof CustomerControllerApiGetListCustomerGroup
   */
  readonly sortBy?: string;
}

/**
 * Request parameters for getListCustomerGroupCache operation in CustomerControllerApi.
 * @export
 * @interface CustomerControllerApiGetListCustomerGroupCacheRequest
 */
export interface CustomerControllerApiGetListCustomerGroupCacheRequest {
  /**
   *
   * @type {string}
   * @memberof CustomerControllerApiGetListCustomerGroupCache
   */
  readonly groupName?: string;

  /**
   *
   * @type {number}
   * @memberof CustomerControllerApiGetListCustomerGroupCache
   */
  readonly page?: number;

  /**
   *
   * @type {number}
   * @memberof CustomerControllerApiGetListCustomerGroupCache
   */
  readonly pageSize?: number;

  /**
   *
   * @type {'ASC' | 'DESC'}
   * @memberof CustomerControllerApiGetListCustomerGroupCache
   */
  readonly sortDirection?: "ASC" | "DESC";

  /**
   *
   * @type {string}
   * @memberof CustomerControllerApiGetListCustomerGroupCache
   */
  readonly sortBy?: string;
}

/**
 * Request parameters for updateCustomer operation in CustomerControllerApi.
 * @export
 * @interface CustomerControllerApiUpdateCustomerRequest
 */
export interface CustomerControllerApiUpdateCustomerRequest {
  /**
   *
   * @type {UpdateCustomerRequest}
   * @memberof CustomerControllerApiUpdateCustomer
   */
  readonly updateCustomerRequest: UpdateCustomerRequest;
}

/**
 * Request parameters for updateCustomerGroup operation in CustomerControllerApi.
 * @export
 * @interface CustomerControllerApiUpdateCustomerGroupRequest
 */
export interface CustomerControllerApiUpdateCustomerGroupRequest {
  /**
   *
   * @type {UpdateCustomerGroupRequest}
   * @memberof CustomerControllerApiUpdateCustomerGroup
   */
  readonly updateCustomerGroupRequest: UpdateCustomerGroupRequest;
}

/**
 * CustomerControllerApi - object-oriented interface
 * @export
 * @class CustomerControllerApi
 * @extends {BaseAPI}
 */
export class CustomerControllerApi extends BaseAPI {
  /**
   *
   * @summary Thêm danh sách customer vào group
   * @param {CustomerControllerApiAddCustomersToGroupRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerControllerApi
   */
  public addCustomersToGroup(
    requestParameters: CustomerControllerApiAddCustomersToGroupRequest,
    options?: AxiosRequestConfig,
  ) {
    return CustomerControllerApiFp(this.configuration)
      .addCustomersToGroup(
        requestParameters.addCustomersToGroupRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Thêm customer vào customer group
   * @param {CustomerControllerApiAddUserToGroupRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerControllerApi
   */
  public addUserToGroup(
    requestParameters: CustomerControllerApiAddUserToGroupRequest,
    options?: AxiosRequestConfig,
  ) {
    return CustomerControllerApiFp(this.configuration)
      .addUserToGroup(requestParameters.addCustomerToGroupRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Tạo customer
   * @param {CustomerControllerApiCreateCustomerRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerControllerApi
   */
  public createCustomer(
    requestParameters: CustomerControllerApiCreateCustomerRequest,
    options?: AxiosRequestConfig,
  ) {
    return CustomerControllerApiFp(this.configuration)
      .createCustomer(requestParameters.createCustomerRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Tạo customer group
   * @param {CustomerControllerApiCreateCustomerGroupRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerControllerApi
   */
  public createCustomerGroup(
    requestParameters: CustomerControllerApiCreateCustomerGroupRequest,
    options?: AxiosRequestConfig,
  ) {
    return CustomerControllerApiFp(this.configuration)
      .createCustomerGroup(
        requestParameters.createCustomerGroupRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xóa khách hàng
   * @param {CustomerControllerApiDeleteCustomerRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerControllerApi
   */
  public deleteCustomer(
    requestParameters: CustomerControllerApiDeleteCustomerRequest,
    options?: AxiosRequestConfig,
  ) {
    return CustomerControllerApiFp(this.configuration)
      .deleteCustomer(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xóa nhóm khách hàng
   * @param {CustomerControllerApiDeleteCustomerGroupRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerControllerApi
   */
  public deleteCustomerGroup(
    requestParameters: CustomerControllerApiDeleteCustomerGroupRequest,
    options?: AxiosRequestConfig,
  ) {
    return CustomerControllerApiFp(this.configuration)
      .deleteCustomerGroup(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy chi tiết customer
   * @param {CustomerControllerApiGetDetailCustomerRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerControllerApi
   */
  public getDetailCustomer(
    requestParameters: CustomerControllerApiGetDetailCustomerRequest,
    options?: AxiosRequestConfig,
  ) {
    return CustomerControllerApiFp(this.configuration)
      .getDetailCustomer(requestParameters.customerId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy chi tiết customer group
   * @param {CustomerControllerApiGetDetailCustomerGroupRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerControllerApi
   */
  public getDetailCustomerGroup(
    requestParameters: CustomerControllerApiGetDetailCustomerGroupRequest,
    options?: AxiosRequestConfig,
  ) {
    return CustomerControllerApiFp(this.configuration)
      .getDetailCustomerGroup(requestParameters.customerGroupId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy danh sách customer
   * @param {CustomerControllerApiGetListCustomerRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerControllerApi
   */
  public getListCustomer(
    requestParameters: CustomerControllerApiGetListCustomerRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return CustomerControllerApiFp(this.configuration)
      .getListCustomer(
        requestParameters.keyword,
        requestParameters.haveGroup,
        requestParameters.groupId,
        requestParameters.page,
        requestParameters.pageSize,
        requestParameters.sortDirection,
        requestParameters.sortBy,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CustomerControllerApiGetListCustomerGroupRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerControllerApi
   */
  public getListCustomerGroup(
    requestParameters: CustomerControllerApiGetListCustomerGroupRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return CustomerControllerApiFp(this.configuration)
      .getListCustomerGroup(
        requestParameters.groupName,
        requestParameters.page,
        requestParameters.pageSize,
        requestParameters.sortDirection,
        requestParameters.sortBy,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CustomerControllerApiGetListCustomerGroupCacheRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerControllerApi
   */
  public getListCustomerGroupCache(
    requestParameters: CustomerControllerApiGetListCustomerGroupCacheRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return CustomerControllerApiFp(this.configuration)
      .getListCustomerGroupCache(
        requestParameters.groupName,
        requestParameters.page,
        requestParameters.pageSize,
        requestParameters.sortDirection,
        requestParameters.sortBy,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Cập nhật customer
   * @param {CustomerControllerApiUpdateCustomerRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerControllerApi
   */
  public updateCustomer(
    requestParameters: CustomerControllerApiUpdateCustomerRequest,
    options?: AxiosRequestConfig,
  ) {
    return CustomerControllerApiFp(this.configuration)
      .updateCustomer(requestParameters.updateCustomerRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Cập nhật customer group
   * @param {CustomerControllerApiUpdateCustomerGroupRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerControllerApi
   */
  public updateCustomerGroup(
    requestParameters: CustomerControllerApiUpdateCustomerGroupRequest,
    options?: AxiosRequestConfig,
  ) {
    return CustomerControllerApiFp(this.configuration)
      .updateCustomerGroup(
        requestParameters.updateCustomerGroupRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * EWalletControllerApi - axios parameter creator
 * @export
 */
export const EWalletControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * - Tạo mới một ví điện tử
     * @summary Tạo mới một ví điện tử
     * @param {string} name
     * @param {string} shortName
     * @param {string} commonName
     * @param {File} logo
     * @param {File} icon
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEWallet: async (
      name: string,
      shortName: string,
      commonName: string,
      logo: File,
      icon: File,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'name' is not null or undefined
      assertParamExists("createEWallet", "name", name);
      // verify required parameter 'shortName' is not null or undefined
      assertParamExists("createEWallet", "shortName", shortName);
      // verify required parameter 'commonName' is not null or undefined
      assertParamExists("createEWallet", "commonName", commonName);
      // verify required parameter 'logo' is not null or undefined
      assertParamExists("createEWallet", "logo", logo);
      // verify required parameter 'icon' is not null or undefined
      assertParamExists("createEWallet", "icon", icon);
      const localVarPath = `/api/eWallet/cms/v1/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (name !== undefined) {
        localVarFormParams.append("name", name as any);
      }

      if (shortName !== undefined) {
        localVarFormParams.append("shortName", shortName as any);
      }

      if (commonName !== undefined) {
        localVarFormParams.append("commonName", commonName as any);
      }

      if (logo !== undefined) {
        localVarFormParams.append("logo", logo as any);
      }

      if (icon !== undefined) {
        localVarFormParams.append("icon", icon as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Xóa một ví điện tử
     * @summary Xóa một ví điện tử
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEWallet: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("deleteEWallet", "id", id);
      const localVarPath = `/api/eWallet/cms/v1/delete/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Lấy tất cả danh ví điện tử
     * @summary Lấy tất cả danh sách ví điện tử
     * @param {string} [name]
     * @param {number} [pageSize]
     * @param {number} [pageNumber]
     * @param {string} [sortBy]
     * @param {string} [sortDir]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllEWallets: async (
      name?: string,
      pageSize?: number,
      pageNumber?: number,
      sortBy?: string,
      sortDir?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/eWallet/cms/v1/getAll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (name !== undefined) {
        localVarQueryParameter["name"] = name;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      if (pageNumber !== undefined) {
        localVarQueryParameter["pageNumber"] = pageNumber;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter["sortBy"] = sortBy;
      }

      if (sortDir !== undefined) {
        localVarQueryParameter["sortDir"] = sortDir;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * EWalletControllerApi - functional programming interface
 * @export
 */
export const EWalletControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    EWalletControllerApiAxiosParamCreator(configuration);
  return {
    /**
     * - Tạo mới một ví điện tử
     * @summary Tạo mới một ví điện tử
     * @param {string} name
     * @param {string} shortName
     * @param {string} commonName
     * @param {File} logo
     * @param {File} icon
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createEWallet(
      name: string,
      shortName: string,
      commonName: string,
      logo: File,
      icon: File,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseEWalletResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createEWallet(
        name,
        shortName,
        commonName,
        logo,
        icon,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Xóa một ví điện tử
     * @summary Xóa một ví điện tử
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteEWallet(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseEWalletResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEWallet(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Lấy tất cả danh ví điện tử
     * @summary Lấy tất cả danh sách ví điện tử
     * @param {string} [name]
     * @param {number} [pageSize]
     * @param {number} [pageNumber]
     * @param {string} [sortBy]
     * @param {string} [sortDir]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllEWallets(
      name?: string,
      pageSize?: number,
      pageNumber?: number,
      sortBy?: string,
      sortDir?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseEWalletResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllEWallets(
        name,
        pageSize,
        pageNumber,
        sortBy,
        sortDir,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * EWalletControllerApi - factory interface
 * @export
 */
export const EWalletControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = EWalletControllerApiFp(configuration);
  return {
    /**
     * - Tạo mới một ví điện tử
     * @summary Tạo mới một ví điện tử
     * @param {string} name
     * @param {string} shortName
     * @param {string} commonName
     * @param {File} logo
     * @param {File} icon
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEWallet(
      name: string,
      shortName: string,
      commonName: string,
      logo: File,
      icon: File,
      options?: any,
    ): AxiosPromise<ResponseBaseEWalletResponse> {
      return localVarFp
        .createEWallet(name, shortName, commonName, logo, icon, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Xóa một ví điện tử
     * @summary Xóa một ví điện tử
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEWallet(
      id: number,
      options?: any,
    ): AxiosPromise<ResponseBaseEWalletResponse> {
      return localVarFp
        .deleteEWallet(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Lấy tất cả danh ví điện tử
     * @summary Lấy tất cả danh sách ví điện tử
     * @param {string} [name]
     * @param {number} [pageSize]
     * @param {number} [pageNumber]
     * @param {string} [sortBy]
     * @param {string} [sortDir]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllEWallets(
      name?: string,
      pageSize?: number,
      pageNumber?: number,
      sortBy?: string,
      sortDir?: string,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseEWalletResponse> {
      return localVarFp
        .getAllEWallets(name, pageSize, pageNumber, sortBy, sortDir, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createEWallet operation in EWalletControllerApi.
 * @export
 * @interface EWalletControllerApiCreateEWalletRequest
 */
export interface EWalletControllerApiCreateEWalletRequest {
  /**
   *
   * @type {string}
   * @memberof EWalletControllerApiCreateEWallet
   */
  readonly name: string;

  /**
   *
   * @type {string}
   * @memberof EWalletControllerApiCreateEWallet
   */
  readonly shortName: string;

  /**
   *
   * @type {string}
   * @memberof EWalletControllerApiCreateEWallet
   */
  readonly commonName: string;

  /**
   *
   * @type {File}
   * @memberof EWalletControllerApiCreateEWallet
   */
  readonly logo: File;

  /**
   *
   * @type {File}
   * @memberof EWalletControllerApiCreateEWallet
   */
  readonly icon: File;
}

/**
 * Request parameters for deleteEWallet operation in EWalletControllerApi.
 * @export
 * @interface EWalletControllerApiDeleteEWalletRequest
 */
export interface EWalletControllerApiDeleteEWalletRequest {
  /**
   *
   * @type {number}
   * @memberof EWalletControllerApiDeleteEWallet
   */
  readonly id: number;
}

/**
 * Request parameters for getAllEWallets operation in EWalletControllerApi.
 * @export
 * @interface EWalletControllerApiGetAllEWalletsRequest
 */
export interface EWalletControllerApiGetAllEWalletsRequest {
  /**
   *
   * @type {string}
   * @memberof EWalletControllerApiGetAllEWallets
   */
  readonly name?: string;

  /**
   *
   * @type {number}
   * @memberof EWalletControllerApiGetAllEWallets
   */
  readonly pageSize?: number;

  /**
   *
   * @type {number}
   * @memberof EWalletControllerApiGetAllEWallets
   */
  readonly pageNumber?: number;

  /**
   *
   * @type {string}
   * @memberof EWalletControllerApiGetAllEWallets
   */
  readonly sortBy?: string;

  /**
   *
   * @type {string}
   * @memberof EWalletControllerApiGetAllEWallets
   */
  readonly sortDir?: string;
}

/**
 * EWalletControllerApi - object-oriented interface
 * @export
 * @class EWalletControllerApi
 * @extends {BaseAPI}
 */
export class EWalletControllerApi extends BaseAPI {
  /**
   * - Tạo mới một ví điện tử
   * @summary Tạo mới một ví điện tử
   * @param {EWalletControllerApiCreateEWalletRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EWalletControllerApi
   */
  public createEWallet(
    requestParameters: EWalletControllerApiCreateEWalletRequest,
    options?: AxiosRequestConfig,
  ) {
    return EWalletControllerApiFp(this.configuration)
      .createEWallet(
        requestParameters.name,
        requestParameters.shortName,
        requestParameters.commonName,
        requestParameters.logo,
        requestParameters.icon,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Xóa một ví điện tử
   * @summary Xóa một ví điện tử
   * @param {EWalletControllerApiDeleteEWalletRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EWalletControllerApi
   */
  public deleteEWallet(
    requestParameters: EWalletControllerApiDeleteEWalletRequest,
    options?: AxiosRequestConfig,
  ) {
    return EWalletControllerApiFp(this.configuration)
      .deleteEWallet(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Lấy tất cả danh ví điện tử
   * @summary Lấy tất cả danh sách ví điện tử
   * @param {EWalletControllerApiGetAllEWalletsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EWalletControllerApi
   */
  public getAllEWallets(
    requestParameters: EWalletControllerApiGetAllEWalletsRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return EWalletControllerApiFp(this.configuration)
      .getAllEWallets(
        requestParameters.name,
        requestParameters.pageSize,
        requestParameters.pageNumber,
        requestParameters.sortBy,
        requestParameters.sortDir,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * EkycControllerApi - axios parameter creator
 * @export
 */
export const EkycControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary OCR CMND, CCCD, Passport,.. với ekyc
     * @param {string} ekycType
     * @param {File} imageFront
     * @param {File} [imageBack]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    detectCard: async (
      ekycType: string,
      imageFront: File,
      imageBack?: File,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'ekycType' is not null or undefined
      assertParamExists("detectCard", "ekycType", ekycType);
      // verify required parameter 'imageFront' is not null or undefined
      assertParamExists("detectCard", "imageFront", imageFront);
      const localVarPath = `/api/ekyc/v1/uploadCard`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (ekycType !== undefined) {
        localVarFormParams.append("ekycType", ekycType as any);
      }

      if (imageFront !== undefined) {
        localVarFormParams.append("imageFront", imageFront as any);
      }

      if (imageBack !== undefined) {
        localVarFormParams.append("imageBack", imageBack as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * EkycControllerApi - functional programming interface
 * @export
 */
export const EkycControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    EkycControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary OCR CMND, CCCD, Passport,.. với ekyc
     * @param {string} ekycType
     * @param {File} imageFront
     * @param {File} [imageBack]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async detectCard(
      ekycType: string,
      imageFront: File,
      imageBack?: File,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseEkycResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.detectCard(
        ekycType,
        imageFront,
        imageBack,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * EkycControllerApi - factory interface
 * @export
 */
export const EkycControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = EkycControllerApiFp(configuration);
  return {
    /**
     *
     * @summary OCR CMND, CCCD, Passport,.. với ekyc
     * @param {string} ekycType
     * @param {File} imageFront
     * @param {File} [imageBack]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    detectCard(
      ekycType: string,
      imageFront: File,
      imageBack?: File,
      options?: any,
    ): AxiosPromise<ResponseBaseEkycResponse> {
      return localVarFp
        .detectCard(ekycType, imageFront, imageBack, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for detectCard operation in EkycControllerApi.
 * @export
 * @interface EkycControllerApiDetectCardRequest
 */
export interface EkycControllerApiDetectCardRequest {
  /**
   *
   * @type {string}
   * @memberof EkycControllerApiDetectCard
   */
  readonly ekycType: string;

  /**
   *
   * @type {File}
   * @memberof EkycControllerApiDetectCard
   */
  readonly imageFront: File;

  /**
   *
   * @type {File}
   * @memberof EkycControllerApiDetectCard
   */
  readonly imageBack?: File;
}

/**
 * EkycControllerApi - object-oriented interface
 * @export
 * @class EkycControllerApi
 * @extends {BaseAPI}
 */
export class EkycControllerApi extends BaseAPI {
  /**
   *
   * @summary OCR CMND, CCCD, Passport,.. với ekyc
   * @param {EkycControllerApiDetectCardRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EkycControllerApi
   */
  public detectCard(
    requestParameters: EkycControllerApiDetectCardRequest,
    options?: AxiosRequestConfig,
  ) {
    return EkycControllerApiFp(this.configuration)
      .detectCard(
        requestParameters.ekycType,
        requestParameters.imageFront,
        requestParameters.imageBack,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ExtensionPosControllerApi - axios parameter creator
 * @export
 */
export const ExtensionPosControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Hủy giao dịch từ máy POS
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    posCancelTransaction: async (
      transactionId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'transactionId' is not null or undefined
      assertParamExists("posCancelTransaction", "transactionId", transactionId);
      const localVarPath =
        `/api/pos/v1/cancelTransaction/{transactionId}`.replace(
          `{${"transactionId"}}`,
          encodeURIComponent(String(transactionId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Kiểm tra giao dịch từ máy POS
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    posCheckTransaction: async (
      transactionId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'transactionId' is not null or undefined
      assertParamExists("posCheckTransaction", "transactionId", transactionId);
      const localVarPath =
        `/api/pos/v1/checkTransaction/{transactionId}`.replace(
          `{${"transactionId"}}`,
          encodeURIComponent(String(transactionId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Tạo 1 giao dịch mới từ máy POS
     * @param {PosCreateTransactionRequest} posCreateTransactionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    posCreateTransaction: async (
      posCreateTransactionRequest: PosCreateTransactionRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'posCreateTransactionRequest' is not null or undefined
      assertParamExists(
        "posCreateTransaction",
        "posCreateTransactionRequest",
        posCreateTransactionRequest,
      );
      const localVarPath = `/api/pos/v1/createTransaction`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        posCreateTransactionRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ExtensionPosControllerApi - functional programming interface
 * @export
 */
export const ExtensionPosControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    ExtensionPosControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Hủy giao dịch từ máy POS
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async posCancelTransaction(
      transactionId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.posCancelTransaction(
          transactionId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Kiểm tra giao dịch từ máy POS
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async posCheckTransaction(
      transactionId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePosCheckTransactionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.posCheckTransaction(
          transactionId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Tạo 1 giao dịch mới từ máy POS
     * @param {PosCreateTransactionRequest} posCreateTransactionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async posCreateTransaction(
      posCreateTransactionRequest: PosCreateTransactionRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseTransactionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.posCreateTransaction(
          posCreateTransactionRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * ExtensionPosControllerApi - factory interface
 * @export
 */
export const ExtensionPosControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ExtensionPosControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Hủy giao dịch từ máy POS
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    posCancelTransaction(
      transactionId: string,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .posCancelTransaction(transactionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Kiểm tra giao dịch từ máy POS
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    posCheckTransaction(
      transactionId: string,
      options?: any,
    ): AxiosPromise<ResponseBasePosCheckTransactionResponse> {
      return localVarFp
        .posCheckTransaction(transactionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Tạo 1 giao dịch mới từ máy POS
     * @param {PosCreateTransactionRequest} posCreateTransactionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    posCreateTransaction(
      posCreateTransactionRequest: PosCreateTransactionRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseTransactionResponse> {
      return localVarFp
        .posCreateTransaction(posCreateTransactionRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for posCancelTransaction operation in ExtensionPosControllerApi.
 * @export
 * @interface ExtensionPosControllerApiPosCancelTransactionRequest
 */
export interface ExtensionPosControllerApiPosCancelTransactionRequest {
  /**
   *
   * @type {string}
   * @memberof ExtensionPosControllerApiPosCancelTransaction
   */
  readonly transactionId: string;
}

/**
 * Request parameters for posCheckTransaction operation in ExtensionPosControllerApi.
 * @export
 * @interface ExtensionPosControllerApiPosCheckTransactionRequest
 */
export interface ExtensionPosControllerApiPosCheckTransactionRequest {
  /**
   *
   * @type {string}
   * @memberof ExtensionPosControllerApiPosCheckTransaction
   */
  readonly transactionId: string;
}

/**
 * Request parameters for posCreateTransaction operation in ExtensionPosControllerApi.
 * @export
 * @interface ExtensionPosControllerApiPosCreateTransactionRequest
 */
export interface ExtensionPosControllerApiPosCreateTransactionRequest {
  /**
   *
   * @type {PosCreateTransactionRequest}
   * @memberof ExtensionPosControllerApiPosCreateTransaction
   */
  readonly posCreateTransactionRequest: PosCreateTransactionRequest;
}

/**
 * ExtensionPosControllerApi - object-oriented interface
 * @export
 * @class ExtensionPosControllerApi
 * @extends {BaseAPI}
 */
export class ExtensionPosControllerApi extends BaseAPI {
  /**
   *
   * @summary Hủy giao dịch từ máy POS
   * @param {ExtensionPosControllerApiPosCancelTransactionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExtensionPosControllerApi
   */
  public posCancelTransaction(
    requestParameters: ExtensionPosControllerApiPosCancelTransactionRequest,
    options?: AxiosRequestConfig,
  ) {
    return ExtensionPosControllerApiFp(this.configuration)
      .posCancelTransaction(requestParameters.transactionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Kiểm tra giao dịch từ máy POS
   * @param {ExtensionPosControllerApiPosCheckTransactionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExtensionPosControllerApi
   */
  public posCheckTransaction(
    requestParameters: ExtensionPosControllerApiPosCheckTransactionRequest,
    options?: AxiosRequestConfig,
  ) {
    return ExtensionPosControllerApiFp(this.configuration)
      .posCheckTransaction(requestParameters.transactionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Tạo 1 giao dịch mới từ máy POS
   * @param {ExtensionPosControllerApiPosCreateTransactionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExtensionPosControllerApi
   */
  public posCreateTransaction(
    requestParameters: ExtensionPosControllerApiPosCreateTransactionRequest,
    options?: AxiosRequestConfig,
  ) {
    return ExtensionPosControllerApiFp(this.configuration)
      .posCreateTransaction(
        requestParameters.posCreateTransactionRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ExternalPaySubscriptionControllerApi - axios parameter creator
 * @export
 */
export const ExternalPaySubscriptionControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Tạo mới Subscription
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPaySubscription1: async (
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xApiClient' is not null or undefined
      assertParamExists("createPaySubscription1", "xApiClient", xApiClient);
      // verify required parameter 'xApiValidate' is not null or undefined
      assertParamExists("createPaySubscription1", "xApiValidate", xApiValidate);
      // verify required parameter 'xApiTime' is not null or undefined
      assertParamExists("createPaySubscription1", "xApiTime", xApiTime);
      // verify required parameter 'encryptedBodyRequest' is not null or undefined
      assertParamExists(
        "createPaySubscription1",
        "encryptedBodyRequest",
        encryptedBodyRequest,
      );
      const localVarPath = `/api/external/subscription/v1/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xApiClient != null) {
        localVarHeaderParameter["x-api-client"] = String(xApiClient);
      }

      if (xApiValidate != null) {
        localVarHeaderParameter["x-api-validate"] = String(xApiValidate);
      }

      if (xApiTime != null) {
        localVarHeaderParameter["x-api-time"] =
          typeof xApiTime === "string" ? xApiTime : JSON.stringify(xApiTime);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        encryptedBodyRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy danh sách Subscription
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllPaySubscription: async (
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xApiClient' is not null or undefined
      assertParamExists("getAllPaySubscription", "xApiClient", xApiClient);
      // verify required parameter 'xApiValidate' is not null or undefined
      assertParamExists("getAllPaySubscription", "xApiValidate", xApiValidate);
      // verify required parameter 'xApiTime' is not null or undefined
      assertParamExists("getAllPaySubscription", "xApiTime", xApiTime);
      // verify required parameter 'encryptedBodyRequest' is not null or undefined
      assertParamExists(
        "getAllPaySubscription",
        "encryptedBodyRequest",
        encryptedBodyRequest,
      );
      const localVarPath = `/api/external/subscription/v1/getAll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xApiClient != null) {
        localVarHeaderParameter["x-api-client"] = String(xApiClient);
      }

      if (xApiValidate != null) {
        localVarHeaderParameter["x-api-validate"] = String(xApiValidate);
      }

      if (xApiTime != null) {
        localVarHeaderParameter["x-api-time"] =
          typeof xApiTime === "string" ? xApiTime : JSON.stringify(xApiTime);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        encryptedBodyRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy thông tin Subscription
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaySubscription: async (
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xApiClient' is not null or undefined
      assertParamExists("getPaySubscription", "xApiClient", xApiClient);
      // verify required parameter 'xApiValidate' is not null or undefined
      assertParamExists("getPaySubscription", "xApiValidate", xApiValidate);
      // verify required parameter 'xApiTime' is not null or undefined
      assertParamExists("getPaySubscription", "xApiTime", xApiTime);
      // verify required parameter 'encryptedBodyRequest' is not null or undefined
      assertParamExists(
        "getPaySubscription",
        "encryptedBodyRequest",
        encryptedBodyRequest,
      );
      const localVarPath = `/api/external/subscription/v1/get`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xApiClient != null) {
        localVarHeaderParameter["x-api-client"] = String(xApiClient);
      }

      if (xApiValidate != null) {
        localVarHeaderParameter["x-api-validate"] = String(xApiValidate);
      }

      if (xApiTime != null) {
        localVarHeaderParameter["x-api-time"] =
          typeof xApiTime === "string" ? xApiTime : JSON.stringify(xApiTime);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        encryptedBodyRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Cập trạng thái Subscription
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStatePaySubscription: async (
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xApiClient' is not null or undefined
      assertParamExists("updateStatePaySubscription", "xApiClient", xApiClient);
      // verify required parameter 'xApiValidate' is not null or undefined
      assertParamExists(
        "updateStatePaySubscription",
        "xApiValidate",
        xApiValidate,
      );
      // verify required parameter 'xApiTime' is not null or undefined
      assertParamExists("updateStatePaySubscription", "xApiTime", xApiTime);
      // verify required parameter 'encryptedBodyRequest' is not null or undefined
      assertParamExists(
        "updateStatePaySubscription",
        "encryptedBodyRequest",
        encryptedBodyRequest,
      );
      const localVarPath = `/api/external/subscription/v1/updateState`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xApiClient != null) {
        localVarHeaderParameter["x-api-client"] = String(xApiClient);
      }

      if (xApiValidate != null) {
        localVarHeaderParameter["x-api-validate"] = String(xApiValidate);
      }

      if (xApiTime != null) {
        localVarHeaderParameter["x-api-time"] =
          typeof xApiTime === "string" ? xApiTime : JSON.stringify(xApiTime);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        encryptedBodyRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ExternalPaySubscriptionControllerApi - functional programming interface
 * @export
 */
export const ExternalPaySubscriptionControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    ExternalPaySubscriptionControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Tạo mới Subscription
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createPaySubscription1(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseString>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createPaySubscription1(
          xApiClient,
          xApiValidate,
          xApiTime,
          encryptedBodyRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy danh sách Subscription
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllPaySubscription(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseString>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllPaySubscription(
          xApiClient,
          xApiValidate,
          xApiTime,
          encryptedBodyRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy thông tin Subscription
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPaySubscription(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseString>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPaySubscription(
          xApiClient,
          xApiValidate,
          xApiTime,
          encryptedBodyRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Cập trạng thái Subscription
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateStatePaySubscription(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseString>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateStatePaySubscription(
          xApiClient,
          xApiValidate,
          xApiTime,
          encryptedBodyRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * ExternalPaySubscriptionControllerApi - factory interface
 * @export
 */
export const ExternalPaySubscriptionControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ExternalPaySubscriptionControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Tạo mới Subscription
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPaySubscription1(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseString> {
      return localVarFp
        .createPaySubscription1(
          xApiClient,
          xApiValidate,
          xApiTime,
          encryptedBodyRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy danh sách Subscription
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllPaySubscription(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseString> {
      return localVarFp
        .getAllPaySubscription(
          xApiClient,
          xApiValidate,
          xApiTime,
          encryptedBodyRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy thông tin Subscription
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaySubscription(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseString> {
      return localVarFp
        .getPaySubscription(
          xApiClient,
          xApiValidate,
          xApiTime,
          encryptedBodyRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Cập trạng thái Subscription
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStatePaySubscription(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseString> {
      return localVarFp
        .updateStatePaySubscription(
          xApiClient,
          xApiValidate,
          xApiTime,
          encryptedBodyRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createPaySubscription1 operation in ExternalPaySubscriptionControllerApi.
 * @export
 * @interface ExternalPaySubscriptionControllerApiCreatePaySubscription1Request
 */
export interface ExternalPaySubscriptionControllerApiCreatePaySubscription1Request {
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionControllerApiCreatePaySubscription1
   */
  readonly xApiClient: string;

  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionControllerApiCreatePaySubscription1
   */
  readonly xApiValidate: string;

  /**
   *
   * @type {number}
   * @memberof ExternalPaySubscriptionControllerApiCreatePaySubscription1
   */
  readonly xApiTime: number;

  /**
   *
   * @type {EncryptedBodyRequest}
   * @memberof ExternalPaySubscriptionControllerApiCreatePaySubscription1
   */
  readonly encryptedBodyRequest: EncryptedBodyRequest;
}

/**
 * Request parameters for getAllPaySubscription operation in ExternalPaySubscriptionControllerApi.
 * @export
 * @interface ExternalPaySubscriptionControllerApiGetAllPaySubscriptionRequest
 */
export interface ExternalPaySubscriptionControllerApiGetAllPaySubscriptionRequest {
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionControllerApiGetAllPaySubscription
   */
  readonly xApiClient: string;

  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionControllerApiGetAllPaySubscription
   */
  readonly xApiValidate: string;

  /**
   *
   * @type {number}
   * @memberof ExternalPaySubscriptionControllerApiGetAllPaySubscription
   */
  readonly xApiTime: number;

  /**
   *
   * @type {EncryptedBodyRequest}
   * @memberof ExternalPaySubscriptionControllerApiGetAllPaySubscription
   */
  readonly encryptedBodyRequest: EncryptedBodyRequest;
}

/**
 * Request parameters for getPaySubscription operation in ExternalPaySubscriptionControllerApi.
 * @export
 * @interface ExternalPaySubscriptionControllerApiGetPaySubscriptionRequest
 */
export interface ExternalPaySubscriptionControllerApiGetPaySubscriptionRequest {
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionControllerApiGetPaySubscription
   */
  readonly xApiClient: string;

  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionControllerApiGetPaySubscription
   */
  readonly xApiValidate: string;

  /**
   *
   * @type {number}
   * @memberof ExternalPaySubscriptionControllerApiGetPaySubscription
   */
  readonly xApiTime: number;

  /**
   *
   * @type {EncryptedBodyRequest}
   * @memberof ExternalPaySubscriptionControllerApiGetPaySubscription
   */
  readonly encryptedBodyRequest: EncryptedBodyRequest;
}

/**
 * Request parameters for updateStatePaySubscription operation in ExternalPaySubscriptionControllerApi.
 * @export
 * @interface ExternalPaySubscriptionControllerApiUpdateStatePaySubscriptionRequest
 */
export interface ExternalPaySubscriptionControllerApiUpdateStatePaySubscriptionRequest {
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionControllerApiUpdateStatePaySubscription
   */
  readonly xApiClient: string;

  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionControllerApiUpdateStatePaySubscription
   */
  readonly xApiValidate: string;

  /**
   *
   * @type {number}
   * @memberof ExternalPaySubscriptionControllerApiUpdateStatePaySubscription
   */
  readonly xApiTime: number;

  /**
   *
   * @type {EncryptedBodyRequest}
   * @memberof ExternalPaySubscriptionControllerApiUpdateStatePaySubscription
   */
  readonly encryptedBodyRequest: EncryptedBodyRequest;
}

/**
 * ExternalPaySubscriptionControllerApi - object-oriented interface
 * @export
 * @class ExternalPaySubscriptionControllerApi
 * @extends {BaseAPI}
 */
export class ExternalPaySubscriptionControllerApi extends BaseAPI {
  /**
   *
   * @summary Tạo mới Subscription
   * @param {ExternalPaySubscriptionControllerApiCreatePaySubscription1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalPaySubscriptionControllerApi
   */
  public createPaySubscription1(
    requestParameters: ExternalPaySubscriptionControllerApiCreatePaySubscription1Request,
    options?: AxiosRequestConfig,
  ) {
    return ExternalPaySubscriptionControllerApiFp(this.configuration)
      .createPaySubscription1(
        requestParameters.xApiClient,
        requestParameters.xApiValidate,
        requestParameters.xApiTime,
        requestParameters.encryptedBodyRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy danh sách Subscription
   * @param {ExternalPaySubscriptionControllerApiGetAllPaySubscriptionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalPaySubscriptionControllerApi
   */
  public getAllPaySubscription(
    requestParameters: ExternalPaySubscriptionControllerApiGetAllPaySubscriptionRequest,
    options?: AxiosRequestConfig,
  ) {
    return ExternalPaySubscriptionControllerApiFp(this.configuration)
      .getAllPaySubscription(
        requestParameters.xApiClient,
        requestParameters.xApiValidate,
        requestParameters.xApiTime,
        requestParameters.encryptedBodyRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy thông tin Subscription
   * @param {ExternalPaySubscriptionControllerApiGetPaySubscriptionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalPaySubscriptionControllerApi
   */
  public getPaySubscription(
    requestParameters: ExternalPaySubscriptionControllerApiGetPaySubscriptionRequest,
    options?: AxiosRequestConfig,
  ) {
    return ExternalPaySubscriptionControllerApiFp(this.configuration)
      .getPaySubscription(
        requestParameters.xApiClient,
        requestParameters.xApiValidate,
        requestParameters.xApiTime,
        requestParameters.encryptedBodyRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Cập trạng thái Subscription
   * @param {ExternalPaySubscriptionControllerApiUpdateStatePaySubscriptionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalPaySubscriptionControllerApi
   */
  public updateStatePaySubscription(
    requestParameters: ExternalPaySubscriptionControllerApiUpdateStatePaySubscriptionRequest,
    options?: AxiosRequestConfig,
  ) {
    return ExternalPaySubscriptionControllerApiFp(this.configuration)
      .updateStatePaySubscription(
        requestParameters.xApiClient,
        requestParameters.xApiValidate,
        requestParameters.xApiTime,
        requestParameters.encryptedBodyRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ExternalPaySubscriptionInvoiceControllerApi - axios parameter creator
 * @export
 */
export const ExternalPaySubscriptionInvoiceControllerApiAxiosParamCreator =
  function (configuration?: Configuration) {
    return {
      /**
       *
       * @summary Lấy thông tin Invoice
       * @param {string} xApiClient
       * @param {string} xApiValidate
       * @param {number} xApiTime
       * @param {EncryptedBodyRequest} encryptedBodyRequest
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      getAllPaySubscriptionInvoice: async (
        xApiClient: string,
        xApiValidate: string,
        xApiTime: number,
        encryptedBodyRequest: EncryptedBodyRequest,
        options: AxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'xApiClient' is not null or undefined
        assertParamExists(
          "getAllPaySubscriptionInvoice",
          "xApiClient",
          xApiClient,
        );
        // verify required parameter 'xApiValidate' is not null or undefined
        assertParamExists(
          "getAllPaySubscriptionInvoice",
          "xApiValidate",
          xApiValidate,
        );
        // verify required parameter 'xApiTime' is not null or undefined
        assertParamExists("getAllPaySubscriptionInvoice", "xApiTime", xApiTime);
        // verify required parameter 'encryptedBodyRequest' is not null or undefined
        assertParamExists(
          "getAllPaySubscriptionInvoice",
          "encryptedBodyRequest",
          encryptedBodyRequest,
        );
        const localVarPath = `/api/external/subscription/invoice/v1/getAll`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: "POST",
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        if (xApiClient != null) {
          localVarHeaderParameter["x-api-client"] = String(xApiClient);
        }

        if (xApiValidate != null) {
          localVarHeaderParameter["x-api-validate"] = String(xApiValidate);
        }

        if (xApiTime != null) {
          localVarHeaderParameter["x-api-time"] =
            typeof xApiTime === "string" ? xApiTime : JSON.stringify(xApiTime);
        }

        localVarHeaderParameter["Content-Type"] = "application/json";

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };
        localVarRequestOptions.data = serializeDataIfNeeded(
          encryptedBodyRequest,
          localVarRequestOptions,
          configuration,
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
      /**
       *
       * @summary Lấy thông tin chi tiết Invoice
       * @param {string} xApiClient
       * @param {string} xApiValidate
       * @param {number} xApiTime
       * @param {EncryptedBodyRequest} encryptedBodyRequest
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      getDetailPaySubscriptionInvoice: async (
        xApiClient: string,
        xApiValidate: string,
        xApiTime: number,
        encryptedBodyRequest: EncryptedBodyRequest,
        options: AxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'xApiClient' is not null or undefined
        assertParamExists(
          "getDetailPaySubscriptionInvoice",
          "xApiClient",
          xApiClient,
        );
        // verify required parameter 'xApiValidate' is not null or undefined
        assertParamExists(
          "getDetailPaySubscriptionInvoice",
          "xApiValidate",
          xApiValidate,
        );
        // verify required parameter 'xApiTime' is not null or undefined
        assertParamExists(
          "getDetailPaySubscriptionInvoice",
          "xApiTime",
          xApiTime,
        );
        // verify required parameter 'encryptedBodyRequest' is not null or undefined
        assertParamExists(
          "getDetailPaySubscriptionInvoice",
          "encryptedBodyRequest",
          encryptedBodyRequest,
        );
        const localVarPath = `/api/external/subscription/invoice/v1/get`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: "POST",
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        if (xApiClient != null) {
          localVarHeaderParameter["x-api-client"] = String(xApiClient);
        }

        if (xApiValidate != null) {
          localVarHeaderParameter["x-api-validate"] = String(xApiValidate);
        }

        if (xApiTime != null) {
          localVarHeaderParameter["x-api-time"] =
            typeof xApiTime === "string" ? xApiTime : JSON.stringify(xApiTime);
        }

        localVarHeaderParameter["Content-Type"] = "application/json";

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };
        localVarRequestOptions.data = serializeDataIfNeeded(
          encryptedBodyRequest,
          localVarRequestOptions,
          configuration,
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    };
  };

/**
 * ExternalPaySubscriptionInvoiceControllerApi - functional programming interface
 * @export
 */
export const ExternalPaySubscriptionInvoiceControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    ExternalPaySubscriptionInvoiceControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Lấy thông tin Invoice
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllPaySubscriptionInvoice(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseString>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllPaySubscriptionInvoice(
          xApiClient,
          xApiValidate,
          xApiTime,
          encryptedBodyRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy thông tin chi tiết Invoice
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDetailPaySubscriptionInvoice(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseString>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getDetailPaySubscriptionInvoice(
          xApiClient,
          xApiValidate,
          xApiTime,
          encryptedBodyRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * ExternalPaySubscriptionInvoiceControllerApi - factory interface
 * @export
 */
export const ExternalPaySubscriptionInvoiceControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp =
    ExternalPaySubscriptionInvoiceControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Lấy thông tin Invoice
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllPaySubscriptionInvoice(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseString> {
      return localVarFp
        .getAllPaySubscriptionInvoice(
          xApiClient,
          xApiValidate,
          xApiTime,
          encryptedBodyRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy thông tin chi tiết Invoice
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDetailPaySubscriptionInvoice(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseString> {
      return localVarFp
        .getDetailPaySubscriptionInvoice(
          xApiClient,
          xApiValidate,
          xApiTime,
          encryptedBodyRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getAllPaySubscriptionInvoice operation in ExternalPaySubscriptionInvoiceControllerApi.
 * @export
 * @interface ExternalPaySubscriptionInvoiceControllerApiGetAllPaySubscriptionInvoiceRequest
 */
export interface ExternalPaySubscriptionInvoiceControllerApiGetAllPaySubscriptionInvoiceRequest {
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionInvoiceControllerApiGetAllPaySubscriptionInvoice
   */
  readonly xApiClient: string;

  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionInvoiceControllerApiGetAllPaySubscriptionInvoice
   */
  readonly xApiValidate: string;

  /**
   *
   * @type {number}
   * @memberof ExternalPaySubscriptionInvoiceControllerApiGetAllPaySubscriptionInvoice
   */
  readonly xApiTime: number;

  /**
   *
   * @type {EncryptedBodyRequest}
   * @memberof ExternalPaySubscriptionInvoiceControllerApiGetAllPaySubscriptionInvoice
   */
  readonly encryptedBodyRequest: EncryptedBodyRequest;
}

/**
 * Request parameters for getDetailPaySubscriptionInvoice operation in ExternalPaySubscriptionInvoiceControllerApi.
 * @export
 * @interface ExternalPaySubscriptionInvoiceControllerApiGetDetailPaySubscriptionInvoiceRequest
 */
export interface ExternalPaySubscriptionInvoiceControllerApiGetDetailPaySubscriptionInvoiceRequest {
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionInvoiceControllerApiGetDetailPaySubscriptionInvoice
   */
  readonly xApiClient: string;

  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionInvoiceControllerApiGetDetailPaySubscriptionInvoice
   */
  readonly xApiValidate: string;

  /**
   *
   * @type {number}
   * @memberof ExternalPaySubscriptionInvoiceControllerApiGetDetailPaySubscriptionInvoice
   */
  readonly xApiTime: number;

  /**
   *
   * @type {EncryptedBodyRequest}
   * @memberof ExternalPaySubscriptionInvoiceControllerApiGetDetailPaySubscriptionInvoice
   */
  readonly encryptedBodyRequest: EncryptedBodyRequest;
}

/**
 * ExternalPaySubscriptionInvoiceControllerApi - object-oriented interface
 * @export
 * @class ExternalPaySubscriptionInvoiceControllerApi
 * @extends {BaseAPI}
 */
export class ExternalPaySubscriptionInvoiceControllerApi extends BaseAPI {
  /**
   *
   * @summary Lấy thông tin Invoice
   * @param {ExternalPaySubscriptionInvoiceControllerApiGetAllPaySubscriptionInvoiceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalPaySubscriptionInvoiceControllerApi
   */
  public getAllPaySubscriptionInvoice(
    requestParameters: ExternalPaySubscriptionInvoiceControllerApiGetAllPaySubscriptionInvoiceRequest,
    options?: AxiosRequestConfig,
  ) {
    return ExternalPaySubscriptionInvoiceControllerApiFp(this.configuration)
      .getAllPaySubscriptionInvoice(
        requestParameters.xApiClient,
        requestParameters.xApiValidate,
        requestParameters.xApiTime,
        requestParameters.encryptedBodyRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy thông tin chi tiết Invoice
   * @param {ExternalPaySubscriptionInvoiceControllerApiGetDetailPaySubscriptionInvoiceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalPaySubscriptionInvoiceControllerApi
   */
  public getDetailPaySubscriptionInvoice(
    requestParameters: ExternalPaySubscriptionInvoiceControllerApiGetDetailPaySubscriptionInvoiceRequest,
    options?: AxiosRequestConfig,
  ) {
    return ExternalPaySubscriptionInvoiceControllerApiFp(this.configuration)
      .getDetailPaySubscriptionInvoice(
        requestParameters.xApiClient,
        requestParameters.xApiValidate,
        requestParameters.xApiTime,
        requestParameters.encryptedBodyRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ExternalPaySubscriptionPlanControllerApi - axios parameter creator
 * @export
 */
export const ExternalPaySubscriptionPlanControllerApiAxiosParamCreator =
  function (configuration?: Configuration) {
    return {
      /**
       *
       * @summary Tạo mới plan
       * @param {string} xApiClient
       * @param {string} xApiValidate
       * @param {number} xApiTime
       * @param {EncryptedBodyRequest} encryptedBodyRequest
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      createPaySubscriptionPlan1: async (
        xApiClient: string,
        xApiValidate: string,
        xApiTime: number,
        encryptedBodyRequest: EncryptedBodyRequest,
        options: AxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'xApiClient' is not null or undefined
        assertParamExists(
          "createPaySubscriptionPlan1",
          "xApiClient",
          xApiClient,
        );
        // verify required parameter 'xApiValidate' is not null or undefined
        assertParamExists(
          "createPaySubscriptionPlan1",
          "xApiValidate",
          xApiValidate,
        );
        // verify required parameter 'xApiTime' is not null or undefined
        assertParamExists("createPaySubscriptionPlan1", "xApiTime", xApiTime);
        // verify required parameter 'encryptedBodyRequest' is not null or undefined
        assertParamExists(
          "createPaySubscriptionPlan1",
          "encryptedBodyRequest",
          encryptedBodyRequest,
        );
        const localVarPath = `/api/external/subscription/plan/v1/create`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: "POST",
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        if (xApiClient != null) {
          localVarHeaderParameter["x-api-client"] = String(xApiClient);
        }

        if (xApiValidate != null) {
          localVarHeaderParameter["x-api-validate"] = String(xApiValidate);
        }

        if (xApiTime != null) {
          localVarHeaderParameter["x-api-time"] =
            typeof xApiTime === "string" ? xApiTime : JSON.stringify(xApiTime);
        }

        localVarHeaderParameter["Content-Type"] = "application/json";

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };
        localVarRequestOptions.data = serializeDataIfNeeded(
          encryptedBodyRequest,
          localVarRequestOptions,
          configuration,
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
      /**
       *
       * @summary Lấy danh sách các Subscription
       * @param {string} xApiClient
       * @param {string} xApiValidate
       * @param {number} xApiTime
       * @param {EncryptedBodyRequest} encryptedBodyRequest
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      getAllPaySubscriptionPlan: async (
        xApiClient: string,
        xApiValidate: string,
        xApiTime: number,
        encryptedBodyRequest: EncryptedBodyRequest,
        options: AxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'xApiClient' is not null or undefined
        assertParamExists(
          "getAllPaySubscriptionPlan",
          "xApiClient",
          xApiClient,
        );
        // verify required parameter 'xApiValidate' is not null or undefined
        assertParamExists(
          "getAllPaySubscriptionPlan",
          "xApiValidate",
          xApiValidate,
        );
        // verify required parameter 'xApiTime' is not null or undefined
        assertParamExists("getAllPaySubscriptionPlan", "xApiTime", xApiTime);
        // verify required parameter 'encryptedBodyRequest' is not null or undefined
        assertParamExists(
          "getAllPaySubscriptionPlan",
          "encryptedBodyRequest",
          encryptedBodyRequest,
        );
        const localVarPath = `/api/external/subscription/plan/v1/getAll`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: "POST",
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        if (xApiClient != null) {
          localVarHeaderParameter["x-api-client"] = String(xApiClient);
        }

        if (xApiValidate != null) {
          localVarHeaderParameter["x-api-validate"] = String(xApiValidate);
        }

        if (xApiTime != null) {
          localVarHeaderParameter["x-api-time"] =
            typeof xApiTime === "string" ? xApiTime : JSON.stringify(xApiTime);
        }

        localVarHeaderParameter["Content-Type"] = "application/json";

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };
        localVarRequestOptions.data = serializeDataIfNeeded(
          encryptedBodyRequest,
          localVarRequestOptions,
          configuration,
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
      /**
       *
       * @summary Lấy thông tin Plan
       * @param {string} xApiClient
       * @param {string} xApiValidate
       * @param {number} xApiTime
       * @param {EncryptedBodyRequest} encryptedBodyRequest
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      getPaySubscriptionPlan: async (
        xApiClient: string,
        xApiValidate: string,
        xApiTime: number,
        encryptedBodyRequest: EncryptedBodyRequest,
        options: AxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'xApiClient' is not null or undefined
        assertParamExists("getPaySubscriptionPlan", "xApiClient", xApiClient);
        // verify required parameter 'xApiValidate' is not null or undefined
        assertParamExists(
          "getPaySubscriptionPlan",
          "xApiValidate",
          xApiValidate,
        );
        // verify required parameter 'xApiTime' is not null or undefined
        assertParamExists("getPaySubscriptionPlan", "xApiTime", xApiTime);
        // verify required parameter 'encryptedBodyRequest' is not null or undefined
        assertParamExists(
          "getPaySubscriptionPlan",
          "encryptedBodyRequest",
          encryptedBodyRequest,
        );
        const localVarPath = `/api/external/subscription/plan/v1/get`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: "POST",
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        if (xApiClient != null) {
          localVarHeaderParameter["x-api-client"] = String(xApiClient);
        }

        if (xApiValidate != null) {
          localVarHeaderParameter["x-api-validate"] = String(xApiValidate);
        }

        if (xApiTime != null) {
          localVarHeaderParameter["x-api-time"] =
            typeof xApiTime === "string" ? xApiTime : JSON.stringify(xApiTime);
        }

        localVarHeaderParameter["Content-Type"] = "application/json";

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };
        localVarRequestOptions.data = serializeDataIfNeeded(
          encryptedBodyRequest,
          localVarRequestOptions,
          configuration,
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
      /**
       *
       * @summary Lấy thông tin Plan
       * @param {string} xApiClient
       * @param {string} xApiValidate
       * @param {number} xApiTime
       * @param {EncryptedBodyRequest} encryptedBodyRequest
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      updateStatePaySubscriptionPlan: async (
        xApiClient: string,
        xApiValidate: string,
        xApiTime: number,
        encryptedBodyRequest: EncryptedBodyRequest,
        options: AxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'xApiClient' is not null or undefined
        assertParamExists(
          "updateStatePaySubscriptionPlan",
          "xApiClient",
          xApiClient,
        );
        // verify required parameter 'xApiValidate' is not null or undefined
        assertParamExists(
          "updateStatePaySubscriptionPlan",
          "xApiValidate",
          xApiValidate,
        );
        // verify required parameter 'xApiTime' is not null or undefined
        assertParamExists(
          "updateStatePaySubscriptionPlan",
          "xApiTime",
          xApiTime,
        );
        // verify required parameter 'encryptedBodyRequest' is not null or undefined
        assertParamExists(
          "updateStatePaySubscriptionPlan",
          "encryptedBodyRequest",
          encryptedBodyRequest,
        );
        const localVarPath = `/api/external/subscription/plan/v1/updateState`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: "POST",
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        if (xApiClient != null) {
          localVarHeaderParameter["x-api-client"] = String(xApiClient);
        }

        if (xApiValidate != null) {
          localVarHeaderParameter["x-api-validate"] = String(xApiValidate);
        }

        if (xApiTime != null) {
          localVarHeaderParameter["x-api-time"] =
            typeof xApiTime === "string" ? xApiTime : JSON.stringify(xApiTime);
        }

        localVarHeaderParameter["Content-Type"] = "application/json";

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };
        localVarRequestOptions.data = serializeDataIfNeeded(
          encryptedBodyRequest,
          localVarRequestOptions,
          configuration,
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    };
  };

/**
 * ExternalPaySubscriptionPlanControllerApi - functional programming interface
 * @export
 */
export const ExternalPaySubscriptionPlanControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    ExternalPaySubscriptionPlanControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Tạo mới plan
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createPaySubscriptionPlan1(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseString>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createPaySubscriptionPlan1(
          xApiClient,
          xApiValidate,
          xApiTime,
          encryptedBodyRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy danh sách các Subscription
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllPaySubscriptionPlan(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseString>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllPaySubscriptionPlan(
          xApiClient,
          xApiValidate,
          xApiTime,
          encryptedBodyRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy thông tin Plan
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPaySubscriptionPlan(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseString>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPaySubscriptionPlan(
          xApiClient,
          xApiValidate,
          xApiTime,
          encryptedBodyRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy thông tin Plan
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateStatePaySubscriptionPlan(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseString>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateStatePaySubscriptionPlan(
          xApiClient,
          xApiValidate,
          xApiTime,
          encryptedBodyRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * ExternalPaySubscriptionPlanControllerApi - factory interface
 * @export
 */
export const ExternalPaySubscriptionPlanControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ExternalPaySubscriptionPlanControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Tạo mới plan
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPaySubscriptionPlan1(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseString> {
      return localVarFp
        .createPaySubscriptionPlan1(
          xApiClient,
          xApiValidate,
          xApiTime,
          encryptedBodyRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy danh sách các Subscription
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllPaySubscriptionPlan(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseString> {
      return localVarFp
        .getAllPaySubscriptionPlan(
          xApiClient,
          xApiValidate,
          xApiTime,
          encryptedBodyRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy thông tin Plan
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaySubscriptionPlan(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseString> {
      return localVarFp
        .getPaySubscriptionPlan(
          xApiClient,
          xApiValidate,
          xApiTime,
          encryptedBodyRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy thông tin Plan
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStatePaySubscriptionPlan(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseString> {
      return localVarFp
        .updateStatePaySubscriptionPlan(
          xApiClient,
          xApiValidate,
          xApiTime,
          encryptedBodyRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createPaySubscriptionPlan1 operation in ExternalPaySubscriptionPlanControllerApi.
 * @export
 * @interface ExternalPaySubscriptionPlanControllerApiCreatePaySubscriptionPlan1Request
 */
export interface ExternalPaySubscriptionPlanControllerApiCreatePaySubscriptionPlan1Request {
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionPlanControllerApiCreatePaySubscriptionPlan1
   */
  readonly xApiClient: string;

  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionPlanControllerApiCreatePaySubscriptionPlan1
   */
  readonly xApiValidate: string;

  /**
   *
   * @type {number}
   * @memberof ExternalPaySubscriptionPlanControllerApiCreatePaySubscriptionPlan1
   */
  readonly xApiTime: number;

  /**
   *
   * @type {EncryptedBodyRequest}
   * @memberof ExternalPaySubscriptionPlanControllerApiCreatePaySubscriptionPlan1
   */
  readonly encryptedBodyRequest: EncryptedBodyRequest;
}

/**
 * Request parameters for getAllPaySubscriptionPlan operation in ExternalPaySubscriptionPlanControllerApi.
 * @export
 * @interface ExternalPaySubscriptionPlanControllerApiGetAllPaySubscriptionPlanRequest
 */
export interface ExternalPaySubscriptionPlanControllerApiGetAllPaySubscriptionPlanRequest {
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionPlanControllerApiGetAllPaySubscriptionPlan
   */
  readonly xApiClient: string;

  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionPlanControllerApiGetAllPaySubscriptionPlan
   */
  readonly xApiValidate: string;

  /**
   *
   * @type {number}
   * @memberof ExternalPaySubscriptionPlanControllerApiGetAllPaySubscriptionPlan
   */
  readonly xApiTime: number;

  /**
   *
   * @type {EncryptedBodyRequest}
   * @memberof ExternalPaySubscriptionPlanControllerApiGetAllPaySubscriptionPlan
   */
  readonly encryptedBodyRequest: EncryptedBodyRequest;
}

/**
 * Request parameters for getPaySubscriptionPlan operation in ExternalPaySubscriptionPlanControllerApi.
 * @export
 * @interface ExternalPaySubscriptionPlanControllerApiGetPaySubscriptionPlanRequest
 */
export interface ExternalPaySubscriptionPlanControllerApiGetPaySubscriptionPlanRequest {
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionPlanControllerApiGetPaySubscriptionPlan
   */
  readonly xApiClient: string;

  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionPlanControllerApiGetPaySubscriptionPlan
   */
  readonly xApiValidate: string;

  /**
   *
   * @type {number}
   * @memberof ExternalPaySubscriptionPlanControllerApiGetPaySubscriptionPlan
   */
  readonly xApiTime: number;

  /**
   *
   * @type {EncryptedBodyRequest}
   * @memberof ExternalPaySubscriptionPlanControllerApiGetPaySubscriptionPlan
   */
  readonly encryptedBodyRequest: EncryptedBodyRequest;
}

/**
 * Request parameters for updateStatePaySubscriptionPlan operation in ExternalPaySubscriptionPlanControllerApi.
 * @export
 * @interface ExternalPaySubscriptionPlanControllerApiUpdateStatePaySubscriptionPlanRequest
 */
export interface ExternalPaySubscriptionPlanControllerApiUpdateStatePaySubscriptionPlanRequest {
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionPlanControllerApiUpdateStatePaySubscriptionPlan
   */
  readonly xApiClient: string;

  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionPlanControllerApiUpdateStatePaySubscriptionPlan
   */
  readonly xApiValidate: string;

  /**
   *
   * @type {number}
   * @memberof ExternalPaySubscriptionPlanControllerApiUpdateStatePaySubscriptionPlan
   */
  readonly xApiTime: number;

  /**
   *
   * @type {EncryptedBodyRequest}
   * @memberof ExternalPaySubscriptionPlanControllerApiUpdateStatePaySubscriptionPlan
   */
  readonly encryptedBodyRequest: EncryptedBodyRequest;
}

/**
 * ExternalPaySubscriptionPlanControllerApi - object-oriented interface
 * @export
 * @class ExternalPaySubscriptionPlanControllerApi
 * @extends {BaseAPI}
 */
export class ExternalPaySubscriptionPlanControllerApi extends BaseAPI {
  /**
   *
   * @summary Tạo mới plan
   * @param {ExternalPaySubscriptionPlanControllerApiCreatePaySubscriptionPlan1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalPaySubscriptionPlanControllerApi
   */
  public createPaySubscriptionPlan1(
    requestParameters: ExternalPaySubscriptionPlanControllerApiCreatePaySubscriptionPlan1Request,
    options?: AxiosRequestConfig,
  ) {
    return ExternalPaySubscriptionPlanControllerApiFp(this.configuration)
      .createPaySubscriptionPlan1(
        requestParameters.xApiClient,
        requestParameters.xApiValidate,
        requestParameters.xApiTime,
        requestParameters.encryptedBodyRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy danh sách các Subscription
   * @param {ExternalPaySubscriptionPlanControllerApiGetAllPaySubscriptionPlanRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalPaySubscriptionPlanControllerApi
   */
  public getAllPaySubscriptionPlan(
    requestParameters: ExternalPaySubscriptionPlanControllerApiGetAllPaySubscriptionPlanRequest,
    options?: AxiosRequestConfig,
  ) {
    return ExternalPaySubscriptionPlanControllerApiFp(this.configuration)
      .getAllPaySubscriptionPlan(
        requestParameters.xApiClient,
        requestParameters.xApiValidate,
        requestParameters.xApiTime,
        requestParameters.encryptedBodyRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy thông tin Plan
   * @param {ExternalPaySubscriptionPlanControllerApiGetPaySubscriptionPlanRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalPaySubscriptionPlanControllerApi
   */
  public getPaySubscriptionPlan(
    requestParameters: ExternalPaySubscriptionPlanControllerApiGetPaySubscriptionPlanRequest,
    options?: AxiosRequestConfig,
  ) {
    return ExternalPaySubscriptionPlanControllerApiFp(this.configuration)
      .getPaySubscriptionPlan(
        requestParameters.xApiClient,
        requestParameters.xApiValidate,
        requestParameters.xApiTime,
        requestParameters.encryptedBodyRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy thông tin Plan
   * @param {ExternalPaySubscriptionPlanControllerApiUpdateStatePaySubscriptionPlanRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalPaySubscriptionPlanControllerApi
   */
  public updateStatePaySubscriptionPlan(
    requestParameters: ExternalPaySubscriptionPlanControllerApiUpdateStatePaySubscriptionPlanRequest,
    options?: AxiosRequestConfig,
  ) {
    return ExternalPaySubscriptionPlanControllerApiFp(this.configuration)
      .updateStatePaySubscriptionPlan(
        requestParameters.xApiClient,
        requestParameters.xApiValidate,
        requestParameters.xApiTime,
        requestParameters.encryptedBodyRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ExternalPaySubscriptionSimpleControllerApi - axios parameter creator
 * @export
 */
export const ExternalPaySubscriptionSimpleControllerApiAxiosParamCreator =
  function (configuration?: Configuration) {
    return {
      /**
       *
       * @summary Tạo subscription
       * @param {string} xApiClient
       * @param {string} xApiKey
       * @param {ExternalCreatePaySubscriptionRequest} externalCreatePaySubscriptionRequest
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      createSubscription: async (
        xApiClient: string,
        xApiKey: string,
        externalCreatePaySubscriptionRequest: ExternalCreatePaySubscriptionRequest,
        options: AxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'xApiClient' is not null or undefined
        assertParamExists("createSubscription", "xApiClient", xApiClient);
        // verify required parameter 'xApiKey' is not null or undefined
        assertParamExists("createSubscription", "xApiKey", xApiKey);
        // verify required parameter 'externalCreatePaySubscriptionRequest' is not null or undefined
        assertParamExists(
          "createSubscription",
          "externalCreatePaySubscriptionRequest",
          externalCreatePaySubscriptionRequest,
        );
        const localVarPath = `/api/external/subscription/simple/v1/create`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: "POST",
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        if (xApiClient != null) {
          localVarHeaderParameter["x-api-client"] = String(xApiClient);
        }

        if (xApiKey != null) {
          localVarHeaderParameter["x-api-key"] = String(xApiKey);
        }

        localVarHeaderParameter["Content-Type"] = "application/json";

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };
        localVarRequestOptions.data = serializeDataIfNeeded(
          externalCreatePaySubscriptionRequest,
          localVarRequestOptions,
          configuration,
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
      /**
       *
       * @summary Tạo Plan
       * @param {string} xApiClient
       * @param {string} xApiKey
       * @param {ExternalCreatePaySubscriptionPlanRequest} externalCreatePaySubscriptionPlanRequest
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      createSubscriptionPlan: async (
        xApiClient: string,
        xApiKey: string,
        externalCreatePaySubscriptionPlanRequest: ExternalCreatePaySubscriptionPlanRequest,
        options: AxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'xApiClient' is not null or undefined
        assertParamExists("createSubscriptionPlan", "xApiClient", xApiClient);
        // verify required parameter 'xApiKey' is not null or undefined
        assertParamExists("createSubscriptionPlan", "xApiKey", xApiKey);
        // verify required parameter 'externalCreatePaySubscriptionPlanRequest' is not null or undefined
        assertParamExists(
          "createSubscriptionPlan",
          "externalCreatePaySubscriptionPlanRequest",
          externalCreatePaySubscriptionPlanRequest,
        );
        const localVarPath = `/api/external/subscription/simple/plan/v1/create`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: "POST",
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        if (xApiClient != null) {
          localVarHeaderParameter["x-api-client"] = String(xApiClient);
        }

        if (xApiKey != null) {
          localVarHeaderParameter["x-api-key"] = String(xApiKey);
        }

        localVarHeaderParameter["Content-Type"] = "application/json";

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };
        localVarRequestOptions.data = serializeDataIfNeeded(
          externalCreatePaySubscriptionPlanRequest,
          localVarRequestOptions,
          configuration,
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
      /**
       *
       * @summary Lấy danh sách invoice
       * @param {string} xApiClient
       * @param {string} xApiKey
       * @param {ExternalGetAllPaySubscriptionInvoiceRequest} externalGetAllPaySubscriptionInvoiceRequest
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      getAllSubscriptionInvoice: async (
        xApiClient: string,
        xApiKey: string,
        externalGetAllPaySubscriptionInvoiceRequest: ExternalGetAllPaySubscriptionInvoiceRequest,
        options: AxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'xApiClient' is not null or undefined
        assertParamExists(
          "getAllSubscriptionInvoice",
          "xApiClient",
          xApiClient,
        );
        // verify required parameter 'xApiKey' is not null or undefined
        assertParamExists("getAllSubscriptionInvoice", "xApiKey", xApiKey);
        // verify required parameter 'externalGetAllPaySubscriptionInvoiceRequest' is not null or undefined
        assertParamExists(
          "getAllSubscriptionInvoice",
          "externalGetAllPaySubscriptionInvoiceRequest",
          externalGetAllPaySubscriptionInvoiceRequest,
        );
        const localVarPath = `/api/external/subscription/simple/invoice/v1/getAll`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: "POST",
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        if (xApiClient != null) {
          localVarHeaderParameter["x-api-client"] = String(xApiClient);
        }

        if (xApiKey != null) {
          localVarHeaderParameter["x-api-key"] = String(xApiKey);
        }

        localVarHeaderParameter["Content-Type"] = "application/json";

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };
        localVarRequestOptions.data = serializeDataIfNeeded(
          externalGetAllPaySubscriptionInvoiceRequest,
          localVarRequestOptions,
          configuration,
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
      /**
       *
       * @summary Lấy thông tin Subscription
       * @param {string} xApiClient
       * @param {string} xApiKey
       * @param {ExternalGetPaySubscriptionRequest} externalGetPaySubscriptionRequest
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      getPaySubscription1: async (
        xApiClient: string,
        xApiKey: string,
        externalGetPaySubscriptionRequest: ExternalGetPaySubscriptionRequest,
        options: AxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'xApiClient' is not null or undefined
        assertParamExists("getPaySubscription1", "xApiClient", xApiClient);
        // verify required parameter 'xApiKey' is not null or undefined
        assertParamExists("getPaySubscription1", "xApiKey", xApiKey);
        // verify required parameter 'externalGetPaySubscriptionRequest' is not null or undefined
        assertParamExists(
          "getPaySubscription1",
          "externalGetPaySubscriptionRequest",
          externalGetPaySubscriptionRequest,
        );
        const localVarPath = `/api/external/subscription/simple/v1/get`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: "POST",
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        if (xApiClient != null) {
          localVarHeaderParameter["x-api-client"] = String(xApiClient);
        }

        if (xApiKey != null) {
          localVarHeaderParameter["x-api-key"] = String(xApiKey);
        }

        localVarHeaderParameter["Content-Type"] = "application/json";

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };
        localVarRequestOptions.data = serializeDataIfNeeded(
          externalGetPaySubscriptionRequest,
          localVarRequestOptions,
          configuration,
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
      /**
       *
       * @summary Lấy chi tiet invoice
       * @param {string} xApiClient
       * @param {string} xApiKey
       * @param {ExternalGetPaySubscriptionInvoiceRequest} externalGetPaySubscriptionInvoiceRequest
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      getSubscriptionInvoice: async (
        xApiClient: string,
        xApiKey: string,
        externalGetPaySubscriptionInvoiceRequest: ExternalGetPaySubscriptionInvoiceRequest,
        options: AxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'xApiClient' is not null or undefined
        assertParamExists("getSubscriptionInvoice", "xApiClient", xApiClient);
        // verify required parameter 'xApiKey' is not null or undefined
        assertParamExists("getSubscriptionInvoice", "xApiKey", xApiKey);
        // verify required parameter 'externalGetPaySubscriptionInvoiceRequest' is not null or undefined
        assertParamExists(
          "getSubscriptionInvoice",
          "externalGetPaySubscriptionInvoiceRequest",
          externalGetPaySubscriptionInvoiceRequest,
        );
        const localVarPath = `/api/external/subscription/simple/invoice/v1/get`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: "POST",
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        if (xApiClient != null) {
          localVarHeaderParameter["x-api-client"] = String(xApiClient);
        }

        if (xApiKey != null) {
          localVarHeaderParameter["x-api-key"] = String(xApiKey);
        }

        localVarHeaderParameter["Content-Type"] = "application/json";

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };
        localVarRequestOptions.data = serializeDataIfNeeded(
          externalGetPaySubscriptionInvoiceRequest,
          localVarRequestOptions,
          configuration,
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
      /**
       *
       * @summary Lấy thông tin Plan
       * @param {string} xApiClient
       * @param {string} xApiKey
       * @param {ExternalGetPaySubscriptionPlanRequest} externalGetPaySubscriptionPlanRequest
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      getSubscriptionPlan: async (
        xApiClient: string,
        xApiKey: string,
        externalGetPaySubscriptionPlanRequest: ExternalGetPaySubscriptionPlanRequest,
        options: AxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'xApiClient' is not null or undefined
        assertParamExists("getSubscriptionPlan", "xApiClient", xApiClient);
        // verify required parameter 'xApiKey' is not null or undefined
        assertParamExists("getSubscriptionPlan", "xApiKey", xApiKey);
        // verify required parameter 'externalGetPaySubscriptionPlanRequest' is not null or undefined
        assertParamExists(
          "getSubscriptionPlan",
          "externalGetPaySubscriptionPlanRequest",
          externalGetPaySubscriptionPlanRequest,
        );
        const localVarPath = `/api/external/subscription/simple/plan/v1/get`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: "POST",
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        if (xApiClient != null) {
          localVarHeaderParameter["x-api-client"] = String(xApiClient);
        }

        if (xApiKey != null) {
          localVarHeaderParameter["x-api-key"] = String(xApiKey);
        }

        localVarHeaderParameter["Content-Type"] = "application/json";

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };
        localVarRequestOptions.data = serializeDataIfNeeded(
          externalGetPaySubscriptionPlanRequest,
          localVarRequestOptions,
          configuration,
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
      /**
       *
       * @summary Cập nhật trạng thái Subscription
       * @param {string} xApiClient
       * @param {string} xApiKey
       * @param {ExternalUpdateStatePaySubscriptionRequest} externalUpdateStatePaySubscriptionRequest
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      updateStateSubscription: async (
        xApiClient: string,
        xApiKey: string,
        externalUpdateStatePaySubscriptionRequest: ExternalUpdateStatePaySubscriptionRequest,
        options: AxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'xApiClient' is not null or undefined
        assertParamExists("updateStateSubscription", "xApiClient", xApiClient);
        // verify required parameter 'xApiKey' is not null or undefined
        assertParamExists("updateStateSubscription", "xApiKey", xApiKey);
        // verify required parameter 'externalUpdateStatePaySubscriptionRequest' is not null or undefined
        assertParamExists(
          "updateStateSubscription",
          "externalUpdateStatePaySubscriptionRequest",
          externalUpdateStatePaySubscriptionRequest,
        );
        const localVarPath = `/api/external/subscription/simple/v1/updateState`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: "POST",
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        if (xApiClient != null) {
          localVarHeaderParameter["x-api-client"] = String(xApiClient);
        }

        if (xApiKey != null) {
          localVarHeaderParameter["x-api-key"] = String(xApiKey);
        }

        localVarHeaderParameter["Content-Type"] = "application/json";

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };
        localVarRequestOptions.data = serializeDataIfNeeded(
          externalUpdateStatePaySubscriptionRequest,
          localVarRequestOptions,
          configuration,
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
      /**
       *
       * @summary Lấy thông tin Plan
       * @param {string} xApiClient
       * @param {string} xApiKey
       * @param {ExternalUpdateStatePaySubscriptionPlanRequest} externalUpdateStatePaySubscriptionPlanRequest
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      updateStateSubscriptionPlan: async (
        xApiClient: string,
        xApiKey: string,
        externalUpdateStatePaySubscriptionPlanRequest: ExternalUpdateStatePaySubscriptionPlanRequest,
        options: AxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'xApiClient' is not null or undefined
        assertParamExists(
          "updateStateSubscriptionPlan",
          "xApiClient",
          xApiClient,
        );
        // verify required parameter 'xApiKey' is not null or undefined
        assertParamExists("updateStateSubscriptionPlan", "xApiKey", xApiKey);
        // verify required parameter 'externalUpdateStatePaySubscriptionPlanRequest' is not null or undefined
        assertParamExists(
          "updateStateSubscriptionPlan",
          "externalUpdateStatePaySubscriptionPlanRequest",
          externalUpdateStatePaySubscriptionPlanRequest,
        );
        const localVarPath = `/api/external/subscription/simple/plan/v1/updateState`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: "POST",
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        if (xApiClient != null) {
          localVarHeaderParameter["x-api-client"] = String(xApiClient);
        }

        if (xApiKey != null) {
          localVarHeaderParameter["x-api-key"] = String(xApiKey);
        }

        localVarHeaderParameter["Content-Type"] = "application/json";

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };
        localVarRequestOptions.data = serializeDataIfNeeded(
          externalUpdateStatePaySubscriptionPlanRequest,
          localVarRequestOptions,
          configuration,
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    };
  };

/**
 * ExternalPaySubscriptionSimpleControllerApi - functional programming interface
 * @export
 */
export const ExternalPaySubscriptionSimpleControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    ExternalPaySubscriptionSimpleControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Tạo subscription
     * @param {string} xApiClient
     * @param {string} xApiKey
     * @param {ExternalCreatePaySubscriptionRequest} externalCreatePaySubscriptionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createSubscription(
      xApiClient: string,
      xApiKey: string,
      externalCreatePaySubscriptionRequest: ExternalCreatePaySubscriptionRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseExternalPaySubscriptionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createSubscription(
          xApiClient,
          xApiKey,
          externalCreatePaySubscriptionRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Tạo Plan
     * @param {string} xApiClient
     * @param {string} xApiKey
     * @param {ExternalCreatePaySubscriptionPlanRequest} externalCreatePaySubscriptionPlanRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createSubscriptionPlan(
      xApiClient: string,
      xApiKey: string,
      externalCreatePaySubscriptionPlanRequest: ExternalCreatePaySubscriptionPlanRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseExternalPaySubscriptionPlanResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createSubscriptionPlan(
          xApiClient,
          xApiKey,
          externalCreatePaySubscriptionPlanRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy danh sách invoice
     * @param {string} xApiClient
     * @param {string} xApiKey
     * @param {ExternalGetAllPaySubscriptionInvoiceRequest} externalGetAllPaySubscriptionInvoiceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllSubscriptionInvoice(
      xApiClient: string,
      xApiKey: string,
      externalGetAllPaySubscriptionInvoiceRequest: ExternalGetAllPaySubscriptionInvoiceRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseExternalPaySubscriptionInvoiceResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllSubscriptionInvoice(
          xApiClient,
          xApiKey,
          externalGetAllPaySubscriptionInvoiceRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy thông tin Subscription
     * @param {string} xApiClient
     * @param {string} xApiKey
     * @param {ExternalGetPaySubscriptionRequest} externalGetPaySubscriptionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPaySubscription1(
      xApiClient: string,
      xApiKey: string,
      externalGetPaySubscriptionRequest: ExternalGetPaySubscriptionRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseExternalPaySubscriptionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPaySubscription1(
          xApiClient,
          xApiKey,
          externalGetPaySubscriptionRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy chi tiet invoice
     * @param {string} xApiClient
     * @param {string} xApiKey
     * @param {ExternalGetPaySubscriptionInvoiceRequest} externalGetPaySubscriptionInvoiceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSubscriptionInvoice(
      xApiClient: string,
      xApiKey: string,
      externalGetPaySubscriptionInvoiceRequest: ExternalGetPaySubscriptionInvoiceRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseExternalPaySubscriptionInvoiceResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSubscriptionInvoice(
          xApiClient,
          xApiKey,
          externalGetPaySubscriptionInvoiceRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy thông tin Plan
     * @param {string} xApiClient
     * @param {string} xApiKey
     * @param {ExternalGetPaySubscriptionPlanRequest} externalGetPaySubscriptionPlanRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSubscriptionPlan(
      xApiClient: string,
      xApiKey: string,
      externalGetPaySubscriptionPlanRequest: ExternalGetPaySubscriptionPlanRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseExternalPaySubscriptionPlanResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSubscriptionPlan(
          xApiClient,
          xApiKey,
          externalGetPaySubscriptionPlanRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Cập nhật trạng thái Subscription
     * @param {string} xApiClient
     * @param {string} xApiKey
     * @param {ExternalUpdateStatePaySubscriptionRequest} externalUpdateStatePaySubscriptionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateStateSubscription(
      xApiClient: string,
      xApiKey: string,
      externalUpdateStatePaySubscriptionRequest: ExternalUpdateStatePaySubscriptionRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseExternalPaySubscriptionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateStateSubscription(
          xApiClient,
          xApiKey,
          externalUpdateStatePaySubscriptionRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy thông tin Plan
     * @param {string} xApiClient
     * @param {string} xApiKey
     * @param {ExternalUpdateStatePaySubscriptionPlanRequest} externalUpdateStatePaySubscriptionPlanRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateStateSubscriptionPlan(
      xApiClient: string,
      xApiKey: string,
      externalUpdateStatePaySubscriptionPlanRequest: ExternalUpdateStatePaySubscriptionPlanRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseExternalPaySubscriptionPlanResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateStateSubscriptionPlan(
          xApiClient,
          xApiKey,
          externalUpdateStatePaySubscriptionPlanRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * ExternalPaySubscriptionSimpleControllerApi - factory interface
 * @export
 */
export const ExternalPaySubscriptionSimpleControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp =
    ExternalPaySubscriptionSimpleControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Tạo subscription
     * @param {string} xApiClient
     * @param {string} xApiKey
     * @param {ExternalCreatePaySubscriptionRequest} externalCreatePaySubscriptionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSubscription(
      xApiClient: string,
      xApiKey: string,
      externalCreatePaySubscriptionRequest: ExternalCreatePaySubscriptionRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseExternalPaySubscriptionResponse> {
      return localVarFp
        .createSubscription(
          xApiClient,
          xApiKey,
          externalCreatePaySubscriptionRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Tạo Plan
     * @param {string} xApiClient
     * @param {string} xApiKey
     * @param {ExternalCreatePaySubscriptionPlanRequest} externalCreatePaySubscriptionPlanRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSubscriptionPlan(
      xApiClient: string,
      xApiKey: string,
      externalCreatePaySubscriptionPlanRequest: ExternalCreatePaySubscriptionPlanRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseExternalPaySubscriptionPlanResponse> {
      return localVarFp
        .createSubscriptionPlan(
          xApiClient,
          xApiKey,
          externalCreatePaySubscriptionPlanRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy danh sách invoice
     * @param {string} xApiClient
     * @param {string} xApiKey
     * @param {ExternalGetAllPaySubscriptionInvoiceRequest} externalGetAllPaySubscriptionInvoiceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllSubscriptionInvoice(
      xApiClient: string,
      xApiKey: string,
      externalGetAllPaySubscriptionInvoiceRequest: ExternalGetAllPaySubscriptionInvoiceRequest,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseExternalPaySubscriptionInvoiceResponse> {
      return localVarFp
        .getAllSubscriptionInvoice(
          xApiClient,
          xApiKey,
          externalGetAllPaySubscriptionInvoiceRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy thông tin Subscription
     * @param {string} xApiClient
     * @param {string} xApiKey
     * @param {ExternalGetPaySubscriptionRequest} externalGetPaySubscriptionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaySubscription1(
      xApiClient: string,
      xApiKey: string,
      externalGetPaySubscriptionRequest: ExternalGetPaySubscriptionRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseExternalPaySubscriptionResponse> {
      return localVarFp
        .getPaySubscription1(
          xApiClient,
          xApiKey,
          externalGetPaySubscriptionRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy chi tiet invoice
     * @param {string} xApiClient
     * @param {string} xApiKey
     * @param {ExternalGetPaySubscriptionInvoiceRequest} externalGetPaySubscriptionInvoiceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscriptionInvoice(
      xApiClient: string,
      xApiKey: string,
      externalGetPaySubscriptionInvoiceRequest: ExternalGetPaySubscriptionInvoiceRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseExternalPaySubscriptionInvoiceResponse> {
      return localVarFp
        .getSubscriptionInvoice(
          xApiClient,
          xApiKey,
          externalGetPaySubscriptionInvoiceRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy thông tin Plan
     * @param {string} xApiClient
     * @param {string} xApiKey
     * @param {ExternalGetPaySubscriptionPlanRequest} externalGetPaySubscriptionPlanRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscriptionPlan(
      xApiClient: string,
      xApiKey: string,
      externalGetPaySubscriptionPlanRequest: ExternalGetPaySubscriptionPlanRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseExternalPaySubscriptionPlanResponse> {
      return localVarFp
        .getSubscriptionPlan(
          xApiClient,
          xApiKey,
          externalGetPaySubscriptionPlanRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Cập nhật trạng thái Subscription
     * @param {string} xApiClient
     * @param {string} xApiKey
     * @param {ExternalUpdateStatePaySubscriptionRequest} externalUpdateStatePaySubscriptionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStateSubscription(
      xApiClient: string,
      xApiKey: string,
      externalUpdateStatePaySubscriptionRequest: ExternalUpdateStatePaySubscriptionRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseExternalPaySubscriptionResponse> {
      return localVarFp
        .updateStateSubscription(
          xApiClient,
          xApiKey,
          externalUpdateStatePaySubscriptionRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy thông tin Plan
     * @param {string} xApiClient
     * @param {string} xApiKey
     * @param {ExternalUpdateStatePaySubscriptionPlanRequest} externalUpdateStatePaySubscriptionPlanRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStateSubscriptionPlan(
      xApiClient: string,
      xApiKey: string,
      externalUpdateStatePaySubscriptionPlanRequest: ExternalUpdateStatePaySubscriptionPlanRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseExternalPaySubscriptionPlanResponse> {
      return localVarFp
        .updateStateSubscriptionPlan(
          xApiClient,
          xApiKey,
          externalUpdateStatePaySubscriptionPlanRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createSubscription operation in ExternalPaySubscriptionSimpleControllerApi.
 * @export
 * @interface ExternalPaySubscriptionSimpleControllerApiCreateSubscriptionRequest
 */
export interface ExternalPaySubscriptionSimpleControllerApiCreateSubscriptionRequest {
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionSimpleControllerApiCreateSubscription
   */
  readonly xApiClient: string;

  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionSimpleControllerApiCreateSubscription
   */
  readonly xApiKey: string;

  /**
   *
   * @type {ExternalCreatePaySubscriptionRequest}
   * @memberof ExternalPaySubscriptionSimpleControllerApiCreateSubscription
   */
  readonly externalCreatePaySubscriptionRequest: ExternalCreatePaySubscriptionRequest;
}

/**
 * Request parameters for createSubscriptionPlan operation in ExternalPaySubscriptionSimpleControllerApi.
 * @export
 * @interface ExternalPaySubscriptionSimpleControllerApiCreateSubscriptionPlanRequest
 */
export interface ExternalPaySubscriptionSimpleControllerApiCreateSubscriptionPlanRequest {
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionSimpleControllerApiCreateSubscriptionPlan
   */
  readonly xApiClient: string;

  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionSimpleControllerApiCreateSubscriptionPlan
   */
  readonly xApiKey: string;

  /**
   *
   * @type {ExternalCreatePaySubscriptionPlanRequest}
   * @memberof ExternalPaySubscriptionSimpleControllerApiCreateSubscriptionPlan
   */
  readonly externalCreatePaySubscriptionPlanRequest: ExternalCreatePaySubscriptionPlanRequest;
}

/**
 * Request parameters for getAllSubscriptionInvoice operation in ExternalPaySubscriptionSimpleControllerApi.
 * @export
 * @interface ExternalPaySubscriptionSimpleControllerApiGetAllSubscriptionInvoiceRequest
 */
export interface ExternalPaySubscriptionSimpleControllerApiGetAllSubscriptionInvoiceRequest {
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionSimpleControllerApiGetAllSubscriptionInvoice
   */
  readonly xApiClient: string;

  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionSimpleControllerApiGetAllSubscriptionInvoice
   */
  readonly xApiKey: string;

  /**
   *
   * @type {ExternalGetAllPaySubscriptionInvoiceRequest}
   * @memberof ExternalPaySubscriptionSimpleControllerApiGetAllSubscriptionInvoice
   */
  readonly externalGetAllPaySubscriptionInvoiceRequest: ExternalGetAllPaySubscriptionInvoiceRequest;
}

/**
 * Request parameters for getPaySubscription1 operation in ExternalPaySubscriptionSimpleControllerApi.
 * @export
 * @interface ExternalPaySubscriptionSimpleControllerApiGetPaySubscription1Request
 */
export interface ExternalPaySubscriptionSimpleControllerApiGetPaySubscription1Request {
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionSimpleControllerApiGetPaySubscription1
   */
  readonly xApiClient: string;

  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionSimpleControllerApiGetPaySubscription1
   */
  readonly xApiKey: string;

  /**
   *
   * @type {ExternalGetPaySubscriptionRequest}
   * @memberof ExternalPaySubscriptionSimpleControllerApiGetPaySubscription1
   */
  readonly externalGetPaySubscriptionRequest: ExternalGetPaySubscriptionRequest;
}

/**
 * Request parameters for getSubscriptionInvoice operation in ExternalPaySubscriptionSimpleControllerApi.
 * @export
 * @interface ExternalPaySubscriptionSimpleControllerApiGetSubscriptionInvoiceRequest
 */
export interface ExternalPaySubscriptionSimpleControllerApiGetSubscriptionInvoiceRequest {
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionSimpleControllerApiGetSubscriptionInvoice
   */
  readonly xApiClient: string;

  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionSimpleControllerApiGetSubscriptionInvoice
   */
  readonly xApiKey: string;

  /**
   *
   * @type {ExternalGetPaySubscriptionInvoiceRequest}
   * @memberof ExternalPaySubscriptionSimpleControllerApiGetSubscriptionInvoice
   */
  readonly externalGetPaySubscriptionInvoiceRequest: ExternalGetPaySubscriptionInvoiceRequest;
}

/**
 * Request parameters for getSubscriptionPlan operation in ExternalPaySubscriptionSimpleControllerApi.
 * @export
 * @interface ExternalPaySubscriptionSimpleControllerApiGetSubscriptionPlanRequest
 */
export interface ExternalPaySubscriptionSimpleControllerApiGetSubscriptionPlanRequest {
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionSimpleControllerApiGetSubscriptionPlan
   */
  readonly xApiClient: string;

  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionSimpleControllerApiGetSubscriptionPlan
   */
  readonly xApiKey: string;

  /**
   *
   * @type {ExternalGetPaySubscriptionPlanRequest}
   * @memberof ExternalPaySubscriptionSimpleControllerApiGetSubscriptionPlan
   */
  readonly externalGetPaySubscriptionPlanRequest: ExternalGetPaySubscriptionPlanRequest;
}

/**
 * Request parameters for updateStateSubscription operation in ExternalPaySubscriptionSimpleControllerApi.
 * @export
 * @interface ExternalPaySubscriptionSimpleControllerApiUpdateStateSubscriptionRequest
 */
export interface ExternalPaySubscriptionSimpleControllerApiUpdateStateSubscriptionRequest {
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionSimpleControllerApiUpdateStateSubscription
   */
  readonly xApiClient: string;

  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionSimpleControllerApiUpdateStateSubscription
   */
  readonly xApiKey: string;

  /**
   *
   * @type {ExternalUpdateStatePaySubscriptionRequest}
   * @memberof ExternalPaySubscriptionSimpleControllerApiUpdateStateSubscription
   */
  readonly externalUpdateStatePaySubscriptionRequest: ExternalUpdateStatePaySubscriptionRequest;
}

/**
 * Request parameters for updateStateSubscriptionPlan operation in ExternalPaySubscriptionSimpleControllerApi.
 * @export
 * @interface ExternalPaySubscriptionSimpleControllerApiUpdateStateSubscriptionPlanRequest
 */
export interface ExternalPaySubscriptionSimpleControllerApiUpdateStateSubscriptionPlanRequest {
  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionSimpleControllerApiUpdateStateSubscriptionPlan
   */
  readonly xApiClient: string;

  /**
   *
   * @type {string}
   * @memberof ExternalPaySubscriptionSimpleControllerApiUpdateStateSubscriptionPlan
   */
  readonly xApiKey: string;

  /**
   *
   * @type {ExternalUpdateStatePaySubscriptionPlanRequest}
   * @memberof ExternalPaySubscriptionSimpleControllerApiUpdateStateSubscriptionPlan
   */
  readonly externalUpdateStatePaySubscriptionPlanRequest: ExternalUpdateStatePaySubscriptionPlanRequest;
}

/**
 * ExternalPaySubscriptionSimpleControllerApi - object-oriented interface
 * @export
 * @class ExternalPaySubscriptionSimpleControllerApi
 * @extends {BaseAPI}
 */
export class ExternalPaySubscriptionSimpleControllerApi extends BaseAPI {
  /**
   *
   * @summary Tạo subscription
   * @param {ExternalPaySubscriptionSimpleControllerApiCreateSubscriptionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalPaySubscriptionSimpleControllerApi
   */
  public createSubscription(
    requestParameters: ExternalPaySubscriptionSimpleControllerApiCreateSubscriptionRequest,
    options?: AxiosRequestConfig,
  ) {
    return ExternalPaySubscriptionSimpleControllerApiFp(this.configuration)
      .createSubscription(
        requestParameters.xApiClient,
        requestParameters.xApiKey,
        requestParameters.externalCreatePaySubscriptionRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Tạo Plan
   * @param {ExternalPaySubscriptionSimpleControllerApiCreateSubscriptionPlanRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalPaySubscriptionSimpleControllerApi
   */
  public createSubscriptionPlan(
    requestParameters: ExternalPaySubscriptionSimpleControllerApiCreateSubscriptionPlanRequest,
    options?: AxiosRequestConfig,
  ) {
    return ExternalPaySubscriptionSimpleControllerApiFp(this.configuration)
      .createSubscriptionPlan(
        requestParameters.xApiClient,
        requestParameters.xApiKey,
        requestParameters.externalCreatePaySubscriptionPlanRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy danh sách invoice
   * @param {ExternalPaySubscriptionSimpleControllerApiGetAllSubscriptionInvoiceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalPaySubscriptionSimpleControllerApi
   */
  public getAllSubscriptionInvoice(
    requestParameters: ExternalPaySubscriptionSimpleControllerApiGetAllSubscriptionInvoiceRequest,
    options?: AxiosRequestConfig,
  ) {
    return ExternalPaySubscriptionSimpleControllerApiFp(this.configuration)
      .getAllSubscriptionInvoice(
        requestParameters.xApiClient,
        requestParameters.xApiKey,
        requestParameters.externalGetAllPaySubscriptionInvoiceRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy thông tin Subscription
   * @param {ExternalPaySubscriptionSimpleControllerApiGetPaySubscription1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalPaySubscriptionSimpleControllerApi
   */
  public getPaySubscription1(
    requestParameters: ExternalPaySubscriptionSimpleControllerApiGetPaySubscription1Request,
    options?: AxiosRequestConfig,
  ) {
    return ExternalPaySubscriptionSimpleControllerApiFp(this.configuration)
      .getPaySubscription1(
        requestParameters.xApiClient,
        requestParameters.xApiKey,
        requestParameters.externalGetPaySubscriptionRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy chi tiet invoice
   * @param {ExternalPaySubscriptionSimpleControllerApiGetSubscriptionInvoiceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalPaySubscriptionSimpleControllerApi
   */
  public getSubscriptionInvoice(
    requestParameters: ExternalPaySubscriptionSimpleControllerApiGetSubscriptionInvoiceRequest,
    options?: AxiosRequestConfig,
  ) {
    return ExternalPaySubscriptionSimpleControllerApiFp(this.configuration)
      .getSubscriptionInvoice(
        requestParameters.xApiClient,
        requestParameters.xApiKey,
        requestParameters.externalGetPaySubscriptionInvoiceRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy thông tin Plan
   * @param {ExternalPaySubscriptionSimpleControllerApiGetSubscriptionPlanRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalPaySubscriptionSimpleControllerApi
   */
  public getSubscriptionPlan(
    requestParameters: ExternalPaySubscriptionSimpleControllerApiGetSubscriptionPlanRequest,
    options?: AxiosRequestConfig,
  ) {
    return ExternalPaySubscriptionSimpleControllerApiFp(this.configuration)
      .getSubscriptionPlan(
        requestParameters.xApiClient,
        requestParameters.xApiKey,
        requestParameters.externalGetPaySubscriptionPlanRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Cập nhật trạng thái Subscription
   * @param {ExternalPaySubscriptionSimpleControllerApiUpdateStateSubscriptionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalPaySubscriptionSimpleControllerApi
   */
  public updateStateSubscription(
    requestParameters: ExternalPaySubscriptionSimpleControllerApiUpdateStateSubscriptionRequest,
    options?: AxiosRequestConfig,
  ) {
    return ExternalPaySubscriptionSimpleControllerApiFp(this.configuration)
      .updateStateSubscription(
        requestParameters.xApiClient,
        requestParameters.xApiKey,
        requestParameters.externalUpdateStatePaySubscriptionRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy thông tin Plan
   * @param {ExternalPaySubscriptionSimpleControllerApiUpdateStateSubscriptionPlanRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalPaySubscriptionSimpleControllerApi
   */
  public updateStateSubscriptionPlan(
    requestParameters: ExternalPaySubscriptionSimpleControllerApiUpdateStateSubscriptionPlanRequest,
    options?: AxiosRequestConfig,
  ) {
    return ExternalPaySubscriptionSimpleControllerApiFp(this.configuration)
      .updateStateSubscriptionPlan(
        requestParameters.xApiClient,
        requestParameters.xApiKey,
        requestParameters.externalUpdateStatePaySubscriptionPlanRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * FAQControllerApi - axios parameter creator
 * @export
 */
export const FAQControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * - Tạo mới một câu hỏi thường gặp
     * @summary Tạo mới một câu hỏi thường gặp
     * @param {CreateFAQRequest} createFAQRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFAQ: async (
      createFAQRequest: CreateFAQRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createFAQRequest' is not null or undefined
      assertParamExists("createFAQ", "createFAQRequest", createFAQRequest);
      const localVarPath = `/api/faq/portal/v1/add`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createFAQRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Xóa thông tin câu hỏi thường gặp
     * @summary Xóa thông tin câu hỏi thường gặp
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFAQ: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("deleteFAQ", "id", id);
      const localVarPath = `/api/faq/portal/v1/delete/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Lấy câu hỏi thường gặp
     * @summary Lấy câu hỏi thường gặp
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFAQById: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getFAQById", "id", id);
      const localVarPath = `/api/faq/portal/v1/get/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Danh sách câu hỏi câu hỏi thường gặp
     * @summary Danh sách câu hỏi thường gặp
     * @param {string} [from]
     * @param {string} [to]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFAQPages: async (
      from?: string,
      to?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/faq/portal/v1/getAll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (from !== undefined) {
        localVarQueryParameter["from"] = from;
      }

      if (to !== undefined) {
        localVarQueryParameter["to"] = to;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter["sortDirection"] = sortDirection;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter["sortBy"] = sortBy;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Cập nhật thông tin câu hỏi thường gặp
     * @summary Cập nhật thông tin câu hỏi thường gặp
     * @param {UpdateFAQRequest} updateFAQRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFAQ: async (
      updateFAQRequest: UpdateFAQRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateFAQRequest' is not null or undefined
      assertParamExists("updateFAQ", "updateFAQRequest", updateFAQRequest);
      const localVarPath = `/api/faq/portal/v1/update`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateFAQRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FAQControllerApi - functional programming interface
 * @export
 */
export const FAQControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    FAQControllerApiAxiosParamCreator(configuration);
  return {
    /**
     * - Tạo mới một câu hỏi thường gặp
     * @summary Tạo mới một câu hỏi thường gặp
     * @param {CreateFAQRequest} createFAQRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createFAQ(
      createFAQRequest: CreateFAQRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseUpdateFAQResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createFAQ(
        createFAQRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Xóa thông tin câu hỏi thường gặp
     * @summary Xóa thông tin câu hỏi thường gặp
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteFAQ(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseUpdateFAQResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFAQ(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Lấy câu hỏi thường gặp
     * @summary Lấy câu hỏi thường gặp
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFAQById(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseUpdateFAQResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFAQById(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Danh sách câu hỏi câu hỏi thường gặp
     * @summary Danh sách câu hỏi thường gặp
     * @param {string} [from]
     * @param {string} [to]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFAQPages(
      from?: string,
      to?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseUpdateFAQResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFAQPages(
        from,
        to,
        page,
        pageSize,
        sortDirection,
        sortBy,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Cập nhật thông tin câu hỏi thường gặp
     * @summary Cập nhật thông tin câu hỏi thường gặp
     * @param {UpdateFAQRequest} updateFAQRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateFAQ(
      updateFAQRequest: UpdateFAQRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseUpdateFAQResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateFAQ(
        updateFAQRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * FAQControllerApi - factory interface
 * @export
 */
export const FAQControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = FAQControllerApiFp(configuration);
  return {
    /**
     * - Tạo mới một câu hỏi thường gặp
     * @summary Tạo mới một câu hỏi thường gặp
     * @param {CreateFAQRequest} createFAQRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFAQ(
      createFAQRequest: CreateFAQRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseUpdateFAQResponse> {
      return localVarFp
        .createFAQ(createFAQRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Xóa thông tin câu hỏi thường gặp
     * @summary Xóa thông tin câu hỏi thường gặp
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFAQ(
      id: string,
      options?: any,
    ): AxiosPromise<ResponseBaseUpdateFAQResponse> {
      return localVarFp
        .deleteFAQ(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Lấy câu hỏi thường gặp
     * @summary Lấy câu hỏi thường gặp
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFAQById(
      id: string,
      options?: any,
    ): AxiosPromise<ResponseBaseUpdateFAQResponse> {
      return localVarFp
        .getFAQById(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Danh sách câu hỏi câu hỏi thường gặp
     * @summary Danh sách câu hỏi thường gặp
     * @param {string} [from]
     * @param {string} [to]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFAQPages(
      from?: string,
      to?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseUpdateFAQResponse> {
      return localVarFp
        .getFAQPages(from, to, page, pageSize, sortDirection, sortBy, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Cập nhật thông tin câu hỏi thường gặp
     * @summary Cập nhật thông tin câu hỏi thường gặp
     * @param {UpdateFAQRequest} updateFAQRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFAQ(
      updateFAQRequest: UpdateFAQRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseUpdateFAQResponse> {
      return localVarFp
        .updateFAQ(updateFAQRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createFAQ operation in FAQControllerApi.
 * @export
 * @interface FAQControllerApiCreateFAQRequest
 */
export interface FAQControllerApiCreateFAQRequest {
  /**
   *
   * @type {CreateFAQRequest}
   * @memberof FAQControllerApiCreateFAQ
   */
  readonly createFAQRequest: CreateFAQRequest;
}

/**
 * Request parameters for deleteFAQ operation in FAQControllerApi.
 * @export
 * @interface FAQControllerApiDeleteFAQRequest
 */
export interface FAQControllerApiDeleteFAQRequest {
  /**
   *
   * @type {string}
   * @memberof FAQControllerApiDeleteFAQ
   */
  readonly id: string;
}

/**
 * Request parameters for getFAQById operation in FAQControllerApi.
 * @export
 * @interface FAQControllerApiGetFAQByIdRequest
 */
export interface FAQControllerApiGetFAQByIdRequest {
  /**
   *
   * @type {string}
   * @memberof FAQControllerApiGetFAQById
   */
  readonly id: string;
}

/**
 * Request parameters for getFAQPages operation in FAQControllerApi.
 * @export
 * @interface FAQControllerApiGetFAQPagesRequest
 */
export interface FAQControllerApiGetFAQPagesRequest {
  /**
   *
   * @type {string}
   * @memberof FAQControllerApiGetFAQPages
   */
  readonly from?: string;

  /**
   *
   * @type {string}
   * @memberof FAQControllerApiGetFAQPages
   */
  readonly to?: string;

  /**
   *
   * @type {number}
   * @memberof FAQControllerApiGetFAQPages
   */
  readonly page?: number;

  /**
   *
   * @type {number}
   * @memberof FAQControllerApiGetFAQPages
   */
  readonly pageSize?: number;

  /**
   *
   * @type {'ASC' | 'DESC'}
   * @memberof FAQControllerApiGetFAQPages
   */
  readonly sortDirection?: "ASC" | "DESC";

  /**
   *
   * @type {string}
   * @memberof FAQControllerApiGetFAQPages
   */
  readonly sortBy?: string;
}

/**
 * Request parameters for updateFAQ operation in FAQControllerApi.
 * @export
 * @interface FAQControllerApiUpdateFAQRequest
 */
export interface FAQControllerApiUpdateFAQRequest {
  /**
   *
   * @type {UpdateFAQRequest}
   * @memberof FAQControllerApiUpdateFAQ
   */
  readonly updateFAQRequest: UpdateFAQRequest;
}

/**
 * FAQControllerApi - object-oriented interface
 * @export
 * @class FAQControllerApi
 * @extends {BaseAPI}
 */
export class FAQControllerApi extends BaseAPI {
  /**
   * - Tạo mới một câu hỏi thường gặp
   * @summary Tạo mới một câu hỏi thường gặp
   * @param {FAQControllerApiCreateFAQRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FAQControllerApi
   */
  public createFAQ(
    requestParameters: FAQControllerApiCreateFAQRequest,
    options?: AxiosRequestConfig,
  ) {
    return FAQControllerApiFp(this.configuration)
      .createFAQ(requestParameters.createFAQRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Xóa thông tin câu hỏi thường gặp
   * @summary Xóa thông tin câu hỏi thường gặp
   * @param {FAQControllerApiDeleteFAQRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FAQControllerApi
   */
  public deleteFAQ(
    requestParameters: FAQControllerApiDeleteFAQRequest,
    options?: AxiosRequestConfig,
  ) {
    return FAQControllerApiFp(this.configuration)
      .deleteFAQ(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Lấy câu hỏi thường gặp
   * @summary Lấy câu hỏi thường gặp
   * @param {FAQControllerApiGetFAQByIdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FAQControllerApi
   */
  public getFAQById(
    requestParameters: FAQControllerApiGetFAQByIdRequest,
    options?: AxiosRequestConfig,
  ) {
    return FAQControllerApiFp(this.configuration)
      .getFAQById(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Danh sách câu hỏi câu hỏi thường gặp
   * @summary Danh sách câu hỏi thường gặp
   * @param {FAQControllerApiGetFAQPagesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FAQControllerApi
   */
  public getFAQPages(
    requestParameters: FAQControllerApiGetFAQPagesRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return FAQControllerApiFp(this.configuration)
      .getFAQPages(
        requestParameters.from,
        requestParameters.to,
        requestParameters.page,
        requestParameters.pageSize,
        requestParameters.sortDirection,
        requestParameters.sortBy,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Cập nhật thông tin câu hỏi thường gặp
   * @summary Cập nhật thông tin câu hỏi thường gặp
   * @param {FAQControllerApiUpdateFAQRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FAQControllerApi
   */
  public updateFAQ(
    requestParameters: FAQControllerApiUpdateFAQRequest,
    options?: AxiosRequestConfig,
  ) {
    return FAQControllerApiFp(this.configuration)
      .updateFAQ(requestParameters.updateFAQRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * FeeControllerApi - axios parameter creator
 * @export
 */
export const FeeControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Thêm biểu phí
     * @param {AddFeeRequest} addFeeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    add: async (
      addFeeRequest: AddFeeRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'addFeeRequest' is not null or undefined
      assertParamExists("add", "addFeeRequest", addFeeRequest);
      const localVarPath = `/api/fee/cms/v1/add`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        addFeeRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Duyệt biểu phí
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approve1: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("approve1", "id", id);
      const localVarPath = `/api/fee/cms/v1/approve`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (id !== undefined) {
        localVarQueryParameter["id"] = id;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [Cms] Tính toán phí
     * @param {string} date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calFeeReportCms: async (
      date: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'date' is not null or undefined
      assertParamExists("calFeeReportCms", "date", date);
      const localVarPath = `/api/fee/v1/cms/feeReport/cal`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (date !== undefined) {
        localVarQueryParameter["date"] = date;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [CMS] Kiểm tra mã biểu phí
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkFeeCode: async (
      code: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists("checkFeeCode", "code", code);
      const localVarPath = `/api/fee/cms/v1/checkFee/{code}`.replace(
        `{${"code"}}`,
        encodeURIComponent(String(code)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xoá biểu phí
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    delete1: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("delete1", "id", id);
      const localVarPath = `/api/fee/cms/v1/delete/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Từ chối duyệt biểu phí
     * @param {string} id
     * @param {string} reasonDeny
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deny1: async (
      id: string,
      reasonDeny: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("deny1", "id", id);
      // verify required parameter 'reasonDeny' is not null or undefined
      assertParamExists("deny1", "reasonDeny", reasonDeny);
      const localVarPath = `/api/fee/cms/v1/deny`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (id !== undefined) {
        localVarQueryParameter["id"] = id;
      }

      if (reasonDeny !== undefined) {
        localVarQueryParameter["reasonDeny"] = reasonDeny;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [CMS] Xuất file báo cáo phí
     * @param {string} fromDate
     * @param {string} toDate
     * @param {string} [prefix]
     * @param {string} [merchantName]
     * @param {'WAITING_PAYMENT' | 'PAID' | 'PROVISIONAL'} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportReportFeeCms: async (
      fromDate: string,
      toDate: string,
      prefix?: string,
      merchantName?: string,
      status?: "WAITING_PAYMENT" | "PAID" | "PROVISIONAL",
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'fromDate' is not null or undefined
      assertParamExists("exportReportFeeCms", "fromDate", fromDate);
      // verify required parameter 'toDate' is not null or undefined
      assertParamExists("exportReportFeeCms", "toDate", toDate);
      const localVarPath = `/api/fee/cms/v1/exportExcel/feeReport`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (fromDate !== undefined) {
        localVarQueryParameter["fromDate"] = fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter["toDate"] = toDate;
      }

      if (prefix !== undefined) {
        localVarQueryParameter["prefix"] = prefix;
      }

      if (merchantName !== undefined) {
        localVarQueryParameter["merchantName"] = merchantName;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [CMS] Xuất file chi tiết báo cáo phí
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportReportFeeDetail: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("exportReportFeeDetail", "id", id);
      const localVarPath = `/api/fee/cms/v1/exportExcel/feeReport/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [Portal] Xuất file báo cáo phí
     * @param {string} fromDate
     * @param {string} toDate
     * @param {'WAITING_PAYMENT' | 'PAID' | 'PROVISIONAL'} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportReportFeePortal: async (
      fromDate: string,
      toDate: string,
      status?: "WAITING_PAYMENT" | "PAID" | "PROVISIONAL",
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'fromDate' is not null or undefined
      assertParamExists("exportReportFeePortal", "fromDate", fromDate);
      // verify required parameter 'toDate' is not null or undefined
      assertParamExists("exportReportFeePortal", "toDate", toDate);
      const localVarPath = `/api/fee/portal/v1/exportExcel/feeReport`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (fromDate !== undefined) {
        localVarQueryParameter["fromDate"] = fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter["toDate"] = toDate;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [CMS] Sinh mã biểu phí
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    genFeeCode: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/fee/cms/v1/genCode`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy tất cả biểu phí
     * @param {string} [feeName]
     * @param {string} [feeCode]
     * @param {'CREATED' | 'APPROVED' | 'DENIED'} [feeStatus]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {'COBO' | 'POBO' | 'COBO_INTER_CARD' | 'COBO_DEBIT_DOMESTIC_CARD' | 'COBO_CREDIT_DOMESTIC_CARD' | 'POBO_INTER_BANK'} [serviceType]
     * @param {'FREE' | 'FIXED_FEE' | 'BRACKET_FEE'} [feeMethod]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAll1: async (
      feeName?: string,
      feeCode?: string,
      feeStatus?: "CREATED" | "APPROVED" | "DENIED",
      fromDate?: string,
      toDate?: string,
      serviceType?:
        | "COBO"
        | "POBO"
        | "COBO_INTER_CARD"
        | "COBO_DEBIT_DOMESTIC_CARD"
        | "COBO_CREDIT_DOMESTIC_CARD"
        | "POBO_INTER_BANK",
      feeMethod?: "FREE" | "FIXED_FEE" | "BRACKET_FEE",
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/fee/cms/v1/getAll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (feeName !== undefined) {
        localVarQueryParameter["feeName"] = feeName;
      }

      if (feeCode !== undefined) {
        localVarQueryParameter["feeCode"] = feeCode;
      }

      if (feeStatus !== undefined) {
        localVarQueryParameter["feeStatus"] = feeStatus;
      }

      if (fromDate !== undefined) {
        localVarQueryParameter["fromDate"] = fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter["toDate"] = toDate;
      }

      if (serviceType !== undefined) {
        localVarQueryParameter["serviceType"] = serviceType;
      }

      if (feeMethod !== undefined) {
        localVarQueryParameter["feeMethod"] = feeMethod;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy tất cả biểu phí theo service type
     * @param {'COBO' | 'POBO' | 'COBO_INTER_CARD' | 'COBO_DEBIT_DOMESTIC_CARD' | 'COBO_CREDIT_DOMESTIC_CARD' | 'POBO_INTER_BANK'} serviceType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllByServiceType: async (
      serviceType:
        | "COBO"
        | "POBO"
        | "COBO_INTER_CARD"
        | "COBO_DEBIT_DOMESTIC_CARD"
        | "COBO_CREDIT_DOMESTIC_CARD"
        | "POBO_INTER_BANK",
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'serviceType' is not null or undefined
      assertParamExists("getAllByServiceType", "serviceType", serviceType);
      const localVarPath = `/api/fee/v1/getAllByServiceType`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (serviceType !== undefined) {
        localVarQueryParameter["serviceType"] = serviceType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy chi tiết biểu phí
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDetail2: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getDetail2", "id", id);
      const localVarPath = `/api/fee/cms/v1/detail/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy lịch sử thay đổi trạng thái biểu phí cms
     * @param {string} [feeId]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHistoryFee: async (
      feeId?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/fee/cms/v1/getHistory`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (feeId !== undefined) {
        localVarQueryParameter["feeId"] = feeId;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [Cms]Lấy chi tiết báo cáo phí Cms theo merchant
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReportFeeDetail: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getReportFeeDetail", "id", id);
      const localVarPath = `/api/fee/cms/v1/getDetailFeeReport`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (id !== undefined) {
        localVarQueryParameter["id"] = id;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [Portal]Lấy chi tiết báo cáo phí
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReportFeeDetailPortal: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getReportFeeDetailPortal", "id", id);
      const localVarPath = `/api/fee/portal/v1/getDetailFeeReport`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (id !== undefined) {
        localVarQueryParameter["id"] = id;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [Cms]Lấy danh sách báo cáo phí Cms theo merchant
     * @param {string} fromDate
     * @param {string} toDate
     * @param {string} [merchantName]
     * @param {string} [prefix]
     * @param {'WAITING_PAYMENT' | 'PAID' | 'PROVISIONAL'} [status]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReportFeeMerchant: async (
      fromDate: string,
      toDate: string,
      merchantName?: string,
      prefix?: string,
      status?: "WAITING_PAYMENT" | "PAID" | "PROVISIONAL",
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'fromDate' is not null or undefined
      assertParamExists("getReportFeeMerchant", "fromDate", fromDate);
      // verify required parameter 'toDate' is not null or undefined
      assertParamExists("getReportFeeMerchant", "toDate", toDate);
      const localVarPath = `/api/fee/cms/v1/getReportFeeMerchant`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (fromDate !== undefined) {
        localVarQueryParameter["fromDate"] = fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter["toDate"] = toDate;
      }

      if (merchantName !== undefined) {
        localVarQueryParameter["merchantName"] = merchantName;
      }

      if (prefix !== undefined) {
        localVarQueryParameter["prefix"] = prefix;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [Portal]Lấy danh sách báo cáo phí
     * @param {string} fromDate
     * @param {string} toDate
     * @param {'WAITING_PAYMENT' | 'PAID' | 'PROVISIONAL'} [status]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReportFeeMerchantPortal: async (
      fromDate: string,
      toDate: string,
      status?: "WAITING_PAYMENT" | "PAID" | "PROVISIONAL",
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'fromDate' is not null or undefined
      assertParamExists("getReportFeeMerchantPortal", "fromDate", fromDate);
      // verify required parameter 'toDate' is not null or undefined
      assertParamExists("getReportFeeMerchantPortal", "toDate", toDate);
      const localVarPath = `/api/fee/portal/v1/getReportFeeMerchant`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (fromDate !== undefined) {
        localVarQueryParameter["fromDate"] = fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter["toDate"] = toDate;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [Cms]Lấy chi tiết danh sách giao dịch báo cáo phí Cms
     * @param {string} [feeReportId]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransactionFeeReport: async (
      feeReportId?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/fee/cms/v1/transactions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (feeReportId !== undefined) {
        localVarQueryParameter["feeReportId"] = feeReportId;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [Portal]Lấy chi tiết danh sách giao dịch báo cáo phí Portal
     * @param {string} [feeReportId]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransactionFeeReportPortal: async (
      feeReportId?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/fee/portal/v1/transactions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (feeReportId !== undefined) {
        localVarQueryParameter["feeReportId"] = feeReportId;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [PORTAL] Thanh toán báo cáo phí
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    payFeeMerchant: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("payFeeMerchant", "id", id);
      const localVarPath = `/api/fee/portal/v1/payment/feeReport/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [Cms] Thanh toán báo cáo phí theo tháng
     * @param {PayFeeReportCmsRequest} payFeeReportCmsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    payFeeReportCms: async (
      payFeeReportCmsRequest: PayFeeReportCmsRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'payFeeReportCmsRequest' is not null or undefined
      assertParamExists(
        "payFeeReportCms",
        "payFeeReportCmsRequest",
        payFeeReportCmsRequest,
      );
      const localVarPath = `/api/fee/cms/v1/feeReport/pay`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        payFeeReportCmsRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [Cms] SendMail
     * @param {SendEmailFeeReportRequest} sendEmailFeeReportRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendMail: async (
      sendEmailFeeReportRequest: SendEmailFeeReportRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'sendEmailFeeReportRequest' is not null or undefined
      assertParamExists(
        "sendMail",
        "sendEmailFeeReportRequest",
        sendEmailFeeReportRequest,
      );
      const localVarPath = `/api/fee/cms/v1/feeReport/sendMail`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        sendEmailFeeReportRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Cập nhật biểu phí
     * @param {UpdateFeeRequest} updateFeeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update1: async (
      updateFeeRequest: UpdateFeeRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateFeeRequest' is not null or undefined
      assertParamExists("update1", "updateFeeRequest", updateFeeRequest);
      const localVarPath = `/api/fee/cms/v1/update`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateFeeRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FeeControllerApi - functional programming interface
 * @export
 */
export const FeeControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    FeeControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Thêm biểu phí
     * @param {AddFeeRequest} addFeeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async add(
      addFeeRequest: AddFeeRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseAddFeeResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.add(
        addFeeRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Duyệt biểu phí
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async approve1(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusFeeResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.approve1(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary [Cms] Tính toán phí
     * @param {string} date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async calFeeReportCms(
      date: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.calFeeReportCms(
        date,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary [CMS] Kiểm tra mã biểu phí
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkFeeCode(
      code: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.checkFeeCode(
        code,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Xoá biểu phí
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async delete1(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.delete1(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Từ chối duyệt biểu phí
     * @param {string} id
     * @param {string} reasonDeny
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deny1(
      id: string,
      reasonDeny: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusFeeResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deny1(
        id,
        reasonDeny,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary [CMS] Xuất file báo cáo phí
     * @param {string} fromDate
     * @param {string} toDate
     * @param {string} [prefix]
     * @param {string} [merchantName]
     * @param {'WAITING_PAYMENT' | 'PAID' | 'PROVISIONAL'} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportReportFeeCms(
      fromDate: string,
      toDate: string,
      prefix?: string,
      merchantName?: string,
      status?: "WAITING_PAYMENT" | "PAID" | "PROVISIONAL",
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.exportReportFeeCms(
          fromDate,
          toDate,
          prefix,
          merchantName,
          status,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary [CMS] Xuất file chi tiết báo cáo phí
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportReportFeeDetail(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.exportReportFeeDetail(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary [Portal] Xuất file báo cáo phí
     * @param {string} fromDate
     * @param {string} toDate
     * @param {'WAITING_PAYMENT' | 'PAID' | 'PROVISIONAL'} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportReportFeePortal(
      fromDate: string,
      toDate: string,
      status?: "WAITING_PAYMENT" | "PAID" | "PROVISIONAL",
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.exportReportFeePortal(
          fromDate,
          toDate,
          status,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary [CMS] Sinh mã biểu phí
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async genFeeCode(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseFeeCodeResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.genFeeCode(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy tất cả biểu phí
     * @param {string} [feeName]
     * @param {string} [feeCode]
     * @param {'CREATED' | 'APPROVED' | 'DENIED'} [feeStatus]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {'COBO' | 'POBO' | 'COBO_INTER_CARD' | 'COBO_DEBIT_DOMESTIC_CARD' | 'COBO_CREDIT_DOMESTIC_CARD' | 'POBO_INTER_BANK'} [serviceType]
     * @param {'FREE' | 'FIXED_FEE' | 'BRACKET_FEE'} [feeMethod]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAll1(
      feeName?: string,
      feeCode?: string,
      feeStatus?: "CREATED" | "APPROVED" | "DENIED",
      fromDate?: string,
      toDate?: string,
      serviceType?:
        | "COBO"
        | "POBO"
        | "COBO_INTER_CARD"
        | "COBO_DEBIT_DOMESTIC_CARD"
        | "COBO_CREDIT_DOMESTIC_CARD"
        | "POBO_INTER_BANK",
      feeMethod?: "FREE" | "FIXED_FEE" | "BRACKET_FEE",
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseFeeDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAll1(
        feeName,
        feeCode,
        feeStatus,
        fromDate,
        toDate,
        serviceType,
        feeMethod,
        page,
        size,
        sort,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy tất cả biểu phí theo service type
     * @param {'COBO' | 'POBO' | 'COBO_INTER_CARD' | 'COBO_DEBIT_DOMESTIC_CARD' | 'COBO_CREDIT_DOMESTIC_CARD' | 'POBO_INTER_BANK'} serviceType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllByServiceType(
      serviceType:
        | "COBO"
        | "POBO"
        | "COBO_INTER_CARD"
        | "COBO_DEBIT_DOMESTIC_CARD"
        | "COBO_CREDIT_DOMESTIC_CARD"
        | "POBO_INTER_BANK",
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetAllFeeNameDtoResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllByServiceType(
          serviceType,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy chi tiết biểu phí
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDetail2(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetFeeInfoResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDetail2(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy lịch sử thay đổi trạng thái biểu phí cms
     * @param {string} [feeId]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getHistoryFee(
      feeId?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseFeeHistoryDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getHistoryFee(
        feeId,
        page,
        size,
        sort,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary [Cms]Lấy chi tiết báo cáo phí Cms theo merchant
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getReportFeeDetail(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseMonthlyFeeReportResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getReportFeeDetail(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary [Portal]Lấy chi tiết báo cáo phí
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getReportFeeDetailPortal(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseMonthlyFeeReportResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getReportFeeDetailPortal(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary [Cms]Lấy danh sách báo cáo phí Cms theo merchant
     * @param {string} fromDate
     * @param {string} toDate
     * @param {string} [merchantName]
     * @param {string} [prefix]
     * @param {'WAITING_PAYMENT' | 'PAID' | 'PROVISIONAL'} [status]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getReportFeeMerchant(
      fromDate: string,
      toDate: string,
      merchantName?: string,
      prefix?: string,
      status?: "WAITING_PAYMENT" | "PAID" | "PROVISIONAL",
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetAllFeeReportResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getReportFeeMerchant(
          fromDate,
          toDate,
          merchantName,
          prefix,
          status,
          page,
          size,
          sort,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary [Portal]Lấy danh sách báo cáo phí
     * @param {string} fromDate
     * @param {string} toDate
     * @param {'WAITING_PAYMENT' | 'PAID' | 'PROVISIONAL'} [status]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getReportFeeMerchantPortal(
      fromDate: string,
      toDate: string,
      status?: "WAITING_PAYMENT" | "PAID" | "PROVISIONAL",
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetAllFeeReportResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getReportFeeMerchantPortal(
          fromDate,
          toDate,
          status,
          page,
          size,
          sort,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary [Cms]Lấy chi tiết danh sách giao dịch báo cáo phí Cms
     * @param {string} [feeReportId]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTransactionFeeReport(
      feeReportId?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseTransactionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getTransactionFeeReport(
          feeReportId,
          page,
          size,
          sort,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary [Portal]Lấy chi tiết danh sách giao dịch báo cáo phí Portal
     * @param {string} [feeReportId]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTransactionFeeReportPortal(
      feeReportId?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseTransactionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getTransactionFeeReportPortal(
          feeReportId,
          page,
          size,
          sort,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary [PORTAL] Thanh toán báo cáo phí
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async payFeeMerchant(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePayLinkUrlResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.payFeeMerchant(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary [Cms] Thanh toán báo cáo phí theo tháng
     * @param {PayFeeReportCmsRequest} payFeeReportCmsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async payFeeReportCms(
      payFeeReportCmsRequest: PayFeeReportCmsRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.payFeeReportCms(
        payFeeReportCmsRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary [Cms] SendMail
     * @param {SendEmailFeeReportRequest} sendEmailFeeReportRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sendMail(
      sendEmailFeeReportRequest: SendEmailFeeReportRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.sendMail(
        sendEmailFeeReportRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Cập nhật biểu phí
     * @param {UpdateFeeRequest} updateFeeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async update1(
      updateFeeRequest: UpdateFeeRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusFeeResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.update1(
        updateFeeRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * FeeControllerApi - factory interface
 * @export
 */
export const FeeControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = FeeControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Thêm biểu phí
     * @param {AddFeeRequest} addFeeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    add(
      addFeeRequest: AddFeeRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseAddFeeResponse> {
      return localVarFp
        .add(addFeeRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Duyệt biểu phí
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approve1(
      id: string,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusFeeResponse> {
      return localVarFp
        .approve1(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [Cms] Tính toán phí
     * @param {string} date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calFeeReportCms(
      date: string,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .calFeeReportCms(date, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [CMS] Kiểm tra mã biểu phí
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkFeeCode(
      code: string,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .checkFeeCode(code, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xoá biểu phí
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    delete1(
      id: string,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .delete1(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Từ chối duyệt biểu phí
     * @param {string} id
     * @param {string} reasonDeny
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deny1(
      id: string,
      reasonDeny: string,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusFeeResponse> {
      return localVarFp
        .deny1(id, reasonDeny, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [CMS] Xuất file báo cáo phí
     * @param {string} fromDate
     * @param {string} toDate
     * @param {string} [prefix]
     * @param {string} [merchantName]
     * @param {'WAITING_PAYMENT' | 'PAID' | 'PROVISIONAL'} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportReportFeeCms(
      fromDate: string,
      toDate: string,
      prefix?: string,
      merchantName?: string,
      status?: "WAITING_PAYMENT" | "PAID" | "PROVISIONAL",
      options?: any,
    ): AxiosPromise<File> {
      return localVarFp
        .exportReportFeeCms(
          fromDate,
          toDate,
          prefix,
          merchantName,
          status,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [CMS] Xuất file chi tiết báo cáo phí
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportReportFeeDetail(id: number, options?: any): AxiosPromise<File> {
      return localVarFp
        .exportReportFeeDetail(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [Portal] Xuất file báo cáo phí
     * @param {string} fromDate
     * @param {string} toDate
     * @param {'WAITING_PAYMENT' | 'PAID' | 'PROVISIONAL'} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportReportFeePortal(
      fromDate: string,
      toDate: string,
      status?: "WAITING_PAYMENT" | "PAID" | "PROVISIONAL",
      options?: any,
    ): AxiosPromise<File> {
      return localVarFp
        .exportReportFeePortal(fromDate, toDate, status, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [CMS] Sinh mã biểu phí
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    genFeeCode(options?: any): AxiosPromise<ResponseBaseFeeCodeResponse> {
      return localVarFp
        .genFeeCode(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy tất cả biểu phí
     * @param {string} [feeName]
     * @param {string} [feeCode]
     * @param {'CREATED' | 'APPROVED' | 'DENIED'} [feeStatus]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {'COBO' | 'POBO' | 'COBO_INTER_CARD' | 'COBO_DEBIT_DOMESTIC_CARD' | 'COBO_CREDIT_DOMESTIC_CARD' | 'POBO_INTER_BANK'} [serviceType]
     * @param {'FREE' | 'FIXED_FEE' | 'BRACKET_FEE'} [feeMethod]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAll1(
      feeName?: string,
      feeCode?: string,
      feeStatus?: "CREATED" | "APPROVED" | "DENIED",
      fromDate?: string,
      toDate?: string,
      serviceType?:
        | "COBO"
        | "POBO"
        | "COBO_INTER_CARD"
        | "COBO_DEBIT_DOMESTIC_CARD"
        | "COBO_CREDIT_DOMESTIC_CARD"
        | "POBO_INTER_BANK",
      feeMethod?: "FREE" | "FIXED_FEE" | "BRACKET_FEE",
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseFeeDto> {
      return localVarFp
        .getAll1(
          feeName,
          feeCode,
          feeStatus,
          fromDate,
          toDate,
          serviceType,
          feeMethod,
          page,
          size,
          sort,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy tất cả biểu phí theo service type
     * @param {'COBO' | 'POBO' | 'COBO_INTER_CARD' | 'COBO_DEBIT_DOMESTIC_CARD' | 'COBO_CREDIT_DOMESTIC_CARD' | 'POBO_INTER_BANK'} serviceType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllByServiceType(
      serviceType:
        | "COBO"
        | "POBO"
        | "COBO_INTER_CARD"
        | "COBO_DEBIT_DOMESTIC_CARD"
        | "COBO_CREDIT_DOMESTIC_CARD"
        | "POBO_INTER_BANK",
      options?: any,
    ): AxiosPromise<ResponseBaseGetAllFeeNameDtoResponse> {
      return localVarFp
        .getAllByServiceType(serviceType, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy chi tiết biểu phí
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDetail2(
      id: string,
      options?: any,
    ): AxiosPromise<ResponseBaseGetFeeInfoResponse> {
      return localVarFp
        .getDetail2(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy lịch sử thay đổi trạng thái biểu phí cms
     * @param {string} [feeId]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHistoryFee(
      feeId?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseFeeHistoryDto> {
      return localVarFp
        .getHistoryFee(feeId, page, size, sort, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [Cms]Lấy chi tiết báo cáo phí Cms theo merchant
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReportFeeDetail(
      id: number,
      options?: any,
    ): AxiosPromise<ResponseBaseMonthlyFeeReportResponse> {
      return localVarFp
        .getReportFeeDetail(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [Portal]Lấy chi tiết báo cáo phí
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReportFeeDetailPortal(
      id: number,
      options?: any,
    ): AxiosPromise<ResponseBaseMonthlyFeeReportResponse> {
      return localVarFp
        .getReportFeeDetailPortal(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [Cms]Lấy danh sách báo cáo phí Cms theo merchant
     * @param {string} fromDate
     * @param {string} toDate
     * @param {string} [merchantName]
     * @param {string} [prefix]
     * @param {'WAITING_PAYMENT' | 'PAID' | 'PROVISIONAL'} [status]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReportFeeMerchant(
      fromDate: string,
      toDate: string,
      merchantName?: string,
      prefix?: string,
      status?: "WAITING_PAYMENT" | "PAID" | "PROVISIONAL",
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any,
    ): AxiosPromise<ResponseBaseGetAllFeeReportResponse> {
      return localVarFp
        .getReportFeeMerchant(
          fromDate,
          toDate,
          merchantName,
          prefix,
          status,
          page,
          size,
          sort,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [Portal]Lấy danh sách báo cáo phí
     * @param {string} fromDate
     * @param {string} toDate
     * @param {'WAITING_PAYMENT' | 'PAID' | 'PROVISIONAL'} [status]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReportFeeMerchantPortal(
      fromDate: string,
      toDate: string,
      status?: "WAITING_PAYMENT" | "PAID" | "PROVISIONAL",
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any,
    ): AxiosPromise<ResponseBaseGetAllFeeReportResponse> {
      return localVarFp
        .getReportFeeMerchantPortal(
          fromDate,
          toDate,
          status,
          page,
          size,
          sort,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [Cms]Lấy chi tiết danh sách giao dịch báo cáo phí Cms
     * @param {string} [feeReportId]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransactionFeeReport(
      feeReportId?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseTransactionResponse> {
      return localVarFp
        .getTransactionFeeReport(feeReportId, page, size, sort, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [Portal]Lấy chi tiết danh sách giao dịch báo cáo phí Portal
     * @param {string} [feeReportId]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransactionFeeReportPortal(
      feeReportId?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseTransactionResponse> {
      return localVarFp
        .getTransactionFeeReportPortal(feeReportId, page, size, sort, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [PORTAL] Thanh toán báo cáo phí
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    payFeeMerchant(
      id: number,
      options?: any,
    ): AxiosPromise<ResponseBasePayLinkUrlResponse> {
      return localVarFp
        .payFeeMerchant(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [Cms] Thanh toán báo cáo phí theo tháng
     * @param {PayFeeReportCmsRequest} payFeeReportCmsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    payFeeReportCms(
      payFeeReportCmsRequest: PayFeeReportCmsRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .payFeeReportCms(payFeeReportCmsRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [Cms] SendMail
     * @param {SendEmailFeeReportRequest} sendEmailFeeReportRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendMail(
      sendEmailFeeReportRequest: SendEmailFeeReportRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .sendMail(sendEmailFeeReportRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Cập nhật biểu phí
     * @param {UpdateFeeRequest} updateFeeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update1(
      updateFeeRequest: UpdateFeeRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusFeeResponse> {
      return localVarFp
        .update1(updateFeeRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for add operation in FeeControllerApi.
 * @export
 * @interface FeeControllerApiAddRequest
 */
export interface FeeControllerApiAddRequest {
  /**
   *
   * @type {AddFeeRequest}
   * @memberof FeeControllerApiAdd
   */
  readonly addFeeRequest: AddFeeRequest;
}

/**
 * Request parameters for approve1 operation in FeeControllerApi.
 * @export
 * @interface FeeControllerApiApprove1Request
 */
export interface FeeControllerApiApprove1Request {
  /**
   *
   * @type {string}
   * @memberof FeeControllerApiApprove1
   */
  readonly id: string;
}

/**
 * Request parameters for calFeeReportCms operation in FeeControllerApi.
 * @export
 * @interface FeeControllerApiCalFeeReportCmsRequest
 */
export interface FeeControllerApiCalFeeReportCmsRequest {
  /**
   *
   * @type {string}
   * @memberof FeeControllerApiCalFeeReportCms
   */
  readonly date: string;
}

/**
 * Request parameters for checkFeeCode operation in FeeControllerApi.
 * @export
 * @interface FeeControllerApiCheckFeeCodeRequest
 */
export interface FeeControllerApiCheckFeeCodeRequest {
  /**
   *
   * @type {string}
   * @memberof FeeControllerApiCheckFeeCode
   */
  readonly code: string;
}

/**
 * Request parameters for delete1 operation in FeeControllerApi.
 * @export
 * @interface FeeControllerApiDelete1Request
 */
export interface FeeControllerApiDelete1Request {
  /**
   *
   * @type {string}
   * @memberof FeeControllerApiDelete1
   */
  readonly id: string;
}

/**
 * Request parameters for deny1 operation in FeeControllerApi.
 * @export
 * @interface FeeControllerApiDeny1Request
 */
export interface FeeControllerApiDeny1Request {
  /**
   *
   * @type {string}
   * @memberof FeeControllerApiDeny1
   */
  readonly id: string;

  /**
   *
   * @type {string}
   * @memberof FeeControllerApiDeny1
   */
  readonly reasonDeny: string;
}

/**
 * Request parameters for exportReportFeeCms operation in FeeControllerApi.
 * @export
 * @interface FeeControllerApiExportReportFeeCmsRequest
 */
export interface FeeControllerApiExportReportFeeCmsRequest {
  /**
   *
   * @type {string}
   * @memberof FeeControllerApiExportReportFeeCms
   */
  readonly fromDate: string;

  /**
   *
   * @type {string}
   * @memberof FeeControllerApiExportReportFeeCms
   */
  readonly toDate: string;

  /**
   *
   * @type {string}
   * @memberof FeeControllerApiExportReportFeeCms
   */
  readonly prefix?: string;

  /**
   *
   * @type {string}
   * @memberof FeeControllerApiExportReportFeeCms
   */
  readonly merchantName?: string;

  /**
   *
   * @type {'WAITING_PAYMENT' | 'PAID' | 'PROVISIONAL'}
   * @memberof FeeControllerApiExportReportFeeCms
   */
  readonly status?: "WAITING_PAYMENT" | "PAID" | "PROVISIONAL";
}

/**
 * Request parameters for exportReportFeeDetail operation in FeeControllerApi.
 * @export
 * @interface FeeControllerApiExportReportFeeDetailRequest
 */
export interface FeeControllerApiExportReportFeeDetailRequest {
  /**
   *
   * @type {number}
   * @memberof FeeControllerApiExportReportFeeDetail
   */
  readonly id: number;
}

/**
 * Request parameters for exportReportFeePortal operation in FeeControllerApi.
 * @export
 * @interface FeeControllerApiExportReportFeePortalRequest
 */
export interface FeeControllerApiExportReportFeePortalRequest {
  /**
   *
   * @type {string}
   * @memberof FeeControllerApiExportReportFeePortal
   */
  readonly fromDate: string;

  /**
   *
   * @type {string}
   * @memberof FeeControllerApiExportReportFeePortal
   */
  readonly toDate: string;

  /**
   *
   * @type {'WAITING_PAYMENT' | 'PAID' | 'PROVISIONAL'}
   * @memberof FeeControllerApiExportReportFeePortal
   */
  readonly status?: "WAITING_PAYMENT" | "PAID" | "PROVISIONAL";
}

/**
 * Request parameters for getAll1 operation in FeeControllerApi.
 * @export
 * @interface FeeControllerApiGetAll1Request
 */
export interface FeeControllerApiGetAll1Request {
  /**
   *
   * @type {string}
   * @memberof FeeControllerApiGetAll1
   */
  readonly feeName?: string;

  /**
   *
   * @type {string}
   * @memberof FeeControllerApiGetAll1
   */
  readonly feeCode?: string;

  /**
   *
   * @type {'CREATED' | 'APPROVED' | 'DENIED'}
   * @memberof FeeControllerApiGetAll1
   */
  readonly feeStatus?: "CREATED" | "APPROVED" | "DENIED";

  /**
   *
   * @type {string}
   * @memberof FeeControllerApiGetAll1
   */
  readonly fromDate?: string;

  /**
   *
   * @type {string}
   * @memberof FeeControllerApiGetAll1
   */
  readonly toDate?: string;

  /**
   *
   * @type {'COBO' | 'POBO' | 'COBO_INTER_CARD' | 'COBO_DEBIT_DOMESTIC_CARD' | 'COBO_CREDIT_DOMESTIC_CARD' | 'POBO_INTER_BANK'}
   * @memberof FeeControllerApiGetAll1
   */
  readonly serviceType?:
    | "COBO"
    | "POBO"
    | "COBO_INTER_CARD"
    | "COBO_DEBIT_DOMESTIC_CARD"
    | "COBO_CREDIT_DOMESTIC_CARD"
    | "POBO_INTER_BANK";

  /**
   *
   * @type {'FREE' | 'FIXED_FEE' | 'BRACKET_FEE'}
   * @memberof FeeControllerApiGetAll1
   */
  readonly feeMethod?: "FREE" | "FIXED_FEE" | "BRACKET_FEE";

  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof FeeControllerApiGetAll1
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof FeeControllerApiGetAll1
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof FeeControllerApiGetAll1
   */
  readonly sort?: Array<string>;
}

/**
 * Request parameters for getAllByServiceType operation in FeeControllerApi.
 * @export
 * @interface FeeControllerApiGetAllByServiceTypeRequest
 */
export interface FeeControllerApiGetAllByServiceTypeRequest {
  /**
   *
   * @type {'COBO' | 'POBO' | 'COBO_INTER_CARD' | 'COBO_DEBIT_DOMESTIC_CARD' | 'COBO_CREDIT_DOMESTIC_CARD' | 'POBO_INTER_BANK'}
   * @memberof FeeControllerApiGetAllByServiceType
   */
  readonly serviceType:
    | "COBO"
    | "POBO"
    | "COBO_INTER_CARD"
    | "COBO_DEBIT_DOMESTIC_CARD"
    | "COBO_CREDIT_DOMESTIC_CARD"
    | "POBO_INTER_BANK";
}

/**
 * Request parameters for getDetail2 operation in FeeControllerApi.
 * @export
 * @interface FeeControllerApiGetDetail2Request
 */
export interface FeeControllerApiGetDetail2Request {
  /**
   *
   * @type {string}
   * @memberof FeeControllerApiGetDetail2
   */
  readonly id: string;
}

/**
 * Request parameters for getHistoryFee operation in FeeControllerApi.
 * @export
 * @interface FeeControllerApiGetHistoryFeeRequest
 */
export interface FeeControllerApiGetHistoryFeeRequest {
  /**
   *
   * @type {string}
   * @memberof FeeControllerApiGetHistoryFee
   */
  readonly feeId?: string;

  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof FeeControllerApiGetHistoryFee
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof FeeControllerApiGetHistoryFee
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof FeeControllerApiGetHistoryFee
   */
  readonly sort?: Array<string>;
}

/**
 * Request parameters for getReportFeeDetail operation in FeeControllerApi.
 * @export
 * @interface FeeControllerApiGetReportFeeDetailRequest
 */
export interface FeeControllerApiGetReportFeeDetailRequest {
  /**
   *
   * @type {number}
   * @memberof FeeControllerApiGetReportFeeDetail
   */
  readonly id: number;
}

/**
 * Request parameters for getReportFeeDetailPortal operation in FeeControllerApi.
 * @export
 * @interface FeeControllerApiGetReportFeeDetailPortalRequest
 */
export interface FeeControllerApiGetReportFeeDetailPortalRequest {
  /**
   *
   * @type {number}
   * @memberof FeeControllerApiGetReportFeeDetailPortal
   */
  readonly id: number;
}

/**
 * Request parameters for getReportFeeMerchant operation in FeeControllerApi.
 * @export
 * @interface FeeControllerApiGetReportFeeMerchantRequest
 */
export interface FeeControllerApiGetReportFeeMerchantRequest {
  /**
   *
   * @type {string}
   * @memberof FeeControllerApiGetReportFeeMerchant
   */
  readonly fromDate: string;

  /**
   *
   * @type {string}
   * @memberof FeeControllerApiGetReportFeeMerchant
   */
  readonly toDate: string;

  /**
   *
   * @type {string}
   * @memberof FeeControllerApiGetReportFeeMerchant
   */
  readonly merchantName?: string;

  /**
   *
   * @type {string}
   * @memberof FeeControllerApiGetReportFeeMerchant
   */
  readonly prefix?: string;

  /**
   *
   * @type {'WAITING_PAYMENT' | 'PAID' | 'PROVISIONAL'}
   * @memberof FeeControllerApiGetReportFeeMerchant
   */
  readonly status?: "WAITING_PAYMENT" | "PAID" | "PROVISIONAL";

  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof FeeControllerApiGetReportFeeMerchant
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof FeeControllerApiGetReportFeeMerchant
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof FeeControllerApiGetReportFeeMerchant
   */
  readonly sort?: Array<string>;
}

/**
 * Request parameters for getReportFeeMerchantPortal operation in FeeControllerApi.
 * @export
 * @interface FeeControllerApiGetReportFeeMerchantPortalRequest
 */
export interface FeeControllerApiGetReportFeeMerchantPortalRequest {
  /**
   *
   * @type {string}
   * @memberof FeeControllerApiGetReportFeeMerchantPortal
   */
  readonly fromDate: string;

  /**
   *
   * @type {string}
   * @memberof FeeControllerApiGetReportFeeMerchantPortal
   */
  readonly toDate: string;

  /**
   *
   * @type {'WAITING_PAYMENT' | 'PAID' | 'PROVISIONAL'}
   * @memberof FeeControllerApiGetReportFeeMerchantPortal
   */
  readonly status?: "WAITING_PAYMENT" | "PAID" | "PROVISIONAL";

  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof FeeControllerApiGetReportFeeMerchantPortal
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof FeeControllerApiGetReportFeeMerchantPortal
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof FeeControllerApiGetReportFeeMerchantPortal
   */
  readonly sort?: Array<string>;
}

/**
 * Request parameters for getTransactionFeeReport operation in FeeControllerApi.
 * @export
 * @interface FeeControllerApiGetTransactionFeeReportRequest
 */
export interface FeeControllerApiGetTransactionFeeReportRequest {
  /**
   *
   * @type {string}
   * @memberof FeeControllerApiGetTransactionFeeReport
   */
  readonly feeReportId?: string;

  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof FeeControllerApiGetTransactionFeeReport
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof FeeControllerApiGetTransactionFeeReport
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof FeeControllerApiGetTransactionFeeReport
   */
  readonly sort?: Array<string>;
}

/**
 * Request parameters for getTransactionFeeReportPortal operation in FeeControllerApi.
 * @export
 * @interface FeeControllerApiGetTransactionFeeReportPortalRequest
 */
export interface FeeControllerApiGetTransactionFeeReportPortalRequest {
  /**
   *
   * @type {string}
   * @memberof FeeControllerApiGetTransactionFeeReportPortal
   */
  readonly feeReportId?: string;

  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof FeeControllerApiGetTransactionFeeReportPortal
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof FeeControllerApiGetTransactionFeeReportPortal
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof FeeControllerApiGetTransactionFeeReportPortal
   */
  readonly sort?: Array<string>;
}

/**
 * Request parameters for payFeeMerchant operation in FeeControllerApi.
 * @export
 * @interface FeeControllerApiPayFeeMerchantRequest
 */
export interface FeeControllerApiPayFeeMerchantRequest {
  /**
   *
   * @type {number}
   * @memberof FeeControllerApiPayFeeMerchant
   */
  readonly id: number;
}

/**
 * Request parameters for payFeeReportCms operation in FeeControllerApi.
 * @export
 * @interface FeeControllerApiPayFeeReportCmsRequest
 */
export interface FeeControllerApiPayFeeReportCmsRequest {
  /**
   *
   * @type {PayFeeReportCmsRequest}
   * @memberof FeeControllerApiPayFeeReportCms
   */
  readonly payFeeReportCmsRequest: PayFeeReportCmsRequest;
}

/**
 * Request parameters for sendMail operation in FeeControllerApi.
 * @export
 * @interface FeeControllerApiSendMailRequest
 */
export interface FeeControllerApiSendMailRequest {
  /**
   *
   * @type {SendEmailFeeReportRequest}
   * @memberof FeeControllerApiSendMail
   */
  readonly sendEmailFeeReportRequest: SendEmailFeeReportRequest;
}

/**
 * Request parameters for update1 operation in FeeControllerApi.
 * @export
 * @interface FeeControllerApiUpdate1Request
 */
export interface FeeControllerApiUpdate1Request {
  /**
   *
   * @type {UpdateFeeRequest}
   * @memberof FeeControllerApiUpdate1
   */
  readonly updateFeeRequest: UpdateFeeRequest;
}

/**
 * FeeControllerApi - object-oriented interface
 * @export
 * @class FeeControllerApi
 * @extends {BaseAPI}
 */
export class FeeControllerApi extends BaseAPI {
  /**
   *
   * @summary Thêm biểu phí
   * @param {FeeControllerApiAddRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeeControllerApi
   */
  public add(
    requestParameters: FeeControllerApiAddRequest,
    options?: AxiosRequestConfig,
  ) {
    return FeeControllerApiFp(this.configuration)
      .add(requestParameters.addFeeRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Duyệt biểu phí
   * @param {FeeControllerApiApprove1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeeControllerApi
   */
  public approve1(
    requestParameters: FeeControllerApiApprove1Request,
    options?: AxiosRequestConfig,
  ) {
    return FeeControllerApiFp(this.configuration)
      .approve1(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [Cms] Tính toán phí
   * @param {FeeControllerApiCalFeeReportCmsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeeControllerApi
   */
  public calFeeReportCms(
    requestParameters: FeeControllerApiCalFeeReportCmsRequest,
    options?: AxiosRequestConfig,
  ) {
    return FeeControllerApiFp(this.configuration)
      .calFeeReportCms(requestParameters.date, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [CMS] Kiểm tra mã biểu phí
   * @param {FeeControllerApiCheckFeeCodeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeeControllerApi
   */
  public checkFeeCode(
    requestParameters: FeeControllerApiCheckFeeCodeRequest,
    options?: AxiosRequestConfig,
  ) {
    return FeeControllerApiFp(this.configuration)
      .checkFeeCode(requestParameters.code, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xoá biểu phí
   * @param {FeeControllerApiDelete1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeeControllerApi
   */
  public delete1(
    requestParameters: FeeControllerApiDelete1Request,
    options?: AxiosRequestConfig,
  ) {
    return FeeControllerApiFp(this.configuration)
      .delete1(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Từ chối duyệt biểu phí
   * @param {FeeControllerApiDeny1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeeControllerApi
   */
  public deny1(
    requestParameters: FeeControllerApiDeny1Request,
    options?: AxiosRequestConfig,
  ) {
    return FeeControllerApiFp(this.configuration)
      .deny1(requestParameters.id, requestParameters.reasonDeny, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [CMS] Xuất file báo cáo phí
   * @param {FeeControllerApiExportReportFeeCmsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeeControllerApi
   */
  public exportReportFeeCms(
    requestParameters: FeeControllerApiExportReportFeeCmsRequest,
    options?: AxiosRequestConfig,
  ) {
    return FeeControllerApiFp(this.configuration)
      .exportReportFeeCms(
        requestParameters.fromDate,
        requestParameters.toDate,
        requestParameters.prefix,
        requestParameters.merchantName,
        requestParameters.status,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [CMS] Xuất file chi tiết báo cáo phí
   * @param {FeeControllerApiExportReportFeeDetailRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeeControllerApi
   */
  public exportReportFeeDetail(
    requestParameters: FeeControllerApiExportReportFeeDetailRequest,
    options?: AxiosRequestConfig,
  ) {
    return FeeControllerApiFp(this.configuration)
      .exportReportFeeDetail(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [Portal] Xuất file báo cáo phí
   * @param {FeeControllerApiExportReportFeePortalRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeeControllerApi
   */
  public exportReportFeePortal(
    requestParameters: FeeControllerApiExportReportFeePortalRequest,
    options?: AxiosRequestConfig,
  ) {
    return FeeControllerApiFp(this.configuration)
      .exportReportFeePortal(
        requestParameters.fromDate,
        requestParameters.toDate,
        requestParameters.status,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [CMS] Sinh mã biểu phí
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeeControllerApi
   */
  public genFeeCode(options?: AxiosRequestConfig) {
    return FeeControllerApiFp(this.configuration)
      .genFeeCode(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy tất cả biểu phí
   * @param {FeeControllerApiGetAll1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeeControllerApi
   */
  public getAll1(
    requestParameters: FeeControllerApiGetAll1Request = {},
    options?: AxiosRequestConfig,
  ) {
    return FeeControllerApiFp(this.configuration)
      .getAll1(
        requestParameters.feeName,
        requestParameters.feeCode,
        requestParameters.feeStatus,
        requestParameters.fromDate,
        requestParameters.toDate,
        requestParameters.serviceType,
        requestParameters.feeMethod,
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy tất cả biểu phí theo service type
   * @param {FeeControllerApiGetAllByServiceTypeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeeControllerApi
   */
  public getAllByServiceType(
    requestParameters: FeeControllerApiGetAllByServiceTypeRequest,
    options?: AxiosRequestConfig,
  ) {
    return FeeControllerApiFp(this.configuration)
      .getAllByServiceType(requestParameters.serviceType, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy chi tiết biểu phí
   * @param {FeeControllerApiGetDetail2Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeeControllerApi
   */
  public getDetail2(
    requestParameters: FeeControllerApiGetDetail2Request,
    options?: AxiosRequestConfig,
  ) {
    return FeeControllerApiFp(this.configuration)
      .getDetail2(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy lịch sử thay đổi trạng thái biểu phí cms
   * @param {FeeControllerApiGetHistoryFeeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeeControllerApi
   */
  public getHistoryFee(
    requestParameters: FeeControllerApiGetHistoryFeeRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return FeeControllerApiFp(this.configuration)
      .getHistoryFee(
        requestParameters.feeId,
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [Cms]Lấy chi tiết báo cáo phí Cms theo merchant
   * @param {FeeControllerApiGetReportFeeDetailRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeeControllerApi
   */
  public getReportFeeDetail(
    requestParameters: FeeControllerApiGetReportFeeDetailRequest,
    options?: AxiosRequestConfig,
  ) {
    return FeeControllerApiFp(this.configuration)
      .getReportFeeDetail(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [Portal]Lấy chi tiết báo cáo phí
   * @param {FeeControllerApiGetReportFeeDetailPortalRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeeControllerApi
   */
  public getReportFeeDetailPortal(
    requestParameters: FeeControllerApiGetReportFeeDetailPortalRequest,
    options?: AxiosRequestConfig,
  ) {
    return FeeControllerApiFp(this.configuration)
      .getReportFeeDetailPortal(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [Cms]Lấy danh sách báo cáo phí Cms theo merchant
   * @param {FeeControllerApiGetReportFeeMerchantRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeeControllerApi
   */
  public getReportFeeMerchant(
    requestParameters: FeeControllerApiGetReportFeeMerchantRequest,
    options?: AxiosRequestConfig,
  ) {
    return FeeControllerApiFp(this.configuration)
      .getReportFeeMerchant(
        requestParameters.fromDate,
        requestParameters.toDate,
        requestParameters.merchantName,
        requestParameters.prefix,
        requestParameters.status,
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [Portal]Lấy danh sách báo cáo phí
   * @param {FeeControllerApiGetReportFeeMerchantPortalRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeeControllerApi
   */
  public getReportFeeMerchantPortal(
    requestParameters: FeeControllerApiGetReportFeeMerchantPortalRequest,
    options?: AxiosRequestConfig,
  ) {
    return FeeControllerApiFp(this.configuration)
      .getReportFeeMerchantPortal(
        requestParameters.fromDate,
        requestParameters.toDate,
        requestParameters.status,
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [Cms]Lấy chi tiết danh sách giao dịch báo cáo phí Cms
   * @param {FeeControllerApiGetTransactionFeeReportRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeeControllerApi
   */
  public getTransactionFeeReport(
    requestParameters: FeeControllerApiGetTransactionFeeReportRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return FeeControllerApiFp(this.configuration)
      .getTransactionFeeReport(
        requestParameters.feeReportId,
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [Portal]Lấy chi tiết danh sách giao dịch báo cáo phí Portal
   * @param {FeeControllerApiGetTransactionFeeReportPortalRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeeControllerApi
   */
  public getTransactionFeeReportPortal(
    requestParameters: FeeControllerApiGetTransactionFeeReportPortalRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return FeeControllerApiFp(this.configuration)
      .getTransactionFeeReportPortal(
        requestParameters.feeReportId,
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [PORTAL] Thanh toán báo cáo phí
   * @param {FeeControllerApiPayFeeMerchantRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeeControllerApi
   */
  public payFeeMerchant(
    requestParameters: FeeControllerApiPayFeeMerchantRequest,
    options?: AxiosRequestConfig,
  ) {
    return FeeControllerApiFp(this.configuration)
      .payFeeMerchant(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [Cms] Thanh toán báo cáo phí theo tháng
   * @param {FeeControllerApiPayFeeReportCmsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeeControllerApi
   */
  public payFeeReportCms(
    requestParameters: FeeControllerApiPayFeeReportCmsRequest,
    options?: AxiosRequestConfig,
  ) {
    return FeeControllerApiFp(this.configuration)
      .payFeeReportCms(requestParameters.payFeeReportCmsRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [Cms] SendMail
   * @param {FeeControllerApiSendMailRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeeControllerApi
   */
  public sendMail(
    requestParameters: FeeControllerApiSendMailRequest,
    options?: AxiosRequestConfig,
  ) {
    return FeeControllerApiFp(this.configuration)
      .sendMail(requestParameters.sendEmailFeeReportRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Cập nhật biểu phí
   * @param {FeeControllerApiUpdate1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeeControllerApi
   */
  public update1(
    requestParameters: FeeControllerApiUpdate1Request,
    options?: AxiosRequestConfig,
  ) {
    return FeeControllerApiFp(this.configuration)
      .update1(requestParameters.updateFeeRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ForControlControllerApi - axios parameter creator
 * @export
 */
export const ForControlControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary PORTAL: Tạo yêu cầu đối soát với transaction
     * @param {AddForControlPortalRequest} addForControlPortalRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addForControl: async (
      addForControlPortalRequest: AddForControlPortalRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'addForControlPortalRequest' is not null or undefined
      assertParamExists(
        "addForControl",
        "addForControlPortalRequest",
        addForControlPortalRequest,
      );
      const localVarPath = `/api/forcontrol/portal/v1`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        addForControlPortalRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary CMS:Duyệt yêu cầu đối soát với id đối soát
     * @param {ApproveForControlCmsRequest} approveForControlCmsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approve: async (
      approveForControlCmsRequest: ApproveForControlCmsRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'approveForControlCmsRequest' is not null or undefined
      assertParamExists(
        "approve",
        "approveForControlCmsRequest",
        approveForControlCmsRequest,
      );
      const localVarPath = `/api/forcontrol/cms/v1/approve`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        approveForControlCmsRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary CMS: Lấy danh sách đối soát
     * @param {string} [forControlCode]
     * @param {string} [txnNumber]
     * @param {string} [merchantCode]
     * @param {string} [merchantName]
     * @param {'CREATE' | 'WAITING_SOLVE' | 'SOLVING' | 'SUCCESS' | 'DENY'} [status]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsGetAll: async (
      forControlCode?: string,
      txnNumber?: string,
      merchantCode?: string,
      merchantName?: string,
      status?: "CREATE" | "WAITING_SOLVE" | "SOLVING" | "SUCCESS" | "DENY",
      fromDate?: string,
      toDate?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/forcontrol/cms/v1`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (forControlCode !== undefined) {
        localVarQueryParameter["forControlCode"] = forControlCode;
      }

      if (txnNumber !== undefined) {
        localVarQueryParameter["txnNumber"] = txnNumber;
      }

      if (merchantCode !== undefined) {
        localVarQueryParameter["merchantCode"] = merchantCode;
      }

      if (merchantName !== undefined) {
        localVarQueryParameter["merchantName"] = merchantName;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (fromDate !== undefined) {
        localVarQueryParameter["fromDate"] = fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter["toDate"] = toDate;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary CMS: Lấy danh sách lịch sử đối soát
     * @param {string} code
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsHistoryGetAll: async (
      code: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists("cmsHistoryGetAll", "code", code);
      const localVarPath = `/api/forcontrol/cms/v1/history`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (code !== undefined) {
        localVarQueryParameter["code"] = code;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary PORTAL: Xóa dữ liệu đối soát
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteForControl: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("deleteForControl", "id", id);
      const localVarPath = `/api/forcontrol/portal/v1/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Từ chối duyệt yêu cầu đối soát với id đối soát
     * @param {DenyForControlCmsRequest} denyForControlCmsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deny: async (
      denyForControlCmsRequest: DenyForControlCmsRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'denyForControlCmsRequest' is not null or undefined
      assertParamExists(
        "deny",
        "denyForControlCmsRequest",
        denyForControlCmsRequest,
      );
      const localVarPath = `/api/forcontrol/cms/v1/deny`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        denyForControlCmsRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary CMS: Xuất báo cáo pdf đối soát
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportPdfCms: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("exportPdfCms", "id", id);
      const localVarPath = `/api/forcontrol/cms/v1/exportPdf/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [CMS] Xuất file excel danh sách đối soát
     * @param {string} [forControlCode]
     * @param {string} [txnNumber]
     * @param {string} [merchantCode]
     * @param {string} [merchantName]
     * @param {'CREATE' | 'WAITING_SOLVE' | 'SOLVING' | 'SUCCESS' | 'DENY'} [status]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportReportCms: async (
      forControlCode?: string,
      txnNumber?: string,
      merchantCode?: string,
      merchantName?: string,
      status?: "CREATE" | "WAITING_SOLVE" | "SOLVING" | "SUCCESS" | "DENY",
      fromDate?: string,
      toDate?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/forcontrol/cms/v1/exportExcel`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (forControlCode !== undefined) {
        localVarQueryParameter["forControlCode"] = forControlCode;
      }

      if (txnNumber !== undefined) {
        localVarQueryParameter["txnNumber"] = txnNumber;
      }

      if (merchantCode !== undefined) {
        localVarQueryParameter["merchantCode"] = merchantCode;
      }

      if (merchantName !== undefined) {
        localVarQueryParameter["merchantName"] = merchantName;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (fromDate !== undefined) {
        localVarQueryParameter["fromDate"] = fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter["toDate"] = toDate;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [PORTAL] Xuất file excel danh sách đối soát
     * @param {string} [keyword]
     * @param {'CREATE' | 'WAITING_SOLVE' | 'SOLVING' | 'SUCCESS' | 'DENY'} [status]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportReportPortal: async (
      keyword?: string,
      status?: "CREATE" | "WAITING_SOLVE" | "SOLVING" | "SUCCESS" | "DENY",
      fromDate?: string,
      toDate?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/forcontrol/portal/v1/exportExcel`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (keyword !== undefined) {
        localVarQueryParameter["keyword"] = keyword;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (fromDate !== undefined) {
        localVarQueryParameter["fromDate"] = fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter["toDate"] = toDate;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary PORTAL: Xem chi tiết đối soát
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDetailForControl: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getDetailForControl", "id", id);
      const localVarPath = `/api/forcontrol/portal/v1/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary CMS: Xem chi tiết đối soát
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDetailForControlCMS: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getDetailForControlCMS", "id", id);
      const localVarPath = `/api/forcontrol/cms/v1/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary PORTAL: Lấy danh sách đối soát
     * @param {string} [keyword]
     * @param {'CREATE' | 'WAITING_SOLVE' | 'SOLVING' | 'SUCCESS' | 'DENY'} [status]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    portalGetAll: async (
      keyword?: string,
      status?: "CREATE" | "WAITING_SOLVE" | "SOLVING" | "SUCCESS" | "DENY",
      fromDate?: string,
      toDate?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/forcontrol/portal/v1`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (keyword !== undefined) {
        localVarQueryParameter["keyword"] = keyword;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (fromDate !== undefined) {
        localVarQueryParameter["fromDate"] = fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter["toDate"] = toDate;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Portal: Lấy danh sách lịch sử đối soát
     * @param {string} code
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    portalHistoryGetAll: async (
      code: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists("portalHistoryGetAll", "code", code);
      const localVarPath = `/api/forcontrol/portal/v1/history`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (code !== undefined) {
        localVarQueryParameter["code"] = code;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary CMS:Tiếp nhận yêu cầu đối soát và xử lý với id đối soát
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    solving: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("solving", "id", id);
      const localVarPath = `/api/forcontrol/cms/v1/solving/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ForControlControllerApi - functional programming interface
 * @export
 */
export const ForControlControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    ForControlControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary PORTAL: Tạo yêu cầu đối soát với transaction
     * @param {AddForControlPortalRequest} addForControlPortalRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addForControl(
      addForControlPortalRequest: AddForControlPortalRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseForControlResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addForControl(
        addForControlPortalRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary CMS:Duyệt yêu cầu đối soát với id đối soát
     * @param {ApproveForControlCmsRequest} approveForControlCmsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async approve(
      approveForControlCmsRequest: ApproveForControlCmsRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseForControlResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.approve(
        approveForControlCmsRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary CMS: Lấy danh sách đối soát
     * @param {string} [forControlCode]
     * @param {string} [txnNumber]
     * @param {string} [merchantCode]
     * @param {string} [merchantName]
     * @param {'CREATE' | 'WAITING_SOLVE' | 'SOLVING' | 'SUCCESS' | 'DENY'} [status]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cmsGetAll(
      forControlCode?: string,
      txnNumber?: string,
      merchantCode?: string,
      merchantName?: string,
      status?: "CREATE" | "WAITING_SOLVE" | "SOLVING" | "SUCCESS" | "DENY",
      fromDate?: string,
      toDate?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseForControlDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.cmsGetAll(
        forControlCode,
        txnNumber,
        merchantCode,
        merchantName,
        status,
        fromDate,
        toDate,
        page,
        size,
        sort,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary CMS: Lấy danh sách lịch sử đối soát
     * @param {string} code
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cmsHistoryGetAll(
      code: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseForControlHistoryDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cmsHistoryGetAll(
          code,
          page,
          size,
          sort,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary PORTAL: Xóa dữ liệu đối soát
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteForControl(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteForControl(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Từ chối duyệt yêu cầu đối soát với id đối soát
     * @param {DenyForControlCmsRequest} denyForControlCmsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deny(
      denyForControlCmsRequest: DenyForControlCmsRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseForControlResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deny(
        denyForControlCmsRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary CMS: Xuất báo cáo pdf đối soát
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportPdfCms(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.exportPdfCms(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary [CMS] Xuất file excel danh sách đối soát
     * @param {string} [forControlCode]
     * @param {string} [txnNumber]
     * @param {string} [merchantCode]
     * @param {string} [merchantName]
     * @param {'CREATE' | 'WAITING_SOLVE' | 'SOLVING' | 'SUCCESS' | 'DENY'} [status]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportReportCms(
      forControlCode?: string,
      txnNumber?: string,
      merchantCode?: string,
      merchantName?: string,
      status?: "CREATE" | "WAITING_SOLVE" | "SOLVING" | "SUCCESS" | "DENY",
      fromDate?: string,
      toDate?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.exportReportCms(
        forControlCode,
        txnNumber,
        merchantCode,
        merchantName,
        status,
        fromDate,
        toDate,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary [PORTAL] Xuất file excel danh sách đối soát
     * @param {string} [keyword]
     * @param {'CREATE' | 'WAITING_SOLVE' | 'SOLVING' | 'SUCCESS' | 'DENY'} [status]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportReportPortal(
      keyword?: string,
      status?: "CREATE" | "WAITING_SOLVE" | "SOLVING" | "SUCCESS" | "DENY",
      fromDate?: string,
      toDate?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.exportReportPortal(
          keyword,
          status,
          fromDate,
          toDate,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary PORTAL: Xem chi tiết đối soát
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDetailForControl(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseForControlResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getDetailForControl(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary CMS: Xem chi tiết đối soát
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDetailForControlCMS(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseForControlResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getDetailForControlCMS(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary PORTAL: Lấy danh sách đối soát
     * @param {string} [keyword]
     * @param {'CREATE' | 'WAITING_SOLVE' | 'SOLVING' | 'SUCCESS' | 'DENY'} [status]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async portalGetAll(
      keyword?: string,
      status?: "CREATE" | "WAITING_SOLVE" | "SOLVING" | "SUCCESS" | "DENY",
      fromDate?: string,
      toDate?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseForControlDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.portalGetAll(
        keyword,
        status,
        fromDate,
        toDate,
        page,
        size,
        sort,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Portal: Lấy danh sách lịch sử đối soát
     * @param {string} code
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async portalHistoryGetAll(
      code: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseForControlHistoryDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.portalHistoryGetAll(
          code,
          page,
          size,
          sort,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary CMS:Tiếp nhận yêu cầu đối soát và xử lý với id đối soát
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async solving(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseForControlResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.solving(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * ForControlControllerApi - factory interface
 * @export
 */
export const ForControlControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ForControlControllerApiFp(configuration);
  return {
    /**
     *
     * @summary PORTAL: Tạo yêu cầu đối soát với transaction
     * @param {AddForControlPortalRequest} addForControlPortalRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addForControl(
      addForControlPortalRequest: AddForControlPortalRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseForControlResponse> {
      return localVarFp
        .addForControl(addForControlPortalRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary CMS:Duyệt yêu cầu đối soát với id đối soát
     * @param {ApproveForControlCmsRequest} approveForControlCmsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approve(
      approveForControlCmsRequest: ApproveForControlCmsRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseForControlResponse> {
      return localVarFp
        .approve(approveForControlCmsRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary CMS: Lấy danh sách đối soát
     * @param {string} [forControlCode]
     * @param {string} [txnNumber]
     * @param {string} [merchantCode]
     * @param {string} [merchantName]
     * @param {'CREATE' | 'WAITING_SOLVE' | 'SOLVING' | 'SUCCESS' | 'DENY'} [status]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsGetAll(
      forControlCode?: string,
      txnNumber?: string,
      merchantCode?: string,
      merchantName?: string,
      status?: "CREATE" | "WAITING_SOLVE" | "SOLVING" | "SUCCESS" | "DENY",
      fromDate?: string,
      toDate?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseForControlDto> {
      return localVarFp
        .cmsGetAll(
          forControlCode,
          txnNumber,
          merchantCode,
          merchantName,
          status,
          fromDate,
          toDate,
          page,
          size,
          sort,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary CMS: Lấy danh sách lịch sử đối soát
     * @param {string} code
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsHistoryGetAll(
      code: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseForControlHistoryDto> {
      return localVarFp
        .cmsHistoryGetAll(code, page, size, sort, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary PORTAL: Xóa dữ liệu đối soát
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteForControl(
      id: number,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .deleteForControl(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Từ chối duyệt yêu cầu đối soát với id đối soát
     * @param {DenyForControlCmsRequest} denyForControlCmsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deny(
      denyForControlCmsRequest: DenyForControlCmsRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseForControlResponse> {
      return localVarFp
        .deny(denyForControlCmsRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary CMS: Xuất báo cáo pdf đối soát
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportPdfCms(id: number, options?: any): AxiosPromise<File> {
      return localVarFp
        .exportPdfCms(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [CMS] Xuất file excel danh sách đối soát
     * @param {string} [forControlCode]
     * @param {string} [txnNumber]
     * @param {string} [merchantCode]
     * @param {string} [merchantName]
     * @param {'CREATE' | 'WAITING_SOLVE' | 'SOLVING' | 'SUCCESS' | 'DENY'} [status]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportReportCms(
      forControlCode?: string,
      txnNumber?: string,
      merchantCode?: string,
      merchantName?: string,
      status?: "CREATE" | "WAITING_SOLVE" | "SOLVING" | "SUCCESS" | "DENY",
      fromDate?: string,
      toDate?: string,
      options?: any,
    ): AxiosPromise<File> {
      return localVarFp
        .exportReportCms(
          forControlCode,
          txnNumber,
          merchantCode,
          merchantName,
          status,
          fromDate,
          toDate,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [PORTAL] Xuất file excel danh sách đối soát
     * @param {string} [keyword]
     * @param {'CREATE' | 'WAITING_SOLVE' | 'SOLVING' | 'SUCCESS' | 'DENY'} [status]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportReportPortal(
      keyword?: string,
      status?: "CREATE" | "WAITING_SOLVE" | "SOLVING" | "SUCCESS" | "DENY",
      fromDate?: string,
      toDate?: string,
      options?: any,
    ): AxiosPromise<File> {
      return localVarFp
        .exportReportPortal(keyword, status, fromDate, toDate, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary PORTAL: Xem chi tiết đối soát
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDetailForControl(
      id: number,
      options?: any,
    ): AxiosPromise<ResponseBaseForControlResponse> {
      return localVarFp
        .getDetailForControl(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary CMS: Xem chi tiết đối soát
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDetailForControlCMS(
      id: number,
      options?: any,
    ): AxiosPromise<ResponseBaseForControlResponse> {
      return localVarFp
        .getDetailForControlCMS(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary PORTAL: Lấy danh sách đối soát
     * @param {string} [keyword]
     * @param {'CREATE' | 'WAITING_SOLVE' | 'SOLVING' | 'SUCCESS' | 'DENY'} [status]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    portalGetAll(
      keyword?: string,
      status?: "CREATE" | "WAITING_SOLVE" | "SOLVING" | "SUCCESS" | "DENY",
      fromDate?: string,
      toDate?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseForControlDto> {
      return localVarFp
        .portalGetAll(
          keyword,
          status,
          fromDate,
          toDate,
          page,
          size,
          sort,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Portal: Lấy danh sách lịch sử đối soát
     * @param {string} code
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    portalHistoryGetAll(
      code: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseForControlHistoryDto> {
      return localVarFp
        .portalHistoryGetAll(code, page, size, sort, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary CMS:Tiếp nhận yêu cầu đối soát và xử lý với id đối soát
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    solving(
      id: number,
      options?: any,
    ): AxiosPromise<ResponseBaseForControlResponse> {
      return localVarFp
        .solving(id, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for addForControl operation in ForControlControllerApi.
 * @export
 * @interface ForControlControllerApiAddForControlRequest
 */
export interface ForControlControllerApiAddForControlRequest {
  /**
   *
   * @type {AddForControlPortalRequest}
   * @memberof ForControlControllerApiAddForControl
   */
  readonly addForControlPortalRequest: AddForControlPortalRequest;
}

/**
 * Request parameters for approve operation in ForControlControllerApi.
 * @export
 * @interface ForControlControllerApiApproveRequest
 */
export interface ForControlControllerApiApproveRequest {
  /**
   *
   * @type {ApproveForControlCmsRequest}
   * @memberof ForControlControllerApiApprove
   */
  readonly approveForControlCmsRequest: ApproveForControlCmsRequest;
}

/**
 * Request parameters for cmsGetAll operation in ForControlControllerApi.
 * @export
 * @interface ForControlControllerApiCmsGetAllRequest
 */
export interface ForControlControllerApiCmsGetAllRequest {
  /**
   *
   * @type {string}
   * @memberof ForControlControllerApiCmsGetAll
   */
  readonly forControlCode?: string;

  /**
   *
   * @type {string}
   * @memberof ForControlControllerApiCmsGetAll
   */
  readonly txnNumber?: string;

  /**
   *
   * @type {string}
   * @memberof ForControlControllerApiCmsGetAll
   */
  readonly merchantCode?: string;

  /**
   *
   * @type {string}
   * @memberof ForControlControllerApiCmsGetAll
   */
  readonly merchantName?: string;

  /**
   *
   * @type {'CREATE' | 'WAITING_SOLVE' | 'SOLVING' | 'SUCCESS' | 'DENY'}
   * @memberof ForControlControllerApiCmsGetAll
   */
  readonly status?: "CREATE" | "WAITING_SOLVE" | "SOLVING" | "SUCCESS" | "DENY";

  /**
   *
   * @type {string}
   * @memberof ForControlControllerApiCmsGetAll
   */
  readonly fromDate?: string;

  /**
   *
   * @type {string}
   * @memberof ForControlControllerApiCmsGetAll
   */
  readonly toDate?: string;

  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof ForControlControllerApiCmsGetAll
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof ForControlControllerApiCmsGetAll
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof ForControlControllerApiCmsGetAll
   */
  readonly sort?: Array<string>;
}

/**
 * Request parameters for cmsHistoryGetAll operation in ForControlControllerApi.
 * @export
 * @interface ForControlControllerApiCmsHistoryGetAllRequest
 */
export interface ForControlControllerApiCmsHistoryGetAllRequest {
  /**
   *
   * @type {string}
   * @memberof ForControlControllerApiCmsHistoryGetAll
   */
  readonly code: string;

  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof ForControlControllerApiCmsHistoryGetAll
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof ForControlControllerApiCmsHistoryGetAll
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof ForControlControllerApiCmsHistoryGetAll
   */
  readonly sort?: Array<string>;
}

/**
 * Request parameters for deleteForControl operation in ForControlControllerApi.
 * @export
 * @interface ForControlControllerApiDeleteForControlRequest
 */
export interface ForControlControllerApiDeleteForControlRequest {
  /**
   *
   * @type {number}
   * @memberof ForControlControllerApiDeleteForControl
   */
  readonly id: number;
}

/**
 * Request parameters for deny operation in ForControlControllerApi.
 * @export
 * @interface ForControlControllerApiDenyRequest
 */
export interface ForControlControllerApiDenyRequest {
  /**
   *
   * @type {DenyForControlCmsRequest}
   * @memberof ForControlControllerApiDeny
   */
  readonly denyForControlCmsRequest: DenyForControlCmsRequest;
}

/**
 * Request parameters for exportPdfCms operation in ForControlControllerApi.
 * @export
 * @interface ForControlControllerApiExportPdfCmsRequest
 */
export interface ForControlControllerApiExportPdfCmsRequest {
  /**
   *
   * @type {number}
   * @memberof ForControlControllerApiExportPdfCms
   */
  readonly id: number;
}

/**
 * Request parameters for exportReportCms operation in ForControlControllerApi.
 * @export
 * @interface ForControlControllerApiExportReportCmsRequest
 */
export interface ForControlControllerApiExportReportCmsRequest {
  /**
   *
   * @type {string}
   * @memberof ForControlControllerApiExportReportCms
   */
  readonly forControlCode?: string;

  /**
   *
   * @type {string}
   * @memberof ForControlControllerApiExportReportCms
   */
  readonly txnNumber?: string;

  /**
   *
   * @type {string}
   * @memberof ForControlControllerApiExportReportCms
   */
  readonly merchantCode?: string;

  /**
   *
   * @type {string}
   * @memberof ForControlControllerApiExportReportCms
   */
  readonly merchantName?: string;

  /**
   *
   * @type {'CREATE' | 'WAITING_SOLVE' | 'SOLVING' | 'SUCCESS' | 'DENY'}
   * @memberof ForControlControllerApiExportReportCms
   */
  readonly status?: "CREATE" | "WAITING_SOLVE" | "SOLVING" | "SUCCESS" | "DENY";

  /**
   *
   * @type {string}
   * @memberof ForControlControllerApiExportReportCms
   */
  readonly fromDate?: string;

  /**
   *
   * @type {string}
   * @memberof ForControlControllerApiExportReportCms
   */
  readonly toDate?: string;
}

/**
 * Request parameters for exportReportPortal operation in ForControlControllerApi.
 * @export
 * @interface ForControlControllerApiExportReportPortalRequest
 */
export interface ForControlControllerApiExportReportPortalRequest {
  /**
   *
   * @type {string}
   * @memberof ForControlControllerApiExportReportPortal
   */
  readonly keyword?: string;

  /**
   *
   * @type {'CREATE' | 'WAITING_SOLVE' | 'SOLVING' | 'SUCCESS' | 'DENY'}
   * @memberof ForControlControllerApiExportReportPortal
   */
  readonly status?: "CREATE" | "WAITING_SOLVE" | "SOLVING" | "SUCCESS" | "DENY";

  /**
   *
   * @type {string}
   * @memberof ForControlControllerApiExportReportPortal
   */
  readonly fromDate?: string;

  /**
   *
   * @type {string}
   * @memberof ForControlControllerApiExportReportPortal
   */
  readonly toDate?: string;
}

/**
 * Request parameters for getDetailForControl operation in ForControlControllerApi.
 * @export
 * @interface ForControlControllerApiGetDetailForControlRequest
 */
export interface ForControlControllerApiGetDetailForControlRequest {
  /**
   *
   * @type {number}
   * @memberof ForControlControllerApiGetDetailForControl
   */
  readonly id: number;
}

/**
 * Request parameters for getDetailForControlCMS operation in ForControlControllerApi.
 * @export
 * @interface ForControlControllerApiGetDetailForControlCMSRequest
 */
export interface ForControlControllerApiGetDetailForControlCMSRequest {
  /**
   *
   * @type {number}
   * @memberof ForControlControllerApiGetDetailForControlCMS
   */
  readonly id: number;
}

/**
 * Request parameters for portalGetAll operation in ForControlControllerApi.
 * @export
 * @interface ForControlControllerApiPortalGetAllRequest
 */
export interface ForControlControllerApiPortalGetAllRequest {
  /**
   *
   * @type {string}
   * @memberof ForControlControllerApiPortalGetAll
   */
  readonly keyword?: string;

  /**
   *
   * @type {'CREATE' | 'WAITING_SOLVE' | 'SOLVING' | 'SUCCESS' | 'DENY'}
   * @memberof ForControlControllerApiPortalGetAll
   */
  readonly status?: "CREATE" | "WAITING_SOLVE" | "SOLVING" | "SUCCESS" | "DENY";

  /**
   *
   * @type {string}
   * @memberof ForControlControllerApiPortalGetAll
   */
  readonly fromDate?: string;

  /**
   *
   * @type {string}
   * @memberof ForControlControllerApiPortalGetAll
   */
  readonly toDate?: string;

  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof ForControlControllerApiPortalGetAll
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof ForControlControllerApiPortalGetAll
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof ForControlControllerApiPortalGetAll
   */
  readonly sort?: Array<string>;
}

/**
 * Request parameters for portalHistoryGetAll operation in ForControlControllerApi.
 * @export
 * @interface ForControlControllerApiPortalHistoryGetAllRequest
 */
export interface ForControlControllerApiPortalHistoryGetAllRequest {
  /**
   *
   * @type {string}
   * @memberof ForControlControllerApiPortalHistoryGetAll
   */
  readonly code: string;

  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof ForControlControllerApiPortalHistoryGetAll
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof ForControlControllerApiPortalHistoryGetAll
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof ForControlControllerApiPortalHistoryGetAll
   */
  readonly sort?: Array<string>;
}

/**
 * Request parameters for solving operation in ForControlControllerApi.
 * @export
 * @interface ForControlControllerApiSolvingRequest
 */
export interface ForControlControllerApiSolvingRequest {
  /**
   *
   * @type {number}
   * @memberof ForControlControllerApiSolving
   */
  readonly id: number;
}

/**
 * ForControlControllerApi - object-oriented interface
 * @export
 * @class ForControlControllerApi
 * @extends {BaseAPI}
 */
export class ForControlControllerApi extends BaseAPI {
  /**
   *
   * @summary PORTAL: Tạo yêu cầu đối soát với transaction
   * @param {ForControlControllerApiAddForControlRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ForControlControllerApi
   */
  public addForControl(
    requestParameters: ForControlControllerApiAddForControlRequest,
    options?: AxiosRequestConfig,
  ) {
    return ForControlControllerApiFp(this.configuration)
      .addForControl(requestParameters.addForControlPortalRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary CMS:Duyệt yêu cầu đối soát với id đối soát
   * @param {ForControlControllerApiApproveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ForControlControllerApi
   */
  public approve(
    requestParameters: ForControlControllerApiApproveRequest,
    options?: AxiosRequestConfig,
  ) {
    return ForControlControllerApiFp(this.configuration)
      .approve(requestParameters.approveForControlCmsRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary CMS: Lấy danh sách đối soát
   * @param {ForControlControllerApiCmsGetAllRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ForControlControllerApi
   */
  public cmsGetAll(
    requestParameters: ForControlControllerApiCmsGetAllRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return ForControlControllerApiFp(this.configuration)
      .cmsGetAll(
        requestParameters.forControlCode,
        requestParameters.txnNumber,
        requestParameters.merchantCode,
        requestParameters.merchantName,
        requestParameters.status,
        requestParameters.fromDate,
        requestParameters.toDate,
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary CMS: Lấy danh sách lịch sử đối soát
   * @param {ForControlControllerApiCmsHistoryGetAllRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ForControlControllerApi
   */
  public cmsHistoryGetAll(
    requestParameters: ForControlControllerApiCmsHistoryGetAllRequest,
    options?: AxiosRequestConfig,
  ) {
    return ForControlControllerApiFp(this.configuration)
      .cmsHistoryGetAll(
        requestParameters.code,
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary PORTAL: Xóa dữ liệu đối soát
   * @param {ForControlControllerApiDeleteForControlRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ForControlControllerApi
   */
  public deleteForControl(
    requestParameters: ForControlControllerApiDeleteForControlRequest,
    options?: AxiosRequestConfig,
  ) {
    return ForControlControllerApiFp(this.configuration)
      .deleteForControl(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Từ chối duyệt yêu cầu đối soát với id đối soát
   * @param {ForControlControllerApiDenyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ForControlControllerApi
   */
  public deny(
    requestParameters: ForControlControllerApiDenyRequest,
    options?: AxiosRequestConfig,
  ) {
    return ForControlControllerApiFp(this.configuration)
      .deny(requestParameters.denyForControlCmsRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary CMS: Xuất báo cáo pdf đối soát
   * @param {ForControlControllerApiExportPdfCmsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ForControlControllerApi
   */
  public exportPdfCms(
    requestParameters: ForControlControllerApiExportPdfCmsRequest,
    options?: AxiosRequestConfig,
  ) {
    return ForControlControllerApiFp(this.configuration)
      .exportPdfCms(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [CMS] Xuất file excel danh sách đối soát
   * @param {ForControlControllerApiExportReportCmsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ForControlControllerApi
   */
  public exportReportCms(
    requestParameters: ForControlControllerApiExportReportCmsRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return ForControlControllerApiFp(this.configuration)
      .exportReportCms(
        requestParameters.forControlCode,
        requestParameters.txnNumber,
        requestParameters.merchantCode,
        requestParameters.merchantName,
        requestParameters.status,
        requestParameters.fromDate,
        requestParameters.toDate,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [PORTAL] Xuất file excel danh sách đối soát
   * @param {ForControlControllerApiExportReportPortalRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ForControlControllerApi
   */
  public exportReportPortal(
    requestParameters: ForControlControllerApiExportReportPortalRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return ForControlControllerApiFp(this.configuration)
      .exportReportPortal(
        requestParameters.keyword,
        requestParameters.status,
        requestParameters.fromDate,
        requestParameters.toDate,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary PORTAL: Xem chi tiết đối soát
   * @param {ForControlControllerApiGetDetailForControlRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ForControlControllerApi
   */
  public getDetailForControl(
    requestParameters: ForControlControllerApiGetDetailForControlRequest,
    options?: AxiosRequestConfig,
  ) {
    return ForControlControllerApiFp(this.configuration)
      .getDetailForControl(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary CMS: Xem chi tiết đối soát
   * @param {ForControlControllerApiGetDetailForControlCMSRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ForControlControllerApi
   */
  public getDetailForControlCMS(
    requestParameters: ForControlControllerApiGetDetailForControlCMSRequest,
    options?: AxiosRequestConfig,
  ) {
    return ForControlControllerApiFp(this.configuration)
      .getDetailForControlCMS(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary PORTAL: Lấy danh sách đối soát
   * @param {ForControlControllerApiPortalGetAllRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ForControlControllerApi
   */
  public portalGetAll(
    requestParameters: ForControlControllerApiPortalGetAllRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return ForControlControllerApiFp(this.configuration)
      .portalGetAll(
        requestParameters.keyword,
        requestParameters.status,
        requestParameters.fromDate,
        requestParameters.toDate,
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Portal: Lấy danh sách lịch sử đối soát
   * @param {ForControlControllerApiPortalHistoryGetAllRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ForControlControllerApi
   */
  public portalHistoryGetAll(
    requestParameters: ForControlControllerApiPortalHistoryGetAllRequest,
    options?: AxiosRequestConfig,
  ) {
    return ForControlControllerApiFp(this.configuration)
      .portalHistoryGetAll(
        requestParameters.code,
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary CMS:Tiếp nhận yêu cầu đối soát và xử lý với id đối soát
   * @param {ForControlControllerApiSolvingRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ForControlControllerApi
   */
  public solving(
    requestParameters: ForControlControllerApiSolvingRequest,
    options?: AxiosRequestConfig,
  ) {
    return ForControlControllerApiFp(this.configuration)
      .solving(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * JobInvokerControllerApi - axios parameter creator
 * @export
 */
export const JobInvokerControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/batch/invokejob`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * JobInvokerControllerApi - functional programming interface
 * @export
 */
export const JobInvokerControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    JobInvokerControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handles(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.handles(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * JobInvokerControllerApi - factory interface
 * @export
 */
export const JobInvokerControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = JobInvokerControllerApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handles(options?: any): AxiosPromise<string> {
      return localVarFp
        .handles(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * JobInvokerControllerApi - object-oriented interface
 * @export
 * @class JobInvokerControllerApi
 * @extends {BaseAPI}
 */
export class JobInvokerControllerApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobInvokerControllerApi
   */
  public handles(options?: AxiosRequestConfig) {
    return JobInvokerControllerApiFp(this.configuration)
      .handles(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * KeycloakControllerApi - axios parameter creator
 * @export
 */
export const KeycloakControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Migrate keycloak
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    migrateKeycloak: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/keycloak/v1/migrate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * KeycloakControllerApi - functional programming interface
 * @export
 */
export const KeycloakControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    KeycloakControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Migrate keycloak
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async migrateKeycloak(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.migrateKeycloak(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * KeycloakControllerApi - factory interface
 * @export
 */
export const KeycloakControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = KeycloakControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Migrate keycloak
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    migrateKeycloak(options?: any): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .migrateKeycloak(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * KeycloakControllerApi - object-oriented interface
 * @export
 * @class KeycloakControllerApi
 * @extends {BaseAPI}
 */
export class KeycloakControllerApi extends BaseAPI {
  /**
   *
   * @summary Migrate keycloak
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KeycloakControllerApi
   */
  public migrateKeycloak(options?: AxiosRequestConfig) {
    return KeycloakControllerApiFp(this.configuration)
      .migrateKeycloak(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * LoginByQRControllerApi - axios parameter creator
 * @export
 */
export const LoginByQRControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary [STEP 2]: Lấy thông tin login session
     * @param {QrLoginGetInfoRequest} qrLoginGetInfoRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    qrLoginGetInfo: async (
      qrLoginGetInfoRequest: QrLoginGetInfoRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'qrLoginGetInfoRequest' is not null or undefined
      assertParamExists(
        "qrLoginGetInfo",
        "qrLoginGetInfoRequest",
        qrLoginGetInfoRequest,
      );
      const localVarPath = `/api/auth/portal/v1/qrLogin/query`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        qrLoginGetInfoRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [STEP 3]: Lấy thông tin token đăng nhập
     * @param {QrLoginGetTokenRequest} qrLoginGetTokenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    qrLoginGetToken: async (
      qrLoginGetTokenRequest: QrLoginGetTokenRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'qrLoginGetTokenRequest' is not null or undefined
      assertParamExists(
        "qrLoginGetToken",
        "qrLoginGetTokenRequest",
        qrLoginGetTokenRequest,
      );
      const localVarPath = `/api/auth/portal/v1/qrLogin/token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        qrLoginGetTokenRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [STEP 4]: Lấy thông tin user mới
     * @param {QrLoginGetUserInfoRequest} qrLoginGetUserInfoRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    qrLoginGetUserInfo: async (
      qrLoginGetUserInfoRequest: QrLoginGetUserInfoRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'qrLoginGetUserInfoRequest' is not null or undefined
      assertParamExists(
        "qrLoginGetUserInfo",
        "qrLoginGetUserInfoRequest",
        qrLoginGetUserInfoRequest,
      );
      const localVarPath = `/api/auth/portal/v1/qrLogin/getUserInfo`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        qrLoginGetUserInfoRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [STEP 1]: Gửi yêu cầu login với QR code
     * @param {QrLoginRequest} qrLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    qrLoginRequest: async (
      qrLoginRequest: QrLoginRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'qrLoginRequest' is not null or undefined
      assertParamExists("qrLoginRequest", "qrLoginRequest", qrLoginRequest);
      const localVarPath = `/api/auth/portal/v1/qrLogin/request`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        qrLoginRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [QR-REGISTER: 2]: Tạo tài khoản mới và lấy token
     * @param {QrRegisterGetTokenRequest} qrRegisterGetTokenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    qrRegisterGetNewUserToken: async (
      qrRegisterGetTokenRequest: QrRegisterGetTokenRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'qrRegisterGetTokenRequest' is not null or undefined
      assertParamExists(
        "qrRegisterGetNewUserToken",
        "qrRegisterGetTokenRequest",
        qrRegisterGetTokenRequest,
      );
      const localVarPath = `/api/auth/portal/v1/qrLogin/register/get-token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        qrRegisterGetTokenRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [QR-REGISTER: 1]: Đăng ký nếu người dùng chưa có tài khoản - Thiết lập mật khẩu
     * @param {QrRegisterSetPasswordRequest} qrRegisterSetPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    qrRegisterSetPassword: async (
      qrRegisterSetPasswordRequest: QrRegisterSetPasswordRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'qrRegisterSetPasswordRequest' is not null or undefined
      assertParamExists(
        "qrRegisterSetPassword",
        "qrRegisterSetPasswordRequest",
        qrRegisterSetPasswordRequest,
      );
      const localVarPath = `/api/auth/portal/v1/qrLogin/register/setPassword`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        qrRegisterSetPasswordRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LoginByQRControllerApi - functional programming interface
 * @export
 */
export const LoginByQRControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    LoginByQRControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary [STEP 2]: Lấy thông tin login session
     * @param {QrLoginGetInfoRequest} qrLoginGetInfoRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async qrLoginGetInfo(
      qrLoginGetInfoRequest: QrLoginGetInfoRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseQrLoginGetInfoResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.qrLoginGetInfo(
        qrLoginGetInfoRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary [STEP 3]: Lấy thông tin token đăng nhập
     * @param {QrLoginGetTokenRequest} qrLoginGetTokenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async qrLoginGetToken(
      qrLoginGetTokenRequest: QrLoginGetTokenRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseAccessTokenResponseCustom>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.qrLoginGetToken(
        qrLoginGetTokenRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary [STEP 4]: Lấy thông tin user mới
     * @param {QrLoginGetUserInfoRequest} qrLoginGetUserInfoRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async qrLoginGetUserInfo(
      qrLoginGetUserInfoRequest: QrLoginGetUserInfoRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseQrUserInfoResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.qrLoginGetUserInfo(
          qrLoginGetUserInfoRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary [STEP 1]: Gửi yêu cầu login với QR code
     * @param {QrLoginRequest} qrLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async qrLoginRequest(
      qrLoginRequest: QrLoginRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseQrLoginResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.qrLoginRequest(
        qrLoginRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary [QR-REGISTER: 2]: Tạo tài khoản mới và lấy token
     * @param {QrRegisterGetTokenRequest} qrRegisterGetTokenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async qrRegisterGetNewUserToken(
      qrRegisterGetTokenRequest: QrRegisterGetTokenRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseAccessTokenResponseCustom>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.qrRegisterGetNewUserToken(
          qrRegisterGetTokenRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary [QR-REGISTER: 1]: Đăng ký nếu người dùng chưa có tài khoản - Thiết lập mật khẩu
     * @param {QrRegisterSetPasswordRequest} qrRegisterSetPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async qrRegisterSetPassword(
      qrRegisterSetPasswordRequest: QrRegisterSetPasswordRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.qrRegisterSetPassword(
          qrRegisterSetPasswordRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * LoginByQRControllerApi - factory interface
 * @export
 */
export const LoginByQRControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = LoginByQRControllerApiFp(configuration);
  return {
    /**
     *
     * @summary [STEP 2]: Lấy thông tin login session
     * @param {QrLoginGetInfoRequest} qrLoginGetInfoRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    qrLoginGetInfo(
      qrLoginGetInfoRequest: QrLoginGetInfoRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseQrLoginGetInfoResponse> {
      return localVarFp
        .qrLoginGetInfo(qrLoginGetInfoRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [STEP 3]: Lấy thông tin token đăng nhập
     * @param {QrLoginGetTokenRequest} qrLoginGetTokenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    qrLoginGetToken(
      qrLoginGetTokenRequest: QrLoginGetTokenRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseAccessTokenResponseCustom> {
      return localVarFp
        .qrLoginGetToken(qrLoginGetTokenRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [STEP 4]: Lấy thông tin user mới
     * @param {QrLoginGetUserInfoRequest} qrLoginGetUserInfoRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    qrLoginGetUserInfo(
      qrLoginGetUserInfoRequest: QrLoginGetUserInfoRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseQrUserInfoResponse> {
      return localVarFp
        .qrLoginGetUserInfo(qrLoginGetUserInfoRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [STEP 1]: Gửi yêu cầu login với QR code
     * @param {QrLoginRequest} qrLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    qrLoginRequest(
      qrLoginRequest: QrLoginRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseQrLoginResponse> {
      return localVarFp
        .qrLoginRequest(qrLoginRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [QR-REGISTER: 2]: Tạo tài khoản mới và lấy token
     * @param {QrRegisterGetTokenRequest} qrRegisterGetTokenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    qrRegisterGetNewUserToken(
      qrRegisterGetTokenRequest: QrRegisterGetTokenRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseAccessTokenResponseCustom> {
      return localVarFp
        .qrRegisterGetNewUserToken(qrRegisterGetTokenRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [QR-REGISTER: 1]: Đăng ký nếu người dùng chưa có tài khoản - Thiết lập mật khẩu
     * @param {QrRegisterSetPasswordRequest} qrRegisterSetPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    qrRegisterSetPassword(
      qrRegisterSetPasswordRequest: QrRegisterSetPasswordRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .qrRegisterSetPassword(qrRegisterSetPasswordRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for qrLoginGetInfo operation in LoginByQRControllerApi.
 * @export
 * @interface LoginByQRControllerApiQrLoginGetInfoRequest
 */
export interface LoginByQRControllerApiQrLoginGetInfoRequest {
  /**
   *
   * @type {QrLoginGetInfoRequest}
   * @memberof LoginByQRControllerApiQrLoginGetInfo
   */
  readonly qrLoginGetInfoRequest: QrLoginGetInfoRequest;
}

/**
 * Request parameters for qrLoginGetToken operation in LoginByQRControllerApi.
 * @export
 * @interface LoginByQRControllerApiQrLoginGetTokenRequest
 */
export interface LoginByQRControllerApiQrLoginGetTokenRequest {
  /**
   *
   * @type {QrLoginGetTokenRequest}
   * @memberof LoginByQRControllerApiQrLoginGetToken
   */
  readonly qrLoginGetTokenRequest: QrLoginGetTokenRequest;
}

/**
 * Request parameters for qrLoginGetUserInfo operation in LoginByQRControllerApi.
 * @export
 * @interface LoginByQRControllerApiQrLoginGetUserInfoRequest
 */
export interface LoginByQRControllerApiQrLoginGetUserInfoRequest {
  /**
   *
   * @type {QrLoginGetUserInfoRequest}
   * @memberof LoginByQRControllerApiQrLoginGetUserInfo
   */
  readonly qrLoginGetUserInfoRequest: QrLoginGetUserInfoRequest;
}

/**
 * Request parameters for qrLoginRequest operation in LoginByQRControllerApi.
 * @export
 * @interface LoginByQRControllerApiQrLoginRequestRequest
 */
export interface LoginByQRControllerApiQrLoginRequestRequest {
  /**
   *
   * @type {QrLoginRequest}
   * @memberof LoginByQRControllerApiQrLoginRequest
   */
  readonly qrLoginRequest: QrLoginRequest;
}

/**
 * Request parameters for qrRegisterGetNewUserToken operation in LoginByQRControllerApi.
 * @export
 * @interface LoginByQRControllerApiQrRegisterGetNewUserTokenRequest
 */
export interface LoginByQRControllerApiQrRegisterGetNewUserTokenRequest {
  /**
   *
   * @type {QrRegisterGetTokenRequest}
   * @memberof LoginByQRControllerApiQrRegisterGetNewUserToken
   */
  readonly qrRegisterGetTokenRequest: QrRegisterGetTokenRequest;
}

/**
 * Request parameters for qrRegisterSetPassword operation in LoginByQRControllerApi.
 * @export
 * @interface LoginByQRControllerApiQrRegisterSetPasswordRequest
 */
export interface LoginByQRControllerApiQrRegisterSetPasswordRequest {
  /**
   *
   * @type {QrRegisterSetPasswordRequest}
   * @memberof LoginByQRControllerApiQrRegisterSetPassword
   */
  readonly qrRegisterSetPasswordRequest: QrRegisterSetPasswordRequest;
}

/**
 * LoginByQRControllerApi - object-oriented interface
 * @export
 * @class LoginByQRControllerApi
 * @extends {BaseAPI}
 */
export class LoginByQRControllerApi extends BaseAPI {
  /**
   *
   * @summary [STEP 2]: Lấy thông tin login session
   * @param {LoginByQRControllerApiQrLoginGetInfoRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoginByQRControllerApi
   */
  public qrLoginGetInfo(
    requestParameters: LoginByQRControllerApiQrLoginGetInfoRequest,
    options?: AxiosRequestConfig,
  ) {
    return LoginByQRControllerApiFp(this.configuration)
      .qrLoginGetInfo(requestParameters.qrLoginGetInfoRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [STEP 3]: Lấy thông tin token đăng nhập
   * @param {LoginByQRControllerApiQrLoginGetTokenRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoginByQRControllerApi
   */
  public qrLoginGetToken(
    requestParameters: LoginByQRControllerApiQrLoginGetTokenRequest,
    options?: AxiosRequestConfig,
  ) {
    return LoginByQRControllerApiFp(this.configuration)
      .qrLoginGetToken(requestParameters.qrLoginGetTokenRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [STEP 4]: Lấy thông tin user mới
   * @param {LoginByQRControllerApiQrLoginGetUserInfoRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoginByQRControllerApi
   */
  public qrLoginGetUserInfo(
    requestParameters: LoginByQRControllerApiQrLoginGetUserInfoRequest,
    options?: AxiosRequestConfig,
  ) {
    return LoginByQRControllerApiFp(this.configuration)
      .qrLoginGetUserInfo(requestParameters.qrLoginGetUserInfoRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [STEP 1]: Gửi yêu cầu login với QR code
   * @param {LoginByQRControllerApiQrLoginRequestRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoginByQRControllerApi
   */
  public qrLoginRequest(
    requestParameters: LoginByQRControllerApiQrLoginRequestRequest,
    options?: AxiosRequestConfig,
  ) {
    return LoginByQRControllerApiFp(this.configuration)
      .qrLoginRequest(requestParameters.qrLoginRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [QR-REGISTER: 2]: Tạo tài khoản mới và lấy token
   * @param {LoginByQRControllerApiQrRegisterGetNewUserTokenRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoginByQRControllerApi
   */
  public qrRegisterGetNewUserToken(
    requestParameters: LoginByQRControllerApiQrRegisterGetNewUserTokenRequest,
    options?: AxiosRequestConfig,
  ) {
    return LoginByQRControllerApiFp(this.configuration)
      .qrRegisterGetNewUserToken(
        requestParameters.qrRegisterGetTokenRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [QR-REGISTER: 1]: Đăng ký nếu người dùng chưa có tài khoản - Thiết lập mật khẩu
   * @param {LoginByQRControllerApiQrRegisterSetPasswordRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoginByQRControllerApi
   */
  public qrRegisterSetPassword(
    requestParameters: LoginByQRControllerApiQrRegisterSetPasswordRequest,
    options?: AxiosRequestConfig,
  ) {
    return LoginByQRControllerApiFp(this.configuration)
      .qrRegisterSetPassword(
        requestParameters.qrRegisterSetPasswordRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * MerchantControllerApi - axios parameter creator
 * @export
 */
export const MerchantControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * - Xem tất cả các thành viên
     * @summary Xem tất cả các thành viên
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllMerchantMembers: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/merchant/portal/v1/getAllMembers`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy thông tin credential
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCredential: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/merchant/portal/v1/getCredential`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy thông tin định danh
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEkycInfo: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/merchant/portal/v1/getEkycInfo`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Xem chi tiết thành viên
     * @summary Xem chi tiết thành viên
     * @param {string} memberId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMember: async (
      memberId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'memberId' is not null or undefined
      assertParamExists("getMember", "memberId", memberId);
      const localVarPath =
        `/api/merchant/portal/v1/getMember/{memberId}`.replace(
          `{${"memberId"}}`,
          encodeURIComponent(String(memberId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Get merchant, yêu cầu từ user
     * @summary Get merchant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMerchant1: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/merchant/portal/v1/getMerchant`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy thông tin định danh
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubmitInfo: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/merchant/portal/v1/getSubmitInfo`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy thông tin webhook
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    getWebhookInfo: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/merchant/portal/v1/getWebhookInfo`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Tạo mới merchant credential
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshCredential: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/merchant/portal/v1/refreshCredential`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Cập nhật thông tin merchant
     * @param {SubmitMerchantInfoRequest} submitMerchantInfoRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    submitInfo: async (
      submitMerchantInfoRequest: SubmitMerchantInfoRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'submitMerchantInfoRequest' is not null or undefined
      assertParamExists(
        "submitInfo",
        "submitMerchantInfoRequest",
        submitMerchantInfoRequest,
      );
      const localVarPath = `/api/merchant/portal/v1/submitInfo`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        submitMerchantInfoRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [Portal][Định danh] Gửi thông tin định danh
     * @param {PortalSubmitInfoV2Request} portalSubmitInfoV2Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    submitInfoV2: async (
      portalSubmitInfoV2Request: PortalSubmitInfoV2Request,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'portalSubmitInfoV2Request' is not null or undefined
      assertParamExists(
        "submitInfoV2",
        "portalSubmitInfoV2Request",
        portalSubmitInfoV2Request,
      );
      const localVarPath = `/api/merchant/portal/v2/submitInfo`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        portalSubmitInfoV2Request,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Cập nhật thông tin merchant
     * @summary Cập nhật thông tin merchant
     * @param {UpdateMerchantRequest} updateMerchantRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update: async (
      updateMerchantRequest: UpdateMerchantRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateMerchantRequest' is not null or undefined
      assertParamExists(
        "update",
        "updateMerchantRequest",
        updateMerchantRequest,
      );
      const localVarPath = `/api/merchant/portal/v1/updateMerchant`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateMerchantRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Cập nhật thông tin webhook
     * @summary Cập nhật thông tin webhook
     * @param {UpdateWebhookRequest} updateWebhookRequest
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    updateWebhook: async (
      updateWebhookRequest: UpdateWebhookRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateWebhookRequest' is not null or undefined
      assertParamExists(
        "updateWebhook",
        "updateWebhookRequest",
        updateWebhookRequest,
      );
      const localVarPath = `/api/merchant/portal/v1/updateWebhook`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateWebhookRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MerchantControllerApi - functional programming interface
 * @export
 */
export const MerchantControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    MerchantControllerApiAxiosParamCreator(configuration);
  return {
    /**
     * - Xem tất cả các thành viên
     * @summary Xem tất cả các thành viên
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllMerchantMembers(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseListResponseUserResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllMerchantMembers(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy thông tin credential
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCredential(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetMerchantCredentialResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCredential(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy thông tin định danh
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEkycInfo(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetMerchantEkycInfoResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getEkycInfo(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Xem chi tiết thành viên
     * @summary Xem chi tiết thành viên
     * @param {string} memberId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMember(
      memberId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseUserResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getMember(
        memberId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Get merchant, yêu cầu từ user
     * @summary Get merchant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMerchant1(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetMerchantResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getMerchant1(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy thông tin định danh
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSubmitInfo(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetSubmitInfoResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSubmitInfo(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy thông tin webhook
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async getWebhookInfo(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetWebhookInfoResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getWebhookInfo(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Tạo mới merchant credential
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async refreshCredential(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetMerchantCredentialResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.refreshCredential(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Cập nhật thông tin merchant
     * @param {SubmitMerchantInfoRequest} submitMerchantInfoRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async submitInfo(
      submitMerchantInfoRequest: SubmitMerchantInfoRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseSubmitProfileInfoResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.submitInfo(
        submitMerchantInfoRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary [Portal][Định danh] Gửi thông tin định danh
     * @param {PortalSubmitInfoV2Request} portalSubmitInfoV2Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async submitInfoV2(
      portalSubmitInfoV2Request: PortalSubmitInfoV2Request,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseSubmitProfileInfoResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.submitInfoV2(
        portalSubmitInfoV2Request,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Cập nhật thông tin merchant
     * @summary Cập nhật thông tin merchant
     * @param {UpdateMerchantRequest} updateMerchantRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async update(
      updateMerchantRequest: UpdateMerchantRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseUpdateMerchantResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.update(
        updateMerchantRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Cập nhật thông tin webhook
     * @summary Cập nhật thông tin webhook
     * @param {UpdateWebhookRequest} updateWebhookRequest
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async updateWebhook(
      updateWebhookRequest: UpdateWebhookRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseUpdateWebhookResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateWebhook(
        updateWebhookRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * MerchantControllerApi - factory interface
 * @export
 */
export const MerchantControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = MerchantControllerApiFp(configuration);
  return {
    /**
     * - Xem tất cả các thành viên
     * @summary Xem tất cả các thành viên
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllMerchantMembers(
      options?: any,
    ): AxiosPromise<ResponseBaseListResponseUserResponse> {
      return localVarFp
        .getAllMerchantMembers(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy thông tin credential
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCredential(
      options?: any,
    ): AxiosPromise<ResponseBaseGetMerchantCredentialResponse> {
      return localVarFp
        .getCredential(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy thông tin định danh
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEkycInfo(
      options?: any,
    ): AxiosPromise<ResponseBaseGetMerchantEkycInfoResponse> {
      return localVarFp
        .getEkycInfo(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Xem chi tiết thành viên
     * @summary Xem chi tiết thành viên
     * @param {string} memberId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMember(
      memberId: string,
      options?: any,
    ): AxiosPromise<ResponseBaseUserResponse> {
      return localVarFp
        .getMember(memberId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Get merchant, yêu cầu từ user
     * @summary Get merchant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMerchant1(options?: any): AxiosPromise<ResponseBaseGetMerchantResponse> {
      return localVarFp
        .getMerchant1(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy thông tin định danh
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubmitInfo(
      options?: any,
    ): AxiosPromise<ResponseBaseGetSubmitInfoResponse> {
      return localVarFp
        .getSubmitInfo(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy thông tin webhook
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    getWebhookInfo(
      options?: any,
    ): AxiosPromise<ResponseBaseGetWebhookInfoResponse> {
      return localVarFp
        .getWebhookInfo(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Tạo mới merchant credential
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshCredential(
      options?: any,
    ): AxiosPromise<ResponseBaseGetMerchantCredentialResponse> {
      return localVarFp
        .refreshCredential(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Cập nhật thông tin merchant
     * @param {SubmitMerchantInfoRequest} submitMerchantInfoRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    submitInfo(
      submitMerchantInfoRequest: SubmitMerchantInfoRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseSubmitProfileInfoResponse> {
      return localVarFp
        .submitInfo(submitMerchantInfoRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [Portal][Định danh] Gửi thông tin định danh
     * @param {PortalSubmitInfoV2Request} portalSubmitInfoV2Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    submitInfoV2(
      portalSubmitInfoV2Request: PortalSubmitInfoV2Request,
      options?: any,
    ): AxiosPromise<ResponseBaseSubmitProfileInfoResponse> {
      return localVarFp
        .submitInfoV2(portalSubmitInfoV2Request, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Cập nhật thông tin merchant
     * @summary Cập nhật thông tin merchant
     * @param {UpdateMerchantRequest} updateMerchantRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(
      updateMerchantRequest: UpdateMerchantRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseUpdateMerchantResponse> {
      return localVarFp
        .update(updateMerchantRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Cập nhật thông tin webhook
     * @summary Cập nhật thông tin webhook
     * @param {UpdateWebhookRequest} updateWebhookRequest
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    updateWebhook(
      updateWebhookRequest: UpdateWebhookRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseUpdateWebhookResponse> {
      return localVarFp
        .updateWebhook(updateWebhookRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getMember operation in MerchantControllerApi.
 * @export
 * @interface MerchantControllerApiGetMemberRequest
 */
export interface MerchantControllerApiGetMemberRequest {
  /**
   *
   * @type {string}
   * @memberof MerchantControllerApiGetMember
   */
  readonly memberId: string;
}

/**
 * Request parameters for submitInfo operation in MerchantControllerApi.
 * @export
 * @interface MerchantControllerApiSubmitInfoRequest
 */
export interface MerchantControllerApiSubmitInfoRequest {
  /**
   *
   * @type {SubmitMerchantInfoRequest}
   * @memberof MerchantControllerApiSubmitInfo
   */
  readonly submitMerchantInfoRequest: SubmitMerchantInfoRequest;
}

/**
 * Request parameters for submitInfoV2 operation in MerchantControllerApi.
 * @export
 * @interface MerchantControllerApiSubmitInfoV2Request
 */
export interface MerchantControllerApiSubmitInfoV2Request {
  /**
   *
   * @type {PortalSubmitInfoV2Request}
   * @memberof MerchantControllerApiSubmitInfoV2
   */
  readonly portalSubmitInfoV2Request: PortalSubmitInfoV2Request;
}

/**
 * Request parameters for update operation in MerchantControllerApi.
 * @export
 * @interface MerchantControllerApiUpdateRequest
 */
export interface MerchantControllerApiUpdateRequest {
  /**
   *
   * @type {UpdateMerchantRequest}
   * @memberof MerchantControllerApiUpdate
   */
  readonly updateMerchantRequest: UpdateMerchantRequest;
}

/**
 * Request parameters for updateWebhook operation in MerchantControllerApi.
 * @export
 * @interface MerchantControllerApiUpdateWebhookRequest
 */
export interface MerchantControllerApiUpdateWebhookRequest {
  /**
   *
   * @type {UpdateWebhookRequest}
   * @memberof MerchantControllerApiUpdateWebhook
   */
  readonly updateWebhookRequest: UpdateWebhookRequest;
}

/**
 * MerchantControllerApi - object-oriented interface
 * @export
 * @class MerchantControllerApi
 * @extends {BaseAPI}
 */
export class MerchantControllerApi extends BaseAPI {
  /**
   * - Xem tất cả các thành viên
   * @summary Xem tất cả các thành viên
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MerchantControllerApi
   */
  public getAllMerchantMembers(options?: AxiosRequestConfig) {
    return MerchantControllerApiFp(this.configuration)
      .getAllMerchantMembers(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy thông tin credential
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MerchantControllerApi
   */
  public getCredential(options?: AxiosRequestConfig) {
    return MerchantControllerApiFp(this.configuration)
      .getCredential(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy thông tin định danh
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MerchantControllerApi
   */
  public getEkycInfo(options?: AxiosRequestConfig) {
    return MerchantControllerApiFp(this.configuration)
      .getEkycInfo(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Xem chi tiết thành viên
   * @summary Xem chi tiết thành viên
   * @param {MerchantControllerApiGetMemberRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MerchantControllerApi
   */
  public getMember(
    requestParameters: MerchantControllerApiGetMemberRequest,
    options?: AxiosRequestConfig,
  ) {
    return MerchantControllerApiFp(this.configuration)
      .getMember(requestParameters.memberId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Get merchant, yêu cầu từ user
   * @summary Get merchant
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MerchantControllerApi
   */
  public getMerchant1(options?: AxiosRequestConfig) {
    return MerchantControllerApiFp(this.configuration)
      .getMerchant1(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy thông tin định danh
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MerchantControllerApi
   */
  public getSubmitInfo(options?: AxiosRequestConfig) {
    return MerchantControllerApiFp(this.configuration)
      .getSubmitInfo(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy thông tin webhook
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof MerchantControllerApi
   */
  public getWebhookInfo(options?: AxiosRequestConfig) {
    return MerchantControllerApiFp(this.configuration)
      .getWebhookInfo(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Tạo mới merchant credential
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MerchantControllerApi
   */
  public refreshCredential(options?: AxiosRequestConfig) {
    return MerchantControllerApiFp(this.configuration)
      .refreshCredential(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Cập nhật thông tin merchant
   * @param {MerchantControllerApiSubmitInfoRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MerchantControllerApi
   */
  public submitInfo(
    requestParameters: MerchantControllerApiSubmitInfoRequest,
    options?: AxiosRequestConfig,
  ) {
    return MerchantControllerApiFp(this.configuration)
      .submitInfo(requestParameters.submitMerchantInfoRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [Portal][Định danh] Gửi thông tin định danh
   * @param {MerchantControllerApiSubmitInfoV2Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MerchantControllerApi
   */
  public submitInfoV2(
    requestParameters: MerchantControllerApiSubmitInfoV2Request,
    options?: AxiosRequestConfig,
  ) {
    return MerchantControllerApiFp(this.configuration)
      .submitInfoV2(requestParameters.portalSubmitInfoV2Request, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Cập nhật thông tin merchant
   * @summary Cập nhật thông tin merchant
   * @param {MerchantControllerApiUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MerchantControllerApi
   */
  public update(
    requestParameters: MerchantControllerApiUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return MerchantControllerApiFp(this.configuration)
      .update(requestParameters.updateMerchantRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Cập nhật thông tin webhook
   * @summary Cập nhật thông tin webhook
   * @param {MerchantControllerApiUpdateWebhookRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof MerchantControllerApi
   */
  public updateWebhook(
    requestParameters: MerchantControllerApiUpdateWebhookRequest,
    options?: AxiosRequestConfig,
  ) {
    return MerchantControllerApiFp(this.configuration)
      .updateWebhook(requestParameters.updateWebhookRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * NapasCallbackControllerApi - axios parameter creator
 * @export
 */
export const NapasCallbackControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Cập nhật trạng thái giao dịch
     * @param {NapasNotifyTransactionRequest} napasNotifyTransactionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    processIpn: async (
      napasNotifyTransactionRequest: NapasNotifyTransactionRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'napasNotifyTransactionRequest' is not null or undefined
      assertParamExists(
        "processIpn",
        "napasNotifyTransactionRequest",
        napasNotifyTransactionRequest,
      );
      const localVarPath = `/internal/api/napas/callback/v1/ipn`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        napasNotifyTransactionRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * NapasCallbackControllerApi - functional programming interface
 * @export
 */
export const NapasCallbackControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    NapasCallbackControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Cập nhật trạng thái giao dịch
     * @param {NapasNotifyTransactionRequest} napasNotifyTransactionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async processIpn(
      napasNotifyTransactionRequest: NapasNotifyTransactionRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseNapasNotifyTransactionResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.processIpn(
        napasNotifyTransactionRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * NapasCallbackControllerApi - factory interface
 * @export
 */
export const NapasCallbackControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = NapasCallbackControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Cập nhật trạng thái giao dịch
     * @param {NapasNotifyTransactionRequest} napasNotifyTransactionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    processIpn(
      napasNotifyTransactionRequest: NapasNotifyTransactionRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseNapasNotifyTransactionResponse> {
      return localVarFp
        .processIpn(napasNotifyTransactionRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for processIpn operation in NapasCallbackControllerApi.
 * @export
 * @interface NapasCallbackControllerApiProcessIpnRequest
 */
export interface NapasCallbackControllerApiProcessIpnRequest {
  /**
   *
   * @type {NapasNotifyTransactionRequest}
   * @memberof NapasCallbackControllerApiProcessIpn
   */
  readonly napasNotifyTransactionRequest: NapasNotifyTransactionRequest;
}

/**
 * NapasCallbackControllerApi - object-oriented interface
 * @export
 * @class NapasCallbackControllerApi
 * @extends {BaseAPI}
 */
export class NapasCallbackControllerApi extends BaseAPI {
  /**
   *
   * @summary Cập nhật trạng thái giao dịch
   * @param {NapasCallbackControllerApiProcessIpnRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NapasCallbackControllerApi
   */
  public processIpn(
    requestParameters: NapasCallbackControllerApiProcessIpnRequest,
    options?: AxiosRequestConfig,
  ) {
    return NapasCallbackControllerApiFp(this.configuration)
      .processIpn(requestParameters.napasNotifyTransactionRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * OpenBankingControllerApi - axios parameter creator
 * @export
 */
export const OpenBankingControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary 1. Kiểm tra tài khoản thanh toán
     * @param {string} xApiClient
     * @param {CheckAccountNoRequest} checkAccountNoRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkAccountNo: async (
      xApiClient: string,
      checkAccountNoRequest: CheckAccountNoRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xApiClient' is not null or undefined
      assertParamExists("checkAccountNo", "xApiClient", xApiClient);
      // verify required parameter 'checkAccountNoRequest' is not null or undefined
      assertParamExists(
        "checkAccountNo",
        "checkAccountNoRequest",
        checkAccountNoRequest,
      );
      const localVarPath = `/api/openBanking/v1/simple/checkAccountNo`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xApiClient != null) {
        localVarHeaderParameter["x-api-client"] = String(xApiClient);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        checkAccountNoRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary 1. Kiểm tra tài khoản thanh toán
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkAccountNo1: async (
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xApiClient' is not null or undefined
      assertParamExists("checkAccountNo1", "xApiClient", xApiClient);
      // verify required parameter 'xApiValidate' is not null or undefined
      assertParamExists("checkAccountNo1", "xApiValidate", xApiValidate);
      // verify required parameter 'xApiTime' is not null or undefined
      assertParamExists("checkAccountNo1", "xApiTime", xApiTime);
      // verify required parameter 'encryptedBodyRequest' is not null or undefined
      assertParamExists(
        "checkAccountNo1",
        "encryptedBodyRequest",
        encryptedBodyRequest,
      );
      const localVarPath = `/api/openBanking/v1/checkAccountNo`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xApiClient != null) {
        localVarHeaderParameter["x-api-client"] = String(xApiClient);
      }

      if (xApiValidate != null) {
        localVarHeaderParameter["x-api-validate"] = String(xApiValidate);
      }

      if (xApiTime != null) {
        localVarHeaderParameter["x-api-time"] =
          typeof xApiTime === "string" ? xApiTime : JSON.stringify(xApiTime);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        encryptedBodyRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary 2. Xác nhận liên kết tài khoản
     * @param {string} xApiClient
     * @param {LinkAccountRequest} linkAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    linkAccount: async (
      xApiClient: string,
      linkAccountRequest: LinkAccountRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xApiClient' is not null or undefined
      assertParamExists("linkAccount", "xApiClient", xApiClient);
      // verify required parameter 'linkAccountRequest' is not null or undefined
      assertParamExists(
        "linkAccount",
        "linkAccountRequest",
        linkAccountRequest,
      );
      const localVarPath = `/api/openBanking/v1/simple/linkAccount`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xApiClient != null) {
        localVarHeaderParameter["x-api-client"] = String(xApiClient);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        linkAccountRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary 2. Xác nhận liên kết tài khoản
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    linkAccount1: async (
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xApiClient' is not null or undefined
      assertParamExists("linkAccount1", "xApiClient", xApiClient);
      // verify required parameter 'xApiValidate' is not null or undefined
      assertParamExists("linkAccount1", "xApiValidate", xApiValidate);
      // verify required parameter 'xApiTime' is not null or undefined
      assertParamExists("linkAccount1", "xApiTime", xApiTime);
      // verify required parameter 'encryptedBodyRequest' is not null or undefined
      assertParamExists(
        "linkAccount1",
        "encryptedBodyRequest",
        encryptedBodyRequest,
      );
      const localVarPath = `/api/openBanking/v1/linkAccount`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xApiClient != null) {
        localVarHeaderParameter["x-api-client"] = String(xApiClient);
      }

      if (xApiValidate != null) {
        localVarHeaderParameter["x-api-validate"] = String(xApiValidate);
      }

      if (xApiTime != null) {
        localVarHeaderParameter["x-api-time"] =
          typeof xApiTime === "string" ? xApiTime : JSON.stringify(xApiTime);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        encryptedBodyRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary 3. Xác thực cho liên kết tài khoản
     * @param {string} xApiClient
     * @param {VerifyLinkAccountRequest} verifyLinkAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyLinkAccount: async (
      xApiClient: string,
      verifyLinkAccountRequest: VerifyLinkAccountRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xApiClient' is not null or undefined
      assertParamExists("verifyLinkAccount", "xApiClient", xApiClient);
      // verify required parameter 'verifyLinkAccountRequest' is not null or undefined
      assertParamExists(
        "verifyLinkAccount",
        "verifyLinkAccountRequest",
        verifyLinkAccountRequest,
      );
      const localVarPath = `/api/openBanking/v1/simple/linkAccount/verify`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xApiClient != null) {
        localVarHeaderParameter["x-api-client"] = String(xApiClient);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        verifyLinkAccountRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary 3. Xác thực cho liên kết tài khoản
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyLinkAccount1: async (
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xApiClient' is not null or undefined
      assertParamExists("verifyLinkAccount1", "xApiClient", xApiClient);
      // verify required parameter 'xApiValidate' is not null or undefined
      assertParamExists("verifyLinkAccount1", "xApiValidate", xApiValidate);
      // verify required parameter 'xApiTime' is not null or undefined
      assertParamExists("verifyLinkAccount1", "xApiTime", xApiTime);
      // verify required parameter 'encryptedBodyRequest' is not null or undefined
      assertParamExists(
        "verifyLinkAccount1",
        "encryptedBodyRequest",
        encryptedBodyRequest,
      );
      const localVarPath = `/api/openBanking/v1/linkAccount/verify`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xApiClient != null) {
        localVarHeaderParameter["x-api-client"] = String(xApiClient);
      }

      if (xApiValidate != null) {
        localVarHeaderParameter["x-api-validate"] = String(xApiValidate);
      }

      if (xApiTime != null) {
        localVarHeaderParameter["x-api-time"] =
          typeof xApiTime === "string" ? xApiTime : JSON.stringify(xApiTime);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        encryptedBodyRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OpenBankingControllerApi - functional programming interface
 * @export
 */
export const OpenBankingControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    OpenBankingControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary 1. Kiểm tra tài khoản thanh toán
     * @param {string} xApiClient
     * @param {CheckAccountNoRequest} checkAccountNoRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkAccountNo(
      xApiClient: string,
      checkAccountNoRequest: CheckAccountNoRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseCheckAccountNoResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.checkAccountNo(
        xApiClient,
        checkAccountNoRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary 1. Kiểm tra tài khoản thanh toán
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkAccountNo1(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseString>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.checkAccountNo1(
        xApiClient,
        xApiValidate,
        xApiTime,
        encryptedBodyRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary 2. Xác nhận liên kết tài khoản
     * @param {string} xApiClient
     * @param {LinkAccountRequest} linkAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async linkAccount(
      xApiClient: string,
      linkAccountRequest: LinkAccountRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseLinkAccountResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.linkAccount(
        xApiClient,
        linkAccountRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary 2. Xác nhận liên kết tài khoản
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async linkAccount1(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseString>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.linkAccount1(
        xApiClient,
        xApiValidate,
        xApiTime,
        encryptedBodyRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary 3. Xác thực cho liên kết tài khoản
     * @param {string} xApiClient
     * @param {VerifyLinkAccountRequest} verifyLinkAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async verifyLinkAccount(
      xApiClient: string,
      verifyLinkAccountRequest: VerifyLinkAccountRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseVerifyLinkAccountResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.verifyLinkAccount(
          xApiClient,
          verifyLinkAccountRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary 3. Xác thực cho liên kết tài khoản
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async verifyLinkAccount1(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseString>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.verifyLinkAccount1(
          xApiClient,
          xApiValidate,
          xApiTime,
          encryptedBodyRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * OpenBankingControllerApi - factory interface
 * @export
 */
export const OpenBankingControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = OpenBankingControllerApiFp(configuration);
  return {
    /**
     *
     * @summary 1. Kiểm tra tài khoản thanh toán
     * @param {string} xApiClient
     * @param {CheckAccountNoRequest} checkAccountNoRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkAccountNo(
      xApiClient: string,
      checkAccountNoRequest: CheckAccountNoRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseCheckAccountNoResponse> {
      return localVarFp
        .checkAccountNo(xApiClient, checkAccountNoRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary 1. Kiểm tra tài khoản thanh toán
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkAccountNo1(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseString> {
      return localVarFp
        .checkAccountNo1(
          xApiClient,
          xApiValidate,
          xApiTime,
          encryptedBodyRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary 2. Xác nhận liên kết tài khoản
     * @param {string} xApiClient
     * @param {LinkAccountRequest} linkAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    linkAccount(
      xApiClient: string,
      linkAccountRequest: LinkAccountRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseLinkAccountResponse> {
      return localVarFp
        .linkAccount(xApiClient, linkAccountRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary 2. Xác nhận liên kết tài khoản
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    linkAccount1(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseString> {
      return localVarFp
        .linkAccount1(
          xApiClient,
          xApiValidate,
          xApiTime,
          encryptedBodyRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary 3. Xác thực cho liên kết tài khoản
     * @param {string} xApiClient
     * @param {VerifyLinkAccountRequest} verifyLinkAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyLinkAccount(
      xApiClient: string,
      verifyLinkAccountRequest: VerifyLinkAccountRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseVerifyLinkAccountResponse> {
      return localVarFp
        .verifyLinkAccount(xApiClient, verifyLinkAccountRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary 3. Xác thực cho liên kết tài khoản
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyLinkAccount1(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseString> {
      return localVarFp
        .verifyLinkAccount1(
          xApiClient,
          xApiValidate,
          xApiTime,
          encryptedBodyRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for checkAccountNo operation in OpenBankingControllerApi.
 * @export
 * @interface OpenBankingControllerApiCheckAccountNoRequest
 */
export interface OpenBankingControllerApiCheckAccountNoRequest {
  /**
   *
   * @type {string}
   * @memberof OpenBankingControllerApiCheckAccountNo
   */
  readonly xApiClient: string;

  /**
   *
   * @type {CheckAccountNoRequest}
   * @memberof OpenBankingControllerApiCheckAccountNo
   */
  readonly checkAccountNoRequest: CheckAccountNoRequest;
}

/**
 * Request parameters for checkAccountNo1 operation in OpenBankingControllerApi.
 * @export
 * @interface OpenBankingControllerApiCheckAccountNo1Request
 */
export interface OpenBankingControllerApiCheckAccountNo1Request {
  /**
   *
   * @type {string}
   * @memberof OpenBankingControllerApiCheckAccountNo1
   */
  readonly xApiClient: string;

  /**
   *
   * @type {string}
   * @memberof OpenBankingControllerApiCheckAccountNo1
   */
  readonly xApiValidate: string;

  /**
   *
   * @type {number}
   * @memberof OpenBankingControllerApiCheckAccountNo1
   */
  readonly xApiTime: number;

  /**
   *
   * @type {EncryptedBodyRequest}
   * @memberof OpenBankingControllerApiCheckAccountNo1
   */
  readonly encryptedBodyRequest: EncryptedBodyRequest;
}

/**
 * Request parameters for linkAccount operation in OpenBankingControllerApi.
 * @export
 * @interface OpenBankingControllerApiLinkAccountRequest
 */
export interface OpenBankingControllerApiLinkAccountRequest {
  /**
   *
   * @type {string}
   * @memberof OpenBankingControllerApiLinkAccount
   */
  readonly xApiClient: string;

  /**
   *
   * @type {LinkAccountRequest}
   * @memberof OpenBankingControllerApiLinkAccount
   */
  readonly linkAccountRequest: LinkAccountRequest;
}

/**
 * Request parameters for linkAccount1 operation in OpenBankingControllerApi.
 * @export
 * @interface OpenBankingControllerApiLinkAccount1Request
 */
export interface OpenBankingControllerApiLinkAccount1Request {
  /**
   *
   * @type {string}
   * @memberof OpenBankingControllerApiLinkAccount1
   */
  readonly xApiClient: string;

  /**
   *
   * @type {string}
   * @memberof OpenBankingControllerApiLinkAccount1
   */
  readonly xApiValidate: string;

  /**
   *
   * @type {number}
   * @memberof OpenBankingControllerApiLinkAccount1
   */
  readonly xApiTime: number;

  /**
   *
   * @type {EncryptedBodyRequest}
   * @memberof OpenBankingControllerApiLinkAccount1
   */
  readonly encryptedBodyRequest: EncryptedBodyRequest;
}

/**
 * Request parameters for verifyLinkAccount operation in OpenBankingControllerApi.
 * @export
 * @interface OpenBankingControllerApiVerifyLinkAccountRequest
 */
export interface OpenBankingControllerApiVerifyLinkAccountRequest {
  /**
   *
   * @type {string}
   * @memberof OpenBankingControllerApiVerifyLinkAccount
   */
  readonly xApiClient: string;

  /**
   *
   * @type {VerifyLinkAccountRequest}
   * @memberof OpenBankingControllerApiVerifyLinkAccount
   */
  readonly verifyLinkAccountRequest: VerifyLinkAccountRequest;
}

/**
 * Request parameters for verifyLinkAccount1 operation in OpenBankingControllerApi.
 * @export
 * @interface OpenBankingControllerApiVerifyLinkAccount1Request
 */
export interface OpenBankingControllerApiVerifyLinkAccount1Request {
  /**
   *
   * @type {string}
   * @memberof OpenBankingControllerApiVerifyLinkAccount1
   */
  readonly xApiClient: string;

  /**
   *
   * @type {string}
   * @memberof OpenBankingControllerApiVerifyLinkAccount1
   */
  readonly xApiValidate: string;

  /**
   *
   * @type {number}
   * @memberof OpenBankingControllerApiVerifyLinkAccount1
   */
  readonly xApiTime: number;

  /**
   *
   * @type {EncryptedBodyRequest}
   * @memberof OpenBankingControllerApiVerifyLinkAccount1
   */
  readonly encryptedBodyRequest: EncryptedBodyRequest;
}

/**
 * OpenBankingControllerApi - object-oriented interface
 * @export
 * @class OpenBankingControllerApi
 * @extends {BaseAPI}
 */
export class OpenBankingControllerApi extends BaseAPI {
  /**
   *
   * @summary 1. Kiểm tra tài khoản thanh toán
   * @param {OpenBankingControllerApiCheckAccountNoRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OpenBankingControllerApi
   */
  public checkAccountNo(
    requestParameters: OpenBankingControllerApiCheckAccountNoRequest,
    options?: AxiosRequestConfig,
  ) {
    return OpenBankingControllerApiFp(this.configuration)
      .checkAccountNo(
        requestParameters.xApiClient,
        requestParameters.checkAccountNoRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary 1. Kiểm tra tài khoản thanh toán
   * @param {OpenBankingControllerApiCheckAccountNo1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OpenBankingControllerApi
   */
  public checkAccountNo1(
    requestParameters: OpenBankingControllerApiCheckAccountNo1Request,
    options?: AxiosRequestConfig,
  ) {
    return OpenBankingControllerApiFp(this.configuration)
      .checkAccountNo1(
        requestParameters.xApiClient,
        requestParameters.xApiValidate,
        requestParameters.xApiTime,
        requestParameters.encryptedBodyRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary 2. Xác nhận liên kết tài khoản
   * @param {OpenBankingControllerApiLinkAccountRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OpenBankingControllerApi
   */
  public linkAccount(
    requestParameters: OpenBankingControllerApiLinkAccountRequest,
    options?: AxiosRequestConfig,
  ) {
    return OpenBankingControllerApiFp(this.configuration)
      .linkAccount(
        requestParameters.xApiClient,
        requestParameters.linkAccountRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary 2. Xác nhận liên kết tài khoản
   * @param {OpenBankingControllerApiLinkAccount1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OpenBankingControllerApi
   */
  public linkAccount1(
    requestParameters: OpenBankingControllerApiLinkAccount1Request,
    options?: AxiosRequestConfig,
  ) {
    return OpenBankingControllerApiFp(this.configuration)
      .linkAccount1(
        requestParameters.xApiClient,
        requestParameters.xApiValidate,
        requestParameters.xApiTime,
        requestParameters.encryptedBodyRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary 3. Xác thực cho liên kết tài khoản
   * @param {OpenBankingControllerApiVerifyLinkAccountRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OpenBankingControllerApi
   */
  public verifyLinkAccount(
    requestParameters: OpenBankingControllerApiVerifyLinkAccountRequest,
    options?: AxiosRequestConfig,
  ) {
    return OpenBankingControllerApiFp(this.configuration)
      .verifyLinkAccount(
        requestParameters.xApiClient,
        requestParameters.verifyLinkAccountRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary 3. Xác thực cho liên kết tài khoản
   * @param {OpenBankingControllerApiVerifyLinkAccount1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OpenBankingControllerApi
   */
  public verifyLinkAccount1(
    requestParameters: OpenBankingControllerApiVerifyLinkAccount1Request,
    options?: AxiosRequestConfig,
  ) {
    return OpenBankingControllerApiFp(this.configuration)
      .verifyLinkAccount1(
        requestParameters.xApiClient,
        requestParameters.xApiValidate,
        requestParameters.xApiTime,
        requestParameters.encryptedBodyRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PORTALSettingControllerApi - axios parameter creator
 * @export
 */
export const PORTALSettingControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * - Thêm mới thành viên
     * @summary Thêm mới thành viên
     * @param {PortalCreateMemberRequest} portalCreateMemberRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMember: async (
      portalCreateMemberRequest: PortalCreateMemberRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'portalCreateMemberRequest' is not null or undefined
      assertParamExists(
        "createMember",
        "portalCreateMemberRequest",
        portalCreateMemberRequest,
      );
      const localVarPath = `/api/setting/portal/v1/member/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        portalCreateMemberRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Xóa thành viên
     * @summary Xóa thành viên
     * @param {string} memberId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMember: async (
      memberId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'memberId' is not null or undefined
      assertParamExists("deleteMember", "memberId", memberId);
      const localVarPath =
        `/api/setting/portal/v1/member/delete/{memberId}`.replace(
          `{${"memberId"}}`,
          encodeURIComponent(String(memberId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy tham số box hỗ trợ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSysContactParams: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/setting/portal/v1/getSystemContactParams`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Cập nhật email nhận thông báo
     * @summary Cập nhật email nhận thông báo
     * @param {UpdateContactEmailRequest} updateContactEmailRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateContactEmail: async (
      updateContactEmailRequest: UpdateContactEmailRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateContactEmailRequest' is not null or undefined
      assertParamExists(
        "updateContactEmail",
        "updateContactEmailRequest",
        updateContactEmailRequest,
      );
      const localVarPath = `/api/setting/portal/v1/email/update`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateContactEmailRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Chỉnh sửa thành viên
     * @summary Chỉnh sửa thành viên
     * @param {string} memberId
     * @param {PortalUpdateMemberRequest} portalUpdateMemberRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMember: async (
      memberId: string,
      portalUpdateMemberRequest: PortalUpdateMemberRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'memberId' is not null or undefined
      assertParamExists("updateMember", "memberId", memberId);
      // verify required parameter 'portalUpdateMemberRequest' is not null or undefined
      assertParamExists(
        "updateMember",
        "portalUpdateMemberRequest",
        portalUpdateMemberRequest,
      );
      const localVarPath =
        `/api/setting/portal/v1/member/update/{memberId}`.replace(
          `{${"memberId"}}`,
          encodeURIComponent(String(memberId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        portalUpdateMemberRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PORTALSettingControllerApi - functional programming interface
 * @export
 */
export const PORTALSettingControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    PORTALSettingControllerApiAxiosParamCreator(configuration);
  return {
    /**
     * - Thêm mới thành viên
     * @summary Thêm mới thành viên
     * @param {PortalCreateMemberRequest} portalCreateMemberRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createMember(
      portalCreateMemberRequest: PortalCreateMemberRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseUserResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createMember(
        portalCreateMemberRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Xóa thành viên
     * @summary Xóa thành viên
     * @param {string} memberId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteMember(
      memberId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseUserResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMember(
        memberId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy tham số box hỗ trợ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSysContactParams(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseAllSystemParamResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSysContactParams(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Cập nhật email nhận thông báo
     * @summary Cập nhật email nhận thông báo
     * @param {UpdateContactEmailRequest} updateContactEmailRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateContactEmail(
      updateContactEmailRequest: UpdateContactEmailRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateContactEmail(
          updateContactEmailRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Chỉnh sửa thành viên
     * @summary Chỉnh sửa thành viên
     * @param {string} memberId
     * @param {PortalUpdateMemberRequest} portalUpdateMemberRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateMember(
      memberId: string,
      portalUpdateMemberRequest: PortalUpdateMemberRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseUserResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateMember(
        memberId,
        portalUpdateMemberRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PORTALSettingControllerApi - factory interface
 * @export
 */
export const PORTALSettingControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PORTALSettingControllerApiFp(configuration);
  return {
    /**
     * - Thêm mới thành viên
     * @summary Thêm mới thành viên
     * @param {PortalCreateMemberRequest} portalCreateMemberRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMember(
      portalCreateMemberRequest: PortalCreateMemberRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseUserResponse> {
      return localVarFp
        .createMember(portalCreateMemberRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Xóa thành viên
     * @summary Xóa thành viên
     * @param {string} memberId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMember(
      memberId: string,
      options?: any,
    ): AxiosPromise<ResponseBaseUserResponse> {
      return localVarFp
        .deleteMember(memberId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy tham số box hỗ trợ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSysContactParams(
      options?: any,
    ): AxiosPromise<ResponseBaseAllSystemParamResponse> {
      return localVarFp
        .getSysContactParams(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Cập nhật email nhận thông báo
     * @summary Cập nhật email nhận thông báo
     * @param {UpdateContactEmailRequest} updateContactEmailRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateContactEmail(
      updateContactEmailRequest: UpdateContactEmailRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .updateContactEmail(updateContactEmailRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Chỉnh sửa thành viên
     * @summary Chỉnh sửa thành viên
     * @param {string} memberId
     * @param {PortalUpdateMemberRequest} portalUpdateMemberRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMember(
      memberId: string,
      portalUpdateMemberRequest: PortalUpdateMemberRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseUserResponse> {
      return localVarFp
        .updateMember(memberId, portalUpdateMemberRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createMember operation in PORTALSettingControllerApi.
 * @export
 * @interface PORTALSettingControllerApiCreateMemberRequest
 */
export interface PORTALSettingControllerApiCreateMemberRequest {
  /**
   *
   * @type {PortalCreateMemberRequest}
   * @memberof PORTALSettingControllerApiCreateMember
   */
  readonly portalCreateMemberRequest: PortalCreateMemberRequest;
}

/**
 * Request parameters for deleteMember operation in PORTALSettingControllerApi.
 * @export
 * @interface PORTALSettingControllerApiDeleteMemberRequest
 */
export interface PORTALSettingControllerApiDeleteMemberRequest {
  /**
   *
   * @type {string}
   * @memberof PORTALSettingControllerApiDeleteMember
   */
  readonly memberId: string;
}

/**
 * Request parameters for updateContactEmail operation in PORTALSettingControllerApi.
 * @export
 * @interface PORTALSettingControllerApiUpdateContactEmailRequest
 */
export interface PORTALSettingControllerApiUpdateContactEmailRequest {
  /**
   *
   * @type {UpdateContactEmailRequest}
   * @memberof PORTALSettingControllerApiUpdateContactEmail
   */
  readonly updateContactEmailRequest: UpdateContactEmailRequest;
}

/**
 * Request parameters for updateMember operation in PORTALSettingControllerApi.
 * @export
 * @interface PORTALSettingControllerApiUpdateMemberRequest
 */
export interface PORTALSettingControllerApiUpdateMemberRequest {
  /**
   *
   * @type {string}
   * @memberof PORTALSettingControllerApiUpdateMember
   */
  readonly memberId: string;

  /**
   *
   * @type {PortalUpdateMemberRequest}
   * @memberof PORTALSettingControllerApiUpdateMember
   */
  readonly portalUpdateMemberRequest: PortalUpdateMemberRequest;
}

/**
 * PORTALSettingControllerApi - object-oriented interface
 * @export
 * @class PORTALSettingControllerApi
 * @extends {BaseAPI}
 */
export class PORTALSettingControllerApi extends BaseAPI {
  /**
   * - Thêm mới thành viên
   * @summary Thêm mới thành viên
   * @param {PORTALSettingControllerApiCreateMemberRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PORTALSettingControllerApi
   */
  public createMember(
    requestParameters: PORTALSettingControllerApiCreateMemberRequest,
    options?: AxiosRequestConfig,
  ) {
    return PORTALSettingControllerApiFp(this.configuration)
      .createMember(requestParameters.portalCreateMemberRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Xóa thành viên
   * @summary Xóa thành viên
   * @param {PORTALSettingControllerApiDeleteMemberRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PORTALSettingControllerApi
   */
  public deleteMember(
    requestParameters: PORTALSettingControllerApiDeleteMemberRequest,
    options?: AxiosRequestConfig,
  ) {
    return PORTALSettingControllerApiFp(this.configuration)
      .deleteMember(requestParameters.memberId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy tham số box hỗ trợ
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PORTALSettingControllerApi
   */
  public getSysContactParams(options?: AxiosRequestConfig) {
    return PORTALSettingControllerApiFp(this.configuration)
      .getSysContactParams(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Cập nhật email nhận thông báo
   * @summary Cập nhật email nhận thông báo
   * @param {PORTALSettingControllerApiUpdateContactEmailRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PORTALSettingControllerApi
   */
  public updateContactEmail(
    requestParameters: PORTALSettingControllerApiUpdateContactEmailRequest,
    options?: AxiosRequestConfig,
  ) {
    return PORTALSettingControllerApiFp(this.configuration)
      .updateContactEmail(requestParameters.updateContactEmailRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Chỉnh sửa thành viên
   * @summary Chỉnh sửa thành viên
   * @param {PORTALSettingControllerApiUpdateMemberRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PORTALSettingControllerApi
   */
  public updateMember(
    requestParameters: PORTALSettingControllerApiUpdateMemberRequest,
    options?: AxiosRequestConfig,
  ) {
    return PORTALSettingControllerApiFp(this.configuration)
      .updateMember(
        requestParameters.memberId,
        requestParameters.portalUpdateMemberRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PayButtonControllerApi - axios parameter creator
 * @export
 */
export const PayButtonControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Tao moi Pay Button
     * @param {CreatePayButtonRequest} createPayButtonRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPayButton: async (
      createPayButtonRequest: CreatePayButtonRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createPayButtonRequest' is not null or undefined
      assertParamExists(
        "createPayButton",
        "createPayButtonRequest",
        createPayButtonRequest,
      );
      const localVarPath = `/api/paybutton/v1/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createPayButtonRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Chinh sua Pay Button
     * @param {DeletePayButtonRequest} deletePayButtonRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePayButton: async (
      deletePayButtonRequest: DeletePayButtonRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deletePayButtonRequest' is not null or undefined
      assertParamExists(
        "deletePayButton",
        "deletePayButtonRequest",
        deletePayButtonRequest,
      );
      const localVarPath = `/api/paybutton/v1/delete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        deletePayButtonRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy giao diện button
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExternalButton: async (
      code: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists("getExternalButton", "code", code);
      const localVarPath = `/api/paybutton/public/v1/external/{code}`.replace(
        `{${"code"}}`,
        encodeURIComponent(String(code)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy danh sách button
     * @param {string} [keyword]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getList1: async (
      keyword?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/paybutton/v1/getList`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (keyword !== undefined) {
        localVarQueryParameter["keyword"] = keyword;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter["sortDirection"] = sortDirection;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter["sortBy"] = sortBy;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xem thông tin button bằng button id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPayButton: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getPayButton", "id", id);
      const localVarPath = `/api/paybutton/v1/getDetail/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary xem thông tin button bằng button code
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPayButtonByCode: async (
      code: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists("getPayButtonByCode", "code", code);
      const localVarPath = `/api/paybutton/public/v1/{code}`.replace(
        `{${"code"}}`,
        encodeURIComponent(String(code)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Chinh sua Pay Button
     * @param {UpdatePayButtonRequest} updatePayButtonRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePayButton: async (
      updatePayButtonRequest: UpdatePayButtonRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updatePayButtonRequest' is not null or undefined
      assertParamExists(
        "updatePayButton",
        "updatePayButtonRequest",
        updatePayButtonRequest,
      );
      const localVarPath = `/api/paybutton/v1/update`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updatePayButtonRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PayButtonControllerApi - functional programming interface
 * @export
 */
export const PayButtonControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    PayButtonControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Tao moi Pay Button
     * @param {CreatePayButtonRequest} createPayButtonRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createPayButton(
      createPayButtonRequest: CreatePayButtonRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePayButtonResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createPayButton(
        createPayButtonRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Chinh sua Pay Button
     * @param {DeletePayButtonRequest} deletePayButtonRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deletePayButton(
      deletePayButtonRequest: DeletePayButtonRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deletePayButton(
        deletePayButtonRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy giao diện button
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getExternalButton(
      code: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetExternalButtonResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getExternalButton(code, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy danh sách button
     * @param {string} [keyword]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getList1(
      keyword?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponsePayButtonResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getList1(
        keyword,
        page,
        pageSize,
        sortDirection,
        sortBy,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Xem thông tin button bằng button id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPayButton(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePayButtonResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPayButton(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary xem thông tin button bằng button code
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPayButtonByCode(
      code: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePayButtonResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPayButtonByCode(code, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Chinh sua Pay Button
     * @param {UpdatePayButtonRequest} updatePayButtonRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updatePayButton(
      updatePayButtonRequest: UpdatePayButtonRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePayButtonResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updatePayButton(
        updatePayButtonRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PayButtonControllerApi - factory interface
 * @export
 */
export const PayButtonControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PayButtonControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Tao moi Pay Button
     * @param {CreatePayButtonRequest} createPayButtonRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPayButton(
      createPayButtonRequest: CreatePayButtonRequest,
      options?: any,
    ): AxiosPromise<ResponseBasePayButtonResponse> {
      return localVarFp
        .createPayButton(createPayButtonRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Chinh sua Pay Button
     * @param {DeletePayButtonRequest} deletePayButtonRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePayButton(
      deletePayButtonRequest: DeletePayButtonRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .deletePayButton(deletePayButtonRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy giao diện button
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExternalButton(
      code: string,
      options?: any,
    ): AxiosPromise<ResponseBaseGetExternalButtonResponse> {
      return localVarFp
        .getExternalButton(code, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy danh sách button
     * @param {string} [keyword]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getList1(
      keyword?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponsePayButtonResponse> {
      return localVarFp
        .getList1(keyword, page, pageSize, sortDirection, sortBy, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xem thông tin button bằng button id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPayButton(
      id: string,
      options?: any,
    ): AxiosPromise<ResponseBasePayButtonResponse> {
      return localVarFp
        .getPayButton(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary xem thông tin button bằng button code
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPayButtonByCode(
      code: string,
      options?: any,
    ): AxiosPromise<ResponseBasePayButtonResponse> {
      return localVarFp
        .getPayButtonByCode(code, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Chinh sua Pay Button
     * @param {UpdatePayButtonRequest} updatePayButtonRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePayButton(
      updatePayButtonRequest: UpdatePayButtonRequest,
      options?: any,
    ): AxiosPromise<ResponseBasePayButtonResponse> {
      return localVarFp
        .updatePayButton(updatePayButtonRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createPayButton operation in PayButtonControllerApi.
 * @export
 * @interface PayButtonControllerApiCreatePayButtonRequest
 */
export interface PayButtonControllerApiCreatePayButtonRequest {
  /**
   *
   * @type {CreatePayButtonRequest}
   * @memberof PayButtonControllerApiCreatePayButton
   */
  readonly createPayButtonRequest: CreatePayButtonRequest;
}

/**
 * Request parameters for deletePayButton operation in PayButtonControllerApi.
 * @export
 * @interface PayButtonControllerApiDeletePayButtonRequest
 */
export interface PayButtonControllerApiDeletePayButtonRequest {
  /**
   *
   * @type {DeletePayButtonRequest}
   * @memberof PayButtonControllerApiDeletePayButton
   */
  readonly deletePayButtonRequest: DeletePayButtonRequest;
}

/**
 * Request parameters for getExternalButton operation in PayButtonControllerApi.
 * @export
 * @interface PayButtonControllerApiGetExternalButtonRequest
 */
export interface PayButtonControllerApiGetExternalButtonRequest {
  /**
   *
   * @type {string}
   * @memberof PayButtonControllerApiGetExternalButton
   */
  readonly code: string;
}

/**
 * Request parameters for getList1 operation in PayButtonControllerApi.
 * @export
 * @interface PayButtonControllerApiGetList1Request
 */
export interface PayButtonControllerApiGetList1Request {
  /**
   *
   * @type {string}
   * @memberof PayButtonControllerApiGetList1
   */
  readonly keyword?: string;

  /**
   *
   * @type {number}
   * @memberof PayButtonControllerApiGetList1
   */
  readonly page?: number;

  /**
   *
   * @type {number}
   * @memberof PayButtonControllerApiGetList1
   */
  readonly pageSize?: number;

  /**
   *
   * @type {'ASC' | 'DESC'}
   * @memberof PayButtonControllerApiGetList1
   */
  readonly sortDirection?: "ASC" | "DESC";

  /**
   *
   * @type {string}
   * @memberof PayButtonControllerApiGetList1
   */
  readonly sortBy?: string;
}

/**
 * Request parameters for getPayButton operation in PayButtonControllerApi.
 * @export
 * @interface PayButtonControllerApiGetPayButtonRequest
 */
export interface PayButtonControllerApiGetPayButtonRequest {
  /**
   *
   * @type {string}
   * @memberof PayButtonControllerApiGetPayButton
   */
  readonly id: string;
}

/**
 * Request parameters for getPayButtonByCode operation in PayButtonControllerApi.
 * @export
 * @interface PayButtonControllerApiGetPayButtonByCodeRequest
 */
export interface PayButtonControllerApiGetPayButtonByCodeRequest {
  /**
   *
   * @type {string}
   * @memberof PayButtonControllerApiGetPayButtonByCode
   */
  readonly code: string;
}

/**
 * Request parameters for updatePayButton operation in PayButtonControllerApi.
 * @export
 * @interface PayButtonControllerApiUpdatePayButtonRequest
 */
export interface PayButtonControllerApiUpdatePayButtonRequest {
  /**
   *
   * @type {UpdatePayButtonRequest}
   * @memberof PayButtonControllerApiUpdatePayButton
   */
  readonly updatePayButtonRequest: UpdatePayButtonRequest;
}

/**
 * PayButtonControllerApi - object-oriented interface
 * @export
 * @class PayButtonControllerApi
 * @extends {BaseAPI}
 */
export class PayButtonControllerApi extends BaseAPI {
  /**
   *
   * @summary Tao moi Pay Button
   * @param {PayButtonControllerApiCreatePayButtonRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayButtonControllerApi
   */
  public createPayButton(
    requestParameters: PayButtonControllerApiCreatePayButtonRequest,
    options?: AxiosRequestConfig,
  ) {
    return PayButtonControllerApiFp(this.configuration)
      .createPayButton(requestParameters.createPayButtonRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Chinh sua Pay Button
   * @param {PayButtonControllerApiDeletePayButtonRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayButtonControllerApi
   */
  public deletePayButton(
    requestParameters: PayButtonControllerApiDeletePayButtonRequest,
    options?: AxiosRequestConfig,
  ) {
    return PayButtonControllerApiFp(this.configuration)
      .deletePayButton(requestParameters.deletePayButtonRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy giao diện button
   * @param {PayButtonControllerApiGetExternalButtonRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayButtonControllerApi
   */
  public getExternalButton(
    requestParameters: PayButtonControllerApiGetExternalButtonRequest,
    options?: AxiosRequestConfig,
  ) {
    return PayButtonControllerApiFp(this.configuration)
      .getExternalButton(requestParameters.code, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy danh sách button
   * @param {PayButtonControllerApiGetList1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayButtonControllerApi
   */
  public getList1(
    requestParameters: PayButtonControllerApiGetList1Request = {},
    options?: AxiosRequestConfig,
  ) {
    return PayButtonControllerApiFp(this.configuration)
      .getList1(
        requestParameters.keyword,
        requestParameters.page,
        requestParameters.pageSize,
        requestParameters.sortDirection,
        requestParameters.sortBy,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xem thông tin button bằng button id
   * @param {PayButtonControllerApiGetPayButtonRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayButtonControllerApi
   */
  public getPayButton(
    requestParameters: PayButtonControllerApiGetPayButtonRequest,
    options?: AxiosRequestConfig,
  ) {
    return PayButtonControllerApiFp(this.configuration)
      .getPayButton(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary xem thông tin button bằng button code
   * @param {PayButtonControllerApiGetPayButtonByCodeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayButtonControllerApi
   */
  public getPayButtonByCode(
    requestParameters: PayButtonControllerApiGetPayButtonByCodeRequest,
    options?: AxiosRequestConfig,
  ) {
    return PayButtonControllerApiFp(this.configuration)
      .getPayButtonByCode(requestParameters.code, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Chinh sua Pay Button
   * @param {PayButtonControllerApiUpdatePayButtonRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayButtonControllerApi
   */
  public updatePayButton(
    requestParameters: PayButtonControllerApiUpdatePayButtonRequest,
    options?: AxiosRequestConfig,
  ) {
    return PayButtonControllerApiFp(this.configuration)
      .updatePayButton(requestParameters.updatePayButtonRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PayPageControllerApi - axios parameter creator
 * @export
 */
export const PayPageControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Thay đổi trạng thái của Paypage
     * @param {string} payPageCode
     * @param {'ACTIVE' | 'INACTIVE'} status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeStatus: async (
      payPageCode: string,
      status: "ACTIVE" | "INACTIVE",
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'payPageCode' is not null or undefined
      assertParamExists("changeStatus", "payPageCode", payPageCode);
      // verify required parameter 'status' is not null or undefined
      assertParamExists("changeStatus", "status", status);
      const localVarPath = `/api/paypage/v1/changeStatus/{payPageCode}`.replace(
        `{${"payPageCode"}}`,
        encodeURIComponent(String(payPageCode)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Thay đổi tiêu đề của Paypage
     * @param {string} payPageCode
     * @param {ChangePayPageTitleRequest} changePayPageTitleRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeTitle: async (
      payPageCode: string,
      changePayPageTitleRequest: ChangePayPageTitleRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'payPageCode' is not null or undefined
      assertParamExists("changeTitle", "payPageCode", payPageCode);
      // verify required parameter 'changePayPageTitleRequest' is not null or undefined
      assertParamExists(
        "changeTitle",
        "changePayPageTitleRequest",
        changePayPageTitleRequest,
      );
      const localVarPath = `/api/paypage/v1/changeTitle/{payPageCode}`.replace(
        `{${"payPageCode"}}`,
        encodeURIComponent(String(payPageCode)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        changePayPageTitleRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Tạo mới paypage
     * @param {CreatePayPageRequest} createPayPageRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create1: async (
      createPayPageRequest: CreatePayPageRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createPayPageRequest' is not null or undefined
      assertParamExists(
        "create1",
        "createPayPageRequest",
        createPayPageRequest,
      );
      const localVarPath = `/api/paypage/v1/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createPayPageRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy tất cả paypages
     * @param {string} [name]
     * @param {'ACTIVE' | 'INACTIVE'} [status]
     * @param {string} [createdFrom]
     * @param {string} [createdTo]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllPayPage: async (
      name?: string,
      status?: "ACTIVE" | "INACTIVE",
      createdFrom?: string,
      createdTo?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/paypage/v1/getAll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (name !== undefined) {
        localVarQueryParameter["name"] = name;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (createdFrom !== undefined) {
        localVarQueryParameter["createdFrom"] = createdFrom;
      }

      if (createdTo !== undefined) {
        localVarQueryParameter["createdTo"] = createdTo;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter["sortDirection"] = sortDirection;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter["sortBy"] = sortBy;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy tất cả giao dịch của paypages
     * @param {string} payPageCode
     * @param {string} [transactionId]
     * @param {'NONE' | 'CREATED' | 'SUCCESS' | 'CANCELED' | 'FAIL' | 'TIMEOUT' | 'PENDING'} [status]
     * @param {string} [createdFrom]
     * @param {string} [createdTo]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllPayPageTransactions: async (
      payPageCode: string,
      transactionId?: string,
      status?:
        | "NONE"
        | "CREATED"
        | "SUCCESS"
        | "CANCELED"
        | "FAIL"
        | "TIMEOUT"
        | "PENDING",
      createdFrom?: string,
      createdTo?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'payPageCode' is not null or undefined
      assertParamExists(
        "getAllPayPageTransactions",
        "payPageCode",
        payPageCode,
      );
      const localVarPath =
        `/api/paypage/v1/getAllTransactions/{payPageCode}`.replace(
          `{${"payPageCode"}}`,
          encodeURIComponent(String(payPageCode)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (transactionId !== undefined) {
        localVarQueryParameter["transactionId"] = transactionId;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (createdFrom !== undefined) {
        localVarQueryParameter["createdFrom"] = createdFrom;
      }

      if (createdTo !== undefined) {
        localVarQueryParameter["createdTo"] = createdTo;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter["sortDirection"] = sortDirection;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter["sortBy"] = sortBy;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xem chi tiết paypage
     * @param {string} payPageCode
     * @param {string} [trackingButton]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDetail1: async (
      payPageCode: string,
      trackingButton?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'payPageCode' is not null or undefined
      assertParamExists("getDetail1", "payPageCode", payPageCode);
      const localVarPath =
        `/api/paypage/public/v1/getDetail/{payPageCode}`.replace(
          `{${"payPageCode"}}`,
          encodeURIComponent(String(payPageCode)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (trackingButton !== undefined) {
        localVarQueryParameter["trackingButton"] = trackingButton;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy tổng số giao dịch và tổng doanh thu (thành công) của paypages
     * @param {string} payPageCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPayPageRevenue: async (
      payPageCode: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'payPageCode' is not null or undefined
      assertParamExists("getPayPageRevenue", "payPageCode", payPageCode);
      const localVarPath = `/api/paypage/v1/getRevenue/{payPageCode}`.replace(
        `{${"payPageCode"}}`,
        encodeURIComponent(String(payPageCode)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xem chi tiết paypage
     * @param {string} payPageCode
     * @param {string} [trackingButton]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPrivateDetail: async (
      payPageCode: string,
      trackingButton?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'payPageCode' is not null or undefined
      assertParamExists("getPrivateDetail", "payPageCode", payPageCode);
      const localVarPath = `/api/paypage/v1/getDetail/{payPageCode}`.replace(
        `{${"payPageCode"}}`,
        encodeURIComponent(String(payPageCode)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (trackingButton !== undefined) {
        localVarQueryParameter["trackingButton"] = trackingButton;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Đặt hàng PayPage
     * @param {string} payPageCode
     * @param {PayPageOrderRequest} payPageOrderRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    order: async (
      payPageCode: string,
      payPageOrderRequest: PayPageOrderRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'payPageCode' is not null or undefined
      assertParamExists("order", "payPageCode", payPageCode);
      // verify required parameter 'payPageOrderRequest' is not null or undefined
      assertParamExists("order", "payPageOrderRequest", payPageOrderRequest);
      const localVarPath =
        `/api/paypage/public/v1/createOrder/{payPageCode}`.replace(
          `{${"payPageCode"}}`,
          encodeURIComponent(String(payPageCode)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        payPageOrderRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Portal xem chi tiết paypage
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    portalGetDetail: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("portalGetDetail", "id", id);
      const localVarPath = `/api/paypage/v1/getDetail/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Gửi link thanh toán qua Email/SMS
     * @param {SendEmailSmsPayPageRequest} sendEmailSmsPayPageRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendEmailSms: async (
      sendEmailSmsPayPageRequest: SendEmailSmsPayPageRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'sendEmailSmsPayPageRequest' is not null or undefined
      assertParamExists(
        "sendEmailSms",
        "sendEmailSmsPayPageRequest",
        sendEmailSmsPayPageRequest,
      );
      const localVarPath = `/api/paypage/v1/sendEmailSms`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        sendEmailSmsPayPageRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PayPageControllerApi - functional programming interface
 * @export
 */
export const PayPageControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    PayPageControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Thay đổi trạng thái của Paypage
     * @param {string} payPageCode
     * @param {'ACTIVE' | 'INACTIVE'} status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async changeStatus(
      payPageCode: string,
      status: "ACTIVE" | "INACTIVE",
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.changeStatus(
        payPageCode,
        status,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Thay đổi tiêu đề của Paypage
     * @param {string} payPageCode
     * @param {ChangePayPageTitleRequest} changePayPageTitleRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async changeTitle(
      payPageCode: string,
      changePayPageTitleRequest: ChangePayPageTitleRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.changeTitle(
        payPageCode,
        changePayPageTitleRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Tạo mới paypage
     * @param {CreatePayPageRequest} createPayPageRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async create1(
      createPayPageRequest: CreatePayPageRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePayPageResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.create1(
        createPayPageRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy tất cả paypages
     * @param {string} [name]
     * @param {'ACTIVE' | 'INACTIVE'} [status]
     * @param {string} [createdFrom]
     * @param {string} [createdTo]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllPayPage(
      name?: string,
      status?: "ACTIVE" | "INACTIVE",
      createdFrom?: string,
      createdTo?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponsePayPageResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPayPage(
        name,
        status,
        createdFrom,
        createdTo,
        page,
        pageSize,
        sortDirection,
        sortBy,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy tất cả giao dịch của paypages
     * @param {string} payPageCode
     * @param {string} [transactionId]
     * @param {'NONE' | 'CREATED' | 'SUCCESS' | 'CANCELED' | 'FAIL' | 'TIMEOUT' | 'PENDING'} [status]
     * @param {string} [createdFrom]
     * @param {string} [createdTo]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllPayPageTransactions(
      payPageCode: string,
      transactionId?: string,
      status?:
        | "NONE"
        | "CREATED"
        | "SUCCESS"
        | "CANCELED"
        | "FAIL"
        | "TIMEOUT"
        | "PENDING",
      createdFrom?: string,
      createdTo?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponsePayPageTransactionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllPayPageTransactions(
          payPageCode,
          transactionId,
          status,
          createdFrom,
          createdTo,
          page,
          pageSize,
          sortDirection,
          sortBy,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Xem chi tiết paypage
     * @param {string} payPageCode
     * @param {string} [trackingButton]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDetail1(
      payPageCode: string,
      trackingButton?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePayPageDetailResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDetail1(
        payPageCode,
        trackingButton,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy tổng số giao dịch và tổng doanh thu (thành công) của paypages
     * @param {string} payPageCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPayPageRevenue(
      payPageCode: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePayPageRevenueResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPayPageRevenue(payPageCode, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Xem chi tiết paypage
     * @param {string} payPageCode
     * @param {string} [trackingButton]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPrivateDetail(
      payPageCode: string,
      trackingButton?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePayPageDetailResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPrivateDetail(
          payPageCode,
          trackingButton,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Đặt hàng PayPage
     * @param {string} payPageCode
     * @param {PayPageOrderRequest} payPageOrderRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async order(
      payPageCode: string,
      payPageOrderRequest: PayPageOrderRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseOrderResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.order(
        payPageCode,
        payPageOrderRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Portal xem chi tiết paypage
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async portalGetDetail(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePayPageDetailResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.portalGetDetail(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Gửi link thanh toán qua Email/SMS
     * @param {SendEmailSmsPayPageRequest} sendEmailSmsPayPageRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sendEmailSms(
      sendEmailSmsPayPageRequest: SendEmailSmsPayPageRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.sendEmailSms(
        sendEmailSmsPayPageRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PayPageControllerApi - factory interface
 * @export
 */
export const PayPageControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PayPageControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Thay đổi trạng thái của Paypage
     * @param {string} payPageCode
     * @param {'ACTIVE' | 'INACTIVE'} status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeStatus(
      payPageCode: string,
      status: "ACTIVE" | "INACTIVE",
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .changeStatus(payPageCode, status, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Thay đổi tiêu đề của Paypage
     * @param {string} payPageCode
     * @param {ChangePayPageTitleRequest} changePayPageTitleRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeTitle(
      payPageCode: string,
      changePayPageTitleRequest: ChangePayPageTitleRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .changeTitle(payPageCode, changePayPageTitleRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Tạo mới paypage
     * @param {CreatePayPageRequest} createPayPageRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create1(
      createPayPageRequest: CreatePayPageRequest,
      options?: any,
    ): AxiosPromise<ResponseBasePayPageResponse> {
      return localVarFp
        .create1(createPayPageRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy tất cả paypages
     * @param {string} [name]
     * @param {'ACTIVE' | 'INACTIVE'} [status]
     * @param {string} [createdFrom]
     * @param {string} [createdTo]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllPayPage(
      name?: string,
      status?: "ACTIVE" | "INACTIVE",
      createdFrom?: string,
      createdTo?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponsePayPageResponse> {
      return localVarFp
        .getAllPayPage(
          name,
          status,
          createdFrom,
          createdTo,
          page,
          pageSize,
          sortDirection,
          sortBy,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy tất cả giao dịch của paypages
     * @param {string} payPageCode
     * @param {string} [transactionId]
     * @param {'NONE' | 'CREATED' | 'SUCCESS' | 'CANCELED' | 'FAIL' | 'TIMEOUT' | 'PENDING'} [status]
     * @param {string} [createdFrom]
     * @param {string} [createdTo]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllPayPageTransactions(
      payPageCode: string,
      transactionId?: string,
      status?:
        | "NONE"
        | "CREATED"
        | "SUCCESS"
        | "CANCELED"
        | "FAIL"
        | "TIMEOUT"
        | "PENDING",
      createdFrom?: string,
      createdTo?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponsePayPageTransactionResponse> {
      return localVarFp
        .getAllPayPageTransactions(
          payPageCode,
          transactionId,
          status,
          createdFrom,
          createdTo,
          page,
          pageSize,
          sortDirection,
          sortBy,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xem chi tiết paypage
     * @param {string} payPageCode
     * @param {string} [trackingButton]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDetail1(
      payPageCode: string,
      trackingButton?: string,
      options?: any,
    ): AxiosPromise<ResponseBasePayPageDetailResponse> {
      return localVarFp
        .getDetail1(payPageCode, trackingButton, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy tổng số giao dịch và tổng doanh thu (thành công) của paypages
     * @param {string} payPageCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPayPageRevenue(
      payPageCode: string,
      options?: any,
    ): AxiosPromise<ResponseBasePayPageRevenueResponse> {
      return localVarFp
        .getPayPageRevenue(payPageCode, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xem chi tiết paypage
     * @param {string} payPageCode
     * @param {string} [trackingButton]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPrivateDetail(
      payPageCode: string,
      trackingButton?: string,
      options?: any,
    ): AxiosPromise<ResponseBasePayPageDetailResponse> {
      return localVarFp
        .getPrivateDetail(payPageCode, trackingButton, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Đặt hàng PayPage
     * @param {string} payPageCode
     * @param {PayPageOrderRequest} payPageOrderRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    order(
      payPageCode: string,
      payPageOrderRequest: PayPageOrderRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseOrderResponse> {
      return localVarFp
        .order(payPageCode, payPageOrderRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Portal xem chi tiết paypage
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    portalGetDetail(
      id: number,
      options?: any,
    ): AxiosPromise<ResponseBasePayPageDetailResponse> {
      return localVarFp
        .portalGetDetail(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Gửi link thanh toán qua Email/SMS
     * @param {SendEmailSmsPayPageRequest} sendEmailSmsPayPageRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendEmailSms(
      sendEmailSmsPayPageRequest: SendEmailSmsPayPageRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .sendEmailSms(sendEmailSmsPayPageRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for changeStatus operation in PayPageControllerApi.
 * @export
 * @interface PayPageControllerApiChangeStatusRequest
 */
export interface PayPageControllerApiChangeStatusRequest {
  /**
   *
   * @type {string}
   * @memberof PayPageControllerApiChangeStatus
   */
  readonly payPageCode: string;

  /**
   *
   * @type {'ACTIVE' | 'INACTIVE'}
   * @memberof PayPageControllerApiChangeStatus
   */
  readonly status: "ACTIVE" | "INACTIVE";
}

/**
 * Request parameters for changeTitle operation in PayPageControllerApi.
 * @export
 * @interface PayPageControllerApiChangeTitleRequest
 */
export interface PayPageControllerApiChangeTitleRequest {
  /**
   *
   * @type {string}
   * @memberof PayPageControllerApiChangeTitle
   */
  readonly payPageCode: string;

  /**
   *
   * @type {ChangePayPageTitleRequest}
   * @memberof PayPageControllerApiChangeTitle
   */
  readonly changePayPageTitleRequest: ChangePayPageTitleRequest;
}

/**
 * Request parameters for create1 operation in PayPageControllerApi.
 * @export
 * @interface PayPageControllerApiCreate1Request
 */
export interface PayPageControllerApiCreate1Request {
  /**
   *
   * @type {CreatePayPageRequest}
   * @memberof PayPageControllerApiCreate1
   */
  readonly createPayPageRequest: CreatePayPageRequest;
}

/**
 * Request parameters for getAllPayPage operation in PayPageControllerApi.
 * @export
 * @interface PayPageControllerApiGetAllPayPageRequest
 */
export interface PayPageControllerApiGetAllPayPageRequest {
  /**
   *
   * @type {string}
   * @memberof PayPageControllerApiGetAllPayPage
   */
  readonly name?: string;

  /**
   *
   * @type {'ACTIVE' | 'INACTIVE'}
   * @memberof PayPageControllerApiGetAllPayPage
   */
  readonly status?: "ACTIVE" | "INACTIVE";

  /**
   *
   * @type {string}
   * @memberof PayPageControllerApiGetAllPayPage
   */
  readonly createdFrom?: string;

  /**
   *
   * @type {string}
   * @memberof PayPageControllerApiGetAllPayPage
   */
  readonly createdTo?: string;

  /**
   *
   * @type {number}
   * @memberof PayPageControllerApiGetAllPayPage
   */
  readonly page?: number;

  /**
   *
   * @type {number}
   * @memberof PayPageControllerApiGetAllPayPage
   */
  readonly pageSize?: number;

  /**
   *
   * @type {'ASC' | 'DESC'}
   * @memberof PayPageControllerApiGetAllPayPage
   */
  readonly sortDirection?: "ASC" | "DESC";

  /**
   *
   * @type {string}
   * @memberof PayPageControllerApiGetAllPayPage
   */
  readonly sortBy?: string;
}

/**
 * Request parameters for getAllPayPageTransactions operation in PayPageControllerApi.
 * @export
 * @interface PayPageControllerApiGetAllPayPageTransactionsRequest
 */
export interface PayPageControllerApiGetAllPayPageTransactionsRequest {
  /**
   *
   * @type {string}
   * @memberof PayPageControllerApiGetAllPayPageTransactions
   */
  readonly payPageCode: string;

  /**
   *
   * @type {string}
   * @memberof PayPageControllerApiGetAllPayPageTransactions
   */
  readonly transactionId?: string;

  /**
   *
   * @type {'NONE' | 'CREATED' | 'SUCCESS' | 'CANCELED' | 'FAIL' | 'TIMEOUT' | 'PENDING'}
   * @memberof PayPageControllerApiGetAllPayPageTransactions
   */
  readonly status?:
    | "NONE"
    | "CREATED"
    | "SUCCESS"
    | "CANCELED"
    | "FAIL"
    | "TIMEOUT"
    | "PENDING";

  /**
   *
   * @type {string}
   * @memberof PayPageControllerApiGetAllPayPageTransactions
   */
  readonly createdFrom?: string;

  /**
   *
   * @type {string}
   * @memberof PayPageControllerApiGetAllPayPageTransactions
   */
  readonly createdTo?: string;

  /**
   *
   * @type {number}
   * @memberof PayPageControllerApiGetAllPayPageTransactions
   */
  readonly page?: number;

  /**
   *
   * @type {number}
   * @memberof PayPageControllerApiGetAllPayPageTransactions
   */
  readonly pageSize?: number;

  /**
   *
   * @type {'ASC' | 'DESC'}
   * @memberof PayPageControllerApiGetAllPayPageTransactions
   */
  readonly sortDirection?: "ASC" | "DESC";

  /**
   *
   * @type {string}
   * @memberof PayPageControllerApiGetAllPayPageTransactions
   */
  readonly sortBy?: string;
}

/**
 * Request parameters for getDetail1 operation in PayPageControllerApi.
 * @export
 * @interface PayPageControllerApiGetDetail1Request
 */
export interface PayPageControllerApiGetDetail1Request {
  /**
   *
   * @type {string}
   * @memberof PayPageControllerApiGetDetail1
   */
  readonly payPageCode: string;

  /**
   *
   * @type {string}
   * @memberof PayPageControllerApiGetDetail1
   */
  readonly trackingButton?: string;
}

/**
 * Request parameters for getPayPageRevenue operation in PayPageControllerApi.
 * @export
 * @interface PayPageControllerApiGetPayPageRevenueRequest
 */
export interface PayPageControllerApiGetPayPageRevenueRequest {
  /**
   *
   * @type {string}
   * @memberof PayPageControllerApiGetPayPageRevenue
   */
  readonly payPageCode: string;
}

/**
 * Request parameters for getPrivateDetail operation in PayPageControllerApi.
 * @export
 * @interface PayPageControllerApiGetPrivateDetailRequest
 */
export interface PayPageControllerApiGetPrivateDetailRequest {
  /**
   *
   * @type {string}
   * @memberof PayPageControllerApiGetPrivateDetail
   */
  readonly payPageCode: string;

  /**
   *
   * @type {string}
   * @memberof PayPageControllerApiGetPrivateDetail
   */
  readonly trackingButton?: string;
}

/**
 * Request parameters for order operation in PayPageControllerApi.
 * @export
 * @interface PayPageControllerApiOrderRequest
 */
export interface PayPageControllerApiOrderRequest {
  /**
   *
   * @type {string}
   * @memberof PayPageControllerApiOrder
   */
  readonly payPageCode: string;

  /**
   *
   * @type {PayPageOrderRequest}
   * @memberof PayPageControllerApiOrder
   */
  readonly payPageOrderRequest: PayPageOrderRequest;
}

/**
 * Request parameters for portalGetDetail operation in PayPageControllerApi.
 * @export
 * @interface PayPageControllerApiPortalGetDetailRequest
 */
export interface PayPageControllerApiPortalGetDetailRequest {
  /**
   *
   * @type {number}
   * @memberof PayPageControllerApiPortalGetDetail
   */
  readonly id: number;
}

/**
 * Request parameters for sendEmailSms operation in PayPageControllerApi.
 * @export
 * @interface PayPageControllerApiSendEmailSmsRequest
 */
export interface PayPageControllerApiSendEmailSmsRequest {
  /**
   *
   * @type {SendEmailSmsPayPageRequest}
   * @memberof PayPageControllerApiSendEmailSms
   */
  readonly sendEmailSmsPayPageRequest: SendEmailSmsPayPageRequest;
}

/**
 * PayPageControllerApi - object-oriented interface
 * @export
 * @class PayPageControllerApi
 * @extends {BaseAPI}
 */
export class PayPageControllerApi extends BaseAPI {
  /**
   *
   * @summary Thay đổi trạng thái của Paypage
   * @param {PayPageControllerApiChangeStatusRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayPageControllerApi
   */
  public changeStatus(
    requestParameters: PayPageControllerApiChangeStatusRequest,
    options?: AxiosRequestConfig,
  ) {
    return PayPageControllerApiFp(this.configuration)
      .changeStatus(
        requestParameters.payPageCode,
        requestParameters.status,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Thay đổi tiêu đề của Paypage
   * @param {PayPageControllerApiChangeTitleRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayPageControllerApi
   */
  public changeTitle(
    requestParameters: PayPageControllerApiChangeTitleRequest,
    options?: AxiosRequestConfig,
  ) {
    return PayPageControllerApiFp(this.configuration)
      .changeTitle(
        requestParameters.payPageCode,
        requestParameters.changePayPageTitleRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Tạo mới paypage
   * @param {PayPageControllerApiCreate1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayPageControllerApi
   */
  public create1(
    requestParameters: PayPageControllerApiCreate1Request,
    options?: AxiosRequestConfig,
  ) {
    return PayPageControllerApiFp(this.configuration)
      .create1(requestParameters.createPayPageRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy tất cả paypages
   * @param {PayPageControllerApiGetAllPayPageRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayPageControllerApi
   */
  public getAllPayPage(
    requestParameters: PayPageControllerApiGetAllPayPageRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return PayPageControllerApiFp(this.configuration)
      .getAllPayPage(
        requestParameters.name,
        requestParameters.status,
        requestParameters.createdFrom,
        requestParameters.createdTo,
        requestParameters.page,
        requestParameters.pageSize,
        requestParameters.sortDirection,
        requestParameters.sortBy,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy tất cả giao dịch của paypages
   * @param {PayPageControllerApiGetAllPayPageTransactionsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayPageControllerApi
   */
  public getAllPayPageTransactions(
    requestParameters: PayPageControllerApiGetAllPayPageTransactionsRequest,
    options?: AxiosRequestConfig,
  ) {
    return PayPageControllerApiFp(this.configuration)
      .getAllPayPageTransactions(
        requestParameters.payPageCode,
        requestParameters.transactionId,
        requestParameters.status,
        requestParameters.createdFrom,
        requestParameters.createdTo,
        requestParameters.page,
        requestParameters.pageSize,
        requestParameters.sortDirection,
        requestParameters.sortBy,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xem chi tiết paypage
   * @param {PayPageControllerApiGetDetail1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayPageControllerApi
   */
  public getDetail1(
    requestParameters: PayPageControllerApiGetDetail1Request,
    options?: AxiosRequestConfig,
  ) {
    return PayPageControllerApiFp(this.configuration)
      .getDetail1(
        requestParameters.payPageCode,
        requestParameters.trackingButton,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy tổng số giao dịch và tổng doanh thu (thành công) của paypages
   * @param {PayPageControllerApiGetPayPageRevenueRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayPageControllerApi
   */
  public getPayPageRevenue(
    requestParameters: PayPageControllerApiGetPayPageRevenueRequest,
    options?: AxiosRequestConfig,
  ) {
    return PayPageControllerApiFp(this.configuration)
      .getPayPageRevenue(requestParameters.payPageCode, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xem chi tiết paypage
   * @param {PayPageControllerApiGetPrivateDetailRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayPageControllerApi
   */
  public getPrivateDetail(
    requestParameters: PayPageControllerApiGetPrivateDetailRequest,
    options?: AxiosRequestConfig,
  ) {
    return PayPageControllerApiFp(this.configuration)
      .getPrivateDetail(
        requestParameters.payPageCode,
        requestParameters.trackingButton,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Đặt hàng PayPage
   * @param {PayPageControllerApiOrderRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayPageControllerApi
   */
  public order(
    requestParameters: PayPageControllerApiOrderRequest,
    options?: AxiosRequestConfig,
  ) {
    return PayPageControllerApiFp(this.configuration)
      .order(
        requestParameters.payPageCode,
        requestParameters.payPageOrderRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Portal xem chi tiết paypage
   * @param {PayPageControllerApiPortalGetDetailRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayPageControllerApi
   */
  public portalGetDetail(
    requestParameters: PayPageControllerApiPortalGetDetailRequest,
    options?: AxiosRequestConfig,
  ) {
    return PayPageControllerApiFp(this.configuration)
      .portalGetDetail(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Gửi link thanh toán qua Email/SMS
   * @param {PayPageControllerApiSendEmailSmsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayPageControllerApi
   */
  public sendEmailSms(
    requestParameters: PayPageControllerApiSendEmailSmsRequest,
    options?: AxiosRequestConfig,
  ) {
    return PayPageControllerApiFp(this.configuration)
      .sendEmailSms(requestParameters.sendEmailSmsPayPageRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PayPosControllerApi - axios parameter creator
 * @export
 */
export const PayPosControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary UniPos kiểm tra merchant bằng số điện thoại
     * @param {string} xApiKey
     * @param {string} phone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkMerchant: async (
      xApiKey: string,
      phone: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xApiKey' is not null or undefined
      assertParamExists("checkMerchant", "xApiKey", xApiKey);
      // verify required parameter 'phone' is not null or undefined
      assertParamExists("checkMerchant", "phone", phone);
      const localVarPath = `/api/paypos/v1/uniPos/checkMerchant`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (phone !== undefined) {
        localVarQueryParameter["phone"] = phone;
      }

      if (xApiKey != null) {
        localVarHeaderParameter["x-api-key"] = String(xApiKey);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Portal lấy lịch sử giao dịch pos chi tiết
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDetailPosTransaction: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getDetailPosTransaction", "id", id);
      const localVarPath = `/api/paypos/v1/getDetailTransaction/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy danh sách máy POS
     * @param {string} [keyword]
     * @param {'P8' | 'P12'} [modelPos]
     * @param {'ACTIVE' | 'INACTIVE'} [status]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListPos: async (
      keyword?: string,
      modelPos?: "P8" | "P12",
      status?: "ACTIVE" | "INACTIVE",
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/paypos/v1/getListPos`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (keyword !== undefined) {
        localVarQueryParameter["keyword"] = keyword;
      }

      if (modelPos !== undefined) {
        localVarQueryParameter["modelPos"] = modelPos;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter["sortDirection"] = sortDirection;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter["sortBy"] = sortBy;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get merchantId of user
     * @param {string} xApiKey
     * @param {string} [keycloakId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMerchant: async (
      xApiKey: string,
      keycloakId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xApiKey' is not null or undefined
      assertParamExists("getMerchant", "xApiKey", xApiKey);
      const localVarPath = `/api/paypos/v1/uniPos/getMerchant`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (keycloakId !== undefined) {
        localVarQueryParameter["keycloakId"] = keycloakId;
      }

      if (xApiKey != null) {
        localVarHeaderParameter["x-api-key"] = String(xApiKey);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy danh sách kết toán
     * @param {string} [keyword]
     * @param {'SUCCESS' | 'FAIL' | 'CUT_OVER'} [status]
     * @param {string} [createdFrom]
     * @param {string} [createdTo]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPosListTransactionInPortal: async (
      keyword?: string,
      status?: "SUCCESS" | "FAIL" | "CUT_OVER",
      createdFrom?: string,
      createdTo?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/paypos/v1/portal/getSettlementList`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (keyword !== undefined) {
        localVarQueryParameter["keyword"] = keyword;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (createdFrom !== undefined) {
        localVarQueryParameter["createdFrom"] = createdFrom;
      }

      if (createdTo !== undefined) {
        localVarQueryParameter["createdTo"] = createdTo;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter["sortDirection"] = sortDirection;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter["sortBy"] = sortBy;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lịch sử thanh toán cho thanh toán thẻ và QR
     * @param {'VIET_QR' | 'ATM_CARD' | 'BANKING'} paymentType
     * @param {boolean} successAndFail
     * @param {string} [keyword]
     * @param {string} [deviceId]
     * @param {boolean} [finish]
     * @param {'SUCCESS' | 'FAILED' | 'TIMEOUT' | 'CANCEL' | 'REFUNDED' | 'CREATED'} [status]
     * @param {string} [createdFrom]
     * @param {string} [createdTo]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPosListTransactionInPortal1: async (
      paymentType: "VIET_QR" | "ATM_CARD" | "BANKING",
      successAndFail: boolean,
      keyword?: string,
      deviceId?: string,
      finish?: boolean,
      status?:
        | "SUCCESS"
        | "FAILED"
        | "TIMEOUT"
        | "CANCEL"
        | "REFUNDED"
        | "CREATED",
      createdFrom?: string,
      createdTo?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'paymentType' is not null or undefined
      assertParamExists(
        "getPosListTransactionInPortal1",
        "paymentType",
        paymentType,
      );
      // verify required parameter 'successAndFail' is not null or undefined
      assertParamExists(
        "getPosListTransactionInPortal1",
        "successAndFail",
        successAndFail,
      );
      const localVarPath = `/api/paypos/v1/portal/getAll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (keyword !== undefined) {
        localVarQueryParameter["keyword"] = keyword;
      }

      if (deviceId !== undefined) {
        localVarQueryParameter["deviceId"] = deviceId;
      }

      if (finish !== undefined) {
        localVarQueryParameter["finish"] = finish;
      }

      if (paymentType !== undefined) {
        localVarQueryParameter["paymentType"] = paymentType;
      }

      if (successAndFail !== undefined) {
        localVarQueryParameter["successAndFail"] = successAndFail;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (createdFrom !== undefined) {
        localVarQueryParameter["createdFrom"] = createdFrom;
      }

      if (createdTo !== undefined) {
        localVarQueryParameter["createdTo"] = createdTo;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter["sortDirection"] = sortDirection;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter["sortBy"] = sortBy;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy chi tiết kết toán
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSettlementDetail: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getSettlementDetail", "id", id);
      const localVarPath =
        `/api/paypos/v1/portal/getSettlementDetail/{id}`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(id)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PayPosControllerApi - functional programming interface
 * @export
 */
export const PayPosControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    PayPosControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary UniPos kiểm tra merchant bằng số điện thoại
     * @param {string} xApiKey
     * @param {string} phone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkMerchant(
      xApiKey: string,
      phone: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseMerchantInfoResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.checkMerchant(
        xApiKey,
        phone,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Portal lấy lịch sử giao dịch pos chi tiết
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDetailPosTransaction(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePayPosTransactionDetailResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getDetailPosTransaction(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy danh sách máy POS
     * @param {string} [keyword]
     * @param {'P8' | 'P12'} [modelPos]
     * @param {'ACTIVE' | 'INACTIVE'} [status]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getListPos(
      keyword?: string,
      modelPos?: "P8" | "P12",
      status?: "ACTIVE" | "INACTIVE",
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseGetPageListPosPortalResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getListPos(
        keyword,
        modelPos,
        status,
        page,
        pageSize,
        sortDirection,
        sortBy,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get merchantId of user
     * @param {string} xApiKey
     * @param {string} [keycloakId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMerchant(
      xApiKey: string,
      keycloakId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetMerchantInfoFromKeycloakResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getMerchant(
        xApiKey,
        keycloakId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy danh sách kết toán
     * @param {string} [keyword]
     * @param {'SUCCESS' | 'FAIL' | 'CUT_OVER'} [status]
     * @param {string} [createdFrom]
     * @param {string} [createdTo]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPosListTransactionInPortal(
      keyword?: string,
      status?: "SUCCESS" | "FAIL" | "CUT_OVER",
      createdFrom?: string,
      createdTo?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseGetPosHistoryTransactionPortalResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPosListTransactionInPortal(
          keyword,
          status,
          createdFrom,
          createdTo,
          page,
          pageSize,
          sortDirection,
          sortBy,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lịch sử thanh toán cho thanh toán thẻ và QR
     * @param {'VIET_QR' | 'ATM_CARD' | 'BANKING'} paymentType
     * @param {boolean} successAndFail
     * @param {string} [keyword]
     * @param {string} [deviceId]
     * @param {boolean} [finish]
     * @param {'SUCCESS' | 'FAILED' | 'TIMEOUT' | 'CANCEL' | 'REFUNDED' | 'CREATED'} [status]
     * @param {string} [createdFrom]
     * @param {string} [createdTo]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPosListTransactionInPortal1(
      paymentType: "VIET_QR" | "ATM_CARD" | "BANKING",
      successAndFail: boolean,
      keyword?: string,
      deviceId?: string,
      finish?: boolean,
      status?:
        | "SUCCESS"
        | "FAILED"
        | "TIMEOUT"
        | "CANCEL"
        | "REFUNDED"
        | "CREATED",
      createdFrom?: string,
      createdTo?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseGetPosHistoryTransactionPortalResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPosListTransactionInPortal1(
          paymentType,
          successAndFail,
          keyword,
          deviceId,
          finish,
          status,
          createdFrom,
          createdTo,
          page,
          pageSize,
          sortDirection,
          sortBy,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy chi tiết kết toán
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSettlementDetail(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseSettlementDetailResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSettlementDetail(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PayPosControllerApi - factory interface
 * @export
 */
export const PayPosControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PayPosControllerApiFp(configuration);
  return {
    /**
     *
     * @summary UniPos kiểm tra merchant bằng số điện thoại
     * @param {string} xApiKey
     * @param {string} phone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkMerchant(
      xApiKey: string,
      phone: string,
      options?: any,
    ): AxiosPromise<ResponseBaseMerchantInfoResponse> {
      return localVarFp
        .checkMerchant(xApiKey, phone, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Portal lấy lịch sử giao dịch pos chi tiết
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDetailPosTransaction(
      id: string,
      options?: any,
    ): AxiosPromise<ResponseBasePayPosTransactionDetailResponse> {
      return localVarFp
        .getDetailPosTransaction(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy danh sách máy POS
     * @param {string} [keyword]
     * @param {'P8' | 'P12'} [modelPos]
     * @param {'ACTIVE' | 'INACTIVE'} [status]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListPos(
      keyword?: string,
      modelPos?: "P8" | "P12",
      status?: "ACTIVE" | "INACTIVE",
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseGetPageListPosPortalResponse> {
      return localVarFp
        .getListPos(
          keyword,
          modelPos,
          status,
          page,
          pageSize,
          sortDirection,
          sortBy,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get merchantId of user
     * @param {string} xApiKey
     * @param {string} [keycloakId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMerchant(
      xApiKey: string,
      keycloakId?: string,
      options?: any,
    ): AxiosPromise<ResponseBaseGetMerchantInfoFromKeycloakResponse> {
      return localVarFp
        .getMerchant(xApiKey, keycloakId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy danh sách kết toán
     * @param {string} [keyword]
     * @param {'SUCCESS' | 'FAIL' | 'CUT_OVER'} [status]
     * @param {string} [createdFrom]
     * @param {string} [createdTo]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPosListTransactionInPortal(
      keyword?: string,
      status?: "SUCCESS" | "FAIL" | "CUT_OVER",
      createdFrom?: string,
      createdTo?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseGetPosHistoryTransactionPortalResponse> {
      return localVarFp
        .getPosListTransactionInPortal(
          keyword,
          status,
          createdFrom,
          createdTo,
          page,
          pageSize,
          sortDirection,
          sortBy,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lịch sử thanh toán cho thanh toán thẻ và QR
     * @param {'VIET_QR' | 'ATM_CARD' | 'BANKING'} paymentType
     * @param {boolean} successAndFail
     * @param {string} [keyword]
     * @param {string} [deviceId]
     * @param {boolean} [finish]
     * @param {'SUCCESS' | 'FAILED' | 'TIMEOUT' | 'CANCEL' | 'REFUNDED' | 'CREATED'} [status]
     * @param {string} [createdFrom]
     * @param {string} [createdTo]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPosListTransactionInPortal1(
      paymentType: "VIET_QR" | "ATM_CARD" | "BANKING",
      successAndFail: boolean,
      keyword?: string,
      deviceId?: string,
      finish?: boolean,
      status?:
        | "SUCCESS"
        | "FAILED"
        | "TIMEOUT"
        | "CANCEL"
        | "REFUNDED"
        | "CREATED",
      createdFrom?: string,
      createdTo?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseGetPosHistoryTransactionPortalResponse> {
      return localVarFp
        .getPosListTransactionInPortal1(
          paymentType,
          successAndFail,
          keyword,
          deviceId,
          finish,
          status,
          createdFrom,
          createdTo,
          page,
          pageSize,
          sortDirection,
          sortBy,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy chi tiết kết toán
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSettlementDetail(
      id: string,
      options?: any,
    ): AxiosPromise<ResponseBaseSettlementDetailResponse> {
      return localVarFp
        .getSettlementDetail(id, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for checkMerchant operation in PayPosControllerApi.
 * @export
 * @interface PayPosControllerApiCheckMerchantRequest
 */
export interface PayPosControllerApiCheckMerchantRequest {
  /**
   *
   * @type {string}
   * @memberof PayPosControllerApiCheckMerchant
   */
  readonly xApiKey: string;

  /**
   *
   * @type {string}
   * @memberof PayPosControllerApiCheckMerchant
   */
  readonly phone: string;
}

/**
 * Request parameters for getDetailPosTransaction operation in PayPosControllerApi.
 * @export
 * @interface PayPosControllerApiGetDetailPosTransactionRequest
 */
export interface PayPosControllerApiGetDetailPosTransactionRequest {
  /**
   *
   * @type {string}
   * @memberof PayPosControllerApiGetDetailPosTransaction
   */
  readonly id: string;
}

/**
 * Request parameters for getListPos operation in PayPosControllerApi.
 * @export
 * @interface PayPosControllerApiGetListPosRequest
 */
export interface PayPosControllerApiGetListPosRequest {
  /**
   *
   * @type {string}
   * @memberof PayPosControllerApiGetListPos
   */
  readonly keyword?: string;

  /**
   *
   * @type {'P8' | 'P12'}
   * @memberof PayPosControllerApiGetListPos
   */
  readonly modelPos?: "P8" | "P12";

  /**
   *
   * @type {'ACTIVE' | 'INACTIVE'}
   * @memberof PayPosControllerApiGetListPos
   */
  readonly status?: "ACTIVE" | "INACTIVE";

  /**
   *
   * @type {number}
   * @memberof PayPosControllerApiGetListPos
   */
  readonly page?: number;

  /**
   *
   * @type {number}
   * @memberof PayPosControllerApiGetListPos
   */
  readonly pageSize?: number;

  /**
   *
   * @type {'ASC' | 'DESC'}
   * @memberof PayPosControllerApiGetListPos
   */
  readonly sortDirection?: "ASC" | "DESC";

  /**
   *
   * @type {string}
   * @memberof PayPosControllerApiGetListPos
   */
  readonly sortBy?: string;
}

/**
 * Request parameters for getMerchant operation in PayPosControllerApi.
 * @export
 * @interface PayPosControllerApiGetMerchantRequest
 */
export interface PayPosControllerApiGetMerchantRequest {
  /**
   *
   * @type {string}
   * @memberof PayPosControllerApiGetMerchant
   */
  readonly xApiKey: string;

  /**
   *
   * @type {string}
   * @memberof PayPosControllerApiGetMerchant
   */
  readonly keycloakId?: string;
}

/**
 * Request parameters for getPosListTransactionInPortal operation in PayPosControllerApi.
 * @export
 * @interface PayPosControllerApiGetPosListTransactionInPortalRequest
 */
export interface PayPosControllerApiGetPosListTransactionInPortalRequest {
  /**
   *
   * @type {string}
   * @memberof PayPosControllerApiGetPosListTransactionInPortal
   */
  readonly keyword?: string;

  /**
   *
   * @type {'SUCCESS' | 'FAIL' | 'CUT_OVER'}
   * @memberof PayPosControllerApiGetPosListTransactionInPortal
   */
  readonly status?: "SUCCESS" | "FAIL" | "CUT_OVER";

  /**
   *
   * @type {string}
   * @memberof PayPosControllerApiGetPosListTransactionInPortal
   */
  readonly createdFrom?: string;

  /**
   *
   * @type {string}
   * @memberof PayPosControllerApiGetPosListTransactionInPortal
   */
  readonly createdTo?: string;

  /**
   *
   * @type {number}
   * @memberof PayPosControllerApiGetPosListTransactionInPortal
   */
  readonly page?: number;

  /**
   *
   * @type {number}
   * @memberof PayPosControllerApiGetPosListTransactionInPortal
   */
  readonly pageSize?: number;

  /**
   *
   * @type {'ASC' | 'DESC'}
   * @memberof PayPosControllerApiGetPosListTransactionInPortal
   */
  readonly sortDirection?: "ASC" | "DESC";

  /**
   *
   * @type {string}
   * @memberof PayPosControllerApiGetPosListTransactionInPortal
   */
  readonly sortBy?: string;
}

/**
 * Request parameters for getPosListTransactionInPortal1 operation in PayPosControllerApi.
 * @export
 * @interface PayPosControllerApiGetPosListTransactionInPortal1Request
 */
export interface PayPosControllerApiGetPosListTransactionInPortal1Request {
  /**
   *
   * @type {'VIET_QR' | 'ATM_CARD' | 'BANKING'}
   * @memberof PayPosControllerApiGetPosListTransactionInPortal1
   */
  readonly paymentType: "VIET_QR" | "ATM_CARD" | "BANKING";

  /**
   *
   * @type {boolean}
   * @memberof PayPosControllerApiGetPosListTransactionInPortal1
   */
  readonly successAndFail: boolean;

  /**
   *
   * @type {string}
   * @memberof PayPosControllerApiGetPosListTransactionInPortal1
   */
  readonly keyword?: string;

  /**
   *
   * @type {string}
   * @memberof PayPosControllerApiGetPosListTransactionInPortal1
   */
  readonly deviceId?: string;

  /**
   *
   * @type {boolean}
   * @memberof PayPosControllerApiGetPosListTransactionInPortal1
   */
  readonly finish?: boolean;

  /**
   *
   * @type {'SUCCESS' | 'FAILED' | 'TIMEOUT' | 'CANCEL' | 'REFUNDED' | 'CREATED'}
   * @memberof PayPosControllerApiGetPosListTransactionInPortal1
   */
  readonly status?:
    | "SUCCESS"
    | "FAILED"
    | "TIMEOUT"
    | "CANCEL"
    | "REFUNDED"
    | "CREATED";

  /**
   *
   * @type {string}
   * @memberof PayPosControllerApiGetPosListTransactionInPortal1
   */
  readonly createdFrom?: string;

  /**
   *
   * @type {string}
   * @memberof PayPosControllerApiGetPosListTransactionInPortal1
   */
  readonly createdTo?: string;

  /**
   *
   * @type {number}
   * @memberof PayPosControllerApiGetPosListTransactionInPortal1
   */
  readonly page?: number;

  /**
   *
   * @type {number}
   * @memberof PayPosControllerApiGetPosListTransactionInPortal1
   */
  readonly pageSize?: number;

  /**
   *
   * @type {'ASC' | 'DESC'}
   * @memberof PayPosControllerApiGetPosListTransactionInPortal1
   */
  readonly sortDirection?: "ASC" | "DESC";

  /**
   *
   * @type {string}
   * @memberof PayPosControllerApiGetPosListTransactionInPortal1
   */
  readonly sortBy?: string;
}

/**
 * Request parameters for getSettlementDetail operation in PayPosControllerApi.
 * @export
 * @interface PayPosControllerApiGetSettlementDetailRequest
 */
export interface PayPosControllerApiGetSettlementDetailRequest {
  /**
   *
   * @type {string}
   * @memberof PayPosControllerApiGetSettlementDetail
   */
  readonly id: string;
}

/**
 * PayPosControllerApi - object-oriented interface
 * @export
 * @class PayPosControllerApi
 * @extends {BaseAPI}
 */
export class PayPosControllerApi extends BaseAPI {
  /**
   *
   * @summary UniPos kiểm tra merchant bằng số điện thoại
   * @param {PayPosControllerApiCheckMerchantRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayPosControllerApi
   */
  public checkMerchant(
    requestParameters: PayPosControllerApiCheckMerchantRequest,
    options?: AxiosRequestConfig,
  ) {
    return PayPosControllerApiFp(this.configuration)
      .checkMerchant(
        requestParameters.xApiKey,
        requestParameters.phone,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Portal lấy lịch sử giao dịch pos chi tiết
   * @param {PayPosControllerApiGetDetailPosTransactionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayPosControllerApi
   */
  public getDetailPosTransaction(
    requestParameters: PayPosControllerApiGetDetailPosTransactionRequest,
    options?: AxiosRequestConfig,
  ) {
    return PayPosControllerApiFp(this.configuration)
      .getDetailPosTransaction(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy danh sách máy POS
   * @param {PayPosControllerApiGetListPosRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayPosControllerApi
   */
  public getListPos(
    requestParameters: PayPosControllerApiGetListPosRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return PayPosControllerApiFp(this.configuration)
      .getListPos(
        requestParameters.keyword,
        requestParameters.modelPos,
        requestParameters.status,
        requestParameters.page,
        requestParameters.pageSize,
        requestParameters.sortDirection,
        requestParameters.sortBy,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get merchantId of user
   * @param {PayPosControllerApiGetMerchantRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayPosControllerApi
   */
  public getMerchant(
    requestParameters: PayPosControllerApiGetMerchantRequest,
    options?: AxiosRequestConfig,
  ) {
    return PayPosControllerApiFp(this.configuration)
      .getMerchant(
        requestParameters.xApiKey,
        requestParameters.keycloakId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy danh sách kết toán
   * @param {PayPosControllerApiGetPosListTransactionInPortalRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayPosControllerApi
   */
  public getPosListTransactionInPortal(
    requestParameters: PayPosControllerApiGetPosListTransactionInPortalRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return PayPosControllerApiFp(this.configuration)
      .getPosListTransactionInPortal(
        requestParameters.keyword,
        requestParameters.status,
        requestParameters.createdFrom,
        requestParameters.createdTo,
        requestParameters.page,
        requestParameters.pageSize,
        requestParameters.sortDirection,
        requestParameters.sortBy,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lịch sử thanh toán cho thanh toán thẻ và QR
   * @param {PayPosControllerApiGetPosListTransactionInPortal1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayPosControllerApi
   */
  public getPosListTransactionInPortal1(
    requestParameters: PayPosControllerApiGetPosListTransactionInPortal1Request,
    options?: AxiosRequestConfig,
  ) {
    return PayPosControllerApiFp(this.configuration)
      .getPosListTransactionInPortal1(
        requestParameters.paymentType,
        requestParameters.successAndFail,
        requestParameters.keyword,
        requestParameters.deviceId,
        requestParameters.finish,
        requestParameters.status,
        requestParameters.createdFrom,
        requestParameters.createdTo,
        requestParameters.page,
        requestParameters.pageSize,
        requestParameters.sortDirection,
        requestParameters.sortBy,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy chi tiết kết toán
   * @param {PayPosControllerApiGetSettlementDetailRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayPosControllerApi
   */
  public getSettlementDetail(
    requestParameters: PayPosControllerApiGetSettlementDetailRequest,
    options?: AxiosRequestConfig,
  ) {
    return PayPosControllerApiFp(this.configuration)
      .getSettlementDetail(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PaySubscriptionControllerApi - axios parameter creator
 * @export
 */
export const PaySubscriptionControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Hủy Pay Subscription
     * @param {string} id
     * @param {CancelPaySubscriptionRequest} cancelPaySubscriptionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelPaySubscription: async (
      id: string,
      cancelPaySubscriptionRequest: CancelPaySubscriptionRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("cancelPaySubscription", "id", id);
      // verify required parameter 'cancelPaySubscriptionRequest' is not null or undefined
      assertParamExists(
        "cancelPaySubscription",
        "cancelPaySubscriptionRequest",
        cancelPaySubscriptionRequest,
      );
      const localVarPath = `/api/subscription/v1/cancel/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        cancelPaySubscriptionRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Tiếp tục Subscription
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    continuePaySubscription: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("continuePaySubscription", "id", id);
      const localVarPath = `/api/subscription/v1/continue/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Thêm mới Pay Subscription
     * @param {CreatePaySubscriptionRequest} createPaySubscriptionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPaySubscription: async (
      createPaySubscriptionRequest: CreatePaySubscriptionRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createPaySubscriptionRequest' is not null or undefined
      assertParamExists(
        "createPaySubscription",
        "createPaySubscriptionRequest",
        createPaySubscriptionRequest,
      );
      const localVarPath = `/api/subscription/v1/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createPaySubscriptionRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Gia hạn Pay Subscription
     * @param {string} id
     * @param {ExtendPaySubscriptionRequest} extendPaySubscriptionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    extendPaySubscription: async (
      id: string,
      extendPaySubscriptionRequest: ExtendPaySubscriptionRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("extendPaySubscription", "id", id);
      // verify required parameter 'extendPaySubscriptionRequest' is not null or undefined
      assertParamExists(
        "extendPaySubscription",
        "extendPaySubscriptionRequest",
        extendPaySubscriptionRequest,
      );
      const localVarPath = `/api/subscription/v1/extend/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        extendPaySubscriptionRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Gia hạn thanh toán Pay Subscription
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    extendPaymentPaySubscription: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("extendPaymentPaySubscription", "id", id);
      const localVarPath = `/api/subscription/v1/extend-payment/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy danh sách Pay Subscription
     * @param {string} [keyword]
     * @param {'TRIAL' | 'ACTIVE' | 'PAUSED' | 'PENDING_CANCELLATION' | 'CANCELLED' | 'EXPIRED' | 'PAYMENT_EXPIRED' | 'CREATE_INVOICE_FAILED'} [status]
     * @param {string} [planId]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllPaySubscription1: async (
      keyword?: string,
      status?:
        | "TRIAL"
        | "ACTIVE"
        | "PAUSED"
        | "PENDING_CANCELLATION"
        | "CANCELLED"
        | "EXPIRED"
        | "PAYMENT_EXPIRED"
        | "CREATE_INVOICE_FAILED",
      planId?: string,
      fromDate?: string,
      toDate?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/subscription/v1/get-all`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (keyword !== undefined) {
        localVarQueryParameter["keyword"] = keyword;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (planId !== undefined) {
        localVarQueryParameter["planId"] = planId;
      }

      if (fromDate !== undefined) {
        localVarQueryParameter["fromDate"] = fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter["toDate"] = toDate;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy chi tiết Pay Subscription
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDetailPaySubscription: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getDetailPaySubscription", "id", id);
      const localVarPath = `/api/subscription/v1/get-detail/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Tạm dừng Pay Subscription
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pausePaySubscription: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("pausePaySubscription", "id", id);
      const localVarPath = `/api/subscription/v1/pause/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Khôi phục Pay Subscription
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recreateInvoicePaySubscription: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("recreateInvoicePaySubscription", "id", id);
      const localVarPath = `/api/subscription/v1/recreate-invoice/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Khôi phục Pay Subscription
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    restorePaySubscription: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("restorePaySubscription", "id", id);
      const localVarPath = `/api/subscription/v1/restore/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PaySubscriptionControllerApi - functional programming interface
 * @export
 */
export const PaySubscriptionControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    PaySubscriptionControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Hủy Pay Subscription
     * @param {string} id
     * @param {CancelPaySubscriptionRequest} cancelPaySubscriptionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cancelPaySubscription(
      id: string,
      cancelPaySubscriptionRequest: CancelPaySubscriptionRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cancelPaySubscription(
          id,
          cancelPaySubscriptionRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Tiếp tục Subscription
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async continuePaySubscription(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.continuePaySubscription(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Thêm mới Pay Subscription
     * @param {CreatePaySubscriptionRequest} createPaySubscriptionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createPaySubscription(
      createPaySubscriptionRequest: CreatePaySubscriptionRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePaySubscriptionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createPaySubscription(
          createPaySubscriptionRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Gia hạn Pay Subscription
     * @param {string} id
     * @param {ExtendPaySubscriptionRequest} extendPaySubscriptionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async extendPaySubscription(
      id: string,
      extendPaySubscriptionRequest: ExtendPaySubscriptionRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.extendPaySubscription(
          id,
          extendPaySubscriptionRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Gia hạn thanh toán Pay Subscription
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async extendPaymentPaySubscription(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.extendPaymentPaySubscription(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy danh sách Pay Subscription
     * @param {string} [keyword]
     * @param {'TRIAL' | 'ACTIVE' | 'PAUSED' | 'PENDING_CANCELLATION' | 'CANCELLED' | 'EXPIRED' | 'PAYMENT_EXPIRED' | 'CREATE_INVOICE_FAILED'} [status]
     * @param {string} [planId]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllPaySubscription1(
      keyword?: string,
      status?:
        | "TRIAL"
        | "ACTIVE"
        | "PAUSED"
        | "PENDING_CANCELLATION"
        | "CANCELLED"
        | "EXPIRED"
        | "PAYMENT_EXPIRED"
        | "CREATE_INVOICE_FAILED",
      planId?: string,
      fromDate?: string,
      toDate?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponsePaySubscriptionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllPaySubscription1(
          keyword,
          status,
          planId,
          fromDate,
          toDate,
          page,
          size,
          sort,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy chi tiết Pay Subscription
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDetailPaySubscription(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePaySubscriptionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getDetailPaySubscription(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Tạm dừng Pay Subscription
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async pausePaySubscription(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.pausePaySubscription(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Khôi phục Pay Subscription
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async recreateInvoicePaySubscription(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.recreateInvoicePaySubscription(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Khôi phục Pay Subscription
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async restorePaySubscription(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.restorePaySubscription(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PaySubscriptionControllerApi - factory interface
 * @export
 */
export const PaySubscriptionControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PaySubscriptionControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Hủy Pay Subscription
     * @param {string} id
     * @param {CancelPaySubscriptionRequest} cancelPaySubscriptionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelPaySubscription(
      id: string,
      cancelPaySubscriptionRequest: CancelPaySubscriptionRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .cancelPaySubscription(id, cancelPaySubscriptionRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Tiếp tục Subscription
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    continuePaySubscription(
      id: string,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .continuePaySubscription(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Thêm mới Pay Subscription
     * @param {CreatePaySubscriptionRequest} createPaySubscriptionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPaySubscription(
      createPaySubscriptionRequest: CreatePaySubscriptionRequest,
      options?: any,
    ): AxiosPromise<ResponseBasePaySubscriptionResponse> {
      return localVarFp
        .createPaySubscription(createPaySubscriptionRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Gia hạn Pay Subscription
     * @param {string} id
     * @param {ExtendPaySubscriptionRequest} extendPaySubscriptionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    extendPaySubscription(
      id: string,
      extendPaySubscriptionRequest: ExtendPaySubscriptionRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .extendPaySubscription(id, extendPaySubscriptionRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Gia hạn thanh toán Pay Subscription
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    extendPaymentPaySubscription(
      id: string,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .extendPaymentPaySubscription(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy danh sách Pay Subscription
     * @param {string} [keyword]
     * @param {'TRIAL' | 'ACTIVE' | 'PAUSED' | 'PENDING_CANCELLATION' | 'CANCELLED' | 'EXPIRED' | 'PAYMENT_EXPIRED' | 'CREATE_INVOICE_FAILED'} [status]
     * @param {string} [planId]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllPaySubscription1(
      keyword?: string,
      status?:
        | "TRIAL"
        | "ACTIVE"
        | "PAUSED"
        | "PENDING_CANCELLATION"
        | "CANCELLED"
        | "EXPIRED"
        | "PAYMENT_EXPIRED"
        | "CREATE_INVOICE_FAILED",
      planId?: string,
      fromDate?: string,
      toDate?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponsePaySubscriptionResponse> {
      return localVarFp
        .getAllPaySubscription1(
          keyword,
          status,
          planId,
          fromDate,
          toDate,
          page,
          size,
          sort,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy chi tiết Pay Subscription
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDetailPaySubscription(
      id: string,
      options?: any,
    ): AxiosPromise<ResponseBasePaySubscriptionResponse> {
      return localVarFp
        .getDetailPaySubscription(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Tạm dừng Pay Subscription
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pausePaySubscription(
      id: string,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .pausePaySubscription(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Khôi phục Pay Subscription
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recreateInvoicePaySubscription(
      id: string,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .recreateInvoicePaySubscription(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Khôi phục Pay Subscription
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    restorePaySubscription(
      id: string,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .restorePaySubscription(id, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for cancelPaySubscription operation in PaySubscriptionControllerApi.
 * @export
 * @interface PaySubscriptionControllerApiCancelPaySubscriptionRequest
 */
export interface PaySubscriptionControllerApiCancelPaySubscriptionRequest {
  /**
   *
   * @type {string}
   * @memberof PaySubscriptionControllerApiCancelPaySubscription
   */
  readonly id: string;

  /**
   *
   * @type {CancelPaySubscriptionRequest}
   * @memberof PaySubscriptionControllerApiCancelPaySubscription
   */
  readonly cancelPaySubscriptionRequest: CancelPaySubscriptionRequest;
}

/**
 * Request parameters for continuePaySubscription operation in PaySubscriptionControllerApi.
 * @export
 * @interface PaySubscriptionControllerApiContinuePaySubscriptionRequest
 */
export interface PaySubscriptionControllerApiContinuePaySubscriptionRequest {
  /**
   *
   * @type {string}
   * @memberof PaySubscriptionControllerApiContinuePaySubscription
   */
  readonly id: string;
}

/**
 * Request parameters for createPaySubscription operation in PaySubscriptionControllerApi.
 * @export
 * @interface PaySubscriptionControllerApiCreatePaySubscriptionRequest
 */
export interface PaySubscriptionControllerApiCreatePaySubscriptionRequest {
  /**
   *
   * @type {CreatePaySubscriptionRequest}
   * @memberof PaySubscriptionControllerApiCreatePaySubscription
   */
  readonly createPaySubscriptionRequest: CreatePaySubscriptionRequest;
}

/**
 * Request parameters for extendPaySubscription operation in PaySubscriptionControllerApi.
 * @export
 * @interface PaySubscriptionControllerApiExtendPaySubscriptionRequest
 */
export interface PaySubscriptionControllerApiExtendPaySubscriptionRequest {
  /**
   *
   * @type {string}
   * @memberof PaySubscriptionControllerApiExtendPaySubscription
   */
  readonly id: string;

  /**
   *
   * @type {ExtendPaySubscriptionRequest}
   * @memberof PaySubscriptionControllerApiExtendPaySubscription
   */
  readonly extendPaySubscriptionRequest: ExtendPaySubscriptionRequest;
}

/**
 * Request parameters for extendPaymentPaySubscription operation in PaySubscriptionControllerApi.
 * @export
 * @interface PaySubscriptionControllerApiExtendPaymentPaySubscriptionRequest
 */
export interface PaySubscriptionControllerApiExtendPaymentPaySubscriptionRequest {
  /**
   *
   * @type {string}
   * @memberof PaySubscriptionControllerApiExtendPaymentPaySubscription
   */
  readonly id: string;
}

/**
 * Request parameters for getAllPaySubscription1 operation in PaySubscriptionControllerApi.
 * @export
 * @interface PaySubscriptionControllerApiGetAllPaySubscription1Request
 */
export interface PaySubscriptionControllerApiGetAllPaySubscription1Request {
  /**
   *
   * @type {string}
   * @memberof PaySubscriptionControllerApiGetAllPaySubscription1
   */
  readonly keyword?: string;

  /**
   *
   * @type {'TRIAL' | 'ACTIVE' | 'PAUSED' | 'PENDING_CANCELLATION' | 'CANCELLED' | 'EXPIRED' | 'PAYMENT_EXPIRED' | 'CREATE_INVOICE_FAILED'}
   * @memberof PaySubscriptionControllerApiGetAllPaySubscription1
   */
  readonly status?:
    | "TRIAL"
    | "ACTIVE"
    | "PAUSED"
    | "PENDING_CANCELLATION"
    | "CANCELLED"
    | "EXPIRED"
    | "PAYMENT_EXPIRED"
    | "CREATE_INVOICE_FAILED";

  /**
   *
   * @type {string}
   * @memberof PaySubscriptionControllerApiGetAllPaySubscription1
   */
  readonly planId?: string;

  /**
   *
   * @type {string}
   * @memberof PaySubscriptionControllerApiGetAllPaySubscription1
   */
  readonly fromDate?: string;

  /**
   *
   * @type {string}
   * @memberof PaySubscriptionControllerApiGetAllPaySubscription1
   */
  readonly toDate?: string;

  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof PaySubscriptionControllerApiGetAllPaySubscription1
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof PaySubscriptionControllerApiGetAllPaySubscription1
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof PaySubscriptionControllerApiGetAllPaySubscription1
   */
  readonly sort?: Array<string>;
}

/**
 * Request parameters for getDetailPaySubscription operation in PaySubscriptionControllerApi.
 * @export
 * @interface PaySubscriptionControllerApiGetDetailPaySubscriptionRequest
 */
export interface PaySubscriptionControllerApiGetDetailPaySubscriptionRequest {
  /**
   *
   * @type {string}
   * @memberof PaySubscriptionControllerApiGetDetailPaySubscription
   */
  readonly id: string;
}

/**
 * Request parameters for pausePaySubscription operation in PaySubscriptionControllerApi.
 * @export
 * @interface PaySubscriptionControllerApiPausePaySubscriptionRequest
 */
export interface PaySubscriptionControllerApiPausePaySubscriptionRequest {
  /**
   *
   * @type {string}
   * @memberof PaySubscriptionControllerApiPausePaySubscription
   */
  readonly id: string;
}

/**
 * Request parameters for recreateInvoicePaySubscription operation in PaySubscriptionControllerApi.
 * @export
 * @interface PaySubscriptionControllerApiRecreateInvoicePaySubscriptionRequest
 */
export interface PaySubscriptionControllerApiRecreateInvoicePaySubscriptionRequest {
  /**
   *
   * @type {string}
   * @memberof PaySubscriptionControllerApiRecreateInvoicePaySubscription
   */
  readonly id: string;
}

/**
 * Request parameters for restorePaySubscription operation in PaySubscriptionControllerApi.
 * @export
 * @interface PaySubscriptionControllerApiRestorePaySubscriptionRequest
 */
export interface PaySubscriptionControllerApiRestorePaySubscriptionRequest {
  /**
   *
   * @type {string}
   * @memberof PaySubscriptionControllerApiRestorePaySubscription
   */
  readonly id: string;
}

/**
 * PaySubscriptionControllerApi - object-oriented interface
 * @export
 * @class PaySubscriptionControllerApi
 * @extends {BaseAPI}
 */
export class PaySubscriptionControllerApi extends BaseAPI {
  /**
   *
   * @summary Hủy Pay Subscription
   * @param {PaySubscriptionControllerApiCancelPaySubscriptionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaySubscriptionControllerApi
   */
  public cancelPaySubscription(
    requestParameters: PaySubscriptionControllerApiCancelPaySubscriptionRequest,
    options?: AxiosRequestConfig,
  ) {
    return PaySubscriptionControllerApiFp(this.configuration)
      .cancelPaySubscription(
        requestParameters.id,
        requestParameters.cancelPaySubscriptionRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Tiếp tục Subscription
   * @param {PaySubscriptionControllerApiContinuePaySubscriptionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaySubscriptionControllerApi
   */
  public continuePaySubscription(
    requestParameters: PaySubscriptionControllerApiContinuePaySubscriptionRequest,
    options?: AxiosRequestConfig,
  ) {
    return PaySubscriptionControllerApiFp(this.configuration)
      .continuePaySubscription(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Thêm mới Pay Subscription
   * @param {PaySubscriptionControllerApiCreatePaySubscriptionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaySubscriptionControllerApi
   */
  public createPaySubscription(
    requestParameters: PaySubscriptionControllerApiCreatePaySubscriptionRequest,
    options?: AxiosRequestConfig,
  ) {
    return PaySubscriptionControllerApiFp(this.configuration)
      .createPaySubscription(
        requestParameters.createPaySubscriptionRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Gia hạn Pay Subscription
   * @param {PaySubscriptionControllerApiExtendPaySubscriptionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaySubscriptionControllerApi
   */
  public extendPaySubscription(
    requestParameters: PaySubscriptionControllerApiExtendPaySubscriptionRequest,
    options?: AxiosRequestConfig,
  ) {
    return PaySubscriptionControllerApiFp(this.configuration)
      .extendPaySubscription(
        requestParameters.id,
        requestParameters.extendPaySubscriptionRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Gia hạn thanh toán Pay Subscription
   * @param {PaySubscriptionControllerApiExtendPaymentPaySubscriptionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaySubscriptionControllerApi
   */
  public extendPaymentPaySubscription(
    requestParameters: PaySubscriptionControllerApiExtendPaymentPaySubscriptionRequest,
    options?: AxiosRequestConfig,
  ) {
    return PaySubscriptionControllerApiFp(this.configuration)
      .extendPaymentPaySubscription(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy danh sách Pay Subscription
   * @param {PaySubscriptionControllerApiGetAllPaySubscription1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaySubscriptionControllerApi
   */
  public getAllPaySubscription1(
    requestParameters: PaySubscriptionControllerApiGetAllPaySubscription1Request = {},
    options?: AxiosRequestConfig,
  ) {
    return PaySubscriptionControllerApiFp(this.configuration)
      .getAllPaySubscription1(
        requestParameters.keyword,
        requestParameters.status,
        requestParameters.planId,
        requestParameters.fromDate,
        requestParameters.toDate,
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy chi tiết Pay Subscription
   * @param {PaySubscriptionControllerApiGetDetailPaySubscriptionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaySubscriptionControllerApi
   */
  public getDetailPaySubscription(
    requestParameters: PaySubscriptionControllerApiGetDetailPaySubscriptionRequest,
    options?: AxiosRequestConfig,
  ) {
    return PaySubscriptionControllerApiFp(this.configuration)
      .getDetailPaySubscription(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Tạm dừng Pay Subscription
   * @param {PaySubscriptionControllerApiPausePaySubscriptionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaySubscriptionControllerApi
   */
  public pausePaySubscription(
    requestParameters: PaySubscriptionControllerApiPausePaySubscriptionRequest,
    options?: AxiosRequestConfig,
  ) {
    return PaySubscriptionControllerApiFp(this.configuration)
      .pausePaySubscription(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Khôi phục Pay Subscription
   * @param {PaySubscriptionControllerApiRecreateInvoicePaySubscriptionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaySubscriptionControllerApi
   */
  public recreateInvoicePaySubscription(
    requestParameters: PaySubscriptionControllerApiRecreateInvoicePaySubscriptionRequest,
    options?: AxiosRequestConfig,
  ) {
    return PaySubscriptionControllerApiFp(this.configuration)
      .recreateInvoicePaySubscription(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Khôi phục Pay Subscription
   * @param {PaySubscriptionControllerApiRestorePaySubscriptionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaySubscriptionControllerApi
   */
  public restorePaySubscription(
    requestParameters: PaySubscriptionControllerApiRestorePaySubscriptionRequest,
    options?: AxiosRequestConfig,
  ) {
    return PaySubscriptionControllerApiFp(this.configuration)
      .restorePaySubscription(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PaySubscriptionInvoiceControllerApi - axios parameter creator
 * @export
 */
export const PaySubscriptionInvoiceControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Lấy danh sách Pay Subscription Invocie
     * @param {string} [id]
     * @param {string} [subscriptionId]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllPaySubscriptionInvoice1: async (
      id?: string,
      subscriptionId?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/subscription-invoice/v1/get-all`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (id !== undefined) {
        localVarQueryParameter["id"] = id;
      }

      if (subscriptionId !== undefined) {
        localVarQueryParameter["subscriptionId"] = subscriptionId;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy chi tiết Pay Subcription Invoice
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDetailPaySubscriptionInvoice1: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getDetailPaySubscriptionInvoice1", "id", id);
      const localVarPath =
        `/api/subscription-invoice/v1/get-detail/{id}`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(id)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PaySubscriptionInvoiceControllerApi - functional programming interface
 * @export
 */
export const PaySubscriptionInvoiceControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    PaySubscriptionInvoiceControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Lấy danh sách Pay Subscription Invocie
     * @param {string} [id]
     * @param {string} [subscriptionId]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllPaySubscriptionInvoice1(
      id?: string,
      subscriptionId?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponsePaySubscriptionInvoiceResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllPaySubscriptionInvoice1(
          id,
          subscriptionId,
          page,
          size,
          sort,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy chi tiết Pay Subcription Invoice
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDetailPaySubscriptionInvoice1(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePaySubscriptionInvoiceResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getDetailPaySubscriptionInvoice1(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PaySubscriptionInvoiceControllerApi - factory interface
 * @export
 */
export const PaySubscriptionInvoiceControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PaySubscriptionInvoiceControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Lấy danh sách Pay Subscription Invocie
     * @param {string} [id]
     * @param {string} [subscriptionId]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllPaySubscriptionInvoice1(
      id?: string,
      subscriptionId?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponsePaySubscriptionInvoiceResponse> {
      return localVarFp
        .getAllPaySubscriptionInvoice1(
          id,
          subscriptionId,
          page,
          size,
          sort,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy chi tiết Pay Subcription Invoice
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDetailPaySubscriptionInvoice1(
      id: string,
      options?: any,
    ): AxiosPromise<ResponseBasePaySubscriptionInvoiceResponse> {
      return localVarFp
        .getDetailPaySubscriptionInvoice1(id, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getAllPaySubscriptionInvoice1 operation in PaySubscriptionInvoiceControllerApi.
 * @export
 * @interface PaySubscriptionInvoiceControllerApiGetAllPaySubscriptionInvoice1Request
 */
export interface PaySubscriptionInvoiceControllerApiGetAllPaySubscriptionInvoice1Request {
  /**
   *
   * @type {string}
   * @memberof PaySubscriptionInvoiceControllerApiGetAllPaySubscriptionInvoice1
   */
  readonly id?: string;

  /**
   *
   * @type {string}
   * @memberof PaySubscriptionInvoiceControllerApiGetAllPaySubscriptionInvoice1
   */
  readonly subscriptionId?: string;

  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof PaySubscriptionInvoiceControllerApiGetAllPaySubscriptionInvoice1
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof PaySubscriptionInvoiceControllerApiGetAllPaySubscriptionInvoice1
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof PaySubscriptionInvoiceControllerApiGetAllPaySubscriptionInvoice1
   */
  readonly sort?: Array<string>;
}

/**
 * Request parameters for getDetailPaySubscriptionInvoice1 operation in PaySubscriptionInvoiceControllerApi.
 * @export
 * @interface PaySubscriptionInvoiceControllerApiGetDetailPaySubscriptionInvoice1Request
 */
export interface PaySubscriptionInvoiceControllerApiGetDetailPaySubscriptionInvoice1Request {
  /**
   *
   * @type {string}
   * @memberof PaySubscriptionInvoiceControllerApiGetDetailPaySubscriptionInvoice1
   */
  readonly id: string;
}

/**
 * PaySubscriptionInvoiceControllerApi - object-oriented interface
 * @export
 * @class PaySubscriptionInvoiceControllerApi
 * @extends {BaseAPI}
 */
export class PaySubscriptionInvoiceControllerApi extends BaseAPI {
  /**
   *
   * @summary Lấy danh sách Pay Subscription Invocie
   * @param {PaySubscriptionInvoiceControllerApiGetAllPaySubscriptionInvoice1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaySubscriptionInvoiceControllerApi
   */
  public getAllPaySubscriptionInvoice1(
    requestParameters: PaySubscriptionInvoiceControllerApiGetAllPaySubscriptionInvoice1Request = {},
    options?: AxiosRequestConfig,
  ) {
    return PaySubscriptionInvoiceControllerApiFp(this.configuration)
      .getAllPaySubscriptionInvoice1(
        requestParameters.id,
        requestParameters.subscriptionId,
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy chi tiết Pay Subcription Invoice
   * @param {PaySubscriptionInvoiceControllerApiGetDetailPaySubscriptionInvoice1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaySubscriptionInvoiceControllerApi
   */
  public getDetailPaySubscriptionInvoice1(
    requestParameters: PaySubscriptionInvoiceControllerApiGetDetailPaySubscriptionInvoice1Request,
    options?: AxiosRequestConfig,
  ) {
    return PaySubscriptionInvoiceControllerApiFp(this.configuration)
      .getDetailPaySubscriptionInvoice1(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PaySubscriptionPlanControllerApi - axios parameter creator
 * @export
 */
export const PaySubscriptionPlanControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Thêm mới một Pay Subscription Plan
     * @param {CreatePaySubscriptionPlanRequest} createPaySubscriptionPlanRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPaySubscriptionPlan: async (
      createPaySubscriptionPlanRequest: CreatePaySubscriptionPlanRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createPaySubscriptionPlanRequest' is not null or undefined
      assertParamExists(
        "createPaySubscriptionPlan",
        "createPaySubscriptionPlanRequest",
        createPaySubscriptionPlanRequest,
      );
      const localVarPath = `/api/subscription-plan/v1/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createPaySubscriptionPlanRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xóa một Pay Subscription Plan
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePaySubscriptionPlan: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("deletePaySubscriptionPlan", "id", id);
      const localVarPath = `/api/subscription-plan/v1/delete/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy danh sách Pay Subscription Plan
     * @param {string} [keyword]
     * @param {'CREATED' | 'ACTIVE' | 'INACTIVE'} [status]
     * @param {'RECURRING_QUANTITY' | 'METERED_USAGE'} [type]
     * @param {'PREPAID' | 'POSTPAID'} [paymentType]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllPaySubscriptionPlan1: async (
      keyword?: string,
      status?: "CREATED" | "ACTIVE" | "INACTIVE",
      type?: "RECURRING_QUANTITY" | "METERED_USAGE",
      paymentType?: "PREPAID" | "POSTPAID",
      fromDate?: string,
      toDate?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/subscription-plan/v1/get-all`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (keyword !== undefined) {
        localVarQueryParameter["keyword"] = keyword;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (type !== undefined) {
        localVarQueryParameter["type"] = type;
      }

      if (paymentType !== undefined) {
        localVarQueryParameter["paymentType"] = paymentType;
      }

      if (fromDate !== undefined) {
        localVarQueryParameter["fromDate"] = fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter["toDate"] = toDate;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy nhanh danh sách Pay Subscription Plan
     * @param {string} [keyword]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllPaySubscriptionPlanCache: async (
      keyword?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/subscription-plan/v1/get-all-cache`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (keyword !== undefined) {
        localVarQueryParameter["keyword"] = keyword;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy chi tiết một Pay Subscription Plan
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDetailPaySubscriptionPlan: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getDetailPaySubscriptionPlan", "id", id);
      const localVarPath = `/api/subscription-plan/v1/get-detail/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Chỉnh sửa một Pay Subscription Plan
     * @param {UpdatePaySubscriptionPlanRequest} updatePaySubscriptionPlanRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePaySubscriptionPlan: async (
      updatePaySubscriptionPlanRequest: UpdatePaySubscriptionPlanRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updatePaySubscriptionPlanRequest' is not null or undefined
      assertParamExists(
        "updatePaySubscriptionPlan",
        "updatePaySubscriptionPlanRequest",
        updatePaySubscriptionPlanRequest,
      );
      const localVarPath = `/api/subscription-plan/v1/update`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updatePaySubscriptionPlanRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PaySubscriptionPlanControllerApi - functional programming interface
 * @export
 */
export const PaySubscriptionPlanControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    PaySubscriptionPlanControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Thêm mới một Pay Subscription Plan
     * @param {CreatePaySubscriptionPlanRequest} createPaySubscriptionPlanRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createPaySubscriptionPlan(
      createPaySubscriptionPlanRequest: CreatePaySubscriptionPlanRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePaySubscriptionPlanResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createPaySubscriptionPlan(
          createPaySubscriptionPlanRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Xóa một Pay Subscription Plan
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deletePaySubscriptionPlan(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deletePaySubscriptionPlan(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy danh sách Pay Subscription Plan
     * @param {string} [keyword]
     * @param {'CREATED' | 'ACTIVE' | 'INACTIVE'} [status]
     * @param {'RECURRING_QUANTITY' | 'METERED_USAGE'} [type]
     * @param {'PREPAID' | 'POSTPAID'} [paymentType]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllPaySubscriptionPlan1(
      keyword?: string,
      status?: "CREATED" | "ACTIVE" | "INACTIVE",
      type?: "RECURRING_QUANTITY" | "METERED_USAGE",
      paymentType?: "PREPAID" | "POSTPAID",
      fromDate?: string,
      toDate?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponsePaySubscriptionPlanResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllPaySubscriptionPlan1(
          keyword,
          status,
          type,
          paymentType,
          fromDate,
          toDate,
          page,
          size,
          sort,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy nhanh danh sách Pay Subscription Plan
     * @param {string} [keyword]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllPaySubscriptionPlanCache(
      keyword?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetAllPaySubscriptionPlanCacheResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllPaySubscriptionPlanCache(
          keyword,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy chi tiết một Pay Subscription Plan
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDetailPaySubscriptionPlan(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePaySubscriptionPlanResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getDetailPaySubscriptionPlan(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Chỉnh sửa một Pay Subscription Plan
     * @param {UpdatePaySubscriptionPlanRequest} updatePaySubscriptionPlanRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updatePaySubscriptionPlan(
      updatePaySubscriptionPlanRequest: UpdatePaySubscriptionPlanRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePaySubscriptionPlanResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updatePaySubscriptionPlan(
          updatePaySubscriptionPlanRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PaySubscriptionPlanControllerApi - factory interface
 * @export
 */
export const PaySubscriptionPlanControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PaySubscriptionPlanControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Thêm mới một Pay Subscription Plan
     * @param {CreatePaySubscriptionPlanRequest} createPaySubscriptionPlanRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPaySubscriptionPlan(
      createPaySubscriptionPlanRequest: CreatePaySubscriptionPlanRequest,
      options?: any,
    ): AxiosPromise<ResponseBasePaySubscriptionPlanResponse> {
      return localVarFp
        .createPaySubscriptionPlan(createPaySubscriptionPlanRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xóa một Pay Subscription Plan
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePaySubscriptionPlan(
      id: string,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .deletePaySubscriptionPlan(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy danh sách Pay Subscription Plan
     * @param {string} [keyword]
     * @param {'CREATED' | 'ACTIVE' | 'INACTIVE'} [status]
     * @param {'RECURRING_QUANTITY' | 'METERED_USAGE'} [type]
     * @param {'PREPAID' | 'POSTPAID'} [paymentType]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllPaySubscriptionPlan1(
      keyword?: string,
      status?: "CREATED" | "ACTIVE" | "INACTIVE",
      type?: "RECURRING_QUANTITY" | "METERED_USAGE",
      paymentType?: "PREPAID" | "POSTPAID",
      fromDate?: string,
      toDate?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponsePaySubscriptionPlanResponse> {
      return localVarFp
        .getAllPaySubscriptionPlan1(
          keyword,
          status,
          type,
          paymentType,
          fromDate,
          toDate,
          page,
          size,
          sort,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy nhanh danh sách Pay Subscription Plan
     * @param {string} [keyword]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllPaySubscriptionPlanCache(
      keyword?: string,
      options?: any,
    ): AxiosPromise<ResponseBaseGetAllPaySubscriptionPlanCacheResponse> {
      return localVarFp
        .getAllPaySubscriptionPlanCache(keyword, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy chi tiết một Pay Subscription Plan
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDetailPaySubscriptionPlan(
      id: string,
      options?: any,
    ): AxiosPromise<ResponseBasePaySubscriptionPlanResponse> {
      return localVarFp
        .getDetailPaySubscriptionPlan(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Chỉnh sửa một Pay Subscription Plan
     * @param {UpdatePaySubscriptionPlanRequest} updatePaySubscriptionPlanRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePaySubscriptionPlan(
      updatePaySubscriptionPlanRequest: UpdatePaySubscriptionPlanRequest,
      options?: any,
    ): AxiosPromise<ResponseBasePaySubscriptionPlanResponse> {
      return localVarFp
        .updatePaySubscriptionPlan(updatePaySubscriptionPlanRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createPaySubscriptionPlan operation in PaySubscriptionPlanControllerApi.
 * @export
 * @interface PaySubscriptionPlanControllerApiCreatePaySubscriptionPlanRequest
 */
export interface PaySubscriptionPlanControllerApiCreatePaySubscriptionPlanRequest {
  /**
   *
   * @type {CreatePaySubscriptionPlanRequest}
   * @memberof PaySubscriptionPlanControllerApiCreatePaySubscriptionPlan
   */
  readonly createPaySubscriptionPlanRequest: CreatePaySubscriptionPlanRequest;
}

/**
 * Request parameters for deletePaySubscriptionPlan operation in PaySubscriptionPlanControllerApi.
 * @export
 * @interface PaySubscriptionPlanControllerApiDeletePaySubscriptionPlanRequest
 */
export interface PaySubscriptionPlanControllerApiDeletePaySubscriptionPlanRequest {
  /**
   *
   * @type {string}
   * @memberof PaySubscriptionPlanControllerApiDeletePaySubscriptionPlan
   */
  readonly id: string;
}

/**
 * Request parameters for getAllPaySubscriptionPlan1 operation in PaySubscriptionPlanControllerApi.
 * @export
 * @interface PaySubscriptionPlanControllerApiGetAllPaySubscriptionPlan1Request
 */
export interface PaySubscriptionPlanControllerApiGetAllPaySubscriptionPlan1Request {
  /**
   *
   * @type {string}
   * @memberof PaySubscriptionPlanControllerApiGetAllPaySubscriptionPlan1
   */
  readonly keyword?: string;

  /**
   *
   * @type {'CREATED' | 'ACTIVE' | 'INACTIVE'}
   * @memberof PaySubscriptionPlanControllerApiGetAllPaySubscriptionPlan1
   */
  readonly status?: "CREATED" | "ACTIVE" | "INACTIVE";

  /**
   *
   * @type {'RECURRING_QUANTITY' | 'METERED_USAGE'}
   * @memberof PaySubscriptionPlanControllerApiGetAllPaySubscriptionPlan1
   */
  readonly type?: "RECURRING_QUANTITY" | "METERED_USAGE";

  /**
   *
   * @type {'PREPAID' | 'POSTPAID'}
   * @memberof PaySubscriptionPlanControllerApiGetAllPaySubscriptionPlan1
   */
  readonly paymentType?: "PREPAID" | "POSTPAID";

  /**
   *
   * @type {string}
   * @memberof PaySubscriptionPlanControllerApiGetAllPaySubscriptionPlan1
   */
  readonly fromDate?: string;

  /**
   *
   * @type {string}
   * @memberof PaySubscriptionPlanControllerApiGetAllPaySubscriptionPlan1
   */
  readonly toDate?: string;

  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof PaySubscriptionPlanControllerApiGetAllPaySubscriptionPlan1
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof PaySubscriptionPlanControllerApiGetAllPaySubscriptionPlan1
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof PaySubscriptionPlanControllerApiGetAllPaySubscriptionPlan1
   */
  readonly sort?: Array<string>;
}

/**
 * Request parameters for getAllPaySubscriptionPlanCache operation in PaySubscriptionPlanControllerApi.
 * @export
 * @interface PaySubscriptionPlanControllerApiGetAllPaySubscriptionPlanCacheRequest
 */
export interface PaySubscriptionPlanControllerApiGetAllPaySubscriptionPlanCacheRequest {
  /**
   *
   * @type {string}
   * @memberof PaySubscriptionPlanControllerApiGetAllPaySubscriptionPlanCache
   */
  readonly keyword?: string;
}

/**
 * Request parameters for getDetailPaySubscriptionPlan operation in PaySubscriptionPlanControllerApi.
 * @export
 * @interface PaySubscriptionPlanControllerApiGetDetailPaySubscriptionPlanRequest
 */
export interface PaySubscriptionPlanControllerApiGetDetailPaySubscriptionPlanRequest {
  /**
   *
   * @type {string}
   * @memberof PaySubscriptionPlanControllerApiGetDetailPaySubscriptionPlan
   */
  readonly id: string;
}

/**
 * Request parameters for updatePaySubscriptionPlan operation in PaySubscriptionPlanControllerApi.
 * @export
 * @interface PaySubscriptionPlanControllerApiUpdatePaySubscriptionPlanRequest
 */
export interface PaySubscriptionPlanControllerApiUpdatePaySubscriptionPlanRequest {
  /**
   *
   * @type {UpdatePaySubscriptionPlanRequest}
   * @memberof PaySubscriptionPlanControllerApiUpdatePaySubscriptionPlan
   */
  readonly updatePaySubscriptionPlanRequest: UpdatePaySubscriptionPlanRequest;
}

/**
 * PaySubscriptionPlanControllerApi - object-oriented interface
 * @export
 * @class PaySubscriptionPlanControllerApi
 * @extends {BaseAPI}
 */
export class PaySubscriptionPlanControllerApi extends BaseAPI {
  /**
   *
   * @summary Thêm mới một Pay Subscription Plan
   * @param {PaySubscriptionPlanControllerApiCreatePaySubscriptionPlanRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaySubscriptionPlanControllerApi
   */
  public createPaySubscriptionPlan(
    requestParameters: PaySubscriptionPlanControllerApiCreatePaySubscriptionPlanRequest,
    options?: AxiosRequestConfig,
  ) {
    return PaySubscriptionPlanControllerApiFp(this.configuration)
      .createPaySubscriptionPlan(
        requestParameters.createPaySubscriptionPlanRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xóa một Pay Subscription Plan
   * @param {PaySubscriptionPlanControllerApiDeletePaySubscriptionPlanRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaySubscriptionPlanControllerApi
   */
  public deletePaySubscriptionPlan(
    requestParameters: PaySubscriptionPlanControllerApiDeletePaySubscriptionPlanRequest,
    options?: AxiosRequestConfig,
  ) {
    return PaySubscriptionPlanControllerApiFp(this.configuration)
      .deletePaySubscriptionPlan(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy danh sách Pay Subscription Plan
   * @param {PaySubscriptionPlanControllerApiGetAllPaySubscriptionPlan1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaySubscriptionPlanControllerApi
   */
  public getAllPaySubscriptionPlan1(
    requestParameters: PaySubscriptionPlanControllerApiGetAllPaySubscriptionPlan1Request = {},
    options?: AxiosRequestConfig,
  ) {
    return PaySubscriptionPlanControllerApiFp(this.configuration)
      .getAllPaySubscriptionPlan1(
        requestParameters.keyword,
        requestParameters.status,
        requestParameters.type,
        requestParameters.paymentType,
        requestParameters.fromDate,
        requestParameters.toDate,
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy nhanh danh sách Pay Subscription Plan
   * @param {PaySubscriptionPlanControllerApiGetAllPaySubscriptionPlanCacheRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaySubscriptionPlanControllerApi
   */
  public getAllPaySubscriptionPlanCache(
    requestParameters: PaySubscriptionPlanControllerApiGetAllPaySubscriptionPlanCacheRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return PaySubscriptionPlanControllerApiFp(this.configuration)
      .getAllPaySubscriptionPlanCache(requestParameters.keyword, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy chi tiết một Pay Subscription Plan
   * @param {PaySubscriptionPlanControllerApiGetDetailPaySubscriptionPlanRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaySubscriptionPlanControllerApi
   */
  public getDetailPaySubscriptionPlan(
    requestParameters: PaySubscriptionPlanControllerApiGetDetailPaySubscriptionPlanRequest,
    options?: AxiosRequestConfig,
  ) {
    return PaySubscriptionPlanControllerApiFp(this.configuration)
      .getDetailPaySubscriptionPlan(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Chỉnh sửa một Pay Subscription Plan
   * @param {PaySubscriptionPlanControllerApiUpdatePaySubscriptionPlanRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaySubscriptionPlanControllerApi
   */
  public updatePaySubscriptionPlan(
    requestParameters: PaySubscriptionPlanControllerApiUpdatePaySubscriptionPlanRequest,
    options?: AxiosRequestConfig,
  ) {
    return PaySubscriptionPlanControllerApiFp(this.configuration)
      .updatePaySubscriptionPlan(
        requestParameters.updatePaySubscriptionPlanRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PayboxPayboxControllerApi - axios parameter creator
 * @export
 */
export const PayboxPayboxControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Update State transaction paybox
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelPayboxTransaction: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("cancelPayboxTransaction", "id", id);
      const localVarPath = `/api/paybox/transaction/v1/cancel/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all transaction paybox
     * @param {string} [keyword]
     * @param {'NONE' | 'CREATED' | 'SUCCESS' | 'CANCELED' | 'FAIL' | 'TIMEOUT' | 'PENDING'} [status]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {string} [transactionId]
     * @param {string} [refTransactionId]
     * @param {string} [merchantId]
     * @param {string} [payboxDeviceId]
     * @param {number} [pageSize]
     * @param {number} [pageNumber]
     * @param {string} [sortBy]
     * @param {string} [sortDir]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllPayboxTransaction: async (
      keyword?: string,
      status?:
        | "NONE"
        | "CREATED"
        | "SUCCESS"
        | "CANCELED"
        | "FAIL"
        | "TIMEOUT"
        | "PENDING",
      fromDate?: string,
      toDate?: string,
      transactionId?: string,
      refTransactionId?: string,
      merchantId?: string,
      payboxDeviceId?: string,
      pageSize?: number,
      pageNumber?: number,
      sortBy?: string,
      sortDir?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/paybox/transaction/v1/getAll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (keyword !== undefined) {
        localVarQueryParameter["keyword"] = keyword;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (fromDate !== undefined) {
        localVarQueryParameter["fromDate"] =
          (fromDate as any) instanceof Date
            ? (fromDate as any).toISOString()
            : fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter["toDate"] =
          (toDate as any) instanceof Date
            ? (toDate as any).toISOString()
            : toDate;
      }

      if (transactionId !== undefined) {
        localVarQueryParameter["transactionId"] = transactionId;
      }

      if (refTransactionId !== undefined) {
        localVarQueryParameter["refTransactionId"] = refTransactionId;
      }

      if (merchantId !== undefined) {
        localVarQueryParameter["merchantId"] = merchantId;
      }

      if (payboxDeviceId !== undefined) {
        localVarQueryParameter["payboxDeviceId"] = payboxDeviceId;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      if (pageNumber !== undefined) {
        localVarQueryParameter["pageNumber"] = pageNumber;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter["sortBy"] = sortBy;
      }

      if (sortDir !== undefined) {
        localVarQueryParameter["sortDir"] = sortDir;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PayboxPayboxControllerApi - functional programming interface
 * @export
 */
export const PayboxPayboxControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    PayboxPayboxControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Update State transaction paybox
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cancelPayboxTransaction(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cancelPayboxTransaction(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get all transaction paybox
     * @param {string} [keyword]
     * @param {'NONE' | 'CREATED' | 'SUCCESS' | 'CANCELED' | 'FAIL' | 'TIMEOUT' | 'PENDING'} [status]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {string} [transactionId]
     * @param {string} [refTransactionId]
     * @param {string} [merchantId]
     * @param {string} [payboxDeviceId]
     * @param {number} [pageSize]
     * @param {number} [pageNumber]
     * @param {string} [sortBy]
     * @param {string} [sortDir]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllPayboxTransaction(
      keyword?: string,
      status?:
        | "NONE"
        | "CREATED"
        | "SUCCESS"
        | "CANCELED"
        | "FAIL"
        | "TIMEOUT"
        | "PENDING",
      fromDate?: string,
      toDate?: string,
      transactionId?: string,
      refTransactionId?: string,
      merchantId?: string,
      payboxDeviceId?: string,
      pageSize?: number,
      pageNumber?: number,
      sortBy?: string,
      sortDir?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetAllPayboxTransactionsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllPayboxTransaction(
          keyword,
          status,
          fromDate,
          toDate,
          transactionId,
          refTransactionId,
          merchantId,
          payboxDeviceId,
          pageSize,
          pageNumber,
          sortBy,
          sortDir,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PayboxPayboxControllerApi - factory interface
 * @export
 */
export const PayboxPayboxControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PayboxPayboxControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Update State transaction paybox
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelPayboxTransaction(
      id: string,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .cancelPayboxTransaction(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get all transaction paybox
     * @param {string} [keyword]
     * @param {'NONE' | 'CREATED' | 'SUCCESS' | 'CANCELED' | 'FAIL' | 'TIMEOUT' | 'PENDING'} [status]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {string} [transactionId]
     * @param {string} [refTransactionId]
     * @param {string} [merchantId]
     * @param {string} [payboxDeviceId]
     * @param {number} [pageSize]
     * @param {number} [pageNumber]
     * @param {string} [sortBy]
     * @param {string} [sortDir]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllPayboxTransaction(
      keyword?: string,
      status?:
        | "NONE"
        | "CREATED"
        | "SUCCESS"
        | "CANCELED"
        | "FAIL"
        | "TIMEOUT"
        | "PENDING",
      fromDate?: string,
      toDate?: string,
      transactionId?: string,
      refTransactionId?: string,
      merchantId?: string,
      payboxDeviceId?: string,
      pageSize?: number,
      pageNumber?: number,
      sortBy?: string,
      sortDir?: string,
      options?: any,
    ): AxiosPromise<ResponseBaseGetAllPayboxTransactionsResponse> {
      return localVarFp
        .getAllPayboxTransaction(
          keyword,
          status,
          fromDate,
          toDate,
          transactionId,
          refTransactionId,
          merchantId,
          payboxDeviceId,
          pageSize,
          pageNumber,
          sortBy,
          sortDir,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for cancelPayboxTransaction operation in PayboxPayboxControllerApi.
 * @export
 * @interface PayboxPayboxControllerApiCancelPayboxTransactionRequest
 */
export interface PayboxPayboxControllerApiCancelPayboxTransactionRequest {
  /**
   *
   * @type {string}
   * @memberof PayboxPayboxControllerApiCancelPayboxTransaction
   */
  readonly id: string;
}

/**
 * Request parameters for getAllPayboxTransaction operation in PayboxPayboxControllerApi.
 * @export
 * @interface PayboxPayboxControllerApiGetAllPayboxTransactionRequest
 */
export interface PayboxPayboxControllerApiGetAllPayboxTransactionRequest {
  /**
   *
   * @type {string}
   * @memberof PayboxPayboxControllerApiGetAllPayboxTransaction
   */
  readonly keyword?: string;

  /**
   *
   * @type {'NONE' | 'CREATED' | 'SUCCESS' | 'CANCELED' | 'FAIL' | 'TIMEOUT' | 'PENDING'}
   * @memberof PayboxPayboxControllerApiGetAllPayboxTransaction
   */
  readonly status?:
    | "NONE"
    | "CREATED"
    | "SUCCESS"
    | "CANCELED"
    | "FAIL"
    | "TIMEOUT"
    | "PENDING";

  /**
   *
   * @type {string}
   * @memberof PayboxPayboxControllerApiGetAllPayboxTransaction
   */
  readonly fromDate?: string;

  /**
   *
   * @type {string}
   * @memberof PayboxPayboxControllerApiGetAllPayboxTransaction
   */
  readonly toDate?: string;

  /**
   *
   * @type {string}
   * @memberof PayboxPayboxControllerApiGetAllPayboxTransaction
   */
  readonly transactionId?: string;

  /**
   *
   * @type {string}
   * @memberof PayboxPayboxControllerApiGetAllPayboxTransaction
   */
  readonly refTransactionId?: string;

  /**
   *
   * @type {string}
   * @memberof PayboxPayboxControllerApiGetAllPayboxTransaction
   */
  readonly merchantId?: string;

  /**
   *
   * @type {string}
   * @memberof PayboxPayboxControllerApiGetAllPayboxTransaction
   */
  readonly payboxDeviceId?: string;

  /**
   *
   * @type {number}
   * @memberof PayboxPayboxControllerApiGetAllPayboxTransaction
   */
  readonly pageSize?: number;

  /**
   *
   * @type {number}
   * @memberof PayboxPayboxControllerApiGetAllPayboxTransaction
   */
  readonly pageNumber?: number;

  /**
   *
   * @type {string}
   * @memberof PayboxPayboxControllerApiGetAllPayboxTransaction
   */
  readonly sortBy?: string;

  /**
   *
   * @type {string}
   * @memberof PayboxPayboxControllerApiGetAllPayboxTransaction
   */
  readonly sortDir?: string;
}

/**
 * PayboxPayboxControllerApi - object-oriented interface
 * @export
 * @class PayboxPayboxControllerApi
 * @extends {BaseAPI}
 */
export class PayboxPayboxControllerApi extends BaseAPI {
  /**
   *
   * @summary Update State transaction paybox
   * @param {PayboxPayboxControllerApiCancelPayboxTransactionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayboxPayboxControllerApi
   */
  public cancelPayboxTransaction(
    requestParameters: PayboxPayboxControllerApiCancelPayboxTransactionRequest,
    options?: AxiosRequestConfig,
  ) {
    return PayboxPayboxControllerApiFp(this.configuration)
      .cancelPayboxTransaction(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get all transaction paybox
   * @param {PayboxPayboxControllerApiGetAllPayboxTransactionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayboxPayboxControllerApi
   */
  public getAllPayboxTransaction(
    requestParameters: PayboxPayboxControllerApiGetAllPayboxTransactionRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return PayboxPayboxControllerApiFp(this.configuration)
      .getAllPayboxTransaction(
        requestParameters.keyword,
        requestParameters.status,
        requestParameters.fromDate,
        requestParameters.toDate,
        requestParameters.transactionId,
        requestParameters.refTransactionId,
        requestParameters.merchantId,
        requestParameters.payboxDeviceId,
        requestParameters.pageSize,
        requestParameters.pageNumber,
        requestParameters.sortBy,
        requestParameters.sortDir,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PayboxUmeeControllerApi - axios parameter creator
 * @export
 */
export const PayboxUmeeControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Umee lấy thông tin máy paybox
     * @param {UmeeGetDeviceRequest} umeeGetDeviceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    umeeGetDevice: async (
      umeeGetDeviceRequest: UmeeGetDeviceRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'umeeGetDeviceRequest' is not null or undefined
      assertParamExists(
        "umeeGetDevice",
        "umeeGetDeviceRequest",
        umeeGetDeviceRequest,
      );
      const localVarPath = `/api/paybox/umee/v1/getDevice`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        umeeGetDeviceRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Umee lấy thông tin máy paybox
     * @param {UmeeNotifyRequest} umeeNotifyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    umeeNotify: async (
      umeeNotifyRequest: UmeeNotifyRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'umeeNotifyRequest' is not null or undefined
      assertParamExists("umeeNotify", "umeeNotifyRequest", umeeNotifyRequest);
      const localVarPath = `/api/paybox/umee/v1/notify`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        umeeNotifyRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Umee liên kết với merchant
     * @param {UmeePairRequest} umeePairRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    umeePair: async (
      umeePairRequest: UmeePairRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'umeePairRequest' is not null or undefined
      assertParamExists("umeePair", "umeePairRequest", umeePairRequest);
      const localVarPath = `/api/paybox/umee/v1/pair`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        umeePairRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Hiển thị mã QR giao dịch từ Umee lên paybox
     * @param {UmeeShowTransactionRequest} umeeShowTransactionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    umeeShowQrTransaction: async (
      umeeShowTransactionRequest: UmeeShowTransactionRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'umeeShowTransactionRequest' is not null or undefined
      assertParamExists(
        "umeeShowQrTransaction",
        "umeeShowTransactionRequest",
        umeeShowTransactionRequest,
      );
      const localVarPath = `/api/paybox/umee/v1/showQr`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        umeeShowTransactionRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Umee hủy liên kết với merchant
     * @param {UmeeUnpairRequest} umeeUnpairRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    umeeUnpair: async (
      umeeUnpairRequest: UmeeUnpairRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'umeeUnpairRequest' is not null or undefined
      assertParamExists("umeeUnpair", "umeeUnpairRequest", umeeUnpairRequest);
      const localVarPath = `/api/paybox/umee/v1/unpair`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        umeeUnpairRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PayboxUmeeControllerApi - functional programming interface
 * @export
 */
export const PayboxUmeeControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    PayboxUmeeControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Umee lấy thông tin máy paybox
     * @param {UmeeGetDeviceRequest} umeeGetDeviceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async umeeGetDevice(
      umeeGetDeviceRequest: UmeeGetDeviceRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseUmeeGetDeviceResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.umeeGetDevice(
        umeeGetDeviceRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Umee lấy thông tin máy paybox
     * @param {UmeeNotifyRequest} umeeNotifyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async umeeNotify(
      umeeNotifyRequest: UmeeNotifyRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.umeeNotify(
        umeeNotifyRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Umee liên kết với merchant
     * @param {UmeePairRequest} umeePairRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async umeePair(
      umeePairRequest: UmeePairRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseUmeePairResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.umeePair(
        umeePairRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Hiển thị mã QR giao dịch từ Umee lên paybox
     * @param {UmeeShowTransactionRequest} umeeShowTransactionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async umeeShowQrTransaction(
      umeeShowTransactionRequest: UmeeShowTransactionRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.umeeShowQrTransaction(
          umeeShowTransactionRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Umee hủy liên kết với merchant
     * @param {UmeeUnpairRequest} umeeUnpairRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async umeeUnpair(
      umeeUnpairRequest: UmeeUnpairRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.umeeUnpair(
        umeeUnpairRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PayboxUmeeControllerApi - factory interface
 * @export
 */
export const PayboxUmeeControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PayboxUmeeControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Umee lấy thông tin máy paybox
     * @param {UmeeGetDeviceRequest} umeeGetDeviceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    umeeGetDevice(
      umeeGetDeviceRequest: UmeeGetDeviceRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseUmeeGetDeviceResponse> {
      return localVarFp
        .umeeGetDevice(umeeGetDeviceRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Umee lấy thông tin máy paybox
     * @param {UmeeNotifyRequest} umeeNotifyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    umeeNotify(
      umeeNotifyRequest: UmeeNotifyRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .umeeNotify(umeeNotifyRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Umee liên kết với merchant
     * @param {UmeePairRequest} umeePairRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    umeePair(
      umeePairRequest: UmeePairRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseUmeePairResponse> {
      return localVarFp
        .umeePair(umeePairRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Hiển thị mã QR giao dịch từ Umee lên paybox
     * @param {UmeeShowTransactionRequest} umeeShowTransactionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    umeeShowQrTransaction(
      umeeShowTransactionRequest: UmeeShowTransactionRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .umeeShowQrTransaction(umeeShowTransactionRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Umee hủy liên kết với merchant
     * @param {UmeeUnpairRequest} umeeUnpairRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    umeeUnpair(
      umeeUnpairRequest: UmeeUnpairRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .umeeUnpair(umeeUnpairRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for umeeGetDevice operation in PayboxUmeeControllerApi.
 * @export
 * @interface PayboxUmeeControllerApiUmeeGetDeviceRequest
 */
export interface PayboxUmeeControllerApiUmeeGetDeviceRequest {
  /**
   *
   * @type {UmeeGetDeviceRequest}
   * @memberof PayboxUmeeControllerApiUmeeGetDevice
   */
  readonly umeeGetDeviceRequest: UmeeGetDeviceRequest;
}

/**
 * Request parameters for umeeNotify operation in PayboxUmeeControllerApi.
 * @export
 * @interface PayboxUmeeControllerApiUmeeNotifyRequest
 */
export interface PayboxUmeeControllerApiUmeeNotifyRequest {
  /**
   *
   * @type {UmeeNotifyRequest}
   * @memberof PayboxUmeeControllerApiUmeeNotify
   */
  readonly umeeNotifyRequest: UmeeNotifyRequest;
}

/**
 * Request parameters for umeePair operation in PayboxUmeeControllerApi.
 * @export
 * @interface PayboxUmeeControllerApiUmeePairRequest
 */
export interface PayboxUmeeControllerApiUmeePairRequest {
  /**
   *
   * @type {UmeePairRequest}
   * @memberof PayboxUmeeControllerApiUmeePair
   */
  readonly umeePairRequest: UmeePairRequest;
}

/**
 * Request parameters for umeeShowQrTransaction operation in PayboxUmeeControllerApi.
 * @export
 * @interface PayboxUmeeControllerApiUmeeShowQrTransactionRequest
 */
export interface PayboxUmeeControllerApiUmeeShowQrTransactionRequest {
  /**
   *
   * @type {UmeeShowTransactionRequest}
   * @memberof PayboxUmeeControllerApiUmeeShowQrTransaction
   */
  readonly umeeShowTransactionRequest: UmeeShowTransactionRequest;
}

/**
 * Request parameters for umeeUnpair operation in PayboxUmeeControllerApi.
 * @export
 * @interface PayboxUmeeControllerApiUmeeUnpairRequest
 */
export interface PayboxUmeeControllerApiUmeeUnpairRequest {
  /**
   *
   * @type {UmeeUnpairRequest}
   * @memberof PayboxUmeeControllerApiUmeeUnpair
   */
  readonly umeeUnpairRequest: UmeeUnpairRequest;
}

/**
 * PayboxUmeeControllerApi - object-oriented interface
 * @export
 * @class PayboxUmeeControllerApi
 * @extends {BaseAPI}
 */
export class PayboxUmeeControllerApi extends BaseAPI {
  /**
   *
   * @summary Umee lấy thông tin máy paybox
   * @param {PayboxUmeeControllerApiUmeeGetDeviceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayboxUmeeControllerApi
   */
  public umeeGetDevice(
    requestParameters: PayboxUmeeControllerApiUmeeGetDeviceRequest,
    options?: AxiosRequestConfig,
  ) {
    return PayboxUmeeControllerApiFp(this.configuration)
      .umeeGetDevice(requestParameters.umeeGetDeviceRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Umee lấy thông tin máy paybox
   * @param {PayboxUmeeControllerApiUmeeNotifyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayboxUmeeControllerApi
   */
  public umeeNotify(
    requestParameters: PayboxUmeeControllerApiUmeeNotifyRequest,
    options?: AxiosRequestConfig,
  ) {
    return PayboxUmeeControllerApiFp(this.configuration)
      .umeeNotify(requestParameters.umeeNotifyRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Umee liên kết với merchant
   * @param {PayboxUmeeControllerApiUmeePairRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayboxUmeeControllerApi
   */
  public umeePair(
    requestParameters: PayboxUmeeControllerApiUmeePairRequest,
    options?: AxiosRequestConfig,
  ) {
    return PayboxUmeeControllerApiFp(this.configuration)
      .umeePair(requestParameters.umeePairRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Hiển thị mã QR giao dịch từ Umee lên paybox
   * @param {PayboxUmeeControllerApiUmeeShowQrTransactionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayboxUmeeControllerApi
   */
  public umeeShowQrTransaction(
    requestParameters: PayboxUmeeControllerApiUmeeShowQrTransactionRequest,
    options?: AxiosRequestConfig,
  ) {
    return PayboxUmeeControllerApiFp(this.configuration)
      .umeeShowQrTransaction(
        requestParameters.umeeShowTransactionRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Umee hủy liên kết với merchant
   * @param {PayboxUmeeControllerApiUmeeUnpairRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayboxUmeeControllerApi
   */
  public umeeUnpair(
    requestParameters: PayboxUmeeControllerApiUmeeUnpairRequest,
    options?: AxiosRequestConfig,
  ) {
    return PayboxUmeeControllerApiFp(this.configuration)
      .umeeUnpair(requestParameters.umeeUnpairRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PaygateCallbackControllerApi - axios parameter creator
 * @export
 */
export const PaygateCallbackControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * - Kiểm tra trước khi gọi hoạch toán
     * @summary Kiểm tra trước khi gọi hoạch toán
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    depositChecking: async (
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xApiClient' is not null or undefined
      assertParamExists("depositChecking", "xApiClient", xApiClient);
      // verify required parameter 'xApiValidate' is not null or undefined
      assertParamExists("depositChecking", "xApiValidate", xApiValidate);
      // verify required parameter 'xApiTime' is not null or undefined
      assertParamExists("depositChecking", "xApiTime", xApiTime);
      // verify required parameter 'encryptedBodyRequest' is not null or undefined
      assertParamExists(
        "depositChecking",
        "encryptedBodyRequest",
        encryptedBodyRequest,
      );
      const localVarPath = `/api/paygate/callback/v2/depositChecking`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xApiClient != null) {
        localVarHeaderParameter["x-api-client"] = String(xApiClient);
      }

      if (xApiValidate != null) {
        localVarHeaderParameter["x-api-validate"] = String(xApiValidate);
      }

      if (xApiTime != null) {
        localVarHeaderParameter["x-api-time"] =
          typeof xApiTime === "string" ? xApiTime : JSON.stringify(xApiTime);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        encryptedBodyRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Kiểm tra tài khoản ảo
     * @summary Kiểm tra tài khoản ảo
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inquiryChecking: async (
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xApiClient' is not null or undefined
      assertParamExists("inquiryChecking", "xApiClient", xApiClient);
      // verify required parameter 'xApiValidate' is not null or undefined
      assertParamExists("inquiryChecking", "xApiValidate", xApiValidate);
      // verify required parameter 'xApiTime' is not null or undefined
      assertParamExists("inquiryChecking", "xApiTime", xApiTime);
      // verify required parameter 'encryptedBodyRequest' is not null or undefined
      assertParamExists(
        "inquiryChecking",
        "encryptedBodyRequest",
        encryptedBodyRequest,
      );
      const localVarPath = `/api/paygate/callback/v2/inquiryChecking`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xApiClient != null) {
        localVarHeaderParameter["x-api-client"] = String(xApiClient);
      }

      if (xApiValidate != null) {
        localVarHeaderParameter["x-api-validate"] = String(xApiValidate);
      }

      if (xApiTime != null) {
        localVarHeaderParameter["x-api-time"] =
          typeof xApiTime === "string" ? xApiTime : JSON.stringify(xApiTime);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        encryptedBodyRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Cập nhật trạng thái giao dịch
     * @summary Cập nhật trạng thái giao dịch
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notifyTransaction1: async (
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xApiClient' is not null or undefined
      assertParamExists("notifyTransaction1", "xApiClient", xApiClient);
      // verify required parameter 'xApiValidate' is not null or undefined
      assertParamExists("notifyTransaction1", "xApiValidate", xApiValidate);
      // verify required parameter 'xApiTime' is not null or undefined
      assertParamExists("notifyTransaction1", "xApiTime", xApiTime);
      // verify required parameter 'encryptedBodyRequest' is not null or undefined
      assertParamExists(
        "notifyTransaction1",
        "encryptedBodyRequest",
        encryptedBodyRequest,
      );
      const localVarPath = `/api/paygate/callback/v2/notifyTransaction`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xApiClient != null) {
        localVarHeaderParameter["x-api-client"] = String(xApiClient);
      }

      if (xApiValidate != null) {
        localVarHeaderParameter["x-api-validate"] = String(xApiValidate);
      }

      if (xApiTime != null) {
        localVarHeaderParameter["x-api-time"] =
          typeof xApiTime === "string" ? xApiTime : JSON.stringify(xApiTime);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        encryptedBodyRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PaygateCallbackControllerApi - functional programming interface
 * @export
 */
export const PaygateCallbackControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    PaygateCallbackControllerApiAxiosParamCreator(configuration);
  return {
    /**
     * - Kiểm tra trước khi gọi hoạch toán
     * @summary Kiểm tra trước khi gọi hoạch toán
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async depositChecking(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseString>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.depositChecking(
        xApiClient,
        xApiValidate,
        xApiTime,
        encryptedBodyRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Kiểm tra tài khoản ảo
     * @summary Kiểm tra tài khoản ảo
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async inquiryChecking(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseString>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.inquiryChecking(
        xApiClient,
        xApiValidate,
        xApiTime,
        encryptedBodyRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Cập nhật trạng thái giao dịch
     * @summary Cập nhật trạng thái giao dịch
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notifyTransaction1(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseString>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.notifyTransaction1(
          xApiClient,
          xApiValidate,
          xApiTime,
          encryptedBodyRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PaygateCallbackControllerApi - factory interface
 * @export
 */
export const PaygateCallbackControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PaygateCallbackControllerApiFp(configuration);
  return {
    /**
     * - Kiểm tra trước khi gọi hoạch toán
     * @summary Kiểm tra trước khi gọi hoạch toán
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    depositChecking(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseString> {
      return localVarFp
        .depositChecking(
          xApiClient,
          xApiValidate,
          xApiTime,
          encryptedBodyRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * - Kiểm tra tài khoản ảo
     * @summary Kiểm tra tài khoản ảo
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inquiryChecking(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseString> {
      return localVarFp
        .inquiryChecking(
          xApiClient,
          xApiValidate,
          xApiTime,
          encryptedBodyRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * - Cập nhật trạng thái giao dịch
     * @summary Cập nhật trạng thái giao dịch
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notifyTransaction1(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseString> {
      return localVarFp
        .notifyTransaction1(
          xApiClient,
          xApiValidate,
          xApiTime,
          encryptedBodyRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for depositChecking operation in PaygateCallbackControllerApi.
 * @export
 * @interface PaygateCallbackControllerApiDepositCheckingRequest
 */
export interface PaygateCallbackControllerApiDepositCheckingRequest {
  /**
   *
   * @type {string}
   * @memberof PaygateCallbackControllerApiDepositChecking
   */
  readonly xApiClient: string;

  /**
   *
   * @type {string}
   * @memberof PaygateCallbackControllerApiDepositChecking
   */
  readonly xApiValidate: string;

  /**
   *
   * @type {number}
   * @memberof PaygateCallbackControllerApiDepositChecking
   */
  readonly xApiTime: number;

  /**
   *
   * @type {EncryptedBodyRequest}
   * @memberof PaygateCallbackControllerApiDepositChecking
   */
  readonly encryptedBodyRequest: EncryptedBodyRequest;
}

/**
 * Request parameters for inquiryChecking operation in PaygateCallbackControllerApi.
 * @export
 * @interface PaygateCallbackControllerApiInquiryCheckingRequest
 */
export interface PaygateCallbackControllerApiInquiryCheckingRequest {
  /**
   *
   * @type {string}
   * @memberof PaygateCallbackControllerApiInquiryChecking
   */
  readonly xApiClient: string;

  /**
   *
   * @type {string}
   * @memberof PaygateCallbackControllerApiInquiryChecking
   */
  readonly xApiValidate: string;

  /**
   *
   * @type {number}
   * @memberof PaygateCallbackControllerApiInquiryChecking
   */
  readonly xApiTime: number;

  /**
   *
   * @type {EncryptedBodyRequest}
   * @memberof PaygateCallbackControllerApiInquiryChecking
   */
  readonly encryptedBodyRequest: EncryptedBodyRequest;
}

/**
 * Request parameters for notifyTransaction1 operation in PaygateCallbackControllerApi.
 * @export
 * @interface PaygateCallbackControllerApiNotifyTransaction1Request
 */
export interface PaygateCallbackControllerApiNotifyTransaction1Request {
  /**
   *
   * @type {string}
   * @memberof PaygateCallbackControllerApiNotifyTransaction1
   */
  readonly xApiClient: string;

  /**
   *
   * @type {string}
   * @memberof PaygateCallbackControllerApiNotifyTransaction1
   */
  readonly xApiValidate: string;

  /**
   *
   * @type {number}
   * @memberof PaygateCallbackControllerApiNotifyTransaction1
   */
  readonly xApiTime: number;

  /**
   *
   * @type {EncryptedBodyRequest}
   * @memberof PaygateCallbackControllerApiNotifyTransaction1
   */
  readonly encryptedBodyRequest: EncryptedBodyRequest;
}

/**
 * PaygateCallbackControllerApi - object-oriented interface
 * @export
 * @class PaygateCallbackControllerApi
 * @extends {BaseAPI}
 */
export class PaygateCallbackControllerApi extends BaseAPI {
  /**
   * - Kiểm tra trước khi gọi hoạch toán
   * @summary Kiểm tra trước khi gọi hoạch toán
   * @param {PaygateCallbackControllerApiDepositCheckingRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaygateCallbackControllerApi
   */
  public depositChecking(
    requestParameters: PaygateCallbackControllerApiDepositCheckingRequest,
    options?: AxiosRequestConfig,
  ) {
    return PaygateCallbackControllerApiFp(this.configuration)
      .depositChecking(
        requestParameters.xApiClient,
        requestParameters.xApiValidate,
        requestParameters.xApiTime,
        requestParameters.encryptedBodyRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Kiểm tra tài khoản ảo
   * @summary Kiểm tra tài khoản ảo
   * @param {PaygateCallbackControllerApiInquiryCheckingRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaygateCallbackControllerApi
   */
  public inquiryChecking(
    requestParameters: PaygateCallbackControllerApiInquiryCheckingRequest,
    options?: AxiosRequestConfig,
  ) {
    return PaygateCallbackControllerApiFp(this.configuration)
      .inquiryChecking(
        requestParameters.xApiClient,
        requestParameters.xApiValidate,
        requestParameters.xApiTime,
        requestParameters.encryptedBodyRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Cập nhật trạng thái giao dịch
   * @summary Cập nhật trạng thái giao dịch
   * @param {PaygateCallbackControllerApiNotifyTransaction1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaygateCallbackControllerApi
   */
  public notifyTransaction1(
    requestParameters: PaygateCallbackControllerApiNotifyTransaction1Request,
    options?: AxiosRequestConfig,
  ) {
    return PaygateCallbackControllerApiFp(this.configuration)
      .notifyTransaction1(
        requestParameters.xApiClient,
        requestParameters.xApiValidate,
        requestParameters.xApiTime,
        requestParameters.encryptedBodyRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PaylinkControllerApi - axios parameter creator
 * @export
 */
export const PaylinkControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Tạo mới paylink
     * @param {CreatePayLinkRequest} createPayLinkRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPayLink: async (
      createPayLinkRequest: CreatePayLinkRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createPayLinkRequest' is not null or undefined
      assertParamExists(
        "createPayLink",
        "createPayLinkRequest",
        createPayLinkRequest,
      );
      const localVarPath = `/api/paylink/v1/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createPayLinkRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * x-ummepay-client: key sử dụng cho các service nội bộ
     * @summary Tạo mới paylink dành cho http client nội bộ sử dụng api key
     * @param {CreatePayLinkInternalRequest} createPayLinkInternalRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPayLinkForInternal: async (
      createPayLinkInternalRequest: CreatePayLinkInternalRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createPayLinkInternalRequest' is not null or undefined
      assertParamExists(
        "createPayLinkForInternal",
        "createPayLinkInternalRequest",
        createPayLinkInternalRequest,
      );
      const localVarPath = `/api/paylink/private/v1/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication API-Key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "x-api-client",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createPayLinkInternalRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - keyword: mã PayLink hoặc mã giao dịch tham chiếu
     * @summary Lấy danh sách paylink
     * @param {string} [keyword]
     * @param {string} [phone]
     * @param {string} [requestDateFrom]
     * @param {string} [requestDateTo]
     * @param {'NONE' | 'CREATED' | 'SUCCESS' | 'CANCELED' | 'FAIL' | 'TIMEOUT' | 'PENDING'} [status]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListPayLink: async (
      keyword?: string,
      phone?: string,
      requestDateFrom?: string,
      requestDateTo?: string,
      status?:
        | "NONE"
        | "CREATED"
        | "SUCCESS"
        | "CANCELED"
        | "FAIL"
        | "TIMEOUT"
        | "PENDING",
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/paylink/v1/getList`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (keyword !== undefined) {
        localVarQueryParameter["keyword"] = keyword;
      }

      if (phone !== undefined) {
        localVarQueryParameter["phone"] = phone;
      }

      if (requestDateFrom !== undefined) {
        localVarQueryParameter["requestDateFrom"] =
          (requestDateFrom as any) instanceof Date
            ? (requestDateFrom as any).toISOString()
            : requestDateFrom;
      }

      if (requestDateTo !== undefined) {
        localVarQueryParameter["requestDateTo"] =
          (requestDateTo as any) instanceof Date
            ? (requestDateTo as any).toISOString()
            : requestDateTo;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter["sortDirection"] = sortDirection;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter["sortBy"] = sortBy;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy thông tin paylink
     * @param {string} payLinkCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPayLinkDetail: async (
      payLinkCode: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'payLinkCode' is not null or undefined
      assertParamExists("getPayLinkDetail", "payLinkCode", payLinkCode);
      const localVarPath = `/api/paylink/public/v1/{payLinkCode}`.replace(
        `{${"payLinkCode"}}`,
        encodeURIComponent(String(payLinkCode)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xử lý thanh toán PayLink Báo cáo phí
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handleFeePayLink: async (
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xApiClient' is not null or undefined
      assertParamExists("handleFeePayLink", "xApiClient", xApiClient);
      // verify required parameter 'xApiValidate' is not null or undefined
      assertParamExists("handleFeePayLink", "xApiValidate", xApiValidate);
      // verify required parameter 'xApiTime' is not null or undefined
      assertParamExists("handleFeePayLink", "xApiTime", xApiTime);
      // verify required parameter 'encryptedBodyRequest' is not null or undefined
      assertParamExists(
        "handleFeePayLink",
        "encryptedBodyRequest",
        encryptedBodyRequest,
      );
      const localVarPath = `/api/paylink/private/v1/fee/notifyTransaction`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xApiClient != null) {
        localVarHeaderParameter["x-api-client"] = String(xApiClient);
      }

      if (xApiValidate != null) {
        localVarHeaderParameter["x-api-validate"] = String(xApiValidate);
      }

      if (xApiTime != null) {
        localVarHeaderParameter["x-api-time"] =
          typeof xApiTime === "string" ? xApiTime : JSON.stringify(xApiTime);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        encryptedBodyRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Cập nhật trạng thái giao dịch từ Napas
     * @param {string} napasResult
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notifyTransaction: async (
      napasResult: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'napasResult' is not null or undefined
      assertParamExists("notifyTransaction", "napasResult", napasResult);
      const localVarPath = `/api/paylink/v1/napas/complete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (napasResult !== undefined) {
        localVarQueryParameter["napasResult"] = napasResult;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Điều hướng thanh toán Napas
     * @param {string} napasResult
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notifyTransactionRedirect: async (
      napasResult: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'napasResult' is not null or undefined
      assertParamExists(
        "notifyTransactionRedirect",
        "napasResult",
        napasResult,
      );
      const localVarPath = `/api/paylink/v1/napas/redirect`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (napasResult !== undefined) {
        localVarQueryParameter["napasResult"] = napasResult;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Thông tin thanh toán theo phương thức thanh toán chuyển khoản
     * @param {string} payLinkCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    payBankTransfer: async (
      payLinkCode: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'payLinkCode' is not null or undefined
      assertParamExists("payBankTransfer", "payLinkCode", payLinkCode);
      const localVarPath =
        `/api/paylink/public/v1/bankTransfer/{payLinkCode}`.replace(
          `{${"payLinkCode"}}`,
          encodeURIComponent(String(payLinkCode)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Thông tin thanh toán theo phương thức thanh toán thẻ Napas
     * @param {string} payLinkCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    payNapas: async (
      payLinkCode: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'payLinkCode' is not null or undefined
      assertParamExists("payNapas", "payLinkCode", payLinkCode);
      const localVarPath =
        `/api/paylink/public/v1/atm-card/{payLinkCode}`.replace(
          `{${"payLinkCode"}}`,
          encodeURIComponent(String(payLinkCode)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Thông tin thanh toán theo phương thức thanh toán VietQR
     * @param {string} payLinkCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    payVietQR: async (
      payLinkCode: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'payLinkCode' is not null or undefined
      assertParamExists("payVietQR", "payLinkCode", payLinkCode);
      const localVarPath =
        `/api/paylink/public/v1/vietqr/{payLinkCode}`.replace(
          `{${"payLinkCode"}}`,
          encodeURIComponent(String(payLinkCode)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Kiểm tra trạng thái paylink
     * @param {string} payLinkCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    queryPayLink: async (
      payLinkCode: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'payLinkCode' is not null or undefined
      assertParamExists("queryPayLink", "payLinkCode", payLinkCode);
      const localVarPath = `/api/paylink/public/v1/query/{payLinkCode}`.replace(
        `{${"payLinkCode"}}`,
        encodeURIComponent(String(payLinkCode)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Gửi link thanh toán qua Email/SMS
     * @param {SendEmailSmsPayLinkRequest} sendEmailSmsPayLinkRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendEmailSms1: async (
      sendEmailSmsPayLinkRequest: SendEmailSmsPayLinkRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'sendEmailSmsPayLinkRequest' is not null or undefined
      assertParamExists(
        "sendEmailSms1",
        "sendEmailSmsPayLinkRequest",
        sendEmailSmsPayLinkRequest,
      );
      const localVarPath = `/api/paylink/v1/sendEmailSms`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        sendEmailSmsPayLinkRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Cập nhật thông tin khách hàng thanh toán
     * @param {UpdateCustomerInfoRequest} updateCustomerInfoRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCustomerInfo: async (
      updateCustomerInfoRequest: UpdateCustomerInfoRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateCustomerInfoRequest' is not null or undefined
      assertParamExists(
        "updateCustomerInfo",
        "updateCustomerInfoRequest",
        updateCustomerInfoRequest,
      );
      const localVarPath = `/api/paylink/public/v1/updateCustomerInfo`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateCustomerInfoRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PaylinkControllerApi - functional programming interface
 * @export
 */
export const PaylinkControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    PaylinkControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Tạo mới paylink
     * @param {CreatePayLinkRequest} createPayLinkRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createPayLink(
      createPayLinkRequest: CreatePayLinkRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePayLinkResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createPayLink(
        createPayLinkRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * x-ummepay-client: key sử dụng cho các service nội bộ
     * @summary Tạo mới paylink dành cho http client nội bộ sử dụng api key
     * @param {CreatePayLinkInternalRequest} createPayLinkInternalRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createPayLinkForInternal(
      createPayLinkInternalRequest: CreatePayLinkInternalRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseResponseData>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createPayLinkForInternal(
          createPayLinkInternalRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - keyword: mã PayLink hoặc mã giao dịch tham chiếu
     * @summary Lấy danh sách paylink
     * @param {string} [keyword]
     * @param {string} [phone]
     * @param {string} [requestDateFrom]
     * @param {string} [requestDateTo]
     * @param {'NONE' | 'CREATED' | 'SUCCESS' | 'CANCELED' | 'FAIL' | 'TIMEOUT' | 'PENDING'} [status]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getListPayLink(
      keyword?: string,
      phone?: string,
      requestDateFrom?: string,
      requestDateTo?: string,
      status?:
        | "NONE"
        | "CREATED"
        | "SUCCESS"
        | "CANCELED"
        | "FAIL"
        | "TIMEOUT"
        | "PENDING",
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponsePayLinkDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getListPayLink(
        keyword,
        phone,
        requestDateFrom,
        requestDateTo,
        status,
        page,
        pageSize,
        sortDirection,
        sortBy,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy thông tin paylink
     * @param {string} payLinkCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPayLinkDetail(
      payLinkCode: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePayLinkResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPayLinkDetail(payLinkCode, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Xử lý thanh toán PayLink Báo cáo phí
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handleFeePayLink(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseString>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.handleFeePayLink(
          xApiClient,
          xApiValidate,
          xApiTime,
          encryptedBodyRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Cập nhật trạng thái giao dịch từ Napas
     * @param {string} napasResult
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notifyTransaction(
      napasResult: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseNapasNotifyTransactionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.notifyTransaction(napasResult, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Điều hướng thanh toán Napas
     * @param {string} napasResult
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notifyTransactionRedirect(
      napasResult: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RedirectView>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.notifyTransactionRedirect(
          napasResult,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Thông tin thanh toán theo phương thức thanh toán chuyển khoản
     * @param {string} payLinkCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async payBankTransfer(
      payLinkCode: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetPayLinkBankTransferResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.payBankTransfer(
        payLinkCode,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Thông tin thanh toán theo phương thức thanh toán thẻ Napas
     * @param {string} payLinkCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async payNapas(
      payLinkCode: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetPayLinkNapasResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.payNapas(
        payLinkCode,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Thông tin thanh toán theo phương thức thanh toán VietQR
     * @param {string} payLinkCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async payVietQR(
      payLinkCode: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetPayLinkVietQRResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.payVietQR(
        payLinkCode,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Kiểm tra trạng thái paylink
     * @param {string} payLinkCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async queryPayLink(
      payLinkCode: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePayLinkStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.queryPayLink(
        payLinkCode,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Gửi link thanh toán qua Email/SMS
     * @param {SendEmailSmsPayLinkRequest} sendEmailSmsPayLinkRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sendEmailSms1(
      sendEmailSmsPayLinkRequest: SendEmailSmsPayLinkRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.sendEmailSms1(
        sendEmailSmsPayLinkRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Cập nhật thông tin khách hàng thanh toán
     * @param {UpdateCustomerInfoRequest} updateCustomerInfoRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateCustomerInfo(
      updateCustomerInfoRequest: UpdateCustomerInfoRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateCustomerInfo(
          updateCustomerInfoRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PaylinkControllerApi - factory interface
 * @export
 */
export const PaylinkControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PaylinkControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Tạo mới paylink
     * @param {CreatePayLinkRequest} createPayLinkRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPayLink(
      createPayLinkRequest: CreatePayLinkRequest,
      options?: any,
    ): AxiosPromise<ResponseBasePayLinkResponse> {
      return localVarFp
        .createPayLink(createPayLinkRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * x-ummepay-client: key sử dụng cho các service nội bộ
     * @summary Tạo mới paylink dành cho http client nội bộ sử dụng api key
     * @param {CreatePayLinkInternalRequest} createPayLinkInternalRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPayLinkForInternal(
      createPayLinkInternalRequest: CreatePayLinkInternalRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseResponseData> {
      return localVarFp
        .createPayLinkForInternal(createPayLinkInternalRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - keyword: mã PayLink hoặc mã giao dịch tham chiếu
     * @summary Lấy danh sách paylink
     * @param {string} [keyword]
     * @param {string} [phone]
     * @param {string} [requestDateFrom]
     * @param {string} [requestDateTo]
     * @param {'NONE' | 'CREATED' | 'SUCCESS' | 'CANCELED' | 'FAIL' | 'TIMEOUT' | 'PENDING'} [status]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListPayLink(
      keyword?: string,
      phone?: string,
      requestDateFrom?: string,
      requestDateTo?: string,
      status?:
        | "NONE"
        | "CREATED"
        | "SUCCESS"
        | "CANCELED"
        | "FAIL"
        | "TIMEOUT"
        | "PENDING",
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponsePayLinkDto> {
      return localVarFp
        .getListPayLink(
          keyword,
          phone,
          requestDateFrom,
          requestDateTo,
          status,
          page,
          pageSize,
          sortDirection,
          sortBy,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy thông tin paylink
     * @param {string} payLinkCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPayLinkDetail(
      payLinkCode: string,
      options?: any,
    ): AxiosPromise<ResponseBasePayLinkResponse> {
      return localVarFp
        .getPayLinkDetail(payLinkCode, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xử lý thanh toán PayLink Báo cáo phí
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handleFeePayLink(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseString> {
      return localVarFp
        .handleFeePayLink(
          xApiClient,
          xApiValidate,
          xApiTime,
          encryptedBodyRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Cập nhật trạng thái giao dịch từ Napas
     * @param {string} napasResult
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notifyTransaction(
      napasResult: string,
      options?: any,
    ): AxiosPromise<ResponseBaseNapasNotifyTransactionResponse> {
      return localVarFp
        .notifyTransaction(napasResult, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Điều hướng thanh toán Napas
     * @param {string} napasResult
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notifyTransactionRedirect(
      napasResult: string,
      options?: any,
    ): AxiosPromise<RedirectView> {
      return localVarFp
        .notifyTransactionRedirect(napasResult, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Thông tin thanh toán theo phương thức thanh toán chuyển khoản
     * @param {string} payLinkCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    payBankTransfer(
      payLinkCode: string,
      options?: any,
    ): AxiosPromise<ResponseBaseGetPayLinkBankTransferResponse> {
      return localVarFp
        .payBankTransfer(payLinkCode, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Thông tin thanh toán theo phương thức thanh toán thẻ Napas
     * @param {string} payLinkCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    payNapas(
      payLinkCode: string,
      options?: any,
    ): AxiosPromise<ResponseBaseGetPayLinkNapasResponse> {
      return localVarFp
        .payNapas(payLinkCode, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Thông tin thanh toán theo phương thức thanh toán VietQR
     * @param {string} payLinkCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    payVietQR(
      payLinkCode: string,
      options?: any,
    ): AxiosPromise<ResponseBaseGetPayLinkVietQRResponse> {
      return localVarFp
        .payVietQR(payLinkCode, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Kiểm tra trạng thái paylink
     * @param {string} payLinkCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    queryPayLink(
      payLinkCode: string,
      options?: any,
    ): AxiosPromise<ResponseBasePayLinkStatusResponse> {
      return localVarFp
        .queryPayLink(payLinkCode, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Gửi link thanh toán qua Email/SMS
     * @param {SendEmailSmsPayLinkRequest} sendEmailSmsPayLinkRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendEmailSms1(
      sendEmailSmsPayLinkRequest: SendEmailSmsPayLinkRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .sendEmailSms1(sendEmailSmsPayLinkRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Cập nhật thông tin khách hàng thanh toán
     * @param {UpdateCustomerInfoRequest} updateCustomerInfoRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCustomerInfo(
      updateCustomerInfoRequest: UpdateCustomerInfoRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .updateCustomerInfo(updateCustomerInfoRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createPayLink operation in PaylinkControllerApi.
 * @export
 * @interface PaylinkControllerApiCreatePayLinkRequest
 */
export interface PaylinkControllerApiCreatePayLinkRequest {
  /**
   *
   * @type {CreatePayLinkRequest}
   * @memberof PaylinkControllerApiCreatePayLink
   */
  readonly createPayLinkRequest: CreatePayLinkRequest;
}

/**
 * Request parameters for createPayLinkForInternal operation in PaylinkControllerApi.
 * @export
 * @interface PaylinkControllerApiCreatePayLinkForInternalRequest
 */
export interface PaylinkControllerApiCreatePayLinkForInternalRequest {
  /**
   *
   * @type {CreatePayLinkInternalRequest}
   * @memberof PaylinkControllerApiCreatePayLinkForInternal
   */
  readonly createPayLinkInternalRequest: CreatePayLinkInternalRequest;
}

/**
 * Request parameters for getListPayLink operation in PaylinkControllerApi.
 * @export
 * @interface PaylinkControllerApiGetListPayLinkRequest
 */
export interface PaylinkControllerApiGetListPayLinkRequest {
  /**
   *
   * @type {string}
   * @memberof PaylinkControllerApiGetListPayLink
   */
  readonly keyword?: string;

  /**
   *
   * @type {string}
   * @memberof PaylinkControllerApiGetListPayLink
   */
  readonly phone?: string;

  /**
   *
   * @type {string}
   * @memberof PaylinkControllerApiGetListPayLink
   */
  readonly requestDateFrom?: string;

  /**
   *
   * @type {string}
   * @memberof PaylinkControllerApiGetListPayLink
   */
  readonly requestDateTo?: string;

  /**
   *
   * @type {'NONE' | 'CREATED' | 'SUCCESS' | 'CANCELED' | 'FAIL' | 'TIMEOUT' | 'PENDING'}
   * @memberof PaylinkControllerApiGetListPayLink
   */
  readonly status?:
    | "NONE"
    | "CREATED"
    | "SUCCESS"
    | "CANCELED"
    | "FAIL"
    | "TIMEOUT"
    | "PENDING";

  /**
   *
   * @type {number}
   * @memberof PaylinkControllerApiGetListPayLink
   */
  readonly page?: number;

  /**
   *
   * @type {number}
   * @memberof PaylinkControllerApiGetListPayLink
   */
  readonly pageSize?: number;

  /**
   *
   * @type {'ASC' | 'DESC'}
   * @memberof PaylinkControllerApiGetListPayLink
   */
  readonly sortDirection?: "ASC" | "DESC";

  /**
   *
   * @type {string}
   * @memberof PaylinkControllerApiGetListPayLink
   */
  readonly sortBy?: string;
}

/**
 * Request parameters for getPayLinkDetail operation in PaylinkControllerApi.
 * @export
 * @interface PaylinkControllerApiGetPayLinkDetailRequest
 */
export interface PaylinkControllerApiGetPayLinkDetailRequest {
  /**
   *
   * @type {string}
   * @memberof PaylinkControllerApiGetPayLinkDetail
   */
  readonly payLinkCode: string;
}

/**
 * Request parameters for handleFeePayLink operation in PaylinkControllerApi.
 * @export
 * @interface PaylinkControllerApiHandleFeePayLinkRequest
 */
export interface PaylinkControllerApiHandleFeePayLinkRequest {
  /**
   *
   * @type {string}
   * @memberof PaylinkControllerApiHandleFeePayLink
   */
  readonly xApiClient: string;

  /**
   *
   * @type {string}
   * @memberof PaylinkControllerApiHandleFeePayLink
   */
  readonly xApiValidate: string;

  /**
   *
   * @type {number}
   * @memberof PaylinkControllerApiHandleFeePayLink
   */
  readonly xApiTime: number;

  /**
   *
   * @type {EncryptedBodyRequest}
   * @memberof PaylinkControllerApiHandleFeePayLink
   */
  readonly encryptedBodyRequest: EncryptedBodyRequest;
}

/**
 * Request parameters for notifyTransaction operation in PaylinkControllerApi.
 * @export
 * @interface PaylinkControllerApiNotifyTransactionRequest
 */
export interface PaylinkControllerApiNotifyTransactionRequest {
  /**
   *
   * @type {string}
   * @memberof PaylinkControllerApiNotifyTransaction
   */
  readonly napasResult: string;
}

/**
 * Request parameters for notifyTransactionRedirect operation in PaylinkControllerApi.
 * @export
 * @interface PaylinkControllerApiNotifyTransactionRedirectRequest
 */
export interface PaylinkControllerApiNotifyTransactionRedirectRequest {
  /**
   *
   * @type {string}
   * @memberof PaylinkControllerApiNotifyTransactionRedirect
   */
  readonly napasResult: string;
}

/**
 * Request parameters for payBankTransfer operation in PaylinkControllerApi.
 * @export
 * @interface PaylinkControllerApiPayBankTransferRequest
 */
export interface PaylinkControllerApiPayBankTransferRequest {
  /**
   *
   * @type {string}
   * @memberof PaylinkControllerApiPayBankTransfer
   */
  readonly payLinkCode: string;
}

/**
 * Request parameters for payNapas operation in PaylinkControllerApi.
 * @export
 * @interface PaylinkControllerApiPayNapasRequest
 */
export interface PaylinkControllerApiPayNapasRequest {
  /**
   *
   * @type {string}
   * @memberof PaylinkControllerApiPayNapas
   */
  readonly payLinkCode: string;
}

/**
 * Request parameters for payVietQR operation in PaylinkControllerApi.
 * @export
 * @interface PaylinkControllerApiPayVietQRRequest
 */
export interface PaylinkControllerApiPayVietQRRequest {
  /**
   *
   * @type {string}
   * @memberof PaylinkControllerApiPayVietQR
   */
  readonly payLinkCode: string;
}

/**
 * Request parameters for queryPayLink operation in PaylinkControllerApi.
 * @export
 * @interface PaylinkControllerApiQueryPayLinkRequest
 */
export interface PaylinkControllerApiQueryPayLinkRequest {
  /**
   *
   * @type {string}
   * @memberof PaylinkControllerApiQueryPayLink
   */
  readonly payLinkCode: string;
}

/**
 * Request parameters for sendEmailSms1 operation in PaylinkControllerApi.
 * @export
 * @interface PaylinkControllerApiSendEmailSms1Request
 */
export interface PaylinkControllerApiSendEmailSms1Request {
  /**
   *
   * @type {SendEmailSmsPayLinkRequest}
   * @memberof PaylinkControllerApiSendEmailSms1
   */
  readonly sendEmailSmsPayLinkRequest: SendEmailSmsPayLinkRequest;
}

/**
 * Request parameters for updateCustomerInfo operation in PaylinkControllerApi.
 * @export
 * @interface PaylinkControllerApiUpdateCustomerInfoRequest
 */
export interface PaylinkControllerApiUpdateCustomerInfoRequest {
  /**
   *
   * @type {UpdateCustomerInfoRequest}
   * @memberof PaylinkControllerApiUpdateCustomerInfo
   */
  readonly updateCustomerInfoRequest: UpdateCustomerInfoRequest;
}

/**
 * PaylinkControllerApi - object-oriented interface
 * @export
 * @class PaylinkControllerApi
 * @extends {BaseAPI}
 */
export class PaylinkControllerApi extends BaseAPI {
  /**
   *
   * @summary Tạo mới paylink
   * @param {PaylinkControllerApiCreatePayLinkRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaylinkControllerApi
   */
  public createPayLink(
    requestParameters: PaylinkControllerApiCreatePayLinkRequest,
    options?: AxiosRequestConfig,
  ) {
    return PaylinkControllerApiFp(this.configuration)
      .createPayLink(requestParameters.createPayLinkRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * x-ummepay-client: key sử dụng cho các service nội bộ
   * @summary Tạo mới paylink dành cho http client nội bộ sử dụng api key
   * @param {PaylinkControllerApiCreatePayLinkForInternalRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaylinkControllerApi
   */
  public createPayLinkForInternal(
    requestParameters: PaylinkControllerApiCreatePayLinkForInternalRequest,
    options?: AxiosRequestConfig,
  ) {
    return PaylinkControllerApiFp(this.configuration)
      .createPayLinkForInternal(
        requestParameters.createPayLinkInternalRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - keyword: mã PayLink hoặc mã giao dịch tham chiếu
   * @summary Lấy danh sách paylink
   * @param {PaylinkControllerApiGetListPayLinkRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaylinkControllerApi
   */
  public getListPayLink(
    requestParameters: PaylinkControllerApiGetListPayLinkRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return PaylinkControllerApiFp(this.configuration)
      .getListPayLink(
        requestParameters.keyword,
        requestParameters.phone,
        requestParameters.requestDateFrom,
        requestParameters.requestDateTo,
        requestParameters.status,
        requestParameters.page,
        requestParameters.pageSize,
        requestParameters.sortDirection,
        requestParameters.sortBy,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy thông tin paylink
   * @param {PaylinkControllerApiGetPayLinkDetailRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaylinkControllerApi
   */
  public getPayLinkDetail(
    requestParameters: PaylinkControllerApiGetPayLinkDetailRequest,
    options?: AxiosRequestConfig,
  ) {
    return PaylinkControllerApiFp(this.configuration)
      .getPayLinkDetail(requestParameters.payLinkCode, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xử lý thanh toán PayLink Báo cáo phí
   * @param {PaylinkControllerApiHandleFeePayLinkRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaylinkControllerApi
   */
  public handleFeePayLink(
    requestParameters: PaylinkControllerApiHandleFeePayLinkRequest,
    options?: AxiosRequestConfig,
  ) {
    return PaylinkControllerApiFp(this.configuration)
      .handleFeePayLink(
        requestParameters.xApiClient,
        requestParameters.xApiValidate,
        requestParameters.xApiTime,
        requestParameters.encryptedBodyRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Cập nhật trạng thái giao dịch từ Napas
   * @param {PaylinkControllerApiNotifyTransactionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaylinkControllerApi
   */
  public notifyTransaction(
    requestParameters: PaylinkControllerApiNotifyTransactionRequest,
    options?: AxiosRequestConfig,
  ) {
    return PaylinkControllerApiFp(this.configuration)
      .notifyTransaction(requestParameters.napasResult, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Điều hướng thanh toán Napas
   * @param {PaylinkControllerApiNotifyTransactionRedirectRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaylinkControllerApi
   */
  public notifyTransactionRedirect(
    requestParameters: PaylinkControllerApiNotifyTransactionRedirectRequest,
    options?: AxiosRequestConfig,
  ) {
    return PaylinkControllerApiFp(this.configuration)
      .notifyTransactionRedirect(requestParameters.napasResult, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Thông tin thanh toán theo phương thức thanh toán chuyển khoản
   * @param {PaylinkControllerApiPayBankTransferRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaylinkControllerApi
   */
  public payBankTransfer(
    requestParameters: PaylinkControllerApiPayBankTransferRequest,
    options?: AxiosRequestConfig,
  ) {
    return PaylinkControllerApiFp(this.configuration)
      .payBankTransfer(requestParameters.payLinkCode, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Thông tin thanh toán theo phương thức thanh toán thẻ Napas
   * @param {PaylinkControllerApiPayNapasRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaylinkControllerApi
   */
  public payNapas(
    requestParameters: PaylinkControllerApiPayNapasRequest,
    options?: AxiosRequestConfig,
  ) {
    return PaylinkControllerApiFp(this.configuration)
      .payNapas(requestParameters.payLinkCode, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Thông tin thanh toán theo phương thức thanh toán VietQR
   * @param {PaylinkControllerApiPayVietQRRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaylinkControllerApi
   */
  public payVietQR(
    requestParameters: PaylinkControllerApiPayVietQRRequest,
    options?: AxiosRequestConfig,
  ) {
    return PaylinkControllerApiFp(this.configuration)
      .payVietQR(requestParameters.payLinkCode, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Kiểm tra trạng thái paylink
   * @param {PaylinkControllerApiQueryPayLinkRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaylinkControllerApi
   */
  public queryPayLink(
    requestParameters: PaylinkControllerApiQueryPayLinkRequest,
    options?: AxiosRequestConfig,
  ) {
    return PaylinkControllerApiFp(this.configuration)
      .queryPayLink(requestParameters.payLinkCode, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Gửi link thanh toán qua Email/SMS
   * @param {PaylinkControllerApiSendEmailSms1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaylinkControllerApi
   */
  public sendEmailSms1(
    requestParameters: PaylinkControllerApiSendEmailSms1Request,
    options?: AxiosRequestConfig,
  ) {
    return PaylinkControllerApiFp(this.configuration)
      .sendEmailSms1(requestParameters.sendEmailSmsPayLinkRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Cập nhật thông tin khách hàng thanh toán
   * @param {PaylinkControllerApiUpdateCustomerInfoRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaylinkControllerApi
   */
  public updateCustomerInfo(
    requestParameters: PaylinkControllerApiUpdateCustomerInfoRequest,
    options?: AxiosRequestConfig,
  ) {
    return PaylinkControllerApiFp(this.configuration)
      .updateCustomerInfo(requestParameters.updateCustomerInfoRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PaymentControllerApi - axios parameter creator
 * @export
 */
export const PaymentControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * - Huỷ giao dịch từ phía merchant
     * @summary Huỷ giao dịch
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelTransaction: async (
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xApiClient' is not null or undefined
      assertParamExists("cancelTransaction", "xApiClient", xApiClient);
      // verify required parameter 'xApiValidate' is not null or undefined
      assertParamExists("cancelTransaction", "xApiValidate", xApiValidate);
      // verify required parameter 'xApiTime' is not null or undefined
      assertParamExists("cancelTransaction", "xApiTime", xApiTime);
      // verify required parameter 'encryptedBodyRequest' is not null or undefined
      assertParamExists(
        "cancelTransaction",
        "encryptedBodyRequest",
        encryptedBodyRequest,
      );
      const localVarPath = `/api/payment/v1/cancel`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xApiClient != null) {
        localVarHeaderParameter["x-api-client"] = String(xApiClient);
      }

      if (xApiValidate != null) {
        localVarHeaderParameter["x-api-validate"] = String(xApiValidate);
      }

      if (xApiTime != null) {
        localVarHeaderParameter["x-api-time"] =
          typeof xApiTime === "string" ? xApiTime : JSON.stringify(xApiTime);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        encryptedBodyRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Kiểm tra trạng thái giao dịch, có thể trả về timeout hoặc thành công
     * @summary 2. Kiểm tra trạng thái giao dịch
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkTransaction: async (
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xApiClient' is not null or undefined
      assertParamExists("checkTransaction", "xApiClient", xApiClient);
      // verify required parameter 'xApiValidate' is not null or undefined
      assertParamExists("checkTransaction", "xApiValidate", xApiValidate);
      // verify required parameter 'xApiTime' is not null or undefined
      assertParamExists("checkTransaction", "xApiTime", xApiTime);
      // verify required parameter 'encryptedBodyRequest' is not null or undefined
      assertParamExists(
        "checkTransaction",
        "encryptedBodyRequest",
        encryptedBodyRequest,
      );
      const localVarPath = `/api/payment/v1/check`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xApiClient != null) {
        localVarHeaderParameter["x-api-client"] = String(xApiClient);
      }

      if (xApiValidate != null) {
        localVarHeaderParameter["x-api-validate"] = String(xApiValidate);
      }

      if (xApiTime != null) {
        localVarHeaderParameter["x-api-time"] =
          typeof xApiTime === "string" ? xApiTime : JSON.stringify(xApiTime);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        encryptedBodyRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Tạo một giao dịch mới, trả về QR code
     * @summary 1. Tạo một giao dịch mới
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTransaction: async (
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xApiClient' is not null or undefined
      assertParamExists("createTransaction", "xApiClient", xApiClient);
      // verify required parameter 'xApiValidate' is not null or undefined
      assertParamExists("createTransaction", "xApiValidate", xApiValidate);
      // verify required parameter 'xApiTime' is not null or undefined
      assertParamExists("createTransaction", "xApiTime", xApiTime);
      // verify required parameter 'encryptedBodyRequest' is not null or undefined
      assertParamExists(
        "createTransaction",
        "encryptedBodyRequest",
        encryptedBodyRequest,
      );
      const localVarPath = `/api/payment/v1/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xApiClient != null) {
        localVarHeaderParameter["x-api-client"] = String(xApiClient);
      }

      if (xApiValidate != null) {
        localVarHeaderParameter["x-api-validate"] = String(xApiValidate);
      }

      if (xApiTime != null) {
        localVarHeaderParameter["x-api-time"] =
          typeof xApiTime === "string" ? xApiTime : JSON.stringify(xApiTime);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        encryptedBodyRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Huỷ tài khoản ảo
     * @summary Huỷ tài khoản ảo
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    disableVirtualAccount: async (
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xApiClient' is not null or undefined
      assertParamExists("disableVirtualAccount", "xApiClient", xApiClient);
      // verify required parameter 'xApiValidate' is not null or undefined
      assertParamExists("disableVirtualAccount", "xApiValidate", xApiValidate);
      // verify required parameter 'xApiTime' is not null or undefined
      assertParamExists("disableVirtualAccount", "xApiTime", xApiTime);
      // verify required parameter 'encryptedBodyRequest' is not null or undefined
      assertParamExists(
        "disableVirtualAccount",
        "encryptedBodyRequest",
        encryptedBodyRequest,
      );
      const localVarPath = `/api/payment/v1/virtualAccount/disable`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xApiClient != null) {
        localVarHeaderParameter["x-api-client"] = String(xApiClient);
      }

      if (xApiValidate != null) {
        localVarHeaderParameter["x-api-validate"] = String(xApiValidate);
      }

      if (xApiTime != null) {
        localVarHeaderParameter["x-api-time"] =
          typeof xApiTime === "string" ? xApiTime : JSON.stringify(xApiTime);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        encryptedBodyRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Tạo tài khoản ảo
     * @summary Tạo tài khoản ảo
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    enableVirtualAccount: async (
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xApiClient' is not null or undefined
      assertParamExists("enableVirtualAccount", "xApiClient", xApiClient);
      // verify required parameter 'xApiValidate' is not null or undefined
      assertParamExists("enableVirtualAccount", "xApiValidate", xApiValidate);
      // verify required parameter 'xApiTime' is not null or undefined
      assertParamExists("enableVirtualAccount", "xApiTime", xApiTime);
      // verify required parameter 'encryptedBodyRequest' is not null or undefined
      assertParamExists(
        "enableVirtualAccount",
        "encryptedBodyRequest",
        encryptedBodyRequest,
      );
      const localVarPath = `/api/payment/v1/virtualAccount/enable`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xApiClient != null) {
        localVarHeaderParameter["x-api-client"] = String(xApiClient);
      }

      if (xApiValidate != null) {
        localVarHeaderParameter["x-api-validate"] = String(xApiValidate);
      }

      if (xApiTime != null) {
        localVarHeaderParameter["x-api-time"] =
          typeof xApiTime === "string" ? xApiTime : JSON.stringify(xApiTime);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        encryptedBodyRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Lấy lịch sử giao dịch
     * @summary Lấy lịch sử giao dịch
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransaction1: async (
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xApiClient' is not null or undefined
      assertParamExists("getTransaction1", "xApiClient", xApiClient);
      // verify required parameter 'xApiValidate' is not null or undefined
      assertParamExists("getTransaction1", "xApiValidate", xApiValidate);
      // verify required parameter 'xApiTime' is not null or undefined
      assertParamExists("getTransaction1", "xApiTime", xApiTime);
      // verify required parameter 'encryptedBodyRequest' is not null or undefined
      assertParamExists(
        "getTransaction1",
        "encryptedBodyRequest",
        encryptedBodyRequest,
      );
      const localVarPath = `/api/payment/v1/getTransaction`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xApiClient != null) {
        localVarHeaderParameter["x-api-client"] = String(xApiClient);
      }

      if (xApiValidate != null) {
        localVarHeaderParameter["x-api-validate"] = String(xApiValidate);
      }

      if (xApiTime != null) {
        localVarHeaderParameter["x-api-time"] =
          typeof xApiTime === "string" ? xApiTime : JSON.stringify(xApiTime);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        encryptedBodyRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PaymentControllerApi - functional programming interface
 * @export
 */
export const PaymentControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    PaymentControllerApiAxiosParamCreator(configuration);
  return {
    /**
     * - Huỷ giao dịch từ phía merchant
     * @summary Huỷ giao dịch
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cancelTransaction(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseString>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cancelTransaction(
          xApiClient,
          xApiValidate,
          xApiTime,
          encryptedBodyRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Kiểm tra trạng thái giao dịch, có thể trả về timeout hoặc thành công
     * @summary 2. Kiểm tra trạng thái giao dịch
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkTransaction(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseString>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.checkTransaction(
          xApiClient,
          xApiValidate,
          xApiTime,
          encryptedBodyRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Tạo một giao dịch mới, trả về QR code
     * @summary 1. Tạo một giao dịch mới
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createTransaction(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseString>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createTransaction(
          xApiClient,
          xApiValidate,
          xApiTime,
          encryptedBodyRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Huỷ tài khoản ảo
     * @summary Huỷ tài khoản ảo
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async disableVirtualAccount(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseString>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.disableVirtualAccount(
          xApiClient,
          xApiValidate,
          xApiTime,
          encryptedBodyRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Tạo tài khoản ảo
     * @summary Tạo tài khoản ảo
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async enableVirtualAccount(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseString>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.enableVirtualAccount(
          xApiClient,
          xApiValidate,
          xApiTime,
          encryptedBodyRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Lấy lịch sử giao dịch
     * @summary Lấy lịch sử giao dịch
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTransaction1(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseString>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTransaction1(
        xApiClient,
        xApiValidate,
        xApiTime,
        encryptedBodyRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PaymentControllerApi - factory interface
 * @export
 */
export const PaymentControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PaymentControllerApiFp(configuration);
  return {
    /**
     * - Huỷ giao dịch từ phía merchant
     * @summary Huỷ giao dịch
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelTransaction(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseString> {
      return localVarFp
        .cancelTransaction(
          xApiClient,
          xApiValidate,
          xApiTime,
          encryptedBodyRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * - Kiểm tra trạng thái giao dịch, có thể trả về timeout hoặc thành công
     * @summary 2. Kiểm tra trạng thái giao dịch
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkTransaction(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseString> {
      return localVarFp
        .checkTransaction(
          xApiClient,
          xApiValidate,
          xApiTime,
          encryptedBodyRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * - Tạo một giao dịch mới, trả về QR code
     * @summary 1. Tạo một giao dịch mới
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTransaction(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseString> {
      return localVarFp
        .createTransaction(
          xApiClient,
          xApiValidate,
          xApiTime,
          encryptedBodyRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * - Huỷ tài khoản ảo
     * @summary Huỷ tài khoản ảo
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    disableVirtualAccount(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseString> {
      return localVarFp
        .disableVirtualAccount(
          xApiClient,
          xApiValidate,
          xApiTime,
          encryptedBodyRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * - Tạo tài khoản ảo
     * @summary Tạo tài khoản ảo
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    enableVirtualAccount(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseString> {
      return localVarFp
        .enableVirtualAccount(
          xApiClient,
          xApiValidate,
          xApiTime,
          encryptedBodyRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * - Lấy lịch sử giao dịch
     * @summary Lấy lịch sử giao dịch
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransaction1(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseString> {
      return localVarFp
        .getTransaction1(
          xApiClient,
          xApiValidate,
          xApiTime,
          encryptedBodyRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for cancelTransaction operation in PaymentControllerApi.
 * @export
 * @interface PaymentControllerApiCancelTransactionRequest
 */
export interface PaymentControllerApiCancelTransactionRequest {
  /**
   *
   * @type {string}
   * @memberof PaymentControllerApiCancelTransaction
   */
  readonly xApiClient: string;

  /**
   *
   * @type {string}
   * @memberof PaymentControllerApiCancelTransaction
   */
  readonly xApiValidate: string;

  /**
   *
   * @type {number}
   * @memberof PaymentControllerApiCancelTransaction
   */
  readonly xApiTime: number;

  /**
   *
   * @type {EncryptedBodyRequest}
   * @memberof PaymentControllerApiCancelTransaction
   */
  readonly encryptedBodyRequest: EncryptedBodyRequest;
}

/**
 * Request parameters for checkTransaction operation in PaymentControllerApi.
 * @export
 * @interface PaymentControllerApiCheckTransactionRequest
 */
export interface PaymentControllerApiCheckTransactionRequest {
  /**
   *
   * @type {string}
   * @memberof PaymentControllerApiCheckTransaction
   */
  readonly xApiClient: string;

  /**
   *
   * @type {string}
   * @memberof PaymentControllerApiCheckTransaction
   */
  readonly xApiValidate: string;

  /**
   *
   * @type {number}
   * @memberof PaymentControllerApiCheckTransaction
   */
  readonly xApiTime: number;

  /**
   *
   * @type {EncryptedBodyRequest}
   * @memberof PaymentControllerApiCheckTransaction
   */
  readonly encryptedBodyRequest: EncryptedBodyRequest;
}

/**
 * Request parameters for createTransaction operation in PaymentControllerApi.
 * @export
 * @interface PaymentControllerApiCreateTransactionRequest
 */
export interface PaymentControllerApiCreateTransactionRequest {
  /**
   *
   * @type {string}
   * @memberof PaymentControllerApiCreateTransaction
   */
  readonly xApiClient: string;

  /**
   *
   * @type {string}
   * @memberof PaymentControllerApiCreateTransaction
   */
  readonly xApiValidate: string;

  /**
   *
   * @type {number}
   * @memberof PaymentControllerApiCreateTransaction
   */
  readonly xApiTime: number;

  /**
   *
   * @type {EncryptedBodyRequest}
   * @memberof PaymentControllerApiCreateTransaction
   */
  readonly encryptedBodyRequest: EncryptedBodyRequest;
}

/**
 * Request parameters for disableVirtualAccount operation in PaymentControllerApi.
 * @export
 * @interface PaymentControllerApiDisableVirtualAccountRequest
 */
export interface PaymentControllerApiDisableVirtualAccountRequest {
  /**
   *
   * @type {string}
   * @memberof PaymentControllerApiDisableVirtualAccount
   */
  readonly xApiClient: string;

  /**
   *
   * @type {string}
   * @memberof PaymentControllerApiDisableVirtualAccount
   */
  readonly xApiValidate: string;

  /**
   *
   * @type {number}
   * @memberof PaymentControllerApiDisableVirtualAccount
   */
  readonly xApiTime: number;

  /**
   *
   * @type {EncryptedBodyRequest}
   * @memberof PaymentControllerApiDisableVirtualAccount
   */
  readonly encryptedBodyRequest: EncryptedBodyRequest;
}

/**
 * Request parameters for enableVirtualAccount operation in PaymentControllerApi.
 * @export
 * @interface PaymentControllerApiEnableVirtualAccountRequest
 */
export interface PaymentControllerApiEnableVirtualAccountRequest {
  /**
   *
   * @type {string}
   * @memberof PaymentControllerApiEnableVirtualAccount
   */
  readonly xApiClient: string;

  /**
   *
   * @type {string}
   * @memberof PaymentControllerApiEnableVirtualAccount
   */
  readonly xApiValidate: string;

  /**
   *
   * @type {number}
   * @memberof PaymentControllerApiEnableVirtualAccount
   */
  readonly xApiTime: number;

  /**
   *
   * @type {EncryptedBodyRequest}
   * @memberof PaymentControllerApiEnableVirtualAccount
   */
  readonly encryptedBodyRequest: EncryptedBodyRequest;
}

/**
 * Request parameters for getTransaction1 operation in PaymentControllerApi.
 * @export
 * @interface PaymentControllerApiGetTransaction1Request
 */
export interface PaymentControllerApiGetTransaction1Request {
  /**
   *
   * @type {string}
   * @memberof PaymentControllerApiGetTransaction1
   */
  readonly xApiClient: string;

  /**
   *
   * @type {string}
   * @memberof PaymentControllerApiGetTransaction1
   */
  readonly xApiValidate: string;

  /**
   *
   * @type {number}
   * @memberof PaymentControllerApiGetTransaction1
   */
  readonly xApiTime: number;

  /**
   *
   * @type {EncryptedBodyRequest}
   * @memberof PaymentControllerApiGetTransaction1
   */
  readonly encryptedBodyRequest: EncryptedBodyRequest;
}

/**
 * PaymentControllerApi - object-oriented interface
 * @export
 * @class PaymentControllerApi
 * @extends {BaseAPI}
 */
export class PaymentControllerApi extends BaseAPI {
  /**
   * - Huỷ giao dịch từ phía merchant
   * @summary Huỷ giao dịch
   * @param {PaymentControllerApiCancelTransactionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentControllerApi
   */
  public cancelTransaction(
    requestParameters: PaymentControllerApiCancelTransactionRequest,
    options?: AxiosRequestConfig,
  ) {
    return PaymentControllerApiFp(this.configuration)
      .cancelTransaction(
        requestParameters.xApiClient,
        requestParameters.xApiValidate,
        requestParameters.xApiTime,
        requestParameters.encryptedBodyRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Kiểm tra trạng thái giao dịch, có thể trả về timeout hoặc thành công
   * @summary 2. Kiểm tra trạng thái giao dịch
   * @param {PaymentControllerApiCheckTransactionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentControllerApi
   */
  public checkTransaction(
    requestParameters: PaymentControllerApiCheckTransactionRequest,
    options?: AxiosRequestConfig,
  ) {
    return PaymentControllerApiFp(this.configuration)
      .checkTransaction(
        requestParameters.xApiClient,
        requestParameters.xApiValidate,
        requestParameters.xApiTime,
        requestParameters.encryptedBodyRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Tạo một giao dịch mới, trả về QR code
   * @summary 1. Tạo một giao dịch mới
   * @param {PaymentControllerApiCreateTransactionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentControllerApi
   */
  public createTransaction(
    requestParameters: PaymentControllerApiCreateTransactionRequest,
    options?: AxiosRequestConfig,
  ) {
    return PaymentControllerApiFp(this.configuration)
      .createTransaction(
        requestParameters.xApiClient,
        requestParameters.xApiValidate,
        requestParameters.xApiTime,
        requestParameters.encryptedBodyRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Huỷ tài khoản ảo
   * @summary Huỷ tài khoản ảo
   * @param {PaymentControllerApiDisableVirtualAccountRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentControllerApi
   */
  public disableVirtualAccount(
    requestParameters: PaymentControllerApiDisableVirtualAccountRequest,
    options?: AxiosRequestConfig,
  ) {
    return PaymentControllerApiFp(this.configuration)
      .disableVirtualAccount(
        requestParameters.xApiClient,
        requestParameters.xApiValidate,
        requestParameters.xApiTime,
        requestParameters.encryptedBodyRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Tạo tài khoản ảo
   * @summary Tạo tài khoản ảo
   * @param {PaymentControllerApiEnableVirtualAccountRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentControllerApi
   */
  public enableVirtualAccount(
    requestParameters: PaymentControllerApiEnableVirtualAccountRequest,
    options?: AxiosRequestConfig,
  ) {
    return PaymentControllerApiFp(this.configuration)
      .enableVirtualAccount(
        requestParameters.xApiClient,
        requestParameters.xApiValidate,
        requestParameters.xApiTime,
        requestParameters.encryptedBodyRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Lấy lịch sử giao dịch
   * @summary Lấy lịch sử giao dịch
   * @param {PaymentControllerApiGetTransaction1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentControllerApi
   */
  public getTransaction1(
    requestParameters: PaymentControllerApiGetTransaction1Request,
    options?: AxiosRequestConfig,
  ) {
    return PaymentControllerApiFp(this.configuration)
      .getTransaction1(
        requestParameters.xApiClient,
        requestParameters.xApiValidate,
        requestParameters.xApiTime,
        requestParameters.encryptedBodyRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PaymentMethodControllerApi - axios parameter creator
 * @export
 */
export const PaymentMethodControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Thêm mới phương thức giao dịch
     * @param {CreatePaymentMethodRequest} createPaymentMethodRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPaymentMethod: async (
      createPaymentMethodRequest: CreatePaymentMethodRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createPaymentMethodRequest' is not null or undefined
      assertParamExists(
        "createPaymentMethod",
        "createPaymentMethodRequest",
        createPaymentMethodRequest,
      );
      const localVarPath = `/api/payment-method/cms/v1/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createPaymentMethodRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy danh sách phương thức thanh toán
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListPaymentMethod: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/payment-method/public/v1/getList`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Cập nhật phương thức giao dịch
     * @param {UpdatePaymentMethodRequest} updatePaymentMethodRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePaymentMethod: async (
      updatePaymentMethodRequest: UpdatePaymentMethodRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updatePaymentMethodRequest' is not null or undefined
      assertParamExists(
        "updatePaymentMethod",
        "updatePaymentMethodRequest",
        updatePaymentMethodRequest,
      );
      const localVarPath = `/api/payment-method/cms/v1/update`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updatePaymentMethodRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PaymentMethodControllerApi - functional programming interface
 * @export
 */
export const PaymentMethodControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    PaymentMethodControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Thêm mới phương thức giao dịch
     * @param {CreatePaymentMethodRequest} createPaymentMethodRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createPaymentMethod(
      createPaymentMethodRequest: CreatePaymentMethodRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePaymentMethodInfo>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createPaymentMethod(
          createPaymentMethodRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy danh sách phương thức thanh toán
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getListPaymentMethod(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetListPaymentMethodResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getListPaymentMethod(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Cập nhật phương thức giao dịch
     * @param {UpdatePaymentMethodRequest} updatePaymentMethodRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updatePaymentMethod(
      updatePaymentMethodRequest: UpdatePaymentMethodRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePaymentMethodInfo>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updatePaymentMethod(
          updatePaymentMethodRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PaymentMethodControllerApi - factory interface
 * @export
 */
export const PaymentMethodControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PaymentMethodControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Thêm mới phương thức giao dịch
     * @param {CreatePaymentMethodRequest} createPaymentMethodRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPaymentMethod(
      createPaymentMethodRequest: CreatePaymentMethodRequest,
      options?: any,
    ): AxiosPromise<ResponseBasePaymentMethodInfo> {
      return localVarFp
        .createPaymentMethod(createPaymentMethodRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy danh sách phương thức thanh toán
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListPaymentMethod(
      options?: any,
    ): AxiosPromise<ResponseBaseGetListPaymentMethodResponse> {
      return localVarFp
        .getListPaymentMethod(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Cập nhật phương thức giao dịch
     * @param {UpdatePaymentMethodRequest} updatePaymentMethodRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePaymentMethod(
      updatePaymentMethodRequest: UpdatePaymentMethodRequest,
      options?: any,
    ): AxiosPromise<ResponseBasePaymentMethodInfo> {
      return localVarFp
        .updatePaymentMethod(updatePaymentMethodRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createPaymentMethod operation in PaymentMethodControllerApi.
 * @export
 * @interface PaymentMethodControllerApiCreatePaymentMethodRequest
 */
export interface PaymentMethodControllerApiCreatePaymentMethodRequest {
  /**
   *
   * @type {CreatePaymentMethodRequest}
   * @memberof PaymentMethodControllerApiCreatePaymentMethod
   */
  readonly createPaymentMethodRequest: CreatePaymentMethodRequest;
}

/**
 * Request parameters for updatePaymentMethod operation in PaymentMethodControllerApi.
 * @export
 * @interface PaymentMethodControllerApiUpdatePaymentMethodRequest
 */
export interface PaymentMethodControllerApiUpdatePaymentMethodRequest {
  /**
   *
   * @type {UpdatePaymentMethodRequest}
   * @memberof PaymentMethodControllerApiUpdatePaymentMethod
   */
  readonly updatePaymentMethodRequest: UpdatePaymentMethodRequest;
}

/**
 * PaymentMethodControllerApi - object-oriented interface
 * @export
 * @class PaymentMethodControllerApi
 * @extends {BaseAPI}
 */
export class PaymentMethodControllerApi extends BaseAPI {
  /**
   *
   * @summary Thêm mới phương thức giao dịch
   * @param {PaymentMethodControllerApiCreatePaymentMethodRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentMethodControllerApi
   */
  public createPaymentMethod(
    requestParameters: PaymentMethodControllerApiCreatePaymentMethodRequest,
    options?: AxiosRequestConfig,
  ) {
    return PaymentMethodControllerApiFp(this.configuration)
      .createPaymentMethod(
        requestParameters.createPaymentMethodRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy danh sách phương thức thanh toán
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentMethodControllerApi
   */
  public getListPaymentMethod(options?: AxiosRequestConfig) {
    return PaymentMethodControllerApiFp(this.configuration)
      .getListPaymentMethod(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Cập nhật phương thức giao dịch
   * @param {PaymentMethodControllerApiUpdatePaymentMethodRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentMethodControllerApi
   */
  public updatePaymentMethod(
    requestParameters: PaymentMethodControllerApiUpdatePaymentMethodRequest,
    options?: AxiosRequestConfig,
  ) {
    return PaymentMethodControllerApiFp(this.configuration)
      .updatePaymentMethod(
        requestParameters.updatePaymentMethodRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PaymentSimpleControllerApi - axios parameter creator
 * @export
 */
export const PaymentSimpleControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * - Huỷ giao dịch từ phía merchant
     * @summary Huỷ giao dịch, bypass mã hoá
     * @param {string} xApiClient
     * @param {string} xApiKey
     * @param {CancelTransactionRequest} cancelTransactionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelTransactionSimple: async (
      xApiClient: string,
      xApiKey: string,
      cancelTransactionRequest: CancelTransactionRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xApiClient' is not null or undefined
      assertParamExists("cancelTransactionSimple", "xApiClient", xApiClient);
      // verify required parameter 'xApiKey' is not null or undefined
      assertParamExists("cancelTransactionSimple", "xApiKey", xApiKey);
      // verify required parameter 'cancelTransactionRequest' is not null or undefined
      assertParamExists(
        "cancelTransactionSimple",
        "cancelTransactionRequest",
        cancelTransactionRequest,
      );
      const localVarPath = `/api/payment/v1/simple/cancel`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xApiClient != null) {
        localVarHeaderParameter["x-api-client"] = String(xApiClient);
      }

      if (xApiKey != null) {
        localVarHeaderParameter["x-api-key"] = String(xApiKey);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        cancelTransactionRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Kiểm tra trạng thái giao dịch, có thể trả về timeout hoặc thành công
     * @summary 2. Kiểm tra trạng thái giao dịch, bypass mã hoá
     * @param {string} xApiClient
     * @param {string} xApiKey
     * @param {QueryTransactionRequest} queryTransactionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkTransactionSimple: async (
      xApiClient: string,
      xApiKey: string,
      queryTransactionRequest: QueryTransactionRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xApiClient' is not null or undefined
      assertParamExists("checkTransactionSimple", "xApiClient", xApiClient);
      // verify required parameter 'xApiKey' is not null or undefined
      assertParamExists("checkTransactionSimple", "xApiKey", xApiKey);
      // verify required parameter 'queryTransactionRequest' is not null or undefined
      assertParamExists(
        "checkTransactionSimple",
        "queryTransactionRequest",
        queryTransactionRequest,
      );
      const localVarPath = `/api/payment/v1/simple/query`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xApiClient != null) {
        localVarHeaderParameter["x-api-client"] = String(xApiClient);
      }

      if (xApiKey != null) {
        localVarHeaderParameter["x-api-key"] = String(xApiKey);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        queryTransactionRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Tạo một giao dịch mới, trả về QR code
     * @summary 1. Tạo một giao dịch mới, bypass mã hoá
     * @param {string} xApiClient
     * @param {string} xApiKey
     * @param {CreateTransactionRequest} createTransactionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTransactionSimple: async (
      xApiClient: string,
      xApiKey: string,
      createTransactionRequest: CreateTransactionRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xApiClient' is not null or undefined
      assertParamExists("createTransactionSimple", "xApiClient", xApiClient);
      // verify required parameter 'xApiKey' is not null or undefined
      assertParamExists("createTransactionSimple", "xApiKey", xApiKey);
      // verify required parameter 'createTransactionRequest' is not null or undefined
      assertParamExists(
        "createTransactionSimple",
        "createTransactionRequest",
        createTransactionRequest,
      );
      const localVarPath = `/api/payment/v1/simple/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xApiClient != null) {
        localVarHeaderParameter["x-api-client"] = String(xApiClient);
      }

      if (xApiKey != null) {
        localVarHeaderParameter["x-api-key"] = String(xApiKey);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createTransactionRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Huỷ tài khoản ảo
     * @summary Huỷ tài khoản ảo, bypass mã hoá
     * @param {string} xApiClient
     * @param {string} xApiKey
     * @param {DisableVirtualAccountRequest} disableVirtualAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    disableVirtualAccount1: async (
      xApiClient: string,
      xApiKey: string,
      disableVirtualAccountRequest: DisableVirtualAccountRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xApiClient' is not null or undefined
      assertParamExists("disableVirtualAccount1", "xApiClient", xApiClient);
      // verify required parameter 'xApiKey' is not null or undefined
      assertParamExists("disableVirtualAccount1", "xApiKey", xApiKey);
      // verify required parameter 'disableVirtualAccountRequest' is not null or undefined
      assertParamExists(
        "disableVirtualAccount1",
        "disableVirtualAccountRequest",
        disableVirtualAccountRequest,
      );
      const localVarPath = `/api/payment/v1/simple/virtualAccount/disable`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xApiClient != null) {
        localVarHeaderParameter["x-api-client"] = String(xApiClient);
      }

      if (xApiKey != null) {
        localVarHeaderParameter["x-api-key"] = String(xApiKey);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        disableVirtualAccountRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Tạo tài khoản ảo
     * @summary Tạo tài khoản ảo, bypass mã hoá
     * @param {string} xApiClient
     * @param {string} xApiKey
     * @param {EnableVirtualAccountRequest} enableVirtualAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    enableVirtualAccount1: async (
      xApiClient: string,
      xApiKey: string,
      enableVirtualAccountRequest: EnableVirtualAccountRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xApiClient' is not null or undefined
      assertParamExists("enableVirtualAccount1", "xApiClient", xApiClient);
      // verify required parameter 'xApiKey' is not null or undefined
      assertParamExists("enableVirtualAccount1", "xApiKey", xApiKey);
      // verify required parameter 'enableVirtualAccountRequest' is not null or undefined
      assertParamExists(
        "enableVirtualAccount1",
        "enableVirtualAccountRequest",
        enableVirtualAccountRequest,
      );
      const localVarPath = `/api/payment/v1/simple/virtualAccount/enable`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xApiClient != null) {
        localVarHeaderParameter["x-api-client"] = String(xApiClient);
      }

      if (xApiKey != null) {
        localVarHeaderParameter["x-api-key"] = String(xApiKey);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        enableVirtualAccountRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Lấy lịch sử giao dịch
     * @summary Lấy lịch sử giao dịch
     * @param {string} xApiClient
     * @param {string} xApiKey
     * @param {GetTransactionRequest} getTransactionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransaction: async (
      xApiClient: string,
      xApiKey: string,
      getTransactionRequest: GetTransactionRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xApiClient' is not null or undefined
      assertParamExists("getTransaction", "xApiClient", xApiClient);
      // verify required parameter 'xApiKey' is not null or undefined
      assertParamExists("getTransaction", "xApiKey", xApiKey);
      // verify required parameter 'getTransactionRequest' is not null or undefined
      assertParamExists(
        "getTransaction",
        "getTransactionRequest",
        getTransactionRequest,
      );
      const localVarPath = `/api/payment/v1/simple/getTransaction`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xApiClient != null) {
        localVarHeaderParameter["x-api-client"] = String(xApiClient);
      }

      if (xApiKey != null) {
        localVarHeaderParameter["x-api-key"] = String(xApiKey);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        getTransactionRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PaymentSimpleControllerApi - functional programming interface
 * @export
 */
export const PaymentSimpleControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    PaymentSimpleControllerApiAxiosParamCreator(configuration);
  return {
    /**
     * - Huỷ giao dịch từ phía merchant
     * @summary Huỷ giao dịch, bypass mã hoá
     * @param {string} xApiClient
     * @param {string} xApiKey
     * @param {CancelTransactionRequest} cancelTransactionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cancelTransactionSimple(
      xApiClient: string,
      xApiKey: string,
      cancelTransactionRequest: CancelTransactionRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseCancelTransactionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cancelTransactionSimple(
          xApiClient,
          xApiKey,
          cancelTransactionRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Kiểm tra trạng thái giao dịch, có thể trả về timeout hoặc thành công
     * @summary 2. Kiểm tra trạng thái giao dịch, bypass mã hoá
     * @param {string} xApiClient
     * @param {string} xApiKey
     * @param {QueryTransactionRequest} queryTransactionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkTransactionSimple(
      xApiClient: string,
      xApiKey: string,
      queryTransactionRequest: QueryTransactionRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseQueryTransactionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.checkTransactionSimple(
          xApiClient,
          xApiKey,
          queryTransactionRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Tạo một giao dịch mới, trả về QR code
     * @summary 1. Tạo một giao dịch mới, bypass mã hoá
     * @param {string} xApiClient
     * @param {string} xApiKey
     * @param {CreateTransactionRequest} createTransactionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createTransactionSimple(
      xApiClient: string,
      xApiKey: string,
      createTransactionRequest: CreateTransactionRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseCreateTransactionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createTransactionSimple(
          xApiClient,
          xApiKey,
          createTransactionRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Huỷ tài khoản ảo
     * @summary Huỷ tài khoản ảo, bypass mã hoá
     * @param {string} xApiClient
     * @param {string} xApiKey
     * @param {DisableVirtualAccountRequest} disableVirtualAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async disableVirtualAccount1(
      xApiClient: string,
      xApiKey: string,
      disableVirtualAccountRequest: DisableVirtualAccountRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseCancelTransactionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.disableVirtualAccount1(
          xApiClient,
          xApiKey,
          disableVirtualAccountRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Tạo tài khoản ảo
     * @summary Tạo tài khoản ảo, bypass mã hoá
     * @param {string} xApiClient
     * @param {string} xApiKey
     * @param {EnableVirtualAccountRequest} enableVirtualAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async enableVirtualAccount1(
      xApiClient: string,
      xApiKey: string,
      enableVirtualAccountRequest: EnableVirtualAccountRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseVirtualAccountResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.enableVirtualAccount1(
          xApiClient,
          xApiKey,
          enableVirtualAccountRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Lấy lịch sử giao dịch
     * @summary Lấy lịch sử giao dịch
     * @param {string} xApiClient
     * @param {string} xApiKey
     * @param {GetTransactionRequest} getTransactionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTransaction(
      xApiClient: string,
      xApiKey: string,
      getTransactionRequest: GetTransactionRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponsePaymentTransactionResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTransaction(
        xApiClient,
        xApiKey,
        getTransactionRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PaymentSimpleControllerApi - factory interface
 * @export
 */
export const PaymentSimpleControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PaymentSimpleControllerApiFp(configuration);
  return {
    /**
     * - Huỷ giao dịch từ phía merchant
     * @summary Huỷ giao dịch, bypass mã hoá
     * @param {string} xApiClient
     * @param {string} xApiKey
     * @param {CancelTransactionRequest} cancelTransactionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelTransactionSimple(
      xApiClient: string,
      xApiKey: string,
      cancelTransactionRequest: CancelTransactionRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseCancelTransactionResponse> {
      return localVarFp
        .cancelTransactionSimple(
          xApiClient,
          xApiKey,
          cancelTransactionRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * - Kiểm tra trạng thái giao dịch, có thể trả về timeout hoặc thành công
     * @summary 2. Kiểm tra trạng thái giao dịch, bypass mã hoá
     * @param {string} xApiClient
     * @param {string} xApiKey
     * @param {QueryTransactionRequest} queryTransactionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkTransactionSimple(
      xApiClient: string,
      xApiKey: string,
      queryTransactionRequest: QueryTransactionRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseQueryTransactionResponse> {
      return localVarFp
        .checkTransactionSimple(
          xApiClient,
          xApiKey,
          queryTransactionRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * - Tạo một giao dịch mới, trả về QR code
     * @summary 1. Tạo một giao dịch mới, bypass mã hoá
     * @param {string} xApiClient
     * @param {string} xApiKey
     * @param {CreateTransactionRequest} createTransactionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTransactionSimple(
      xApiClient: string,
      xApiKey: string,
      createTransactionRequest: CreateTransactionRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseCreateTransactionResponse> {
      return localVarFp
        .createTransactionSimple(
          xApiClient,
          xApiKey,
          createTransactionRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * - Huỷ tài khoản ảo
     * @summary Huỷ tài khoản ảo, bypass mã hoá
     * @param {string} xApiClient
     * @param {string} xApiKey
     * @param {DisableVirtualAccountRequest} disableVirtualAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    disableVirtualAccount1(
      xApiClient: string,
      xApiKey: string,
      disableVirtualAccountRequest: DisableVirtualAccountRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseCancelTransactionResponse> {
      return localVarFp
        .disableVirtualAccount1(
          xApiClient,
          xApiKey,
          disableVirtualAccountRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * - Tạo tài khoản ảo
     * @summary Tạo tài khoản ảo, bypass mã hoá
     * @param {string} xApiClient
     * @param {string} xApiKey
     * @param {EnableVirtualAccountRequest} enableVirtualAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    enableVirtualAccount1(
      xApiClient: string,
      xApiKey: string,
      enableVirtualAccountRequest: EnableVirtualAccountRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseVirtualAccountResponse> {
      return localVarFp
        .enableVirtualAccount1(
          xApiClient,
          xApiKey,
          enableVirtualAccountRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * - Lấy lịch sử giao dịch
     * @summary Lấy lịch sử giao dịch
     * @param {string} xApiClient
     * @param {string} xApiKey
     * @param {GetTransactionRequest} getTransactionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransaction(
      xApiClient: string,
      xApiKey: string,
      getTransactionRequest: GetTransactionRequest,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponsePaymentTransactionResponse> {
      return localVarFp
        .getTransaction(xApiClient, xApiKey, getTransactionRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for cancelTransactionSimple operation in PaymentSimpleControllerApi.
 * @export
 * @interface PaymentSimpleControllerApiCancelTransactionSimpleRequest
 */
export interface PaymentSimpleControllerApiCancelTransactionSimpleRequest {
  /**
   *
   * @type {string}
   * @memberof PaymentSimpleControllerApiCancelTransactionSimple
   */
  readonly xApiClient: string;

  /**
   *
   * @type {string}
   * @memberof PaymentSimpleControllerApiCancelTransactionSimple
   */
  readonly xApiKey: string;

  /**
   *
   * @type {CancelTransactionRequest}
   * @memberof PaymentSimpleControllerApiCancelTransactionSimple
   */
  readonly cancelTransactionRequest: CancelTransactionRequest;
}

/**
 * Request parameters for checkTransactionSimple operation in PaymentSimpleControllerApi.
 * @export
 * @interface PaymentSimpleControllerApiCheckTransactionSimpleRequest
 */
export interface PaymentSimpleControllerApiCheckTransactionSimpleRequest {
  /**
   *
   * @type {string}
   * @memberof PaymentSimpleControllerApiCheckTransactionSimple
   */
  readonly xApiClient: string;

  /**
   *
   * @type {string}
   * @memberof PaymentSimpleControllerApiCheckTransactionSimple
   */
  readonly xApiKey: string;

  /**
   *
   * @type {QueryTransactionRequest}
   * @memberof PaymentSimpleControllerApiCheckTransactionSimple
   */
  readonly queryTransactionRequest: QueryTransactionRequest;
}

/**
 * Request parameters for createTransactionSimple operation in PaymentSimpleControllerApi.
 * @export
 * @interface PaymentSimpleControllerApiCreateTransactionSimpleRequest
 */
export interface PaymentSimpleControllerApiCreateTransactionSimpleRequest {
  /**
   *
   * @type {string}
   * @memberof PaymentSimpleControllerApiCreateTransactionSimple
   */
  readonly xApiClient: string;

  /**
   *
   * @type {string}
   * @memberof PaymentSimpleControllerApiCreateTransactionSimple
   */
  readonly xApiKey: string;

  /**
   *
   * @type {CreateTransactionRequest}
   * @memberof PaymentSimpleControllerApiCreateTransactionSimple
   */
  readonly createTransactionRequest: CreateTransactionRequest;
}

/**
 * Request parameters for disableVirtualAccount1 operation in PaymentSimpleControllerApi.
 * @export
 * @interface PaymentSimpleControllerApiDisableVirtualAccount1Request
 */
export interface PaymentSimpleControllerApiDisableVirtualAccount1Request {
  /**
   *
   * @type {string}
   * @memberof PaymentSimpleControllerApiDisableVirtualAccount1
   */
  readonly xApiClient: string;

  /**
   *
   * @type {string}
   * @memberof PaymentSimpleControllerApiDisableVirtualAccount1
   */
  readonly xApiKey: string;

  /**
   *
   * @type {DisableVirtualAccountRequest}
   * @memberof PaymentSimpleControllerApiDisableVirtualAccount1
   */
  readonly disableVirtualAccountRequest: DisableVirtualAccountRequest;
}

/**
 * Request parameters for enableVirtualAccount1 operation in PaymentSimpleControllerApi.
 * @export
 * @interface PaymentSimpleControllerApiEnableVirtualAccount1Request
 */
export interface PaymentSimpleControllerApiEnableVirtualAccount1Request {
  /**
   *
   * @type {string}
   * @memberof PaymentSimpleControllerApiEnableVirtualAccount1
   */
  readonly xApiClient: string;

  /**
   *
   * @type {string}
   * @memberof PaymentSimpleControllerApiEnableVirtualAccount1
   */
  readonly xApiKey: string;

  /**
   *
   * @type {EnableVirtualAccountRequest}
   * @memberof PaymentSimpleControllerApiEnableVirtualAccount1
   */
  readonly enableVirtualAccountRequest: EnableVirtualAccountRequest;
}

/**
 * Request parameters for getTransaction operation in PaymentSimpleControllerApi.
 * @export
 * @interface PaymentSimpleControllerApiGetTransactionRequest
 */
export interface PaymentSimpleControllerApiGetTransactionRequest {
  /**
   *
   * @type {string}
   * @memberof PaymentSimpleControllerApiGetTransaction
   */
  readonly xApiClient: string;

  /**
   *
   * @type {string}
   * @memberof PaymentSimpleControllerApiGetTransaction
   */
  readonly xApiKey: string;

  /**
   *
   * @type {GetTransactionRequest}
   * @memberof PaymentSimpleControllerApiGetTransaction
   */
  readonly getTransactionRequest: GetTransactionRequest;
}

/**
 * PaymentSimpleControllerApi - object-oriented interface
 * @export
 * @class PaymentSimpleControllerApi
 * @extends {BaseAPI}
 */
export class PaymentSimpleControllerApi extends BaseAPI {
  /**
   * - Huỷ giao dịch từ phía merchant
   * @summary Huỷ giao dịch, bypass mã hoá
   * @param {PaymentSimpleControllerApiCancelTransactionSimpleRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentSimpleControllerApi
   */
  public cancelTransactionSimple(
    requestParameters: PaymentSimpleControllerApiCancelTransactionSimpleRequest,
    options?: AxiosRequestConfig,
  ) {
    return PaymentSimpleControllerApiFp(this.configuration)
      .cancelTransactionSimple(
        requestParameters.xApiClient,
        requestParameters.xApiKey,
        requestParameters.cancelTransactionRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Kiểm tra trạng thái giao dịch, có thể trả về timeout hoặc thành công
   * @summary 2. Kiểm tra trạng thái giao dịch, bypass mã hoá
   * @param {PaymentSimpleControllerApiCheckTransactionSimpleRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentSimpleControllerApi
   */
  public checkTransactionSimple(
    requestParameters: PaymentSimpleControllerApiCheckTransactionSimpleRequest,
    options?: AxiosRequestConfig,
  ) {
    return PaymentSimpleControllerApiFp(this.configuration)
      .checkTransactionSimple(
        requestParameters.xApiClient,
        requestParameters.xApiKey,
        requestParameters.queryTransactionRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Tạo một giao dịch mới, trả về QR code
   * @summary 1. Tạo một giao dịch mới, bypass mã hoá
   * @param {PaymentSimpleControllerApiCreateTransactionSimpleRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentSimpleControllerApi
   */
  public createTransactionSimple(
    requestParameters: PaymentSimpleControllerApiCreateTransactionSimpleRequest,
    options?: AxiosRequestConfig,
  ) {
    return PaymentSimpleControllerApiFp(this.configuration)
      .createTransactionSimple(
        requestParameters.xApiClient,
        requestParameters.xApiKey,
        requestParameters.createTransactionRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Huỷ tài khoản ảo
   * @summary Huỷ tài khoản ảo, bypass mã hoá
   * @param {PaymentSimpleControllerApiDisableVirtualAccount1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentSimpleControllerApi
   */
  public disableVirtualAccount1(
    requestParameters: PaymentSimpleControllerApiDisableVirtualAccount1Request,
    options?: AxiosRequestConfig,
  ) {
    return PaymentSimpleControllerApiFp(this.configuration)
      .disableVirtualAccount1(
        requestParameters.xApiClient,
        requestParameters.xApiKey,
        requestParameters.disableVirtualAccountRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Tạo tài khoản ảo
   * @summary Tạo tài khoản ảo, bypass mã hoá
   * @param {PaymentSimpleControllerApiEnableVirtualAccount1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentSimpleControllerApi
   */
  public enableVirtualAccount1(
    requestParameters: PaymentSimpleControllerApiEnableVirtualAccount1Request,
    options?: AxiosRequestConfig,
  ) {
    return PaymentSimpleControllerApiFp(this.configuration)
      .enableVirtualAccount1(
        requestParameters.xApiClient,
        requestParameters.xApiKey,
        requestParameters.enableVirtualAccountRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Lấy lịch sử giao dịch
   * @summary Lấy lịch sử giao dịch
   * @param {PaymentSimpleControllerApiGetTransactionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentSimpleControllerApi
   */
  public getTransaction(
    requestParameters: PaymentSimpleControllerApiGetTransactionRequest,
    options?: AxiosRequestConfig,
  ) {
    return PaymentSimpleControllerApiFp(this.configuration)
      .getTransaction(
        requestParameters.xApiClient,
        requestParameters.xApiKey,
        requestParameters.getTransactionRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PortalDashboardControllerApi - axios parameter creator
 * @export
 */
export const PortalDashboardControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Lấy tổng số giao dịch và tổng tiền ngày hôm nay trên hệ thống UMEEPAY
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalTodayTransactions: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/dashboard/portal/v1/transaction/total/today`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy tổng số giao dịch và tổng tiền ngày hôm nay từ BANK
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalTodayTransactionsFromBank: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/dashboard/portal/v1/bankTransaction/total/today`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy tổng số giao dịch và tổng tiền trên hệ thống UMEEPAY
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalTransaction: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/dashboard/portal/v1/transaction/total`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy tổng số giao dịch và tổng tiền từ BANK
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalTransactionFromBank: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/dashboard/portal/v1/bankTransaction/total`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Thống kê biểu đồ giao dịch Trên hệ thống UmeePay
     * @param {string} from
     * @param {string} to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransactionStatistic: async (
      from: string,
      to: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'from' is not null or undefined
      assertParamExists("getTransactionStatistic", "from", from);
      // verify required parameter 'to' is not null or undefined
      assertParamExists("getTransactionStatistic", "to", to);
      const localVarPath = `/api/dashboard/portal/v1/transaction/statistic`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (from !== undefined) {
        localVarQueryParameter["from"] =
          (from as any) instanceof Date
            ? (from as any).toISOString().substr(0, 10)
            : from;
      }

      if (to !== undefined) {
        localVarQueryParameter["to"] =
          (to as any) instanceof Date
            ? (to as any).toISOString().substr(0, 10)
            : to;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Thống kê biểu đồ giao dịch Trên hệ thống từ BANK
     * @param {string} from
     * @param {string} to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransactionStatisticFromBank: async (
      from: string,
      to: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'from' is not null or undefined
      assertParamExists("getTransactionStatisticFromBank", "from", from);
      // verify required parameter 'to' is not null or undefined
      assertParamExists("getTransactionStatisticFromBank", "to", to);
      const localVarPath = `/api/dashboard/portal/v1/bankTransaction/statistic`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (from !== undefined) {
        localVarQueryParameter["from"] =
          (from as any) instanceof Date
            ? (from as any).toISOString().substr(0, 10)
            : from;
      }

      if (to !== undefined) {
        localVarQueryParameter["to"] =
          (to as any) instanceof Date
            ? (to as any).toISOString().substr(0, 10)
            : to;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PortalDashboardControllerApi - functional programming interface
 * @export
 */
export const PortalDashboardControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    PortalDashboardControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Lấy tổng số giao dịch và tổng tiền ngày hôm nay trên hệ thống UMEEPAY
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTotalTodayTransactions(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePortalTotalTransactionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getTotalTodayTransactions(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy tổng số giao dịch và tổng tiền ngày hôm nay từ BANK
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTotalTodayTransactionsFromBank(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePortalTotalTransactionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getTotalTodayTransactionsFromBank(
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy tổng số giao dịch và tổng tiền trên hệ thống UMEEPAY
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTotalTransaction(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePortalTotalTransactionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getTotalTransaction(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy tổng số giao dịch và tổng tiền từ BANK
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTotalTransactionFromBank(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePortalTotalTransactionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getTotalTransactionFromBank(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Thống kê biểu đồ giao dịch Trên hệ thống UmeePay
     * @param {string} from
     * @param {string} to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTransactionStatistic(
      from: string,
      to: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePortalTransactionStatisticResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getTransactionStatistic(
          from,
          to,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Thống kê biểu đồ giao dịch Trên hệ thống từ BANK
     * @param {string} from
     * @param {string} to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTransactionStatisticFromBank(
      from: string,
      to: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePortalTransactionStatisticResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getTransactionStatisticFromBank(
          from,
          to,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PortalDashboardControllerApi - factory interface
 * @export
 */
export const PortalDashboardControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PortalDashboardControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Lấy tổng số giao dịch và tổng tiền ngày hôm nay trên hệ thống UMEEPAY
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalTodayTransactions(
      options?: any,
    ): AxiosPromise<ResponseBasePortalTotalTransactionResponse> {
      return localVarFp
        .getTotalTodayTransactions(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy tổng số giao dịch và tổng tiền ngày hôm nay từ BANK
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalTodayTransactionsFromBank(
      options?: any,
    ): AxiosPromise<ResponseBasePortalTotalTransactionResponse> {
      return localVarFp
        .getTotalTodayTransactionsFromBank(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy tổng số giao dịch và tổng tiền trên hệ thống UMEEPAY
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalTransaction(
      options?: any,
    ): AxiosPromise<ResponseBasePortalTotalTransactionResponse> {
      return localVarFp
        .getTotalTransaction(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy tổng số giao dịch và tổng tiền từ BANK
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalTransactionFromBank(
      options?: any,
    ): AxiosPromise<ResponseBasePortalTotalTransactionResponse> {
      return localVarFp
        .getTotalTransactionFromBank(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Thống kê biểu đồ giao dịch Trên hệ thống UmeePay
     * @param {string} from
     * @param {string} to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransactionStatistic(
      from: string,
      to: string,
      options?: any,
    ): AxiosPromise<ResponseBasePortalTransactionStatisticResponse> {
      return localVarFp
        .getTransactionStatistic(from, to, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Thống kê biểu đồ giao dịch Trên hệ thống từ BANK
     * @param {string} from
     * @param {string} to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransactionStatisticFromBank(
      from: string,
      to: string,
      options?: any,
    ): AxiosPromise<ResponseBasePortalTransactionStatisticResponse> {
      return localVarFp
        .getTransactionStatisticFromBank(from, to, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getTransactionStatistic operation in PortalDashboardControllerApi.
 * @export
 * @interface PortalDashboardControllerApiGetTransactionStatisticRequest
 */
export interface PortalDashboardControllerApiGetTransactionStatisticRequest {
  /**
   *
   * @type {string}
   * @memberof PortalDashboardControllerApiGetTransactionStatistic
   */
  readonly from: string;

  /**
   *
   * @type {string}
   * @memberof PortalDashboardControllerApiGetTransactionStatistic
   */
  readonly to: string;
}

/**
 * Request parameters for getTransactionStatisticFromBank operation in PortalDashboardControllerApi.
 * @export
 * @interface PortalDashboardControllerApiGetTransactionStatisticFromBankRequest
 */
export interface PortalDashboardControllerApiGetTransactionStatisticFromBankRequest {
  /**
   *
   * @type {string}
   * @memberof PortalDashboardControllerApiGetTransactionStatisticFromBank
   */
  readonly from: string;

  /**
   *
   * @type {string}
   * @memberof PortalDashboardControllerApiGetTransactionStatisticFromBank
   */
  readonly to: string;
}

/**
 * PortalDashboardControllerApi - object-oriented interface
 * @export
 * @class PortalDashboardControllerApi
 * @extends {BaseAPI}
 */
export class PortalDashboardControllerApi extends BaseAPI {
  /**
   *
   * @summary Lấy tổng số giao dịch và tổng tiền ngày hôm nay trên hệ thống UMEEPAY
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalDashboardControllerApi
   */
  public getTotalTodayTransactions(options?: AxiosRequestConfig) {
    return PortalDashboardControllerApiFp(this.configuration)
      .getTotalTodayTransactions(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy tổng số giao dịch và tổng tiền ngày hôm nay từ BANK
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalDashboardControllerApi
   */
  public getTotalTodayTransactionsFromBank(options?: AxiosRequestConfig) {
    return PortalDashboardControllerApiFp(this.configuration)
      .getTotalTodayTransactionsFromBank(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy tổng số giao dịch và tổng tiền trên hệ thống UMEEPAY
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalDashboardControllerApi
   */
  public getTotalTransaction(options?: AxiosRequestConfig) {
    return PortalDashboardControllerApiFp(this.configuration)
      .getTotalTransaction(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy tổng số giao dịch và tổng tiền từ BANK
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalDashboardControllerApi
   */
  public getTotalTransactionFromBank(options?: AxiosRequestConfig) {
    return PortalDashboardControllerApiFp(this.configuration)
      .getTotalTransactionFromBank(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Thống kê biểu đồ giao dịch Trên hệ thống UmeePay
   * @param {PortalDashboardControllerApiGetTransactionStatisticRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalDashboardControllerApi
   */
  public getTransactionStatistic(
    requestParameters: PortalDashboardControllerApiGetTransactionStatisticRequest,
    options?: AxiosRequestConfig,
  ) {
    return PortalDashboardControllerApiFp(this.configuration)
      .getTransactionStatistic(
        requestParameters.from,
        requestParameters.to,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Thống kê biểu đồ giao dịch Trên hệ thống từ BANK
   * @param {PortalDashboardControllerApiGetTransactionStatisticFromBankRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalDashboardControllerApi
   */
  public getTransactionStatisticFromBank(
    requestParameters: PortalDashboardControllerApiGetTransactionStatisticFromBankRequest,
    options?: AxiosRequestConfig,
  ) {
    return PortalDashboardControllerApiFp(this.configuration)
      .getTransactionStatisticFromBank(
        requestParameters.from,
        requestParameters.to,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PortalPayInfoApi - axios parameter creator
 * @export
 */
export const PortalPayInfoApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Thông tin thanh toán bằng Thẻ ATM
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAtmCard: async (
      transactionId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'transactionId' is not null or undefined
      assertParamExists("getAtmCard", "transactionId", transactionId);
      const localVarPath =
        `/api/payinfo/public/v1/pay/atm-card/{transactionId}`.replace(
          `{${"transactionId"}}`,
          encodeURIComponent(String(transactionId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Thông tin thanh toán Banking
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBankTransfer: async (
      transactionId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'transactionId' is not null or undefined
      assertParamExists("getBankTransfer", "transactionId", transactionId);
      const localVarPath =
        `/api/payinfo/public/v1/pay/bankTransfer/{transactionId}`.replace(
          `{${"transactionId"}}`,
          encodeURIComponent(String(transactionId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Thông tin thanh toán bằng Việt QR
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVietQR: async (
      transactionId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'transactionId' is not null or undefined
      assertParamExists("getVietQR", "transactionId", transactionId);
      const localVarPath =
        `/api/payinfo/public/v1/pay/vietQR/{transactionId}`.replace(
          `{${"transactionId"}}`,
          encodeURIComponent(String(transactionId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Kiểm tra trạng thái thanh toán
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    queryPayInfo: async (
      transactionId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'transactionId' is not null or undefined
      assertParamExists("queryPayInfo", "transactionId", transactionId);
      const localVarPath =
        `/api/payinfo/public/v1/query/{transactionId}`.replace(
          `{${"transactionId"}}`,
          encodeURIComponent(String(transactionId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PortalPayInfoApi - functional programming interface
 * @export
 */
export const PortalPayInfoApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    PortalPayInfoApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Thông tin thanh toán bằng Thẻ ATM
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAtmCard(
      transactionId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetVietQRInfoResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAtmCard(
        transactionId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Thông tin thanh toán Banking
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBankTransfer(
      transactionId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetBankTransferInfoResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBankTransfer(
        transactionId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Thông tin thanh toán bằng Việt QR
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getVietQR(
      transactionId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetVietQRInfoResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getVietQR(
        transactionId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Kiểm tra trạng thái thanh toán
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async queryPayInfo(
      transactionId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePayInfoStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.queryPayInfo(
        transactionId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PortalPayInfoApi - factory interface
 * @export
 */
export const PortalPayInfoApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PortalPayInfoApiFp(configuration);
  return {
    /**
     *
     * @summary Thông tin thanh toán bằng Thẻ ATM
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAtmCard(
      transactionId: string,
      options?: any,
    ): AxiosPromise<ResponseBaseGetVietQRInfoResponse> {
      return localVarFp
        .getAtmCard(transactionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Thông tin thanh toán Banking
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBankTransfer(
      transactionId: string,
      options?: any,
    ): AxiosPromise<ResponseBaseGetBankTransferInfoResponse> {
      return localVarFp
        .getBankTransfer(transactionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Thông tin thanh toán bằng Việt QR
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVietQR(
      transactionId: string,
      options?: any,
    ): AxiosPromise<ResponseBaseGetVietQRInfoResponse> {
      return localVarFp
        .getVietQR(transactionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Kiểm tra trạng thái thanh toán
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    queryPayInfo(
      transactionId: string,
      options?: any,
    ): AxiosPromise<ResponseBasePayInfoStatusResponse> {
      return localVarFp
        .queryPayInfo(transactionId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getAtmCard operation in PortalPayInfoApi.
 * @export
 * @interface PortalPayInfoApiGetAtmCardRequest
 */
export interface PortalPayInfoApiGetAtmCardRequest {
  /**
   *
   * @type {string}
   * @memberof PortalPayInfoApiGetAtmCard
   */
  readonly transactionId: string;
}

/**
 * Request parameters for getBankTransfer operation in PortalPayInfoApi.
 * @export
 * @interface PortalPayInfoApiGetBankTransferRequest
 */
export interface PortalPayInfoApiGetBankTransferRequest {
  /**
   *
   * @type {string}
   * @memberof PortalPayInfoApiGetBankTransfer
   */
  readonly transactionId: string;
}

/**
 * Request parameters for getVietQR operation in PortalPayInfoApi.
 * @export
 * @interface PortalPayInfoApiGetVietQRRequest
 */
export interface PortalPayInfoApiGetVietQRRequest {
  /**
   *
   * @type {string}
   * @memberof PortalPayInfoApiGetVietQR
   */
  readonly transactionId: string;
}

/**
 * Request parameters for queryPayInfo operation in PortalPayInfoApi.
 * @export
 * @interface PortalPayInfoApiQueryPayInfoRequest
 */
export interface PortalPayInfoApiQueryPayInfoRequest {
  /**
   *
   * @type {string}
   * @memberof PortalPayInfoApiQueryPayInfo
   */
  readonly transactionId: string;
}

/**
 * PortalPayInfoApi - object-oriented interface
 * @export
 * @class PortalPayInfoApi
 * @extends {BaseAPI}
 */
export class PortalPayInfoApi extends BaseAPI {
  /**
   *
   * @summary Thông tin thanh toán bằng Thẻ ATM
   * @param {PortalPayInfoApiGetAtmCardRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalPayInfoApi
   */
  public getAtmCard(
    requestParameters: PortalPayInfoApiGetAtmCardRequest,
    options?: AxiosRequestConfig,
  ) {
    return PortalPayInfoApiFp(this.configuration)
      .getAtmCard(requestParameters.transactionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Thông tin thanh toán Banking
   * @param {PortalPayInfoApiGetBankTransferRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalPayInfoApi
   */
  public getBankTransfer(
    requestParameters: PortalPayInfoApiGetBankTransferRequest,
    options?: AxiosRequestConfig,
  ) {
    return PortalPayInfoApiFp(this.configuration)
      .getBankTransfer(requestParameters.transactionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Thông tin thanh toán bằng Việt QR
   * @param {PortalPayInfoApiGetVietQRRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalPayInfoApi
   */
  public getVietQR(
    requestParameters: PortalPayInfoApiGetVietQRRequest,
    options?: AxiosRequestConfig,
  ) {
    return PortalPayInfoApiFp(this.configuration)
      .getVietQR(requestParameters.transactionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Kiểm tra trạng thái thanh toán
   * @param {PortalPayInfoApiQueryPayInfoRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalPayInfoApi
   */
  public queryPayInfo(
    requestParameters: PortalPayInfoApiQueryPayInfoRequest,
    options?: AxiosRequestConfig,
  ) {
    return PortalPayInfoApiFp(this.configuration)
      .queryPayInfo(requestParameters.transactionId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PortalPayOutControllerApi - axios parameter creator
 * @export
 */
export const PortalPayOutControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Duyệt PayOut step 1: Duyệt lệnh chuyển tiền
     * @param {ApproveSinglePayOutRequest} approveSinglePayOutRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approveSinglePayOut: async (
      approveSinglePayOutRequest: ApproveSinglePayOutRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'approveSinglePayOutRequest' is not null or undefined
      assertParamExists(
        "approveSinglePayOut",
        "approveSinglePayOutRequest",
        approveSinglePayOutRequest,
      );
      const localVarPath = `/api/payout/v1/single/approve`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        approveSinglePayOutRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Tạo lệnh chuyển tiền lô
     * @param {CreateBatchPayOutRequest} createBatchPayOutRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createBatchPayOut: async (
      createBatchPayOutRequest: CreateBatchPayOutRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createBatchPayOutRequest' is not null or undefined
      assertParamExists(
        "createBatchPayOut",
        "createBatchPayOutRequest",
        createBatchPayOutRequest,
      );
      const localVarPath = `/api/payout/v1/batch/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createBatchPayOutRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Tạo mới người nhận
     * @param {CreateReceiverRequest} createReceiverRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createReceiver: async (
      createReceiverRequest: CreateReceiverRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createReceiverRequest' is not null or undefined
      assertParamExists(
        "createReceiver",
        "createReceiverRequest",
        createReceiverRequest,
      );
      const localVarPath = `/api/payout/v1/receiver/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createReceiverRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Tạo lệnh chuyển tiền đơn
     * @param {CreateSinglePayOutRequest} createSinglePayOutRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSinglePayOut: async (
      createSinglePayOutRequest: CreateSinglePayOutRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createSinglePayOutRequest' is not null or undefined
      assertParamExists(
        "createSinglePayOut",
        "createSinglePayOutRequest",
        createSinglePayOutRequest,
      );
      const localVarPath = `/api/payout/v1/single/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createSinglePayOutRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xoá người nhận
     * @param {number} receiverId
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteReceiver: async (
      receiverId: number,
      body: object,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'receiverId' is not null or undefined
      assertParamExists("deleteReceiver", "receiverId", receiverId);
      // verify required parameter 'body' is not null or undefined
      assertParamExists("deleteReceiver", "body", body);
      const localVarPath =
        `/api/payout/v1/receiver/delete/{receiverId}`.replace(
          `{${"receiverId"}}`,
          encodeURIComponent(String(receiverId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Từ chối Duyệt lệnh lệnh chuyển tiền
     * @param {DisapproveSinglePayOutRequest} disapproveSinglePayOutRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    disapproveSinglePayOut: async (
      disapproveSinglePayOutRequest: DisapproveSinglePayOutRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'disapproveSinglePayOutRequest' is not null or undefined
      assertParamExists(
        "disapproveSinglePayOut",
        "disapproveSinglePayOutRequest",
        disapproveSinglePayOutRequest,
      );
      const localVarPath = `/api/payout/v1/single/disapprove`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        disapproveSinglePayOutRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xem danh sách lệnh chuyển tiền lô
     * @param {string} [name]
     * @param {string} [id]
     * @param {'WAITING' | 'SUCCESS' | 'FAILED' | 'TRANSFERRING' | 'DENIED' | 'COMPLETED'} [status]
     * @param {string} [fromAccountNo]
     * @param {string} [createdFrom]
     * @param {string} [createdTo]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllBatchPayOuts: async (
      name?: string,
      id?: string,
      status?:
        | "WAITING"
        | "SUCCESS"
        | "FAILED"
        | "TRANSFERRING"
        | "DENIED"
        | "COMPLETED",
      fromAccountNo?: string,
      createdFrom?: string,
      createdTo?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/payout/v1/batch/getAll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (name !== undefined) {
        localVarQueryParameter["name"] = name;
      }

      if (id !== undefined) {
        localVarQueryParameter["id"] = id;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (fromAccountNo !== undefined) {
        localVarQueryParameter["fromAccountNo"] = fromAccountNo;
      }

      if (createdFrom !== undefined) {
        localVarQueryParameter["createdFrom"] = createdFrom;
      }

      if (createdTo !== undefined) {
        localVarQueryParameter["createdTo"] = createdTo;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter["sortDirection"] = sortDirection;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter["sortBy"] = sortBy;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xem danh sách người nhận đã lưu
     * @param {string} [query]
     * @param {'CUSTOMER' | 'EMPLOYEE' | 'MERCHANT' | 'FRIEND' | 'FAMILY' | 'OTHER'} [group]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllSavedReceivers: async (
      query?: string,
      group?:
        | "CUSTOMER"
        | "EMPLOYEE"
        | "MERCHANT"
        | "FRIEND"
        | "FAMILY"
        | "OTHER",
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/payout/v1/receiver/getAll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (query !== undefined) {
        localVarQueryParameter["query"] = query;
      }

      if (group !== undefined) {
        localVarQueryParameter["group"] = group;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter["sortDirection"] = sortDirection;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter["sortBy"] = sortBy;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xem danh sách lệnh chuyển tiền đơn
     * @param {string} [fromAccount]
     * @param {string} [toAccount]
     * @param {'WAITING' | 'SUCCESS' | 'FAILED' | 'TRANSFERRING' | 'DENIED'} [status]
     * @param {string} [transactionId]
     * @param {string} [createdFrom]
     * @param {string} [createdTo]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllSinglePayOuts: async (
      fromAccount?: string,
      toAccount?: string,
      status?: "WAITING" | "SUCCESS" | "FAILED" | "TRANSFERRING" | "DENIED",
      transactionId?: string,
      createdFrom?: string,
      createdTo?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/payout/v1/single/getAll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (fromAccount !== undefined) {
        localVarQueryParameter["fromAccount"] = fromAccount;
      }

      if (toAccount !== undefined) {
        localVarQueryParameter["toAccount"] = toAccount;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (transactionId !== undefined) {
        localVarQueryParameter["transactionId"] = transactionId;
      }

      if (createdFrom !== undefined) {
        localVarQueryParameter["createdFrom"] = createdFrom;
      }

      if (createdTo !== undefined) {
        localVarQueryParameter["createdTo"] = createdTo;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter["sortDirection"] = sortDirection;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter["sortBy"] = sortBy;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Chi tiết lệnh chuyển lô
     * @param {string} payOutId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBatchPayoutDetail: async (
      payOutId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'payOutId' is not null or undefined
      assertParamExists("getBatchPayoutDetail", "payOutId", payOutId);
      const localVarPath = `/api/payout/v1/batch/getDetail/{payOutId}`.replace(
        `{${"payOutId"}}`,
        encodeURIComponent(String(payOutId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Chi tiết lệnh chuyển tiền
     * @param {string} payOutId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSinglePayoutDetail: async (
      payOutId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'payOutId' is not null or undefined
      assertParamExists("getSinglePayoutDetail", "payOutId", payOutId);
      const localVarPath = `/api/payout/v1/single/getDetail/{payOutId}`.replace(
        `{${"payOutId"}}`,
        encodeURIComponent(String(payOutId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Chỉnh sửa người nhận
     * @param {number} receiverId
     * @param {UpdateReceiverRequest} updateReceiverRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateReceiver: async (
      receiverId: number,
      updateReceiverRequest: UpdateReceiverRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'receiverId' is not null or undefined
      assertParamExists("updateReceiver", "receiverId", receiverId);
      // verify required parameter 'updateReceiverRequest' is not null or undefined
      assertParamExists(
        "updateReceiver",
        "updateReceiverRequest",
        updateReceiverRequest,
      );
      const localVarPath =
        `/api/payout/v1/receiver/update/{receiverId}`.replace(
          `{${"receiverId"}}`,
          encodeURIComponent(String(receiverId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateReceiverRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Upload file chuyển tiền lô
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadBatchFile: async (
      file: File,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'file' is not null or undefined
      assertParamExists("uploadBatchFile", "file", file);
      const localVarPath = `/api/payout/v1/batch/uploadFile`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (file !== undefined) {
        localVarFormParams.append("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Duyệt PayOut step 2: Tiến hành check OTP
     * @param {VerifyOTPSinglePayOutRequest} verifyOTPSinglePayOutRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifySinglePayOut: async (
      verifyOTPSinglePayOutRequest: VerifyOTPSinglePayOutRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'verifyOTPSinglePayOutRequest' is not null or undefined
      assertParamExists(
        "verifySinglePayOut",
        "verifyOTPSinglePayOutRequest",
        verifyOTPSinglePayOutRequest,
      );
      const localVarPath = `/api/payout/v1/single/approve/verify`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        verifyOTPSinglePayOutRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PortalPayOutControllerApi - functional programming interface
 * @export
 */
export const PortalPayOutControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    PortalPayOutControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Duyệt PayOut step 1: Duyệt lệnh chuyển tiền
     * @param {ApproveSinglePayOutRequest} approveSinglePayOutRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async approveSinglePayOut(
      approveSinglePayOutRequest: ApproveSinglePayOutRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseOTPPayOutResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.approveSinglePayOut(
          approveSinglePayOutRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Tạo lệnh chuyển tiền lô
     * @param {CreateBatchPayOutRequest} createBatchPayOutRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createBatchPayOut(
      createBatchPayOutRequest: CreateBatchPayOutRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseBatchPayOutResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createBatchPayOut(
          createBatchPayOutRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Tạo mới người nhận
     * @param {CreateReceiverRequest} createReceiverRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createReceiver(
      createReceiverRequest: CreateReceiverRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePayOutReceiverDTO>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createReceiver(
        createReceiverRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Tạo lệnh chuyển tiền đơn
     * @param {CreateSinglePayOutRequest} createSinglePayOutRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createSinglePayOut(
      createSinglePayOutRequest: CreateSinglePayOutRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseSinglePayOutResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createSinglePayOut(
          createSinglePayOutRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Xoá người nhận
     * @param {number} receiverId
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteReceiver(
      receiverId: number,
      body: object,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePayOutReceiverDTO>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReceiver(
        receiverId,
        body,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Từ chối Duyệt lệnh lệnh chuyển tiền
     * @param {DisapproveSinglePayOutRequest} disapproveSinglePayOutRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async disapproveSinglePayOut(
      disapproveSinglePayOutRequest: DisapproveSinglePayOutRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.disapproveSinglePayOut(
          disapproveSinglePayOutRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Xem danh sách lệnh chuyển tiền lô
     * @param {string} [name]
     * @param {string} [id]
     * @param {'WAITING' | 'SUCCESS' | 'FAILED' | 'TRANSFERRING' | 'DENIED' | 'COMPLETED'} [status]
     * @param {string} [fromAccountNo]
     * @param {string} [createdFrom]
     * @param {string} [createdTo]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllBatchPayOuts(
      name?: string,
      id?: string,
      status?:
        | "WAITING"
        | "SUCCESS"
        | "FAILED"
        | "TRANSFERRING"
        | "DENIED"
        | "COMPLETED",
      fromAccountNo?: string,
      createdFrom?: string,
      createdTo?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseBatchPayOutResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllBatchPayOuts(
          name,
          id,
          status,
          fromAccountNo,
          createdFrom,
          createdTo,
          page,
          pageSize,
          sortDirection,
          sortBy,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Xem danh sách người nhận đã lưu
     * @param {string} [query]
     * @param {'CUSTOMER' | 'EMPLOYEE' | 'MERCHANT' | 'FRIEND' | 'FAMILY' | 'OTHER'} [group]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllSavedReceivers(
      query?: string,
      group?:
        | "CUSTOMER"
        | "EMPLOYEE"
        | "MERCHANT"
        | "FRIEND"
        | "FAMILY"
        | "OTHER",
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponsePayOutReceiverDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllSavedReceivers(
          query,
          group,
          page,
          pageSize,
          sortDirection,
          sortBy,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Xem danh sách lệnh chuyển tiền đơn
     * @param {string} [fromAccount]
     * @param {string} [toAccount]
     * @param {'WAITING' | 'SUCCESS' | 'FAILED' | 'TRANSFERRING' | 'DENIED'} [status]
     * @param {string} [transactionId]
     * @param {string} [createdFrom]
     * @param {string} [createdTo]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllSinglePayOuts(
      fromAccount?: string,
      toAccount?: string,
      status?: "WAITING" | "SUCCESS" | "FAILED" | "TRANSFERRING" | "DENIED",
      transactionId?: string,
      createdFrom?: string,
      createdTo?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseSinglePayOutResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllSinglePayOuts(
          fromAccount,
          toAccount,
          status,
          transactionId,
          createdFrom,
          createdTo,
          page,
          pageSize,
          sortDirection,
          sortBy,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Chi tiết lệnh chuyển lô
     * @param {string} payOutId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBatchPayoutDetail(
      payOutId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseBatchPayOutDetailResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getBatchPayoutDetail(payOutId, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Chi tiết lệnh chuyển tiền
     * @param {string} payOutId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSinglePayoutDetail(
      payOutId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseSinglePayOutDetailResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSinglePayoutDetail(
          payOutId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Chỉnh sửa người nhận
     * @param {number} receiverId
     * @param {UpdateReceiverRequest} updateReceiverRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateReceiver(
      receiverId: number,
      updateReceiverRequest: UpdateReceiverRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePayOutReceiverDTO>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateReceiver(
        receiverId,
        updateReceiverRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Upload file chuyển tiền lô
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadBatchFile(
      file: File,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseListResponsePayOutUploadReceiverDTO>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.uploadBatchFile(
        file,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Duyệt PayOut step 2: Tiến hành check OTP
     * @param {VerifyOTPSinglePayOutRequest} verifyOTPSinglePayOutRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async verifySinglePayOut(
      verifyOTPSinglePayOutRequest: VerifyOTPSinglePayOutRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.verifySinglePayOut(
          verifyOTPSinglePayOutRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PortalPayOutControllerApi - factory interface
 * @export
 */
export const PortalPayOutControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PortalPayOutControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Duyệt PayOut step 1: Duyệt lệnh chuyển tiền
     * @param {ApproveSinglePayOutRequest} approveSinglePayOutRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approveSinglePayOut(
      approveSinglePayOutRequest: ApproveSinglePayOutRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseOTPPayOutResponse> {
      return localVarFp
        .approveSinglePayOut(approveSinglePayOutRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Tạo lệnh chuyển tiền lô
     * @param {CreateBatchPayOutRequest} createBatchPayOutRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createBatchPayOut(
      createBatchPayOutRequest: CreateBatchPayOutRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseBatchPayOutResponse> {
      return localVarFp
        .createBatchPayOut(createBatchPayOutRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Tạo mới người nhận
     * @param {CreateReceiverRequest} createReceiverRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createReceiver(
      createReceiverRequest: CreateReceiverRequest,
      options?: any,
    ): AxiosPromise<ResponseBasePayOutReceiverDTO> {
      return localVarFp
        .createReceiver(createReceiverRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Tạo lệnh chuyển tiền đơn
     * @param {CreateSinglePayOutRequest} createSinglePayOutRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSinglePayOut(
      createSinglePayOutRequest: CreateSinglePayOutRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseSinglePayOutResponse> {
      return localVarFp
        .createSinglePayOut(createSinglePayOutRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xoá người nhận
     * @param {number} receiverId
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteReceiver(
      receiverId: number,
      body: object,
      options?: any,
    ): AxiosPromise<ResponseBasePayOutReceiverDTO> {
      return localVarFp
        .deleteReceiver(receiverId, body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Từ chối Duyệt lệnh lệnh chuyển tiền
     * @param {DisapproveSinglePayOutRequest} disapproveSinglePayOutRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    disapproveSinglePayOut(
      disapproveSinglePayOutRequest: DisapproveSinglePayOutRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .disapproveSinglePayOut(disapproveSinglePayOutRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xem danh sách lệnh chuyển tiền lô
     * @param {string} [name]
     * @param {string} [id]
     * @param {'WAITING' | 'SUCCESS' | 'FAILED' | 'TRANSFERRING' | 'DENIED' | 'COMPLETED'} [status]
     * @param {string} [fromAccountNo]
     * @param {string} [createdFrom]
     * @param {string} [createdTo]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllBatchPayOuts(
      name?: string,
      id?: string,
      status?:
        | "WAITING"
        | "SUCCESS"
        | "FAILED"
        | "TRANSFERRING"
        | "DENIED"
        | "COMPLETED",
      fromAccountNo?: string,
      createdFrom?: string,
      createdTo?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseBatchPayOutResponse> {
      return localVarFp
        .getAllBatchPayOuts(
          name,
          id,
          status,
          fromAccountNo,
          createdFrom,
          createdTo,
          page,
          pageSize,
          sortDirection,
          sortBy,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xem danh sách người nhận đã lưu
     * @param {string} [query]
     * @param {'CUSTOMER' | 'EMPLOYEE' | 'MERCHANT' | 'FRIEND' | 'FAMILY' | 'OTHER'} [group]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllSavedReceivers(
      query?: string,
      group?:
        | "CUSTOMER"
        | "EMPLOYEE"
        | "MERCHANT"
        | "FRIEND"
        | "FAMILY"
        | "OTHER",
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponsePayOutReceiverDTO> {
      return localVarFp
        .getAllSavedReceivers(
          query,
          group,
          page,
          pageSize,
          sortDirection,
          sortBy,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xem danh sách lệnh chuyển tiền đơn
     * @param {string} [fromAccount]
     * @param {string} [toAccount]
     * @param {'WAITING' | 'SUCCESS' | 'FAILED' | 'TRANSFERRING' | 'DENIED'} [status]
     * @param {string} [transactionId]
     * @param {string} [createdFrom]
     * @param {string} [createdTo]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllSinglePayOuts(
      fromAccount?: string,
      toAccount?: string,
      status?: "WAITING" | "SUCCESS" | "FAILED" | "TRANSFERRING" | "DENIED",
      transactionId?: string,
      createdFrom?: string,
      createdTo?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseSinglePayOutResponse> {
      return localVarFp
        .getAllSinglePayOuts(
          fromAccount,
          toAccount,
          status,
          transactionId,
          createdFrom,
          createdTo,
          page,
          pageSize,
          sortDirection,
          sortBy,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Chi tiết lệnh chuyển lô
     * @param {string} payOutId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBatchPayoutDetail(
      payOutId: string,
      options?: any,
    ): AxiosPromise<ResponseBaseBatchPayOutDetailResponse> {
      return localVarFp
        .getBatchPayoutDetail(payOutId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Chi tiết lệnh chuyển tiền
     * @param {string} payOutId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSinglePayoutDetail(
      payOutId: string,
      options?: any,
    ): AxiosPromise<ResponseBaseSinglePayOutDetailResponse> {
      return localVarFp
        .getSinglePayoutDetail(payOutId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Chỉnh sửa người nhận
     * @param {number} receiverId
     * @param {UpdateReceiverRequest} updateReceiverRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateReceiver(
      receiverId: number,
      updateReceiverRequest: UpdateReceiverRequest,
      options?: any,
    ): AxiosPromise<ResponseBasePayOutReceiverDTO> {
      return localVarFp
        .updateReceiver(receiverId, updateReceiverRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Upload file chuyển tiền lô
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadBatchFile(
      file: File,
      options?: any,
    ): AxiosPromise<ResponseBaseListResponsePayOutUploadReceiverDTO> {
      return localVarFp
        .uploadBatchFile(file, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Duyệt PayOut step 2: Tiến hành check OTP
     * @param {VerifyOTPSinglePayOutRequest} verifyOTPSinglePayOutRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifySinglePayOut(
      verifyOTPSinglePayOutRequest: VerifyOTPSinglePayOutRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .verifySinglePayOut(verifyOTPSinglePayOutRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for approveSinglePayOut operation in PortalPayOutControllerApi.
 * @export
 * @interface PortalPayOutControllerApiApproveSinglePayOutRequest
 */
export interface PortalPayOutControllerApiApproveSinglePayOutRequest {
  /**
   *
   * @type {ApproveSinglePayOutRequest}
   * @memberof PortalPayOutControllerApiApproveSinglePayOut
   */
  readonly approveSinglePayOutRequest: ApproveSinglePayOutRequest;
}

/**
 * Request parameters for createBatchPayOut operation in PortalPayOutControllerApi.
 * @export
 * @interface PortalPayOutControllerApiCreateBatchPayOutRequest
 */
export interface PortalPayOutControllerApiCreateBatchPayOutRequest {
  /**
   *
   * @type {CreateBatchPayOutRequest}
   * @memberof PortalPayOutControllerApiCreateBatchPayOut
   */
  readonly createBatchPayOutRequest: CreateBatchPayOutRequest;
}

/**
 * Request parameters for createReceiver operation in PortalPayOutControllerApi.
 * @export
 * @interface PortalPayOutControllerApiCreateReceiverRequest
 */
export interface PortalPayOutControllerApiCreateReceiverRequest {
  /**
   *
   * @type {CreateReceiverRequest}
   * @memberof PortalPayOutControllerApiCreateReceiver
   */
  readonly createReceiverRequest: CreateReceiverRequest;
}

/**
 * Request parameters for createSinglePayOut operation in PortalPayOutControllerApi.
 * @export
 * @interface PortalPayOutControllerApiCreateSinglePayOutRequest
 */
export interface PortalPayOutControllerApiCreateSinglePayOutRequest {
  /**
   *
   * @type {CreateSinglePayOutRequest}
   * @memberof PortalPayOutControllerApiCreateSinglePayOut
   */
  readonly createSinglePayOutRequest: CreateSinglePayOutRequest;
}

/**
 * Request parameters for deleteReceiver operation in PortalPayOutControllerApi.
 * @export
 * @interface PortalPayOutControllerApiDeleteReceiverRequest
 */
export interface PortalPayOutControllerApiDeleteReceiverRequest {
  /**
   *
   * @type {number}
   * @memberof PortalPayOutControllerApiDeleteReceiver
   */
  readonly receiverId: number;

  /**
   *
   * @type {object}
   * @memberof PortalPayOutControllerApiDeleteReceiver
   */
  readonly body: object;
}

/**
 * Request parameters for disapproveSinglePayOut operation in PortalPayOutControllerApi.
 * @export
 * @interface PortalPayOutControllerApiDisapproveSinglePayOutRequest
 */
export interface PortalPayOutControllerApiDisapproveSinglePayOutRequest {
  /**
   *
   * @type {DisapproveSinglePayOutRequest}
   * @memberof PortalPayOutControllerApiDisapproveSinglePayOut
   */
  readonly disapproveSinglePayOutRequest: DisapproveSinglePayOutRequest;
}

/**
 * Request parameters for getAllBatchPayOuts operation in PortalPayOutControllerApi.
 * @export
 * @interface PortalPayOutControllerApiGetAllBatchPayOutsRequest
 */
export interface PortalPayOutControllerApiGetAllBatchPayOutsRequest {
  /**
   *
   * @type {string}
   * @memberof PortalPayOutControllerApiGetAllBatchPayOuts
   */
  readonly name?: string;

  /**
   *
   * @type {string}
   * @memberof PortalPayOutControllerApiGetAllBatchPayOuts
   */
  readonly id?: string;

  /**
   *
   * @type {'WAITING' | 'SUCCESS' | 'FAILED' | 'TRANSFERRING' | 'DENIED' | 'COMPLETED'}
   * @memberof PortalPayOutControllerApiGetAllBatchPayOuts
   */
  readonly status?:
    | "WAITING"
    | "SUCCESS"
    | "FAILED"
    | "TRANSFERRING"
    | "DENIED"
    | "COMPLETED";

  /**
   *
   * @type {string}
   * @memberof PortalPayOutControllerApiGetAllBatchPayOuts
   */
  readonly fromAccountNo?: string;

  /**
   *
   * @type {string}
   * @memberof PortalPayOutControllerApiGetAllBatchPayOuts
   */
  readonly createdFrom?: string;

  /**
   *
   * @type {string}
   * @memberof PortalPayOutControllerApiGetAllBatchPayOuts
   */
  readonly createdTo?: string;

  /**
   *
   * @type {number}
   * @memberof PortalPayOutControllerApiGetAllBatchPayOuts
   */
  readonly page?: number;

  /**
   *
   * @type {number}
   * @memberof PortalPayOutControllerApiGetAllBatchPayOuts
   */
  readonly pageSize?: number;

  /**
   *
   * @type {'ASC' | 'DESC'}
   * @memberof PortalPayOutControllerApiGetAllBatchPayOuts
   */
  readonly sortDirection?: "ASC" | "DESC";

  /**
   *
   * @type {string}
   * @memberof PortalPayOutControllerApiGetAllBatchPayOuts
   */
  readonly sortBy?: string;
}

/**
 * Request parameters for getAllSavedReceivers operation in PortalPayOutControllerApi.
 * @export
 * @interface PortalPayOutControllerApiGetAllSavedReceiversRequest
 */
export interface PortalPayOutControllerApiGetAllSavedReceiversRequest {
  /**
   *
   * @type {string}
   * @memberof PortalPayOutControllerApiGetAllSavedReceivers
   */
  readonly query?: string;

  /**
   *
   * @type {'CUSTOMER' | 'EMPLOYEE' | 'MERCHANT' | 'FRIEND' | 'FAMILY' | 'OTHER'}
   * @memberof PortalPayOutControllerApiGetAllSavedReceivers
   */
  readonly group?:
    | "CUSTOMER"
    | "EMPLOYEE"
    | "MERCHANT"
    | "FRIEND"
    | "FAMILY"
    | "OTHER";

  /**
   *
   * @type {number}
   * @memberof PortalPayOutControllerApiGetAllSavedReceivers
   */
  readonly page?: number;

  /**
   *
   * @type {number}
   * @memberof PortalPayOutControllerApiGetAllSavedReceivers
   */
  readonly pageSize?: number;

  /**
   *
   * @type {'ASC' | 'DESC'}
   * @memberof PortalPayOutControllerApiGetAllSavedReceivers
   */
  readonly sortDirection?: "ASC" | "DESC";

  /**
   *
   * @type {string}
   * @memberof PortalPayOutControllerApiGetAllSavedReceivers
   */
  readonly sortBy?: string;
}

/**
 * Request parameters for getAllSinglePayOuts operation in PortalPayOutControllerApi.
 * @export
 * @interface PortalPayOutControllerApiGetAllSinglePayOutsRequest
 */
export interface PortalPayOutControllerApiGetAllSinglePayOutsRequest {
  /**
   *
   * @type {string}
   * @memberof PortalPayOutControllerApiGetAllSinglePayOuts
   */
  readonly fromAccount?: string;

  /**
   *
   * @type {string}
   * @memberof PortalPayOutControllerApiGetAllSinglePayOuts
   */
  readonly toAccount?: string;

  /**
   *
   * @type {'WAITING' | 'SUCCESS' | 'FAILED' | 'TRANSFERRING' | 'DENIED'}
   * @memberof PortalPayOutControllerApiGetAllSinglePayOuts
   */
  readonly status?:
    | "WAITING"
    | "SUCCESS"
    | "FAILED"
    | "TRANSFERRING"
    | "DENIED";

  /**
   *
   * @type {string}
   * @memberof PortalPayOutControllerApiGetAllSinglePayOuts
   */
  readonly transactionId?: string;

  /**
   *
   * @type {string}
   * @memberof PortalPayOutControllerApiGetAllSinglePayOuts
   */
  readonly createdFrom?: string;

  /**
   *
   * @type {string}
   * @memberof PortalPayOutControllerApiGetAllSinglePayOuts
   */
  readonly createdTo?: string;

  /**
   *
   * @type {number}
   * @memberof PortalPayOutControllerApiGetAllSinglePayOuts
   */
  readonly page?: number;

  /**
   *
   * @type {number}
   * @memberof PortalPayOutControllerApiGetAllSinglePayOuts
   */
  readonly pageSize?: number;

  /**
   *
   * @type {'ASC' | 'DESC'}
   * @memberof PortalPayOutControllerApiGetAllSinglePayOuts
   */
  readonly sortDirection?: "ASC" | "DESC";

  /**
   *
   * @type {string}
   * @memberof PortalPayOutControllerApiGetAllSinglePayOuts
   */
  readonly sortBy?: string;
}

/**
 * Request parameters for getBatchPayoutDetail operation in PortalPayOutControllerApi.
 * @export
 * @interface PortalPayOutControllerApiGetBatchPayoutDetailRequest
 */
export interface PortalPayOutControllerApiGetBatchPayoutDetailRequest {
  /**
   *
   * @type {string}
   * @memberof PortalPayOutControllerApiGetBatchPayoutDetail
   */
  readonly payOutId: string;
}

/**
 * Request parameters for getSinglePayoutDetail operation in PortalPayOutControllerApi.
 * @export
 * @interface PortalPayOutControllerApiGetSinglePayoutDetailRequest
 */
export interface PortalPayOutControllerApiGetSinglePayoutDetailRequest {
  /**
   *
   * @type {string}
   * @memberof PortalPayOutControllerApiGetSinglePayoutDetail
   */
  readonly payOutId: string;
}

/**
 * Request parameters for updateReceiver operation in PortalPayOutControllerApi.
 * @export
 * @interface PortalPayOutControllerApiUpdateReceiverRequest
 */
export interface PortalPayOutControllerApiUpdateReceiverRequest {
  /**
   *
   * @type {number}
   * @memberof PortalPayOutControllerApiUpdateReceiver
   */
  readonly receiverId: number;

  /**
   *
   * @type {UpdateReceiverRequest}
   * @memberof PortalPayOutControllerApiUpdateReceiver
   */
  readonly updateReceiverRequest: UpdateReceiverRequest;
}

/**
 * Request parameters for uploadBatchFile operation in PortalPayOutControllerApi.
 * @export
 * @interface PortalPayOutControllerApiUploadBatchFileRequest
 */
export interface PortalPayOutControllerApiUploadBatchFileRequest {
  /**
   *
   * @type {File}
   * @memberof PortalPayOutControllerApiUploadBatchFile
   */
  readonly file: File;
}

/**
 * Request parameters for verifySinglePayOut operation in PortalPayOutControllerApi.
 * @export
 * @interface PortalPayOutControllerApiVerifySinglePayOutRequest
 */
export interface PortalPayOutControllerApiVerifySinglePayOutRequest {
  /**
   *
   * @type {VerifyOTPSinglePayOutRequest}
   * @memberof PortalPayOutControllerApiVerifySinglePayOut
   */
  readonly verifyOTPSinglePayOutRequest: VerifyOTPSinglePayOutRequest;
}

/**
 * PortalPayOutControllerApi - object-oriented interface
 * @export
 * @class PortalPayOutControllerApi
 * @extends {BaseAPI}
 */
export class PortalPayOutControllerApi extends BaseAPI {
  /**
   *
   * @summary Duyệt PayOut step 1: Duyệt lệnh chuyển tiền
   * @param {PortalPayOutControllerApiApproveSinglePayOutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalPayOutControllerApi
   */
  public approveSinglePayOut(
    requestParameters: PortalPayOutControllerApiApproveSinglePayOutRequest,
    options?: AxiosRequestConfig,
  ) {
    return PortalPayOutControllerApiFp(this.configuration)
      .approveSinglePayOut(
        requestParameters.approveSinglePayOutRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Tạo lệnh chuyển tiền lô
   * @param {PortalPayOutControllerApiCreateBatchPayOutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalPayOutControllerApi
   */
  public createBatchPayOut(
    requestParameters: PortalPayOutControllerApiCreateBatchPayOutRequest,
    options?: AxiosRequestConfig,
  ) {
    return PortalPayOutControllerApiFp(this.configuration)
      .createBatchPayOut(requestParameters.createBatchPayOutRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Tạo mới người nhận
   * @param {PortalPayOutControllerApiCreateReceiverRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalPayOutControllerApi
   */
  public createReceiver(
    requestParameters: PortalPayOutControllerApiCreateReceiverRequest,
    options?: AxiosRequestConfig,
  ) {
    return PortalPayOutControllerApiFp(this.configuration)
      .createReceiver(requestParameters.createReceiverRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Tạo lệnh chuyển tiền đơn
   * @param {PortalPayOutControllerApiCreateSinglePayOutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalPayOutControllerApi
   */
  public createSinglePayOut(
    requestParameters: PortalPayOutControllerApiCreateSinglePayOutRequest,
    options?: AxiosRequestConfig,
  ) {
    return PortalPayOutControllerApiFp(this.configuration)
      .createSinglePayOut(requestParameters.createSinglePayOutRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xoá người nhận
   * @param {PortalPayOutControllerApiDeleteReceiverRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalPayOutControllerApi
   */
  public deleteReceiver(
    requestParameters: PortalPayOutControllerApiDeleteReceiverRequest,
    options?: AxiosRequestConfig,
  ) {
    return PortalPayOutControllerApiFp(this.configuration)
      .deleteReceiver(
        requestParameters.receiverId,
        requestParameters.body,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Từ chối Duyệt lệnh lệnh chuyển tiền
   * @param {PortalPayOutControllerApiDisapproveSinglePayOutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalPayOutControllerApi
   */
  public disapproveSinglePayOut(
    requestParameters: PortalPayOutControllerApiDisapproveSinglePayOutRequest,
    options?: AxiosRequestConfig,
  ) {
    return PortalPayOutControllerApiFp(this.configuration)
      .disapproveSinglePayOut(
        requestParameters.disapproveSinglePayOutRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xem danh sách lệnh chuyển tiền lô
   * @param {PortalPayOutControllerApiGetAllBatchPayOutsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalPayOutControllerApi
   */
  public getAllBatchPayOuts(
    requestParameters: PortalPayOutControllerApiGetAllBatchPayOutsRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return PortalPayOutControllerApiFp(this.configuration)
      .getAllBatchPayOuts(
        requestParameters.name,
        requestParameters.id,
        requestParameters.status,
        requestParameters.fromAccountNo,
        requestParameters.createdFrom,
        requestParameters.createdTo,
        requestParameters.page,
        requestParameters.pageSize,
        requestParameters.sortDirection,
        requestParameters.sortBy,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xem danh sách người nhận đã lưu
   * @param {PortalPayOutControllerApiGetAllSavedReceiversRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalPayOutControllerApi
   */
  public getAllSavedReceivers(
    requestParameters: PortalPayOutControllerApiGetAllSavedReceiversRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return PortalPayOutControllerApiFp(this.configuration)
      .getAllSavedReceivers(
        requestParameters.query,
        requestParameters.group,
        requestParameters.page,
        requestParameters.pageSize,
        requestParameters.sortDirection,
        requestParameters.sortBy,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xem danh sách lệnh chuyển tiền đơn
   * @param {PortalPayOutControllerApiGetAllSinglePayOutsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalPayOutControllerApi
   */
  public getAllSinglePayOuts(
    requestParameters: PortalPayOutControllerApiGetAllSinglePayOutsRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return PortalPayOutControllerApiFp(this.configuration)
      .getAllSinglePayOuts(
        requestParameters.fromAccount,
        requestParameters.toAccount,
        requestParameters.status,
        requestParameters.transactionId,
        requestParameters.createdFrom,
        requestParameters.createdTo,
        requestParameters.page,
        requestParameters.pageSize,
        requestParameters.sortDirection,
        requestParameters.sortBy,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Chi tiết lệnh chuyển lô
   * @param {PortalPayOutControllerApiGetBatchPayoutDetailRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalPayOutControllerApi
   */
  public getBatchPayoutDetail(
    requestParameters: PortalPayOutControllerApiGetBatchPayoutDetailRequest,
    options?: AxiosRequestConfig,
  ) {
    return PortalPayOutControllerApiFp(this.configuration)
      .getBatchPayoutDetail(requestParameters.payOutId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Chi tiết lệnh chuyển tiền
   * @param {PortalPayOutControllerApiGetSinglePayoutDetailRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalPayOutControllerApi
   */
  public getSinglePayoutDetail(
    requestParameters: PortalPayOutControllerApiGetSinglePayoutDetailRequest,
    options?: AxiosRequestConfig,
  ) {
    return PortalPayOutControllerApiFp(this.configuration)
      .getSinglePayoutDetail(requestParameters.payOutId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Chỉnh sửa người nhận
   * @param {PortalPayOutControllerApiUpdateReceiverRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalPayOutControllerApi
   */
  public updateReceiver(
    requestParameters: PortalPayOutControllerApiUpdateReceiverRequest,
    options?: AxiosRequestConfig,
  ) {
    return PortalPayOutControllerApiFp(this.configuration)
      .updateReceiver(
        requestParameters.receiverId,
        requestParameters.updateReceiverRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Upload file chuyển tiền lô
   * @param {PortalPayOutControllerApiUploadBatchFileRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalPayOutControllerApi
   */
  public uploadBatchFile(
    requestParameters: PortalPayOutControllerApiUploadBatchFileRequest,
    options?: AxiosRequestConfig,
  ) {
    return PortalPayOutControllerApiFp(this.configuration)
      .uploadBatchFile(requestParameters.file, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Duyệt PayOut step 2: Tiến hành check OTP
   * @param {PortalPayOutControllerApiVerifySinglePayOutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalPayOutControllerApi
   */
  public verifySinglePayOut(
    requestParameters: PortalPayOutControllerApiVerifySinglePayOutRequest,
    options?: AxiosRequestConfig,
  ) {
    return PortalPayOutControllerApiFp(this.configuration)
      .verifySinglePayOut(
        requestParameters.verifyOTPSinglePayOutRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PortalPayPageOrderControllerApi - axios parameter creator
 * @export
 */
export const PortalPayPageOrderControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Xem tất cả orders PayPage
     * @param {string} [id]
     * @param {string} [refId]
     * @param {string} [from]
     * @param {string} [to]
     * @param {'CREATED' | 'PAID' | 'CANCEL'} [status]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllOrders: async (
      id?: string,
      refId?: string,
      from?: string,
      to?: string,
      status?: "CREATED" | "PAID" | "CANCEL",
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/order/v1/getAll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (id !== undefined) {
        localVarQueryParameter["id"] = id;
      }

      if (refId !== undefined) {
        localVarQueryParameter["refId"] = refId;
      }

      if (from !== undefined) {
        localVarQueryParameter["from"] = from;
      }

      if (to !== undefined) {
        localVarQueryParameter["to"] = to;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter["sortDirection"] = sortDirection;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter["sortBy"] = sortBy;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xem chi tiết Order orders PayPage
     * @param {string} orderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrder: async (
      orderId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderId' is not null or undefined
      assertParamExists("getOrder", "orderId", orderId);
      const localVarPath = `/api/order/v1/getDetail/{orderId}`.replace(
        `{${"orderId"}}`,
        encodeURIComponent(String(orderId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy danh sách order item của order
     * @param {string} orderId
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrderItems: async (
      orderId: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderId' is not null or undefined
      assertParamExists("getOrderItems", "orderId", orderId);
      const localVarPath = `/api/order/v1/{orderId}/order-items`.replace(
        `{${"orderId"}}`,
        encodeURIComponent(String(orderId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Cập nhật trạng thái đơn hàng
     * @param {UpdateOrderStatusRequest} updateOrderStatusRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOrderStatus: async (
      updateOrderStatusRequest: UpdateOrderStatusRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateOrderStatusRequest' is not null or undefined
      assertParamExists(
        "updateOrderStatus",
        "updateOrderStatusRequest",
        updateOrderStatusRequest,
      );
      const localVarPath = `/api/order/v1/updateStatus`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateOrderStatusRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PortalPayPageOrderControllerApi - functional programming interface
 * @export
 */
export const PortalPayPageOrderControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    PortalPayPageOrderControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Xem tất cả orders PayPage
     * @param {string} [id]
     * @param {string} [refId]
     * @param {string} [from]
     * @param {string} [to]
     * @param {'CREATED' | 'PAID' | 'CANCEL'} [status]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllOrders(
      id?: string,
      refId?: string,
      from?: string,
      to?: string,
      status?: "CREATED" | "PAID" | "CANCEL",
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseOrderResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllOrders(
        id,
        refId,
        from,
        to,
        status,
        page,
        pageSize,
        sortDirection,
        sortBy,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Xem chi tiết Order orders PayPage
     * @param {string} orderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrder(
      orderId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseOrderDetailResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOrder(
        orderId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy danh sách order item của order
     * @param {string} orderId
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrderItems(
      orderId: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseOrderItemResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderItems(
        orderId,
        page,
        size,
        sort,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Cập nhật trạng thái đơn hàng
     * @param {UpdateOrderStatusRequest} updateOrderStatusRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateOrderStatus(
      updateOrderStatusRequest: UpdateOrderStatusRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateOrderStatus(
          updateOrderStatusRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PortalPayPageOrderControllerApi - factory interface
 * @export
 */
export const PortalPayPageOrderControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PortalPayPageOrderControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Xem tất cả orders PayPage
     * @param {string} [id]
     * @param {string} [refId]
     * @param {string} [from]
     * @param {string} [to]
     * @param {'CREATED' | 'PAID' | 'CANCEL'} [status]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllOrders(
      id?: string,
      refId?: string,
      from?: string,
      to?: string,
      status?: "CREATED" | "PAID" | "CANCEL",
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseOrderResponse> {
      return localVarFp
        .getAllOrders(
          id,
          refId,
          from,
          to,
          status,
          page,
          pageSize,
          sortDirection,
          sortBy,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xem chi tiết Order orders PayPage
     * @param {string} orderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrder(
      orderId: string,
      options?: any,
    ): AxiosPromise<ResponseBaseOrderDetailResponse> {
      return localVarFp
        .getOrder(orderId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy danh sách order item của order
     * @param {string} orderId
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrderItems(
      orderId: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseOrderItemResponse> {
      return localVarFp
        .getOrderItems(orderId, page, size, sort, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Cập nhật trạng thái đơn hàng
     * @param {UpdateOrderStatusRequest} updateOrderStatusRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOrderStatus(
      updateOrderStatusRequest: UpdateOrderStatusRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .updateOrderStatus(updateOrderStatusRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getAllOrders operation in PortalPayPageOrderControllerApi.
 * @export
 * @interface PortalPayPageOrderControllerApiGetAllOrdersRequest
 */
export interface PortalPayPageOrderControllerApiGetAllOrdersRequest {
  /**
   *
   * @type {string}
   * @memberof PortalPayPageOrderControllerApiGetAllOrders
   */
  readonly id?: string;

  /**
   *
   * @type {string}
   * @memberof PortalPayPageOrderControllerApiGetAllOrders
   */
  readonly refId?: string;

  /**
   *
   * @type {string}
   * @memberof PortalPayPageOrderControllerApiGetAllOrders
   */
  readonly from?: string;

  /**
   *
   * @type {string}
   * @memberof PortalPayPageOrderControllerApiGetAllOrders
   */
  readonly to?: string;

  /**
   *
   * @type {'CREATED' | 'PAID' | 'CANCEL'}
   * @memberof PortalPayPageOrderControllerApiGetAllOrders
   */
  readonly status?: "CREATED" | "PAID" | "CANCEL";

  /**
   *
   * @type {number}
   * @memberof PortalPayPageOrderControllerApiGetAllOrders
   */
  readonly page?: number;

  /**
   *
   * @type {number}
   * @memberof PortalPayPageOrderControllerApiGetAllOrders
   */
  readonly pageSize?: number;

  /**
   *
   * @type {'ASC' | 'DESC'}
   * @memberof PortalPayPageOrderControllerApiGetAllOrders
   */
  readonly sortDirection?: "ASC" | "DESC";

  /**
   *
   * @type {string}
   * @memberof PortalPayPageOrderControllerApiGetAllOrders
   */
  readonly sortBy?: string;
}

/**
 * Request parameters for getOrder operation in PortalPayPageOrderControllerApi.
 * @export
 * @interface PortalPayPageOrderControllerApiGetOrderRequest
 */
export interface PortalPayPageOrderControllerApiGetOrderRequest {
  /**
   *
   * @type {string}
   * @memberof PortalPayPageOrderControllerApiGetOrder
   */
  readonly orderId: string;
}

/**
 * Request parameters for getOrderItems operation in PortalPayPageOrderControllerApi.
 * @export
 * @interface PortalPayPageOrderControllerApiGetOrderItemsRequest
 */
export interface PortalPayPageOrderControllerApiGetOrderItemsRequest {
  /**
   *
   * @type {string}
   * @memberof PortalPayPageOrderControllerApiGetOrderItems
   */
  readonly orderId: string;

  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof PortalPayPageOrderControllerApiGetOrderItems
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof PortalPayPageOrderControllerApiGetOrderItems
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof PortalPayPageOrderControllerApiGetOrderItems
   */
  readonly sort?: Array<string>;
}

/**
 * Request parameters for updateOrderStatus operation in PortalPayPageOrderControllerApi.
 * @export
 * @interface PortalPayPageOrderControllerApiUpdateOrderStatusRequest
 */
export interface PortalPayPageOrderControllerApiUpdateOrderStatusRequest {
  /**
   *
   * @type {UpdateOrderStatusRequest}
   * @memberof PortalPayPageOrderControllerApiUpdateOrderStatus
   */
  readonly updateOrderStatusRequest: UpdateOrderStatusRequest;
}

/**
 * PortalPayPageOrderControllerApi - object-oriented interface
 * @export
 * @class PortalPayPageOrderControllerApi
 * @extends {BaseAPI}
 */
export class PortalPayPageOrderControllerApi extends BaseAPI {
  /**
   *
   * @summary Xem tất cả orders PayPage
   * @param {PortalPayPageOrderControllerApiGetAllOrdersRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalPayPageOrderControllerApi
   */
  public getAllOrders(
    requestParameters: PortalPayPageOrderControllerApiGetAllOrdersRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return PortalPayPageOrderControllerApiFp(this.configuration)
      .getAllOrders(
        requestParameters.id,
        requestParameters.refId,
        requestParameters.from,
        requestParameters.to,
        requestParameters.status,
        requestParameters.page,
        requestParameters.pageSize,
        requestParameters.sortDirection,
        requestParameters.sortBy,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xem chi tiết Order orders PayPage
   * @param {PortalPayPageOrderControllerApiGetOrderRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalPayPageOrderControllerApi
   */
  public getOrder(
    requestParameters: PortalPayPageOrderControllerApiGetOrderRequest,
    options?: AxiosRequestConfig,
  ) {
    return PortalPayPageOrderControllerApiFp(this.configuration)
      .getOrder(requestParameters.orderId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy danh sách order item của order
   * @param {PortalPayPageOrderControllerApiGetOrderItemsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalPayPageOrderControllerApi
   */
  public getOrderItems(
    requestParameters: PortalPayPageOrderControllerApiGetOrderItemsRequest,
    options?: AxiosRequestConfig,
  ) {
    return PortalPayPageOrderControllerApiFp(this.configuration)
      .getOrderItems(
        requestParameters.orderId,
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Cập nhật trạng thái đơn hàng
   * @param {PortalPayPageOrderControllerApiUpdateOrderStatusRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalPayPageOrderControllerApi
   */
  public updateOrderStatus(
    requestParameters: PortalPayPageOrderControllerApiUpdateOrderStatusRequest,
    options?: AxiosRequestConfig,
  ) {
    return PortalPayPageOrderControllerApiFp(this.configuration)
      .updateOrderStatus(requestParameters.updateOrderStatusRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PortalProductControllerApi - axios parameter creator
 * @export
 */
export const PortalProductControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Thêm nhiều sản phẩm vào nhóm sản phẩm
     * @param {AddProductsToCateRequest} addProductsToCateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addProductsToCategory: async (
      addProductsToCateRequest: AddProductsToCateRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'addProductsToCateRequest' is not null or undefined
      assertParamExists(
        "addProductsToCategory",
        "addProductsToCateRequest",
        addProductsToCateRequest,
      );
      const localVarPath = `/api/product/portal/v1/addProductsToCategory`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        addProductsToCateRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - ACTIVE: Cho phép, INACTIVE: Tạm ngưng
     * @summary Cho phép hoặc tạm ngưng sản phẩm
     * @param {number} id
     * @param {ChangeProductStatusRequest} changeProductStatusRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeProductStatus: async (
      id: number,
      changeProductStatusRequest: ChangeProductStatusRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("changeProductStatus", "id", id);
      // verify required parameter 'changeProductStatusRequest' is not null or undefined
      assertParamExists(
        "changeProductStatus",
        "changeProductStatusRequest",
        changeProductStatusRequest,
      );
      const localVarPath = `/api/product/portal/v1/changeStatus/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        changeProductStatusRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Kiểm tra trạng thái sản phẩm
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkProduct: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("checkProduct", "id", id);
      const localVarPath = `/api/product/portal/v1/check/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Tạo danh mục sản phẩm
     * @summary Tạo danh mục sản phẩm
     * @param {CreateCategoryRequest} createCategoryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCategory: async (
      createCategoryRequest: CreateCategoryRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createCategoryRequest' is not null or undefined
      assertParamExists(
        "createCategory",
        "createCategoryRequest",
        createCategoryRequest,
      );
      const localVarPath = `/api/product/portal/v1/category/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createCategoryRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Tạo sản phẩm
     * @summary Tạo sản phẩm
     * @param {CreateProductRequest} createProductRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createProduct: async (
      createProductRequest: CreateProductRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createProductRequest' is not null or undefined
      assertParamExists(
        "createProduct",
        "createProductRequest",
        createProductRequest,
      );
      const localVarPath = `/api/product/portal/v1/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createProductRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xóa danh mục sản phẩm
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCategory: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("deleteCategory", "id", id);
      const localVarPath =
        `/api/product/portal/v1/category/delete/{id}`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(id)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Xóa sản phẩm
     * @summary Xóa sản phẩm
     * @param {number} prodId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProduct: async (
      prodId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'prodId' is not null or undefined
      assertParamExists("deleteProduct", "prodId", prodId);
      const localVarPath = `/api/product/portal/v1/delete/{prodId}`.replace(
        `{${"prodId"}}`,
        encodeURIComponent(String(prodId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Danh sách danh mục sản phẩm
     * @summary Danh sách danh mục sản phẩm
     * @param {string} [query]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllCategories: async (
      query?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/product/portal/v1/category/getAll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (query !== undefined) {
        localVarQueryParameter["query"] = query;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Danh sách sản phẩm
     * @summary Danh sách sản phẩm
     * @param {number} [categoryId]
     * @param {string} [query]
     * @param {'STOCKING' | 'OUT_OF_STOCK' | 'PENDING'} [status]
     * @param {boolean} [haveCate]
     * @param {string} [createdFrom]
     * @param {string} [createdTo]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllProduct: async (
      categoryId?: number,
      query?: string,
      status?: "STOCKING" | "OUT_OF_STOCK" | "PENDING",
      haveCate?: boolean,
      createdFrom?: string,
      createdTo?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/product/portal/v1/getAll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (categoryId !== undefined) {
        localVarQueryParameter["categoryId"] = categoryId;
      }

      if (query !== undefined) {
        localVarQueryParameter["query"] = query;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (haveCate !== undefined) {
        localVarQueryParameter["haveCate"] = haveCate;
      }

      if (createdFrom !== undefined) {
        localVarQueryParameter["createdFrom"] = createdFrom;
      }

      if (createdTo !== undefined) {
        localVarQueryParameter["createdTo"] = createdTo;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter["sortDirection"] = sortDirection;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter["sortBy"] = sortBy;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy chi tiết category
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDetailCategory: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getDetailCategory", "id", id);
      const localVarPath =
        `/api/product/portal/v1/category/detail/{id}`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(id)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Xem chi tiết sản phẩm
     * @summary Xem chi tiết sản phẩm
     * @param {number} prodId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductDetail: async (
      prodId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'prodId' is not null or undefined
      assertParamExists("getProductDetail", "prodId", prodId);
      const localVarPath = `/api/product/portal/v1/getDetail/{prodId}`.replace(
        `{${"prodId"}}`,
        encodeURIComponent(String(prodId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Chỉnh sửa mục sản phẩm
     * @summary Chỉnh sửa danh mục sản phẩm
     * @param {number} cateId
     * @param {UpdateCategoryRequest} updateCategoryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCategory: async (
      cateId: number,
      updateCategoryRequest: UpdateCategoryRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'cateId' is not null or undefined
      assertParamExists("updateCategory", "cateId", cateId);
      // verify required parameter 'updateCategoryRequest' is not null or undefined
      assertParamExists(
        "updateCategory",
        "updateCategoryRequest",
        updateCategoryRequest,
      );
      const localVarPath =
        `/api/product/portal/v1/category/update/{cateId}`.replace(
          `{${"cateId"}}`,
          encodeURIComponent(String(cateId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateCategoryRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Chỉnh sửa sản phẩm
     * @summary Chỉnh sửa sản phẩm
     * @param {number} prodId
     * @param {UpdateProductRequest} updateProductRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProduct: async (
      prodId: number,
      updateProductRequest: UpdateProductRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'prodId' is not null or undefined
      assertParamExists("updateProduct", "prodId", prodId);
      // verify required parameter 'updateProductRequest' is not null or undefined
      assertParamExists(
        "updateProduct",
        "updateProductRequest",
        updateProductRequest,
      );
      const localVarPath = `/api/product/portal/v1/update/{prodId}`.replace(
        `{${"prodId"}}`,
        encodeURIComponent(String(prodId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateProductRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PortalProductControllerApi - functional programming interface
 * @export
 */
export const PortalProductControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    PortalProductControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Thêm nhiều sản phẩm vào nhóm sản phẩm
     * @param {AddProductsToCateRequest} addProductsToCateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addProductsToCategory(
      addProductsToCateRequest: AddProductsToCateRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.addProductsToCategory(
          addProductsToCateRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - ACTIVE: Cho phép, INACTIVE: Tạm ngưng
     * @summary Cho phép hoặc tạm ngưng sản phẩm
     * @param {number} id
     * @param {ChangeProductStatusRequest} changeProductStatusRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async changeProductStatus(
      id: number,
      changeProductStatusRequest: ChangeProductStatusRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseProductResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.changeProductStatus(
          id,
          changeProductStatusRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Kiểm tra trạng thái sản phẩm
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkProduct(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseCheckProductResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.checkProduct(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Tạo danh mục sản phẩm
     * @summary Tạo danh mục sản phẩm
     * @param {CreateCategoryRequest} createCategoryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createCategory(
      createCategoryRequest: CreateCategoryRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseCategoryResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createCategory(
        createCategoryRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Tạo sản phẩm
     * @summary Tạo sản phẩm
     * @param {CreateProductRequest} createProductRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createProduct(
      createProductRequest: CreateProductRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseProductResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createProduct(
        createProductRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Xóa danh mục sản phẩm
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteCategory(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCategory(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Xóa sản phẩm
     * @summary Xóa sản phẩm
     * @param {number} prodId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteProduct(
      prodId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseProductResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProduct(
        prodId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Danh sách danh mục sản phẩm
     * @summary Danh sách danh mục sản phẩm
     * @param {string} [query]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllCategories(
      query?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseCategoryResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllCategories(
          query,
          page,
          size,
          sort,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Danh sách sản phẩm
     * @summary Danh sách sản phẩm
     * @param {number} [categoryId]
     * @param {string} [query]
     * @param {'STOCKING' | 'OUT_OF_STOCK' | 'PENDING'} [status]
     * @param {boolean} [haveCate]
     * @param {string} [createdFrom]
     * @param {string} [createdTo]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllProduct(
      categoryId?: number,
      query?: string,
      status?: "STOCKING" | "OUT_OF_STOCK" | "PENDING",
      haveCate?: boolean,
      createdFrom?: string,
      createdTo?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseProductResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllProduct(
        categoryId,
        query,
        status,
        haveCate,
        createdFrom,
        createdTo,
        page,
        pageSize,
        sortDirection,
        sortBy,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy chi tiết category
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDetailCategory(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseCategoryResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getDetailCategory(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Xem chi tiết sản phẩm
     * @summary Xem chi tiết sản phẩm
     * @param {number} prodId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProductDetail(
      prodId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseProductResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getProductDetail(prodId, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Chỉnh sửa mục sản phẩm
     * @summary Chỉnh sửa danh mục sản phẩm
     * @param {number} cateId
     * @param {UpdateCategoryRequest} updateCategoryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateCategory(
      cateId: number,
      updateCategoryRequest: UpdateCategoryRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseCategoryResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateCategory(
        cateId,
        updateCategoryRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Chỉnh sửa sản phẩm
     * @summary Chỉnh sửa sản phẩm
     * @param {number} prodId
     * @param {UpdateProductRequest} updateProductRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateProduct(
      prodId: number,
      updateProductRequest: UpdateProductRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseProductResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateProduct(
        prodId,
        updateProductRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PortalProductControllerApi - factory interface
 * @export
 */
export const PortalProductControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PortalProductControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Thêm nhiều sản phẩm vào nhóm sản phẩm
     * @param {AddProductsToCateRequest} addProductsToCateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addProductsToCategory(
      addProductsToCateRequest: AddProductsToCateRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .addProductsToCategory(addProductsToCateRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - ACTIVE: Cho phép, INACTIVE: Tạm ngưng
     * @summary Cho phép hoặc tạm ngưng sản phẩm
     * @param {number} id
     * @param {ChangeProductStatusRequest} changeProductStatusRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeProductStatus(
      id: number,
      changeProductStatusRequest: ChangeProductStatusRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseProductResponse> {
      return localVarFp
        .changeProductStatus(id, changeProductStatusRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Kiểm tra trạng thái sản phẩm
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkProduct(
      id: number,
      options?: any,
    ): AxiosPromise<ResponseBaseCheckProductResponse> {
      return localVarFp
        .checkProduct(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Tạo danh mục sản phẩm
     * @summary Tạo danh mục sản phẩm
     * @param {CreateCategoryRequest} createCategoryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCategory(
      createCategoryRequest: CreateCategoryRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseCategoryResponse> {
      return localVarFp
        .createCategory(createCategoryRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Tạo sản phẩm
     * @summary Tạo sản phẩm
     * @param {CreateProductRequest} createProductRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createProduct(
      createProductRequest: CreateProductRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseProductResponse> {
      return localVarFp
        .createProduct(createProductRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xóa danh mục sản phẩm
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCategory(
      id: number,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .deleteCategory(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Xóa sản phẩm
     * @summary Xóa sản phẩm
     * @param {number} prodId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProduct(
      prodId: number,
      options?: any,
    ): AxiosPromise<ResponseBaseProductResponse> {
      return localVarFp
        .deleteProduct(prodId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Danh sách danh mục sản phẩm
     * @summary Danh sách danh mục sản phẩm
     * @param {string} [query]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllCategories(
      query?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseCategoryResponse> {
      return localVarFp
        .getAllCategories(query, page, size, sort, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Danh sách sản phẩm
     * @summary Danh sách sản phẩm
     * @param {number} [categoryId]
     * @param {string} [query]
     * @param {'STOCKING' | 'OUT_OF_STOCK' | 'PENDING'} [status]
     * @param {boolean} [haveCate]
     * @param {string} [createdFrom]
     * @param {string} [createdTo]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllProduct(
      categoryId?: number,
      query?: string,
      status?: "STOCKING" | "OUT_OF_STOCK" | "PENDING",
      haveCate?: boolean,
      createdFrom?: string,
      createdTo?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseProductResponse> {
      return localVarFp
        .getAllProduct(
          categoryId,
          query,
          status,
          haveCate,
          createdFrom,
          createdTo,
          page,
          pageSize,
          sortDirection,
          sortBy,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy chi tiết category
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDetailCategory(
      id: number,
      options?: any,
    ): AxiosPromise<ResponseBaseCategoryResponse> {
      return localVarFp
        .getDetailCategory(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Xem chi tiết sản phẩm
     * @summary Xem chi tiết sản phẩm
     * @param {number} prodId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductDetail(
      prodId: number,
      options?: any,
    ): AxiosPromise<ResponseBaseProductResponse> {
      return localVarFp
        .getProductDetail(prodId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Chỉnh sửa mục sản phẩm
     * @summary Chỉnh sửa danh mục sản phẩm
     * @param {number} cateId
     * @param {UpdateCategoryRequest} updateCategoryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCategory(
      cateId: number,
      updateCategoryRequest: UpdateCategoryRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseCategoryResponse> {
      return localVarFp
        .updateCategory(cateId, updateCategoryRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Chỉnh sửa sản phẩm
     * @summary Chỉnh sửa sản phẩm
     * @param {number} prodId
     * @param {UpdateProductRequest} updateProductRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProduct(
      prodId: number,
      updateProductRequest: UpdateProductRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseProductResponse> {
      return localVarFp
        .updateProduct(prodId, updateProductRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for addProductsToCategory operation in PortalProductControllerApi.
 * @export
 * @interface PortalProductControllerApiAddProductsToCategoryRequest
 */
export interface PortalProductControllerApiAddProductsToCategoryRequest {
  /**
   *
   * @type {AddProductsToCateRequest}
   * @memberof PortalProductControllerApiAddProductsToCategory
   */
  readonly addProductsToCateRequest: AddProductsToCateRequest;
}

/**
 * Request parameters for changeProductStatus operation in PortalProductControllerApi.
 * @export
 * @interface PortalProductControllerApiChangeProductStatusRequest
 */
export interface PortalProductControllerApiChangeProductStatusRequest {
  /**
   *
   * @type {number}
   * @memberof PortalProductControllerApiChangeProductStatus
   */
  readonly id: number;

  /**
   *
   * @type {ChangeProductStatusRequest}
   * @memberof PortalProductControllerApiChangeProductStatus
   */
  readonly changeProductStatusRequest: ChangeProductStatusRequest;
}

/**
 * Request parameters for checkProduct operation in PortalProductControllerApi.
 * @export
 * @interface PortalProductControllerApiCheckProductRequest
 */
export interface PortalProductControllerApiCheckProductRequest {
  /**
   *
   * @type {number}
   * @memberof PortalProductControllerApiCheckProduct
   */
  readonly id: number;
}

/**
 * Request parameters for createCategory operation in PortalProductControllerApi.
 * @export
 * @interface PortalProductControllerApiCreateCategoryRequest
 */
export interface PortalProductControllerApiCreateCategoryRequest {
  /**
   *
   * @type {CreateCategoryRequest}
   * @memberof PortalProductControllerApiCreateCategory
   */
  readonly createCategoryRequest: CreateCategoryRequest;
}

/**
 * Request parameters for createProduct operation in PortalProductControllerApi.
 * @export
 * @interface PortalProductControllerApiCreateProductRequest
 */
export interface PortalProductControllerApiCreateProductRequest {
  /**
   *
   * @type {CreateProductRequest}
   * @memberof PortalProductControllerApiCreateProduct
   */
  readonly createProductRequest: CreateProductRequest;
}

/**
 * Request parameters for deleteCategory operation in PortalProductControllerApi.
 * @export
 * @interface PortalProductControllerApiDeleteCategoryRequest
 */
export interface PortalProductControllerApiDeleteCategoryRequest {
  /**
   *
   * @type {number}
   * @memberof PortalProductControllerApiDeleteCategory
   */
  readonly id: number;
}

/**
 * Request parameters for deleteProduct operation in PortalProductControllerApi.
 * @export
 * @interface PortalProductControllerApiDeleteProductRequest
 */
export interface PortalProductControllerApiDeleteProductRequest {
  /**
   *
   * @type {number}
   * @memberof PortalProductControllerApiDeleteProduct
   */
  readonly prodId: number;
}

/**
 * Request parameters for getAllCategories operation in PortalProductControllerApi.
 * @export
 * @interface PortalProductControllerApiGetAllCategoriesRequest
 */
export interface PortalProductControllerApiGetAllCategoriesRequest {
  /**
   *
   * @type {string}
   * @memberof PortalProductControllerApiGetAllCategories
   */
  readonly query?: string;

  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof PortalProductControllerApiGetAllCategories
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof PortalProductControllerApiGetAllCategories
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof PortalProductControllerApiGetAllCategories
   */
  readonly sort?: Array<string>;
}

/**
 * Request parameters for getAllProduct operation in PortalProductControllerApi.
 * @export
 * @interface PortalProductControllerApiGetAllProductRequest
 */
export interface PortalProductControllerApiGetAllProductRequest {
  /**
   *
   * @type {number}
   * @memberof PortalProductControllerApiGetAllProduct
   */
  readonly categoryId?: number;

  /**
   *
   * @type {string}
   * @memberof PortalProductControllerApiGetAllProduct
   */
  readonly query?: string;

  /**
   *
   * @type {'STOCKING' | 'OUT_OF_STOCK' | 'PENDING'}
   * @memberof PortalProductControllerApiGetAllProduct
   */
  readonly status?: "STOCKING" | "OUT_OF_STOCK" | "PENDING";

  /**
   *
   * @type {boolean}
   * @memberof PortalProductControllerApiGetAllProduct
   */
  readonly haveCate?: boolean;

  /**
   *
   * @type {string}
   * @memberof PortalProductControllerApiGetAllProduct
   */
  readonly createdFrom?: string;

  /**
   *
   * @type {string}
   * @memberof PortalProductControllerApiGetAllProduct
   */
  readonly createdTo?: string;

  /**
   *
   * @type {number}
   * @memberof PortalProductControllerApiGetAllProduct
   */
  readonly page?: number;

  /**
   *
   * @type {number}
   * @memberof PortalProductControllerApiGetAllProduct
   */
  readonly pageSize?: number;

  /**
   *
   * @type {'ASC' | 'DESC'}
   * @memberof PortalProductControllerApiGetAllProduct
   */
  readonly sortDirection?: "ASC" | "DESC";

  /**
   *
   * @type {string}
   * @memberof PortalProductControllerApiGetAllProduct
   */
  readonly sortBy?: string;
}

/**
 * Request parameters for getDetailCategory operation in PortalProductControllerApi.
 * @export
 * @interface PortalProductControllerApiGetDetailCategoryRequest
 */
export interface PortalProductControllerApiGetDetailCategoryRequest {
  /**
   *
   * @type {number}
   * @memberof PortalProductControllerApiGetDetailCategory
   */
  readonly id: number;
}

/**
 * Request parameters for getProductDetail operation in PortalProductControllerApi.
 * @export
 * @interface PortalProductControllerApiGetProductDetailRequest
 */
export interface PortalProductControllerApiGetProductDetailRequest {
  /**
   *
   * @type {number}
   * @memberof PortalProductControllerApiGetProductDetail
   */
  readonly prodId: number;
}

/**
 * Request parameters for updateCategory operation in PortalProductControllerApi.
 * @export
 * @interface PortalProductControllerApiUpdateCategoryRequest
 */
export interface PortalProductControllerApiUpdateCategoryRequest {
  /**
   *
   * @type {number}
   * @memberof PortalProductControllerApiUpdateCategory
   */
  readonly cateId: number;

  /**
   *
   * @type {UpdateCategoryRequest}
   * @memberof PortalProductControllerApiUpdateCategory
   */
  readonly updateCategoryRequest: UpdateCategoryRequest;
}

/**
 * Request parameters for updateProduct operation in PortalProductControllerApi.
 * @export
 * @interface PortalProductControllerApiUpdateProductRequest
 */
export interface PortalProductControllerApiUpdateProductRequest {
  /**
   *
   * @type {number}
   * @memberof PortalProductControllerApiUpdateProduct
   */
  readonly prodId: number;

  /**
   *
   * @type {UpdateProductRequest}
   * @memberof PortalProductControllerApiUpdateProduct
   */
  readonly updateProductRequest: UpdateProductRequest;
}

/**
 * PortalProductControllerApi - object-oriented interface
 * @export
 * @class PortalProductControllerApi
 * @extends {BaseAPI}
 */
export class PortalProductControllerApi extends BaseAPI {
  /**
   *
   * @summary Thêm nhiều sản phẩm vào nhóm sản phẩm
   * @param {PortalProductControllerApiAddProductsToCategoryRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalProductControllerApi
   */
  public addProductsToCategory(
    requestParameters: PortalProductControllerApiAddProductsToCategoryRequest,
    options?: AxiosRequestConfig,
  ) {
    return PortalProductControllerApiFp(this.configuration)
      .addProductsToCategory(
        requestParameters.addProductsToCateRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - ACTIVE: Cho phép, INACTIVE: Tạm ngưng
   * @summary Cho phép hoặc tạm ngưng sản phẩm
   * @param {PortalProductControllerApiChangeProductStatusRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalProductControllerApi
   */
  public changeProductStatus(
    requestParameters: PortalProductControllerApiChangeProductStatusRequest,
    options?: AxiosRequestConfig,
  ) {
    return PortalProductControllerApiFp(this.configuration)
      .changeProductStatus(
        requestParameters.id,
        requestParameters.changeProductStatusRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Kiểm tra trạng thái sản phẩm
   * @param {PortalProductControllerApiCheckProductRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalProductControllerApi
   */
  public checkProduct(
    requestParameters: PortalProductControllerApiCheckProductRequest,
    options?: AxiosRequestConfig,
  ) {
    return PortalProductControllerApiFp(this.configuration)
      .checkProduct(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Tạo danh mục sản phẩm
   * @summary Tạo danh mục sản phẩm
   * @param {PortalProductControllerApiCreateCategoryRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalProductControllerApi
   */
  public createCategory(
    requestParameters: PortalProductControllerApiCreateCategoryRequest,
    options?: AxiosRequestConfig,
  ) {
    return PortalProductControllerApiFp(this.configuration)
      .createCategory(requestParameters.createCategoryRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Tạo sản phẩm
   * @summary Tạo sản phẩm
   * @param {PortalProductControllerApiCreateProductRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalProductControllerApi
   */
  public createProduct(
    requestParameters: PortalProductControllerApiCreateProductRequest,
    options?: AxiosRequestConfig,
  ) {
    return PortalProductControllerApiFp(this.configuration)
      .createProduct(requestParameters.createProductRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xóa danh mục sản phẩm
   * @param {PortalProductControllerApiDeleteCategoryRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalProductControllerApi
   */
  public deleteCategory(
    requestParameters: PortalProductControllerApiDeleteCategoryRequest,
    options?: AxiosRequestConfig,
  ) {
    return PortalProductControllerApiFp(this.configuration)
      .deleteCategory(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Xóa sản phẩm
   * @summary Xóa sản phẩm
   * @param {PortalProductControllerApiDeleteProductRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalProductControllerApi
   */
  public deleteProduct(
    requestParameters: PortalProductControllerApiDeleteProductRequest,
    options?: AxiosRequestConfig,
  ) {
    return PortalProductControllerApiFp(this.configuration)
      .deleteProduct(requestParameters.prodId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Danh sách danh mục sản phẩm
   * @summary Danh sách danh mục sản phẩm
   * @param {PortalProductControllerApiGetAllCategoriesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalProductControllerApi
   */
  public getAllCategories(
    requestParameters: PortalProductControllerApiGetAllCategoriesRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return PortalProductControllerApiFp(this.configuration)
      .getAllCategories(
        requestParameters.query,
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Danh sách sản phẩm
   * @summary Danh sách sản phẩm
   * @param {PortalProductControllerApiGetAllProductRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalProductControllerApi
   */
  public getAllProduct(
    requestParameters: PortalProductControllerApiGetAllProductRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return PortalProductControllerApiFp(this.configuration)
      .getAllProduct(
        requestParameters.categoryId,
        requestParameters.query,
        requestParameters.status,
        requestParameters.haveCate,
        requestParameters.createdFrom,
        requestParameters.createdTo,
        requestParameters.page,
        requestParameters.pageSize,
        requestParameters.sortDirection,
        requestParameters.sortBy,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy chi tiết category
   * @param {PortalProductControllerApiGetDetailCategoryRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalProductControllerApi
   */
  public getDetailCategory(
    requestParameters: PortalProductControllerApiGetDetailCategoryRequest,
    options?: AxiosRequestConfig,
  ) {
    return PortalProductControllerApiFp(this.configuration)
      .getDetailCategory(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Xem chi tiết sản phẩm
   * @summary Xem chi tiết sản phẩm
   * @param {PortalProductControllerApiGetProductDetailRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalProductControllerApi
   */
  public getProductDetail(
    requestParameters: PortalProductControllerApiGetProductDetailRequest,
    options?: AxiosRequestConfig,
  ) {
    return PortalProductControllerApiFp(this.configuration)
      .getProductDetail(requestParameters.prodId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Chỉnh sửa mục sản phẩm
   * @summary Chỉnh sửa danh mục sản phẩm
   * @param {PortalProductControllerApiUpdateCategoryRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalProductControllerApi
   */
  public updateCategory(
    requestParameters: PortalProductControllerApiUpdateCategoryRequest,
    options?: AxiosRequestConfig,
  ) {
    return PortalProductControllerApiFp(this.configuration)
      .updateCategory(
        requestParameters.cateId,
        requestParameters.updateCategoryRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Chỉnh sửa sản phẩm
   * @summary Chỉnh sửa sản phẩm
   * @param {PortalProductControllerApiUpdateProductRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalProductControllerApi
   */
  public updateProduct(
    requestParameters: PortalProductControllerApiUpdateProductRequest,
    options?: AxiosRequestConfig,
  ) {
    return PortalProductControllerApiFp(this.configuration)
      .updateProduct(
        requestParameters.prodId,
        requestParameters.updateProductRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PortalRoleControllerApi - axios parameter creator
 * @export
 */
export const PortalRoleControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * - Lấy tất tả action
     * @summary Lấy tất tả action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllPortalPermissionActions: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/roleGroup/portal/v1/getAllPermissionActions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Lấy tất tả permission và action
     * @summary Lấy tất tả permission và action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllPortalPermissions: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/roleGroup/portal/v1/getPermissions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - Lấy tất cả nhóm quyền của đối tác
     * @summary Lấy tất cả nhóm quyền của đối tác
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllPortalRoleGroups: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/roleGroup/portal/v1/getAll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy danh sách quyền hiện tại của User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCurrentPermission: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/roleGroup/portal/v1/getCurrentPermission`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PortalRoleControllerApi - functional programming interface
 * @export
 */
export const PortalRoleControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    PortalRoleControllerApiAxiosParamCreator(configuration);
  return {
    /**
     * - Lấy tất tả action
     * @summary Lấy tất tả action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllPortalPermissionActions(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetAllPortalPermissionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllPortalPermissionActions(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Lấy tất tả permission và action
     * @summary Lấy tất tả permission và action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllPortalPermissions(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetAllPermissionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllPortalPermissions(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - Lấy tất cả nhóm quyền của đối tác
     * @summary Lấy tất cả nhóm quyền của đối tác
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllPortalRoleGroups(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePortalGetAllRoleGroupResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllPortalRoleGroups(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy danh sách quyền hiện tại của User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCurrentPermission(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseRoleGroupDetailResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCurrentPermission(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PortalRoleControllerApi - factory interface
 * @export
 */
export const PortalRoleControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PortalRoleControllerApiFp(configuration);
  return {
    /**
     * - Lấy tất tả action
     * @summary Lấy tất tả action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllPortalPermissionActions(
      options?: any,
    ): AxiosPromise<ResponseBaseGetAllPortalPermissionResponse> {
      return localVarFp
        .getAllPortalPermissionActions(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Lấy tất tả permission và action
     * @summary Lấy tất tả permission và action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllPortalPermissions(
      options?: any,
    ): AxiosPromise<ResponseBaseGetAllPermissionResponse> {
      return localVarFp
        .getAllPortalPermissions(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - Lấy tất cả nhóm quyền của đối tác
     * @summary Lấy tất cả nhóm quyền của đối tác
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllPortalRoleGroups(
      options?: any,
    ): AxiosPromise<ResponseBasePortalGetAllRoleGroupResponse> {
      return localVarFp
        .getAllPortalRoleGroups(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy danh sách quyền hiện tại của User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCurrentPermission(
      options?: any,
    ): AxiosPromise<ResponseBaseRoleGroupDetailResponse> {
      return localVarFp
        .getCurrentPermission(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * PortalRoleControllerApi - object-oriented interface
 * @export
 * @class PortalRoleControllerApi
 * @extends {BaseAPI}
 */
export class PortalRoleControllerApi extends BaseAPI {
  /**
   * - Lấy tất tả action
   * @summary Lấy tất tả action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalRoleControllerApi
   */
  public getAllPortalPermissionActions(options?: AxiosRequestConfig) {
    return PortalRoleControllerApiFp(this.configuration)
      .getAllPortalPermissionActions(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Lấy tất tả permission và action
   * @summary Lấy tất tả permission và action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalRoleControllerApi
   */
  public getAllPortalPermissions(options?: AxiosRequestConfig) {
    return PortalRoleControllerApiFp(this.configuration)
      .getAllPortalPermissions(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - Lấy tất cả nhóm quyền của đối tác
   * @summary Lấy tất cả nhóm quyền của đối tác
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalRoleControllerApi
   */
  public getAllPortalRoleGroups(options?: AxiosRequestConfig) {
    return PortalRoleControllerApiFp(this.configuration)
      .getAllPortalRoleGroups(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy danh sách quyền hiện tại của User
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalRoleControllerApi
   */
  public getCurrentPermission(options?: AxiosRequestConfig) {
    return PortalRoleControllerApiFp(this.configuration)
      .getCurrentPermission(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PortalTransactionControllerApi - axios parameter creator
 * @export
 */
export const PortalTransactionControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Export transaction ra file Excel
     * @param {string} [keyword]
     * @param {'NONE' | 'CREATED' | 'SUCCESS' | 'CANCELED' | 'FAIL' | 'TIMEOUT' | 'PENDING'} [status]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {string} [transactionId]
     * @param {string} [refTransactionId]
     * @param {string} [merchantId]
     * @param {string} [merchantCode]
     * @param {boolean} [isStatistic]
     * @param {'PAY_LINK' | 'PAY_PAGE' | 'PAY_BUTTON' | 'PAY_BOX' | 'PAY_POS' | 'PAY_SUBSCRIPTION' | 'PAY_OUT' | 'STATIC_VIRTUAL_ACCOUNT'} [type]
     * @param {'VIET_QR' | 'ATM_CARD' | 'BANKING'} [paymentType]
     * @param {string} [bankAccountId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportToExcel: async (
      keyword?: string,
      status?:
        | "NONE"
        | "CREATED"
        | "SUCCESS"
        | "CANCELED"
        | "FAIL"
        | "TIMEOUT"
        | "PENDING",
      fromDate?: string,
      toDate?: string,
      transactionId?: string,
      refTransactionId?: string,
      merchantId?: string,
      merchantCode?: string,
      isStatistic?: boolean,
      type?:
        | "PAY_LINK"
        | "PAY_PAGE"
        | "PAY_BUTTON"
        | "PAY_BOX"
        | "PAY_POS"
        | "PAY_SUBSCRIPTION"
        | "PAY_OUT"
        | "STATIC_VIRTUAL_ACCOUNT",
      paymentType?: "VIET_QR" | "ATM_CARD" | "BANKING",
      bankAccountId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/transaction/portal/v1/export`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (keyword !== undefined) {
        localVarQueryParameter["keyword"] = keyword;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (fromDate !== undefined) {
        localVarQueryParameter["fromDate"] = fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter["toDate"] = toDate;
      }

      if (transactionId !== undefined) {
        localVarQueryParameter["transactionId"] = transactionId;
      }

      if (refTransactionId !== undefined) {
        localVarQueryParameter["refTransactionId"] = refTransactionId;
      }

      if (merchantId !== undefined) {
        localVarQueryParameter["merchantId"] = merchantId;
      }

      if (merchantCode !== undefined) {
        localVarQueryParameter["merchantCode"] = merchantCode;
      }

      if (isStatistic !== undefined) {
        localVarQueryParameter["isStatistic"] = isStatistic;
      }

      if (type !== undefined) {
        localVarQueryParameter["type"] = type;
      }

      if (paymentType !== undefined) {
        localVarQueryParameter["paymentType"] = paymentType;
      }

      if (bankAccountId !== undefined) {
        localVarQueryParameter["bankAccountId"] = bankAccountId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Excel export giao dịch sao kê từ ngân hàng
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {'DAY_7' | 'MONTH_1' | 'MONTH_2' | 'MONTH_4'} [period]
     * @param {string} [accountNo]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportToExcelFromBank: async (
      fromDate?: string,
      toDate?: string,
      period?: "DAY_7" | "MONTH_1" | "MONTH_2" | "MONTH_4",
      accountNo?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/transaction/portal/v1/bankTransaction/export`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (fromDate !== undefined) {
        localVarQueryParameter["fromDate"] =
          (fromDate as any) instanceof Date
            ? (fromDate as any).toISOString()
            : fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter["toDate"] =
          (toDate as any) instanceof Date
            ? (toDate as any).toISOString()
            : toDate;
      }

      if (period !== undefined) {
        localVarQueryParameter["period"] = period;
      }

      if (accountNo !== undefined) {
        localVarQueryParameter["accountNo"] = accountNo;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get toàn bộ transaction sao kê từ ngân hàng
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {'DAY_7' | 'MONTH_1' | 'MONTH_2' | 'MONTH_4'} [period]
     * @param {string} [accountNo]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllFromBank: async (
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      fromDate?: string,
      toDate?: string,
      period?: "DAY_7" | "MONTH_1" | "MONTH_2" | "MONTH_4",
      accountNo?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/transaction/portal/v1/bankTransaction/getAll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter["sortDirection"] = sortDirection;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter["sortBy"] = sortBy;
      }

      if (fromDate !== undefined) {
        localVarQueryParameter["fromDate"] =
          (fromDate as any) instanceof Date
            ? (fromDate as any).toISOString()
            : fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter["toDate"] =
          (toDate as any) instanceof Date
            ? (toDate as any).toISOString()
            : toDate;
      }

      if (period !== undefined) {
        localVarQueryParameter["period"] = period;
      }

      if (accountNo !== undefined) {
        localVarQueryParameter["accountNo"] = accountNo;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get toàn bộ transaction
     * @param {number} isStatistic
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {string} [transactionId]
     * @param {string} [refTransactionId]
     * @param {'NONE' | 'CREATED' | 'SUCCESS' | 'CANCELED' | 'FAIL' | 'TIMEOUT' | 'PENDING'} [status]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {string} [keyword]
     * @param {string} [payboxDeviceId]
     * @param {'PAY_LINK' | 'PAY_PAGE' | 'PAY_BUTTON' | 'PAY_BOX' | 'PAY_POS' | 'PAY_SUBSCRIPTION' | 'PAY_OUT' | 'STATIC_VIRTUAL_ACCOUNT'} [type]
     * @param {'VIET_QR' | 'ATM_CARD' | 'BANKING'} [paymentType]
     * @param {string} [bankAccountId]
     * @param {string} [bankAccountNo]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllTransactions: async (
      isStatistic: number,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      transactionId?: string,
      refTransactionId?: string,
      status?:
        | "NONE"
        | "CREATED"
        | "SUCCESS"
        | "CANCELED"
        | "FAIL"
        | "TIMEOUT"
        | "PENDING",
      fromDate?: string,
      toDate?: string,
      keyword?: string,
      payboxDeviceId?: string,
      type?:
        | "PAY_LINK"
        | "PAY_PAGE"
        | "PAY_BUTTON"
        | "PAY_BOX"
        | "PAY_POS"
        | "PAY_SUBSCRIPTION"
        | "PAY_OUT"
        | "STATIC_VIRTUAL_ACCOUNT",
      paymentType?: "VIET_QR" | "ATM_CARD" | "BANKING",
      bankAccountId?: string,
      bankAccountNo?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'isStatistic' is not null or undefined
      assertParamExists("getAllTransactions", "isStatistic", isStatistic);
      const localVarPath = `/api/transaction/portal/v1/getAll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter["sortDirection"] = sortDirection;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter["sortBy"] = sortBy;
      }

      if (transactionId !== undefined) {
        localVarQueryParameter["transactionId"] = transactionId;
      }

      if (refTransactionId !== undefined) {
        localVarQueryParameter["refTransactionId"] = refTransactionId;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (fromDate !== undefined) {
        localVarQueryParameter["fromDate"] = fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter["toDate"] = toDate;
      }

      if (keyword !== undefined) {
        localVarQueryParameter["keyword"] = keyword;
      }

      if (payboxDeviceId !== undefined) {
        localVarQueryParameter["payboxDeviceId"] = payboxDeviceId;
      }

      if (type !== undefined) {
        localVarQueryParameter["type"] = type;
      }

      if (paymentType !== undefined) {
        localVarQueryParameter["paymentType"] = paymentType;
      }

      if (bankAccountId !== undefined) {
        localVarQueryParameter["bankAccountId"] = bankAccountId;
      }

      if (bankAccountNo !== undefined) {
        localVarQueryParameter["bankAccountNo"] = bankAccountNo;
      }

      if (isStatistic !== undefined) {
        localVarQueryParameter["isStatistic"] = isStatistic;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Các thông tin thống kê
     * @param {'SUCCESS' | 'FAIL'} [status]
     * @param {'PAY_LINK' | 'PAY_PAGE' | 'PAY_BUTTON' | 'PAY_BOX' | 'PAY_POS' | 'PAY_SUBSCRIPTION' | 'PAY_OUT' | 'STATIC_VIRTUAL_ACCOUNT'} [type]
     * @param {'VIET_QR' | 'ATM_CARD' | 'BANKING'} [paymentType]
     * @param {string} [bankAccountId]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStatisticInfo: async (
      status?: "SUCCESS" | "FAIL",
      type?:
        | "PAY_LINK"
        | "PAY_PAGE"
        | "PAY_BUTTON"
        | "PAY_BOX"
        | "PAY_POS"
        | "PAY_SUBSCRIPTION"
        | "PAY_OUT"
        | "STATIC_VIRTUAL_ACCOUNT",
      paymentType?: "VIET_QR" | "ATM_CARD" | "BANKING",
      bankAccountId?: string,
      fromDate?: string,
      toDate?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/transaction/portal/v1/getStatisticInfo`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (type !== undefined) {
        localVarQueryParameter["type"] = type;
      }

      if (paymentType !== undefined) {
        localVarQueryParameter["paymentType"] = paymentType;
      }

      if (bankAccountId !== undefined) {
        localVarQueryParameter["bankAccountId"] = bankAccountId;
      }

      if (fromDate !== undefined) {
        localVarQueryParameter["fromDate"] =
          (fromDate as any) instanceof Date
            ? (fromDate as any).toISOString().substr(0, 10)
            : fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter["toDate"] =
          (toDate as any) instanceof Date
            ? (toDate as any).toISOString().substr(0, 10)
            : toDate;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PortalTransactionControllerApi - functional programming interface
 * @export
 */
export const PortalTransactionControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    PortalTransactionControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Export transaction ra file Excel
     * @param {string} [keyword]
     * @param {'NONE' | 'CREATED' | 'SUCCESS' | 'CANCELED' | 'FAIL' | 'TIMEOUT' | 'PENDING'} [status]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {string} [transactionId]
     * @param {string} [refTransactionId]
     * @param {string} [merchantId]
     * @param {string} [merchantCode]
     * @param {boolean} [isStatistic]
     * @param {'PAY_LINK' | 'PAY_PAGE' | 'PAY_BUTTON' | 'PAY_BOX' | 'PAY_POS' | 'PAY_SUBSCRIPTION' | 'PAY_OUT' | 'STATIC_VIRTUAL_ACCOUNT'} [type]
     * @param {'VIET_QR' | 'ATM_CARD' | 'BANKING'} [paymentType]
     * @param {string} [bankAccountId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportToExcel(
      keyword?: string,
      status?:
        | "NONE"
        | "CREATED"
        | "SUCCESS"
        | "CANCELED"
        | "FAIL"
        | "TIMEOUT"
        | "PENDING",
      fromDate?: string,
      toDate?: string,
      transactionId?: string,
      refTransactionId?: string,
      merchantId?: string,
      merchantCode?: string,
      isStatistic?: boolean,
      type?:
        | "PAY_LINK"
        | "PAY_PAGE"
        | "PAY_BUTTON"
        | "PAY_BOX"
        | "PAY_POS"
        | "PAY_SUBSCRIPTION"
        | "PAY_OUT"
        | "STATIC_VIRTUAL_ACCOUNT",
      paymentType?: "VIET_QR" | "ATM_CARD" | "BANKING",
      bankAccountId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.exportToExcel(
        keyword,
        status,
        fromDate,
        toDate,
        transactionId,
        refTransactionId,
        merchantId,
        merchantCode,
        isStatistic,
        type,
        paymentType,
        bankAccountId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Excel export giao dịch sao kê từ ngân hàng
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {'DAY_7' | 'MONTH_1' | 'MONTH_2' | 'MONTH_4'} [period]
     * @param {string} [accountNo]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportToExcelFromBank(
      fromDate?: string,
      toDate?: string,
      period?: "DAY_7" | "MONTH_1" | "MONTH_2" | "MONTH_4",
      accountNo?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.exportToExcelFromBank(
          fromDate,
          toDate,
          period,
          accountNo,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get toàn bộ transaction sao kê từ ngân hàng
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {'DAY_7' | 'MONTH_1' | 'MONTH_2' | 'MONTH_4'} [period]
     * @param {string} [accountNo]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllFromBank(
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      fromDate?: string,
      toDate?: string,
      period?: "DAY_7" | "MONTH_1" | "MONTH_2" | "MONTH_4",
      accountNo?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseTransactionResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllFromBank(
        page,
        pageSize,
        sortDirection,
        sortBy,
        fromDate,
        toDate,
        period,
        accountNo,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get toàn bộ transaction
     * @param {number} isStatistic
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {string} [transactionId]
     * @param {string} [refTransactionId]
     * @param {'NONE' | 'CREATED' | 'SUCCESS' | 'CANCELED' | 'FAIL' | 'TIMEOUT' | 'PENDING'} [status]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {string} [keyword]
     * @param {string} [payboxDeviceId]
     * @param {'PAY_LINK' | 'PAY_PAGE' | 'PAY_BUTTON' | 'PAY_BOX' | 'PAY_POS' | 'PAY_SUBSCRIPTION' | 'PAY_OUT' | 'STATIC_VIRTUAL_ACCOUNT'} [type]
     * @param {'VIET_QR' | 'ATM_CARD' | 'BANKING'} [paymentType]
     * @param {string} [bankAccountId]
     * @param {string} [bankAccountNo]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllTransactions(
      isStatistic: number,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      transactionId?: string,
      refTransactionId?: string,
      status?:
        | "NONE"
        | "CREATED"
        | "SUCCESS"
        | "CANCELED"
        | "FAIL"
        | "TIMEOUT"
        | "PENDING",
      fromDate?: string,
      toDate?: string,
      keyword?: string,
      payboxDeviceId?: string,
      type?:
        | "PAY_LINK"
        | "PAY_PAGE"
        | "PAY_BUTTON"
        | "PAY_BOX"
        | "PAY_POS"
        | "PAY_SUBSCRIPTION"
        | "PAY_OUT"
        | "STATIC_VIRTUAL_ACCOUNT",
      paymentType?: "VIET_QR" | "ATM_CARD" | "BANKING",
      bankAccountId?: string,
      bankAccountNo?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseTransactionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllTransactions(
          isStatistic,
          page,
          pageSize,
          sortDirection,
          sortBy,
          transactionId,
          refTransactionId,
          status,
          fromDate,
          toDate,
          keyword,
          payboxDeviceId,
          type,
          paymentType,
          bankAccountId,
          bankAccountNo,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Các thông tin thống kê
     * @param {'SUCCESS' | 'FAIL'} [status]
     * @param {'PAY_LINK' | 'PAY_PAGE' | 'PAY_BUTTON' | 'PAY_BOX' | 'PAY_POS' | 'PAY_SUBSCRIPTION' | 'PAY_OUT' | 'STATIC_VIRTUAL_ACCOUNT'} [type]
     * @param {'VIET_QR' | 'ATM_CARD' | 'BANKING'} [paymentType]
     * @param {string} [bankAccountId]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStatisticInfo(
      status?: "SUCCESS" | "FAIL",
      type?:
        | "PAY_LINK"
        | "PAY_PAGE"
        | "PAY_BUTTON"
        | "PAY_BOX"
        | "PAY_POS"
        | "PAY_SUBSCRIPTION"
        | "PAY_OUT"
        | "STATIC_VIRTUAL_ACCOUNT",
      paymentType?: "VIET_QR" | "ATM_CARD" | "BANKING",
      bankAccountId?: string,
      fromDate?: string,
      toDate?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetStatisticInfoResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getStatisticInfo(
          status,
          type,
          paymentType,
          bankAccountId,
          fromDate,
          toDate,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PortalTransactionControllerApi - factory interface
 * @export
 */
export const PortalTransactionControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PortalTransactionControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Export transaction ra file Excel
     * @param {string} [keyword]
     * @param {'NONE' | 'CREATED' | 'SUCCESS' | 'CANCELED' | 'FAIL' | 'TIMEOUT' | 'PENDING'} [status]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {string} [transactionId]
     * @param {string} [refTransactionId]
     * @param {string} [merchantId]
     * @param {string} [merchantCode]
     * @param {boolean} [isStatistic]
     * @param {'PAY_LINK' | 'PAY_PAGE' | 'PAY_BUTTON' | 'PAY_BOX' | 'PAY_POS' | 'PAY_SUBSCRIPTION' | 'PAY_OUT' | 'STATIC_VIRTUAL_ACCOUNT'} [type]
     * @param {'VIET_QR' | 'ATM_CARD' | 'BANKING'} [paymentType]
     * @param {string} [bankAccountId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportToExcel(
      keyword?: string,
      status?:
        | "NONE"
        | "CREATED"
        | "SUCCESS"
        | "CANCELED"
        | "FAIL"
        | "TIMEOUT"
        | "PENDING",
      fromDate?: string,
      toDate?: string,
      transactionId?: string,
      refTransactionId?: string,
      merchantId?: string,
      merchantCode?: string,
      isStatistic?: boolean,
      type?:
        | "PAY_LINK"
        | "PAY_PAGE"
        | "PAY_BUTTON"
        | "PAY_BOX"
        | "PAY_POS"
        | "PAY_SUBSCRIPTION"
        | "PAY_OUT"
        | "STATIC_VIRTUAL_ACCOUNT",
      paymentType?: "VIET_QR" | "ATM_CARD" | "BANKING",
      bankAccountId?: string,
      options?: any,
    ): AxiosPromise<File> {
      return localVarFp
        .exportToExcel(
          keyword,
          status,
          fromDate,
          toDate,
          transactionId,
          refTransactionId,
          merchantId,
          merchantCode,
          isStatistic,
          type,
          paymentType,
          bankAccountId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Excel export giao dịch sao kê từ ngân hàng
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {'DAY_7' | 'MONTH_1' | 'MONTH_2' | 'MONTH_4'} [period]
     * @param {string} [accountNo]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportToExcelFromBank(
      fromDate?: string,
      toDate?: string,
      period?: "DAY_7" | "MONTH_1" | "MONTH_2" | "MONTH_4",
      accountNo?: string,
      options?: any,
    ): AxiosPromise<File> {
      return localVarFp
        .exportToExcelFromBank(fromDate, toDate, period, accountNo, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get toàn bộ transaction sao kê từ ngân hàng
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {'DAY_7' | 'MONTH_1' | 'MONTH_2' | 'MONTH_4'} [period]
     * @param {string} [accountNo]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllFromBank(
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      fromDate?: string,
      toDate?: string,
      period?: "DAY_7" | "MONTH_1" | "MONTH_2" | "MONTH_4",
      accountNo?: string,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseTransactionResponse> {
      return localVarFp
        .getAllFromBank(
          page,
          pageSize,
          sortDirection,
          sortBy,
          fromDate,
          toDate,
          period,
          accountNo,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get toàn bộ transaction
     * @param {number} isStatistic
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {string} [transactionId]
     * @param {string} [refTransactionId]
     * @param {'NONE' | 'CREATED' | 'SUCCESS' | 'CANCELED' | 'FAIL' | 'TIMEOUT' | 'PENDING'} [status]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {string} [keyword]
     * @param {string} [payboxDeviceId]
     * @param {'PAY_LINK' | 'PAY_PAGE' | 'PAY_BUTTON' | 'PAY_BOX' | 'PAY_POS' | 'PAY_SUBSCRIPTION' | 'PAY_OUT' | 'STATIC_VIRTUAL_ACCOUNT'} [type]
     * @param {'VIET_QR' | 'ATM_CARD' | 'BANKING'} [paymentType]
     * @param {string} [bankAccountId]
     * @param {string} [bankAccountNo]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllTransactions(
      isStatistic: number,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      transactionId?: string,
      refTransactionId?: string,
      status?:
        | "NONE"
        | "CREATED"
        | "SUCCESS"
        | "CANCELED"
        | "FAIL"
        | "TIMEOUT"
        | "PENDING",
      fromDate?: string,
      toDate?: string,
      keyword?: string,
      payboxDeviceId?: string,
      type?:
        | "PAY_LINK"
        | "PAY_PAGE"
        | "PAY_BUTTON"
        | "PAY_BOX"
        | "PAY_POS"
        | "PAY_SUBSCRIPTION"
        | "PAY_OUT"
        | "STATIC_VIRTUAL_ACCOUNT",
      paymentType?: "VIET_QR" | "ATM_CARD" | "BANKING",
      bankAccountId?: string,
      bankAccountNo?: string,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseTransactionResponse> {
      return localVarFp
        .getAllTransactions(
          isStatistic,
          page,
          pageSize,
          sortDirection,
          sortBy,
          transactionId,
          refTransactionId,
          status,
          fromDate,
          toDate,
          keyword,
          payboxDeviceId,
          type,
          paymentType,
          bankAccountId,
          bankAccountNo,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Các thông tin thống kê
     * @param {'SUCCESS' | 'FAIL'} [status]
     * @param {'PAY_LINK' | 'PAY_PAGE' | 'PAY_BUTTON' | 'PAY_BOX' | 'PAY_POS' | 'PAY_SUBSCRIPTION' | 'PAY_OUT' | 'STATIC_VIRTUAL_ACCOUNT'} [type]
     * @param {'VIET_QR' | 'ATM_CARD' | 'BANKING'} [paymentType]
     * @param {string} [bankAccountId]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStatisticInfo(
      status?: "SUCCESS" | "FAIL",
      type?:
        | "PAY_LINK"
        | "PAY_PAGE"
        | "PAY_BUTTON"
        | "PAY_BOX"
        | "PAY_POS"
        | "PAY_SUBSCRIPTION"
        | "PAY_OUT"
        | "STATIC_VIRTUAL_ACCOUNT",
      paymentType?: "VIET_QR" | "ATM_CARD" | "BANKING",
      bankAccountId?: string,
      fromDate?: string,
      toDate?: string,
      options?: any,
    ): AxiosPromise<ResponseBaseGetStatisticInfoResponse> {
      return localVarFp
        .getStatisticInfo(
          status,
          type,
          paymentType,
          bankAccountId,
          fromDate,
          toDate,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for exportToExcel operation in PortalTransactionControllerApi.
 * @export
 * @interface PortalTransactionControllerApiExportToExcelRequest
 */
export interface PortalTransactionControllerApiExportToExcelRequest {
  /**
   *
   * @type {string}
   * @memberof PortalTransactionControllerApiExportToExcel
   */
  readonly keyword?: string;

  /**
   *
   * @type {'NONE' | 'CREATED' | 'SUCCESS' | 'CANCELED' | 'FAIL' | 'TIMEOUT' | 'PENDING'}
   * @memberof PortalTransactionControllerApiExportToExcel
   */
  readonly status?:
    | "NONE"
    | "CREATED"
    | "SUCCESS"
    | "CANCELED"
    | "FAIL"
    | "TIMEOUT"
    | "PENDING";

  /**
   *
   * @type {string}
   * @memberof PortalTransactionControllerApiExportToExcel
   */
  readonly fromDate?: string;

  /**
   *
   * @type {string}
   * @memberof PortalTransactionControllerApiExportToExcel
   */
  readonly toDate?: string;

  /**
   *
   * @type {string}
   * @memberof PortalTransactionControllerApiExportToExcel
   */
  readonly transactionId?: string;

  /**
   *
   * @type {string}
   * @memberof PortalTransactionControllerApiExportToExcel
   */
  readonly refTransactionId?: string;

  /**
   *
   * @type {string}
   * @memberof PortalTransactionControllerApiExportToExcel
   */
  readonly merchantId?: string;

  /**
   *
   * @type {string}
   * @memberof PortalTransactionControllerApiExportToExcel
   */
  readonly merchantCode?: string;

  /**
   *
   * @type {boolean}
   * @memberof PortalTransactionControllerApiExportToExcel
   */
  readonly isStatistic?: boolean;

  /**
   *
   * @type {'PAY_LINK' | 'PAY_PAGE' | 'PAY_BUTTON' | 'PAY_BOX' | 'PAY_POS' | 'PAY_SUBSCRIPTION' | 'PAY_OUT' | 'STATIC_VIRTUAL_ACCOUNT'}
   * @memberof PortalTransactionControllerApiExportToExcel
   */
  readonly type?:
    | "PAY_LINK"
    | "PAY_PAGE"
    | "PAY_BUTTON"
    | "PAY_BOX"
    | "PAY_POS"
    | "PAY_SUBSCRIPTION"
    | "PAY_OUT"
    | "STATIC_VIRTUAL_ACCOUNT";

  /**
   *
   * @type {'VIET_QR' | 'ATM_CARD' | 'BANKING'}
   * @memberof PortalTransactionControllerApiExportToExcel
   */
  readonly paymentType?: "VIET_QR" | "ATM_CARD" | "BANKING";

  /**
   *
   * @type {string}
   * @memberof PortalTransactionControllerApiExportToExcel
   */
  readonly bankAccountId?: string;
}

/**
 * Request parameters for exportToExcelFromBank operation in PortalTransactionControllerApi.
 * @export
 * @interface PortalTransactionControllerApiExportToExcelFromBankRequest
 */
export interface PortalTransactionControllerApiExportToExcelFromBankRequest {
  /**
   *
   * @type {string}
   * @memberof PortalTransactionControllerApiExportToExcelFromBank
   */
  readonly fromDate?: string;

  /**
   *
   * @type {string}
   * @memberof PortalTransactionControllerApiExportToExcelFromBank
   */
  readonly toDate?: string;

  /**
   *
   * @type {'DAY_7' | 'MONTH_1' | 'MONTH_2' | 'MONTH_4'}
   * @memberof PortalTransactionControllerApiExportToExcelFromBank
   */
  readonly period?: "DAY_7" | "MONTH_1" | "MONTH_2" | "MONTH_4";

  /**
   *
   * @type {string}
   * @memberof PortalTransactionControllerApiExportToExcelFromBank
   */
  readonly accountNo?: string;
}

/**
 * Request parameters for getAllFromBank operation in PortalTransactionControllerApi.
 * @export
 * @interface PortalTransactionControllerApiGetAllFromBankRequest
 */
export interface PortalTransactionControllerApiGetAllFromBankRequest {
  /**
   *
   * @type {number}
   * @memberof PortalTransactionControllerApiGetAllFromBank
   */
  readonly page?: number;

  /**
   *
   * @type {number}
   * @memberof PortalTransactionControllerApiGetAllFromBank
   */
  readonly pageSize?: number;

  /**
   *
   * @type {'ASC' | 'DESC'}
   * @memberof PortalTransactionControllerApiGetAllFromBank
   */
  readonly sortDirection?: "ASC" | "DESC";

  /**
   *
   * @type {string}
   * @memberof PortalTransactionControllerApiGetAllFromBank
   */
  readonly sortBy?: string;

  /**
   *
   * @type {string}
   * @memberof PortalTransactionControllerApiGetAllFromBank
   */
  readonly fromDate?: string;

  /**
   *
   * @type {string}
   * @memberof PortalTransactionControllerApiGetAllFromBank
   */
  readonly toDate?: string;

  /**
   *
   * @type {'DAY_7' | 'MONTH_1' | 'MONTH_2' | 'MONTH_4'}
   * @memberof PortalTransactionControllerApiGetAllFromBank
   */
  readonly period?: "DAY_7" | "MONTH_1" | "MONTH_2" | "MONTH_4";

  /**
   *
   * @type {string}
   * @memberof PortalTransactionControllerApiGetAllFromBank
   */
  readonly accountNo?: string;
}

/**
 * Request parameters for getAllTransactions operation in PortalTransactionControllerApi.
 * @export
 * @interface PortalTransactionControllerApiGetAllTransactionsRequest
 */
export interface PortalTransactionControllerApiGetAllTransactionsRequest {
  /**
   *
   * @type {number}
   * @memberof PortalTransactionControllerApiGetAllTransactions
   */
  readonly isStatistic: number;

  /**
   *
   * @type {number}
   * @memberof PortalTransactionControllerApiGetAllTransactions
   */
  readonly page?: number;

  /**
   *
   * @type {number}
   * @memberof PortalTransactionControllerApiGetAllTransactions
   */
  readonly pageSize?: number;

  /**
   *
   * @type {'ASC' | 'DESC'}
   * @memberof PortalTransactionControllerApiGetAllTransactions
   */
  readonly sortDirection?: "ASC" | "DESC";

  /**
   *
   * @type {string}
   * @memberof PortalTransactionControllerApiGetAllTransactions
   */
  readonly sortBy?: string;

  /**
   *
   * @type {string}
   * @memberof PortalTransactionControllerApiGetAllTransactions
   */
  readonly transactionId?: string;

  /**
   *
   * @type {string}
   * @memberof PortalTransactionControllerApiGetAllTransactions
   */
  readonly refTransactionId?: string;

  /**
   *
   * @type {'NONE' | 'CREATED' | 'SUCCESS' | 'CANCELED' | 'FAIL' | 'TIMEOUT' | 'PENDING'}
   * @memberof PortalTransactionControllerApiGetAllTransactions
   */
  readonly status?:
    | "NONE"
    | "CREATED"
    | "SUCCESS"
    | "CANCELED"
    | "FAIL"
    | "TIMEOUT"
    | "PENDING";

  /**
   *
   * @type {string}
   * @memberof PortalTransactionControllerApiGetAllTransactions
   */
  readonly fromDate?: string;

  /**
   *
   * @type {string}
   * @memberof PortalTransactionControllerApiGetAllTransactions
   */
  readonly toDate?: string;

  /**
   *
   * @type {string}
   * @memberof PortalTransactionControllerApiGetAllTransactions
   */
  readonly keyword?: string;

  /**
   *
   * @type {string}
   * @memberof PortalTransactionControllerApiGetAllTransactions
   */
  readonly payboxDeviceId?: string;

  /**
   *
   * @type {'PAY_LINK' | 'PAY_PAGE' | 'PAY_BUTTON' | 'PAY_BOX' | 'PAY_POS' | 'PAY_SUBSCRIPTION' | 'PAY_OUT' | 'STATIC_VIRTUAL_ACCOUNT'}
   * @memberof PortalTransactionControllerApiGetAllTransactions
   */
  readonly type?:
    | "PAY_LINK"
    | "PAY_PAGE"
    | "PAY_BUTTON"
    | "PAY_BOX"
    | "PAY_POS"
    | "PAY_SUBSCRIPTION"
    | "PAY_OUT"
    | "STATIC_VIRTUAL_ACCOUNT";

  /**
   *
   * @type {'VIET_QR' | 'ATM_CARD' | 'BANKING'}
   * @memberof PortalTransactionControllerApiGetAllTransactions
   */
  readonly paymentType?: "VIET_QR" | "ATM_CARD" | "BANKING";

  /**
   *
   * @type {string}
   * @memberof PortalTransactionControllerApiGetAllTransactions
   */
  readonly bankAccountId?: string;

  /**
   *
   * @type {string}
   * @memberof PortalTransactionControllerApiGetAllTransactions
   */
  readonly bankAccountNo?: string;
}

/**
 * Request parameters for getStatisticInfo operation in PortalTransactionControllerApi.
 * @export
 * @interface PortalTransactionControllerApiGetStatisticInfoRequest
 */
export interface PortalTransactionControllerApiGetStatisticInfoRequest {
  /**
   *
   * @type {'SUCCESS' | 'FAIL'}
   * @memberof PortalTransactionControllerApiGetStatisticInfo
   */
  readonly status?: "SUCCESS" | "FAIL";

  /**
   *
   * @type {'PAY_LINK' | 'PAY_PAGE' | 'PAY_BUTTON' | 'PAY_BOX' | 'PAY_POS' | 'PAY_SUBSCRIPTION' | 'PAY_OUT' | 'STATIC_VIRTUAL_ACCOUNT'}
   * @memberof PortalTransactionControllerApiGetStatisticInfo
   */
  readonly type?:
    | "PAY_LINK"
    | "PAY_PAGE"
    | "PAY_BUTTON"
    | "PAY_BOX"
    | "PAY_POS"
    | "PAY_SUBSCRIPTION"
    | "PAY_OUT"
    | "STATIC_VIRTUAL_ACCOUNT";

  /**
   *
   * @type {'VIET_QR' | 'ATM_CARD' | 'BANKING'}
   * @memberof PortalTransactionControllerApiGetStatisticInfo
   */
  readonly paymentType?: "VIET_QR" | "ATM_CARD" | "BANKING";

  /**
   *
   * @type {string}
   * @memberof PortalTransactionControllerApiGetStatisticInfo
   */
  readonly bankAccountId?: string;

  /**
   *
   * @type {string}
   * @memberof PortalTransactionControllerApiGetStatisticInfo
   */
  readonly fromDate?: string;

  /**
   *
   * @type {string}
   * @memberof PortalTransactionControllerApiGetStatisticInfo
   */
  readonly toDate?: string;
}

/**
 * PortalTransactionControllerApi - object-oriented interface
 * @export
 * @class PortalTransactionControllerApi
 * @extends {BaseAPI}
 */
export class PortalTransactionControllerApi extends BaseAPI {
  /**
   *
   * @summary Export transaction ra file Excel
   * @param {PortalTransactionControllerApiExportToExcelRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalTransactionControllerApi
   */
  public exportToExcel(
    requestParameters: PortalTransactionControllerApiExportToExcelRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return PortalTransactionControllerApiFp(this.configuration)
      .exportToExcel(
        requestParameters.keyword,
        requestParameters.status,
        requestParameters.fromDate,
        requestParameters.toDate,
        requestParameters.transactionId,
        requestParameters.refTransactionId,
        requestParameters.merchantId,
        requestParameters.merchantCode,
        requestParameters.isStatistic,
        requestParameters.type,
        requestParameters.paymentType,
        requestParameters.bankAccountId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Excel export giao dịch sao kê từ ngân hàng
   * @param {PortalTransactionControllerApiExportToExcelFromBankRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalTransactionControllerApi
   */
  public exportToExcelFromBank(
    requestParameters: PortalTransactionControllerApiExportToExcelFromBankRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return PortalTransactionControllerApiFp(this.configuration)
      .exportToExcelFromBank(
        requestParameters.fromDate,
        requestParameters.toDate,
        requestParameters.period,
        requestParameters.accountNo,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get toàn bộ transaction sao kê từ ngân hàng
   * @param {PortalTransactionControllerApiGetAllFromBankRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalTransactionControllerApi
   */
  public getAllFromBank(
    requestParameters: PortalTransactionControllerApiGetAllFromBankRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return PortalTransactionControllerApiFp(this.configuration)
      .getAllFromBank(
        requestParameters.page,
        requestParameters.pageSize,
        requestParameters.sortDirection,
        requestParameters.sortBy,
        requestParameters.fromDate,
        requestParameters.toDate,
        requestParameters.period,
        requestParameters.accountNo,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get toàn bộ transaction
   * @param {PortalTransactionControllerApiGetAllTransactionsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalTransactionControllerApi
   */
  public getAllTransactions(
    requestParameters: PortalTransactionControllerApiGetAllTransactionsRequest,
    options?: AxiosRequestConfig,
  ) {
    return PortalTransactionControllerApiFp(this.configuration)
      .getAllTransactions(
        requestParameters.isStatistic,
        requestParameters.page,
        requestParameters.pageSize,
        requestParameters.sortDirection,
        requestParameters.sortBy,
        requestParameters.transactionId,
        requestParameters.refTransactionId,
        requestParameters.status,
        requestParameters.fromDate,
        requestParameters.toDate,
        requestParameters.keyword,
        requestParameters.payboxDeviceId,
        requestParameters.type,
        requestParameters.paymentType,
        requestParameters.bankAccountId,
        requestParameters.bankAccountNo,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Các thông tin thống kê
   * @param {PortalTransactionControllerApiGetStatisticInfoRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalTransactionControllerApi
   */
  public getStatisticInfo(
    requestParameters: PortalTransactionControllerApiGetStatisticInfoRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return PortalTransactionControllerApiFp(this.configuration)
      .getStatisticInfo(
        requestParameters.status,
        requestParameters.type,
        requestParameters.paymentType,
        requestParameters.bankAccountId,
        requestParameters.fromDate,
        requestParameters.toDate,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PortalVietQRProApi - axios parameter creator
 * @export
 */
export const PortalVietQRProApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary [Portal] Lấy danh sách khách hàng
     * @param {string} [keyword]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllCustomerVietQrPro: async (
      keyword?: string,
      fromDate?: string,
      toDate?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/vietQrPro/portal/v1/customer/getAll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (keyword !== undefined) {
        localVarQueryParameter["keyword"] = keyword;
      }

      if (fromDate !== undefined) {
        localVarQueryParameter["fromDate"] =
          (fromDate as any) instanceof Date
            ? (fromDate as any).toISOString()
            : fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter["toDate"] =
          (toDate as any) instanceof Date
            ? (toDate as any).toISOString()
            : toDate;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [Portal]
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nextStatePermitBankAccount: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("nextStatePermitBankAccount", "id", id);
      const localVarPath =
        `/api/vietQrPro/portal/v1/account/{id}/state`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(id)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [Portal] Đổi trạng thái VietQr Pro
     * @param {PortalNextStateVietQrProRequest} portalNextStateVietQrProRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nextStateVietQrPro: async (
      portalNextStateVietQrProRequest: PortalNextStateVietQrProRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'portalNextStateVietQrProRequest' is not null or undefined
      assertParamExists(
        "nextStateVietQrPro",
        "portalNextStateVietQrProRequest",
        portalNextStateVietQrProRequest,
      );
      const localVarPath = `/api/vietQrPro/portal/v1/state`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        portalNextStateVietQrProRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PortalVietQRProApi - functional programming interface
 * @export
 */
export const PortalVietQRProApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    PortalVietQRProApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary [Portal] Lấy danh sách khách hàng
     * @param {string} [keyword]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllCustomerVietQrPro(
      keyword?: string,
      fromDate?: string,
      toDate?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseVietQrProCustomerResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllCustomerVietQrPro(
          keyword,
          fromDate,
          toDate,
          page,
          size,
          sort,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary [Portal]
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async nextStatePermitBankAccount(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.nextStatePermitBankAccount(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary [Portal] Đổi trạng thái VietQr Pro
     * @param {PortalNextStateVietQrProRequest} portalNextStateVietQrProRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async nextStateVietQrPro(
      portalNextStateVietQrProRequest: PortalNextStateVietQrProRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.nextStateVietQrPro(
          portalNextStateVietQrProRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PortalVietQRProApi - factory interface
 * @export
 */
export const PortalVietQRProApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PortalVietQRProApiFp(configuration);
  return {
    /**
     *
     * @summary [Portal] Lấy danh sách khách hàng
     * @param {string} [keyword]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllCustomerVietQrPro(
      keyword?: string,
      fromDate?: string,
      toDate?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseVietQrProCustomerResponse> {
      return localVarFp
        .getAllCustomerVietQrPro(
          keyword,
          fromDate,
          toDate,
          page,
          size,
          sort,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [Portal]
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nextStatePermitBankAccount(
      id: number,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .nextStatePermitBankAccount(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [Portal] Đổi trạng thái VietQr Pro
     * @param {PortalNextStateVietQrProRequest} portalNextStateVietQrProRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nextStateVietQrPro(
      portalNextStateVietQrProRequest: PortalNextStateVietQrProRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .nextStateVietQrPro(portalNextStateVietQrProRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getAllCustomerVietQrPro operation in PortalVietQRProApi.
 * @export
 * @interface PortalVietQRProApiGetAllCustomerVietQrProRequest
 */
export interface PortalVietQRProApiGetAllCustomerVietQrProRequest {
  /**
   *
   * @type {string}
   * @memberof PortalVietQRProApiGetAllCustomerVietQrPro
   */
  readonly keyword?: string;

  /**
   *
   * @type {string}
   * @memberof PortalVietQRProApiGetAllCustomerVietQrPro
   */
  readonly fromDate?: string;

  /**
   *
   * @type {string}
   * @memberof PortalVietQRProApiGetAllCustomerVietQrPro
   */
  readonly toDate?: string;

  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof PortalVietQRProApiGetAllCustomerVietQrPro
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof PortalVietQRProApiGetAllCustomerVietQrPro
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof PortalVietQRProApiGetAllCustomerVietQrPro
   */
  readonly sort?: Array<string>;
}

/**
 * Request parameters for nextStatePermitBankAccount operation in PortalVietQRProApi.
 * @export
 * @interface PortalVietQRProApiNextStatePermitBankAccountRequest
 */
export interface PortalVietQRProApiNextStatePermitBankAccountRequest {
  /**
   *
   * @type {number}
   * @memberof PortalVietQRProApiNextStatePermitBankAccount
   */
  readonly id: number;
}

/**
 * Request parameters for nextStateVietQrPro operation in PortalVietQRProApi.
 * @export
 * @interface PortalVietQRProApiNextStateVietQrProRequest
 */
export interface PortalVietQRProApiNextStateVietQrProRequest {
  /**
   *
   * @type {PortalNextStateVietQrProRequest}
   * @memberof PortalVietQRProApiNextStateVietQrPro
   */
  readonly portalNextStateVietQrProRequest: PortalNextStateVietQrProRequest;
}

/**
 * PortalVietQRProApi - object-oriented interface
 * @export
 * @class PortalVietQRProApi
 * @extends {BaseAPI}
 */
export class PortalVietQRProApi extends BaseAPI {
  /**
   *
   * @summary [Portal] Lấy danh sách khách hàng
   * @param {PortalVietQRProApiGetAllCustomerVietQrProRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalVietQRProApi
   */
  public getAllCustomerVietQrPro(
    requestParameters: PortalVietQRProApiGetAllCustomerVietQrProRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return PortalVietQRProApiFp(this.configuration)
      .getAllCustomerVietQrPro(
        requestParameters.keyword,
        requestParameters.fromDate,
        requestParameters.toDate,
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [Portal]
   * @param {PortalVietQRProApiNextStatePermitBankAccountRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalVietQRProApi
   */
  public nextStatePermitBankAccount(
    requestParameters: PortalVietQRProApiNextStatePermitBankAccountRequest,
    options?: AxiosRequestConfig,
  ) {
    return PortalVietQRProApiFp(this.configuration)
      .nextStatePermitBankAccount(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [Portal] Đổi trạng thái VietQr Pro
   * @param {PortalVietQRProApiNextStateVietQrProRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortalVietQRProApi
   */
  public nextStateVietQrPro(
    requestParameters: PortalVietQRProApiNextStateVietQrProRequest,
    options?: AxiosRequestConfig,
  ) {
    return PortalVietQRProApiFp(this.configuration)
      .nextStateVietQrPro(
        requestParameters.portalNextStateVietQrProRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PosModelControllerApi - axios parameter creator
 * @export
 */
export const PosModelControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary CMS Lấy danh sách POS model
     * @param {string} [name]
     * @param {number} [minPrice]
     * @param {number} [maxPrice]
     * @param {boolean} [available]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsGetListPosModel: async (
      name?: string,
      minPrice?: number,
      maxPrice?: number,
      available?: boolean,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/posModel/cms/v1/getList`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (name !== undefined) {
        localVarQueryParameter["name"] = name;
      }

      if (minPrice !== undefined) {
        localVarQueryParameter["minPrice"] = minPrice;
      }

      if (maxPrice !== undefined) {
        localVarQueryParameter["maxPrice"] = maxPrice;
      }

      if (available !== undefined) {
        localVarQueryParameter["available"] = available;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter["sortDirection"] = sortDirection;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter["sortBy"] = sortBy;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Tạo POS model
     * @param {CreatePosModelRequest} createPosModelRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPosModel: async (
      createPosModelRequest: CreatePosModelRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createPosModelRequest' is not null or undefined
      assertParamExists(
        "createPosModel",
        "createPosModelRequest",
        createPosModelRequest,
      );
      const localVarPath = `/api/posModel/cms/v1/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createPosModelRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xóa POS model
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePosModel: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("deletePosModel", "id", id);
      const localVarPath = `/api/posModel/cms/v1/delete/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy thông tin POS model
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPosModel: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getPosModel", "id", id);
      const localVarPath = `/api/posModel/v1/get/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Portal Lấy danh sách POS model đang bán
     * @param {string} [name]
     * @param {number} [minPrice]
     * @param {number} [maxPrice]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    portalGetListPosModel: async (
      name?: string,
      minPrice?: number,
      maxPrice?: number,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/posModel/portal/v1/getList`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (name !== undefined) {
        localVarQueryParameter["name"] = name;
      }

      if (minPrice !== undefined) {
        localVarQueryParameter["minPrice"] = minPrice;
      }

      if (maxPrice !== undefined) {
        localVarQueryParameter["maxPrice"] = maxPrice;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter["sortDirection"] = sortDirection;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter["sortBy"] = sortBy;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Cập nhật POS model
     * @param {UpdatePosModelRequest} updatePosModelRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePosModel: async (
      updatePosModelRequest: UpdatePosModelRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updatePosModelRequest' is not null or undefined
      assertParamExists(
        "updatePosModel",
        "updatePosModelRequest",
        updatePosModelRequest,
      );
      const localVarPath = `/api/posModel/cms/v1/update`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updatePosModelRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PosModelControllerApi - functional programming interface
 * @export
 */
export const PosModelControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    PosModelControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary CMS Lấy danh sách POS model
     * @param {string} [name]
     * @param {number} [minPrice]
     * @param {number} [maxPrice]
     * @param {boolean} [available]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cmsGetListPosModel(
      name?: string,
      minPrice?: number,
      maxPrice?: number,
      available?: boolean,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponsePosModelResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cmsGetListPosModel(
          name,
          minPrice,
          maxPrice,
          available,
          page,
          pageSize,
          sortDirection,
          sortBy,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Tạo POS model
     * @param {CreatePosModelRequest} createPosModelRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createPosModel(
      createPosModelRequest: CreatePosModelRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePosModelResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createPosModel(
        createPosModelRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Xóa POS model
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deletePosModel(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deletePosModel(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy thông tin POS model
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPosModel(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePosModelResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPosModel(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Portal Lấy danh sách POS model đang bán
     * @param {string} [name]
     * @param {number} [minPrice]
     * @param {number} [maxPrice]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async portalGetListPosModel(
      name?: string,
      minPrice?: number,
      maxPrice?: number,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponsePosModelResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.portalGetListPosModel(
          name,
          minPrice,
          maxPrice,
          page,
          pageSize,
          sortDirection,
          sortBy,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Cập nhật POS model
     * @param {UpdatePosModelRequest} updatePosModelRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updatePosModel(
      updatePosModelRequest: UpdatePosModelRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePosModelResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updatePosModel(
        updatePosModelRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PosModelControllerApi - factory interface
 * @export
 */
export const PosModelControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PosModelControllerApiFp(configuration);
  return {
    /**
     *
     * @summary CMS Lấy danh sách POS model
     * @param {string} [name]
     * @param {number} [minPrice]
     * @param {number} [maxPrice]
     * @param {boolean} [available]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsGetListPosModel(
      name?: string,
      minPrice?: number,
      maxPrice?: number,
      available?: boolean,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponsePosModelResponse> {
      return localVarFp
        .cmsGetListPosModel(
          name,
          minPrice,
          maxPrice,
          available,
          page,
          pageSize,
          sortDirection,
          sortBy,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Tạo POS model
     * @param {CreatePosModelRequest} createPosModelRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPosModel(
      createPosModelRequest: CreatePosModelRequest,
      options?: any,
    ): AxiosPromise<ResponseBasePosModelResponse> {
      return localVarFp
        .createPosModel(createPosModelRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xóa POS model
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePosModel(
      id: number,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .deletePosModel(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy thông tin POS model
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPosModel(
      id: number,
      options?: any,
    ): AxiosPromise<ResponseBasePosModelResponse> {
      return localVarFp
        .getPosModel(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Portal Lấy danh sách POS model đang bán
     * @param {string} [name]
     * @param {number} [minPrice]
     * @param {number} [maxPrice]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    portalGetListPosModel(
      name?: string,
      minPrice?: number,
      maxPrice?: number,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponsePosModelResponse> {
      return localVarFp
        .portalGetListPosModel(
          name,
          minPrice,
          maxPrice,
          page,
          pageSize,
          sortDirection,
          sortBy,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Cập nhật POS model
     * @param {UpdatePosModelRequest} updatePosModelRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePosModel(
      updatePosModelRequest: UpdatePosModelRequest,
      options?: any,
    ): AxiosPromise<ResponseBasePosModelResponse> {
      return localVarFp
        .updatePosModel(updatePosModelRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for cmsGetListPosModel operation in PosModelControllerApi.
 * @export
 * @interface PosModelControllerApiCmsGetListPosModelRequest
 */
export interface PosModelControllerApiCmsGetListPosModelRequest {
  /**
   *
   * @type {string}
   * @memberof PosModelControllerApiCmsGetListPosModel
   */
  readonly name?: string;

  /**
   *
   * @type {number}
   * @memberof PosModelControllerApiCmsGetListPosModel
   */
  readonly minPrice?: number;

  /**
   *
   * @type {number}
   * @memberof PosModelControllerApiCmsGetListPosModel
   */
  readonly maxPrice?: number;

  /**
   *
   * @type {boolean}
   * @memberof PosModelControllerApiCmsGetListPosModel
   */
  readonly available?: boolean;

  /**
   *
   * @type {number}
   * @memberof PosModelControllerApiCmsGetListPosModel
   */
  readonly page?: number;

  /**
   *
   * @type {number}
   * @memberof PosModelControllerApiCmsGetListPosModel
   */
  readonly pageSize?: number;

  /**
   *
   * @type {'ASC' | 'DESC'}
   * @memberof PosModelControllerApiCmsGetListPosModel
   */
  readonly sortDirection?: "ASC" | "DESC";

  /**
   *
   * @type {string}
   * @memberof PosModelControllerApiCmsGetListPosModel
   */
  readonly sortBy?: string;
}

/**
 * Request parameters for createPosModel operation in PosModelControllerApi.
 * @export
 * @interface PosModelControllerApiCreatePosModelRequest
 */
export interface PosModelControllerApiCreatePosModelRequest {
  /**
   *
   * @type {CreatePosModelRequest}
   * @memberof PosModelControllerApiCreatePosModel
   */
  readonly createPosModelRequest: CreatePosModelRequest;
}

/**
 * Request parameters for deletePosModel operation in PosModelControllerApi.
 * @export
 * @interface PosModelControllerApiDeletePosModelRequest
 */
export interface PosModelControllerApiDeletePosModelRequest {
  /**
   *
   * @type {number}
   * @memberof PosModelControllerApiDeletePosModel
   */
  readonly id: number;
}

/**
 * Request parameters for getPosModel operation in PosModelControllerApi.
 * @export
 * @interface PosModelControllerApiGetPosModelRequest
 */
export interface PosModelControllerApiGetPosModelRequest {
  /**
   *
   * @type {number}
   * @memberof PosModelControllerApiGetPosModel
   */
  readonly id: number;
}

/**
 * Request parameters for portalGetListPosModel operation in PosModelControllerApi.
 * @export
 * @interface PosModelControllerApiPortalGetListPosModelRequest
 */
export interface PosModelControllerApiPortalGetListPosModelRequest {
  /**
   *
   * @type {string}
   * @memberof PosModelControllerApiPortalGetListPosModel
   */
  readonly name?: string;

  /**
   *
   * @type {number}
   * @memberof PosModelControllerApiPortalGetListPosModel
   */
  readonly minPrice?: number;

  /**
   *
   * @type {number}
   * @memberof PosModelControllerApiPortalGetListPosModel
   */
  readonly maxPrice?: number;

  /**
   *
   * @type {number}
   * @memberof PosModelControllerApiPortalGetListPosModel
   */
  readonly page?: number;

  /**
   *
   * @type {number}
   * @memberof PosModelControllerApiPortalGetListPosModel
   */
  readonly pageSize?: number;

  /**
   *
   * @type {'ASC' | 'DESC'}
   * @memberof PosModelControllerApiPortalGetListPosModel
   */
  readonly sortDirection?: "ASC" | "DESC";

  /**
   *
   * @type {string}
   * @memberof PosModelControllerApiPortalGetListPosModel
   */
  readonly sortBy?: string;
}

/**
 * Request parameters for updatePosModel operation in PosModelControllerApi.
 * @export
 * @interface PosModelControllerApiUpdatePosModelRequest
 */
export interface PosModelControllerApiUpdatePosModelRequest {
  /**
   *
   * @type {UpdatePosModelRequest}
   * @memberof PosModelControllerApiUpdatePosModel
   */
  readonly updatePosModelRequest: UpdatePosModelRequest;
}

/**
 * PosModelControllerApi - object-oriented interface
 * @export
 * @class PosModelControllerApi
 * @extends {BaseAPI}
 */
export class PosModelControllerApi extends BaseAPI {
  /**
   *
   * @summary CMS Lấy danh sách POS model
   * @param {PosModelControllerApiCmsGetListPosModelRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PosModelControllerApi
   */
  public cmsGetListPosModel(
    requestParameters: PosModelControllerApiCmsGetListPosModelRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return PosModelControllerApiFp(this.configuration)
      .cmsGetListPosModel(
        requestParameters.name,
        requestParameters.minPrice,
        requestParameters.maxPrice,
        requestParameters.available,
        requestParameters.page,
        requestParameters.pageSize,
        requestParameters.sortDirection,
        requestParameters.sortBy,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Tạo POS model
   * @param {PosModelControllerApiCreatePosModelRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PosModelControllerApi
   */
  public createPosModel(
    requestParameters: PosModelControllerApiCreatePosModelRequest,
    options?: AxiosRequestConfig,
  ) {
    return PosModelControllerApiFp(this.configuration)
      .createPosModel(requestParameters.createPosModelRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xóa POS model
   * @param {PosModelControllerApiDeletePosModelRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PosModelControllerApi
   */
  public deletePosModel(
    requestParameters: PosModelControllerApiDeletePosModelRequest,
    options?: AxiosRequestConfig,
  ) {
    return PosModelControllerApiFp(this.configuration)
      .deletePosModel(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy thông tin POS model
   * @param {PosModelControllerApiGetPosModelRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PosModelControllerApi
   */
  public getPosModel(
    requestParameters: PosModelControllerApiGetPosModelRequest,
    options?: AxiosRequestConfig,
  ) {
    return PosModelControllerApiFp(this.configuration)
      .getPosModel(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Portal Lấy danh sách POS model đang bán
   * @param {PosModelControllerApiPortalGetListPosModelRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PosModelControllerApi
   */
  public portalGetListPosModel(
    requestParameters: PosModelControllerApiPortalGetListPosModelRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return PosModelControllerApiFp(this.configuration)
      .portalGetListPosModel(
        requestParameters.name,
        requestParameters.minPrice,
        requestParameters.maxPrice,
        requestParameters.page,
        requestParameters.pageSize,
        requestParameters.sortDirection,
        requestParameters.sortBy,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Cập nhật POS model
   * @param {PosModelControllerApiUpdatePosModelRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PosModelControllerApi
   */
  public updatePosModel(
    requestParameters: PosModelControllerApiUpdatePosModelRequest,
    options?: AxiosRequestConfig,
  ) {
    return PosModelControllerApiFp(this.configuration)
      .updatePosModel(requestParameters.updatePosModelRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PosOrderControllerApi - axios parameter creator
 * @export
 */
export const PosOrderControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary CMS Lấy danh sách đơn hàng đặt mua pos
     * @param {'DELIVERING' | 'DELIVERED' | 'ORDERED' | 'WAITING_APPROVED' | 'APPROVED' | 'CANCELLED'} [posOrderStatus]
     * @param {string} [keyword]
     * @param {string} [merchantId]
     * @param {string} [createdFrom]
     * @param {string} [createdTo]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsGetListOrder: async (
      posOrderStatus?:
        | "DELIVERING"
        | "DELIVERED"
        | "ORDERED"
        | "WAITING_APPROVED"
        | "APPROVED"
        | "CANCELLED",
      keyword?: string,
      merchantId?: string,
      createdFrom?: string,
      createdTo?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/posOrder/cms/v1/getList`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (posOrderStatus !== undefined) {
        localVarQueryParameter["posOrderStatus"] = posOrderStatus;
      }

      if (keyword !== undefined) {
        localVarQueryParameter["keyword"] = keyword;
      }

      if (merchantId !== undefined) {
        localVarQueryParameter["merchantId"] = merchantId;
      }

      if (createdFrom !== undefined) {
        localVarQueryParameter["createdFrom"] = createdFrom;
      }

      if (createdTo !== undefined) {
        localVarQueryParameter["createdTo"] = createdTo;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter["sortDirection"] = sortDirection;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter["sortBy"] = sortBy;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary CMS Lấy thông tin đơn hàng mua máy pos
     * @param {string} orderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsGetPosOrder: async (
      orderId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderId' is not null or undefined
      assertParamExists("cmsGetPosOrder", "orderId", orderId);
      const localVarPath = `/api/posOrder/cms/v1/get`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (orderId !== undefined) {
        localVarQueryParameter["orderId"] = orderId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary CMS Thay đổi trạng thái của đơn hàng
     * @param {CmsSetStatePosOrderRequest} cmsSetStatePosOrderRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsSetStatePosOrder: async (
      cmsSetStatePosOrderRequest: CmsSetStatePosOrderRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'cmsSetStatePosOrderRequest' is not null or undefined
      assertParamExists(
        "cmsSetStatePosOrder",
        "cmsSetStatePosOrderRequest",
        cmsSetStatePosOrderRequest,
      );
      const localVarPath = `/api/posOrder/cms/v1/setState`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        cmsSetStatePosOrderRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Portal Hủy đơn hàng mua máy pos
     * @param {string} orderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    portalCancelPosOrder: async (
      orderId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderId' is not null or undefined
      assertParamExists("portalCancelPosOrder", "orderId", orderId);
      const localVarPath = `/api/posOrder/portal/v1/cancel`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (orderId !== undefined) {
        localVarQueryParameter["orderId"] = orderId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Portal Lấy danh sách lịch sử đơn hàng đặt mua pos
     * @param {'WAITING_PAYMENT' | 'CANCELLED' | 'TIME_OUT' | 'PAID'} [posOrderStatusPayment]
     * @param {'DELIVERING' | 'DELIVERED' | 'ORDERED' | 'WAITING_APPROVED' | 'APPROVED' | 'CANCELLED'} [posOrderStatus]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    portalGetListOrder: async (
      posOrderStatusPayment?:
        | "WAITING_PAYMENT"
        | "CANCELLED"
        | "TIME_OUT"
        | "PAID",
      posOrderStatus?:
        | "DELIVERING"
        | "DELIVERED"
        | "ORDERED"
        | "WAITING_APPROVED"
        | "APPROVED"
        | "CANCELLED",
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/posOrder/portal/v1/getList`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (posOrderStatusPayment !== undefined) {
        localVarQueryParameter["posOrderStatusPayment"] = posOrderStatusPayment;
      }

      if (posOrderStatus !== undefined) {
        localVarQueryParameter["posOrderStatus"] = posOrderStatus;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter["sortDirection"] = sortDirection;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter["sortBy"] = sortBy;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Portal Lấy thông tin đơn hàng mua máy pos
     * @param {string} orderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    portalGetPosOrder: async (
      orderId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderId' is not null or undefined
      assertParamExists("portalGetPosOrder", "orderId", orderId);
      const localVarPath = `/api/posOrder/portal/v1/get`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (orderId !== undefined) {
        localVarQueryParameter["orderId"] = orderId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Portal Đặt mua máy pos
     * @param {PortalPlacePosOrderRequest} portalPlacePosOrderRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    portalPlacePosOrder: async (
      portalPlacePosOrderRequest: PortalPlacePosOrderRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'portalPlacePosOrderRequest' is not null or undefined
      assertParamExists(
        "portalPlacePosOrder",
        "portalPlacePosOrderRequest",
        portalPlacePosOrderRequest,
      );
      const localVarPath = `/api/posOrder/portal/v1/place`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        portalPlacePosOrderRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Portal Yêu cầu hoàn trả đơn
     * @param {string} orderId
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    portalRequestReturnPosOrder: async (
      orderId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderId' is not null or undefined
      assertParamExists("portalRequestReturnPosOrder", "orderId", orderId);
      const localVarPath = `/api/posOrder/portal/v1/requestReturn`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (orderId !== undefined) {
        localVarQueryParameter["orderId"] = orderId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Portal Cập nhật thông tin đơn hàng khi đã đặt
     * @param {PortalUpdatePosOrderRequest} portalUpdatePosOrderRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    portalUpdatePosOrder: async (
      portalUpdatePosOrderRequest: PortalUpdatePosOrderRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'portalUpdatePosOrderRequest' is not null or undefined
      assertParamExists(
        "portalUpdatePosOrder",
        "portalUpdatePosOrderRequest",
        portalUpdatePosOrderRequest,
      );
      const localVarPath = `/api/posOrder/portal/v1/update`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        portalUpdatePosOrderRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PosOrderControllerApi - functional programming interface
 * @export
 */
export const PosOrderControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    PosOrderControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary CMS Lấy danh sách đơn hàng đặt mua pos
     * @param {'DELIVERING' | 'DELIVERED' | 'ORDERED' | 'WAITING_APPROVED' | 'APPROVED' | 'CANCELLED'} [posOrderStatus]
     * @param {string} [keyword]
     * @param {string} [merchantId]
     * @param {string} [createdFrom]
     * @param {string} [createdTo]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cmsGetListOrder(
      posOrderStatus?:
        | "DELIVERING"
        | "DELIVERED"
        | "ORDERED"
        | "WAITING_APPROVED"
        | "APPROVED"
        | "CANCELLED",
      keyword?: string,
      merchantId?: string,
      createdFrom?: string,
      createdTo?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseCmsListOrderResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.cmsGetListOrder(
        posOrderStatus,
        keyword,
        merchantId,
        createdFrom,
        createdTo,
        page,
        pageSize,
        sortDirection,
        sortBy,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary CMS Lấy thông tin đơn hàng mua máy pos
     * @param {string} orderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cmsGetPosOrder(
      orderId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseCmsOrderDetailResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.cmsGetPosOrder(
        orderId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary CMS Thay đổi trạng thái của đơn hàng
     * @param {CmsSetStatePosOrderRequest} cmsSetStatePosOrderRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cmsSetStatePosOrder(
      cmsSetStatePosOrderRequest: CmsSetStatePosOrderRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cmsSetStatePosOrder(
          cmsSetStatePosOrderRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Portal Hủy đơn hàng mua máy pos
     * @param {string} orderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async portalCancelPosOrder(
      orderId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.portalCancelPosOrder(orderId, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Portal Lấy danh sách lịch sử đơn hàng đặt mua pos
     * @param {'WAITING_PAYMENT' | 'CANCELLED' | 'TIME_OUT' | 'PAID'} [posOrderStatusPayment]
     * @param {'DELIVERING' | 'DELIVERED' | 'ORDERED' | 'WAITING_APPROVED' | 'APPROVED' | 'CANCELLED'} [posOrderStatus]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async portalGetListOrder(
      posOrderStatusPayment?:
        | "WAITING_PAYMENT"
        | "CANCELLED"
        | "TIME_OUT"
        | "PAID",
      posOrderStatus?:
        | "DELIVERING"
        | "DELIVERED"
        | "ORDERED"
        | "WAITING_APPROVED"
        | "APPROVED"
        | "CANCELLED",
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponsePosOrderListResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.portalGetListOrder(
          posOrderStatusPayment,
          posOrderStatus,
          page,
          pageSize,
          sortDirection,
          sortBy,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Portal Lấy thông tin đơn hàng mua máy pos
     * @param {string} orderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async portalGetPosOrder(
      orderId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePosOrderResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.portalGetPosOrder(orderId, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Portal Đặt mua máy pos
     * @param {PortalPlacePosOrderRequest} portalPlacePosOrderRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async portalPlacePosOrder(
      portalPlacePosOrderRequest: PortalPlacePosOrderRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePortalPlacePosOrderResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.portalPlacePosOrder(
          portalPlacePosOrderRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Portal Yêu cầu hoàn trả đơn
     * @param {string} orderId
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async portalRequestReturnPosOrder(
      orderId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.portalRequestReturnPosOrder(
          orderId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Portal Cập nhật thông tin đơn hàng khi đã đặt
     * @param {PortalUpdatePosOrderRequest} portalUpdatePosOrderRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async portalUpdatePosOrder(
      portalUpdatePosOrderRequest: PortalUpdatePosOrderRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePosOrderResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.portalUpdatePosOrder(
          portalUpdatePosOrderRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PosOrderControllerApi - factory interface
 * @export
 */
export const PosOrderControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PosOrderControllerApiFp(configuration);
  return {
    /**
     *
     * @summary CMS Lấy danh sách đơn hàng đặt mua pos
     * @param {'DELIVERING' | 'DELIVERED' | 'ORDERED' | 'WAITING_APPROVED' | 'APPROVED' | 'CANCELLED'} [posOrderStatus]
     * @param {string} [keyword]
     * @param {string} [merchantId]
     * @param {string} [createdFrom]
     * @param {string} [createdTo]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsGetListOrder(
      posOrderStatus?:
        | "DELIVERING"
        | "DELIVERED"
        | "ORDERED"
        | "WAITING_APPROVED"
        | "APPROVED"
        | "CANCELLED",
      keyword?: string,
      merchantId?: string,
      createdFrom?: string,
      createdTo?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseCmsListOrderResponse> {
      return localVarFp
        .cmsGetListOrder(
          posOrderStatus,
          keyword,
          merchantId,
          createdFrom,
          createdTo,
          page,
          pageSize,
          sortDirection,
          sortBy,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary CMS Lấy thông tin đơn hàng mua máy pos
     * @param {string} orderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsGetPosOrder(
      orderId: string,
      options?: any,
    ): AxiosPromise<ResponseBaseCmsOrderDetailResponse> {
      return localVarFp
        .cmsGetPosOrder(orderId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary CMS Thay đổi trạng thái của đơn hàng
     * @param {CmsSetStatePosOrderRequest} cmsSetStatePosOrderRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsSetStatePosOrder(
      cmsSetStatePosOrderRequest: CmsSetStatePosOrderRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .cmsSetStatePosOrder(cmsSetStatePosOrderRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Portal Hủy đơn hàng mua máy pos
     * @param {string} orderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    portalCancelPosOrder(
      orderId: string,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .portalCancelPosOrder(orderId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Portal Lấy danh sách lịch sử đơn hàng đặt mua pos
     * @param {'WAITING_PAYMENT' | 'CANCELLED' | 'TIME_OUT' | 'PAID'} [posOrderStatusPayment]
     * @param {'DELIVERING' | 'DELIVERED' | 'ORDERED' | 'WAITING_APPROVED' | 'APPROVED' | 'CANCELLED'} [posOrderStatus]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    portalGetListOrder(
      posOrderStatusPayment?:
        | "WAITING_PAYMENT"
        | "CANCELLED"
        | "TIME_OUT"
        | "PAID",
      posOrderStatus?:
        | "DELIVERING"
        | "DELIVERED"
        | "ORDERED"
        | "WAITING_APPROVED"
        | "APPROVED"
        | "CANCELLED",
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponsePosOrderListResponse> {
      return localVarFp
        .portalGetListOrder(
          posOrderStatusPayment,
          posOrderStatus,
          page,
          pageSize,
          sortDirection,
          sortBy,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Portal Lấy thông tin đơn hàng mua máy pos
     * @param {string} orderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    portalGetPosOrder(
      orderId: string,
      options?: any,
    ): AxiosPromise<ResponseBasePosOrderResponse> {
      return localVarFp
        .portalGetPosOrder(orderId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Portal Đặt mua máy pos
     * @param {PortalPlacePosOrderRequest} portalPlacePosOrderRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    portalPlacePosOrder(
      portalPlacePosOrderRequest: PortalPlacePosOrderRequest,
      options?: any,
    ): AxiosPromise<ResponseBasePortalPlacePosOrderResponse> {
      return localVarFp
        .portalPlacePosOrder(portalPlacePosOrderRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Portal Yêu cầu hoàn trả đơn
     * @param {string} orderId
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    portalRequestReturnPosOrder(
      orderId: string,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .portalRequestReturnPosOrder(orderId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Portal Cập nhật thông tin đơn hàng khi đã đặt
     * @param {PortalUpdatePosOrderRequest} portalUpdatePosOrderRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    portalUpdatePosOrder(
      portalUpdatePosOrderRequest: PortalUpdatePosOrderRequest,
      options?: any,
    ): AxiosPromise<ResponseBasePosOrderResponse> {
      return localVarFp
        .portalUpdatePosOrder(portalUpdatePosOrderRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for cmsGetListOrder operation in PosOrderControllerApi.
 * @export
 * @interface PosOrderControllerApiCmsGetListOrderRequest
 */
export interface PosOrderControllerApiCmsGetListOrderRequest {
  /**
   *
   * @type {'DELIVERING' | 'DELIVERED' | 'ORDERED' | 'WAITING_APPROVED' | 'APPROVED' | 'CANCELLED'}
   * @memberof PosOrderControllerApiCmsGetListOrder
   */
  readonly posOrderStatus?:
    | "DELIVERING"
    | "DELIVERED"
    | "ORDERED"
    | "WAITING_APPROVED"
    | "APPROVED"
    | "CANCELLED";

  /**
   *
   * @type {string}
   * @memberof PosOrderControllerApiCmsGetListOrder
   */
  readonly keyword?: string;

  /**
   *
   * @type {string}
   * @memberof PosOrderControllerApiCmsGetListOrder
   */
  readonly merchantId?: string;

  /**
   *
   * @type {string}
   * @memberof PosOrderControllerApiCmsGetListOrder
   */
  readonly createdFrom?: string;

  /**
   *
   * @type {string}
   * @memberof PosOrderControllerApiCmsGetListOrder
   */
  readonly createdTo?: string;

  /**
   *
   * @type {number}
   * @memberof PosOrderControllerApiCmsGetListOrder
   */
  readonly page?: number;

  /**
   *
   * @type {number}
   * @memberof PosOrderControllerApiCmsGetListOrder
   */
  readonly pageSize?: number;

  /**
   *
   * @type {'ASC' | 'DESC'}
   * @memberof PosOrderControllerApiCmsGetListOrder
   */
  readonly sortDirection?: "ASC" | "DESC";

  /**
   *
   * @type {string}
   * @memberof PosOrderControllerApiCmsGetListOrder
   */
  readonly sortBy?: string;
}

/**
 * Request parameters for cmsGetPosOrder operation in PosOrderControllerApi.
 * @export
 * @interface PosOrderControllerApiCmsGetPosOrderRequest
 */
export interface PosOrderControllerApiCmsGetPosOrderRequest {
  /**
   *
   * @type {string}
   * @memberof PosOrderControllerApiCmsGetPosOrder
   */
  readonly orderId: string;
}

/**
 * Request parameters for cmsSetStatePosOrder operation in PosOrderControllerApi.
 * @export
 * @interface PosOrderControllerApiCmsSetStatePosOrderRequest
 */
export interface PosOrderControllerApiCmsSetStatePosOrderRequest {
  /**
   *
   * @type {CmsSetStatePosOrderRequest}
   * @memberof PosOrderControllerApiCmsSetStatePosOrder
   */
  readonly cmsSetStatePosOrderRequest: CmsSetStatePosOrderRequest;
}

/**
 * Request parameters for portalCancelPosOrder operation in PosOrderControllerApi.
 * @export
 * @interface PosOrderControllerApiPortalCancelPosOrderRequest
 */
export interface PosOrderControllerApiPortalCancelPosOrderRequest {
  /**
   *
   * @type {string}
   * @memberof PosOrderControllerApiPortalCancelPosOrder
   */
  readonly orderId: string;
}

/**
 * Request parameters for portalGetListOrder operation in PosOrderControllerApi.
 * @export
 * @interface PosOrderControllerApiPortalGetListOrderRequest
 */
export interface PosOrderControllerApiPortalGetListOrderRequest {
  /**
   *
   * @type {'WAITING_PAYMENT' | 'CANCELLED' | 'TIME_OUT' | 'PAID'}
   * @memberof PosOrderControllerApiPortalGetListOrder
   */
  readonly posOrderStatusPayment?:
    | "WAITING_PAYMENT"
    | "CANCELLED"
    | "TIME_OUT"
    | "PAID";

  /**
   *
   * @type {'DELIVERING' | 'DELIVERED' | 'ORDERED' | 'WAITING_APPROVED' | 'APPROVED' | 'CANCELLED'}
   * @memberof PosOrderControllerApiPortalGetListOrder
   */
  readonly posOrderStatus?:
    | "DELIVERING"
    | "DELIVERED"
    | "ORDERED"
    | "WAITING_APPROVED"
    | "APPROVED"
    | "CANCELLED";

  /**
   *
   * @type {number}
   * @memberof PosOrderControllerApiPortalGetListOrder
   */
  readonly page?: number;

  /**
   *
   * @type {number}
   * @memberof PosOrderControllerApiPortalGetListOrder
   */
  readonly pageSize?: number;

  /**
   *
   * @type {'ASC' | 'DESC'}
   * @memberof PosOrderControllerApiPortalGetListOrder
   */
  readonly sortDirection?: "ASC" | "DESC";

  /**
   *
   * @type {string}
   * @memberof PosOrderControllerApiPortalGetListOrder
   */
  readonly sortBy?: string;
}

/**
 * Request parameters for portalGetPosOrder operation in PosOrderControllerApi.
 * @export
 * @interface PosOrderControllerApiPortalGetPosOrderRequest
 */
export interface PosOrderControllerApiPortalGetPosOrderRequest {
  /**
   *
   * @type {string}
   * @memberof PosOrderControllerApiPortalGetPosOrder
   */
  readonly orderId: string;
}

/**
 * Request parameters for portalPlacePosOrder operation in PosOrderControllerApi.
 * @export
 * @interface PosOrderControllerApiPortalPlacePosOrderRequest
 */
export interface PosOrderControllerApiPortalPlacePosOrderRequest {
  /**
   *
   * @type {PortalPlacePosOrderRequest}
   * @memberof PosOrderControllerApiPortalPlacePosOrder
   */
  readonly portalPlacePosOrderRequest: PortalPlacePosOrderRequest;
}

/**
 * Request parameters for portalRequestReturnPosOrder operation in PosOrderControllerApi.
 * @export
 * @interface PosOrderControllerApiPortalRequestReturnPosOrderRequest
 */
export interface PosOrderControllerApiPortalRequestReturnPosOrderRequest {
  /**
   *
   * @type {string}
   * @memberof PosOrderControllerApiPortalRequestReturnPosOrder
   */
  readonly orderId: string;
}

/**
 * Request parameters for portalUpdatePosOrder operation in PosOrderControllerApi.
 * @export
 * @interface PosOrderControllerApiPortalUpdatePosOrderRequest
 */
export interface PosOrderControllerApiPortalUpdatePosOrderRequest {
  /**
   *
   * @type {PortalUpdatePosOrderRequest}
   * @memberof PosOrderControllerApiPortalUpdatePosOrder
   */
  readonly portalUpdatePosOrderRequest: PortalUpdatePosOrderRequest;
}

/**
 * PosOrderControllerApi - object-oriented interface
 * @export
 * @class PosOrderControllerApi
 * @extends {BaseAPI}
 */
export class PosOrderControllerApi extends BaseAPI {
  /**
   *
   * @summary CMS Lấy danh sách đơn hàng đặt mua pos
   * @param {PosOrderControllerApiCmsGetListOrderRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PosOrderControllerApi
   */
  public cmsGetListOrder(
    requestParameters: PosOrderControllerApiCmsGetListOrderRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return PosOrderControllerApiFp(this.configuration)
      .cmsGetListOrder(
        requestParameters.posOrderStatus,
        requestParameters.keyword,
        requestParameters.merchantId,
        requestParameters.createdFrom,
        requestParameters.createdTo,
        requestParameters.page,
        requestParameters.pageSize,
        requestParameters.sortDirection,
        requestParameters.sortBy,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary CMS Lấy thông tin đơn hàng mua máy pos
   * @param {PosOrderControllerApiCmsGetPosOrderRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PosOrderControllerApi
   */
  public cmsGetPosOrder(
    requestParameters: PosOrderControllerApiCmsGetPosOrderRequest,
    options?: AxiosRequestConfig,
  ) {
    return PosOrderControllerApiFp(this.configuration)
      .cmsGetPosOrder(requestParameters.orderId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary CMS Thay đổi trạng thái của đơn hàng
   * @param {PosOrderControllerApiCmsSetStatePosOrderRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PosOrderControllerApi
   */
  public cmsSetStatePosOrder(
    requestParameters: PosOrderControllerApiCmsSetStatePosOrderRequest,
    options?: AxiosRequestConfig,
  ) {
    return PosOrderControllerApiFp(this.configuration)
      .cmsSetStatePosOrder(
        requestParameters.cmsSetStatePosOrderRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Portal Hủy đơn hàng mua máy pos
   * @param {PosOrderControllerApiPortalCancelPosOrderRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PosOrderControllerApi
   */
  public portalCancelPosOrder(
    requestParameters: PosOrderControllerApiPortalCancelPosOrderRequest,
    options?: AxiosRequestConfig,
  ) {
    return PosOrderControllerApiFp(this.configuration)
      .portalCancelPosOrder(requestParameters.orderId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Portal Lấy danh sách lịch sử đơn hàng đặt mua pos
   * @param {PosOrderControllerApiPortalGetListOrderRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PosOrderControllerApi
   */
  public portalGetListOrder(
    requestParameters: PosOrderControllerApiPortalGetListOrderRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return PosOrderControllerApiFp(this.configuration)
      .portalGetListOrder(
        requestParameters.posOrderStatusPayment,
        requestParameters.posOrderStatus,
        requestParameters.page,
        requestParameters.pageSize,
        requestParameters.sortDirection,
        requestParameters.sortBy,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Portal Lấy thông tin đơn hàng mua máy pos
   * @param {PosOrderControllerApiPortalGetPosOrderRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PosOrderControllerApi
   */
  public portalGetPosOrder(
    requestParameters: PosOrderControllerApiPortalGetPosOrderRequest,
    options?: AxiosRequestConfig,
  ) {
    return PosOrderControllerApiFp(this.configuration)
      .portalGetPosOrder(requestParameters.orderId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Portal Đặt mua máy pos
   * @param {PosOrderControllerApiPortalPlacePosOrderRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PosOrderControllerApi
   */
  public portalPlacePosOrder(
    requestParameters: PosOrderControllerApiPortalPlacePosOrderRequest,
    options?: AxiosRequestConfig,
  ) {
    return PosOrderControllerApiFp(this.configuration)
      .portalPlacePosOrder(
        requestParameters.portalPlacePosOrderRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Portal Yêu cầu hoàn trả đơn
   * @param {PosOrderControllerApiPortalRequestReturnPosOrderRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof PosOrderControllerApi
   */
  public portalRequestReturnPosOrder(
    requestParameters: PosOrderControllerApiPortalRequestReturnPosOrderRequest,
    options?: AxiosRequestConfig,
  ) {
    return PosOrderControllerApiFp(this.configuration)
      .portalRequestReturnPosOrder(requestParameters.orderId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Portal Cập nhật thông tin đơn hàng khi đã đặt
   * @param {PosOrderControllerApiPortalUpdatePosOrderRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PosOrderControllerApi
   */
  public portalUpdatePosOrder(
    requestParameters: PosOrderControllerApiPortalUpdatePosOrderRequest,
    options?: AxiosRequestConfig,
  ) {
    return PosOrderControllerApiFp(this.configuration)
      .portalUpdatePosOrder(
        requestParameters.portalUpdatePosOrderRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * QunLWebhookApi - axios parameter creator
 * @export
 */
export const QunLWebhookApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary [CMS] Duyệt Webhook Url
     * @param {CmsApproveWebhookUrlRequest} cmsApproveWebhookUrlRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsApproveWebhookUrl: async (
      cmsApproveWebhookUrlRequest: CmsApproveWebhookUrlRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'cmsApproveWebhookUrlRequest' is not null or undefined
      assertParamExists(
        "cmsApproveWebhookUrl",
        "cmsApproveWebhookUrlRequest",
        cmsApproveWebhookUrlRequest,
      );
      const localVarPath = `/api/webhook/cms/v1/approve`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        cmsApproveWebhookUrlRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [CMS] Từ chối duyệt Webhook Url V2
     * @param {CmsDisapproveWebhookUrlV2Request} cmsDisapproveWebhookUrlV2Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsApproveWebhookUrlV2: async (
      cmsDisapproveWebhookUrlV2Request: CmsDisapproveWebhookUrlV2Request,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'cmsDisapproveWebhookUrlV2Request' is not null or undefined
      assertParamExists(
        "cmsApproveWebhookUrlV2",
        "cmsDisapproveWebhookUrlV2Request",
        cmsDisapproveWebhookUrlV2Request,
      );
      const localVarPath = `/api/webhook/cms/v2/disapprove`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        cmsDisapproveWebhookUrlV2Request,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [CMS] Duyệt Webhook Url V2
     * @param {CmsApproveWebhookUrlV2Request} cmsApproveWebhookUrlV2Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsApproveWebhookUrlV21: async (
      cmsApproveWebhookUrlV2Request: CmsApproveWebhookUrlV2Request,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'cmsApproveWebhookUrlV2Request' is not null or undefined
      assertParamExists(
        "cmsApproveWebhookUrlV21",
        "cmsApproveWebhookUrlV2Request",
        cmsApproveWebhookUrlV2Request,
      );
      const localVarPath = `/api/webhook/cms/v2/approve`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        cmsApproveWebhookUrlV2Request,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [CMS] Lấy danh sách Webhook Url
     * @param {string} [merchantCode]
     * @param {string} [merchantName]
     * @param {'PENDING' | 'APPROVED' | 'REJECTED'} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsGetListWebhookUrl: async (
      merchantCode?: string,
      merchantName?: string,
      status?: "PENDING" | "APPROVED" | "REJECTED",
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/webhook/cms/v1/getList`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (merchantCode !== undefined) {
        localVarQueryParameter["merchantCode"] = merchantCode;
      }

      if (merchantName !== undefined) {
        localVarQueryParameter["merchantName"] = merchantName;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [CMS] Lấy thông tin Webhook Url của một merchant
     * @param {string} merchantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsGetWebhookUrl: async (
      merchantId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'merchantId' is not null or undefined
      assertParamExists("cmsGetWebhookUrl", "merchantId", merchantId);
      const localVarPath = `/api/webhook/cms/v1/getInfo/{merchantId}`.replace(
        `{${"merchantId"}}`,
        encodeURIComponent(String(merchantId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [Portal] Xóa thông tin Webhook Url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    portalDeleteWebhookUrl: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/webhook/portal/v1/delete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [Portal] Lấy thông tin Webhook Url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    portalGetWebhookUrl: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/webhook/portal/v1/getInfo`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [Portal] Cập nhật Webhook Url
     * @param {PortalUpdateWebhookUrlRequest} portalUpdateWebhookUrlRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    portalUpdateWebhookUrl: async (
      portalUpdateWebhookUrlRequest: PortalUpdateWebhookUrlRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'portalUpdateWebhookUrlRequest' is not null or undefined
      assertParamExists(
        "portalUpdateWebhookUrl",
        "portalUpdateWebhookUrlRequest",
        portalUpdateWebhookUrlRequest,
      );
      const localVarPath = `/api/webhook/portal/v1/update`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        portalUpdateWebhookUrlRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * QunLWebhookApi - functional programming interface
 * @export
 */
export const QunLWebhookApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    QunLWebhookApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary [CMS] Duyệt Webhook Url
     * @param {CmsApproveWebhookUrlRequest} cmsApproveWebhookUrlRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cmsApproveWebhookUrl(
      cmsApproveWebhookUrlRequest: CmsApproveWebhookUrlRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseCmsWebhookUrlResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cmsApproveWebhookUrl(
          cmsApproveWebhookUrlRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary [CMS] Từ chối duyệt Webhook Url V2
     * @param {CmsDisapproveWebhookUrlV2Request} cmsDisapproveWebhookUrlV2Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cmsApproveWebhookUrlV2(
      cmsDisapproveWebhookUrlV2Request: CmsDisapproveWebhookUrlV2Request,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseCmsWebhookUrlResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cmsApproveWebhookUrlV2(
          cmsDisapproveWebhookUrlV2Request,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary [CMS] Duyệt Webhook Url V2
     * @param {CmsApproveWebhookUrlV2Request} cmsApproveWebhookUrlV2Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cmsApproveWebhookUrlV21(
      cmsApproveWebhookUrlV2Request: CmsApproveWebhookUrlV2Request,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseCmsWebhookUrlResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cmsApproveWebhookUrlV21(
          cmsApproveWebhookUrlV2Request,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary [CMS] Lấy danh sách Webhook Url
     * @param {string} [merchantCode]
     * @param {string} [merchantName]
     * @param {'PENDING' | 'APPROVED' | 'REJECTED'} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cmsGetListWebhookUrl(
      merchantCode?: string,
      merchantName?: string,
      status?: "PENDING" | "APPROVED" | "REJECTED",
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseCmsWebhookUrlResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cmsGetListWebhookUrl(
          merchantCode,
          merchantName,
          status,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary [CMS] Lấy thông tin Webhook Url của một merchant
     * @param {string} merchantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cmsGetWebhookUrl(
      merchantId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseCmsWebhookUrlResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cmsGetWebhookUrl(merchantId, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary [Portal] Xóa thông tin Webhook Url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async portalDeleteWebhookUrl(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.portalDeleteWebhookUrl(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary [Portal] Lấy thông tin Webhook Url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async portalGetWebhookUrl(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseWebhookUrlResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.portalGetWebhookUrl(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary [Portal] Cập nhật Webhook Url
     * @param {PortalUpdateWebhookUrlRequest} portalUpdateWebhookUrlRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async portalUpdateWebhookUrl(
      portalUpdateWebhookUrlRequest: PortalUpdateWebhookUrlRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseWebhookUrlResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.portalUpdateWebhookUrl(
          portalUpdateWebhookUrlRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * QunLWebhookApi - factory interface
 * @export
 */
export const QunLWebhookApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = QunLWebhookApiFp(configuration);
  return {
    /**
     *
     * @summary [CMS] Duyệt Webhook Url
     * @param {CmsApproveWebhookUrlRequest} cmsApproveWebhookUrlRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsApproveWebhookUrl(
      cmsApproveWebhookUrlRequest: CmsApproveWebhookUrlRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseCmsWebhookUrlResponse> {
      return localVarFp
        .cmsApproveWebhookUrl(cmsApproveWebhookUrlRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [CMS] Từ chối duyệt Webhook Url V2
     * @param {CmsDisapproveWebhookUrlV2Request} cmsDisapproveWebhookUrlV2Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsApproveWebhookUrlV2(
      cmsDisapproveWebhookUrlV2Request: CmsDisapproveWebhookUrlV2Request,
      options?: any,
    ): AxiosPromise<ResponseBaseCmsWebhookUrlResponse> {
      return localVarFp
        .cmsApproveWebhookUrlV2(cmsDisapproveWebhookUrlV2Request, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [CMS] Duyệt Webhook Url V2
     * @param {CmsApproveWebhookUrlV2Request} cmsApproveWebhookUrlV2Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsApproveWebhookUrlV21(
      cmsApproveWebhookUrlV2Request: CmsApproveWebhookUrlV2Request,
      options?: any,
    ): AxiosPromise<ResponseBaseCmsWebhookUrlResponse> {
      return localVarFp
        .cmsApproveWebhookUrlV21(cmsApproveWebhookUrlV2Request, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [CMS] Lấy danh sách Webhook Url
     * @param {string} [merchantCode]
     * @param {string} [merchantName]
     * @param {'PENDING' | 'APPROVED' | 'REJECTED'} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsGetListWebhookUrl(
      merchantCode?: string,
      merchantName?: string,
      status?: "PENDING" | "APPROVED" | "REJECTED",
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseCmsWebhookUrlResponse> {
      return localVarFp
        .cmsGetListWebhookUrl(merchantCode, merchantName, status, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [CMS] Lấy thông tin Webhook Url của một merchant
     * @param {string} merchantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cmsGetWebhookUrl(
      merchantId: string,
      options?: any,
    ): AxiosPromise<ResponseBaseCmsWebhookUrlResponse> {
      return localVarFp
        .cmsGetWebhookUrl(merchantId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [Portal] Xóa thông tin Webhook Url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    portalDeleteWebhookUrl(
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .portalDeleteWebhookUrl(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [Portal] Lấy thông tin Webhook Url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    portalGetWebhookUrl(
      options?: any,
    ): AxiosPromise<ResponseBaseWebhookUrlResponse> {
      return localVarFp
        .portalGetWebhookUrl(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [Portal] Cập nhật Webhook Url
     * @param {PortalUpdateWebhookUrlRequest} portalUpdateWebhookUrlRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    portalUpdateWebhookUrl(
      portalUpdateWebhookUrlRequest: PortalUpdateWebhookUrlRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseWebhookUrlResponse> {
      return localVarFp
        .portalUpdateWebhookUrl(portalUpdateWebhookUrlRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for cmsApproveWebhookUrl operation in QunLWebhookApi.
 * @export
 * @interface QunLWebhookApiCmsApproveWebhookUrlRequest
 */
export interface QunLWebhookApiCmsApproveWebhookUrlRequest {
  /**
   *
   * @type {CmsApproveWebhookUrlRequest}
   * @memberof QunLWebhookApiCmsApproveWebhookUrl
   */
  readonly cmsApproveWebhookUrlRequest: CmsApproveWebhookUrlRequest;
}

/**
 * Request parameters for cmsApproveWebhookUrlV2 operation in QunLWebhookApi.
 * @export
 * @interface QunLWebhookApiCmsApproveWebhookUrlV2Request
 */
export interface QunLWebhookApiCmsApproveWebhookUrlV2Request {
  /**
   *
   * @type {CmsDisapproveWebhookUrlV2Request}
   * @memberof QunLWebhookApiCmsApproveWebhookUrlV2
   */
  readonly cmsDisapproveWebhookUrlV2Request: CmsDisapproveWebhookUrlV2Request;
}

/**
 * Request parameters for cmsApproveWebhookUrlV21 operation in QunLWebhookApi.
 * @export
 * @interface QunLWebhookApiCmsApproveWebhookUrlV21Request
 */
export interface QunLWebhookApiCmsApproveWebhookUrlV21Request {
  /**
   *
   * @type {CmsApproveWebhookUrlV2Request}
   * @memberof QunLWebhookApiCmsApproveWebhookUrlV21
   */
  readonly cmsApproveWebhookUrlV2Request: CmsApproveWebhookUrlV2Request;
}

/**
 * Request parameters for cmsGetListWebhookUrl operation in QunLWebhookApi.
 * @export
 * @interface QunLWebhookApiCmsGetListWebhookUrlRequest
 */
export interface QunLWebhookApiCmsGetListWebhookUrlRequest {
  /**
   *
   * @type {string}
   * @memberof QunLWebhookApiCmsGetListWebhookUrl
   */
  readonly merchantCode?: string;

  /**
   *
   * @type {string}
   * @memberof QunLWebhookApiCmsGetListWebhookUrl
   */
  readonly merchantName?: string;

  /**
   *
   * @type {'PENDING' | 'APPROVED' | 'REJECTED'}
   * @memberof QunLWebhookApiCmsGetListWebhookUrl
   */
  readonly status?: "PENDING" | "APPROVED" | "REJECTED";
}

/**
 * Request parameters for cmsGetWebhookUrl operation in QunLWebhookApi.
 * @export
 * @interface QunLWebhookApiCmsGetWebhookUrlRequest
 */
export interface QunLWebhookApiCmsGetWebhookUrlRequest {
  /**
   *
   * @type {string}
   * @memberof QunLWebhookApiCmsGetWebhookUrl
   */
  readonly merchantId: string;
}

/**
 * Request parameters for portalUpdateWebhookUrl operation in QunLWebhookApi.
 * @export
 * @interface QunLWebhookApiPortalUpdateWebhookUrlRequest
 */
export interface QunLWebhookApiPortalUpdateWebhookUrlRequest {
  /**
   *
   * @type {PortalUpdateWebhookUrlRequest}
   * @memberof QunLWebhookApiPortalUpdateWebhookUrl
   */
  readonly portalUpdateWebhookUrlRequest: PortalUpdateWebhookUrlRequest;
}

/**
 * QunLWebhookApi - object-oriented interface
 * @export
 * @class QunLWebhookApi
 * @extends {BaseAPI}
 */
export class QunLWebhookApi extends BaseAPI {
  /**
   *
   * @summary [CMS] Duyệt Webhook Url
   * @param {QunLWebhookApiCmsApproveWebhookUrlRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QunLWebhookApi
   */
  public cmsApproveWebhookUrl(
    requestParameters: QunLWebhookApiCmsApproveWebhookUrlRequest,
    options?: AxiosRequestConfig,
  ) {
    return QunLWebhookApiFp(this.configuration)
      .cmsApproveWebhookUrl(
        requestParameters.cmsApproveWebhookUrlRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [CMS] Từ chối duyệt Webhook Url V2
   * @param {QunLWebhookApiCmsApproveWebhookUrlV2Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QunLWebhookApi
   */
  public cmsApproveWebhookUrlV2(
    requestParameters: QunLWebhookApiCmsApproveWebhookUrlV2Request,
    options?: AxiosRequestConfig,
  ) {
    return QunLWebhookApiFp(this.configuration)
      .cmsApproveWebhookUrlV2(
        requestParameters.cmsDisapproveWebhookUrlV2Request,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [CMS] Duyệt Webhook Url V2
   * @param {QunLWebhookApiCmsApproveWebhookUrlV21Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QunLWebhookApi
   */
  public cmsApproveWebhookUrlV21(
    requestParameters: QunLWebhookApiCmsApproveWebhookUrlV21Request,
    options?: AxiosRequestConfig,
  ) {
    return QunLWebhookApiFp(this.configuration)
      .cmsApproveWebhookUrlV21(
        requestParameters.cmsApproveWebhookUrlV2Request,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [CMS] Lấy danh sách Webhook Url
   * @param {QunLWebhookApiCmsGetListWebhookUrlRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QunLWebhookApi
   */
  public cmsGetListWebhookUrl(
    requestParameters: QunLWebhookApiCmsGetListWebhookUrlRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return QunLWebhookApiFp(this.configuration)
      .cmsGetListWebhookUrl(
        requestParameters.merchantCode,
        requestParameters.merchantName,
        requestParameters.status,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [CMS] Lấy thông tin Webhook Url của một merchant
   * @param {QunLWebhookApiCmsGetWebhookUrlRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QunLWebhookApi
   */
  public cmsGetWebhookUrl(
    requestParameters: QunLWebhookApiCmsGetWebhookUrlRequest,
    options?: AxiosRequestConfig,
  ) {
    return QunLWebhookApiFp(this.configuration)
      .cmsGetWebhookUrl(requestParameters.merchantId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [Portal] Xóa thông tin Webhook Url
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QunLWebhookApi
   */
  public portalDeleteWebhookUrl(options?: AxiosRequestConfig) {
    return QunLWebhookApiFp(this.configuration)
      .portalDeleteWebhookUrl(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [Portal] Lấy thông tin Webhook Url
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QunLWebhookApi
   */
  public portalGetWebhookUrl(options?: AxiosRequestConfig) {
    return QunLWebhookApiFp(this.configuration)
      .portalGetWebhookUrl(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [Portal] Cập nhật Webhook Url
   * @param {QunLWebhookApiPortalUpdateWebhookUrlRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QunLWebhookApi
   */
  public portalUpdateWebhookUrl(
    requestParameters: QunLWebhookApiPortalUpdateWebhookUrlRequest,
    options?: AxiosRequestConfig,
  ) {
    return QunLWebhookApiFp(this.configuration)
      .portalUpdateWebhookUrl(
        requestParameters.portalUpdateWebhookUrlRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ResponseMessageControllerApi - axios parameter creator
 * @export
 */
export const ResponseMessageControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Tạo mới response message
     * @param {CreateResponseMessageRequest} createResponseMessageRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createResponseMessage: async (
      createResponseMessageRequest: CreateResponseMessageRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createResponseMessageRequest' is not null or undefined
      assertParamExists(
        "createResponseMessage",
        "createResponseMessageRequest",
        createResponseMessageRequest,
      );
      const localVarPath = `/api/message/cms/v1/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createResponseMessageRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xóa response message
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteResponseMessage: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("deleteResponseMessage", "id", id);
      const localVarPath = `/api/message/cms/v1/delete/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xem tất cả response message
     * @param {string} [query]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllResponseMessages: async (
      query?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/message/v1/getAll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (query !== undefined) {
        localVarQueryParameter["query"] = query;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter["sortDirection"] = sortDirection;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter["sortBy"] = sortBy;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xem thông tin response message với ID
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDetailResponseMessage: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getDetailResponseMessage", "id", id);
      const localVarPath = `/api/message/cms/v1/getDetail/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xem thông tin response message với response code
     * @param {number} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDetailResponseMessageByCode: async (
      code: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists("getDetailResponseMessageByCode", "code", code);
      const localVarPath = `/api/message/cms/v1/getByCode/{code}`.replace(
        `{${"code"}}`,
        encodeURIComponent(String(code)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Chỉnh sửa response message
     * @param {number} id
     * @param {UpdateResponseMessageRequest} updateResponseMessageRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateResponseMessage: async (
      id: number,
      updateResponseMessageRequest: UpdateResponseMessageRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("updateResponseMessage", "id", id);
      // verify required parameter 'updateResponseMessageRequest' is not null or undefined
      assertParamExists(
        "updateResponseMessage",
        "updateResponseMessageRequest",
        updateResponseMessageRequest,
      );
      const localVarPath = `/api/message/cms/v1/update/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateResponseMessageRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ResponseMessageControllerApi - functional programming interface
 * @export
 */
export const ResponseMessageControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    ResponseMessageControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Tạo mới response message
     * @param {CreateResponseMessageRequest} createResponseMessageRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createResponseMessage(
      createResponseMessageRequest: CreateResponseMessageRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseResponseMessageResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createResponseMessage(
          createResponseMessageRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Xóa response message
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteResponseMessage(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseResponseMessageResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteResponseMessage(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Xem tất cả response message
     * @param {string} [query]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllResponseMessages(
      query?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseResponseMessageResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllResponseMessages(
          query,
          page,
          pageSize,
          sortDirection,
          sortBy,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Xem thông tin response message với ID
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDetailResponseMessage(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseResponseMessageResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getDetailResponseMessage(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Xem thông tin response message với response code
     * @param {number} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDetailResponseMessageByCode(
      code: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseResponseMessageResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getDetailResponseMessageByCode(
          code,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Chỉnh sửa response message
     * @param {number} id
     * @param {UpdateResponseMessageRequest} updateResponseMessageRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateResponseMessage(
      id: number,
      updateResponseMessageRequest: UpdateResponseMessageRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseResponseMessageResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateResponseMessage(
          id,
          updateResponseMessageRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * ResponseMessageControllerApi - factory interface
 * @export
 */
export const ResponseMessageControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ResponseMessageControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Tạo mới response message
     * @param {CreateResponseMessageRequest} createResponseMessageRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createResponseMessage(
      createResponseMessageRequest: CreateResponseMessageRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseResponseMessageResponse> {
      return localVarFp
        .createResponseMessage(createResponseMessageRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xóa response message
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteResponseMessage(
      id: number,
      options?: any,
    ): AxiosPromise<ResponseBaseResponseMessageResponse> {
      return localVarFp
        .deleteResponseMessage(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xem tất cả response message
     * @param {string} [query]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'ASC' | 'DESC'} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllResponseMessages(
      query?: string,
      page?: number,
      pageSize?: number,
      sortDirection?: "ASC" | "DESC",
      sortBy?: string,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseResponseMessageResponse> {
      return localVarFp
        .getAllResponseMessages(
          query,
          page,
          pageSize,
          sortDirection,
          sortBy,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xem thông tin response message với ID
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDetailResponseMessage(
      id: number,
      options?: any,
    ): AxiosPromise<ResponseBaseResponseMessageResponse> {
      return localVarFp
        .getDetailResponseMessage(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xem thông tin response message với response code
     * @param {number} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDetailResponseMessageByCode(
      code: number,
      options?: any,
    ): AxiosPromise<ResponseBaseResponseMessageResponse> {
      return localVarFp
        .getDetailResponseMessageByCode(code, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Chỉnh sửa response message
     * @param {number} id
     * @param {UpdateResponseMessageRequest} updateResponseMessageRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateResponseMessage(
      id: number,
      updateResponseMessageRequest: UpdateResponseMessageRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseResponseMessageResponse> {
      return localVarFp
        .updateResponseMessage(id, updateResponseMessageRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createResponseMessage operation in ResponseMessageControllerApi.
 * @export
 * @interface ResponseMessageControllerApiCreateResponseMessageRequest
 */
export interface ResponseMessageControllerApiCreateResponseMessageRequest {
  /**
   *
   * @type {CreateResponseMessageRequest}
   * @memberof ResponseMessageControllerApiCreateResponseMessage
   */
  readonly createResponseMessageRequest: CreateResponseMessageRequest;
}

/**
 * Request parameters for deleteResponseMessage operation in ResponseMessageControllerApi.
 * @export
 * @interface ResponseMessageControllerApiDeleteResponseMessageRequest
 */
export interface ResponseMessageControllerApiDeleteResponseMessageRequest {
  /**
   *
   * @type {number}
   * @memberof ResponseMessageControllerApiDeleteResponseMessage
   */
  readonly id: number;
}

/**
 * Request parameters for getAllResponseMessages operation in ResponseMessageControllerApi.
 * @export
 * @interface ResponseMessageControllerApiGetAllResponseMessagesRequest
 */
export interface ResponseMessageControllerApiGetAllResponseMessagesRequest {
  /**
   *
   * @type {string}
   * @memberof ResponseMessageControllerApiGetAllResponseMessages
   */
  readonly query?: string;

  /**
   *
   * @type {number}
   * @memberof ResponseMessageControllerApiGetAllResponseMessages
   */
  readonly page?: number;

  /**
   *
   * @type {number}
   * @memberof ResponseMessageControllerApiGetAllResponseMessages
   */
  readonly pageSize?: number;

  /**
   *
   * @type {'ASC' | 'DESC'}
   * @memberof ResponseMessageControllerApiGetAllResponseMessages
   */
  readonly sortDirection?: "ASC" | "DESC";

  /**
   *
   * @type {string}
   * @memberof ResponseMessageControllerApiGetAllResponseMessages
   */
  readonly sortBy?: string;
}

/**
 * Request parameters for getDetailResponseMessage operation in ResponseMessageControllerApi.
 * @export
 * @interface ResponseMessageControllerApiGetDetailResponseMessageRequest
 */
export interface ResponseMessageControllerApiGetDetailResponseMessageRequest {
  /**
   *
   * @type {number}
   * @memberof ResponseMessageControllerApiGetDetailResponseMessage
   */
  readonly id: number;
}

/**
 * Request parameters for getDetailResponseMessageByCode operation in ResponseMessageControllerApi.
 * @export
 * @interface ResponseMessageControllerApiGetDetailResponseMessageByCodeRequest
 */
export interface ResponseMessageControllerApiGetDetailResponseMessageByCodeRequest {
  /**
   *
   * @type {number}
   * @memberof ResponseMessageControllerApiGetDetailResponseMessageByCode
   */
  readonly code: number;
}

/**
 * Request parameters for updateResponseMessage operation in ResponseMessageControllerApi.
 * @export
 * @interface ResponseMessageControllerApiUpdateResponseMessageRequest
 */
export interface ResponseMessageControllerApiUpdateResponseMessageRequest {
  /**
   *
   * @type {number}
   * @memberof ResponseMessageControllerApiUpdateResponseMessage
   */
  readonly id: number;

  /**
   *
   * @type {UpdateResponseMessageRequest}
   * @memberof ResponseMessageControllerApiUpdateResponseMessage
   */
  readonly updateResponseMessageRequest: UpdateResponseMessageRequest;
}

/**
 * ResponseMessageControllerApi - object-oriented interface
 * @export
 * @class ResponseMessageControllerApi
 * @extends {BaseAPI}
 */
export class ResponseMessageControllerApi extends BaseAPI {
  /**
   *
   * @summary Tạo mới response message
   * @param {ResponseMessageControllerApiCreateResponseMessageRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResponseMessageControllerApi
   */
  public createResponseMessage(
    requestParameters: ResponseMessageControllerApiCreateResponseMessageRequest,
    options?: AxiosRequestConfig,
  ) {
    return ResponseMessageControllerApiFp(this.configuration)
      .createResponseMessage(
        requestParameters.createResponseMessageRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xóa response message
   * @param {ResponseMessageControllerApiDeleteResponseMessageRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResponseMessageControllerApi
   */
  public deleteResponseMessage(
    requestParameters: ResponseMessageControllerApiDeleteResponseMessageRequest,
    options?: AxiosRequestConfig,
  ) {
    return ResponseMessageControllerApiFp(this.configuration)
      .deleteResponseMessage(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xem tất cả response message
   * @param {ResponseMessageControllerApiGetAllResponseMessagesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResponseMessageControllerApi
   */
  public getAllResponseMessages(
    requestParameters: ResponseMessageControllerApiGetAllResponseMessagesRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return ResponseMessageControllerApiFp(this.configuration)
      .getAllResponseMessages(
        requestParameters.query,
        requestParameters.page,
        requestParameters.pageSize,
        requestParameters.sortDirection,
        requestParameters.sortBy,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xem thông tin response message với ID
   * @param {ResponseMessageControllerApiGetDetailResponseMessageRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResponseMessageControllerApi
   */
  public getDetailResponseMessage(
    requestParameters: ResponseMessageControllerApiGetDetailResponseMessageRequest,
    options?: AxiosRequestConfig,
  ) {
    return ResponseMessageControllerApiFp(this.configuration)
      .getDetailResponseMessage(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xem thông tin response message với response code
   * @param {ResponseMessageControllerApiGetDetailResponseMessageByCodeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResponseMessageControllerApi
   */
  public getDetailResponseMessageByCode(
    requestParameters: ResponseMessageControllerApiGetDetailResponseMessageByCodeRequest,
    options?: AxiosRequestConfig,
  ) {
    return ResponseMessageControllerApiFp(this.configuration)
      .getDetailResponseMessageByCode(requestParameters.code, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Chỉnh sửa response message
   * @param {ResponseMessageControllerApiUpdateResponseMessageRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResponseMessageControllerApi
   */
  public updateResponseMessage(
    requestParameters: ResponseMessageControllerApiUpdateResponseMessageRequest,
    options?: AxiosRequestConfig,
  ) {
    return ResponseMessageControllerApiFp(this.configuration)
      .updateResponseMessage(
        requestParameters.id,
        requestParameters.updateResponseMessageRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SettleAtmCardFromNapasApi - axios parameter creator
 * @export
 */
export const SettleAtmCardFromNapasApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Duyệt thanh toán phiên giao dịch thanh toán thẻ Napas
     * @param {string} sessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approveSession: async (
      sessionId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'sessionId' is not null or undefined
      assertParamExists("approveSession", "sessionId", sessionId);
      const localVarPath =
        `/api/napas/cms/settlement/v1/{sessionId}/approve`.replace(
          `{${"sessionId"}}`,
          encodeURIComponent(String(sessionId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy danh sách các session napas
     * @param {string} from dd/MM/yyyy
     * @param {string} to dd/MM/yyyy
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNapasSessionList: async (
      from: string,
      to: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'from' is not null or undefined
      assertParamExists("getNapasSessionList", "from", from);
      // verify required parameter 'to' is not null or undefined
      assertParamExists("getNapasSessionList", "to", to);
      const localVarPath = `/api/napas/cms/settlement/v1/getSessionList`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (from !== undefined) {
        localVarQueryParameter["from"] = from;
      }

      if (to !== undefined) {
        localVarQueryParameter["to"] = to;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy chi tiết phiên thanh toán thẻ Napas
     * @param {string} sessionId
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSettle: async (
      sessionId: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'sessionId' is not null or undefined
      assertParamExists("getSettle", "sessionId", sessionId);
      const localVarPath =
        `/api/napas/cms/settlement/v1/{sessionId}/getDetail`.replace(
          `{${"sessionId"}}`,
          encodeURIComponent(String(sessionId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Thực hiện thanh toán phiên giao dịch thanh toán thẻ Napas
     * @param {string} sessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    settleSession: async (
      sessionId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'sessionId' is not null or undefined
      assertParamExists("settleSession", "sessionId", sessionId);
      const localVarPath =
        `/api/napas/cms/settlement/v1/{sessionId}/settle`.replace(
          `{${"sessionId"}}`,
          encodeURIComponent(String(sessionId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Thực hiện xác thực phiên giao dịch thanh toán thẻ Napas
     * @param {string} sessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validSession: async (
      sessionId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'sessionId' is not null or undefined
      assertParamExists("validSession", "sessionId", sessionId);
      const localVarPath =
        `/api/napas/cms/settlement/v1/{sessionId}/valid`.replace(
          `{${"sessionId"}}`,
          encodeURIComponent(String(sessionId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SettleAtmCardFromNapasApi - functional programming interface
 * @export
 */
export const SettleAtmCardFromNapasApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    SettleAtmCardFromNapasApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Duyệt thanh toán phiên giao dịch thanh toán thẻ Napas
     * @param {string} sessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async approveSession(
      sessionId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseApproveNapasSessionResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.approveSession(
        sessionId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy danh sách các session napas
     * @param {string} from dd/MM/yyyy
     * @param {string} to dd/MM/yyyy
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNapasSessionList(
      from: string,
      to: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseNapasSessionDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getNapasSessionList(
          from,
          to,
          page,
          size,
          sort,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy chi tiết phiên thanh toán thẻ Napas
     * @param {string} sessionId
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSettle(
      sessionId: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseGetNapasDetailSessionResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSettle(
        sessionId,
        page,
        size,
        sort,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Thực hiện thanh toán phiên giao dịch thanh toán thẻ Napas
     * @param {string} sessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async settleSession(
      sessionId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseSettleNapasSessionResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.settleSession(
        sessionId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Thực hiện xác thực phiên giao dịch thanh toán thẻ Napas
     * @param {string} sessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async validSession(
      sessionId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseValidNapasSessionResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.validSession(
        sessionId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * SettleAtmCardFromNapasApi - factory interface
 * @export
 */
export const SettleAtmCardFromNapasApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SettleAtmCardFromNapasApiFp(configuration);
  return {
    /**
     *
     * @summary Duyệt thanh toán phiên giao dịch thanh toán thẻ Napas
     * @param {string} sessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approveSession(
      sessionId: string,
      options?: any,
    ): AxiosPromise<ResponseBaseApproveNapasSessionResponse> {
      return localVarFp
        .approveSession(sessionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy danh sách các session napas
     * @param {string} from dd/MM/yyyy
     * @param {string} to dd/MM/yyyy
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNapasSessionList(
      from: string,
      to: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseNapasSessionDto> {
      return localVarFp
        .getNapasSessionList(from, to, page, size, sort, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy chi tiết phiên thanh toán thẻ Napas
     * @param {string} sessionId
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSettle(
      sessionId: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any,
    ): AxiosPromise<ResponseBaseGetNapasDetailSessionResponse> {
      return localVarFp
        .getSettle(sessionId, page, size, sort, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Thực hiện thanh toán phiên giao dịch thanh toán thẻ Napas
     * @param {string} sessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    settleSession(
      sessionId: string,
      options?: any,
    ): AxiosPromise<ResponseBaseSettleNapasSessionResponse> {
      return localVarFp
        .settleSession(sessionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Thực hiện xác thực phiên giao dịch thanh toán thẻ Napas
     * @param {string} sessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validSession(
      sessionId: string,
      options?: any,
    ): AxiosPromise<ResponseBaseValidNapasSessionResponse> {
      return localVarFp
        .validSession(sessionId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for approveSession operation in SettleAtmCardFromNapasApi.
 * @export
 * @interface SettleAtmCardFromNapasApiApproveSessionRequest
 */
export interface SettleAtmCardFromNapasApiApproveSessionRequest {
  /**
   *
   * @type {string}
   * @memberof SettleAtmCardFromNapasApiApproveSession
   */
  readonly sessionId: string;
}

/**
 * Request parameters for getNapasSessionList operation in SettleAtmCardFromNapasApi.
 * @export
 * @interface SettleAtmCardFromNapasApiGetNapasSessionListRequest
 */
export interface SettleAtmCardFromNapasApiGetNapasSessionListRequest {
  /**
   * dd/MM/yyyy
   * @type {string}
   * @memberof SettleAtmCardFromNapasApiGetNapasSessionList
   */
  readonly from: string;

  /**
   * dd/MM/yyyy
   * @type {string}
   * @memberof SettleAtmCardFromNapasApiGetNapasSessionList
   */
  readonly to: string;

  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof SettleAtmCardFromNapasApiGetNapasSessionList
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof SettleAtmCardFromNapasApiGetNapasSessionList
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof SettleAtmCardFromNapasApiGetNapasSessionList
   */
  readonly sort?: Array<string>;
}

/**
 * Request parameters for getSettle operation in SettleAtmCardFromNapasApi.
 * @export
 * @interface SettleAtmCardFromNapasApiGetSettleRequest
 */
export interface SettleAtmCardFromNapasApiGetSettleRequest {
  /**
   *
   * @type {string}
   * @memberof SettleAtmCardFromNapasApiGetSettle
   */
  readonly sessionId: string;

  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof SettleAtmCardFromNapasApiGetSettle
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof SettleAtmCardFromNapasApiGetSettle
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof SettleAtmCardFromNapasApiGetSettle
   */
  readonly sort?: Array<string>;
}

/**
 * Request parameters for settleSession operation in SettleAtmCardFromNapasApi.
 * @export
 * @interface SettleAtmCardFromNapasApiSettleSessionRequest
 */
export interface SettleAtmCardFromNapasApiSettleSessionRequest {
  /**
   *
   * @type {string}
   * @memberof SettleAtmCardFromNapasApiSettleSession
   */
  readonly sessionId: string;
}

/**
 * Request parameters for validSession operation in SettleAtmCardFromNapasApi.
 * @export
 * @interface SettleAtmCardFromNapasApiValidSessionRequest
 */
export interface SettleAtmCardFromNapasApiValidSessionRequest {
  /**
   *
   * @type {string}
   * @memberof SettleAtmCardFromNapasApiValidSession
   */
  readonly sessionId: string;
}

/**
 * SettleAtmCardFromNapasApi - object-oriented interface
 * @export
 * @class SettleAtmCardFromNapasApi
 * @extends {BaseAPI}
 */
export class SettleAtmCardFromNapasApi extends BaseAPI {
  /**
   *
   * @summary Duyệt thanh toán phiên giao dịch thanh toán thẻ Napas
   * @param {SettleAtmCardFromNapasApiApproveSessionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettleAtmCardFromNapasApi
   */
  public approveSession(
    requestParameters: SettleAtmCardFromNapasApiApproveSessionRequest,
    options?: AxiosRequestConfig,
  ) {
    return SettleAtmCardFromNapasApiFp(this.configuration)
      .approveSession(requestParameters.sessionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy danh sách các session napas
   * @param {SettleAtmCardFromNapasApiGetNapasSessionListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettleAtmCardFromNapasApi
   */
  public getNapasSessionList(
    requestParameters: SettleAtmCardFromNapasApiGetNapasSessionListRequest,
    options?: AxiosRequestConfig,
  ) {
    return SettleAtmCardFromNapasApiFp(this.configuration)
      .getNapasSessionList(
        requestParameters.from,
        requestParameters.to,
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy chi tiết phiên thanh toán thẻ Napas
   * @param {SettleAtmCardFromNapasApiGetSettleRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettleAtmCardFromNapasApi
   */
  public getSettle(
    requestParameters: SettleAtmCardFromNapasApiGetSettleRequest,
    options?: AxiosRequestConfig,
  ) {
    return SettleAtmCardFromNapasApiFp(this.configuration)
      .getSettle(
        requestParameters.sessionId,
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Thực hiện thanh toán phiên giao dịch thanh toán thẻ Napas
   * @param {SettleAtmCardFromNapasApiSettleSessionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettleAtmCardFromNapasApi
   */
  public settleSession(
    requestParameters: SettleAtmCardFromNapasApiSettleSessionRequest,
    options?: AxiosRequestConfig,
  ) {
    return SettleAtmCardFromNapasApiFp(this.configuration)
      .settleSession(requestParameters.sessionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Thực hiện xác thực phiên giao dịch thanh toán thẻ Napas
   * @param {SettleAtmCardFromNapasApiValidSessionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettleAtmCardFromNapasApi
   */
  public validSession(
    requestParameters: SettleAtmCardFromNapasApiValidSessionRequest,
    options?: AxiosRequestConfig,
  ) {
    return SettleAtmCardFromNapasApiFp(this.configuration)
      .validSession(requestParameters.sessionId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ShopSettingApi - axios parameter creator
 * @export
 */
export const ShopSettingApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Lấy thông tin Shop Setting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getShopSetting: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/shop-setting/v1/get`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Cập nhật thông tin Shop Setting
     * @param {UpdateShopSettingRequest} updateShopSettingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateShopSetting: async (
      updateShopSettingRequest: UpdateShopSettingRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateShopSettingRequest' is not null or undefined
      assertParamExists(
        "updateShopSetting",
        "updateShopSettingRequest",
        updateShopSettingRequest,
      );
      const localVarPath = `/api/shop-setting/v1/update`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateShopSettingRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ShopSettingApi - functional programming interface
 * @export
 */
export const ShopSettingApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    ShopSettingApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Lấy thông tin Shop Setting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getShopSetting(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseShopSettingResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getShopSetting(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Cập nhật thông tin Shop Setting
     * @param {UpdateShopSettingRequest} updateShopSettingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateShopSetting(
      updateShopSettingRequest: UpdateShopSettingRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseShopSettingResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateShopSetting(
          updateShopSettingRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * ShopSettingApi - factory interface
 * @export
 */
export const ShopSettingApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ShopSettingApiFp(configuration);
  return {
    /**
     *
     * @summary Lấy thông tin Shop Setting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getShopSetting(
      options?: any,
    ): AxiosPromise<ResponseBaseShopSettingResponse> {
      return localVarFp
        .getShopSetting(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Cập nhật thông tin Shop Setting
     * @param {UpdateShopSettingRequest} updateShopSettingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateShopSetting(
      updateShopSettingRequest: UpdateShopSettingRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseShopSettingResponse> {
      return localVarFp
        .updateShopSetting(updateShopSettingRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for updateShopSetting operation in ShopSettingApi.
 * @export
 * @interface ShopSettingApiUpdateShopSettingRequest
 */
export interface ShopSettingApiUpdateShopSettingRequest {
  /**
   *
   * @type {UpdateShopSettingRequest}
   * @memberof ShopSettingApiUpdateShopSetting
   */
  readonly updateShopSettingRequest: UpdateShopSettingRequest;
}

/**
 * ShopSettingApi - object-oriented interface
 * @export
 * @class ShopSettingApi
 * @extends {BaseAPI}
 */
export class ShopSettingApi extends BaseAPI {
  /**
   *
   * @summary Lấy thông tin Shop Setting
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ShopSettingApi
   */
  public getShopSetting(options?: AxiosRequestConfig) {
    return ShopSettingApiFp(this.configuration)
      .getShopSetting(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Cập nhật thông tin Shop Setting
   * @param {ShopSettingApiUpdateShopSettingRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ShopSettingApi
   */
  public updateShopSetting(
    requestParameters: ShopSettingApiUpdateShopSettingRequest,
    options?: AxiosRequestConfig,
  ) {
    return ShopSettingApiFp(this.configuration)
      .updateShopSetting(requestParameters.updateShopSettingRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SupportControllerApi - axios parameter creator
 * @export
 */
export const SupportControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Xóa yêu cầu hỗ trợ
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("_delete", "id", id);
      const localVarPath = `/api/support/v1/delete/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Tạo yêu cầu hỗ trợ
     * @param {CreateSupportRequest} createSupportRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create: async (
      createSupportRequest: CreateSupportRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createSupportRequest' is not null or undefined
      assertParamExists("create", "createSupportRequest", createSupportRequest);
      const localVarPath = `/api/support/v1/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createSupportRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Chi tiết yêu cầu hỗ trợ
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDetail: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getDetail", "id", id);
      const localVarPath = `/api/support/v1/getDetail/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Danh sách yêu cầu hỗ trợ
     * @param {string} [from]
     * @param {string} [to]
     * @param {string} [query]
     * @param {'CONTENT_1' | 'CONTENT_2' | 'CONTENT_3'} [content]
     * @param {string} [number]
     * @param {string} [size]
     * @param {string} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getList: async (
      from?: string,
      to?: string,
      query?: string,
      content?: "CONTENT_1" | "CONTENT_2" | "CONTENT_3",
      number?: string,
      size?: string,
      sortDirection?: string,
      sortBy?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/support/v1/getList`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (from !== undefined) {
        localVarQueryParameter["from"] = from;
      }

      if (to !== undefined) {
        localVarQueryParameter["to"] = to;
      }

      if (query !== undefined) {
        localVarQueryParameter["query"] = query;
      }

      if (content !== undefined) {
        localVarQueryParameter["content"] = content;
      }

      if (number !== undefined) {
        localVarQueryParameter["number"] = number;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter["sortDirection"] = sortDirection;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter["sortBy"] = sortBy;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SupportControllerApi - functional programming interface
 * @export
 */
export const SupportControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    SupportControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Xóa yêu cầu hỗ trợ
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async _delete(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseSupportResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator._delete(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Tạo yêu cầu hỗ trợ
     * @param {CreateSupportRequest} createSupportRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async create(
      createSupportRequest: CreateSupportRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseSupportResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.create(
        createSupportRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Chi tiết yêu cầu hỗ trợ
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDetail(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseSupportResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDetail(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Danh sách yêu cầu hỗ trợ
     * @param {string} [from]
     * @param {string} [to]
     * @param {string} [query]
     * @param {'CONTENT_1' | 'CONTENT_2' | 'CONTENT_3'} [content]
     * @param {string} [number]
     * @param {string} [size]
     * @param {string} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getList(
      from?: string,
      to?: string,
      query?: string,
      content?: "CONTENT_1" | "CONTENT_2" | "CONTENT_3",
      number?: string,
      size?: string,
      sortDirection?: string,
      sortBy?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePageResponseSupportResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getList(
        from,
        to,
        query,
        content,
        number,
        size,
        sortDirection,
        sortBy,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * SupportControllerApi - factory interface
 * @export
 */
export const SupportControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SupportControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Xóa yêu cầu hỗ trợ
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(
      id: string,
      options?: any,
    ): AxiosPromise<ResponseBaseSupportResponse> {
      return localVarFp
        ._delete(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Tạo yêu cầu hỗ trợ
     * @param {CreateSupportRequest} createSupportRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(
      createSupportRequest: CreateSupportRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseSupportResponse> {
      return localVarFp
        .create(createSupportRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Chi tiết yêu cầu hỗ trợ
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDetail(
      id: string,
      options?: any,
    ): AxiosPromise<ResponseBaseSupportResponse> {
      return localVarFp
        .getDetail(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Danh sách yêu cầu hỗ trợ
     * @param {string} [from]
     * @param {string} [to]
     * @param {string} [query]
     * @param {'CONTENT_1' | 'CONTENT_2' | 'CONTENT_3'} [content]
     * @param {string} [number]
     * @param {string} [size]
     * @param {string} [sortDirection]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getList(
      from?: string,
      to?: string,
      query?: string,
      content?: "CONTENT_1" | "CONTENT_2" | "CONTENT_3",
      number?: string,
      size?: string,
      sortDirection?: string,
      sortBy?: string,
      options?: any,
    ): AxiosPromise<ResponseBasePageResponseSupportResponse> {
      return localVarFp
        .getList(
          from,
          to,
          query,
          content,
          number,
          size,
          sortDirection,
          sortBy,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for _delete operation in SupportControllerApi.
 * @export
 * @interface SupportControllerApiDeleteRequest
 */
export interface SupportControllerApiDeleteRequest {
  /**
   *
   * @type {string}
   * @memberof SupportControllerApiDelete
   */
  readonly id: string;
}

/**
 * Request parameters for create operation in SupportControllerApi.
 * @export
 * @interface SupportControllerApiCreateRequest
 */
export interface SupportControllerApiCreateRequest {
  /**
   *
   * @type {CreateSupportRequest}
   * @memberof SupportControllerApiCreate
   */
  readonly createSupportRequest: CreateSupportRequest;
}

/**
 * Request parameters for getDetail operation in SupportControllerApi.
 * @export
 * @interface SupportControllerApiGetDetailRequest
 */
export interface SupportControllerApiGetDetailRequest {
  /**
   *
   * @type {string}
   * @memberof SupportControllerApiGetDetail
   */
  readonly id: string;
}

/**
 * Request parameters for getList operation in SupportControllerApi.
 * @export
 * @interface SupportControllerApiGetListRequest
 */
export interface SupportControllerApiGetListRequest {
  /**
   *
   * @type {string}
   * @memberof SupportControllerApiGetList
   */
  readonly from?: string;

  /**
   *
   * @type {string}
   * @memberof SupportControllerApiGetList
   */
  readonly to?: string;

  /**
   *
   * @type {string}
   * @memberof SupportControllerApiGetList
   */
  readonly query?: string;

  /**
   *
   * @type {'CONTENT_1' | 'CONTENT_2' | 'CONTENT_3'}
   * @memberof SupportControllerApiGetList
   */
  readonly content?: "CONTENT_1" | "CONTENT_2" | "CONTENT_3";

  /**
   *
   * @type {string}
   * @memberof SupportControllerApiGetList
   */
  readonly number?: string;

  /**
   *
   * @type {string}
   * @memberof SupportControllerApiGetList
   */
  readonly size?: string;

  /**
   *
   * @type {string}
   * @memberof SupportControllerApiGetList
   */
  readonly sortDirection?: string;

  /**
   *
   * @type {string}
   * @memberof SupportControllerApiGetList
   */
  readonly sortBy?: string;
}

/**
 * SupportControllerApi - object-oriented interface
 * @export
 * @class SupportControllerApi
 * @extends {BaseAPI}
 */
export class SupportControllerApi extends BaseAPI {
  /**
   *
   * @summary Xóa yêu cầu hỗ trợ
   * @param {SupportControllerApiDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SupportControllerApi
   */
  public _delete(
    requestParameters: SupportControllerApiDeleteRequest,
    options?: AxiosRequestConfig,
  ) {
    return SupportControllerApiFp(this.configuration)
      ._delete(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Tạo yêu cầu hỗ trợ
   * @param {SupportControllerApiCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SupportControllerApi
   */
  public create(
    requestParameters: SupportControllerApiCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return SupportControllerApiFp(this.configuration)
      .create(requestParameters.createSupportRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Chi tiết yêu cầu hỗ trợ
   * @param {SupportControllerApiGetDetailRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SupportControllerApi
   */
  public getDetail(
    requestParameters: SupportControllerApiGetDetailRequest,
    options?: AxiosRequestConfig,
  ) {
    return SupportControllerApiFp(this.configuration)
      .getDetail(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Danh sách yêu cầu hỗ trợ
   * @param {SupportControllerApiGetListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SupportControllerApi
   */
  public getList(
    requestParameters: SupportControllerApiGetListRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return SupportControllerApiFp(this.configuration)
      .getList(
        requestParameters.from,
        requestParameters.to,
        requestParameters.query,
        requestParameters.content,
        requestParameters.number,
        requestParameters.size,
        requestParameters.sortDirection,
        requestParameters.sortBy,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ToolControllerApi - axios parameter creator
 * @export
 */
export const ToolControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Gửi tiền
     * @param {ToolDepositRequest} toolDepositRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deposit: async (
      toolDepositRequest: ToolDepositRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'toolDepositRequest' is not null or undefined
      assertParamExists("deposit", "toolDepositRequest", toolDepositRequest);
      const localVarPath = `/api/tool/v1/deposit`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        toolDepositRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Kiểm tra tài khoản
     * @param {ToolInquiryRequest} toolInquiryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inquiry: async (
      toolInquiryRequest: ToolInquiryRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'toolInquiryRequest' is not null or undefined
      assertParamExists("inquiry", "toolInquiryRequest", toolInquiryRequest);
      const localVarPath = `/api/tool/v1/inquiry`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        toolInquiryRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy thông tin từ QR
     * @param {File} image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    scanQr: async (
      image: File,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'image' is not null or undefined
      assertParamExists("scanQr", "image", image);
      const localVarPath = `/api/tool/v1/scanQr`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (image !== undefined) {
        localVarFormParams.append("image", image as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ToolControllerApi - functional programming interface
 * @export
 */
export const ToolControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    ToolControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Gửi tiền
     * @param {ToolDepositRequest} toolDepositRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deposit(
      toolDepositRequest: ToolDepositRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseToolDepositResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deposit(
        toolDepositRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Kiểm tra tài khoản
     * @param {ToolInquiryRequest} toolInquiryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async inquiry(
      toolInquiryRequest: ToolInquiryRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseToolInquiryResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.inquiry(
        toolInquiryRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy thông tin từ QR
     * @param {File} image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async scanQr(
      image: File,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseToolScanQrResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.scanQr(
        image,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * ToolControllerApi - factory interface
 * @export
 */
export const ToolControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ToolControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Gửi tiền
     * @param {ToolDepositRequest} toolDepositRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deposit(
      toolDepositRequest: ToolDepositRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseToolDepositResponse> {
      return localVarFp
        .deposit(toolDepositRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Kiểm tra tài khoản
     * @param {ToolInquiryRequest} toolInquiryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inquiry(
      toolInquiryRequest: ToolInquiryRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseToolInquiryResponse> {
      return localVarFp
        .inquiry(toolInquiryRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy thông tin từ QR
     * @param {File} image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    scanQr(
      image: File,
      options?: any,
    ): AxiosPromise<ResponseBaseToolScanQrResponse> {
      return localVarFp
        .scanQr(image, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for deposit operation in ToolControllerApi.
 * @export
 * @interface ToolControllerApiDepositRequest
 */
export interface ToolControllerApiDepositRequest {
  /**
   *
   * @type {ToolDepositRequest}
   * @memberof ToolControllerApiDeposit
   */
  readonly toolDepositRequest: ToolDepositRequest;
}

/**
 * Request parameters for inquiry operation in ToolControllerApi.
 * @export
 * @interface ToolControllerApiInquiryRequest
 */
export interface ToolControllerApiInquiryRequest {
  /**
   *
   * @type {ToolInquiryRequest}
   * @memberof ToolControllerApiInquiry
   */
  readonly toolInquiryRequest: ToolInquiryRequest;
}

/**
 * Request parameters for scanQr operation in ToolControllerApi.
 * @export
 * @interface ToolControllerApiScanQrRequest
 */
export interface ToolControllerApiScanQrRequest {
  /**
   *
   * @type {File}
   * @memberof ToolControllerApiScanQr
   */
  readonly image: File;
}

/**
 * ToolControllerApi - object-oriented interface
 * @export
 * @class ToolControllerApi
 * @extends {BaseAPI}
 */
export class ToolControllerApi extends BaseAPI {
  /**
   *
   * @summary Gửi tiền
   * @param {ToolControllerApiDepositRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ToolControllerApi
   */
  public deposit(
    requestParameters: ToolControllerApiDepositRequest,
    options?: AxiosRequestConfig,
  ) {
    return ToolControllerApiFp(this.configuration)
      .deposit(requestParameters.toolDepositRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Kiểm tra tài khoản
   * @param {ToolControllerApiInquiryRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ToolControllerApi
   */
  public inquiry(
    requestParameters: ToolControllerApiInquiryRequest,
    options?: AxiosRequestConfig,
  ) {
    return ToolControllerApiFp(this.configuration)
      .inquiry(requestParameters.toolInquiryRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy thông tin từ QR
   * @param {ToolControllerApiScanQrRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ToolControllerApi
   */
  public scanQr(
    requestParameters: ToolControllerApiScanQrRequest,
    options?: AxiosRequestConfig,
  ) {
    return ToolControllerApiFp(this.configuration)
      .scanQr(requestParameters.image, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UseeControllerApi - axios parameter creator
 * @export
 */
export const UseeControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Hủy transaction từ dịch vụ Usee
     * @param {string} useePairingKey
     * @param {USeeCancelTransactionRequest} uSeeCancelTransactionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelTransactionUSee: async (
      useePairingKey: string,
      uSeeCancelTransactionRequest: USeeCancelTransactionRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'useePairingKey' is not null or undefined
      assertParamExists(
        "cancelTransactionUSee",
        "useePairingKey",
        useePairingKey,
      );
      // verify required parameter 'uSeeCancelTransactionRequest' is not null or undefined
      assertParamExists(
        "cancelTransactionUSee",
        "uSeeCancelTransactionRequest",
        uSeeCancelTransactionRequest,
      );
      const localVarPath =
        `/api/usee/v1/cancelTransaction/{usee-pairing-key}`.replace(
          `{${"usee-pairing-key"}}`,
          encodeURIComponent(String(useePairingKey)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        uSeeCancelTransactionRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Kiểm tra trạng thái Transaction từ dịch vụ Usee
     * @param {string} useePairingKey
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkTransactionUSee: async (
      useePairingKey: string,
      transactionId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'useePairingKey' is not null or undefined
      assertParamExists(
        "checkTransactionUSee",
        "useePairingKey",
        useePairingKey,
      );
      // verify required parameter 'transactionId' is not null or undefined
      assertParamExists("checkTransactionUSee", "transactionId", transactionId);
      const localVarPath =
        `/api/usee/v1/checkTransaction/{usee-pairing-key}`.replace(
          `{${"usee-pairing-key"}}`,
          encodeURIComponent(String(useePairingKey)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (transactionId !== undefined) {
        localVarQueryParameter["transactionId"] = transactionId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Tạo 1 transaction mới từ dịch vụ Usee
     * @param {string} useePairingKey
     * @param {USeeCreateTransactionRequest} uSeeCreateTransactionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTransactionUSee: async (
      useePairingKey: string,
      uSeeCreateTransactionRequest: USeeCreateTransactionRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'useePairingKey' is not null or undefined
      assertParamExists(
        "createTransactionUSee",
        "useePairingKey",
        useePairingKey,
      );
      // verify required parameter 'uSeeCreateTransactionRequest' is not null or undefined
      assertParamExists(
        "createTransactionUSee",
        "uSeeCreateTransactionRequest",
        uSeeCreateTransactionRequest,
      );
      const localVarPath =
        `/api/usee/v1/createTransaction/{usee-pairing-key}`.replace(
          `{${"usee-pairing-key"}}`,
          encodeURIComponent(String(useePairingKey)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        uSeeCreateTransactionRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Tất cả các device từ tài khoản đã liên kết
     * @param {string} useePairingKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllDevicesUSee: async (
      useePairingKey: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'useePairingKey' is not null or undefined
      assertParamExists("getAllDevicesUSee", "useePairingKey", useePairingKey);
      const localVarPath =
        `/api/usee/v1/getAllDevices/{usee-pairing-key}`.replace(
          `{${"usee-pairing-key"}}`,
          encodeURIComponent(String(useePairingKey)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get 1 device từ tài khoản đã liên kết
     * @param {string} useePairingKey
     * @param {string} [deviceId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDeviceUSee: async (
      useePairingKey: string,
      deviceId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'useePairingKey' is not null or undefined
      assertParamExists("getDeviceUSee", "useePairingKey", useePairingKey);
      const localVarPath = `/api/usee/v1/getDevice/{usee-pairing-key}`.replace(
        `{${"usee-pairing-key"}}`,
        encodeURIComponent(String(useePairingKey)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (deviceId !== undefined) {
        localVarQueryParameter["deviceId"] = deviceId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy pairing key của Usee và paybox
     * @param {string} code
     * @param {string} refUserId
     * @param {string} sessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUSeeKey: async (
      code: string,
      refUserId: string,
      sessionId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists("getUSeeKey", "code", code);
      // verify required parameter 'refUserId' is not null or undefined
      assertParamExists("getUSeeKey", "refUserId", refUserId);
      // verify required parameter 'sessionId' is not null or undefined
      assertParamExists("getUSeeKey", "sessionId", sessionId);
      const localVarPath = `/api/usee/v1/getKey`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (code !== undefined) {
        localVarQueryParameter["code"] = code;
      }

      if (refUserId !== undefined) {
        localVarQueryParameter["refUserId"] = refUserId;
      }

      if (sessionId !== undefined) {
        localVarQueryParameter["sessionId"] = sessionId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Liên kết Usee với tài khoản Unicloud ID
     * @param {string} [refUserId]
     * @param {string} [redirectUrl]
     * @param {string} [sessionId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    linkUSee: async (
      refUserId?: string,
      redirectUrl?: string,
      sessionId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/usee/v1/link`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (refUserId !== undefined) {
        localVarQueryParameter["refUserId"] = refUserId;
      }

      if (redirectUrl !== undefined) {
        localVarQueryParameter["redirectUrl"] = redirectUrl;
      }

      if (sessionId !== undefined) {
        localVarQueryParameter["sessionId"] = sessionId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UseeControllerApi - functional programming interface
 * @export
 */
export const UseeControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    UseeControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Hủy transaction từ dịch vụ Usee
     * @param {string} useePairingKey
     * @param {USeeCancelTransactionRequest} uSeeCancelTransactionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cancelTransactionUSee(
      useePairingKey: string,
      uSeeCancelTransactionRequest: USeeCancelTransactionRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cancelTransactionUSee(
          useePairingKey,
          uSeeCancelTransactionRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Kiểm tra trạng thái Transaction từ dịch vụ Usee
     * @param {string} useePairingKey
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkTransactionUSee(
      useePairingKey: string,
      transactionId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseUSeeCheckTransactionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.checkTransactionUSee(
          useePairingKey,
          transactionId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Tạo 1 transaction mới từ dịch vụ Usee
     * @param {string} useePairingKey
     * @param {USeeCreateTransactionRequest} uSeeCreateTransactionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createTransactionUSee(
      useePairingKey: string,
      uSeeCreateTransactionRequest: USeeCreateTransactionRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createTransactionUSee(
          useePairingKey,
          uSeeCreateTransactionRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get Tất cả các device từ tài khoản đã liên kết
     * @param {string} useePairingKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllDevicesUSee(
      useePairingKey: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseUSeeGetDevicesResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllDevicesUSee(
          useePairingKey,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get 1 device từ tài khoản đã liên kết
     * @param {string} useePairingKey
     * @param {string} [deviceId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDeviceUSee(
      useePairingKey: string,
      deviceId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseUSeeGetDeviceResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviceUSee(
        useePairingKey,
        deviceId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy pairing key của Usee và paybox
     * @param {string} code
     * @param {string} refUserId
     * @param {string} sessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUSeeKey(
      code: string,
      refUserId: string,
      sessionId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseUSeeGetPairingKeyResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUSeeKey(
        code,
        refUserId,
        sessionId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Liên kết Usee với tài khoản Unicloud ID
     * @param {string} [refUserId]
     * @param {string} [redirectUrl]
     * @param {string} [sessionId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async linkUSee(
      refUserId?: string,
      redirectUrl?: string,
      sessionId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseUSeeLinkMerchantResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.linkUSee(
        refUserId,
        redirectUrl,
        sessionId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * UseeControllerApi - factory interface
 * @export
 */
export const UseeControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = UseeControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Hủy transaction từ dịch vụ Usee
     * @param {string} useePairingKey
     * @param {USeeCancelTransactionRequest} uSeeCancelTransactionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelTransactionUSee(
      useePairingKey: string,
      uSeeCancelTransactionRequest: USeeCancelTransactionRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .cancelTransactionUSee(
          useePairingKey,
          uSeeCancelTransactionRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Kiểm tra trạng thái Transaction từ dịch vụ Usee
     * @param {string} useePairingKey
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkTransactionUSee(
      useePairingKey: string,
      transactionId: string,
      options?: any,
    ): AxiosPromise<ResponseBaseUSeeCheckTransactionResponse> {
      return localVarFp
        .checkTransactionUSee(useePairingKey, transactionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Tạo 1 transaction mới từ dịch vụ Usee
     * @param {string} useePairingKey
     * @param {USeeCreateTransactionRequest} uSeeCreateTransactionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTransactionUSee(
      useePairingKey: string,
      uSeeCreateTransactionRequest: USeeCreateTransactionRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .createTransactionUSee(
          useePairingKey,
          uSeeCreateTransactionRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Tất cả các device từ tài khoản đã liên kết
     * @param {string} useePairingKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllDevicesUSee(
      useePairingKey: string,
      options?: any,
    ): AxiosPromise<ResponseBaseUSeeGetDevicesResponse> {
      return localVarFp
        .getAllDevicesUSee(useePairingKey, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get 1 device từ tài khoản đã liên kết
     * @param {string} useePairingKey
     * @param {string} [deviceId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDeviceUSee(
      useePairingKey: string,
      deviceId?: string,
      options?: any,
    ): AxiosPromise<ResponseBaseUSeeGetDeviceResponse> {
      return localVarFp
        .getDeviceUSee(useePairingKey, deviceId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy pairing key của Usee và paybox
     * @param {string} code
     * @param {string} refUserId
     * @param {string} sessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUSeeKey(
      code: string,
      refUserId: string,
      sessionId: string,
      options?: any,
    ): AxiosPromise<ResponseBaseUSeeGetPairingKeyResponse> {
      return localVarFp
        .getUSeeKey(code, refUserId, sessionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Liên kết Usee với tài khoản Unicloud ID
     * @param {string} [refUserId]
     * @param {string} [redirectUrl]
     * @param {string} [sessionId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    linkUSee(
      refUserId?: string,
      redirectUrl?: string,
      sessionId?: string,
      options?: any,
    ): AxiosPromise<ResponseBaseUSeeLinkMerchantResponse> {
      return localVarFp
        .linkUSee(refUserId, redirectUrl, sessionId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for cancelTransactionUSee operation in UseeControllerApi.
 * @export
 * @interface UseeControllerApiCancelTransactionUSeeRequest
 */
export interface UseeControllerApiCancelTransactionUSeeRequest {
  /**
   *
   * @type {string}
   * @memberof UseeControllerApiCancelTransactionUSee
   */
  readonly useePairingKey: string;

  /**
   *
   * @type {USeeCancelTransactionRequest}
   * @memberof UseeControllerApiCancelTransactionUSee
   */
  readonly uSeeCancelTransactionRequest: USeeCancelTransactionRequest;
}

/**
 * Request parameters for checkTransactionUSee operation in UseeControllerApi.
 * @export
 * @interface UseeControllerApiCheckTransactionUSeeRequest
 */
export interface UseeControllerApiCheckTransactionUSeeRequest {
  /**
   *
   * @type {string}
   * @memberof UseeControllerApiCheckTransactionUSee
   */
  readonly useePairingKey: string;

  /**
   *
   * @type {string}
   * @memberof UseeControllerApiCheckTransactionUSee
   */
  readonly transactionId: string;
}

/**
 * Request parameters for createTransactionUSee operation in UseeControllerApi.
 * @export
 * @interface UseeControllerApiCreateTransactionUSeeRequest
 */
export interface UseeControllerApiCreateTransactionUSeeRequest {
  /**
   *
   * @type {string}
   * @memberof UseeControllerApiCreateTransactionUSee
   */
  readonly useePairingKey: string;

  /**
   *
   * @type {USeeCreateTransactionRequest}
   * @memberof UseeControllerApiCreateTransactionUSee
   */
  readonly uSeeCreateTransactionRequest: USeeCreateTransactionRequest;
}

/**
 * Request parameters for getAllDevicesUSee operation in UseeControllerApi.
 * @export
 * @interface UseeControllerApiGetAllDevicesUSeeRequest
 */
export interface UseeControllerApiGetAllDevicesUSeeRequest {
  /**
   *
   * @type {string}
   * @memberof UseeControllerApiGetAllDevicesUSee
   */
  readonly useePairingKey: string;
}

/**
 * Request parameters for getDeviceUSee operation in UseeControllerApi.
 * @export
 * @interface UseeControllerApiGetDeviceUSeeRequest
 */
export interface UseeControllerApiGetDeviceUSeeRequest {
  /**
   *
   * @type {string}
   * @memberof UseeControllerApiGetDeviceUSee
   */
  readonly useePairingKey: string;

  /**
   *
   * @type {string}
   * @memberof UseeControllerApiGetDeviceUSee
   */
  readonly deviceId?: string;
}

/**
 * Request parameters for getUSeeKey operation in UseeControllerApi.
 * @export
 * @interface UseeControllerApiGetUSeeKeyRequest
 */
export interface UseeControllerApiGetUSeeKeyRequest {
  /**
   *
   * @type {string}
   * @memberof UseeControllerApiGetUSeeKey
   */
  readonly code: string;

  /**
   *
   * @type {string}
   * @memberof UseeControllerApiGetUSeeKey
   */
  readonly refUserId: string;

  /**
   *
   * @type {string}
   * @memberof UseeControllerApiGetUSeeKey
   */
  readonly sessionId: string;
}

/**
 * Request parameters for linkUSee operation in UseeControllerApi.
 * @export
 * @interface UseeControllerApiLinkUSeeRequest
 */
export interface UseeControllerApiLinkUSeeRequest {
  /**
   *
   * @type {string}
   * @memberof UseeControllerApiLinkUSee
   */
  readonly refUserId?: string;

  /**
   *
   * @type {string}
   * @memberof UseeControllerApiLinkUSee
   */
  readonly redirectUrl?: string;

  /**
   *
   * @type {string}
   * @memberof UseeControllerApiLinkUSee
   */
  readonly sessionId?: string;
}

/**
 * UseeControllerApi - object-oriented interface
 * @export
 * @class UseeControllerApi
 * @extends {BaseAPI}
 */
export class UseeControllerApi extends BaseAPI {
  /**
   *
   * @summary Hủy transaction từ dịch vụ Usee
   * @param {UseeControllerApiCancelTransactionUSeeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UseeControllerApi
   */
  public cancelTransactionUSee(
    requestParameters: UseeControllerApiCancelTransactionUSeeRequest,
    options?: AxiosRequestConfig,
  ) {
    return UseeControllerApiFp(this.configuration)
      .cancelTransactionUSee(
        requestParameters.useePairingKey,
        requestParameters.uSeeCancelTransactionRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Kiểm tra trạng thái Transaction từ dịch vụ Usee
   * @param {UseeControllerApiCheckTransactionUSeeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UseeControllerApi
   */
  public checkTransactionUSee(
    requestParameters: UseeControllerApiCheckTransactionUSeeRequest,
    options?: AxiosRequestConfig,
  ) {
    return UseeControllerApiFp(this.configuration)
      .checkTransactionUSee(
        requestParameters.useePairingKey,
        requestParameters.transactionId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Tạo 1 transaction mới từ dịch vụ Usee
   * @param {UseeControllerApiCreateTransactionUSeeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UseeControllerApi
   */
  public createTransactionUSee(
    requestParameters: UseeControllerApiCreateTransactionUSeeRequest,
    options?: AxiosRequestConfig,
  ) {
    return UseeControllerApiFp(this.configuration)
      .createTransactionUSee(
        requestParameters.useePairingKey,
        requestParameters.uSeeCreateTransactionRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Tất cả các device từ tài khoản đã liên kết
   * @param {UseeControllerApiGetAllDevicesUSeeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UseeControllerApi
   */
  public getAllDevicesUSee(
    requestParameters: UseeControllerApiGetAllDevicesUSeeRequest,
    options?: AxiosRequestConfig,
  ) {
    return UseeControllerApiFp(this.configuration)
      .getAllDevicesUSee(requestParameters.useePairingKey, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get 1 device từ tài khoản đã liên kết
   * @param {UseeControllerApiGetDeviceUSeeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UseeControllerApi
   */
  public getDeviceUSee(
    requestParameters: UseeControllerApiGetDeviceUSeeRequest,
    options?: AxiosRequestConfig,
  ) {
    return UseeControllerApiFp(this.configuration)
      .getDeviceUSee(
        requestParameters.useePairingKey,
        requestParameters.deviceId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy pairing key của Usee và paybox
   * @param {UseeControllerApiGetUSeeKeyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UseeControllerApi
   */
  public getUSeeKey(
    requestParameters: UseeControllerApiGetUSeeKeyRequest,
    options?: AxiosRequestConfig,
  ) {
    return UseeControllerApiFp(this.configuration)
      .getUSeeKey(
        requestParameters.code,
        requestParameters.refUserId,
        requestParameters.sessionId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Liên kết Usee với tài khoản Unicloud ID
   * @param {UseeControllerApiLinkUSeeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UseeControllerApi
   */
  public linkUSee(
    requestParameters: UseeControllerApiLinkUSeeRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return UseeControllerApiFp(this.configuration)
      .linkUSee(
        requestParameters.refUserId,
        requestParameters.redirectUrl,
        requestParameters.sessionId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UserControllerApi - axios parameter creator
 * @export
 */
export const UserControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Đổi mật khẩu
     * @param {CheckPasswordRequest} checkPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changePasswordCheck: async (
      checkPasswordRequest: CheckPasswordRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'checkPasswordRequest' is not null or undefined
      assertParamExists(
        "changePasswordCheck",
        "checkPasswordRequest",
        checkPasswordRequest,
      );
      const localVarPath = `/api/user/portal/v1/changePassword/check`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        checkPasswordRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Đổi mật khẩu - submit
     * @param {ChangePasswordRequest} changePasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changePasswordSubmit: async (
      changePasswordRequest: ChangePasswordRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'changePasswordRequest' is not null or undefined
      assertParamExists(
        "changePasswordSubmit",
        "changePasswordRequest",
        changePasswordRequest,
      );
      const localVarPath = `/api/user/portal/v1/changePassword/submit`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        changePasswordRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Xác thực số điện thoại
     * @param {string} phone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkPhoneRegister: async (
      phone: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'phone' is not null or undefined
      assertParamExists("checkPhoneRegister", "phone", phone);
      const localVarPath = `/api/user/portal/v1/register/checkPhone`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (phone !== undefined) {
        localVarQueryParameter["phone"] = phone;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Kiểm tra OTP
     * @param {ForgotPasswordCheckOTPRequest} forgotPasswordCheckOTPRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    forgotPasswordCheckOTP: async (
      forgotPasswordCheckOTPRequest: ForgotPasswordCheckOTPRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'forgotPasswordCheckOTPRequest' is not null or undefined
      assertParamExists(
        "forgotPasswordCheckOTP",
        "forgotPasswordCheckOTPRequest",
        forgotPasswordCheckOTPRequest,
      );
      const localVarPath = `/api/user/portal/v1/forgotPassword/checkOTP`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        forgotPasswordCheckOTPRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Check Phone để reset password
     * @param {string} phone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    forgotPasswordCheckPhone: async (
      phone: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'phone' is not null or undefined
      assertParamExists("forgotPasswordCheckPhone", "phone", phone);
      const localVarPath = `/api/user/portal/v1/forgotPassword/checkPhone`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (phone !== undefined) {
        localVarQueryParameter["phone"] = phone;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Quên mật khẩu
     * @param {ForgotPasswordSubmitRequest} forgotPasswordSubmitRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    forgotPasswordSubmit: async (
      forgotPasswordSubmitRequest: ForgotPasswordSubmitRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'forgotPasswordSubmitRequest' is not null or undefined
      assertParamExists(
        "forgotPasswordSubmit",
        "forgotPasswordSubmitRequest",
        forgotPasswordSubmitRequest,
      );
      const localVarPath = `/api/user/portal/v1/forgotPassword/submit`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        forgotPasswordSubmitRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lấy thông tin người dùng
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserInfo: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/user/portal/v1/getInfo`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Tạo tài khoản người dùng
     * @param {CreateUserRequest} createUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    register: async (
      createUserRequest: CreateUserRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createUserRequest' is not null or undefined
      assertParamExists("register", "createUserRequest", createUserRequest);
      const localVarPath = `/api/user/portal/v1/register/submit`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createUserRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Gửi yêu cầu tạo tài khoản test mode cho User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registerUserTestMode: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/user/portal/v1/registerUserSandBox`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Cập nhật thông tin cho user
     * @param {UpdateUserInfoRequest} updateUserInfoRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserInfo: async (
      updateUserInfoRequest: UpdateUserInfoRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateUserInfoRequest' is not null or undefined
      assertParamExists(
        "updateUserInfo",
        "updateUserInfoRequest",
        updateUserInfoRequest,
      );
      const localVarPath = `/api/user/portal/v1/updateInfo`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateUserInfoRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserControllerApi - functional programming interface
 * @export
 */
export const UserControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    UserControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Đổi mật khẩu
     * @param {CheckPasswordRequest} checkPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async changePasswordCheck(
      checkPasswordRequest: CheckPasswordRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseCheckPasswordResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.changePasswordCheck(
          checkPasswordRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Đổi mật khẩu - submit
     * @param {ChangePasswordRequest} changePasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async changePasswordSubmit(
      changePasswordRequest: ChangePasswordRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.changePasswordSubmit(
          changePasswordRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Xác thực số điện thoại
     * @param {string} phone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkPhoneRegister(
      phone: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseForgotPasswordCheckPhoneResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.checkPhoneRegister(phone, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Kiểm tra OTP
     * @param {ForgotPasswordCheckOTPRequest} forgotPasswordCheckOTPRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async forgotPasswordCheckOTP(
      forgotPasswordCheckOTPRequest: ForgotPasswordCheckOTPRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseForgotPasswordCheckOTPResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.forgotPasswordCheckOTP(
          forgotPasswordCheckOTPRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Check Phone để reset password
     * @param {string} phone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async forgotPasswordCheckPhone(
      phone: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseForgotPasswordCheckPhoneResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.forgotPasswordCheckPhone(
          phone,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Quên mật khẩu
     * @param {ForgotPasswordSubmitRequest} forgotPasswordSubmitRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async forgotPasswordSubmit(
      forgotPasswordSubmitRequest: ForgotPasswordSubmitRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.forgotPasswordSubmit(
          forgotPasswordSubmitRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Lấy thông tin người dùng
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserInfo(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseUserResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getUserInfo(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Tạo tài khoản người dùng
     * @param {CreateUserRequest} createUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async register(
      createUserRequest: CreateUserRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseUserResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.register(
        createUserRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Gửi yêu cầu tạo tài khoản test mode cho User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async registerUserTestMode(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.registerUserTestMode(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Cập nhật thông tin cho user
     * @param {UpdateUserInfoRequest} updateUserInfoRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUserInfo(
      updateUserInfoRequest: UpdateUserInfoRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseUserResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserInfo(
        updateUserInfoRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * UserControllerApi - factory interface
 * @export
 */
export const UserControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = UserControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Đổi mật khẩu
     * @param {CheckPasswordRequest} checkPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changePasswordCheck(
      checkPasswordRequest: CheckPasswordRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseCheckPasswordResponse> {
      return localVarFp
        .changePasswordCheck(checkPasswordRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Đổi mật khẩu - submit
     * @param {ChangePasswordRequest} changePasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changePasswordSubmit(
      changePasswordRequest: ChangePasswordRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .changePasswordSubmit(changePasswordRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Xác thực số điện thoại
     * @param {string} phone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkPhoneRegister(
      phone: string,
      options?: any,
    ): AxiosPromise<ResponseBaseForgotPasswordCheckPhoneResponse> {
      return localVarFp
        .checkPhoneRegister(phone, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Kiểm tra OTP
     * @param {ForgotPasswordCheckOTPRequest} forgotPasswordCheckOTPRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    forgotPasswordCheckOTP(
      forgotPasswordCheckOTPRequest: ForgotPasswordCheckOTPRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseForgotPasswordCheckOTPResponse> {
      return localVarFp
        .forgotPasswordCheckOTP(forgotPasswordCheckOTPRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Check Phone để reset password
     * @param {string} phone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    forgotPasswordCheckPhone(
      phone: string,
      options?: any,
    ): AxiosPromise<ResponseBaseForgotPasswordCheckPhoneResponse> {
      return localVarFp
        .forgotPasswordCheckPhone(phone, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Quên mật khẩu
     * @param {ForgotPasswordSubmitRequest} forgotPasswordSubmitRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    forgotPasswordSubmit(
      forgotPasswordSubmitRequest: ForgotPasswordSubmitRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .forgotPasswordSubmit(forgotPasswordSubmitRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lấy thông tin người dùng
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserInfo(options?: any): AxiosPromise<ResponseBaseUserResponse> {
      return localVarFp
        .getUserInfo(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Tạo tài khoản người dùng
     * @param {CreateUserRequest} createUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    register(
      createUserRequest: CreateUserRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseUserResponse> {
      return localVarFp
        .register(createUserRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Gửi yêu cầu tạo tài khoản test mode cho User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registerUserTestMode(
      options?: any,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .registerUserTestMode(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Cập nhật thông tin cho user
     * @param {UpdateUserInfoRequest} updateUserInfoRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserInfo(
      updateUserInfoRequest: UpdateUserInfoRequest,
      options?: any,
    ): AxiosPromise<ResponseBaseUserResponse> {
      return localVarFp
        .updateUserInfo(updateUserInfoRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for changePasswordCheck operation in UserControllerApi.
 * @export
 * @interface UserControllerApiChangePasswordCheckRequest
 */
export interface UserControllerApiChangePasswordCheckRequest {
  /**
   *
   * @type {CheckPasswordRequest}
   * @memberof UserControllerApiChangePasswordCheck
   */
  readonly checkPasswordRequest: CheckPasswordRequest;
}

/**
 * Request parameters for changePasswordSubmit operation in UserControllerApi.
 * @export
 * @interface UserControllerApiChangePasswordSubmitRequest
 */
export interface UserControllerApiChangePasswordSubmitRequest {
  /**
   *
   * @type {ChangePasswordRequest}
   * @memberof UserControllerApiChangePasswordSubmit
   */
  readonly changePasswordRequest: ChangePasswordRequest;
}

/**
 * Request parameters for checkPhoneRegister operation in UserControllerApi.
 * @export
 * @interface UserControllerApiCheckPhoneRegisterRequest
 */
export interface UserControllerApiCheckPhoneRegisterRequest {
  /**
   *
   * @type {string}
   * @memberof UserControllerApiCheckPhoneRegister
   */
  readonly phone: string;
}

/**
 * Request parameters for forgotPasswordCheckOTP operation in UserControllerApi.
 * @export
 * @interface UserControllerApiForgotPasswordCheckOTPRequest
 */
export interface UserControllerApiForgotPasswordCheckOTPRequest {
  /**
   *
   * @type {ForgotPasswordCheckOTPRequest}
   * @memberof UserControllerApiForgotPasswordCheckOTP
   */
  readonly forgotPasswordCheckOTPRequest: ForgotPasswordCheckOTPRequest;
}

/**
 * Request parameters for forgotPasswordCheckPhone operation in UserControllerApi.
 * @export
 * @interface UserControllerApiForgotPasswordCheckPhoneRequest
 */
export interface UserControllerApiForgotPasswordCheckPhoneRequest {
  /**
   *
   * @type {string}
   * @memberof UserControllerApiForgotPasswordCheckPhone
   */
  readonly phone: string;
}

/**
 * Request parameters for forgotPasswordSubmit operation in UserControllerApi.
 * @export
 * @interface UserControllerApiForgotPasswordSubmitRequest
 */
export interface UserControllerApiForgotPasswordSubmitRequest {
  /**
   *
   * @type {ForgotPasswordSubmitRequest}
   * @memberof UserControllerApiForgotPasswordSubmit
   */
  readonly forgotPasswordSubmitRequest: ForgotPasswordSubmitRequest;
}

/**
 * Request parameters for register operation in UserControllerApi.
 * @export
 * @interface UserControllerApiRegisterRequest
 */
export interface UserControllerApiRegisterRequest {
  /**
   *
   * @type {CreateUserRequest}
   * @memberof UserControllerApiRegister
   */
  readonly createUserRequest: CreateUserRequest;
}

/**
 * Request parameters for updateUserInfo operation in UserControllerApi.
 * @export
 * @interface UserControllerApiUpdateUserInfoRequest
 */
export interface UserControllerApiUpdateUserInfoRequest {
  /**
   *
   * @type {UpdateUserInfoRequest}
   * @memberof UserControllerApiUpdateUserInfo
   */
  readonly updateUserInfoRequest: UpdateUserInfoRequest;
}

/**
 * UserControllerApi - object-oriented interface
 * @export
 * @class UserControllerApi
 * @extends {BaseAPI}
 */
export class UserControllerApi extends BaseAPI {
  /**
   *
   * @summary Đổi mật khẩu
   * @param {UserControllerApiChangePasswordCheckRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public changePasswordCheck(
    requestParameters: UserControllerApiChangePasswordCheckRequest,
    options?: AxiosRequestConfig,
  ) {
    return UserControllerApiFp(this.configuration)
      .changePasswordCheck(requestParameters.checkPasswordRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Đổi mật khẩu - submit
   * @param {UserControllerApiChangePasswordSubmitRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public changePasswordSubmit(
    requestParameters: UserControllerApiChangePasswordSubmitRequest,
    options?: AxiosRequestConfig,
  ) {
    return UserControllerApiFp(this.configuration)
      .changePasswordSubmit(requestParameters.changePasswordRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Xác thực số điện thoại
   * @param {UserControllerApiCheckPhoneRegisterRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public checkPhoneRegister(
    requestParameters: UserControllerApiCheckPhoneRegisterRequest,
    options?: AxiosRequestConfig,
  ) {
    return UserControllerApiFp(this.configuration)
      .checkPhoneRegister(requestParameters.phone, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Kiểm tra OTP
   * @param {UserControllerApiForgotPasswordCheckOTPRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public forgotPasswordCheckOTP(
    requestParameters: UserControllerApiForgotPasswordCheckOTPRequest,
    options?: AxiosRequestConfig,
  ) {
    return UserControllerApiFp(this.configuration)
      .forgotPasswordCheckOTP(
        requestParameters.forgotPasswordCheckOTPRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Check Phone để reset password
   * @param {UserControllerApiForgotPasswordCheckPhoneRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public forgotPasswordCheckPhone(
    requestParameters: UserControllerApiForgotPasswordCheckPhoneRequest,
    options?: AxiosRequestConfig,
  ) {
    return UserControllerApiFp(this.configuration)
      .forgotPasswordCheckPhone(requestParameters.phone, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Quên mật khẩu
   * @param {UserControllerApiForgotPasswordSubmitRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public forgotPasswordSubmit(
    requestParameters: UserControllerApiForgotPasswordSubmitRequest,
    options?: AxiosRequestConfig,
  ) {
    return UserControllerApiFp(this.configuration)
      .forgotPasswordSubmit(
        requestParameters.forgotPasswordSubmitRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lấy thông tin người dùng
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public getUserInfo(options?: AxiosRequestConfig) {
    return UserControllerApiFp(this.configuration)
      .getUserInfo(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Tạo tài khoản người dùng
   * @param {UserControllerApiRegisterRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public register(
    requestParameters: UserControllerApiRegisterRequest,
    options?: AxiosRequestConfig,
  ) {
    return UserControllerApiFp(this.configuration)
      .register(requestParameters.createUserRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Gửi yêu cầu tạo tài khoản test mode cho User
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public registerUserTestMode(options?: AxiosRequestConfig) {
    return UserControllerApiFp(this.configuration)
      .registerUserTestMode(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Cập nhật thông tin cho user
   * @param {UserControllerApiUpdateUserInfoRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public updateUserInfo(
    requestParameters: UserControllerApiUpdateUserInfoRequest,
    options?: AxiosRequestConfig,
  ) {
    return UserControllerApiFp(this.configuration)
      .updateUserInfo(requestParameters.updateUserInfoRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
