import { Document } from "@/api/generated-sources/kienlong-pay";
import { DocumentModel, DocumentType } from "@/models/DocumentModel";

export function mapDocumentModal(dto: Document | undefined): DocumentModel {
  return {
    fileName: dto?.fileName ?? "",
    id: dto?.id ?? 0,
    type: DocumentType[
      (dto?.type ?? "BUSINESS_LICENCE") as keyof typeof DocumentType
    ],
    url: dto?.url ?? "",
  };
}
