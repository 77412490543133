export interface ProductModel {
  id: string;
  name: string;
  description: string;
  imageUrl: string;
  priceType: ProductPriceType;
  storageType: ProductStorageType;
  storageQuantity: number;
  sold: number;
  price: number;
  revenue: number;
  createdAt: string;
  createdBy: string;
  modifiedAt: string;
  modifiedBy: string;
  categoryId: number;
  categoryName: string;
  stockStatus?: ProductStockStatus;
  status?: ProductStatus;
  code: string;
  hasPayPage: boolean;
}

export enum ProductPriceType {
  FIXED_PRICE = "FIXED_PRICE",
  QUANTITY_PRICE = "QUANTITY_PRICE",
  CUSTOMER_PRICE = "CUSTOMER_PRICE",
}

export enum ProductStorageType {
  LIMITED = "LIMITED",
  UNLIMITED = "UNLIMITED",
}

export enum ProductStockStatus {
  STOCKING = "STOCKING",
  OUT_OF_STOCK = "OUT_OF_STOCK",
  PENDING = "PENDING",
}

export enum ProductStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum ProductStockStatusString {
  ALL = "ALL",
  STOCKING = "STOCKING",
  OUT_OF_STOCK = "OUT_OF_STOCK",
  PENDING = "PENDING",
}

export const productMapping = (dto: ProductModel) => {
  return {
    id: dto?.id ? dto?.id : "",
    name: dto?.name ? dto?.name : "-",
    description: dto?.description ? dto?.description : "-",
    imageUrl: dto?.imageUrl ? dto?.imageUrl : "",
    priceType: dto?.priceType ?? ProductPriceType.CUSTOMER_PRICE,
    storageType: dto?.storageType ?? ProductStorageType.LIMITED,
    storageQuantity: dto?.storageQuantity ? dto?.storageQuantity : 0,
    sold: dto?.sold ? dto?.sold : 0,
    price: dto?.price ? dto?.price : 0,
    revenue: dto?.revenue ? dto?.revenue : 0,
    createdAt: dto?.createdAt ? dto?.createdAt : "-",
    createdBy: dto?.createdBy ? dto?.createdBy : "-",
    modifiedAt: dto?.modifiedAt ? dto?.modifiedAt : "-",
    modifiedBy: dto?.modifiedBy ? dto?.name : "-",
    categoryId: dto?.categoryId ? dto?.categoryId : 0,
    categoryName: dto?.categoryName ? dto?.categoryName : "-",
    stockStatus: dto?.stockStatus ?? ProductStockStatus?.PENDING,
    status: dto?.status ?? ProductStatus.INACTIVE,
  };
};
