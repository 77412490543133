import { CategoryResponse } from "@/api/generated-sources/kienlong-pay";
import { CategoryModel } from "@/models/CategoryModel";

export function mapCategoryModel(
  dto: CategoryResponse | undefined,
): CategoryModel {
  return {
    createdAt: dto?.createdAt ?? "",
    createdBy: dto?.createdBy ?? "",
    description: dto?.description ?? "",
    id: dto?.id ?? 0,
    modifiedAt: dto?.modifiedAt ?? "",
    modifiedBy: dto?.modifiedBy ?? "",
    name: dto?.name ?? "",
    productCount: dto?.productCount ?? 0,
    code: dto?.code ?? "",
  };
}
