export class Environment {
  /** env for host */
  public static readonly hostApp: string =
    import.meta.env.KLB_PAY_HOST ?? "http://localhost:8080";
  public static readonly hostApiKlbPay: string =
    import.meta.env.KLB_PAY_HOST_API ??
    "https://api-staging.kienlongbank.co/pay";
  public static readonly hostApiFlexSalary: string =
    import.meta.env.KLB_PAY_HOST_API_FLEX_SALARY ??
    "https://api.dev.kienlongbank.co/ewa-middleware";
  public static readonly hostApiStorage: string =
    import.meta.env.KLB_PAY_HOST_API_STORAGE ??
    "https://api-staging.kienlongbank.co";
  public static readonly hostApiPayBox: string =
    import.meta.env.KLB_PAY_HOST_API_PAYBOX ??
    "https://api-paybox-ca-dev.hcm.unicloud.ai";

  public static readonly hostDevDoc: string =
    import.meta.env.KLB_PAY_DEVELOP_DOCUMENT ?? "#";
  public static readonly hostApiKlbPayTestMode: string =
    import.meta.env.KLB_PAY_HOST_API_TEST_MODE ??
    "https://api-staging.kienlongbank.co/pay-mirror";
  public static readonly hostApiStorageTestMode: string =
    import.meta.env.KLB_PAY_HOST_API_STORAGE_TEST_MODE ??
    "https://api-staging.kienlongbank.co";
  public static readonly hostApiPayBoxTestMode: string =
    import.meta.env.KLB_PAY_HOST_API_PAYBOX_TEST_MODE ??
    "https://api-paybox-ca-dev.hcm.unicloud.ai";

  public static readonly feePolicyLink: string =
    import.meta.env.KLB_PAY_FEE_POLICY_LINK ?? "#";
  public static readonly termsLink: string =
    import.meta.env.KLB_PAY_TERMS_LINK ?? "#";
  public static readonly registerTermsLink: string =
    import.meta.env.KLB_PAY_REGISTER_TERMS_LINK ?? "#";
  public static readonly registerPrivacyPolicyLink: string =
    import.meta.env.KLB_PAY_REGISTER_PRIVACY_POLICY_LINK ?? "#";

  /** env for config */
  public static readonly componentPrefix: string =
    import.meta.env.KLB_PAY_COMPONENT_PREFIX ?? "klb_pay";
  public static readonly basePath: string =
    import.meta.env.KLB_PAY_BASE_PATH ?? "/";

  public static readonly saltKey: string =
    import.meta.env.KLB_PAY_SALT_KEY ?? "mch_duolcinu_2211";
  public static readonly timeoutApp: number = import.meta.env
    .KLB_PAY_TIME_OUT_APP
    ? parseInt(import.meta.env.KLB_PAY_TIME_OUT_APP)
    : 300;

  public static readonly onPayPage: boolean =
    (import.meta.env.KLB_PAY_PAY_PAGE ?? "true") === "true";
  public static readonly onPayLink: boolean =
    (import.meta.env.KLB_PAY_PAY_LINK ?? "true") === "true";
  public static readonly onPayBox: boolean =
    (import.meta.env.KLB_PAY_PAY_BOX ?? "true") === "true";
  public static readonly onPayButton: boolean =
    (import.meta.env.KLB_PAY_PAY_BUTTON ?? "true") === "true";
  public static readonly onPayPos: boolean =
    (import.meta.env.KLB_PAY_PAY_POS ?? "true") === "true";
  public static readonly onPayOut: boolean =
    (import.meta.env.KLB_PAY_PAY_OUT ?? "true") === "true";
  public static readonly onBalance: boolean =
    (import.meta.env.KLB_PAY_BALANCE ?? "true") === "true";
  public static readonly onTransaction: boolean =
    (import.meta.env.KLB_PAY_TRANSACTION ?? "true") === "true";
  public static readonly onStatistic: boolean =
    (import.meta.env.KLB_PAY_STATISTIC ?? "true") === "true";
  public static readonly onPayGate: boolean =
    (import.meta.env.KLB_PAY_PAY_GATE ?? "true") === "true";
  public static readonly onProduct: boolean =
    (import.meta.env.KLB_PAY_PRODUCT ?? "true") === "true";
  public static readonly onCustomer: boolean =
    (import.meta.env.KLB_PAY_CUSTOMER ?? "true") === "true";
  public static readonly onSupport: boolean =
    (import.meta.env.KLB_PAY_SUPPORT ?? "true") === "true";
  public static readonly onMember: boolean =
    (import.meta.env.KLB_PAY_MEMBER ?? "true") === "true";
  public static readonly onBankAccount: boolean =
    (import.meta.env.KLB_PAY_BANK_ACCOUNT ?? "true") === "true";
  public static readonly onChangePassword: boolean =
    (import.meta.env.KLB_PAY_CHANGE_PASSWORD ?? "true") === "true";
  public static readonly onEmailNoti: boolean =
    (import.meta.env.KLB_PAY_EMAIL_NOTI ?? "true") === "true";
  public static readonly onPaymentSetting: boolean =
    (import.meta.env.KLB_PAY_PAYMENT_SETTING ?? "true") === "true";
  public static readonly onForControl: boolean =
    (import.meta.env.KLB_PAY_FOR_CONTROL ?? "true") === "true";
  public static readonly onFeeReport: boolean =
    (import.meta.env.KLB_PAY_FEE_REPORT ?? "true") === "true";
  public static readonly onPaySubscription: boolean =
    (import.meta.env.KLB_PAY_SUBSCRIPTION ?? "true") === "true";
  public static readonly onPaymentMethodVietQr: boolean =
    (import.meta.env.KLB_PAY_PAYMENT_METHOD_VIET_QR ?? "true") === "true";
  public static readonly onPaymentMethodAtmcard: boolean =
    (import.meta.env.KLB_PAY_PAYMENT_METHOD_ATM_CARD ?? "true") === "true";
  public static readonly onPaymentMethodBanking: boolean =
    (import.meta.env.KLB_PAY_PAYMENT_METHOD_BANKING ?? "true") === "true";
  public static readonly onLoginQr: boolean =
    (import.meta.env.KLB_PAY_LOGIN_BY_QR ?? "true") === "true";
  public static readonly onUltilities: boolean =
    (import.meta.env.KLB_PAY_UTILITIES ?? "false") === "true";
  public static readonly onUltilitiesVietQrPro: boolean =
    (import.meta.env.KLB_PAY_UTILITIES_VIETQR_PRO ?? "true") === "true";
  public static readonly onUltilitiesAppMarket: boolean =
    (import.meta.env.KLB_PAY_UTILITIES_APP_MARKET ?? "true") === "true";
  public static readonly onUltilitiesFlexibleSalaryPayment: boolean =
    (import.meta.env.KLB_PAY_UTILITIES_FLEXIBLE_SALARY_PAYMENT ?? "true") ===
    "true";

  public static readonly vietQrProDocumentUrl: string = import.meta.env
    .KLB_PAY_VIET_QR_PRO_DOCUMENT_URL;
}
