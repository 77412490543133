export interface VietQrProModel {
  id: number;
  name: string;
  active: boolean;
  accountNo: string;
  accountName: string;
  bankName: string;
  createAt: string;
  revenue: number;
}

export enum VietQrProStatusType {
  INACTIVE = "INACTIVE",
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  LOCK = "LOCK",
  REJECTED = "REJECTED",
}

export enum PortalNextStateVietQrProRequestActionType {
  REQUEST_ACTIVATION = "REQUEST_ACTIVATION",
  ENABLE = "ENABLE",
  DISABLE = "DISABLE",
}
