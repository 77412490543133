import { FlexSalaryClient } from "@/api/flexsalary_http_client";
import { ErrorModel } from "@/models/ErrorModel";
import { LogworkRepository } from "../LogworkRepository";
import {
  mapDayOfWeekItemModel,
  mapLogworkTableRecordModel,
  mapLogworkTableRecordWithStatusModel,
} from "../adapters/logwork-adapter";
import {
  DayOfWeekItemModel,
  LogworkTableRecordModel,
  LogworkTableRecordWithStatusModel,
} from "../models/LogworkModel";

export class LogworkRepositoryImp implements LogworkRepository {
  private _c: FlexSalaryClient;
  constructor(flexsalaryClient: FlexSalaryClient) {
    this._c = flexsalaryClient;
  }

  public async createLogwork({
    override,
    data,
    periodId,
  }: {
    override: boolean;
    periodId: string;
    data: {
      employeeCode: string;
      employeeName: string;
      items: Array<{ date: string; workload: number }>;
    }[];
  }): Promise<void> {
    try {
      let resp = await this._c.logwork.createLogwork({
        logworkRequest: {
          data: data.map((d) => {
            return {
              employeeCode: d.employeeCode,
              employeeName: d.employeeName,
              items: d.items.map((it) => {
                return {
                  date: it.date,
                  workload: it.workload,
                };
              }),
            };
          }),
          overrideOldData: override,
        },
        periodId: periodId,
      });
      if (resp.data.code === 20000) {
        return;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async uploadLogworkFile({
    file,
    periodId,
  }: {
    periodId: string;
    file: File;
  }): Promise<{
    listDaysOfWeek: Array<DayOfWeekItemModel>;
    validItems: Array<LogworkTableRecordModel>;
    invalidItems: Array<LogworkTableRecordWithStatusModel>;
  }> {
    try {
      let resp = await this._c.logwork.uploadLogworkFile({
        periodId: periodId,
        file: file,
      });
      if (resp.data.code === 20000) {
        return {
          listDaysOfWeek:
            resp.data.data?.listDaysOfWeek?.map((d) =>
              mapDayOfWeekItemModel(d),
            ) ?? [],
          invalidItems:
            resp.data.data?.invalidItems?.map((d) =>
              mapLogworkTableRecordWithStatusModel(d),
            ) ?? [],
          validItems:
            resp.data.data?.validItems?.map((d) =>
              mapLogworkTableRecordModel(d),
            ) ?? [],
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async listLogwork({
    page = 0,
    size = 10,
    keyword,
    periodId,
  }: {
    keyword?: string;
    periodId: string;
    page?: number;
    size?: number;
  }): Promise<{
    listDaysOfWeek: Array<DayOfWeekItemModel>;
    records: Array<LogworkTableRecordModel>;
    totalRecords: number;
  }> {
    try {
      let resp = await this._c.logwork.logworkList({
        page: page,
        size: size,
        periodId: periodId,
        keyword: keyword,
      });
      if (resp.data.code === 20000) {
        return {
          listDaysOfWeek:
            resp.data.data?.listDaysOfWeek?.map((d) =>
              mapDayOfWeekItemModel(d),
            ) ?? [],
          records:
            resp.data.data?.records?.data?.map((d) =>
              mapLogworkTableRecordModel(d),
            ) ?? [],
          totalRecords: resp.data.data?.records?.totalElements ?? 0,
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
  public async updateLogwork({
    data,
  }: {
    data: {
      employeeCode: string;
      employeeName: string;
      items: Array<{ date: string; workload: number }>;
    }[];
  }): Promise<void> {
    try {
      let resp = await this._c.logwork.updateLogwork({
        updateLogworkRequest: {
          data: data,
        },
      });
      if (resp.data.code === 20000) {
        return;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
  public async templateFile({ periodId }: { periodId: string }): Promise<File> {
    try {
      const resp = await this._c.logwork.generateTemplateFile(
        {
          periodId: periodId,
        },
        { responseType: "arraybuffer" },
      );
      return resp.data as File;
    } catch (error) {
      throw error;
    }
  }
}
