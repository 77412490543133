import { ErrorModel } from "@/models/ErrorModel";
import { PhoneOtpModel } from "@/models/PhoneOtpModel";
import { UserModel } from "@/models/UserModel";
import { UserReposiroty } from "../UserRepository";
import { hashString } from "@/utils/hashString";
import { KienlongPayClient } from "@/api/kienlong_pay_http_client";
import { mapUserModel } from "@/adapters/user_adapter";
import {
  Configuration,
  UserControllerApi,
} from "@/api/generated-sources/kienlong-pay";
import { Environment } from "@/services/environment";

export class UserReposirotyImp implements UserReposiroty {
  private client: KienlongPayClient;
  constructor(kienlongPayClient: KienlongPayClient) {
    this.client = kienlongPayClient;
  }

  public async otpRegister({
    phoneNumber,
  }: {
    phoneNumber: string;
  }): Promise<PhoneOtpModel> {
    let _client = new UserControllerApi(
      new Configuration({
        basePath: Environment.hostApiKlbPay,
      }),
    );
    try {
      let resp = await _client.checkPhoneRegister({
        phone: phoneNumber,
      });
      if (resp.data.code === 0) {
        return {
          sessionId: resp.data.data?.sessionId ?? "",
          timeOut: resp.data.data?.timeout ?? 0,
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async register({
    sessionId,
    phone,
    otp,
    password,
    affiliateCode,
  }: {
    sessionId: string;
    phone: string;
    otp: string;
    password: string;
    affiliateCode?: string;
  }): Promise<UserModel> {
    let _client = new UserControllerApi(
      new Configuration({
        basePath: Environment.hostApiKlbPay,
      }),
    );
    try {
      let resp = await _client.register({
        createUserRequest: {
          otp: otp,
          password: hashString(password),
          phone: phone,
          sessionId: sessionId,
          affiliateCode: affiliateCode,
        },
      });
      if (resp.data.code === 0) {
        return mapUserModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async otpResetPassword({
    phoneNumber,
  }: {
    phoneNumber: string;
  }): Promise<PhoneOtpModel> {
    let _client = new UserControllerApi(
      new Configuration({
        basePath: Environment.hostApiKlbPay,
      }),
    );
    try {
      let resp = await _client.forgotPasswordCheckPhone({
        phone: phoneNumber,
      });
      if (resp.data.code === 0) {
        return {
          sessionId: resp.data.data?.sessionId ?? "",
          timeOut: resp.data.data?.timeout ?? 0,
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async verifyOtpPhoneResetPassword({
    otp,
    phoneNumber,
    sessionId,
  }: {
    otp: string;
    phoneNumber: string;
    sessionId: string;
  }): Promise<PhoneOtpModel> {
    let _client = new UserControllerApi(
      new Configuration({
        basePath: Environment.hostApiKlbPay,
      }),
    );
    try {
      let resp = await _client.forgotPasswordCheckOTP({
        forgotPasswordCheckOTPRequest: {
          otp: otp,
          phone: phoneNumber,
          sessionId: sessionId,
        },
      });
      if (resp.data.code === 0) {
        return {
          sessionId: resp.data.data?.sessionId ?? "",
          timeOut: resp.data.data?.timeout ?? 0,
          token: resp.data.data?.token,
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async submitResetPassword({
    phoneNumber,
    sessionId,
    token,
    password,
  }: {
    phoneNumber: string;
    sessionId: string;
    token: string;
    password: string;
  }): Promise<boolean> {
    let _client = new UserControllerApi(
      new Configuration({
        basePath: Environment.hostApiKlbPay,
      }),
    );
    try {
      let resp = await _client.forgotPasswordSubmit({
        forgotPasswordSubmitRequest: {
          phone: phoneNumber,
          sessionId: sessionId,
          password: hashString(password),
          token: token,
        },
      });
      if (resp.data.code === 0) {
        return resp.data.data?.success ?? false;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async getInfo(): Promise<UserModel> {
    let _client = new UserControllerApi(
      new Configuration({
        basePath: Environment.hostApiKlbPay,
      }),
    );
    try {
      let resp = await _client.getUserInfo();
      if (resp.data.code === 0) {
        return mapUserModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async updateInfo({ avatar }: { avatar: string }): Promise<UserModel> {
    let _client = new UserControllerApi(
      new Configuration({
        basePath: Environment.hostApiKlbPay,
      }),
    );
    try {
      let resp = await _client.updateUserInfo({
        updateUserInfoRequest: {
          avatar: avatar,
        },
      });
      if (resp.data.code === 0) {
        return mapUserModel(resp.data.data);
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async checkPasswordChanged({
    password,
  }: {
    password: string;
  }): Promise<{
    sessionId: string;
    token: string;
    timeout: number;
  }> {
    let _client = new UserControllerApi(
      new Configuration({
        basePath: Environment.hostApiKlbPay,
      }),
    );
    try {
      let resp = await _client.changePasswordCheck({
        checkPasswordRequest: {
          password: hashString(password),
        },
      });
      if (resp.data.code === 0) {
        return {
          sessionId: resp.data.data?.sessionId ?? "",
          timeout: resp.data.data?.timeout ?? 0,
          token: resp.data.data?.token ?? "",
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  public async submitPasswordChanged({
    password,
    token,
    sessionId,
  }: {
    password: string;
    token: string;
    sessionId: string;
  }): Promise<boolean> {
    let _client = new UserControllerApi(
      new Configuration({
        basePath: Environment.hostApiKlbPay,
      }),
    );
    try {
      let resp = await _client.changePasswordSubmit({
        changePasswordRequest: {
          password: hashString(password),
          sessionId: sessionId,
          token: token,
        },
      });
      if (resp.data.code === 0) {
        return resp.data.data?.success ?? false;
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
