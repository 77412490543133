import { KienlongPayClient } from "@/api/kienlong_pay_http_client";
import { ErrorModel } from "@/models/ErrorModel";
import { PosOderRepository } from "../PosOrderRepository";
import { SortDirectionType } from "@/models/Sort";
import { PageResponsePosOrderListResponse } from "@/api/generated-sources/kienlong-pay";
import {
  PosOrderStatusPaymentType,
  PosOrderStatusType,
} from "@/models/PosOrderModel";

export class PosOderRepositoryImp implements PosOderRepository {
  private client: KienlongPayClient;
  constructor(kienlongPayClient: KienlongPayClient) {
    this.client = kienlongPayClient;
  }

  public async portalGetListOrder({
    posOrderStatusPayment,
    posOrderStatus,
    page,
    pageSize,
    sortDirection,
    sortBy,
  }: {
    posOrderStatusPayment?: PosOrderStatusPaymentType;
    posOrderStatus: PosOrderStatusType;
    page?: number;
    pageSize?: number;
    sortDirection?: SortDirectionType;
    sortBy?: string;
  }): Promise<PageResponsePosOrderListResponse> {
    try {
      let resp = await this.client.posOrderController.portalGetListOrder({
        posOrderStatusPayment,
        posOrderStatus,
        page,
        pageSize,
        sortDirection,
        sortBy,
      });
      if (resp.data.code === 0) {
        return {
          pageNumber: resp.data.data?.pageNumber ?? 0,
          pageSize: resp.data.data?.pageSize ?? 0,
          totalPage: resp.data.data?.totalPage ?? 0,
          totalSize: resp.data.data?.totalSize ?? 0,
          items: resp.data.data?.items,
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
