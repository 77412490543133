import {
  GetPageListPosPortalResponse,
  GetPosHistoryTransactionPortalResponse,
  PayPosTransactionDetailResponse,
} from "@/api/generated-sources/kienlong-pay";
import { PaymentType } from "@/models/PaylinkModel";
import {
  PagePosPortalDetailModel,
  PagePosPortalHistoryModel,
  PagePosPortalModel,
  PosPortalHistoryStatus,
  PosPortalStatus,
  PosPortalTypeModel,
} from "@/models/PosModelModel";

export function mapPayPosModel(
  dto: GetPageListPosPortalResponse | undefined,
): PagePosPortalModel {
  return {
    id: dto?.id ?? "---",
    deviceId: dto?.deviceId ?? "---",
    model: PosPortalTypeModel[dto?.model as keyof typeof PosPortalTypeModel],
    revenue: dto?.revenue ?? 0,
    status: PosPortalStatus[dto?.status as keyof typeof PosPortalStatus],
    transactionNotSettlementNumber: dto?.transactionNotSettlementNumber ?? 0,
  };
}

export function mappingPayPosHistoryTransaction(
  dto: GetPosHistoryTransactionPortalResponse,
): PagePosPortalHistoryModel {
  return {
    id: dto?.id ?? "---",
    createdAt: dto?.createdAt ?? "---",
    amount: dto?.amount ?? 0,
    status:
      PosPortalHistoryStatus[
        dto?.status as keyof typeof PosPortalHistoryStatus
      ],
    customerName: dto?.customerName ?? "---",
    accountNumber: dto?.accountNumber ?? "---",
    deviceId: dto?.deviceId ?? "---",
    finish: dto?.finish ?? false,
    settlementTime: dto?.settlementTime ?? "---",
    staffName: dto?.staffName ?? "---",
  };
}

export const mappingPayPosDetailTransaction = (
  dto: PayPosTransactionDetailResponse,
): PagePosPortalDetailModel => {
  return {
    id: dto?.id ? dto?.id : "---",
    amount: dto?.amount ? dto?.amount : 0,
    paymentType: PaymentType[dto?.paymentType as keyof typeof PaymentType],
    accountNumber: dto?.accountNumber ?? "---",
    createdAt: dto?.createdAt ?? "---",
    customerEmail: dto?.customerEmail ?? "---",
    customerName: dto?.customerName ?? "---",
    deviceName: dto?.deviceName ?? "---",
    finish: dto?.finish ?? false,
    staffName: dto?.staffName ?? "---",
    status:
      PosPortalHistoryStatus[
        dto?.status as keyof typeof PosPortalHistoryStatus
      ],
    transactionCode: dto?.transactionCode ?? "---",
  };
};
