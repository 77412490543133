export interface DayOfWeekItemModel {
  dayOfWeek: DayOfWeekType;
  dateString: string;
  workDate: boolean;
}

export enum DayOfWeekType {
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY",
}

export interface LogworkTableRecordWithStatusModel {
  id?: number;
  employeeCode: string;
  employeeName: string;
  numberOfWorkDays: number;
  totalWorkDays: number;
  logworks: Array<number>;
  status: Array<string>;
}

export interface LogworkTableRecordModel {
  employeeCode: string;
  employeeName: string;
  numberOfWorkDays: number;
  totalWorkDays: number;
  logworks: Array<number>;
}
