import { BehaviorSubject } from "rxjs";
import { ErrorModel } from "@/models/ErrorModel";
import { LoginStatus } from "@/models/LoginStatus";
import { Session } from "@/domains/session";

export class AppSession extends Session {
  public loginStatus: BehaviorSubject<LoginStatus> =
    new BehaviorSubject<LoginStatus>(LoginStatus.Idle);
  public isDarkMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );

  public isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  public isShowModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  public modal: BehaviorSubject<JSX.Element | null> =
    new BehaviorSubject<JSX.Element | null>(null);

  public error: BehaviorSubject<ErrorModel | null> =
    new BehaviorSubject<ErrorModel | null>(null);

  public isShowBreadcrumb: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public breadcrumb: BehaviorSubject<JSX.Element | null> =
    new BehaviorSubject<JSX.Element | null>(null);

  public isShowMessage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  public message: BehaviorSubject<JSX.Element | null> =
    new BehaviorSubject<JSX.Element | null>(null);

  public bottomBarComponent: BehaviorSubject<JSX.Element | null> =
    new BehaviorSubject<JSX.Element | null>(null);

  public queueModal: BehaviorSubject<JSX.Element | null> =
    new BehaviorSubject<JSX.Element | null>(null);

  public routersAndRoles: {
    id: number;
    routerPaths: string[];
  }[] = [];

  public closeAll() {
    this.bottomBarComponent.complete();
    this.isShowBreadcrumb.complete();
    this.isLoading.complete();
    this.isShowModal.complete();
    this.isShowMessage.complete();
    this.error.complete();
    this.isDarkMode.complete();
    this.loginStatus.complete();
    this.modal.complete();
    this.message.complete();
  }
}
