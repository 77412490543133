import { PaymentType } from "./PaylinkModel";
import { ShopSettingModel } from "./ShopModel";

export interface BankTransferInfoModel {
  shopName: string;
  merchantCode: string;
  merchantLogoUrl: string;
  amount: number;
  description: string;
  bankName: string;
  accountNo: string;
  accountName: string;
  content: string;
  successUrl: string;
  failUrl: string;
  redirectAfter: number;
  status: PayInfoStatus;
}

export interface VietQrInfoModel {
  shopName: string;
  merchantCode: string;
  merchantLogoUrl: string;
  qrContent: string;
  amount: number;
  description: string;
  successUrl: string;
  failUrl: string;
  redirectAfter: number;
  status: PayInfoStatus;
  email: string;
  phone: string;
  bankAccountName: string;
  bankAccountNo: string;
  shopSetting: ShopSettingModel;
  virtualAccount: string;
}

export interface PayInfoModel {
  status: PayInfoStatus;
  successUrl: string;
  failUrl: string;
  redirectAfter: number;
  rtxNumber: string;
  dateTime: string;
  paymentType: PaymentType;
  description: string;
  accountName: string;
  amount: number;
}

export enum PayInfoStatus {
  NONE = "NONE",
  CREATED = "CREATED",
  SUCCESS = "SUCCESS",
  CANCELED = "CANCELED",
  FAIL = "FAIL",
  TIMEOUT = "TIMEOUT",
}
