export interface DocumentModel {
  id: number;
  fileName: string;
  type: DocumentType;
  url: string;
}

export enum DocumentType {
  BUSINESS_LICENCE = "BUSINESS_LICENCE",
}
