import { KienlongPayClient } from "@/api/kienlong_pay_http_client";
import { ErrorModel } from "@/models/ErrorModel";
import { SortDirectionType } from "@/models/Sort";
import { EWalletModel } from "@/models/EWalletModel";
import { EWalletRepository } from "../EWalletRepository";

export class EWalletRepositoryImp implements EWalletRepository {
  private client: KienlongPayClient;
  constructor(payboxClient: KienlongPayClient) {
    this.client = payboxClient;
  }
  async getAllEWallet({
    name,
    page,
    pageSize,
    sortBy,
    sortDirection,
  }: {
    name?: string | undefined;
    page?: number | undefined;
    pageSize?: number | undefined;
    sortBy?: string | undefined;
    sortDirection: SortDirectionType;
  }): Promise<{
    pageNumber: number;
    pageSize: number;
    totalSize: number;
    totalPage: number;
    items: EWalletModel[];
  }> {
    try {
      let resp = await this.client.eWalletController.getAllEWallets({
        name: name,
        pageNumber: page,
        pageSize: pageSize,
        sortBy: sortBy,
        sortDir: sortDirection,
      });
      if (resp.data.code === 0) {
        return {
          pageNumber: resp.data.data?.pageNumber ?? 0,
          pageSize: resp.data.data?.pageSize ?? 0,
          totalPage: resp.data.data?.totalPage ?? 0,
          totalSize: resp.data.data?.totalSize ?? 0,
          items:
            resp.data.data?.items?.map((d): EWalletModel => {
              return {
                id: d.id,
                name: d.name ?? "",
                shortName: d.shortName ?? "",
                commonName: d.commonName ?? "",
                logo: d.logo ?? "",
                icon: d.icon ?? "",
              };
            }) ?? [],
        };
      } else {
        throw new ErrorModel({
          code: resp.data.code,
          message: resp.data.message,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
