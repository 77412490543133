import { PayButtonResponse } from "@/api/generated-sources/kienlong-pay";

export const PayButtonThemeType = {
  Light: "LIGHT",
  Dark: "DARK",
} as const;

export interface PayButtonModel {
  id: string;
  code: string;
  title: string;
  label: string;
  theme: (typeof PayButtonThemeType)[keyof typeof PayButtonThemeType];
  numOfPayments: number;
  numOfClicks: number;
  script: string;
  payPageInfo: PayPageInfo;
}

export interface PayPageInfo {
  payPageId: number;
  payPageCode: string;
  payPageName: string;
}

export interface PayButtonPagination {
  pageNumber: number;
  pageSize: number;
  totalSize: number;
  totalPage: number;
  items: Array<PayButtonModel>;
}

export interface PayButtonRequestCreate {
  payPageId: number;
  title: string;
  label: string;
  theme: (typeof PayButtonThemeType)[keyof typeof PayButtonThemeType];
}

export interface PayButtonRequestUpdate {
  payPageId: number;
  payButtonId: string;
  title: string;
  label: string;
  theme: (typeof PayButtonThemeType)[keyof typeof PayButtonThemeType];
}

export enum ThemeButton {
  DARK = "Tối",
  LIGHT = "Sáng",
}

export interface ExternalButton {
  title: string;
  label: string;
  theme: (typeof PayButtonThemeType)[keyof typeof PayButtonThemeType];
  referencePageUrl: string;
}

export const payButtonMapping = (data: PayButtonResponse | undefined) => {
  return {
    id: data?.id ?? "---",
    code: data?.code ?? "---",
    title: data?.title ?? "---",
    label: data?.label ?? "---",
    theme: data?.theme ?? "DARK",
    numOfPayments: data?.numOfPayments ?? 0,
    numOfClicks: data?.numOfClicks ?? 0,
    script: data?.script ?? "---",
    payPageInfo: {
      payPageCode: data?.payPageInfo?.payPageCode ?? "---",
      payPageId: data?.payPageInfo?.payPageId ?? 0,
      payPageName: data?.payPageInfo?.payPageName ?? "---",
    },
  };
};
